import React, { Component } from 'react';

export default React.createContext({
	Inbox: [],
	countInbox: '',
	account: '',
	pagination: {},
	pageNumber: [],
	disableBPage: true,
	disablepage: false,
	customPageSize: '10',
	currentPage: 1,
	isLoading: false,
	update: ''
});
