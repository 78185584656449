import React from 'react';

const TextArea = props => {
	const {
		edit = false,
		onEditHandleChange,
		newError = {},
		marginTop,
		feildName,
		iconClassName,
		iconColor,
		inputLabelName,
		textFont,
		customWidth,
		enterWidth,
		design,
		readOnly,
		dispatchFlag,
		dispatch,
		placeholder,
		name,
		disabledField,
		required,
		errors,
		stateData,
		onHandleChange,
		row,
		col,
		isValidte
	} = props;
	return (
		<div class="col-md-6" style={{ marginTop: marginTop }}>
			{/* <textarea name="" id="" cols="30" rows="10"></textarea> */}
			<div class="md-form mb-0">
				<label for={feildName}>
					<i
						className={iconClassName}
						style={{ marginRight: 4, color: iconColor }}
					/>
					<p style={{ margin: 0, fontSize: textFont, color: '#000' }}>
						{inputLabelName}
					</p>
				</label>
				<textarea
					readOnly={readOnly}
					placeholder={placeholder}
					name={feildName}
					class={`form-control ${
						errors[feildName] && stateData[feildName] === '' && isValidte
							? 'is-invalid'
							: stateData[feildName] !== '' && isValidte
							? 'is-valid'
							: ''
					}`}
					onChange={
						edit
							? e => onEditHandleChange(e)
							: dispatchFlag
							? e =>
									dispatch({
										type: 'onChange',
										payload: { name: feildName, value: e.target.value }
									})
							: !isValidte
							? e => onHandleChange(e, stateData.current)
							: e => onHandleChange(e)
					}
					disabled={stateData[disabledField]}
					value={stateData[feildName]}
					required={required}
					style={{
						width: customWidth ? enterWidth : '',
						borderTop: design ? 0 : '',
						borderLeft: design ? 0 : '',
						borderRight: design ? 0 : '',
						boxShadow: design ? 'none' : ''
					}}
					cols={col}
					rows={row}
				></textarea>
				{Object.keys(newError).length > 0 && newError[feildName] ? (
					<p style={{ color: 'red', fontSize: 15 }}>field required</p>
				) : (
					''
				)}
				{/* <input
        style={{
          width: customWidth ? enterWidth : '',
          borderTop: design ? 0 : '',
          borderLeft: design ? 0 : '',
          borderRight: design ? 0 : '',
          boxShadow: design ? 'none' : ''
        }}
        readOnly={readOnly}
        type={type}
        placeholder={placeholder}
        name={feildName}
        class={`form-control ${
          errors[feildName] && stateData[feildName] === ''
            ? 'is-invalid'
            : stateData[feildName] !== ''
            ? 'is-valid'
            : ''
        }`}
        onChange={e => onHandleChange(e)}
        disabled={stateData[disabledField]}
        value={stateData[feildName]}
        required={required}
      /> */}
			</div>
		</div>
	);
};

export default TextArea;
