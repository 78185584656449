import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import DateandTime from '../Transport/DateandTime';
import isNull from 'lodash/isNull'
import DatePicker from 'react-date-picker';
import SnackBar from '../../components/SnackBar/SnackBar';
import moment from 'moment'
import { type } from 'jquery';

import LoaderWrapper from "../../components/HOC/LoaderWrapper";
import ThemeContext from "../../context/themeContext/ThemeContext";



class AddManagerShortLeave extends Component {
    constructor(props){
        super(props);
        this.state = {
            errorMsg: false,
            successMsg: false,
            msg: '',
            isLoading: false,

            file: null,
            description: null,
            type: null,
            needOfOtherResource: null
            
        }
    }

    componentDidMount() {
        this.getSimple(`ManagerStaffId?userName=${this.props.userData.unique_name}`, 'staffData');

    }


    getSimple = (type, state) => {
        this.props.sharedActions.getDataWithoutDispatch(
            `/api/${type}`
        ).then(success => {
            this.setState({
                [state]: success,
                isLoading: false
            })


        }).catch(error => {
            this.setState({
                msg: error.response ? error.response.data.Message : 'Error Occured!!',
                errorMsg: true,
                isLoading: false
            })
            console.error('Error occured', error);
        })
    }

    myFunction = () => {
		// Get the snackbar DIV
		var x = document.getElementById('snackbar');

		// StepperGeneralButtons the "show" class to DIV
		x.className = 'show';

		// After 3 seconds, remove the show class from DIV
		setTimeout(function() {
		}, 3000);
	};

    onHandleTextChange = e => {
        console.log([e.target.name], e.target.value);
        
        this.setState({
            [e.target.name]: e.target.value
        })
    }



    handleSubmit = e => {
        e.preventDefault();
        const { 
            description,
            type,
            needOfOtherResource,
            staffData
         } = this.state;

         let staffId = staffData && staffData.managerStaff && staffData.managerStaff.length > 0 && staffData.managerStaff[0].staffId
        this.getSimple(`ShortLeaveValidation/?staffId=${staffId}`, 'valid');
        console.log(this.state.valid);
        
         
        this.setState({
            isLoading: true
        })
        if(
            isNull(description) || 
            isNull(this.state.type) ||
            isNull(staffId) ||
            isNull(needOfOtherResource) && this.state.valid == 'Proceed'

        ){
            this.setState({
                msg: 'Please fill the form respectively!'
            })
            this.props.snackbar()
        }else{
            
            let data = {
                staffId,
                description,
                type: this.state.type,
                needOfOtherResource,
                accountId: this.props.userData.unique_name,
                accountType: this.props.userData.role 
            }

        this.props.sharedActions.addRecordWithoutDispatch(
            `/api/ShortLeaves`,
            data
        ).then(success => {
            console.log(success);
            
            this.setState({
                msg: 'Leave Form Submitted!!',
                isLoading: false,
                fromDate: null,
                toDate: null,
                description: null,
                file: null,
            })
            this.props.snackbar()
        }).catch(error => {
            this.setState({
                msg: error.response ?  error.response.data.Message : 'Error Occured!!',
                isLoading: false
            })
            this.props.snackbar()

            console.error('Error occured', error);
            
        })
        }    
    }


    render(){

        const { successMsg, errorMsg, msg, isLoading, termList, student, session, talentCategory, talentSubCategory, valid,
                description, needOfOtherResource, 
             } = this.state;

        return (
            <div className="container">
				<section class="mb-4">
				<ThemeContext.Consumer>
                    {(context) => (
                    <h2  
                        style={{ background: context.themeColors.primaryColor }}
                        className="h1-responsive font-weight-bold text-center my-4 generalHead">
                        Add Short Leave
                    </h2>
                     )}
                </ThemeContext.Consumer>
				<SnackBar msg={this.state.msg} backColor={this.state.backColor} />

				 <p class="text-center w-responsive mx-auto mb-5" />

                    <div className="row" style={{
                        justifyContent: 'center',
                        textAlign: 'center'
                    }}>

                <ThemeContext.Consumer>
                {(context) => (
                        <div className="col-md-9 mb-md-0 mb-5" style={{
                            border: 'solid',
                            borderColor: context.themeColors.primaryColor,
                            padding: 40,
                            borderRadius: 15,
                            width: 'auto'

                        }}>
                      <LoaderWrapper isLoading={this.state.isLoading}>
                            <form id="payroll-form" name="payroll-form" onSubmit={this.handleSubmit} >
                                <div className="row">
									<div className="col-md-12">
										<div className="md-form mb-0">
											<DateandTime />
										</div>
									</div>
								</div>
								<br />
                                <div class="row">
									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="AdminName" class="">
												Admin Name
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												type="text"
												name="AdminName"
												class="form-control"
												readOnly={true}
												value={this.props.userData.unique_name}
											/>
										</div>
								    </div>

									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="AdminType" class="">
												Admin Type
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												type="text"
												name="AdminType"
												class="form-control"
												readOnly={true}
												value={this.props.userData.role}
											/>
										</div>
								    </div>
                                </div>
                                    <br/>
                                <div className="row">
                                <div className="col-md-6">
                                        <div className="md-form mb-0" >
                                            <label for="type">
                                                Type
                                            </label>
                                            <select class="custom-select" 
                                                value={this.state.type}
                                                name="type"
                                                onChange={this.onHandleTextChange}
                                            >
                                                        <option value="">Please Select Leave Type</option>
                                                        <option value="Half Day">Hald Day</option>
                                                        <option value="Other">Other</option>
                                                       
                                            </select>  
                                        </div>
                                        </div>
                                        <div className="col-md-6">
                                        <div className="md-form mb-0" >
                                            <label for="needOfOtherResource">
                                                Need of Other Resource
                                            </label>
                                            <select class="custom-select" 
                                                value={needOfOtherResource}
                                                name="needOfOtherResource"
                                                onChange={this.onHandleTextChange}
                                            >
                                                        <option value="">Please Select Need</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">No</option>
                                                       
                                            </select>  
                                        </div>
                                        </div>
                                </div>  
                                <br/>
                                <div className="row">
                                        <div className="col-md-12">
                                        <div className="md-form mb-0" >
                                        <label for="description">
                                                Description
                                            </label>
                                            
                                            <textarea class="form-control"
                                                rows={6}
                                                value={description}
                                                name="description"
                                                onChange={this.onHandleTextChange}
                                            />
                                            
                                        </div>
                                        </div>
                                        
                                </div>
                                
                                        <br/>
                                        <br/>
                                        <div className="text-center text-md-center">
								<button type="submit" className="btns" style={{
                                                    background: context.themeColors.primaryColor,
                                            }}>
										Add
										<span
											style={{ marginBottom: 5 }}
											class={`${
												this.state.isLoading
													? 'spinner-border spinner-border-sm'
													: ''
											}`}
										></span>
									</button>
								</div>
							</form>
							</LoaderWrapper>
                            <div class="status" />
                </div>
              )}
            </ThemeContext.Consumer>
                    </div>
				</section>
			</div>
        );
    }

}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch),
	};
}
function mapStateToProps(state) {
	return {
		userData: state.setUserReducer.users,
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AddManagerShortLeave);
