import React from "react";
import { connect } from "react-redux";
import $ from "jquery";
import SelectionInput from "../../components/SelectionInput/SelectionInput";
import TextInput from "../../components/TextInput/TextInput";
import FormLayoutWrapper from "../../components/HOC/FormLayoutWrapper";
import * as sharedActions from "../../Actions/sharedActions";
import Button from "../../components/Button";
import SnackBar from "../../components/SnackBar/SnackBar";
import { bindActionCreators } from "redux";
import Spinner from "../../components/Spinner/Spinner";
import ThemeContext from "../../context/themeContext/ThemeContext";

class AddOtherCertificate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      accountId: props.name,
      accountType: props.role,
      errors: {},
      errorMsg: false,
      isLoadingInitialData: true,
      certificateData: [],
      name: "",
      certificateId: "",
    };
  }

  componentDidUpdate() {
    if (this.state.msg || this.state.errorMsg) {
      setTimeout(() => {
        this.setState({
          msg: false,
          errorMsg: false,
        });
      }, 3000);
    }
  }

  componentDidMount() {
    $(document).ready(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });
    this.props.sharedActions
      .getDataWithoutDispatch("/api/CertificateCategory")
      .then((success) => {
        this.setState({
          certificateData: success,
          isLoadingInitialData: false,
        });
      })
      .catch((error) => {
        this.setState({
          isLoadingInitialData: false,
        });
        console.log(error);
      });
  }

  onHandleSelectChange = (e) => {
    if (e.target.value !== "Certificate") {
      this.setState({
        certificateId: e.target.value,
      });
    }
  };

  onHandleTextChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { certificateId, name, accountId, accountType } = this.state;

    if (Object.is(name, "") || Object.is(certificateId, "")) {
      this.setState({
        error: true,
        errorMsg: true,
      });
    } else {
      this.setState({
        isLoading: true,
      });
      const data = {
        certificateId,
        name,
        accountId,
        accountType,
      };
      this.props.sharedActions
        .simpleAddRequest("/api/OtherCertificate/", data)
        .then((success) => {
          this.setState({
            msg: "Added Certificate Successfully",
            isLoading: false,
            errorMsg: false,
            error: false,
            certificateId: "",
            name: "",
          });
          this.props.snackbar();
        });
    }
  };
  render() {
    const { errors, isLoadingInitialData, certificateData } = this.state;

    const Form = (
      <ThemeContext.Consumer>
        {(context) => (
          <FormLayoutWrapper
            styleHead={true}
            formName="Add Certificate"
            borderColor={context.themeColors.primaryColor}
            borderRadius={15}
          >
            {this.state.errorMsg ? (
              <p style={{ color: "red", textAlign: "center" }}>
                Please fill the form properly
              </p>
            ) : (
              ""
            )}

            <div className="row">
              <TextInput
                feildName={"accountId"}
                inputLabelName={"Admin Name"}
                errors={errors}
                stateData={this.state}
                placeholder="Admin Name"
                readOnly={true}
                type={"text"}
                isValidte={false}
              />

              <TextInput
                feildName={"accountType"}
                inputLabelName={"Account Type"}
                errors={errors}
                stateData={this.state}
                placeholder="Account Type"
                readOnly={true}
                type={"text"}
                isValidte={false}
              />

              <TextInput
                marginTop={20}
                feildName={"name"}
                inputLabelName={"Name"}
                onHandleChange={this.onHandleTextChange}
                errors={errors}
                stateData={this.state}
                // iconClassName={'fas fa-percentage'}
                placeholder={"Name"}
                isValidte={false}
              />

              <SelectionInput
                marginTop={20}
                feildName={"certificateId"}
                selectName={"Certificate"}
                onHandleChange={(e) => this.onHandleSelectChange(e)}
                errors={errors}
                optionsArrys={certificateData}
                selectedText={"Certificate"}
                stateData={this.state}
                // iconClassName={'fas fa-building'}
                optionType="dynamicWithPropIdAndName"
                property={"certificateTitle"}
                propertyId={"id"}
                successflag={"successflag"}
                isValidte={false}
                // editUse={'staffEdit'}
              />
            </div>
            <br />
            <Button
              width="100%"
              buttonClass="customButton"
              btnName="Add"
              loaderBmargin={5}
              stateData={this.state}
              marginTop={20}
              onHandleSubmit={this.onSubmit}
              margin={"0 auto"}
              textColor={"white"}
              btnBackColor={context.themeColors.primaryColor}
            />
          </FormLayoutWrapper>
        )}
      </ThemeContext.Consumer>
    );
    return (
      <React.Fragment>
        <SnackBar msg={this.state.msg} />
        {isLoadingInitialData ? <Spinner /> : Form}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    role: state.setUserReducer.users.role,
    name: state.setUserReducer.users.unique_name,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    sharedActions: bindActionCreators(sharedActions, dispatch),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddOtherCertificate);
