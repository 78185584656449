import React, { useEffect, useState, useContext } from 'react'
import $ from 'jquery';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import SelectionInput from '../../components/SelectionInput/SelectionInput';
import general from '../../utils/general'
import TextInput from '../../components/TextInput/TextInput'
import Button from '../../components/Button';
import TextArea from '../../components/TextArea'
import FormLayoutWrapper from '../../components/HOC/FormLayoutWrapper';
import httpWithRequiredSetData from '../../hooks/Services/httpWithRequiredSetData';
import themeContext from "../../context/themeContext/ThemeContext";
import TimePicker from "../../components/TimePicker";
import moment from "moment";

const AddLoginTimeLimit = props => {

    const themeColor = useContext(themeContext);
    const [userData, setUserData] = useState([])
    const [users, setUsers] = useState([])

    const [isLoading, setIsLoading] = useState(false)
    const [msg, setSnackMsg] = useState('')
    const [state, setState] = useState({
        accountId: props.name,
        accountType: props.role,
        isLoading: '',
        errorMsg: '',

        userName: '',
        loginType: '',
        timeLimit:'',
        sendAll: false
    })

    useEffect(() => {
        if (msg || state['errorMsg']) {
            setTimeout(() => {
                setState(prevState => ({
                    ...prevState, errorMsg: false
                }))
            }, 3000);
        }
    });

    const onHandleTimeChange = (time) => {
        setState(prevState => ({
            ...prevState, timeLimit: time
        })
        )
      };

    const onHandleSelectChange = e => {
        if (e.target.value !== 'Login Type' && e.target.value !== '') {

            if(e.target.name === 'loginType'){
                props.sharedActions
                .getDataWithoutDispatch(
                    `/api/LoginType/?loginType=${e.target.value.toLowerCase()}`
                )
                .then(success => {
                    setUserData(success)
                })
                .catch(error => {
                    setUserData([])
                });
            }

            const target = e.target
            setState(prevState => ({
                ...prevState, [target.name]: target.value
            })
            )
        }
    }

    const onHandleUserChange = e => {
        if (e.target.value !== 'Select User' && e.target.value !== '') {
            const target = e.target
            setState(prevState => ({
                ...prevState, [target.name]: target.value
            })
            )
            setUsers([])
            
            users.push(e.target.value)
            console.log(users);
            
        }
    }

    const allUsers = e => {
        users.pop();
		setState(prevState => ({
			...prevState,
			sendAll: !state['sendAll']
		}));
		if (!state['sendAll']) {
			userData.map(item => {
				users.push(item['userName']);
			});
		} else {
			setUsers([]);
		}
		console.log(users);
	};

    const onSubmit = (e) => {
        e.preventDefault();
        console.log(state);

        if (users.length<1 ||
            state['loginType'] === '' || 
            state['timeLimit'] === ''
        ) {
            setState(prevState => ({
                ...prevState, errorMsg: true
            }))
        }
        else {
            setIsLoading(true)

            let time = moment(state['timeLimit']).format("hh:mm:ss a");

            const usersTemp = users.map(item => ({
                userName: item,
                loginType: state['loginType'],
                timeLimit: time,
				accountId: state['accountId'],
				accountType: state['accountType']
			}));

            const data = {
                LoginTimeLimit: usersTemp
            }

            props.sharedActions
                .simpleAddRequest('/api/LoginTimeLimit',
                    data)
                .then(success => {
                    setIsLoading(false)
                    setSnackMsg('Added Login Time Limit Successfully!')
                    setState({
                        accountId: props.name,
                        accountType: props.role,
                        isLoading: '',
                        errorMsg: '',

                        userName: '',
                        loginType: '',
                        timeLimit:'',
                    })
                    setUserData([])
                    props.snackbar();
                })
                .catch(error => {
                    if (error && error.response && error.response.status == 404) {
                        // 404-Error no understanding of 404 Error
                        setIsLoading(false)
                        props.snackbar();
                        setSnackMsg(error.response.data.Message)
                    } else if (error && error.response && error.response.status == 400) {
                        // 404-Error no understanding of 404 Error
                        setIsLoading(false)
                        props.snackbar();
                        setSnackMsg(error.response.data.Message)
                    }
                    setIsLoading(false)
                })
        }

    }

    const Form = (
        <FormLayoutWrapper
            formName='Add Login Time Limit'
            borderColor={themeColor.themeColors.primaryColor}
            borderRadius={15}
        >
            {state.errorMsg ? (
                <p style={{ color: 'red', textAlign: 'center' }}>
                    Please fill the form properly
                </p>
            ) : (
                    ''
                )}

            <div className="row">
            <SelectionInput
                    marginTop={20}
                    feildName={'loginType'}
                    selectName={'Login Type'}
                    onHandleChange={e => onHandleSelectChange(e)}
                    errors={{}}
                    optionsArrys={general.loginTimeLimit}
                    selectedText={'Login Type'}
                    stateData={state}
                    // iconClassName={'fas fa-building'}
                    optionType="static"
                    successflag={'successflag'}
                    isValidte={false}
                // editUse={'staffEdit'}
                />

            <SelectionInput
					isFeildDisabled={state['sendAll']}
					marginTop={20}
					feildName={'userName'}
					selectName={'Username'}
					onHandleChange={e => onHandleUserChange(e)}
					errors={{}}
					optionsArrys={userData}
					selectedText={'Select User'}
					stateData={state}
					// iconClassName={'fas fa-building'}
					optionType="dynamicWithPropIdAndName"
					property={'userName'}
					propertyId={'userName'}
					successflag={'successflag'}
					isValidte={false}
					// editUse={'staffEdit'}
				/>
{ userData.length < 1 ? '' : 
<div class="col-md-12" style={{ marginTop: '20px' }}>
						<div class="custom-control custom-switch">
							<input
								type="checkbox"
								class="custom-control-input"
								id="customSwitch1"
								onChange={allUsers}
							/>
							<label class="custom-control-label" for="customSwitch1">
            <strong>Set to all {state['loginType']}</strong>
							</label>
						</div>
					</div>}

            <div class="col-md-6" style={{ marginTop: 20 }}>
                <div class="md-form mb-0">
                  <label for="email" class="">
                    <p style={{ margin: 0, color: "#000" }}>Time</p>
                  </label>
                  <br />
                  <TimePicker
                    className="dateTimePicker"
                    showSecond={false}
                    placeholder={"Hr-Min-(AM/PM)"}
                    name="tourTime"
                    defaultValue={null}
                    onChange={(e) => onHandleTimeChange(e)}
                    format={general.timeFormat}

                    // inputReadOnly
                  />
                </div>
              </div>

            </div>

            <br />

            <Button
                width="100%"
                buttonClass="customButton"
                btnName="Add"
                loaderBmargin={5}
                stateData={{
                    isLoading
                }}
                marginTop={20}
                onHandleSubmit={onSubmit}
                margin={'0 auto'}
                textColor={'white'}
                btnBackColor={themeColor.themeColors.primaryColor}
            />

        </FormLayoutWrapper>
    )

    return (
        <React.Fragment>
            <SnackBar msg={msg} />
            {Form}
        </React.Fragment>
    )
}


function mapStateToProps(state) {
    return {
        name: state.setUserReducer.users.unique_name,
        role: state.setUserReducer.users.role,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        sharedActions: bindActionCreators(sharedActions, dispatch)
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddLoginTimeLimit);