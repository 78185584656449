import React from "react";
import ViewRendering from "../../../components/ViewRendering";
import { renderingStuff, images } from "../../../utils";
import DashboardLayout from "../../../components/HOC/DashboardLayout";
import NewViewRendering from "../../../components/ViewRendering/NewViewRendering";

function AcademicCMS(props) {
  return (
    <DashboardLayout name="CMS Dashboard" image={images.imgAcademicCMS}>
      <NewViewRendering {...props} data={renderingStuff.academicCMSLinks} />
    </DashboardLayout>
  );
}

export default AcademicCMS;
