import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as BudgetItemsAction from "../../Actions/BudgetItems";
import * as sharedActions from "../../Actions/sharedActions";
import SnackBar from "../../components/SnackBar/SnackBar";
import $ from "jquery";
import ThemeContext from "../../context/themeContext/ThemeContext";

class ViewBudgetItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adminData: [],
      sessions: [],
      modalData: null,
      particular: "",
      amount: "",
      openingAmount: "",
      description: "",
      sessionId: "",
      sessionName: "",
      enabled: false,
      id: null,
      isLoading: true,
      disablepage: false,
      disableBPage: true,
      currentPage: 1,
      todosPerPage: 10,
      pageCounter: 10,
      msg: "",
    };
  }
  handleClickNextFrwd = () => {
    const { adminData, todosPerPage, count, currentPage } = this.state;
    let pageNumbers = [];
    for (
      let i = 1;
      i <= Math.ceil(adminData.length / this.state.todosPerPage);
      i++
    ) {
      pageNumbers.push(i);
    }
    console.log("pagelength: ", pageNumbers.length);
    console.log("current page: ", currentPage);
    if (currentPage === pageNumbers.length) {
      this.setState({
        disablepage: true,
      });
    } else {
      this.setState((prevState) => ({
        currentPage: prevState.currentPage + 1,
        disableBPage: false,
      }));
    }
  };
  handleClickNextBack = () => {
    const { adminData, todosPerPage, count, currentPage } = this.state;
    console.log("current page: ", currentPage);
    if (currentPage === 1) {
      this.setState({
        disableBPage: true,
        disablepage: false,
      });
    } else {
      this.setState((prevState) => ({
        currentPage: prevState.currentPage - 1,
      }));
      this.setState({
        disablepage: false,
      });
    }
  };
  handleClickNext = (event) => {
    this.setState({
      currentPage: Number(event.target.id),
      disablepage: false,
      disableBPage: false,
    });
  };

  componentDidUpdate() {
    $('[data-toggle="tooltip"]').tooltip();
  }
  componentDidMount() {
    $(document).ready(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });
    if (this.props.allTransportState.apiFlag) {
      this.props.sharedActions
        .getDataWithoutDispatch("/api/BudgetPlan")
        .then((success) => {
          debugger;
          this.setState({
            adminData: success.allItems,
            session: success.sessions,
            isLoading: false,
          });
        })
        .catch((err) => {
          debugger;
          this.setState({
            isLoading: false,
          });

          console.log(err);
        });
    }
  }
  btnTechHover = () => {
    $(document).ready(function () {
      $("button").hover(
        function () {
          $(this).css("box-shadow", "0px 5px 5px grey");
          $(this).css("border-color", "white");
        },
        function () {
          $(this).css("box-shadow", "0px 0px 0px white");
        }
      );
    });
  };
  myFunction = () => {
    // Get the snackbar DIV
    var x = document.getElementById("snackbar");

    // StepperGeneralButtons the "show" class to DIV
    x.className = "show";

    // After 3 seconds, remove the show class from DIV
    setTimeout(function () {
      x.className = x.className.replace("show", "");
    }, 3000);
  };
  render() {
    const { adminData, modalData, select } = this.state;
    console.log("Session Ka Data", this.state.sessions);
    const { session } = this.state.sessions;

    console.log("modal data: ", this.state.modalData);
    const Loader = (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div class="spinner-border" style={{ color: "#01AC8A" }}></div>
      </div>
    );
    let pageNumbers = [];
    for (
      let i = 1;
      i <= Math.ceil(adminData.length / this.state.todosPerPage);
      i++
    ) {
      pageNumbers.push(i);
    }
    const renderPageNumbers = pageNumbers.map((number, index) => {
      return index <= 3 ? (
        <li
          class={`page-item ${
            this.state.currentPage === number ? "active" : ""
          } `}
        >
          <a
            key={number}
            id={number}
            onClick={this.handleClickNext}
            class="page-link "
            href="#"
          >
            {number}
          </a>
        </li>
      ) : (
        ""
      );
    });
    const { currentPage, todosPerPage } = this.state;
    const indexOfLastTodo = currentPage * todosPerPage;
    const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
    const currentTodos = adminData.slice(indexOfFirstTodo, indexOfLastTodo);
    const renderTodos = currentTodos.map((item, index) => {
      return (
        <tr key={index}>
          <th scope="row" style={{ padding: 1 }}>
            {index + 1}
          </th>
          <td style={{ padding: 1 }}>{item.particular}</td>
          <td style={{ padding: 1 }}>{item.amount}</td>
          <td style={{ padding: 1 }}>{item.openingAmount}</td>
          <td style={{ padding: 1 }}>{item.description}</td>
          <td style={{ padding: 1 }}>{item.session}</td>
          <td style={{ padding: 1 }}>{item.date}</td>
          <td style={{ padding: 1 }}>{item.time}</td>
          <td style={{ padding: 1 }}>{item.accountId}</td>
          <td style={{ padding: 1 }}>{item.accountType}</td>
        </tr>
      );
    });
    const MainContent = (
      <div>
        <div className="table-responsive">
          <ThemeContext.Consumer>
            {(context) => (
              <table
                class="table table-hover table-bordered"
                style={{ textAlign: "center" }}
              >
                <thead
                  style={{
                    background: context.themeColors.primaryColor,
                    color: "white",
                  }}
                >
                  <tr>
                    <th scope="col" style={{ padding: 1 }}>
                      #
                    </th>
                    <th scope="col" style={{ padding: 1 }}>
                      Particular
                    </th>
                    <th scope="col" style={{ padding: 1 }}>
                      Amount
                    </th>
                    <th scope="col" style={{ padding: 1 }}>
                      Opening Amount
                    </th>
                    <th scope="col" style={{ padding: 1 }}>
                      Description
                    </th>
                    <th scope="col" style={{ padding: 1 }}>
                      Session
                    </th>
                    <th scope="col" style={{ padding: 1 }}>
                      Date
                    </th>
                    <th scope="col" style={{ padding: 1 }}>
                      Time
                    </th>
                    <th scope="col" style={{ padding: 1 }}>
                      Account Id
                    </th>
                    <th scope="col" style={{ padding: 1 }}>
                      Account Type
                    </th>
                  </tr>
                </thead>
                <tbody>{renderTodos}</tbody>
              </table>
            )}
          </ThemeContext.Consumer>
        </div>
      </div>
    );
    return (
      <div>
        <div className="page-header">
          <SnackBar msg={this.state.msg} />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div>
              <h2>View Budget Items</h2>
            </div>
            <nav aria-label="Page navigation example">
              <ul style={{ color: "#01AC8A" }} class="pagination">
                <li
                  class={`page-item ${
                    this.state.disableBPage ? "disabled" : ""
                  }`}
                >
                  <a
                    onClick={this.handleClickNextBack}
                    class="page-link"
                    href="#"
                  >
                    {this.state.disableBPage ? (
                      <i
                        style={{ color: "grey" }}
                        class="far fa-stop-circle"
                      ></i>
                    ) : (
                      <i class="fas fa-backward"></i>
                    )}
                  </a>
                </li>
                {renderPageNumbers}
                <li
                  class={`page-item ${
                    this.state.disablepage ? "disabled" : ""
                  }`}
                >
                  <a
                    onClick={this.handleClickNextFrwd}
                    class="page-link"
                    href="#"
                  >
                    {this.state.disablepage ? (
                      <i
                        style={{ color: "grey" }}
                        class="far fa-stop-circle"
                      ></i>
                    ) : (
                      <i class="fas fa-forward"></i>
                    )}
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        {this.state.isLoading ? Loader : MainContent}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    BudgetItemsAction: bindActionCreators(BudgetItemsAction, dispatch),
    sharedActions: bindActionCreators(sharedActions, dispatch),
  };
}
function mapStateToProps(state) {
  return {
    AllTransportData: state.setBudgetItemsReducer.Data,
    allTransportState: state.setBudgetItemsReducer,
    users: state.setUserReducer.users,
    // selectData: state.setHomeWorkReducer.select,
    selectSession: state.setBudgetItemsReducer.sessionSelect,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewBudgetItems);
