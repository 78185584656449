import React, { Component } from 'react';

import GeneralFileUploadOption from '../../components/Admission/GeneralFileUploadOption';
import SpecificFileUpload from '../../components/Admission/SpecificFileUpload';
import GeneralRadioOptionSelection from '../../components/Admission/GeneralRadioOptionSelection';
export default class ParentsDocuments extends Component {
	constructor(props) {
		super(props);
		this.state = {
			gridNum: 4
		};
	}
	render() {
		const { stateData, onCustomRadioStudentDocs, fileOnChange } = this.props;

		return (
			<div>
				<div class="col-md-9 mb-md-0 mb-5">
					<form
						id="contact-form"
						name="contact-form"
						// onSubmit={this.onHandleSubmit}
						noValidate
					>
						{/*<div class="row">*/}
						{/*<div class="col-md-12">*/}
						{/*<div class="md-form mb-0">*/}
						{/*/!*<DateandTime/>*!/*/}
						{/*</div>*/}
						{/*</div>*/}
						{/*</div>*/}
						<br />
						<div>
							{/* start */}
							<div>
								<div style={{ display: 'flex', flexDirection: 'row' }}>
									<div style={{ display: 'flex', flexDirection: 'row' }}>
										<div style={{ display: 'flex', flexDirection: 'column' }}>
											<div
												style={{
													paddingBottom: 30,
													paddingRight: 30,
													display: 'flex',
													flexDirection: 'row',
													flexWrap: 'wrap'
												}}
											>
												<SpecificFileUpload
													onHandleTextChange={onCustomRadioStudentDocs}
													stateData={stateData}
													Name="Passport Father:"
													idForYes="customRadiopassPDY"
													idForNo="customRadiopassPDN"
													feildName="passportFather"
													uploadName="filePath_passportFather"
												/>
												<GeneralFileUploadOption
													name="filePath_passportFather"
													feildName={'passportFather'}
													fileOnChange={fileOnChange}
													stateData={stateData}
												/>
											</div>
											<div
												style={{
													paddingBottom: 30,
													paddingRight: 30,
													display: 'flex',
													flexDirection: 'row',
													flexWrap: 'wrap'
												}}
											>
												<SpecificFileUpload
													onHandleTextChange={onCustomRadioStudentDocs}
													stateData={stateData}
													Name="Passport Mother:"
													idForYes="customRadiopassMY"
													idForNo="customRadiopassMN"
													feildName="passportMother"
													uploadName="filePath_passportMother"
												/>
												<GeneralFileUploadOption
													name="filePath_passportMother"
													feildName={'passportMother'}
													fileOnChange={fileOnChange}
													stateData={stateData}
												/>
											</div>
											<div
												style={{
													paddingBottom: 30,
													paddingRight: 30,
													display: 'flex',
													flexDirection: 'row',
													flexWrap: 'wrap'
												}}
											>
												<SpecificFileUpload
													onHandleTextChange={onCustomRadioStudentDocs}
													stateData={stateData}
													Name="Id Card Father:"
													idForYes="customRadioIdY"
													idForNo="customRadioIdN"
													feildName="idCardFather"
													uploadName="filePath_idCardFather"
												/>
												<GeneralFileUploadOption
													name="filePath_idCardFather"
													feildName={'idCardFather'}
													fileOnChange={fileOnChange}
													stateData={stateData}
												/>
											</div>
											<div
												style={{
													paddingBottom: 30,
													paddingRight: 30,
													display: 'flex',
													flexDirection: 'row',
													flexWrap: 'wrap'
												}}
											>
												<SpecificFileUpload
													onHandleTextChange={onCustomRadioStudentDocs}
													stateData={stateData}
													Name="Id Card Mother:"
													idForYes="customRadioIdMY"
													idForNo="customRadioIdMN"
													feildName="idCardMother"
													uploadName="filePath_idCardMother"
												/>
												<GeneralFileUploadOption
													name="filePath_idCardMother"
													feildName={'idCardMother'}
													fileOnChange={fileOnChange}
													stateData={stateData}
												/>
											</div>
										</div>
									</div>

									{/* <div style={{display:'flex',flexDirection:'column'}} >
                        <div style={{  paddingBottom:30, paddingRight:30, display:'flex' , flexDirection: 'row' , flexWrap:'wrap' }} >
                        <GeneralRadioOptionSelection
                                onHandleTextChange={onHandleTextChange}
                                stateData={stateData}
                                Name='Medical History:'
                                idForYes='customRadiomedY'
                                idForNo='customRadiomedN'
                                feildName='medicalHistory'
                                />
                            <GeneralFileUploadOption
                                name='filePath_medicalHistory'
                                feildName={'medicalHistory'}
                                fileOnChange={fileOnChange}
                                stateData={stateData}
                                />
                        </div>
                        
                        </div> */}
								</div>
							</div>

							{/* end  */}
							{/* <br />
                                <div class="row">
                                <GeneralRadioOptionSelection 
                                    onHandleTextChange={onHandleTextChange} 
                                    stateData={stateData} 
                                    Name='Passport Father:' 
                                    idForYes='customRadiopassPDY' 
                                    idForNo='customRadiopassPDN' 
                                    feildName='passportFather' 
                                    />
                                <GeneralRadioOptionSelection 
                                    onHandleTextChange={onHandleTextChange} 
                                    stateData={stateData} 
                                    Name='Passport Mother:' 
                                    idForYes='customRadiopassMY' 
                                    idForNo='customRadiopassMN' 
                                    feildName='passportMother' 
                                    />    
                                <GeneralRadioOptionSelection 
                                    onHandleTextChange={onHandleTextChange} 
                                    stateData={stateData} 
                                    Name='Id Card Father:' 
                                    idForYes='customRadioIdY' 
                                    idForNo='customRadioIdN' 
                                    feildName='idCardFather' 
                                    />   
                                    <GeneralRadioOptionSelection 
                                    onHandleTextChange={onHandleTextChange} 
                                    stateData={stateData} 
                                    Name='Id Card Mother:' 
                                    idForYes='customRadioIdMY' 
                                    idForNo='customRadioIdMN' 
                                    feildName='idCardMother' 
                                    />   
                                </div> */}

							<br />
							<br />
							{/*<div class="text-center text-md-center">*/}
							{/*<button class="btns">Add</button>*/}
							{/*</div>*/}
							<br />
							<br />
							{/*<div class="text-center text-md-center">*/}
							{/*<button class="btns">Add</button>*/}
							{/* </div> */}
						</div>
					</form>

					<div class="status" />
				</div>
			</div>
		);
	}
}
