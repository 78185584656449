import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as BudgetItemsAction from "../../Actions/BudgetItems";
import * as sharedActions from "../../Actions/sharedActions";
import SnackBar from "../../components/SnackBar/SnackBar";
import $ from "jquery";
import ThemeContext from "../../context/themeContext/ThemeContext";

class SearchItemsBySession extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adminData: [],
      sessions: [],
      modalData: null,
      particular: "",
      amount: "",
      openingAmount: "",
      description: "",
      sessionId: "",
      sessionName: "",
      enabled: false,
      id: null,
      isLoading: true,
      disablepage: false,
      disableBPage: true,
      currentPage: 1,
      todosPerPage: 10,
      pageCounter: 10,
      msg: "",
      btnLoad: false,
    };
  }
  handleClickNextFrwd = () => {
    const { adminData, todosPerPage, count, currentPage } = this.state;
    let pageNumbers = [];
    for (
      let i = 1;
      i <= Math.ceil(adminData.length / this.state.todosPerPage);
      i++
    ) {
      pageNumbers.push(i);
    }
    console.log("pagelength: ", pageNumbers.length);
    console.log("current page: ", currentPage);
    if (currentPage === pageNumbers.length) {
      this.setState({
        disablepage: true,
      });
    } else {
      this.setState((prevState) => ({
        currentPage: prevState.currentPage + 1,
        disableBPage: false,
      }));
    }
  };
  handleClickNextBack = () => {
    const { adminData, todosPerPage, count, currentPage } = this.state;
    console.log("current page: ", currentPage);
    if (currentPage === 1) {
      this.setState({
        disableBPage: true,
        disablepage: false,
      });
    } else {
      this.setState((prevState) => ({
        currentPage: prevState.currentPage - 1,
      }));
      this.setState({
        disablepage: false,
      });
    }
  };
  handleClickNext = (event) => {
    this.setState({
      currentPage: Number(event.target.id),
      disablepage: false,
      disableBPage: false,
    });
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.adminData !== nextProps.AllTransportData) {
      return {
        adminData: nextProps.AllTransportData,
        sessions: nextProps.selectSession,
      };
    }
    return null;
  }
  componentDidUpdate() {
    $('[data-toggle="tooltip"]').tooltip();
  }
  componentDidMount() {
    $(document).ready(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });
    // this.props.sharedActions.gAllData('/api/classSectionGradeTeacher', this.props.HomeWorkAction.getClassesForHomeWork)
    //     .then((success) => {
    //
    //         console.log(success);
    //     }).catch((error) => {
    //
    //         console.log(error);
    //     })
    this.props.sharedActions
      .gAllData(
        "/api/Session",
        this.props.BudgetItemsAction.getSessionForBudgetItems
      )
      .then((success) => {})
      .catch((error) => {});
    if (this.props.allTransportState.apiFlag) {
      this.props.sharedActions
        .getAllData(
          "/api/getBudgetPalnBySession/?sessionId=1",
          this.props.BudgetItemsAction.searchBudgetItemBySession,
          this.props.BudgetItemsAction.updateBudgetItemFlag
        )
        .then((success) => {
          this.setState({
            isLoading: false,
          });
        })
        .catch((err) => {
          this.setState({
            isLoading: false,
          });

          console.log(err);
        });
    }
  }
  btnTechHover = () => {
    $(document).ready(function () {
      $("button").hover(
        function () {
          $(this).css("box-shadow", "0px 5px 5px grey");
          $(this).css("border-color", "white");
        },
        function () {
          $(this).css("box-shadow", "0px 0px 0px white");
        }
      );
    });
  };
  onTakeItemDelete = (itemId) => {
    this.setState({
      modalData: [],
    });
    this.props.sharedActions
      .deleteRecord(
        "/api/HomeWork/",
        itemId,
        this.props.HomeWorkAction.deleteHomeWork
      )
      .then((success) => {
        this.setState({
          msg: "Delete Record Successfully!",
        });
        this.props.snackbar();
      })
      .catch((error) => {});
  };
  onTakeItem = (itemId) => {
    this.setState({
      modalData: [],
    });
    const { adminData } = this.state;
    let getData = adminData.filter((item) => item.budgetPlanId === itemId);

    this.setState({
      modalData: getData,
      particular: getData[0].particular,
      description: getData[0].description,
      amount: getData[0].amount,
      id: getData[0].budgetPlanId,
      sessionName: getData[0].session,
      sessionId: getData[0].sessionId,
      openingAmount: getData[0].openingAmount,
    });
  };

  onHandleText = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSelectChange = (e) => {
    console.log(e.target.value);
    let result = JSON.parse(e.target.value);
    console.log(result);
    this.setState({
      routeName: result.dropOff,
      routeId: result.id,
    });
  };

  onHandleSubmit = (e) => {
    this.setState({
      btnLoad: true,
    });
    this.props.sharedActions
      .getAllData(
        `/api/getBudgetPalnBySession/?sessionId=${this.state.sessionId}`,
        this.props.BudgetItemsAction.searchBudgetItemBySession,
        this.props.BudgetItemsAction.updateBudgetItemFlag
      )
      .then((success) => {
        this.setState({
          isLoading: false,
          btnLoad: false,
        });
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
          btnLoad: false,
        });

        console.log(err);
      });
    console.log(this.state);
  };
  onEditClick = (itemId) => {
    this.onTakeItem(itemId);
    this.setState({ enabled: false });
  };
  myFunction = () => {
    // Get the snackbar DIV
    var x = document.getElementById("snackbar");

    // StepperGeneralButtons the "show" class to DIV
    x.className = "show";

    // After 3 seconds, remove the show class from DIV
    setTimeout(function () {
      x.className = x.className.replace("show", "");
    }, 3000);
  };
  onSelectSessionChange = (e) => {
    console.log(e.target.value);
    this.setState({
      sessionId: e.target.value,
    });
  };
  onSelectClassChange = (e) => {
    console.log(e.target.value);
    let result = JSON.parse(e.target.value);

    const Grade = this.state.select.grade;
    const Subject = this.state.select.subject;
    const Staff = this.state.select.staff;
    const Section = this.state.select.sections;
    let check = Grade.filter((item) => item.classId == result.classId);

    console.log(check);
    this.setState({
      gradeName: check[0] && check[0].gradeName,
      classId: result.classId,
      className: result.className,
      gradeId: check[0] && check[0].gradeId,
      gradeName: check[0] && check[0].gradeName,
    });
    let checkTwo = Subject.filter((item) => item.classId == result.classId);

    this.setState({
      Subject: checkTwo,
    });
    let checkThird = Staff.filter((item) => item.classId == result.classId);

    this.setState({
      Staff: checkThird,
    });
    let checkFourth = Section.filter((item) => item.classId == result.classId);

    this.setState({
      Section: checkFourth,
    });
  };
  onSelectSubjectChange = (e) => {
    console.log(e.target.value);
    let result = JSON.parse(e.target.value);

    this.setState({
      subjectId: result.subjectId,
      subjectName: result.subjectName,
    });
  };
  onSectionChange = (e) => {
    console.log(e.target.value);
    let result = JSON.parse(e.target.value);

    this.setState({
      sectionId: result.sectionId,
      sectionName: result.section,
    });
  };
  onStaffChange = (e) => {
    console.log(e.target.value);
    let result = JSON.parse(e.target.value);

    this.setState({
      staffId: result.staffId,
      staffName: result.staffName,
    });
  };
  render() {
    const { adminData, modalData, select } = this.state;
    console.log("Session Ka Data", this.state.sessions);
    const { session } = this.state.sessions;

    console.log("modal data: ", this.state.modalData);
    const Loader = (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div class="spinner-border" style={{ color: "#01AC8A" }}></div>
      </div>
    );
    let pageNumbers = [];
    for (
      let i = 1;
      i <= Math.ceil(adminData.length / this.state.todosPerPage);
      i++
    ) {
      pageNumbers.push(i);
    }
    const renderPageNumbers = pageNumbers.map((number, index) => {
      return index <= 3 ? (
        <li
          class={`page-item ${
            this.state.currentPage === number ? "active" : ""
          } `}
        >
          <a
            key={number}
            id={number}
            onClick={this.handleClickNext}
            class="page-link "
            href="#"
          >
            {number}
          </a>
        </li>
      ) : (
        ""
      );
    });
    const { currentPage, todosPerPage } = this.state;
    const indexOfLastTodo = currentPage * todosPerPage;
    const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
    const currentTodos = adminData.slice(indexOfFirstTodo, indexOfLastTodo);
    const renderTodos = currentTodos.map((item, index) => {
      return (
        <tr key={index}>
          <th scope="row" style={{ padding: 1 }}>
            {index + 1}
          </th>
          <td style={{ padding: 1 }}>{item.particular}</td>
          <td style={{ padding: 1 }}>{item.amount}</td>
          <td style={{ padding: 1 }}>{item.openingAmount}</td>
          <td style={{ padding: 1 }}>{item.description}</td>
          <td style={{ padding: 1 }}>{item.session}</td>
          <td style={{ padding: 1 }}>{item.date}</td>
          <td style={{ padding: 1 }}>{item.time}</td>
          <td style={{ padding: 1 }}>{item.accountId}</td>
          <td style={{ padding: 1 }}>{item.accountType}</td>
        </tr>
      );
    });
    const MainContent = (
      <div>
        <div class="modal" id="myModal1">
          <div class="modal-dialog">
            <div class="modal-content modal_content_custom">
              <div
                style={{
                  borderBottomLeftRadius: 30,
                  borderBottomRightRadius: 30,
                  borderTopLeftRadius: 18,
                  borderTopRightRadius: 18,
                }}
                class="modal-header modal_custom_header"
              >
                <h4 style={{ color: "white" }} class="modal-title">
                  Confirm
                </h4>
                <button
                  style={{ color: "white" }}
                  type="button"
                  class="close"
                  data-dismiss="modal"
                >
                  &times;
                </button>
              </div>
              <div class="modal-body">
                {modalData &&
                  modalData.map((item, index) => (
                    <div key={index}>
                      <div style={{ textAlign: "center" }}>
                        <h4>Are you sure ?</h4>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <div>
                          <button
                            disabled={this.state.enabled}
                            data-dismiss="modal"
                            onClick={() => this.onTakeItemDelete(this.state.id)}
                            type="button"
                            class="btn btn-primary buttonAppear"
                          >
                            Yes
                          </button>
                        </div>
                        <div>
                          <button
                            data-dismiss="modal"
                            disabled={this.state.enabled}
                            type="button"
                            class="btn btn-primary buttonAppear"
                          >
                            No
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <div class="modal" id="myModal">
          <div class="modal-dialog">
            <div class="modal-content modal_content_custom">
              <div
                style={{
                  borderBottomLeftRadius: 30,
                  borderBottomRightRadius: 30,
                  borderTopLeftRadius: 18,
                  borderTopRightRadius: 18,
                }}
                class="modal-header modal_custom_header"
              >
                <h4 style={{ color: "white" }} class="modal-title">
                  Details
                </h4>
                <button
                  style={{ color: "white" }}
                  type="button"
                  class="close"
                  data-dismiss="modal"
                >
                  &times;
                </button>
              </div>
              <div class="modal-body">
                <form onSubmit={this.onHandleSubmit}>
                  {modalData &&
                    modalData.map((item, index) => (
                      <div key={index}>
                        <fieldset disabled={!this.state.enabled}>
                          <div class="form-group">
                            <label for="transportType">Particular:</label>
                            <input
                              style={{
                                borderTop: 0,
                                borderLeft: 0,
                                borderRight: 0,
                                boxShadow: "none",
                              }}
                              type="text"
                              value={this.state.particular}
                              onChange={this.onHandleText}
                              name="particular"
                              class="form-control"
                              id="transportType"
                            />
                          </div>
                          <div class="form-group">
                            <label for="capacity">Amount</label>
                            <input
                              required
                              style={{
                                borderTop: 0,
                                borderLeft: 0,
                                borderRight: 0,
                                boxShadow: "none",
                              }}
                              value={this.state.amount}
                              onChange={this.onHandleText}
                              type="text"
                              name="amount"
                              class="form-control"
                              id="capacity"
                            />
                          </div>
                          <div class="form-group">
                            <label for="capacity">Opening Amount</label>
                            <input
                              required
                              style={{
                                borderTop: 0,
                                borderLeft: 0,
                                borderRight: 0,
                                boxShadow: "none",
                              }}
                              value={this.state.openingAmount}
                              onChange={this.onHandleText}
                              type="text"
                              name="openingAmount"
                              class="form-control"
                              id="capacity"
                            />
                          </div>
                          <div class="form-group">
                            <label for="Campus Phone">Session</label>
                            <input
                              required
                              style={{
                                borderTop: 0,
                                borderLeft: 0,
                                borderRight: 0,
                                boxShadow: "none",
                              }}
                              value={this.state.sessionName}
                              onChange={this.onHandleText}
                              type="text"
                              name="TotalMarks"
                              readOnly={true}
                              class="form-control"
                              id="campusPhoneNumber"
                            />
                          </div>

                          <br />
                          <div class="col-md-12">
                            <div class="md-form mb-0">
                              <label for="name" class="">
                                Description
                              </label>
                              <textarea
                                style={{ borderColor: "#01AC8A" }}
                                onChange={this.onHandleText}
                                type="text"
                                name="description"
                                class="form-control"
                                value={this.state.description}
                                placeholder="Description of Home Work"
                              />
                            </div>
                          </div>
                        </fieldset>
                        <br />
                        <br />
                        <button
                          disabled={this.state.enabled}
                          onClick={() => this.setState({ enabled: true })}
                          type="button"
                          class="btn btn-primary buttonAppear"
                        >
                          Edit
                        </button>
                        <button
                          style={{ marginLeft: 5 }}
                          disabled={!this.state.enabled}
                          type="submit"
                          class="btn btn-primary buttonAppear"
                        >
                          Save
                        </button>
                      </div>
                    ))}
                </form>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  style={{ background: "#00435D" }}
                  class="btn btn-danger"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="table-responsive">
          <ThemeContext.Consumer>
            {(context) => (
              <table
                class="table table-hover table-bordered"
                style={{ textAlign: "center" }}
              >
                <thead
                  style={{
                    background: context.themeColors.primaryColor,
                    color: "white",
                  }}
                >
                  <tr>
                    <th scope="col" style={{ padding: 1 }}>
                      #
                    </th>
                    <th scope="col" style={{ padding: 1 }}>
                      Particular
                    </th>
                    <th scope="col" style={{ padding: 1 }}>
                      Amount
                    </th>
                    <th scope="col" style={{ padding: 1 }}>
                      Opening Amount
                    </th>
                    <th scope="col" style={{ padding: 1 }}>
                      Description
                    </th>
                    <th scope="col" style={{ padding: 1 }}>
                      Session
                    </th>
                    <th scope="col" style={{ padding: 1 }}>
                      Date
                    </th>
                    <th scope="col" style={{ padding: 1 }}>
                      Time
                    </th>
                    <th scope="col" style={{ padding: 1 }}>
                      Account Id
                    </th>
                    <th scope="col" style={{ padding: 1 }}>
                      Account Type
                    </th>
                  </tr>
                </thead>
                <tbody>{renderTodos}</tbody>
              </table>
            )}
          </ThemeContext.Consumer>
        </div>
      </div>
    );
    return (
      <div>
        <div className="page-header">
          <SnackBar msg={this.state.msg} />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div>
              <h2>Budget Items Session Wise</h2>
            </div>
            <nav aria-label="Page navigation example">
              <ul style={{ color: "#01AC8A" }} class="pagination">
                <li
                  class={`page-item ${
                    this.state.disableBPage ? "disabled" : ""
                  }`}
                >
                  <a
                    onClick={this.handleClickNextBack}
                    class="page-link"
                    href="#"
                  >
                    {this.state.disableBPage ? (
                      <i
                        style={{ color: "grey" }}
                        class="far fa-stop-circle"
                      ></i>
                    ) : (
                      <i class="fas fa-backward"></i>
                    )}
                  </a>
                </li>
                {renderPageNumbers}
                <li
                  class={`page-item ${
                    this.state.disablepage ? "disabled" : ""
                  }`}
                >
                  <a
                    onClick={this.handleClickNextFrwd}
                    class="page-link"
                    href="#"
                  >
                    {this.state.disablepage ? (
                      <i
                        style={{ color: "grey" }}
                        class="far fa-stop-circle"
                      ></i>
                    ) : (
                      <i class="fas fa-forward"></i>
                    )}
                  </a>
                </li>
              </ul>
            </nav>
          </div>
          <div class="row justify-content-end">
            <div class="col-md-3" style={{ marginTop: 20 }}>
              <div class="md-form mb-0">
                <label for="email" class="">
                  <i
                    style={{ marginRight: 4 }}
                    className="fas fa-bars animated fadeIn"
                  />
                  Session's
                </label>
                <select
                  onChange={this.onSelectSessionChange}
                  class="custom-select"
                  name="sectionName"
                >
                  <option selected={this.state.msg ? true : false}>
                    Select Current Session..
                  </option>
                  {session &&
                    session.map((item, index) => (
                      <option value={`${item.sessionId}`}>
                        {item.currentSession}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div class="col-md-9" style={{ marginTop: 50 }}>
              <ThemeContext.Consumer>
                {(context) => (
                  <div class="md-form mb-0">
                    <button
                      type="submit"
                      class="buttonHover2"
                      style={{
                        background: context.themeColors.primaryColor,
                      }}
                      onClick={this.onHandleSubmit}
                    >
                      Search
                      <span
                        style={{ marginBottom: 5 }}
                        class={`${
                          this.state.btnLoad
                            ? "spinner-border spinner-border-sm"
                            : ""
                        }`}
                      ></span>
                    </button>
                  </div>
                )}
              </ThemeContext.Consumer>
            </div>
          </div>

          <br />
          <br />
          {/* <div class="text-center text-md-right">
                    <button type="submit" class="btns" onClick={this.onHandleSubmit}>
                        Search
						<span
							style={{ marginBottom: 5 }}
							class={`${
								this.state.btnLoad ? 'spinner-border spinner-border-sm' : ''
							}`}
						></span>
                    </button>
				</div> */}
        </div>

        {this.props.allTransportState.apiFlag ? Loader : MainContent}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    BudgetItemsAction: bindActionCreators(BudgetItemsAction, dispatch),
    sharedActions: bindActionCreators(sharedActions, dispatch),
  };
}
function mapStateToProps(state) {
  return {
    AllTransportData: state.setBudgetItemsReducer.searchData,
    allTransportState: state.setBudgetItemsReducer,
    users: state.setUserReducer.users,
    // selectData: state.setHomeWorkReducer.select,
    selectSession: state.setBudgetItemsReducer.sessionSelect,
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchItemsBySession);
