import React, { Component } from "react";
import { Prompt } from "react-router-dom";

const country = [
  "Pakistan",
  "Oman",
  "UAE",
  "Saudia",
  "Iran",
  "Iraq",
  "Australia",
];
export default class StudentAdress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gridNum: 4,
    };
    this.props.onChangeFormFlag();
  }
  render() {
    const {
      onHandleTextChange,
      stateData,
      onChangeFormFlag,
      editData,
      errors,
    } = this.props;

    return (
      <div>
        <Prompt
          when={stateData.valid !== true}
          message="Leaving this form will lose your data"
        />
        <section class="mb-4">
          <div
            class="row"
            style={{
              justifyContent: "left",
              textAlign: "left",
            }}
          >
            <div
              style={{ fontSize: 20, padding: 10 }}
              class="col-md-9 mb-md-0 mb-5"
            >
              <form
                id="contact-form"
                name="contact-form"
                // onSubmit={this.onHandleSubmit}
                noValidate
              >
                {/*<div class="row">*/}
                {/*<div class="col-md-12">*/}
                {/*<div class="md-form mb-0">*/}
                {/*/!*<DateandTime/>*!/*/}
                {/*</div>*/}
                {/*</div>*/}
                {/*</div>*/}
                <br />
                <div class="row">
                  <div class="col-md-6">
                    <div class="md-form mb-0">
                      <label for="name" class="">
                        <i
                          className="fas fa-house animated fadeIn"
                          style={{ marginRight: 4 }}
                        />
                        Student Id Card
                      </label>
                      <input
                        style={{
                          borderTop: 0,
                          borderLeft: 0,
                          borderRight: 0,
                          boxShadow: "none",
                          width: "80%",
                        }}
                        onChange={(e) => onHandleTextChange(e)}
                        value={stateData.studentIdCardNo}
                        name="studentIdCardNo"
                        type="text"
                        class={`form-control ${
                          errors.studentIdCardNo ? "is-invalid" : ""
                        }`}
                        id="exampleInputEmail1"
                        placeholder="Student Id Card Number"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="md-form mb-0">
                      <label for="name" class="">
                        {/*<i*/}
                        {/*className="fas fa-user-shield animated fadeIn"*/}
                        {/*style={{ marginRight: 4 }}*/}
                        {/*/>*/}
                        Address:
                      </label>
                      <textarea
                        style={{
                          borderTop: 0,
                          borderLeft: 0,
                          borderRight: 0,
                          boxShadow: "none",
                          width: "80%",
                        }}
                        value={stateData.address}
                        onChange={(e) => onHandleTextChange(e)}
                        name="address"
                        type="text"
                        class={`form-control ${
                          errors.address ? "is-invalid" : ""
                        }`}
                        id="exampleInputEmail1"
                        placeholder="Student Address "
                      ></textarea>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="md-form mb-0">
                      <label for="name" class="">
                        <i
                          className="fas fa-house animated fadeIn"
                          style={{ marginRight: 4 }}
                        />
                        Student Email Id
                      </label>
                      <input
                        style={{
                          borderTop: 0,
                          borderLeft: 0,
                          borderRight: 0,
                          boxShadow: "none",
                          width: "80%",
                        }}
                        value={stateData.studentEmailId}
                        onChange={(e) => onHandleTextChange(e)}
                        name="studentEmailId"
                        type="text"
                        class={`form-control ${
                          errors.studentEmailId ? "is-invalid" : ""
                        }`}
                        id="exampleInputEmail1"
                        placeholder="Student Email Id"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="md-form mb-0">
                      <label for="name" class="">
                        <i
                          className="fas fa-house animated fadeIn"
                          style={{ marginRight: 4 }}
                        />
                        House No:
                      </label>
                      <input
                        style={{
                          borderTop: 0,
                          borderLeft: 0,
                          borderRight: 0,
                          boxShadow: "none",
                          width: "80%",
                        }}
                        value={stateData.houseNo}
                        onChange={(e) => onHandleTextChange(e)}
                        name="houseNo"
                        type="text"
                        class={`form-control ${
                          errors.houseNo ? "is-invalid" : ""
                        }`}
                        id="exampleInputEmail1"
                        placeholder="House No"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="md-form mb-0">
                      <label for="name" class="">
                        <i
                          className="fas fa-house animated fadeIn"
                          style={{ marginRight: 4 }}
                        />
                        Street No
                      </label>
                      <input
                        style={{
                          borderTop: 0,
                          borderLeft: 0,
                          borderRight: 0,
                          boxShadow: "none",
                          width: "80%",
                        }}
                        value={stateData.streetNo}
                        onChange={(e) => onHandleTextChange(e)}
                        name={"streetNo"}
                        type="text"
                        class={`form-control ${
                          errors.streetNo ? "is-invalid" : ""
                        }`}
                        id="exampleInputEmail1"
                        placeholder="Street No"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="md-form mb-0">
                      <label for="name" class="">
                        <i
                          className="fas fa-house animated fadeIn"
                          style={{ marginRight: 4 }}
                        />
                        City
                      </label>
                      <input
                        style={{
                          borderTop: 0,
                          borderLeft: 0,
                          borderRight: 0,
                          boxShadow: "none",
                          width: "80%",
                        }}
                        value={stateData.city}
                        onChange={(e) => onHandleTextChange(e)}
                        name={"city"}
                        type="text"
                        class={`form-control ${
                          errors.city ? "is-invalid" : ""
                        }`}
                        id="exampleInputEmail1"
                        placeholder="City"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="md-form mb-0">
                      <label for="name" class="">
                        <i
                          className="fas fa-house animated fadeIn"
                          style={{ marginRight: 4 }}
                        />
                        Area
                      </label>
                      <input
                        style={{
                          borderTop: 0,
                          borderLeft: 0,
                          borderRight: 0,
                          boxShadow: "none",
                          width: "80%",
                        }}
                        value={stateData.area}
                        onChange={(e) => onHandleTextChange(e)}
                        name={"area"}
                        type="text"
                        class={`form-control ${
                          errors.area ? "is-invalid" : ""
                        }`}
                        id="exampleInputEmail1"
                        placeholder="Area"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="md-form mb-0">
                      <label for="email" class="">
                        {/*<i*/}
                        {/*style={{ marginRight: 4 }}*/}
                        {/*className="fas fa-university animated fadeIn"*/}
                        {/*/>*/}
                        Country
                      </label>
                      <br />
                      <select
                        style={{
                          borderTop: 0,
                          borderLeft: 0,
                          borderRight: 0,
                          boxShadow: "none",
                          width: "80%",
                        }}
                        onChange={(e) => onHandleTextChange(e)}
                        className={`custom-select form-control ${
                          errors.country ? "is-invalid" : ""
                        }`}
                        value={stateData.country}
                        name="country"
                      >
                        <option selected={true}>Choose Country...</option>
                        {country.map((item, index) => (
                          <option value={item} key={index}>
                            {item}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="md-form mb-0">
                      <label for="name" class="">
                        <i
                          className="fas fa-house animated fadeIn"
                          style={{ marginRight: 4 }}
                        />
                        Way No:
                      </label>
                      <input
                        name={"wayNo"}
                        style={{
                          borderTop: 0,
                          borderLeft: 0,
                          borderRight: 0,
                          boxShadow: "none",
                          width: "80%",
                        }}
                        value={stateData.wayNo}
                        onChange={(e) => onHandleTextChange(e)}
                        type="text"
                        class={`form-control ${
                          errors.wayNo ? "is-invalid" : ""
                        }`}
                        id="exampleInputEmail1"
                        placeholder="Way No"
                      />
                    </div>
                  </div>
                </div>
                <br />
                <br />
                {/*<div class="text-center text-md-center">*/}
                {/*<button class="btns">Add</button>*/}
                {/*</div>*/}
              </form>
              <div class="status" />
            </div>
          </div>
        </section>
      </div>
    );
  }
}
