import React from "react";
// import ViewRendering from "../../../components/ViewRendering";
import { renderingStuff, images } from "../utils";
import NewViewRendering from "../components/ViewRendering/NewViewRendering";
import DashboardLayout from "../components/HOC/DashboardLayout";

function OnlineResourceDashboard(props) {
  return (
    <div>
		<DashboardLayout name="Online Resource" image={images.imgAcademicOnlineResource}>
			<NewViewRendering {...props} data={renderingStuff.OnlineResourceLinks} />
		</DashboardLayout>
    </div>
  );
}

export default OnlineResourceDashboard;