
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import $ from 'jquery';
import isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom'
import isNull from 'lodash/isNull'
import moment from 'moment'

import ThemeContext from "../../context/themeContext/ThemeContext";


class ViewFlagManagementSkill extends Component {
    constructor(props){
        super(props);
        this.state = {
            isLoading: true,
            modalEdit: false,
            editToggle: false,
            generateToggle: false,
            generateIndex: '',
            msg: '',
            editIndex: '',
            classId: "",
            sectionId: "",
            subjectId: "",

			accountId: this.props.userData.unique_name,
			accountType: this.props.userData.role

        }

        


    }

    componentDidMount(){
        this.getSimple('classSectionGradeTeacher', 'classSectionGrade')
        this.getSimple('Session', 'session')
        this.getSimple(`DisciplineReports/Student/?studentId=4&classId=12&sectionId=1015&sessionId=2&term=1`, 'k')
    }

    getSimple = (type, state) => {
        this.props.sharedActions.getDataWithoutDispatch(
            `/api/${type}`
        ).then(success => { 
            this.setState({
                [state]: success,
                isLoading: false
            })


        }).catch(error => {
            this.setState({
                msg: 'Error Occured!!',
                errorMsg: true,
                isLoading: false
            })
            console.error('Error occured', error);
        })
    }

    

    PrintContent = el => {
        var restorepage = document.body.innerHTML;
        var Printcontent = document.getElementById(el).innerHTML;
        document.body.innerHTML = Printcontent;
        
        setTimeout(() => {
            window.print();
            window.location.reload();
        }, 700)
        document.body.innerHTML = restorepage;
        window.reload();
        
    };



    myFunction = () => {
		// Get the snackbar DIV
		var x = document.getElementById('snackbar');

		// StepperGeneralButtons the "show" class to DIV
		x.className = 'show';

		// After 3 seconds, remove the show class from DIV
		setTimeout(function() {
		}, 3000);
	};


        handleSearch = () => {
            const { classId, sectionId, subjectId, sessionId, currentPage, dataLimit } = this.state
            if( isEmpty(classId) || isEmpty(sectionId) || isEmpty(subjectId) || isEmpty(sessionId)){
                
                this.setState({
                    msg: "Please Fill The Form Repectively!",
                })            
                this.props.snackbar()
            }else{
                
                // this.getSimple(`StudentEffortAvg/?classId=${classId}&sectionId=${sectionId}&sessionId=${sessionId}&subjectId=${subjectId}`, 'studentSkill')
                // this.getSimple(`StudentEffortAvg/?classId=${classId}&sectionId=${sectionId}&sessionId=${sessionId}&subjectId=${subjectId}`, 'studentSkill')
                // this.getSimple(`StudentEffortAvg/?classId=${classId}&sectionId=${sectionId}&sessionId=${sessionId}&subjectId=${subjectId}`, 'studentSkill')

                // let promise1 = new Promise((res, rej) => {
                //     this.props.sharedActions.getDataWithoutDispatch(
                //         `/api/${type}`
                //     ).then(success => { 
                //         res(success)
                //     }).catch(error => {
                //         rej(rej)
                //     })
                // })

                let promiseOrange =  new Promise((res, rej) => {
                    this.props.sharedActions.getDataWithoutDispatch(
                        `/api/StudentEffortAvg/?classId=${classId}&sectionId=${sectionId}&sessionId=${sessionId}&subjectId=${subjectId}&status=Orange`
                    ).then(success => { 
                        res(success)
                    }).catch(error => {
                        rej(error)
                    })
                })
                let promiseRed = new Promise((res, rej) => {
                    this.props.sharedActions.getDataWithoutDispatch(
                        `/api/StudentEffortAvg/?classId=${classId}&sectionId=${sectionId}&sessionId=${sessionId}&subjectId=${subjectId}&status=Red`
                    ).then(success => { 
                        res(success)
                    }).catch(error => {
                        rej(error)
                    })
                })
                let promiseGreen = new Promise((res, rej) => {
                    this.props.sharedActions.getDataWithoutDispatch(
                        `/api/StudentEffortAvg/?classId=${classId}&sectionId=${sectionId}&sessionId=${sessionId}&subjectId=${subjectId}&status=Green`
                    ).then(success => { 
                        res(success)
                    }).catch(error => {
                        rej(error)
                    })
                })
                Promise.all([promiseGreen, promiseRed, promiseOrange]).then(fulfill => {
                    let compositeData = []
                    fulfill.map((item, index) => {
                        item.TwoConsecutiveSkillReport.map(data => {
                            if(index == 2){
                                var fill = fulfill[1].TwoConsecutiveSkillReport.filter(data1 => data1.studentAdmissionFormId == data.studentAdmissionFormId )
                                if(fill.length > 0){

                                }else{
                                    compositeData.push({...data, flagColor: 'Orange' })

                                }

                            }else{
                                compositeData.push({...data, flagColor: index == 0 ? 'Green' : 'Red' })
                            }
                        })
                    })
                    this.setState({
                        studentSkill: compositeData,
                        isLoading: false
                    })
                    
                }).catch(error => {
                    this.setState({
                        msg: 'Error Occured!!',
                        errorMsg: true,
                        isLoading: false
                    })
                    console.error('Error occured', error);
                })
                

                
            }
        }

    btnTechHover = () => {
		$(document).ready(function() {
			$('button').hover(
				function() {
					$(this).css('box-shadow', '0px 5px 5px grey');
					$(this).css('border-color', 'white');
				},
				function() {
					$(this).css('box-shadow', '0px 0px 0px white');
				}
			);
		});
	};

    onHandleText = e => {
		this.setState({
			[e.target.name]: e.target.value
        });
        console.log([e.target.name], e.target.value)
    };

    onHandleFilter = e => {
        this.setState({
			[e.target.name]: e.target.value
        })
        console.log([e.target.name], e.target.value)
    };
    


    render(){

        const { search, isLoading, editToggle, msg, accountId, accountType, classSectionGrade, session, studentSkill, date,
            classId, sectionId, subjectId, sessionId,
        } = this.state;



        const Loader = (
            <ThemeContext.Consumer>
            {(context) => (
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<div className="spinner-border" style={{ color: context.themeColors.primaryColor }}></div>
			</div>
                                                                                                                        )}
                                                                                                                        </ThemeContext.Consumer>
        );
        
        const renderStudentSkillData = studentSkill && studentSkill.map((item, index) => {
            return (
                <ThemeContext.Consumer>
                {(context) => (
                <tr key={index} >
                    <th scope="row" style={{ padding: 1 }} >
                        {index + 1}
                    </th>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.name}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.className}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.section}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.subjcet} 
                        {/* Wrong Student Spelling Because of API */}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.session}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        <i class="fa fa-flag-o" style={{fontSize: 24, color: item.flagColor }} ></i>
                    </td>
                </tr>
                                                                                                                            )}
                                                                                                                            </ThemeContext.Consumer>
                );
            
        }) 

        return (
            <ThemeContext.Consumer>
            {(context) => (
            <div className="page-header"> 
                <SnackBar msg={msg} />
                <div style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                }}>
                    <div class="row">
                    <div class="col-xs-6 col-sm-6 col-md-6">
                                <h2  
                        style={{ background: context.themeColors.primaryColor }}
                        className="h1-responsive font-weight-bold text-center  generalHead">
                        Flag Management Skill Report
                    </h2>
							</div>
                        </div>
                        <div class="row my-3 mx-5 py-4" style={{
                            border: `2px solid ${context.themeColors.primaryColor}`,
                            borderRadius: 20
                        }} >
                           
                            <div class="col-md-3" >
                            <select class="custom-select" 
                                                value={classId}
                                                name="classId"
                                                onChange={this.onHandleFilter}
                                                disabled={!classSectionGrade}
                                            >
                                                        <option value="">Please Select Class</option>
                                                       {classSectionGrade && classSectionGrade.classes.map((value, index) => {
                                                           
                                                           return <option value={value.classId}>{value.className}</option>
                                                       })}
                                            </select>
                            </div>
                            <div class="col-md-3" >
                            <select class="custom-select" 
                                                value={sectionId}
                                                name="sectionId"
                                                onChange={this.onHandleFilter}
                                                disabled={!classId}

                                            >
                                                        <option value="">Please Select Section</option>
                                                       {classSectionGrade && classSectionGrade.sections.map((value, index) => {


                                                                return value.classId == classId && 
                                                                <option value={value.sectionId}>{value.section}</option>

                                                       })}
                                            </select>
                            </div>
                            <div class="col-md-3" >
                            <select class="custom-select" 
                                                value={subjectId}
                                                name="subjectId"
                                                onChange={this.onHandleFilter}
                                                disabled={!sectionId}

                                            >
                                                        <option value="">Please Select Subject</option>
                                                       {classSectionGrade && classSectionGrade.subject.map((value, index) => {

                                                                return value.classId == classId &&
                                                                <option value={value.subjectId}>{value.subjectName}</option>

                                                       })}
                                            </select>
                            </div>
                            <div class="col-md-3" >
                            <select class="custom-select" 
                                                value={sessionId}
                                                name="sessionId"
                                                onChange={this.onHandleFilter}
                                                disabled={!subjectId}

                                            >
                                                        <option value="">Please Select Session</option>
                                                       {session && session.session.map((value, index) => {

                                                                return <option value={value.sessionId}>{value.currentSession}</option>

                                                       })}
                                            </select>
                            </div>
                            
    
                        </div>
                        <div class="row my-4">
                        <div className="col-md-3 text-right">
                        </div>
                            <div class="col-md-12 text-center" >
                                            <button
                                            class="btn btn-primary buttonAppear"
                                            onClick={this.handleSearch}
                                            style={{marginRight: '2%', background: context.themeColors.primaryColor}}
                                            >
                            <i class="fa fa-search"></i> Search
                                        </button>
                                        <Link to={{
                            pathname: "PrintFlagManagementSkill",
                            state: {
                                studentSkill, classSectionGrade, session, classId, sectionId, subjectId, sessionId
                            }
                        }}  >
                            <a href="#" data-toggle="tooltip" title="Edit!">
                                
                            <button
                            style={{background: context.themeColors.primaryColor}}
                                            class="btn btn-primary buttonAppear"
                                            disabled={!studentSkill}
                                            >
                            
                                    <i className="fas fa-list"></i> View
                                </button>
                            </a> 
                            
                        </Link>
                            </div>
                            {/* <div class="row">
                                <div class="col-md-6">
                                <Pagination
                                    disableBPage={disableBPage}
                                    disablepage={disablepage}
                                    currentPage={currentPage}
                                    pageNumber={pageNumber}
                                    handleClickNext={this.handleClickNext}
                                    handleClickNextBack={this.handleClickNextBack}
                                    handleClickNextFrwd={this.handleClickNextFrwd}
                                />
                                </div>
                            </div>
                             */}
                        </div>
                        <div class="row my-2 mx-2" style={{paddingLeft: '20px'}} >
                            <div class="col-md-3" >
                                    <span>Class: <strong style={{color: context.themeColors.primaryColor}} >{studentSkill && classSectionGrade.classes.filter(data => data.classId == classId)[0].className}</strong></span>     
                            </div>
                            <div class="col-md-3"  >
                                    <span>Section: <strong style={{color: context.themeColors.primaryColor}} >{studentSkill && classSectionGrade.sections.filter(data => data.sectionId == sectionId)[0].section}</strong></span>     
                            </div>
                            <div class="col-md-3" >
                                    <span>Subject: <strong style={{color: context.themeColors.primaryColor}} >{studentSkill && classSectionGrade.subject.filter(data => data.subjectId == subjectId)[0].subjectName}</strong></span>     
                            </div>
                            <div class="col-md-3" >
                                    <span>Session: <strong style={{color: context.themeColors.primaryColor}} >{studentSkill && session.session.filter(data => data.sessionId == sessionId)[0].currentSession}</strong></span>     
                            </div>
                        </div>
                    <div className="table-responsive">
					<table className="table table-hover text-center">
						<thead style={{ background: context.themeColors.primaryColor, color: 'white' }}>
							<tr>
								<th scope="col" style={{ padding: 1 }}>
									#
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Student Name
								</th>
								<th scope="col" style={{ padding: 1 }}>
                                    Calss
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Section
								</th>
								<th scope="col" style={{ padding: 1 }}>
                                    Subject
								</th>
                                <th scope="col" style={{ padding: 1 }}>
                                    Session
								</th>
                                <th scope="col" style={{ padding: 1 }}>
                                    Flag
								</th>
							</tr>
						</thead>
						<tbody>{isLoading ? Loader : renderStudentSkillData}</tbody>
					</table>
				</div>
                </div>
            </div>
                                                                                                                        )}
                                                                                                                        </ThemeContext.Consumer>
        );
    }

}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch),
	};
}
function mapStateToProps(state) {
	return {
		userData: state.setUserReducer.users,
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ViewFlagManagementSkill);
