
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import $ from 'jquery';
import isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom'
import isNull from 'lodash/isNull'
import moment from 'moment'
import Pagination from '../../components/Pagination/Pagination'

import ThemeContext from "../../context/themeContext/ThemeContext";



class ViewAssignmentStaff extends Component {
    constructor(props){
        super(props);
        this.state = {
            isLoading: true,
            modalEdit: false,
            editToggle: false,
            generateToggle: false,
            generateIndex: '',
            msg: '',
            editIndex: '',
            classId: "",
            sectionId: "",
            subjectId: "",
            pageNumber: [],
            dataLimit: 10,
            currentPage: 1,
            termList: [{id: '1st', name: '1st Term'},{id: '2nd', name: '2nd Term'},{id: '3rd', name: '3rd Term'}],

			accountId: this.props.userData.unique_name,
			accountType: this.props.userData.role

        }

    }

    static contextType = ThemeContext

    componentDidMount(){
		this.getSimple('Session', 'session')
		this.getSimple('classSectionGradeTeacher', 'staffData')
			
			

    }
    handleSearch = () => {
        const {  classId, sectionId, subjectId, sessionId, term, planType, staffData } = this.state
        
        if(isNull(this.state.classId) || isNull(this.state.sectionId) ||  isNull(subjectId) || isNull(sessionId) || isNull(term)){
            console.log('Empty');
            
            this.setState({
                msg: "Please Fill The Form Repectively!",
            })            
            this.props.snackbar()
        }else{
            this.getPaginationSimple(`Assignments/?classId=${this.state.classId}&sectionId=${this.state.sectionId}&sessionId=${this.state.sessionId}&term=${this.state.term}&pageSize=${this.state.dataLimit}&pageNumber=${this.state.currentPage}`, 'assignmentData')
            
        }
    }
    
    getSimple = (type, state) => {
        this.props.sharedActions.getDataWithoutDispatch(
            `/api/${type}`
        ).then(success => { 
            this.setState({
                [state]: success,
                isLoading: false
            })


        }).catch(error => {
            this.setState({
                msg: 'Error Occured!!',
                errorMsg: true,
                isLoading: false
            })
            console.error('Error occured', error);
        })
    }

    getPaginationSimple = (type, state) => {
        this.props.sharedActions.getDataWithoutDispatch(
            `/api/${type}`
        ).then(success => { 
            this.setState({
                [state]: success,
                isLoading: false
            })
            this.setState({
                currentPage: success.paginationMetadata.currentPage,
                disableBPage: success.paginationMetadata.previousPage == 'No' ? true : false,
                disablepage: success.paginationMetadata.nextPage == 'No' ? true : false,
                pageNumber: Array.from(new Array(success.paginationMetadata.totalPages), (val, index) => index + 1)
            })



        }).catch(error => {
            this.setState({
                msg: error.response ? error.response.data.Message : 'Error Occured!!',
                isLoading: false
            })
            this.props.snackbar();
            console.error('Error occured', error);
        })
    }

    
	handleClickNextFrwd = () => {
		const {  currentPage, pageNumber } = this.state;
		console.log('current page: ', currentPage, pageNumber);
        if (currentPage === pageNumber.length) {
			this.setState({
				disablepage: true
            }, () => {
                this.handleSearch()

            });

            
		} else {
			this.setState({
				currentPage: currentPage + 1,
				disableBPage: false
            }, () => {
                this.handleSearch()

            });
            
            
		}
    };
    
	handleClickNextBack = () => {
		const { currentPage } = this.state;
		console.log('current page: ', currentPage);
		if (currentPage === 1) {
			this.setState({
				disableBPage: true,
				disablepage: false
            }, () => {
                this.handleSearch()

            });
		} else {
			this.setState({
                currentPage: currentPage - 1,
				disablepage: false
            }, () => {
                this.handleSearch()

            });
		}
    };
    
	handleClickNext = event => {
        const { currentPage, pageNumber } = this.state
                this.setState({
                    currentPage: Number(event.target.id),
                    disablepage: false,
                    disableBPage: false
                }, () => {
                    this.handleSearch()
    
                });
		
	};

    getDoubleState = (type, state) => {
        this.props.sharedActions.getDataWithoutDispatch(
            `/api/${type}`
        ).then(success => { 
            this.setState({
                [state]: success,
                contData: success,
                isLoading: false
            })


        }).catch(error => {
            this.setState({
                msg: 'Error Occured!!',
                errorMsg: true,
                isLoading: false
            })
            console.error('Error occured', error);
        })
    }


    
    onHandleFileChange = e => {
        console.log( e.target.name, e.target.files);
        this.setState({
            [e.target.name]: e.target.files[0]
        })
    }

    PrintContent = el => {
        var restorepage = document.body.innerHTML;
        var Printcontent = document.getElementById(el).innerHTML;
        document.body.innerHTML = Printcontent;
        
        setTimeout(() => {
            window.print();
            window.location.reload();
        }, 700)
        document.body.innerHTML = restorepage;
        window.reload();
        
    };



    myFunction = () => {
		// Get the snackbar DIV
		var x = document.getElementById('snackbar');

		// StepperGeneralButtons the "show" class to DIV
		x.className = 'show';

		// After 3 seconds, remove the show class from DIV
		setTimeout(function() {
		}, 3000);
	};



    btnTechHover = () => {
		$(document).ready(function() {
			$('button').hover(
				function() {
					$(this).css('box-shadow', '0px 5px 5px grey');
					$(this).css('border-color', 'white');
				},
				function() {
					$(this).css('box-shadow', '0px 0px 0px white');
				}
			);
		});
	};

    onHandleText = e => {
		this.setState({
			[e.target.name]: e.target.value
        });
        console.log([e.target.name], e.target.value)
    };

    onHandleFilter = e => {
        this.setState({
			[e.target.name]: e.target.value
        })
        console.log([e.target.name], e.target.value)
    };


    handleSubmit = e => {
        e.preventDefault();
        const { 
            id,
            classId,
            className,
            fileName,
            fileLength,
            filePath,
            date,
            gradeName,
            gradeId,
            assignmentName,
            section,
            sectionId,
            sessionId,
            sessionName,
            staffId,
            staffName,
            subjectId,
            subjectName,
            submissiondate,
            postedDate,
            reminderDate,
            marks,
            term,
            time,
            link,
            accountId,
            accountType,
            file,
            staffData
         } = this.state;

        this.setState({
            isLoading: true
        })
        if(
            isNull(submissiondate) || 
            isNull(reminderDate) || 
            isNull(assignmentName) || 
            isNull(link) || 
            isNull(staffId) ||
            isNull(marks) 

        ){
            this.setState({
                msg: 'Please fill the form respectively!'
            })
            this.props.snackbar()
        }else{
            
            let data = new FormData();
            if(file){
                data.append('file', file, file.name);
                data.append('fileName', file.name);
            }else{
                data.append('fileName', fileName);
                data.append('filePath', filePath);
                data.append('fileLength', fileLength);
            }
            data.append('submissiondate', moment(submissiondate, "YYYY-MM-DD").format("MM/DD/YYYY"));
            data.append('reminderDate', moment(reminderDate, "YYYY-MM-DD").format("MM/DD/YYYY"));
            data.append('postedDate', postedDate);
            data.append('marks', marks);
            data.append('assignmentId', id);
            data.append('className', className);
            data.append('classId', classId);
            data.append('date', date);
            data.append('gradeName', gradeName);
            data.append('gradeId', gradeId);
            data.append('assignmentName', assignmentName);
            data.append('section', section);
            data.append('sectionId', sectionId);
            data.append('sessionId', sessionId);
            data.append('sessionName', sessionName);
            data.append('staffId', staffId);
            data.append('staffName', staffName);
            data.append('subjectId', subjectId);
            data.append('subjectName', subjectName);
            data.append('term', term);
            data.append('time', time);
            data.append('link', link);
            data.append('accountId', accountId);
            data.append('accountType', accountType);
            var data2= {}
			for (var pair of data.entries()) {
				data2 = {...data2, [pair[0]]: pair[1] }
                console.log(pair[0]+ ', ' + pair[1]); 
            }

            console.log('data model', data)
            this.props.sharedActions.editRecordWithoutDispatch(
                '/api/Assignments/',
                id,
                data
            ).then(success => {
                console.log('success', success)
                this.state.assignmentData.assignment.splice(this.state.editIndex, 1, data2)
                console.log(this.state.assignmentData);
                
                this.setState({
                    msg: 'Record Changed Successfully!',
                    editToggle: false,
                    isLoading: false
                    
                })
                this.props.snackbar();
                $('#myModal1').modal('hide')
                
    
            }).catch(error => {
                console.error('Error name: ', error)
                    
                    $('#myModal1').modal('hide')
                this.setState({
                    msg: error.response ? error.response.data.Message : 'Error Occured!!',
                    isLoading: false
                })
                this.props.snackbar();
    
    
            })
        }    
    }

    
    onEditClick = id => {
        const { assignmentData } = this.state
        let modalData = assignmentData.assignment.filter((items, index ) =>  {
            if(id === items.assignmentId){
                    
            this.setState({
                editIndex: index
            })
            console.log(index)
            return id === items.assignmentId 
            }
        })
        console.log(modalData)
        this.setState({
            id: modalData[0].assignmentId,
            classId: modalData[0].classId,
            className: modalData[0].className,
            fileName: modalData[0].fileName,
            filePath: modalData[0].filePath,
            fileLength: modalData[0].fileLength,
            date: modalData[0].date,
            gradeId: modalData[0].gradeId,
            gradeName: modalData[0].gradeName,
            assignmentName: modalData[0].assignmentName,
            section: modalData[0].section,
            sectionId: modalData[0].sectionId,
            sessionId: modalData[0].sessionId,
            sessionName: modalData[0].sessionName,
            staffId: modalData[0].staffId,
            staffName: modalData[0].staffName,
            subjectId: modalData[0].subjectId,
            subjectName: modalData[0].subjectName,
            submissiondate: moment(modalData[0].submissiondate, "MM/DD/YYYY").format("YYYY-MM-DD"),
            reminderDate: moment(modalData[0].reminderDate, "MM/DD/YYYY").format("YYYY-MM-DD"),
            postedDate: modalData[0].postedDate,
            marks: modalData[0].marks,
            term: modalData[0].term,
            time: modalData[0].time,
            link: modalData[0].link,
            accountId: modalData[0].accountId,
            accountType: modalData[0].accountType,
            
        }, () => {
            $('#myModal1').modal({
                show: true
            })
        })
    }

    
    onDeleteClick = id => {
        const { assignmentData } = this.state
        let modalData = assignmentData.assignment.filter((items, index ) =>  {
            if(id === items.assignmentId){
                    
            this.setState({
                editIndex: index
            })
            console.log(index)
            return id === items.assignmentId 
            }
        })
        console.log(id)
        this.setState({
             modalDataDeleteId: id
            
        }, () => {
            $('#myModal2').modal({
                show: true
            })
        })
    }

    handleDelete = id => {
        
        const { editIndex } = this.state;

        this.props.sharedActions.deleteRecordWithoutDispatch(
            '/api/Assignments',
            id
        ).then(success => {
            this.state.assignmentData.assignment.splice(editIndex, 1)
            
                this.setState({
                    msg: 'Record Deleted!',
                })
                this.props.snackbar();

            
        }).catch(error => {
            
            this.setState({
                msg: error.response ? error.response.data.Message : 'Error Occured!!',
                isLoading: false
            })
            this.props.snackbar();

        })
    }

    render(){

        const { search, isLoading, editToggle, msg, assignmentData,
                currentPage, pageNumber, disableBPage, disablepage, 
                staffData, termList, session,
            id,
            classId,
            className,
            date,
            gradeName,
            gradeId,
            fileName,
            fileLength,
            filePath,
            assignmentName,
            section,
            sectionId,
            sessionId,
            sessionName,
            staffId,
            staffName,
            subjectId,
            subjectName,
            postedDate,
            reminderDate,
            submissiondate,
            marks,
            term,
            time,
            link,
            accountId,
            accountType,
            file
        } = this.state;



        const Loader = (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div className="spinner-border" style={{ color: this.context.themeColors.primaryColor }}></div>
            </div>
        );

        const deleteassignmentData = (
            <div>
            <div className="modal" id="myModal2">
                <div className="modal-dialog">
                    <div className="modal-content modal_content_custom">
                        <div
                            style={{
                                background: this.context.themeColors.primaryColor,
                                borderBottomLeftRadius: 30,
                                borderBottomRightRadius: 30,
                                borderTopLeftRadius: 18,
                                borderTopRightRadius: 18
                            }}
                            className="modal-header modal_custom_header"
                        >
                            <h4 style={{ color: 'white' }} className="modal-title">
                                Confirm
                            </h4>
                            <button
                                style={{ color: 'white' }}
                                on
                                type="button"
                                className="close"
                                data-dismiss="modal"
                            >
                                &times;
                            </button>
                        </div>
                        <div className="modal-body">
                        <div >
                                            <div style={{ textAlign: 'center' }}>
                                                <h4>Are you sure?</h4>
                                            </div>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-evenly'
                                                }}
                                            >
                                                <div>
                                                    <button
                                                        data-dismiss="modal"
                                                        onClick={() => this.handleDelete(this.state.modalDataDeleteId)}
                                                        type="button"
                                                        class="btn btn-primary buttonAppear"
                                                    >
                                                        Yes
                                                    </button>
                                                </div>
                                                <div>
                                                    <button
                                                        data-dismiss="modal"
                                                        type="button"
                                                        class="btn btn-primary buttonAppear"
                                                    >
                                                        No
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                        </div>
                        <div class="modal-footer">
                                <button
                                    type="button"
                                    style={{ background: '#00435D' }}
                                    class="btn btn-danger"
                                    onClick={() => this.setState({editToggle: false})}
                                    data-dismiss="modal"
                                >
                                    Close
                                </button>
                            </div>
                    </div>
                </div>
            </div>
            </div>
        )
    

        const editassignmentData = (
            <div>
            <div className="modal" id="myModal1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content modal_content_custom">
                        <div
                            style={{
                                background: this.context.themeColors.primaryColor,                                borderBottomLeftRadius: 30,
                                borderBottomRightRadius: 30,
                                borderTopLeftRadius: 18,
                                borderTopRightRadius: 18
                            }}
                            className="modal-header modal_custom_header"
                        >
                            <h4 style={{ color: 'white' }} className="modal-title">
                                Edit
                            </h4>
                            <button
                                style={{ color: 'white' }}
                                on
                                type="button"
                                className="close"
                                data-dismiss="modal"
                            >
                                &times;
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={this.handleSubmit} >
                            <fieldset disabled={!editToggle}>
                                <div className="row">
                                    <div className="col-md-6">
                                <div className="form-group">
                                <label for="accountId">Account Name:</label>
                                <input style={{
                                    borderTop: 0,
                                    borderRight: 0,
                                    borderLeft: 0
                                }}
                                type="text"
                                disabled
                                value={accountId}
                                name="modalDataAccountId"
                                className="form-control"
                                id="accountId"
                                />
                                </div>
                                     </div>
                                    <div className="col-md-6">

                                <div className="form-group">
                                <label for="accountType">Account Type:</label>
                                <input style={{
                                    borderTop: 0,
                                    borderRight: 0,
                                    borderLeft: 0
                                }}
                                type="text"
                                disabled
                                value={accountType}
                                name="modalDataAccountType"
                                className="form-control"
                                id="accountType"
                                />
                                </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">

                                    <div className="form-group">
                                        <label for="staffName">Staff Name</label>
                                        <input style={{
                                            borderTop: 0,
                                            borderRight: 0,
                                            borderLeft: 0
                                        }}
                                        type="text"
                                        disabled
                                        value={staffName}
                                        name="staffName"
                                        className="form-control"
                                        id="staffName"
                                />
                                    </div>
                                </div>

                                   <div className="col-md-6">

                                    <div className="form-group">
                                        <label for="sessionName">Session</label>
                                        <input style={{
                                            borderTop: 0,
                                            borderRight: 0,
                                            borderLeft: 0
                                        }}
                                        type="text"
                                        disabled
                                        value={sessionName}
                                        name="sessionName"
                                        className="form-control"
                                        id="sessionName"
                                />
                                    </div>
                                </div>
                            </div>
                            
                            <div className="row">
                                    
                                <div className="col-md-6">

                                    <div className="form-group">
                                        <label for="term">term</label>
                                        <input style={{
                                            borderTop: 0,
                                            borderRight: 0,
                                            borderLeft: 0
                                        }}
                                        type="text"
                                        disabled
                                        value={term}
                                        name="term"
                                        className="form-control"
                                        id="term"
                                />
                                    </div>
                                </div>
                                <div className="col-md-6">

                                    <div className="form-group">
                                        <label for="className">Class Name</label>
                                        <input style={{
                                            borderTop: 0,
                                            borderRight: 0,
                                            borderLeft: 0
                                        }}
                                        type="text"
                                        disabled
                                        value={className}
                                        name="className"
                                        className="form-control"
                                        id="className"
                                />
                                    </div>
                                </div>
                                </div>
                                
                            <div className="row">
                                    
                                <div className="col-md-6">

                                    <div className="form-group">
                                        <label for="section">Section</label>
                                        <input style={{
                                            borderTop: 0,
                                            borderRight: 0,
                                            borderLeft: 0
                                        }}
                                        type="text"
                                        disabled
                                        value={section}
                                        name="section"
                                        className="form-control"
                                        id="section"
                                />
                                    </div>
                                </div>
                                
                                <div className="col-md-6">

                                    <div className="form-group">
                                        <label for="gradeName">Grade</label>
                                        <input style={{
                                            borderTop: 0,
                                            borderRight: 0,
                                            borderLeft: 0
                                        }}
                                        type="text"
                                        disabled
                                        value={gradeName}
                                        name="gradeName"
                                        className="form-control"
                                        id="gradeName"
                                />
                                    </div>
                                </div>
                                </div>
                                <div className="row">
                                    
                                <div className="col-md-6">

                                    <div className="form-group">
                                        <label for="subjectName">Subject</label>
                                        <input style={{
                                            borderTop: 0,
                                            borderRight: 0,
                                            borderLeft: 0
                                        }}
                                        type="text"
                                        disabled
                                        value={subjectName}
                                        name="subjectName"
                                        className="form-control"
                                        id="subjectName"
                                />
                                    </div>
                                </div>
                                
                                <div className="col-md-6">

                                    <div className="form-group">
                                        <label for="fileName">File Name</label>
                                        <input style={{
                                            borderTop: 0,
                                            borderRight: 0,
                                            borderLeft: 0
                                        }}
                                        type="text"
                                        disabled
                                        value={fileName}
                                        name="fileName"
                                        className="form-control"
                                        id="fileName"
                                />
                                    </div>
                                </div>
                                </div>
                                <div className="row">

                                 <div class="col-md-6">
                                        <div className="md-form mb-0 " >
                                        <label for="postedDate">
                                                Posted Date
                                            </label>
                                            <input style={{
                                            borderTop: 0,
                                            borderRight: 0,
                                            borderLeft: 0
                                        }}
                                            type="text"
                                            disabled
                                            value={postedDate}
                                            name="postedDate"
                                            className="form-control"
                                            id="postedDate"
                                        />
                                                    
                                        </div>
						            </div>   

                                <div className="col-md-6">

                                    <div className="form-group">
                                        <label for="assignmentName">Assignment</label>
                                        <input style={{
                                            borderTop: 0,
                                            borderRight: 0,
                                            borderLeft: 0
                                        }}
                                        type="text"
                                        onChange={this.onHandleText}
                                        value={assignmentName}
                                        name="assignmentName"
                                        className="form-control"
                                        id="assignmentName"
                                />
                                    </div>
                                </div>
                                <div className="col-md-6">

                                    <div className="form-group">
                                        <label for="marks">Marks</label>
                                        <input style={{
                                            borderTop: 0,
                                            borderRight: 0,
                                            borderLeft: 0
                                        }}
                                        type="text"
                                        onChange={this.onHandleText}
                                        value={marks}
                                        name="marks"
                                        className="form-control"
                                        id="marks"
                                />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                        <div className="md-form mb-0 " >
                                        <label for="submissiondate">
                                                Submission Date
                                            </label>
                                            <input class="custom-select" 
                                                type='date'
                                                value={submissiondate}
                                                name="submissiondate"
                                                onChange={this.onHandleText}
                                            />
                                                    
                                        </div>
						            </div>
                                    <div class="col-md-6">
                                        <div className="md-form mb-0 " >
                                        <label for="reminderDate">
                                                Reminder Date
                                            </label>
                                            <input class="custom-select" 
                                                type='date'
                                                value={reminderDate}
                                                name="reminderDate"
                                                onChange={this.onHandleText}
                                            />
                                                    
                                        </div>
						            </div>
                                </div>
                                <div className="row">
                                    
                                <div class="col-md-12">
                                    <label>
                                        Assignment File
                                    </label>
                                         <div class="custom-file">
                                        <input 
                                            type="file" 
                                            class="custom-file-input" 
                                            id="file"
                                            name="file"
                                            accept="application/pdf"
                                            onChange={this.onHandleFileChange}
                                            />
                                        <label class="custom-file-label" for="customFile">{file ? file.name : "Choose File"}</label>
                                        </div>
						            </div>
                                       
                                </div>
                                <div className="row">
                                <div className="col-md-12">

                                    <div className="form-group">
                                        <label for="link">Description</label>
                                        <textarea style={{
                                            borderTop: 0,
                                            borderRight: 0,
                                            borderLeft: 0
                                        }}
                                        rows={6}
                                        type="text"
                                        onChange={this.onHandleText}
                                        value={link}
                                        name="link"
                                        className="form-control"
                                        id="link"
                                />
                                    </div>
                                </div>
                                </div>
                            </fieldset>
                            <br />
							<br />
								<button
									disabled={editToggle}
									onClick={() => this.setState({ editToggle: true })}
									type="button"
									class="btn btn-primary buttonAppear"
									>
								    	Edit
									</button>
									<button
										style={{ marginLeft: 5 }}
										disabled={!editToggle}
										type="submit"
										class="btn btn-primary buttonAppear"
										>
										Save
									</button>
                            </form>
                        </div>
                        <div class="modal-footer">
								<button
									type="button"
									style={{ background: '#00435D' }}
                                    class="btn btn-danger"
                                    onClick={() => this.setState({editToggle: false})}
									data-dismiss="modal"
								>
									Close
								</button>
							</div>
                    </div>
                </div>
            </div>
            </div>                 
        )
        
        const renderAssignmentData = assignmentData && assignmentData.assignment.map((item, index) => {
            return (
                <tr key={index} >
                    <th scope="row" style={{ padding: 1 }} >
                        {index + 1}
                    </th>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.staffName}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.assignmentName}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.sessionName}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.term}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.className}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.section}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.gradeName}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.subjectName}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.date}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.reminderDate}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.submissiondate}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.link}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.fileName}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.marks}
                    </td>
                    <td className="borderSpace" style={{ padding: 1, color: 'red' }}>
						<a
							href={
								'https://embassyportal.schoolsmart.org.uk/api/DownloadAssignments/?fileName=' +
								item.fileName
							}
							data-toggle="tooltip"
							title="Download File"
						>
							{/* <form action={this.state.filePath}> */}
							<button
								// onClick={this.downloadFile(item.fileName)}
								// type="submit"
								style={{
									cursor: 'pointer',
									fontSize: 20,
									border: 'none',
									marginLeft: 5,
									background: 'none',
									marginBottom: 5
								}}
							>
								<i style={{ color: '#01425e' }} class="fas fa-download"></i>
							</button>
							{/* </form> */}
						</a>
                    </td>
                </tr>
                
                );
            
        }) 

        return (
            <div>
				<section  className="mb-4">
				<h2  
                        style={{ background: this.context.themeColors.primaryColor }}
                        className="h1-responsive font-weight-bold text-center  generalHead">
                        View Assignment
                    </h2>
			    <div>
                <SnackBar msg={msg} />
                <div style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                }}>
                    <div>
                            <div class="row my-3 mx-5 py-4" style={{
                            border: `2px solid ${this.context.themeColors.primaryColor}`,
                            borderRadius: 20
                        }} >
                                <div class="col-md-4" >
                                    
                                <select class="custom-select" 
                                value={classId}
                                name="classId"
                                onChange={this.onHandleText}
                                disabled={!staffData}
                            >
                                        <option value="">Please Select Class</option>
                                       {staffData && staffData.classes.map((value, index) => {
                                           return <option value={value.classId}>{value.className}</option>
                                       })}
                            </select>
                            </div>
                             <div class="col-md-4" >
                             <select class="custom-select" 
                                value={sectionId}
                                name="sectionId"
                                onChange={this.onHandleText}
                                disabled={!classId}

                            >
                                        <option value="">Please Select Section</option>
                                       {staffData && staffData.sections.map((value, index) => {


                                                return value.classId == classId && 
                                                <option value={value.sectionId}>{value.section}</option>

                                       })}
                            </select>
                            </div>
                                       
                            <div class="col-md-4" >
                            <select class="custom-select" 
                                                value={sessionId}
                                                name="sessionId"
                                                onChange={this.onHandleText}
                                                disabled={!sectionId || !session}

                                            >
                                                        <option value="">Please Select Session</option>
                                                       {session && session.session.map((value, index) => {

                                                                return <option value={value.sessionId}>{value.currentSession}</option>

                                                       })}
                                            </select>
                            </div>
                              <div class="col-md-4" >
                             <select class="custom-select" 
                                value={term}
                                style={{marginTop: '3%'}}
                                name="term"
                                onChange={this.onHandleText}
                                disabled={!sessionId}

                            >
                                        <option value="">Please Select Term</option>
                                       {termList.map((value, index) => {

                                                return <option value={value.id}>{value.name}</option>

                                       })}
                            </select>
                            </div>

                        </div>
                        <div class="row my-4">
                        <div className="col-md-12 text-center">

                                            <button
                                            class="btn btn-primary buttonAppear"
                                            onClick={this.handleSearch}
                                            style={{marginRight: '2%'}}
                                            >
                            <i class="fa fa-search"></i> Search
                                        </button>
                                        <Link to={{
                            pathname: "PrintAssignment",
                            state: {
                                assignmentData,
                                date: moment().format('MM/DD/YYYY'),
                                currentSession: assignmentData && session.session.filter(d => d.sessionId == sessionId)[0].currentSession,
                                term,
                                className: assignmentData && staffData.classes.filter(d => d.classId == classId)[0].className,
                                section: assignmentData && staffData.sections.filter(d => d.sectionId == sectionId)[0].section,

                            }
                        }}  >
                            <a href="#" data-toggle="tooltip" title="Edit!">
                                
                            <button
                                            class="btn btn-primary buttonAppear"
                                            // disabled={!studentAttendance}
                                            >
                            
                                    <i className="fas fa-list"></i> View
                                </button>
                            </a> 
                            
                        </Link>
                            </div>
                            
                        </div>
                        <div class="row">
                                <div class="col-md-12 text-right">
                                <Pagination
                                    disableBPage={disableBPage}
                                    disablepage={disablepage}
                                    currentPage={currentPage}
                                    pageNumber={pageNumber}
                                    handleClickNext={this.handleClickNext}
                                    handleClickNextBack={this.handleClickNextBack}
                                    handleClickNextFrwd={this.handleClickNextFrwd}
                                />
                                </div>
                            </div>
                    <div className="table-responsive">
					<table className="table table-hover text-center">
						<thead style={{ background: this.context.themeColors.primaryColor, color: 'white' }}>
							<tr>
								<th scope="col" style={{ padding: 1 }}>
									#
								</th>
                                <th scope="col" style={{ padding: 1 }}>
									Staff Name
								</th>
                                <th scope="col" style={{ padding: 1 }}>
									Assignment
								</th>
                                <th scope="col" style={{ padding: 1 }}>
									Session
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Term
								</th>
								<th scope="col" style={{ padding: 1 }}>
                                    Class
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Section
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Grade
								</th>
								<th scope="col" style={{ padding: 1 }}>
                                    Subject
								</th>
                                <th scope="col" style={{ padding: 1 }}>
                                    Date
								</th>
                                <th scope="col" style={{ padding: 1 }}>
                                    Reminder Date
								</th>
                                <th scope="col" style={{ padding: 1 }}>
                                    Submission Date
								</th>
                                <th scope="col" style={{ padding: 1 }}>
                                    Description
								</th>
                                <th scope="col" style={{ padding: 1 }}>
									File Name
								</th>
                                <th scope="col" style={{ padding: 1 }}>
									Marks
								</th>
                                <th scope="col" style={{ padding: 1 }}>
                                    Actions
								</th>
							</tr>
						</thead>
						<tbody>{isLoading ? Loader : renderAssignmentData}</tbody>
					</table>
				</div>
                </div>
            </div>
            </div>
            </section>
            </div>
        );
    }

}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch),
	};
}
function mapStateToProps(state) {
	return {
		userData: state.setUserReducer.users,
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ViewAssignmentStaff);
