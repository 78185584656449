import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import DateandTime from '../Transport/DateandTime';
import isNull from 'lodash/isNull'

import SnackBar from "../../components/SnackBar/SnackBar";
import LoaderWrapper from "../../components/HOC/LoaderWrapper";
import ThemeContext from "../../context/themeContext/ThemeContext";






class AddEmergencyMaintenance extends Component {
    constructor(props){
        super(props);
        this.state = {
            errorMsg: false,
            successMsg: false,
            msg: '',
            isLoading: false,
            monthList: [],
            yearList: [],
            staffId: null,
            floorId: null,
            roomId: null,
            categoryId: null,
            description: null,

            typeWise: 'floor'
        }
    }

    componentDidMount() {
            this.getSimple('Dropdown/FloorRoom', 'floorData')
            this.getSimple('campusDropdown', 'campus')

    }


    getSimple = (type, state) => {
        this.props.sharedActions.getDataWithoutDispatch(
            `/api/${type}`
        ).then(success => {
            this.setState({
                [state]: success,
                isLoading: false
            })


        }).catch(error => {
            this.setState({
                msg: 'Error Occured!!',
                isLoading: false
            })
            console.error('Error occured', error);
        })
    }

    myFunction = () => {
		// Get the snackbar DIV
		var x = document.getElementById('snackbar');

		// StepperGeneralButtons the "show" class to DIV
		x.className = 'show';

		// After 3 seconds, remove the show class from DIV
		setTimeout(function() {
		}, 3000);
	};

    onHandleTextChange = e => {
        console.log([e.target.name], e.target.value);
        
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleSubmit = e => {
        e.preventDefault();
        const { floorId, roomId, categoryId, staffId, description, typeWise } = this.state;
        if(
            isNull(floorId) || 
           (typeWise == 'floor' ?  false : isNull(roomId)) || 
            isNull(categoryId) || 
            isNull(staffId) || 
            isNull(description) 

        ){
            this.setState({
                successMsg: false,
                errorMsg: true,
                msg: 'Please fill the form respectively!'
            })
        }else{

        let data = {
            accountId: this.props.userData.unique_name,
            accountType: this.props.userData.role,
            floorId,
            roomId: typeWise == 'floor' ? '-' : roomId,
            categoryId,
            staffId,
            description,
        }

        console.log(data);

        this.props.sharedActions.addRecordWithoutDispatch(
            `/api/EmergencyMaintenance`,
            data
        ).then(success => {
            console.log(success);
            
            this.setState({
                msg: 'Emergency Maintenace Added!',
                isLoading: false,
                departmentId: '',
                postId: '',
                staffId: '',
                floorId: '',
                roomId: '',
                categoryId: '',
                staffId: '',
                description: '',
            })
            this.props.snackbar();
        }).catch(error => {
            this.setState({
                msg: error.response ? error.response.data.Message : 'Error Occured!!',
                isLoading: false
            })
            this.props.snackbar();
            console.error('Error occured', error);
            
        })
    }
    }   

    render(){

        const { successMsg, errorMsg, msg, isLoading, campus, floorData, typeWise,
            staffId, departmentId, postId, floorId, roomId, categoryId, description
        } = this.state;

        return (
            <div className="container">
				<section  className="mb-4">
                <ThemeContext.Consumer>
                    {(context) => (
                    <h2  
                        style={{ background: context.themeColors.primaryColor }}
                        className="h1-responsive font-weight-bold text-center my-4 generalHead">
                        Add Emergency Maintenance
                    </h2>
                     )}
                </ThemeContext.Consumer>

              <SnackBar msg={this.state.msg} backColor={this.state.backColor} />

                    <p class="text-center w-responsive mx-auto mb-5" />

                    <div className="row" style={{
                        justifyContent: 'center',
                        textAlign: 'center'
                    }}>

                <ThemeContext.Consumer>
                {(context) => (
                        <div className="col-md-9 mb-md-0 mb-5" style={{
                            border: 'solid',
                            borderColor: context.themeColors.primaryColor,
                            padding: 40,
                            borderRadius: 15,
                            width: 'auto'

                        }}>
                      <LoaderWrapper isLoading={this.state.isLoading}>
                            <form id="emergencyMaintenance-form" name="emergencyMaintenance-form" onSubmit={this.handleSubmit} >
                                <div className="row">
									<div className="col-md-12">
										<div className="md-form mb-0">
											<DateandTime />
										</div>
									</div>
								</div>
								<br />
                                <div class="row">
									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="AdminName" class="">
												Admin Name
											</label>
											<input
												
												type="text"
												name="AdminName"
												class="form-control"
												readOnly={true}
												value={this.props.userData.unique_name}
											/>
										</div>
								    </div>

									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="AdminType" class="">
												Admin Type
											</label>
											<input
												
												type="text"
												name="AdminType"
												class="form-control"
												readOnly={true}
												value={this.props.userData.role}
											/>
										</div>
								    </div>
                                </div><div class="row my-4">
									<div class="col-md-6">
										<div class="md-form mb-0  custom-control custom-radio custom-control-inline">
                                                <input 
                                                    type="radio" 
                                                    class="custom-control-input" 
                                                    id="customRadio" 
                                                    name="typeWise" 
                                                    value="floor"
                                                    onChange={this.onHandleTextChange}
                                                    defaultChecked
                                                    />
                                                <label class="custom-control-label" for="customRadio">Floor Wise</label>
										</div>
								    </div>

									<div class="col-md-6">
										<div class="md-form mb-0 custom-control custom-radio custom-control-inline ">
                                                <input 
                                                    type="radio" 
                                                    class="custom-control-input" 
                                                    id="customRadio2" 
                                                    name="typeWise" 
                                                    onChange={this.onHandleTextChange}
                                                    value="room"/>
                                                <label class="custom-control-label" for="customRadio2">Room Wise</label>
										</div>
								    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="md-form mb-0" >
                                            <label for="departmentId">
                                                Department
                                            </label>
                                            <select class="custom-select" 
                                                value={departmentId}
                                                name="departmentId"
                                                onChange={this.onHandleTextChange}
                                            >
                                                        <option value="">Please Select Department</option>
                                                       {campus && campus.department.map((value, index) => {

                                                                return <option value={value.departmentId}>{value.departmentName}</option>

                                                       })}
                                            </select>  
                                        </div>
                                        </div>
                                        <div className="col-md-6">
                                        <div className="md-form mb-0" >
                                            <label for="postId">
                                                Post
                                            </label>
                                            <select class="custom-select" 
                                                value={postId}
                                                name="postId"
                                                onChange={this.onHandleTextChange}
                                                disabled={!departmentId}
                                            >
                                                        <option value="">Please Select post</option>
                                                       {campus && campus.post.map((value, index) => {
                                                           
                                                           return  value.departmentId == departmentId && 
                                                           <option value={value.postId}>{value.postName}</option>
                                                       })}
                                            </select>  
                                        </div>
                                        </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="md-form mb-0" >
                                            <label for="staffId">
                                                Staff
                                            </label>
                                            <select class="custom-select" 
                                                value={staffId}
                                                name="staffId"
                                                onChange={this.onHandleTextChange}
                                                disabled={!postId}

                                            >
                                                        <option value="">Please Select Staff</option>
                                                        {campus && campus.staff.map((value, index) => {
                                                           
                                                           return value.departmentId == departmentId && value.postId == postId &&
                                                           <option value={value.staffId}>{value.staffName}</option>
                                                       })}
                                            </select>  
                                        </div>
                                        </div>
                                        <div className="col-md-6">
                                        <div className="md-form mb-0" >
                                        <label for="floorId">
                                                Floor Name
                                            </label>
                                            <select class="custom-select" 
                                                value={floorId}
                                                name="floorId"
                                                onChange={this.onHandleTextChange}
                                                disabled={!staffId}
                                            >
                                                        <option value="">Please Select Floor</option>
                                                        {floorData && floorData.floors.map((value, index) => {
                                                           
                                                           
                                                            return <option value={value.id}>{value.floorName}</option>
                                                       })}
                                            </select>
                                        </div>
                                        </div>
                                       
                                </div>
                                <div className="row">
                                {typeWise == 'room' && <div className="col-md-6">
                                        <div className="md-form mb-0" >
                                        <label for="roomId">
                                                Room Name
                                            </label>
                                            <select class="custom-select" 
                                                value={roomId}
                                                name="roomId"
                                                onChange={this.onHandleTextChange}
                                                disabled={!floorId || typeWise == 'floor'}
                                            >
                                                        <option value="-">Please Select Room</option>
                                                        {floorData && floorData.rooms.map((value, index) => {
                                                           
                                                           return floorId == value.floorId && 
                                                           <option value={value.id}>{value.roomName}</option>
                                                       })}
                                            </select>
                                        </div>
                                        </div>}
                                        <div className="col-md-6">
                                        <div className="md-form mb-0" >
                                        <label for="categoryId">
                                                Category
                                            </label>
                                            <select class="custom-select" 
                                                value={categoryId}
                                                name="categoryId"
                                                onChange={this.onHandleTextChange}
                                                disabled={!floorId || !typeWise == 'floor'}
                                            >
                                                        <option value="">Please Select Category</option>
                                                        {floorData && floorData.category.map((value, index) => {
                                                           
                                                           return <option value={value.id}>{value.categoryName}</option>
                                                       })}
                                            </select>
                                        </div>
                                        </div>
                                       
                                </div>
                                <br/>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="md-form mb-0" >
                                        <label for="description">
                                                Description
                                            </label>
                                            <textarea
												
                                                type="text"
                                                row={6}
												name="description"
												class="form-control"
                                                value={description}
                                                onChange={this.onHandleTextChange}
                                                disabled={!categoryId}

											/>  
                                        </div>
                                        </div>
                                       
                                </div>
                                        <br/>
                                        <br/>
                                        <div className="text-center text-md-center">
								<button type="submit" className="btns" style={{
                                                    background: context.themeColors.primaryColor,
                                            }}>
										Add
										<span
											style={{
												marginBottom: 5
											}}
											className={
												isLoading && 'spinner-border spinner-border-sm'
											}
										></span>
									</button>
								</div>
							</form>
							</LoaderWrapper>
                            <div class="status" />
                </div>
              )}
            </ThemeContext.Consumer>
                    </div>
                </section>
			</div>
        );
    }

}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch),
	};
}
function mapStateToProps(state) {
	return {
		userData: state.setUserReducer.users,
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AddEmergencyMaintenance);
