import React, { Component } from "react";
import $ from "jquery";
import "../Admin/viewAdmin.css";
import Axios from "axios";
import config from "../../config/config";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as getAllAdminActions from "../../Actions/getAllAdmin";
import * as HrDevelopmentActions from "../../Actions/HrDevelopment";
import * as sharedActions from "../../Actions/sharedActions";
import SnackBar from "../../components/SnackBar/SnackBar";
import ThemeContext from "../../context/themeContext/ThemeContext";

class EditHrDevelopment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adminData: [],
      modalData: null,
      description: "",
      title: "",
      trainingDate: "",
      trainingTime: "",
      location: "",
      fees: "",
      link: "",
      type: "",
      chooseFile: "",
      enabled: false,
      id: null,
      isLoading: true,
      disablepage: false,
      disableBPage: true,
      currentPage: 1,
      todosPerPage: 10,
      pageCounter: 10,
      msg: "",
    };
  }

  handleClickNextFrwd = () => {
    const { adminData, todosPerPage, count, currentPage } = this.state;
    let pageNumbers = [];
    for (
      let i = 1;
      i <= Math.ceil(adminData.length / this.state.todosPerPage);
      i++
    ) {
      pageNumbers.push(i);
    }

    console.log("pagelength: ", pageNumbers.length);
    console.log("current page: ", currentPage);
    if (currentPage === pageNumbers.length) {
      this.setState({
        disablepage: true,
      });
    } else {
      this.setState((prevState) => ({
        currentPage: prevState.currentPage + 1,
        disableBPage: false,
      }));
    }
  };
  handleClickNextBack = () => {
    const { adminData, todosPerPage, count, currentPage } = this.state;
    console.log("current page: ", currentPage);
    if (currentPage === 1) {
      this.setState({
        disableBPage: true,
        disablepage: false,
      });
    } else {
      this.setState((prevState) => ({
        currentPage: prevState.currentPage - 1,
      }));
      this.setState({
        disablepage: false,
      });
    }
  };
  handleClickNext = (event) => {
    this.setState({
      currentPage: Number(event.target.id),
      disablepage: false,
      disableBPage: false,
    });
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.adminData !== nextProps.AllAdminData) {
      return {
        adminData: nextProps.AllAdminData,
      };
    }
    return null;
  }
  componentDidUpdate() {
    $('[data-toggle="tooltip"]').tooltip();
    $("#chooseFile").bind("change", function () {
      var filename = $("#chooseFile").val();
      if (/^\s*$/.test(filename)) {
        $(".file-upload").removeClass("active");
        $("#noFile").text("No file chosen...");
      } else {
        $(".file-upload").addClass("active");
        $("#noFile").text(filename.replace("C:\\fakepath\\", ""));
      }
    });
  }
  componentDidMount() {
    $(document).ready(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });
    if (this.props.allAdminState.apiFlagEdit) {
      this.props.sharedActions
        .getAllData(
          "/api/HrDevTrainning?type=Courses",
          this.props.HrDevelopmentActions.setAllHrDevelopementForEditInRedux,
          this.props.HrDevelopmentActions.updateHrDevelopementFlagEdit
        )
        .then((success) => {
          this.setState({
            isLoading: false,
          });
        })
        .catch((err) => {
          this.setState({
            isLoading: false,
          });

          console.log(err);
        });
    }
  }
  onSearchStudent = (e) => {
    this.setState({
      btnLoad: true,
    });
    this.props.sharedActions
      .getAllData(
        `/api/HrDevTrainning?type=${this.state.type}`,
        this.props.HrDevelopmentActions.setAllHrDevelopementForEditInRedux,
        this.props.HrDevelopmentActions.updateHrDevelopementFlagEdit
      )
      .then((success) => {
        this.setState({
          isLoading: false,
          btnLoad: false,
        });
      })
      .catch((err) => {
        this.props.HrDevelopmentActions.updateHrDevelopementFlagEdit(false);
        this.setState({
          isLoading: false,
          btnLoad: false,
        });

        console.log(err);
      });
  };
  btnTechHover = () => {
    $(document).ready(function () {
      $("button").hover(
        function () {
          $(this).css("box-shadow", "0px 5px 5px grey");
          $(this).css("border-color", "white");
        },
        function () {
          $(this).css("box-shadow", "0px 0px 0px white");
        }
      );
    });
  };
  onTakeItemDelete = (itemId) => {
    this.setState({
      modalData: [],
    });
    this.props.sharedActions
      .deleteRecord(
        "/api/HrDevTrainning/",
        itemId,
        this.props.HrDevelopmentActions.deleteHrDevelopment
      )
      .then((success) => {
        this.setState({
          msg: "Delete Record Successfully!",
        });
        this.props.snackbar();
      })
      .catch((error) => {});
  };
  onTakeItem = (itemId) => {
    // campusAddress:'',
    // campusName:'',
    // campusPhoneNumber:"",
    // campusCity:"",
    this.setState({
      modalData: [],
    });
    const { adminData } = this.state;
    let getData = adminData.filter((item) => item.id === itemId);

    this.setState({
      modalData: getData,
      description: getData[0].description,
      title: getData[0].title,
      type: getData[0].type,
      trainingDate: getData[0].trainingDate,
      trainingTime: getData[0].trainingTime,
      location: getData[0].location,
      fees: getData[0].fees,
      link: getData[0].link,
      id: getData[0].id,
    });
  };

  onHandleText = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onHandleSubmit = (e) => {
    e.preventDefault();
    const {
      description,
      type,
      id,
      title,
      trainingTime,
      trainingDate,
      location,
      fees,
      link,
    } = this.state;
    let file = this.state.chooseFile;
    let accountId = this.props.users.unique_name;
    let accountType = this.props.users.role;
    let data = new FormData();
    data.append("file", file);
    data.append("description", description);
    data.append("title", title);
    data.append("trainingTime", trainingTime);
    data.append("trainingDate", trainingDate);
    data.append("location", location);
    data.append("fees", fees);
    data.append("link", link);
    data.append("type", type);
    data.append("accountType", accountType);
    data.append("accountId", accountId);
    console.log("DATA Part", data);
    this.props.sharedActions
      .editRecord(
        "/api/HrDevTrainning/",
        id,
        data,
        this.props.HrDevelopmentActions.updateHrDevelopment
      )
      .then((success) => {
        this.setState({ enabled: false, msg: "Edit Record Successfully!" });
        this.props.snackbar();
      })
      .catch((err) => {});
  };
  onEditClick = (itemId) => {
    this.onTakeItem(itemId);
    this.setState({ enabled: false });
  };
  onChange = (e) => {
    this.setState({
      chooseFile: e.target.files[0],
    });
    console.log(this.state.chooseFile);
  };
  inputData = (e) => {
    console.log(e.target.value);
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  render() {
    const { adminData, modalData } = this.state;

    console.log("modal data: ", this.state.modalData);
    const Loader = (
      <ThemeContext.Consumer>
        {(context) => (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              class="spinner-border"
              style={{ color: context.themeColors.primaryColor }}
            ></div>
          </div>
        )}
      </ThemeContext.Consumer>
    );
    let pageNumbers = [];
    for (
      let i = 1;
      i <= Math.ceil(adminData.length / this.state.todosPerPage);
      i++
    ) {
      pageNumbers.push(i);
    }
    const renderPageNumbers = pageNumbers.map((number, index) => {
      return index <= 3 ? (
        <li
          class={`page-item ${
            this.state.currentPage === number ? "active" : ""
          } `}
        >
          <a
            key={number}
            id={number}
            onClick={this.handleClickNext}
            class="page-link "
            href="#"
          >
            {number}
          </a>
        </li>
      ) : (
        ""
      );
    });
    const { currentPage, todosPerPage } = this.state;
    const indexOfLastTodo = currentPage * todosPerPage;
    const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
    const currentTodos = adminData.slice(indexOfFirstTodo, indexOfLastTodo);
    const renderTodos = currentTodos.map((item, index) => {
      return (
        <tr key={index}>
          <th scope="row">{index + 1}</th>
          <td>{item.title}</td>
          <td>{item.fileName}</td>
          <td>{item.description}</td>
          <td>{item.trainingDate}</td>
          <td>{item.trainingTime}</td>
          <td>{item.location}</td>
          <td>{item.fees}</td>
          <td>{item.link}</td>
          <td>{item.accountId}</td>
          <td>{item.accountType}</td>
          <td>
            <a href="#" data-toggle="tooltip" title="Edit!">
              <button
                id="firstbutton"
                onClick={() => this.onEditClick(item.id)}
                onMouseOver={this.btnTechHover()}
                data-toggle="modal"
                data-target="#myModal"
                style={{
                  cursor: "pointer",
                  fontSize: 20,
                  border: "none",
                  marginLeft: 5,
                  background: "none",
                  marginBottom: 5,
                }}
              >
                <i class="fas fa-pen"></i>
              </button>
            </a>
            <a href="#" data-toggle="tooltip" title="Delete!">
              <button
                onClick={() => this.onEditClick(item.id)}
                data-toggle="modal"
                data-target="#myModal1"
                style={{
                  cursor: "pointer",
                  fontSize: 20,
                  border: "none",
                  background: "none",
                  marginLeft: 5,
                  marginBottom: 5,
                }}
              >
                <i style={{ color: "#D11A2A" }} class="fas fa-trash-alt"></i>
              </button>
            </a>
          </td>
        </tr>
      );
    });
    const MainContent = (
      <div>
        <div class="modal" id="myModal1">
          <div class="modal-dialog">
            <div class="modal-content modal_content_custom">
              <div
                style={{
                  borderBottomLeftRadius: 30,
                  borderBottomRightRadius: 30,
                  borderTopLeftRadius: 18,
                  borderTopRightRadius: 18,
                }}
                class="modal-header modal_custom_header"
              >
                <h4 style={{ color: "white" }} class="modal-title">
                  Confirm
                </h4>
                <button
                  style={{ color: "white" }}
                  type="button"
                  class="close"
                  data-dismiss="modal"
                >
                  &times;
                </button>
              </div>
              <div class="modal-body">
                {modalData &&
                  modalData.map((item, index) => (
                    <div key={index}>
                      <div style={{ textAlign: "center" }}>
                        <h4>Are you sure ?</h4>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <div>
                          <button
                            disabled={this.state.enabled}
                            data-dismiss="modal"
                            onClick={() => this.onTakeItemDelete(this.state.id)}
                            type="button"
                            class="btn btn-primary buttonAppear"
                          >
                            Yes
                          </button>
                        </div>
                        <div>
                          <button
                            data-dismiss="modal"
                            disabled={this.state.enabled}
                            type="button"
                            class="btn btn-primary buttonAppear"
                          >
                            No
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <div class="modal" id="myModal">
          <div class="modal-dialog">
            <div class="modal-content modal_content_custom">
              <div
                style={{
                  borderBottomLeftRadius: 30,
                  borderBottomRightRadius: 30,
                  borderTopLeftRadius: 18,
                  borderTopRightRadius: 18,
                }}
                class="modal-header modal_custom_header"
              >
                <h4 style={{ color: "white" }} class="modal-title">
                  Details
                </h4>
                <button
                  style={{ color: "white" }}
                  type="button"
                  class="close"
                  data-dismiss="modal"
                >
                  &times;
                </button>
              </div>
              <div class="modal-body">
                <form onSubmit={this.onHandleSubmit}>
                  {modalData &&
                    modalData.map((item, index) => (
                      <div key={index}>
                        <fieldset disabled={!this.state.enabled}>
                          <div class="form-group">
                            <label for="campusName">Title:</label>
                            <input
                              style={{
                                borderTop: 0,
                                borderLeft: 0,
                                borderRight: 0,
                                boxShadow: "none",
                              }}
                              type="text"
                              value={this.state.title}
                              onChange={this.onHandleText}
                              name="title"
                              class="form-control"
                              id="campusName"
                            />
                          </div>
                          <div class="form-group">
                            <label for="campusName">Type:</label>
                            <input
                              style={{
                                borderTop: 0,
                                borderLeft: 0,
                                borderRight: 0,
                                boxShadow: "none",
                              }}
                              type="text"
                              value={this.state.type}
                              readOnly={true}
                              onChange={this.onHandleText}
                              name="title"
                              class="form-control"
                              id="campusName"
                            />
                          </div>
                          <div class="form-group">
                            <label for="Campus City">Date</label>
                            <input
                              required
                              style={{
                                borderTop: 0,
                                borderLeft: 0,
                                borderRight: 0,
                                boxShadow: "none",
                              }}
                              value={this.state.trainingDate}
                              onChange={this.onHandleText}
                              type="text"
                              name="trainingDate"
                              class="form-control"
                              id="campusCity"
                            />
                          </div>
                          <div class="form-group">
                            <label for="Campus Address"> Time</label>
                            <input
                              required
                              style={{
                                borderTop: 0,
                                borderLeft: 0,
                                borderRight: 0,
                                boxShadow: "none",
                              }}
                              value={this.state.trainingTime}
                              onChange={this.onHandleText}
                              type="text"
                              name="trainingTime"
                              class="form-control"
                              id="campusAddress"
                            />
                          </div>
                          <div class="form-group">
                            <label for="Campus Address">Location</label>
                            <input
                              required
                              style={{
                                borderTop: 0,
                                borderLeft: 0,
                                borderRight: 0,
                                boxShadow: "none",
                              }}
                              value={this.state.location}
                              onChange={this.onHandleText}
                              type="text"
                              name="location"
                              class="form-control"
                              id="campusAddress"
                            />
                          </div>
                          <div class="form-group">
                            <label for="Campus Address">Fees</label>
                            <input
                              required
                              style={{
                                borderTop: 0,
                                borderLeft: 0,
                                borderRight: 0,
                                boxShadow: "none",
                              }}
                              value={this.state.fees}
                              onChange={this.onHandleText}
                              type="text"
                              name="fees"
                              class="form-control"
                              id="campusAddress"
                            />
                          </div>
                          <div class="form-group">
                            <label for="Campus Address">Link</label>
                            <input
                              style={{
                                borderTop: 0,
                                borderLeft: 0,
                                borderRight: 0,
                                boxShadow: "none",
                              }}
                              value={this.state.link}
                              onChange={this.onHandleText}
                              type="text"
                              name="link"
                              class="form-control"
                              id="campusAddress"
                            />
                          </div>
                          <div class="form-group">
                            <label for="campusName">Description:</label>
                            <input
                              style={{
                                borderTop: 0,
                                borderLeft: 0,
                                borderRight: 0,
                                boxShadow: "none",
                              }}
                              type="text"
                              value={this.state.description}
                              onChange={this.onHandleText}
                              name="description"
                              class="form-control"
                              id="description"
                            />
                          </div>
                          <br />
                          <div class="form-group">
                            <label for="campusName">Upload File:</label>
                            <div class="file-upload">
                              <div class="file-select">
                                <div class="file-select-button" id="fileName">
                                  Choose File
                                </div>
                                <div class="file-select-name" id="noFile">
                                  No File Choosen
                                </div>
                                <input
                                  type="file"
                                  name="chooseFile"
                                  id="chooseFile"
                                  onChange={this.onChange}
                                />
                              </div>
                            </div>
                          </div>
                        </fieldset>
                        <br />
                        <br />
                        <button
                          disabled={this.state.enabled}
                          onClick={() => this.setState({ enabled: true })}
                          type="button"
                          class="btn btn-primary buttonAppear"
                        >
                          Edit
                        </button>
                        <button
                          style={{ marginLeft: 5 }}
                          disabled={!this.state.enabled}
                          type="submit"
                          class="btn btn-primary buttonAppear"
                        >
                          Save
                        </button>
                      </div>
                    ))}
                </form>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  style={{ background: "#00435D" }}
                  class="btn btn-danger"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="table-responsive">
          <ThemeContext.Consumer>
            {(context) => (
              <table class="table table-hover table-bordered">
                <thead
                  style={{
                    background: context.themeColors.primaryColor,
                    color: "white",
                  }}
                >
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Title</th>
                    <th scope="col">File Name</th>
                    <th scope="col">Description</th>
                    <th scope="col"> Date</th>
                    <th scope="col"> Time</th>
                    <th scope="col">Location</th>
                    <th scope="col">Fees</th>
                    <th scope="col">Link</th>
                    <th scope="col">AccountId</th>
                    <th scope="col">AccountType</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>{renderTodos}</tbody>
              </table>
            )}
          </ThemeContext.Consumer>
        </div>
      </div>
    );
    return (
      <div>
        <div className="page-header">
          <SnackBar msg={this.state.msg} />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div>
              <h2>
                Hr Development&nbsp;Details&nbsp;{this.state.adminData.type}
              </h2>
            </div>
            <nav aria-label="Page navigation example">
              <ul style={{ color: "#01AC8A" }} class="pagination">
                <li
                  class={`page-item ${
                    this.state.disableBPage ? "disabled" : ""
                  }`}
                >
                  <a
                    onClick={this.handleClickNextBack}
                    class="page-link"
                    href="#"
                  >
                    {this.state.disableBPage ? (
                      <i
                        style={{ color: "grey" }}
                        class="far fa-stop-circle"
                      ></i>
                    ) : (
                      <i class="fas fa-backward"></i>
                    )}
                  </a>
                </li>
                {renderPageNumbers}
                <li
                  class={`page-item ${
                    this.state.disablepage ? "disabled" : ""
                  }`}
                >
                  <a
                    onClick={this.handleClickNextFrwd}
                    class="page-link"
                    href="#"
                  >
                    {this.state.disablepage ? (
                      <i
                        style={{ color: "grey" }}
                        class="far fa-stop-circle"
                      ></i>
                    ) : (
                      <i class="fas fa-forward"></i>
                    )}
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div class="row" style={{ justifyContent: "center" }}>
          <div class="col-md-6" style={{ marginTop: 20 }}>
            <div class="md-form mb-0">
              <label for="name" class="">
                Select Type
              </label>
              <select
                onChange={this.inputData}
                class="custom-select"
                name="type"
              >
                <option>Choose Type..</option>
                <option value="Courses">Courses</option>
                <option value="Conference">Conference</option>
                <option value="Seminars">Seminars</option>
                <option value="Training">Training</option>
                <option value="Workshop">Workshop</option>
                <option value="Face To Face">Face Yo Face</option>
                <option value="Online">Online</option>
                <option value="Webinar">Webinar</option>
              </select>
            </div>
          </div>
        </div>
        <div class="text-center text-md-center mt-4">
          <ThemeContext.Consumer>
            {(context) => (
              <button
                type="submit"
                style={{
                  border: "none",
                  fontSize: 20,
                  borderRadius: 4,
                  color: "white",
                  backgroundColor: context.themeColors.primaryColor,
                }}
                onClick={this.onSearchStudent}
              >
                Search
                <span
                  style={{ marginBottom: 5 }}
                  class={`${
                    this.state.btnLoad ? "spinner-border spinner-border-sm" : ""
                  }`}
                ></span>
              </button>
            )}
          </ThemeContext.Consumer>
        </div>
        {this.props.allAdminState.apiFlagEdit ? Loader : MainContent}
      </div>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(getAllAdminActions, dispatch),
    HrDevelopmentActions: bindActionCreators(HrDevelopmentActions, dispatch),
    sharedActions: bindActionCreators(sharedActions, dispatch),
  };
}
function mapStateToProps(state) {
  return {
    AllAdminData: state.setHrDevelopmentReducer.DataEdit,
    allAdminState: state.setHrDevelopmentReducer,
    users: state.setUserReducer.users,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(EditHrDevelopment);
