import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull'
import { months } from 'moment';
import { counter } from '@fortawesome/fontawesome-svg-core';
import moment from 'moment'
import AddCleanlinessCategory from './AddCleanlinessCategory';

import LoaderWrapper from "../../components/HOC/LoaderWrapper";
import ThemeContext from "../../context/themeContext/ThemeContext";





class ViewCyclicCleaness extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: '',
            isLoading: true,
            modalEdit: false,
            editToggle: false,
            generateToggle: false,
            generateIndex: '',
            msg: '',
            editIndex: '',
            type: '',

            floorId: null,
            floorName: null,
            roomId: null,
            roomName: null,
            categoryId: null,
            categoryName: null,
            month: null,
            year: null,
            change: [],
            res: [],
            callit: []
        }




    }
    static contextType = ThemeContext

    componentDidMount() {
        this.getSimple('Dropdown/FloorRoom', 'floorData')
        this.generateMonthAndYear(30)
    }

    searchByType = e => {
        this.getSimple(`Dropdown/FloorRoom/?type=${e.target.value}`, 'floorData')
        this.setState({
            type: e.target.value
        })
    }

    generateMonthAndYear = (limit) => {
        const year = new Date().getFullYear();
        const years = Array.from(new Array(limit), (val, index) => index + year);
        this.setState({
            yearList: years,
            monthList: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
        })
    }

    getSimple = (type, state) => {
        this.props.sharedActions.getDataWithoutDispatch(
            `/api/${type}`
        ).then(success => {
            this.setState({
                [state]: success,
                isLoading: false
            })


        }).catch(error => {
            this.setState({
                msg: 'Error Occured!!',
                isLoading: false
            })
            console.error('Error occured', error);
        })
    }

    getCyclicData = (type, state) => {
        this.props.sharedActions.getDataWithoutDispatch(
            `/api/${type}`
        ).then(success => {

            // let cycData = []
            //     this.state.floorData.category.map(val => {
            //         var fill = success.dateWise.filter(data => {
            //             return val.id == data.categoryId
            //         })
            //     cycData.push(fill)

            //     })


            this.setState({
                [state]: success,
                isLoading: false
            })


        }).catch(error => {
            this.setState({
                msg: error.response ? error.response.data.Message : 'Error Occured!!',
                isLoading: false
            })
            console.error('Error occured', error);
        })
    }



    onEditClick = id => {
        const { cyclicCleaness, change } = this.state
        console.log(change);


        this.setState({
            editIndex: id
        })
        cyclicCleaness.dateWise.map((items, index) => {
            if (items.date == id) {
                this.state.change.push({
                    id: items.cleaness[0].id,
                    checkBox: items.cleaness[0].checkBox == 'Yes' ? true : false,
                    categoryId: items.categoryId,
                    date: items.date
                })
                console.log(change);

            }
        })
        $('#myModal1').modal({
            show: true
        })


    }



    myFunction = () => {
        // Get the snackbar DIV
        var x = document.getElementById('snackbar');

        // StepperGeneralButtons the "show" class to DIV
        x.className = 'show';

        // After 3 seconds, remove the show class from DIV
        setTimeout(function () {
        }, 3000);
    };

    handleSubmit = e => {
        e.preventDefault();
        console.log('Submit Called')

        const { change } = this.state;
        let count = 0
        change.map((data, index) => {
            let modal = {
                checkBox: data.checkBox == true ? 'Yes' : 'No',
            }
            console.log(modal);

            this.props.sharedActions.editRecordWithoutDispatch(
                `/api/CyclicCleaness/`,
                data.id,
                modal
            ).then(success => {
                this.state.res.push(success)
                count = count + 1
                if (count == change.length) {
                    this.changeView()
                    this.setState({
                        isLoading: false
                    })
                }


            }).catch(error => {
                console.log(error);
                this.setState({
                    msg: error.response ? error.response.data.Message : 'Error Occured!!',
                    isLoading: false,
                    change: []
                })
                this.props.snackbar();
            })
        })


        // console.log('data model', data)
        // this.props.sharedActions.editRecordWithoutDispatch(
        //     `/api/CyclicCleaness/`,
        //     data.id,
        //     {
        //         checkBox: data.checkBox
        //     }
        // ).then(success => {
        //     console.log('success', success)
        //     this.state.floorData.splice(this.state.editIndex, 1, data)

        //         $('#myModal1').modal('hide')

        //     this.setState({
        //         msg: 'Record Changed Successfully!',


        //     })
        //     this.props.snackbar();


        // }).catch(error => {
        //     console.error('Error name: ', error)

        //         $('#myModal1').modal('hide')
        //     this.setState({
        //         msg: 'Error Occured!'
        //     })
        //     this.props.snackbar();


        // })
    }

    changeView = () => {
        const { res, cyclicCleaness } = this.state
        let dw = cyclicCleaness.dateWise

        cyclicCleaness.dateWise.map((data, index) => {
            console.log('No', data);
            res.map(val => {
                if (val.id == data.cleaness[0].id) {
                    dw[index].cleaness.length = 0
                    dw[index].cleaness.push(val)
                }
            })


        })
        this.setState({
            cyclicCleaness: { ...this.state.cyclicCleaness, dateWise: dw },
            isLoading: true,
            msg: 'Record Changed Successfully!',

        })


        $('#myModal1').modal('hide')

        this.props.snackbar();



    }

    onDeleteClick = id => {
        const { floorData } = this.state
        let modalData = floorData.filter((items, index) => {
            if (id === items.id) {

                this.setState({
                    editIndex: index
                })
                console.log(index)
                return id === items.id
            }
        })
        console.log(id)
        this.setState({
            modalDataDeleteId: id

        }, () => {
            $('#myModal2').modal({
                show: true
            })
        })
    }

    handleDelete = id => {

        const { editIndex } = this.state;

        this.props.sharedActions.deleteRecordWithoutDispatch(
            '/api/Floors',
            id
        ).then(success => {
            this.state.floorData.splice(editIndex, 1)

            this.setState({
                msg: 'Record Deleted!',
            })
            this.props.snackbar();


        }).catch(error => {

            this.setState({
                msg: error.response ? error.response.data.Message : 'Error Occured!!',
                isLoading: false
            })
            this.props.snackbar();

        })
    }

    onHandleCheckChange = (e, id) => {
        console.log([e.target.name], e.target.checked);
        const changeArrOfObj = this.state.change;
        this.state.change.map((data, index) => {
            console.log(data.id, id);
            if (data.id == id) {
                changeArrOfObj[index].checkBox = e.target.checked
            }
        })
        this.setState({
            change: changeArrOfObj
        })
        console.log(changeArrOfObj, this.state.change);


    }

    btnTechHover = () => {
        $(document).ready(function () {
            $('button').hover(
                function () {
                    $(this).css('box-shadow', '0px 5px 5px grey');
                    $(this).css('border-color', 'white');
                },
                function () {
                    $(this).css('box-shadow', '0px 0px 0px white');
                }
            );
        });
    };

    onHandleText = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
        console.log([e.target.name], e.target.value)
    };

    handleSearch = () => {
        const { floorId, roomId, month, year, type } = this.state
        if (isNull(floorId) || isNull(roomId) || isNull(month) || isNull(year)) {
        } else {

            this.getCyclicData(`CyclicCleaness/?roomId=${roomId}&floorId=${floorId}&month=${month}&year=${year}&type=${type}`, 'cyclicCleaness')

        }
    }


    render() {

        const { search, isLoading, editToggle, editIndex, msg, floorData, monthList, yearList, cyclicCleaness,
            floorId, floorName, roomId, roomName, categoryId, categoryName, month, year, accountId, accountType,
        } = this.state;


        const deleteFloorData = (
            <div>
                <div className="modal" id="myModal2">
                    <div className="modal-dialog">
                        <div className="modal-content modal_content_custom">
                            <div
                                style={{
                                    background: this.context.themeColors.primaryColor,
                                    borderBottomLeftRadius: 30,
                                    borderBottomRightRadius: 30,
                                    borderTopLeftRadius: 18,
                                    borderTopRightRadius: 18
                                }}
                                className="modal-header modal_custom_header"
                            >
                                <h4 style={{ color: 'white' }} className="modal-title">
                                    Confirm
                            </h4>
                                <button
                                    style={{ color: 'white' }}
                                    on
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                >
                                    &times;
                            </button>
                            </div>
                            <div className="modal-body">
                                <div >
                                    <div style={{ textAlign: 'center' }}>
                                        <h4>Are you sure?</h4>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-evenly'
                                        }}
                                    >
                                        <div>
                                            <button
                                                data-dismiss="modal"
                                                onClick={() => this.handleDelete(this.state.modalDataDeleteId)}
                                                type="button"
                                                class="btn btn-primary buttonAppear"
                                            >
                                                Yes
													</button>
                                        </div>
                                        <div>
                                            <button
                                                data-dismiss="modal"
                                                type="button"
                                                class="btn btn-primary buttonAppear"
                                            >
                                                No
													</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button
                                    type="button"
                                    style={{ background: '#00435D' }}
                                    class="btn btn-danger"
                                    onClick={() => this.setState({ editToggle: false })}
                                    data-dismiss="modal"
                                >
                                    Close
								</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )


        const editCyclicCleanessData = (
            <div>
                <div className="modal" id="myModal1">
                    <div className="modal-dialog">
                        <div className="modal-content modal_content_custom">
                            <div
                                style={{
                                    background: this.context.themeColors.primaryColor,
                                    borderBottomLeftRadius: 30,
                                    borderBottomRightRadius: 30,
                                    borderTopLeftRadius: 18,
                                    borderTopRightRadius: 18
                                }}
                                className="modal-header modal_custom_header"
                            >
                                <h4 style={{ color: 'white' }} className="modal-title">
                                    Edit
                            </h4>
                                <button
                                    style={{ color: 'white' }}
                                    on
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                >
                                    &times;
                            </button>
                            </div>
                            <div className="modal-body">
                                <form onSubmit={this.handleSubmit} >
                                    <fieldset disabled={!editToggle}>

                                        {/* <div className="form-group">
                                <label for="accountId">Account Name:</label>
                                <input style={{
                                    borderTop: 0,
                                    borderRight: 0,
                                    borderLeft: 0
                                }}
                                type="text"
                                disabled
                                value={accountId}
                                name="modalDataAccountId"
                                className="form-control"
                                id="accountId"
                                />
                                </div>
                                <div className="form-group">
                                <label for="accountType">Account Type:</label>
                                <input style={{
                                    borderTop: 0,
                                    borderRight: 0,
                                    borderLeft: 0
                                }}
                                type="text"
                                disabled
                                value={accountType}
                                name="modalDataAccountType"
                                className="form-control"
                                id="accountType"
                                />
                                </div> */}
                                        {cyclicCleaness && cyclicCleaness.dateWise.map((data, index) => {


                                            return data.date == editIndex && <div className="my-2 custom-control custom-checkbox form group" >
                                                <input
                                                    type="checkbox"
                                                    class="custom-control-input"
                                                    id={`checkBox${index}`}
                                                    name='checkBox'
                                                    defaultChecked={data.cleaness[0].checkBox == 'Yes' ? true : false}
                                                    onChange={e => this.onHandleCheckChange(e, data.cleaness[0].id)}
                                                />
                                                <label class="custom-control-label" for={`checkBox${index}`}>{data.categoryName}</label>
                                            </div>

                                        })}

                                    </fieldset>
                                    <br />
                                    <br />
                                    <button
                                        disabled={editToggle}
                                        onClick={() => this.setState({ editToggle: true })}
                                        type="button"
                                        class="btn btn-primary buttonAppear"
                                    >
                                        Edit
									</button>
                                    <button
                                        style={{ marginLeft: 5 }}
                                        disabled={!editToggle}
                                        type="submit"
                                        class="btn btn-primary buttonAppear"
                                    >
                                        Save
									</button>
                                </form>
                            </div>
                            <div class="modal-footer">
                                <button
                                    type="button"
                                    style={{ background: '#00435D' }}
                                    class="btn btn-danger"
                                    onClick={() => this.setState({ editToggle: false })}
                                    data-dismiss="modal"
                                >
                                    Close
								</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )

        const Loader = (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div className="spinner-border" style={{ color: this.context.themeColors.primaryColor }}></div>
            </div>
        );

        const renderCyclicCleanessData = cyclicCleaness && cyclicCleaness.datelist.map((item, index) => {
            return (
                moment(new Date).format(`MM/DD/YYYY`) >= item && <tr key={index} >
                    <th scope="row" className="bordersSpace" style={{ padding: 1 }}  >
                        {item}
                    </th>
                    {floorData.category.map((data, index) => {
                        var fill = cyclicCleaness.dateWise.filter(val => {
                            return data.id == val.categoryId
                        })
                        console.log('fill', fill);


                        return fill.length == 1 && item == fill[0].date && <td className="borderSpace" style={{ padding: 1 }} >
                            {fill[0].cleaness[0].checkBox == 'Yes' ? <span style={{ color: 'green' }} class="fas fa-check"></span> : <span style={{ color: 'red' }} class="fas fa-times"></span>}
                        </td>

                    })}


                    {cyclicCleaness.dateWise.length > 0 && floorData && floorData.category.map(data => {
                        var fill = cyclicCleaness.dateWise.filter(val => {
                            return data.id == val.categoryId
                        })

                        console.log('Table Heading', fill);

                        return (
                            fill.length > 0 && item != fill[0].date && <td className="borderSpace" style={{ padding: 1 }} ></td>
                        );
                    })}
                    <td className="borderSpace" style={{ padding: 1 }}>
                        <a href="#" data-toggle="tooltip" title="Edit!">

                            <button
                                id="firstbutton"
                                onClick={() => this.onEditClick(item)}
                                onMouseOver={this.btnTechHover()}
                                style={{
                                    cursor: 'pointer',
                                    fontSize: 20,
                                    border: 'none',
                                    marginLeft: 5,
                                    background: 'none',
                                    marginBottom: 5
                                }}
                            >
                                <i className="fas fa-pen"></i>
                            </button>
                        </a>
                    </td>

                </tr>
            );
        })

        const renderTableheading = cyclicCleaness && floorData && floorData.category.map((data, index) => {
            var fill = cyclicCleaness.dateWise.filter(val => {
                return data.id == val.categoryId
            })

            console.log('Table Heading', fill);

            return (
                fill.length > 0 && <th scope="col" key={fill[0].categoryId} style={{ padding: 1 }}>
                    {fill[0].categoryName}
                </th>
            );
        })

        return (
            <div className="page-header">
                <SnackBar msg={msg} />
                <div style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                }}>
                    <div class="row">

                        <div class="col-md-6" >
                            <h2
                                style={{ background: this.context.themeColors.primaryColor }}
                                className="h1-responsive font-weight-bold text-center  generalHead">
                                View Cyclic Cleanliness
                    </h2>
                        </div>
                    </div>
                    <div class="row my-3 mx-5 py-4 justify-content-center" style={{
                        border: `2px solid ${this.context.themeColors.primaryColor}`,
                        borderRadius: 20
                    }} >
                        <div className="col-md-3">
                            <select class="custom-select"
                                value={this.state.type}
                                name="type"
                                onChange={this.searchByType}

                            >
                                <option value="">Please Select Type</option>
                                <option value="Daily">Daily</option>
                                <option value="Weekly">Weekly</option>
                                <option value="Monthly">Monthly</option>
                                <option value="Quarterly">Quarterly</option>
                                <option value="HalfYearly">HalfYearly</option>
                                <option value="Annually">Annually</option>
                            </select>
                        </div>
                        <div class="col-md-3" >
                            <select class="custom-select"
                                value={floorId}
                                name="floorId"
                                onChange={this.onHandleText}
                            >
                                <option value={null}>Please Select Floor</option>
                                {floorData && floorData.floors.map((value, index) => {

                                    return <option value={value.id}>{value.floorName}</option>
                                })}
                            </select>
                        </div>
                        <div class="col-md-3" >
                            <select class="custom-select"
                                value={roomId}
                                name="roomId"
                                onChange={this.onHandleText}
                                disabled={!floorId}
                            >
                                <option value={null}>Please Select Room</option>
                                {floorId && floorData.rooms.map((value, index) => {

                                    return value.floorId == floorId &&
                                        <option value={value.id}>{value.roomName}</option>
                                })}
                            </select>
                        </div>
                        <div class="col-md-3" >
                            <select class="custom-select"
                                value={month}
                                name="month"
                                onChange={this.onHandleText}
                                disabled={!roomId}
                            >
                                <option value={null}>Please Select Month</option>
                                {monthList && monthList.map((value, index) => {

                                    return <option value={value}>{value}</option>
                                })}
                            </select>
                        </div>
                        <div class="col-md-3 mt-2" >
                            <select class="custom-select"
                                value={year}
                                name="year"
                                onChange={this.onHandleText}
                                disabled={!month}
                            >
                                <option value={null}>Please Select Year</option>
                                {yearList && yearList.map((value, index) => {

                                    return <option value={value}>{value}</option>
                                })}
                            </select>
                        </div>

                    </div>
                    <div class="row my-2">
                        <div class="col-md-12  text-center" >
                            <button
                                class="btn btn-primary buttonAppear"
                                onClick={this.handleSearch}
                                style={{ marginRight: '2%' }}
                            >
                                <i class="fa fa-search"></i> Search
									</button>
                            <Link to={{
                                pathname: "PrintCyclicCleanliness",
                                state: {
                                    cyclicCleaness: cyclicCleaness,
                                    floorData: floorData
                                }
                            }}  >
                                <a href="#" data-toggle="tooltip" title="Edit!">

                                    <button
                                        class="btn btn-primary buttonAppear"
                                        disabled={!cyclicCleaness}
                                    >

                                        <i className="fas fa-list"></i> View
							</button>
                                </a>

                            </Link>
                        </div>

                    </div>
                    <div class="row my-2 mx-2" style={{ paddingLeft: '20px' }} >
                        <div class="col-md-3" >
                            <h5>Floor: <strong style={{ color: this.context.themeColors.primaryColor }} >{cyclicCleaness && cyclicCleaness.info.floorName}</strong></h5>
                        </div>
                        <div class="col-md-3" >
                            <h5>Room: <strong style={{ color: this.context.themeColors.primaryColor }} >{cyclicCleaness && cyclicCleaness.info.roomName}</strong></h5>
                        </div>
                        <div class="col-md-3" >
                            <h5>Month: <strong style={{ color: this.context.themeColors.primaryColor }} >{cyclicCleaness && monthList[cyclicCleaness.datelist[0].split("/")[0] - 1]}</strong></h5>
                        </div>
                        <div class="col-md-3 "  >
                            <h5>Year: <strong style={{ color: this.context.themeColors.primaryColor }} >{cyclicCleaness && cyclicCleaness.datelist[0].split("/")[2]}</strong></h5>
                        </div>
                    </div>

                    <div className="table-responsive">
                        <table className="table table-hover text-center table-bordered">
                            <thead style={{ background: this.context.themeColors.primaryColor, color: 'white' }}>
                                <tr>
                                    <th scope="col" style={{ padding: 1 }}>
                                        Date
								</th>
                                    {renderTableheading}
                                    <th scope="col" style={{ padding: 1 }}>
                                        Actions
                                </th>
                                </tr>
                            </thead>
                            <tbody>{isLoading ? Loader : renderCyclicCleanessData}</tbody>
                        </table>
                    </div>
                </div>
                {editCyclicCleanessData}
                {/* {deleteFloorData} */}
            </div>
        );
    }

}

function mapDispatchToProps(dispatch) {
    return {
        sharedActions: bindActionCreators(sharedActions, dispatch),
    };
}
function mapStateToProps(state) {
    return {
        userData: state.setUserReducer.users,
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewCyclicCleaness);
