import * as actionTypes from '../Actions/actionTypes';

const defaultState = {
	apiFlag: true,
	Data: null
};

export default function setClassSectionGradeReducer(
	state = defaultState,
	action = {}
) {
	switch (action.type) {
		case actionTypes.GET_CLASS_SECTION_GRADE:
			return {
				...state,
				Data: { ...action.payload }
			};

		case actionTypes.SET_LOADER_SECTION_GRADE:
			return { ...state, apiFlag: action.payload };

		default:
			return state;
	}
}
