import React from 'react';
import $ from 'jquery'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import Colors from '../../utils/app_constants/colors_constants';
import Spinner from '../../components/Spinner/Spinner';
import ThemeContext from "../../context/themeContext/ThemeContext";

class IndentFormTemplate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            indentItems: [],
            indentData: {},
            indentForms: {},
            isLoading: true
        }
    }

    componentDidMount() {
        const { match } = this.props;
        $(document).ready(function () {
            $('[data-toggle="tooltip"]').tooltip();
        });
        this.props.sharedActions
            .getDataWithoutDispatch(
                `/api/IndentForm/${match.params.id}`
            )
            .then(success => {
                console.log(success);
                this.setState({
                    indentData: success,
                    indentForms: success.IndentForms,
                    indentItems: success.IndentItems,
                    isLoading: false
                })
            })
            .catch(error => {
                this.setState({
                    isLoading: false
                });
                console.log(error);
            });
    }

    PrintContent = el => {
        var restorepage = document.body.innerHTML;
        var Printcontent = document.getElementById(el).innerHTML;
        document.body.innerHTML = Printcontent;
        
        setTimeout(() => {
            window.print();
            window.location.reload();
        }, 700)
        document.body.innerHTML = restorepage;
    };

    render() {
        const { indentItems, indentForms, isLoading } = this.state;

        const renderItems = (
            indentItems.map((item, index) =>
            <ThemeContext.Consumer>
            {(context) => (
                <tr style={{
                    border: `1px solid `,
                    padding: 2,
                    borderColor: context.themeColors.primaryColor
                }}>
                    <th width='5%' style={{ border: `1px solid `, borderColor:  context.themeColors.primaryColor}}>{index + 1}</th>
                    <td width='40%' style={{ border: `1px solid `, borderColor: context.themeColors.primaryColor }}>{item.product}</td>
                    <td style={{ border: `1px solid `, borderColor:  context.themeColors.primaryColor}}>{item.quantity}</td>
                    <td style={{ border: `1px solid `, borderColor: context.themeColors.primaryColor }}>{item.unit}</td>
                    <td style={{ border: `1px solid `, borderColor: context.themeColors.primaryColor }}>{item.expectedDate}</td>
                    <td width='15%' style={{ border: `1px solid `, borderColor: context.themeColors.primaryColor }}>{item.remarks}</td>
                </tr>
            )}
             </ThemeContext.Consumer>
            ))

        const voucher = (
            <section class='mb-4'>
                <div class="divclass">
                    <img src={require('../../Assets/images/3 headers-03.png')} />
                </div>
                <ThemeContext.Consumer>
            {(context) => (
                <div class='row text-center' style={{ flexGrow: 1, borderBottom: 5, borderRight: 0, borderLeft: 0, borderStyle: 'solid', borderColor: context.themeColors.primaryColor, margin: '5px' }}>
                    <div class='col-md-6'>
                        {/* <input
                            type='image'
                            src={require('../../Assets/images/logo1.png')}
                            class="rounded mx-auto d-block"
                            style={{ width: 350, height: 250 }}
                            alt='school-smart'
                        /> */}
                        <div>
                            <b>Address: </b>
                        </div>
                        <div>
                            <b>City State Zip Code </b>
                        </div>
                        <div>
                            <b>Telephone: </b>
                        </div>
                        <div>
                            <b>Email: </b>
                        </div>
                    </div>
                    <div class='col-md-6 print'>
                        <h2 style={{
                            backgroundColor: context.themeColors.primaryColor,
                            textAlign: 'center',
                            color: 'white'
                        }}
                            className="h1-responsive font-weight-bold text-center my-4 generalHead">
                            {' '}
                            MATERIAL INDENT{' '}
                        </h2>
                    </div>

                </div>
                   )}
                     </ThemeContext.Consumer>
                <div style={{ marginTop: 30 }}>
                <ThemeContext.Consumer>
            {(context) => (
                    <div className="table-responsive">
                        <table class="table table-hover" style={{ textAlign: 'center' }}>
                            <thead class='headCol borderSpace' style={{   background: context.themeColors.primaryColor,
                      color: "white", textAlign: 'center' }}>
                                <tr class="vendorListHeading">
                                    <th scope="col" style={{
                                        border: `1px solid`,
                                        borderColor: context.themeColors.primaryColor
                                    }}
                                    >#</th>
                                    <th scope="col" style={{
                                        border: '1px solid',
                                        borderColor: context.themeColors.primaryColor
                                    }}
                                    >Product/Material</th>
                                    <th scope="col" style={{
                                        border: `1px solid`,
                                        borderColor: context.themeColors.primaryColor
                                    }}
                                    > QTY</th>
                                    <th scope="col" style={{
                                        border: `1px solid`,
                                        borderColor: context.themeColors.primaryColor
                                    }}
                                    >Unit</th>
                                    <th scope="col" style={{
                                        border: `1px solid `,
                                        borderColor: context.themeColors.primaryColor
                                    }}
                                    >Expected Date</th>
                                    <th scope="col" style={{
                                        border: `1px solid`,
                                        borderColor: context.themeColors.primaryColor
                                    }}
                                    >Remarks</th>
                                </tr>
                            </thead>
                            <tbody>
                                {renderItems}
                            </tbody>
                        </table>
                    </div>
                     )}
                     </ThemeContext.Consumer>
                </div>

                <div style={{ marginTop: 30 }}>
                <ThemeContext.Consumer>
            {(context) => (
                    <div className="table-responsive">
                        <table class="table table-hover" style={{ textAlign: 'center' }} >
                            <thead class='headCol borderSpace' style={{ background: context.themeColors.primaryColor, color:'white', textAlign: 'center' }}>
                                <tr class="vendorListHeading">
                                    <th colspan='6' scope="col" style={{
                                        border: `1px solid ${context.themeColors.primaryColor}`,
                                        borderColor: context.themeColors.primaryColor,
                                        fontSize: 20
                                    }}
                                    >REQUIRED FOR / PURPOSE OF INDENT</th>
                                </tr>
                            </thead>
                            <tbody >
                                <tr style={{
                                    border: `1px solid`,
                                    padding: 2,
                                    borderColor: context.themeColors.primaryColor
                                }}>
                                    <td colspan='6' style={{ height: '200px', border: `1px solid`, borderColor: context.themeColors.primaryColor }}>{indentForms.purpose}</td>
                                </tr>
                                <tr style={{
                                    border: `1px solid`,
                                    padding: 2,
                                    borderColor: context.themeColors.primaryColor, textAlign: 'center'
                                }}>
                                    <td width='50%' colspan='3' style={{ border: `1px solid`, borderColor:  context.themeColors.primaryColor }}>ISSUED BY</td>
                                    <td width='50%' colspan='3' style={{ border: `1px solid`, borderColor: context.themeColors.primaryColor }}>APPROVED BY</td>
                                </tr>
                                <tr style={{
                                    border: `1px solid`,
                                    padding: 2,
                                    borderColor: context.themeColors.primaryColor, textAlign: 'center'
                                }}>
                                    <td width='50%' colspan='3' style={{ border: `1px solid`, borderColor: context.themeColors.primaryColor }}>{indentForms.accountId}</td>
                                    <td width='50%' colspan='3' style={{ border: `1px solid `, borderColor: context.themeColors.primaryColor }}>{indentForms.approvedBy}</td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                                         )}
                                         </ThemeContext.Consumer>
                </div>

            </section>

        )
        return (
            <div>
                <div id='div1'>
                    <div class='container'>
                        {isLoading ? <Spinner /> : voucher}
                    </div>
                    <div id="footer">
                        <div class="h1-responsive font-weight-bold text-center my-4">
                            <img
                                src={require('../../Assets/images/Powerd-01.svg')}
                                style={{ width: 200 }}
                            />
                        </div>
                    </div>
                </div>

                <div class="text-center text-md-right">
                    <label for="name" class="" onClick={() => this.PrintContent('div1')}>
                        <i
                            className="fas fa-print fa-2x animated fadeIn"
                            style={{ marginRight: 4 }}
                        />
                        Print
                            </label>
                </div>

            </div>

        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        sharedActions: bindActionCreators(sharedActions, dispatch)
    };
}

export default connect(null, mapDispatchToProps)(IndentFormTemplate);