import React, { Component, useState } from "react";
import { ViewDataTable } from "../../../components/Edit";
import axios from "axios";
import config from "../../../config/config";
import $ from "jquery";
import { utilsTTActions } from "../../../utils";
import SnackBar from "../../../components/SnackBar/SnackBar";
import http from "../../../hooks/http";

const daysForEdit = [
  { day: "Monday", off: false },
  { day: "Tuesday", off: false },
  { day: "Wednesday", off: false },
  { day: "Thursday", off: true },
  { day: "Friday", off: true },
  { day: "Saturday", off: true },
  { day: "Sunday", off: true },
];
const TimeTableView = (props) => {
  const [errors, setErrors] = useState(null);
  const [allStaffData, setAllStaffData] = useState(null);
  const [newStructPeriodData, setNewPeriodStructData] = useState(null);
  const [editHorizonData, setEditHorizonData] = useState([]);
  const [editPeriodData, setEditPeriodData] = useState([]);
  const [subAndTechData, setSubAndTechData] = useState([]);
  const [isNewLoading, setNewLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const [headerDetails, setHeaderDetails] = useState(null);
  const [deletId, setDeleteId] = useState(null);
  const [isLoading, fetchData, setFetchData] = http("/api/timetables", []);
  const [periods, setPeriodsData] = useState([]);
  const [horizontalData, setHorizontal] = useState([]);
  const [techNames, setTeachNames] = useState([]);
  const [workingDays, setDays] = useState([
    { day: "Monday", off: true },
    { day: "Tuesday", off: true },
    { day: "Wednesday", off: true },
    { day: "Thursday", off: true },
    { day: "Friday", off: true },
    { day: "Saturday", off: true },
    { day: "Sunday", off: true },
  ]);

  const [locationKeys, setLocationKeys] = useState([]);

  const [periodBoxData, setPeriodBoxData] = useState([]);

  const onChangeDynamic = (e, index, day) => {
    const NewStructurePeriod = JSON.parse(JSON.stringify(editPeriodData));
    console.log(NewStructurePeriod);

    NewStructurePeriod[day][index][e.target.name] = e.target.value;

    if (e.target.name === "subjectId") {
      let getStaffData = allStaffData.filter(
        (item) => item.subjectId == parseInt(e.target.value)
      );
      console.log(getStaffData);
      try {
        let assignStaff = [...getStaffData[0].assignStaff];

        if (assignStaff.length > 0) {
          NewStructurePeriod[day][index]["staffDropDown"][0][
            "assignStaff"
          ] = assignStaff;

          console.log(NewStructurePeriod);
        }
      } catch (error) {
        console.log(error);
      }
    }

    console.log(NewStructurePeriod);

    setEditPeriodData(NewStructurePeriod); // ??

    if (e.target.name === "subjectId") {
      let $select = $(`#${"staffId"}_${day}_${index}_post`);

      $select.val("Choose Staff");
    }
  };

  const onEditSubmit = (event) => {
    let {
      Monday,
      Tuesday,
      Wednesday,
      Thursday,
      Friday,
      Saturday,
      Sunday,
    } = editPeriodData;

    const Data = {
      timetables: { ...headerDetails },
      periods: [
        ...Monday,
        ...Tuesday,
        ...Wednesday,
        ...Thursday,
        ...Friday,
        ...Saturday,
        ...Sunday,
      ],
    };
    let token = "";
    if (localStorage.jwtToken) token = localStorage.getItem("jwtToken");
    axios
      .put(`${config.localhttp}/api/periods/${headerDetails.Id}`, Data, {
        headers: { Authorization: `Bearer  ${token}` },
      })
      .then((success) => {
        console.log(success);

        setMsg("Update Successfully!");
        $("#ttEditModal").modal("hide");
        props.snackbar();
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 400) {
          if (
            error.response &&
            error.response.data &&
            error.response.data &&
            error.response.data.Message
          ) {
            let newErrors = { ...errors };
            newErrors.responseError = error.response.data.Message;
            setErrors(newErrors);
          }
        }
      });
  };

  const modifyDays = (daysClone, activeDays) => {
    let arr = [];
    let found = false;
    for (let i = 0; i < daysClone.length; i++) {
      for (let j = 0; j < activeDays.length; j++) {
        if (daysClone[i].day === activeDays[j].dayName) {
          arr.push({ ...daysClone[i], off: false });
          found = true;
          break;
        } else {
          found = false;
        }
      }
      if (!found) {
        arr.push({ ...daysClone[i] });
      }
    }
    return arr;
  };
  const EditClick = (obj) => {
    let token = "";
    if (localStorage.jwtToken) token = localStorage.getItem("jwtToken");
    setNewLoading(true);
    debugger;
    Promise.all([
      axios.get(
        `${config.localhttp}/api/AssignSubjectsToTeacher?classId=${obj.ClassID}&gradeId=${obj.GradeID}&sectionId=${obj.SectionID}`,
        { headers: { Authorization: `Bearer  ${token}` } }
      ),
      axios.get(`${config.localhttp}/api/timetables/${obj.Id}`, {
        headers: { Authorization: `Bearer  ${token}` },
      }),
      axios.get(
        `${config.localhttp}/api/ConsolidatedDropdown?classId=${obj.ClassID}&sectionId=${obj.SectionID}&gradeId=${obj.GradeID}`,
        { headers: { Authorization: `Bearer  ${token}` } }
      ),
    ])
      .then((success) => {
        debugger;
        // if (success.status === 200) {
        console.log("sub and teach data: ", success);
        setDays([
          { day: "Monday", off: true },
          { day: "Tuesday", off: true },
          { day: "Wednesday", off: true },
          { day: "Thursday", off: true },
          { day: "Friday", off: true },
          { day: "Saturday", off: true },
          { day: "Sunday", off: true },
        ]);
        console.log("succes of time table data: ", success);
        const getNewWorkingDays = modifyDays(
          [
            { day: "Monday", off: true },
            { day: "Tuesday", off: true },
            { day: "Wednesday", off: true },
            { day: "Thursday", off: true },
            { day: "Friday", off: true },
            { day: "Saturday", off: true },
            { day: "Sunday", off: true },
          ],
          success[1].data.list.activeDays
        );

        console.log("new data of period: ", getNewWorkingDays);
        setDays(getNewWorkingDays);
        let getNewPeriodStructure = getNewWorkingDays.map((item, index) => {
          return {
            ...utilsTTActions.changeDataStructure(
              success[1].data.list.periods,
              item.day
            ),
          };
        });

        setSubAndTechData(success[0].data);

        setNewPeriodStructData({ ...getNewPeriodStructure[6] });

        setAllStaffData(success[2].data.dropdown);

        let getData = utilsTTActions.changeEditDataStruct(
          [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
            "Sunday",
          ],
          success[2].data.dropdown,
          { ...getNewPeriodStructure[6] }
        );

        // let getnew = { ...getNewPeriodStructure[6] };
        setEditPeriodData(getData);

        setHeaderDetails(success[1].data.timeTables[0]);

        let sDate = new Date(
          new Date(
            `${success[1].data.timeTables[0].date} ${success[1].data.timeTables[0].startTime}`
          )
        );
        let sTryDate = new Date(
          new Date(
            `${success[1].data.timeTables[0].date} ${success[1].data.timeTables[0].startTime}`
          )
        );
        // let getSDate= props.stateData.offSDate;
        let eDate = null;
        let contain = null;
        let getupdatedResult = success[1].data.list.horizontalData.map(
          (item, index) => {
            if (index === 0) {
              contain += parseInt(item.periodDuration);
              eDate = new Date(sDate).getTime() + parseInt(item.periodDuration);

              return {
                ...item,
                dispTime:
                  new Date(sDate).toLocaleTimeString() +
                  " to " +
                  new Date(
                    new Date(sDate).getTime() + parseInt(item.periodDuration)
                  ).toLocaleTimeString(),
              };
            } else {
              contain += parseInt(item.periodDuration);

              sDate = new Date(sTryDate).getTime() + contain;
              let disSDateVar = new Date(new Date(eDate)).toLocaleTimeString();
              let disEDateVar = new Date(new Date(sDate)).toLocaleTimeString();

              eDate = new Date(sTryDate).getTime() + contain;
              return { ...item, dispTime: disSDateVar + " to " + disEDateVar };
            }
          }
        );
        setEditHorizonData(getupdatedResult);
        debugger;
        $("#ttEditModal").modal();
        setNewLoading(false);
        // }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const removeElement = () => {
    let getDeleteId = deletId;
    let token = "";
    if (localStorage.jwtToken) token = localStorage.getItem("jwtToken");
    axios
      .delete(`${config.localhttp}/api/timetables/${getDeleteId}`, {
        data: { getDeleteId }, // or data: jdId, depending on how you handle it in the back end
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((success) => {
        if (success.status === 200) {
          let getNewFilterArray = fetchData.filter(
            (item) => item.Id !== getDeleteId
          );
          setMsg("Delete Successfully!");
          $("#ttDeleteCon").modal("hide");
          setFetchData(getNewFilterArray);
          props.snackbar();
          setDeleteId(null);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const DeleteClick = (obj) => {
    $("#ttDeleteCon").modal();
    setDeleteId(obj.Id);
  };
  const ViewClick = (obj) => {
    console.log(obj);
    debugger;
    let token = "";
    if (localStorage.jwtToken) token = localStorage.getItem("jwtToken");
    setNewLoading(true);
    axios
      .get(`${config.localhttp}/api/timetables?id=${obj.Id}`, {
        headers: { Authorization: `Bearer  ${token}` },
      })
      .then((success) => {
        setDays([
          { day: "Sunday", off: true },
          { day: "Monday", off: true },
          { day: "Tuesday", off: true },
          { day: "Wednesday", off: true },
          { day: "Thursday", off: true },
          { day: "Friday", off: true },
          { day: "Saturday", off: true },
        ]);
        console.log("succes of time table data: ", success);
        const getNewWorkingDays = modifyDays(
          [
            { day: "Sunday", off: true },
            { day: "Monday", off: true },
            { day: "Tuesday", off: true },
            { day: "Wednesday", off: true },
            { day: "Thursday", off: true },
            { day: "Friday", off: true },
            { day: "Saturday", off: true },
          ],
          success.data.list.activeDays
        );

        console.log("new data of period: ", getNewWorkingDays);
        setDays(getNewWorkingDays);
        setHeaderDetails(success.data.timeTables[0]);
        setTeachNames(success.data.list.Names);
        setHorizontal(success.data.list.horizontalData);
        setPeriodsData(success.data.list.periods);
        let sDate = new Date(
          new Date(
            `${success.data.timeTables[0].date} ${success.data.timeTables[0].startTime}`
          )
        );
        let sTryDate = new Date(
          new Date(
            `${success.data.timeTables[0].date} ${success.data.timeTables[0].startTime}`
          )
        );
        // let getSDate= props.stateData.offSDate;
        let eDate = null;
        let contain = null;
        let getupdatedResult = success.data.list.horizontalData.map(
          (item, index) => {
            if (index === 0) {
              contain += parseInt(item.periodDuration);
              eDate = new Date(sDate).getTime() + parseInt(item.periodDuration);

              return {
                ...item,
                dispTime:
                  new Date(sDate).toLocaleTimeString() +
                  " to " +
                  new Date(
                    new Date(sDate).getTime() + parseInt(item.periodDuration)
                  ).toLocaleTimeString(),
              };
            } else {
              contain += parseInt(item.periodDuration);

              sDate = new Date(sTryDate).getTime() + contain;
              let disSDateVar = new Date(new Date(eDate)).toLocaleTimeString();
              let disEDateVar = new Date(new Date(sDate)).toLocaleTimeString();

              eDate = new Date(sTryDate).getTime() + contain;
              return { ...item, dispTime: disSDateVar + " to " + disEDateVar };
            }
          }
        );
        setPeriodBoxData(getupdatedResult);
        setNewLoading(false);

        $("#ttViewModal").modal();
      })
      .catch((error) => {});
  };

  return (
    <div>
      <SnackBar msg={msg} />
      {isNewLoading ? <p>Loading....</p> : ""}
      <p style={{ fontSize: 40 }}>Time Table Details </p>
      <ViewDataTable
        onEditSubmit={onEditSubmit}
        onChangeDynamic={onChangeDynamic}
        subAndTechData={subAndTechData}
        eidtHorizonData={editHorizonData}
        daysForEdit={workingDays}
        EditClick={EditClick}
        ViewClick={ViewClick}
        DeleteClick={DeleteClick}
        headerDetails={headerDetails}
        removeElement={removeElement}
        techNames={techNames}
        horizontalData={horizontalData}
        workingDays={workingDays}
        editPeriodData={editPeriodData}
        periodBoxData={periodBoxData}
        periods={periods}
        Data={fetchData}
        Loading={isLoading}
        errors={errors}
        actionsName={["ttView", "ttDelete", "ttEdit"]}
        actionFieldName="ttAction"
        Actions={[
          {
            bottomCloseButtonName: "Discard Changes",
            bottomSaveButtonName: "Update",
            header: true,
            ttAction: "ttEdit",
            tooltipTitle: "Edit!",
            btnId: "ttEdit",
            fullModal: "modal-full",
            type: "",
            iconName: "fa-pen",
            mdId: "ttEditModal",
            height: 900,
            modalTitle: "Edit Time Table",
            modalBody: "edit body",
            modalSize: "modal-xl",
            handleSubmit: () => onEditSubmit(),
          },
          {
            header: true,
            bottomCloseButtonName: "",
            bottomSaveButtonName: "",

            ttAction: "ttDelete",
            tooltipTitle: "Delete!",
            fullModal: "",
            height: 100,
            btnId: "ttDelete",
            iconName: "fa-trash-alt",
            color: "#D11A2A",
            mdId: "ttDeleteCon",
            modalTitle: "Confirmation",
            type: "reuse",
            modalBody: "body",
            modalSize: "modal-sm",
            handleSubmit: () => DeleteClick(),
          },
          {
            bottomCloseButtonName: "",
            bottomSaveButtonName: "",

            header: false,
            tooltipTitle: "View!",
            ttAction: "ttView",
            type: "reuse",
            fullModal: "modal-full",
            height: 900,
            btnId: "ttView",
            iconName: "fa-list",
            color: "#01AC8A",
            mdId: "ttViewModal",
            modalTitle: "Time Table",
            modalBody: "view body",
            modalSize: "modal-xl",
            handleSubmit: () => ViewClick(),
          },
        ]}
        renderFields={["Class", "Grade", "Section", "accountId", "accountType"]}
        tHeadData={[
          "Class Name",
          "Grade Name",
          "Section",
          "Account ID",
          "Account Type",
          "Actions",
        ]}
      />
    </div>
  );
};

export default TimeTableView;
