import * as actionTypes from '../Actions/actionTypes';

const defaultState = {
	apiFlag: true,
	Data2: [],
	Data: [],
	selectStudent: [],
	selectSession: [],
	selectStaff: [],
	select: []
};

export default function setUserWiseReducer(state = defaultState, action = {}) {
	switch (action.type) {
		// case actionTypes.ADD_STOCK_USAGE:
		//
		//     return { ...state };

		// case actionTypes.SET_ALL_ITEMS_NAME_INTO_ADD_STOCK_USAGE_SELECT:
		//
		//     return {
		//         ...state,
		//         select: [...action.payload]
		//     };
		case actionTypes.SET_ALL_STUDENT_NAME_INTO_USERWISE_SELECT:
			return {
				...state,
				selectStudent: action.payload
			};
		case actionTypes.SET_ALL_SESSIONS_INTO_USERWISE_SELECT:
			return {
				...state,
				selectSession: action.payload
			};
		// case actionTypes.SET_ALL_STAFF_NAME_INTO_ADD_STOCK_USAGE_SELECT:
		//
		//     return {
		//         ...state,
		//         selectStaff: action.payload
		//     };
		case actionTypes.GET_ALL_USERWISE_SEARCH:
			return {
				...state,
				Data: action.payload
			};
		// case actionTypes.GET_PARTIAL_STOCK_USAGE:
		//
		//     return {
		//         ...state,
		//         Data2: [...action.payload]
		//     };
		case actionTypes.SET_LOADER_FLAG_USERWISE_ADD:
			return {
				...state,
				apiFlag: action.payload
			};
		// case actionTypes.DELETE_STOCK_USAGE:
		//
		//     let filterCandidateReqList = state.Data.filter(
		//         filterOBJ => filterOBJ.id !== action.payload
		//     );
		//
		//     return {
		//         ...state,
		//         Data: filterCandidateReqList
		//     };
		// case actionTypes.EDIT_STOCK_USAGE_RECORD:
		//     var adminData = [...state.Data];
		//     var index = adminData.findIndex(obj => obj.id === action.payload.id);
		//
		//     adminData[index].name = action.payload.name;
		//     adminData[index].price = action.payload.price;
		//     adminData[index].type = action.payload.type;
		//     adminData[index].t1.name = action.payload.supplierName;
		//     adminData[index].t3.name = action.payload.categoryName;
		//     return {
		//         ...state,
		//         Data: [...adminData]
		//     };
		default:
			return state;
	}
}
