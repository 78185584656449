import React, { Component } from 'react';

const RenderAddFormData = props => {
	const { itemObj, Icons, sectionType, indVal } = props;

	let siblingDetails = {
		'Sibling Name: ': itemObj.siblingName,
		'Age :': itemObj.age,
		'Relation: ': itemObj.relation,
		'Education:': itemObj.education,
		'School :': itemObj.school
	};
	let prevSchoolDetails = {
		'Previous School Name: ': itemObj.previousSchoolName,
		'Enroll No :': itemObj.enrollNo,
		'From Year :': itemObj.fromYear,
		'Year Upto :': itemObj.yearUpto,
		'Reason Leaving :': itemObj.reasonLeaving
	};
	let studentTrans = {
		'By Walk :': itemObj[0].byWalk,
		'Driver Id Card Copy :': itemObj[0].driverIdCardCopy,
		'Driver Id Card No :': itemObj[0].driverIdCardNo,
		'Driver Mobile No :': itemObj[0].driverMobileNo,
		'Parent Pick Up :': itemObj[0].parentPickUp,
		'School Bus :': itemObj[0].schoolBus,
		'Transport Type :': itemObj[0].transportType,
		'Vehicle Number:': itemObj[0].vehicleNo
	};
	let otherInfos = {
		'Other Activity :': itemObj[0].otherActivity,
		'Sports :': itemObj[0].sports
	};
	let widthDraw = {
		'Class Left :': itemObj[0].classLeft,
		'Date Leaving: ': itemObj[0].dateLeaving,
		'Reason :': itemObj[0].reason
	};
	let medicalCon = {
		'Need Medication :': itemObj[0].needMedication,
		'Other Medication :': itemObj[0].otherMedication,
		'Threat Condition :': itemObj[0].threatCondition
	};
	let parentDoc = {
		'Father Passport :': itemObj[0].passportFather,
		'Passport Mother :': itemObj[0].passportMother,
		'Father Id Card :': itemObj[0].idCardFather,
		'Mother Id Card: ': itemObj[0].idCardMother
	};
	let studentDoc = {
		'Date Of Birth Certficate:': itemObj[0].dobCertificate,
		'Transfer Certificate :': itemObj[0].transferCertificate,
		'Medical History:': itemObj[0].medicalHistory,
		'Ministry Vacination:': itemObj[0].ministryVacination,
		'Passport Copy:': itemObj[0].passportCopy,
		'ID Card Copy:': itemObj[0].idCardCopy,
		'Last Result :': itemObj[0].lastResult,
		'Test Result: ': itemObj[0].testResult
	};
	let parentData = {
		'Father Name : ': itemObj[0].fatherName,
		'Occupation: ': itemObj[0].occupation,
		'Parent Mobile No :': itemObj[0].parentMobileNo,
		'Mother Name: ': itemObj[0].motherName,
		'Emergency Contact: ': itemObj[0].emergencyContact,
		'Income :': itemObj[0].income,
		'Guardian Name: ': itemObj[0].garduainName,
		'Parent Email Id: ': itemObj[0].parentEmailId,
		'Parent CNIC : ': itemObj[0].parentIdCardNo
	};

	let addData = {
		'Registration No : ': itemObj[0].registrationNo,
		'First Name: ': itemObj[0].firstName,
		'Last Name: ': itemObj[0].lastName,
		'Father Name: ': itemObj[0].fatherName,
		'Roll No :': itemObj[0].rollNo,
		'Admission No: ': itemObj[0].admissionNo,
		'Admission Type: ': itemObj[0].admissionType,
		'Current Session: ': itemObj[0].currentSession,
		'Language: ': itemObj[0].language,
		'Gender: ': itemObj[0].gender,
		'Date Of Birth: ': itemObj[0].dob,
		'Age :': itemObj[0].age,
		'Place Of Birth: ': itemObj[0].placeOfBirth,
		'Nationality: ': itemObj[0].nationality,
		'Religion: ': itemObj[0].religion,
		'Campus Name: ': itemObj[0].campusName,
		'Section :': itemObj[0].section,
		'Username: ': itemObj[0].userName,
		'Password: ': itemObj[0].password,
		'Result Status :': itemObj[0].resultStatus,
		'Status :': itemObj[0].status,
		'Account Type :': itemObj[0].accountType,
		'Account Id :': itemObj[0].accountId
	};

	let getData = {
		'Address :': itemObj[0].address,
		'House No :': itemObj[0].houseNo,
		'Student Id Card No :': itemObj[0].studentIdCardNo,
		'Student Email Id :': itemObj[0].studentEmailId,
		'Way No :': itemObj[0].wayNo,
		'Street No :': itemObj[0].streetNo,
		'Area :': itemObj[0].area,
		'City :': itemObj[0].city,
		'Country :': itemObj[0].country
	};

	let keys = null;
	switch (sectionType) {
		case 'StudentAddress':
			keys = Object.keys(getData);
			break;

		case 'StudentAddmission':
			keys = Object.keys(addData);
			break;

		case 'ParentInfos':
			keys = Object.keys(parentData);
			break;

		case 'StudentDocs':
			keys = Object.keys(studentDoc);
			break;

		case 'ParentDocs':
			keys = Object.keys(parentDoc);
			break;
		case 'MedicalCon':
			keys = Object.keys(medicalCon);
			break;
		case 'WidthDraw':
			keys = Object.keys(widthDraw);
			break;
		case 'OtherInfo':
			keys = Object.keys(otherInfos);
			break;
		case 'StudentTransport':
			keys = Object.keys(studentTrans);
			break;
		case 'SiblingDetails':
			keys = Object.keys(siblingDetails);
			break;
		case 'PrevousSchoolDetails':
			keys = Object.keys(prevSchoolDetails);
			break;

		default:
			break;
	}
	return (
		<React.Fragment>
			{keys.map((item, index) => (
				<div style={{ padding: 10 }} class="col-md-5">
					<div class="md-form mb-0">
						<label for="name" class="" style={{ fontWeight: 'bold' }}>
							<i className={`${Icons[index]}`} style={{ marginRight: 4 }} />
							{item}
						</label>
						<label style={{ marginLeft: 6 }}>
							{sectionType === 'StudentAddress'
								? getData[`${item}`]
								: sectionType === 'StudentAddmission'
								? addData[`${item}`]
								: sectionType === 'ParentInfos'
								? parentData[`${item}`]
								: sectionType === 'StudentDocs'
								? studentDoc[`${item}`]
								: sectionType === 'ParentDocs'
								? parentDoc[`${item}`]
								: sectionType === 'MedicalCon'
								? medicalCon[`${item}`]
								: sectionType === 'WidthDraw'
								? widthDraw[`${item}`]
								: sectionType === 'OtherInfo'
								? otherInfos[`${item}`]
								: sectionType === 'StudentTransport'
								? studentTrans[`${item}`]
								: sectionType === 'SiblingDetails'
								? siblingDetails[`${item}`]
								: sectionType === 'PrevousSchoolDetails'
								? prevSchoolDetails[`${item}`]
								: ''}
						</label>
					</div>
				</div>
			))}
		</React.Fragment>
	);
};
export default RenderAddFormData;
