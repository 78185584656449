import React, { Component } from "react";
import Loader from "../../../components/HOC/LoaderWrapper";
import DateandTime from "../../Transport/DateandTime";
import * as sharedActions from "../../../Actions/sharedActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import MultipleBilling from "./MultipleBilling";
import SnackBar from "../../../components/SnackBar/SnackBar";
import LoaderWrapper from "../../../components/HOC/LoaderWrapper";
import ThemeContext from "../../../context/themeContext/ThemeContext";

class AddBilling extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      Budget: [],
      AccountsData: [],
      backColor: "",
      session: [],
      sessionId: "",
      payTo: "",
      account: "",
      ledger: "",
      amount: "",
      VoucherDescriptions: [
        {
          amount: "",
          paymentMethod: "",
          description: "",
        },
      ],
      errorMsg: false,
      msg: false,
    };
  }

  onClickDeleteItem = (arrName) => {
    let getStateArr = this.state[`${arrName}`];
    let getDeleteLength = getStateArr.length - 1;
    if (getDeleteLength > 0) {
      let getNewDetails = getStateArr.filter(
        (item, index) => index !== getDeleteLength
      );
      console.log(getNewDetails);
      this.setState({
        [arrName]: [...getNewDetails],
      });
    }
  };

  onClickAddItem = (arrName, obj) => {
    let getStateArr = this.state[`${arrName}`];
    getStateArr = [...getStateArr, obj];
    console.log(arrName);
    this.setState({
      [arrName]: getStateArr,
    });
  };
  updateSpecialArrObjOnChange = (e, index, arrName) => {
    const changeArrOfObj = this.state[`${arrName}`];
    changeArrOfObj[index][e.target.name] = e.target.value;
    this.setState({
      [arrName]: changeArrOfObj,
    });
  };
  onChangeText = (e) => {
    console.log(e.target.value);
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onHandleTextChange = (e) => {
    console.log(e.target.value);
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onHandleSubmit = (e) => {
    e.preventDefault();
    const {
      amount,
      sessionId,
      payTo,
      account,
      ledger,
      VoucherDescriptions,
    } = this.state;
    if (
      Object.is(sessionId, "") ||
      Object.is(payTo, "") ||
      Object.is(account, "") ||
      Object.is(amount, "") ||
      Object.is(ledger, "")
    ) {
      this.setState({
        errorMsg: true,
      });
    } else {
      const data = {
        Vouchers: {
          payTo,
          sessionId,
          account,
          amount,
          ledger,
          accountType: this.props.role,
          accountId: this.props.name,
        },
        VoucherDescriptions,
      };
      this.props.sharedActions
        .simpleAddRequest("/api/BillingVoucher", data)
        .then((success) => {
          this.setState({
            errorMsg: false,
            ledger: "",
            amount: "",
            account: "",
            sessionId: "",
            payTo: "",
            msg: "Billing Added Successfully",
            backColor: "#01ac8a",
          });
          this.props.snackbar();
        })
        .catch((error) => {
          this.setState({
            msg: error.response.data.Message,
            backColor: "red",
          });
          this.props.snackbar();
        });
    }
    console.log(this.state);
  };
  componentDidMount() {
    this.props.sharedActions
      .getDataWithoutDispatch("/api/AccountGet")
      .then((success) => {
        debugger;
        this.setState({
          session: success.session,
          Budget: success.budget,
          isLoading: false,
        });
      })
      .catch((err) => {
        this.setState({});
        console.log(err);
      });
  }

  componentDidUpdate() {
    if (this.state.msg || this.state.errorMsg) {
      setTimeout(() => {
        this.setState({
          msg: false,
          errorMsg: false,
        });
      }, 3000);
    }
  }

  onSessionChange = (e) => {
    let accountsfilter = this.state.Budget.filter(
      (item) => item.sessionId == e.target.value
    );
    this.setState({
      sessionId: e.target.value,
      AccountsData: accountsfilter,
    });
  };

  render() {
    return (
          <ThemeContext.Consumer>
            {(context) => (
      <div>
        <section class="mb-4">
          <h2 
          class="h1-responsive font-weight-bold text-center my-4 generalHead"
          style={{ background: context.themeColors.primaryColor }}
        >
            Add Billing
          </h2>

          <SnackBar msg={this.state.msg} backColor={this.state.backColor} />
          <p class="text-center w-responsive mx-auto mb-5" />

          <div
            class="row"
            style={{
              justifyContent: "center",
              textAlign: "center",
            }}
          >
                <div
                  class="col-md-9 mb-md-0 mb-5"
                  style={{
                    border: "solid",
                    borderColor: context.themeColors.primaryColor,
                    padding: 40,
                    borderRadius: 15,
                    width: "auto",
                  }}
                >
                  <LoaderWrapper isLoading={this.state.isLoading}>
                    <form id="contact-form" name="contact-form">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="md-form mb-0">
                            <DateandTime />
                          </div>
                        </div>
                      </div>
                      <br />
                      <div class="row">
                        <div class="col-md-6" style={{ marginTop: 20 }}>
                          <div class="md-form mb-0">
                            <label for="name" class="font-weight-bold">
                              Admin Name
                            </label>
                            <input
                              value={this.props.name}
                              readOnly={true}
                              type="text"
                              name="AdminName"
                              class="form-control"
                              onChange={this.onHandleTextChange}
                            />
                          </div>
                        </div>

                        <div class="col-md-6" style={{ marginTop: 20 }}>
                          <div class="md-form mb-0">
                            <label for="name" class="font-weight-bold">
                              Admin Type
                            </label>
                            <input
                              value={this.props.role}
                              readOnly={true}
                              type="text"
                              name="AdminType"
                              class="form-control"
                              onChange={this.onHandleTextChange}
                            />
                          </div>
                        </div>
                        <div class="col-md-6" style={{ marginTop: 20 }}>
                          <div class="md-form mb-0">
                            <label for="name" class="font-weight-bold">
                              Pay To
                            </label>
                            <input
                              placeholder="Pay to"
                              type="text"
                              name="payTo"
                              class="form-control"
                              value={this.state.payTo}
                              onChange={this.onHandleTextChange}
                            />
                          </div>
                        </div>

                        <div class="col-md-6" style={{ marginTop: 20 }}>
                          <div class="md-form mb-0">
                            <label for="name" class="font-weight-bold">
                              Ledger
                            </label>
                            <select
                              onChange={this.onHandleTextChange}
                              class="custom-select"
                              name="ledger"
                            >
                              <option value="">Ledger</option>
                              <option value="Debit">Debit</option>
                              <option value="Credit">Credit</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-6" style={{ marginTop: 20 }}>
                          <div class="md-form mb-0">
                            <label for="email" class="font-weight-bold">
                              Amount
                            </label>
                            <input
                              placeholder="Enter Amount"
                              type="number"
                              name="amount"
                              value={this.state.amount}
                              className="form-control check"
                              onChange={this.onHandleTextChange}
                            />
                          </div>
                        </div>
                        <div class="col-md-6" style={{ marginTop: 20 }}>
                          <div class="md-form mb-0">
                            <label class="font-weight-bold">Session</label>
                            <select
                              onChange={this.onSessionChange}
                              class="custom-select"
                              name="sessionId"
                            >
                              <option selected={this.state.msg ? true : false}>
                                Select Session..
                              </option>
                              {this.state.session &&
                                this.state.session.map((item, index) => (
                                  <option
                                    key={index}
                                    value={`${item.sessionId}`}
                                  >
                                    {item.session}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                        <div class="col-md-6" style={{ marginTop: 20 }}>
                          <div class="md-form mb-0">
                            <label class="font-weight-bold">Account</label>
                            <select
                              onChange={this.onHandleTextChange}
                              class="custom-select"
                              name="account"
                            >
                              <option selected={this.state.msg ? true : false}>
                                Select Account..
                              </option>
                              {this.state.AccountsData &&
                                this.state.AccountsData.map((item, index) => (
                                  <option
                                    key={index}
                                    value={`${item.particular}`}
                                  >
                                    {item.particular}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <br />
                          <br />
                          <MultipleBilling
                            onHandleTextChange={this.onChangeText}
                            stateData={this.state}
                            updateSpecialArrObjOnChange={
                              this.updateSpecialArrObjOnChange
                            }
                            onClickAddItem={this.onClickAddItem}
                            onClickDeleteItem={this.onClickDeleteItem}
                          />
                        </div>
                      </div>
                      <br />
                      <br />

                      {this.state.errorMsg ? (
                        <p style={{ color: "red", textAlign: "center" }}>
                          Please fill the form properly
                        </p>
                      ) : (
                        ""
                      )}
                      <div class="text-right text-md-right">
                        <button
                          onClick={this.onHandleSubmit}
                          class="buttonHover2"
                          style={{
                            background: context.themeColors.primaryColor,
                          }}
                        >
                          Add
                        </button>
                      </div>
                    </form>
                  </LoaderWrapper>
                  <div class="status" />
                </div>
          </div>
        </section>
      </div>
              )}
            </ThemeContext.Consumer>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    sharedActions: bindActionCreators(sharedActions, dispatch),
  };
}
function mapStateToProps(state) {
  return {
    auth: state.setUserReducer.auth,
    role: state.setUserReducer.users.role,
    name: state.setUserReducer.users.unique_name,
    accountId: state.setUserReducer.users.accountId,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(AddBilling);
