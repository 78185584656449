import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';

class TestDashboard extends Component {
	state = {};
	componentDidMount() {
		$(document).click(function(e) {
			if (!$(e.target).is('.panel-body')) {
				$('.collapse').collapse('hide');
			}
		});
	}
	render() {
		return (
			<div>
				<section class="mb-4">
					<h3 class="h3-responsive font-weight-bold text-center my-4">
						Test Modules
					</h3>
					<div
						class="row"
						style={{
							justifyContent: 'center',
							textAlign: 'center',
							marginBottom: -45
						}}
					>
						<div class="col-md-8">
							<div class="md-form mb-0">
								<hr class="style-head" />
							</div>
						</div>
					</div>

					<p class="text-center w-responsive mx-auto mb-5" />

					<div
						class="row"
						style={{ textAlign: 'center', justifyContent: 'center' }}
					>
						<div class="col-md-9 mb-md-0 mb-5">
							<form id="contact-form" name="contact-form">
								<div class="row">
									<div class="col-md-6">
										<div class="md-form mb-0">
											<Link to="/dashboard/AddTestInfo">
												<img
													src={require('../Assets/TestDashboard/add test info.svg')}
													width="200"
													height="200"
												/>
											</Link>
										</div>
									</div>
									<div class="col-md-6">
										<div class="md-form mb-0">
											<Link to="/dashboard/ViewTestInfo">
												<img
													src={require('../Assets/TestDashboard/view test info.svg')}
													width="200"
													height="200"
												/>
											</Link>
										</div>
									</div>
									<div class="col-md-6">
										<div class="md-form mb-0">
											<Link to="/dashboard/AddTest">
												<img
													src={require('../Assets/TestDashboard/add test.svg')}
													width="200"
													height="200"
												/>
											</Link>
										</div>
									</div>
									<div class="col-md-6">
										<div class="md-form mb-0">
											<Link to="/dashboard/ViewTest">
												<img
													src={require('../Assets/TestDashboard/view test.svg')}
													width="200"
													height="200"
												/>
											</Link>
										</div>
									</div>
								</div>
							</form>
							<div class="status" />
						</div>
					</div>
				</section>
			</div>
		);
	}
}

export default TestDashboard;
