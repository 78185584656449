class Colors {
  static SECOND_PRIMARY_COLOR = "#93d8dd";
  static SECOND_PRIMARY_COLOR_TWO = "#E3F5F6";
  static THIRD_PRIMARY_COLOR = "#a75a8d";
  static THIRD_PRIMARY_COLOR_TWO = "#E2C9DA";
  static FOURTH_PRIMARY_COLOR = "#f2805c";
  static FOURTH_PRIMARY_COLOR_TWO = "#FACABD";
  static LIGHT_GREEN = "#4C9A2A";
  static BLACK = "#000";
  static DARK_GREY = "#A9A9A9";
  static DISABLE_GREEN = "#a6f1a6";
  static DISABLE_GREY = "	#D3D3D3";
  static RED_DANGER = "#FF0000";
  static SEA_GREEN_THEME = "#01AC8A";
  static WHITE = "#fff";
  static DARK_SEA_GREEN_THEME = "#BDE9E1";
  static YELLOW = "#fcd810";
  static DARK_BLUE = "#01b0f1";
  static PURPLE = "#7030a0";
  static RED = "#ff6600";
  static DARK_GREEN = "#00af50";
}
export default Colors;
