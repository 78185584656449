import React from 'react';
import { renderingStuff, images } from '../../../utils';
import ViewRendering from '../../../components/ViewRendering';
const TimeTableScreen = () => {
	return (
		<ViewRendering
			mainHead={true}
			mainHeading={images.imgAcHead}
			data={renderingStuff.timetableLinks}
		/>
	);
};

export default TimeTableScreen;
