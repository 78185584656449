import React from 'react';
import $ from 'jquery'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SelectionInput from '../../components/SelectionInput/SelectionInput';
import TextInput from '../../components/TextInput/TextInput';
import TextArea from '../../components/TextArea'
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import general from '../../utils/general';
import DatePicker from 'react-date-picker';
import { Redirect } from 'react-router-dom'

class EditEvaluationForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mainData: {},
            errors: {},
            teacherEvaluationDetails: [],
            teacherEvaluation: {},
            evaluationComments: {},
            isLoadingInitialData: true,
            className: '',
            sectionName: '',
            session: '',
            staffName: '',
            subjectName: '',
            classId: '',
            sectionId: '',
            sessionId: '',
            staffId: '',
            subjectId: '',
            observerName: '',
            schoolName: 'Dorat Al Khaleej',
            designation: '',
            topic: '',
            discussion: '',
            recommendation: '',
            otherComments: '',
            observerFrom: '',
            remarks: '',
            withOrwithoutNotficiation: '',
            accountId: props.name,
            accountType: props.role
        }
    }

    componentDidMount() {
        const { match, } = this.props;
        $(document).ready(function () {
            $('[data-toggle="tooltip"]').tooltip();
        });


        this.props.sharedActions
            .getDataWithoutDispatch(
                `/api/GetEvalutionForUpdation/?id=${match.params.id}`
            )
            .then(success => {
                this.setState({
                    teacherEvaluationDetails: success.TeacherEvaluationDetails,
                    teacherEvaluation: success.TeacherEvaluations,
                    evaluationComments: success.EvaluationComments,
                    isLoadingInitialData: false,
                    observerName: success.TeacherEvaluations.observerName,
                    designation: success.TeacherEvaluations.designation,
                    topic: success.TeacherEvaluations.topic,
                    otherComments: success.EvaluationComments.otherComments,
                    recommendation: success.EvaluationComments.recommendation,
                    score: success.EvaluationComments.score,
                    percentage: success.EvaluationComments.percentage,
                    className: success.TeacherEvaluations.className,
                    classId: success.TeacherEvaluations.classId,
                    sectionName: success.TeacherEvaluations.sectionName,
                    sectionId: success.TeacherEvaluations.sectionId,
                    subjectName: success.TeacherEvaluations.subjectName,
                    subjectId: success.TeacherEvaluations.subjectId,
                    session: success.TeacherEvaluations.session,
                    sessionId: success.TeacherEvaluations.sessionId,
                    staffName: success.TeacherEvaluations.staff,
                    staffId: success.TeacherEvaluations.staffId,
                    discussion: success.EvaluationComments.discussion,
                    observerFrom: general.formateToDate(success.TeacherEvaluations.observerFrom),
                    withOrwithoutNotficiation: success.TeacherEvaluations.withOrwithoutNotficiation
                })
            })
            .catch(error => {
                this.setState({
                    isLoadingInitialData: false
                });
                console.log(error);
            });
    }

    onHandleTextChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onDateChange = (date) => {
        this.setState({
            observerFrom: date
        })
    }

    onHandleSelectChange = (e) => {
        if (e.target.value === 'Select') {
            this.setState({
                [e.target.name]: ''
            })
        } else {

            this.setState({
                [e.target.name]: e.target.value
            })
        }
    }

    onHandleRemarksChange = (e, index) => {

        const { teacherEvaluationDetails } = this.state;

        teacherEvaluationDetails[index].remarks = e.target.value

        this.setState({
        })
    }

    handleOptionChange = (e, index) => {
        const { teacherEvaluationDetails } = this.state;

        teacherEvaluationDetails[index].grade = e.target.value

        this.setState({
        })
    };

    onSubmit = (e) => {
        e.preventDefault();

        const { schoolName, classId, sectionId, subjectId, sessionId, staffId, observerName, designation, topic, accountId, accountType, recommendation, otherComments, discussion, teacherEvaluationDetails, withOrwithoutNotficiation, observerFrom } = this.state;
        const { match, } = this.props;

        if (Object.is(classId, '') ||
            Object.is(sectionId, '') ||
            Object.is(subjectId, '') ||
            Object.is(sessionId, '') ||
            Object.is(staffId, '') ||
            Object.is(observerName, '') ||
            Object.is(designation, '') ||
            Object.is(topic, '') ||
            Object.is(recommendation, '') ||
            Object.is(otherComments, '') ||
            Object.is(discussion, '') ||
            Object.is(observerFrom, '') ||
            Object.is(withOrwithoutNotficiation, '')
        ) {
            console.log('failed');
            this.setState({
                errorMsg: true
            });
        } else {
            this.setState({
                isLoading: true
            })

            const observeFrom = general.dateToFormat(observerFrom)
            const data = {
                TeacherEvaluations: {
                    schoolName,
                    classId,
                    sectionId,
                    sessionId,
                    staffId,
                    subjectId,
                    observerName,
                    designation,
                    accountId,
                    accountType,
                    topic,
                    withOrWithout: withOrwithoutNotficiation,
                    observeFrom,
                },
                TeacherEvaluationDetails: teacherEvaluationDetails,
                EvaluationComments: {
                    discussion,
                    recommendation,
                    otherComments
                }
            }
            console.log(data);


            this.props.sharedActions
                .editRecordWithoutDispatch(
                    '/api/TeacherEvaluationForm/',
                    match.params.id,
                    data
                )
                .then(success => {
                    this.setState({
                        msg: 'Edited Evaluation Successfully',
                        isLoading: false,
                        errMsg: false,
                        error: false
                    })
                    this.props.snackbar();
                    setTimeout(() => {
                        this.props.history.push('/dashboard/ViewTeacherEvaluation')
                    }, 1000)
                })
                .catch(err => {
                    this.setState({
                        isLoading: false
                    })
                })
        }
    }
    render() {
        const { errors, isLoadingInitialData, teacherEvaluationDetails, observerFrom } = this.state;
        const Loader = (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div class="spinner-border" style={{ color: '#01AC8A' }}></div>
            </div>
        );

        const Form = (
            <div>

                <div className="row">

                    <div class='col-md-12'>
                        <h1 className="h1-responsive font-weight-bold my-4 generalHead">
                            <i class="fas fa-pen"></i>
                            {' '}
                            Edit Evaluation Record {' '}
                        </h1>
                    </div>


                    <div class='col-md-12'>
                        <h3 className="h1-responsive font-weight-bold my-4 generalHead">
                            {' '}
                            Teacher Evaluation {' '}
                        </h3>
                    </div>


                    <TextInput
                        marginTop={20}
                        feildName={'schoolName'}
                        inputLabelName={'School Name'}
                        errors={errors}
                        stateData={this.state}
                        placeholder="School Name"
                        readOnly={true}
                        type={'text'}
                        isValidte={false}
                    />

                    <TextInput
                        marginTop={20}
                        feildName={'observerName'}
                        inputLabelName={'Observer Name'}
                        onHandleChange={this.onHandleTextChange}
                        errors={errors}
                        stateData={this.state}
                        // iconClassName={'fas fa-percentage'}
                        placeholder={'Observer Name'}
                        isValidte={false}
                    />

                    <TextInput
                        marginTop={20}
                        feildName={'designation'}
                        inputLabelName={'Designation'}
                        onHandleChange={this.onHandleTextChange}
                        errors={errors}
                        stateData={this.state}
                        // iconClassName={'fas fa-percentage'}
                        placeholder={'Designation'}
                        isValidte={false}
                    />

                    <TextInput
                        marginTop={20}
                        feildName={'topic'}
                        inputLabelName={'Topic'}
                        onHandleChange={this.onHandleTextChange}
                        errors={errors}
                        stateData={this.state}
                        // iconClassName={'fas fa-percentage'}
                        placeholder={'Topic'}
                        isValidte={false}
                    />

                    <TextInput
                        marginTop={20}
                        feildName={'className'}
                        inputLabelName={'Class Name'}
                        errors={errors}
                        stateData={this.state}
                        placeholder="Class Name"
                        readOnly={true}
                        type={'text'}
                        isValidte={false}

                    />

                    <TextInput
                        marginTop={20}
                        feildName={'sectionName'}
                        inputLabelName={'Section Name'}
                        errors={errors}
                        stateData={this.state}
                        placeholder="Section"
                        readOnly={true}
                        type={'text'}
                        isValidte={false}

                    />

                    <TextInput
                        marginTop={20}
                        feildName={'subjectName'}
                        inputLabelName={'Subject Name'}
                        errors={errors}
                        stateData={this.state}
                        placeholder="Subject Name"
                        readOnly={true}
                        type={'text'}
                        isValidte={false}

                    />

                    <TextInput
                        marginTop={20}
                        feildName={'session'}
                        inputLabelName={'Session'}
                        errors={errors}
                        stateData={this.state}
                        placeholder="Session"
                        readOnly={true}
                        type={'text'}
                        isValidte={false}

                    />

                    <TextInput
                        marginTop={20}
                        feildName={'staffName'}
                        inputLabelName={'Staff Name'}
                        errors={errors}
                        stateData={this.state}
                        placeholder="Staff Name"
                        readOnly={true}
                        type={'text'}
                        isValidte={false}

                    />

                    <SelectionInput
                        marginTop={20}
                        feildName={'withOrwithoutNotficiation'}
                        selectName={'Prior Notification'}
                        onHandleChange={e => this.onHandleSelectChange(e)}
                        errors={errors}
                        optionsArrys={general.withOrWithout}
                        selectedText={'Select'}
                        stateData={this.state}
                        // iconClassName={'fas fa-building'}
                        optionType="static"
                        successflag={'successflag'}
                        isValidte={false}
                    // editUse={'staffEdit'}
                    />
                    <div class="col-md-6" style={{ marginTop: 25 }}>
                        <div class="md-form mb-0">
                            <label for="email" class="">
                                Observed From
											</label>
                            <br />
                            <DatePicker
                                //                                    maxDate={true}
                                onChange={e => this.onDateChange(e)}
                                value={observerFrom}
                                selectDateOnly
                            />
                        </div>
                    </div>

                    <div class='col-md-12' style={{ marginTop: 30 }}>
                        <h3 className="h1-responsive font-weight-bold my-4 generalHead">
                            {' '}
                            Teacher Evaluation Details{' '}
                        </h3>
                    </div>

                    <div class="col-md-12"  >
                        {teacherEvaluationDetails.map((item, index) => (
                            <form class='form-inline' style={{ marginBottom: 25 }}>
                                <div class='col-md-4' style={{ textAlign: 'left' }}>
                                    <text class='text-left' style={{ fontSize: 18 }}><strong>{index + 1}. {item.evaluationSkill}</strong></text>
                                </div>
                                <div class='col-md-4 form-inline'>
                                    <label style={{ color: '#007b5e', fontWeight: 'bold', marginRight: 10, fontSize: 20 }}>
                                        <input
                                            style={{ width: 20, height: 20 }}
                                            type="radio"
                                            value='A'
                                            name={item.evaluationSkill}
                                            onChange={e => this.handleOptionChange(e, index)}
                                            checked={item.grade === 'A' ? 'checked' : ''}
                                        />
                                        A</label>
                                    <label style={{ color: '#007b5e', fontWeight: 'bold', marginRight: 10, fontSize: 20 }}>
                                        <input
                                            style={{ width: 20, height: 20 }}
                                            type="radio"
                                            value='B'
                                            name={item.evaluationSkill}
                                            onChange={e => this.handleOptionChange(e, index)}
                                            checked={item.grade === 'B' ? 'checked' : ''}
                                        />
                                        B</label>

                                    <label style={{ color: '#007b5e', fontWeight: 'bold', marginRight: 10, fontSize: 20 }}>
                                        <input
                                            style={{ width: 20, height: 20 }}
                                            type="radio"
                                            value='C'
                                            name={item.evaluationSkill}
                                            onChange={e => this.handleOptionChange(e, index)}
                                            checked={item.grade === 'C' ? 'checked' : ''}
                                        />
                                        C</label>

                                    <label style={{ color: '#007b5e', fontWeight: 'bold', marginRight: 10, fontSize: 20 }}>
                                        <input
                                            style={{ width: 20, height: 20 }}
                                            type="radio"
                                            value='D'
                                            name={item.evaluationSkill}
                                            onChange={e => this.handleOptionChange(e, index)}
                                            checked={item.grade === 'D' ? 'checked' : ''}
                                        />
                                        D</label>

                                    <label style={{ color: '#007b5e', fontWeight: 'bold', marginRight: 10, fontSize: 20 }}>
                                        <input
                                            style={{ width: 20, height: 20 }}
                                            type="radio"
                                            value='E'
                                            name={item.evaluationSkill}
                                            onChange={e => this.handleOptionChange(e, index)}
                                            checked={item.grade === 'E' ? 'checked' : false}
                                        />
                                        E</label>

                                    <label style={{ color: '#007b5e', fontWeight: 'bold', marginRight: 10, fontSize: 20 }}>
                                        <input
                                            style={{ width: 20, height: 20 }}
                                            type="radio"
                                            value='N'
                                            name={item.evaluationSkill}
                                            onChange={e => this.handleOptionChange(e, index)}
                                            checked={item.grade === 'N' ? 'checked' : ''}
                                        />
                                        N</label>
                                </div>
                                {/*                                 <div class='col-md-4'>
                                    <TextInput
                                        feildName={item.remarks}
                                        onHandleChange={e => this.onHandleRemarksChange(e, index)}
                                        errors={errors}
                                        stateData={this.state}
                                        placeholder={'Remarks'}
                                        isValidte={false}
                                    />
                                </div>
 */}
                                <div class="col-md-4">
                                    <div class="md-form mb-0">
                                        <input
                                            type="text"
                                            value={item.remarks}
                                            class="form-control"
                                            placeholder={'Remarks'}
                                            onChange={e => this.onHandleRemarksChange(e, index)}
                                        />
                                    </div>
                                </div>
                            </form>
                        ))}
                    </div>
                    <div class='col-md-12' style={{ marginTop: 30 }}>
                        <h3 className="h1-responsive font-weight-bold my-4 generalHead">
                            {' '}
                            Evaluation Comments{' '}
                        </h3>
                    </div>

                    <TextArea
                        feildName={'recommendation'}
                        inputLabelName={'Recommendation'}
                        onHandleChange={this.onHandleTextChange}
                        errors={errors}
                        stateData={this.state}
                        // iconClassName={'fas fa-percentage'}
                        placeholder={'Add Recommendation'}
                        isValidte={false}
                    />
                    <TextArea
                        feildName={'otherComments'}
                        inputLabelName={'Other Comments'}
                        onHandleChange={this.onHandleTextChange}
                        errors={errors}
                        stateData={this.state}
                        // iconClassName={'fas fa-percentage'}
                        placeholder={'Add Other Comments'}
                        isValidte={false}
                    />
                    <SelectionInput
                        marginTop={20}
                        feildName={'discussion'}
                        selectName={'Discussion'}
                        onHandleChange={e => this.onHandleSelectChange(e)}
                        errors={errors}
                        optionsArrys={general.YesNO}
                        selectedText={'Select'}
                        stateData={this.state}
                        // iconClassName={'fas fa-building'}
                        optionType="static"
                        successflag={'successflag'}
                        isValidte={false}
                    // editUse={'staffEdit'}
                    />

                </div>
                <br />

                <div class="text-center text-md-center">
                    <button type="submit" class="btnAdd" style={{ fontSize: 20, width: '50%' }} onClick={e => this.onSubmit(e)}>
                        Edit {' '}
                        <i class='fas fa-arrow-circle-right'></i>

                        <span
                            style={{ marginBottom: 5 }}
                            class={`${
                                this.state.isLoading
                                    ? 'spinner-border spinner-border-sm'
                                    : ''
                                }`}
                        ></span>
                    </button>
                </div>

            </div>
        )

        return (
            <React.Fragment>
                <SnackBar msg={this.state.msg} />
                {isLoadingInitialData ? Loader : Form}
            </React.Fragment>

        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        sharedActions: bindActionCreators(sharedActions, dispatch)
    };
}
function mapStateToProps(state) {
    return {
        role: state.setUserReducer.users.role,
        name: state.setUserReducer.users.unique_name,
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditEvaluationForm);
