import React, { Component } from 'react';
import DateandTime from '../Transport/DateandTime';
import validator from '../../validator/registrationValidator';
import { connect } from 'react-redux';
import SelectionInput from '../../components/SelectionInput/SelectionInput';
import TextInput from '../../components/TextInput/TextInput';
import FormLayoutWrapper from '../../components/HOC/FormLayoutWrapper';
import * as sharedActions from '../../Actions/sharedActions';
import * as StaffActions from '../../Actions/StaffActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import { bindActionCreators } from 'redux';
import Axios from 'axios';
import config from '../../config/config';
const options = ['Mixed', 'Performance', 'Fixed'];
const timeDurationOptions = ['Half Year', 'Annual'];
const dummyStaff = ['taha', 'adil', 'sanaullah', 'syed hamza'];
class Salary extends Component {
	constructor(props) {
		super(props);
		this.state = {
			staffDropData: [],
			type: 'Type',
			staffId: 'Choose Staff',
			timeDuration: 'Type',
			percentageBasic: '',
			department: '',
			deptId: '',
			post: '',
			percentagePerformance: '',
			accountType: '',
			accountId: '',
			errors: {},
			isDataLoading: true,
			isLoading: false,
			disabledPB: true,
			disabledP: true,
			successflag: false,
			filterStaffData: [],
			filterPostData: [],
			deptName: ''
		};
	}
	isValid = () => {
		const { errors, isValid } = validator(this.state, 'checkAppraisalData');
		if (!isValid) {
			this.setState({
				errors
			});
		}
		return isValid;
	};
	onHandleTextChange = e => {
		console.log(e.target.value);

		this.setState({
			[e.target.name]: e.target.value
		});
	};
	getFilterDepartment = deptName => {
		const { staffDropData } = this.state;
		let getPostNonTechData = staffDropData.department.filter(
			item => item.departmentName === deptName
		);

		this.setState({
			deptId: getPostNonTechData[0].departmentId
		});
	};
	onHandleDepartChange = e => {
		const { staffDropData } = this.state;
		console.log(e.target.value);

		if (e.target.value !== 'Type') {
			let getDepartName = staffDropData.department.filter(
				item => item.departmentId == parseInt(e.target.value)
			)[0].departmentName;

			let getPostNonTechData = staffDropData.post.filter(
				item => item.departmentName === getDepartName
			);
			this.getFilterDepartment(getDepartName);

			this.setState({
				filterPostData: getPostNonTechData,
				department: e.target.value,
				successflag: false
			});
		}
	};

	static getDerivedStateFromProps(nextProps, prevState) {
		if (prevState.staffDropData !== nextProps.getStaffData) {
			return {
				staffDropData: nextProps.getStaffData
			};
		}
		return null;
	}
	componentDidMount() {
		this.props.shareActions
			.gAllData(
				'/api/StaffPostDepartActive',
				this.props.staffActions.getStaffData
			)
			.then(success => {
				this.setState({
					isDataLoading: false
				});
			})
			.catch(error => {
				console.log(error);
			});
	}
	onHandleAppraisalTypeChange = e => {
		switch (e.target.value) {
			case 'Mixed':
				this.setState({
					disabledP: false,
					disabledPB: false,
					[e.target.name]: e.target.value,
					successflag: false
				});
				break;
			case 'Performance':
				{
					let { errors } = this.state;
					let getNewError = { ...errors };
					getNewError.percentageBasic = false;
					this.setState({
						disabledP: false,
						disabledPB: true,
						[e.target.name]: e.target.value,
						errors: getNewError,
						percentageBasic: '',
						successflag: false
					});
				}
				break;
			case 'Fixed':
				{
					let { errors } = this.state;
					let getNewError = { ...errors };
					getNewError.percentagePerformance = false;
					this.setState({
						disabledPB: false,
						disabledP: true,
						[e.target.name]: e.target.value,
						errors: getNewError,
						percentagePerformance: '',
						successflag: false
					});
				}
				break;
			default:
				const { errors } = this.state;
				let getNewError = { ...errors };
				getNewError.percentageBasic = false;
				getNewError.percentagePerformance = false;
				this.setState({
					disabledP: true,
					disabledPB: true,
					[e.target.name]: e.target.value,
					percentagePerformance: '',
					percentageBasic: '',
					errors: getNewError,
					successflag: false
				});
				break;
		}
	};
	onHandleSubmit = e => {
		const {
			type,
			timeDuration,
			staffId,
			percentagePerformance,
			percentageBasic
		} = this.state;
		e.preventDefault();
		if (this.isValid()) {
			if (percentagePerformance === '') {
				let data = {
					type,
					timeDuration,
					staffId,
					percentagePerformance: '0',
					percentageBasic,
					accountId: this.props.name,
					accountType: this.props.role
				};
				this.setState({
					isLoading: true
				});
				this.props.shareActions
					.simplePostReq('/api/Appraisals/', staffId)
					.then(success1 => {
						console.log(success1);

						this.props.shareActions
							.addRecord(
								'/api/Appraisals',
								data,
								this.props.staffActions.addAppraisal
							)
							.then(success => {
								this.setState({
									isLoading: false,
									type: 'Type',
									staffId: 'Choose Staff',
									timeDuration: 'Type',
									percentageBasic: '',
									percentagePerformance: '',
									successflag: true,
									errors: {},
									department: '',
									deptId: '',
									post: '',
									disabledPB: true,
									disabledP: true
								});
								console.log('required props: ', this.props);

								this.props.snackbar();
							})
							.catch(error => {
								this.setState({
									isLoading: false
								});
							});
					})
					.catch(error => {
						const { errors } = this.state;
						let newErrors = { ...errors };
						newErrors.msg = 'The form is already filled for this Staff Member!';
						this.setState({
							isLoading: false,
							errors: newErrors
						});
					});
			} else if (percentageBasic === '') {
				let data = {
					type,
					timeDuration,
					staffId,
					percentagePerformance,
					percentageBasic: '0',
					accountId: this.props.name,
					accountType: this.props.role
				};
				this.setState({
					isLoading: true
				});
				this.props.shareActions
					.simplePostReq('/api/Appraisals/', staffId)
					.then(success1 => {
						console.log(success1);

						this.props.shareActions
							.addRecord(
								'/api/Appraisals',
								data,
								this.props.staffActions.addAppraisal
							)
							.then(success => {
								this.setState({
									isLoading: false,
									type: 'Type',
									staffId: 'Choose Staff',
									timeDuration: 'Type',
									percentageBasic: '',
									percentagePerformance: '',
									successflag: true,
									errors: {},
									department: '',
									deptId: '',
									post: '',
									disabledPB: true,
									disabledP: true
								});
								console.log('required props: ', this.props);

								this.props.snackbar();
							})
							.catch(error => {
								this.setState({
									isLoading: false
								});
							});
					})
					.catch(error => {
						const { errors } = this.state;
						let newErrors = { ...errors };
						newErrors.msg = 'The form is already filled for this Staff Member!';

						this.setState({
							isLoading: false,
							errors: newErrors
						});
					});
			} else {
				let data = {
					type,
					timeDuration,
					staffId,
					percentagePerformance,
					percentageBasic,
					accountId: this.props.name,
					accountType: this.props.role
				};
				this.setState({
					isLoading: true
				});
				this.props.shareActions
					.simplePostReq('/api/Appraisals/', staffId)
					.then(success1 => {
						console.log(success1);

						this.props.shareActions
							.addRecord(
								'/api/Appraisals',
								data,
								this.props.staffActions.addAppraisal
							)
							.then(success => {
								this.setState({
									isLoading: false,
									type: 'Type',
									staffId: 'Choose Staff',
									timeDuration: 'Type',
									percentageBasic: '',
									percentagePerformance: '',
									successflag: true,
									errors: {},
									department: '',
									deptId: '',
									post: '',
									disabledPB: true,
									disabledP: true
								});
								console.log('required props: ', this.props);

								this.props.snackbar();
							})
							.catch(error => {
								this.setState({
									isLoading: false
								});
							});
					})
					.catch(error => {
						const { errors } = this.state;
						let newErrors = { ...errors };
						newErrors.msg = 'The form is already filled for this Staff Member!';
						this.setState({
							isLoading: false,
							errors: newErrors
						});
					});
			}
		}
	};
	errorAppear = msg => {
		const { errors } = this.state;
		if (true) {
			return (
				<p style={{ textAlign: 'center' }} class="text-info">
					{errors.msg}
				</p>
			);
		}
	};
	getStaffData = (department = 0, post = 0) => {
		const { staffDropData } = this.state;
		let getFilterStaff = staffDropData.staff.filter(
			item =>
				item.departmentId === parseInt(department) &&
				item.postId === parseInt(post)
		);

		this.setState({
			filterStaffData: getFilterStaff
		});
	};
	// getPostData = (data) => {
	//     const { staffDropData } = this.state;

	//
	//     switch (data.target.value) {
	//         case 'Non Teaching Staff':
	//             let getPostNonTechData = staffDropData.post.filter((item) => item.departmentName === 'Non Teaching Staff');
	//
	//             this.setState({
	//                 filterPostData: getPostNonTechData,
	//                 department:getData.itemName,
	//             });
	//             break;
	//         case 'Teaching Staff':
	//             let getPostTechData = staffDropData.post.filter((item) => item.departmentName === 'Teaching Staff');
	//
	//             this.setState({
	//                 filterPostData: getPostTechData,
	//                 department:getData.itemName
	//             });
	//             break;
	//         default:
	//             break;
	//     }
	// }
	// getSelectedDepartment = (e) => {
	//     const
	// }
	onChangePostAndDepart = (e, type) => {
		const { department, post, deptId } = this.state;
		switch (type) {
			case 'post':
				this.setState({
					post: e.target.value
				});

				this.getStaffData(deptId, e.target.value);
				break;
			case 'department':
				this.setState({
					department: e.target.value
				});
				this.getStaffData(e.target.value, post);
				break;

			default:
				break;
		}
	};
	render() {
		const { errors, isDataLoading, staffDropData, successflag } = this.state;

		const Loading = (
			<div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
				<p>Loading...</p>
			</div>
		);
		const Form = (
			<FormLayoutWrapper
				formName="Appraisal"
				borderColor="#01ac8a"
				borderRadius={15}
			>
				<SnackBar msg="Appraisal Successfully!" />
				<div>{this.errorAppear(errors.msg)}</div>
				<div className="row">
					<div class="col-md-6">
						<div class="md-form mb-0">
							<label for="name" class="">
								{/* <i
                                    className="fas fa-user-shield animated fadeIn"
                                    style={{ marginRight: 4 }}
                                /> */}
								Admin Name
							</label>
							<input
								value={this.props.name}
								type="text"
								readOnly={true}
								name="AdminName"
								class="form-control"
								onChange={this.onHandleTextChange}
							/>
						</div>
					</div>

					<div class="col-md-6">
						<div class="md-form mb-0">
							<label for="name" class="">
								{/* <i
                                    className="fas fa-user-alt animated fadeIn"
                                    style={{ marginRight: 4 }}
                                /> */}
								Admin Type
							</label>
							<input
								readOnly={true}
								value={this.props.role}
								type="text"
								name="AdminType"
								class="form-control"
								onChange={this.onHandleTextChange}
							/>
						</div>
					</div>
					<SelectionInput
						marginTop={20}
						feildName={'department'}
						selectName={'Department'}
						onHandleChange={e => this.onHandleDepartChange(e)}
						errors={errors}
						optionsArrys={this.state.staffDropData.department}
						selectedText={'Type'}
						stateData={this.state}
						// iconClassName={'fas fa-building'}
						optionType="dynamicWithPropIdAndName"
						property={'departmentName'}
						propertyId={'departmentId'}
						successflag={'successflag'}
						// editUse={'staffEdit'}
					/>
					<SelectionInput
						marginTop={20}
						feildName={'post'}
						selectName={'Post'}
						onHandleChange={e => this.onChangePostAndDepart(e, 'post')}
						errors={errors}
						optionsArrys={this.state.filterPostData}
						selectedText={'Type'}
						stateData={this.state}
						// iconClassName={'fas fa-level-up-alt'}
						optionType="dynamic"
						property={'postName'}
						propertyId={'postId'}
						successflag={'successflag'}
						// editUse={'staffEdit'}
					/>
					<SelectionInput
						marginTop={20}
						feildName={'staffId'}
						selectName={'Staff Name'}
						onHandleChange={this.onHandleTextChange}
						errors={errors}
						optionsArrys={this.state.filterStaffData}
						selectedText={'Choose Staff'}
						stateData={this.state}
						// iconClassName={'fas fa-university animated fadeIn'}
						optionType="dynamic"
						property={'staffName'}
						propertyId={'staffId'}
						successflag={'successflag'}
					/>

					<SelectionInput
						marginTop={20}
						feildName={'timeDuration'}
						selectName={'Time Duration'}
						onHandleChange={this.onHandleTextChange}
						errors={errors}
						optionsArrys={timeDurationOptions}
						selectedText={'Type'}
						stateData={this.state}
						// iconClassName={'fas fa-clock animated fadeIn'}
						optionType="static"
						successflag={'successflag'}
					/>

					<SelectionInput
						marginTop={20}
						feildName={'type'}
						selectName={'Appraisal Type'}
						onHandleChange={this.onHandleAppraisalTypeChange}
						errors={errors}
						optionsArrys={options}
						selectedText={'Type'}
						stateData={this.state}
						// iconClassName={'fas fa-money-bill-wave animated fadeIn'}
						optionType="static"
						successflag={'successflag'}
					/>
					<TextInput
						marginTop={20}
						feildName={'percentageBasic'}
						inputLabelName={'Percentage Basic'}
						onHandleChange={this.onHandleTextChange}
						errors={errors}
						stateData={this.state}
						// iconClassName={'fas fa-percentage'}
						placeholder={'Add Value in %'}
						disabledField={'disabledPB'}
					/>
					<TextInput
						marginTop={20}
						feildName={'percentagePerformance'}
						inputLabelName={'Percentage Performance'}
						onHandleChange={this.onHandleTextChange}
						errors={errors}
						stateData={this.state}
						// iconClassName={'fas fa-percentage'}
						placeholder={'Add Value in %'}
						disabledField={'disabledP'}
					/>

					<br />
					<br />
				</div>
				<div style={{ width: '50%', margin: '0 auto', marginTop: 20 }}>
					<button
						style={{ width: '80%' }}
						onClick={this.onHandleSubmit}
						className="btns"
					>
						Add
						<span
							style={{ marginBottom: 5 }}
							class={`${
								this.state.isLoading ? 'spinner-border spinner-border-sm' : ''
							}`}
						></span>
					</button>
				</div>
			</FormLayoutWrapper>
		);
		return <React.Fragment>{isDataLoading ? Loading : Form}</React.Fragment>;
	}
}
function mapStateToProps(state) {
	return {
		role: state.setUserReducer.users.role,
		name: state.setUserReducer.users.unique_name,
		getStaffData: state.setStaffReducer.staffData
	};
}
function mapDispatchToProps(dispatch) {
	return {
		staffActions: bindActionCreators(StaffActions, dispatch),
		shareActions: bindActionCreators(sharedActions, dispatch)
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Salary);
