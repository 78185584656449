import React, { Component, useContext } from "react";
import themeContext from "../../context/themeContext/ThemeContext";

const StepperButtonsCom = (props) => {
  const themeColor = useContext(themeContext);
  return (
    <div
      className={"row"}
      style={{
        fontSize: 20,
        display: "flex",
        justifyContent: "space-between",
        paddingLeft: 30,
      }}
    >
      <div style={{ display: "flex" }}>
        <div style={{ marginBottom: 5 }} class="text-center">
          <button
            style={{ background: themeColor.themeColors.primaryColor }}
            onClick={(e) => props.onNextClick(e, props.formType)}
            class="btns"
          >
            {props.formType === "ParentDocsForm" ? "Submit" : "Next"}
          </button>
        </div>

        {props.formType === "studentAddForm" ? (
          <p></p>
        ) : (
          <div style={{ marginLeft: 80 }} class="text-center">
            <button
              style={{ background: themeColor.themeColors.primaryColor }}
              class="btns"
              onClick={(e) => props.onPreviousClick(e)}
            >
              Previous
            </button>
          </div>
        )}
      </div>
      {props.formType === "studentSiblings" ||
      props.formType === "studentPreviousSchoolDetails" ? (
        <div class="text-center">
          <button
            style={{ background: themeColor.themeColors.primaryColor }}
            class="btns"
            onClick={(e) => props.skipStep(e)}
          >
            Skip
          </button>
        </div>
      ) : (
        <p></p>
      )}
    </div>
  );
};
export default StepperButtonsCom;
