import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import DateandTime from '../Transport/DateandTime';
import isNull from 'lodash/isNull';

import SnackBar from "../../components/SnackBar/SnackBar";
import LoaderWrapper from "../../components/HOC/LoaderWrapper";
import ThemeContext from "../../context/themeContext/ThemeContext";

class AddBook extends Component {
	constructor(props) {
		super(props);
		this.state = {
			errorMsg: false,
			successMsg: false,
			msg: '',
            backColor: '',
			isLoading: false,
			classAndSubject: null,
			gradeId: null,
			booksCategory: [],
			bookCategoryId: null,
			gradeAndCategory: null,
			className: null,
			categoryName: null,
			bookName: null,
			author: null,
			quantity: null,
			barcode: null
		};
	}

	componentDidMount() {
		debugger;
		this.getSimple('bookCategoryDropDown', 'gradeAndCategory');
	}

    getSimple = (type, state) => {
        this.props.sharedActions.getDataWithoutDispatch(
            `/api/${type}`
        ).then(success => {
			debugger;
            this.setState({
                [state]: success,
                isLoading: false
            })


        }).catch(error => {
            this.setState({
                msg: 'Error Occured!!',
                isLoading: false
            })
            this.props.snackbar();
            console.error('Error occured', error);
        })
    }


	myFunction = () => {
		// Get the snackbar DIV
		var x = document.getElementById('snackbar');

		// StepperGeneralButtons the "show" class to DIV
		x.className = 'show';

		// After 3 seconds, remove the show class from DIV
		setTimeout(function() {}, 3000);
	};

	onHandleTextChange = e => {
		console.log([e.target.name], e.target.value);

		this.setState({
			[e.target.name]: e.target.value
		});
	};

	onFilterGrade = e => {
		console.log([e.target.name], e.target.value);

		let filterCategory = this.state.gradeAndCategory.booksCategory.filter((item) => item.gradeId == e.target.value);

		this.setState({
			[e.target.name]: e.target.value,
			booksCategory: filterCategory
		});
	};

	handleSubmit = e => {
		e.preventDefault();
		const {
			className,
			categoryName,
			gradeId,
			bookName,
			author,
			quantity,
			barcode,
			bookCategoryId
		} = this.state;
		if (
			isNull(gradeId) ||
			isNull(bookCategoryId) ||
			isNull(bookName) ||
			isNull(author) ||
			isNull(quantity) ||
			isNull(barcode)
		) {
			this.setState({
				msg: 'Please fill the form respectively!'
			});
			this.props.snackbar();
		} else {
			let data = {
				accountId: this.props.userData.unique_name,
				accountType: this.props.userData.role,
				bookName: bookName,
				bookCategoryId,
				Author: author,
				quantity: quantity,
				barCodeNo: barcode
			};

			this.props.sharedActions
				.simpleAddRequest(`/api/Books`, data)
				.then(success => {
					console.log(success);

					this.setState({
						msg: 'Book Added!',
						gradeId: '',
						bookCategoryId: '',
						bookName: '',
						author: '',
						quantity: '',
						barcode: ''
					});
					this.props.snackbar();
				})
				.catch(error => {
					this.setState({
						msg: error.response ? error.response.data.Message : 'Error Occured!!',
						isLoading: false
					});
					
					this.props.snackbar();
					console.error('Error occured', error);
				});
		}
	};
	

	render() {
		const {
			successMsg,
			errorMsg,
			msg,
			bookCategoryId,
			booksCategory,
			gradeId,
			isLoading,
			gradeAndCategory,
			classAndSubject,
			className,
			categoryName,
			bookName,
			author,
			quantity,
			barcode
		} = this.state;

		return (
			<div className="container">
				<section  className="mb-4">
                <ThemeContext.Consumer>
                    {(context) => (
                    <h2  
                        style={{ background: context.themeColors.primaryColor }}
                        className="h1-responsive font-weight-bold text-center my-4 generalHead">
                        Add Book
                    </h2>
                     )}
                </ThemeContext.Consumer>

              <SnackBar msg={this.state.msg} backColor={this.state.backColor} />

                    <p class="text-center w-responsive mx-auto mb-5" />

                    <div className="row" style={{
                        justifyContent: 'center',
                        textAlign: 'center'
                    }}>

                <ThemeContext.Consumer>
                {(context) => (
                        <div className="col-md-9 mb-md-0 mb-5" style={{
                            border: 'solid',
                            borderColor: context.themeColors.primaryColor,
                            padding: 40,
                            borderRadius: 15,
                            width: 'auto'

                        }}>
                      <LoaderWrapper isLoading={this.state.isLoading}>
							<form
								id="book-form"
								name="book-form"
								onSubmit={this.handleSubmit}
							>
								<div className="row">
									<div className="col-md-12">
										<div className="md-form mb-0">
											<DateandTime />
										</div>
									</div>
								</div>
								<br />
								<div class="row">
									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="AdminName" class="font-weight-bold">
												Admin Name
											</label>
											<input
												type="text"
												name="AdminName"
												class="form-control"
												readOnly={true}
												value={this.props.userData.unique_name}
											/>
										</div>
									</div>

									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="AdminType" class="font-weight-bold">
												Admin Type
											</label>
											<input
												type="text"
												name="AdminType"
												class="form-control"
												readOnly={true}
												value={this.props.userData.role}
											/>
										</div>
									</div>
								</div>
								<br />
								<div className="row">
									<div className="col-md-6">
										<div className="md-form mb-0">
											<label for="className" class="font-weight-bold">
												Grade
											</label>
											<select
												class="custom-select"
												value={gradeId}
												name="gradeId"
												onChange={this.onFilterGrade}
												disabled={!booksCategory}
											>
												<option value="">Please Select Grade</option>
												{gradeAndCategory &&
													gradeAndCategory.Grade.map((value, index) => {
														return (
															<option value={value.id}>
																{value.gradeName}
															</option>
														);
													})}
											</select>
										</div>
									</div>
									<div className="col-md-6">
										<div className="md-form mb-0">
											<label for="categoryName" class="font-weight-bold">
												Category
											</label>
											<select
												class="custom-select"
												value={bookCategoryId}
												name="bookCategoryId"
												onChange={this.onHandleTextChange}
												disabled={!gradeId}
											>
												<option value="">Please Select Category</option>
												{booksCategory &&
													booksCategory.map((value, index) => {
														return (
															
																<option value={value.id}>
																	{value.bookCategory}
																</option>
														)
													})}
											</select>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-md-6" style={{ marginTop: 20 }}>
										<div className="md-form mb-0">
											<label for="bookName" class="font-weight-bold">
												Book Name
											</label>
											<input
												type="text"
												name="bookName"
												class="form-control"
												value={bookName}
												onChange={this.onHandleTextChange}
												disabled={!bookCategoryId}
											/>
										</div>
									</div>
									<div className="col-md-6" style={{ marginTop: 20 }}>
										<div className="md-form mb-0">
											<label for="author" class="font-weight-bold">
												Author
											</label>
											<input
												type="text"
												name="author"
												class="form-control"
												value={author}
												onChange={this.onHandleTextChange}
												disabled={!bookCategoryId}
											/>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-md-6" style={{ marginTop: 20 }}>
										<div className="md-form mb-0">
											<label for="quantity" class="font-weight-bold">
												Quantity
											</label>
											<input
												type="number"
												name="quantity"
												class="form-control"
												value={quantity}
												onChange={this.onHandleTextChange}
												disabled={!bookCategoryId}
											/>
										</div>
									</div>
									<div className="col-md-6" style={{ marginTop: 20 }}>
										<div className="md-form mb-0">
											<label for="barcode" class="font-weight-bold">
												Barcode
											</label>
											<input
												type="text"
												name="barcode"
												class="form-control"
												value={barcode}
												onChange={this.onHandleTextChange}
												disabled={!bookCategoryId}
											/>
										</div>
									</div>
								</div>
								<br />
								<br />
								<div className="text-center text-md-center">
									<button type="submit" className="btns" style={{
                                                    background: context.themeColors.primaryColor,
                                            }}>
										Add
										<span
											style={{
												marginBottom: 5
											}}
											className={
												isLoading && 'spinner-border spinner-border-sm'
											}
										></span>
									</button>
								</div>
							</form>
							</LoaderWrapper>
                            <div class="status" />
                </div>
              )}
            </ThemeContext.Consumer>
                    </div>
                </section>
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch)
	};
}
function mapStateToProps(state) {
	return {
		userData: state.setUserReducer.users
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AddBook);
