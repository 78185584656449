
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import $ from 'jquery';
import isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom'
import isNull from 'lodash/isNull'
import moment from 'moment'
import Pagination from '../../components/Pagination/Pagination'

import ThemeContext from "../../context/themeContext/ThemeContext";


class ViewStaffShortLeave extends Component {
    constructor(props){
        super(props);
        this.state = {
            isLoading: true,
            modalEdit: false,
            editToggle: false,
            generateToggle: false,
            generateIndex: '',
            msg: '',
            editIndex: '',
            classId: "",
            sectionId: "",
            subjectId: "",
            pageNumber: [],
            dataLimit: 10,
            currentPage: 1,

			accountId: this.props.userData.unique_name,
			accountType: this.props.userData.role

        }

    }
	static contextType = ThemeContext

    componentDidMount(){
        this.handleSearch()
    }
    handleSearch = () => {
        this.customSimple(`DescriptiveReport?userName=${this.props.userData.unique_name}`, 'staffData')

    }

    customSimple = (type, state) => {
        this.props.sharedActions.getDataWithoutDispatch(
            `/api/${type}`
        ).then(success => { 
            this.setState({
                [state]: success,
            })
            let staffId = success && success.classes && success.classes.length > 0 && success.classes[0].staffId
            this.getPaginationSimple(`ShortLeaves/?staffId=${staffId}&pageSize=${this.state.dataLimit}&pageNumber=${this.state.currentPage}`, 'leaveData')


        }).catch(error => {
            this.setState({
                msg: error.response ? error.response.data.Message : 'Error Occured!!',
                isLoading: false
            })
            this.props.snackbar();
            console.error('Error occured', error);
        })
    }

    
    getSimple = (type, state) => {
        this.props.sharedActions.getDataWithoutDispatch(
            `/api/${type}`
        ).then(success => { 
            this.setState({
                [state]: success,
                isLoading: false
            })


        }).catch(error => {
            this.setState({
                msg: 'Error Occured!!',
                errorMsg: true,
                isLoading: false
            })
            console.error('Error occured', error);
        })
    }

    getPaginationSimple = (type, state) => {
        this.props.sharedActions.getDataWithoutDispatch(
            `/api/${type}`
        ).then(success => { 
            this.setState({
                [state]: success,
                isLoading: false
            })
            this.setState({
                currentPage: success.paginationMetadata.currentPage,
                disableBPage: success.paginationMetadata.previousPage == 'No' ? true : false,
                disablepage: success.paginationMetadata.nextPage == 'No' ? true : false,
                pageNumber: Array.from(new Array(success.paginationMetadata.totalPages), (val, index) => index + 1)
            })



        }).catch(error => {
            this.setState({
                msg: error.response ? error.response.data.Message : 'Error Occured!!',
                isLoading: false
            })
            this.props.snackbar();

            console.error('Error occured', error);
        })
    }

    
	handleClickNextFrwd = () => {
		const {  currentPage, pageNumber } = this.state;
		console.log('current page: ', currentPage, pageNumber);
        if (currentPage === pageNumber.length) {
			this.setState({
				disablepage: true
            }, () => {
                this.handleSearch()

            });

            
		} else {
			this.setState({
				currentPage: currentPage + 1,
				disableBPage: false
            }, () => {
                this.handleSearch()

            });
            
            
		}
    };
    
	handleClickNextBack = () => {
		const { currentPage } = this.state;
		console.log('current page: ', currentPage);
		if (currentPage === 1) {
			this.setState({
				disableBPage: true,
				disablepage: false
            }, () => {
                this.handleSearch()

            });
		} else {
			this.setState({
                currentPage: currentPage - 1,
				disablepage: false
            }, () => {
                this.handleSearch()

            });
		}
    };
    
	handleClickNext = event => {
        const { currentPage, pageNumber } = this.state
                this.setState({
                    currentPage: Number(event.target.id),
                    disablepage: false,
                    disableBPage: false
                }, () => {
                    this.handleSearch()
    
                });
		
	};

    getDoubleState = (type, state) => {
        this.props.sharedActions.getDataWithoutDispatch(
            `/api/${type}`
        ).then(success => { 
            this.setState({
                [state]: success,
                contData: success,
                isLoading: false
            })


        }).catch(error => {
            this.setState({
                msg: 'Error Occured!!',
                errorMsg: true,
                isLoading: false
            })
            console.error('Error occured', error);
        })
    }
    



    PrintContent = el => {
        var restorepage = document.body.innerHTML;
        var Printcontent = document.getElementById(el).innerHTML;
        document.body.innerHTML = Printcontent;
        
        setTimeout(() => {
            window.print();
            window.location.reload();
        }, 700)
        document.body.innerHTML = restorepage;
        window.reload();
        
    };



    myFunction = () => {
		// Get the snackbar DIV
		var x = document.getElementById('snackbar');

		// StepperGeneralButtons the "show" class to DIV
		x.className = 'show';

		// After 3 seconds, remove the show class from DIV
		setTimeout(function() {
		}, 3000);
	};



    btnTechHover = () => {
		$(document).ready(function() {
			$('button').hover(
				function() {
					$(this).css('box-shadow', '0px 5px 5px grey');
					$(this).css('border-color', 'white');
				},
				function() {
					$(this).css('box-shadow', '0px 0px 0px white');
				}
			);
		});
	};

    onHandleText = e => {
		this.setState({
			[e.target.name]: e.target.value
        });
        console.log([e.target.name], e.target.value)
    };

    onHandleFilter = e => {
        this.setState({
			[e.target.name]: e.target.value
        })
        console.log([e.target.name], e.target.value)
    };
    


    render(){

        const { search, isLoading, editToggle, msg, accountId, accountType, classSectionGrade, session, studentSkill, date,
            classId, sectionId, subjectId, searchDate, sessionId,
            currentPage, pageNumber, disableBPage, disablepage, 
            filePath, leaveData
        } = this.state;

        const Loader = (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div className="spinner-border" style={{ color: this.context.themeColors.primaryColor }}></div>
            </div>
        );


        //Spelling Mistake from Backend
        const renderLeavesData = leaveData && leaveData.shorLeaves.map((item, index) => {
            return (
                <tr key={index} >
                    <th scope="row" style={{ padding: 1 }} >
                        {index + 1}
                    </th>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.date}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.checkIn}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.checkOut}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.hours}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.type}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.needOfOtherResource}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.description}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.status}
                    </td>
                </tr>
                
                );
            
        }) 

        return (
            <div>
				<section  className="mb-4">
				<h2  
                        style={{ background: this.context.themeColors.primaryColor }}
                        className="h1-responsive font-weight-bold text-center  generalHead">
                        View Short Leave
                    </h2>
			    <div>
                <SnackBar msg={msg} />
                <div style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                }}>
                            <div class="row my-2">
                                <div class="col-md-6">
                                <Pagination
                                    disableBPage={disableBPage}
                                    disablepage={disablepage}
                                    currentPage={currentPage}
                                    pageNumber={pageNumber}
                                    handleClickNext={this.handleClickNext}
                                    handleClickNextBack={this.handleClickNextBack}
                                    handleClickNextFrwd={this.handleClickNextFrwd}
                                />
                                </div>
                            </div>
                            
                    <div className="table-responsive">
					<table className="table table-hover text-center">
						<thead style={{ background: this.context.themeColors.primaryColor, color: 'white' }}>
							<tr>
								<th scope="col" style={{ padding: 1 }}>
									#
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Application Date
								</th>
								<th scope="col" style={{ padding: 1 }}>
                                    Check In
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Check Out
								</th>
                                <th scope="col" style={{ padding: 1 }}>
									No. of Hours
								</th>
								<th scope="col" style={{ padding: 1 }}>
                                    Leave Type
								</th>
                                <th scope="col" style={{ padding: 1 }}>
                                    Need Of Other Resources
								</th>
                                <th scope="col" style={{ padding: 1 }}>
                                    Description
								</th>
                                <th scope="col" style={{ padding: 1 }}>
                                    Status
								</th>
							</tr>
						</thead>
						<tbody>{isLoading ? Loader : renderLeavesData}</tbody>
					</table>
				</div>
                </div>
            </div>
            </section>
            </div>
        );
    }

}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch),
	};
}
function mapStateToProps(state) {
	return {
		userData: state.setUserReducer.users,
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ViewStaffShortLeave);
