import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as sharedActions from "../../Actions/sharedActions";
import DateandTime from "../Transport/DateandTime";
import isNull from "lodash/isNull";
import moment from "moment";

import SnackBar from "../../components/SnackBar/SnackBar";
import LoaderWrapper from "../../components/HOC/LoaderWrapper";
import ThemeContext from "../../context/themeContext/ThemeContext";

class IssueBook extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMsg: false,
      successMsg: false,
      msg: "",
      isLoading: false,
      backColor: "",

      bookData: null,
      bookName: null,
      author: null,

      campus: null,
      departmentId: null,
      postId: null,
      staffId: null,

      student: null,
      classId: null,
      sectionId: null,
      studentId: null,

      issuedTo: null,
      bookBarcodeNo: null,
      issueDate: moment(new Date()).format("yyyy-MM-DD"),
      expiryDate: null,
    };
  }

  componentDidMount() {
    this.getSimple("campusDropdown", "campus");
    this.getSimple("Student", "student");
  }

  getSimple = (type, state) => {
    this.props.sharedActions
      .getDataWithoutDispatch(`/api/${type}`)
      .then((success) => {
        this.setState({
          [state]: success,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          msg: "Error Occured!!",
          isLoading: false,
        });
        this.props.snackbar();
        console.error("Error occured", error);
      });
  };

  myFunction = () => {
    // Get the snackbar DIV
    var x = document.getElementById("snackbar");

    // StepperGeneralButtons the "show" class to DIV
    x.className = "show";

    // After 3 seconds, remove the show class from DIV
    setTimeout(function () { }, 3000);
  };

  onHandleTextChange = (e) => {
    console.log([e.target.name], e.target.value);

    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  checkBookBarcode = (barcode) => {
    this.setState({
      isLoading: true,
    });
    this.props.sharedActions
      .getDataWithoutDispatch(`/api/Books/?barcode=${barcode}`)
      .then((success) => {
        this.setState({
          bookData: success[0],
          bookName: success[0].bookName,
          author: success[0].author,
          bookId: success[0].id,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          msg: error.response ? error.response.data.Message : "Error Occured!!",
          isLoading: false,
        });
        this.props.snackbar();
        console.error("Error name: ", error);
      });
  };

  handleStudentSubmit = (e) => {
    e.preventDefault();
    const {
      classId,
      sectionId,
      studentId,
      bookId,
      issueDate,
      expiryDate,
    } = this.state;
    if (
      isNull(classId) ||
      isNull(sectionId) ||
      isNull(studentId) ||
      isNull(bookId) ||
      isNull(expiryDate)
    ) {
      this.setState({
        successMsg: false,
        errorMsg: true,
        msg: "Please fill the form respectively!",
      });
    } else {
      const issueDateBreak = issueDate.split("-");
      const expiryDateBreak = expiryDate.split("-");

      const parsedIssueDate = `${issueDateBreak[1]}/${issueDateBreak[2]}/${issueDateBreak[0]}`;
      const parsedExpiryDate = `${expiryDateBreak[1]}/${expiryDateBreak[2]}/${expiryDateBreak[0]}`;

      let data = {
        accountId: this.props.userData.unique_name,
        accountType: this.props.userData.role,
        status: "Issued",
        classId,
        sectionId,
        studentId,
        bookId,
        dateOfIssue: parsedIssueDate,
        dateOfExpiry: parsedExpiryDate,
      };

      console.log(data);

      this.props.sharedActions
        .simpleAddRequest(`/api/IssueBooks`, data)
        .then((success) => {
          console.log(success);

          this.setState({
            msg: "Book Issued!",
            isLoading: false,
            classId: "",
            sectionId: "",
            studentId: "",
            bookId: "",
            author: "",
            bookBarcodeNo: "",
            bookName: "",
            expiryDate: "",
            staffId: "",
          });
          this.props.snackbar();
        })
        .catch((error) => {
          this.setState({
            msg: error.response
              ? error.response.data.Message
              : "Error Occured!!",
            isLoading: false,
          });
          this.props.snackbar();
          console.error("Error occured", error);
        });
    }
  };

  handleStaffSubmit = (e) => {
    e.preventDefault();
    const { staffId, bookId, issueDate, expiryDate } = this.state;
    if (isNull(staffId) || isNull(bookId) || isNull(expiryDate)) {
      this.setState({
        successMsg: false,
        errorMsg: true,
        msg: "Please fill the form respectively!",
      });
    } else {
      const issueDateBreak = issueDate.split("-");
      const expiryDateBreak = expiryDate.split("-");

      const parsedIssueDate = `${issueDateBreak[1]}/${issueDateBreak[2]}/${issueDateBreak[0]}`;
      const parsedExpiryDate = `${expiryDateBreak[1]}/${expiryDateBreak[2]}/${expiryDateBreak[0]}`;

      let data = {
        accountId: this.props.userData.unique_name,
        accountType: this.props.userData.role,
        status: "Issued",
        staffId,
        bookId,
        dateOfIssue: parsedIssueDate,
        dateOfExpiry: parsedExpiryDate,
      };

      console.log(data);

      this.props.sharedActions
        .simpleAddRequest(`/api/IssueBooks`, data)
        .then((success) => {
          console.log(success);

          this.setState({
            msg: "Book Issued!",
            isLoading: false,
            classId: "",
            sectionId: "",
            studentId: "",
            bookId: "",
            author: "",
            bookBarcodeNo: "",
            bookName: "",
            expiryDate: "",
            staffId: "",
          });
          this.props.snackbar();
        })
        .catch((error) => {
          this.setState({
            msg: error.response.data.Message,
            isLoading: false,
          });
          this.props.snackbar();

          console.error("Error occured");
        });
    }
  };

  checkStudent = (studentId) => {
    const { student } = this.state;
    let f = student.student.filter((data) => {
      return data.studentId == studentId;
    });

    if (f.length > 0) {
      this.setState({
        classId: f[0].classId,
        sectionId: f[0].sectionId,
        msg: "Student Found!!",
      });
      this.props.snackbar();
    } else {
      this.setState({
        msg: "Student Not Found!!",
      });
      this.props.snackbar();
    }
  };

  checkStaff = (staffId) => {
    const { campus } = this.state;
    let staff = campus.staff.filter((data) => data.staffId == staffId);

    if (staff.length > 0) {
      let dept = campus.department.filter(
        (data) => data.departmentId == staff[0].departmentId
      );
      let post = campus.post.filter((data) => data.postId == staff[0].postId);
      if (dept.length > 0 && post.length > 0) {
        this.setState({
          departmentId: dept[0].departmentId,
          postId: post[0].postId,
          staffId: staff[0].staffId,
          msg: "Staff Found!!",
        });
        this.props.snackbar();
      }
    } else {
      this.setState({
        msg: "Student Not Found!!",
      });
      this.props.snackbar();
    }
  };

  render() {
    const {
      successMsg,
      errorMsg,
      msg,
      isLoading,
      issuedTo,
      bookBarcodeNo,
      issueDate,
      expiryDate,
      student,
      classId,
      sectionId,
      studentId,
      campus,
      departmentId,
      postId,
      staffId,
      bookData,
      bookName,
      author,
    } = this.state;

    const staffForm = (
      <div>
        <div className="row">
          <div className="col-md-6">
            <label for="staffId">Staff ID</label>
            <div className="input-group md-form mb-3">
              <input
                class="form-control"
                type="text"
                value={staffId}
                name="staffId"
                onChange={this.onHandleTextChange}
                disabled={!campus}
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary"
                  disabled={!staffId && !campus}
                  onClick={() => this.checkStaff(staffId)}
                  type="button"
                >
                  <i class="fas fa-search"></i>
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="md-form mb-0">
              <label for="departmentId">Department</label>
              <select
                class="custom-select"
                value={departmentId}
                name="departmentId"
                onChange={this.onHandleTextChange}
                disabled={!campus}
              >
                <option value="">Please Select Department</option>
                {campus &&
                  campus.department.map((value, index) => {
                    return (
                      <option value={value.departmentId}>
                        {value.departmentName}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="md-form mb-0">
              <label for="postId">Post</label>
              <select
                class="custom-select"
                value={postId}
                name="postId"
                onChange={this.onHandleTextChange}
                disabled={!departmentId}
              >
                <option value="">Please Select post</option>
                {campus &&
                  campus.post.map((value, index) => {
                    return (
                      value.departmentId == departmentId && (
                        <option value={value.postId}>{value.postName}</option>
                      )
                    );
                  })}
              </select>
            </div>
          </div>
          <div className="col-md-6">
            <div className="md-form mb-0">
              <label for="staffId">Staff</label>
              <select
                class="custom-select"
                value={staffId}
                name="staffId"
                onChange={this.onHandleTextChange}
                disabled={!postId}
              >
                <option value="">Please Select Staff</option>
                {campus &&
                  campus.staff.map((value, index) => {
                    return (
                      value.departmentId == departmentId &&
                      value.postId == postId && (
                        <option value={value.staffId}>{value.staffName}</option>
                      )
                    );
                  })}
              </select>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <label for="bookBarcodeNo">Book Barcode No.</label>
            <div className="input-group mb-3">
              <input
                class="custom-select"
                type="text"
                value={bookBarcodeNo}
                name="bookBarcodeNo"
                onChange={this.onHandleTextChange}
                disabled={!staffId}
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary"
                  disabled={!staffId}
                  onClick={() => this.checkBookBarcode(bookBarcodeNo)}
                  type="button"
                >
                  Check
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="md-form mb-0">
              <label for="bookName">Book</label>
              <input
                class="custom-select"
                type="text"
                value={bookName}
                name="bookName"
                disabled
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="md-form mb-0">
              <label for="author">Author/Publisher</label>
              <input
                class="custom-select"
                type="text"
                value={author}
                name="author"
                disabled
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="md-form mb-0">
              <label for="issueDate">Issue Date</label>
              <input
                class="custom-select"
                type="date"
                value={issueDate}
                name="issueDate"
                onChange={this.onHandleTextChange}
                disabled
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="md-form mb-0">
              <label for="expiryDate">Return Date</label>
              <input
                class="custom-select"
                type="date"
                value={expiryDate}
                name="expiryDate"
                onChange={this.onHandleTextChange}
                disabled={!bookData}
              />
            </div>
          </div>
        </div>
      </div>
    );

    const studentForm = (
      <div>
        <div className="row">
          <div className="col-md-6">
            <label for="studentId">Student ID</label>
            <div className="input-group md-form mb-3">
              <input
                class="form-control"
                type="text"
                value={studentId}
                name="studentId"
                onChange={this.onHandleTextChange}
                disabled={!student && !campus}
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary"
                  disabled={!studentId && !student && !campus}
                  onClick={() => this.checkStudent(studentId)}
                  type="button"
                >
                  <i class="fas fa-search"></i>
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="md-form mb-0">
              <label for="classId">Class</label>
              <select
                class="custom-select"
                value={classId}
                name="classId"
                onChange={this.onHandleTextChange}
                disabled={!student}
              >
                <option value="">Please Select Class</option>
                {student &&
                  student.classes.map((value, index) => {
                    return (
                      <option value={value.classId}>{value.className}</option>
                    );
                  })}
              </select>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="md-form mb-0">
              <label for="sectionId">Section</label>
              <select
                class="custom-select"
                value={sectionId}
                name="sectionId"
                onChange={this.onHandleTextChange}
                disabled={!classId}
              >
                <option value="">Please Select Section</option>
                {student &&
                  student.sections.map((value, index) => {
                    return (
                      value.classId == classId && (
                        <option value={value.sectionId}>{value.section}</option>
                      )
                    );
                  })}
              </select>
            </div>
          </div>

          <div className="col-md-6">
            <div className="md-form mb-0">
              <label for="studentId">Student</label>
              <select
                class="custom-select"
                value={studentId}
                name="studentId"
                onChange={this.onHandleTextChange}
                disabled={!sectionId}
              >
                <option value="">Please Select Student</option>
                {student &&
                  student.student.map((value, index) => {
                    return (
                      value.classId == classId &&
                      value.sectionId == sectionId && (
                        <option value={value.studentId}>{value.Student}</option>
                      )
                    );
                  })}
              </select>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <label for="bookBarcodeNo">Book Barcode No.</label>
            <div className="input-group mb-3">
              <input
                class="custom-select"
                type="text"
                value={bookBarcodeNo}
                name="bookBarcodeNo"
                onChange={this.onHandleTextChange}
                disabled={!studentId}
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary"
                  disabled={!studentId}
                  onClick={() => this.checkBookBarcode(bookBarcodeNo)}
                  type="button"
                >
                  Check
                </button>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="md-form mb-0">
              <label for="bookName">Book</label>
              <input
                class="custom-select"
                type="text"
                value={bookName}
                name="bookName"
                disabled
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="md-form mb-0">
              <label for="author">Author/Publisher</label>
              <input
                class="custom-select"
                type="text"
                value={author}
                name="author"
                disabled
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="md-form mb-0">
              <label for="issueDate">Issue Date</label>
              <input
                class="custom-select"
                type="date"
                value={issueDate}
                name="issueDate"
                onChange={this.onHandleTextChange}
                disabled
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="md-form mb-0">
              <label for="expiryDate">Return Date</label>
              <input
                class="custom-select"
                type="date"
                value={expiryDate}
                name="expiryDate"
                onChange={this.onHandleTextChange}
                disabled={!bookData}
              />
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <div className="container">
        <section className="mb-4">
          <ThemeContext.Consumer>
            {(context) => (
              <h2
                style={{ background: context.themeColors.primaryColor }}
                className="h1-responsive font-weight-bold text-center my-4 generalHead"
              >
                Issue Book
              </h2>
            )}
          </ThemeContext.Consumer>

          <SnackBar msg={this.state.msg} backColor={this.state.backColor} />

          <p class="text-center w-responsive mx-auto mb-5" />

          <div
            className="row"
            style={{
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <ThemeContext.Consumer>
              {(context) => (
                <div
                  className="col-md-9 mb-md-0 mb-5"
                  style={{
                    border: "solid",
                    borderColor: context.themeColors.primaryColor,
                    padding: 40,
                    borderRadius: 15,
                    width: "auto",
                  }}
                >
                  <LoaderWrapper isLoading={this.state.isLoading}>
                    <form
                      id="payroll-form"
                      name="payroll-form"
                      onSubmit={
                        (issuedTo === "student" && this.handleStudentSubmit) ||
                        (issuedTo === "staff" && this.handleStaffSubmit)
                      }
                    >
                      <div className="row">
                        <div className="col-md-12">
                          <div className="md-form mb-0">
                            <DateandTime />
                          </div>
                        </div>
                      </div>
                      <br />
                      <div class="row">
                        <div class="col-md-6">
                          <div class="md-form mb-0">
                            <label for="AdminName" class="">
                              Admin Name
                            </label>
                            <input
                              style={{ borderColor: "#01AC8A" }}
                              type="text"
                              name="AdminName"
                              class="form-control"
                              readOnly={true}
                              value={this.props.userData.unique_name}
                            />
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="md-form mb-0">
                            <label for="AdminType" class="">
                              Admin Type
                            </label>
                            <input
                              style={{ borderColor: "#01AC8A" }}
                              type="text"
                              name="AdminType"
                              class="form-control"
                              readOnly={true}
                              value={this.props.userData.role}
                            />
                          </div>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-md-12">
                          <div className="md-form mb-0">
                            <label for="issuedTo">Issued to</label>
                            <select
                              class="custom-select"
                              value={issuedTo}
                              name="issuedTo"
                              onChange={this.onHandleTextChange}
                            >
                              <option value="">
                                Please Select Issued Type
                              </option>
                              <option value="student">Student</option>
                              <option value="staff">Staff</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      {issuedTo == "student" && studentForm}
                      {issuedTo == "staff" && staffForm}
                      <br />
                      <br />
                      <div className="text-center text-md-center">
                        <button
                          type="submit"
                          className="btns"
                          style={{
                            background: context.themeColors.primaryColor,
                          }}
                        >
                          Add
                          <span
                            style={{
                              marginBottom: 5,
                            }}
                            className={
                              isLoading && "spinner-border spinner-border-sm"
                            }
                          ></span>
                        </button>
                      </div>
                    </form>
                  </LoaderWrapper>
                  <div class="status" />
                </div>
              )}
            </ThemeContext.Consumer>
          </div>
        </section>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    sharedActions: bindActionCreators(sharedActions, dispatch),
  };
}
function mapStateToProps(state) {
  return {
    userData: state.setUserReducer.users,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(IssueBook);
