import React, { useEffect, useState, useContext } from 'react'
import $ from 'jquery';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import SelectionInput from '../../components/SelectionInput/SelectionInput';
import Pagination from '../../components/Pagination/Pagination'
import Spinner from '../../components/Spinner/Spinner';
import httpWithRequiredSetData from '../../hooks/Services/httpWithRequiredSetData';
import general from '../../utils/general'
import Colors from '../../utils/app_constants/colors_constants';
import CustomFilterSectionLayout from '../../components/CustomFilterSectionLayout/CustomFilterSectionLayout';
import ThemeContext from "../../context/themeContext/ThemeContext";

let tHead = [
    'Status',
    'Student Name',
    'Class',
    'Section',
    'Session',
    'Actions'
];

let renderFields = [
    'status',
    'name',
    'className',
    'section',
    'session',
]

const TransferStudent = props => {
    const themeColor = useContext(ThemeContext);

    const [accountId, setAccountId] = useState(props.name)
    const [accountType, setAccountType] = useState(props.role)
    const [classId, setClassId] = useState('');
    const [postClassId, setPostClassId] = useState('')
    const [className, setClassName] = useState('')
    const [sectionId, setSectionId] = useState('');
    const [postSectionId, setPostSectionId] = useState('')
    const [section, setSection] = useState('')
    const [postSessionId, setPostSessionId] = useState('')
    const [session, setSession] = useState('')
    const [modalData, setModalData] = useState([])
    const [id, setId] = useState('')
    const [studentId, setStudentId] = useState('')
    const [error, setError] = useState(false)
    const [errorMsg, setErrorMsg] = useState(false)
    const [update, setUpdate] = useState('')

    const [classData, setClassData] = useState([]);
    const [postClassData, setPostClassData] = useState([])
    const [sectionData, setSectionData] = useState([]);
    const [postSectionData, setPostSectionData] = useState([]);
    const [postSessionData, setPostSessionData] = useState([]);
    const [studentData, setStudentData] = useState([]);

    const [isClassSectionLoading, classSectionData] = httpWithRequiredSetData(
        '/api/ClassesSections',
        [],
        data => {
            setClassData(data['classes']);
            setPostClassData(data['classes'])
        }
    );

    const [isSessionLoading, sessions] = httpWithRequiredSetData(
        '/api/session',
        [],
        data => {
            setPostSessionData(data['session'])
        }
    );

    const [searchURL, setSearchURL] = useState(
        `/api/GetListOfAllStudentCurrentClassSection?classId=&sectionId=&pageNumber=${1}&pageSize=${10}`)

    const [disableBPage, setdisableBPage] = useState(true);
    const [customPageSize, setCustomPageSize] = useState('10');
    const [currentPage, setCurrentPage] = useState(1);
    const [pageNumber, setPageNumber] = useState([]);
    const [disablepage, setdisablepage] = useState(false);
    const [pagination, setPaginationData] = useState({});

    const [
        isLoading,
        fetchData,
        setFetchData,
        setIsLoading
    ] = httpWithRequiredSetData(searchURL, [], data => {
        let pageNumber = [];
        for (let i = 1; i <= data['paginationMetadata']['totalPages']; i++) {
            pageNumber.push(i);
        }
        setPageNumber(pageNumber);
        setStudentData(data['studentList']);

        setPaginationData(data['paginationMetadata']);
    });

    const onClassChange = inputClassId => {
        setSectionId('');
        if (inputClassId !== 'Choose Class') {

            setIsLoading(true);
            setClassId(inputClassId);

            let getSection = general.dataFilteration(
                classSectionData['gradeSection'],
                ['sectionId', 'section'],
                {
                    id: inputClassId
                }
            );

            setSectionData(getSection)
        }
    };

    const onHandleClassChange = e => {
        if (e.target.value !== 'Class') {

            setPostClassId(e.target.value);
            const filteredClass = postClassData.filter(item => parseInt(item.id) === parseInt(e.target.value))
            setClassName(filteredClass[0].className)

            let getSection = general.dataFilteration(
                classSectionData['gradeSection'],
                ['sectionId', 'section'],
                {
                    id: e.target.value
                }
            );

            setPostSectionId('')
            setPostSectionData(getSection)
        }
    }


    const onHandleChange = e => {
        if (e.target.name === 'sectionId') {
            if (e.target.value !== 'Section') {
                setSectionId(e.target.value);
            }
        } else if (e.target.name === 'postSectionId') {
            if (e.target.value !== 'Section') {
                setPostSectionId(e.target.value);
                const filteredSection = postSectionData.filter(item => parseInt(item.sectionId) === parseInt(e.target.value))
                setSection(filteredSection[0].section)
            }
        } else if (e.target.name === 'postSessionId') {
            if (e.target.value !== 'Session') {
                setPostSessionId(e.target.value);
                const filteredSession = postSessionData.filter(item => parseInt(item.sessionId) === parseInt(e.target.value))
                setSession(filteredSession[0].currentSession)
            }
        }
    }

    useEffect(() => {
        if (error || errorMsg) {
            setTimeout(() => {
                setError(false)
                setErrorMsg(false)
            }, 3000);
        }
    });

    useEffect(() => {
        return () => {
            $('[data-toggle="tooltip"]').tooltip('hide');
        };
    }, []);

    const handleClickNextFrwd = () => {
        console.log('pagelength: ', pageNumber.length);
        console.log('current page: ', currentPage);
        if (currentPage === pageNumber.length) {
            setdisablepage(true);
        } else {
            setCurrentPage(prevState => prevState + 1);
            console.log(currentPage);

            setdisableBPage(false);
        }
    };

    useEffect(() => {
        console.log('Do something after counter has changed', currentPage);
        setIsLoading(true);
        props.sharedActions
            .getDataWithoutDispatch(
                general.condtionalServicesTransferStudent(
                    classId,
                    sectionId,
                    currentPage,
                    customPageSize
                )
            )
            .then(success => {
                setStudentData(success['studentList']);
                setIsLoading(false);
                let pageNumber = [];
                for (let i = 1; i <= success['paginationMetadata']['totalPages']; i++) {
                    pageNumber.push(i);
                }
                setPageNumber(pageNumber);
                setPaginationData(success['paginationMetadata']);
            })
            .catch(error => {
                setIsLoading(false);
                setStudentData([])
                setPaginationData({})
            });
    }, [currentPage, classId, sectionId, update, customPageSize]);
    const handleClickNext = event => {
        setCurrentPage(Number(event.target.id));
        setdisableBPage(false);
        setdisableBPage(false);
    };
    const handleClickNextBack = () => {
        if (currentPage === 1) {
            setdisableBPage(true);
            setdisablepage(false);
        } else {
            setCurrentPage(prevState => prevState - 1);
        }
    };
    const [msg, setSnackMsg] = useState('');

    const onChangePageSize = e => {
        if (e.target.value !== 'Page Entries') {
            setCustomPageSize(e.target.value);
            setCurrentPage(1);
        }
    };

    const onTakeItem = (itemId) => {
        setModalData([])

        let getData = studentData.filter(item => item.id === itemId);

        setModalData(getData)
        setStudentId(getData[0].studentAdmissionFormId)
        setId(getData[0].id)
    }

    const onEditClick = itemId => {
        onTakeItem(itemId)
    }

    const onHandleTransfer = e => {
        e.preventDefault();

        if (postClassId === '' ||
            postSectionId === '' ||
            postSessionId === ''
        ) {
            setError(true)
            setErrorMsg(true)
        } else {

            modalData[0].classId = postClassId
            modalData[0].sectionId = postSectionId
            modalData[0].sessionId = postSessionId
            modalData[0].className = className
            modalData[0].section = section
            modalData[0].session = session

            const data = {
                studentId,
                classId: postClassId,
                sectionId: postSectionId,
                sessionId: postSessionId,
                accountId,
                accountType
            }
            props.sharedActions
                .simpleAddRequest(`/api/AssignNextClassToStudent/?id=${id}`,
                    data
                )
                .then(success => {
                    studentData.forEach((item, index) => {
                        if (item.id === id) {
                            studentData.splice(index, 1, modalData[0])
                        }
                    })
                    $('#myModal').modal('toggle'); //or  $('#IDModal').modal('hide');
                    setSnackMsg('Transferred Successfully!')
                    setErrorMsg(false)
                    setError(false)
                    setPostSessionId('')
                    setPostClassId('')
                    setPostSectionData('')
                    setPostSectionId('')
                    props.snackbar();
                    setTimeout(() => {
                        setUpdate(id)
                    }, 2000);
                })
        }
    }

    const bodyRendering = data =>
        data &&
        data.map((item, index) => (
            <tr>
                <th
                    style={{
                        padding: 2,
                        border: `1px solid ${themeColor.themeColors.primaryColor}`,
                        borderColor: themeColor.themeColors.primaryColor
                    }}
                    scope="row"
                >
                    {index + 1}
                </th>
                {renderFields.map((item1, index) => (
                    <td
                        style={{
                            fontWeight: `${index == 0 ? 'bold' : 'normal'}`,
                            border: `1px solid ${themeColor.themeColors.primaryColor}`,
                            padding: 2,
                            borderColor: themeColor.themeColors.primaryColor
                        }}
                    >
                        {item[item1]}
                    </td>
                ))}
                <td
                    style={{
                        border: `1px solid ${themeColor.themeColors.primaryColor}`,
                        padding: 2,
                        borderColor: themeColor.themeColors.primaryColor
                    }}
                >
                    <a href="#" data-toggle="tooltip" title="Transfer!">
                        <button
                            id="firstbutton"
                            onClick={() => onEditClick(item.id)}
                            data-toggle="modal"
                            data-target="#myModal"
                            style={{
                                cursor: 'pointer',
                                fontSize: 20,
                                border: 'none',
                                marginLeft: 5,
                                background: 'none',
                                marginBottom: 5
                            }}
                        ><ThemeContext.Consumer>
                            {(context)=>(

                           
                            <i style={{ color:context.themeColors.primaryColor }} class="fas fa-exchange-alt"></i>
                            )}
                            </ThemeContext.Consumer>
                            </button>
                    </a>
                </td>
            </tr>
        ));

    return (
        <div>
            <div class="modal" id="myModal">
                <div class="modal-dialog modal-md modal-dialog-centered">
                    <div class="modal-content modal_content_custom">
                        <div
                            style={{
                                borderBottomLeftRadius: 30,
                                borderBottomRightRadius: 30,
                                borderTopLeftRadius: 18,
                                borderTopRightRadius: 18, background: themeColor.themeColors.primaryColor
                            }}
                            class="modal-header modal_custom_header"
                        >
                            <h4 style={{ color: 'white' }} class="modal-title">
                                Transfer To
								</h4>
                            <button
                                style={{ color: 'white' }}
                                type="button"
                                class="close"
                                data-dismiss="modal"
                            >
                                &times;
								</button>
                        </div>
                        <div class="modal-body">
                            <form>
                                {errorMsg ? (
                                    <p style={{ color: 'red', textAlign: 'center' }}>
                                        Please fill the form properly
                                    </p>
                                ) : (
                                        ''
                                    )}
                                {modalData &&
                                    modalData.map((item, index) => (
                                        <div key={index}>
                                            <fieldset>
                                                <div class='row'>

                                                    <SelectionInput
                                                        feildName={'postClassId'}
                                                        selectName={'Class'}
                                                        onHandleChange={e => onHandleClassChange(e)}
                                                        errors={{}}
                                                        optionsArrys={postClassData}
                                                        selectedText={'Class'}
                                                        stateData={{
                                                            postClassId
                                                        }}
                                                        // iconClassName={'fas fa-building'}
                                                        optionType="dynamicWithPropIdAndName"
                                                        property={'className'}
                                                        propertyId={'id'}
                                                        successflag={'successflag'}
                                                        isValidte={false}
                                                    // editUse={'staffEdit'}
                                                    />

                                                    <SelectionInput
                                                        feildName={'postSectionId'}
                                                        selectName={'Section'}
                                                        onHandleChange={e => onHandleChange(e)}
                                                        errors={{}}
                                                        optionsArrys={postSectionData}
                                                        selectedText={'Section'}
                                                        stateData={{
                                                            postSectionId: postSectionId
                                                        }}
                                                        // iconClassName={'fas fa-building'}
                                                        optionType="dynamicWithPropIdAndName"
                                                        property={'section'}
                                                        propertyId={'sectionId'}
                                                        successflag={'successflag'}
                                                        isValidte={false}
                                                    // editUse={'staffEdit'}
                                                    />

                                                    <SelectionInput
                                                        feildName={'postSessionId'}
                                                        selectName={'Session'}
                                                        onHandleChange={e => onHandleChange(e)}
                                                        errors={{}}
                                                        optionsArrys={postSessionData}
                                                        selectedText={'Session'}
                                                        stateData={{ postSessionId: postSessionId }}
                                                        // iconClassName={'fas fa-building'}
                                                        optionType="dynamicWithPropIdAndName"
                                                        property={'currentSession'}
                                                        propertyId={'sessionId'}
                                                        successflag={'successflag'}
                                                        isValidte={false}
                                                    // editUse={'staffEdit'}
                                                    />

                                                </div>
                                            </fieldset>
                                            <br />
                                        </div>
                                    ))}
                            </form>
                        </div>
                        <div class="modal-footer">
                            <button
                                type="button"
                                style={{ background: '#00435D' }}
                                class="btn btn-danger"
                                onClick={e => onHandleTransfer(e)}
                            >
                                Transfer
								</button>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{ textAlign: 'right' }}>
                <SnackBar msg={msg} />
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        <h2 className="h1-responsive font-weight-bold text-left my-4 generalHead" style={{background: themeColor.themeColors.primaryColor }}>
                            {' '}
                            Transfer Student{' '}
                        </h2>
                    </div>
                    <div
                        style={{ marginTop: 40, display: 'flex', flexDirection: 'column' }}
                    >
                        <Pagination
                            disableBPage={disableBPage}
                            disablepage={disablepage}
                            currentPage={currentPage}
                            pageNumber={pageNumber}
                            handleClickNext={handleClickNext}
                            handleClickNextBack={handleClickNextBack}
                            handleClickNextFrwd={handleClickNextFrwd}
                        />
                        <div
                            style={{
                                width: 'fit-content',
                                marginTop: -34,
                                display: 'flex',
                                justifyContent: 'flex-end',
                                marginLeft: 'auto'
                            }}
                        >
                            <SelectionInput
                                selectName="Entries"
                                selectFont={14}
                                selectFontWeight={'bold'}
                                newFlag="customWidth"
                                feildName="customPageSize"
                                selectedText={'Page Entries'}
                                optionsArrys={['10', '20', '30', '40', '50']}
                                errors={{}}
                                isValidte={false}
                                stateData={{
                                    customPageSize: customPageSize
                                }}
                                optionType="static"
                                onHandleChange={onChangePageSize}
                                useFlag={false}
                            />
                        </div>
                    </div>
                </div>
                <CustomFilterSectionLayout displayDirection="column">
                    <div
                        style={{
                            width: '30%',
                            margin: '0 auto',
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                        <React.Fragment>
                            <SelectionInput
                                newFlag="customWidth"
                                feildName="classId"
                                selectName={'Class'}
                                selectedText={'Choose Class'}
                                optionsArrys={classData}
                                errors={{}}
                                isValidte={false}
                                stateData={{
                                    classId: classId
                                }}
                                optionType="dynamic"
                                onHandleChange={e => onClassChange(e.target.value)}
                                property={'className'}
                                propertyId={'id'}
                                useFlag={false}
                            />

                            <SelectionInput
                                selectName="Section"
                                newFlag="customWidth"
                                feildName="sectionId"
                                selectedText={'Section'}
                                optionsArrys={sectionData}
                                errors={{}}
                                isValidte={false}
                                stateData={{
                                    sectionId: sectionId
                                }}
                                optionType="dynamic"
                                onHandleChange={e => onHandleChange(e)}
                                property={'section'}
                                propertyId={'sectionId'}
                                useFlag={false}
                            />

                        </React.Fragment>
                    </div>
                </CustomFilterSectionLayout>

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span className="customBadge" style={{ fontSize: 14 }}>
                        Page: {currentPage}
                    </span>

                    <span className="customBadge" style={{ fontSize: 14 }}>
                        Total Page: {pagination['totalPages']}
                    </span>
                </div>
            </div>
            {isLoading ? (
                <Spinner />
            ) :
                studentData.length < 1 ? <div style={{ display: 'flex', justifyContent: 'center', color: 'red' }}>
                    <strong>No Data</strong>
                </div>
                    : (
                        <div className="table-responsive">
                            <table class="table table-hover">
                                <thead style={{ background: themeColor.themeColors.primaryColor, color: 'white' }}>
                                    <tr>
                                        <th scope="col">#</th>
                                        {tHead.map(item => (
                                            <th
                                                style={{
                                                    border: `1px solid ${themeColor.themeColors.primaryColor}`,
                                                    borderColor: Colors.WHITE
                                                }}
                                                scope="col"
                                            >
                                                {item}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>{bodyRendering(studentData)}</tbody>
                            </table>
                        </div>
                    )}
        </div>
    );
};

function mapStateToProps(state) {
    return {
        role: state.setUserReducer.users.role,
        name: state.setUserReducer.users.unique_name,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        sharedActions: bindActionCreators(sharedActions, dispatch)
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TransferStudent);