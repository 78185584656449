import React, { Component } from 'react';
import './ClassSection.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as StudentResultActions from '../../Actions/StudentResult';
import * as sharedActions from '../../Actions/sharedActions';
import ResultSubComponent from './ResultSubComponent';

class StudentResultStatus extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dummy: [],
			sessions: []
		};
	}
	static getDerivedStateFromProps(nextProps, prevState) {
		if (prevState.dummy !== nextProps.selectStudent) {
			return {
				dummy: nextProps.selectStudent
			};
		}
		return null;
	}
	componentDidMount() {
		this.props.sharedActions
			.gAllData(
				'/api/ClassSection',
				this.props.StudentResultActions.getStudentForStudentResult
			)
			.then(success => {})
			.catch(error => {});
	}
	render() {
		const { classList } = this.state.dummy;
		const { section } = this.state.dummy;
		console.log(section);
		return (
			<div>
				<div style={{ justifyContent: 'center', textAlign: 'center' }}>
					<ResultSubComponent data={classList} Document={section} />
				</div>
				{/* <table class="table">
          <thead className="tablehead">
            <tr>
              <th scope="col">S No.</th>
              <th scope="col">Section</th>
              <th scope="col">Created On</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">1</th>
              <td>Grade 5B</td>
              <td>27-04-2019</td>
              <td>
                {" "}
                <span class="table-remove">
                  <button
                    type="button"
                    class="btn btn-primary btn-rounded btn-sm my-0"
                    style={{ width: 70 }}
                  >
                    Add
                  </button>
                </span>
                &nbsp;&nbsp;&nbsp;
                <span class="table-remove">
                  <button
                    type="button"
                    class="btn btn-danger btn-rounded btn-sm my-0"
                  >
                    Remove
                  </button>
                </span>
              </td>
            </tr>
            <tr>
              <th scope="row">2</th>
              <td>Grade 5G</td>
              <td>27-04-2019</td>
              <td>
                <span class="table-remove">
                  <button
                    type="button"
                    class="btn btn-primary btn-rounded btn-sm my-0"
                    style={{ width: 70 }}
                  >
                    Add
                  </button>
                </span>
                &nbsp;&nbsp;&nbsp;
                <span class="table-remove">
                  <button
                    type="button"
                    class="btn btn-danger btn-rounded btn-sm my-0"
                  >
                    Remove
                  </button>
                </span>
              </td>
            </tr>
            <tr>
              <th scope="row">3</th>
              <td>Grade 5R</td>
              <td>27-04-2019</td>
              <td>
                <span class="table-remove">
                  <button
                    type="button"
                    class="btn btn-primary btn-rounded btn-sm my-0"
                    style={{ width: 70 }}
                  >
                    Add
                  </button>
                </span>
                &nbsp;&nbsp;&nbsp;
                <span class="table-remove">
                  <button
                    type="button"
                    class="btn btn-danger btn-rounded btn-sm my-0"
                  >
                    Remove
                  </button>
                </span>
              </td>
            </tr>
            <tr>
              <th scope="row">4</th>
              <td>Grade 5Y</td>
              <td>27-04-2019</td>
              <td>
                <span class="table-remove">
                  <button
                    type="button"
                    class="btn btn-primary btn-rounded btn-sm my-0"
                    style={{ width: 70 }}
                  >
                    Add
                  </button>
                </span>
                &nbsp;&nbsp;&nbsp;
                <span class="table-remove">
                  <button
                    type="button"
                    class="btn btn-danger btn-rounded btn-sm my-0"
                  >
                    Remove
                  </button>
                </span>
              </td>
            </tr>
            <tr>
              <th scope="row">5</th>
              <td>Grade 7</td>
              <td>27-04-2019</td>
              <td>
                <span class="table-remove">
                  <button
                    type="button"
                    class="btn btn-primary btn-rounded btn-sm my-0"
                    style={{ width: 70 }}
                  >
                    Add
                  </button>
                </span>
                &nbsp;&nbsp;&nbsp;
                <span class="table-remove">
                  <button
                    type="button"
                    class="btn btn-danger btn-rounded btn-sm my-0"
                  >
                    Remove
                  </button>
                </span>
              </td>
            </tr>
          </tbody>
        </table> */}
			</div>
		);
	}
}
function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch),
		StudentResultActions: bindActionCreators(StudentResultActions, dispatch)
	};
}

function mapStateToProps(state) {
	return {
		userData: state.setUserReducer.users,
		selectStudent: state.setStudentResultReducer.selectStudent
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(StudentResultStatus);
