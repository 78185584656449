import React from 'react';
import Head from '../../components/Head';
import '../../Container/signup.css';
import '../../Container/signup';

const Layout = props => {
	return (
		<div>
			<Head {...props} />
		</div>
	);
};

export default Layout;
