import React from 'react';

const StaffWrapper = props => {
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'row',
				flexWrap: 'wrap',
				justifyContent: 'flex-start'
			}}
		>
			<div style={{ display: 'flex', flexWrap: 'wrap', width: '70%' }}>
				{props.children}
			</div>
		</div>
	);
};

export default StaffWrapper;
