import { combineReducers } from 'redux';
import setUserReducer from '../setUserReducer';
import setAllAdminReducer from '../setAllAdminReducer';
import setCampusReducer from '../setCampusReducer';
import setCanteenReducer from '../setCanteenReducer';
import setTransportReducer from '../setTransportReducer';
import setVehicleReducer from '../setVehicleReducer';
import setAddInventoryReducer from '../setAddInventoryReducer';
import setClassSectionGradeReducer from '../setClassSectionGradeReducer';
import setDriverReducer from '../setDriverReducer';
import setCanteenCategoryReducer from '../setCanteenCategoryReducer';
import setCanteenItemsReducer from '../setCanteenItemsReducer';
import setStockReducer from '../setStockReducer';
import setAdmissionReducer from '../setAdmissionReducer';
import setStockUsageReducer from '../setStockUsageReducer';
import setDriverHistoryReducer from '../setDriverHistoryReducer';
import setCensusInformationReducer from '../setCensusInformationReducer';
import setClassWiseReducer from '../setClassWiseReducer';
import setAgeWiseSearchReducer from '../setAgeWiseSearchReducer';
import setCategoryWiseReducer from '../setCategoryWiseReducer';
import setUserWiseReducer from '../setUserWiseReducer';
import setFamilyWiseReducer from '../setFamilyWiseReducer';
import setStudentResultReducer from '../setStudentResultReducer';
import setFinanceReducer from '../setFinanceReducer';
import setBudgetReducer from '../setBudgetReducer';
import setStaffReducer from '../setStaffReducer';
import setTimeTableReducer from '../setTimeTableReducer';
import setStaffFormReducer from '../setStaffFormReducer';
import setStudentEnquiryReducer from '../setStudentEnquiryReducer';
import setStaffEnquiryReducer from '../setStaffEnquiryReducer';
import setPostReducer from '../setPostReducer';
import setDepartmentReducer from '../setDepartmentReducer';
import setHrDevelopmentReducer from '../setHrDevelopmentReducer';
import setPlansReducer from '../setPlansReducer';
import setAssignmentReducer from '../setAssignmentReducer';
import setHomeWorkReducer from '../setHomeWorkReducer';
import setBudgetItemsReducer from '../setBudgetItemsReducer';
import setQuizReducer from '../setQuizReducer';
import setQuizInfoReducer from '../setQuizInfoReducer';
import setTestInfoReducer from '../setTestInfoReducer';
import setTestReducer from '../setTestReducer';
import setExamReducer from '../setExamReducer';
import setExamInfoReducer from '../setExamInfoReducer';
import setPastPapersReducer from '../setPastPapersReducer';
import setSyllabusReducer from '../setSyllabusReducer';
import setStudentSkillReducer from '../setStudentSkillReducer';
import setDescriptiveReducer from '../setDescriptiveReducer';
import setClassDisciplineReducer from '../setClassDisciplineReducer';
import setParentReducer from '../setParentReducer';
const rootReducer = combineReducers({
	setUserReducer,
	setAllAdminReducer,
	setCampusReducer,
	setCanteenReducer,
	setTransportReducer,
	setVehicleReducer,
	setDriverReducer,
	setAddInventoryReducer,
	setClassSectionGradeReducer,
	setCanteenCategoryReducer,
	setCanteenItemsReducer,
	setStockReducer,
	setAdmissionReducer,
	setStockUsageReducer,
	setDriverHistoryReducer,
	setCensusInformationReducer,
	setClassWiseReducer,
	setAgeWiseSearchReducer,
	setCategoryWiseReducer,
	setUserWiseReducer,
	setFamilyWiseReducer,
	setStudentResultReducer,
	setFinanceReducer,
	setBudgetReducer,
	setStaffReducer,
	setTimeTableReducer,
	setStaffFormReducer,
	setStudentEnquiryReducer,
	setStaffEnquiryReducer,
	setPostReducer,
	setDepartmentReducer,
	setHrDevelopmentReducer,
	setPlansReducer,
	setAssignmentReducer,
	setHomeWorkReducer,
	setBudgetItemsReducer,
	setQuizReducer,
	setQuizInfoReducer,
	setTestInfoReducer,
	setTestReducer,
	setExamReducer,
	setExamInfoReducer,
	setPastPapersReducer,
	setSyllabusReducer,
	setStudentSkillReducer,
	setDescriptiveReducer,
	setClassDisciplineReducer,
	setParentReducer
});

export default rootReducer;
// updated Dev Code
