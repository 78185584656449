import React from "react";
import ViewComponent from "../../../components/ViewComponent/ViewComponent";
import CustomHeading from "../../../components/CustomHeading";
import useHttp from "../../../hooks/http";
import { dateConversion, addDataMiddleware } from "../../../utils/general";
import httpWithRequiredSetData from "../../../hooks/Services/httpWithRequiredSetData";
import Colors from "../../../utils/app_constants/colors_constants";
import $ from "jquery";
import * as sharedActions from "../../../Actions/sharedActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import Spinner from "../../../components/Spinner/Spinner";
import SnackBar from "../../../components/SnackBar/SnackBar";
import { useContext } from "react";
import ThemeContext from "../../../context/themeContext/ThemeContext";

let newDummyListData = [
  {
    staffId: "10",
    staffName: "Khalid Latif",
    date: "08/24/2020",
    noOfPeriods: 1,
  },
  {
    staffId: "6",
    staffName: "Sanaullah Sheikh",
    date: "08/24/2020",
    noOfPeriods: 1,
  },
  {
    staffId: "8",
    staffName: "Huma Ahmed",
    date: "08/24/2020",
    noOfPeriods: 1,
  },
  {
    staffId: "2",
    staffName: "Hadiqa Altaf",
    date: "08/24/2020",
    noOfPeriods: 1,
  },
];

// debugger;
let ListData = [
  {
    timeTableId: 18,
    classId: "12",
    sectionId: "1015",
    gradeId: "4",
    previousStaffId: "1",
    preStaffName: "Ali Khan",
    previousSubjectId: "47",
    preSubjectName: "Arabic",
    newstaffId: 17,
    newStaffName: "Badar Sami",
    newSubjectId: 47,
    newSubjectName: "Arabic",
    periodType: "Period",
    name: "1st",
    date: "08/13/2020",
    dayName: "Thursday",
    perioDuration: "45",
    substitutionFor: "Department",
  },
  {
    timeTableId: 22,
    classId: "13",
    sectionId: "1016",
    gradeId: "4",
    previousStaffId: 1,
    preStaffName: "Ali Khan",
    previousSubjectId: 24,
    preSubjectName: "English",
    newstaffId: 11,
    newStaffName: "Madiha Khurum",
    newSubjectId: 24,
    newSubjectName: "English",
    periodType: "Period",
    name: "3rd",
    date: "08/13/2020",
    dayName: "Thursday",
    perioDuration: 45,
    substitutionFor: "Department",
  },
  {
    timeTableId: 21,
    classId: "12",
    sectionId: "1020",
    gradeId: 4,
    previousStaffId: 9,
    preStaffName: "Hira Anwar",
    previousSubjectId: 6,
    preSubjectName: "Business Study",
    newstaffId: 6,
    newStaffName: "Sanaullah Sheikh",
    newSubjectId: 6,
    newSubjectName: "Business Study",
    periodType: "Period",
    name: "5th",
    date: "08/13/2020",
    dayName: "Thursday",
    perioDuration: 45,
    substitutionFor: "Department",
  },
  {
    timeTableId: 18,
    classId: "12",
    sectionId: "1015",
    gradeId: 4,
    previousStaffId: 9,
    preStaffName: "Hira Anwar",
    previousSubjectId: 23,
    preSubjectName: "English",
    newstaffId: 11,
    newStaffName: "Madiha Khurum",
    newSubjectId: 23,
    newSubjectName: "English",
    periodType: "Period",
    name: "6th",
    date: "08/13/2020",
    dayName: "Thursday",
    perioDuration: 45,
    substitutionFor: "Department",
  },
];
function AllPriorityList(props) {
  const themeContext = useContext(ThemeContext);
  const btnTechHover = () => {
    $(document).ready(function () {
      $("button").hover(
        function () {
          $(this).css("box-shadow", "0px 5px 5px grey");
          $(this).css("border-color", "white");
        },
        function () {
          $(this).css("box-shadow", "0px 0px 0px white");
        }
      );
    });
  };
  let [getModalInformation, setModalInformation] = useState([]);
  let [waitingFlag, setFlag] = useState(true);
  let [dummyData, setDummyData] = useState([]);
  let [snackbarMessage, setSnackbarMessage] = useState("");
  let [deleteId, setDeleteId] = useState("");
  useEffect(() => {
    setTimeout(() => {
      setFlag(false);
    }, 5000);
  }, []);
  let [isLoading, getSubstitutionData, setFetchData] = httpWithRequiredSetData(
    `/api/SubstitutionPriority`,
    [],
    (data) => {
      debugger;
      // console.log(data);
      // let addAditionalData = addDataMiddleware(
      // 	{
      // 		Action: [
      // 			{
      // 				// hanlder: deleteHandler,
      // 				exceptionCase: true,
      // 				isEnable: true,
      // 				type: 'normalModalButton',
      // 				modalKey: '#deleteModal',
      // 				title: 'Delete',
      // 				initialPath: '/dashboard/ContinousNewToolCreation',
      // 				iconName: 'fas fa-trash-alt',
      // 				iconColor: Colors.RED_DANGER
      // 			}
      // 		]
      // 	},
      // 	data
      // );
      // debugger;
      setFetchData(data);
    }
  );

  const onViewStaffPeriods = (id, type) => {
    if (type === "delete") {
      setDeleteId(id);
      $(`#exampleModal`).modal("show");
      // setFetchData(getFilteredRecord);
    } else if (type === "edit") {
      let getIdData = getSubstitutionData.filter((item) => item.id === id);
      debugger;
      // props.sharedAction
      // 	.getDataWithoutDispatch(
      // 		`/api/listOfPeriodsStaff/?staffId=${id}&date=${date}`
      // 	)
      // 	.then(success => {
      setModalInformation(getIdData);
      // debugger;
      $(`.bd-example-modal-lg`).modal("show");
    }
    // })
    // .catch(error => {
    // 	debugger;
    // });
  };
  const onTakeItemDelete = (id) => {
    props.sharedAction
      .deleteRecordWithoutDispatch("/api/SubstitutionPriority", id)
      .then((success) => {
        debugger;
        let getFilteredData = getSubstitutionData.filter(
          (item) => item.id !== id
        );
        setFetchData(getFilteredData);
        setSnackbarMessage("Successfully Deleted!");
        props.snackbar();
      })
      .catch((error) => {});
  };
  const onActiveClick = (id, data) => {
    props.sharedAction
      .editRecordWithoutDispatchAndId(`/api/SubstitutionPriority/${id}`, data)
      .then((success) => {
        let getIndex = getSubstitutionData.findIndex((item) => item.id === id);
        let getCloneSubstituteData = [...getSubstitutionData];
        getCloneSubstituteData[getIndex]["status"] = "Active";
        setFetchData(getCloneSubstituteData);
        debugger;
        setSnackbarMessage("Successfully Updated");
        props.snackbar();
      })
      .catch((error) => {});
  };
  const onDeActiveClick = (id, data) => {
    props.sharedAction
      .editRecordWithoutDispatchAndId(`/api/SubstitutionPriority/${id}`, data)
      .then((success) => {
        let getIndex = getSubstitutionData.findIndex((item) => item.id === id);
        let getCloneSubstituteData = [...getSubstitutionData];
        getCloneSubstituteData[getIndex]["status"] = "De-Active";
        setFetchData(getCloneSubstituteData);
        debugger;
        // $(`.bd-example-modal-lg`).modal('show');

        setSnackbarMessage("Successfully Updated");
        props.snackbar();
      })
      .catch((error) => {});
  };
  debugger;
  return (
    <div>
      <CustomHeading headingText={"All Priority List"} />
      <SnackBar backColor={"#000"} msg={snackbarMessage} />
      <br />
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div
              style={{
                borderBottomLeftRadius: 30,
                borderBottomRightRadius: 30,
                borderTopLeftRadius: 3,
                borderTopRightRadius: 3,
              }}
              class="modal-header modal_custom_header"
            >
              <h4 style={{ color: "white" }} class="modal-title">
                Confirm
              </h4>
              <button
                style={{ color: "white" }}
                type="button"
                class="close"
                data-dismiss="modal"
              >
                &times;
              </button>
            </div>
            <div class="modal-body">
              <div>
                <div style={{ textAlign: "center" }}>
                  <h4>Are you sure ?</h4>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                  }}
                >
                  <div>
                    <button
                      // disabled={this.state.enabled}
                      data-dismiss="modal"
                      onClick={() => onTakeItemDelete(deleteId)}
                      type="button"
                      class="btn btn-primary buttonAppear"
                    >
                      Yes
                    </button>
                  </div>
                  <div>
                    <button
                      data-dismiss="modal"
                      // disabled={this.state.enabled}
                      type="button"
                      class="btn btn-primary buttonAppear"
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade bd-example-modal-lg"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content modal_content_custom">
            <div
              style={{
                borderBottomLeftRadius: 30,
                borderBottomRightRadius: 30,
                borderTopLeftRadius: 18,
                borderTopRightRadius: 18,
              }}
              class="modal-header modal_custom_header"
            >
              <h4 style={{ color: "white" }} class="modal-title">
                Period Details
              </h4>
              <button
                style={{ color: "white" }}
                type="button"
                class="close"
                data-dismiss="modal"
              >
                &times;
              </button>
            </div>
            <table class="table">
              <thead style={{ background: Colors.SEA_GREEN_THEME }}>
                <tr>
                  <th
                    style={{
                      color: Colors.WHITE,
                      backgroundColor: themeContext.themeColors.primaryColor,
                    }}
                    scope="col"
                  >
                    #
                  </th>

                  <th
                    style={{
                      color: Colors.WHITE,
                      backgroundColor: themeContext.themeColors.primaryColor,
                    }}
                    scope="col"
                  >
                    Status
                  </th>
                  <th
                    style={{
                      color: Colors.WHITE,
                      backgroundColor: themeContext.themeColors.primaryColor,
                      textAlign: "center",
                    }}
                    scope="col"
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {getModalInformation &&
                  getModalInformation.map((item, index) => (
                    <tr>
                      <th scope="row">{index + 1}</th>

                      <td>{item.status}</td>
                      <td>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <button
                            onClick={() =>
                              onActiveClick(item.id, {
                                id: item.id,
                                priorityName: item.priorityName,
                                priority: item.priority,
                                status: "Active",
                                date: new Date().toLocaleDateString(),
                                time: new Date().toLocaleTimeString(),
                                accountId: props.name,
                                accountType: props.role,
                              })
                            }
                            className={
                              item.status === "Active" ? "btns" : "btn btn-dark"
                            }
                          >
                            Active
                          </button>
                          <button
                            onClick={() =>
                              onDeActiveClick(item.id, {
                                id: item.id,
                                priorityName: item.priorityName,
                                priority: item.priority,
                                status: "De-Active",
                                date: new Date().toLocaleDateString(),
                                time: new Date().toLocaleTimeString(),
                                accountId: props.name,
                                accountType: props.role,
                              })
                            }
                            className={
                              item.status === "Active" ? "btn btn-dark" : "btns"
                            }
                          >
                            Non-Active
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {getSubstitutionData && getSubstitutionData.length > 0 ? (
        <table class="table">
          <thead style={{ background: Colors.SEA_GREEN_THEME }}>
            <tr>
              <th
                style={{
                  color: Colors.WHITE,
                  backgroundColor: themeContext.themeColors.primaryColor,
                }}
                scope="col"
              >
                #
              </th>
              <th
                style={{
                  color: Colors.WHITE,
                  backgroundColor: themeContext.themeColors.primaryColor,
                }}
                scope="col"
              >
                Priority Name
              </th>
              <th
                style={{
                  color: Colors.WHITE,
                  backgroundColor: themeContext.themeColors.primaryColor,
                }}
                scope="col"
              >
                Priority
              </th>
              <th
                style={{
                  color: Colors.WHITE,
                  backgroundColor: themeContext.themeColors.primaryColor,
                }}
                scope="col"
              >
                Status
              </th>
              <th
                style={{
                  color: Colors.WHITE,
                  backgroundColor: themeContext.themeColors.primaryColor,
                }}
                scope="col"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {getSubstitutionData &&
              getSubstitutionData.map((item, index) => (
                <tr>
                  <th scope="row">{index + 1}</th>
                  <td>{item.priorityName}</td>
                  <td>{item.priority}</td>
                  <td>{item.status}</td>
                  <td>
                    <a href="#" data-toggle="tooltip" title="View!">
                      <button
                        id="firstbutton"
                        onClick={() => onViewStaffPeriods(item.id, "edit")}
                        onMouseOver={btnTechHover()}
                        data-toggle="modal"
                        data-target="#myModal"
                        style={{
                          cursor: "pointer",
                          fontSize: 20,
                          border: "none",
                          marginLeft: 5,
                          background: "none",
                          marginBottom: 5,
                        }}
                      >
                        <i class="fas fa-edit"></i>
                      </button>
                    </a>
                    <a href="#" data-toggle="tooltip" title="Delete!">
                      <button
                        id="firstbutton"
                        onClick={() => onViewStaffPeriods(item.id, "delete")}
                        onMouseOver={btnTechHover()}
                        data-toggle="modal"
                        data-target="#myModal"
                        style={{
                          cursor: "pointer",
                          fontSize: 20,
                          border: "none",
                          marginLeft: 5,
                          background: "none",
                          marginBottom: 5,
                        }}
                      >
                        <i class="fas fa-trash text-danger"></i>
                      </button>
                    </a>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      ) : waitingFlag ? (
        <Spinner />
      ) : (
        <p className="text-center text-danger">No Data Found</p>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    role: state.setUserReducer.users.role,
    name: state.setUserReducer.users.unique_name,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sharedAction: bindActionCreators(sharedActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AllPriorityList);
