import * as actionTypes from '../actionTypes';

export function setAllCampusInRedux(allData) {
	return {
		type: actionTypes.GET_ALL_CAMPUS,
		payload: allData
	};
}
export function addCampus(Data) {
	return {
		type: actionTypes.ADD_CAMPUS,
		payload: Data
	};
}
export function updateCampusFlag(setFlag) {
	return {
		type: actionTypes.SET_LOADER_FLAG_CAMPUS,
		payload: setFlag
	};
}
export function updateCampus(data) {
	return {
		type: actionTypes.EDIT_CAMPUS_RECORD,
		payload: data
	};
}
export function deleteCampus(id) {
	return {
		type: actionTypes.DELETE_CAMPUS,
		payload: id
	};
}
