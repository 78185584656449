
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import $ from 'jquery';
import isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom'
import isNull from 'lodash/isNull'
import moment from 'moment'
import Pagination from '../../components/Pagination/Pagination'

import ThemeContext from "../../context/themeContext/ThemeContext";

class ViewSyllabusDept extends Component {
    constructor(props){
        super(props);
        this.state = {
            isLoading: true,
            modalEdit: false,
            editToggle: false,
            generateToggle: false,
            generateIndex: '',
            msg: '',
            editIndex: '',
            classId: "",
            sectionId: "",
            subjectId: "",
            pageNumber: [],
            dataLimit: 10,
            currentPage: 1,
			termList: [{id: '1st', name: '1st Term'},{id: '2nd', name: '2nd Term'},{id: '3rd', name: '3rd Term'}],


			accountId: this.props.userData.unique_name,
			accountType: this.props.userData.role

        }

    }

    static contextType = ThemeContext

    componentDidMount(){
        this.getSimple(`HODDashboard/?username=${this.state.accountId}`, 'hodData')
        this.getSimple('Session', 'session')

    }
    handleSearch = () => {
        const { term, sessionId, hodData } = this.state;

        if( isNull(sessionId) || isNull(term)){
            console.log('Empty');
            
            this.setState({
                msg: "Please Fill The Form Repectively!",
            })            
            this.props.snackbar()
        }else{
            this.getPaginationSimple(`HODSyllabusView/?hodpostId=${hodData.hodPostId}&term=${term}&sessionId=${sessionId}&pageSize=${this.state.dataLimit}&pageNumber=${this.state.currentPage}`, 'syllabusData')
            
        }

    }

    

    
    getSimple = (type, state) => {
        this.props.sharedActions.getDataWithoutDispatch(
            `/api/${type}`
        ).then(success => { 
            this.setState({
                [state]: success,
                isLoading: false
            })


        }).catch(error => {
            this.setState({
                msg: 'Error Occured!!',
                errorMsg: true,
                isLoading: false
            })
            console.error('Error occured', error);
        })
    }
    

    getPaginationSimple = (type, state) => {
        this.props.sharedActions.getDataWithoutDispatch(
            `/api/${type}`
        ).then(success => { 
            this.setState({
                [state]: success,
                isLoading: false
            })
            this.setState({
                currentPage: success.paginationMetadata.currentPage,
                disableBPage: success.paginationMetadata.previousPage == 'No' ? true : false,
                disablepage: success.paginationMetadata.nextPage == 'No' ? true : false,
                pageNumber: Array.from(new Array(success.paginationMetadata.totalPages), (val, index) => index + 1)
            })



        }).catch(error => {
            this.setState({
                msg: error.response ? error.response.data.Message : 'Error Occured!!',
                isLoading: false
            })
            this.props.snackbar();
            console.error('Error occured', error);
        })
    }

    
	handleClickNextFrwd = () => {
		const {  currentPage, pageNumber } = this.state;
		console.log('current page: ', currentPage, pageNumber);
        if (currentPage === pageNumber.length) {
			this.setState({
				disablepage: true
            }, () => {
                this.handleSearch()

            });

            
		} else {
			this.setState({
				currentPage: currentPage + 1,
				disableBPage: false
            }, () => {
                this.handleSearch()

            });
            
            
		}
    };
    
	handleClickNextBack = () => {
		const { currentPage } = this.state;
		console.log('current page: ', currentPage);
		if (currentPage === 1) {
			this.setState({
				disableBPage: true,
				disablepage: false
            }, () => {
                this.handleSearch()

            });
		} else {
			this.setState({
                currentPage: currentPage - 1,
				disablepage: false
            }, () => {
                this.handleSearch()

            });
		}
    };
    
	handleClickNext = event => {
        const { currentPage, pageNumber } = this.state
                this.setState({
                    currentPage: Number(event.target.id),
                    disablepage: false,
                    disableBPage: false
                }, () => {
                    this.handleSearch()
    
                });
		
	};




    PrintContent = el => {
        var restorepage = document.body.innerHTML;
        var Printcontent = document.getElementById(el).innerHTML;
        document.body.innerHTML = Printcontent;
        
        setTimeout(() => {
            window.print();
            window.location.reload();
        }, 700)
        document.body.innerHTML = restorepage;
        window.reload();
        
    };



    myFunction = () => {
		// Get the snackbar DIV
		var x = document.getElementById('snackbar');

		// StepperGeneralButtons the "show" class to DIV
		x.className = 'show';

		// After 3 seconds, remove the show class from DIV
		setTimeout(function() {
		}, 3000);
	};



    btnTechHover = () => {
		$(document).ready(function() {
			$('button').hover(
				function() {
					$(this).css('box-shadow', '0px 5px 5px grey');
					$(this).css('border-color', 'white');
				},
				function() {
					$(this).css('box-shadow', '0px 0px 0px white');
				}
			);
		});
	};

    onHandleText = e => {
		this.setState({
			[e.target.name]: e.target.value
        });
        console.log([e.target.name], e.target.value)
    };

    onHandleFilter = e => {
        this.setState({
			[e.target.name]: e.target.value
        })
        console.log([e.target.name], e.target.value)
    };

    handleSubmit = e => {
        e.preventDefault();
        console.log('Submit Called')

        const {  
            
            id,
            classId,
            className,
            fileName,
            filePath,
            hodPostId,
            section,
            sectionId,
            staffId,
            staffName,
            subjectId,
            subjectName,
            sessionId,
            currentSession,
            status,
            termType,
            term,
            accountId,
            accountType,
        } = this.state;

        let data = {
            
            id,
            classId,
            className,
            fileName,
            filePath,
            hodPostId,
            section,
            sectionId,
            staffId,
            staffName,
            subjectId,
            subjectName,
            sessionId,
            session: currentSession,
            status,
            termType,
            accountId,
            accountType,
        }

        console.log('data model', data)
        this.props.sharedActions.editPatchRecordWithoutDispatch(
            '/api/ApproveSyllabusStatus/?id=',
            id,
            data
        ).then(success => {
            console.log('success', success)
            this.state.syllabusData.syllabusList.splice(this.state.editIndex, 1, data)
            this.setState({
                msg: 'Record Changed Successfully!',
                editToggle: false
                
            })
            this.props.snackbar();
            $('#myModal1').modal('hide')
            

        }).catch(error => {
            console.error('Error name: ', error)
                
                $('#myModal1').modal('hide')
            this.setState({
                msg: error.response ? error.response.data.Message : 'Error Occured!!',
                isLoading: false
            })
            this.props.snackbar();


        })
    }
    
    onEditClick = id => {
        const { syllabusData } = this.state
        let modalData = syllabusData.syllabusList.filter((items, index ) =>  {
            if(id === items.id){
                    
            this.setState({
                editIndex: index
            })
            console.log(index)
            return id === items.id 
            }
        })
        console.log(modalData)
        this.setState({
            id: modalData[0].id,
            classId: modalData[0].classId,
            className: modalData[0].className,
            fileName: modalData[0].fileName,
            filePath: modalData[0].filePath,
            hodPostId: modalData[0].hodPostId,
            section: modalData[0].section,
            sectionId: modalData[0].sectionId,
            staffId: modalData[0].staffId,
            staffName: modalData[0].staffName,
            subjectId: modalData[0].subjectId,
            subjectName: modalData[0].subjectName,
            sessionId: modalData[0].sessionId,
            currentSession: modalData[0].session,
            status: modalData[0].status,
            termType: modalData[0].termType,
            
        }, () => {
            $('#myModal1').modal({
                show: true
            })
        })
    }

    

    render(){

        const { search, isLoading, editToggle, msg, syllabusData, session, termList,
                currentPage, pageNumber, disableBPage, disablepage, 
            
                
                id,
                classId,
                className,
                fileName,
                filePath,
                hodPostId,
                section,
                sectionId,
                staffId,
                staffName,
                subjectId,
                subjectName,
                sessionId,
                currentSession,
                status,
                termType,
                term,
                accountId,
                accountType,
        } = this.state;

        const Loader = (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div className="spinner-border" style={{ color: this.context.themeColors.primaryColor }}></div>
            </div>
        );

        const editLeaveData = (
            <div>
            <div className="modal" id="myModal1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content modal_content_custom">
                        <div
                            style={{
                                background: this.context.themeColors.primaryColor,
                                borderBottomLeftRadius: 30,
                                borderBottomRightRadius: 30,
                                borderTopLeftRadius: 18,
                                borderTopRightRadius: 18
                            }}
                            className="modal-header modal_custom_header"
                        >
                            <h4 style={{ color: 'white' }} className="modal-title">
                                Edit
                            </h4>
                            <button
                                style={{ color: 'white' }}
                                on
                                type="button"
                                className="close"
                                data-dismiss="modal"
                            >
                                &times;
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={this.handleSubmit} >
                            <fieldset disabled={!editToggle}>
                            <div className="row">
                                    <div className="col-md-6">
                                <div className="form-group">
                                <label for="accountId">Account Name:</label>
                                <input style={{
                                    borderTop: 0,
                                    borderRight: 0,
                                    borderLeft: 0
                                }}
                                type="text"
                                disabled
                                value={accountId}
                                name="modalDataAccountId"
                                className="form-control"
                                id="accountId"
                                />
                                </div>
                                     </div>
                                    <div className="col-md-6">

                                <div className="form-group">
                                <label for="accountType">Account Type:</label>
                                <input style={{
                                    borderTop: 0,
                                    borderRight: 0,
                                    borderLeft: 0
                                }}
                                type="text"
                                disabled
                                value={accountType}
                                name="modalDataAccountType"
                                className="form-control"
                                id="accountType"
                                />
                                </div>
                                </div>
                            </div>
                            <div className="row">
                                    <div className="col-md-6">

                                <div className="form-group">
                                <label for="status">
                                                Status
                                            </label>
                                            <select class="custom-select" 
                                                value={status}
                                                name="status"
                                                onChange={this.onHandleText}
                                            >
                                                        <option value="Non-Approved">Non-Approved</option>
                                                        <option value="Approved">Approved</option>
                                            </select>
                                </div>
                                </div>
                                <div className="col-md-6">

                                    <div className="form-group">
                                        <label for="staffName">Staff Name</label>
                                        <input style={{
                                            borderTop: 0,
                                            borderRight: 0,
                                            borderLeft: 0
                                        }}
                                        type="text"
                                        disabled
                                        value={staffName}
                                        name="staffName"
                                        className="form-control"
                                        id="staffName"
                                />
                                    </div>
                                </div>
                            </div>
                            
                            <div className="row">
                                    
                                <div className="col-md-6">

                                    <div className="form-group">
                                        <label for="class">Class</label>
                                        <input style={{
                                            borderTop: 0,
                                            borderRight: 0,
                                            borderLeft: 0
                                        }}
                                        type="text"
                                        disabled
                                        value={className}
                                        name="className"
                                        className="form-control"
                                        id="className"
                                />
                                    </div>
                                </div>
                                <div className="col-md-6">

                                    <div className="form-group">
                                        <label for="section">Section</label>
                                        <input style={{
                                            borderTop: 0,
                                            borderRight: 0,
                                            borderLeft: 0
                                        }}
                                        type="text"
                                        disabled
                                        value={section}
                                        name="section"
                                        className="form-control"
                                        id="section"
                                />
                                    </div>
                                </div>
                                </div>
                                
                            <div className="row">
                                    
                                <div className="col-md-6">

                                    <div className="form-group">
                                        <label for="subjectName">Subject</label>
                                        <input style={{
                                            borderTop: 0,
                                            borderRight: 0,
                                            borderLeft: 0
                                        }}
                                        type="text"
                                        disabled
                                        value={subjectName}
                                        name="subjectName"
                                        className="form-control"
                                        id="subjectName"
                                />
                                    </div>
                                </div>
                                <div className="col-md-6">

                                    <div className="form-group">
                                        <label for="currentSession">Session</label>
                                        <input style={{
                                            borderTop: 0,
                                            borderRight: 0,
                                            borderLeft: 0
                                        }}
                                        type="text"
                                        disabled
                                        value={currentSession}
                                        name="currentSession"
                                        className="form-control"
                                        id="currentSession"
                                />
                                    </div>
                                </div>
                                <div className="col-md-6">

                                    <div className="form-group">
                                        <label for="termType">Term</label>
                                        <input style={{
                                            borderTop: 0,
                                            borderRight: 0,
                                            borderLeft: 0
                                        }}
                                        type="text"
                                        disabled
                                        value={termType}
                                        name="termType"
                                        className="form-control"
                                        id="termType"
                                />
                                    </div>
                                </div>
                                
                                <div className="col-md-6">

                                    <div className="form-group">
                                        <label for="fileName">File Name</label>
                                        <input style={{
                                            borderTop: 0,
                                            borderRight: 0,
                                            borderLeft: 0
                                        }}
                                        type="text"
                                        disabled
                                        value={fileName}
                                        name="fileName"
                                        className="form-control"
                                        id="fileName"
                                />
                                    </div>
                                </div>
                                </div>
                            </fieldset>
                            <br />
							<br />
								<button
									disabled={editToggle}
									onClick={() => this.setState({ editToggle: true })}
									type="button"
									class="btn btn-primary buttonAppear"
									>
								    	Edit
									</button>
									<button
										style={{ marginLeft: 5 }}
										disabled={!editToggle}
										type="submit"
										class="btn btn-primary buttonAppear"
										>
										Save
									</button>
                            </form>
                        </div>
                        <div class="modal-footer">
								<button
									type="button"
									style={{ background: '#00435D' }}
                                    class="btn btn-danger"
                                    onClick={() => this.setState({editToggle: false})}
									data-dismiss="modal"
								>
									Close
								</button>
							</div>
                    </div>
                </div>
            </div>
            </div>                 
        )
        
        const renderSyllabusData = syllabusData && syllabusData.syllabusList.map((item, index) => {
            return (
                <tr key={index} >
                    <th scope="row" style={{ padding: 1 }} >
                        {index + 1}
                    </th>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.staffName}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.className}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.section}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.subjectName}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.session}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.termType}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.fileName}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.status}
                    </td>
                    <td className="borderSpace" style={{ padding: 1, color: 'red' }}>
                    <a href="#" data-toggle="tooltip" title="Edit!">
                            
                            <button
								id="firstbutton"
                                onClick={() => this.onEditClick(item.id)}
								onMouseOver={this.btnTechHover()}
								style={{
									cursor: 'pointer',
									fontSize: 20,
									border: 'none',
									marginLeft: 5,
									background: 'none',
									marginBottom: 5
								}}
							>
								<i className="fas fa-pen"></i>
							</button>
						</a>
                        {<a 
                        href={
                            'https://embassyportal.schoolsmart.org.uk/api/DownloadSyllabusForAll/?fileName=' + item.fileName
                        }
                        data-toggle="tooltip"
                        title="Download File"
                        >                            
                            <button
								id="firstbutton"
                                onMouseOver={this.btnTechHover()}
								style={{
									cursor: 'pointer',
									fontSize: 20,
									border: 'none',
									marginLeft: 5,
									background: 'none',
									marginBottom: 5
								}}
							>
								<i className="fas fa-arrow-down"></i>
							</button>
						</a>}
                    </td>
                </tr>
                
                );
            
        }) 

        return (
            <div>
				<section  className="mb-4">
				<h2  
                        style={{ background: this.context.themeColors.primaryColor }}
                        className="h1-responsive font-weight-bold text-center  generalHead">
                        View Syllabus
                    </h2>
			    <div>
                <SnackBar msg={msg} />
                <div style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                }}>
                    <div>
                        <div class="row my-3 mx-5 py-4" style={{
                            border: `2px solid ${this.context.themeColors.primaryColor}`,
                            borderRadius: 20
                        }} >
                                       
                            <div class="col-md-6" >
                            <select class="custom-select" 
                                                value={sessionId}
                                                name="sessionId"
                                                style={{marginTop: '3%'}}
                                                onChange={this.onHandleText}
                                                disabled={!session}

                                            >
                                                        <option value="">Please Select Session</option>
                                                       {session && session.session.map((value, index) => {

                                                                return <option value={value.sessionId}>{value.currentSession}</option>

                                                       })}
                                            </select>
                            </div>
                              <div class="col-md-6" >
                             <select class="custom-select" 
                                value={term}
                                style={{marginTop: '3%'}}
                                name="term"
                                onChange={this.onHandleText}
                                disabled={!sessionId}

                            >
                                        <option value="">Please Select Term</option>
                                       {termList.map((value, index) => {

                                                return <option value={value.id}>{value.name}</option>

                                       })}
                            </select>
                            </div>
    
                        </div>
                        <div class="row my-4">
                        <div className="col-md-12 text-center">

                                            <button
                                            class="btn btn-primary buttonAppear"
                                            onClick={this.handleSearch}
                                            style={{marginRight: '2%'}}
                                            >
                            <i class="fa fa-search"></i> Search
                                        </button>
                                        <Link to={{
                            pathname: "PrintSyllabusDept",
                            state: {
                                syllabusData,
                                date: moment().format('MM/DD/YYYY'),

                            }
                        }}  >
                            <a href="#" data-toggle="tooltip" title="Edit!">
                                
                            <button
                                            class="btn btn-primary buttonAppear"
                                            // disabled={!studentAttendance}
                                            >
                            
                                    <i className="fas fa-list"></i> View
                                </button>
                            </a> 
                            
                        </Link>
                            </div>
                            
                        </div>     
                        
                        <div class="row">
                                <div class="col-md-12 text-right">
                                <Pagination
                                    disableBPage={disableBPage}
                                    disablepage={disablepage}
                                    currentPage={currentPage}
                                    pageNumber={pageNumber}
                                    handleClickNext={this.handleClickNext}
                                    handleClickNextBack={this.handleClickNextBack}
                                    handleClickNextFrwd={this.handleClickNextFrwd}
                                />
                                </div>
                            </div>
                    <div className="table-responsive">
					<table className="table table-hover text-center">
						<thead style={{ background: this.context.themeColors.primaryColor, color: 'white' }}>
							<tr>
                            <th scope="col" style={{ padding: 1 }}>
									#
								</th>
                                <th scope="col" style={{ padding: 1 }}>
                                    Staff Name
								</th>
								<th scope="col" style={{ padding: 1 }}>
                                    Class
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Section
								</th>
								<th scope="col" style={{ padding: 1 }}>
                                    Subject
								</th>
                                <th scope="col" style={{ padding: 1 }}>
									Session
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Term
								</th>
                                <th scope="col" style={{ padding: 1 }}>
                                    File Name
								</th>
                                <th scope="col" style={{ padding: 1 }}>
									Status
								</th>
                                <th scope="col" style={{ padding: 1 }}>
                                    Actions
								</th>
							</tr>
						</thead>
						<tbody>{isLoading ? Loader : renderSyllabusData}</tbody>
					</table>
				</div>
                </div>
                {editLeaveData}
            </div>
            </div>
            </section>
            </div>
        );
    }

}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch),
	};
}
function mapStateToProps(state) {
	return {
		userData: state.setUserReducer.users,
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ViewSyllabusDept);
