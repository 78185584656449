import * as actionTypes from '../actionTypes';

export function setAllStockInRedux(allData) {
	return {
		type: actionTypes.GET_ALL_STOCK,
		payload: allData
	};
}
export function getItemsForStock(allData) {
	return {
		type: actionTypes.SET_ALL_ITEMS_NAME_INTO_ADD_STOCK_SELECT,
		payload: allData
	};
}
export function getItemsForStockBarcode(allData) {
	return {
		type: actionTypes.SET_ALL_ITEMS_NAME_INTO_ADD_STOCK_BARCODE,
		payload: allData
	};
}
export function addStock(Data) {
	return {
		type: actionTypes.ADD_STOCK,
		payload: Data
	};
}
export function updateStockFlag(setFlag) {
	return {
		type: actionTypes.SET_LOADER_FLAG_STOCK_ADD,
		payload: setFlag
	};
}
export function updateStock(data) {
	return {
		type: actionTypes.EDIT_STOCK_RECORD,
		payload: data
	};
}
export function deleteStock(id) {
	return {
		type: actionTypes.DELETE_STOCK,
		payload: id
	};
}
