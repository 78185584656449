import React from "react";
import ThemeContext from "../../../context/themeContext/ThemeContext";

export default function GenerateTable(props) {
  const { Data, Sheet } = props;
  debugger;
  return (
    <React.Fragment>
      {Sheet == "1" ? (
        <div className="table-responsive">
          <ThemeContext.Consumer>
            {(context) => (
              <table class="table text-center table-sm">
                <thead
                  class="bordersSpace"
                  style={{
                    background: context.themeColors.primaryColor,
                    color: "white",
                  }}
                >
                  <tr class="vendorListHeading bordersSpace">
                    <th className="bordersSpace" rowSpan="2">
                      NO.
                    </th>
                    <th className="bordersSpace" rowSpan="3">
                      Student
                    </th>
                    <th className="bordersSpace" colSpan="2">
                      Partial Deaf
                    </th>
                    <th className="bordersSpace" colSpan="2">
                      Partial Vision
                    </th>
                    <th className="bordersSpace" colSpan="2">
                      Dexterity
                    </th>
                    <th className="bordersSpace" colSpan="2">
                      Mobility Issue
                    </th>
                    <th className="bordersSpace" colSpan="2">
                      Protanopia
                    </th>
                    <th className="bordersSpace" colSpan="2">
                      Stamina
                    </th>
                  </tr>
                  <tr class="vendorListHeading bordersSpace">
                    <th className="bordersSpace">Found</th>
                    <th className="bordersSpace">Reason</th>
                    <th className="bordersSpace">Found</th>
                    <th className="bordersSpace">Reason</th>
                    <th className="bordersSpace">Found</th>
                    <th className="bordersSpace">Reason</th>
                    <th className="bordersSpace">Found</th>
                    <th className="bordersSpace">Reason</th>
                    <th className="bordersSpace">Found</th>
                    <th className="bordersSpace">Reason</th>
                    <th className="bordersSpace">Found</th>
                    <th className="bordersSpace">Reason</th>
                  </tr>
                </thead>

                <tbody>
                  {Data.map((item, index) => (
                    <tr class="font-weight-bold bordersSpace" key={index}>
                      <td className="bordersSpace">{index + 1}</td>
                      <td class="bordersSpace font-weight-bold">
                        {item.studentName}
                      </td>
                      {item.studentNeeds.map((data, indexes) => (
                        <React.Fragment>
                          <td className="bordersSpace">{data.partialDeaf}</td>
                          <td className="bordersSpace">
                            {data.PartialDeafReason}
                          </td>
                          <td className="bordersSpace">{data.partialVision}</td>
                          <td className="bordersSpace">
                            {data.PartialVisionReason}
                          </td>
                          <td className="bordersSpace">{data.dexterity}</td>
                          <td className="bordersSpace">
                            {data.dexterityReason}
                          </td>
                          <td className="bordersSpace">{data.mobilityIssue}</td>
                          <td className="bordersSpace">
                            {data.mobilityIssueReason}
                          </td>
                          <td className="bordersSpace">{data.protanopia}</td>
                          <td className="bordersSpace">
                            {data.protanopiaReason}
                          </td>
                          <td className="bordersSpace">{data.stemina}</td>
                          <td className="bordersSpace">{data.steminaReason}</td>
                        </React.Fragment>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </ThemeContext.Consumer>
        </div>
      ) : Sheet == "2" ? (
        <div className="table-responsive">
          <ThemeContext.Consumer>
            {(context) => (
              <table class="table text-center table-sm">
                <thead
                  class="bordersSpace"
                  style={{
                    background: context.themeColors.primaryColor,
                    color: "white",
                  }}
                >
                  <tr class="vendorListHeading bordersSpace">
                    <th className="bordersSpace" rowSpan="2">
                      NO.
                    </th>
                    <th className="bordersSpace" rowSpan="3">
                      Student
                    </th>
                    <th className="bordersSpace" colSpan="2">
                      Attention Defict Hyper Disorder
                    </th>
                    <th className="bordersSpace" colSpan="2">
                      Dyscalulia
                    </th>
                    <th className="bordersSpace" colSpan="2">
                      Dysgraphia
                    </th>
                    <th className="bordersSpace" colSpan="2">
                      Dyslexia
                    </th>
                    <th className="bordersSpace" colSpan="2">
                      Processing Deficts
                    </th>
                  </tr>
                  <tr class="vendorListHeading bordersSpace">
                    <th className="bordersSpace">Found</th>
                    <th className="bordersSpace">Reason</th>
                    <th className="bordersSpace">Found</th>
                    <th className="bordersSpace">Reason</th>
                    <th className="bordersSpace">Found</th>
                    <th className="bordersSpace">Reason</th>
                    <th className="bordersSpace">Found</th>
                    <th className="bordersSpace">Reason</th>
                    <th className="bordersSpace">Found</th>
                    <th className="bordersSpace">Reason</th>
                  </tr>
                </thead>

                <tbody>
                  {Data.map((item, index) => (
                    <tr class="font-weight-bold bordersSpace" key={index}>
                      <td className="bordersSpace">{index + 1}</td>
                      <td class="bordersSpace font-weight-bold">
                        {item.studentName}
                      </td>
                      {item.studentNeeds.map((data, indexes) => (
                        <React.Fragment>
                          <td className="bordersSpace">
                            {data.attentionDefictHyperDisorder}
                          </td>
                          <td className="bordersSpace">
                            {data.attentionDefictHyperDisorderReason}
                          </td>
                          <td className="bordersSpace">{data.dyscalulia}</td>
                          <td className="bordersSpace">
                            {data.dyscaluliaReason}
                          </td>
                          <td className="bordersSpace">{data.dysgraphia}</td>
                          <td className="bordersSpace">
                            {data.dysgraphiaReason}
                          </td>
                          <td className="bordersSpace">{data.dyslexia}</td>
                          <td className="bordersSpace">
                            {data.dyslexiaReason}
                          </td>
                          <td className="bordersSpace">
                            {data.processingDeficts}
                          </td>
                          <td className="bordersSpace">
                            {data.processingDefictsReason}
                          </td>
                        </React.Fragment>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </ThemeContext.Consumer>
        </div>
      ) : Sheet == "3" ? (
        <div className="table-responsive">
          <ThemeContext.Consumer>
            {(context) => (
              <table class="table text-center table-sm">
                <thead
                  class="bordersSpace"
                  style={{
                    background: context.themeColors.primaryColor,
                    color: "white",
                  }}
                >
                  <tr class="vendorListHeading bordersSpace">
                    <th className="bordersSpace" rowSpan="2">
                      NO.
                    </th>
                    <th className="bordersSpace" rowSpan="3">
                      Student
                    </th>
                    <th className="bordersSpace" colSpan="2">
                      Anxiety
                    </th>
                    <th className="bordersSpace" colSpan="2">
                      Bipolar Disorder
                    </th>
                    <th className="bordersSpace" colSpan="2">
                      Major Depression
                    </th>
                    <th className="bordersSpace" colSpan="2">
                      Schizophrenia
                    </th>
                  </tr>
                  <tr class="vendorListHeading bordersSpace">
                    <th className="bordersSpace">Found</th>
                    <th className="bordersSpace">Reason</th>
                    <th className="bordersSpace">Found</th>
                    <th className="bordersSpace">Reason</th>
                    <th className="bordersSpace">Found</th>
                    <th className="bordersSpace">Reason</th>
                    <th className="bordersSpace">Found</th>
                    <th className="bordersSpace">Reason</th>
                  </tr>
                </thead>

                <tbody>
                  {Data.map((item, index) => (
                    <tr class="font-weight-bold bordersSpace" key={index}>
                      <td className="bordersSpace">{index + 1}</td>
                      <td class="bordersSpace font-weight-bold">
                        {item.studentName}
                      </td>
                      {item.studentNeeds.map((data, indexes) => (
                        <React.Fragment>
                          <td className="bordersSpace">{data.anxiety}</td>
                          <td className="bordersSpace">{data.anxietyReason}</td>
                          <td className="bordersSpace">
                            {data.bipolarDisOrder}
                          </td>
                          <td className="bordersSpace">
                            {data.bipolarDisOrderReason}
                          </td>
                          <td className="bordersSpace">
                            {data.majorDepression}
                          </td>
                          <td className="bordersSpace">
                            {data.majorDepressionReason}
                          </td>
                          <td className="bordersSpace">{data.schizophrenia}</td>
                          <td className="bordersSpace">
                            {data.schizophreniaReason}
                          </td>
                        </React.Fragment>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </ThemeContext.Consumer>
        </div>
      ) : Sheet == "4" ? (
        <div className="table-responsive">
          <ThemeContext.Consumer>
            {(context) => (
              <table class="table text-center table-sm">
                <thead
                  class="bordersSpace"
                  style={{
                    background: context.themeColors.primaryColor,
                    color: "white",
                  }}
                >
                  <tr class="vendorListHeading bordersSpace">
                    <th className="bordersSpace" rowSpan="2">
                      NO.
                    </th>
                    <th className="bordersSpace" rowSpan="3">
                      Student
                    </th>
                    <th className="bordersSpace" colSpan="2">
                      Asperger Syndrome
                    </th>
                    <th className="bordersSpace" colSpan="2">
                      Behaviour
                    </th>
                    <th className="bordersSpace" colSpan="2">
                      Communication
                    </th>
                    <th className="bordersSpace" colSpan="2">
                      Social Interaction
                    </th>
                  </tr>
                  <tr class="vendorListHeading bordersSpace">
                    <th className="bordersSpace">Found</th>
                    <th className="bordersSpace">Reason</th>
                    <th className="bordersSpace">Found</th>
                    <th className="bordersSpace">Reason</th>
                    <th className="bordersSpace">Found</th>
                    <th className="bordersSpace">Reason</th>
                    <th className="bordersSpace">Found</th>
                    <th className="bordersSpace">Reason</th>
                  </tr>
                </thead>

                <tbody>
                  {Data.map((item, index) => (
                    <tr class="font-weight-bold bordersSpace" key={index}>
                      <td className="bordersSpace">{index + 1}</td>
                      <td class="bordersSpace font-weight-bold">
                        {item.studentName}
                      </td>
                      {item.studentNeeds.map((data, indexes) => (
                        <React.Fragment>
                          <td className="bordersSpace">
                            {data.aspergerSyndrome}
                          </td>
                          <td className="bordersSpace">
                            {data.aspergerSyndromeReason}
                          </td>
                          <td className="bordersSpace">{data.behaviour}</td>
                          <td className="bordersSpace">
                            {data.behaviourReason}
                          </td>
                          <td className="bordersSpace">{data.communication}</td>
                          <td className="bordersSpace">
                            {data.communicationReason}
                          </td>
                          <td className="bordersSpace">
                            {data.socialInteraction}
                          </td>
                          <td className="bordersSpace">
                            {data.socialInteractionReason}
                          </td>
                        </React.Fragment>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </ThemeContext.Consumer>
        </div>
      ) : null}
    </React.Fragment>
  );
}
