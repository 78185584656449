import React from "react";
import ViewRendering from "../../../components/ViewRendering";
import { renderingStuff, images } from "../../../utils";
import DashboardLayout from "../../../components/HOC/DashboardLayout";
import NewViewRendering from "../../../components/ViewRendering/NewViewRendering";

const LPWScreen = (props) => {
  return (
    <React.Fragment>
      {/* <ViewRendering
				backColor={true}
				mainHead={true}
				mainHeading={images.imgTThead}
				data={renderingStuff.LPWLinks}
			/> */}
      <DashboardLayout name="Time Table Module" image={images.imgTT}>
        <NewViewRendering {...props} data={renderingStuff.LPWLinks} />
      </DashboardLayout>
    </React.Fragment>
  );
};

export default LPWScreen;
