import * as actionTypes from '../actionTypes';
import Axios from 'axios';
import config from '../../config/config';
import setAuthorizationToken from '../../config/setAuthToken';
import jwt from 'jsonwebtoken';

export function Login(data, url) {
	return dispatch => {
		return Axios.post(`${config.localhttp}${url}`, data).then(res => {
			console.log('response', res);
			const token = res.data;
			console.log('token', token);
			console.log('decode', jwt.decode(token));
			localStorage.setItem('jwtToken', token);
			setAuthorizationToken(token);
			dispatch(setCurrentUser(jwt.decode(token)));
			debugger;
			return res.data;
		});
	};
}
export function AddAdmin(data, url) {
	return dispatch => {
		return Axios.post(`${config.localhttp}${url}`, data)
			.then(success => {
				console.log(success);
				return success;
			})
			.catch(error => {
				console.log(error);
			});
	};
}
export function setCurrentUser(userDetails) {
	return {
		type: actionTypes.SET_USER,
		payload: userDetails
	};
}
export function logout() {
	return dispatch => {
		localStorage.removeItem('jwtToken');
		setAuthorizationToken(false);
		dispatch(setCurrentUser({}));
	};
}
