import * as actionTypes from '../Actions/actionTypes';
import isEmpty from 'lodash/isEmpty';

const defaultState = {
	apiFlag: true,
	Data: []
};

export default function setTransportReducer(state = defaultState, action = {}) {
	switch (action.type) {
		case actionTypes.ADD_TRANSPORT:
			return { ...state };
		case actionTypes.GET_ALL_TRANSPORT:
			return { ...state, Data: [...action.payload] };
		case actionTypes.SET_LOADER_FLAG_TRANSPORT:
			return { ...state, apiFlag: action.payload };
		case actionTypes.DELETE_TRANSPORT:
			let filterCandidateReqList = state.Data.filter(
				filterOBJ => filterOBJ.id !== action.payload
			);
			return {
				...state,
				Data: filterCandidateReqList
			};
		case actionTypes.EDIT_TRANSPORT_RECORD:
			var adminData = [...state.Data];
			var index = adminData.findIndex(obj => obj.id === action.payload.id);
			adminData[index].perKmFare = action.payload.perKmFare;
			adminData[index].fare = action.payload.fare;

			return { ...state, Data: [...adminData] };
		default:
			return state;
	}
}
