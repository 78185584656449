import * as actionTypes from '../actionTypes';

///////////******* Student Admission Form Actions  */
//checking
export function getAllStudentDocuments(data) {
	return {
		type: actionTypes.GET_ALL_STUDENT_DOCUMENTS,
		payload: data
	};
}
export function getAllParentDocument(data) {
	return {
		type: actionTypes.GET_ALL_PARENT_DOCUMENTS,
		payload: data
	};
}
export function setImageDataIntoStore(data) {
	return {
		type: actionTypes.GET_UPLOAD_STUDENT_IMAGE,
		payload: data
	};
}
export function setAllClassSectonGrade(allData) {
	return {
		type: actionTypes.GET_CLASS_SECTION_GRADE,
		payload: allData
	};
}
export function getAllRoutesDetails(allData) {
	return {
		type: actionTypes.GET_ALL_ROUTES_DETAILS,
		payload: allData
	};
}
export function getCurrentSession(allData) {
	return {
		type: actionTypes.GET_CURRENT_SESSION,
		payload: allData
	};
}
export function setFormLeaveFlag(flag) {
	return {
		type: actionTypes.SET_FORM_LEAVE_FLAG,
		payload: flag
	};
}
export function setSingleFormData(data) {
	return {
		type: actionTypes.SET_SINGLE_FORM_DATA,
		payload: data
	};
}
export function setAllAdmissionData(allData) {
	return {
		type: actionTypes.SET_ADMISSION_FORM_DATA,
		payload: allData
	};
}
export function addStudentForm(Data) {
	return {
		type: actionTypes.ADD_STUDENT_ADMISSION_FORM,
		payload: Data
	};
}
export function updateSectionGradeLoader(setFlag) {
	return {
		type: actionTypes.SET_LOADER_SECTION_GRADE,
		payload: setFlag
	};
}

export function getIndividualStudentData(data) {
	return {
		type: actionTypes.GET_INDIVIDUAL_STUDENT_DATA,
		payload: data
	};
}
export function deleteSingleFormData(id) {
	return {
		type: actionTypes.DELETE_ADD_FORM_FROM_VIEW,
		payload: id
	};
}
// export function updateCampus(data) {
//     ;
//     return{
//         type:actionTypes.EDIT_CAMPUS_RECORD,
//         payload:data,
//     }
// }
// export function deleteCampus(id) {
//     ;
//     return{
//         type:actionTypes.DELETE_CAMPUS,
//         payload:id,
//     };
// }
