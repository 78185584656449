import * as actionTypes from '../actionTypes';

export function setAllSyllabusInRedux(allData) {
	return {
		type: actionTypes.GET_ALL_SYLLABUS,
		payload: allData
	};
}
export function setAllPaginationSyllabusInRedux(allData) {
	return {
		type: actionTypes.GET_ALL_SYLLABUS_PAGINATION,
		payload: allData
	};
}
export function addSyllabus(Data) {
	return {
		type: actionTypes.ADD_SYLLABUS,
		payload: Data
	};
}
export function getSessionsForSyllabus(allData) {
	return {
		type: actionTypes.SET_ALL_SESSION_NAME_INTO_SYLLABUS_SELECT,
		payload: allData
	};
}
export function getClassGradeSubjectForSyllabus(allData) {
	return {
		type: actionTypes.SET_ALL_CLASS_NAME_INTO_SYLLABUS_SELECT,
		payload: allData
	};
}
export function updateSyllabusFlag(setFlag) {
	return {
		type: actionTypes.SET_LOADER_FLAG_SYLLABUS,
		payload: setFlag
	};
}
export function updateSyllabusFlagAll(setFlag) {
	return {
		type: actionTypes.SET_LOADER_FLAG_SYLLABUS_ALL,
		payload: setFlag
	};
}
export function updateSyllabus(data) {
	return {
		type: actionTypes.EDIT_SYLLABUS_RECORD,
		payload: data
	};
}
export function deleteSyllabus(id) {
	return {
		type: actionTypes.DELETE_SYLLABUS,
		payload: id
	};
}
