import React from 'react'
import { connect } from 'react-redux';
import $ from 'jquery'
import SelectionInput from '../../components/SelectionInput/SelectionInput';
import TextInput from '../../components/TextInput/TextInput'
import FormLayoutWrapper from '../../components/HOC/FormLayoutWrapper';
import * as sharedActions from '../../Actions/sharedActions';
import Button from '../../components/Button';
import SnackBar from '../../components/SnackBar/SnackBar';
import { bindActionCreators } from 'redux';
import general from '../../utils/general';
import TextArea from '../../components/TextArea'
import DatePicker from 'react-date-picker';
import ThemeContext from "../../context/themeContext/ThemeContext";

class AddLeave extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            accountId: props.name,
            accountType: props.role,
            errors: {},
            errorMsg: false,
            userData: [],
            classData: [],
            sectionData: [],
            sessionData: [],
            className: '',
            student: '',
            classId: '',
            sessionId: '',
            sectionId: '',
            studentId: '',
            subjectId: '',
            fromdate: '',
            todate: '',
            leaveType: '',
            term: '',
            description: '',
            chooseFile: ''
        }
    }

    componentDidMount() {
        $(document).ready(function () {
            $('[data-toggle="tooltip"]').tooltip();
        });
        this.props.sharedActions
            .getDataWithoutDispatch(
                `/api/StudentDetail?userName=${this.props.name}`
            )
            .then(success => {
                this.props.sharedActions
                    .getDataWithoutDispatch(
                        `/api/GetSubjectByClassId/?classId=${success[0].classId}`
                    )
                    .then(success => {
                        this.setState({
                            subjectData: success
                        });
                    })
                    .catch(error => {
                        this.setState({
                            isLoading: false
                        })
                        console.log(error);
                    });

                this.setState({
                    userData: success,
                    className: success[0].className,
                    classId: success[0].classId,
                    section: success[0].section,
                    sectionId: success[0].sectionId,
                    session: success[0].session,
                    sessionId: success[0].sessionId,
                    studentName: success[0].name,
                    studentId: success[0].studentId,
                    isLoading: false
                });
            })
            .catch(error => {
                this.setState({
                    isLoading: false
                });
                console.log(error);
            });
    }

    onHandleSelectChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onChangeFile = e => {
        console.log(e.target.files[0]);
        
        this.setState({
            chooseFile: e.target.files[0]
        });
    };

    handleChange = (date, state) => {
        this.setState({
            [state]: date
        });
    };

    onHandleTextChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onSubmit = (e) => {
        e.preventDefault();
        const {
            studentId,
            classId,
            sectionId,
            sessionId,
            subjectId,
            term,
            leaveType,
            chooseFile,
            toDate,
            fromDate,
            description,
            accountId,
            accountType
        } = this.state;
        if (
            Object.is(studentId, '') ||
            Object.is(classId, '') ||
            Object.is(sectionId, '') ||
            Object.is(sessionId, '') ||
            Object.is(subjectId, '') ||
            Object.is(term, '') ||
            Object.is(leaveType, '') ||
            Object.is(chooseFile, '') ||
            Object.is(toDate, '') ||
            Object.is(fromDate, '') ||
            Object.is(description, '')
        ) {
            this.setState({
                errorMsg: true
            });
        } else {
            let date = new Date(toDate)
            let dateTo = ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear();

            date = new Date(fromDate)
            let dateFrom = ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear();

            let file = chooseFile;
            let accountType = this.state.accountType;
            let accountId = this.state.accountId;
            accountId = this.props.name;
            accountType = this.props.role;
            let data = new FormData();
            data.append('studentId', studentId);
            data.append('classId', classId);
            data.append('sectionId', sectionId);
            data.append('sessionId', sessionId);
            data.append('subjectId', subjectId);
            data.append('term', term);
            data.append('leaveType', leaveType);
            data.append('file', file);
            data.append('todate', dateTo);
            data.append('fromdate', dateFrom);
            data.append('accountId', accountId);
            data.append('accountType', accountType);
            data.append('description', description);
            console.log('DATA Part', data);
            this.setState({
                isLoading: true
            });
            this.props.sharedActions
                .simpleAddRequest(
                    '/api/StudentLeaveApplication/',
                    data,
                )
                .then(success => {
                    this.setState({
                        toDate: null,
                        fromDate: null,
                        term: '',
                        leaveType: '',
                        description:'',
                        errorMsg: false,
                        subjectId: '',
                        isLoading: false,
                        msg: 'Leave Requested Successfully!',
                        chooseFile: ''
                    });
                    this.props.snackbar();
                })
                .catch(error => { });
        }

    };

    render() {
        const { errors, isLoading, subjectData} = this.state;

        const Loader = (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div class="spinner-border" style={{ color: '#01AC8A' }}></div>
            </div>
        );

        const Form = (
            <ThemeContext.Consumer>
            {(context) => (
            <FormLayoutWrapper
                formName='Leave Form'
                borderColor={context.themeColors.primaryColor}
                borderRadius={15}
            >
                {this.state.errorMsg ? (
                    <p style={{ color: 'red', textAlign: 'center' }}>
                        Please fill the form properly
								</p>
                ) : (
                        ''
                    )}


                <div className="row">

                    <TextInput
                        feildName={'studentName'}
                        inputLabelName={'Student Name'}
                        errors={errors}
                        stateData={this.state}
                        placeholder="Student Name"
                        readOnly={true}
                        type={'text'}
                        isValidte={false}
                    />

                    <TextInput
                        feildName={'className'}
                        inputLabelName={'Class Name'}
                        errors={errors}
                        stateData={this.state}
                        placeholder="Class Name"
                        readOnly={true}
                        type={'text'}
                        isValidte={false}

                    />

                    <TextInput
                        feildName={'section'}
                        inputLabelName={'Section Name'}
                        errors={errors}
                        stateData={this.state}
                        placeholder="Section"
                        readOnly={true}
                        type={'text'}
                        isValidte={false}

                    />

                    <TextInput
                        feildName={'session'}
                        inputLabelName={'Session Details'}
                        errors={errors}
                        stateData={this.state}
                        placeholder="Session Details"
                        readOnly={true}
                        type={'text'}
                        isValidte={false}

                    />

                    <SelectionInput
                        marginTop={20}
                        feildName={'subjectId'}
                        selectName={'Subject Name'}
                        onHandleChange={e => this.onHandleSelectChange(e)}
                        errors={errors}
                        optionsArrys={subjectData}
                        selectedText={'Subject'}
                        stateData={this.state}
                        // iconClassName={'fas fa-building'}
                        optionType="dynamicWithPropIdAndName"
                        property={'subjectName'}
                        propertyId={'id'}
                        successflag={'successflag'}
                        isValidte={false}
                    // editUse={'staffEdit'}
                    />

                    <SelectionInput
                        marginTop={20}
                        feildName={'term'}
                        selectName={'Term'}
                        onHandleChange={e => this.onHandleSelectChange(e)}
                        errors={errors}
                        optionsArrys={general.term}
                        selectedText={'Term'}
                        stateData={this.state}
                        // iconClassName={'fas fa-building'}
                        optionType="static"
                        successflag={'successflag'}
                        isValidte={false}
                    // editUse={'staffEdit'}
                    />

                    <SelectionInput
                        marginTop={20}
                        feildName={'leaveType'}
                        selectName={'Leave Type'}
                        onHandleChange={e => this.onHandleSelectChange(e)}
                        errors={errors}
                        optionsArrys={general.leaveType}
                        selectedText={'Leave Type'}
                        stateData={this.state}
                        // iconClassName={'fas fa-building'}
                        optionType="static"
                        successflag={'successflag'}
                        isValidte={false}
                    // editUse={'staffEdit'}

                    />

                    <div class="col-md-6" style={{ marginTop: 47 }}>
                        <div class="md-form mb-0">
                            <label for="email" class=""
                                style={{
                                    fontSize: 17,
                                    color: '#000',
                                    fontWeight: 'normal'
                                }}   >
                                File
											</label>

                            <div class="file-upload">
                                <div class="file-select">
                                    <div class="file-select-button" id="fileName">
                                        Choose File
													</div>
                                    <div class="file-select-name" id="noFile">
                                        {this.state.chooseFile === '' ? 'No File Choosen' : this.state.chooseFile.name}
                                    </div>
                                    <input
                                        type="file"
                                        name="chooseFile"
                                        id="chooseFile"
                                        onChange={this.onChangeFile}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6" style={{ marginTop: 25 }}>
                        <div class="md-form mb-0">
                            <label for="email" class="">
                                Date From
											</label>
                            <br />
                            <DatePicker
                                onChange={e => this.handleChange(e, 'fromDate')}
                                value={this.state.fromDate}
                                selectDateOnly
                            />
                        </div>
                    </div>

                    <div class="col-md-6" style={{ marginTop: 25 }}>
                        <div class="md-form mb-0">
                            <label for="email" class="">
                                Date To
											</label>
                            <br />
                            <DatePicker
                                onChange={e => this.handleChange(e, 'toDate')}
                                value={this.state.toDate}
                            />
                        </div>
                    </div>



                    <TextArea
                        marginTop={20}
                        feildName={'description'}
                        inputLabelName={'Description'}
                        onHandleChange={this.onHandleTextChange}
                        errors={errors}
                        stateData={this.state}
                        // iconClassName={'fas fa-percentage'}
                        placeholder={'Add Description'}
                        isValidte={false}
                    />
                </div>
                <br />
                <Button
                    width="100%"
                    buttonClass="customButton"
                    btnName="Submit"
                    loaderBmargin={5}
                    stateData={this.state}
                    marginTop={20}
                    onHandleSubmit={this.onSubmit}
                    margin={'0 auto'}
                />

            </FormLayoutWrapper >
                )}
                </ThemeContext.Consumer>
        )
        return (
            <React.Fragment>
                <SnackBar msg={this.state.msg} />
                {isLoading ? Loader : Form}
            </React.Fragment>

        )
    }
}

function mapStateToProps(state) {
    return {
        role: state.setUserReducer.users.role,
        name: state.setUserReducer.users.unique_name,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        sharedActions: bindActionCreators(sharedActions, dispatch)
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddLeave);