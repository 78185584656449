import * as actionTypes from '../Actions/actionTypes';

const defaultState = {
	apiFlag: true,
	Data: [],
	selectSupplier: [],
	select: [],
	getlegderlist: [],
	sessionSelect: []
};

export default function setFinanceReducer(state = defaultState, action = {}) {
	switch (action.type) {
		case actionTypes.ADD_FINANCE:
			return { ...state };
		case actionTypes.SET_ALL_SESSION_NAME_INTO_FINANCE_SELECT:
			return {
				...state,
				sessionSelect: action.payload
			};
		case actionTypes.GET_ALL_FINANCE:
			return {
				...state,
				Data: action.payload,
				getlegderlist: action.payload.getlegderlist
			};
		case actionTypes.Add_Recrod_To_FINANCE:
			let updateLedgerList = [action.payload, ...state.getlegderlist];

			return {
				...state,
				getlegderlist: [...updateLedgerList]
			};
		case actionTypes.SET_LOADER_FLAG_FINANCE_ADD:
			return {
				...state,
				apiFlag: action.payload
			};
		case actionTypes.DELETE_FINANCE:
			let filterCandidateReqList = state.getlegderlist.filter(
				filterOBJ => filterOBJ.id !== action.payload
			);

			return {
				...state,
				getlegderlist: filterCandidateReqList
			};
		case actionTypes.EDIT_FINANCE_RECORD:
			var adminData = state.getlegderlist;

			var index = adminData.findIndex(obj => obj.id === action.payload.id);

			adminData[index].account = action.payload.account;
			adminData[index].description = action.payload.description;
			adminData[index].withdrawAmount = action.payload.withdrawAmount;
			adminData[index].depositAmount = action.payload.depositAmount;
			return {
				...state,
				getlegderlist: [...adminData]
			};
		default:
			return state;
	}
}
