import React, { Component } from 'react';
import './AddGm.css';
import { Prompt } from 'react-router-dom';
import Axios from 'axios/index';
import config from '../../../config/config';
import { connect } from 'react-redux';
import DateandTime from '../../Transport/DateandTime';
import SnackBar from '../../../components/SnackBar/SnackBar';

class GM extends Component {
	constructor(props) {
		super(props);
		this.state = {
			username: '',
			password: '',
			post: 'general manager',
			valid: false,
			msg: '',
			errorMsg: false,
			name: '',
			backColor: ''
		};
	}
	onHandleTextChange = e => {
		console.log(e.target.value);
		this.setState({
			[e.target.name]: e.target.value
		});
	};

	onHandleSubmit = e => {
		e.preventDefault();
		const { username, password } = this.state;
		if (Object.is(username, '') || Object.is(password, '')) {
			this.setState({
				valid: false,
				errorMsg: true
			});
		} else {
			this.setState({
				valid: true
			});
			let token = '';
			if (localStorage.jwtToken) {
				token += localStorage.getItem('jwtToken') + token;
			}
			var Headconfig = {
				headers: {
					'Access-Control-Allow-Origin': '*',
					Authorization: 'Bearer ' + token
				}
			};
			var bodyParameters = {
				userName: this.state.username,
				name: this.state.name,
				password: this.state.password,
				post: this.state.post,
				accountType: this.props.role,
				accountId: 'Dawar01'
			};
			Axios.post(`${config.localhttp}/api/GManager`, bodyParameters, Headconfig)
				.then(response => {
					console.log(response);

					if (response.status == 200) {
						this.setState({
							username: '',
							password: '',
							name: '',
							msg: 'General Manager Added Successfully',
							backColor: '#01ac8a'
						});
						this.props.snackbar();
					}
				})
				.catch(error => {
					this.setState({
						backColor: 'red',
						msg: error.response.data.Message
					});
					this.props.snackbar();
					if (error.response) {
						// The request was made and the server responded with a status code

						// that falls out of the range of 2xx
						console.log(error.response.data);
						console.log(error.response.status);
						console.log(error.response.headers);
					} else if (error.request) {
						// The request was made but no response was received

						// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
						// http.ClientRequest in node.js
						console.log(error.request);
					} else {
						// Something happened in setting up the request that triggered an Error
						console.log('Error', error.message);
					}
				});
		}
	};
	componentDidUpdate() {
		if (this.state.msg || this.state.errorMsg) {
			setTimeout(() => {
				this.setState({
					msg: false,
					errorMsg: false
				});
			}, 3000);
		}
	}
	Message = (msg, color, fontSize, flag) => {
		if (flag) {
			return (
				<p style={{ fontSize: fontSize }} class={`text-${color}`}>
					{msg}
				</p>
			);
		}
	};

	render() {
		return (
			<div className="container">
				<SnackBar msg={this.state.msg} backColor={this.state.backColor} />
				<Prompt
					when={this.state.valid !== true}
					message="Leaving this form will lose your data"
				/>
				<section class="mb-4">
					<h2 class="h1-responsive font-weight-bold text-center my-4">
						Add General Manager
					</h2>

					<p class="text-center w-responsive mx-auto mb-5" />

					<div
						class="row"
						style={{
							justifyContent: 'center',
							textAlign: 'center'
						}}
					>
						<div
							class="col-md-9 mb-md-0 mb-5"
							style={{
								border: 'solid',
								borderColor: '#01ac8a',
								padding: 40,
								borderRadius: 15,
								width: 'auto'
							}}
						>
							{this.Message(
								'Please fill the form properly!',
								'danger',
								20,
								this.state.errorMsg
							)}

							<form id="contact-form" name="contact-form">
								<div class="row">
									<div class="col-md-12">
										<div class="md-form mb-0">
											<DateandTime />
										</div>
									</div>
								</div>
								<br />
								<div class="row">
									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="name" class="">
												Admin Name
											</label>
											<input
												type="text"
												style={{ borderColor: '#01AC8A' }}
												readOnly={true}
												value={this.props.name}
												name="AdminName"
												class="form-control"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="name" class="">
												Admin Type
											</label>
											<input
												type="text"
												style={{ borderColor: '#01AC8A' }}
												readOnly={true}
												value={this.props.role}
												name="AdminType"
												class="form-control"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="name" class="">
												Name
											</label>
											<input
												type="text"
												style={{ borderColor: '#01AC8A' }}
												name="name"
												value={this.state.name}
												class="form-control"
												placeholder={'Enter Name'}
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="name" class="">
												Username
											</label>
											<input
												type="text"
												name="username"
												value={this.state.username}
												style={{ borderColor: '#01AC8A' }}
												class="form-control"
												placeholder={'Enter Username'}
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="name" class="">
												Password
											</label>
											<input
												type="password"
												name="password"
												style={{ borderColor: '#01AC8A' }}
												value={this.state.password}
												class="form-control"
												placeholder={'Enter Password'}
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>
								</div>
							</form>
							<br />
							<br />
							<div class="text-center text-md-center">
								<button onClick={this.onHandleSubmit} class="btns">
									Add
								</button>
							</div>
							<div class="status" />
						</div>
					</div>
				</section>
			</div>
		);
	}
}
function mapStateToProps(state) {
	return {
		auth: state.setUserReducer.auth,
		role: state.setUserReducer.users.role,
		name: state.setUserReducer.users.unique_name
	};
}
export default connect(
	mapStateToProps,
	null
)(GM);
