import React from "react";
import CustomHeading from "../CustomHeading/index";
import DateandTime from "../../Container/Transport/DateandTime";
import { MAIN_HEADING, SMALL_HEADING, SUB_HEADING } from "../../utils/general";
import { useContext } from "react";
import ThemeContext from "../../context/themeContext/ThemeContext";
const FormLayoutWrapper = (props) => {
  const {
    formName,
    borderColor,
    borderRadius,
    type,
    errorFlag,
    flagName,
    styleHead = false,
  } = props;
  const themeContext = useContext(ThemeContext);
  return (
    <React.Fragment>
      <section class="mb-4">
        {styleHead ? (
          <CustomHeading headingText={formName} type={SUB_HEADING} />
        ) : (
          <h2
            style={{ background: themeContext.themeColors.primaryColor }}
            class="h1-responsive font-weight-bold text-center my-4 generalHead"
          >
            {formName}
          </h2>
        )}

        <p class="text-center w-responsive mx-auto mb-5" />
        <div
          class="row"
          style={{
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <div
            class="col-md-9 mb-md-0 mb-5"
            style={{
              border: "solid",
              borderColor: borderColor,
              padding: 40,
              borderRadius: borderRadius,
              width: "auto",
            }}
          >
            {errorFlag && errorFlag[flagName] ? (
              <p style={{ color: "red" }}>Please fill the form properly!</p>
            ) : (
              ""
            )}
            <form
              action="javascript:void(0)"
              id="contact-form"
              name="contact-form"
            >
              <div class="row">
                <div class="col-md-12">
                  <div class="md-form mb-0">
                    <DateandTime />
                  </div>
                </div>
              </div>
              <br />
              <div class={type}>{props.children}</div>
            </form>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};
export default FormLayoutWrapper;
