import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import $ from 'jquery';
import isEmpty from 'lodash/isEmpty';
import Header from '../../Assets/images/3 headers-03.png';

import LoaderWrapper from "../../components/HOC/LoaderWrapper";
import ThemeContext from "../../context/themeContext/ThemeContext";


class PayslipDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			payslip: this.props.location.state.item
		};
	}

	static contextType = ThemeContext


	componentDidMount() {
		console.log('Payslip', this.state.payslip);
	}

	myFunction = () => {
		// Get the snackbar DIV
		var x = document.getElementById('snackbar');

		// StepperGeneralButtons the "show" class to DIV
		x.className = 'show';

		// After 3 seconds, remove the show class from DIV
		setTimeout(function() {}, 3000);
	};

	onHandleText = e => {
		this.setState({
			[e.target.name]: e.target.value
		});
	};

	PrintContent = el => {
		var restorepage = document.body.innerHTML;
		var Printcontent = document.getElementById(el).innerHTML;
		document.body.innerHTML = Printcontent;
		window.print();
		document.body.innerHTML = restorepage;
	};

	render() {
		console.log('Props called');

		console.log('Props', this.props);
		const { payslip } = this.state;

		return (
			<div>
				<div id="slip">
					<div
						class="container"
						style={{ border: `2px solid ${this.context.themeColors.primaryColor}`, borderRadius: '5px' }}
					>
						<div class="row" style={{ borderBottom: `1px solid grey` }}>
							<div class="">
								<div
									style={{
										width: '100%',
										height: '100%'
									}}
								>
									<img src={Header} height={'100%'} width={'100%'} />
								</div>
							</div>
						</div>
						<div class="row" style={{ borderBottom: `1px solid grey` }}>
							<div class="col-xs-6 col-sm-6 col-md-6">
								<h5>Payslip</h5>
								<p style={{ color: 'black' }}>
									Name: <strong>{payslip.staffName}</strong>
								</p>
							</div>
							<div
								class="col-xs-6 col-sm-6 col-md-6 text-right"
								style={{ fontSize: 12 }}
							>
								<h5>{payslip.slipNo}</h5>
								<p style={{ color: 'black' }}>
									Date:<strong> {payslip.date}</strong>
									<br />
									Month:<strong> {payslip.month}</strong>
									<br />
									Year:<strong> {payslip.year}</strong>
									<br />
									Status:<strong> Approved</strong>
								</p>
							</div>
						</div>

						<div class="row" style={{ borderBottom: `1px solid grey` }}>
							<div class="col-xs-6 col-sm-6 col-md-6" style={{ fontSize: 12 }}>
								<p style={{ color: 'black' }}>
									No. of Absent:<strong> {payslip.noOfAbsents}</strong>
									<br />
									No. of Presents:<strong> {payslip.noOfPresents}</strong>
									<br />
									No. of Lates:<strong> {payslip.noOfLates}</strong>
									<br />
									No. of Leaves:<strong> {payslip.noOfLeaves}</strong>
								</p>
							</div>
							<div
								class="col-xs-6 col-sm-6 col-md-6 text-right"
								style={{ fontSize: 12 }}
							>
								<p style={{ color: 'black' }}>
									No. of Short Leaves:<strong> {payslip.noOfLeaves}</strong>
									<br />
									No. Late Hours:<strong> {payslip.lateHours}</strong>
									<br />
									No. Late Minutes:<strong> {payslip.lateMinutes}</strong>
									<br />
									No. Consecutive Lates:
									<strong> {payslip.consecutiveLate}</strong>
								</p>
							</div>
						</div>

						<div class="row">
							<table class="table table-hover table-sm">
								<thead>
									<tr>
										<th class="text-left">#</th>
										<th class="text-left col-md-2">Description</th>
										<th class="text-right">Amount</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td class="text-left"> 1 </td>
										<td class="text-left">
											<em>Basic Salary</em>
										</td>
										<td class="text-right">{payslip.basicSalary}</td>
									</tr>
									<tr>
										<td class="text-left"> 3 </td>
										<td class="text-left">
											<em>Loan Amount</em>
										</td>
										<td class="text-right">{payslip.loanAmount}</td>
									</tr>
									<tr>
										<td class="text-left"> 4 </td>
										<td class="text-left">
											<em>Allowances</em>
										</td>
										<td class="text-right">{payslip.allowances}</td>
									</tr>
									<tr>
										<td class="text-left"> 5 </td>
										<td class="text-left">
											<em>Bonus Amount</em>
										</td>
										<td class="text-right">{payslip.bonusAmount}</td>
									</tr>
									<tr>
										<td class="text-left"> 6 </td>
										<td class="text-left">
											<em>Tax Amount in %</em>
										</td>
										<td class="text-right">{payslip.taxAmount}%</td>
									</tr>
									<tr>
										<td class="text-left"> 7 </td>
										<td class="text-left">
											<em>Absent Salary Cut</em>
										</td>
										<td class="text-right">{payslip.absentsalaryCut}</td>
									</tr>
									<tr>
										<td class="text-left"> 8 </td>
										<td class="text-left">
											<em>Late Salary Cut</em>
										</td>
										<td class="text-right">{payslip.lateSalaryCut}</td>
									</tr>
									<tr>
										<td class="text-left"> 9 </td>
										<td class="text-left">
											<em>Consecutive Salary Cut</em>
										</td>
										<td class="text-right">
											{payslip.consecutiveLateSalaryCut}
										</td>
									</tr>
									<tr>
										<td class="text-left"> 10 </td>
										<td class="text-left">
											<em>Per Day Salary</em>
										</td>
										<td class="text-right">{payslip.perdaySalary}</td>
									</tr>
									<tr>
										<td>   </td>
										<td class="text-right">
											<p>
												<strong>Gross Income: </strong>
											</p>
											<p>
												<strong>Total Amount Deduct: </strong>
											</p>
										</td>
										<td class="text-right">
											<p>
												<strong>{payslip.grossIncome}</strong>
											</p>
											<p>
												<strong>{payslip.totatAmountDeduct}</strong>
											</p>
										</td>
									</tr>
									<tr>
										<td>   </td>
										<td class="text-right">
											<h5>
												<strong>Net Income: </strong>
											</h5>
										</td>
										<td class="text-right text-danger">
											<h4>
												<strong>{payslip.NetIncome}</strong>
											</h4>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div class="row" style={{ borderBottom: `1px solid grey` }}>
							<div class="col-xs-6 col-sm-6 col-md-6" style={{ fontSize: 12 }}>
								<br />
								<br />
								<br />
								<p style={{ color: 'black' }}>
									Issued by. _______________________
								</p>
							</div>
							<div
								class="col-xs-6 col-sm-6 col-md-6 text-right"
								style={{ fontSize: 12 }}
							>
								<br />
								<br />
								<br />
								<p style={{ color: 'black' }}>
									Recieved by. _______________________
								</p>
							</div>
						</div>
					</div>
				</div>
				<button
					class="btn btn-primary"
					onClick={() => this.PrintContent('slip')}
				>
					Print
				</button>
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch)
	};
}
function mapStateToProps(state) {
	return {
		userData: state.setUserReducer.users
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PayslipDetails);
