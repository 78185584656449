import React, { Component } from "react";
import $ from "jquery";
import "../Admin/viewAdmin.css";
import Axios from "axios";
import config from "../../config/config";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as getAllAdminActions from "../../Actions/getAllAdmin";
import setAllAdminReducer from "../../Reducers/setAllAdminReducer";
import * as CampusActions from "../../Actions/Campus";
import * as sharedActions from "../../Actions/sharedActions";
import setCampusReducer from "../../Reducers/setCampusReducer";
import SnackBar from "../../components/SnackBar/SnackBar";
import ThemeContext from "../../context/themeContext/ThemeContext";

class FeeAccounts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adminData: "",
      modalData: null,
      campusAddress: "",
      campusName: "",
      campusCity: "",
      campusPhoneNumber: "",
      enabled: false,
      id: null,
      isLoading: true,
      disablepage: false,
      disableBPage: true,
      currentPage: 1,
      todosPerPage: 10,
      pageCounter: 10,
      session: "",
      sessionId: '1',
      msg: "",
    };
  }

  handleClickNextFrwd = () => {
    const { adminData, todosPerPage, count, currentPage } = this.state;
    let pageNumbers = [];
    for (
      let i = 1;
      i <= Math.ceil(adminData.length / this.state.todosPerPage);
      i++
    ) {
      pageNumbers.push(i);
    }

    console.log("pagelength: ", pageNumbers.length);
    console.log("current page: ", currentPage);
    if (currentPage === pageNumbers.length) {
      this.setState({
        disablepage: true,
      });
    } else {
      this.setState((prevState) => ({
        currentPage: prevState.currentPage + 1,
        disableBPage: false,
      }));
    }
  };
  handleClickNextBack = () => {
    const { adminData, todosPerPage, count, currentPage } = this.state;
    console.log("current page: ", currentPage);
    if (currentPage === 1) {
      this.setState({
        disableBPage: true,
        disablepage: false,
      });
    } else {
      this.setState((prevState) => ({
        currentPage: prevState.currentPage - 1,
      }));
      this.setState({
        disablepage: false,
      });
    }
  };
  handleClickNext = (event) => {
    this.setState({
      currentPage: Number(event.target.id),
      disablepage: false,
      disableBPage: false,
    });
  };
  //   static getDerivedStateFromProps(nextProps, prevState) {
  //     if (prevState.adminData !== nextProps.AllAdminData) {
  //       return {
  //         adminData: nextProps.AllAdminData,
  //       };
  //     }
  //     return null;
  //   }
  componentDidUpdate() {
    $('[data-toggle="tooltip"]').tooltip();
  }
  componentDidMount() {
    $(document).ready(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });
    if (this.props.allAdminState.apiFlag) {
      this.props.sharedActions
        .getDataWithoutDispatch("/api/AccountReport/?sessionId=1")
        .then((success) => {
          debugger;
          this.setState({
            adminData: success,
            isLoading: false,
          });
        })
        .catch((err) => {
          this.setState({
            isLoading: false,
          });

          console.log(err);
        });
    }
    this.props.sharedActions
      .getDataWithoutDispatch("/api/Session")
      .then((success) => {
        debugger;
        this.setState({
          session: success,
        });
      })
      .catch((err) => {
        this.setState({});

        console.log(err);
      });
  }
  btnTechHover = () => {
    $(document).ready(function () {
      $("button").hover(
        function () {
          $(this).css("box-shadow", "0px 5px 5px grey");
          $(this).css("border-color", "white");
        },
        function () {
          $(this).css("box-shadow", "0px 0px 0px white");
        }
      );
    });
  };
  onTakeItemDelete = (itemId) => {
    this.setState({
      modalData: [],
    });
    this.props.sharedActions
      .deleteRecord(
        "/api/CampusDetail/",
        itemId,
        this.props.campusActions.deleteCampus
      )
      .then((success) => {
        this.setState({
          msg: "Delete Record Successfully!",
        });
        this.props.snackbar();
      })
      .catch((error) => {});
  };
  onTakeItem = (itemId) => {
    // campusAddress:'',
    // campusName:'',
    // campusPhoneNumber:"",
    // campusCity:"",
    this.setState({
      modalData: [],
    });
    const { adminData } = this.state;
    let getData = adminData.filter((item) => item.id === itemId);

    this.setState({
      modalData: getData,
      campusAddress: getData[0].campusAddress,
      campusName: getData[0].campusName,
      campusPhoneNumber: getData[0].campusPhoneNumber,
      campusCity: getData[0].campusCity,
      id: getData[0].id,
    });
  };

  onHandleText = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onHandleSubmit = (e) => {
    e.preventDefault();
    const {
      campusAddress,
      id,
      campusCity,
      campusPhoneNumber,
      campusName,
    } = this.state;
    var bodyParameters = {
      id,
      campusName,
      campusCity,
      campusPhoneNumber,
      campusAddress,
      accountId: this.props.users.unique_name,
      accountType: this.props.users.role,
    };
    this.props.sharedActions
      .editRecord(
        "/api/CampusDetail/",
        id,
        bodyParameters,
        this.props.campusActions.updateCampus
      )
      .then((success) => {
        this.setState({ enabled: false, msg: "Edit Record Successfully!" });
        this.props.snackbar();
      })
      .catch((err) => {});
  };
  onEditClick = (itemId) => {
    this.onTakeItem(itemId);
    this.setState({ enabled: false });
  };
  PrintContent = (el) => {
    var restorepage = document.body.innerHTML;
    var Printcontent = document.getElementById(el).innerHTML;
    document.body.innerHTML = Printcontent;
    window.print();
    document.body.innerHTML = restorepage;
    window.location.reload();
  };
  changeSession = (sessionId) => {
    this.props.sharedActions
    .getDataWithoutDispatch(`/api/AccountReport/?sessionId=${sessionId}`)
    .then((success) => {
      debugger;
      this.setState({
          sessionId: sessionId,
        adminData: success,
        isLoading: false,
      });
    })
    .catch((err) => {
      this.setState({
        adminData: '',
        isLoading: false,
      });

      console.log(err);
    });
  }
  transferAmount = (month) => {
    this.props.sharedActions
    .simpleAddRequest(`/api/TransferAmount/?sessionId=${this.state.sessionId}&month=${month}` , null)
    .then((success) => {
      debugger;
      this.setState({
        msg: success,
      });
      this.props.snackbar();
    })
    .catch((err) => {
        this.setState({
            msg: err,
          });
          this.props.snackbar();

      console.log(err);
    });
  }
  render() {
    const { adminData, modalData } = this.state;

    console.log("admin Data: ", this.state.adminData);
    console.log("Session: ", this.state.session);
    const Loader = (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div class="spinner-border" style={{ color: "#01AC8A" }}></div>
      </div>
    );
    let pageNumbers = [];
    for (
      let i = 1;
      i <= Math.ceil(adminData.length / this.state.todosPerPage);
      i++
    ) {
      pageNumbers.push(i);
    }
    const renderPageNumbers = pageNumbers.map((number, index) => {
      return index <= 3 ? (
        <ThemeContext.Consumer>
          {(context) => (
            <li
              class={`page-item ${
                this.state.currentPage === number
                  ? context.themeColors.primaryColor
                  : ""
              } `}
            >
              <a
                key={number}
                id={number}
                onClick={this.handleClickNext}
                class="page-link "
                href="#"
                style={{
                  background: `${
                    this.state.currentPage === number
                      ? context.themeColors.primaryColor
                      : ""
                  }`,
                  color: `${
                    this.state.currentPage === number
                      ? "#fff"
                      : context.themeColors.primaryColor
                  }`,
                }}
              >
                {number}
              </a>
            </li>
          )}
        </ThemeContext.Consumer>
      ) : (
        ""
      );
    });
    const { currentPage, todosPerPage , session } = this.state;
    const indexOfLastTodo = currentPage * todosPerPage;
    const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
    // const currentTodos = adminData.slice(indexOfFirstTodo, indexOfLastTodo);
    const renderTodos =
      adminData !== "" ? (
        <React.Fragment>
          <tr>
            <td>
              <label className="font-weight-bold">August</label>
            </td>
            <td>{adminData["aR"]["AugReport"]["rv"]}</td>
            <td>{adminData["aR"]["AugReport"]["ca"]}</td>
            <td>
              <a href="#" data-toggle="tooltip" title="Edit!">
                <button
                  id="firstbutton"
                  onClick={() => this.transferAmount('August')}
                  onMouseOver={this.btnTechHover()}
                  style={{
                    cursor: "pointer",
                    fontSize: 20,
                    border: "none",
                    marginLeft: 5,
                    background: "none",
                    marginBottom: 5,
                  }}
                >
                  <i class="fas fa-pen"></i>
                </button>
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <label className="font-weight-bold">September</label>
            </td>
            <td>{adminData["aR"]["SepReport"]["rv"]}</td>
            <td>{adminData["aR"]["SepReport"]["ca"]}</td>
            <td>
              <a href="#" data-toggle="tooltip" title="Edit!">
                <button
                  id="firstbutton"
                  onClick={() => this.transferAmount('September')}
                  onMouseOver={this.btnTechHover()}
                  style={{
                    cursor: "pointer",
                    fontSize: 20,
                    border: "none",
                    marginLeft: 5,
                    background: "none",
                    marginBottom: 5,
                  }}
                >
                  <i class="fas fa-pen"></i>
                </button>
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <label className="font-weight-bold">October</label>
            </td>
            <td>{adminData["aR"]["OctReport"]["rv"]}</td>
            <td>{adminData["aR"]["OctReport"]["ca"]}</td>
            <td>
              <a href="#" data-toggle="tooltip" title="Edit!">
                <button
                  id="firstbutton"
                  onClick={() => this.transferAmount('October')}
                  onMouseOver={this.btnTechHover()}
                  style={{
                    cursor: "pointer",
                    fontSize: 20,
                    border: "none",
                    marginLeft: 5,
                    background: "none",
                    marginBottom: 5,
                  }}
                >
                  <i class="fas fa-pen"></i>
                </button>
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <label className="font-weight-bold">November</label>
            </td>
            <td>{adminData["aR"]["NovReport"]["rv"]}</td>
            <td>{adminData["aR"]["NovReport"]["ca"]}</td>
            <td>
              <a href="#" data-toggle="tooltip" title="Edit!">
                <button
                  id="firstbutton"
                  onClick={() => this.transferAmount('November')}
                  onMouseOver={this.btnTechHover()}
                  style={{
                    cursor: "pointer",
                    fontSize: 20,
                    border: "none",
                    marginLeft: 5,
                    background: "none",
                    marginBottom: 5,
                  }}
                >
                  <i class="fas fa-pen"></i>
                </button>
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <label className="font-weight-bold">December</label>
            </td>
            <td>{adminData["aR"]["DecReport"]["rv"]}</td>
            <td>{adminData["aR"]["DecReport"]["ca"]}</td>
            <td>
              <a href="#" data-toggle="tooltip" title="Edit!">
                <button
                  id="firstbutton"
                  onClick={() => this.transferAmount('December')}
                  onMouseOver={this.btnTechHover()}
                  style={{
                    cursor: "pointer",
                    fontSize: 20,
                    border: "none",
                    marginLeft: 5,
                    background: "none",
                    marginBottom: 5,
                  }}
                >
                  <i class="fas fa-pen"></i>
                </button>
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <label className="font-weight-bold">January</label>
            </td>
            <td>{adminData["aR"]["JanReport"]["rv"]}</td>
            <td>{adminData["aR"]["JanReport"]["ca"]}</td>
            <td>
              <a href="#" data-toggle="tooltip" title="Edit!">
                <button
                  id="firstbutton"
                  onClick={() => this.transferAmount('January')}
                  onMouseOver={this.btnTechHover()}
                  style={{
                    cursor: "pointer",
                    fontSize: 20,
                    border: "none",
                    marginLeft: 5,
                    background: "none",
                    marginBottom: 5,
                  }}
                >
                  <i class="fas fa-pen"></i>
                </button>
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <label className="font-weight-bold">February</label>
            </td>
            <td>{adminData["aR"]["FebReport"]["rv"]}</td>
            <td>{adminData["aR"]["FebReport"]["ca"]}</td>
            <td>
              <a href="#" data-toggle="tooltip" title="Edit!">
                <button
                  id="firstbutton"
                  onClick={() => this.transferAmount('February')}
                  onMouseOver={this.btnTechHover()}
                  style={{
                    cursor: "pointer",
                    fontSize: 20,
                    border: "none",
                    marginLeft: 5,
                    background: "none",
                    marginBottom: 5,
                  }}
                >
                  <i class="fas fa-pen"></i>
                </button>
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <label className="font-weight-bold">March</label>
            </td>
            <td>{adminData["aR"]["MarchReport"]["rv"]}</td>
            <td>{adminData["aR"]["MarchReport"]["ca"]}</td>
            <td>
              <a href="#" data-toggle="tooltip" title="Edit!">
                <button
                  id="firstbutton"
                  onClick={() => this.transferAmount('March')}
                  onMouseOver={this.btnTechHover()}
                  style={{
                    cursor: "pointer",
                    fontSize: 20,
                    border: "none",
                    marginLeft: 5,
                    background: "none",
                    marginBottom: 5,
                  }}
                >
                  <i class="fas fa-pen"></i>
                </button>
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <label className="font-weight-bold">April</label>
            </td>
            <td>{adminData["aR"]["AprilReport"]["rv"]}</td>
            <td>{adminData["aR"]["AprilReport"]["ca"]}</td>
            <td>
              <a href="#" data-toggle="tooltip" title="Edit!">
                <button
                  id="firstbutton"
                  onClick={() => this.transferAmount('April')}
                  onMouseOver={this.btnTechHover()}
                  style={{
                    cursor: "pointer",
                    fontSize: 20,
                    border: "none",
                    marginLeft: 5,
                    background: "none",
                    marginBottom: 5,
                  }}
                >
                  <i class="fas fa-pen"></i>
                </button>
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <label className="font-weight-bold">May</label>
            </td>
            <td>{adminData["aR"]["MayReport"]["rv"]}</td>
            <td>{adminData["aR"]["MayReport"]["ca"]}</td>
            <td>
              <a href="#" data-toggle="tooltip" title="Edit!">
                <button
                  id="firstbutton"
                  onClick={() => this.transferAmount('May')}
                  onMouseOver={this.btnTechHover()}
                  style={{
                    cursor: "pointer",
                    fontSize: 20,
                    border: "none",
                    marginLeft: 5,
                    background: "none",
                    marginBottom: 5,
                  }}
                >
                  <i class="fas fa-pen"></i>
                </button>
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <label className="font-weight-bold">June</label>
            </td>
            <td>{adminData["aR"]["JuneReport"]["rv"]}</td>
            <td>{adminData["aR"]["JuneReport"]["ca"]}</td>
            <td>
              <a href="#" data-toggle="tooltip" title="Edit!">
                <button
                  id="firstbutton"
                  onClick={() => this.transferAmount('June')}
                  onMouseOver={this.btnTechHover()}
                  style={{
                    cursor: "pointer",
                    fontSize: 20,
                    border: "none",
                    marginLeft: 5,
                    background: "none",
                    marginBottom: 5,
                  }}
                >
                  <i class="fas fa-pen"></i>
                </button>
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <label className="font-weight-bold">July</label>
            </td>
            <td>{adminData["aR"]["JulyReport"]["rv"]}</td>
            <td>{adminData["aR"]["JulyReport"]["ca"]}</td>
            <td>
              <a href="#" data-toggle="tooltip" title="Edit!">
                <button
                  id="firstbutton"
                  onClick={() => this.transferAmount('July')}
                  onMouseOver={this.btnTechHover()}
                  style={{
                    cursor: "pointer",
                    fontSize: 20,
                    border: "none",
                    marginLeft: 5,
                    background: "none",
                    marginBottom: 5,
                  }}
                >
                  <i class="fas fa-pen"></i>
                </button>
              </a>
            </td>
          </tr>
        </React.Fragment>
      ) : null;

    const MainContent = (
      <div>
        <div class="modal" id="myModal1">
          <div class="modal-dialog">
            <div class="modal-content modal_content_custom">
              <div
                style={{
                  borderBottomLeftRadius: 30,
                  borderBottomRightRadius: 30,
                  borderTopLeftRadius: 18,
                  borderTopRightRadius: 18,
                }}
                class="modal-header modal_custom_header"
              >
                <h4 style={{ color: "white" }} class="modal-title">
                  Confirm
                </h4>
                <button
                  style={{ color: "white" }}
                  type="button"
                  class="close"
                  data-dismiss="modal"
                >
                  &times;
                </button>
              </div>
              <div class="modal-body">
                {modalData &&
                  modalData.map((item, index) => (
                    <div key={index}>
                      <div style={{ textAlign: "center" }}>
                        <h4>Are you sure ?</h4>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <div>
                          <button
                            disabled={this.state.enabled}
                            data-dismiss="modal"
                            onClick={() => this.onTakeItemDelete(this.state.id)}
                            type="button"
                            class="btn btn-primary buttonAppear"
                          >
                            Yes
                          </button>
                        </div>
                        <div>
                          <button
                            data-dismiss="modal"
                            disabled={this.state.enabled}
                            type="button"
                            class="btn btn-primary buttonAppear"
                          >
                            No
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <div class="modal" id="myModal">
          <div class="modal-dialog">
            <div class="modal-content modal_content_custom">
              <div
                style={{
                  borderBottomLeftRadius: 30,
                  borderBottomRightRadius: 30,
                  borderTopLeftRadius: 18,
                  borderTopRightRadius: 18,
                }}
                class="modal-header modal_custom_header"
              >
                <h4 style={{ color: "white" }} class="modal-title">
                  Details
                </h4>
                <button
                  style={{ color: "white" }}
                  type="button"
                  class="close"
                  data-dismiss="modal"
                >
                  &times;
                </button>
              </div>
              <div class="modal-body">
                <form onSubmit={this.onHandleSubmit}>
                  {modalData &&
                    modalData.map((item, index) => (
                      <div key={index}>
                        <fieldset disabled={!this.state.enabled}>
                          <div class="form-group">
                            <label for="campusName">Campus Name:</label>
                            <input
                              style={{
                                borderTop: 0,
                                borderLeft: 0,
                                borderRight: 0,
                                boxShadow: "none",
                              }}
                              type="text"
                              value={this.state.campusName}
                              onChange={this.onHandleText}
                              name="campusName"
                              class="form-control"
                              id="campusName"
                            />
                          </div>
                          <div class="form-group">
                            <label for="Campus City">Campus City</label>
                            <input
                              required
                              style={{
                                borderTop: 0,
                                borderLeft: 0,
                                borderRight: 0,
                                boxShadow: "none",
                              }}
                              value={this.state.campusCity}
                              onChange={this.onHandleText}
                              type="text"
                              name="campusCity"
                              class="form-control"
                              id="campusCity"
                            />
                          </div>
                          <div class="form-group">
                            <label for="Campus Address">Campus Address</label>
                            <input
                              required
                              style={{
                                borderTop: 0,
                                borderLeft: 0,
                                borderRight: 0,
                                boxShadow: "none",
                              }}
                              value={this.state.campusAddress}
                              onChange={this.onHandleText}
                              type="text"
                              name="campusAddress"
                              class="form-control"
                              id="campusAddress"
                            />
                          </div>
                          <div class="form-group">
                            <label for="Campus Phone">Campus Address</label>
                            <input
                              required
                              style={{
                                borderTop: 0,
                                borderLeft: 0,
                                borderRight: 0,
                                boxShadow: "none",
                              }}
                              value={this.state.campusPhoneNumber}
                              onChange={this.onHandleText}
                              type="text"
                              name="campusPhoneNumber"
                              class="form-control"
                              id="campusPhoneNumber"
                            />
                          </div>
                        </fieldset>
                        <button
                          disabled={this.state.enabled}
                          onClick={() => this.setState({ enabled: true })}
                          type="button"
                          class="btn btn-primary buttonAppear"
                        >
                          Edit
                        </button>
                        <button
                          style={{ marginLeft: 5 }}
                          disabled={!this.state.enabled}
                          type="submit"
                          class="btn btn-primary buttonAppear"
                        >
                          Save
                        </button>
                      </div>
                    ))}
                </form>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  style={{ background: "#00435D" }}
                  class="btn btn-danger"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="">
          <img
            src={require("../../Assets/images/header.png")}
            style={{
              width: "100%",
              height: "150",
            }}
          />
        </div>
        <div className="table-responsive">
          <ThemeContext.Consumer>
            {(context) => (
              <table
                class="table table-hover table-bordered"
                // style={{ textAlign: "center" }}
              >
                <thead
                  style={{
                    background: context.themeColors.primaryColor,
                    color: "white",
                  }}
                >
                  <tr>
                    <th scope="col">Month</th>
                    <th scope="col">Advance Revenue Account</th>
                    <th scope="col">Current Account</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>

                <tbody>{renderTodos}</tbody>
              </table>
            )}
          </ThemeContext.Consumer>
        </div>
        <div className="row">
          <div className="col-md-3">
            <div className="card p-3 shadow-sm">
              <label>One Time</label> <br />{" "}
              <label className="font-weight-bold">{adminData["oneTime"]}</label>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card p-3 shadow-sm">
              <label>Advance Revenue</label> <br />{" "}
              <label className="font-weight-bold">
                {adminData["getAdvanceRevenue"]}
              </label>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card p-3 shadow-sm">
              <label>Current Account</label> <br />{" "}
              <label className="font-weight-bold">
                {adminData["getCurrentAccount"]}
              </label>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card p-3 shadow-sm">
              <label>Total Current Amount</label> <br />{" "}
              <label className="font-weight-bold">
                {adminData["totalCurrentAmount"]}
              </label>
            </div>
          </div>
        </div>
      </div>
    );
    return (
      <ThemeContext.Consumer>
        {(context) => (
          <div>
            <div id="div1">
              <div className="page-header">
                <SnackBar msg={this.state.msg} />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <h2
                      class="h1-responsive font-weight-bold text-center my-4 generalHead"
                      style={{ background: context.themeColors.primaryColor }}
                    >
                      Fee Accounts
                    </h2>
                  </div>
                  <nav aria-label="Page navigation example">
                    <ul
                      style={{ background: context.themeColors.primaryColor }}
                      class="pagination"
                    >
                      <li
                        class={`page-item ${
                          this.state.disableBPage ? "disabled" : ""
                        }`}
                      >
                        <a
                          onClick={this.handleClickNextBack}
                          class="page-link"
                          href="#"
                        >
                          {this.state.disableBPage ? (
                            <i
                              style={{ color: "grey" }}
                              class="far fa-stop-circle"
                            ></i>
                          ) : (
                            <i class="fas fa-backward"></i>
                          )}
                        </a>
                      </li>
                      {renderPageNumbers}
                      <li
                        class={`page-item ${
                          this.state.disablepage ? "disabled" : ""
                        }`}
                      >
                        <a
                          onClick={this.handleClickNextFrwd}
                          class="page-link"
                          href="#"
                        >
                          {this.state.disablepage ? (
                            <i
                              style={{ color: "grey" }}
                              class="far fa-stop-circle"
                            ></i>
                          ) : (
                            <i class="fas fa-forward"></i>
                          )}
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
              <div className="row justify-content-between">
                <div className="col-md-3"></div>
                <div class="col-md-2">
                  <div class="md-form mb-0">
                    <label for="email" class="">
                      Sessions
                    </label>
                    <select
                      onChange={(e) =>
                        this.changeSession(e.target.value)
                      }
                      class="custom-select no-print"
                      name="CategoryName"
                    >
                      <option selected={this.state.msg ? true : false}>
                        Select Session
                      </option>
                      {session &&
                        session.session.map((value, index) => {
                          return (
                            <option key={index} value={value.sessionId}>
                              {value.currentSession}
                            </option>
                          );
                        })}
                      ))
                    </select>
                  </div>
                </div>
              </div>
              {this.state.isLoading ? Loader : MainContent}
              <div class="page-footer">
                <div class="text-left">Printed By: {this.props.users.role}</div>
                <div
                  class="text-right"
                  style={{ marginTop: -25, marginRight: 20 }}
                >
                  Powered by School Smart®
                </div>
              </div>
            </div>
            <div
              class="text-center text-md-right displayElemet"
              style={{ marginBottom: 10 }}
            >
              <label
                for="name"
                class=""
                onClick={() => this.PrintContent("div1")}
              >
                <i
                  className="fas fa-print fa-2x animated fadeIn"
                  style={{ marginRight: 4 }}
                />
                Print
              </label>
            </div>
          </div>
        )}
      </ThemeContext.Consumer>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(getAllAdminActions, dispatch),
    campusActions: bindActionCreators(CampusActions, dispatch),
    sharedActions: bindActionCreators(sharedActions, dispatch),
  };
}
function mapStateToProps(state) {
  return {
    AllAdminData: state.setCampusReducer.Data,
    allAdminState: state.setCampusReducer,
    users: state.setUserReducer.users,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(FeeAccounts);
