import React, { Component, useContext } from "react";
import themeContext from "../../context/themeContext/ThemeContext";
// import { connect } from 'react-redux';
// import { bindActionCreators } from 'redux';
// import * as flashAction from '../../Actions/flashmessageAction/flashmessage';
// import * as checkTokenStatus from '../../Actions/dashBoardActions.js/dashboardActions';
// import loading from '../../images/loading.gif';
//loading.gif';
const LayoutWrapper = (props) => {
  const themeColors = useContext(themeContext);

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <div
          style={{ background: themeColors.themeColors.primaryColor }}
          className="stepNotifier animated fadeInRight"
        >
          {props.step}
        </div>
      </div>
      <section class="mb-4">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <h2
              style={{ background: themeColors.themeColors.primaryColor }}
              class="h1-responsive font-weight-bold text-left my-4 generalHead "
            >
              {props.formType}
            </h2>
          </div>
        </div>

        <div>{props.children}</div>
      </section>
    </div>
  );
};

// function mapStateToProps(state) {
//     return{
//         auth:state.setuser.isAuth,
//     }
// }

// function mapDispatchToProps(dispatch) {
//     return{
//         action_1:bindActionCreators(checkTokenStatus,dispatch),
//         action:bindActionCreators(flashAction,dispatch)
//     }
// }
export default LayoutWrapper;
