import React, { Component, useState, useEffect } from 'react';
import $ from 'jquery';
import lifecycle from 'react-pure-lifecycle';
// import React, {} from 'react';
const methods = {
	componentDidMount(props) {
		// const {} = props;
		$('#chooseFile').bind('change', function() {
			var filename = $('#chooseFile').val();
			if (/^\s*$/.test(filename)) {
				$('.file-upload').removeClass('active');
				$('#noFile').text('No file chosen...');
			} else {
				$('.file-upload').addClass('active');
				$('#noFile').text(filename.replace('C:\\fakepath\\', ''));
			}
		});
	}
};
const GeneralFileUploadOption = props => {
	const {
		stateData,
		feildName,
		fileOnChange,
		name,
		dispatchFlag,
		changeWidth
	} = props;

	return (
		<React.Fragment>
			{stateData[feildName] === 'Yes' ? (
				<div
					style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap' }}
				>
					<div class="custom-file" style={{ width: '100%', borderRadius: 20 }}>
						<div class="file-upload" style={{ width: '100%' }}>
							<div
								class="file-select"
								style={{ width: changeWidth ? '100%' : '200%' }}
							>
								<div class="file-select-button" id="fileName">
									Choose File
								</div>
								<div class="file-select-name" id="noFile">
									{stateData[name].name}
								</div>
								<input
									type="file"
									name={name}
									onChange={
										dispatchFlag ? e => fileOnChange(e) : e => fileOnChange(e)
									}
								/>
							</div>
						</div>

						{/* </div> */}
					</div>
				</div>
			) : stateData[feildName] === 'No' ? (
				<p></p>
			) : (
				<p></p>
			)}
		</React.Fragment>
	);
};
export default lifecycle(methods)(GeneralFileUploadOption);
