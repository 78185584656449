
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import $ from 'jquery';
import isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom'
import moment from 'moment'

import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { isNull } from 'lodash';

import ThemeContext from "../../context/themeContext/ThemeContext";


const localizer = momentLocalizer(moment);


class AddAcademicCalendar extends Component {
    constructor(props){
        super(props);
        this.state = {
            isLoading: true,
            modalEdit: false,
            editToggle: false,
            generateToggle: false,
            generateIndex: '',
            msg: '',
            editIndex: '',
            classId: "",
            sectionId: "",
            subjectId: "",
            pageNumber: [],
            dataLimit: 40,
            calenderPayload: [],

            

            sessionId: this.props.location.state.sessionId,
            fromMonth: this.props.location.state.fromMonth,
            fromYear: this.props.location.state.fromYear,
            toMonth: this.props.location.state.toMonth,
            toYear: this.props.location.state.toYear,
            calenderData: this.props.location.state.calenderData,

            positionDate: moment(`${this.props.location.state.fromYear}-${this.props.location.state.fromMonth}-01"`, "YYYY-MMMM-DD").toDate(),
            eventColor: "FFFFFF",

            dayList: [ 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],

            events: [],

			accountId: this.props.userData.unique_name,
			accountType: this.props.userData.role

        }

    }

    static contextType = ThemeContext

    componentDidMount(){
        this.getSimple('Session', 'session')
        this.getSimple('AnnualCalendar', "d")

    }


    getSimple = (type, state) => {
        this.props.sharedActions.getDataWithoutDispatch(
            `/api/${type}`
        ).then(success => { 
            this.setState({
                [state]: success,
                isLoading: false
            })


        }).catch(error => {
            this.setState({
                msg: 'Error Occured!!',
                errorMsg: true,
                isLoading: false
            })
            console.error('Error occured', error);
        })
    }

    

    PrintContent = el => {
        var restorepage = document.body.innerHTML;
        var Printcontent = document.getElementById(el).innerHTML;
        document.body.innerHTML = Printcontent;
        
        setTimeout(() => {
            window.print();
            window.location.reload();
        }, 700)
        document.body.innerHTML = restorepage;
        window.reload();
        
    };



    myFunction = () => {
		// Get the snackbar DIV
		var x = document.getElementById('snackbar');

		// StepperGeneralButtons the "show" class to DIV
		x.className = 'show';

		// After 3 seconds, remove the show class from DIV
		setTimeout(function() {
		}, 3000);
	};

    eventStyleGetter = (event, start, end, isSelected) => {
		var backgroundColor = '#' + event.hexColor;
		var style = {
            backgroundColor: backgroundColor,
            color: 'black',
			borderRadius: '0px',
			opacity: 0.8,
			border: '0px',
            display: 'block',
            textAlign: 'center',
            fontWeight: 'bold'
            
		};
		return {
			style: style
		};
	};
    btnTechHover = () => {
		$(document).ready(function() {
			$('button').hover(
				function() {
					$(this).css('box-shadow', '0px 5px 5px grey');
					$(this).css('border-color', 'white');
				},
				function() {
					$(this).css('box-shadow', '0px 0px 0px white');
				}
			);
		});
	};

    onHandleText = e => {
		this.setState({
			[e.target.name]: e.target.value
        });
        console.log([e.target.name], e.target.value)
    };

    handleNavigate= e => {
        const { fromYear, fromMonth, toYear, toMonth } = this.state;
        let fromParsedStamp = moment(`${fromYear}-${fromMonth}-01`, "YYYY-MMMM-DD").unix() 
        let toParsedStamp = moment(`${toYear}-${toMonth}-01`, "YYYY-MMMM-DD").unix() 
        var currentParsedStamp = moment(e).unix()


        if(currentParsedStamp >= fromParsedStamp && currentParsedStamp <= toParsedStamp){
            console.log(currentParsedStamp >= fromParsedStamp , currentParsedStamp <= toParsedStamp)
            this.setState({
                positionDate: e
            })
        }

    }

    onEventAdd = (e) => {
        console.log(e);
            let fill = e.slots.filter(data => {
               return moment(data).format("ddd") == "Fri" || moment(data).format("ddd") == "Sat"
            })
            if(fill.length > 0){
                this.setState({
                    msg: "Event are not Allowed on Friday and Saturday"
                })

                this.props.snackbar();

        }else{
            console.log(moment(e.end).add(1, 'days')._d)
            this.setState({
                rawStartDate: e.start,
                rawEndDate: moment(e.end).add(1, 'days')._d,
                eventStartDate: moment(e.start).format("MM-DD-YYYY"),
                eventEndDate: moment(e.end).format("MM-DD-YYYY"),
                oneDayEvent: e.start == e.end
            }, () => {
                $('#myModal1').modal({
                    show: true
                })
            })

        }
        
    }


    handleSubmit = e => {
        e.preventDefault();
        const { rawStartDate, rawEndDate, eventTitle, eventColor, events } = this.state;

        if(isNull(eventTitle) || isNull(eventColor)){
            this.setState({
                msg: 'Please fill the form respectivily!',
            })
            this.props.snackbar();
        }else{
            let tempEvents = events
            this.setState({
                events: [...tempEvents, {
                    title: eventTitle,
                    start: rawStartDate,
                    end: rawEndDate,
                    hexColor: eventColor,
                    resource: moment().unix()
                }],
                msg: 'Event Added Succefully!',

            }, () => {
                this.setState({
                    rawStartDate: "",
                    rawEndDate: "",
                    eventColor: "FFFFFF",
                    eventTitle: "",
                    eventDate: "",
                }, () => {
                    $('#myModal1').modal('hide')
                    
                    this.props.snackbar();
                    console.log(this.state.events);
                })
            
             })

            
            
        }
    }


    onEditClick = e => {
        const { events } = this.state
        let modalData = events.filter((items, index ) =>  {
            if(e.resource === items.resource){
                    
            this.setState({
                editIndex: index
            })
            console.log(index)
            return e.resource === items.resource 
            }
        })
        console.log(modalData)
        this.setState({
            rawStartDate: modalData[0].start,
            rawEndDate: modalData[0].end,
            eventStartDate: moment(modalData[0].start).format("MM-DD-YYYY"),
            eventEndDate: moment(modalData[0].end).subtract(1, "days").format("MM-DD-YYYY"),
            resource: modalData[0].resource,
            eventTitle: modalData[0].title,
            eventColor: modalData[0].hexColor,
            oneDayEvent: moment(modalData[0].start).format("MM-DD-YYYY") == moment(modalData[0].end).subtract(1, "days").format("MM-DD-YYYY")


        }, () => {
            console.log(this.state.oneDayEvent, moment(modalData[0].start).format("MM-DD-YYYY") , moment(modalData[0].end).subtract(1, "days").format("MM-DD-YYYY"));
            
            $('#myModal2').modal({
                show: true
            })
        })
    }

    handleEdit = e => {
        e.preventDefault()
        const { events, rawStartDate, rawEndDate, eventStartDate, eventEndDate, resource, eventColor, eventTitle } = this.state;
        let data = {
            title: eventTitle,
            hexColor: eventColor,
            resource,
            start: rawStartDate,
            end: rawEndDate
        }
        this.state.events.splice(this.state.editIndex, 1, data)
        this.setState({
            rawStartDate: "",
            rawEndDate: "",
            eventColor: "FFFFFF",
            eventTitle: "",
            eventStartDate: "",
            eventEndDate: "",
            oneDayEvent: "",
            msg: "Event Changed!"
        }, () => {
            $('#myModal2').modal('hide')
            
            this.props.snackbar();
        })

    }

    handleDelete = e => {
        const { events } = this.state
        events.filter((items, index ) =>  {
            if(e.resource === items.resource){
            
                this.state.events.splice(index, 1)

                return e.resource === items.resource 
            }
        })
            console.log(events);
            
            this.setState({
                msg: "Event Removed Successfully!"
            })
            this.props.snackbar();

    }

    onClickEvent = e => {
        this.setState({
            selectedEvent: e
        }, () => {
            $('#myModal4').modal({
                show: true
            })
            console.log(this.state.events);

        })
    }

    handleCalenderSubmit = () => {
        const { events, accountId, accountType, sessionId } = this.state;
        let payload = {
            AnnualCalendars: {
                    sessionId,
                    accountId,
                    accountType
        },
        AnnualCalendarContents: []
    }

    this.state.events.map((data, index) => {
        let oneDayEvent = moment(data.start).format("MM-DD-YYYY") == moment(data.end).subtract(1, "days").format("MM-DD-YYYY")
        if(oneDayEvent){
            payload.AnnualCalendarContents.push({
                title: data.title,
                hexColor: data.hexColor,
                resource: data.resource,
                day: moment(data.start).format("dddd"),
                month: moment(data.start).format("MM"),
                year: moment(data.start).format("YYYY"),
                date: moment(data.start).format("MM/DD/YYYY"),
            })
            
        }else{
            let startOfEvent = moment(data.start).format("MM-DD-YYYY")
            let endOfEvent = moment(data.end).subtract(1, "days").format("MM-DD-YYYY")
            let numOfDay =  Math.abs(moment(startOfEvent, "MM-DD-YYYY").diff(moment(endOfEvent, "MM-DD-YYYY"), 'days') - 1)

            console.log("event length", numOfDay, startOfEvent, endOfEvent);

           Array.from(new Array(numOfDay), (val, index) => index).map((d, i) => {

            payload.AnnualCalendarContents.push({
                title: data.title,
                hexColor: data.hexColor,
                resource: data.resource,
                day: moment(data.start).add(d, 'days').format("dddd"),
                month: moment(data.start).add(d, 'days').format("MM"),
                year: moment(data.start).add(d, 'days').format("YYYY"),
                date: moment(data.start).add(d, 'days').format("MM/DD/YYYY"),
            })

        })
            
        }


    })

    this.props.sharedActions.addRecordWithoutDispatch(
        `/api/AnnualCalendar`,
        payload
    ).then(success => {
        console.log(success);
        
        this.setState({
            msg: 'Academic Calendar Added!',
        }, () => {
            this.props.snackbar()
            this.props.history.push({pathname: '/dashboard/AcademicCalendarMain' })
        })
    }).catch(error => {

        this.setState({
                msg: error.response ? error.response.data.Message : 'Error Occured!!',
                isLoading: false
            })        
            this.props.snackbar()
        console.error('Error occured', error);
        
    })


    console.log(payload);
    
    
    }

    render(){

        const { search, isLoading, editToggle, msg, accountId, accountType, classSectionGrade, session, studentSkill, date, academicCalender, dayList,
            classId, sectionId, subjectId, searchDate, 
            currentPage, pageNumber, disableBPage, disablepage,

            sessionId, fromMonth, fromYear, toMonth, toYear, //previous data
            positionDate, //set data
            eventDate, eventStartDate, eventEndDate, eventTitle, eventColor, oneDayEvent //event data
        } = this.state;

        const selectEventModal = (
            <div>
            <div className="modal fade" id="myModal4">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content modal_content_custom">
                        <div
                            style={{
                                background: this.context.themeColors.primaryColor,
                                borderBottomLeftRadius: 30,
                                borderBottomRightRadius: 30,
                                borderTopLeftRadius: 18,
                                borderTopRightRadius: 18
                            }}
                            className="modal-header modal_custom_header"
                        >
                            <h4 style={{ color: 'white' }} className="modal-title">
                                Actions
                            </h4>
                            <button
                                style={{ color: 'white' }}
                                on
                                type="button"
                                className="close"
                                data-dismiss="modal"
                            >
                                &times;
                            </button>
                        </div>
                        <div className="modal-body">
                        <div >
											<div
												style={{
													display: 'flex',
													justifyContent: 'space-evenly'
												}}
											>
												<div>
													<button
														data-dismiss="modal"
														onClick={() => this.onEditClick(this.state.selectedEvent)}
														type="button"
														class="btn btn-primary buttonAppear"
													>
														Edit
													</button>
												</div>
												<div>
													<button
														data-dismiss="modal"
                                                        type="button"
                                                        onClick={() => {
                                                            $('#myModal3').modal({
                                                                show: true
                                                            })
                                                        }}
                                                        class="btn btn-primary buttonAppear"
                                                        style={{backgroundColor: 'red'}}
													>
														Remove
													</button>
												</div>
											</div>
										</div>
                        </div>
                        <div class="modal-footer">
								<button
									type="button"
									style={{ background: '#00435D' }}
                                    class="btn btn-danger"
                                    onClick={() => this.setState({editToggle: false})}
									data-dismiss="modal"
								>
									Close
								</button>
							</div>
                    </div>
                </div>
            </div>
            </div>
        )

        const deleteEventModal = (
            <div>
            <div className="modal" id="myModal3">
                <div className="modal-dialog  modal-dialog-centered">
                    <div className="modal-content modal_content_custom">
                        <div
                            style={{
                                background: this.context.themeColors.primaryColor,
                                borderBottomLeftRadius: 30,
                                borderBottomRightRadius: 30,
                                borderTopLeftRadius: 18,
                                borderTopRightRadius: 18
                            }}
                            className="modal-header modal_custom_header"
                        >
                            <h4 style={{ color: 'white' }} className="modal-title">
                                Confirm
                            </h4>
                            <button
                                style={{ color: 'white' }}
                                on
                                type="button"
                                className="close"
                                data-dismiss="modal"
                            >
                                &times;
                            </button>
                        </div>
                        <div className="modal-body">
                        <div >
											<div style={{ textAlign: 'center' }}>
												<h4>Are you sure?</h4>
											</div>
											<div
												style={{
													display: 'flex',
													justifyContent: 'space-evenly'
												}}
											>
												<div>
													<button
														data-dismiss="modal"
														onClick={() => this.handleDelete(this.state.selectedEvent)}
														type="button"
														class="btn btn-primary buttonAppear"
													>
														Yes
													</button>
												</div>
												<div>
													<button
														data-dismiss="modal"
														type="button"
														class="btn btn-primary buttonAppear"
													>
														No
													</button>
												</div>
											</div>
										</div>
                        </div>
                        <div class="modal-footer">
								<button
									type="button"
									style={{ background: '#00435D' }}
                                    class="btn btn-danger"
                                    onClick={() => this.setState({editToggle: false})}
									data-dismiss="modal"
								>
									Close
								</button>
							</div>
                    </div>
                </div>
            </div>
            </div>
        )

        
    const EditEventModal = (
            <div>
            <div className="modal" id="myModal2">
                <div className="modal-dialog">
                    <div className="modal-content modal_content_custom">
                        <div
                            style={{
                                background: this.context.themeColors.primaryColor,
                                borderBottomLeftRadius: 30,
                                borderBottomRightRadius: 30,
                                borderTopLeftRadius: 18,
                                borderTopRightRadius: 18
                            }}
                            className="modal-header modal_custom_header"
                        >
                            <h4 style={{ color: 'white' }} className="modal-title">
                                Edit
                            </h4>
                            <button
                                style={{ color: 'white' }}
                                on
                                type="button"
                                className="close"
                                data-dismiss="modal"
                            >
                                &times;
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={this.handleEdit} >
                            <fieldset disabled={!editToggle}>
                            <div className="form-group">
                                <label for="accountId">Account Name:</label>
                                <input style={{
                                    borderTop: 0,
                                    borderRight: 0,
                                    borderLeft: 0
                                }}
                                type="text"
                                disabled
                                value={accountId}
                                name="modalDataAccountId"
                                className="form-control"
                                id="accountId"
                                />
                                </div>
                                <div className="form-group">
                                <label for="accountType">Account Type:</label>
                                <input style={{
                                    borderTop: 0,
                                    borderRight: 0,
                                    borderLeft: 0
                                }}
                                type="text"
                                disabled
                                value={accountType}
                                name="modalDataAccountType"
                                className="form-control"
                                id="accountType"
                                />
                                </div>
                                {oneDayEvent ? <div className="form-group">
                                <label for="eventDate">Event Date</label>
                                <input style={{
                                    borderTop: 0,
                                    borderRight: 0,
                                    borderLeft: 0
                                }}
                                disabled={true}
                                type="text"
                                value={eventStartDate}
                                name="eventDate"
                                className="form-control"
                                id="eventDate1"
                                />
                                </div> : <React.Fragment>
                                <div className="form-group">
                                <label for="eventDate">Event Start Date</label>
                                <input style={{
                                    borderTop: 0,
                                    borderRight: 0,
                                    borderLeft: 0
                                }}
                                disabled={true}
                                type="text"
                                value={eventStartDate}
                                name="eventDate"
                                className="form-control"
                                id="eventDate2"
                                />
                                </div><div className="form-group">
                                <label for="eventDate">Event End Date</label>
                                <input style={{
                                    borderTop: 0,
                                    borderRight: 0,
                                    borderLeft: 0
                                }}
                                disabled={true}
                                type="text"
                                value={eventEndDate}
                                name="eventDate"
                                className="form-control"
                                id="eventDate3"
                                />
                                </div>
                                </React.Fragment> }
                                <div className="form-group">
                                <label for="staffName">Event Title</label>
                                <input 
                                onChange={this.onHandleText}
                                type="text"
                                value={eventTitle}
                                name="eventTitle"
                                className="form-control"
                                id="eventTitle"
                                />
                                </div>
                                <div className="form-group">
                                <label for="eventColor">Event Color</label>
                                <select class="custom-select" 
                                value={eventColor}
                                
                                name="eventColor"
                                onChange={this.onHandleText}
                                >
                                        <option value="FFFFFF">Please Select Event Type</option>
                                        <option value="FFBB00" style={{background: '#FFBB00'}}>Holidays</option>
                                        <option value="FF4040" style={{background: '#FF4040'}}>Exams</option>
                                        <option value="0FFF00" style={{background: '#0FFF00'}}>School Events</option>
                                        <option value="00FFF0" style={{background: '#00FFF0'}}>Parent Meetings</option>
                                                
                                </select>
                                </div>

                            </fieldset>
                            <br />
							<br />
								<button
									disabled={editToggle}
									onClick={() => this.setState({ editToggle: true })}
									type="button"
									class="btn btn-primary buttonAppear"
									>
								    	Edit
									</button>
									<button
										style={{ marginLeft: 5 }}
										disabled={!editToggle}
										type="submit"
										class="btn btn-primary buttonAppear"
										>
										Save
									</button>
                            </form>
                        </div>
                        <div class="modal-footer">
								<button
									type="button"
									style={{ background: '#00435D' }}
                                    class="btn btn-danger"
                                    onClick={() => this.setState({editToggle: false})}
									data-dismiss="modal"
								>
									Close
								</button>
							</div>
                    </div>
                </div>
            </div>
            </div>                 
        )


        const addEventModal = (
            <div>
            <div className="modal" id="myModal1">
                <div className="modal-dialog">
                    <div className="modal-content modal_content_custom">
                        <div
                            style={{
                                background: this.context.themeColors.primaryColor,
                                borderBottomLeftRadius: 30,
                                borderBottomRightRadius: 30,
                                borderTopLeftRadius: 18,
                                borderTopRightRadius: 18
                            }}
                            className="modal-header modal_custom_header"
                        >
                            <h4 style={{ color: 'white' }} className="modal-title">
                                Add Event
                            </h4>
                            <button
                                style={{ color: 'white' }}
                                on
                                type="button"
                                className="close"
                                data-dismiss="modal"
                            >
                                &times;
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={this.handleSubmit} >
                            <fieldset>
                                <div className="form-group">
                                <label for="accountId">Account Name:</label>
                                <input style={{
                                    borderTop: 0,
                                    borderRight: 0,
                                    borderLeft: 0
                                }}
                                type="text"
                                disabled
                                value={accountId}
                                name="modalDataAccountId"
                                className="form-control"
                                id="accountId"
                                />
                                </div>
                                <div className="form-group">
                                <label for="accountType">Account Type:</label>
                                <input style={{
                                    borderTop: 0,
                                    borderRight: 0,
                                    borderLeft: 0
                                }}
                                type="text"
                                disabled
                                value={accountType}
                                name="modalDataAccountType"
                                className="form-control"
                                id="accountType"
                                />
                                </div>
                                {oneDayEvent ? <div className="form-group">
                                <label for="eventDate">Event Date</label>
                                <input style={{
                                    borderTop: 0,
                                    borderRight: 0,
                                    borderLeft: 0
                                }}
                                disabled={true}
                                type="text"
                                value={eventStartDate}
                                name="eventDate"
                                className="form-control"
                                id="eventDate4"
                                />
                                </div> : <React.Fragment>
                                <div className="form-group">
                                <label for="eventDate">Event Start Date</label>
                                <input style={{
                                    borderTop: 0,
                                    borderRight: 0,
                                    borderLeft: 0
                                }}
                                disabled={true}
                                type="text"
                                value={eventStartDate}
                                name="eventDate"
                                className="form-control"
                                id="eventDate5"
                                />
                                </div><div className="form-group">
                                <label for="eventDate">Event End Date</label>
                                <input style={{
                                    borderTop: 0,
                                    borderRight: 0,
                                    borderLeft: 0
                                }}
                                disabled={true}
                                type="text"
                                value={eventEndDate}
                                name="eventDate"
                                className="form-control"
                                id="eventDate6"
                                />
                                </div>
                                </React.Fragment> }
                                <div className="form-group">
                                <label for="staffName">Event Title</label>
                                <input 
                                onChange={this.onHandleText}
                                type="text"
                                value={eventTitle}
                                name="eventTitle"
                                className="form-control"
                                id="eventTitle"
                                />
                                </div>
                                <div className="form-group">
                                <label for="eventColor">Event Color</label>
                                <select class="custom-select" 
                                value={eventColor}
                                
                                name="eventColor"
                                onChange={this.onHandleText}
                                >
                                        <option value="FFFFFF">Please Select Event Type</option>
                                        <option value="FFBB00" style={{background: '#FFBB00'}}>Holidays</option>
                                        <option value="FF4040" style={{background: '#FF4040'}}>Exams</option>
                                        <option value="0FFF00" style={{background: '#0FFF00'}}>School Events</option>
                                        <option value="00FFF0" style={{background: '#00FFF0'}}>Parent Meetings</option>
                                                
                                </select>
                                </div>

                            </fieldset>
                            <br />
							<br />
									<button
										style={{ marginLeft: 5 }}
										type="submit"
										class="btn btn-primary buttonAppear"
										>
										Add
									</button>
                            </form>
                        </div>
                        <div class="modal-footer">
								<button
									type="button"
									style={{ background: '#00435D' }}
                                    class="btn btn-danger"
                                    onClick={() => this.setState({editToggle: false})}
									data-dismiss="modal"
								>
									Close
								</button>
							</div>
                    </div>
                </div>
            </div>
            </div>                 
        )

        const Loader = (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div className="spinner-border" style={{ color: this.context.themeColors.primaryColor }}></div>
            </div>
        );

        return (
            <div>
				<section  className="mb-4">
				<h2  
                        style={{ background: this.context.themeColors.primaryColor }}
                        className="h1-responsive font-weight-bold text-center  generalHead">
                        Add Academic Calendar
                    </h2>
			    <div>
                <SnackBar msg={msg} />
                <div style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                }}>
                    <div className="my-2" >
                            <div class="col-md-12 text-right">
                            <button
										type="submit"
                                        class="btn btn-primary buttonAppear"
                                        onClick={this.handleCalenderSubmit}
                                        disabled={this.state.events.length == 0}
										>
										Submit Calendar
									</button>
							</div>
                        </div>
                        
                        <div class="row my-2 mx-1" style={{paddingLeft: '5px', border: `2px solid ${this.context.themeColors.primaryColor}`}} >
                            <div class="col-md-12 text-center" >
                                    <span>Session: <strong style={{color: this.context.themeColors.primaryColor}} >{session && session.session.filter(data => data.sessionId == sessionId)[0].currentSession}</strong></span>     
                            </div>
                            <div class="col-md-6"  >
                                    <span>From Month: <strong style={{color: this.context.themeColors.primaryColor}} >{fromMonth}</strong></span>     
                            </div>
                            <div class="col-md-6 text-right" >
                                    <span>From Year: <strong style={{color: this.context.themeColors.primaryColor}} >{fromYear}</strong></span>     
                            </div>
                            <div class="col-md-6" >
                                    <span>To From: <strong style={{color: this.context.themeColors.primaryColor}} >{toMonth}</strong></span>     
                            </div>
                            
                            <div class="col-md-6 text-right" >
                                    <span>To Year: <strong style={{color: this.context.themeColors.primaryColor}} >{toYear}</strong></span>     
                            </div>
                        </div>
                    <div class="row">
                        <div class="col-md-12">

                            <Calendar
                                localizer={localizer}
                                selectable
                                popup
                                events={this.state.events}
                                startAccessor="start"
                                endAccessor="end"
                                style={{ height: 700 }}
                                onSelectSlot={this.onEventAdd}
                                eventPropGetter={this.eventStyleGetter}
                                onSelectEvent={this.onClickEvent}
                                date={positionDate}
                                onNavigate={this.handleNavigate}
                                />
                        </div>

                    </div>
                    {addEventModal}
                    {selectEventModal}
                    {EditEventModal}
                    {deleteEventModal}
                </div>
            </div>
            </section>
            </div>
        );
    }

}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch),
	};
}
function mapStateToProps(state) {
	return {
		userData: state.setUserReducer.users,
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AddAcademicCalendar);
