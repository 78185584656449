import * as actionTypes from '../actionTypes';

export function setAllExamInRedux(allData) {
	return {
		type: actionTypes.GET_ALL_EXAM,
		payload: allData
	};
}
export function addExam(Data) {
	return {
		type: actionTypes.ADD_EXAM,
		payload: Data
	};
}
export function getSessionsForExam(allData) {
	return {
		type: actionTypes.GET_SESSION_FOR_EXAM,
		payload: allData
	};
}
export function getExamsForExamDetails(allData) {
	return {
		type: actionTypes.GET_EXAM_FOR_EXAMDETAILS,
		payload: allData
	};
}
export function getClassGradeSubjjectForExam(allData) {
	return {
		type: actionTypes.GET_CLASS_GRADE_SUBJECT_FOR_EXAM,
		payload: allData
	};
}
export function updateExamFlag(setFlag) {
	return {
		type: actionTypes.SET_LOADER_FLAG_EXAM,
		payload: setFlag
	};
}
export function updateExam(data) {
	return {
		type: actionTypes.EDIT_EXAM_RECORD,
		payload: data
	};
}
export function deleteExam(id) {
	return {
		type: actionTypes.DELETE_EXAM,
		payload: id
	};
}
