import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import $ from 'jquery';
import isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom'
import isNull from 'lodash/isNull'
import moment from 'moment'

import ThemeContext from "../../context/themeContext/ThemeContext";

class ViewDailyClassAttendance extends Component {
    constructor(props){
        super(props);
        this.state = {
            isLoading: true,
            modalEdit: false,
            editToggle: false,
            generateToggle: false,
            generateIndex: '',
            msg: '',
            editIndex: '',

            date: moment().format('MM/DD/YYYY'),
            searchDate: moment().format('YYYY-MM-DD'),
			accountId: this.props.userData.unique_name,
			accountType: this.props.userData.role

        }

        


    }

    componentDidMount(){
        
        this.getSimple('classSectionGradeTeacher', 'classSectionGrade')
        this.getSimple('Session', 'session')
    }

    getSimple = (type, state) => {
        this.props.sharedActions.getDataWithoutDispatch(
            `/api/${type}`
        ).then(success => { 
            this.setState({
                [state]: success,
                isLoading: false
            })


        }).catch(error => {
            this.setState({
                msg: 'Error Occured!!',
                errorMsg: true,
                isLoading: false
            })
            console.error('Error occured', error);
        })
    }


    


    PrintContent = el => {
        var restorepage = document.body.innerHTML;
        var Printcontent = document.getElementById(el).innerHTML;
        document.body.innerHTML = Printcontent;
        
        setTimeout(() => {
            window.print();
            window.location.reload();
        }, 700)
        document.body.innerHTML = restorepage;
        window.reload();
        
    };



    myFunction = () => {
		// Get the snackbar DIV
		var x = document.getElementById('snackbar');

		// StepperGeneralButtons the "show" class to DIV
		x.className = 'show';

		// After 3 seconds, remove the show class from DIV
		setTimeout(function() {
		}, 3000);
	};


        handleSearch = () => {
            const { classId, sectionId, sessionId, searchDate } = this.state
            console.log(classId, sectionId, sessionId, searchDate );
            
            
            if( isEmpty(classId) || isEmpty(sessionId) || isEmpty(sectionId) ){
                console.log('Empty');
                
                this.setState({
                    msg: "Please Fill The Form Repectively!",
                })            
                this.props.snackbar()
            }else{
                let parsedDate = moment(searchDate).format('MM/DD/YYYY')
                this.getSimple(`AllStudentAttendance/Date/?classId=${classId}&sectionId=${sectionId}&sessionId=${sessionId}&date=${parsedDate}`, 'classAttendance')
                
            }
        }

    btnTechHover = () => {
		$(document).ready(function() {
			$('button').hover(
				function() {
					$(this).css('box-shadow', '0px 5px 5px grey');
					$(this).css('border-color', 'white');
				},
				function() {
					$(this).css('box-shadow', '0px 0px 0px white');
				}
			);
		});
	};

    onHandleText = e => {
		this.setState({
			[e.target.name]: e.target.value
        });
        console.log([e.target.name], e.target.value)
    };
    


    render(){

        const { search, isLoading, editToggle, msg, accountId, accountType, classSectionGrade, session, date, classAttendance,
            classId, sectionId, sessionId, searchDate
        } = this.state;



        const Loader = (
            <ThemeContext.Consumer>
            {(context) => (
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<div className="spinner-border" style={{ color: context.themeColors.primaryColor }}></div>
			</div>
                                                                                                                                                )}
                                                                                                                                                </ThemeContext.Consumer>
        );
        
        const renderClassAttendanceData = classAttendance && classAttendance.Student.map((item, index) => {
            return (
                <ThemeContext.Consumer>
                {(context) => (
            <tr key={index} >
                <th scope="row" style={{ padding: 1 }} >
                    {index + 1}
                </th>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.StudentName}
                </td>
                {item.attendance.map((data, index2) => {
                    return <td className="borderSpace" style={{ padding: 1 } && data.attendanceStatus == 'Present' ? data.attendanceStatus == 'Present' && data.lateTime == 'yes' ? { color: 'orange'} :  { color: 'green'} : { color: 'red'}}  >
                                {data.attendanceStatus.substring(0,1) == 'A' && 
                                `${data.attendanceStatus.substring(0,1)} - ${data.leaveType.substring(0,1) == 'E' ? 'E' : 'NE'}`}
                                {data.attendanceStatus.substring(0,1) == 'P' && 
                                `${data.attendanceStatus.substring(0,1)} ${data.lateTime == 'yes' ? ' - Late' : ''}`}
                            </td>
                    
                })}
            </tr>
                                                                                                                                                )}
                                                                                                                                                </ThemeContext.Consumer>
            );
        }) 

        const renderSubjectList = classAttendance && classAttendance.subjectInfo.map((data, index) => {
            return <th scope="col" style={{ padding: 1 }} key={data.subjectId} >
                {data.subjectName}
            </th>
        })

        return (
            <ThemeContext.Consumer>
            {(context) => (
            <div className="page-header"> 
                <SnackBar msg={msg} />
                <div style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                }}>
                    <div class="row">
                            <div class="col-xs-6 col-sm-6 col-md-6">
                                <h2  
                        style={{ background: context.themeColors.primaryColor }}
                        className="h1-responsive font-weight-bold text-center  generalHead">
                        Daily Class Report
                    </h2>
							</div>
                            <div class="col-xs-6 col-sm-6 col-md-6 text-right">
								<h5>{date}</h5>
							</div>
                        </div>
                        <div class="row my-3 mx-5 py-4" style={{
                            border: `2px solid ${context.themeColors.primaryColor}`,
                            borderRadius: 20
                        }} >
                           
                            <div class="col-md-4" >
                            <select class="custom-select" 
                                                value={classId}
                                                name="classId"
                                                onChange={this.onHandleText}
                                                disabled={!classSectionGrade}
                                            >
                                                        <option value="">Please Select Class</option>
                                                       {classSectionGrade && classSectionGrade.classes.map((value, index) => {
                                                           
                                                           return <option value={value.classId}>{value.className}</option>
                                                       })}
                                            </select>
                            </div>
                            <div class="col-md-4" >
                            <select class="custom-select" 
                                                value={sectionId}
                                                name="sectionId"
                                                onChange={this.onHandleText}
                                                disabled={!classId}

                                            >
                                                        <option value="">Please Select Section</option>
                                                       {classSectionGrade && classSectionGrade.sections.map((value, index) => {


                                                                return value.classId == classId && 
                                                                <option value={value.sectionId}>{value.section}</option>

                                                       })}
                                            </select>
                            </div>
                            <div class="col-md-4" >
                            <select class="custom-select" 
                                                value={sessionId}
                                                name="sessionId"
                                                onChange={this.onHandleText}
                                                disabled={!sectionId}

                                            >
                                                        <option value="">Please Select Session</option>
                                                       {session && session.session.map((value, index) => {

                                                                return <option value={value.sessionId}>{value.currentSession}</option>

                                                       })}
                                            </select>
                            </div>
                            
    
                        </div>
                        <div class="row my-4">
                        <div className="col-md-3 text-right">
                        </div>
                        <div className="col-md-3 text-right">
                                        <div className="md-form mb-0 " >
                                            <input class="custom-select" 
                                                type='date'
                                                value={searchDate}
                                                name="searchDate"
                                                onChange={this.onHandleText}
                                            />
                                                    
                                        </div>
                                        </div>

                            <div class="col-md-4  text-left" >
                                            <button
                                            class="btn btn-primary buttonAppear"
                                            onClick={this.handleSearch}
                                            style={{marginRight: '2%', background: context.themeColors.primaryColor}}
                                            >
                            <i class="fa fa-search"></i> Search
                                        </button>
                                        <Link to={{
                            pathname: "PrintDailyClassAttendanceReport",
                            state: {
                                classAttendance,
                                date: moment(searchDate).format('DD/MM/YYYY')
                            }
                        }}  >
                            <a href="#" data-toggle="tooltip" title="Edit!">
                                
                            <button
                                            class="btn btn-primary buttonAppear"
                                            disabled={!classAttendance}
                                            style={{background: context.themeColors.primaryColor}}
                                            >
                            
                                    <i className="fas fa-list"></i> View
                                </button>
                            </a> 
                            
                        </Link>
                            </div>
                            
                        </div>
                        <div class="row my-2 mx-2" style={{paddingLeft: '20px'}} >
                            <div class="col-md-6" >
                                    <span>Class: <strong style={{color: context.themeColors.primaryColor}} >{classAttendance && classAttendance.info.className}</strong></span>     
                            </div>
                            <div class="col-md-6"  >
                                    <span>Section: <strong style={{color: context.themeColors.primaryColor}} >{classAttendance && classAttendance.info.section}</strong></span>     
                            </div>
                            <div class="col-md-6" >
                                    <span>Date: <strong style={{color: context.themeColors.primaryColor}} >{classAttendance && classAttendance.info.date}</strong></span>     
                            </div>
                            <div class="col-md-6" >
                                    <span>Session: <strong style={{color: context.themeColors.primaryColor}} >{classAttendance && classAttendance.info.sessionName}</strong></span>     
                            </div>
                        </div>
                    <div className="table-responsive">
					<table className="table table-hover table-bordered text-center">
						<thead style={{ background: context.themeColors.primaryColor, color: 'white' }}>
							<tr>
								<th scope="col" style={{ padding: 1 }}>
									#
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Student Name
								</th>
                                {renderSubjectList}
							</tr>
						</thead>
						<tbody>{isLoading ? Loader : renderClassAttendanceData}</tbody>
					</table>
				</div>
                </div>
            </div>
                                                                                                                                                )}
                                                                                                                                                </ThemeContext.Consumer>
        );
    }

}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch),
	};
}
function mapStateToProps(state) {
	return {
		userData: state.setUserReducer.users,
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ViewDailyClassAttendance);
