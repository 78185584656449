import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as SkillAction from '../../Actions/StudentSkill';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import MultipleSkills from './MultipleSkill';
import StarRatings from 'react-star-ratings';
import '../Admin/viewAdmin.css';

import $ from 'jquery';
// import TableComponent from "./TableComponent";
class PrintAllStudentSkills extends Component {
	constructor(props) {
		super(props);
		this.state = {
			SkillReports: [],
			adminData: [],
			modalData: null,
			vehicleNo: '',
			capacity: '',
			transportType: '',
			enabled: false,
			id: null,
			select: [],
			efforts: '',
			participation: '',
			average: '',
			academicProgress: '',
			studentName: '',
			isLoading: true,
			disablepage: false,
			disableBPage: true,
			currentPage: 1,
			todosPerPage: 10,
			pageCounter: 10,
			msg: '',
			errorMsg: '',
			increament: 1,
			selectClassData: '',
			classId: '',
			className: '',
			sectionName: '',
			sectionId: '',
			sections: [],
			subjects: [],
			session: [],
			sessionId: '',
			sessionName: '',
			subjectId: '',
			subjectName: '',
			staffId: '',
			staffName: '',
			term: '',
			btnLoad: false,
			exist: false,
			checking: [],
			searchCheck: true,
			multiple: []
		};
	}
	PrintContent = el => {
		var restorepage = document.body.innerHTML;
		var Printcontent = document.getElementById(el).innerHTML;
		document.body.innerHTML = Printcontent;
		window.print();
		document.body.innerHTML = restorepage;
	};
	handleClickNextFrwd = () => {
		const { adminData, todosPerPage, count, currentPage } = this.state;
		let pageNumbers = [];
		for (
			let i = 1;
			i <= Math.ceil(adminData.length / this.state.todosPerPage);
			i++
		) {
			pageNumbers.push(i);
		}
		console.log('pagelength: ', pageNumbers.length);
		console.log('current page: ', currentPage);
		if (currentPage === pageNumbers.length) {
			this.setState({
				disablepage: true
			});
		} else {
			this.setState(prevState => ({
				currentPage: prevState.currentPage + 1,
				disableBPage: false
			}));
		}
	};
	handleClickNextBack = () => {
		const { adminData, todosPerPage, count, currentPage } = this.state;
		console.log('current page: ', currentPage);
		if (currentPage === 1) {
			this.setState({
				disableBPage: true,
				disablepage: false
			});
		} else {
			this.setState(prevState => ({
				currentPage: prevState.currentPage - 1
			}));
			this.setState({
				disablepage: false
			});
		}
	};
	handleClickNext = event => {
		this.setState({
			currentPage: Number(event.target.id),
			disablepage: false,
			disableBPage: false
		});
	};
	static getDerivedStateFromProps(nextProps, prevState) {
		if (
			prevState.adminData !== nextProps.AllTransportData ||
			prevState.selectClassData !== nextProps.selectData ||
			prevState.session !== nextProps.SessionSelect
		) {
			return {
				adminData: nextProps.AllTransportData,
				selectClassData: nextProps.selectData,
				session: nextProps.SessionSelect
			};
		}
		return null;
	}
	onSelectChange = e => {
		console.log(e.target.value);
		let result = JSON.parse(e.target.value);
		const sections = this.state.selectClassData.staffClassSection;
		let check = sections.filter(item => item.classId == result.classId);
		console.log(check);

		this.setState({
			sections: check,
			staffId: check[0].staffId,
			staffName: check[0].staffName,
			classId: result.classId,
			className: result.className
		});
	};
	onSectionChange = e => {
		console.log(e.target.value);
		let result = JSON.parse(e.target.value);
		const sections = this.state.selectClassData.staffClassSection;
		let check2 = sections.filter(
			item =>
				item.classId == this.state.classId && item.sectionId == result.sectionId
		);
		this.setState({
			subjects: check2,
			sectionId: result.sectionId,
			sectionName: result.section
		});
		console.log(this.state.staffId);
	};
	onSessionChange = e => {
		console.log(e.target.value);
		let result = JSON.parse(e.target.value);
		this.setState({
			sessionId: result.sessionId,
			sessionName: result.currentSession
		});
		console.log(this.state);
	};
	onSubjectChange = e => {
		console.log(e.target.value);
		let result = JSON.parse(e.target.value);
		this.setState({
			subjectId: result.subjectId,
			subjectName: result.subjectName
		});
	};
	componentDidUpdate() {
		$('[data-toggle="tooltip"]').tooltip();

		if (this.state.msg || this.state.errorMsg || this.state.exist) {
			setTimeout(() => {
				this.setState({
					msg: false,
					errorMsg: false,
					exist: false
				});
			}, 3000);
		}
	}
	componentDidMount() {
		$(document).ready(function() {
			$('[data-toggle="tooltip"]').tooltip();
		});
		this.props.sharedActions
			.gAllData(
				`/api/DescriptiveReport?userName=${this.props.users.unique_name}`,
				this.props.SkillAction.getClassSectionSubjectForStudentSkill
			)
			.then(success => {
				console.log(success);
			})
			.catch(error => {
				console.log(error);
			});
		this.props.sharedActions
			.gAllData('/api/Session', this.props.SkillAction.getSessionsForSkills)
			.then(success => {})
			.catch(error => {});
		// this.props.sharedActions.getAllData(`/api/Assignments/getassign/Student/?Classid=0&secid=0`, this.props.AssignmentActions.getParticularAssignment, this.props.AssignmentActions.updateAssignmentFlag)
		//     .then((success) => {
		//
		//         this.setState({
		//             isLoading: false,
		//         })
		//     }).catch((err) => {
		//         this.props.AssignmentActions.updateAssignmentFlag(false);

		//         this.setState({
		//             isLoading: false
		//         });
		//
		//         console.log(err);
		//     })
	}
	onSearchStudent = e => {
		this.setState({
			btnLoad: true
		});
		this.props.sharedActions
			.getAllData(
				`/api/studentSkillView/?classId=${this.state.classId}&sectionId=${this.state.sectionId}&term=${this.state.term}&sessionId=${this.state.sessionId}&subjectId=${this.state.subjectId}`,
				this.props.SkillAction.setAllSkillsViewInRedux,
				this.props.SkillAction.updateSkillFlagView
			)
			.then(success => {
				this.setState({
					isLoading: false,
					btnLoad: false,
					searchCheck: false
				});
			})
			.catch(err => {
				this.props.SkillAction.updateSkillFlagView(false);

				this.setState({
					isLoading: false,
					btnLoad: false,
					searchCheck: true
				});

				console.log(err);
			});
	};
	btnTechHover = () => {
		$(document).ready(function() {
			$('button').hover(
				function() {
					$(this).css('box-shadow', '0px 5px 5px grey');
					$(this).css('border-color', 'white');
				},
				function() {
					$(this).css('box-shadow', '0px 0px 0px white');
				}
			);
		});
	};
	onTakeItemDelete = itemId => {
		this.setState({
			modalData: []
		});
		this.props.sharedActions
			.deleteRecord(
				'/api/Vehicle/',
				itemId,
				this.props.routesActions.deleteVehicle
			)
			.then(success => {
				this.setState({
					msg: 'Delete Record Successfully!'
				});
				this.props.snackbar();
			})
			.catch(error => {});
	};
	onTakeItem = itemId => {
		this.setState({
			modalData: []
		});
		const { adminData } = this.state;
		let getData = adminData.filter(item => item.skillReportId === itemId);

		this.setState({
			modalData: getData,
			efforts: getData[0].efforts,
			average: getData[0].average,
			academicProgress: getData[0].academicProgress,
			id: getData[0].skillReportId,
			studentName: getData[0].stduentName,
			participation: getData[0].participation
		});
	};

	onHandleTextNumber = e => {
		console.log(e.target.value);
		if (e.target.value <= 5) {
			this.setState({
				[e.target.name]: e.target.value
			});
		}
		console.log(this.state.effort);
	};
	onHandleText = e => {
		this.setState({
			[e.target.name]: e.target.value
		});
	};

	onHandleSubmit = e => {
		e.preventDefault();
		const { efforts, participation, academicProgress, id } = this.state;
		let data = { id, efforts, participation, academicProgress };

		this.props.sharedActions
			.editPatchRecord(
				'/api/editSkillReport/?id=',
				id,
				data,
				this.props.SkillAction.updateSkill
			)
			.then(success => {
				this.setState({ enabled: false, msg: 'Edit Record Successfully!' });
				// this.props.campusActions.updateCampusFlag(true);
			})
			.catch(err => {
				console.log(err);
			});
		console.log(this.state);
	};
	onEditClick = itemId => {
		this.onTakeItem(itemId);
		this.setState({ enabled: false });
	};
	myFunction = () => {
		// Get the snackbar DIV
		var x = document.getElementById('snackbar');

		// StepperGeneralButtons the "show" class to DIV
		x.className = 'show';

		// After 3 seconds, remove the show class from DIV
		setTimeout(function() {
			x.className = x.className.replace('show', '');
		}, 3000);
	};

	onClickDeleteItem = arrName => {
		let getStateArr = this.state[`${arrName}`];
		let getDeleteLength = getStateArr.length - 1;
		if (getDeleteLength > 0) {
			let getNewDetails = getStateArr.filter(
				(item, index) => index !== getDeleteLength
			);
			console.log(getNewDetails);
			this.setState({
				[arrName]: [...getNewDetails]
			});
		}
	};

	onClickAddItem = (arrName, obj) => {
		let getStateArr = this.state[`${arrName}`];
		getStateArr = [...getStateArr, obj];
		console.log(arrName);
		this.setState({
			[arrName]: getStateArr
		});
	};
	updateSpecialArrObjOnChange = (e, index, arrName) => {
		if (e.target.value <= 5) {
			const changeArrOfObj = this.state[`${arrName}`];
			changeArrOfObj[index][e.target.name] = e.target.value;
			this.setState({
				[arrName]: changeArrOfObj
			});
		}
	};
	onChangeText = e => {
		console.log(e.target.value);
		this.setState({
			[e.target.name]: e.target.value
		});
	};
	render() {
		const { adminData, modalData, select } = this.state;
		console.log('Daata', this.state.SkillReports);
		console.log('Admin Data', this.state.adminData);
		console.log('Classes Ka Data', this.state.selectClassData);
		console.log('Session Ka Data', this.state.session);
		const { session } = this.state.session;
		const { classes } = this.state.selectClassData;
		// const { studentEnuiry } = this.state.adminData
		// const { paginationMetadata } = this.state.adminData
		// console.log("Pagination Ka Data", paginationMetadata);
		// console.log("Pagination Checking Ka Data", this.state.checking);

		console.log('modal data: ', this.state.modalData);
		const Loader = (
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<label style={{ color: 'red' }}>No Data</label>
			</div>
		);
		let pageNumbers = [];
		for (
			let i = 1;
			i <=
			Math.ceil(this.state.checking.totalCount / this.state.checking.pageSize);
			i++
		) {
			pageNumbers.push(i);
		}
		const renderPageNumbers = pageNumbers.map((number, index) => {
			return (
				<li
					class={`page-item ${
						this.state.checking.currentPage === number ? 'active' : ''
					} `}
				>
					<a
						key={number}
						id={number}
						onClick={() => this.paginationRequest(number)}
						class="page-link "
						href="#"
					>
						{number}
					</a>
				</li>
			);
		});
		// const { currentPage, todosPerPage } = this.state;
		// const indexOfLastTodo = currentPage * todosPerPage;
		// const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
		// const currentTodos = adminData.slice(indexOfFirstTodo, indexOfLastTodo);
		const renderTodos = adminData.map((item, index) => {
			return (
				<tr key={index}>
					<th scope="row" className="bordersSpace" style={{ padding: 2 }}>
						{index + 1}
					</th>
					<td className="bordersSpace" style={{ padding: 2 }}>
						{item.stduentName}
					</td>
					<td className="bordersSpace" style={{ padding: 2 }}>
						<StarRatings
							rating={item.efforts}
							starRatedColor="#01425e"
							numberOfStars={5}
							name="rating"
							starDimension="20px"
							starSpacing="5px"
						/>
					</td>
					<td className="bordersSpace" style={{ padding: 2 }}>
						<StarRatings
							rating={item.participation}
							starRatedColor="#01425e"
							numberOfStars={5}
							name="rating"
							starDimension="20px"
							starSpacing="5px"
						/>
					</td>
					<td className="bordersSpace" style={{ padding: 2 }}>
						<StarRatings
							rating={item.academicProgress}
							starRatedColor="#01425e"
							numberOfStars={5}
							name="rating"
							starDimension="20px"
							starSpacing="5px"
						/>
					</td>
					<td className="bordersSpace" style={{ padding: 2 }}>
						<StarRatings
							rating={item.average}
							starRatedColor="#01425e"
							numberOfStars={5}
							name="rating"
							starDimension="20px"
							starSpacing="5px"
						/>
					</td>
				</tr>
			);
		});
		const MainContent = (
			<div>
				<div id="div1">
					<div class="row justify-content-between">
						<div class="col-md-4">
							<div class="md-form mb-0">
								<label for="name" class="">
									Term: &nbsp;
								</label>
								<label
									for="name"
									class=""
									style={{ fontWeight: 'bold', fontSize: 20, color: '#01425e' }}
								>
									{this.state.term}
								</label>
							</div>
						</div>
						<div class="col-sm-3">
							<div class="md-form mb-0">
								<label for="name" class="">
									Class: &nbsp;
								</label>
								<label
									for="name"
									class=""
									style={{ fontWeight: 'bold', fontSize: 20, color: '#01425e' }}
								>
									{this.state.className}
								</label>
							</div>
						</div>
					</div>
					<div class="row justify-content-between">
						<div class="col-md-4">
							<div class="md-form mb-0">
								<label for="name" class="">
									Session: &nbsp;
								</label>
								<label
									for="name"
									class=""
									style={{ fontWeight: 'bold', fontSize: 20, color: '#01425e' }}
								>
									{this.state.sessionName}
								</label>
							</div>
						</div>
						<div class="col-sm-3">
							<div class="md-form mb-0">
								<label for="name" class="">
									Section: &nbsp;
								</label>
								<label
									for="name"
									class=""
									style={{ fontWeight: 'bold', fontSize: 20, color: '#01425e' }}
								>
									{this.state.sectionName}
								</label>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-12">
							<div class="md-form mb-0">
								<label for="name" class="">
									Staff: &nbsp;
								</label>
								<label
									for="name"
									class=""
									style={{ fontWeight: 'bold', fontSize: 20, color: '#01425e' }}
								>
									{this.state.staffName}
								</label>
							</div>
						</div>
					</div>
					<div className="table-responsive">
						<table class="table table-hover text-center" id="tbl1">
							<thead style={{ background: '#01AC8A', color: 'white' }}>
								<tr class="vendorListHeading">
									<th scope="col" style={{ padding: 2 }}>
										#
									</th>
									<th scope="col" style={{ padding: 2 }}>
										Student Name
									</th>
									<th scope="col" style={{ padding: 2 }}>
										Efforts
									</th>
									<th scope="col" style={{ padding: 2 }}>
										Participation
									</th>
									<th scope="col" style={{ padding: 2 }}>
										Academic Progress
									</th>
									<th scope="col" style={{ padding: 2 }}>
										Average
									</th>
								</tr>
							</thead>
							<tbody>{renderTodos}</tbody>
						</table>
					</div>
				</div>
				<div class="text-center text-md-right">
					<label for="name" class="" onClick={() => this.PrintContent('div1')}>
						<i
							className="fas fa-print fa-2x animated fadeIn"
							style={{ marginRight: 4 }}
						/>
						Print It Out
					</label>
				</div>
			</div>
		);
		return (
			<div>
				<div className="page-header">
					<SnackBar msg={this.state.msg} />
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between'
						}}
					>
						<div>
							<h2>Print All Student Skills</h2>
						</div>
						<nav aria-label="Page navigation example">
							<ul style={{ color: '#01AC8A' }} class="pagination">
								{/* {renderPageNumbers} */}
							</ul>
						</nav>
					</div>
				</div>
				<div class="row" style={{ justifyContent: 'center' }}>
					<div class="col-md-12">
						<div class="md-form mb-0">
							<label for="name" class="">
								Term
							</label>
							<select
								style={{ borderColor: '#01AC8A' }}
								onChange={this.onHandleText}
								class="custom-select"
								name="term"
							>
								<option>Choose Type..</option>
								<option value="1st">1st Term</option>
								<option value="2nd">2nd Term</option>
								<option value="3rd">3rd Term</option>
							</select>
						</div>
					</div>
					<div class="col-md-3" style={{ marginTop: 20 }}>
						<div class="md-form mb-0">
							<label for="email" class="">
								<i
									style={{ marginRight: 4 }}
									className="fas fa-map animated fadeIn"
								/>
								Class Name
							</label>
							<select
								style={{ borderColor: '#01AC8A' }}
								onChange={this.onSelectChange}
								class="custom-select"
								name="className"
							>
								<option selected={this.state.msg ? true : false}>
									Choose Class...
								</option>
								{classes &&
									classes.map((item, index) => (
										<option key={index} value={`${JSON.stringify(item)}`}>
											{item.className}
										</option>
									))}
							</select>
						</div>
					</div>

					<div class="col-md-3" style={{ marginTop: 20 }}>
						<div class="md-form mb-0">
							<label for="email" class="">
								<i
									style={{ marginRight: 4 }}
									className="fas fa-map animated fadeIn"
								/>
								Section
							</label>
							<select
								style={{ borderColor: '#01AC8A' }}
								onChange={this.onSectionChange}
								class="custom-select"
								name="className"
							>
								<option selected={this.state.msg ? true : false}>
									Choose Section...
								</option>
								{this.state.sections.map((item, index) => (
									<option key={index} value={`${JSON.stringify(item)}`}>
										{item.section}
									</option>
								))}
							</select>
						</div>
					</div>
					<div class="col-md-3" style={{ marginTop: 20 }}>
						<div class="md-form mb-0">
							<label for="email" class="">
								<i
									style={{ marginRight: 4 }}
									className="fas fa-map animated fadeIn"
								/>
								Section
							</label>
							<select
								style={{ borderColor: '#01AC8A' }}
								onChange={this.onSubjectChange}
								class="custom-select"
								name="className"
							>
								<option selected={this.state.msg ? true : false}>
									Choose Section...
								</option>
								{this.state.subjects.map((item, index) => (
									<option key={index} value={`${JSON.stringify(item)}`}>
										{item.subjectName}
									</option>
								))}
							</select>
						</div>
					</div>
					<div class="col-md-3" style={{ marginTop: 20 }}>
						<div class="md-form mb-0">
							<label for="email" class="">
								<i
									style={{ marginRight: 4 }}
									className="fas fa-map animated fadeIn"
								/>
								Session
							</label>
							<select
								style={{ borderColor: '#01AC8A' }}
								onChange={this.onSessionChange}
								class="custom-select"
								name="sessionId"
							>
								<option selected={this.state.msg ? true : false}>
									Choose Session...
								</option>
								{session &&
									session.map((item, index) => (
										<option key={index} value={`${JSON.stringify(item)}`}>
											{item.currentSession}
										</option>
									))}
							</select>
						</div>
					</div>
				</div>
				<div class="text-center text-md-right">
					<button type="submit" class="btnss" onClick={this.onSearchStudent}>
						Search
						<span
							style={{ marginBottom: 5 }}
							class={`${
								this.state.btnLoad ? 'spinner-border spinner-border-sm' : ''
							}`}
						></span>
					</button>
				</div>
				<br />
				{this.state.searchCheck ? Loader : MainContent}
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		SkillAction: bindActionCreators(SkillAction, dispatch),
		sharedActions: bindActionCreators(sharedActions, dispatch)
	};
}
function mapStateToProps(state) {
	return {
		AllTransportData: state.setStudentSkillReducer.DataP,
		selectData: state.setStudentSkillReducer.selectStudent,
		SessionSelect: state.setStudentSkillReducer.selectSession,
		allTransportState: state.setStudentSkillReducer,
		users: state.setUserReducer.users
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PrintAllStudentSkills);
