import * as actionTypes from '../Actions/actionTypes';

const defaultState = {
	apiFlag: true,
	Data: [],
	select: [],
	selectSession: []
};

export default function setPlansReducer(state = defaultState, action = {}) {
	switch (action.type) {
		case actionTypes.SET_ALL_CLASS_NAME_INTO_PLAN_SELECT:
			return {
				...state,
				select: action.payload
			};
		case actionTypes.SET_ALL_SESSION_NAME_INTO_PLAN_SELECT:
			return {
				...state,
				selectSession: action.payload
			};

		case actionTypes.GET_ALL_PLANS:
			return { ...state, Data: action.payload };
		case actionTypes.SET_LOADER_FLAG_PLANS:
			return { ...state, apiFlag: action.payload };
		case actionTypes.DELETE_PLANS:
			let filterCandidateReqList = state.Data.filter(
				filterOBJ => filterOBJ.lessonPlan.planId !== action.payload
			);
			return {
				...state,
				Data: filterCandidateReqList
			};
		case actionTypes.EDIT_PLANS_RECORD:
			var adminData = state.Data;
			// var index = adminData.findIndex(
			// 	obj => obj.planId === action.payload.planId
			// );
			// adminData[index].departmentName = action.payload.departmentName;
			// adminData[index].campusName = action.payload.campusName;
			// adminData[index].campusId = action.payload.campusId;

			return { ...state, Data: adminData };
		default:
			return state;
	}
}
