import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as sharedActions from "../../../Actions/sharedActions";
import SnackBar from "../../../components/SnackBar/SnackBar";
import { Link } from "react-router-dom";
import months from "../../../utils/general";
import CustomFilterSectionLayout from "../../../components/CustomFilterSectionLayout/CustomFilterSectionLayout";
import $ from "jquery";
import ThemeContext from "../../../context/themeContext/ThemeContext";

class ViewBilling extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorText: "",
      AllBillingData: [],
      monthlyStudentList: [],
      newMonthlyStudentList: [],
      adminData: [],
      filterCheck: false,
      status: "",
      msgSnack: "",
      backColor: "",
      year: "",
      month: "",
      modalData: null,
      enabled: false,
      id: null,
      select: [],
      isLoading: true,
      disablepage: false,
      disableBPage: true,
      currentPage: 1,
      todosPerPage: 10,
      pageCounter: 10,
      msg: "",
      errorMsg: "",
      increament: 1,
      sectionId: "",
      sections: [],
      session: [],
      sessionId: "",
      btnLoad: false,
      exist: false,
      checking: [],
      searchCheck: true,
      badRequest: false,
      errorText: "",
    };
  }
  handleClickNextFrwd = () => {
    const { adminData, todosPerPage, count, currentPage } = this.state;
    let pageNumbers = [];
    for (
      let i = 1;
      i <= Math.ceil(adminData.length / this.state.todosPerPage);
      i++
    ) {
      pageNumbers.push(i);
    }
    console.log("pagelength: ", pageNumbers.length);
    console.log("current page: ", currentPage);
    if (currentPage === pageNumbers.length) {
      this.setState({
        disablepage: true,
      });
    } else {
      this.setState((prevState) => ({
        currentPage: prevState.currentPage + 1,
        disableBPage: false,
      }));
    }
  };
  handleClickNextBack = () => {
    const { adminData, todosPerPage, count, currentPage } = this.state;
    console.log("current page: ", currentPage);
    if (currentPage === 1) {
      this.setState({
        disableBPage: true,
        disablepage: false,
      });
    } else {
      this.setState((prevState) => ({
        currentPage: prevState.currentPage - 1,
      }));
      this.setState({
        disablepage: false,
      });
    }
  };
  handleClickNext = (event) => {
    this.setState({
      currentPage: Number(event.target.id),
      disablepage: false,
      disableBPage: false,
    });
  };

  componentDidUpdate() {
    $(".tooltip").tooltip("hide");

    if (
      this.state.msg ||
      this.state.errorMsg ||
      this.state.exist ||
      this.state.badRequest
    ) {
      setTimeout(() => {
        this.setState({
          msg: false,
          errorMsg: false,
          exist: false,
          badRequest: false,
        });
      }, 3000);
    }
  }

  componentDidMount() {
    $(document).ready(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });

    this.props.sharedActions
      .getDataWithoutDispatch(
        "/api/BillingVoucher?PageNumber=1&pageSize=10&month=&sessionId=&status="
      )
      .then((success) => {
        debugger;
        this.setState({
          AllBillingData: success.bill,
          checking: success.paginationMetadata,
          searchCheck: false,
        });
      })
      .catch((err) => {
        this.setState({});

        console.log(err);
      });

    this.props.sharedActions
      .getDataWithoutDispatch("/api/Session")
      .then((success) => {
        this.setState({
          session: success,
        });
      })
      .catch((err) => {
        this.setState({});

        console.log(err);
      });
  }

  btnTechHover = () => {
    $(document).ready(function () {
      $("button").hover(
        function () {
          $(this).css("box-shadow", "0px 5px 5px grey");
          $(this).css("border-color", "white");
        },
        function () {
          $(this).css("box-shadow", "0px 0px 0px white");
        }
      );
    });
  };
  onTakeItemDelete = (itemId) => {
    this.setState({
      modalData: [],
    });
    this.props.sharedActions
      .deleteRecordWithoutDispatchWithoutSlash(
        "/api/BillingVoucher/?id=",
        itemId
      )
      .then((success) => {
        debugger;
        let deleteData = this.state.AllBillingData.filter(
          (item) => item.id != itemId
        );
        this.setState({
          AllBillingData: deleteData,
          msg: "Record Deleted Successfully!",
        });
        this.props.snackbar();
      })
      .catch((error) => {
        debugger;
      });
  };
  onTakeItem = (itemId) => {
    this.setState({
      modalData: [],
    });
    const { AllBillingData } = this.state;
    let getData = AllBillingData.filter((item) => item.id === itemId);

    this.setState({
      modalData: getData,
      status: getData[0].status,
      id: getData[0].id,
    });
  };

  onHandleText = (e) => {
    console.log(e.target.value);
    this.setState({
      [e.target.name]: e.target.value,
    });
    debugger;
  };

  onStatusChange = (e) => {
    console.log(e.target.value);
    this.setState(
      {
        status: e.target.value,
      },
      this.hitFilterApi
    );
  };
  onHandleSubmit = (e) => {
    e.preventDefault();
    const { status, id } = this.state;
    var bodyParameters = {
      status,
    };
    this.props.sharedActions
      .patchParticularRecord(`/api/ApproveVoucher/?id=${id}`, bodyParameters)
      .then((success) => {
        debugger;
        this.setState({ enabled: false, msg: "Edit Record Successfully!" });
        this.props.snackbar();
      })
      .catch((err) => {
        this.setState({
          errorMsg: true,
          errorText: err.response.data.Message,
        });
        debugger;
      });
  };
  onEditClick = (itemId) => {
    this.onTakeItem(itemId);
    this.setState({ enabled: false });
  };

  hitFilterApi = (e) => {
    this.setState({
      searchCheck: true,
    });
    this.props.sharedActions
      .getDataWithoutDispatch(
        `/api/BillingVoucher?PageNumber=1&pageSize=10&month=${this.state.month}&sessionId=${this.state.sessionId}&status=${this.state.status}`
      )
      .then((success) => {
        debugger;
        this.setState({
          AllBillingData: success.bill,
          checking: success.paginationMetadata,
          searchCheck: false,
        });
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
        console.log(err);
      });
  };

  sessionChange = (e) => {
    this.setState(
      {
        sessionId: e.target.value,
      },
      this.hitFilterApi
    );
  };

  onMonthChange = (e) => {
    this.setState(
      {
        month: e.target.value,
      },
      this.hitFilterApi
    );
  };

  paginationRequest = (number) => {
    this.setState({
      searchCheck: true,
    });
    console.log(number);

    this.props.sharedActions
      .getDataWithoutDispatch(
        `/api/BillingVoucher?PageNumber=${number}&pageSize=10&month=${this.state.month}&sessionId=${this.state.sessionId}&status=${this.state.status}`
      )
      .then((success) => {
        this.setState({
          AllBillingData: success.bill,
          checking: success.paginationMetadata,
          searchCheck: false,
        });
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });

        console.log(err);
      });
  };
  render() {
    const { modalData } = this.state;
    const { session } = this.state.session;

    const Loader = (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div class="spinner-border" style={{ color: "#01AC8A" }}></div>
      </div>
    );

    let pageNumbers = [];
    for (
      let i = 1;
      i <=
      Math.ceil(this.state.checking.totalCount / this.state.checking.pageSize);
      i++
    ) {
      pageNumbers.push(i);
    }

    const renderPageNumbers = pageNumbers.map((number, index) => {
      return (
        <li
          class={`page-item ${
            this.state.checking.currentPage === number ? "active" : ""
          } `}
        >
          <a
            key={number}
            id={number}
            onClick={() => this.paginationRequest(number)}
            class="page-link "
            href="#"
          >
            {number}
          </a>
        </li>
      );
    });

    const renderBillData = this.state.AllBillingData.map((item, index) => {
      return (
        <tr key={index}>
          <th scope="row">{index + 1}</th>
          <td>{item.payTo}</td>
          <td>{item.month}</td>
          <td>{item.amount}</td>
          <td>{item.session}</td>
          <td>{item.status}</td>
          <td>{item.accountId}</td>
          <td>{item.accountType}</td>
          <td>
            <a href="#" data-toggle="tooltip" title="Edit!">
              <button
                id="firstbutton"
                onClick={() => this.onEditClick(item.id)}
                onMouseOver={this.btnTechHover()}
                data-toggle="modal"
                data-target="#myModal"
                style={{
                  cursor: "pointer",
                  fontSize: 20,
                  border: "none",
                  marginLeft: 5,
                  background: "none",
                  marginBottom: 5,
                }}
              >
                <i class="fas fa-pen"></i>
              </button>
            </a>
            <a href="#" data-toggle="tooltip" title="Delete!">
              <button
                onClick={() => this.onEditClick(item.id)}
                data-toggle="modal"
                data-target="#myModal1"
                style={{
                  cursor: "pointer",
                  fontSize: 20,
                  border: "none",
                  background: "none",
                  marginLeft: 5,
                  marginBottom: 5,
                }}
              >
                <i style={{ color: "#D11A2A" }} class="fas fa-trash-alt"></i>
              </button>
            </a>
            <Link to={`/dashboard/BillingTemplate/${item.id}`}>
              <a id="tooltipId" data-toggle="tooltip" title="Print Report">
                <button
                  style={{
                    cursor: "pointer",
                    fontSize: 20,
                    border: "none",
                    background: "none",
                    marginLeft: 5,
                    marginBottom: 5,
                  }}
                >
                  <i style={{ color: "#01AC8A" }} class="fas fa-print"></i>
                </button>
              </a>
            </Link>
          </td>
        </tr>
      );
    });

    const MainContent = (
      <div>
        <div class="modal" id="myModal1">
          <div class="modal-dialog">
            <div class="modal-content modal_content_custom">
              <div
                style={{
                  borderBottomLeftRadius: 30,
                  borderBottomRightRadius: 30,
                  borderTopLeftRadius: 18,
                  borderTopRightRadius: 18,
                }}
                class="modal-header modal_custom_header"
              >
                <h4 style={{ color: "white" }} class="modal-title">
                  Confirm
                </h4>
                <button
                  style={{ color: "white" }}
                  type="button"
                  class="close"
                  data-dismiss="modal"
                >
                  &times;
                </button>
              </div>
              <div class="modal-body">
                {modalData &&
                  modalData.map((item, index) => (
                    <div key={index}>
                      <div style={{ textAlign: "center" }}>
                        <h4>Are you sure ?</h4>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <div>
                          <button
                            disabled={this.state.enabled}
                            data-dismiss="modal"
                            onClick={() => this.onTakeItemDelete(this.state.id)}
                            type="button"
                            class="btn btn-primary buttonAppear"
                          >
                            Yes
                          </button>
                        </div>
                        <div>
                          <button
                            data-dismiss="modal"
                            disabled={this.state.enabled}
                            type="button"
                            class="btn btn-primary buttonAppear"
                          >
                            No
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <div class="modal" id="myModal">
          <div class="modal-dialog">
            <div class="modal-content modal_content_custom">
              <div
                style={{
                  borderBottomLeftRadius: 30,
                  borderBottomRightRadius: 30,
                  borderTopLeftRadius: 18,
                  borderTopRightRadius: 18,
                }}
                class="modal-header modal_custom_header"
              >
                <h4 style={{ color: "white" }} class="modal-title">
                  Details
                </h4>
                <button
                  style={{ color: "white" }}
                  type="button"
                  class="close"
                  data-dismiss="modal"
                >
                  &times;
                </button>
              </div>
              <div class="modal-body">
                {this.state.errorMsg ? (
                  <p style={{ color: "red", textAlign: "center" }}>
                    {this.state.errorText}
                  </p>
                ) : (
                  ""
                )}
                <form onSubmit={this.onHandleSubmit}>
                  {modalData &&
                    modalData.map((item, index) => (
                      <div key={index}>
                        <fieldset disabled={!this.state.enabled}>
                          <div class="form-group">
                            <label for="campusName">Status:</label>
                            <input
                              style={{
                                borderTop: 0,
                                borderLeft: 0,
                                borderRight: 0,
                                boxShadow: "none",
                              }}
                              type="text"
                              value={this.state.status}
                              onChange={this.onHandleText}
                              readOnly={true}
                              name="campusName"
                              class="form-control"
                              id="campusName"
                            />
                          </div>
                          <div class="form-group">
                            <label for="campusName">Change Status:</label>
                            <div class="col-md-12" style={{ marginTop: 20 }}>
                              <div class="md-form mb-0">
                                <select
                                  onChange={this.onHandleText}
                                  class="custom-select"
                                  name="status"
                                >
                                  <option value="">Status</option>
                                  <option value="Approve">Approve</option>
                                  <option value="Not Paid">Not Paid</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                        <button
                          disabled={this.state.enabled}
                          onClick={() => this.setState({ enabled: true })}
                          type="button"
                          class="btn btn-primary buttonAppear"
                        >
                          Edit
                        </button>
                        <button
                          style={{ marginLeft: 5 }}
                          disabled={!this.state.enabled}
                          type="submit"
                          class="btn btn-primary buttonAppear"
                        >
                          Save
                        </button>
                      </div>
                    ))}
                </form>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  style={{ background: "#00435D" }}
                  class="btn btn-danger"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span className="customBadge" style={{ fontSize: 14 }}>
            Page: {this.state.checking.currentPage}
          </span>

          <span className="customBadge" style={{ fontSize: 14 }}>
            Total Page: {this.state.checking.totalPages}
          </span>
        </div>
        <ThemeContext.Consumer>
          {(context) => (
            <table
              class="table table-hover table-bordered"
              style={{ textAlign: "center" }}
            >
              <thead
                style={{
                  background: context.themeColors.primaryColor,
                  color: "white",
                }}
              >
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Pay To</th>
                  <th scope="col">Month</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Session</th>
                  <th scope="col">Status</th>
                  <th scope="col">AccountId</th>
                  <th scope="col">AccountType</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>{renderBillData}</tbody>
            </table>
          )}
        </ThemeContext.Consumer>
      </div>
    );
    return (
      <div>
        <SnackBar msg={this.state.msg} backColor={this.state.backColor} />

        {this.state.exist ? (
          <p style={{ color: "red", textAlign: "center" }}>
            Please Fill the Fields
          </p>
        ) : (
          ""
        )}
        {this.state.badRequest ? (
          <p style={{ color: "red", textAlign: "center" }}>
            {this.state.errorText}
          </p>
        ) : (
          ""
        )}
        <div className="page-header">
          <ThemeContext.Consumer>
            {(context) => (
              <div class="row">
                <div class="col-md-3"></div>
                <div class="col-md-6">
                  <h4
                    class="generalHead"
                    style={{
                      backgroundColor: context.themeColors.primaryColor,
                    }}
                  >
                    View Billing List
                  </h4>
                </div>
                <div class="col-md-3 text-right">
                  <nav aria-label="Page navigation example">
                    <ul
                      style={{ color: context.themeColors.primaryColor }}
                      class="pagination"
                    >
                      {renderPageNumbers}
                    </ul>
                  </nav>
                </div>
              </div>
            )}
          </ThemeContext.Consumer>
        </div>
        <br />
        <div class="container">
          <CustomFilterSectionLayout displayDirection="column">
            <div class="row" style={{ justifyContent: "center" }}>
              <div class="col-md-3" style={{ marginTop: 20 }}>
                <div class="md-form mb-0">
                  <select
                    onChange={this.sessionChange}
                    class="custom-select"
                    name="sessionId"
                  >
                    <option value="" selected={this.state.msg ? true : false}>
                      Choose Session...
                    </option>
                    {session &&
                      session.map((item, index) => (
                        <option key={index} value={`${item.sessionId}`}>
                          {item.currentSession}
                        </option>
                      ))}
                  </select>
                </div>
              </div>

              <div class="col-md-3" style={{ marginTop: 20 }}>
                <div class="md-form mb-0">
                  <select
                    onChange={this.onMonthChange}
                    class="custom-select"
                    name="month"
                  >
                    <option value="" selected={this.state.msg ? true : false}>
                      Select Month...
                    </option>
                    {months.Months.map((item, index) => (
                      <option key={index} value={`${item}`}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div class="col-md-3" style={{ marginTop: 20 }}>
                <div class="md-form mb-0">
                  <select
                    onChange={this.onStatusChange}
                    class="custom-select"
                    name="status"
                  >
                    <option value="">Status</option>
                    <option value="Approve">Approve</option>
                    <option value="Not Paid">Not Paid</option>
                  </select>
                </div>
              </div>
            </div>
          </CustomFilterSectionLayout>
        </div>
        <br />
        <br />
        {this.state.searchCheck ? Loader : MainContent}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    sharedActions: bindActionCreators(sharedActions, dispatch),
  };
}
function mapStateToProps(state) {
  return {
    allTransportState: state.setStudentSkillReducer,
    users: state.setUserReducer.users,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewBilling);
