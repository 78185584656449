import React, { Component } from "react";
import DatePicker from "react-date-picker";

const TextBoxesForCheques = (props) => {
  const {
    itemObj,
    onHandleTextChange,
    stateData,
    rowIndex,
    updateSpecialArrObjOnChange,
    updateSpecialArrObjOnChangeDate,
    arrName,
  } = props;

  let keys = Object.keys(itemObj);
  // console.log(stateData.siblingsDetails[0]['age'])
  if (keys.includes("StudentSiblingDetailId") && keys.includes("studentId")) {
    let newKeys = keys.filter(
      (item) => item !== "studentId" && item !== "StudentSiblingDetailId"
    );
    keys = [...newKeys];
  }
  if (keys.includes("PreviousSchoolDetailId") && keys.includes("studentId")) {
    let newKeys = keys.filter(
      (item) => item !== "studentId" && item !== "PreviousSchoolDetailId"
    );
    keys = [...newKeys];
  }

  return (
    <React.Fragment>
      {keys.map((item, index) =>
        item === "date" ? (
          <td key={index}>
            <input
              type="date"
              class="form-control"
              name={`${item}`}
              placeholder={`${item}`}
              onChange={(e) =>
                updateSpecialArrObjOnChangeDate(e, rowIndex, arrName)
              }
              // value={stateData[arrName][rowIndex][`${item}`]}
            />
          </td>
        ) : item === "chequeNo" ? (
          <td key={index}>
            <input
              type="number"
              placeholder={`${item}`}
              class="form-control"
              name={`${item}`}
              value={stateData[arrName][rowIndex][`${item}`]}
              onChange={(e) =>
                updateSpecialArrObjOnChange(e, rowIndex, arrName)
              }
            />
          </td>
        ) : item === "amount" ? (
          <td key={index}>
            <input
              type="number"
              placeholder={`${item}`}
              class="form-control"
              name={`${item}`}
              value={stateData[arrName][rowIndex][`${item}`]}
              onChange={(e) =>
                updateSpecialArrObjOnChange(e, rowIndex, arrName)
              }
            />
          </td>
        ) : (
          <td key={index}>
            <input
              type="text"
              placeholder={`${item}`}
              class="form-control"
              name={`${item}`}
              value={stateData[arrName][rowIndex][`${item}`]}
              onChange={(e) =>
                updateSpecialArrObjOnChange(e, rowIndex, arrName)
              }
            />
          </td>
        )
      )}
    </React.Fragment>
  );
};
export default TextBoxesForCheques;
