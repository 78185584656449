import React from "react";
import CustomBadge from "../CustomBadge/CustomBadge";
import { useContext } from "react";
import themeContext from "../../context/themeContext/ThemeContext";

export default function CustomFilterSectionLayout(props) {
  const themeColor = useContext(themeContext);
  const { displayDirection = "row" } = props;
  return (
    <div
      style={{ borderColor: themeColor.themeColors.primaryColor }}
      className="filter-section"
    >
      <CustomBadge badgeText={"Filters"} badgeIconClass={"fas fa-filter"} />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: displayDirection,
        }}
      >
        {props.children}
      </div>
    </div>
  );
}
