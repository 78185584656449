import React, { Component } from "react";
import "./viewroutes.css";
import { useContext } from "react";
import ThemeContext from "../../context/themeContext/ThemeContext";
const SnackBar = (props) => {
  const themeColor = useContext(ThemeContext);
  return (
    <div
      style={{ backgroundColor: themeColor.themeColors.primaryColor }}
      className="animated fadeInDown"
      id="snackbar"
    >
      {props.msg}
    </div>
  );
};

export default SnackBar;
