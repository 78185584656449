import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';

class ExamDashboard extends Component {
	state = {};
	componentDidMount() {
		$(document).click(function(e) {
			if (!$(e.target).is('.panel-body')) {
				$('.collapse').collapse('hide');
			}
		});
	}
	render() {
		return (
			<div>
				<section class="mb-4">
					<h3 class="h3-responsive font-weight-bold text-center my-4">
						Exam Modules
					</h3>
					<div
						class="row"
						style={{
							justifyContent: 'center',
							textAlign: 'center',
							marginBottom: -45
						}}
					>
						<div class="col-md-8">
							<div class="md-form mb-0">
								<hr class="style-head" />
							</div>
						</div>
					</div>

					<p class="text-center w-responsive mx-auto mb-5" />

					<div
						class="row"
						style={{ textAlign: 'center', justifyContent: 'center' }}
					>
						<div class="col-md-9 mb-md-0 mb-5">
							<form id="contact-form" name="contact-form">
								<div class="row">
									<div class="col-md-4">
										<div class="md-form mb-0">
											<Link to="/dashboard/AddExam">
												<img
													src={require('../Assets/ExamDashboard/add exam.svg')}
													width="200"
													height="200"
												/>
											</Link>
										</div>
									</div>
									<div class="col-md-4">
										<div class="md-form mb-0">
											<Link to="/dashboard/AddExamInfo">
												<img
													src={require('../Assets/ExamDashboard/add exam info.svg')}
													width="200"
													height="200"
												/>
											</Link>
										</div>
									</div>
									<div class="col-md-4">
										<div class="md-form mb-0">
											<Link to="/dashboard/ViewExamInfo">
												<img
													src={require('../Assets/ExamDashboard/view exam info.svg')}
													width="200"
													height="200"
												/>
											</Link>
										</div>
									</div>
									<div class="col-md-6">
										<div class="md-form mb-0">
											<Link to="/dashboard/AddExamDetails">
												<img
													src={require('../Assets/ExamDashboard/add exam details.svg')}
													width="200"
													height="200"
												/>
											</Link>
										</div>
									</div>
									<div class="col-md-6">
										<div class="md-form mb-0">
											<Link to="/dashboard/ViewExamDetails">
												<img
													src={require('../Assets/ExamDashboard/view exam details.svg')}
													width="200"
													height="200"
												/>
											</Link>
										</div>
									</div>
								</div>
							</form>
							<div class="status" />
						</div>
					</div>
				</section>
			</div>
		);
	}
}

export default ExamDashboard;
