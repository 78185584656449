import React, { Component } from 'react';
import { RenderingTd } from '../../TimeTableRendering';
import { utilsTTActions } from '../../../../utils';
let [day1, day2, day3, day4, day5, day6, day7] = utilsTTActions.days;
const allperiod = {};
export default function DayRowCom({
	off,
	day,
	AllPeriods,
	indexDay,
	stateData,
	onChangeNewPeriodData,
	type,
	subAndTechData
}) {
	if (type !== 'EditDataHandling') {
		if (day === day1) {
			allperiod.Monday =
				AllPeriods && AllPeriods.filter(item => item.dayName === day);
		} else if (day === day2) {
			allperiod.Tuesday =
				AllPeriods && AllPeriods.filter(item => item.dayName === day);
		} else if (day === day3) {
			allperiod.Wednesday =
				AllPeriods && AllPeriods.filter(item => item.dayName === day);
		} else if (day === day4) {
			allperiod.Thursday =
				AllPeriods && AllPeriods.filter(item => item.dayName === day);
		} else if (day === day5) {
			allperiod.Friday =
				AllPeriods && AllPeriods.filter(item => item.dayName === day);
		} else if (day === day6) {
			allperiod.Saturday =
				AllPeriods && AllPeriods.filter(item => item.dayName === day);
		} else if (day === day7) {
			allperiod.Sunday =
				AllPeriods && AllPeriods.filter(item => item.dayName === day);
		}
	}

	console.log("All Periods" , allperiod);
	return (
		<React.Fragment>
			<tr>
				{
					<React.Fragment>
						<th scope="row">
							{' '}
							<div
								style={{
									marginTop: !off && type !== 'nonDataHandling' ? 50 : 0,
									display: 'flex',
									flexDirection: 'column'
								}}
							>
								<div>{day}</div>{' '}
								<div>{typeof allperiod[day] !== "undefined" && allperiod[day].length > 0 ? allperiod[day][0]["date"] : null}</div>
								{off ? (
									<div
										style={{
											color: '#fff',
											background: '#0A4F5E',
											width: '60%',
											fontSize: 15
										}}
										class="badge badge-pill badge-warning"
									>
										off
									</div>
								) : (
									''
								)}{' '}
							</div>{' '}
						</th>
						<RenderingTd
							type={type}
							subAndTechData={subAndTechData}
							onChangeNewPeriodData={onChangeNewPeriodData}
							stateData={stateData}
							day={day}
							allPeriod={type === 'EditDataHandling' ? AllPeriods : allperiod}
						/>
					</React.Fragment>
				}
			</tr>
		</React.Fragment>
	);
}
