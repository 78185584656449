import React from "react";
import ViewRendering from "../../../components/ViewRendering";
import { renderingStuff, images } from "../../../utils";

import NewViewRendering from "../../../components/ViewRendering/NewViewRendering";
import DashboardLayout from "../../../components/HOC/DashboardLayout";

function HomeWorkDashboard(props) {
  return (
    <div>
      <DashboardLayout name="Home Work Module" image={images.imgAcHead}>
        <NewViewRendering {...props} data={renderingStuff.homeWorkDashlinks} />
      </DashboardLayout>
    </div>
  );
}

export default HomeWorkDashboard;

// class HomeWorkDashboard extends Component {
// 	state = {};
// 	componentDidMount() {
// 		$(document).click(function(e) {
// 			if (!$(e.target).is('.panel-body')) {
// 				$('.collapse').collapse('hide');
// 			}
// 		});
// 	}
// 	render() {
// 		return (
// 			<div>
// 				<section class="mb-4">
// 					<h3 class="h3-responsive font-weight-bold text-center my-4">
// 						Home Work Module
// 					</h3>
// 					<div
// 						class="row"
// 						style={{
// 							justifyContent: 'center',
// 							textAlign: 'center',
// 							marginBottom: -45
// 						}}
// 					>
// 						<div class="col-md-8">
// 							<div class="md-form mb-0">
// 								<hr class="style-head" />
// 							</div>
// 						</div>
// 					</div>

// 					<p class="text-center w-responsive mx-auto mb-5" />

// 					<div
// 						class="row"
// 						style={{ textAlign: 'center', justifyContent: 'center' }}
// 					>
// 						<div class="col-md-9 mb-md-0 mb-5">
// 							<form id="contact-form" name="contact-form">
// 								<div class="row">
// 									<div class="col-md-6">
// 										<div class="md-form mb-0">
// 											<Link to="/dashboard/AddHomeWork">
// 												<img
// 													src={require('../Assets/HomeWorkDashboard/add hw.svg')}
// 													width="200"
// 													height="200"
// 												/>
// 											</Link>
// 										</div>
// 									</div>
// 									<div class="col-md-6">
// 										<div class="md-form mb-0">
// 											<Link to="/dashboard/ViewHomeWork">
// 												<img
// 													src={require('../Assets/HomeWorkDashboard/view hw.svg')}
// 													width="200"
// 													height="200"
// 												/>
// 											</Link>
// 										</div>
// 									</div>
// 								</div>
// 							</form>
// 							<div class="status" />
// 						</div>
// 					</div>
// 				</section>
// 			</div>
// 		);
// 	}
// }

// export default HomeWorkDashboard;
