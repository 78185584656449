import React, { Component } from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import * as LoginAction from "../Actions/LoginActions/index";
import { bindActionCreators } from "redux";
import config from "../config/config";
import axios from "axios";
import "../components/Head.css";
import "../Container/signup.css";
import "../components/signup";
import logo from "../Assets/DoratPictures/newlogo.png";
import $ from "jquery";
import BulletenContext from "../context/BluttenContext/index";
import * as sharedActions from "../Actions/sharedActions";

import AddStock from "../Container/Inventory/AddStock";
import NewIcon from "../Assets/icons/DashboardIcon/white heading and icon horizontal.svg";
import RoleTypes from "../utils/app_constants/app_constants";
import ThemeContext from "../context/themeContext/ThemeContext";
import Colors from "../utils/app_constants/colors_constants";
import TextArea from "../components/TextArea";
import { useContext } from "react";
import InboxContext from "../context/InboxContext";
import ParentTeacherInboxGlobalContext from "../context/ParentTeacherInboxContext";
import Spinner from "../components/Spinner/Spinner";
import Purple from "../Assets/smartLogo/purple.svg";
import Cyan from "../Assets/smartLogo/cyan.svg";
import Blue from "../Assets/smartLogo/blue.svg";
import Orange from "../Assets/smartLogo/orange.svg";

const Profile = [
  { icon: "fa-user-circle", path: "/AddAdmin", RouteName: "My Profile" },
  { icon: "fa-level-down-alt", path: "/AdminList", RouteName: "Annual Leave" },
];

const Hod_links = [
  {
    icon: "fa-dot-circle",
    path: "/dashboard/StaffLeaveDeptDashboard",
    RouteName: "Staff Leave",
    type: "single",
  },
  {
    icon: "fa-check",
    parentName: "Academic LMS",
    parentIconName: "fa-check",
    type: "double",
    ChildArray: [
      {
        icon: "fa-dot-circle",
        path: "/dashboard/ViewExamDetailsDept",
        RouteName: "Exam Details",
      },
      {
        icon: "fa-dot-circle",
        path: "/dashboard/ViewExamInfoDept",
        RouteName: "Exam Info",
      },
      {
        icon: "fa-dot-circle",
        path: "/dashboard/ViewLessonPlanDept",
        RouteName: "Lesson Plan",
      },
      {
        icon: "fa-dot-circle",
        path: "/dashboard/ViewPastPaperDept",
        RouteName: "Past Paper",
      },
      {
        icon: "fa-dot-circle",
        path: "/dashboard/ViewQuizInfoDept",
        RouteName: "Quiz Info",
      },
      {
        icon: "fa-dot-circle",
        path: "/dashboard/ViewQuizDept",
        RouteName: "Quiz Details",
      },
      {
        icon: "fa-dot-circle",
        path: "/dashboard/ViewSyllabusDept",
        RouteName: "Syllabus",
      },
      {
        icon: "fa-dot-circle",
        path: "/dashboard/ViewTestInfoDept",
        RouteName: "Test Info",
      },
      {
        icon: "fa-dot-circle",
        path: "/dashboard/ViewTestDept",
        RouteName: "Test Details",
      },
    ],
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/CMSHODDashboard",
    RouteName: "CMS",
    type: "single",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/AcademicCalendarMain",
    RouteName: "Academic Calender",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/ConsolidatedTimeTable",
    RouteName: "Timetable",
  },
];
const Communication_links = [
  {
    icon: "fa-dot-circle",
    path: "/dashboard/ListOfRecipients",
    RouteName: "Communication Coordinator",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/ParentPortalManagerDashboard",
    RouteName: "Parent Management",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/AddBulletin",
    RouteName: "Bulletin",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/CEventCalender",
    RouteName: "Event Calender",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/UsersList",
    RouteName: "Portal Coordinator",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/CMSAdminDashboard",
    RouteName: "CMS",
  },
];
const Admission_Links = [
  // {
  // 	icon: 'fa-check',
  // 	parentName: 'Managers',
  // 	parentIconName: 'fa-check',
  // 	type: 'double',
  // 	ChildArray: [
  // 		{
  // 			icon: 'fa-dot-circle',
  // 			path: '/dashboard/AddManager',
  // 			RouteName: 'Add Manager'
  // 		},
  // 		{
  // 			icon: 'fa-dot-circle',
  // 			path: '/dashboard/ViewManager',
  // 			RouteName: 'View Manager'
  // 		}
  // 	]
  // }
  // {
  //   icon: "fa-check",
  //   parentName: "Managers",
  //   parentIconName: "fa-check",
  //   type: "double",
  //   ChildArray: [
  //     {
  //       icon: "fa-dot-circle",
  //       path: "/dashboard/AddManager",
  //       RouteName: "Add Manager",
  //     },
  //     {
  //       icon: "fa-dot-circle",
  //       path: "/dashboard/ViewManager",
  //       RouteName: "View Manager",
  //     },
  //   ],
  // },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/StudentAdmissionAdminDashboard",
    RouteName: "Student Admission",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/AdminExamEntryDashboard",
    RouteName: "Entry Exams",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/ParentPortalManagerDashboard",
    RouteName: "Parent Registration",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/SearchStudent",
    RouteName: "Student Records",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/AdminStudentFrontDesk",
    RouteName: "Online Registration Form",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/CensusDashboard",
    RouteName: "Census Module",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/CMSAdminDashboard",
    RouteName: "CMS",
  },
];
const Wellbeing_Manager_Links = [
  {
    icon: "fa-dot-circle",
    path: "/dashboard/ViewAbsentReport",
    RouteName: "Absent Report",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/ViewLateReport",
    RouteName: "Late Report",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/ViewFlagManagement",
    RouteName: "Flag Management Attendance ",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/ViewFlagManagementSkill",
    RouteName: "Flag Managment Skill",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/ViewDailyDisciplineReport",
    RouteName: "Daily Discipline Report",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/ViewDailyClassAttendance",
    RouteName: "Daily Class Attendance",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/ViewMonthlyStudentAttendanceReport",
    RouteName: "Monthly Student Attendance",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/ViewStudentSkillAverageReport",
    RouteName: "Student Skill Average",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/MedicalHistory",
    RouteName: "Medical History",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/CMSManagerDashboard",
    RouteName: "CMS",
  },
];

const Activity_Manager_Links = [
  {
    icon: "fa-dot-circle",
    path: "/dashboard/TourDashboard",
    RouteName: "Tours",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/CertificateDashboard",
    RouteName: "Certificates",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/AwardDashboard",
    RouteName: "Awards",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/EventCertificateDashboard",
    RouteName: "Event Certificates",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/GameDashboard",
    RouteName: "Games",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/CMSManagerDashboard",
    RouteName: "CMS",
  },
];
const SEN_Manager_Links = [
  {
    icon: "fa-dot-circle",
    path: "/dashboard/StudentTalentDashboard",
    RouteName: "Student Talent",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/CMSManagerDashboard",
    RouteName: "CMS",
  },
];

const Exclusion_Manager_Links = [
  {
    icon: "fa-dot-circle",
    path: "/dashboard/AddExclusion",
    RouteName: "Add Complaint",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/ViewExclusion",
    RouteName: "View Complaint",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/ViewInvestigationCommittee",
    RouteName: "Investigation Committee",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/StudentExclusionRecord",
    RouteName: "Exclusion Record",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/InvestigationList",
    RouteName: "Investigation List",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/CMSManagerDashboard",
    RouteName: "CMS",
  },
];

const Communication_Coordinator_Links = [
  {
    icon: "fa-check",
    parentName: "Head Of Department",
    parentIconName: "fa-check",
    type: "double",
    ChildArray: [
      {
        icon: "fa-dot-circle",
        path: "/dashboard/MessageHOD",
        RouteName: "Message",
      },
      {
        icon: "fa-dot-circle",
        path: "/dashboard/CoordinatorInbox/HOD",
        RouteName: "Inbox",
      },
    ],
  },
  {
    icon: "fa-check",
    parentName: "Academic Coordinator",
    parentIconName: "fa-check",
    type: "double",
    ChildArray: [
      {
        icon: "fa-dot-circle",
        path: "/dashboard/MessageAC",
        RouteName: "Message",
      },
      {
        icon: "fa-dot-circle",
        path: "/dashboard/CoordinatorInbox/Academic%20Coordinator",
        RouteName: "Inbox",
      },
    ],
  },
  {
    icon: "fa-check",
    parentName: "Dean",
    parentIconName: "fa-check",
    type: "double",
    ChildArray: [
      {
        icon: "fa-dot-circle",
        path: "/dashboard/MessageDean",
        RouteName: "Message",
      },
      {
        icon: "fa-dot-circle",
        path: "/dashboard/CoordinatorInbox/Dean",
        RouteName: "Inbox",
      },
    ],
  },
  {
    icon: "fa-check",
    parentName: "Manager",
    parentIconName: "fa-check",
    type: "double",
    ChildArray: [
      {
        icon: "fa-dot-circle",
        path: "/dashboard/MessageManager",
        RouteName: "Message",
      },
      {
        icon: "fa-dot-circle",
        path: "/dashboard/CoordinatorInbox/Manager",
        RouteName: "Inbox",
      },
    ],
  },
  {
    icon: "fa-check",
    parentName: "Staff",
    parentIconName: "fa-check",
    type: "double",
    ChildArray: [
      {
        icon: "fa-dot-circle",
        path: "/dashboard/MessageStaff",
        RouteName: "Message",
      },
      {
        icon: "fa-dot-circle",
        path: "/dashboard/CoordinatorInbox/Staff",
        RouteName: "Inbox",
      },
    ],
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/CMSManagerDashboard",
    RouteName: "CMS",
  },
];

const Portal_Coordinator_Links = [
  {
    icon: "fa-dot-circle",
    path: "/dashboard/HODUsers",
    RouteName: "Head Of Department",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/DeanUsers",
    RouteName: "Dean",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/AdminUsers",
    RouteName: "Admin",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/ManagerUsers",
    RouteName: "Manager",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/CoordinatorUsers",
    RouteName: "Coordinator",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/StaffUsers",
    RouteName: "Staff",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/StudentUsers",
    RouteName: "Student",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/ParentUsers",
    RouteName: "Parent",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/CMSManagerDashboard",
    RouteName: "CMS",
  },
];

const Administration_Links = [
  {
    icon: "fa-check",
    parentName: "Administration Smart",
    parentIconName: "fa-check",
    type: "double",
    ChildArray: [
      {
        icon: "fa-dot-circle",
        path: "/dashboard/InventoryDashboard",
        RouteName: "Inventory Smart",
      },
      {
        icon: "fa-dot-circle",
        path: "/dashboard/CampusDashboard",
        RouteName: "Campus Smart",
      },
      {
        icon: "fa-dot-circle",
        path: "/dashboard/CanteenDashboard",
        RouteName: "Canteen Smart",
      },
      {
        icon: "fa-dot-circle",
        path: "/dashboard/TransportDashboard",
        RouteName: "Transport Smart",
      },
      {
        icon: "fa-dot-circle",
        path: "/dashboard/CensusDashboard",
        RouteName: "Census Information Smart",
      },
      {
        icon: "fa-dot-circle",
        path: "/dashboard/StudentAdmissionDashboard",
        RouteName: "Admission Form Smart",
      },
    ],
  },
];
const gm_Links = [
  // {
  //   icon: "fa-check",
  //   parentName: "Admin",
  //   parentIconName: "fa-check",
  //   type: "double",
  //   ChildArray: [
  //     {
  //       icon: "fa-dot-circle",
  //       path: "/dashboard/AddAdmin",
  //       RouteName: "Add Admin",
  //     },
  //     {
  //       icon: "fa-dot-circle",
  //       path: "/dashboard/ViewAdmin",
  //       RouteName: "View Admin",
  //     },
  //   ],
  // },
  // {
  //   icon: "fa-check",
  //   parentName: "Finance Smart",
  //   parentIconName: "fa-check",
  //   type: "double",
  //   ChildArray: [
  //     {
  //       icon: "fa-check",
  //       path: "/dashboard/AccountLedger",
  //       RouteName: "Account Ledger",
  //     },
  //     {
  //       icon: "fa-check",
  //       path: "/dashboard/AddBudget",
  //       RouteName: "Add Budget",
  //     },
  //     {
  //       icon: "fa-check",
  //       path: "/dashboard/ViewBudget",
  //       RouteName: "View Budget",
  //     },
  //   ],
  // },
  // {
  //   icon: "fa-check",
  //   parentName: "Academic Smart",
  //   parentIconName: "fa-check",
  //   type: "double",
  //   ChildArray: [
  //     {
  //       icon: "fa-check",
  //       path: "/dashboard/AcademicLMS",
  //       RouteName: "LMS",
  //     },
  //     {
  //       icon: "fa-check",
  //       path: "/dashboard/AcademicCMS",
  //       RouteName: "CMS",
  //     },
  //     {
  //       icon: "fa-check",
  //       path: "/dashboard/AcademicSID",
  //       RouteName: "SID",
  //     },
  //   ],
  // },
  // {
  //   icon: "fa-check",
  //   parentName: "Administration Smart",
  //   parentIconName: "fa-check",
  //   type: "double",
  //   ChildArray: [
  //     {
  //       icon: "fa-dot-circle",
  //       path: "/dashboard/InventoryDashboard",
  //       RouteName: "Inventory Smart",
  //     },
  //     {
  //       icon: "fa-dot-circle",
  //       path: "/dashboard/CampusDashboard",
  //       RouteName: "Campus Smart",
  //     },
  //     {
  //       icon: "fa-dot-circle",
  //       path: "/dashboard/CanteenDashboard",
  //       RouteName: "Canteen Smart",
  //     },
  //     {
  //       icon: "fa-dot-circle",
  //       path: "/dashboard/TransportDashboard",
  //       RouteName: "Transport Smart",
  //     },
  //     {
  //       icon: "fa-dot-circle",
  //       path: "/dashboard/CensusDashboard",
  //       RouteName: "Census Information Smart",
  //     },
  //     {
  //       icon: "fa-dot-circle",
  //       path: "/dashboard/StudentAdmissionDashboard",
  //       RouteName: "Admission Form Smart",
  //     },
  //     {
  //       icon: "fa-dot-circle",
  //       path: "/dashboard/AdministrationManagers",
  //       RouteName: "Admin Manager Smart ",
  //     },
  //   ],
  // },
  // {
  //   icon: "fa-check",
  //   parentName: "Communication Smart",
  //   parentIconName: "fa-check",
  //   type: "double",
  //   ChildArray: [
  //     {
  //       icon: "fa-check",
  //       path: "/dashboard/CommunicationDashboard",
  //       RouteName: "Parent Portal",
  //     },
  //     {
  //       icon: "fa-check",
  //       path: "/dashboard/CommunicationDashboard",
  //       RouteName: "Student Portal",
  //     },
  //   ],
  // },
  // {
  //   icon: "fa-check",
  //   path: "/dashboard/HrDashboard",
  //   RouteName: "HR Smart",
  //   type: "single",
  // },
];

const Pastoral_Links = [
  {
    icon: "fa-dot-circle",
    path: "/dashboard/MedicalDashboard",
    RouteName: "Medical Center",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/ActivityDashboard",
    RouteName: "Activity",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/ExclusionDashboard",
    RouteName: "Exclusion",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/PastrolSENManagerDashboard",
    RouteName: "SEN Manager",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/PastrolWellbeingManagerDashboard",
    RouteName: "WellBeing Manager",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/StudentProfileDashboard",
    RouteName: "Student Profile",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/CMSAdminDashboard",
    RouteName: "CMS",
  },
];
const CMS_Link = [
  {
    icon: "fa-dot-circle",
    path: "/dashboard/CMSDeanDashboard",
    RouteName: "CMS",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/AcademicCalendarMain",
    RouteName: "Academic Calender",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/ConsolidatedTimeTable",
    RouteName: "Timetable",
   
  }
];

const Student_Coordinator_Links = [
  {
    icon: "fa-dot-circle",
    path: "/dashboard/ClassOfStudent",
    RouteName: "Class Of Students",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/TransferStudent",
    RouteName: "Transfer Student",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/CMSManagerDashboard",
    RouteName: "CMS",
  },
];

const Staff_Coordinator_Links = [
  {
    icon: "fa-dot-circle",
    path: "/dashboard/AssignSubjectDashboard",
    RouteName: "Assign Subject",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/ClassTeacherDashboard",
    RouteName: "Class Teacher",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/AssignHODDashboard",
    RouteName: "Staff In Department",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/AssignCoordinatorDashboard",
    RouteName: "Grade Coordinator",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/CMSManagerDashboard",
    RouteName: "CMS",
  },
];

const Medical_Staff_Links = [
  {
    icon: "fa-dot-circle",
    path: "/dashboard/PatientDashboard",
    RouteName: "Patient",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/DoctorDashboard",
    RouteName: "Doctor",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/MedicalHistory",
    RouteName: "Medical History",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/CMSManagerDashboard",
    RouteName: "CMS",
  },
];

const sm_Links = [
  {
    icon: "fa-dot-circle",
    path: "/dashboard/CampusSecurityDashboard",
    RouteName: "Campus Security",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/CMSManagerDashboard",
    RouteName: "CMS",
  },
];

const pm_Links = [
  {
    icon: "fa-dot-circle",
    path: "/dashboard/IndentDashboard",
    RouteName: "Procurement",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/CMSManagerDashboard",
    RouteName: "CMS",
  },
];

const Transport = [
  {
    icon: "fa-dot-circle",
    path: "/dashboard/AddRoutes",
    RouteName: "Add Routes",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/ViewRoutes",
    RouteName: "View Routes",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/AddVehicle",
    RouteName: "Add Vehicle",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/ViewVehicle",
    RouteName: "View Vehicle",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/AddDriver",
    RouteName: "Add Driver",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/ViewDriver",
    RouteName: "View Driver",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/AssignVehicle",
    RouteName: "Assign Vehicle",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/ViewAssignVehicle",
    RouteName: "View Assign Vehicle",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/CreateTransportFees",
    RouteName: "Create Transport Fees",
  },
];

const TRANSPORT_COORDINATOR_Links = [
  {
    icon: "fa-dot-circle",
    path: "/dashboard/RouteDashboard",
    RouteName: "Routes",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/VehicleDashboard",
    RouteName: "Vehicles",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/DriverDashboard",
    RouteName: "Drivers",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/TransportMaintenanceDashboard",
    RouteName: "Transport Maintenance",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/CMSManagerDashboard",
    RouteName: "CMS",
  },
];

const EXCLUSION_MANAGER_Links = [
  {
    icon: "fa-dot-circle",
    path: "/dashboard/AddExclusion",
    RouteName: "Add Complain",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/ViewExclusion",
    RouteName: "View Complain",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/ViewInvestigationCommittee",
    RouteName: "Investigation Committee",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/StudentExclusionRecord",
    RouteName: "Exclusion Records",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/InvestigationList",
    RouteName: "Investigation List",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/CMSManagerDashboard",
    RouteName: "CMS",
  },
];

const studentLinks = [
  {
    icon: "fa-check",
    path: "/dashboard/StudentSkillView",
    RouteName: "Student Skill",
    type: "single",
  },
  {
    icon: "fa-check",
    path: "/dashboard/StudentDescriptiveView",
    RouteName: "Descriptive Report",
    type: "single",
  },

  {
    icon: "fa-check",
    path: "/dashboard/StudentRoleDiscipline",
    RouteName: "Student Discipline",
    type: "single",
  },
  {
    icon: "fa-check",
    path: "/dashboard/StudentModuleLMSDashboard",
    RouteName: "LMS",
    type: "single",
  },
  {
    icon: "fa-check",
    path: "/dashboard/CaReportList",
    RouteName: "CA Report",
    type: "single",
  },
  {
    icon: "fa-check",
    path: "/dashboard/StudentTimeTable",
    RouteName: "TimeTable",
    type: "single",
  },
  {
    icon: "fa-check",
    path: "/dashboard/ViewFilterTour",
    RouteName: "Tour",
    type: "single",
  },
  {
    icon: "fa-check",
    path: "/dashboard/ViewEvent",
    RouteName: "Event",
    type: "single",
  },
  {
    icon: "fa-check",
    path: "/dashboard/CEventCalender",
    RouteName: "Event Calender",
    type: "single",
  },
  {
    icon: "fa-check",
    path: "/dashboard/AcademicCalendarMain",
    RouteName: "Academic Calender",
    type: "single",
  },
];
const parentLinks = [
  {
    icon: "fa-check",
    path: "/dashboard/StudentSkillViewP",
    RouteName: "Student Skill",
  },
  {
    icon: "fa-check",
    path: "/dashboard/StudentDescriptiveViewP",
    RouteName: "Descriptive Report",
  },
  {
    icon: "fa-check",
    path: "/dashboard/CaParentReportList",
    RouteName: "Continous Assessment Report",
    type: "single",
  },
  {
    icon: "fa-check",
    path: "/dashboard/ParentStudentDiscipline",
    RouteName: "Student Discipline",
  },
  {
    icon: "fa-check",
    path: "/dashboard/StudentFeeHistoryP",
    RouteName: "Student Fee History",
  },
  {
    icon: "fa-check",
    path: "/dashboard/StudentTimeTable",
    RouteName: "TimeTable",
    type: "single",
  },
  {
    icon: "fa-check",
    path: "/dashboard/ViewFilterTour",
    RouteName: "Tour",
    type: "single",
  },
  {
    icon: "fa-check",
    path: "/dashboard/ViewEvent",
    RouteName: "Event",
    type: "single",
  },
  {
    icon: "fa-check",
    path: "/dashboard/CEventCalender",
    RouteName: "Event Calender",
    type: "single",
  },
  // {
  //   icon: "fa-check",
  //   path: "/dashboard/AcademicCalendarMain",
  //   RouteName: "Academic Calender",
  //   type: "single",
  // },
];

const staffLinks = [
  {
    icon: "fa-check",
    path: "/dashboard/AcademicCalendarMain",
    RouteName: "Academic Calender",
    type: "single",
  },
  ,{
    icon: "fa-check",
    path: "/dashboard/StudentSkillDashboard",
    RouteName: "Student Skill",
    type: "single",
  },
  {
    icon: "fa-check",
    path: "/dashboard/DescriptiveDashboard",
    RouteName: "Descriptive Report",
    type: "single",
  },
  {
    icon: "fa-check",
    path: "/dashboard/StudentAttendanceDashboard",
    RouteName: "Attendance",
    type: "single",
  },
  {
    icon: "fa-check",
    parentName: "Discipline",
    parentIconName: "fa-check",
    type: "double",
    ChildArray: [
      {
        icon: "fa-dot-circle",
        path: "/dashboard/StudentDisciplineRecord",
        RouteName: "Student Discipline Record",
      },
      {
        icon: "fa-dot-circle",
        path: "/dashboard/EditStudentDisciplineRecord",
        RouteName: "Update Student Discipline Record",
      },
      {
        icon: "fa-dot-circle",
        path: "/dashboard/ViewStudentDiscipline",
        RouteName: "Reports of Student Discipline Record",
      },
      // {
      //   icon: "fa-dot-circle",
      //   path: "/dashboard/SubjectDisciplineMarks",
      //   RouteName: " Subject Discipline Marks",
      // },
    ],
  },
  {
    icon: "fa-check",
    parentName: "Continuous Assessment",
    parentIconName: "fa-check",
    type: "double",
    letterSpacing: -1,
    ChildArray: [
      {
        icon: "fa-dot-circle",
        path: "/dashboard/caPrimaryInfo",
        RouteName: "CA Primary Information",
      },
      {
        icon: "fa-dot-circle",
        path: "/dashboard/CASubToolView",
        RouteName: "CA Tools Creation",
      },
      {
        icon: "fa-dot-circle",
        path: "/dashboard/ContinousResultSheet",
        RouteName: "Result Sheet",
      },
    ],
  },
  {
    icon: "fa-check",
    parentName: "Class Discipline",
    parentIconName: "fa-check",
    type: "double",
    ChildArray: [
      {
        icon: "fa-dot-circle",
        path: "/dashboard/ClassDiscipline",
        RouteName: "Student Class Discipline",
      },
      {
        icon: "fa-dot-circle",
        path: "/dashboard/ViewclassDisciplinaryRecords",
        RouteName: "View Class Discipline",
      },
      // {
      // 	icon: 'fa-dot-circle',
      // 	path: '/dashboard/EditStudentDisciplineRecord',
      // 	RouteName: 'Update Student Discipline Record'
      // },
      // {
      // 	icon: 'fa-dot-circle',
      // 	path: '/dashboard/ViewStudentDiscipline',
      // 	RouteName: 'Reports of Student Discipline Record'
      // },
      // {
      // 	icon: 'fa-dot-circle',
      // 	path: '/dashboard/SubjectDisciplineMarks',
      // 	RouteName: ' Subject Discipline Marks'
      // }
    ],
  },
  {
    icon: "fa-check",
    parentName: "Academic LMS",
    parentIconName: "fa-check",
    type: "double",
    ChildArray: [
      {
        icon: "fa-dot-circle",
        path: "/dashboard/StaffModuleLMSHomeWorkDashboard",
        RouteName: "Homework",
      },
      {
        icon: "fa-dot-circle",
        path: "/dashboard/StaffModuleLMSExamInfoDashboard",
        RouteName: "Exam",
      },
      {
        icon: "fa-dot-circle",
        path: "/dashboard/StaffModuleLMSQuizInfoDashboard",
        RouteName: "Quiz",
      },
      {
        icon: "fa-dot-circle",
        path: "/dashboard/StaffModuleLMSAssignmentDashboard",
        RouteName: "Assignment",
      },
      {
        icon: "fa-dot-circle",
        path: "/dashboard/StaffModuleLMSLessonPlanDashboard",
        RouteName: "Lesson Plan",
      },
      {
        icon: "fa-dot-circle",
        path: "/dashboard/StaffModuleLMSSyllabusDashboard",
        RouteName: "Syllabus",
      },
      {
        icon: "fa-dot-circle",
        path: "/dashboard/StaffModuleLMSPastPaperDashboard",
        RouteName: "Past Paper",
      },
      {
        icon: "fa-dot-circle",
        path: "/dashboard/StaffModuleLMSTestDashboard",
        RouteName: "Test",
      },
    ],
  },
  {
    icon: "fa-check",
    path: "/dashboard/IndentStaffDashboard",
    RouteName: "Indent Form",
    type: "single",
  },
  {
    icon: "fa-check",
    path: "/dashboard/StaffLeaveDashboard",
    RouteName: "Staff Leave",
    type: "single",
  },
  {
    icon: "fa-check",
    path: "/dashboard/LeaveDashboard",
    RouteName: "Leave ",
    type: "single",
  },
  {
    icon: "fa-check",
    path: "/dashboard/ViewTourByStaff",
    RouteName: "Tour",
    type: "single",
  },
  {
    icon: "fa-check",
    path: "/dashboard/ViewEvaluationByStaff",
    RouteName: "Teacher Evaluation",
    type: "single",
  },
  {
    icon: "fa-check",
    path: "/dashboard/EventCertificateByStaff",
    RouteName: "Event Certificate",
    type: "single",
  },
  {
    icon: "fa-check",
    parentName: "Exclusion",
    parentIconName: "fa-check",
    type: "double",
    ChildArray: [
      {
        icon: "fa-dot-circle",
        path: "/dashboard/AddExclusion",
        RouteName: "Add Complain",
      },
      {
        icon: "fa-dot-circle",
        path: "/dashboard/ViewExclusionByStaff",
        RouteName: "View Exclusion",
      },
    ],
  },
  {
    icon: "fa-check",
    path: "/dashboard/StaffEventView",
    RouteName: "Event",
    type: "single",
  },
];
const maintenance_coordinator_links = [
  {
    icon: "fa-dot-circle",
    path: "/dashboard/CampusMaintenanceDashboard",
    RouteName: "Campus Maintenance",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/CMSManagerDashboard",
    RouteName: "CMS",
  },
];
const Grade_Coordinator_Links = [
  {
    icon: "fa-dot-circle",
    path: "/dashboard/AssignCoordinatorDashboard",
    RouteName: "Grade Coordinator",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/CMSManagerDashboard",
    RouteName: "CMS",
  },
];
const hrLinks = [
  {
    icon: "fa-dot-circle",
    path: "/dashboard/StaffModule",
    RouteName: "Staff Resgistration Form",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/HrDevelopmentDashboard",
    RouteName: "HR Developement And Training",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/FrontDeskDashboard",
    RouteName: "Front Desk Module",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/DepartmentDashboard",
    RouteName: "Department Module",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/PostDashboard",
    RouteName: "Post Module",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/PayrollDashboard",
    RouteName: "Payroll",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/StudentCoordinatorDashboard",
    RouteName: "Student Coordinator",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/StaffCoordinatorDashboard",
    RouteName: "Staff Coordinator",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/StaffLeaveHRDashboard",
    RouteName: "Staff Leave",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/CMSAdminDashboard",
    RouteName: "CMS",
  },
];

const News_Link = [
  {
    icon: "fa-dot-circle",
    path: "/dashboard/AddBulletin",
    RouteName: "Bulletin",
  },

  ,
  {
    icon: "fa-dot-circle",
    path: "/dashboard/CMSManagerDashboard",
    RouteName: "CMS",
  },
];
const business_Smart = [
  {
    icon: "fas fa-check",
    path: "/dashboard/StudentAdmissionDashboard",
    RouteName: "Student Admission",
  },
  {
    icon: "fas fa-check",
    path: "/dashboard/EntryExamDashboard",
    RouteName: "Entry Exam",
  },
  {
    icon: "fas fa-check",
    path: "/dashboard/ParentPortalManagerDashboard",
    RouteName: "Parent Registration",
  },
];
const Managers_Link = [
  {
    icon: "fa-dot-circle",
    path: "/dashboard/ManagerPostDashboard",
    RouteName: "All Managers",
  },
];
const Library_Link = [
  {
    icon: "fa-dot-circle",
    path: "/dashboard/AddBookCategory",
    RouteName: "Add Book Category",
    type: "single",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/ViewBookCategory",
    RouteName: "View Book Category",
    type: "single",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/AddBook",
    RouteName: "Add Book",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/ViewBook",
    RouteName: "View Book",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/IssueBook",
    RouteName: "Issue Book",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/ViewIssuedBook",
    RouteName: "View Issue Book",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/CMSManagerDashboard",
    RouteName: "CMS",
  },
];
const Pastoral_Smart = [
  {
    icon: "fa-dot-circle",
    path: "/dashboard/PastrolSENManagerDashboard",
    RouteName: "SEN Manager",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/MedicalDashboard",
    RouteName: "Medical Center",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/ActivityDashboard",
    RouteName: "Activities",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/ExclusionDashboard",
    RouteName: "Exclusion",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/StudentProfileDashboard",
    RouteName: "Student Profile",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/PastrolWellbeingManagerDashboard",
    RouteName: "WellBeing Manager",
  },
];
const financeLinks = [
  {
    icon: "fa-dot-circle",
    path: "/dashboard/AccountsDashboard",
    RouteName: "Account Module",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/BudgetDashboard",
    RouteName: "Budget Module",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/CMSAdminDashboard",
    RouteName: "CMS",
  },
  // {
  //   icon: "fa-check",
  //   path: "/dashboard/AccountsDashboard",
  //   RouteName: "Accounts",
  // },
  // {
  //   icon: "fa-check",
  //   path: "/dashboard/AccountLedger",
  //   RouteName: "Account Ledger",
  // },
  // {
  //   icon: "fa-check",
  //   path: "/dashboard/BudgetDashboard",
  //   RouteName: "Budget",
  // },
  // {
  //   icon: "fa-check",
  //   path: "/dashboard/BillingDashboard",
  //   RouteName: "Billing",
  // },
];

const Administration1 = [
  {
    icon: "fa-dot-circle",
    path: "/dashboard/AddAdmin",
    RouteName: "All Admins",
  },
];

const acadmic_Parent_Links = [
  {
    icon: "fa-check",
    parentName: "LMS",
    ChildArray: Administration1,
  },
  {
    icon: "fa-check",
    parentName: "CMS ",
    ChildArray: Administration1,
  },
  {
    icon: "fa-check",
    parentName: "SID",
    ChildArray: Administration1,
  },
];
const AcadmicsLinks = [
  {
    icon: "fa-dot-circle",
    path: "/dashboard/AcademicLMS",
    RouteName: "LMS",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/AcademicCMS",
    RouteName: "CMS",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/AcademicSID",
    RouteName: "SID",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/CMSAdminDashboard",
    RouteName: "CMS Of Admin",
  },
];
const SystemSmartLinks = [
  {
    icon: "fa-dot-circle",
    path: "/dashboard/AddLoginTimeLimit",
    RouteName: "Add Time Limit",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/ViewLoginTimeLimit",
    RouteName: "View Time Limit",
  },
];
const TimeTableSmartLinks = [
  {
    icon: "fa-dot-circle",
    path: "/dashboard/timetable",
    RouteName: "Time Table",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/viewTimetable",
    RouteName: "Time Table Details",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/LessonperweekScreen",
    RouteName: "Lesson Per Week",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/cutoffPost",
    RouteName: "Cut Off Post",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/SubstitutionPeriodList",
    RouteName: "Substitution Period List",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/ApplySubstitution",
    RouteName: "Apply Substitution",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/SubstitutionPeriodList",
    RouteName: "Substitution Period List",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/allPriorityList",
    RouteName: "All Priority List",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/DailySubstitutionRecord",
    RouteName: "Daily Substitution Record",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/StaffTimeTableByCoordinator",
    RouteName: "View Staff Time Table",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/ConsolidatedTimeTable",
    RouteName: "Consolidated Timetable",
  },
];

const StaffFormManagerLinks = [
  {
    icon: "fa-dot-circle",
    path: "/dashboard/staff",
    RouteName: "Add Staff",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/ViewStaff",
    RouteName: "View Staff",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/CMSManagerDashboard",
    RouteName: "CMS",
  },
];
const Accountant_Link = [
  {
    icon: "fa-dot-circle",
    path: "/dashboard/AccountsDashboard",
    RouteName: "Accountant",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/CMSManagerDashboard",
    RouteName: "CMS",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/ViewHrDevelopment",
    RouteName: "Staff Development & Training",
  },
];
const Billing_Link = [
  {
    icon: "fa-dot-circle",
    path: "/dashboard/AddBilling",
    RouteName: "Add Billing",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/ViewBilling",
    RouteName: "View Billing",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/CMSManagerDashboard",
    RouteName: "CMS",
  },
];
const Admission_Link = [
  {
    icon: "fa-dot-circle",
    path: "/dashboard/addForm",
    RouteName: "Add Admission",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/viewAddForm",
    RouteName: "View Admission",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/CMSManagerDashboard",
    RouteName: "CMS",
  },
];
const Doctor_Links = [
  {
    icon: "fa-dot-circle",
    path: "/dashboard/PatientDashboard",
    RouteName: "Patient",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/MedicalHistory",
    RouteName: "Medical History",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/CMSManagerDashboard",
    RouteName: "CMS",
  },
];
const StudentFormManagerLinks = [
  {
    icon: "fa-dot-circle",
    path: "/dashboard/addForm",
    RouteName: "Add Admission",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/viewAddForm",
    RouteName: "View Admission",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/CMSManagerDashboard",
    RouteName: "CMS",
  },
];

const Staff = [
  { icon: "fa-user-circle", parentName: "Profile", RouteName: "My Profile" },
  { icon: "fa-child", path: "/viewCla", RouteName: "Holiday" },
  { icon: "fa-clipboard", path: "/viewClass", RouteName: "Notice" },
  { icon: "fa-sign-out-alt", path: `/`, RouteName: "logout" },
];

// end of staff routes
//
// start of owner and admin routes

const AOSingleRoutes = [
  {
    icon: "fa-dot-circle",
    path: "/dashboard/PrintAdmissionFormFinal",
    RouteName: "Admission Form",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/ViewAppraisal",
    RouteName: "View Appraisal",
  },
  { icon: "fa-dot-circle", path: "/dashboard/Check", RouteName: "Check" },
  { icon: "fa-dot-circle", path: "/viewClass", RouteName: "Help Desk" },
  { icon: "fa-dot-circle", path: "/viewClass", RouteName: "Today's Thought" },
  { icon: "fa-dot-circle", path: "/viewClass", RouteName: "Photo Album" },
  { icon: "fa-dot-circle", path: "/viewClass", RouteName: "Holiday" },
  { icon: "fa-dot-circle", path: "/viewClass", RouteName: "Role Management" },
  { icon: "fa-dot-circle", path: "/viewClass", RouteName: "Notice" },
  { icon: "fa-dot-circle", path: `/`, RouteName: "logout" },
];

const adminLinks = [
  {
    icon: "fa-dot-circle",
    path: "/dashboard/AddAdmin",
    RouteName: "Add Admin",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/viewAdmin",
    RouteName: "View Admin",
  },
];

const Administration = [
  {
    icon: "fa-dot-circle",
    path: "/dashboard/InventoryDashboard",
    RouteName: "Inventory Smart",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/CampusDashboard",
    RouteName: "Campus Smart",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/CanteenDashboard",
    RouteName: "Canteen Smart",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/TransportDashboard",
    RouteName: "Transport Smart",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/CensusDashboard",
    RouteName: "Census Information Smart",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/StudentAdmissionDashboard",
    RouteName: "Admission Form Smart",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/IndentDashboard",
    RouteName: "Indent Form ",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/CampusSecurityDashboard",
    RouteName: "Campus Security ",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/CMSAdminDashboard",
    RouteName: "CMS",
  },
];
const InventoryLinks = [
  {
    icon: "fa-dot-circle",
    path: "/dashboard/AddCategory",
    RouteName: "Add Category",
    type: "single",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/ViewCategory",
    RouteName: "View Category",
    type: "single",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/AddSuppliers",
    RouteName: "Add Suppliers",
    type: "single",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/ViewSuppliers",
    RouteName: "View Suppliers",
    type: "single",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/AddItems",
    RouteName: "Add Items",
    type: "single",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/ViewItems",
    RouteName: "View Items",
    type: "single",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/AddStock",
    RouteName: "Add Stock",
    type: "single",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/ViewStock",
    RouteName: "View Stock",
    type: "single",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/AddStockUsage",
    RouteName: "Add Stock Usage",
    type: "single",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/ViewStockUsage",
    RouteName: "View Stock Usage",
    type: "single",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/CMSManagerDashboard",
    RouteName: "CMS",
  },
];
const CensusInformation = [
  { icon: "fa-dot-circle", path: "/viewClass", RouteName: "View class" },
  {
    icon: "fa-dot-circle",
    path: "/viewClass",
    RouteName: "View Student Session and Count",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/ClassSection",
    RouteName: "Class Section",
  },

  { icon: "fa-dot-circle", path: "/viewClass", RouteName: "View Admission" },
  {
    icon: "fa-dot-circle",
    path: "/viewClass",
    RouteName: "View Tranformed Student",
  },
  { icon: "fa-dot-circle", path: "/viewClass", RouteName: "Student Filter" },
  { icon: "fa-dot-circle", path: "/viewClass", RouteName: "Filter Option" },
];
const HRAdmin = [
  { icon: "fa-dot-circle", path: "/viewClass", RouteName: "Post" },
  { icon: "fa-dot-circle", path: "/viewClass", RouteName: "Department" },
];
// const Transport = [
//   { icon: "fa-route", path: "/viewClass", RouteName: "Route" },
//   { icon: "fa-truck-pickup", path: "/viewClass", RouteName: "Vehicle" },
//   { icon: "fa-wheelchair", path: "/viewClass", RouteName: "Driver" },
//   { icon: "fa-user-ninja", path: "/viewClass", RouteName: "Assign Vehicle" },
//   {
//     icon: "fa-money-bill-wave-alt",
//     path: "/viewClass",
//     RouteName: "Create Transport Fees"
//   }
// ];
const Officer = [
  { icon: "fa-dot-circle", path: "/viewClass", RouteName: "Student Enquiry" },
  { icon: "fa-dot-circle", path: "/viewClass", RouteName: "Staff Enquiry" },
];

const Management = [
  { icon: "fa-dot-circle", path: "/viewClass", RouteName: "Search Student" },
  { icon: "fa-dot-circle", path: "/viewClass", RouteName: "Add Student" },
  { icon: "fa-dot-circle", path: "/viewClass", RouteName: "Student by class" },
];
const Canteen = [
  {
    icon: "fa-dot-circle",
    path: "/dashboard/AddCanteen",
    RouteName: "Add Canteen",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/ViewCanteen",
    RouteName: "View Canteen",
  },
];

const GM = [
  {
    icon: "fa-dot-circle",
    path: "/dashboard/AddGM",
    RouteName: "Add General Manager",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/ViewGm",
    RouteName: "View General Manager",
  },
];
const Campus = [
  {
    icon: "fa-dot-circle",
    path: "/dashboard/AddCampus",
    RouteName: "Add Campus",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/ViewCampus",
    RouteName: "View Campus",
  },
];
const Inventory = [
  {
    icon: "fa-dot-circle",
    path: "/dashboard/AddCategory",
    RouteName: "Add Category",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/ViewCategory",
    RouteName: "View Category",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/AddItems",
    RouteName: "Add Items",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/ViewItems",
    RouteName: "View Items",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/Supplier",
    RouteName: "Add Supplier",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/ViewSupplier",
    RouteName: "View Supplier",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/AddStock",
    RouteName: "Add Stock",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/ViewStock",
    RouteName: "View Stock",
  },
];
function getDataWithChangeIcon(iconName, routes) {
  let getNewData = routes.map((item) => {
    return { ...item, icon: iconName };
  });
  return getNewData;
}
// end of owner and admin routes
const Admission_Links_one = [
  {
    icon: "fa-dot-circle",
    path: "/dashboard/StudentAdmissionDashboard",
    RouteName: "Student Admission",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/EntryExamDashboard",
    RouteName: "Entry Exam",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/ParentPortalManagerDashboard",
    RouteName: "Parent Registration",
  },
];
const Entry_Links_Exam = [
  {
    icon: "fa-dot-circle",
    path: "/dashboard/AddEntryExam",
    RouteName: "Add Candidate",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/ViewEntryExam",
    RouteName: "View Candidate",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/CMSManagerDashboard",
    RouteName: "CMS",
  },
];
const FrontDesk_Staff = [
  {
    icon: "fa-dot-circle",
    path: "/dashboard/StaffEnquiry",
    RouteName: "Add Online Registration Form",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/ViewStaffEnquiryPagination",
    RouteName: "View Online Registration Form",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/ViewAdmittedStaff",
    RouteName: "View Admitted Staff",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/CMSManagerDashboard",
    RouteName: "CMS",
  },
];
const Timetable_Link = [
  {
    icon: "fa-dot-circle",
    path: "/dashboard/timetable",
    RouteName: "Timetable",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/viewTimetable",
    RouteName: "Timetable View",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/LessonperweekScreen",
    RouteName: "Lesson Per Week",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/cutoffPost",
    RouteName: "Cut Off",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/CutOffView",
    RouteName: "Cut Off Detail",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/SubstitutionPeriodList",
    RouteName: "Subsitution Period",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/ApplySubstitution",
    RouteName: "Apply Subsitution",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/SubstitutionPeriority",
    RouteName: "Subsitution Priority",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/SubstitutionPeriodList",
    RouteName: "Subsitution Priority Detail",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/allPriorityList",
    RouteName: "All Priority",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/DailySubstitutionRecord",
    RouteName: "Daily Subsitution Record",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/StaffTimeTableByCoordinator",
    RouteName: "Staff Timetable",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/ConsolidatedTimeTable",
    RouteName: "Consolidated Timetable",
  },
  {
    icon: "fa-dot-circle",
    path: "/dashboard/CMSManagerDashboard",
    RouteName: "CMS",
  },
];
const mainLinks_Owner = [
  {
    icon: "fa-check",
    parentName: "Finance",
    ChildArray: getDataWithChangeIcon("fa-dot-circle", financeLinks),
  },
  {
    icon: "fa-check",
    parentName: "Student",
    ChildArray: Admission_Links_one,
  },
  {
    icon: "fa-check",
    parentName: "Library",
    ChildArray: Library_Link,
  },
  {
    icon: "fa-check",
    parentName: "HR",
    ChildArray: hrLinks,
  },
  {
    icon: "fa-check",
    parentName: "Administration",
    ChildArray: Administration,
  },
  {
    icon: "fa-check",
    parentName: "Communication",
    ChildArray: Communication_links,
  },
  {
    icon: "fa-check",
    parentName: "Pastoral",
    ChildArray: Pastoral_Smart,
  },

  {
    icon: "fa-check",
    parentName: "Admin",
    ChildArray: Administration1,
  },
  {
    icon: "fa-check",
    parentName: "Manager",
    ChildArray: Managers_Link,
  },
  {
    icon: "fa-check",
    parentName: "Academic",
    ChildArray: AcadmicsLinks,
  },
  {
    icon: "fa-check",
    parentName: "Time Table",
    ChildArray: TimeTableSmartLinks,
  },
  {
    icon: "fa-check",
    parentName: "System",
    ChildArray: SystemSmartLinks,
  },
];

const mainLinks_GM = [
  {
    icon: "fas fa-user-shield",
    parentName: "Admin",
    ChildArray: Administration,
  },
  {
    icon: "fa-info-circle",
    parentName: "Census Information",
    ChildArray: CensusInformation,
  },
  { icon: "fa-bus", parentName: "Transport", ChildArray: Transport },
  { icon: "fa-user-check", parentName: "HR Admin", ChildArray: HRAdmin },
  { icon: "fa-male", parentName: "Front Desk Officer ", ChildArray: Officer },
  {
    icon: "fas fa-user-graduate",
    parentName: "Student Record Management",
    ChildArray: Management,
  },
  {
    icon: "fas fa-th-list",
    parentName: "More",
    ChildArray: AOSingleRoutes,
  },
];

const mainLinks_Admin = [
  {
    icon: "fas fa-user-shield",
    parentName: "Admin",
    ChildArray: Administration,
  },
  {
    icon: "fa-info-circle",
    parentName: "Census Information",
    ChildArray: CensusInformation,
  },
  { icon: "fa-bus", parentName: "Transport", ChildArray: Transport },
  { icon: "fa-user-check", parentName: "HR Admin", ChildArray: HRAdmin },
  { icon: "fa-male", parentName: "Front Desk Officer ", ChildArray: Officer },
  {
    icon: "fas fa-user-graduate",
    parentName: "Student Record Management",
    ChildArray: Management,
  },
  {
    icon: "fas fa-th-list",
    parentName: "More",
    ChildArray: AOSingleRoutes,
  },
];

class Head extends Component {
  static contextType = ThemeContext;

  constructor(props) {
    super(props);
    this.state = {
      // role: this.props.location.state.role,
      isMessageLoading: false,
      inboxMessage: "",
    };

    if (!this.props.auth) {
      this.props.history.push("/");
    }
  }

  onLogout = () => {
    console.log("logout now");
    this.props.logoutNow.logout();
  };

  // openAndClose = () => {
  //   $(document).ready(function () {
  //     $('#sidebarCollapse').on('click', function () {
  //       console.log('working');
  //       $('#sidebar').toggleClass('active');
  //     });
  //   });
  // }

  componentDidMount() {
    $(document).ready(function () {
      $(".goog-te-combo").addClass("form-control");

      $("#sidebarCollapse").on("click", function () {
        $("#sidebar").toggleClass("active");
      });
    });
  }
  logout = () => {
    axios
      .put(`${config.localhttp}/logout/confirm`, {
        isOnline: false,
        _id: this.props._id,
      })
      .then((success) => {
        Promise.all([
          this.props.logoutNow.logout(),
          this.props.history.push("/"),
        ])
          .then((success) => {
            console.log(success);
          })
          .catch((err) => {
            console.log(err);
          });
        console.log("logout confirmation: ", success);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  goBack = () => {
    this.props.history.goBack();
  };

  getMessagee = (id, context) => {
    this.setState({
      isMessageLoading: true,
      inboxMessage: "",
    });
    this.props.sharedActions
      .getDataWithoutDispatch(`/api/View${context.account}Msg/?id=${id}`)
      .then((success) => {
        this.setState({
          inboxMessage: success,
          isMessageLoading: false,
        });
        context.setUpdate(id);
      })
      .catch((error) => {
        this.setState({
          inboxMessage: "",
          isMessageLoading: false,
        });
      });
  };

  getMessageeParentTeacher = (id, context) => {
    this.setState({
      isMessageLoading: true,
      inboxMessage: "",
    });
    this.props.sharedActions
      .getDataWithoutDispatch(`/api/${context.account}ViewMessage/?id=${id}`)
      .then((success) => {
        this.setState({
          inboxMessage: success,
          isMessageLoading: false,
        });
        context.setUpdate(id);
      })
      .catch((error) => {
        this.setState({
          inboxMessage: "",
          isMessageLoading: false,
        });
      });
  };

  RenderSingleLinksOrDoubleLinks = (
    icon,
    path,
    RouteName,
    childIconMargin = 4,
    index,
    type = "single",
    letterSpacing = 0,
    parentIconName = "fa-check",
    ParentName = "Default parent name",
    ...args
  ) => {
    return (
      <React.Fragment key={index}>
        {type === "single" ? (
          <ThemeContext.Consumer>
            {(context) => (
              <React.Fragment>
                <li
                  key={index}
                  style={{
                    background: "#f2f2f2",
                    color: context.themeColors.primaryColor,
                  }}
                >
                  <Link
                    key={index}
                    to={`${path}`}
                    style={{
                      background: "#f2f2f2",
                      color: context.themeColors.primaryColor,
                    }}
                  >
                    <i style={{ marginRight: 6 }} className={`fas ${icon}`} />
                    {RouteName}
                  </Link>
                </li>
              </React.Fragment>
            )}
          </ThemeContext.Consumer>
        ) : (
          <ThemeContext.Consumer>
            {(context) => (
              <React.Fragment>
                <a
                  href={`#homeSubmenu${index}`}
                  data-toggle="collapse"
                  style={{
                    background: "#f2f2f2",
                    color: context.themeColors.primaryColor,
                  }}
                  aria-expanded="false"
                  class="dropdown-toggle"
                >
                  {""}
                  <i
                    style={{ marginRight: childIconMargin }}
                    className={`fas ${parentIconName}`}
                  />
                  <span style={{ letterSpacing: letterSpacing }}>
                    {ParentName}
                  </span>
                </a>
                <ul class="collapse list-unstyled" id={`homeSubmenu${index}`}>
                  <li
                    style={{
                      background: "#f2f2f2",
                      color: context.themeColors.primaryColor,
                    }}
                  >
                    {args[0].map((item, index) => (
                      <Link
                        style={{
                          background: "#f2f2f2",
                          color: context.themeColors.primaryColor,
                        }}
                        key={index}
                        to={`${item.path}`}
                      >
                        <i
                          style={{ marginRight: childIconMargin }}
                          className={`fas ${item.icon}`}
                        />
                        {item.RouteName}
                      </Link>
                    ))}
                  </li>
                </ul>
              </React.Fragment>
            )}
          </ThemeContext.Consumer>
        )}
      </React.Fragment>
    );
  };
  RenderSingleLinks = (icon, path, RouteName, childIconMargin = 4, index) => {
    console.log(this.props.role.toLowerCase());
    console.log("Aya");
    return (
      <React.Fragment key={index}>
        <ThemeContext.Consumer>
          {(context) => (
            <li key={index}>
              <Link
                style={{ color: context.themeColors.primaryColor }}
                key={index}
                to={`${path}`}
              >
                <i style={{ marginRight: 6 }} className={`fas ${icon}`} />
                {RouteName}
              </Link>
            </li>
          )}
        </ThemeContext.Consumer>
      </React.Fragment>
    );
  };

  RenderLinksOwner = (
    parentIconName,
    ParentName,
    childIconMargin = 4,
    index,
    ...args
  ) => {
    return (
      <React.Fragment key={index}>
        {/* <img
					src={require('../Assets/icons/DashboardIcon/icon and heading upper csae 96 by 96.svg')}
					style={{ width: 180, height: 50 }}
				/> */}
        <ThemeContext.Consumer>
          {(context) => (
            <a
              style={{
                background: "#f2f2f2",
                color: context.themeColors.primaryColor,
              }}
              href={`#homeSubmenu${index}`}
              data-toggle="collapse"
              aria-expanded="false"
              class="dropdown-toggle"
            >
              {/* <i
                style={{ marginRight: childIconMargin }}
                className={`fas ${parentIconName}`}
              /> */}
              {ParentName + " "}
              <img
                src={
                  (context.themeColors.primaryColor == "#a75a8d" && Purple) ||
                  (context.themeColors.primaryColor == "#93d8dd" && Cyan) ||
                  (context.themeColors.primaryColor == "#f2805c" && Orange) ||
                  (context.themeColors.primaryColor == "#01AC8A" && Blue) ||
                  Blue
                }
                style={{ height: 20, width: 20 }}
              />
              {"mart"}
            </a>
          )}
        </ThemeContext.Consumer>
        <ThemeContext.Consumer>
          {(context) => (
            <ul class="collapse list-unstyled" id={`homeSubmenu${index}`}>
              <li style={{ background: "#f2f2f2" }}>
                {args[0].map((item, index) => (
                  <Link
                    style={{
                      background: "#f2f2f2",
                      color: context.themeColors.primaryColor,
                    }}
                    key={index}
                    to={`${item.path}`}
                  >
                    <i
                      style={{ marginRight: childIconMargin }}
                      className={`fas ${item.icon}`}
                    />
                    {item.RouteName}
                  </Link>
                ))}
              </li>
            </ul>
          )}
        </ThemeContext.Consumer>
      </React.Fragment>
    );
  };
  RenderLinks = (
    parentIconName,
    ParentName,
    childIconMargin = 4,
    index,
    ...args
  ) => {
    return (
      <React.Fragment key={index}>
        {/* <img
					src={require('../Assets/icons/DashboardIcon/icon and heading upper csae 96 by 96.svg')}
					style={{ width: 180, height: 50 }}
				/> */}
        <ThemeContext.Consumer>
          {(context) => (
            <a
              style={{
                background: "#f2f2f2",
                color: context.themeColors.primaryColor,
              }}
              href={`#homeSubmenu${index}`}
              data-toggle="collapse"
              aria-expanded="false"
              class="dropdown-toggle"
            >
              {""}
              <i
                style={{ marginRight: childIconMargin }}
                className={`fas ${parentIconName}`}
              />

              {ParentName}
            </a>
          )}
        </ThemeContext.Consumer>
        <ThemeContext.Consumer>
          {(context) => (
            <ul class="collapse list-unstyled" id={`homeSubmenu${index}`}>
              <li style={{ background: "#f2f2f2" }}>
                {args[0].map((item, index) => (
                  <Link
                    style={{
                      background: "#f2f2f2",
                      color: context.themeColors.primaryColor,
                    }}
                    key={index}
                    to={`${item.path}`}
                  >
                    <i
                      style={{ marginRight: childIconMargin }}
                      className={`fas ${item.icon}`}
                    />
                    {item.RouteName}
                  </Link>
                ))}
              </li>
            </ul>
          )}
        </ThemeContext.Consumer>
      </React.Fragment>
    );
  };
  render() {
    const { inboxMessage } = this.state;
    let value = this.context;
    console.log(value);
    console.log(this.props);
    let conditionalLinks = (
      <Nav>
        <Nav.Link
          style={{ fontSize: 18, color: "black" }}
          as={Link}
          to="/signup"
        >
          Sign Up {"|"}
        </Nav.Link>
        <Nav.Link style={{ fontSize: 18, color: "black" }} as={Link} to="/">
          Login
        </Nav.Link>
      </Nav>
    );
    if (!this.props.auth) {
      return <Redirect to="/" />;
    }
    let renderUserRole = Object.is(this.props.role.toLowerCase(), "Admin")
      ? mainLinks_Admin
      : Object.is(this.props.role.toLowerCase(), RoleTypes.STUDENT_SMART)
      ? studentLinks
      : Object.is(this.props.role.toLowerCase(), RoleTypes.STAFF_SMART)
      ? staffLinks
      : Object.is(this.props.role.toLowerCase(), RoleTypes.PARENT_SMART)
      ? parentLinks
      : Object.is(this.props.role.toLowerCase().toLowerCase(), RoleTypes.OWNER)
      ? mainLinks_Owner
      : Object.is(this.props.role.toLowerCase(), RoleTypes.ACADMIC_SMART)
      ? AcadmicsLinks
      : Object.is(this.props.role.toLowerCase(), RoleTypes.FINANCE_SMART)
      ? financeLinks
      : Object.is(this.props.role.toLowerCase(), RoleTypes.HR_SMART)
      ? hrLinks
      : Object.is(this.props.role.toLowerCase(), RoleTypes.COMMUNICATION_SMART)
      ? Communication_links
      : Object.is(this.props.role.toLowerCase(), RoleTypes.HOD_SMART)
      ? Hod_links
      : Object.is(this.props.role.toLowerCase(), RoleTypes.PHYSICS_HOD)
      ? Hod_links
      : Object.is(this.props.role.toLowerCase(), RoleTypes.MATHEMATICS_HOD)
      ? Hod_links
      : Object.is(this.props.role.toLowerCase(), RoleTypes.CHEMISTRY_HOD)
      ? Hod_links
      : Object.is(this.props.role.toLowerCase(), RoleTypes.BIOLOGY_HOD)
      ? Hod_links
      : Object.is(this.props.role.toLowerCase(), RoleTypes.GENERAL_SCIENCE_HOD)
      ? Hod_links
      : Object.is(this.props.role.toLowerCase(), RoleTypes.ENGLISH_HOD)
      ? Hod_links
      : Object.is(this.props.role.toLowerCase(), RoleTypes.FRENCHCHINESE_HOD)
      ? Hod_links
      : Object.is(this.props.role.toLowerCase(), RoleTypes.ICT_HOD)
      ? Hod_links
      : Object.is(
          this.props.role.toLowerCase(),
          RoleTypes.BUSINESS_ACCOUNTS_HOD
        )
      ? Hod_links
      : Object.is(this.props.role.toLowerCase(), RoleTypes.ARABIC_HOD)
      ? Hod_links
      : Object.is(this.props.role.toLowerCase(), RoleTypes.ISLAMIC_HOD)
      ? Hod_links
      : Object.is(this.props.role.toLowerCase(), RoleTypes.SOCIAL_HOD)
      ? Hod_links
      : Object.is(this.props.role.toLowerCase(), RoleTypes.MUSIC_HOD)
      ? Hod_links
      : Object.is(this.props.role.toLowerCase(), RoleTypes.ARTS_HOD)
      ? Hod_links
      : Object.is(this.props.role.toLowerCase(), RoleTypes.SPORTS_HOD)
      ? Hod_links
      : Object.is(this.props.role.toLowerCase(), RoleTypes.DESIGN_DRAMA_HOD)
      ? Hod_links
      : Object.is(
          this.props.role.toLowerCase(),
          RoleTypes.GENERAL_MANAGER_SMART
        )
      ? mainLinks_Owner
      : Object.is(this.props.role.toLowerCase(), RoleTypes.ADMISSION_SMART)
      ? Admission_Links
      : Object.is(this.props.role.toLowerCase(), RoleTypes.INVENTORY_MANAGER)
      ? InventoryLinks
      : Object.is(this.props.role.toLowerCase(), RoleTypes.LIBRARY_MANAGER)
      ? Library_Link
      : Object.is(this.props.role.toLowerCase(), RoleTypes.ADMINISTRATION_SMART)
      ? Administration
      : Object.is(this.props.role.toLowerCase(), RoleTypes.PASTORAL_SMART)
      ? Pastoral_Links
      : Object.is(this.props.role.toLowerCase(), RoleTypes.SECURITY_MANAGER)
      ? sm_Links
      : Object.is(this.props.role.toLowerCase(), RoleTypes.PROCUREMENT_MANAGER)
      ? pm_Links
      : Object.is(this.props.role.toLowerCase(), RoleTypes.STUDENT_COORDINATOR)
      ? Admission_Link
      : Object.is(
          this.props.role.toLowerCase(),
          RoleTypes.ENTRY_EXAM_COORDINATOR
        )
      ? Entry_Links_Exam
      : Object.is(
          this.props.role.toLowerCase(),
          RoleTypes.REGISTRATION_COORDINATOR
        )
      ? Student_Coordinator_Links
      : Object.is(
          this.props.role.toLowerCase(),
          RoleTypes.TRANSPORT_COORDINATOR
        )
      ? TRANSPORT_COORDINATOR_Links
      : Object.is(this.props.role.toLowerCase(), RoleTypes.BILLING_MANAGER)
      ? Billing_Link
      : Object.is(this.props.role.toLowerCase(), RoleTypes.NEWS_COORDINATOR)
      ? News_Link
      : Object.is(
          this.props.role.toLowerCase(),
          RoleTypes.TIMETABLE_COORDINATOR
        )
      ? Timetable_Link
      : Object.is(this.props.role.toLowerCase(), RoleTypes.Grade_Coordinator)
      ? Grade_Coordinator_Links
      : Object.is(this.props.role.toLowerCase(), RoleTypes.STAFF_COORDINATOR)
      ? Staff_Coordinator_Links
      : Object.is(this.props.role.toLowerCase(), RoleTypes.DOCTOR_MANAGER)
      ? Doctor_Links
      : Object.is(this.props.role.toLowerCase(), RoleTypes.MEDICAL_STAFF)
      ? Medical_Staff_Links
      : Object.is(
          this.props.role.toLowerCase(),
          RoleTypes.EXCLUSION_COORDINATOR
        )
      ? Exclusion_Manager_Links
      : // : Object.is(this.props.role.toLowerCase(), RoleTypes.ADMISSION_MANAGER)
      // ? Administration
      Object.is(this.props.role.toLowerCase(), RoleTypes.WELLBEING_COORDINATOR)
      ? Wellbeing_Manager_Links
      : Object.is(this.props.role.toLowerCase(), RoleTypes.PASTORAL_SMART)
      ? Pastoral_Links
      : Object.is(this.props.role.toLowerCase(), RoleTypes.SECURITY_MANAGER)
      ? sm_Links
      : Object.is(this.props.role.toLowerCase(), RoleTypes.PROCUREMENT_MANAGER)
      ? pm_Links
      : Object.is(this.props.role.toLowerCase(), RoleTypes.ACTIVITY_COORDINATOR)
      ? Activity_Manager_Links
      : Object.is(this.props.role.toLowerCase(), RoleTypes.SEN)
      ? SEN_Manager_Links
      : Object.is(
          this.props.role.toLowerCase(),
          RoleTypes.COMMUNICATION_COORDINATOR
        )
      ? Communication_Coordinator_Links
      : Object.is(this.props.role.toLowerCase(), RoleTypes.PORTAL_COORDINATOR)
      ? Portal_Coordinator_Links
      : Object.is(this.props.role.toLowerCase(), RoleTypes.FRONT_DESK)
      ? FrontDesk_Staff
      : Object.is(this.props.role.toLowerCase(), RoleTypes.ACCOUNTANT_MANAGER)
      ? Accountant_Link
      : Object.is(this.props.role.toLowerCase(), RoleTypes.STAFF_FORM_MANAGER)
      ? StaffFormManagerLinks
      : Object.is(this.props.role.toLowerCase(), RoleTypes.STUDENT_FORM_MANAGER)
      ? StudentFormManagerLinks
      : Object.is(
          this.props.role.toLowerCase(),
          RoleTypes.MAINTENANCE_COORDINATOR
        )
      ? maintenance_coordinator_links
      : Object.is(this.props.role.toLowerCase(), RoleTypes.DEAN_ARABIC)
      ? CMS_Link
      
      : Object.is(this.props.role.toLowerCase(), RoleTypes.DEAN_ENGLISH)
      ? CMS_Link
     
      : Object.is(this.props.role.toLowerCase(), RoleTypes.DEAN_ACTIVITIES)
      ? CMS_Link
      : // : Object.is(this.props.role.toLowerCase(), RoleTypes.ADMISSION_MANAGER)
        // ? Administration
        "";
    let renderSubUserRole = Object.is(this.props.role, "Admin")
      ? AOSingleRoutes
      : Object.is(this.props.role, RoleTypes.STUDENT_SMART)
      ? AOSingleRoutes
      : Object.is(this.props.role, RoleTypes.STAFF_SMART)
      ? Staff
      : Object.is(this.props.role, RoleTypes.PARENT_SMART)
      ? AOSingleRoutes
      : Object.is(this.props.role, "Teacher")
      ? AOSingleRoutes
      : Object.is(this.props.role.toLowerCase(), RoleTypes.OWNER)
      ? AOSingleRoutes
      : Object.is(this.props.role, "GeneralManager")
      ? AOSingleRoutes
      : "";
    console.log(this.props.match);

    const messageVieww = (
      <ThemeContext.Consumer>
        {(context) => (
          <div class="row">
            <div class="col-md-12 form-inline">
              <div class="col-md-2">
                <strong>From</strong>
              </div>
              <div class="col-md-10 border-bottom">
                <strong
                  style={{
                    backgroundColor: "#bbb",
                  }}
                >
                  {inboxMessage.name}
                </strong>
              </div>
            </div>

            <br />

            <div class="col-md-12 form-inline" style={{ marginTop: "20px" }}>
              <div class="col-md-2">
                <strong>Date & Time</strong>
              </div>
              <div class="col-md-10 border-bottom">
                <strong
                  style={{
                    backgroundColor: "#bbb",
                  }}
                >
                  {inboxMessage.date}
                </strong>
                {"     "}
                <strong
                  style={{
                    backgroundColor: "#bbb",
                    marginLeft: "10px",
                  }}
                >
                  {" "}
                  {inboxMessage.time}{" "}
                </strong>
              </div>
            </div>

            <div class="col-md-12 form-inline" style={{ marginTop: "20px" }}>
              <div class="col-md-12 text-center border-bottom">
                <h4
                  className="h1-responsive font-weight-bold my-4 generalHead"
                  style={{ background: context.themeColors.primaryColor }}
                >
                  <strong
                    style={{
                      color: "#fff",
                    }}
                  >
                    MESSAGE
                  </strong>
                  {"     "}
                </h4>
              </div>
            </div>

            <div class="col-md-12 form-inline" style={{ marginTop: "20px" }}>
              <div class="col-md-12 text-center border-bottom">
                <div class="col-md-12 ">
                  <TextArea
                    row={15}
                    readOnly={true}
                    customWidth={true}
                    enterWidth={"220%"}
                    feildName={"message"}
                    errors={{}}
                    stateData={inboxMessage}
                    // iconClassName={'fas fa-percentage'}
                    placeholder={"Message"}
                    isValidte={false}
                  />
                </div>
                <br />
                <br />
              </div>
              <br />
              <br />
            </div>
          </div>
        )}
      </ThemeContext.Consumer>
    );

    return (
      <div>
        <ThemeContext.Consumer>
          {(context) => (
            <div class="modal" id="messageDialogModal">
              <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content modal_content_custom">
                  <div
                    style={{
                      borderBottomLeftRadius: 30,
                      borderBottomRightRadius: 30,
                      borderTopLeftRadius: 18,
                      borderTopRightRadius: 18,
                      background: context.themeColors.primaryColor,
                    }}
                    class="modal-header modal_custom_header"
                  >
                    <h4 style={{ color: "white" }} class="modal-title">
                      Message
                    </h4>
                    <button
                      style={{ color: "white" }}
                      type="button"
                      class="close"
                      data-dismiss="modal"
                    >
                      &times;
                    </button>
                  </div>
                  <div class="modal-body">
                    <div>
                      <div>
                        {this.state.isMessageLoading ? (
                          <Spinner />
                        ) : (
                          messageVieww
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </ThemeContext.Consumer>
        <div class="wrapper">
          {/* <!-- Sidebar  --> */}
          <ThemeContext.Consumer>
            {(context) => (
              <nav
                className="displayElemet"
                id="sidebar"
                style={{ background: "#f2f2f2" }}
              >
                <ThemeContext.Consumer>
                  {(context) => (
                    <div
                      style={{ background: "#f2f2f2" }}
                      class="sidebar-header"
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <img id="logimg" height={50} src={logo} alt="#logo" />
                      </div>
                      <div style={{ textAlign: "center", fontSize: 30 }}>
                        {/* <i
                          class={`fas  ${
                            Object.is(this.props.role.toLowerCase(), "admin")
                              ? "fa-user-shield"
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.STUDENT_SMART
                                )
                              ? "fa-user-tag"
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.STAFF_SMART
                                )
                              ? "fa-users"
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.PARENT_SMART
                                )
                              ? "fa-restroom"
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  "teacher"
                                )
                              ? "fa-chalkboard-teacher"
                              : Object.is(
                                  this.props.role.toLowerCase().toLowerCase(),
                                  RoleTypes.OWNER
                                )
                              ? "fa-user-tie"
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.GENERAL_MANAGER_SMART
                                )
                              ? "fa-user-tie"
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.ACADMIC_SMART
                                )
                              ? "fa-user-tie"
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.FINANCE_SMART
                                )
                              ? "fa-user-tie"
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.HR_SMART
                                )
                              ? "fa-user-tie"
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.HOD_SMART
                                )
                              ? "fa-user-cog"
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.COMMUNICATION_SMART
                                )
                              ? "fa-envelope"
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.ADMISSION_SMART
                                )
                              ? "fa-ad"
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.ADMINISTRATION_SMART
                                )
                              ? "fa-ad"
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.ADMISSION_MANAGER
                                )
                              ? "fa-ad"
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.ACCOUNTANT_MANAGER
                                )
                              ? "fa-ad"
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.BILLING_MANAGER
                                )
                              ? "fa-ad"
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.ENTRY_EXAM_COORDINATOR
                                )
                              ? "fa-user-cog"
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.PASTORAL_SMART
                                )
                              ? "fa-ad"
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.SECURITY_MANAGER
                                )
                              ? "fa-user-secret"
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.PROCUREMENT_MANAGER
                                )
                              ? "fa-ad"
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.STUDENT_COORDINATOR
                                )
                              ? "fa-user-tie"
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.TRANSPORT_COORDINATOR
                                )
                              ? "fa-user-tie"
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.STAFF_COORDINATOR
                                )
                              ? "fa-user-tie"
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.MEDICAL_STAFF
                                )
                              ? "fa-medkit"
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.EXCLUSION_MANAGER
                                )
                              ? "fa-user-tie"
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.WELLBEING_COORDINATOR
                                )
                              ? "fa-user-cog"
                              : ""
                          }`} */}
                        {/* /> */}
                      </div>
                    </div>
                  )}
                </ThemeContext.Consumer>
                <ThemeContext.Consumer>
                  {(context) => (
                    <ul
                      style={{
                        background: "#f2f2f2",
                        borderRadius: 0,
                        margin: 3,
                      }}
                      class="list-unstyled components"
                    >
                      <div
                        style={{
                          textAlign: "center",
                          // borderBottom: "2px solid #ffff"/,
                        }}
                      >
                        <div
                          className="displayElemet"
                          id="google_translate_element"
                        ></div>
                        <div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              margin: 5,
                              padding: 5,
                              marginLeft: 22,
                              borderRadius: 10,
                              background: '"#f2f2f2"',
                            }}
                          >
                            <ThemeContext.Consumer>
                              {(context) => (
                                <div
                                  className="doubleCircularDiv2 ripple"
                                  onClick={() => {
                                    context.setThemeColor({
                                      ...context.themeColors,
                                      primaryColor: Colors.SECOND_PRIMARY_COLOR,
                                      secondryColor:
                                        Colors.SECOND_PRIMARY_COLOR_TWO,
                                    });
                                  }}
                                  style={{
                                    height: 30,
                                    width: 30,
                                    backgroundColor: Colors.LIGHT_GREEN,
                                    borderRadius: "50%",
                                    marginLeft: 10,
                                  }}
                                ></div>
                              )}
                            </ThemeContext.Consumer>
                            <div style={{ width: 5 }} />

                            <ThemeContext.Consumer>
                              {(context) => (
                                <div
                                  className="doubleCircularDiv4 ripple"
                                  onClick={() =>
                                    context.setThemeColor({
                                      ...context.themeColors,
                                      primaryColor: Colors.FOURTH_PRIMARY_COLOR,
                                      secondryColor:
                                        Colors.FOURTH_PRIMARY_COLOR_TWO,
                                    })
                                  }
                                  style={{
                                    height: 30,
                                    width: 30,
                                    backgroundColor: Colors.DARK_GREY,
                                    borderRadius: "50%",
                                    marginLeft: 10,
                                  }}
                                ></div>
                              )}
                            </ThemeContext.Consumer>
                            <div style={{ width: 5 }} />
                            <ThemeContext.Consumer>
                              {(context) => (
                                <div
                                  className="doubleCircularDiv ripple"
                                  onClick={() =>
                                    context.setThemeColor({
                                      ...context.themeColors,
                                      primaryColor: Colors.SEA_GREEN_THEME,
                                      secondryColor:
                                        Colors.DARK_SEA_GREEN_THEME,
                                    })
                                  }
                                  style={{
                                    height: 30,
                                    width: 30,
                                    backgroundColor: Colors.DARK_GREY,
                                    borderRadius: "50%",
                                    marginLeft: 10,
                                  }}
                                ></div>
                              )}
                            </ThemeContext.Consumer>
                            {/* <div style={{ width: 5 }} /> */}
                            <div style={{ width: 5 }} />
                            <ThemeContext.Consumer>
                              {(context) => (
                                <div
                                  className="doubleCircularDiv3 ripple"
                                  onClick={() =>
                                    context.setThemeColor({
                                      ...context.themeColors,
                                      primaryColor: Colors.THIRD_PRIMARY_COLOR,
                                      secondryColor:
                                        Colors.THIRD_PRIMARY_COLOR_TWO,
                                    })
                                  }
                                  style={{
                                    height: 30,
                                    width: 30,
                                    backgroundColor: Colors.orange_theme,
                                    borderRadius: "50%",
                                    marginLeft: 10,
                                  }}
                                ></div>
                              )}
                            </ThemeContext.Consumer>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              margin: 5,
                              padding: 5,
                              marginLeft: 22,
                              borderRadius: 10,
                              background: '"#f2f2f2"',
                            }}
                          >
                            <ThemeContext.Consumer>
                              {(context) => (
                                <div
                                  className=""
                                  onClick={() => {
                                    context.setThemeColor({
                                      ...context.themeColors,
                                      primaryColor: Colors.DARK_BLUE,
                                      secondryColor:
                                        Colors.DARK_BLUE,
                                    });
                                  }}
                                  style={{
                                    height: 30,
                                    width: 30,
                                    backgroundColor: Colors.DARK_BLUE,
                                    borderRadius: "50%",
                                    marginLeft: 10,
                                  }}
                                ></div>
                              )}
                            </ThemeContext.Consumer>
                            <div style={{ width: 5 }} />

                            <ThemeContext.Consumer>
                              {(context) => (
                                <div
                                  className=""
                                  onClick={() =>
                                    context.setThemeColor({
                                      ...context.themeColors,
                                      primaryColor: Colors.PURPLE,
                                      secondryColor:
                                        Colors.PURPLE,
                                    })
                                  }
                                  style={{
                                    height: 30,
                                    width: 30,
                                    backgroundColor: Colors.PURPLE,
                                    borderRadius: "50%",
                                    marginLeft: 10,
                                  }}
                                ></div>
                              )}
                            </ThemeContext.Consumer>
                            <div style={{ width: 5 }} />
                            <ThemeContext.Consumer>
                              {(context) => (
                                <div
                                  className=""
                                  onClick={() =>
                                    context.setThemeColor({
                                      ...context.themeColors,
                                      primaryColor: Colors.RED,
                                      secondryColor:
                                        Colors.RED,
                                    })
                                  }
                                  style={{
                                    height: 30,
                                    width: 30,
                                    backgroundColor: Colors.RED,
                                    borderRadius: "50%",
                                    marginLeft: 10,
                                  }}
                                ></div>
                              )}
                            </ThemeContext.Consumer>
                            {/* <div style={{ width: 5 }} /> */}
                            <div style={{ width: 5 }} />
                            <ThemeContext.Consumer>
                              {(context) => (
                                <div
                                  className=""
                                  onClick={() =>
                                    context.setThemeColor({
                                      ...context.themeColors,
                                      primaryColor: Colors.DARK_GREEN,
                                      secondryColor:
                                        Colors.DARK_GREEN,
                                    })
                                  }
                                  style={{
                                    height: 30,
                                    width: 30,
                                    backgroundColor: Colors.DARK_GREEN,
                                    borderRadius: "50%",
                                    marginLeft: 10,
                                  }}
                                ></div>
                              )}
                            </ThemeContext.Consumer>
                          </div>
                        </div>
                        {/* <h3>
                          {Object.is(this.props.role, "admin")
                            ? "Admin Menu"
                            : Object.is(
                                this.props.role.toLowerCase(),
                                RoleTypes.STUDENT_SMART
                              )
                            ? this.props.name
                            : Object.is(
                                this.props.role.toLowerCase(),
                                RoleTypes.STAFF_SMART
                              )
                            ? this.props.name
                            : Object.is(
                                this.props.role.toLowerCase(),
                                RoleTypes.HR_SMART
                              )
                            ? this.props.name
                            : Object.is(
                                this.props.role.toLowerCase(),
                                RoleTypes.PARENT_SMART
                              )
                            ? this.props.name.toUpperCase()
                            : Object.is(
                                this.props.role.toLowerCase(),
                                "teacher"
                              )
                            ? "Teacher Menu"
                            : Object.is(
                                this.props.role.toLowerCase().toLowerCase(),
                                RoleTypes.OWNER
                              )
                            ? this.props.name
                            : Object.is(
                                this.props.role.toLowerCase(),
                                RoleTypes.GENERAL_MANAGER_SMART
                              )
                            ? this.props.name
                            : Object.is(
                                this.props.role.toLowerCase(),
                                RoleTypes.ACADMIC_SMART
                              )
                            ? this.props.name
                            : Object.is(
                                this.props.role.toLowerCase(),
                                RoleTypes.FINANCE_SMART
                              )
                            ? this.props.name
                            : Object.is(
                                this.props.role.toLowerCase(),
                                RoleTypes.COMMUNICATION_SMART
                              )
                            ? this.props.name
                            : Object.is(
                                this.props.role.toLowerCase(),
                                RoleTypes.HOD_SMART
                              )
                            ? this.props.name
                            : Object.is(
                                this.props.role.toLowerCase(),
                                RoleTypes.ADMISSION_SMART
                              )
                            ? this.props.name
                            : Object.is(
                                this.props.role.toLowerCase(),
                                RoleTypes.ADMINISTRATION_SMART
                              )
                            ? this.props.name
                            : Object.is(
                                this.props.role.toLowerCase(),
                                RoleTypes.ADMISSION_MANAGER
                              )
                            ? this.props.name
                            : Object.is(
                                this.props.role.toLowerCase(),
                                RoleTypes.ACCOUNTANT_MANAGER
                              )
                            ? this.props.name
                            : Object.is(
                                this.props.role.toLowerCase(),
                                RoleTypes.BILLING_MANAGER
                              )
                            ? this.props.name
                            : Object.is(
                                this.props.role.toLowerCase(),
                                RoleTypes.ENTRY_EXAM_COORDINATOR
                              )
                            ? this.props.name
                            : Object.is(
                                this.props.role.toLowerCase(),
                                RoleTypes.REGISTRATION_COORDINATOR
                              )
                            ? this.props.name
                            : Object.is(
                                this.props.role.toLowerCase(),
                                RoleTypes.WELLBEING_COORDINATOR
                              )
                            ? this.props.name
                            : Object.is(
                                this.props.role.toLowerCase(),
                                RoleTypes.ACTIVITY_COORDINATOR
                              )
                            ? this.props.name
                            : Object.is(
                                this.props.role.toLowerCase(),
                                RoleTypes.STAFF_COORDINATOR
                              )
                            ? this.props.name
                            : Object.is(
                                this.props.role.toLowerCase(),
                                RoleTypes.STUDENT_COORDINATOR
                              )
                            ? this.props.name
                            : Object.is(
                                this.props.role.toLowerCase(),
                                RoleTypes.MEDICAL_STAFF
                              )
                            ? this.props.name
                            : Object.is(
                                this.props.role.toLowerCase(),
                                RoleTypes.MAINTENANCE_COORDINATOR
                              )
                            ? this.props.name
                            : Object.is(
                                this.props.role.toLowerCase(),
                                RoleTypes.TRANSPORT_COORDINATOR
                              )
                            ? this.props.name
                            : Object.is(
                                this.props.role.toLowerCase(),
                                RoleTypes.CANTEEN_COORDINATOR
                              )
                            ? this.props.name
                            : Object.is(
                                this.props.role.toLowerCase(),
                                RoleTypes.TIMETABLE_COORDINATOR
                              )
                            ? this.props.name
                            : Object.is(
                                this.props.role.toLowerCase(),
                                RoleTypes.PORTAL_COORDINATOR
                              )
                            ? this.props.name
                            : Object.is(
                                this.props.role.toLowerCase(),
                                RoleTypes.EXAM_COORDINATOR
                              )
                            ? this.props.name
                            : Object.is(
                                this.props.role.toLowerCase(),
                                RoleTypes.NEWS_COORDINATOR
                              )
                            ? this.props.name
                            : Object.is(
                                this.props.role.toLowerCase(),
                                RoleTypes.SEN
                              )
                            ? this.props.name
                            : Object.is(
                                this.props.role.toLowerCase(),
                                RoleTypes.FRONT_DESK
                              )
                            ? this.props.name
                            : Object.is(
                                this.props.role.toLowerCase(),
                                RoleTypes.PASTORAL_SMART
                              )
                            ? this.props.name
                            : Object.is(
                                this.props.role.toLowerCase(),
                                RoleTypes.SECURITY_MANAGER
                              )
                            ? this.props.name
                            : Object.is(
                                this.props.role.toLowerCase(),
                                RoleTypes.PROCUREMENT_MANAGER
                              )
                            ? this.props.name
                            : Object.is(
                                this.props.role.toLowerCase(),
                                RoleTypes.EXCLUSION_MANAGER
                              )
                            ? this.props.name
                            : ""}
                        </h3> */}
                      </div>
                      <li>
                        {renderUserRole &&
                          renderUserRole.map((item, index) =>
                            Object.is(
                              this.props.role.toLowerCase(),
                              RoleTypes.ACADMIC_SMART
                            )
                              ? this.RenderSingleLinks(
                                  item.icon,
                                  item.path,
                                  item.RouteName,
                                  index
                                )
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.STUDENT_SMART
                                )
                              ? this.RenderSingleLinksOrDoubleLinks(
                                  item.icon,
                                  item.path,
                                  item.RouteName,
                                  4,
                                  index,
                                  item.type,
                                  item.letterSpacing,
                                  item.parentIconName,
                                  item.parentName,
                                  item.ChildArray
                                )
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.DEAN_ACTIVITIES
                                )
                              ? this.RenderSingleLinksOrDoubleLinks(
                                  item.icon,
                                  item.path,
                                  item.RouteName,
                                  4,
                                  index,
                                  item.type,
                                  item.letterSpacing,
                                  item.parentIconName,
                                  item.parentName,
                                  item.ChildArray
                                )
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.STAFF_SMART
                                )
                              ? this.RenderSingleLinksOrDoubleLinks(
                                  item.icon,
                                  item.path,
                                  item.RouteName,
                                  4,
                                  index,
                                  item.type,
                                  item.letterSpacing,
                                  item.parentIconName,
                                  item.parentName,
                                  item.ChildArray
                                )
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.DEAN_ENGLISH
                                )
                              ? this.RenderSingleLinksOrDoubleLinks(
                                  item.icon,
                                  item.path,
                                  item.RouteName,
                                  4,
                                  index,
                                  item.type,
                                  item.letterSpacing,
                                  item.parentIconName,
                                  item.parentName,
                                  item.ChildArray
                                )
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.REGISTRATION_COORDINATOR
                                )
                              ? this.RenderSingleLinksOrDoubleLinks(
                                  item.icon,
                                  item.path,
                                  item.RouteName,
                                  4,
                                  index,
                                  item.type,
                                  item.letterSpacing,
                                  item.parentIconName,
                                  item.parentName,
                                  item.ChildArray
                                )
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.ENTRY_EXAM_COORDINATOR
                                )
                              ? this.RenderSingleLinksOrDoubleLinks(
                                  item.icon,
                                  item.path,
                                  item.RouteName,
                                  4,
                                  index,
                                  item.type,
                                  item.letterSpacing,
                                  item.parentIconName,
                                  item.parentName,
                                  item.ChildArray
                                )
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.NEWS_COORDINATOR
                                )
                              ? this.RenderSingleLinksOrDoubleLinks(
                                  item.icon,
                                  item.path,
                                  item.RouteName,
                                  4,
                                  index,
                                  item.type,
                                  item.letterSpacing,
                                  item.parentIconName,
                                  item.parentName,
                                  item.ChildArray
                                )
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.Grade_Coordinator
                                )
                              ? this.RenderSingleLinksOrDoubleLinks(
                                  item.icon,
                                  item.path,
                                  item.RouteName,
                                  4,
                                  index,
                                  item.type,
                                  item.letterSpacing,
                                  item.parentIconName,
                                  item.parentName,
                                  item.ChildArray
                                )
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.HR_SMART
                                )
                              ? this.RenderSingleLinks(
                                  item.icon,
                                  item.path,
                                  item.RouteName,
                                  index
                                )
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.HOD_SMART
                                )
                              ? this.RenderSingleLinks(
                                  item.icon,
                                  item.path,
                                  item.RouteName,
                                  index
                                )
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.COMMUNICATION_SMART
                                )
                              ? this.RenderSingleLinks(
                                  item.icon,
                                  item.path,
                                  item.RouteName,
                                  index
                                )
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.PARENT_SMART
                                )
                              ? this.RenderSingleLinks(
                                  item.icon,
                                  item.path,
                                  item.RouteName,
                                  index
                                )
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.GENERAL_MANAGER_SMART
                                )
                              ? this.RenderLinksOwner(
                                  item.icon,
                                  item.parentName,
                                  4,
                                  index,
                                  item.ChildArray
                                )
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.ADMINISTRATION_SMART
                                )
                              ? this.RenderSingleLinks(
                                  item.icon,
                                  item.path,
                                  item.RouteName,
                                  index
                                )
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.DEAN_ARABIC
                                )
                              ? this.RenderSingleLinks(
                                  item.icon,
                                  item.path,
                                  item.RouteName,
                                  index
                                )
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.ADMISSION_SMART
                                )
                              ? this.RenderSingleLinksOrDoubleLinks(
                                  item.icon,
                                  item.path,
                                  item.RouteName,
                                  4,
                                  index,
                                  item.type,
                                  item.parentIconName,
                                  item.parentName,
                                  item.ChildArray
                                )
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.INVENTORY_MANAGER
                                )
                              ? this.RenderSingleLinksOrDoubleLinks(
                                  item.icon,
                                  item.path,
                                  item.RouteName,
                                  4,
                                  index,
                                  item.type,
                                  item.parentIconName,
                                  item.parentName,
                                  item.ChildArray
                                )
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.LIBRARY_MANAGER
                                )
                              ? this.RenderSingleLinksOrDoubleLinks(
                                  item.icon,
                                  item.path,
                                  item.RouteName,
                                  4,
                                  index,
                                  item.type,
                                  item.parentIconName,
                                  item.parentName,
                                  item.ChildArray
                                )
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.DOCTOR_MANAGER
                                )
                              ? this.RenderSingleLinksOrDoubleLinks(
                                  item.icon,
                                  item.path,
                                  item.RouteName,
                                  4,
                                  index,
                                  item.type,
                                  item.parentIconName,
                                  item.parentName,
                                  item.ChildArray
                                )
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.BILLING_MANAGER
                                )
                              ? this.RenderSingleLinksOrDoubleLinks(
                                  item.icon,
                                  item.path,
                                  item.RouteName,
                                  4,
                                  index,
                                  item.type,
                                  item.parentIconName,
                                  item.parentName,
                                  item.ChildArray
                                )
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.ACCOUNTANT_MANAGER
                                )
                              ? this.RenderSingleLinksOrDoubleLinks(
                                  item.icon,
                                  item.path,
                                  item.RouteName,
                                  4,
                                  index,
                                  item.type,
                                  item.parentIconName,
                                  item.parentName,
                                  item.ChildArray
                                )
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.FRONT_DESK
                                )
                              ? this.RenderSingleLinksOrDoubleLinks(
                                  item.icon,
                                  item.path,
                                  item.RouteName,
                                  4,
                                  index,
                                  item.type,
                                  item.parentIconName,
                                  item.parentName,
                                  item.ChildArray
                                )
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.FINANCE_SMART
                                )
                              ? this.RenderSingleLinks(
                                  item.icon,
                                  item.path,
                                  item.RouteName,
                                  index
                                )
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.PASTORAL_SMART
                                )
                              ? this.RenderSingleLinks(
                                  item.icon,
                                  item.path,
                                  item.RouteName,
                                  index
                                )
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.SECURITY_MANAGER
                                )
                              ? this.RenderSingleLinks(
                                  item.icon,
                                  item.path,
                                  item.RouteName,
                                  index
                                )
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.PROCUREMENT_MANAGER
                                )
                              ? this.RenderSingleLinks(
                                  item.icon,
                                  item.path,
                                  item.RouteName,
                                  index
                                )
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.STUDENT_COORDINATOR
                                )
                              ? this.RenderSingleLinks(
                                  item.icon,
                                  item.path,
                                  item.RouteName,
                                  index
                                )
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.STAFF_COORDINATOR
                                )
                              ? this.RenderSingleLinks(
                                  item.icon,
                                  item.path,
                                  item.RouteName,
                                  index
                                )
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.TRANSPORT_COORDINATOR
                                )
                              ? this.RenderSingleLinks(
                                  item.icon,
                                  item.path,
                                  item.RouteName,
                                  index
                                )
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.TIMETABLE_COORDINATOR
                                )
                              ? this.RenderSingleLinks(
                                  item.icon,
                                  item.path,
                                  item.RouteName,
                                  index
                                )
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.MEDICAL_STAFF
                                )
                              ? this.RenderSingleLinks(
                                  item.icon,
                                  item.path,
                                  item.RouteName,
                                  index
                                )
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.EXCLUSION_COORDINATOR
                                )
                              ? this.RenderSingleLinks(
                                  item.icon,
                                  item.path,
                                  item.RouteName,
                                  index
                                )
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.WELLBEING_COORDINATOR
                                )
                              ? this.RenderSingleLinks(
                                  item.icon,
                                  item.path,
                                  item.RouteName,
                                  index
                                )
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.ACTIVITY_COORDINATOR
                                )
                              ? this.RenderSingleLinks(
                                  item.icon,
                                  item.path,
                                  item.RouteName,
                                  index
                                )
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.SEN
                                )
                              ? this.RenderSingleLinks(
                                  item.icon,
                                  item.path,
                                  item.RouteName,
                                  index
                                )
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.COMMUNICATION_COORDINATOR
                                )
                              ? this.RenderSingleLinksOrDoubleLinks(
                                  item.icon,
                                  item.path,
                                  item.RouteName,
                                  4,
                                  index,
                                  item.type,
                                  item.letterSpacing,
                                  item.parentIconName,
                                  item.parentName,
                                  item.ChildArray
                                )
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.PORTAL_COORDINATOR
                                )
                              ? this.RenderSingleLinks(
                                  item.icon,
                                  item.path,
                                  item.RouteName,
                                  index
                                )
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.STAFF_FORM_MANAGER
                                )
                              ? this.RenderSingleLinks(
                                  item.icon,
                                  item.path,
                                  item.RouteName,
                                  index
                                )
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.STUDENT_FORM_MANAGER
                                )
                              ? this.RenderSingleLinks(
                                  item.icon,
                                  item.path,
                                  item.RouteName,
                                  index
                                )
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.PHYSICS_HOD
                                )
                              ? this.RenderSingleLinksOrDoubleLinks(
                                  item.icon,
                                  item.path,
                                  item.RouteName,
                                  4,
                                  index,
                                  item.type,
                                  item.letterSpacing,
                                  item.parentIconName,
                                  item.parentName,
                                  item.ChildArray
                                )
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.MATHEMATICS_HOD
                                )
                              ? this.RenderSingleLinksOrDoubleLinks(
                                  item.icon,
                                  item.path,
                                  item.RouteName,
                                  4,
                                  index,
                                  item.type,
                                  item.letterSpacing,
                                  item.parentIconName,
                                  item.parentName,
                                  item.ChildArray
                                )
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.CHEMISTRY_HOD
                                )
                              ? this.RenderSingleLinksOrDoubleLinks(
                                  item.icon,
                                  item.path,
                                  item.RouteName,
                                  4,
                                  index,
                                  item.type,
                                  item.letterSpacing,
                                  item.parentIconName,
                                  item.parentName,
                                  item.ChildArray
                                )
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.BIOLOGY_HOD
                                )
                              ? this.RenderSingleLinksOrDoubleLinks(
                                  item.icon,
                                  item.path,
                                  item.RouteName,
                                  4,
                                  index,
                                  item.type,
                                  item.letterSpacing,
                                  item.parentIconName,
                                  item.parentName,
                                  item.ChildArray
                                )
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.GENERAL_SCIENCE_HOD
                                )
                              ? this.RenderSingleLinksOrDoubleLinks(
                                  item.icon,
                                  item.path,
                                  item.RouteName,
                                  4,
                                  index,
                                  item.type,
                                  item.letterSpacing,
                                  item.parentIconName,
                                  item.parentName,
                                  item.ChildArray
                                )
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.ENGLISH_HOD
                                )
                              ? this.RenderSingleLinksOrDoubleLinks(
                                  item.icon,
                                  item.path,
                                  item.RouteName,
                                  4,
                                  index,
                                  item.type,
                                  item.letterSpacing,
                                  item.parentIconName,
                                  item.parentName,
                                  item.ChildArray
                                )
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.FRENCHCHINESE_HOD
                                )
                              ? this.RenderSingleLinksOrDoubleLinks(
                                  item.icon,
                                  item.path,
                                  item.RouteName,
                                  4,
                                  index,
                                  item.type,
                                  item.letterSpacing,
                                  item.parentIconName,
                                  item.parentName,
                                  item.ChildArray
                                )
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.ICT_HOD
                                )
                              ? this.RenderSingleLinksOrDoubleLinks(
                                  item.icon,
                                  item.path,
                                  item.RouteName,
                                  4,
                                  index,
                                  item.type,
                                  item.letterSpacing,
                                  item.parentIconName,
                                  item.parentName,
                                  item.ChildArray
                                )
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.BUSINESS_ACCOUNTS_HOD
                                )
                              ? this.RenderSingleLinksOrDoubleLinks(
                                  item.icon,
                                  item.path,
                                  item.RouteName,
                                  4,
                                  index,
                                  item.type,
                                  item.letterSpacing,
                                  item.parentIconName,
                                  item.parentName,
                                  item.ChildArray
                                )
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.ARABIC_HOD
                                )
                              ? this.RenderSingleLinksOrDoubleLinks(
                                  item.icon,
                                  item.path,
                                  item.RouteName,
                                  4,
                                  index,
                                  item.type,
                                  item.letterSpacing,
                                  item.parentIconName,
                                  item.parentName,
                                  item.ChildArray
                                )
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.ISLAMIC_HOD
                                )
                              ? this.RenderSingleLinksOrDoubleLinks(
                                  item.icon,
                                  item.path,
                                  item.RouteName,
                                  4,
                                  index,
                                  item.type,
                                  item.letterSpacing,
                                  item.parentIconName,
                                  item.parentName,
                                  item.ChildArray
                                )
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.SOCIAL_HOD
                                )
                              ? this.RenderSingleLinksOrDoubleLinks(
                                  item.icon,
                                  item.path,
                                  item.RouteName,
                                  4,
                                  index,
                                  item.type,
                                  item.letterSpacing,
                                  item.parentIconName,
                                  item.parentName,
                                  item.ChildArray
                                )
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.MUSIC_HOD
                                )
                              ? this.RenderSingleLinksOrDoubleLinks(
                                  item.icon,
                                  item.path,
                                  item.RouteName,
                                  4,
                                  index,
                                  item.type,
                                  item.letterSpacing,
                                  item.parentIconName,
                                  item.parentName,
                                  item.ChildArray
                                )
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.ARTS_HOD
                                )
                              ? this.RenderSingleLinksOrDoubleLinks(
                                  item.icon,
                                  item.path,
                                  item.RouteName,
                                  4,
                                  index,
                                  item.type,
                                  item.letterSpacing,
                                  item.parentIconName,
                                  item.parentName,
                                  item.ChildArray
                                )
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.SPORTS_HOD
                                )
                              ? this.RenderSingleLinksOrDoubleLinks(
                                  item.icon,
                                  item.path,
                                  item.RouteName,
                                  4,
                                  index,
                                  item.type,
                                  item.letterSpacing,
                                  item.parentIconName,
                                  item.parentName,
                                  item.ChildArray
                                )
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.DESIGN_DRAMA_HOD
                                )
                              ? this.RenderSingleLinksOrDoubleLinks(
                                  item.icon,
                                  item.path,
                                  item.RouteName,
                                  4,
                                  index,
                                  item.type,
                                  item.letterSpacing,
                                  item.parentIconName,
                                  item.parentName,
                                  item.ChildArray
                                )
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.MAINTENANCE_COORDINATOR
                                )
                              ? this.RenderSingleLinksOrDoubleLinks(
                                  item.icon,
                                  item.path,
                                  item.RouteName,
                                  4,
                                  index,
                                  item.type,
                                  item.letterSpacing,
                                  item.parentIconName,
                                  item.parentName,
                                  item.ChildArray
                                )
                              : Object.is(
                                  this.props.role.toLowerCase(),
                                  RoleTypes.OWNER
                                )
                              ? this.RenderLinksOwner(
                                  item.icon,
                                  item.parentName,
                                  4,
                                  index,
                                  item.ChildArray
                                )
                              : this.RenderLinks(
                                  item.icon,
                                  item.parentName,
                                  4,
                                  index,
                                  item.ChildArray
                                )
                          )}
                      </li>
                    </ul>
                  )}
                </ThemeContext.Consumer>
              </nav>
            )}
          </ThemeContext.Consumer>
          {/* <!-- Page Content  --> */}
          <div id="content">
            <ThemeContext.Consumer>
              {(context) => (
                <nav
                  class=" navbar navbar-expand-lg navbar-light"
                  style={{
                    background: context.themeColors.primaryColor,
                    borderRadius: 5,
                    boxShadow: "5px 5px 5px grey",
                  }}
                >
                  <div class="container-fluid">
                    <ThemeContext.Consumer>
                      {(context) => (
                        <React.Fragment>
                          <button
                            style={{
                              borderRadius: 22,

                              backgroundColor: Colors.BLACK,
                            }}
                            type="button"
                            id="sidebarCollapse"
                            class="btn btn-info"
                          >
                            <i class="fas fa-exchange-alt" />
                          </button>
                          <Link to="/dashboard">
                            <button
                              type="button"
                              class="btn btn-info"
                              style={{
                                marginLeft: 15,
                                backgroundColor: Colors.BLACK,
                                borderRadius: 22,
                                borderColor: Colors.BLACK,
                              }}
                            >
                              <i class="fas fa-home" />
                            </button>
                          </Link>
                        </React.Fragment>
                      )}
                    </ThemeContext.Consumer>
                    <div
                      className="text-right col-md-6"
                      style={{ marginLeft: 35 }}
                    >
                      <img
                        src={require("../Assets/New_Admission_Icon/SSWhiteP.png")}
                        width="240"
                        height="60"
                      />
                    </div>
                    <div
                      class="collapse navbar-collapse"
                      id="navbarSupportedContent"
                    >
                      <ul
                        class="nav navbar-nav ml-auto"
                        style={{ marginTop: 0 }}
                      >
                        <li class="nav-item"></li>
                        <li class="nav-item">
                          {/* <Link
                            style={{
                              color: "white",
                              marginRight: 25,
                              // borderColor: "none",
                            }}
                            // className="nav-item"
                            onClick={this.goBack}
                            to="/"
                          >
                            <ThemeContext.Consumer>
                              {(context) => (
                                <button
                                  type="button"
                                  class="btn btn-info"
                                  style={{
                                    marginTop: 10,
                                    // borderColor: "#f2f2f2",
                                    marginLeft: 15,
                                    backgroundColor: Colors.BLACK,
                                    borderRadius: 22,
                                  }}
                                >
                                  <i class="fas fa-bell"></i>
                                </button>
                              )}
                            </ThemeContext.Consumer>
                          </Link> */}
                        </li>
                        {this.props.role.toLowerCase() === "parent" ||
                        this.props.role.toLowerCase() === "staff" ? (
                          <ThemeContext.Consumer>
                            {(contextTheme) => (
                              <ParentTeacherInboxGlobalContext.Consumer>
                                {(contextPTInbox) => (
                                  <li
                                    class="nav-item dropdown"
                                    style={{ color: "white", marginRight: 5 }}
                                  >
                                    <button
                                      type="button"
                                      class="btn btn-info"
                                      style={{
                                        marginTop: 10,
                                        backgroundColor: Colors.BLACK,
                                        borderColor: Colors.BLACK,
                                        borderRadius: 22,
                                      }}
                                    >
                                      <i
                                        data-toggle="dropdown"
                                        class="nav-link"
                                        class="fas fa-user-tie"
                                        style={{
                                          fontSize: "22px",
                                          position: "relative",
                                          display: "inline-block",
                                        }}
                                      >
                                        {contextPTInbox.countInbox === 0 ? (
                                          ""
                                        ) : (
                                          <span
                                            class="badge badge-danger"
                                            style={{ fontSize: "12px" }}
                                          >
                                            {contextPTInbox.countInbox}
                                          </span>
                                        )}
                                      </i>
                                      <ul
                                        class="dropdown-menu"
                                        style={{ width: "250px" }}
                                      >
                                        <li
                                          class="dropdown-item"
                                          style={{
                                            backgroundColor:
                                              contextTheme.themeColors
                                                .primaryColor,
                                          }}
                                        ></li>
                                        {contextPTInbox.Inbox.length < 1 ? (
                                          <li class="text-center">
                                            <strong>No Messages</strong>
                                          </li>
                                        ) : (
                                          contextPTInbox.Inbox.slice(0, 5).map(
                                            (item) => (
                                              <li class="text-center">
                                                <div class="card shadow-lg">
                                                  <div
                                                    class="card-body"
                                                    style={{
                                                      color: "#fff",
                                                      backgroundColor: `${
                                                        item.status == 0
                                                          ? "#ededed"
                                                          : "#ffffff"
                                                      }`,
                                                    }}
                                                  >
                                                    <button
                                                      data-toggle="modal"
                                                      data-target="#messageDialogModal"
                                                      onClick={() =>
                                                        this.getMessageeParentTeacher(
                                                          item.id,
                                                          contextPTInbox
                                                        )
                                                      }
                                                      style={{
                                                        cursor: "pointer",
                                                        border: "none",
                                                        background: "none",
                                                      }}
                                                    >
                                                      <strong>
                                                        {item.title}
                                                      </strong>
                                                      <br />
                                                      <strong
                                                        style={{
                                                          color: "#bbb",
                                                          fontSize: "10px",
                                                          marginLeft: "5px",
                                                        }}
                                                      >
                                                        {item.date}
                                                      </strong>
                                                    </button>
                                                  </div>
                                                </div>
                                              </li>
                                            )
                                          )
                                        )}
                                        {contextPTInbox.Inbox.length < 1 ? (
                                          ""
                                        ) : (
                                          <li
                                            class="text-center"
                                            style={{
                                              backgroundColor:
                                                contextTheme.themeColors
                                                  .primaryColor,
                                              color: "#fff",
                                              fontSize: "18px",
                                            }}
                                          >
                                            <Link
                                              style={{
                                                backgroundColor:
                                                  contextTheme.themeColors
                                                    .primaryColor,
                                                color: "#fff",
                                              }}
                                              to={`/dashboard/ParentTeacherInbox`}
                                            >
                                              <button
                                                class="text-center"
                                                id="firstbutton"
                                                style={{
                                                  cursor: "pointer",
                                                  border: "none",
                                                  background: "none",
                                                }}
                                              >
                                                <i
                                                  style={{ color: "#fff" }}
                                                  class="fas fa-inbox"
                                                ></i>
                                                <strong
                                                  style={{ color: "#fff" }}
                                                >
                                                  {" "}
                                                  View Inbox
                                                </strong>
                                              </button>
                                            </Link>
                                          </li>
                                        )}
                                      </ul>
                                    </button>
                                  </li>
                                )}
                              </ParentTeacherInboxGlobalContext.Consumer>
                            )}
                          </ThemeContext.Consumer>
                        ) : (
                          ""
                        )}
                        <ThemeContext.Consumer>
                          {(contextTheme) => (
                            <InboxContext.Consumer>
                              {(context) => (
                                <li
                                  class="nav-item dropdown"
                                  style={{ color: "white", marginRight: 5 }}
                                >
                                  <button
                                    type="button"
                                    class="btn btn-info"
                                    style={{
                                      marginTop: 10,
                                      backgroundColor: Colors.BLACK,
                                      borderColor: Colors.BLACK,
                                      borderRadius: 22,
                                    }}
                                  >
                                    <i
                                      data-toggle="dropdown"
                                      class="nav-link"
                                      class="fab fa-facebook-messenger"
                                      style={{
                                        fontSize: "22px",
                                        position: "relative",
                                        display: "inline-block",
                                      }}
                                    >
                                      {context.countInbox === 0 ? (
                                        ""
                                      ) : (
                                        <span
                                          class="badge badge-danger"
                                          style={{ fontSize: "12px" }}
                                        >
                                          {context.countInbox}
                                        </span>
                                      )}
                                    </i>
                                    <ul
                                      class="dropdown-menu"
                                      style={{ width: "250px" }}
                                    >
                                      <li
                                        class="dropdown-item"
                                        style={{
                                          backgroundColor:
                                            contextTheme.themeColors
                                              .primaryColor,
                                        }}
                                      ></li>
                                      {context.Inbox.length < 1 ? (
                                        <li class="text-center">
                                          <strong>No Messages</strong>
                                        </li>
                                      ) : (
                                        context.Inbox.slice(0, 5).map(
                                          (item) => (
                                            <li class="text-center">
                                              <div class="card shadow-lg">
                                                <div
                                                  class="card-body"
                                                  style={{
                                                    color: "#fff",
                                                    backgroundColor: `${
                                                      item.status == 0
                                                        ? "#ededed"
                                                        : "#ffffff"
                                                    }`,
                                                  }}
                                                >
                                                  <button
                                                    data-toggle="modal"
                                                    data-target="#messageDialogModal"
                                                    onClick={() =>
                                                      this.getMessagee(
                                                        item.id,
                                                        context
                                                      )
                                                    }
                                                    style={{
                                                      cursor: "pointer",
                                                      border: "none",
                                                      background: "none",
                                                    }}
                                                  >
                                                    <strong>
                                                      {item.title}
                                                    </strong>
                                                    <br />
                                                    <strong
                                                      style={{
                                                        color: "#bbb",
                                                        fontSize: "10px",
                                                        marginLeft: "5px",
                                                      }}
                                                    >
                                                      {item.date}
                                                    </strong>
                                                  </button>
                                                </div>
                                              </div>
                                            </li>
                                          )
                                        )
                                      )}
                                      {context.Inbox.length < 1 ? (
                                        ""
                                      ) : (
                                        <li
                                          class="text-center"
                                          style={{
                                            backgroundColor:
                                              contextTheme.themeColors
                                                .primaryColor,
                                            color: "#fff",
                                            fontSize: "18px",
                                          }}
                                        >
                                          <Link
                                            style={{
                                              backgroundColor:
                                                contextTheme.themeColors
                                                  .primaryColor,
                                              color: "#fff",
                                            }}
                                            to={`/dashboard/Inbox`}
                                          >
                                            <button
                                              class="text-center"
                                              id="firstbutton"
                                              style={{
                                                cursor: "pointer",
                                                border: "none",
                                                background: "none",
                                              }}
                                            >
                                              <i
                                                style={{ color: "#fff" }}
                                                class="fas fa-inbox"
                                              ></i>
                                              <strong style={{ color: "#fff" }}>
                                                {" "}
                                                View Inbox
                                              </strong>
                                            </button>
                                          </Link>
                                        </li>
                                      )}
                                    </ul>
                                  </button>
                                </li>
                              )}
                            </InboxContext.Consumer>
                          )}
                        </ThemeContext.Consumer>
                        <li class="nav-item">
                          <Link
                            style={{ color: "white", marginRight: 15 }}
                            className="nav-item"
                            onClick={this.goBack}
                            to="/"
                          >
                            <ThemeContext.Consumer>
                              {(context) => (
                                <button
                                  type="button"
                                  class="btn btn-info"
                                  style={{
                                    marginTop: 10,
                                    marginLeft: 5,
                                    backgroundColor: Colors.BLACK,
                                    borderColor: Colors.BLACK,
                                    borderRadius: 22,
                                  }}
                                >
                                  <i class="fas fa-arrow-left"></i>
                                </button>
                              )}
                            </ThemeContext.Consumer>
                          </Link>
                        </li>

                        <li class="nav-item dropdown">
                          <a href="#" data-toggle="dropdown" class="nav-link">
                            <img
                              src={`https://dummyimage.com/100x100/000/fff&text=${this.props.name[0].toUpperCase()}`}
                              style={{ borderRadius: 100, marginRight: 5 }}
                              alt="Avatar"
                              height={39}
                            />
                            <label className="font-weight-bold">
                              {this.props.name}
                            </label>
                            <i
                              style={{ marginLeft: 5 }}
                              class="fas fa-angle-down"
                            ></i>
                          </a>
                          <ul class="dropdown-menu">
                            {/* <li>
													<a href="#" class="dropdown-item">
                          <i class="fa fa-user-o"></i> Profile
													</a>
                          </li>
                          <li>
													<a href="#" class="dropdown-item">
                          <i class="fa fa-calendar-o"></i> Calendar
													</a>
                          </li>
                          <li>
													<a href="#" class="dropdown-item">
                          <i class="fa fa-sliders"></i> Settings
													</a>
												</li> */}
                            <li class="dropdown-item"></li>
                            <li>
                              <a
                                onClick={this.onLogout}
                                href="#"
                                class="dropdown-item"
                              >
                                <i class="fas fa-sign-out-alt"></i> Logout
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                </nav>
              )}
            </ThemeContext.Consumer>
            {this.props.children}
            <div
              style={{ position: "relative", right: 21 }}
              class="displayElemet"
            >
              <ThemeContext.Consumer>
                {(context) => (
                  <div
                    style={{
                      position: "fixed",
                      bottom: 0,
                      width: "100%",
                      border: `1px solid ${context.themeColors.primaryColor}`,
                      backgroundColor: context.themeColors.primaryColor,
                    }}
                  >
                    <div>
                      <marquee style={{ width: "100%", color: "#fff" }}>
                        {}

                        <BulletenContext.Consumer>
                          {(context) =>
                            context &&
                            context.Bulleten &&
                            context.Bulleten.map((item) => (
                              <React.Fragment>
                                <span class="badge badge-secondary">
                                  News:{" "}
                                </span>
                                &nbsp;&nbsp;
                                <span>{item.newsDescription}</span>
                                &nbsp;&nbsp;
                              </React.Fragment>
                            ))
                          }
                        </BulletenContext.Consumer>

                        {/* {this.state.bulletinData.map(item => (
											<React.Fragment>
                      <span class="badge badge-secondary">News: </span>
                      &nbsp;&nbsp;
                      <span>{item.newsDescription}</span>
												&nbsp;&nbsp;
												
                        </React.Fragment>
                      ))} */}
                      </marquee>
                    </div>
                  </div>
                )}
              </ThemeContext.Consumer>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.setUserReducer.auth,
    name: state.setUserReducer.users.unique_name,

    role: state.setUserReducer.users.role,
    notificationLink: state.setUserReducer.notificationLink,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    logoutNow: bindActionCreators(LoginAction, dispatch),
    sharedActions: bindActionCreators(sharedActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Head));
