import React from 'react';
import { Types } from '../../hooks/HookReducers/StaffReducer';
import CustomDatePicker from '../../components/CustomDatePicker';
import CustomNativeDatePicker from '../../components/CustomNativeDatePicker';
const RenderDynamicSiblingStaffRow = props => {
	const {
		itemObj,
		onHandleTextChange,
		stateData,
		onDateChange,
		rowIndex,
		updateSpecialArrObjOnChange,
		arrName,
		hookReuse = false,
		dispatch
	} = props;

	let keys = Object.keys(itemObj);
	// console.log(stateData.siblingsDetails[0]['age'])
	if (keys.includes('StudentSiblingDetailId') && keys.includes('studentId')) {
		let newKeys = keys.filter(
			item => item !== 'studentId' && item !== 'StudentSiblingDetailId'
		);
		keys = [...newKeys];
	}
	if (keys.includes('PreviousSchoolDetailId') && keys.includes('studentId')) {
		let newKeys = keys.filter(
			item => item !== 'studentId' && item !== 'PreviousSchoolDetailId'
		);
		keys = [...newKeys];
	}
	if (keys.includes('id') && keys.includes('staffId')) {
		let newKeys = keys.filter(item => item !== 'id' && item !== 'staffId');
		keys = [...newKeys];
	}

	return (
		<React.Fragment>
			{keys.map((item, index) =>
				item === 'siblingpassDOI' ||
				item === 'siblingpassDOE' ||
				item === 'siblingIdcardDOI' ||
				item === 'siblingIdcardDOE' ||
				item === 'siblingDob' ? (
					<td style={{ padding: 0 }}>
						<div style={{ marginTop: 15, width: '85%' }}>
							<input
								// style={{ borderTop: 0, borderLeft: 0, borderRight: 0 }}
								type="date"
								style={{ width: '100%' }}
								name={item}
								id=""
								className={'dateStyle'}
								tileClassName={'dateStyle'}
								onChange={date => onDateChange(date, item, rowIndex)}
								value={stateData[arrName][rowIndex][item]}
							/>
						</div>
					</td>
				) : (
					<td key={index}>
						<input
							type="text"
							placeholder={`${item}`}
							class="form-control"
							name={`${item}`}
							value={stateData[arrName][rowIndex][`${item}`]}
							onChange={
								hookReuse
									? e => onDateChange(e, item, rowIndex)
									: e => updateSpecialArrObjOnChange(e, rowIndex, arrName)
							}
						/>
					</td>
				)
			)}
		</React.Fragment>
	);
};
export default RenderDynamicSiblingStaffRow;
