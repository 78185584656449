import React from 'react';
import { Fade } from 'react-reveal';
import TimePicker from '../../../components/TimePicker';
import moment from 'moment';
import SelectionInput from '../../../components/SelectionInput/SelectionInput';
import { alphaNumericReturn } from '../../../utils';

const format = 'h:mm a';
const now = moment()
	.hour(0)
	.minute(0);
const TimeTableStep2 = props => {
	const { errors, onTextChange } = props;
	const returnDropDown = localProps => {
		const { noOfPeriods = 0 } = localProps;
		let getBreakNumber = [];
		for (let index = 1; index <= parseInt(noOfPeriods / 2); index++) {
			getBreakNumber.push(index);
		}
		console.log('get number: ', getBreakNumber);
		return (
			<React.Fragment>
				{noOfPeriods > 0 && noOfPeriods !== '' ? (
					<div style={{ width: '100%' }}>
						<SelectionInput
							isIcon={false}
							selectedText="Select Number"
							optionsArrys={getBreakNumber}
							isValidte={false}
							selectName={'Number Of Breaks '}
							onHandleChange={e => onTextChange(e)}
							optionType="static"
							feildName="noOfBreaks"
						/>
					</div>
				) : (
					''
				)}
			</React.Fragment>
		);
	};
	return (
		<div
			style={{
				fontSize: 20,
				border: '1px solid #D3D3D3',
				boxShadow: '0px 0px 15px #0A4F5E',
				padding: 10,
				borderRadius: 5
			}}
		>
			<div class="row animated fadeInDown">
				<div class="col-md-12">
					<div style={{ textAlign: 'center' }} class="md-form mb-0">
						<label for="name" class="">
							<i
								className="fas fa-house animated fadeIn"
								style={{ marginRight: 4 }}
							/>
							<p style={{ fontSize: 30, textAlign: 'center' }}>
								<span
									style={{ position: 'relative', bottom: 70 }}
									class="badge badge-pill badge-dark"
								>
									<p style={{ margin: 'auto', color: '#fff' }}>
										Per Period Timing
									</p>
								</span>
							</p>
						</label>
						<br />
						{errors.step2 ? (
							<p
								style={{
									color: 'red',
									fontSize: 17,
									textAlign: 'center',
									position: 'relative',
									bottom: 50
								}}
							>
								Please Enter Correct Time
							</p>
						) : (
							''
						)}
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								flexWrap: 'wrap',
								justifyContent: 'center',
								position: 'relative',
								bottom: 60
							}}
						>
							<div>
								<p style={{ margin: 0, color: '#000', fontSize: 17 }}>
									Start Time
								</p>
								<div>
									<TimePicker
										className="dateTimePicker"
										showSecond={false}
										placeholder={'Hr-Min-(AM/PM)'}
										name="offSDate"
										defaultValue={null}
										onChange={e => props.onChange(e, 'PerPSDate')}
										format={format}
										use12Hours
										// inputReadOnly
									/>
								</div>
							</div>
							<div style={{ margin: 25, color: '#000', fontSize: 17 }}>-</div>
							<div>
								<p style={{ margin: 0, color: '#000', fontSize: 17 }}>
									End Time
								</p>
								<div>
									{/* <DatePicker
                            style={{ borderTop: 0, borderLeft: 0, borderRight: 0 }}
                          //  onChange={(e)=>onChangeDate(e,'dateLeaving')}
                          //  value={new Date(stateData.dateLeaving)}
                          /> */}
									<TimePicker
										className="dateTimePicker"
										showSecond={false}
										placeholder={'Hr-Min-(AM/PM)'}
										name="offSDate"
										defaultValue={null}
										onChange={e => props.onChange(e, 'PerPEDate')}
										format={format}
										use12Hours
										// inputReadOnly
									/>
								</div>
							</div>
						</div>
						{/* <div>
              <input style={{ width: '50%' }} value={props.stateData.noOfPeriods} readOnly={true} className='textFeildInput' type='number' placeholder='Total No Of Periods'
              />

            </div> */}
					</div>
				</div>
			</div>
			<div style={{ display: 'flex', position: 'relative', bottom: 30 }}>
				{props.stateData.noOfPeriods !== '' ? (
					<div>
						<span
							style={{
								fontSize: 17
							}}
							class="badge badge-pill badge-warning"
						>
							no of periods: {props.stateData.noOfPeriods}
						</span>
					</div>
				) : (
					''
				)}
				{props.stateData.noOfPeriods > 0
					? returnDropDown({ noOfPeriods: props.stateData.noOfPeriods })
					: ''}
			</div>
		</div>
	);
};
export default TimeTableStep2;
