import React from "react";
import MenuItem from "../MenuItem/MenuItem";
import { useContext } from "react";
import ThemeContext from "../../context/themeContext/ThemeContext";
import CardsForView from "../CardsForView";
import { connect } from "react-redux";

function NewViewRendering(props) {
  const { data, isImage = false } = props;
  const themeColor = useContext(ThemeContext);

  return (
    <div className="row mt-2">
      {data.map((item) =>
        item.title === "CMS" && props.role.toLowerCase() === "owner" ? (
          <div></div>
        ) : (
          <div className="col-md-6">
            <CardsForView isImage={isImage} data={item} />
          </div>
        )
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    name: state.setUserReducer.users.unique_name,
    role: state.setUserReducer.users.role,
  };
}

  export default connect(mapStateToProps, null)(NewViewRendering);
