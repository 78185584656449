import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as sharedActions from "../../Actions/sharedActions";
import TextInput from "../../components/TextInput/TextInput";
import SelectionInput from "../../components/SelectionInput/SelectionInput";
import FormLayoutWrapper from "../../components/HOC/FormLayoutWrapper";
import SnackBar from "../../components/SnackBar/SnackBar";
import general from "../../utils/general";
import ThemeContext from "../../context/themeContext/ThemeContext";

class AddSubjectRenew extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mainData: {},
      gradesData: [],
      isLoading: true,
      userName: props.username,
      role: props.role,
      errors: {},
      errMsg: false,
      msg: "",
      subjectName: "",
      courseType: "",
      classId: "",
      gradeId: "",
      gradeName: "",
    };
  }

  onChangeSubjectHandle = (e) => {
    this.setState({
      subjectName: e.target.value,
    });
  };

  onChangeClassHandle = (e) => {
    const { gradesData } = this.state;

    var grade = gradesData.filter((grade) => grade.classId == e.target.value);
    const gradeName = grade[0].gradeName;
    this.setState({
      classId: e.target.value,
      gradeName: grade[0].gradeName,
      gradeId: grade[0].gradeId,
    });
  };

  onChangeCourseHandle = (e) => {
    this.setState({
      courseType: e.target.value,
    });
  };

  componentDidMount() {
    this.props.sharedActions
      .getDataWithoutDispatch("/api/classsectiongradeteacher")
      .then((success) => {
        this.setState({
          mainData: success,
          isLoading: false,
          gradesData: success.grade,
        });
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
        console.log(err);
      });
  }

  onHandleSubmit = (e) => {
    e.preventDefault();

    const {
      courseType,
      role,
      userName,
      className,
      classId,
      gradeId,
      subjectName,
      mainData,
    } = this.state;

    var cName = mainData.classes.filter(
      (classIns) => classIns.classId == classId
    );

    console.log(this.state);

    if (
      Object.is(subjectName, "") ||
      Object.is(classId, "") ||
      Object.is(className, "") ||
      Object.is(gradeId, "") ||
      Object.is(courseType, "")
    ) {
      this.setState({
        errMsg: true,
      });
    } else {
      this.setState({
        isLoading: true,
      });
      let data = {
        className: cName[0].className,
        subjectName: subjectName,
        classId: classId,
        accountId: userName,
        accountType: role,
        gradeId: gradeId,
        courseType: courseType,
      };

      this.props.sharedActions
        .simpleAddRequest("/api/AddSubject", data)
        .then((success) => {
          this.setState({
            classId: "",
            subjectName: "",
            gradeId: "",
            courseType: "",
            msg: "Successfully Submitted!",
            errMsg: false,
            isLoading: false,
            gradeName: "",
          });
          this.props.snackbar();
        });
    }
  };

  render() {
    const { errors, isLoading, mainData } = this.state;

    const Loader = (
      <ThemeContext.Consumer>
        {(context) => (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              class="spinner-border"
              style={{ color: context.themeColors.primaryColor }}
            ></div>
          </div>
        )}
      </ThemeContext.Consumer>
    );

    const Form = (
      <ThemeContext.Consumer>
        {(context) => (
          <FormLayoutWrapper
            formName="Add Subject"
            borderColor={context.themeColors.primaryColor}
            borderRadius={15}
          >
            {this.state.errMsg ? (
              <p style={{ color: "red", textAlign: "center" }}>
                Please fill the form properly
              </p>
            ) : (
              ""
            )}
            <div className="row">
              <TextInput
                disabledField={true}
                isValidte={false}
                stateData={this.state}
                feildName={"userName"}
                inputLabelName={"Admin Name"}
                errors={errors}
                textFont={16}
                readOnly={true}
              />

              <TextInput
                disabledField={true}
                isValidte={false}
                stateData={this.state}
                feildName={"role"}
                inputLabelName={"Admin Type"}
                errors={errors}
                textFont={16}
                readOnly={true}
              />

              <br />
              <br />

              <SelectionInput
                feildName={"className"}
                isValidte={false}
                selectName={"Class Name"}
                onHandleChange={(e) => this.onChangeClassHandle(e)}
                errors={errors}
                optionsArrys={mainData.classes}
                selectedText={"Class Name"}
                stateData={this.state}
                optionType="dynamicWithPropIdAndName"
                property={"className"}
                propertyId={"classId"}
              />

              <TextInput
                isValidte={false}
                stateData={this.state}
                feildName={"subjectName"}
                inputLabelName={"Subject Name"}
                errors={errors}
                textFont={16}
                onHandleChange={this.onChangeSubjectHandle}
              />

              <SelectionInput
                feildName={"courseType"}
                isValidte={false}
                selectName={"Course Type"}
                onHandleChange={this.onChangeCourseHandle}
                errors={errors}
                optionsArrys={general.courseType}
                selectedText={"Course Type"}
                stateData={this.state}
                // iconClassName={'fas fa-clock animated fadeIn'}
                optionType="static"
                selectFont={16}
                successflag={"successflag"}
              />

              <TextInput
                disabledField={true}
                isValidte={false}
                stateData={this.state}
                feildName={"gradeName"}
                inputLabelName={"Grade Name"}
                errors={errors}
                textFont={16}
                readOnly={true}
              />
            </div>

            <div class="float-right" style={{ paddingTop: 20 }}>
              <button
                type="submit"
                class="btn"
                onClick={this.onHandleSubmit}
                style={{
                  borderRadius: 10,
                  backgroundColor: context.themeColors.primaryColor,
                  color: "white",
                }}
              >
                Add
                <span
                  style={{
                    marginBottom: 5,
                  }}
                  class={`${
                    this.state.isLoading
                      ? "spinner-border spinner-border-sm"
                      : ""
                  }`}
                ></span>
              </button>
            </div>
          </FormLayoutWrapper>
        )}
      </ThemeContext.Consumer>
    );
    return (
      <div class="container">
        {isLoading ? Loader : Form}
        <SnackBar msg={this.state.msg} />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    sharedActions: bindActionCreators(sharedActions, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    role: state.setUserReducer.users.role,
    username: state.setUserReducer.users.unique_name,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddSubjectRenew);
