import React from "react";
import { connect } from "react-redux";
import $ from "jquery";
import TextInput from "../../../components/TextInput/TextInput";
import FormLayoutWrapper from "../../../components/HOC/FormLayoutWrapper";
import * as sharedActions from "../../../Actions/sharedActions";
import Button from "../../../components/Button";
import SnackBar from "../../../components/SnackBar/SnackBar";
import TextArea from "../../../components/TextArea";
import SelectionInput from "../../../components/SelectionInput/SelectionInput";
import { bindActionCreators } from "redux";
import ThemeContext from "../../../context/themeContext/ThemeContext";

class AddOffGame extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      accountId: props.name,
      accountType: props.role,
      errors: {},
      errorMsg: false,
      studentId: "",
      classId: "",
      sectionId: "",
      notes: "",
      reason: "",
      gameId: "",
      isLoadingInitialData: true,
      isLoadingSearch: "",
    };
  }

  componentDidUpdate() {
    if (this.state.msg || this.state.errorMsg) {
      setTimeout(() => {
        this.setState({
          msg: false,
          errorMsg: false,
        });
      }, 3000);
    }
  }

  componentDidMount() {
    $(document).ready(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });

    this.props.sharedActions
      .getDataWithoutDispatch("/api/Game")
      .then((success) => {
        this.setState({
          gameData: success,
          isLoadingInitialData: false,
        });
      })
      .catch((error) =>
        this.setState({
          isLoadingInitialData: false,
        })
      );
  }

  onHandleTextChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onHandleSelectChange = (e) => {
    if (e.target.value !== "Game") {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  };

  onSubmitGetStudent = (e) => {
    const { studentId } = this.state;

    e.preventDefault();

    if (studentId === "") {
      this.setState({
        error: true,
        errorMsg: true,
      });
    } else {
      this.setState({
        isLoadingSearch: true,
      });

      this.props.sharedActions
        .getDataWithoutDispatch(
          `/api/StudentInformationById?studentId=${studentId}`
        )
        .then((success) => {
          this.setState({
            isLoadingSearch: false,
            classId: success.classId,
            sectionId: success.sectionId,
            studentId: success.studentAdmissionFormId,
            name: success.studentName,
            section: success.section,
            className: success.className,
          });
          console.log(success);
        })
        .catch((error) => {
          this.setState({
            isLoadingSearch: false,
          });
        });
    }
  };

  onSubmit = (e) => {
    e.preventDefault();
    const {
      notes,
      reason,
      gameId,
      studentId,
      classId,
      sectionId,
      accountId,
      accountType,
    } = this.state;

    if (
      gameId === "" ||
      studentId === "" ||
      classId === "" ||
      sectionId === ""
    ) {
      this.setState({
        error: true,
        errorMsg: true,
      });
    } else {
      this.setState({
        isLoading: true,
      });
      const data = {
        gameId,
        studentId,
        classId,
        sectionId,
        notes,
        reason,
        accountId,
        accountType,
      };
      console.log(data);
      this.props.sharedActions
        .simpleAddRequest("/api/OffGame/", data)
        .then((success) => {
          this.setState({
            msg: "Added Off Game Successfully",
            isLoading: false,
            studentId: "",
            classId: "",
            sectionId: "",
            notes: "",
            reason: "",
            gameId: "",
            className: "",
            section: "",
            name: "",
            errorMsg: false,
            error: false,
          });
          this.props.snackbar();
        });
    }
  };
  render() {
    const { errors, isLoadingSearch, gameData } = this.state;

    const Form = (
      <ThemeContext.Consumer>
        {(context) => (
          <FormLayoutWrapper
            formName="Add Off Game"
            styleHead={true}
            borderColor={context.themeColors.primaryColor}
            borderRadius={15}
          >
            {this.state.errorMsg ? (
              <p style={{ color: "red", textAlign: "center" }}>
                Please fill the form properly
              </p>
            ) : (
              ""
            )}

            <div className="row" style={{ justifyContent: "center" }}>
              <TextInput
                feildName={"studentId"}
                onHandleChange={this.onHandleTextChange}
                errors={errors}
                stateData={this.state}
                placeholder={"Enter Student ID"}
                isValidte={false}
              />
            </div>
            <Button
              icon={true}
              width="100%"
              buttonClass="customButton"
              btnName="Search"
              loaderBmargin={5}
              stateData={{
                isLoading: isLoadingSearch,
              }}
              marginTop={20}
              onHandleSubmit={this.onSubmitGetStudent}
              margin={"0 auto"}
              textColor="white"
              btnBackColor={context.themeColors.primaryColor}
            />

            <div className="row">
              <TextInput
                feildName={"className"}
                inputLabelName={"Class Name"}
                errors={errors}
                stateData={this.state}
                placeholder="Class Name"
                readOnly={true}
                type={"text"}
                isValidte={false}
              />

              <TextInput
                feildName={"section"}
                inputLabelName={"Section"}
                errors={errors}
                stateData={this.state}
                placeholder="Section"
                readOnly={true}
                type={"text"}
                isValidte={false}
              />

              <TextInput
                marginTop={20}
                feildName={"name"}
                inputLabelName={"Student Name"}
                errors={errors}
                stateData={this.state}
                placeholder="Student Name"
                readOnly={true}
                type={"text"}
                isValidte={false}
              />

              <SelectionInput
                marginTop={20}
                feildName={"gameId"}
                selectName={"Game"}
                onHandleChange={(e) => this.onHandleSelectChange(e)}
                errors={errors}
                optionsArrys={gameData}
                selectedText={"Game"}
                stateData={this.state}
                // iconClassName={'fas fa-building'}
                optionType="dynamicWithPropIdAndName"
                property={"game"}
                propertyId={"id"}
                successflag={"successflag"}
                isValidte={false}
                // editUse={'staffEdit'}
              />

              <TextInput
                marginTop={20}
                feildName={"reason"}
                inputLabelName={"Reason"}
                onHandleChange={this.onHandleTextChange}
                errors={errors}
                stateData={this.state}
                placeholder={"Reason"}
                isValidte={false}
              />

              <TextArea
                feildName={"notes"}
                inputLabelName={"Notes"}
                onHandleChange={this.onHandleTextChange}
                errors={errors}
                stateData={this.state}
                // iconClassName={'fas fa-percentage'}
                placeholder={"Add Note"}
                isValidte={false}
              />
            </div>
            <br />
            <Button
              width="100%"
              buttonClass="customButton"
              btnName="Add"
              loaderBmargin={5}
              stateData={this.state}
              marginTop={20}
              onHandleSubmit={this.onSubmit}
              margin={"0 auto"}
              textColor={"white"}
              btnBackColor={context.themeColors.primaryColor}
            />
          </FormLayoutWrapper>
        )}
      </ThemeContext.Consumer>
    );
    return (
      <React.Fragment>
        <SnackBar msg={this.state.msg} />
        {Form}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    role: state.setUserReducer.users.role,
    name: state.setUserReducer.users.unique_name,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    sharedActions: bindActionCreators(sharedActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(AddOffGame);
