import * as actionTypes from '../Actions/actionTypes';
import isEmpty from 'lodash/isEmpty';

const defaultState = {
	apiFlag: true,
	Data: []
};

export default function setParentReducer(state = defaultState, action = {}) {
	switch (action.type) {
		case actionTypes.GET_ALL_PARENT:
			return { ...state, Data: [...action.payload] };
		case actionTypes.SET_LOADER_FLAG_PARENT:
			return { ...state, apiFlag: action.payload };
		case actionTypes.DELETE_PARENT:
			let filterCandidateReqList = state.Data.filter(
				filterOBJ => filterOBJ.id !== action.payload
			);
		// return {
		// 	...state,
		// 	Data: filterCandidateReqList
		// };
		case actionTypes.EDIT_PARENT_RECORD:
			var adminData = [...state.Data];
			var index = adminData.findIndex(obj => obj.id === action.payload.id);
			adminData[index].campusAddress = action.payload.campusName;
			adminData[index].campusName = action.payload.campusName;
			adminData[index].campusCity = action.payload.campusCity;
			adminData[index].campusPhoneNumber = action.payload.campusPhoneNumber;

			return { ...state, Data: [...adminData] };
		default:
			return state;
	}
}
