import React, { Component } from 'react';
import DateandTime from './../Transport/DateandTime';
import GeneralSelectionOptions from '../../components/Admission/GeneralSelectionOptions';
import GeneralRadioOptionSelection from '../../components/Admission/GeneralRadioOptionSelection';
export default class StudentTransport extends Component {
	constructor(props) {
		super(props);
		this.state = {
			gridNum: 4
		};
	}
	render() {
		const {
			onHandleTextChange,
			stateData,
			onCustomTranportTextChange,
			routesData,
			onCustomChange,
			onChangeNoOptions,
			onChangeTransportStatus
		} = this.props;

		return (
			<div>
				<section class="mb-4">
					<DateandTime />
					<div
						class="row"
						style={{
							justifyContent: 'left',
							textAlign: 'left'
						}}
					>
						<div class="col-md-9 mb-md-0 mb-5">
							<form
								id="contact-form"
								name="contact-form"
								// onSubmit={this.onHandleSubmit}
								noValidate
							>
								{/*<div class="row">*/}
								{/*<div class="col-md-12">*/}
								{/*<div class="md-form mb-0">*/}
								{/*</div>*/}
								{/*</div>*/}
								{/*</div>*/}
								<br />
								<div>
									<div>
										<GeneralRadioOptionSelection
											onHandleTextChange={onHandleTextChange}
											onChangeTransportStatus={onChangeTransportStatus}
											stateData={stateData}
											Name="School Bus:"
											idForYes="customRadiotransY"
											idForNo="customRadiotransN"
											feildName="transportStatus"
										/>
									</div>
									<div>
										{stateData.transportStatus === null ? (
											<p></p>
										) : stateData.transportStatus === 'Yes' ? (
											<div>
												<div class="col-lg-12">
													<div class="md-form mb-0">
														<label for="routeName" class="">
															Route Name
														</label>
														<br />
														<select
															style={{
																borderTop: 0,
																borderLeft: 0,
																borderRight: 0,
																boxShadow: 'none',
																width: '100%'
															}}
															onChange={e => onCustomChange(e)}
															class="custom-select"
															name="section"
															// value={stateData.section}
														>
															<GeneralSelectionOptions
																sectionType={'StudentTransport'}
																pickUp={stateData.pickUp}
																routeData={routesData && routesData.transport}
																{...this.props}
															/>
														</select>
													</div>
													<div class="col-md-12">
														<div class="md-form mb-0">
															<label for="name" class="">
																<i
																	className="fas fa-house animated fadeIn"
																	style={{ marginRight: 4 }}
																/>
																Pick Up
															</label>
															<input
																style={{
																	borderTop: 0,
																	borderLeft: 0,
																	borderRight: 0,
																	boxShadow: 'none',
																	width: '80%'
																}}
																readOnly={true}
																value={stateData.pickUp}
																name="pickUp"
																type="text"
																class="form-control"
																id="exampleInputEmail1"
																placeholder="Pick Up"
															/>
														</div>
													</div>
													<div class="col-md-12">
														<div class="md-form mb-0">
															<label for="name" class="">
																<i
																	className="fas fa-house animated fadeIn"
																	style={{ marginRight: 4 }}
																/>
																Driver Name
															</label>
															<input
																style={{
																	borderTop: 0,
																	borderLeft: 0,
																	borderRight: 0,
																	boxShadow: 'none',
																	width: '80%'
																}}
																readOnly={true}
																value={stateData.driverName}
																name="driverName"
																type="text"
																class="form-control"
																id="exampleInputEmail1"
																placeholder="Driver Name"
															/>
														</div>
													</div>
													<div class="col-md-12">
														<div class="md-form mb-0">
															<label for="name" class="">
																<i
																	className="fas fa-house animated fadeIn"
																	style={{ marginRight: 4 }}
																/>
																Driver License
															</label>
															<input
																style={{
																	borderTop: 0,
																	borderLeft: 0,
																	borderRight: 0,
																	boxShadow: 'none',
																	width: '80%'
																}}
																readOnly={true}
																value={stateData.driverLicense}
																name="driverLicense"
																type="text"
																class="form-control"
																id="exampleInputEmail1"
																placeholder="Driver License"
															/>
														</div>
													</div>
													<div class="col-md-12">
														<div class="md-form mb-0">
															<label for="name" class="">
																<i
																	className="fas fa-house animated fadeIn"
																	style={{ marginRight: 4 }}
																/>
																Driver Id Card No
															</label>
															<input
																style={{
																	borderTop: 0,
																	borderLeft: 0,
																	borderRight: 0,
																	boxShadow: 'none',
																	width: '80%'
																}}
																readOnly={true}
																value={stateData.driverIdCardNo}
																name="driverIdCardNo"
																type="text"
																class="form-control"
																id="exampleInputEmail1"
																placeholder="Driver Id Card No"
															/>
														</div>
													</div>
													<div class="col-md-12">
														<div class="md-form mb-0">
															<label for="name" class="">
																<i
																	className="fas fa-house animated fadeIn"
																	style={{ marginRight: 4 }}
																/>
																Mobile No
															</label>
															<input
																style={{
																	borderTop: 0,
																	borderLeft: 0,
																	borderRight: 0,
																	boxShadow: 'none',
																	width: '80%'
																}}
																value={stateData.driverMobileNo}
																name="mobileNo"
																type="text"
																readOnly={true}
																class="form-control"
																id="exampleInputEmail1"
																placeholder="Driver Mobile No"
															/>
														</div>
													</div>
													<div class="col-md-12">
														<div class="md-form mb-0">
															<label for="name" class="">
																<i
																	className="fas fa-house animated fadeIn"
																	style={{ marginRight: 4 }}
																/>
																Transport Type
															</label>
															<input
																style={{
																	borderTop: 0,
																	borderLeft: 0,
																	borderRight: 0,
																	boxShadow: 'none',
																	width: '80%'
																}}
																value={stateData.transportType}
																name="mobileNo"
																type="text"
																readOnly={true}
																class="form-control"
																id="exampleInputEmail1"
																placeholder="Transport Type"
															/>
														</div>
													</div>
													<div class="col-md-12">
														<div class="md-form mb-0">
															<label for="name" class="">
																<i
																	className="fas fa-house animated fadeIn"
																	style={{ marginRight: 4 }}
																/>
																Vehicle No
															</label>
															<input
																style={{
																	borderTop: 0,
																	borderLeft: 0,
																	borderRight: 0,
																	boxShadow: 'none',
																	width: '80%'
																}}
																value={stateData.vehicleNo}
																name="mobileNo"
																type="text"
																readOnly={true}
																class="form-control"
																id="exampleInputEmail1"
																placeholder="Vehicle No"
															/>
														</div>
													</div>
												</div>
											</div>
										) : (
											<div>
												<div class="col-lg-12">
													<div class="md-form mb-0">
														<label for="name" class="">
															<i
																className="fas fa-house animated fadeIn"
																style={{ marginRight: 4 }}
															/>
															Choose
														</label>
														<div style={{ marginLeft: 4, display: 'flex' }}>
															<div class="custom-control custom-radio">
																<input
																	onChange={e => onChangeNoOptions(e)}
																	type="radio"
																	checked={
																		stateData.parentOrByWalk === 'ByWalk'
																			? true
																			: false
																	}
																	class="custom-control-input"
																	id="customRadio3"
																	name="parentOrByWalk"
																	value={'ByWalk'}
																/>
																<label
																	class="custom-control-label"
																	for="customRadio3"
																>
																	ByWalk
																</label>
															</div>
															&nbsp;&nbsp;
															<div class="custom-control custom-radio custom-control-inline">
																<input
																	onChange={e => onChangeNoOptions(e)}
																	type="radio"
																	checked={
																		stateData.parentOrByWalk === 'ParentPick'
																			? true
																			: false
																	}
																	class="custom-control-input"
																	id="customRadio4"
																	name="parentOrByWalk"
																	value="ParentPick"
																/>
																<label
																	class="custom-control-label"
																	for="customRadio4"
																>
																	Parent Pick
																</label>
															</div>
															<div class="custom-control custom-radio custom-control-inline">
																<input
																	onChange={e => onChangeNoOptions(e)}
																	type="radio"
																	checked={
																		stateData.parentOrByWalk === 'DriverPick'
																			? true
																			: false
																	}
																	class="custom-control-input"
																	id="customRadio5"
																	name="parentOrByWalk"
																	value="DriverPick"
																/>
																<label
																	class="custom-control-label"
																	for="customRadio5"
																>
																	Driver Pick
																</label>
															</div>
														</div>
													</div>
												</div>
											</div>
										)}
									</div>
									{stateData.parentOrByWalk === 'DriverPick' ? (
										<div>
											<div class="col-lg-12">
												<div class="md-form mb-0">
													<div class="col-md-12">
														<div class="md-form mb-0">
															<label for="name" class="">
																<i
																	className="fas fa-house animated fadeIn"
																	style={{ marginRight: 4 }}
																/>
																Driver Name
															</label>
															<input
																style={{
																	borderTop: 0,
																	borderLeft: 0,
																	borderRight: 0,
																	boxShadow: 'none',
																	width: '80%'
																}}
																// readOnly={true}
																onChange={e => onHandleTextChange(e)}
																value={stateData.otherDriverName}
																name="otherDriverName"
																type="text"
																class="form-control"
																id="exampleInputEmail1"
																placeholder="Driver Name"
															/>
														</div>
													</div>
													<div class="col-md-12">
														<div class="md-form mb-0">
															<label for="name" class="">
																<i
																	className="fas fa-house animated fadeIn"
																	style={{ marginRight: 4 }}
																/>
																Vehicle No
															</label>
															<input
																style={{
																	borderTop: 0,
																	borderLeft: 0,
																	borderRight: 0,
																	boxShadow: 'none',
																	width: '80%'
																}}
																onChange={e => onHandleTextChange(e)}
																value={stateData.otherCarNo}
																name="otherCarNo"
																type="text"
																// readOnly={true}
																class="form-control"
																id="exampleInputEmail1"
																placeholder="Vehicle No"
															/>
														</div>
													</div>
													<div class="col-md-12">
														<div class="md-form mb-0">
															<label for="name" class="">
																<i
																	className="fas fa-house animated fadeIn"
																	style={{ marginRight: 4 }}
																/>
																Driver Id Card No
															</label>
															<input
																style={{
																	borderTop: 0,
																	borderLeft: 0,
																	borderRight: 0,
																	boxShadow: 'none',
																	width: '80%'
																}}
																onChange={e => onHandleTextChange(e)}
																// readOnly={true}
																value={stateData.otherDriverCardIdNo}
																name="otherDriverCardIdNo"
																type="text"
																class="form-control"
																id="exampleInputEmail1"
																placeholder="Driver Id Card No"
															/>
														</div>
													</div>
													<div class="col-md-12">
														<div class="md-form mb-0">
															<label for="name" class="">
																<i
																	className="fas fa-house animated fadeIn"
																	style={{ marginRight: 4 }}
																/>
																Mobile No
															</label>
															<input
																style={{
																	borderTop: 0,
																	borderLeft: 0,
																	borderRight: 0,
																	boxShadow: 'none',
																	width: '80%'
																}}
																onChange={e => onHandleTextChange(e)}
																value={stateData.otherDriverMobileNo}
																name="otherDriverMobileNo"
																type="text"
																// readOnly={true}
																class="form-control"
																id="exampleInputEmail1"
																placeholder="Driver Mobile No"
															/>
														</div>
													</div>
												</div>
											</div>
										</div>
									) : (
										<p></p>
									)}
								</div>
								<br />
								<br />
							</form>
							<div class="status" />
						</div>
					</div>
				</section>
			</div>
		);
	}
}
