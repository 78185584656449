import $ from "jquery";
import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import Layout from "../src/Container/HOC/Layout";
import InActivePage from "./components/InActive";
import "./components/SnackBar/viewroutes.css";
// academicDetails
import { AcademicLMS, AcademicSID } from "./Container/Academic";
import AcademicCMS from "./Container/Academic/AcadenucCMS";
import AcademicsDashboard from "./Container/AcademicsDashboard";
import AddAdmin from "./Container/Admin/Add_Admin_Component/AddAdminNew";
import GM from "./Container/Admin/Add_Admin_Component/AddGm";
import ViewGm from "./Container/Admin/Add_Admin_Component/ViewGm";
import ViewAdmin from "./Container/Admin/ViewAdmin";
import AdministrationDashboard from "./Container/AdministrationDashboard";
import AdministrationManagers from "./Container/AdministrationManagers/AdministrationManagers";
import CensusManager from "./Container/AdministrationManagers/CensusManager";
import ViewCensusManager from "./Container/AdministrationManagers/ViewCensusManager";
import AdmissionForm from "./Container/Admission/AdmissionForm";
import EditAddRecord from "./Container/Admission/EditAddRecord";
import EditAdmissionForm from "./Container/Admission/EditAdmissionForm";
import PrintAdmissionForm from "./Container/Admission/PrintAdmissionForm";
import UploadedFilesView from "./Container/Admission/UploadedFilesView";
import ViewAddForm from "./Container/Admission/ViewAddForm";
import AddAssignment from "./Container/Assignment/AddAssignment";
import ViewAssignment from "./Container/Assignment/ViewAssignment";
import ViewParticularStudent from "./Container/Assignment/ViewParticularStudent";
import AssignmentDashboard from "./Container/AssignmentDashboard";
import AddBudget from "./Container/Budget/AddBudget";
import BudgetScreen from "./Container/Budget/BudgetScreen";
import ViewBudget from "./Container/Budget/ViewBudget";
import BudgetDashboard from "./Container/BudgetDashboard";
import AddCampus from "./Container/Campus/AddCampus";
import ViewCampus from "./Container/Campus/ViewCampus";
import CampusDashboard from "./Container/CampusDashboard";
import AddCanteen from "./Container/Canteen/AddCanteen";
import AddCanteenCategory from "./Container/Canteen/AddCanteenCategory";
import AddCanteenItems from "./Container/Canteen/AddCanteenItems";
import ViewCanteen from "./Container/Canteen/ViewCanteen";
import ViewCanteenCategory from "./Container/Canteen/ViewCanteenCategory";
import ViewCanteenItems from "./Container/Canteen/ViewCanteenItems";
import CanteenDashboard from "./Container/CanteenDashboard";
import CensusDashboard from "./Container/CensusDashboard";
import AgeWiseSearch from "./Container/Census_Information/AgeWiseSearch";
import CategoryWiseSearch from "./Container/Census_Information/CategoryWiseSearch";
import ClassWiseSearch from "./Container/Census_Information/ClassWiseSearch";
import FamilyWiseSearch from "./Container/Census_Information/FamilyWiseSearch";
import ProgressBarComponent from "./Container/Census_Information/ProgressBarComponent";
import ClassSection from "./Container/Census_Information/SearchStudent";
import StudentResultStatus from "./Container/Census_Information/StudentResultStatus";
import UserWiseSearch from "./Container/Census_Information/UserWiseSearch";
import Check from "./Container/Check/Check";
import AddDepartment from "./Container/Department/AddDepartment";
import SearchStaffByDepartment from "./Container/Department/SearchStaffByDepartment";
import ViewDepartment from "./Container/Department/ViewDepartment";
import DepartmentDashboard from "./Container/DepartmentDashboard";
import AccountLedger from "./Container/Finance/AccountLedger";
import AddAccountLedger from "./Container/Finance/AddAccountLedger";
import AddBudgetItems from "./Container/Finance/AddBudgetItems";
import SearchItemsBySession from "./Container/Finance/SearchItemsBySession";
import ViewBudgetItems from "./Container/Finance/ViewBudgetItems";
import FinanceDashboard from "./Container/FinanceDashboard";
import FrontDeskDashboard from "./Container/FrontDeskDashboard";
import StaffEnquiry from "./Container/FrontDeskOfficer/StaffEnquiry";
import StudentEnquiry from "./Container/FrontDeskOfficer/StudentEnquiry";
import ViewAdmittedStaff from "./Container/FrontDeskOfficer/ViewAdmittedStaff";
import ViewAdmittedStudent from "./Container/FrontDeskOfficer/ViewAdmittedStudent";
import ViewStaffEnquiry from "./Container/FrontDeskOfficer/ViewStaffEnquiry";
import ViewStaffEnquiryPagination from "./Container/FrontDeskOfficer/ViewStaffEnquiryPagination";
import ViewStudentEnquiry from "./Container/FrontDeskOfficer/ViewStudentEnquiry";
import ViewStudentEnquiryPagination from "./Container/FrontDeskOfficer/ViewStudentEnquiryPagination";
import AddHomeWork from "./Container/HomeWork/AddHomeWork";
import ViewHomeWork from "./Container/HomeWork/ViewHomeWork";
import HrDevelopmentDashboard from "./Container/HrDevelopmentDashboard";
import EditHrDevelopment from "./Container/Hr_Component/EditHrDevelopment";
import HrDashboard from "./Container/Hr_Component/HrDashboard";
import HrDevelopmentAndTraining from "./Container/Hr_Component/HrDevelopmentAndTraining";
import ViewHrDevelopment from "./Container/Hr_Component/ViewHrDevelopment";
import AddInterview from "./Container/Interview/AddInterview";
import AddCategory from "./Container/Inventory/AddCategory";
import AddItems from "./Container/Inventory/AddItems";
import AddStock from "./Container/Inventory/AddStock";
import AddStockUsage from "./Container/Inventory/AddStockUsage";
import AddSuppliers from "./Container/Inventory/AddSuppliers";
import ViewCategory from "./Container/Inventory/ViewCategory";
import ViewItems from "./Container/Inventory/ViewItems";
import IndentAdminDashboard from "./Container/IndentAdminDashboard";
// import from './Container/Transport/'
import ViewStock from "./Container/Inventory/ViewStock";
import ViewStockUsage from "./Container/Inventory/ViewStockUsage";
import ViewSuppliers from "./Container/Inventory/ViewSuppliers";
import InventoryDashboard from "./Container/InventoryDashboard";
import {
  LessonPerWeek,
  LessonPerWeekScreen,
  ViewLessonWeek,
} from "./Container/LessonPerWeek";
import LessonPlanDashboard from "./Container/LessonPlanDashboard";
import AddLessonPlan from "./Container/LessonPlans/AddLessonPlan";
import ViewLessonPlan from "./Container/LessonPlans/ViewLessonPlan";
import AddPost from "./Container/Post/AddPost";
import SearchStaffByPost from "./Container/Post/SearchStaffByPost";
import ViewPost from "./Container/Post/ViewPost";
import PostDashboard from "./Container/PostDashboard";
import AddQuiz from "./Container/Quiz/AddQuiz";
import ViewQuiz from "./Container/Quiz/ViewQuiz";
import QuizDashboard from "./Container/QuizDashboard";
import AddQuizInfo from "./Container/QuizInfo/AddQuizInfo";
import ViewQuizInfo from "./Container/QuizInfo/ViewQuizInfo";
import NotificationAppraisal from "./Container/Salary/NotificationAppraisal";
import Salary from "./Container/Salary/Salary.jsx";
import ViewAppraisal from "./Container/Salary/ViewAppraisal";
// staff imports
import StaffImageUploadSection from "./Container/Staff/imageUploadSection/StaffImageUploadSection";
import Staff from "./Container/Staff";
import EditStaffFrom from "./Container/Staff/staffEditForm.jsx/EditStaffForm";
import StaffEditStepsRendering from "./Container/Staff/staffEditForm.jsx/StaffEditStepsRendering";
import StaffFileUploadView from "./Container/Staff/uploadFileView/StaffFileUploadView";
import StaffView from "./Container/Staff/View/ViewStaff";
import PrintStaffAdmissionForm from "./Container/StaffAdmissionForm/PrintStaffAdmissionForm";
import StudentAdmissionDashboard from "./Container/StudentAdmissionDashboard";
import PrintAdmissionFormFinal from "./Container/StudentAdmissionForm/PrintAdmissionFormFinal";
import StaffAdmissionForm from "./Container/StudentAdmissionForm/PrintStaffForm";
import TestDashboard from "./Container/TestDashboard";
import AddTestInfo from "./Container/TestInfo/AddTestInfo";
import ViewTestInfo from "./Container/TestInfo/ViewTestInfo";
// time table start
import {
  TimeTable,
  TimeTableScreen,
  AcademicTimeTableModule,
} from "./Container/Time-Table";
import { TimeTableView } from "./Container/Time-Table/Time-Table-View";
import AddDriver from "./Container/Transport/AddDriver";
import AddRoute from "./Container/Transport/AddRoute";
import AddRoutes from "./Container/Transport/AddRoutes";
import AddVehicle from "./Container/Transport/AddVehicle";
import AssignVehicle from "./Container/Transport/AssignVehicle";
import CreateTransportFees from "./Container/Transport/CreateTransportFees";
import ViewAssignVehicle from "./Container/Transport/ViewAssignVehicle";
import ViewDriver from "./Container/Transport/ViewDriver";
import ViewDriverHistory from "./Container/Transport/ViewDriverHistory";
import ViewRoutes from "./Container/Transport/ViewRoutes";
import ViewVehicle from "./Container/Transport/ViewVehicle";
import TransportDashboard from "./Container/TransportDashboard";
import Vacancy from "./Container/Vacancy/Vacancy";
import AddTest from "./Container/Test/AddTest";
import ViewTest from "./Container/Test/ViewTest";
import AddExamDetails from "./Container/Exam/AddExamDetails";
import AddExam from "./Container/Exam/AddExam";
import ViewExamDetails from "./Container/Exam/ViewExamDetails";
import AddExamInfo from "./Container/Exam/AddExamInfo";
import ViewExamInfo from "./Container/Exam/ViewExamInfo";
import ExamDashboard from "./Container/ExamDashboard";
import AddPastPapers from "./Container/PastPapers/AddPastPapers";
import ViewPastPapers from "./Container/PastPapers/ViewPastPapers";
import AddSyllabus from "./Container/Syllabus/AddSyllabus";
import EditorComponent from "./Container/Syllabus/EditorComponent";
import ViewEditorComponent from "./Container/Syllabus/ViewEditorComponent";
import ViewSyllabus from "./Container/Syllabus/ViewSyllabus";
import ViewSyllabusTemplate from "./Container/Syllabus/ViewSyllabusTemplate";
import ViewAllSyllabus from "./Container/Syllabus/ViewAllSyllabus";
import SyllabusDashboard from "./Container/SyllabusDashboard";
import PastPaperDashboard from "./Container/PastPaperDashboard";
import StudentSkill from "./Container/StaffLoginModule/StudentSkill";
import ViewStudentSkills from "./Container/StaffLoginModule/ViewStudentSkills";
import PrintAllStudentSkills from "./Container/StaffLoginModule/PrintAllStudentSkills";
import ViewStudentSkillList from "./Container/StaffLoginModule/ViewStudentSkillList";
import PrintSkillReport from "./Container/StaffLoginModule/PrintSkillReport";
import StudentSkillDashboard from "./Container/StudentSkillDashboard";
import DescriptiveReport from "./Container/DescriptiveReportModule/DescriptiveReport";
import EditDescriptiveReport from "./Container/DescriptiveReportModule/EditDescriptiveReport";
import { EDIT_DESCRIPTIVE_RECORD } from "./Actions/actionTypes";
import PrintDescriptiveReport from "./Container/DescriptiveReportModule/PrintDescriptiveReport";
import StudentSkillView from "./Container/StudentLoginModule/StudentSkillView";
import DiscriptiveDashboard from "./Container/DiscriptiveDashboard";
import StudentDescriptiveView from "./Container/StudentLoginModule/StudentDescriptiveView";
import StudentSkillViewAdmin from "./Container/StudentSkillAdmin/StudentSkillViewAdmin";
import StudentDescriptiveViewAdmin from "./Container/StudentSkillAdmin/StudentDescriptiveViewAdmin";
import StudentSkillViewP from "./Container/ParentLoginModule/StudentSkillViewP";
import StudentDescriptiveViewP from "./Container/ParentLoginModule/StudentDescriptiveViewP";
import AddParent from "./Container/Parent/AddParent";
import AlreadyRegister from "./Container/Parent/AlreadyRegister";
import SearchChildByParent from "./Container/Parent/SearchChildByParent";
import SearchParentByChild from "./Container/Parent/SearchParentByChild";
import RegisterStudentToParent from "./Container/Parent/RegisterStudentToParent";

// end academicDetails

//#region Leave
import { AddLeave, ViewLeave, ViewLeaveByStudent } from "./Container/Leave";
//#endregion

//#region AssignSubjectTeacher
import {
  AssignSubject,
  ViewAssignSubject,
} from "./Container/AssignSubjectTeacher";
//#endregion

//#region Indent
import {
  /* ViewBillingTemplate, */ IndentFormTemplate,
  AddIndent,
  ViewIndentForm,
  ViewIndentByStaff,
} from "./Container/Indent";
//#endregion

//#region TeacherObservation
import {
  TeacherObservationBlankTemplate,
  AddTeacherEvaluation,
  ViewTeacherEvaluation,
  EditEvaluationForm,
  ViewEvaluationByStaff,
  EvaluationByStaffTemplate,
} from "./Container/TeacherObservation";
//#endregion

//#region VisitorCard
import {
  GenerateVisitorCardView,
  AddVisitorEntry,
  VisitorEntryRecord,
} from "./Container/Visitor";
//#endregion

//#region MedicalCenter Module
import {
  AddDoctor,
  ViewDoctor,
  AddPatient,
  PatientDetail,
  MedicalSlipTemplate,
  MedicalHistory,
  AddPatient2,
} from "./Container/MedicalCentre";
//#endregion

//# Dashboards
import ClassDashboard from "./Container/ClassDashboard";
import SectionDashboard from "./Container/SectionDashboard";
import SessionDashboard from "./Container/SessionDashboard";
import SubjectDashboard from "./Container/SubjectDashboard";
import ClassTeacherDashboard from "./Container/ClassTeacherDashboard";
import IndentDashboard from "./Container/IndentDashboard";
import IndentStaffDashboard from "./Container/IndentStaffDashboard";
import TeacherEvaluationDashboard from "./Container/TeacherEvaluationDashboard";
import PastoralDashboard from "./Container/PastoralDashboard";
import MedicalDashboard from "./Container/MedicalDashboard";
import DoctorDashboard from "./Container/DoctorDashboard";
import PatientDashboard from "./Container/PatientDashboard";
import CampusSecurityDashboard from "./Container/CampusSecurityDashboard";
import LeaveDashboard from "./Container/LeaveDashboard";
import StudentLeaveDashboard from "./Container/StudentLeaveDashboard";
import TourDashboard from "./Container/TourDashboard";
import ActivityDashboard from "./Container/ActivityDashboard";
import CertificateDashboard from "./Container/CertificateDashboard";
import OtherCertificateDashboard from "./Container/OtherCertificateDashboard";
import AwardDashboard from "./Container/AwardDashboard";
import CertificateCategoryDashboard from "./Container/CertificateCategoryDashboard";
import GameDashboard from "./Container/GameDashboard";
import OffGameDashboard from "./Container/OffGameDashboard";
import EventCertificateDashboard from "./Container/EventCertificateDashboard";
import StudentCoordinatorDashboard from "./Container/StudentCoordinatorDashboard";
import VehicleDashboard from "./Container/VehicleDashboard";
import DriverDashboard from "./Container/DriverDashboard";
import RouteDashboard from "./Container/RouteDashboard";
import TransportMaintenanceDashboard from "./Container/TransportMaintenanceDashboard";
import StaffCoordinatorDashboard from "./Container/StaffCoordinatorDashboard";
import AssignSubjectDashboard from "./Container/AssignSubjectDashboard";
import AssignHODDashboard from "./Container/AssignHODDashboard";
import ExclusionDashboard from "./Container/ExclusionDashboard";
import AssignCoordinatorDashboard from "./Container/AssignCoordinatorDashboard";
import StudentProfileDashboard from "./Container/StudentProfileDashboard";
import CommunicationManagerDashboard from "./Container/CommunicationManagerDashboard";
import CMSManagerDashboard from "./Container/CMSManagerDashboard";
import CMSStaffDashboard from "./Container/CMSStaffDashboard";
import BioMetricDashboard from "./Container/BioMetricDashboard";
import BioMetricStaffDashboard from "./Container/BioMetricStaffDashboard";
import SecurtiyDashboard from "./Container/SecurityDashboard";
import AcademicAdminDashboard from "./Container/AcademicAdminDashboard";
import FrontDeskStudentDashboard from "./Container/FrontDeskStudentDashboard";
import CommuncationStaffDashboard from "./Container/CommuncationStaffDashboard";
import CommunicationParentDashboard from "./Container/CommunicationParentDashboard";
import AdminCMSDashboard from "./Container/AdminCMSDashboard";
import IndentAdminAdd from "./Container/IndentAdminAdd";
import IndentAdminView from "./Container/IndentAdminView";
import IndentManagerDashboard from "./Container/IndentManagerDashboard";
import IndentManagerAdd from "./Container/IndentManagerAdd";
import IndentManagerView from "./Container/IndentManagerView";

//# Dashboards

//#region Tour Module
import {
  AddTour,
  ViewTour,
  ViewClassesInTour,
  ViewFilterTour,
  ViewTourByStaff,
  ViewTourByStudent,
} from "./Container/Tour";
//#endregion

//#region Other Certificate Module
import {
  AddOtherCertificate,
  ViewOtherCertificate,
  OtherCertificateTemplate1,
  OtherCertificateTemplate2,
  AddCertificateCategory,
  ViewCertificateCategory,
  OtherCertificateCategoryTemplate1,
  OtherCertificateCategoryTemplate2,
} from "./Container/OtherCertificate";
//#endregion

//#region Game
import { AddGame, ViewGame, AddOffGame, ViewOffGame } from "./Container/Game";
//#endregion

//#region Student Coordinator
import {
  AssignClassNewAdmit,
  ClassOfStudent,
  TransferStudent,
} from "./Container/StudentCoordinater";
//#endregion

//#region Award Module
import {
  AddAwardCategory,
  ViewAwardCategory,
  AddAward,
  ViewAward,
  ViewStudentInAward,
  ViewStudentInReward,
  AwardTemplate1,
  AwardTemplate2,
  ViewAwardByStudent,
  ViewRewardByStudent,
} from "./Container/Award";
//#endregion

//#region Event Module
import {
  AddEventCertificate,
  ViewEventCertificate,
  EventAttendanceCertificate,
  EventCertificateTemplate1,
  EventCertificateTemplate2,
  EventCertificateByStudent,
  EventCertificateByStaff,
} from "./Container/EventCertificate";
//#endregion

//#region Transport Module
import {
  AddTransportMaintenance,
  ViewTransportMaintenance,
  MaintenanceReport,
} from "./Container/TransportCoordinator";
//#endregion

//#region Staff Coordinator
import {
  StaffToHOD,
  ViewStaffInHOD,
  AssignStaffCoordinatorRenew,
  ViewStaffSubjectRenew,
  AssignSubjectToStaffRenew,
  ViewStaffCoordinatorRenew,
} from "./Container/StaffCoordinator";
//#endregion

//#region Exclusion
import {
  AddExclusion,
  ViewExclusion,
  InvestigationCommittee,
  ViewInvestigationCommittee,
  InvestigationList,
  StudentExclusionRecord,
  ViewExclusionByStaff,
} from "./Container/Exclusion";
//#endregion

//#region Student Profile
import {
  StudentSkillByStudentProfileRenew,
  StudentDescriptiveByStudentProfileRenew,
  ContinousAssessmentByStudentProfileRenew,
  StudentProgressReportByStudentProfileRenew,
  PrintStudentProgressReportStudentProfileRenew,
  StudentDisciplineByStudentProfileRenew,
  PrintSkillReportStudentProfileRenew,
  PrintDescriptiveReportStudentProfileRenew,
  PrintDisciplineReportStudentProfileRenew,
  StudentAttendanceByStudentProfileRenew,
} from "./Container/StudentProfileRenew";
//#endregion

//#region Class Teacher
import {
  AddClassTeacherRenew,
  ViewClassTeacherRenew,
} from "./Container/ClassTeacherRenew";
//#endregion Class Teacher

//#region CommunicationCoordinator
import {
  ListOfRecipients,
  MessageDean,
  MessageHOD,
  MessageAcademicCoordinator,
  MessageManager,
  MessageStaff,
  MessageParent,
  ManagerInbox,
  StaffInbox,
  DeanInbox,
  HODInbox,
  ACInbox,
  CoordinatorInbox,
  HODMessage,
  DeanMessage,
  ManagerMessage,
  AcademicCoordinatorMessage,
  StaffMessage,
  InboxComponent,
} from "./Container/CommunicationCoordinator";
//#endregion

//#region Portal Coordinator
import {
  StudentUsers,
  StaffUsers,
  ParentUsers,
  DeanUsers,
  CoordinatorUsers,
  HODUsers,
  ManagerUsers,
  AdminUsers,
  UsersList,
} from "./Container/PortalCoordinator";
//#endregion

//#region Bio Metric
import {
  BioMetricAttendance,
  BioMetricRegistered,
  PrintBioMetricAttendance,
  PrintBioMetricRegistered,
} from "./Container/Bio-Metric";
//#endregion

//#region
import {
  TeacherMessageParent,
  ParentMessageTeacher,
  ParentTeacherInbox,
} from "./Container/ParentTeacherCommunication";
//#endregion

//#region
import {
  AddLoginTimeLimit,
  ViewLoginTimeLimit,
} from "./Container/LoginTimeLimit";
//#endregion

//#region Staff Time Table
import {
  StaffTimeTable,
  DepartmentWiseTimeTable,
  StaffTimeTableByCoordinator,
  ConsolidatedTimetable,
  StudentTimeTable,
} from "./Container/StaffTimeTable";
//#endregion

//#region CLASS WORK
import { AddClassRenew, ViewClassRenew } from "./Container/ClassRenew";
//#endregion

//#region  SECTION WORK
import { AddSectionRenew, ViewSectionRenew } from "./Container/SectionRenew";
//#endregion

//#region  PASTORAL WORK START
import { AddSessionRenew, ViewSessionRenew } from "./Container/SessionRenew";
//#endregion

//#region SUBJECT WORK
import { AddSubjectRenew, ViewSubjectRenew } from "./Container/SubjectRenew";
//#endregion

// Role: Staff imports of Student Discipline Record
import StudentDisciplineRecord from "./Container/StaffLoginModule/StudentDisciplineRecord";
import ViewStudentDiscipline from "./Container/StaffLoginModule/ViewStudentDiscipline";
import EditStudentDisciplineRecord from "./Container/StaffLoginModule/EditStudentDisciplineRecord";
import StudentDisciplineReports from "./Container/StaffLoginModule/Reports/StudentDisciplineReports";
import SubjectDisciplineMarks from "./Container/StaffLoginModule/SubjectDisciplineMarks/SubjectDisciplineMarks";
import SubjectDisciplineMarksReport from "./Container/StaffLoginModule/Reports/SubjectDisciplineMarksReport";
import ParentStudentDiscipline from "./Container/ParentLoginModule/ParentStudentDiscipline/ParentStudentDiscipline";
// end Role : STAFF  imports of Student Discipline Record

// Role : Student => Student Discipline

import { StudentRoleDiscipline } from "./Container/StudentLoginModule";

// End Imports of Role : Student => Student Discipline

//  Role : Admin => Admin Student Discipline

import { AdminStudentDiscipline, EditAdminDiscipline } from "./Container/Admin";
import ClassDiscipline from "./Container/StaffLoginModule/ClassDisciplineModule/ClassDiscipline";
import AddClassDiscipline from "./Container/StaffLoginModule/ClassDisciplineModule/AddClassDiscipline";
import ViewClassDiscipline from "./Container/StaffLoginModule/ClassDisciplineModule/ViewClassDiscipline";
import ViewClassDisciplinaryRecords from "./Container/StaffLoginModule/ClassDisciplineModule/ViewClassDisciplinaryRecords";
import Sheet1 from "./Container/StaffLoginModule/ClassDisciplineModule/Sheet1";
import ClassDisciplineDashboard from "./Container/ClassDisciplineDashboard";
import UpdateClassDiscipline from "./Container/ClassDisciplineAdmin/UpdateClassDiscipline";
import StaffDocumentUploadSection from "./Container/Staff/StaffDocumentUploadSection/StaffDocumentUploadSection";
import DocumentSectionStep from "./Container/Staff/DocumentSectionStep/DocumentSectionStep";
// imports from Admission Form

import { StudentImageUpload, ParentDocsUpload } from "./Container/Admission";
import StudentDocsUpload from "./Container/Admission/StudentDocsUpload/StudentDocsUpload";

// end imports from Admission form
import CommunicationDashboard from "./Container/ComunicationDashboard";
import ParentPortalManagerDashboard from "./Container/ParentPortalManagerDashboard";
import StaffMainScreen from "./Container/Staff/StaffMainScreen/StaffMainScreen";
import StudentAttendance from "./Container/StudentAttendance/StudentAttendance";
import ViewStudentAttendance from "./Container/StudentAttendance/ViewStudentAttendance";
import AddGm from "./Container/Admin/Add_Admin_Component/AddGm";
import AddAdmissionManager from "./Container/AdmissionManager/AddManager";
import AddManager from "./Container/AdmissionManager/AddManager";
import ViewManager from "./Container/AdmissionManager/ViewManager";
import ManagersDashboard from "./Container/ManagersDashboard";
import AdmissionDashboard from "./Container/AdmissionDashboard";
import StudentAttendanceDashboard from "./Container/StudentAttendanceDashboard";
import AddFeeType from "./Container/FeesModule/AddFeeTypeNew";
import ViewFeeType from "./Container/FeesModule/ViewFeeTypeNew";
import FeesCollection from "./Container/FeesModule/FeesCollectionNew";
import FeeReceipt from "./Container/FeesModule/FeeReceipt";
import MonthlyFeeList from "./Container/FeesModule/MonthlyFeeListNew";
import StudentAttendanceView from "./Container/StudentLoginModule/StudentAttendanceView";
import AccountsDashboard from "./Container/AccountsDashboard";
import FeesDashboard from "./Container/FeesDashboard";
import FeeReportDashboard from "./Container/FeeReportDashboard";
import FloorPlanDashboard from "./Container/FloorPlan";
import Floor from "./Container/Floor";
import Room from "./Container/Room"
import StudentFeeHistoryP from "./Container/ParentLoginModule/StudentFeeHistoryP";
import MonthlyAttendanceView from "./Container/StudentAttendance/MonthlyAttendanceView";
import RangeAttendanceView from "./Container/StudentAttendance/RangeAttendanceView";


import AddDeanLeave from "./Container/DeanLeaves/AddDeanLeave"
import ViewDeanLeave from "./Container/DeanLeaves/ViewDeanLeave"
import AddDeanShortLeave from "./Container/DeanLeaves/AddDeanShortLeave"
import ViewDeanShortLeave from "./Container/DeanLeaves/ViewDeanShortLeave"
// End of Role : Admin =>  Admin Student Discipline

//  Start of Continouse Assesment imports

import {
  ContinousAssesmentReport,
  ContinouseAssesmentViewList,
  PrimaryInfo,
  PrimaryInfoView,
  PrimaryInfoDelete,
  ContinuousSubToolView,
  CaSubToolCreation,
  ContinuousCreateTools,
  ContnuousRenderTools,
  AssignMarks,
  CaAverageReportSheet,
  ContinousEditAssignMarksModule,
  SelectionCriteria,
  ContinousNewToolCreation,
  CaAddExamFeature,
  CaViewExamFeature,
  CaViewFeature,
  CaAddFeature,
  CaAddExamV2Feature,
  CaViewExamV2Feature,
  CaSingleStudentTermReport,
  CaResultSheetSearch,
} from "./Container/Continouse-Assesment";
import CADashboard from "./Container/Continouse-Assesment/Dashboard/CADashboard";

import AddPayroll from "./Container/PayRoll/AddPayroll";
import ViewPayroll from "./Container/PayRoll/ViewPayroll";
import ViewPayslip from "./Container/PayRoll/ViewPayslip";
import PayslipDetails from "./Container/PayRoll/PayslipDetails";
import PayRollDashboard from "./Container/PayRollDashboard";
import AddBook from "./Container/Library/AddBook";
import ViewBook from "./Container/Library/ViewBook";
import IssueBook from "./Container/Library/IssueBook";
import ViewIssuedBook from "./Container/Library/ViewIssuedBook";
import LibraryDashboard from "./Container/LibraryDashboard";
import AddEvent from "./Container/Event/AddEvent";
import ViewEvent from "./Container/Event/ViewEvent";
import UpdateEvent from "./Container/Event/UpdateEvent";
import ViewStudentEvents from "./Container/StudentLoginModule/ViewStudentEvents";
import StaffEventView from "./Container/Event/StaffEventView";
import EventDashboard from "./Container/EventDashboard";
import AddEntryExam from "./Container/EntryExam/AddEntryExam";
import ViewEntryExam from "./Container/EntryExam/ViewEntryExam";
import ViewSubjectMarks from "./Container/EntryExam/ViewSubjectMarks";
import EntryExamDashboard from "./Container/EntryExamDashboard";

// End of continouse Assesment imports

//Start of Student Progrss

import AddStudentProgressReport from "./Container/StudentProgressReport/AddStudentProgressReport";
import ViewStudentProgressReport from "./Container/StudentProgressReport/ViewStudentProgressReport";
import PrintStudentProgressReport from "./Container/StudentProgressReport/PrintStudentProgressReport";
import StudentProgressReportDashboard from "./Container/StudentProgressReportDashboard";

//End of Student Progress

//Campus Maintenance Start

import AddFloor from "./Container/CampusMaintenance/AddFloor";
import ViewFloor from "./Container/CampusMaintenance/ViewFloor";
import AddRoom from "./Container/CampusMaintenance/AddRoom";
import ViewRoom from "./Container/CampusMaintenance/ViewRoom";
import AddCleanlinessCategory from "./Container/CampusMaintenance/AddCleanlinessCategory";
import ViewCleanlinessCategory from "./Container/CampusMaintenance/ViewCleanlinessCategory";
import AddCyclicCleanliness from "./Container/CampusMaintenance/AddCyclicCleanliness";
import ViewCyclicCleanliness from "./Container/CampusMaintenance/ViewCyclicCleanliness";
import PrintCyclicCleanliness from "./Container/CampusMaintenance/PrintCyclicCleanliness";
import AddEmergencyMaintenance from "./Container/CampusMaintenance/AddEmergencyMaintenance";
import ViewEmergencyMaintenance from "./Container/CampusMaintenance/ViewEmergencyMaintenance";
import PrintEmergencyMaintenance from "./Container/CampusMaintenance/PrintEmergencyMaintenance";
import NotificationEmergencyMaintenance from "./Container/CampusMaintenance/NotificationEmergencyMaintenance";
import CampusMaintenanceDashboard from "./Container/CampusMaintenanceDashboard";

//Campus Maintenance End

//SEN Manager Start

import AddTalentCategory from "./Container/SENManager/AddTalentCategory";
import ViewTalentCategory from "./Container/SENManager/ViewTalentCategory";
import AddTalentSubCategory from "./Container/SENManager/AddTalentSubCategory";
import ViewTalentSubCategory from "./Container/SENManager/ViewTalentSubCategory";
import AddStudentTalent from "./Container/SENManager/AddStudentTalent";
import ViewStudentTalent from "./Container/SENManager/ViewStudentTalent";
import PrintStudentTalent from "./Container/SENManager/PrintStudentTalent";
import StudentTalentDashboard from "./Container/StudentTalentDashboard";

//SEN Manager End

//WellBeing Manager Start

import ViewAbsentReport from "./Container/WellBeingManager/ViewAbsentReport";
import ViewLateReport from "./Container/WellBeingManager/ViewLateReport";
import PrintAbsentReport from "./Container/WellBeingManager/PrintAbsentReport";
import PrintLateReport from "./Container/WellBeingManager/PrintLateReport";
import ViewFlagManagement from "./Container/WellBeingManager/ViewFlagManagement";
import PrintFlagManagement from "./Container/WellBeingManager/PrintFlagManagement";
import DailyReportsWellBeingManager from "./Container/DailyReportsWellBeingManager";
import ViewDailyDisciplineReport from "./Container/WellBeingManager/ViewDailyDisciplineReport";
import PrintDailyDisciplineReport from "./Container/WellBeingManager/PrintDailyDisciplineReport";
import ViewDailyClassAttendance from "./Container/WellBeingManager/ViewDailyClassAttendance";
import PrintDailyClassAttendanceReport from "./Container/WellBeingManager/PrintDailyClassAttendanceReport";
import ViewMonthlyStudentAttendanceReport from "./Container/WellBeingManager/ViewMonthlyStudentAttendanceReport";
import PrintMonthlyStudentAttendanceReport from "./Container/WellBeingManager/PrintMonthlyStudentAttendanceReport";
import ViewStudentSkillAverageReport from "./Container/WellBeingManager/ViewStudentSkillAverageReport";
import PrintStudentSkillAverageReport from "./Container/WellBeingManager/PrintStudentSkillAverageReport";
import ViewFlagManagementSkill from "./Container/WellBeingManager/ViewFlagManagementSkill";
import PrintFlagManagementSkill from "./Container/WellBeingManager/PrintFlagManagementSkill";
import ViewStudentDisciplineReport from "./Container/WellBeingManager/ViewStudentDisciplineReport";
import PrintStudentDisciplineReport from "./Container/WellBeingManager/PrintStudentDisciplineReport";

//Wellbeing Manager End

//Pastrol Start

import PastrolDashboard from "./Container/PastrolDashboard";
import PastrolSENManagerDashboard from "./Container/PastrolSENManagerDashboard";
import PastrolWellbeingManagerDashboard from "./Container/PastrolWellbeingManagerDashboard";
import CaTermWiseReport from "./Container/Continouse-Assesment/continous-term-wisre-report/CaTermWiseReport";

//Pastrol End

import { ManagerPostDashboard } from "./Container/ManagerPostDashboard";
import BillingDashboard from "./Container/BillingDashboard";
import AddBilling from "./Container/FeesModule/Billing/AddBilling";
import ViewBilling from "./Container/FeesModule/Billing/ViewBilling";
import AddBulletin from "./Container/Communication/Bulletin/AddBulletin";
import HomeWorkDashboard from "./Container/Academic/HomeWork/HomeWorkDashboard";
import ClassDiscilineDashboard from "./Container/ClassDiscilineDashboard";
import EventCalender from "./Container/Communication/Events/EventCalender";
import CaReportList from "./Container/Continouse-Assesment/continouse-student-reports/CaReportList";
import CaParentReportList from "./Container/Continouse-Assesment/continouse-student-reports/CaParentReportList";

// StudentCurrentClass imports

//Staff Modules

import AddHomeWorkStaff from "./Container/StaffModulesLMS/AddHomeWorkStaff";
import AddAssignmentStaff from "./Container/StaffModulesLMS/AddAssignmentStaff";
import AddLessonPlanStaff from "./Container/StaffModulesLMS/AddLessonPlanStaff";
import AddExamInfoStaff from "./Container/StaffModulesLMS/AddExamInfoStaff";
import AddExamDetailsStaff from "./Container/StaffModulesLMS/AddExamDetailsStaff";
import AddSyllabusStaff from "./Container/StaffModulesLMS/AddSyllabusStaff";
import AddQuizInfoStaff from "./Container/StaffModulesLMS/AddQuizInfoStaff";
import AddQuizStaff from "./Container/StaffModulesLMS/AddQuizStaff";
import AddTestInfoStaff from "./Container/StaffModulesLMS/AddTestInfoStaff";
import AddTestStaff from "./Container/StaffModulesLMS/AddTestStaff";
import AddPastPapersStaff from "./Container/StaffModulesLMS/AddPastPapersStaff";

import ViewHomeWorkStaff from "./Container/StaffModulesLMS/ViewHomeWorkStaff";
import ViewSyllabusStaff from "./Container/StaffModulesLMS/ViewSyllabusStaff";
import ViewSyllabusDept from "./Container/StaffModulesLMS/ViewSyllabusDept";
import ViewAssignmentStaff from "./Container/StaffModulesLMS/ViewAssignmentStaff";
import ViewLessonPlanStaff from "./Container/StaffModulesLMS/ViewLessonPlanStaff";
import ViewLessonPlanDept from "./Container/StaffModulesLMS/ViewLessonPlanDept";
import ViewPastPapersStaff from "./Container/StaffModulesLMS/ViewPastPapersStaff";
import ViewPastPaperDept from "./Container/StaffModulesLMS/ViewPastPaperDept";
import ViewExamInfoStaff from "./Container/StaffModulesLMS/ViewExamInfoStaff";
import ViewExamInfoDept from "./Container/StaffModulesLMS/ViewExamInfoDept";
import ViewExamDetailsStaff from "./Container/StaffModulesLMS/ViewExamDetailsStaff";
import ViewExamDetailsDept from "./Container/StaffModulesLMS/ViewExamDetailsDept";
import ViewQuizInfoStaff from "./Container/StaffModulesLMS/ViewQuizInfoStaff";
import ViewQuizInfoDept from "./Container/StaffModulesLMS/ViewQuizInfoDept";
import ViewQuizStaff from "./Container/StaffModulesLMS/ViewQuizStaff";
import ViewQuizDept from "./Container/StaffModulesLMS/ViewQuizDept";
import ViewTestInfoStaff from "./Container/StaffModulesLMS/ViewTestInfoStaff";
import PrintTestInfoStaff from "./Container/StaffModulesLMS/PrintTestInfoStaff";
import ViewTestInfoDept from "./Container/StaffModulesLMS/ViewTestInfoDept";
import PrintTestInfoDept from "./Container/StaffModulesLMS/PrintTestInfoDept";
import ViewTestStaff from "./Container/StaffModulesLMS/ViewTestStaff";
import PrintTestStaff from "./Container/StaffModulesLMS/PrintTestStaff";
import ViewTestDept from "./Container/StaffModulesLMS/ViewTestDept";
import PrintTestDept from "./Container/StaffModulesLMS/PrintTestDept";

import PrintHomeWorkStaff from "./Container/StaffModulesLMS/PrintHomeWorkStaff";
import PrintSyllabusStaff from "./Container/StaffModulesLMS/PrintSyllabusStaff";
import PrintSyllabusDept from "./Container/StaffModulesLMS/PrintSyllabusDept";
import PrintAssignmentStaff from "./Container/StaffModulesLMS/PrintAssignmentStaff";
import PrintLessonPlanDept from "./Container/StaffModulesLMS/PrintLessonPlanDept";
import PrintLessonPlanStaff from "./Container/StaffModulesLMS/PrintLessonPlanStaff";
import PrintPastPaperDept from "./Container/StaffModulesLMS/PrintPastPaperDept";
import PrintPastPaperStaff from "./Container/StaffModulesLMS/PrintPastPaperStaff";
import PrintExamInfoDept from "./Container/StaffModulesLMS/PrintExamInfoDept";
import PrintExamInfoStaff from "./Container/StaffModulesLMS/PrintExamInfoStaff";
import PrintExamDetailsStaff from "./Container/StaffModulesLMS/PrintExamDetailsStaff";
import PrintExamDetailsDept from "./Container/StaffModulesLMS/PrintExamDetailsDept";
import PrintQuizInfoDept from "./Container/StaffModulesLMS/PrintQuizInfoDept";
import PrintQuizInfoStaff from "./Container/StaffModulesLMS/PrintQuizInfoStaff";
import PrintQuizStaff from "./Container/StaffModulesLMS/PrintQuizStaff";
import PrintQuizDept from "./Container/StaffModulesLMS/PrintQuizDept";

import StaffModuleLMSDashboard from "./Container/StaffModuleLMSDashboard";
import StaffModuleLMSHomeworkDashboard from "./Container/StaffModuleLMSHomeworkDashboard";
import StaffModuleLMSExamInfoDashboard from "./Container/StaffModuleLMSExamInfoDashboard";
import StaffModuleLMSQuizInfoDashboard from "./Container/StaffModuleLMSQuizInfoDashboard";
import StaffModuleLMSAssignmentDashboard from "./Container/StaffModuleLMSAssignmentDashboard";
import StaffModuleLMSLessonPlanDashboard from "./Container/StaffModuleLMSLessonPlanDashboard";
import StaffModuleLMSSyllabusDashboard from "./Container/StaffModuleLMSSyllabusDashboard";
import StaffModuleLMSPastPaperDashboard from "./Container/StaffModuleLMSPastPaperDashboard";
import StaffModuleLMSTestDashboard from "./Container/StaffModuleLMSTestDashboard";

//End Staff Modules

//Start Student LMS Modules

import ViewHomeWorkStudent from "./Container/StudentModulesLMS/ViewHomeWorkStudent";
import ViewAssignmentStudent from "./Container/StudentModulesLMS/ViewAssignmentStudent";
import ViewSyllabusStudentInit from "./Container/StudentModulesLMS/ViewSyllabusStudentInit";
import ViewPastPaperStudentInit from "./Container/StudentModulesLMS/ViewPastPaperStudentInit";
import ViewPastPaperStudent from "./Container/StudentModulesLMS/ViewPastPaperStudent";
import ViewExamInfoStudent from "./Container/StudentModulesLMS/ViewExamInfoStudent";
import ViewQuizInfoStudent from "./Container/StudentModulesLMS/ViewQuizInfoStudent";
import ViewTestInfoStudent from "./Container/StudentModulesLMS/ViewTestInfoStudent";

import StudentModuleLMSDashboard from "./Container/StudentModuleLMSDashboard";
import StudentModuleLMSHomeworkDashboard from "./Container/StudentModuleLMSHomeworkDashboard";

//End Student LMS Modules
//Dept LMS Modules

import DeptModuleLMSDashboard from "./Container/DeptModuleLMSDashboard";
import StaffModuleSIDDashboard from "./Container/StaffModuleSIDDashboard";
import AcademicDeanDashboard from "./Container/AcademicDeanDashboard";
import ArabicHODDashboard from "./Container/ArabicHODDashboard";
import EnglishDeanDashboard from "./Container/EnglishDeanDashboard";
import EnglishHODDashboard from "./Container/EnglishHODDashboard";
import AllPriorityList from "./Container/Time-Table/SubtitutionPeriodList/AllPriorityList";
import SubstitutionPeriodList from "./Container/Time-Table/SubtitutionPeriodList/SubstitutionPeriodList";
import SubstitutionPeriority from "./Container/Time-Table/SubtitutionPeriodList/SubstitutionPeriority";
import DailySubstitutionRecord from "./Container/Time-Table/SubtitutionPeriodList/DailySubstitutionRecord";
import CutOffPost from "./Container/Time-Table/CutOffCrud/CutOffPost/CutOffPost";
import ApplySubstitution from "./Container/Time-Table/SubtitutionPeriodList/ApplySubstitution";
import CutOffView from "./Container/Time-Table/CutOffCrud/CutOffView/CutOffView";

//End Dept LMS Modules

//Academic Calender Start

import InitiateAcademicCalendar from "./Container/AcademicCalendar/InitiateAcademicCalendar";
import AddAcademicCalendar from "./Container/AcademicCalendar/AddAcademicCalendar";
import ViewAcademicCalendar from "./Container/AcademicCalendar/ViewAcademicCalendar";
import AcademicCalendarMain from "./Container/AcademicCalendar/AcademicCalendarMain";
import PrintAcademicCalendar from "./Container/AcademicCalendar/PrintAcademicCalendar";
import AcademicFormViews from "./Container/AcademicFormViews";
import HODDescriptiveView from "./Container/HODFormViews/HODDescriptiveView";
import HODSkillsView from "./Container/HODFormViews/HODSkillsView";
import HODClassDisciplineView from "./Container/HODFormViews/HODClassDisciplineView";

//Academic Calender End

//Staff Leave

import AddStaffLeave from "./Container/StaffLeave/AddStaffLeave";
import ViewStaffLeave from "./Container/StaffLeave/ViewStaffLeave";
import ViewStaffLeaveForHR from "./Container/StaffLeave/ViewStaffLeaveForHR";
import PrintStaffLeaveForHR from "./Container/StaffLeave/PrintStaffLeaveForHR";
import ViewStaffLeaveDept from "./Container/StaffLeave/ViewStaffLeaveDept";

import AddStaffShortLeave from "./Container/StaffLeave/AddStaffShortLeave";
import ViewStaffShortLeave from "./Container/StaffLeave/ViewStaffShortLeave";
import ViewStaffShortLeaveForHR from "./Container/StaffLeave/ViewStaffShortLeaveForHR";
import ViewStaffShortLeaveDept from "./Container/StaffLeave/ViewStaffShortLeaveDept";
import PrintStaffShortLeaveForHR from "./Container/StaffLeave/PrintStaffShortLeaveForHR";

import StaffLeaveDashboard from "./Container/StaffLeaveDashboard";
import StaffLeaveHRDashboard from "./Container/StaffLeaveHRDashboard";
import StaffLeaveDeptDashboard from "./Container/StaffLeaveDeptDashboard";

//End Staff Leave

//Owner LMS reports

import PrintHomeWork from "./Container/HomeWork/PrintHomeWork";
import PrintAssignment from "./Container/Assignment/PrintAssignment";
import PrintExamInfo from "./Container/Exam/PrintExamInfo";
import PrintExam from "./Container/Exam/PrintExam";
import PrintQuizInfo from "./Container/QuizInfo/PrintQuizInfo";
import PrintQuiz from "./Container/Quiz/PrintQuiz";
import PrintPastPapers from "./Container/PastPapers/PrintPastPapers";
import PrintTestInfo from "./Container/TestInfo/PrintTestInfo";
import PrintTest from "./Container/Test/PrintTest";

//End Owner LMS reports

import StaffModuleCMSDashboard from "./Container/StaffModuleCMSDashboard";
import SystemSmartDashboard from "./components/SystemSmartDas/SystemSmartDashboard";
import HODSubjectDisciplineView from "./Container/HODFormViews/HODSubjectDisciplineView";
import HODSubjectDisciplineEdit from "./Container/HODFormViews/HODSubjectDisciplineEdit";
import AcademicHeadResultSheet from "./Container/AcademicHODDashboard/AcademicHeadResultSheet";
import AcademicHeadTeacherEvaluation from "./Container/AcademicHODDashboard/AcademicHeadTeacherEvaluation";
import HODEvaluationFormView from "./Container/HODFormViews/HODEvaluationFormView";
import AddBookCategory from "./Container/Library/AddBookCategory";
import ViewBookCategory from "./Container/Library/ViewBookCategory";
import DateWiseFeeReport from "./Container/FeeReport/DateWiseFeeReport";
import StudentDisciplineDashboard from "./Container/StudentDisciplineDashboard";
import AdminLeaveDashboard from "./Container/AdminLeaveDashboard";
import AddAdminLeave from "./Container/AdminLeave/AddAdminLeave";
import ViewAdminLeave from "./Container/AdminLeave/ViewAdminLeave";
import AddAdminShortLeave from "./Container/AdminLeave/AddAdminShortLeave";
import ViewAdminShortLeave from "./Container/AdminLeave/ViewAdminShortLeave";
import ManagerLeaveDashboard from "./Container/ManagerLeaveDashboard";
import AddManagerLeave from "./Container/ManagerLeave/AddManagerLeave";
import ViewManagerLeave from "./Container/ManagerLeave/ViewManagerLeave";
import AddManagerShortLeave from "./Container/ManagerLeave/AddManagerShortLeave";
import ViewManagerShortLeave from "./Container/ManagerLeave/ViewManagerShortLeave";
import StudentAdmissionAdminDashboard from "./Container/StudentAdmissionAdminDashboard";
import AdminExamEntry from "./Container/AdminEntryExamDashboard";
import AdminOnlineRegStduent from "./Container/AdminStudentFrontDesk";
import Billing_Dashboard_Admin from "./Container/BillingDashboardAdmin";
import ArabicDeanDashoard from "./Container/ArabicDeanDashoard";
import EnglishDeanDashoard2 from "./Container/EnglishDeanDashboard2";
import CMSDeanDashboard from "./Container/CMSDeanDashboard";
import IndentDeanAdd from "./Container/IndentDeanAdd";
import IndentDeanView from "./Container/IndentDeanView";
import IndentDeanDashboard from "./Container/IndentDeanDashboard";
import DeanLeaveDashboard from "./Container/DeanLeaveDashboard";
import CommunicationDeanDashboard from "./Container/CommunicationDeanDashboard";
import DeanManageMessage from "./Container/DeanManageMessage";
import CMSHODDashboard from "./Container/CMSHODDashboard";
import IndentHODDashboard from "./Container/IndentHODDashboard";
import IndentHODAdd from "./Container/IndentHODAdd";
import IndentHODView from "./Container/IndentHODView";
import HODLeaveDashboard from "./Container/HODLeaveDashboard";
import AddHODLeave from "./Container/HODLeaves/AddHODLeave";
import ViewHODLeave from "./Container/HODLeaves/ViewHODLeave";
import AddHODShortLeave from "./Container/HODLeaves/AddHODShortLeave";
import ViewHODShortLeave from "./Container/HODLeaves/ViewHODShortLeave";
import CommunicationHODDashboard from "./Container/CommunicationHODDashboard";
import RangeStockUsageView from "./Container/Inventory/RangeStockUsageView";
import AcademicsPlanDashboard from "./Container/AcademicPlanDashboard";
import ContinuousSubToolViewAdmin from "./Container/Continouse-Assesment/View/ContinuousSubToolViewAdmin";
import CADashboardAdmin from "./Container/Continouse-Assesment/Dashboard/CADashboardAdmin";
import FamilyTreeDashboard from "./Container/FamilyTreeDashboard";
import AcademicFeeType from "./Container/FeesModule/AcademicFeeType";
import OtherFeeType from "./Container/FeesModule/OtherFeeType";
import ViewAcademicFeeType from "./Container/FeesModule/ViewAcademicFeeType";
import ViewOtherFeeType from "./Container/FeesModule/ViewOtherFeeType";
import NewFeeTypeDashboard from "./Container/NewFeeTypeDashboard";
import InvoiceBooking from "./Container/FeesModule/InvoiceBooking";
import AddOnlineResource from "./Container/OnlineResource/AddOnlineResource";
import ViewOnlineResource from "./Container/OnlineResource/ViewOnlineResource";
import OnlineResourceDashboard from "./Container/OnlineResourceDashboard";
import OnlineResourceStaffDashboard from "./Container/OnlineResourceStaffDashboard";
import AdvanceRevenue from "./Container/FeesModule/AdvanceRevenue";
import ReceiptBooking from "./Container/FeesModule/ReceiptBooking";
import FeeVouchers from "./Container/FeesModule/FeeVouchers";
import VoucherDetails from "./Container/FeesModule/VoucherDetails";
import SpecialEducation from "./Container/StaffLoginModule/SpecialEducationNeed/SpecialEducation";
import AddSpecialEducation from "./Container/StaffLoginModule/SpecialEducationNeed/AddSpecialEducation";
import ViewSpecialEducation from "./Container/StaffLoginModule/SpecialEducationNeed/ViewSpecialEducation";
import AllSheetsView from "./Container/StaffLoginModule/SpecialEducationNeed/AllSheetsView";
import ViewSpecialEducationRecords from "./Container/StaffLoginModule/SpecialEducationNeed/ViewSpecialEducationRecords";
import SpecialEducationAdmin from "./Container/StaffLoginModule/SpecialEducationNeed/SpecialEducationAdmin";
import ViewSpecialEducationRecordAdmin from "./Container/StaffLoginModule/SpecialEducationNeed/ViewSpecialEducationRecordAdmin";
import SpecialEducationNeedDashboard from "./Container/SpecialEducationNeedDashboard";
import SpecialEducationNeedDashboardAdmin from "./Container/SpecialEducationNeedDashboardAdmin";
import FeeAccounts from "./Container/FeesModule/FeeAccounts";


// AssignClassTeacher imports

const Dashboard = lazy(() => import("./Container/Dashboard"));

// end staff imports

// import from './Container/Transport/'
// TODO apply code spliting over all component
class Routes extends React.Component {
  PrintContent = (el) => {
    var restorepage = document.body.innerHTML;
    var Printcontent = document.getElementById(el).innerHTML;
    document.body.innerHTML = Printcontent;
    window.print();
    document.body.innerHTML = restorepage;
    setTimeout(function () {
      window.location.reload();
    }, 700);
  };
  myFunction = () => {
    var x = document.getElementById("snackbar");

    // Add the "show" class to DIV
    x.className = "show";

    // After 3 seconds, remove the show class from DIV
    setTimeout(function () {
      x.className = x.className.replace("show", "");
    }, 3000);
  };
  stringConversion = (input) => {
    return [...input.match(/[A-Z][a-z]+/g)].join(" ");
  };
  btnTechHover = () => {
    $(document).ready(function () {
      $("button").hover(
        function () {
          $(this).css("box-shadow", "0px 5px 5px grey");
          $(this).css("border-color", "white");
        },
        function () {
          $(this).css("box-shadow", "0px 0px 0px white");
        }
      );
    });
  };
  render() {
    return (
      <Layout>
        <Switch>
          <Route
            exact
            component={() => (
              <Suspense fallback={<div class="spinner-border text-primary" />}>
                <Dashboard {...this.props} />
              </Suspense>
            )}
            path="/dashboard"
          />
          <Route
            render={(props) => (
              <AddAdmin {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddAdmin"
          />
          <Route
            render={(props) => (
              <ViewAdmin {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewAdmin"
          />
          <Route
            render={(props) => (
              <AcademicLMS {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AcademicLMS"
          />

          <Route
            render={(props) => (
              <AcademicCMS {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AcademicCMS"
          />

          <Route
            render={(props) => (
              <CADashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/CADashboard"
          />
          <Route
            render={(props) => (
              <CADashboardAdmin {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/CADashboardAdmin"
          />

          <Route
            render={(props) => (
              <AcademicSID {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AcademicSID"
          />
          <Route
            render={(props) => (
              <AcademicSID {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AcademicSID"
          />
          <Route
            component={AddCampus}
            path="/dashboard/CampusDashboard/AddCampus"
          />
          <Route
            render={(props) => (
              <ViewCampus {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/CampusDashboard/ViewCampus"
          />
          <Route component={AddCategory} path="/dashboard/AddCategory" />
          <Route
            render={(props) => (
              <ViewCategory {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewCategory"
          />
          <Route
            render={(props) => (
              <ViewItems {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewItems"
          />
        
          <Route component={AddItems} path="/dashboard/AddItems" />
          <Route component={ClassSection} path="/dashboard/SearchStudent" />
          <Route
            component={ClassWiseSearch}
            path="/dashboard/ClassWiseSearch"
          />
          <Route component={ViewAdmin} path="/dashboard/ViewAdmin" />
          <Route component={GM} path="/dashboard/AddGM" />
          <Route
            render={(props) => (
              <AdmissionForm {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddForm"
          />
          <Route
            render={(props) => <AddGm {...props} snackbar={this.myFunction} />}
            path="/dashboard/AddGm"
          />
          <Route component={AdmissionForm} path="/dashboard/AddForm" />
          <Route component={HrDashboard} path="/dashboard/HrDashboard" />
          <Route
            component={CanteenDashboard}
            path="/dashboard/CanteenDashboard"
          />
          <Route
            render={(props) => (
              <ViewHrDevelopment {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewHrDevelopment"
          />
          <Route
            component={(props) => (
              <Salary {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/Appraisal"
          />
          <Route component={AddDepartment} path="/dashboard/AddDepartment" />
          <Route
            render={(props) => (
              <ViewDepartment {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewDepartment"
          />
          <Route component={AddPost} path="/dashboard/AddPost" />
          <Route component={StudentEnquiry} path="/dashboard/StudentEnquiry" />
          <Route component={StaffEnquiry} path="/dashboard/StaffEnquiry" />
          <Route component={Vacancy} path="/dashboard/AddVacancy" />
          <Route component={AddInterview} path="/dashboard/AddInterview" />
          <Route component={ProgressBarComponent} path="/dashboard/Progress" />
          <Route
            component={CampusDashboard}
            path="/dashboard/CampusDashboard"
          />
          <Route
            component={ViewCanteenItems}
            path="/dashboard/ViewCanteenItems"
          />
          <Route
            component={TransportDashboard}
            path="/dashboard/TransportDashboard"
          />
          <Route
            component={AddCanteenItems}
            path="/dashboard/AddCanteenItems"
          />
          <Route
            component={ViewCanteenCategory}
            path="/dashboard/ViewCanteenCategory"
          />
          <Route
            component={AddCanteenCategory}
            path="/dashboard/AddCanteenCategory"
          />
          <Route
            component={HrDevelopmentAndTraining}
            path="/dashboard/HrDevelopmentAndTraining"
          />
          <Route
            render={(props) => <ViewGm {...props} snackbar={this.myFunction} />}
            path="/dashboard/ViewGm"
          />
          <Route component={ViewGm} path="/dashboard/ViewGm" />
          <Route component={AddSuppliers} path="/dashboard/AddSuppliers" />
          <Route component={ViewSuppliers} path="/dashboard/ViewSuppliers" />
          <Route component={AddStock} path="/dashboard/AddStock" />
          <Route component={AddStockUsage} path="/dashboard/AddStockUsage" />
          <Route component={ViewStockUsage} path="/dashboard/ViewStockUsage" />
          <Route component={RangeStockUsageView} path="/dashboard/RangeStockUsageView" />
          <Route
            render={(props) => <ViewStock {...props} snackbar={this.myFunction} />}
            path="/dashboard/ViewStock"
          />
          {/* <Route component={ViewStock} path="/dashboard/ViewStock" /> */}
          <Route component={AddCanteen} path="/dashboard/AddCanteen" />
          <Route component={AgeWiseSearch} path="/dashboard/AgeWiseSearch" />
          <Route
            component={CategoryWiseSearch}
            path="/dashboard/CategoryWiseSearch"
          />
          <Route component={UserWiseSearch} path="/dashboard/UserWiseSearch" />
          <Route
            component={FamilyWiseSearch}
            path="/dashboard/FamilyWiseSearch"
          />
          <Route
            component={StudentResultStatus}
            path="/dashboard/StudentResultStatus"
          />
          <Route
            render={(props) => (
              <ViewCanteen {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewCanteen"
          />
          <Route component={AddRoutes} path="/dashboard/AddRoutes" />
          <Route component={AddRoute} path="/dashboard/AddRoute" />
          <Route
            render={(props) => (
              <ViewRoutes {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewRoutes"
          />
          <Route
            render={(props) => (
              <ViewPost {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewPost"
          />
          <Route
            render={(props) => (
              <InActivePage {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/inActivePage"
          />
          <Route
            component={ViewStaffEnquiry}
            path="/dashboard/ViewStaffEnquiry/:id"
          />
          <Route
            component={ViewStudentEnquiry}
            path="/dashboard/ViewStudentEnquiry/:id"
          />
          <Route
            component={ViewAdmittedStudent}
            path="/dashboard/ViewAdmittedStudent"
          />
          <Route
            component={DepartmentDashboard}
            path="/dashboard/DepartmentDashboard"
          />
          <Route component={PostDashboard} path="/dashboard/PostDashboard" />
          <Route
            component={FrontDeskDashboard}
            path="/dashboard/FrontDeskDashboard"
          />
          <Route
            component={BudgetDashboard}
            path="/dashboard/BudgetDashboard"
          />
          <Route
            component={HrDevelopmentDashboard}
            path="/dashboard/HrDevelopmentDashboard"
          />
          <Route
            component={ViewStudentEnquiryPagination}
            path="/dashboard/ViewStudentEnquiryPagination"
          />
          <Route
            component={ViewAdmittedStaff}
            path="/dashboard/ViewAdmittedStaff"
          />
          <Route component={AddVehicle} path="/dashboard/AddVehicle" />
          <Route component={ViewVehicle} path="/dashboard/ViewVehicle" />
          <Route component={AddDriver} path="/dashboard/AddDriver" />
          <Route
            component={StudentAdmissionDashboard}
            path="/dashboard/StudentAdmissionDashboard"
          />
          <Route
            render={(props) => (
              <AddAccountLedger {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AccountLedger/AddAccountLedger"
          />
          <Route
            render={(props) => (
              <AccountLedger {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AccountLedger"
          />
          <Route component={AddBudget} path="/dashboard/AddBudget" />
          <Route component={ViewBudget} path="/dashboard/ViewBudget" />
          <Route
            component={ViewDriverHistory}
            path="/dashboard/ViewDriverHistory"
          />
          <Route component={ViewDriver} path="/dashboard/ViewDriver" />
          <Route component={AssignVehicle} path="/dashboard/AssignVehicle" />
          <Route
            component={ViewAssignVehicle}
            path="/dashboard/ViewAssignVehicle"
          />
          <Route
            component={CreateTransportFees}
            path="/dashboard/CreateTransportFees"
          />
          <Route component={Check} path="/dashboard/Check" />
          <Route
            component={AdministrationDashboard}
            path="/dashboard/AdministrationDashboard"
          />
          <Route
            component={InventoryDashboard}
            path="/dashboard/InventoryDashboard"
          />
          <Route
            component={CensusDashboard}
            path="/dashboard/CensusDashboard"
          />
          <Route
            component={FinanceDashboard}
            path="/dashboard/FinanceDashboard"
          />
          <Route
            component={StaffAdmissionForm}
            path="/dashboard/StaffAdmissionForm"
          />
          <Route
            component={PrintAdmissionForm}
            path="/dashboard/PrintAdmissionForm"
          />
          <Route
            component={PrintAdmissionFormFinal}
            path="/dashboard/PrintAdmissionFormFinal/:id"
          />
          <Route component={ViewAddForm} path="/dashboard/ViewAddForm" />
          <Route
            component={ViewStaffEnquiryPagination}
            path="/dashboard/ViewStaffEnquiryPagination"
          />
          <Route
            component={PrintAdmissionForm}
            path="/dashboard/PrintForm/:id"
          />
          <Route
            component={(props) => (
              <EditAddRecord
                btnTechHover={this.btnTechHover}
                {...props}
                stringConversion={this.stringConversion}
              />
            )}
            path="/dashboard/EditAddRecord/:id"
          />
          <Route
            component={EditAdmissionForm}
            path="/dashboard/EditAdmissionForm/:sectionType"
          />
          <Route component={Check} path="/dashboard/Check" />
          <Route component={CensusManager} path="/dashboard/CensusManager" />
          <Route
            component={ViewCensusManager}
            path="/dashboard/ViewCensusManager"
          />
          <Route
            component={AdministrationManagers}
            path="/dashboard/AdministrationManagers"
          />
          <Route
            render={(props) => (
              <UploadedFilesView {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/UploadFilesView/:id"
          />
          <Route
            component={HrDevelopmentAndTraining}
            path="/dashboard/HrDevelopmentAndTraining"
          />
          <Route
            render={(props) => <ViewGm {...props} snackbar={this.myFunction} />}
            path="/dashboard/ViewGm"
          />
          <Route component={ViewGm} path="/dashboard/ViewGm" />
          <Route component={AddSuppliers} path="/dashboard/AddSuppliers" />
          <Route component={ViewSuppliers} path="/dashboard/ViewSuppliers" />
          <Route component={AddStock} path="/dashboard/AddStock" />
          <Route component={AddStockUsage} path="/dashboard/AddStockUsage" />
          <Route component={ViewStockUsage} path="/dashboard/ViewStockUsage" />
          <Route component={ViewStock} path="/dashboard/ViewStock" />
          <Route component={AddCanteen} path="/dashboard/AddCanteen" />
          <Route component={AgeWiseSearch} path="/dashboard/AgeWiseSearch" />
          <Route
            component={CategoryWiseSearch}
            path="/dashboard/CategoryWiseSearch"
          />
          <Route component={UserWiseSearch} path="/dashboard/UserWiseSearch" />
          <Route
            component={FamilyWiseSearch}
            path="/dashboard/FamilyWiseSearch"
          />
          <Route
            component={StudentResultStatus}
            path="/dashboard/StudentResultStatus"
          />
          <Route
            render={(props) => (
              <ViewCanteen {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewCanteen"
          />
          <Route component={AddRoutes} path="/dashboard/AddRoutes" />
          <Route component={AddRoute} path="/dashboard/AddRoute" />
          <Route
            render={(props) => (
              <ViewRoutes {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewRoutes"
          />
          <Route
            render={(props) => (
              <ViewPost {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewPost"
          />
          <Route
            component={ViewStaffEnquiry}
            path="/dashboard/ViewStaffEnquiry/:id"
          />
          <Route
            component={ViewStudentEnquiry}
            path="/dashboard/ViewStudentEnquiry/:id"
          />
          <Route
            component={ViewAdmittedStudent}
            path="/dashboard/ViewAdmittedStudent"
          />
          <Route
            component={ViewStudentEnquiryPagination}
            path="/dashboard/ViewStudentEnquiryPagination"
          />
          <Route
            component={ViewAdmittedStaff}
            path="/dashboard/ViewAdmittedStaff"
          />
          <Route component={AddVehicle} path="/dashboard/AddVehicle" />
          <Route component={ViewVehicle} path="/dashboard/ViewVehicle" />
          <Route component={AddDriver} path="/dashboard/AddDriver" />
          <Route
            render={(props) => (
              <AddAccountLedger {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AccountLedger/AddAccountLedger"
          />
          <Route
            render={(props) => (
              <AccountLedger {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AccountLedger"
          />
          <Route component={AddBudget} path="/dashboard/AddBudget" />
          <Route component={ViewBudget} path="/dashboard/ViewBudget" />
          <Route
            render={(props) => <BudgetScreen {...props} />}
            path="/dashboard/BudgetScreen"
          />
          <Route
            component={ViewDriverHistory}
            path="/dashboard/ViewDriverHistory"
          />
          <Route component={ViewDriver} path="/dashboard/ViewDriver" />
          <Route component={AssignVehicle} path="/dashboard/AssignVehicle" />
          <Route
            component={ViewAssignVehicle}
            path="/dashboard/ViewAssignVehicle"
          />
          <Route component={StaffMainScreen} path="/dashboard/StaffModule" />
          <Route
            component={CreateTransportFees}
            path="/dashboard/CreateTransportFees"
          />
          <Route
            component={AdministrationDashboard}
            path="/dashboard/AdministrationDashboard"
          />
          <Route
            component={PrintStaffAdmissionForm}
            path="/dashboard/PrintStaffAdmissionForm/:id"
          />
          <Route
            component={FinanceDashboard}
            path="/dashboard/FinanceDashboard"
          />
          <Route
            component={StaffAdmissionForm}
            path="/dashboard/StaffAdmissionForm"
          />
          <Route
            component={PrintAdmissionForm}
            path="/dashboard/PrintAdmissionForm"
          />
          <Route
            component={PrintAdmissionFormFinal}
            path="/dashboard/PrintAdmissionFormFinal/:id"
          />
          <Route component={ViewAddForm} path="/dashboard/ViewAddForm" />
          <Route
            component={ViewStaffEnquiryPagination}
            path="/dashboard/ViewStaffEnquiryPagination"
          />
          <Route
            component={PrintAdmissionForm}
            path="/dashboard/PrintForm/:id"
          />
          <Route
            component={(props) => (
              <EditAddRecord
                btnTechHover={this.btnTechHover}
                {...props}
                stringConversion={this.stringConversion}
              />
            )}
            path="/dashboard/EditAddRecord/:id"
          />
          <Route
            component={EditAdmissionForm}
            path="/dashboard/EditAdmissionForm/:sectionType"
          />
          <Route component={Check} path="/dashboard/Check" />
          <Route component={CensusManager} path="/dashboard/CensusManager" />
          <Route
            component={ViewCensusManager}
            path="/dashboard/ViewCensusManager"
          />
          <Route
            component={AdministrationManagers}
            path="/dashboard/AdministrationManagers"
          />
          <Route
            component={UploadedFilesView}
            path="/dashboard/UploadFilesView/:id"
          />
          {/* Staff  */}
          <Route
            render={(props) => (
              <StaffImageUploadSection {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/StaffImageUpload/:urlId/:type"
          />
          <Route
            render={(props) => <Staff {...props} snackbar={this.myFunction} />}
            path="/dashboard/Staff"
          />
          {/* <Route
						render={props => (
							<StaffDocumentUploadSection
								{...props}
								snackbar={this.myFunction}
							/>
						)}
						path="/dashboard/StaffDocumentsUpload/:id"
					/> */}
          <Route
            render={(props) => (
              <DocumentSectionStep {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/StaffDocumentSection/:id"
          />
          <Route
            render={(props) => (
              <StaffDocumentUploadSection
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/DocumentsSectionForStaff/:paramsId"
          />

          <Route
            render={(props) => (
              <StaffDocumentUploadSection
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/DocumentsSectionForStaff/:paramsId"
          />

          <Route
            render={(props) => (
              <StaffEditStepsRendering
                stringConversion={this.stringConversion}
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/StaffEdit/:id"
          />
          {/* end Staff */}
          {/* Appraisal */}
          <Route
            render={(props) => (
              <ViewAppraisal {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewAppraisal"
          />
          <Route
            render={(props) => (
              <NotificationAppraisal {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AppraisalNotification"
          />
          {/* End Apprails */}
          {/* Time-Table */}
          <Route
            component={(props) => (
              <LessonPerWeek {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/Lessonperweek"
          />
          <Route
            component={(props) => (
              <LessonPerWeekScreen {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/LessonperweekScreen"
          />
          <Route
            render={(props) => (
              <ViewLessonWeek {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/Viewlessonperweek"
          />
          <Route
            render={(props) => (
              <AcademicTimeTableModule {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/TimeTableModule"
          />
          <Route
            render={(props) => (
              <TimeTableScreen {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/TimeTableScreen"
          />
          <Route
            render={(props) => (
              <CutOffView {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/CutOffView"
          />
          <Route
            render={(props) => (
              <ApplySubstitution {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ApplySubstitution"
          />
          <Route
            render={(props) => (
              <SubstitutionPeriodList {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/SubstitutionPeriodList"
          />
          <Route
            render={(props) => (
              <CutOffPost {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/CutOffPost"
          />
          <Route
            render={(props) => (
              <SubstitutionPeriority {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/SubstitutionPeriority"
          />
          <Route
            render={(props) => (
              <SubstitutionPeriodList {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/SubstitutionPeriorityList"
          />
          <Route
            render={(props) => (
              <AllPriorityList {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AllPriorityList"
          />
          <Route
            render={(props) => (
              <DailySubstitutionRecord {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/DailySubstitutionRecord"
          />
          <Route
            render={(props) => (
              <TimeTable {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/timeTable"
          />
          <Route
            render={(props) => (
              <StaffView {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/viewStaff"
          />
          <Route
            render={(props) => (
              <StaffFileUploadView {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/staffUploadFile/:id/:type"
          />
          <Route
            render={(props) => (
              <EditStaffFrom {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/EditStaffFrom/:sectionType"
          />
          <Route
            render={(props) => (
              <TimeTableView {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/viewtimeTable"
          />
          {/* end of time table */}
          <Route component={AddAssignment} path="/dashboard/AddAssignment" />
          <Route
            component={ViewParticularStudent}
            path="/dashboard/ViewParticularStudent"
          />
          <Route
            render={(props) => (
              <ViewAssignment {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewAssignment"
          />
          <Route
            render={(props) => (
              <AdminOnlineRegStduent {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AdminStudentFrontDesk"
          />
          {/* end of time table */}
          <Route component={AddHomeWork} path="/dashboard/AddHomeWork" />
          <Route
            render={(props) => (
              <ViewHomeWork {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewHomeWork"
          />
          {/* end of time table */}
          <Route component={AddLessonPlan} path="/dashboard/AddLessonPlan" />
          <Route
            render={(props) => (
              <ViewLessonPlan {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewLessonPlan"
          />
          <Route
            component={SearchStaffByDepartment}
            path="/dashboard/SearchStaffByDepartment"
          />
          <Route
            component={SearchStaffByPost}
            path="/dashboard/SearchStaffByPost"
          />
          <Route
            component={AcademicsDashboard}
            path="/dashboard/AcademicsDashboard"
          />
          <Route
            component={HomeWorkDashboard}
            path="/dashboard/HomeWorkDashboard"
          />
          <Route
            component={AssignmentDashboard}
            path="/dashboard/AssignmentDashboard"
          />
          <Route
            component={LessonPlanDashboard}
            path="/dashboard/LessonPlanDashboard"
          />
          <Route
            component={EditHrDevelopment}
            path="/dashboard/EditHrDevelopment"
          />
          <Route component={AddBudgetItems} path="/dashboard/AddBudgetItems" />
          <Route
            component={ViewBudgetItems}
            path="/dashboard/ViewBudgetItems"
          />
          <Route
            component={SearchItemsBySession}
            path="/dashboard/SearchItemsBySession"
          />
          <Route component={AddQuiz} path="/dashboard/AddQuiz" />
          <Route
            render={(props) => (
              <ViewQuiz {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewQuiz"
          />
          <Route component={AddQuizInfo} path="/dashboard/AddQuizInfo" />
          <Route
            render={(props) => (
              <ViewQuizInfo {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewQuizInfo"
          />
          <Route component={AddTestInfo} path="/dashboard/AddTestInfo" />
          <Route
            render={(props) => (
              <ViewTestInfo {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewTestInfo"
          />
          <Route component={QuizDashboard} path="/dashboard/QuizDashboard" />
          <Route component={TestDashboard} path="/dashboard/TestDashboard" />
          <Route component={AddTest} path="/dashboard/AddTest" />
          <Route
            render={(props) => (
              <ViewTest {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewTest"
          />
          <Route component={AddExam} path="/dashboard/AddExam" />
          <Route component={AddExamDetails} path="/dashboard/AddExamDetails" />
          <Route
            render={(props) => (
              <ViewExamDetails {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewExamDetails"
          />
          <Route component={AddExamInfo} path="/dashboard/AddExamInfo" />
          <Route
            render={(props) => (
              <ViewExamInfo {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewExamInfo"
          />
          <Route component={ExamDashboard} path="/dashboard/ExamDashboard" />
          <Route component={AddPastPapers} path="/dashboard/AddPastPapers" />
          <Route
            render={(props) => (
              <ViewPastPapers {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewPastPapers"
          />
          <Route component={AddSyllabus} path="/dashboard/AddSyllabus" />
          <Route
            component={EditorComponent}
            path="/dashboard/EditorComponent"
          />
          <Route component={ViewEditorComponent} path="/dashboard/ViewEditor" />
          <Route
            render={(props) => (
              <ViewSyllabus {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewSyllabus"
          />
          <Route
            component={ViewSyllabusTemplate}
            path="/dashboard/ViewSyllabusTemplate/:id"
          />
          <Route
            component={ViewAllSyllabus}
            path="/dashboard/ViewAllSyllabus"
          />
          <Route
            component={SyllabusDashboard}
            path="/dashboard/SyllabusDashboard"
          />
          <Route
            component={PastPaperDashboard}
            path="/dashboard/PastPaperDashboard"
          />
          <Route component={StudentSkill} path="/dashboard/StudentSkill" />
          <Route
            component={ViewStudentSkills}
            path="/dashboard/ViewStudentSkills"
          />
          <Route
            component={PrintAllStudentSkills}
            path="/dashboard/PrintAllStudentSkills"
          />
          <Route
            component={ViewStudentSkillList}
            path="/dashboard/ViewStudentSkillList"
          />
          <Route
            component={PrintSkillReport}
            path="/dashboard/PrintSkillReport/:id/:class/:section/:term/:session"
          />
          <Route
            component={StudentSkillDashboard}
            path="/dashboard/StudentSkillDashboard"
          />
          <Route
            component={DescriptiveReport}
            path="/dashboard/DescriptiveReport"
          />
          <Route
            render={(props) => (
              <ViewCampus {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/CampusDashboard/ViewCampus"
          />
          <Route
            render={(props) => (
              <EditDescriptiveReport {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/EditDescriptiveReport"
          />
          <Route
            component={PrintDescriptiveReport}
            path="/dashboard/PrintDescriptiveReport/:id/:class/:section/:term/:session"
          />
          <Route
            component={StudentSkillView}
            path="/dashboard/StudentSkillView"
          />
          <Route
            component={DiscriptiveDashboard}
            path="/dashboard/DescriptiveDashboard"
          />
          <Route
            component={StudentDescriptiveView}
            path="/dashboard/StudentDescriptiveView"
          />
          {/*  Student Discipline Record Module */}
          <Route
            path="/dashboard/EditStudentDisciplineRecord"
            render={(props) => (
              <EditStudentDisciplineRecord
                {...props}
                snackbar={this.myFunction}
              />
            )}
          />
          <Route
            render={(props) => (
              <StudentDisciplineRecord {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/StudentDisciplineRecord"
          />
          <Route
            path="/dashboard/ViewStudentDiscipline/:classId/:section/:session/:term"
            render={(props) => (
              <StudentDisciplineReports {...props} snackbar={this.myFunction} />
            )}
          />
          <Route
            render={(props) => (
              <ViewStudentDiscipline {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewStudentDiscipline"
          />
          <Route
            render={(props) => (
              <SubjectDisciplineMarksReport
                {...props}
                snackbar={this.myFunction}
                printFunc={this.PrintContent}
              />
            )}
            path="/dashboard/SubjectDisciplineMarks/:studentId/:classId/:section/:session/:term"
          />
          <Route
            render={(props) => (
              <SubjectDisciplineMarks {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/SubjectDisciplineMarks"
          />
          {/* End of Student Discipline Staff Module */}
          {/* Start of Role : Student Discipline */}
          <Route
            render={(props) => (
              <SubjectDisciplineMarksReport
                {...props}
                snackbar={this.myFunction}
                printFunc={this.PrintContent}
              />
            )}
            path="/dashboard/StudentRoleDiscipline/:studentId/:classId/:section/:session/:term"
          />
          <Route
            render={(props) => (
              <StudentRoleDiscipline {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/StudentRoleDiscipline"
          />
          <Route
            render={(props) => (
              <SubjectDisciplineMarksReport
                {...props}
                snackbar={this.myFunction}
                printFunc={this.PrintContent}
              />
            )}
            path="/dashboard/ParentStudentDiscipline/:studentId/:classId/:section/:session/:term"
          />
          <Route
            render={(props) => (
              <ParentStudentDiscipline {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ParentStudentDiscipline"
          />
          {/* End of Role : STudent Discipline */}
          {/* Start of Role: Admin => Admin Student Discipline */}
          <Route
            render={(props) => (
              <EditAdminDiscipline {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AdminRoleStudentDiscipline/:studentId/:classId/:section/:session/:term/:subjectId"
          />
          <Route
            render={(props) => (
              <SubjectDisciplineMarksReport
                {...props}
                snackbar={this.myFunction}
                printFunc={this.PrintContent}
              />
            )}
            path="/dashboard/AdminRoleStudentDiscipline/:studentId/:classId/:section/:session/:term"
          />
          <Route
            render={(props) => (
              <AdminStudentDiscipline {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AdminRoleStudentDiscipline"
          />
          {/* End of Role: Admin => Admin_Student_Discipline */}

          {/* Start of Continouse Assesments Routes */}
          <Route
            render={(props) => (
              <ContinousEditAssignMarksModule
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/CAEditAssignMarks/:stId/:pId"
          />
          <Route
            render={(props) => (
              <CaReportList {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/CaReportList"
          />
          <Route
            render={(props) => (
              <SelectionCriteria {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/SelectionCriteria/:pid"
          />
          <Route
            render={(props) => (
              <CaTermWiseReport
                {...props}
                printFunc={this.PrintContent}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/CaTermWiseReport/:classId/:sectionId/:sessionId/:term"
          />
          <Route
            render={(props) => (
              <CaSingleStudentTermReport
                {...props}
                printFunc={this.PrintContent}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/CaSingleStudentTermReport/:classId/:sectionId/:sessionId/:term/:studentId"
          />
          <Route
            render={(props) => (
              <ContinousNewToolCreation {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ContinousNewToolCreation"
          />
          <Route
            render={(props) => (
              <CaAddExamFeature {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/CaAddExamFeature/:pid/:type"
          />
          <Route
            render={(props) => (
              <CaAddExamV2Feature {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/CaAddExamV2Feature/:pid"
          />
          <Route
            render={(props) => (
              <CaViewExamV2Feature {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/CaViewExamV2Feature/:pid"
          />
          <Route
            render={(props) => (
              <CaViewExamFeature {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/CaViewExamFeature/:pid"
          />
          <Route
            render={(props) => (
              <CaViewFeature {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/CaViewFeature/:pid/:type"
          />
          <Route
            render={(props) => (
              <CaAddFeature {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/CaAddFeature/:pid/:type"
          />
          <Route
            render={(props) => (
              <CaAverageReportSheet {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/CaAverageSheet/:pid"
          />
          <Route
            render={(props) => (
              <AssignMarks {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/CA_ASSIGN_MAKRS/:id/:type"
          />

          <Route
            render={(props) => (
              <ContinousAssesmentReport {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ContinouseAssesmentViewList/:pid"
          />

          <Route
            render={(props) => (
              <ContinouseAssesmentViewList
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/ContinouseAssesmentViewList"
          />
          <Route
            render={(props) => (
              <PrimaryInfo {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/CAPrimaryInfo"
          />
          <Route
            render={(props) => (
              <PrimaryInfoDelete {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/CAPrimaryInfoView/:id"
          />

          <Route
            render={(props) => (
              <PrimaryInfoView {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/CAPrimaryInfoView"
          />
          <Route
            render={(props) => (
              <CaResultSheetSearch {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ContinousResultSheet"
          />
          <Route
            render={(props) => (
              <AcademicHeadResultSheet {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AcademicHeadResultSheet"
          />
          <Route
            render={(props) => (
              <HODEvaluationFormView {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/HODEvaluationFormView/:name"
          />

          <Route
            render={(props) => (
              <ContnuousRenderTools {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/CASubToolView/:id/:type"
          />

          <Route
            render={(props) => (
              <ContinuousSubToolView {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/CASubToolView"
          />
          <Route
            render={(props) => (
              <ContinuousSubToolViewAdmin {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/CASubToolViewAdmin"
          />

          {/* Primary Info Routes */}

          {/* End of Primary Info */}

          {/* End of Continouse Assesment Routes */}

          <Route
            component={StudentSkillViewAdmin}
            path="/dashboard/StudentSkillViewAdmin"
          />
          <Route
            component={StudentSkillViewP}
            path="/dashboard/StudentSkillViewP"
          />
          <Route
            component={StudentDescriptiveViewAdmin}
            path="/dashboard/StudentDescriptiveViewAdmin"
          />
          <Route
            component={StudentDescriptiveViewP}
            path="/dashboard/StudentDescriptiveViewP"
          />
          {/* Start of Role : Class Discipline For Staff */}
          <Route
            render={(props) => (
              <ClassDiscipline {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ClassDiscipline"
          />

          <Route
            render={(props) => (
              <AddClassDiscipline {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddClassDiscipline"
          />
          <Route
            render={(props) => (
              <ViewClassDiscipline {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewClassDiscipline"
          />
          <Route
            render={(props) => (
              <ViewClassDisciplinaryRecords
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/ViewClassDisciplinaryRecords"
          />
          <Route
            render={(props) => (
              <Sheet1
                {...props}
                snackbar={this.myFunction}
                printFunc={this.PrintContent}
              />
            )}
            path="/dashboard/ClassDisciplineReport"
          />
          {/* End of Role : Class Discipline For Staff */}
          {/* Start of Role : Class Discipline For Admin/Owner */}
          <Route
            render={(props) => <ClassDisciplineDashboard {...props} />}
            path="/dashboard/ClassDisciplineDashboard"
          />
          <Route
            render={(props) => (
              <UpdateClassDiscipline {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/UpdateClassDiscipline"
          />
          {/* End of Role : Class Discipline For Admin/Owner */}
          <Route
            render={(props) => (
              <AddParent {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddParent"
          />
          <Route
            component={AlreadyRegister}
            path="/dashboard/UpdateParentRegistration"
          />
          <Route
            component={SearchParentByChild}
            path="/dashboard/SearchParentByChild"
          />
          <Route
            component={RegisterStudentToParent}
            path="/dashboard/RegisterStudentToParent"
          />

          {/* Student Admission version-v2 imports */}
          <Route
            render={(props) => (
              <StudentImageUpload {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/StudentImageUpload/:urlId/:type"
          />

          <Route
            render={(props) => (
              <StudentDocsUpload {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/StudentDocsUpload/:urlId/:type"
          />

          <Route
            render={(props) => (
              <ParentDocsUpload {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ParentDocsUpload/:urlId/:type"
          />
          <Route
            render={(props) => (
              <SystemSmartDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/SystemSmartDashboard"
          />

          {/* End of Admission version-v2 imports ends */}
          {/* Dashboards for communication */}
          <Route
            component={CommunicationDashboard}
            path="/dashboard/CommunicationDashboard"
          />
          <Route component={AddBulletin} path="/dashboard/AddBulletin" />
          <Route
            render={(props) => (
              <EventCalender {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/CEventCalender"
          />
          <Route
            component={ParentPortalManagerDashboard}
            path="/dashboard/ParentPortalManagerDashboard"
          />
          {/* End Dashboards For Communication */}

          {/* Dashboards for communication */}
          <Route
            render={(props) => (
              <StudentAttendance {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/StudentAttendance"
          />
          <Route
            render={(props) => (
              <ViewStudentAttendance {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewStudentAttendance"
          />
          <Route
            render={(props) => (
              <MonthlyAttendanceView {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/MonthlyAttendanceView"
          />
          <Route
            render={(props) => (
              <RangeAttendanceView {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/RangeAttendanceView"
          />
          {/* End Dashboards For Communication */}
          {/* Admission Manager CRUD Start  */}
          <Route
            render={(props) => (
              <AddManager {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddManager"
          />
          <Route
            render={(props) => (
              <ViewManager {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewManager"
          />
          <Route
            render={(props) => (
              <AdminExamEntry {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AdminExamEntryDashboard"
          />
          <Route
            component={ManagersDashboard}
            path="/dashboard/ManagersDashboard"
          />
          {/* Admission Manager CRUD END  */}
          <Route
            component={AdmissionDashboard}
            path="/dashboard/AdmissionDashboard"
          />
          <Route
            component={StudentAttendanceDashboard}
            path="/dashboard/StudentAttendanceDashboard"
          />
          {/* Fees Finance Module */}
          <Route
            render={(props) => (
              <AddFeeType {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddFeeTypeNew"
          />
          <Route
            render={(props) => (
              <ViewFeeType {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewFeeTypeNew"
          />
          <Route
            render={(props) => (
              <ViewAcademicFeeType {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewAcademicFeeType"
          />
          <Route
            render={(props) => (
              <ViewOtherFeeType {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewOtherFeeType"
          />
          <Route
            render={(props) => (
              <FeesCollection {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/FeesCollectionNew"
          />
          <Route
            render={(props) => (
              <MonthlyFeeList {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/MonthlyFeeListNew"
          />
          <Route
            render={(props) => (
              <FeeReceipt {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/FeeReceipt/:id"
          />
          <Route
            render={(props) => (
              <StudentAttendanceView {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/StudentFeeView"
          />
          <Route
            render={(props) => (
              <AccountsDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AccountsDashboard"
          />
          <Route
            render={(props) => (
              <FeesDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/FeesDashboard"
          />
          <Route
            render={(props) => (
              <AccountsDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AccountsDashboard"
          />
          <Route
            render={(props) => (
              <FeeReportDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/FeeReportDashboard"
          />
          <Route
            render={(props) => (
              <DateWiseFeeReport {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/DateWiseFeeReport"
          />

          <Route
            render={(props) => (
              <StudentFeeHistoryP {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/StudentFeeHistoryP"
          />
          {/* End of Fees Finance Module */}

          {/* Start Assign Subject to Teacher */}
          <Route
            render={(props) => (
              <AssignSubject {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AssignSubject"
          />

          <Route
            render={(props) => (
              <ViewAssignSubject {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewAssignSubject"
          />

          {/* End Assign Subject to Teacher */}

          {/* Start Indent*/}

          {/* 					<Route
						render={props => (
							<ViewBillingTemplate {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/ViewBillingTemplate"
					/>
 */}
          <Route
            render={(props) => (
              <IndentFormTemplate {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/IndentFormTemplate/:id"
          />

          <Route
            render={(props) => (
              <AddIndent {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddIndent"
          />

          <Route
            render={(props) => (
              <ViewIndentForm {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewIndentForm"
          />

          <Route
            render={(props) => (
              <ViewIndentByStaff {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewIndentByStaff"
          />

          {/* End Indent */}

          {/* Start Teacher Observation */}
          <Route
            render={(props) => (
              <TeacherObservationBlankTemplate
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/TeacherObservationBlankTemplate/:id"
          />
          <Route
            render={(props) => (
              <AddTeacherEvaluation {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddTeacherEvaluation"
          />
          <Route
            render={(props) => (
              <ViewTeacherEvaluation {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewTeacherEvaluation"
          />

          <Route
            render={(props) => (
              <EditEvaluationForm {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/EditEvaluationForm/:id"
          />

          <Route
            render={(props) => (
              <ViewEvaluationByStaff {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewEvaluationByStaff"
          />

          <Route
            render={(props) => (
              <EvaluationByStaffTemplate
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/EvaluationByStaffTemplate/:id"
          />

          {/* End Teacher Observation */}

          {/* Start Generate Visitor Card */}
          <Route
            render={(props) => (
              <GenerateVisitorCardView {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/GenerateVisitorCardView"
          />

          <Route
            render={(props) => (
              <AddVisitorEntry {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddVisitorEntry"
          />

          <Route
            render={(props) => (
              <VisitorEntryRecord {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/VisitorEntryRecord"
          />

          {/* End Generate Visitor Card */}

          {/* Start Medical Centre module */}
          <Route
            render={(props) => (
              <AddDoctor {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddDoctor"
          />

          <Route
            render={(props) => (
              <ViewDoctor {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewDoctor"
          />

          <Route
            render={(props) => (
              <AddPatient {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddPatient"
          />

          <Route
            render={(props) => (
              <AddPatient2 {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddPatient2"
          />

          <Route
            render={(props) => (
              <PatientDetail {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/PatientDetail"
          />

          <Route
            render={(props) => (
              <MedicalSlipTemplate {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/MedicalSlipTemplate/:id/:post"
          />
          {/* End Medical Centre module */}

          <Route
            render={(props) => (
              <MedicalHistory {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/MedicalHistory"
          />

          {/* Student Progress Module */}
          <Route
            render={(props) => (
              <AddStudentProgressReport {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddStudentProgressReport"
          />
          <Route
            render={(props) => (
              <ViewStudentProgressReport
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/ViewStudentProgressReport"
          />
          <Route
            render={(props) => (
              <PrintStudentProgressReport
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/PrintStudentProgressReport"
          />
          <Route
            render={(props) => (
              <StudentProgressReportDashboard
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/StudentProgressReportDashboard"
          />
          {/* End of Student Progress Module */}

          {/* Start of Floor Plan */}
          <Route render={(props) => (
            <FloorPlanDashboard {...props} snackbar={this.myFunction} />
          )}
            path="/dashboard/FloorPlanDashboard"
          />
          <Route render={(props) => (
            <Floor {...props} snackbar={this.myFunction} />
          )}
            path="/dashboard/Floor"
          />
          <Route render={(props) => (
            <Room {...props} snackbar={this.myFunction} />
          )}
            path="/dashboard/Room"
          />

          {/* End of Floor Module */}

          {/* Start of Campus Maintenance */}

          <Route
            render={(props) => (
              <AddFloor {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddFloor"
          />
          <Route
            render={(props) => (
              <ViewFloor {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewFloor"
          />
          <Route
            render={(props) => (
              <AddRoom {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddRoom"
          />
          <Route
            render={(props) => (
              <ViewRoom {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewRoom"
          />
          <Route
            render={(props) => (
              <AddCleanlinessCategory {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddCleanlinessCategory"
          />
          <Route
            render={(props) => (
              <ViewCleanlinessCategory {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewCleanlinessCategory"
          />
          <Route
            render={(props) => (
              <AddCyclicCleanliness {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddCyclicCleanliness"
          />
          <Route
            render={(props) => (
              <ViewCyclicCleanliness {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewCyclicCleanliness"
          />
          <Route
            render={(props) => (
              <PrintCyclicCleanliness {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/PrintCyclicCleanliness"
          />
          <Route
            render={(props) => (
              <AddEmergencyMaintenance {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddEmergencyMaintenance"
          />
          <Route
            render={(props) => (
              <ViewEmergencyMaintenance {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewEmergencyMaintenance"
          />
          <Route
            render={(props) => (
              <PrintEmergencyMaintenance
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/PrintEmergencyMaintenance"
          />

          <Route
            render={(props) => (
              <NotificationEmergencyMaintenance
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/NotificationEmergencyMaintenance"
          />
          <Route
            render={(props) => (
              <CampusMaintenanceDashboard
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/CampusMaintenanceDashboard"
          />

          {/* End of Campus Maintenance*/}
          {/* Start of SEN Manager*/}

          <Route
            render={(props) => (
              <AddTalentCategory {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddTalentCategory"
          />
          <Route
            render={(props) => (
              <ViewTalentCategory {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewTalentCategory"
          />
          <Route
            render={(props) => (
              <AddTalentSubCategory {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddTalentSubCategory"
          />

          <Route
            render={(props) => (
              <ViewTalentSubCategory {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewTalentSubCategory"
          />
          <Route
            render={(props) => (
              <AddStudentTalent {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddStudentTalent"
          />
          <Route
            render={(props) => (
              <ViewStudentTalent {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewStudentTalent"
          />
          <Route
            render={(props) => (
              <PrintStudentTalent {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/PrintStudentTalent"
          />
          <Route
            render={(props) => (
              <StudentTalentDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/StudentTalentDashboard"
          />
          {/* End of SEN Manager*/}

          {/* Start of Wellbeing Manager*/}

          <Route
            render={(props) => (
              <ViewAbsentReport {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewAbsentReport"
          />
          <Route
            render={(props) => (
              <ViewLateReport {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewLateReport"
          />
          <Route
            render={(props) => (
              <PrintAbsentReport {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/PrintAbsentReport"
          />
          <Route
            render={(props) => (
              <PrintLateReport {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/PrintLateReport"
          />
          <Route
            render={(props) => (
              <ViewFlagManagement {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewFlagManagement"
          />
          <Route
            render={(props) => (
              <PrintFlagManagement {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/PrintFlagManagement"
          />
          <Route
            render={(props) => (
              <ViewDailyDisciplineReport
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/ViewDailyDisciplineReport"
          />
          <Route
            render={(props) => (
              <DailyReportsWellBeingManager
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/DailyReportsWellBeingManager"
          />
          <Route
            render={(props) => (
              <PrintDailyDisciplineReport
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/PrintDailyDisciplineReport"
          />
          <Route
            render={(props) => (
              <Sheet1
                {...props}
                snackbar={this.myFunction}
                printFunc={this.PrintContent}
              />
            )}
            path="/dashboard/PrinClassDetailDisciplineReport"
          />
          <Route
            render={(props) => (
              <ViewDailyClassAttendance {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewDailyClassAttendance"
          />
          <Route
            render={(props) => (
              <PrintDailyClassAttendanceReport
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/PrintDailyClassAttendanceReport"
          />
          <Route
            render={(props) => (
              <ViewMonthlyStudentAttendanceReport
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/ViewMonthlyStudentAttendanceReport"
          />
          <Route
            render={(props) => (
              <PrintMonthlyStudentAttendanceReport
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/PrintMonthlyStudentAttendanceReport"
          />
          <Route
            render={(props) => (
              <ViewStudentSkillAverageReport
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/ViewStudentSkillAverageReport"
          />
          <Route
            render={(props) => (
              <PrintStudentSkillAverageReport
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/PrintStudentSkillAverageReport"
          />
          <Route
            render={(props) => (
              <ViewFlagManagementSkill {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewFlagManagementSkill"
          />
          <Route
            render={(props) => (
              <PrintFlagManagementSkill {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/PrintFlagManagementSkill"
          />
          <Route
            render={(props) => (
              <ViewStudentDisciplineReport
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/ViewStudentDisciplineReport"
          />
          <Route
            render={(props) => (
              <PrintStudentDisciplineReport
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/PrintStudentDisciplineReport"
          />

          <Route
            render={(props) => (
              <MedicalSlipTemplate {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/MedicalSlipTemplate/:id/:post"
          />
          {/* Dashboards */}
          <Route
            render={(props) => (
              <ClassDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ClassDashboard"
          />
          <Route
            render={(props) => (
              <SectionDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/SectionDashboard"
          />
          <Route
            render={(props) => (
              <SessionDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/SessionDashboard"
          />
          <Route
            render={(props) => (
              <SubjectDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/SubjectDashboard"
          />
          <Route
            render={(props) => (
              <ClassTeacherDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ClassTeacherDashboard"
          />
          <Route
            render={(props) => (
              <IndentDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/IndentDashboard"
          />

          <Route
            render={(props) => (
              <IndentStaffDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/IndentStaffDashboard"
          />

          <Route
            render={(props) => (
              <IndentAdminDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/IndentAdminDashboard"
          />
          <Route
            render={(props) => (
              <IndentAdminAdd {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/IndentAdminAdd"
          />
          <Route
            render={(props) => (
              <IndentAdminView {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/IndentAdminView"
          />
          <Route
            render={(props) => (
              <TeacherEvaluationDashboard
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/TeacherEvaluationDashboard"
          />

          <Route
            render={(props) => (
              <PastoralDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/PastoralDashboard"
          />
          <Route
            render={(props) => (
              <MedicalDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/MedicalDashboard"
          />

          <Route
            render={(props) => (
              <DoctorDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/DoctorDashboard"
          />

          <Route
            render={(props) => (
              <PatientDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/PatientDashboard"
          />

          <Route
            render={(props) => (
              <CampusSecurityDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/CampusSecurityDashboard"
          />

          <Route
            render={(props) => (
              <LeaveDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/LeaveDashboard"
          />
          <Route
            render={(props) => (
              <ViewLeave {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewLeave"
          />

          <Route
            render={(props) => (
              <AddLeave {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddLeave"
          />

          <Route
            render={(props) => (
              <ViewLeaveByStudent {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewLeaveByStudent"
          />

          <Route
            render={(props) => (
              <StudentLeaveDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/StudentLeaveDashboard"
          />

          <Route
            render={(props) => (
              <TourDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/TourDashboard"
          />

          <Route
            render={(props) => (
              <ActivityDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ActivityDashboard"
          />

          <Route
            render={(props) => (
              <CertificateDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/CertificateDashboard"
          />

          <Route
            render={(props) => (
              <OtherCertificateDashboard
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/OtherCertificateDashboard"
          />

          <Route
            render={(props) => (
              <AwardDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AwardDashboard"
          />

          <Route
            render={(props) => (
              <CertificateCategoryDashboard
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/CertificateCategoryDashboard"
          />

          <Route
            render={(props) => (
              <GameDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/GameDashboard"
          />

          <Route
            render={(props) => (
              <OffGameDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/OffGameDashboard"
          />

          <Route
            render={(props) => (
              <EventCertificateDashboard
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/EventCertificateDashboard"
          />

          <Route
            render={(props) => (
              <AddTour {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddTour"
          />

          <Route
            render={(props) => (
              <StudentCoordinatorDashboard
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/StudentCoordinatorDashboard"
          />

          <Route
            render={(props) => (
              <DriverDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/DriverDashboard"
          />

          <Route
            render={(props) => (
              <VehicleDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/VehicleDashboard"
          />

          <Route
            render={(props) => (
              <RouteDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/RouteDashboard"
          />

          <Route
            render={(props) => (
              <TransportMaintenanceDashboard
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/TransportMaintenanceDashboard"
          />

          <Route
            render={(props) => (
              <StaffCoordinatorDashboard
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/StaffCoordinatorDashboard"
          />

          <Route
            render={(props) => (
              <AssignSubjectDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AssignSubjectDashboard"
          />

          <Route
            render={(props) => (
              <AssignHODDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AssignHODDashboard"
          />
          <Route
            render={(props) => (
              <HODSubjectDisciplineView {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/HODSubjectDisciplineView/:name"
          />
          <Route
            render={(props) => (
              <HODSubjectDisciplineEdit {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/HODSubjectDisciplineEdit/:name"
          />

          <Route
            render={(props) => (
              <ExclusionDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ExclusionDashboard"
          />

          <Route
            render={(props) => (
              <AssignCoordinatorDashboard
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/AssignCoordinatorDashboard"
          />

          <Route
            render={(props) => (
              <StudentProfileDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/StudentProfileDashboard"
          />

          <Route
            render={(props) => (
              <CommunicationManagerDashboard
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/CommunicationManagerDashboard"
          />

          <Route
            render={(props) => (
              <CMSManagerDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/CMSManagerDashboard"
          />

          <Route
            render={(props) => (
              <CMSStaffDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/CMSStaffDashboard"
          />

          <Route
            render={(props) => (
              <BioMetricDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/BioMetricDashboard"
          />

          <Route
            render={(props) => (
              <BioMetricStaffDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/BioMetricStaffDashboard"
          />

          <Route
            render={(props) => (
              <SecurtiyDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/SecurityDashboard"
          />

          <Route
            render={(props) => (
              <AcademicAdminDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AcademicAdminDashboard"
          />

          <Route
            render={(props) => (
              <FrontDeskStudentDashboard
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/FrontDeskStudentDashboard"
          />

          <Route
            render={(props) => (
              <CommuncationStaffDashboard
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/CommunicationStaffDashboard"
          />

          <Route
            render={(props) => (
              <CommunicationParentDashboard
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/CommunicationParentDashboard"
          />

          <Route
            render={(props) => (
              <EventDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/EventDashboard"
          />

          {/* Dashboards */}

          {/* Start Tour Module */}

          <Route
            render={(props) => (
              <AddTour {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddTour"
          />

          <Route
            render={(props) => (
              <ViewTour {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewTour"
          />

          <Route
            render={(props) => (
              <ViewClassesInTour {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewClassesInTour/:id"
          />

          <Route
            render={(props) => (
              <ViewFilterTour {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewFilterTour"
          />

          <Route
            render={(props) => (
              <ViewTourByStaff {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewTourByStaff"
          />

          <Route
            render={(props) => (
              <ViewTourByStudent {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewTourByStudent"
          />

          {/* End Tour Module*/}

          {/* Certificate Module*/}

          <Route
            render={(props) => (
              <AddOtherCertificate {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddOtherCertificate"
          />

          <Route
            render={(props) => (
              <ViewOtherCertificate {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewOtherCertificate"
          />

          <Route
            render={(props) => (
              <OtherCertificateTemplate1
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/OtherCertificateTemplate1/:id"
          />

          <Route
            render={(props) => (
              <OtherCertificateTemplate2
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/OtherCertificateTemplate2/:id"
          />

          <Route
            render={(props) => (
              <AddCertificateCategory {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddCertificateCategory"
          />

          <Route
            render={(props) => (
              <ViewCertificateCategory {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewCertificateCategory"
          />

          <Route
            render={(props) => (
              <OtherCertificateCategoryTemplate1
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/OtherCertificateCategoryTemplate1/:id"
          />

          <Route
            render={(props) => (
              <OtherCertificateCategoryTemplate2
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/OtherCertificateCategoryTemplate2/:id"
          />

          {/* End Certificate Module*/}

          {/* Award Module*/}

          <Route
            render={(props) => (
              <AddAwardCategory {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddAwardCategory"
          />

          <Route
            render={(props) => (
              <ViewAwardCategory {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewAwardCategory"
          />

          <Route
            render={(props) => (
              <AddAward {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddAward"
          />

          <Route
            render={(props) => (
              <ViewAward {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewAward"
          />

          <Route
            render={(props) => (
              <ViewStudentInAward {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewStudentInAward/:id"
          />

          <Route
            render={(props) => (
              <ViewStudentInReward {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewStudentInReward/:id"
          />

          <Route
            render={(props) => (
              <AwardTemplate1 {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AwardTemplate1/:id"
          />

          <Route
            render={(props) => (
              <AwardTemplate2 {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AwardTemplate2/:id"
          />

          <Route
            render={(props) => (
              <ViewAwardByStudent {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewAwardByStudent"
          />

          <Route
            render={(props) => (
              <ViewRewardByStudent {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewRewardByStudent"
          />
          {/* End Award Module*/}

          {/* Start Game Module*/}

          <Route
            render={(props) => (
              <AddGame {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddGame"
          />

          <Route
            render={(props) => (
              <ViewGame {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewGame"
          />

          <Route
            render={(props) => (
              <AddOffGame {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddOffGame"
          />

          <Route
            render={(props) => (
              <ViewOffGame {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewOffGame"
          />

          {/* End Game Module*/}

          {/* Start Student Coordinator Module*/}

          <Route
            render={(props) => (
              <AssignClassNewAdmit {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AssignClassNewAdmit"
          />

          <Route
            render={(props) => (
              <ClassOfStudent {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ClassOfStudent"
          />

          <Route
            render={(props) => (
              <TransferStudent {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/TransferStudent"
          />

          {/* End Student Coordinator Module*/}

          {/* Start Game Module*/}

          <Route
            render={(props) => (
              <AddEvent {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddEvent"
          />

          <Route
            render={(props) => (
              <MedicalHistory {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/MedicalHistory"
          />

          {/* End of Wellbeing Manager*/}
          {/* Start of Pastrol*/}
          <Route
            render={(props) => (
              <PastrolDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/PastrolDashboard"
          />
          <Route
            render={(props) => (
              <ViewEvent {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewEvent"
          />

          <Route
            render={(props) => (
              <UpdateEvent {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/UpdateEvent"
          />

          <Route
            render={(props) => (
              <PastrolSENManagerDashboard
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/PastrolSENManagerDashboard"
          />
          <Route
            render={(props) => (
              <StaffEventView {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/StaffEventView"
          />

          <Route
            render={(props) => (
              <PastrolWellbeingManagerDashboard
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/PastrolWellbeingManagerDashboard"
          />

          {/* End of Pastrol*/}
          <Route
            render={(props) => (
              <ViewStudentEvents {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewStudentEvents"
          />

          {/* End Game Module*/}

          {/* End of Event Module */}
          {/* Start Event Module*/}

          <Route
            render={(props) => (
              <AddEventCertificate {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddEventCertificate"
          />

          <Route
            render={(props) => (
              <ViewEventCertificate {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewEventCertificate"
          />

          <Route
            render={(props) => (
              <EventAttendanceCertificate
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/EventAttendanceCertificate/:id"
          />

          <Route
            render={(props) => (
              <EventCertificateTemplate1
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/EventCertificateTemplate1/:id/:post"
          />

          <Route
            render={(props) => (
              <EventCertificateTemplate2
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/EventCertificateTemplate2/:id/:post"
          />

          <Route
            render={(props) => (
              <EventCertificateByStudent
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/EventCertificateByStudent"
          />

          <Route
            render={(props) => (
              <EventCertificateByStaff {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/EventCertificateByStaff"
          />

          {/* End Event Module*/}

          {/* Start Transport Coordinator Module*/}

          <Route
            render={(props) => (
              <AddTransportMaintenance {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddTransportMaintenance"
          />

          <Route
            render={(props) => (
              <ViewTransportMaintenance {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewTransportMaintenance"
          />

          <Route
            render={(props) => (
              <MaintenanceReport {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/MaintenanceReport/:id"
          />

          {/* End Transport Coordinator Module*/}

          {/* Start Staff Coordinator Module*/}

          <Route
            render={(props) => (
              <StaffToHOD {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/StaffToHOD"
          />

          <Route
            render={(props) => (
              <ViewStaffInHOD {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewStaffInHOD"
          />

          <Route
            render={(props) => (
              <AssignStaffCoordinatorRenew
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/AssignStaffCoordinator"
          />

          <Route
            render={(props) => (
              <ViewStaffSubjectRenew {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewStaffSubject"
          />

          <Route
            render={(props) => (
              <AssignSubjectToStaffRenew
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/AssignSubjectToStaff/:id/:name"
          />

          <Route
            render={(props) => (
              <ViewStaffCoordinatorRenew
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/ViewStaffCoordinator"
          />

          {/* End Staff Coordinator Module*/}

          {/* Start Exclusion Module*/}

          <Route
            render={(props) => (
              <AddExclusion {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddExclusion"
          />

          <Route
            render={(props) => (
              <ViewExclusion {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewExclusion"
          />

          <Route
            render={(props) => (
              <InvestigationCommittee {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/InvestigationCommittee/:id"
          />

          <Route
            render={(props) => (
              <ViewInvestigationCommittee
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/ViewInvestigationCommittee"
          />

          <Route
            render={(props) => (
              <InvestigationCommittee {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewInvestigationCommittee"
          />

          <Route
            render={(props) => (
              <InvestigationList {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/InvestigationList"
          />

          <Route
            render={(props) => (
              <StudentExclusionRecord {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/StudentExclusionRecord"
          />

          <Route
            render={(props) => (
              <ViewExclusionByStaff {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewExclusionByStaff"
          />

          {/* End Exclusion Module*/}

          {/* Start of Entry Exam */}
          <Route
            render={(props) => (
              <AddEntryExam {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddEntryExam"
          />

          <Route
            render={(props) => (
              <ViewEntryExam {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewEntryExam"
          />

          <Route
            render={(props) => (
              <ViewSubjectMarks {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewSubjectMarks/:id"
          />
          <Route
            render={(props) => (
              <EntryExamDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/EntryExamDashboard"
          />
          {/* End of Entry Exam */}

          {/* Start Student Profile Module*/}

          <Route
            render={(props) => (
              <StudentSkillByStudentProfileRenew
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/StudentSkillByStudentProfile"
          />

          <Route
            render={(props) => (
              <StudentDescriptiveByStudentProfileRenew
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/StudentDescriptiveByStudentProfile"
          />

          <Route
            render={(props) => (
              <ContinousAssessmentByStudentProfileRenew
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/ContinousAssessmentByStudentProfile"
          />
          <Route
            render={(props) => (
              <CaParentReportList {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/CaParentReportList"
          />

          <Route
            render={(props) => (
              <StudentProgressReportByStudentProfileRenew
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/StudentProgressReportByStudentProfile"
          />

          <Route
            render={(props) => (
              <PrintStudentProgressReportStudentProfileRenew
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/PrintStudentProgressReportStudentProfile/:studentId/:classId/:sectionId/:subjectId/:sessionId/:term"
          />

          <Route
            render={(props) => (
              <StudentDisciplineByStudentProfileRenew
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/StudentDisciplineByStudentProfile"
          />

          <Route
            render={(props) => (
              <PrintSkillReportStudentProfileRenew
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/PrintSkillReportStudentProfile/:id/:class/:section/:term/:session"
          />

          <Route
            render={(props) => (
              <PrintDescriptiveReportStudentProfileRenew
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/PrintDescriptiveReportStudentProfile/:id/:class/:section/:term/:session"
          />

          <Route
            render={(props) => (
              <PrintDisciplineReportStudentProfileRenew
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/PrintDisciplineReportStudentProfile/"
          />

          <Route
            render={(props) => (
              <StudentAttendanceByStudentProfileRenew
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/StudentAttendanceByStudentProfile/"
          />

          {/* End Student Profile Module*/}
          {/* Manager Post */}

          <Route
            render={(props) => (
              <ManagerPostDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ManagerPostDashboard"
          />

          {/* Manager Post*/}

          <Route
            render={(props) => (
              <BillingDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/BillingDashboard"
          />
          <Route
            render={(props) => (
              <AddBilling {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddBilling"
          />
          <Route
            render={(props) => (
              <ViewBilling {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewBilling"
          />

          {/* Library Module*/}

          <Route
            render={(props) => (
              <AddBook {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddBook"
          />
          <Route
            render={(props) => (
              <AddBookCategory {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddBookCategory"
          />
          <Route
            render={(props) => (
              <ViewBookCategory {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewBookCategory"
          />

          <Route
            render={(props) => (
              <ViewBook {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewBook"
          />

          <Route
            render={(props) => (
              <IssueBook {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/IssueBook"
          />

          <Route
            render={(props) => (
              <ViewIssuedBook {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewIssuedBook"
          />

          <Route
            render={(props) => (
              <LibraryDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/LibraryDashboard"
          />

          {/* End of Library Module */}

          {/* Payroll Module */}

          <Route
            render={(props) => (
              <AddPayroll {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddPayroll"
          />

          <Route
            render={(props) => (
              <ViewPayroll {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewPayroll"
          />

          <Route
            render={(props) => (
              <ViewPayslip {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewPayslip"
          />

          <Route
            render={(props) => (
              <PayslipDetails {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/PayslipDetails"
          />

          <Route
            render={(props) => (
              <PayRollDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/PayRollDashboard"
          />

          {/* End of Payroll Module */}

          {/* Communication Coordinator Start */}

          <Route
            render={(props) => (
              <ListOfRecipients {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ListOfRecipients"
          />

          <Route
            render={(props) => (
              <MessageDean {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/MessageDean"
          />

          <Route
            render={(props) => (
              <MessageHOD {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/MessageHOD"
          />

          <Route
            render={(props) => (
              <MessageAcademicCoordinator
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/MessageAC"
          />

          <Route
            render={(props) => (
              <MessageManager {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/MessageManager"
          />

          <Route
            render={(props) => (
              <MessageStaff {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/MessageStaff"
          />

          <Route
            render={(props) => (
              <MessageParent {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/MessageParent"
          />

          <Route
            render={(props) => (
              <ManagerInbox {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ManagerInbox"
          />

          <Route
            render={(props) => (
              <StaffInbox {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/StaffInbox"
          />

          <Route
            render={(props) => (
              <DeanInbox {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/DeanInbox"
          />

          <Route
            render={(props) => (
              <HODInbox {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/HODInbox"
          />

          <Route
            render={(props) => (
              <InboxComponent {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/Inbox"
          />

          <Route
            render={(props) => (
              <ACInbox {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AcademicCoordinatorInbox"
          />

          <Route
            render={(props) => (
              <CoordinatorInbox {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/CoordinatorInbox/:recipient"
          />

          <Route
            render={(props) => (
              <HODMessage {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/HODMessage"
          />

          <Route
            render={(props) => (
              <DeanManageMessage {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/DeanManageMessage"
          />



          <Route
            render={(props) => (
              <DeanMessage {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/DeanMessage"
          />


          <Route
            render={(props) => (
              <ManagerMessage {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ManagerMessage"
          />

          <Route
            render={(props) => (
              <StaffMessage {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/StaffMessage"
          />

          <Route
            render={(props) => (
              <AcademicCoordinatorMessage
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/ACMessage"
          />


          {/* End Communication Coordinator */}

          {/* Start CMS HOD */}
          <Route
            render={(props) => (
              <CMSHODDashboard
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/CMSHODDashboard"
          />
          <Route
            render={(props) => (
              <IndentHODDashboard
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/IndentHODDashboard"
          />
          <Route
            render={(props) => (
              <IndentHODAdd
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/IndentHODAdd"
          />
          <Route
            render={(props) => (
              <IndentHODView
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/IndentHODView"
          />


          {/* END CMS HOD */}
          {/* Start CMS HOD Leave */}
          <Route
            render={(props) => (
              <HODLeaveDashboard
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/HODLeaveDashboard"
          />
          <Route
            render={(props) => (
              <AddHODLeave
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/AddHODLeave"
          />
          <Route
            render={(props) => (
              <ViewHODLeave
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/ViewHODLeave"
          />
          <Route
            render={(props) => (
              <AddHODShortLeave
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/AddHODShortLeave"
          />
          <Route
            render={(props) => (
              <ViewHODShortLeave
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/ViewHODShortLeave"
          />
          {/* END CMS HOD Leave */}

          <Route
            render={(props) => (
              <CommunicationHODDashboard
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/CommunicationHODDashboard"
          />
          {/* Portal Coordinator Start */}

          <Route
            render={(props) => (
              <StudentUsers {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/StudentUsers"
          />

          <Route
            render={(props) => (
              <StaffUsers {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/StaffUsers"
          />
          {/* Start Staff Modules */}

          <Route
            render={(props) => (
              <AddHomeWorkStaff {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddHomeWorkStaff"
          />
          <Route
            render={(props) => (
              <AddAssignmentStaff {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddAssignmentStaff"
          />
          <Route
            render={(props) => (
              <AddLessonPlanStaff {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddLessonPlanStaff"
          />
          <Route
            render={(props) => (
              <AddExamInfoStaff {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddExamInfoStaff"
          />
          <Route
            render={(props) => (
              <AddExamDetailsStaff {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddExamDetailsStaff"
          />

          <Route
            render={(props) => (
              <ParentUsers {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ParentUsers"
          ></Route>
          <Route
            render={(props) => (
              <AddSyllabusStaff {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddSyllabusStaff"
          />
          <Route
            render={(props) => (
              <AddQuizInfoStaff {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddQuizInfoStaff"
          />

          <Route
            render={(props) => (
              <DeanUsers {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/DeanUsers"
          />
          <Route
            render={(props) => (
              <AddQuizStaff {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddQuizStaff"
          />

          <Route
            render={(props) => (
              <CoordinatorUsers {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/CoordinatorUsers"
          />
          <Route
            render={(props) => (
              <AddTestInfoStaff {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddTestInfoStaff"
          />

          <Route
            render={(props) => (
              <HODUsers {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/HODUsers"
          />
          <Route
            render={(props) => (
              <AddTestStaff {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddTestStaff"
          />
          <Route
            render={(props) => (
              <AddPastPapersStaff {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddPastPapersStaff"
          />

          <Route
            render={(props) => (
              <ManagerUsers {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ManagerUsers"
          />
          <Route
            render={(props) => (
              <ViewHomeWorkStaff {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewHomeWorkStaff"
          />

          <Route
            render={(props) => (
              <AdminUsers {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AdminUsers"
          />
          <Route
            render={(props) => (
              <PrintHomeWorkStaff {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/PrintHomeWorkStaff"
          />

          <Route
            render={(props) => (
              <UsersList {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/UsersList"
          />

          {/* End Portal Coordinator */}

          {/* Start Class Teacher */}

          <Route
            render={(props) => (
              <AddClassTeacherRenew {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddClassTeacher"
          />

          <Route
            render={(props) => (
              <ViewClassTeacherRenew {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewClassTeacher"
          />

          {/* End Class Teacher */}

          <Route
            render={(props) => (
              <ClassDiscilineDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ClassDiscilineDashboard"
          />

          {/* Start Staff Modules */}

          <Route
            render={(props) => (
              <AddHomeWorkStaff {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddHomeWorkStaff"
          />
          <Route
            render={(props) => (
              <AddAssignmentStaff {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddAssignmentStaff"
          />
          <Route
            render={(props) => (
              <AddLessonPlanStaff {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddLessonPlanStaff"
          />
          <Route
            render={(props) => (
              <AddExamInfoStaff {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddExamInfoStaff"
          />
          <Route
            render={(props) => (
              <AddExamDetailsStaff {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddExamDetailsStaff"
          />

          <Route
            render={(props) => (
              <AddSyllabusStaff {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddSyllabusStaff"
          />
          <Route
            render={(props) => (
              <AddQuizInfoStaff {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddQuizInfoStaff"
          />

          <Route
            render={(props) => (
              <AddQuizStaff {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddQuizStaff"
          />

          <Route
            render={(props) => (
              <AddTestInfoStaff {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddTestInfoStaff"
          />

          <Route
            render={(props) => (
              <AddTestStaff {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddTestStaff"
          />
          <Route
            render={(props) => (
              <AddPastPapersStaff {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddPastPapersStaff"
          />

          <Route
            render={(props) => (
              <ViewHomeWorkStaff {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewHomeWorkStaff"
          />

          <Route
            render={(props) => (
              <PrintHomeWorkStaff {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/PrintHomeWorkStaff"
          />

          <Route
            render={(props) => (
              <ViewSyllabusStaff {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewSyllabusStaff"
          />

          <Route
            render={(props) => (
              <PrintSyllabusStaff {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/PrintSyllabusStaff"
          />

          <Route
            render={(props) => (
              <ViewSyllabusDept {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewSyllabusDept"
          />

          <Route
            render={(props) => (
              <PrintSyllabusDept {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/PrintSyllabusDept"
          />

          <Route
            render={(props) => (
              <ViewAssignmentStaff {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewAssignmentStaff"
          />

          <Route
            render={(props) => (
              <PrintAssignmentStaff {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/PrintAssignmentStaff"
          />

          <Route
            render={(props) => (
              <ViewLessonPlanStaff {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewLessonPlanStaff"
          />

          <Route
            render={(props) => (
              <ViewLessonPlanDept {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewLessonPlanDept"
          />

          <Route
            render={(props) => (
              <PrintLessonPlanDept {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/PrintLessonPlanDept"
          />

          <Route
            render={(props) => (
              <PrintLessonPlanStaff {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/PrintLessonPlanStaff"
          />

          <Route
            render={(props) => (
              <ViewPastPapersStaff {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewPastPapersStaff"
          />

          <Route
            render={(props) => (
              <ViewPastPaperDept {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewPastPaperDept"
          />

          <Route
            render={(props) => (
              <PrintPastPaperDept {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/PrintPastPaperDept"
          />

          <Route
            render={(props) => (
              <PrintPastPaperStaff {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/PrintPastPaperStaff"
          />

          <Route
            render={(props) => (
              <ViewExamInfoStaff {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewExamInfoStaff"
          />

          <Route
            render={(props) => (
              <ViewExamInfoDept {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewExamInfoDept"
          />

          <Route
            render={(props) => (
              <PrintExamInfoStaff {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/PrintExamInfoStaff"
          />

          <Route
            render={(props) => (
              <PrintExamInfoDept {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/PrintExamInfoDept"
          />

          <Route
            render={(props) => (
              <ViewExamDetailsStaff {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewExamDetailsStaff"
          />

          <Route
            render={(props) => (
              <ViewExamDetailsDept {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewExamDetailsDept"
          />

          <Route
            render={(props) => (
              <PrintExamDetailsStaff {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/PrintExamDetailsStaff"
          />

          <Route
            render={(props) => (
              <PrintExamDetailsDept {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/PrintExamDetailsDept"
          />

          <Route
            render={(props) => (
              <ViewQuizInfoStaff {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewQuizInfoStaff"
          />

          <Route
            render={(props) => (
              <ViewQuizInfoDept {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewQuizInfoDept"
          />

          <Route
            render={(props) => (
              <PrintQuizInfoDept {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/PrintQuizInfoDept"
          />

          <Route
            render={(props) => (
              <PrintQuizInfoStaff {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/PrintQuizInfoStaff"
          />

          <Route
            render={(props) => (
              <ViewQuizStaff {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewQuizStaff"
          />

          <Route
            render={(props) => (
              <ViewQuizDept {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewQuizDept"
          />

          <Route
            render={(props) => (
              <PrintQuizStaff {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/PrintQuizStaff"
          />

          <Route
            render={(props) => (
              <PrintQuizDept {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/PrintQuizDept"
          />

          <Route
            render={(props) => (
              <ViewTestInfoStaff {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewTestInfoStaff"
          />

          <Route
            render={(props) => (
              <PrintTestInfoStaff {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/PrintTestInfoStaff"
          />

          <Route
            render={(props) => (
              <ViewTestInfoDept {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewTestInfoDept"
          />

          <Route
            render={(props) => (
              <PrintTestInfoDept {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/PrintTestInfoDept"
          />

          <Route
            render={(props) => (
              <ViewTestStaff {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewTestStaff"
          />

          <Route
            render={(props) => (
              <PrintTestStaff {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/PrintTestStaff"
          />

          <Route
            render={(props) => (
              <ViewTestDept {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewTestDept"
          />

          <Route
            render={(props) => (
              <PrintTestDept {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/PrintTestDept"
          />

          <Route
            render={(props) => (
              <StaffModuleLMSDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/StaffModuleLMSDashboard"
          />

          <Route
            render={(props) => (
              <StaffModuleLMSHomeworkDashboard
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/StaffModuleLMSHomeworkDashboard"
          />

          <Route
            render={(props) => (
              <StaffModuleLMSExamInfoDashboard
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/StaffModuleLMSExamInfoDashboard"
          />

          <Route
            render={(props) => (
              <StaffModuleLMSQuizInfoDashboard
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/StaffModuleLMSQuizInfoDashboard"
          />

          <Route
            render={(props) => (
              <StaffModuleLMSTestDashboard
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/StaffModuleLMSTestDashboard"
          />

          {/* Start Academic Calender */}

          <Route
            render={(props) => (
              <InitiateAcademicCalendar {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/InitiateAcademicCalendar"
          />

          <Route
            render={(props) => (
              <AddAcademicCalendar {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddAcademicCalendar"
          />

          <Route
            render={(props) => (
              <ViewAcademicCalendar {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewAcademicCalendar"
          />

          <Route
            render={(props) => (
              <AcademicCalendarMain {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AcademicCalendarMain"
          />

          <Route
            render={(props) => (
              <PrintAcademicCalendar {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/PrintAcademicCalendar"
          />

          {/* End Academic Calender */}

          <Route
            render={(props) => (
              <StaffModuleLMSAssignmentDashboard
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/StaffModuleLMSAssignmentDashboard"
          />

          <Route
            render={(props) => (
              <StaffModuleLMSLessonPlanDashboard
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/StaffModuleLMSLessonPlanDashboard"
          />

          <Route
            render={(props) => (
              <StaffModuleLMSSyllabusDashboard
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/StaffModuleLMSSyllabusDashboard"
          />

          <Route
            render={(props) => (
              <StaffModuleLMSPastPaperDashboard
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/StaffModuleLMSPastPaperDashboard"
          />

          {/* End Staff Modules */}
          {/* Start Student LMS Modules */}

          <Route
            render={(props) => (
              <ViewHomeWorkStudent {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewHomeWorkStudent"
          />

          <Route
            render={(props) => (
              <ViewAssignmentStudent {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewAssignmentStudent"
          />

          <Route
            render={(props) => (
              <ViewSyllabusStudentInit {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewSyllabusStudentInit"
          />

          <Route
            render={(props) => (
              <ViewPastPaperStudentInit {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewPastPaperStudentInit"
          />

          <Route
            render={(props) => (
              <ViewPastPaperStudent {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewPastPaperStudent"
          />

          <Route
            render={(props) => (
              <ViewExamInfoStudent {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewExamInfoStudent"
          />

          <Route
            render={(props) => (
              <ViewQuizInfoStudent {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewQuizInfoStudent"
          />

          <Route
            render={(props) => (
              <ViewTestInfoStudent {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewTestInfoStudent"
          />

          <Route
            render={(props) => (
              <StudentModuleLMSDashboard
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/StudentModuleLMSDashboard"
          />

          <Route
            render={(props) => (
              <StudentModuleLMSHomeworkDashboard
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/StudentModuleLMSHomeworkDashboard"
          />
          {/* End Student LMS Modules */}

          {/*Dept LMS Modules */}

          <Route
            render={(props) => (
              <DeptModuleLMSDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/DeptModuleLMSDashboard"
          />

          {/* End Dept LMS Modules */}

          <Route
            render={(props) => (
              <StudentModuleLMSHomeworkDashboard
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/StudentModuleLMSHomeworkDashboard"
          />
          {/* End Student LMS Modules */}

          {/*Dept LMS Modules */}

          <Route
            render={(props) => (
              <DeptModuleLMSDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/DeptModuleLMSDashboard"
          />

          {/* End Dept LMS Modules */}

          {/* SID Modules */}
          <Route
            render={(props) => (
              <StaffModuleSIDDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/StaffModuleSIDDashboard"
          />
          {/* End SID Modules */}

          {/* Academic Head Start */}
          <Route
            render={(props) => (
              <AcademicDeanDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AcademicDeanDashboard"
          />
          <Route
            render={(props) => (
              <ArabicHODDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ArabicHODDashboard/:id"
          />
          <Route
            render={(props) => (
              <EnglishDeanDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/EnglishDeanDashboard/:id"
          />
          <Route
            render={(props) => (
              <EnglishHODDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/EnglishHODDashboard/:id"
          />
          <Route
            render={(props) => (
              <ArabicDeanDashoard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ArabicDeanDashboard/"
          />
          <Route
            render={(props) => (
              <EnglishDeanDashoard2 {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/EnglishDeanDashboard2/"
          />
          <Route
            render={(props) => (
              <CMSDeanDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/CMSDeanDashboard/"
          />
          <Route
            render={(props) => (
              <IndentDeanDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/IndentDeanDashboard/"
          />
          <Route
            render={(props) => (
              <DeanLeaveDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/DeanLeaveDashboard/"
          />
          <Route
            render={(props) => (
              <AddDeanLeave {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddDeanLeave/"
          />
          <Route
            render={(props) => (
              <ViewDeanLeave {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewDeanLeave/"
          />
          <Route
            render={(props) => (
              <AddDeanShortLeave {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddDeanShortLeave/"
          />
          <Route
            render={(props) => (
              <ViewDeanShortLeave {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewDeanShortLeave/"
          />


          <Route
            render={(props) => (
              <AcademicFormViews {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AcademicFormViews/:id"
          />
          {/*End Academic Head Start */}

          {/* HOD Forms STart */}

          <Route
            render={(props) => (
              <HODDescriptiveView {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/HODDescriptiveView/:name"
          />
          <Route
            render={(props) => (
              <HODSkillsView {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/HODSkillsView/:name"
          />
          <Route
            render={(props) => (
              <HODClassDisciplineView {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/HODClassDisciplineView/:name"
          />
         
          {/* Start Staff Leave */}

          <Route
            render={(props) => (
              <AddStaffLeave {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddStaffLeave"
          />

          <Route
            render={(props) => (
              <ViewStaffLeave {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewStaffLeave"
          />

          <Route
            render={(props) => (
              <ViewStaffLeaveForHR {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewStaffLeaveForHR"
          />

          <Route
            render={(props) => (
              <PrintStaffLeaveForHR {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/PrintStaffLeaveForHR"
          />
          <Route
            render={(props) => (
              <ViewStaffLeaveDept {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewStaffLeaveDept"
          />
          <Route
            render={(props) => (
              <StaffLeaveDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/StaffLeaveDashboard"
          />
          <Route
            render={(props) => (
              <AddStaffShortLeave {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddStaffShortLeave"
          />

          <Route
            render={(props) => (
              <ViewStaffShortLeave {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewStaffShortLeave"
          />

          <Route
            render={(props) => (
              <AdminLeaveDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AdminLeaveDashboard"
          />
          <Route
            render={(props) => (
              <AddAdminLeave {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddAdminLeave"
          />
          <Route
            render={(props) => (
              <ViewAdminLeave {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewAdminLeave"
          />
          <Route
            render={(props) => (
              <AddAdminShortLeave {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddAdminShortLeave"
          />
          <Route
            render={(props) => (
              <ViewAdminShortLeave {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewAdminShortLeave"
          />
          <Route
            render={(props) => (
              <ViewStaffShortLeaveForHR {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewStaffShortLeaveForHR"
          />
          <Route
            render={(props) => (
              <ViewStaffShortLeaveDept {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewStaffShortLeaveDept"
          />
          <Route
            render={(props) => (
              <PrintStaffShortLeaveForHR
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/PrintStaffShortLeaveForHR"
          />
          <Route
            render={(props) => (
              <StaffLeaveHRDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/StaffLeaveHRDashboard"
          />

          <Route
            render={(props) => (
              <StaffLeaveDeptDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/StaffLeaveDeptDashboard"
          />
          <Route
            render={(props) => (
              <StaffModuleCMSDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/StaffModuleCMSDashboard"
          />
          {/* Bio Metric */}

          <Route
            render={(props) => (
              <BioMetricAttendance {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/BioMetricAttendance"
          />

          <Route
            render={(props) => (
              <BioMetricRegistered {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/BioMetricRegistered"
          />

          <Route
            render={(props) => (
              <PrintBioMetricRegistered {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/PrintBioMetricRegistered"
          />

          <Route
            render={(props) => (
              <PrintBioMetricAttendance {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/PrintBioMetricAttendance"
          />

          {/* Bio Metric */}
          {/* Time Table Module */}

          <Route
            render={(props) => (
              <StaffTimeTable {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/StaffTimeTable"
          />

          <Route
            render={(props) => (
              <DepartmentWiseTimeTable {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/DepartmentWiseTimeTable"
          />

          <Route
            render={(props) => (
              <StaffTimeTableByCoordinator
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/StaffTimeTableByCoordinator"
          />

          <Route
            render={(props) => (
              <ConsolidatedTimetable {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ConsolidatedTimetable"
          />

          <Route
            render={(props) => (
              <StudentTimeTable {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/StudentTimeTable"
          />

          {/* Time Table Module */}

          {/* Start Class Section Session Subject Class Teacher */}

          <Route
            render={(props) => (
              <AddClassRenew {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddClass"
          />

          <Route
            render={(props) => (
              <ViewClassRenew {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewClass"
          />

          <Route
            render={(props) => (
              <AddSectionRenew {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddSection"
          />

          <Route
            render={(props) => (
              <ViewSectionRenew {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewSection"
          />

          <Route
            render={(props) => (
              <AddSubjectRenew {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddSubject"
          />

          <Route
            render={(props) => (
              <ViewSubjectRenew {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewSubject"
          />

          <Route
            render={(props) => (
              <AddSessionRenew {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddSession"
          />

          <Route
            render={(props) => (
              <ViewSessionRenew {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewSession"
          />
          {/* End of Class Section Session Subject */}

          {/* Bio Metric */}
          {/* Owner LMS Reports*/}

          <Route
            render={(props) => (
              <PrintHomeWork {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/PrintHomeWork"
          />

          <Route
            render={(props) => (
              <PrintAssignment {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/PrintAssignment"
          />

          <Route
            render={(props) => (
              <PrintExamInfo {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/PrintExamInfo"
          />

          <Route
            render={(props) => (
              <PrintExam {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/PrintExam"
          />

          <Route
            render={(props) => (
              <PrintQuizInfo {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/PrintQuizInfo"
          />

          <Route
            render={(props) => (
              <PrintQuiz {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/PrintQuiz"
          />

          <Route
            render={(props) => (
              <PrintPastPapers {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/PrintPastPapers"
          />

          <Route
            render={(props) => (
              <PrintTestInfo {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/PrintTestInfo"
          />

          <Route
            render={(props) => (
              <PrintTest {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/PrintTest"
          />
          {/* Owner LMS Reports End*/}

          {/* Teacher Parent Message Start*/}

          <Route
            render={(props) => (
              <TeacherMessageParent {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/TeacherMessageParent"
          />

          <Route
            render={(props) => (
              <ParentMessageTeacher {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ParentMessageTeacher"
          />
          <Route
            render={(props) => (
              <ParentTeacherInbox {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ParentTeacherInbox"
          />
          <Route
            render={(props) => (
              <IndentDeanAdd {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/IndentDeanAdd"
          />
          <Route
            render={(props) => (
              <IndentDeanView {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/IndentDeanView"
          />

          {/* Teacher Parent Message End*/}

          <Route
            render={(props) => (
              <AddLoginTimeLimit {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddLoginTimeLimit"
          />

          <Route
            render={(props) => (
              <ViewLoginTimeLimit {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewLoginTimeLimit"
          />
          <Route
            render={(props) => (
              <StudentDisciplineDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/StudentDisciplineDashboard"
          />
          <Route
            render={(props) => (
              <AdminCMSDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/CMSAdminDashboard"
          />
          <Route
            render={(props) => (
              <StudentAdmissionAdminDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/StudentAdmissionAdminDashboard"
          />
          <Route
            render={(props) => (
              <IndentManagerDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/IndentManagerDashboard"
          />
          <Route
            render={(props) => (
              <Billing_Dashboard_Admin {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/BillingDashboardAdmin"
          />
          <Route
            render={(props) => (
              <IndentManagerAdd {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/IndentManagerAdd"
          />
          <Route
            render={(props) => (
              <IndentManagerView {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/IndentManagerView"
          />
          <Route
            render={(props) => (
              <ManagerLeaveDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ManagerLeaveDashboard"
          />
          <Route
            render={(props) => (
              <AddManagerLeave {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddManagerLeave"
          />
          <Route
            render={(props) => (
              <ViewManagerLeave {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewManagerLeave"
          />
          <Route
            render={(props) => (
              <AddManagerShortLeave {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddManagerShortLeave"
          />
          <Route
            render={(props) => (
              <ViewManagerShortLeave {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewManagerShortLeave"
          />
          <Route
            render={(props) => (
              <CommunicationDeanDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/CommunicationDeanDashboard"
          />
          <Route
            render={(props) => (
              <AcademicsPlanDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AcademicsPlanDashboard"
          />
          <Route
            render={(props) => (
              <FamilyTreeDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/FamilyTreeDashboard"
          />
          <Route
            render={(props) => (
              <AcademicFeeType {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AcademicFeeType"
          />
          <Route
            render={(props) => (
              <OtherFeeType {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/OtherFeeType"
          />
          <Route
            render={(props) => (
              <NewFeeTypeDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/FeeTypeDashboard"
          />
          <Route
            render={(props) => (
              <InvoiceBooking {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/InvoiceBooking"
          />
          <Route
            render={(props) => (
              <AddOnlineResource {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AddOnlineResource"
          />
          <Route
            render={(props) => (
              <ViewOnlineResource {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ViewOnlineResource"
          />
          <Route
            render={(props) => (
              <OnlineResourceDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/OnlineResourceDashboard"
          />

          <Route
            render={(props) => (
              <OnlineResourceStaffDashboard {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/OnlineResourceStaffDashboard"
          />

          <Route
            render={(props) => (
              <AdvanceRevenue {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/AdvanceRevenue"
          />
          <Route
            render={(props) => (
              <ReceiptBooking {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/ReceiptBooking"
          />
          <Route
            render={(props) => (
              <FeeVouchers {...props} snackbar={this.myFunction} />
            )}
            path="/dashboard/FeeVouchers"
          />
          <Route
            render={(props) => (
              <VoucherDetails
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/VoucherDetails/:paymentType/:class/:student/:session"
          />

          <Route
            render={(props) => (
              <SpecialEducation
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/SpecialEducation"
          />
          <Route
            render={(props) => (
              <AddSpecialEducation
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/AddSpecialEducation"
          />
          <Route
            render={(props) => (
              <ViewSpecialEducation
                {...props}
                snackbar={this.myFunction}
              />
            )}
            path="/dashboard/ViewSpecialEducation"
          />
           <Route
            render={(props) => (
              <AllSheetsView
                {...props}
                snackbar={this.myFunction}
                printFunc={this.PrintContent}
              />
            )}
            path="/dashboard/SpecialEducationNeedsReport"
          />
           <Route
            render={(props) => (
              <ViewSpecialEducationRecords
                {...props}
                snackbar={this.myFunction}
                printFunc={this.PrintContent}
              />
            )}
            path="/dashboard/ViewSpecialEducationRecords"
          />

          {/* Admin Routes Special Education Needs */}

           <Route
            render={(props) => (
              <SpecialEducationAdmin
                {...props}
                snackbar={this.myFunction}
                printFunc={this.PrintContent}
              />
            )}
            path="/dashboard/SpecialEducationAdmin"
          />
           <Route
            render={(props) => (
              <ViewSpecialEducationRecordAdmin
                {...props}
                snackbar={this.myFunction}
                printFunc={this.PrintContent}
              />
            )}
            path="/dashboard/ViewSpecialEducationRecordAdmin"
          />
           <Route
            render={(props) => (
              <SpecialEducationNeedDashboard
                {...props}
                snackbar={this.myFunction}
                printFunc={this.PrintContent}
              />
            )}
            path="/dashboard/SpecialEducationNeedDashboard"
          />
           <Route
            render={(props) => (
              <SpecialEducationNeedDashboardAdmin
                {...props}
                snackbar={this.myFunction}
                printFunc={this.PrintContent}
              />
            )}
            path="/dashboard/SpecialEducationNeedDashboardAdmin"
          />
           <Route
            render={(props) => (
              <FeeAccounts
                {...props}
                snackbar={this.myFunction}
                printFunc={this.PrintContent}
              />
            )}
            path="/dashboard/FeeAccounts"
          />
        </Switch>
      </Layout>
    );
  }
}

export default Routes;
