import React, { Component } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";

class ShowTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      check: false,
    };
  }
  btnTechHover = () => {
    $(document).ready(function () {
      $("button").hover(
        function () {
          $(this).css("box-shadow", "0px 5px 5px grey");
          $(this).css("border-color", "white");
        },
        function () {
          $(this).css("box-shadow", "0px 0px 0px white");
        }
      );
    });
  };
  componentDidUpdate() {
    $('[data-toggle="tooltip"]').tooltip();
  }
  componentDidMount() {
    $(document).ready(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });
  }
  render() {
    const Show = this.props.data && this.props.data.map((item, index) => (
      <tr key={index}>
        <th scope="row" style={{ padding: 2 }}>
          {index + 1}
        </th>
        <td style={{ padding: 2 }}>{item.currentSession}</td>
        <td style={{ padding: 2 }}>{item.studentAdmissionFormId}</td>
        <td style={{ padding: 2 }}>{item.firstName}</td>
        <td style={{ padding: 2 }}>{item.fatherName}</td>
        <td style={{ padding: 2 }}>{item.parentMobileNo}</td>
        <td style={{ padding: 2 }}>{item.age}</td>
        <td style={{ padding: 2 }}>{item.className}</td>
        <td style={{ padding: 2 }}>{item.section}</td>
        <td style={{ padding: 2 }} className="no-print">
          <Link
            to={`/dashboard/PrintAdmissionFormFinal/${item.studentAdmissionFormId}`}
          >
            <button
              onMouseOver={this.btnTechHover()}
              style={{
                cursor: "pointer",
                fontSize: 20,
                border: "none",
                background: "none",
                marginLeft: 5,
                marginBottom: 5,
              }}
            >
              <a id="tooltipId" data-toggle="tooltip" title="View Form">
                <i
                  style={{ color: "#34495e" }}
                  class="fas fa-window-maximize"
                ></i>
              </a>
            </button>
          </Link>
        </td>
      </tr>
    ));
    return <React.Fragment>{Show}</React.Fragment>;
  }
}

export default ShowTable;
