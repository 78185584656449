import React, { useContext } from "react";
import ThemeContext from "../../../context/themeContext/ThemeContext";
import useHttp from "../../../hooks/http";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import Spinner from "../../../components/Spinner/Spinner";

function CaReportList(props) {
  const themeContext = useContext(ThemeContext);
  const [isLoading, getData] = useHttp(
    `/api/CA_PrimaryList_StudentId/?studentUsername=${props.name}`,
    []
  );
  debugger;
  debugger;
  return (
    <div>
      <h2
        style={{
          width: "fit-content",
          background: themeContext.themeColors.primaryColor,
        }}
        className="generalHead"
      >
        Student Continouse Report List
      </h2>
      <br />
      {getData && getData.length > 0 ? (
        <table class="table">
          <thead>
            <tr
              style={{
                background: themeContext.themeColors.primaryColor,
                color: "#fff",
              }}
            >
              <th scope="col">#</th>
              <th scope="col">Name</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {getData &&
              getData.map((item, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>{item.name}</td>
                  <td>
                    <a href="#" data-toggle="tooltip" title="Edit!">
                      <Link
                        to={`/dashboard/CaSingleStudentTermReport/${item.classId}/${item.sectionId}/${item.sessionId}/${item.term}/${item.studentId}`}
                      >
                        <button
                          id="firstbutton"
                          data-toggle="modal"
                          data-target="#myModal"
                          style={{
                            cursor: "pointer",
                            fontSize: 20,
                            border: "none",
                            marginLeft: 5,
                            background: "none",
                            marginBottom: 5,
                          }}
                        >
                          <i class="fas fa-list"></i>
                        </button>
                      </Link>
                    </a>
                    {/* <a href="#" data-toggle="tooltip" title="Edit!">
                      <Link to={`/dashboard/CaViewExamFeature/${item.pId}`}>
                        <button
                          id="firstbutton"
                          data-toggle="modal"
                          data-target="#myModal"
                          style={{
                            cursor: "pointer",
                            fontSize: 20,
                            border: "none",
                            marginLeft: 5,
                            background: "none",
                            marginBottom: 5,
                          }}
                        >
                          <i class="fas fa-list-alt"></i>
                        </button>
                      </Link>
                    </a>
                    <a href="#" data-toggle="tooltip" title="Edit!">
                      <Link to={`/dashboard/CaAverageSheet/${item.pId}`}>
                        <button
                          id="firstbutton"
                          data-toggle="modal"
                          data-target="#myModal"
                          style={{
                            cursor: "pointer",
                            fontSize: 20,
                            border: "none",
                            marginLeft: 5,
                            background: "none",
                            marginBottom: 5,
                          }}
                        >
                          <i class="fas fa-clipboard-list"></i>
                        </button>
                      </Link>
                    </a> */}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      ) : (
        <Spinner />
      )}
    </div>
  );
}
function mapStateToProps(state) {
  return {
    role: state.setUserReducer.users.role,
    name: state.setUserReducer.users.unique_name,
  };
}

export default connect(mapStateToProps, null)(CaReportList);
