import * as actionTypes from '../actionTypes';

export function setAllVehicleInRedux(allData) {
	return {
		type: actionTypes.GET_ALL_VEHICLE,
		payload: allData
	};
}
export function getRouteForVehicle(allData) {
	return {
		type: actionTypes.SET_ALL_ROUTE_NAME_INTO_VEHICLE_SELECT,
		payload: allData
	};
}

export function getVehicleForDriver(allData) {
	return {
		type: actionTypes.SET_ALL_VEHICLE_NAME_INTO_DRIVER_SELECT,
		payload: allData
	};
}
export function addVehicle(Data) {
	return {
		type: actionTypes.ADD_VEHICLE,
		payload: Data
	};
}
export function updateVehicleFlag(setFlag) {
	return {
		type: actionTypes.SET_LOADER_FLAG_VEHICLE,
		payload: setFlag
	};
}
export function updateVehicle(data) {
	return {
		type: actionTypes.EDIT_VEHICLE_RECORD,
		payload: data
	};
}
export function deleteVehicle(id) {
	return {
		type: actionTypes.DELETE_VEHICLE,
		payload: id
	};
}
