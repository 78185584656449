import React, { Component } from 'react';

const RenderDynamicRow = props => {
	const {
		itemObj,
		onHandleTextChange,
		stateData,
		rowIndex,
		updateSpecialArrObjOnChange,
		arrName
	} = props;

	let keys = Object.keys(itemObj);
	// console.log(stateData.siblingsDetails[0]['age'])
	if (keys.includes('StudentSiblingDetailId') && keys.includes('studentId')) {
		let newKeys = keys.filter(
			item => item !== 'studentId' && item !== 'StudentSiblingDetailId'
		);
		keys = [...newKeys];
	}
	if (keys.includes('PreviousSchoolDetailId') && keys.includes('studentId')) {
		let newKeys = keys.filter(
			item => item !== 'studentId' && item !== 'PreviousSchoolDetailId'
		);
		keys = [...newKeys];
	}

	return (
		<React.Fragment>
			{keys.map((item, index) => (
				<td key={index}>
					<input
						type="text"
						placeholder={`${item}`}
						class="form-control"
						name={`${item}`}
						value={stateData[arrName][rowIndex][`${item}`]}
						onChange={e => updateSpecialArrObjOnChange(e, rowIndex, arrName)}
					/>
				</td>
			))}
		</React.Fragment>
	);
};
export default RenderDynamicRow;
