import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import $ from 'jquery';
import Spinner from '../../components/Spinner/Spinner';

const FormData = [
	{
		id: 1,
		question:
			'Ability to understand, process and implement information introduced'
	},
	{
		id: 2,
		question: 'Ideas generation and developing generic research activities'
	},
	{
		id: 3,
		question: 'Creativity and critical thinking'
	},
	{
		id: 4,
		question:
			'Proficient at learning in arange of ways, including through information technologies'
	},
	{
		id: 5,
		question: 'Tasks managment and process documentation'
	},
	{
		id: 6,
		question:
			'Knowledge of practical and technical consendirations related to tasks/projects'
	},
	{
		id: 7,
		question: 'Attendance'
	},
	{
		id: 8,
		question: 'Classroom interactivity'
	},
	{
		id: 9,
		question: 'Ability of working and productivity under stress'
	},
	{
		id: 10,
		question: 'Performance and ability of working within teamwork'
	},
	{
		id: 11,
		question: 'Artwork otcomes and presentations quality'
	},
	{
		id: 12,
		question: 'Communication and language skills'
	},
	{
		id: 13,
		question: 'Meeting with dead lines'
	},
	{
		id: 14,
		question: 'Interest in the course'
	}
];

class PrintStudentProgressReportStudentProfileRenew extends Component {
	constructor(props) {
		super(props);
		this.state = {
			report: {},
			mainData: [],
			searchCheck: true
			/* studentId: this.props.match.params.studentId,
            classId: this.props.match.params.classId,
            sectionId: this.props.match.params.sectionId,
            sessionId: this.props.match.params.sessionId, */
		};
	}

	componentDidMount() {
		this.props.sharedActions
			.getDataWithoutDispatch(
				`/api/StudentProgressTrackReport/?studentId=${this.props.match.params.studentId}&classId=${this.props.match.params.classId}&sectionId=${this.props.match.params.sectionId}&subjectId=${this.props.match.params.subjectId}&sessionId=${this.props.match.params.sessionId}&term=${this.props.match.params.term}`
			)
			.then(success => {
				this.setState({
					mainData: success,
					report: success[0],
					searchCheck: false
				});
			})
			.catch(err => {
				this.setState({
					report: {},
					mainData: [],
					searchCheck: false
				});
			});
	}

	myFunction = () => {
		// Get the snackbar DIV
		var x = document.getElementById('snackbar');

		// StepperGeneralButtons the "show" class to DIV
		x.className = 'show';

		// After 3 seconds, remove the show class from DIV
		setTimeout(function() {}, 3000);
	};

	onHandleText = e => {
		this.setState({
			[e.target.name]: e.target.value
		});
	};

	PrintContent = el => {
		var restorepage = document.body.innerHTML;
		var Printcontent = document.getElementById(el).innerHTML;
		document.body.innerHTML = Printcontent;

		setTimeout(() => {
			window.print();
			window.location.reload();
		}, 700);
		document.body.innerHTML = restorepage;
	};

	render() {
		console.log('Props called');

		console.log('Props', this.props);
		const { report } = this.state;

		const renderFormData = FormData.map((data, index) => {
			return (
				<div>
					<div className="row">
						<div className="col-md-12">
							<div className="md-form mb-0">
								<p
									class="text-justify"
									style={{
										color: 'black',
										fontWeight: 'bold',
										marginBottom: '3px'
									}}
								>
									<strong>{data.id}). </strong>
									{data.question}
								</p>
							</div>
							<div class="custom-control custom-radio custom-control-inline">
								<input
									disabled
									checked={report[`comment${data.id}`] == 'Excellent'}
									type="radio"
									id={`Excellent${data.id}`}
									onChange={this.onHandleTextChange}
									value="Excellent"
									name={`comment${data.id}`}
									class="custom-control-input"
								/>
								<label class="custom-control-label" for={`Excellent${data.id}`}>
									Excellent
								</label>
							</div>
							<div class="custom-control custom-radio custom-control-inline">
								<input
									disabled
									checked={report[`comment${data.id}`] == 'Very Good'}
									type="radio"
									id={`VeryGood${data.id}`}
									onChange={this.onHandleTextChange}
									value="Very Good"
									name={`comment${data.id}`}
									class="custom-control-input"
								/>
								<label class="custom-control-label" for={`VeryGood${data.id}`}>
									Very Good
								</label>
							</div>
							<div class="custom-control custom-radio custom-control-inline">
								<input
									disabled
									checked={report[`comment${data.id}`] == 'Good'}
									type="radio"
									id={`Good${data.id}`}
									onChange={this.onHandleTextChange}
									value="Good"
									name={`comment${data.id}`}
									class="custom-control-input"
								/>
								<label class="custom-control-label" for={`Good${data.id}`}>
									Good
								</label>
							</div>
							<div class="custom-control custom-radio custom-control-inline">
								<input
									disabled
									checked={report[`comment${data.id}`] == 'Average'}
									type="radio"
									id={`Average${data.id}`}
									onChange={this.onHandleTextChange}
									value="Average"
									name={`comment${data.id}`}
									class="custom-control-input"
								/>
								<label class="custom-control-label" for={`Average${data.id}`}>
									Average
								</label>
							</div>
							<div class="custom-control custom-radio custom-control-inline">
								<input
									disabled
									checked={report[`comment${data.id}`] == 'Need More Work'}
									type="radio"
									id={`NeedMoreWork${data.id}`}
									onChange={this.onHandleTextChange}
									value="Need More Work"
									name={`comment${data.id}`}
									class="custom-control-input"
								/>
								<label
									class="custom-control-label"
									for={`NeedMoreWork${data.id}`}
								>
									Need More Work
								</label>
							</div>
							<div class="custom-control custom-radio custom-control-inline">
								<input
									disabled
									checked={report[`comment${data.id}`] == 'Not Applicable'}
									type="radio"
									id={`NotApplicable${data.id}`}
									onChange={this.onHandleTextChange}
									value="Not Applicable"
									name={`comment${data.id}`}
									class="custom-control-input"
								/>
								<label
									class="custom-control-label"
									for={`NotApplicable${data.id}`}
								>
									Not Applicable
								</label>
							</div>
						</div>
					</div>
					<br />
				</div>
			);
		});

		const MainContent = (
			<div>
				<div id="slip">
					<div
						class="container"
						style={{ border: '2px solid teal', borderRadius: '5px' }}
					>
						<div class="row" style={{ borderBottom: `2px solid teal` }}>
							<div class="col-md-8">
								<h4 style={{ color: 'teal', fontWeight: 'bolder' }}>
									Student Progress Report
								</h4>
							</div>
						</div>
						<div class="row" style={{}}>
							<div class="col-md-8" style={{ border: `2px solid teal` }}>
								<p style={{ color: 'black', margin: '0px' }}>
									Subject: <strong>{report.subjectName}</strong>
								</p>
							</div>
							<div class="col-md-4" style={{ border: `2px solid teal` }}>
								<p style={{ color: 'black', margin: '0px' }}>
									Teacher:<strong> {report.staffName}</strong>
								</p>
							</div>
						</div>
						<div class="row" style={{ borderBottom: `2px solid teal` }}>
							<div class="col-md-6" style={{ border: `2px solid teal` }}>
								<p style={{ color: 'black', margin: '0px' }}>
									Student Name:<strong> {report.studentName}</strong>
								</p>
							</div>
							<div class="col-md-3" style={{ border: `2px solid teal` }}>
								<p style={{ color: 'black', margin: '0px' }}>
									Class:<strong> {report.className}</strong>
								</p>
							</div>
							<div class="col-md-3" style={{ border: `2px solid teal` }}>
								<p style={{ color: 'black', margin: '0px' }}>
									Section:<strong> {report.section}</strong>
								</p>
							</div>
						</div>

						<div
							style={{
								marginTop: '2%'
							}}
						>
							{renderFormData}
						</div>
						<div class="row" style={{ borderBottom: `1px solid teal` }}>
							<div class="col-xs-4 col-sm-4 col-md-4" style={{ fontSize: 12 }}>
								<br />
								<br />
								<br />
								<p style={{ color: 'black' }}>
									Teacher: _______________________
								</p>
							</div>
							<div class="col-xs-4 col-sm-4 col-md-4" style={{ fontSize: 12 }}>
								<br />
								<br />
								<br />
								<p style={{ color: 'black' }}>
									Student: _______________________
								</p>
							</div>
							<div
								class="col-xs-4 col-sm-4 col-md-4 text-right"
								style={{ fontSize: 12 }}
							>
								<br />
								<br />
								<br />
								<p style={{ color: 'black', margin: '0px' }}>
									Date: <strong>{report.date}</strong>
								</p>
							</div>
						</div>
					</div>
				</div>
				<br />
				<div class="row" style={{ marginBottom: '2%', marginRight: '1%' }}>
					<div class="col-md-10"></div>
					<div class="col-md-2">
						<button
							class="btn btn-primary buttonAppear"
							onClick={() => this.PrintContent('slip')}
						>
							<i class="fas fa-print"></i> Print
						</button>
					</div>
				</div>
				<br />
			</div>
		);

		return (
			<div>
				{this.state.searchCheck ? (
					<Spinner />
				) : this.state.mainData.length < 1 ? (
					<div
						style={{ display: 'flex', justifyContent: 'center', color: 'red' }}
					>
						<strong>Not Found</strong>
					</div>
				) : (
					MainContent
				)}
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch)
	};
}
function mapStateToProps(state) {
	return {
		userData: state.setUserReducer.users
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PrintStudentProgressReportStudentProfileRenew);
