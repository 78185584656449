import React, { Component } from 'react';
import DateandTime from '../Transport/DateandTime';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as FinanceAction from '../../Actions/Finance';
import * as sharedActions from '../../Actions/sharedActions';
import thunk from 'redux-thunk';

class AddAccountLedger extends Component {
	constructor(props) {
		super(props);
		console.log(props);

		this.state = {
			//API States
			account: '',
			description: '',
			depositAmount: '',
			withdrawAmount: '',
			cashDepositeFrom: '',
			cashWithdrawBy: '',
			accountId: '',
			accountType: '',

			//UI States
			depositCheck: false,
			withdrawCheck: false,
			checked: false,
			errorMsg: false,
			msg: false
		};
	}
	//UI Functions
	depositAmountChange = e => {
		e.preventDefault();
		this.setState({
			depositCheck: !this.state.depositCheck,
			withdrawCheck: false
		});
	};
	withdrawAmountChange = e => {
		e.preventDefault();
		this.setState({
			withdrawCheck: !this.state.withdrawCheck,
			depositCheck: false
		});
	};

	onHandleTextChange = e => {
		console.log(e.target.value);
		this.setState({
			[e.target.name]: e.target.value
		});
	};
	onHandleSubmit = e => {
		e.preventDefault();
		const { account, description, depositAmount, withdrawAmount } = this.state;
		if (Object.is(account, '') || Object.is(description, '')) {
			this.setState({
				valid: false,
				errorMsg: true
			});
		} else {
			this.setState({
				valid: true
			});
			var bodyParameters = {
				account: this.state.account,
				description: this.state.description,
				depositAmount: this.state.depositAmount,
				withdrawAmount: this.state.withdrawAmount,
				cashDepositeFrom: this.state.cashDepositeFrom,
				cashWithdrawBy: this.state.cashWithdrawBy,
				accountId: this.props.userData.unique_name,
				accountType: this.props.userData.role
			};
			this.props.sharedActions
				.addRecord(
					`/api/AccountLedger`,
					bodyParameters,
					this.props.FinanceAction.addFinance
				)
				.then(success => {
					// this.props.adminActions.updateGlobalFlag(true);
					this.setState({
						account: '',
						description: '',
						depositAmount: '',
						withdrawAmount: '',
						cashDepositeFrom: '',
						cashWithdrawBy: '',
						withdrawCheck: false,
						depositCheck: false,
						msg: true,
						errorMsg: false
					});
					this.props.FinanceAction.addRecordToFinance(bodyParameters);
				})
				.catch(error => {});
		}
		console.log(this.state);
	};
	componentDidUpdate() {
		if (this.state.msg || this.state.errorMsg) {
			setTimeout(() => {
				this.setState({
					msg: false,
					errorMsg: false
				});
			}, 3000);
		}
	}
	Message = (msg, color, fontSize, flag) => {
		if (flag) {
			return (
				<p style={{ fontSize: fontSize }} class={`text-${color}`}>
					{msg}
				</p>
			);
		}
	};
	render() {
		const withdrawl = this.state.withdrawCheck;
		const deposit = this.state.depositCheck;
		console.log('props', this.props);

		return (
			<div>
				<section class="mb-4">
					<h2 class="h1-responsive font-weight-bold text-center my-4">
						Add Account
					</h2>

					<div
						class="row"
						style={{
							justifyContent: 'center',
							textAlign: 'center'
						}}
					>
						<div
							class="col-md-9 mb-md-0 mb-5"
							style={{
								border: 'solid',
								borderColor: '#01ac8a',
								padding: 40,
								borderRadius: 15,
								width: 'auto'
							}}
						>
							{this.Message(
								'Account Added Successfully!',
								'success',
								20,
								this.state.msg
							)}
							{this.Message(
								'Please fill the form properly!',
								'danger',
								20,
								this.state.errorMsg
							)}
							<form id="contact-form" name="contact-form">
								<div class="row">
									<div class="col-md-12">
										<div class="md-form mb-0">
											<DateandTime />
										</div>
									</div>
								</div>
								<br />
								<div class="row">
									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="name" class="">
												<i
													className="fas fa-user-shield animated fadeIn"
													style={{ marginRight: 4 }}
												/>
												Admin Name
											</label>
											<input
												readOnly={true}
												value={this.props.userData.unique_name}
												type="text"
												name="accountId"
												class="form-control"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="name" class="">
												<i
													className="fas fa-user-alt animated fadeIn"
													style={{ marginRight: 4 }}
												/>
												Admin Type
											</label>
											<input
												readOnly={true}
												value={this.props.userData.role}
												type="text"
												name="accountType"
												class="form-control"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="name" class="">
												<i
													className="fas fa-building"
													style={{ marginRight: 4 }}
												/>
												Account
											</label>
											<input
												type="text"
												name="account"
												class="form-control"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="name" class="">
												<i className="fas fa-key" style={{ marginRight: 4 }} />
												Description
											</label>
											<input
												type="text"
												name="description"
												class="form-control"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>
									<div
										class="col-md-12"
										style={{ marginTop: 20, textAlign: 'center' }}
									>
										<div class="md-form mb-0" style={{ marginTop: 40 }}>
											<label style={{ marginRight: 5 }}>Deposit</label>
											<input
												style={{ marginRight: 20 }}
												type="checkbox"
												onChange={this.depositAmountChange}
												checked={this.state.depositCheck}
											/>
											<label style={{ marginRight: 5 }}>Withdrawl</label>
											<input
												style={{ marginRight: 20 }}
												type="checkbox"
												name="checkbox"
												checked={this.state.withdrawCheck}
												onChange={this.withdrawAmountChange}
											/>
										</div>
									</div>
								</div>
								{deposit ? (
									<div class="row">
										<div
											class="col-md-6"
											style={{ marginTop: 20, textAlign: 'center' }}
										>
											<div class="md-form mb-0">
												<label for="name" class="">
													<i
														className="fas fa-key"
														style={{ marginRight: 4 }}
													/>
													Deposit Amount
												</label>
												<input
													type="text"
													name="depositAmount"
													class="form-control"
													onChange={this.onHandleTextChange}
												/>
											</div>
										</div>
										<div
											class="col-md-6"
											style={{ marginTop: 20, textAlign: 'center' }}
										>
											<div class="md-form mb-0">
												<label for="name" class="">
													<i
														className="fas fa-key"
														style={{ marginRight: 4 }}
													/>
													Depositor Name
												</label>
												<input
													type="text"
													name="cashDepositeFrom"
													class="form-control"
													onChange={this.onHandleTextChange}
												/>
											</div>
										</div>
									</div>
								) : (
									<div />
								)}
								{withdrawl ? (
									<div class="row">
										<div
											class="col-md-6"
											style={{ marginTop: 20, textAlign: 'center' }}
										>
											<div class="md-form mb-0">
												<label for="name" class="">
													<i
														className="fas fa-key"
														style={{ marginRight: 4 }}
													/>
													WithDrawl Amount
												</label>
												<input
													type="text"
													name="withdrawAmount"
													class="form-control"
													onChange={this.onHandleTextChange}
												/>
											</div>
										</div>
										<div
											class="col-md-6"
											style={{ marginTop: 20, textAlign: 'center' }}
										>
											<div class="md-form mb-0">
												<label for="name" class="">
													<i
														className="fas fa-key"
														style={{ marginRight: 4 }}
													/>
													Withdrawal Name
												</label>
												<input
													type="text"
													name="cashWithdrawBy"
													class="form-control"
													onChange={this.onHandleTextChange}
												/>
											</div>
										</div>
									</div>
								) : (
									<div />
								)}

								<br />
								<br />
								<div class="text-center text-md-center">
									<button onClick={this.onHandleSubmit} class="btns">
										Add
									</button>
								</div>
							</form>

							<div class="status" />
						</div>
					</div>
				</section>
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch),
		FinanceAction: bindActionCreators(FinanceAction, dispatch)
	};
}

function mapStateToProps(state) {
	return {
		userData: state.setUserReducer.users
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AddAccountLedger);
