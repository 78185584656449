import React, { Component } from 'react';

class ViewAssignVehicle extends Component {
	state = {};
	render() {
		return (
			<div class="table-responsive">
				<table class="table">
					<thead className="tablehead">
						<tr>
							<th scope="col">S No.</th>
							<th scope="col">Driver Name </th>
							<th scope="col">Driver Id</th>
							<th scope="col">Vehicle Registration</th>
							<th scope="col">Transportation Type</th>
							<th scope="col">Actions</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<th scope="row">1</th>
							<td>Hamza</td>
							<td>21</td>
							<td>abc 124</td>
							<td>Car AC</td>
							<td>
								{' '}
								<span class="table-remove">
									<button
										type="button"
										class="btn btn-primary btn-rounded btn-sm my-0"
										style={{ width: 70 }}
									>
										Add
									</button>
								</span>
								&nbsp;&nbsp;&nbsp;
								<span class="table-remove">
									<button
										type="button"
										class="btn btn-danger btn-rounded btn-sm my-0"
									>
										Remove
									</button>
								</span>
							</td>
						</tr>
						<tr>
							<th scope="row">2</th>
							<td>Sana Ullah</td>
							<td>26</td>
							<td>abc 124</td>
							<td>Van</td>
							<td>
								<span class="table-remove">
									<button
										type="button"
										class="btn btn-primary btn-rounded btn-sm my-0"
										style={{ width: 70 }}
									>
										Add
									</button>
								</span>
								&nbsp;&nbsp;&nbsp;
								<span class="table-remove">
									<button
										type="button"
										class="btn btn-danger btn-rounded btn-sm my-0"
									>
										Remove
									</button>
								</span>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		);
	}
}

export default ViewAssignVehicle;
