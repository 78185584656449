import React, { useEffect, useState, useContext } from 'react'
import $ from 'jquery';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import SelectionInput from '../../components/SelectionInput/SelectionInput';
import Pagination from '../../components/Pagination/Pagination'
import Spinner from '../../components/Spinner/Spinner';
import httpWithRequiredSetData from '../../hooks/Services/httpWithRequiredSetData';
import general from '../../utils/general'
import Colors from '../../utils/app_constants/colors_constants';
import CustomFilterSectionLayout from '../../components/CustomFilterSectionLayout/CustomFilterSectionLayout';
import DatePicker from 'react-date-picker';
import TextInput from '../../components/TextInput/TextInput'
import Button from '../../components/Button';
import TextArea from '../../components/TextArea'
import TimePicker from '../../components/TimePicker';
import moment from 'moment';
import { Link } from 'react-router-dom';
import themeContext from "../../context/themeContext/ThemeContext";

let tHead = [
    'Event',
    'Date',
    'Month',
    'Session',
    'Time',
    'Description',
    'Actions'
];

let renderFields = [
    'eventName',
    'date',
    'month',
    'session',
    'time',
    'description',
]

const ViewEventCertificate = props => {

    const themeColor = useContext(themeContext);

    const [eventData, setEventData] = useState([])
    const [markData, setMarkData] = useState({})
    const [modalData, setModalData] = useState([])
    const [certificateCategoryData, setCertificateCategoryData] = useState([])
    const [sessionData, setSessionData] = useState([])
    const [dId, setDId] = useState('')
    const [studentStaff, setStudentStaff] = useState('')
    const [isLoadingSearch, setIsLoadingSearch] = useState('')
    const [name, setName] = useState('')
    const [className, setClassName] = useState('')
    const [section, setSection] = useState('')
    const [postName, setPostName] = useState('')
    const [isLoadingSubmit, setIsLoadingSubmit] = useState('')
    const [success, setSuccess] = useState(false)

    const [studentId, setStudentId] = useState('');
    const [staffId, setStaffId] = useState('');
    const [date, setDate] = useState('');

    //for edit modal
    const [eventName, setEventName] = useState('')
    const [certificateId, setCertificateId] = useState('')
    const [sessionId, setSessionId] = useState('')
    const [session, setSession] = useState('')
    const [modalDate, setModalDate] = useState('')
    const [time, setTime] = useState('')
    const [month, setMonth] = useState('')
    const [description, setDescription] = useState('')

    const [enabled, setEnabled] = useState(false)

    const [searchURL, setSearchURL] = useState(
        '/api/EventInfo?pageNumber=1&pageSize=10&date='
    );


    const [disableBPage, setdisableBPage] = useState(true);
    const [customPageSize, setCustomPageSize] = useState('10');
    const [currentPage, setCurrentPage] = useState(1);
    const [pageNumber, setPageNumber] = useState([]);
    const [disablepage, setdisablepage] = useState(false);
    const [pagination, setPaginationData] = useState({});


    const [
        isLoading,
        fetchData,
        setFetchData,
        setIsLoading
    ] = httpWithRequiredSetData(searchURL, [], data => {
        let pageNumber = [];
        for (let i = 1; i <= data['paginationMetadata']['totalPages']; i++) {
            pageNumber.push(i);
        }
        setPageNumber(pageNumber);
        setEventData(data['eventInfoList']);

        setPaginationData(data['paginationMetadata']);
    });

    useEffect(() => {

        const promises = [

            props.sharedActions
                .getDataWithoutDispatch(
                    `/api/CertificateCategory/`
                ),
            props.sharedActions
                .getDataWithoutDispatch(
                    '/api/session'
                )
        ]

        Promise.all(promises)
            .then(success => {
                setIsLoading(true)
                setCertificateCategoryData(success[0])
                setSessionData(success[1].session)
                setIsLoading(false)
            })
            .catch(err => {
                setIsLoading(false)
            })

        return () => {
            $('[data-toggle="tooltip"]').tooltip('hide');
        };
    }, []);

    const handleClickNextFrwd = () => {
        console.log('pagelength: ', pageNumber.length);
        console.log('current page: ', currentPage);
        if (currentPage === pageNumber.length) {
            setdisablepage(true);
        } else {
            setCurrentPage(prevState => prevState + 1);
            console.log(currentPage);

            setdisableBPage(false);
        }
    };

    const onEditClick = (item) => {
        setMarkData(item)

        const getData = eventData.filter(it =>
            it.id === item.id
        )
        setModalData(getData)
        setEventName(getData[0].eventName)
        setCertificateId(getData[0].certificateId)
        setSessionId(getData[0].sessionId)
        setMonth(getData[0].month)
        setDescription(getData[0].description)

        let date = general.formateToDate(getData[0].date)
        let time = moment(getData[0].time, 'LT')

        setModalDate(date)
        setTime(time)
    }

    const onHandleChange = (e) => {
        if (e.target.name === 'eventName') {
            setEventName(e.target.value)
        } else if (e.target.name === 'sessionId') {
            setSessionId(e.target.value)
            let session = sessionData.filter(item => parseInt(item.sessionId) === parseInt(e.target.value));
            setSession(session[0].currentSession)
        } else if (e.target.name === 'certificateId') {
            setCertificateId(e.target.value)
        } else if (e.target.name === 'description') {
            setDescription(e.target.value)
        }
    }

    const onDateChange = (date) => {
        setDate(date)
    }

    const onModalDateChange = (date) => {
        setModalDate(date)
        setMonth(general.Months[date.getMonth()])
    }

    const onTimeChange = (time) => {
        setTime(time)
    }

    useEffect(() => {
        console.log('Do something after counter has changed', currentPage);
        setIsLoading(true);
        props.sharedActions
            .getDataWithoutDispatch(
                general.condtionalServicesEvent(
                    date,
                    currentPage,
                    customPageSize
                )
            )
            .then(success => {
                setEventData(success['eventInfoList']);
                setIsLoading(false);
                let pageNumber = [];
                for (let i = 1; i <= success['paginationMetadata']['totalPages']; i++) {
                    pageNumber.push(i);
                }
                setPageNumber(pageNumber);
                setPaginationData(success['paginationMetadata']);
            })
            .catch(error => {
                setEventData([])
                setPaginationData({})
                setIsLoading(false);
            });
    }, [currentPage, date, customPageSize]);

    const handleClickNext = event => {
        setCurrentPage(Number(event.target.id));
        setdisableBPage(false);
        setdisableBPage(false);
    };
    const handleClickNextBack = () => {
        if (currentPage === 1) {
            setdisableBPage(true);
            setdisablepage(false);
        } else {
            setCurrentPage(prevState => prevState - 1);
        }
    };
    const [msg, setSnackMsg] = useState('');

    const onChangePageSize = e => {
        if (e.target.value !== 'Page Entries') {
            setCustomPageSize(e.target.value);
            setCurrentPage(1);
        }
    };

    const setDeleteID = id => {
        setDId(id);
    };

    const onClickDelete = id => {
        setIsLoading(true)

        props.sharedActions
            .deleteRecordWithoutDispatchWithoutSlash(
                '/api/EventInfo/?id=',
                dId
            )
            .then(success => {
                // if (success.status === 200) {
                let getNewFilterArray = eventData.filter(item => item.id !== dId);
                setSnackMsg('Remove Successfully!');
                setEventData(getNewFilterArray);
                setIsLoading(false)
                props.snackbar();
                setDId(null);
                // }
            })
            .catch(error => {
                setIsLoading(false)
                console.log(error);
            });
    };

    const handleOptionChange = (e) => {
        setStudentStaff(e.target.value)
        setStudentId('')
        setStaffId('')
        setClassName('')
        setName('')
        setSection('')
        setPostName('')
        setSuccess(false)
    };

    const [error, setError] = useState(false)
    const [errorMsg, setErrorMsg] = useState(false)

    const onSubmitGetStudent = (e) => {
        e.preventDefault();

        if (studentId === '') {
            setError(true)
            setErrorMsg(true)
        } else {
            setIsLoadingSearch(true)

            props.sharedActions
                .getDataWithoutDispatch(
                    `/api/StudentInformationById?studentId=${studentId}`
                )
                .then(success => {
                    setIsLoadingSearch(false)
                    setName(success.studentName)
                    setClassName(success.className)
                    setSection(success.section)
                    setSuccess(true)
                })
                .catch(error => {
                    if (error && error.response && error.response.status === 400) {
                        // 404-Error no understanding of 404 Error
                        setErrorMsg(error.response.data.Message)
                        setError(true)
                        setIsLoadingSearch(false)
                        setName('')
                        setSuccess(false)
                        setClassName('')
                        setSection('')
                    } else {
                        setIsLoadingSearch(false)
                        setName('')
                        setSuccess(false)
                        setClassName('')
                        setSection('')
                    }
                })
        }
    }

    const onSubmitGetStaff = (e) => {
        e.preventDefault();

        if (staffId === '') {
            setError(true)
            setErrorMsg(true)
        } else {
            setIsLoadingSearch(true)

            props.sharedActions
                .getDataWithoutDispatch(
                    `/api/StaffInformationById?staffId=${staffId}`
                )
                .then(success => {
                    setIsLoadingSearch(false)
                    setName(success.staffName)
                    setPostName(success.postName)
                    setSuccess(true)
                })
                .catch(error => {
                    if (error && error.response && error.response.status === 400) {
                        // 404-Error no understanding of 404 Error
                        setErrorMsg(error.response.data.Message)
                        setError(true)
                        setIsLoadingSearch(false)
                        setName('')
                        setSuccess(false)
                        setPostName('')
                    } else {
                        setIsLoadingSearch(false)
                        setName('')
                        setSuccess(false)
                        setPostName('')
                    }
                })
        }
    }

    const onSubmitAttendance = (e) => {
        e.preventDefault();

        if (success) {

            if (studentId !== '') {
                setIsLoadingSubmit(true)

                const data = {
                    studentId,
                    eventInfoId: markData.id,
                }

                props.sharedActions
                    .simpleAddRequest('/api/EventAttendance/',
                        data
                    )
                    .then(success => {
                        setStudentId('')
                        setStaffId('')
                        setClassName('')
                        setName('')
                        setSection('')
                        setPostName('')
                        setSuccess(false)
                        setSnackMsg('Added SuccessFully!')
                        setIsLoadingSubmit(false)
                    })
                    .catch(error => {
                        if (error && error.response && error.response.status === 400) {
                            // 404-Error no understanding of 404 Error
                            setErrorMsg(error.response.data.Message)
                            setError(true)
                            setIsLoadingSubmit(false)
                        } else {
                            setIsLoadingSubmit(false)
                        }
                    })
            } else if (staffId !== '') {
                setIsLoadingSubmit(true)

                const data = {
                    staffId,
                    eventInfoId: markData.id
                }
                props.sharedActions
                    .simpleAddRequest('/api/EventAttendance/',
                        data
                    )
                    .then(success => {
                        setStudentId('')
                        setStaffId('')
                        setClassName('')
                        setName('')
                        setSection('')
                        setPostName('')
                        setSuccess(false)
                        setSnackMsg('Added SuccessFully!')
                        setIsLoadingSubmit(false)
                    })
                    .catch(error => {
                        if (error && error.response && error.response.status === 400) {
                            // 404-Error no understanding of 404 Error
                            setErrorMsg(error.response.data.Message)
                            setError(true)
                            setIsLoadingSubmit(false)
                        } else {
                            setIsLoadingSubmit(false)
                        }
                    })
            }
        }
    }

    useEffect(() => {
        if (msg || errorMsg) {
            setTimeout(() => {
                setError(false)
                setErrorMsg(false)
            }, 5000);
        }
    });

    const onHandleSubmitUpdate = e => {
        e.preventDefault();

        modalData[0].eventName = eventName
        modalData[0].sessionId = sessionId
        modalData[0].session = session
        modalData[0].date = general.dateToFormat(modalDate)
        let modalTime = moment(time).format("hh:mm a");
        modalData[0].time = modalTime
        modalData[0].month = month
        modalData[0].certificateId = certificateId
        modalData[0].description = description

        props.sharedActions
            .editRecordWithoutDispatchAndId(
                `/api/EventInfo/?id=${markData.id}`,
                modalData[0],
            )
            .then(success => {
                eventData.forEach((item, index) => {
                    if (item.id === markData.id) {
                        eventData.splice(index, 1, modalData[0])
                    }
                })
                setEnabled(false)
                $('#myModal').modal('toggle'); //or  $('#IDModal').modal('hide');
                setSnackMsg('Edited Record Successfulyy!')
                setMarkData({})
                props.snackbar();
            })
            .catch(err => { });
    };

    const PrintContent = el => {
        var restorepage = document.body.innerHTML;
        var Printcontent = document.getElementById(el).innerHTML;
        document.body.innerHTML = Printcontent;
        window.print();
        document.body.innerHTML = restorepage;
        window.location.reload();
    };


    const bodyRendering = data =>
        data &&
        data.map((item, index) => (
            <tr>
                <th
                    style={{
                        padding: 2,
                        border: `1px solid ${Colors.SEA_GREEN_THEME}`,
                        borderColor: themeColor.themeColors.primaryColor
                    }}
                    scope="row"
                >
                    {index + 1}
                </th>
                {renderFields.map((item1, index) => (
                    <td
                        style={{
                            fontWeight: `${index == 0 ? 'bold' : 'normal'}`,
                            border: `1px solid ${Colors.SEA_GREEN_THEME}`,
                            padding: 2,
                            borderColor: themeColor.themeColors.primaryColor
                        }}
                    >
                        {item[item1]}
                    </td>
                ))}
                <td className='displayElemet'
                    style={{
                        border: `1px solid ${Colors.SEA_GREEN_THEME}`,
                        padding: 2,
                        borderColor: themeColor.themeColors.primaryColor
                    }}
                >
                    <a href="#" data-toggle="tooltip" title="Mark Attendance!">
                        <button
                            id="firstbutton"
                            onClick={() => onEditClick(item)}
                            data-toggle="modal"
                            data-target="#myModal2"
                            style={{
                                cursor: 'pointer',
                                fontSize: 20,
                                border: 'none',
                                marginLeft: 5,
                                background: 'none',
                                marginBottom: 5
                            }}
                        >
                            <i class="fas fa-plus" style={{ color: themeColor.themeColors.primaryColor }}></i>
                        </button>
                    </a>

                    <Link to={`/dashboard/EventAttendanceCertificate/${item.id}`}>
                        <button
                            id="firstbutton"
                            // onClick={() => this.onEditClick(item.id)}
                            style={{
                                cursor: 'pointer',
                                fontSize: 20,
                                border: 'none',
                                marginLeft: 5,
                                background: 'none',
                                marginBottom: 5
                            }}
                        >
                            <a href="#" data-toggle="tooltip" title="View!">
                                <i style={{ color: themeColor.themeColors.primaryColor }} class="fas fa-calendar-alt"></i>
                            </a>
                        </button>
                    </Link>

                    <a href="#" data-toggle="tooltip" title="Edit!">
                        <button
                            id="firstbutton"
                            onClick={() => onEditClick(item)}
                            data-toggle="modal"
                            data-target="#myModal"
                            style={{
                                cursor: 'pointer',
                                fontSize: 20,
                                border: 'none',
                                marginLeft: 5,
                                background: 'none',
                                marginBottom: 5
                            }}
                        >
                            <i class="fas fa-pen"></i>
                        </button>
                    </a>

                    <a href="#" data-toggle="tooltip" title="Delete!">
                        <button
                            onClick={() => setDeleteID(item.id)}
                            data-toggle="modal"
                            data-target="#myModal1"
                            style={{
                                cursor: 'pointer',
                                fontSize: 20,
                                border: 'none',
                                background: 'none',
                                marginLeft: 5,
                                marginBottom: 5
                            }}
                        >
                            <i style={{ color: '#D11A2A' }} class="fas fa-trash-alt"></i>
                        </button>
                    </a>
                </td>
            </tr>
        ));

    const Student = (
        <div>
            <div className="row text-center" style={{ justifyContent: 'center' }} >
                <TextInput
                    feildName={'studentId'}
                    onHandleChange={(e) => {
                        setStudentId(e.target.value)
                    }}
                    errors={{}}
                    stateData={{
                        studentId: studentId
                    }}
                    placeholder={'Enter Student ID'}
                    isValidte={false}
                />
                <Button
                    icon={true}
                    width="100%"
                    buttonClass="customButton"
                    btnName="Search"
                    loaderBmargin={5}
                    stateData={{
                        isLoading: isLoadingSearch
                    }}
                    marginTop={20}
                    onHandleSubmit={onSubmitGetStudent}
                    margin={'0 auto'}
                    textColor='white'
                    btnBackColor={themeColor.themeColors.primaryColor}
                />
            </div>
            <div className="row">

                <TextInput
                    feildName={'className'}
                    inputLabelName={'Class Name'}
                    errors={{}}
                    stateData={{
                        className: className
                    }}
                    placeholder="Class Name"
                    readOnly={true}
                    type={'text'}
                    isValidte={false}
                />

                <TextInput
                    feildName={'section'}
                    inputLabelName={'Section'}
                    errors={{}}
                    stateData={{
                        section: section
                    }}
                    placeholder="Section"
                    readOnly={true}
                    type={'text'}
                    isValidte={false}
                />

                <TextInput
                    marginTop={20}
                    feildName={'name'}
                    inputLabelName={'Student Name'}
                    errors={{}}
                    stateData={{
                        name: name
                    }}
                    placeholder="Student Name"
                    readOnly={true}
                    type={'text'}
                    isValidte={false}
                />
            </div>
            <div className="row text-center" style={{ justifyContent: 'center' }} >
                <Button
                    width="100%"
                    buttonClass="customButton"
                    btnName="Add"
                    loaderBmargin={5}
                    stateData={{
                        isLoading: isLoadingSubmit
                    }}
                    marginTop={20}
                    onHandleSubmit={onSubmitAttendance}
                    margin={'0 auto'}
                    textColor={'white'}
                    btnBackColor={themeColor.themeColors.primaryColor}
                />
            </div>
        </div>
    );

    const Staff = (
        <div>
            <div className="row text-center" style={{ justifyContent: 'center' }} >
                <TextInput
                    feildName={'staffId'}
                    onHandleChange={(e) => {
                        setStaffId(e.target.value)
                    }}
                    errors={{}}
                    stateData={{
                        staffId: staffId
                    }}
                    placeholder={'Enter Staff ID'}
                    isValidte={false}
                />
                <Button
                    icon={true}
                    width="100%"
                    buttonClass="customButton"
                    btnName="Search"
                    loaderBmargin={5}
                    stateData={{
                        isLoading: isLoadingSearch
                    }}
                    marginTop={20}
                    onHandleSubmit={onSubmitGetStaff}
                    margin={'0 auto'}
                    textColor='white'
                    btnBackColor={themeColor.themeColors.primaryColor}
                />
            </div>
            <div className="row">

                <TextInput
                    feildName={'postName'}
                    inputLabelName={'Post Name'}
                    errors={{}}
                    stateData={{
                        postName: postName
                    }}
                    placeholder="Post Name"
                    readOnly={true}
                    type={'text'}
                    isValidte={false}
                />

                <TextInput
                    feildName={'name'}
                    inputLabelName={'Staff Name'}
                    errors={{}}
                    stateData={{
                        name: name
                    }}
                    placeholder="Staff Name"
                    readOnly={true}
                    type={'text'}
                    isValidte={false}
                />
            </div>
            <div className="row text-center" style={{ justifyContent: 'center' }} >
                <Button
                    width="100%"
                    buttonClass="customButton"
                    btnName="Add"
                    loaderBmargin={5}
                    stateData={{
                        isLoading: isLoadingSubmit
                    }}
                    marginTop={20}
                    onHandleSubmit={onSubmitAttendance}
                    margin={'0 auto'}
                    textColor={'white'}
                    btnBackColor={themeColor.themeColors.primaryColor}
                />
            </div>
        </div>
    );

    return (
        <div id='div1'>
            <SnackBar msg={msg} />
            <div class="">
          <img
            src={require("../../Assets/images/header.png")}
            style={{
              width: "100%",
              height: "150",
            }}
          />
        </div>
            <div class="modal" id="myModal">
                <div class="modal-dialog modal-md modal-dialog-centered">
                    <div class="modal-content modal_content_custom">
                        <div
                            style={{
                                borderBottomLeftRadius: 30,
                                borderBottomRightRadius: 30,
                                borderTopLeftRadius: 18,
                                borderTopRightRadius: 18,
                                background: themeColor.themeColors.primaryColor
                            }}
                            class="modal-header modal_custom_header"
                        >
                            <h4 style={{ color: 'white' }} class="modal-title">
                                Details
								</h4>
                            <button
                                style={{ color: 'white' }}
                                type="button"
                                class="close"
                                data-dismiss="modal"
                            >
                                &times;
								</button>
                        </div>
                        <div class="modal-body">
                            <form onSubmit={e => onHandleSubmitUpdate(e)}>
                                {modalData &&
                                    modalData.map((item, index) => (
                                        <div key={index}>
                                            <fieldset disabled={!enabled}>
                                                <div class='row'>

                                                    <TextInput
                                                        feildName={'eventName'}
                                                        inputLabelName={'Event'}
                                                        onHandleChange={onHandleChange}
                                                        errors={{}}
                                                        stateData={{ eventName: eventName }}
                                                        placeholder={'Enter Event'}
                                                        isValidte={false}
                                                    />

                                                    <SelectionInput
                                                        feildName={'sessionId'}
                                                        selectName={'Session'}
                                                        onHandleChange={e => onHandleChange(e)}
                                                        errors={{}}
                                                        optionsArrys={sessionData}
                                                        selectedText={'Session'}
                                                        stateData={{ sessionId: sessionId }}
                                                        // iconClassName={'fas fa-building'}
                                                        optionType="dynamicWithPropIdAndName"
                                                        property={'currentSession'}
                                                        propertyId={'sessionId'}
                                                        successflag={'successflag'}
                                                        isValidte={false}
                                                    // editUse={'staffEdit'}
                                                    />

                                                    <div class="col-md-6" >
                                                        <div class="md-form mb-0">
                                                            <label for="email" class="">
                                                                <p style={{ margin: 0, color: '#000' }}>
                                                                    Date
                                </p>
                                                            </label>
                                                            <br />
                                                            <DatePicker
                                                                onChange={e => onModalDateChange(e)}
                                                                value={modalDate}
                                                                selectDateOnly
                                                                disabled={!enabled}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6" >
                                                        <div class="md-form mb-0">
                                                            <label for="email" class="">
                                                                <p style={{ margin: 0, color: '#000' }}>
                                                                    Time
                                </p>
                                                            </label>
                                                            <br />
                                                            <TimePicker
                                                                className="dateTimePicker"
                                                                showSecond={false}
                                                                placeholder={'Hr-Min-(AM/PM)'}
                                                                name="time"
                                                                defaultValue={time}
                                                                onChange={e => onTimeChange(e)}
                                                                format={general.timeFormat}
                                                                disabled={!enabled}
                                                            />
                                                        </div>
                                                    </div>

                                                    <TextInput
                                                        feildName={'month'}
                                                        inputLabelName={'Month'}
                                                        errors={{}}
                                                        stateData={{ month: month }}
                                                        placeholder="Month"
                                                        readOnly={true}
                                                        type={'text'}
                                                        isValidte={false}
                                                    />

                                                    <SelectionInput
                                                        feildName={'certificateId'}
                                                        selectName={'Certificate'}
                                                        onHandleChange={e => onHandleChange(e)}
                                                        errors={{}}
                                                        optionsArrys={certificateCategoryData}
                                                        selectedText={'Certificate'}
                                                        stateData={{ certificateId: certificateId }}
                                                        // iconClassName={'fas fa-building'}
                                                        optionType="dynamicWithPropIdAndName"
                                                        property={'certificateTitle'}
                                                        propertyId={'id'}
                                                        successflag={'successflag'}
                                                        isValidte={false}
                                                    // editUse={'staffEdit'}
                                                    />

                                                    <TextArea
                                                        feildName={'description'}
                                                        inputLabelName={'Description'}
                                                        onHandleChange={onHandleChange}
                                                        errors={{}}
                                                        stateData={{ description: description }}
                                                        // iconClassName={'fas fa-percentage'}
                                                        placeholder={'Add Description'}
                                                        isValidte={false}
                                                    />
                                                </div>
                                            </fieldset>
                                            <br />
                                            <br />
                                            <button
                                                disabled={enabled}
                                                onClick={() => setEnabled(true)}
                                                type="button"
                                                class="btn btn-primary buttonAppear"
                                                style={{background: themeColor.themeColors.primaryColor}}
                                            >
                                                Edit
												</button>
                                            <button
                                                style={{ marginLeft: 5, background: themeColor.themeColors.primaryColor }}
                                                disabled={!enabled}
                                                type="submit"
                                                class="btn btn-primary buttonAppear"
                                            >
                                                Save
												</button>
                                        </div>
                                    ))}
                            </form>
                        </div>
                        <div class="modal-footer">
                            <button
                                type="button"
                                style={{ background: '#00435D' }}
                                class="btn btn-danger"
                                data-dismiss="modal"
                            >
                                Close
								</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal" id="myModal1">
                <div class="modal-dialog">
                    <div class="modal-content modal_content_custom">
                        <div
                            style={{
                                borderBottomLeftRadius: 30,
                                borderBottomRightRadius: 30,
                                borderTopLeftRadius: 18,
                                borderTopRightRadius: 18,
                                background: themeColor.themeColors.primaryColor
                            }}
                            class="modal-header modal_custom_header"
                        >
                            <h4 style={{ color: 'white' }} class="modal-title">
                                Confirm
							</h4>
                            <button
                                style={{ color: 'white' }}
                                type="button"
                                class="close"
                                data-dismiss="modal"
                            >
                                &times;
							</button>
                        </div>
                        <div class="modal-body">
                            <div>
                                <div style={{ textAlign: 'center' }}>
                                    <h4>Are you sure ?</h4>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-evenly'
                                    }}
                                >
                                    <div>
                                        <button
                                            // disabled={this.state.enabled}
                                            data-dismiss="modal"
                                            onClick={() => onClickDelete(dId)}
                                            type="button"
                                            class="btn btn-primary buttonAppear"
                                            style={{background: themeColor.themeColors.primaryColor}}
                                        >
                                            Yes
										</button>
                                    </div>
                                    <div>
                                        <button
                                            data-dismiss="modal"
                                            // disabled={this.state.enabled}
                                            type="button"
                                            class="btn btn-primary buttonAppear"
                                            style={{background: themeColor.themeColors.primaryColor}}
                                        >
                                            No
										</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal" id="myModal2">
                <div class="modal-dialog modal-lg modal-dialog-centered">
                    <div class="modal-content modal_content_custom">
                        <div
                            style={{
                                borderBottomLeftRadius: 30,
                                borderBottomRightRadius: 30,
                                borderTopLeftRadius: 18,
                                borderTopRightRadius: 18,
                                background: themeColor.themeColors.primaryColor
                            }}
                            class="modal-header modal_custom_header"
                        >
                            <h2 style={{ color: 'white' }} class="modal-title">
                                Attendance
							</h2>
                            <button
                                style={{ color: 'white' }}
                                type="button"
                                class="close"
                                data-dismiss="modal"
                            >
                                &times;
							</button>
                        </div>
                        <div class="modal-body">
                            <div class="card">
                                <div class="card-header text-center" style={{ background: themeColor.themeColors.primaryColor, color: 'white' }}>
                                    <h3> <strong>Event Details</strong></h3>
                                </div>
                                <div class="card-body">
                                    <div class='row' style={{ margin: '0 auto' }}>
                                        <div class='col-md-5'>
                                            <strong style={{ fontSize: 17 }}>Event Name: </strong>&nbsp;
								<label
                                                for="name"
                                                class=""
                                                style={{ fontWeight: 'bold', fontSize: 17, color: '#01425e' }}
                                            >{markData.eventName}</label>
                                        </div>
                                        <div class='col-md-4'>
                                            <strong style={{ fontSize: 17 }}>Date: </strong>&nbsp;
								<label
                                                for="name"
                                                class=""
                                                style={{ fontWeight: 'bold', fontSize: 17, color: '#01425e' }}
                                            >{markData.date}</label>
                                        </div>

                                        <div class='col-md-3'>
                                            <strong style={{ fontSize: 17 }}>Month: </strong>&nbsp;
								<label
                                                for="name"
                                                class=""
                                                style={{ fontWeight: 'bold', fontSize: 17, color: '#01425e' }}
                                            >{markData.month}</label>
                                        </div>

                                        <br />
                                        <br />

                                        <div class='col-md-5'>
                                            <strong style={{ fontSize: 17 }}>Session: </strong>&nbsp;
								<label
                                                for="name"
                                                class=""
                                                style={{ fontWeight: 'bold', fontSize: 17, color: '#01425e' }}
                                            >{markData.session}</label>
                                        </div>

                                        <div class='col-md-4'>
                                            <strong style={{ fontSize: 17 }}>Time: </strong>&nbsp;
								<label
                                                for="name"
                                                class=""
                                                style={{ fontWeight: 'bold', fontSize: 17, color: '#01425e' }}
                                            >{markData.time}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class='col-md-12' style={{ marginTop: 30, }}>
                                <h5 className="h1-responsive font-weight-bold my-4 generalHead" style={{background: themeColor.themeColors.primaryColor}}>
                                    {' '}
                            Attendance{' '}
                                </h5>
                            </div>
                            {error ? (
                                <p style={{ color: 'red', textAlign: 'center' }}>
                                    {errorMsg}
                                </p>
                            ) : (
                                    ''
                                )}
                            <div class='col-md-12'>
                                <form class='form-inline' style={{ marginBottom: 25 }}>
                                    <div class='col-md-12 form-inline d-flex justify-content-around'>
                                        <label style={{ color: themeColor.themeColors.primaryColor, fontWeight: 'bold', marginRight: 10, fontSize: 20 }}>
                                            <input
                                                style={{ width: 20, height: 20 }}
                                                type="radio"
                                                value='Student'
                                                name={'Form'}
                                                onChange={e => handleOptionChange(e)}
                                            />
                                Student</label>
                                        <label style={{ color: themeColor.themeColors.primaryColor, fontWeight: 'bold', marginRight: 10, fontSize: 20 }}>
                                            <input
                                                style={{ width: 20, height: 20 }}
                                                type="radio"
                                                value='Staff'
                                                name={'Form'}
                                                onChange={e => handleOptionChange(e)}
                                            />
                                Staff</label>
                                    </div>
                                </form>
                            </div>
                            {studentStaff === 'Staff' ? Staff : studentStaff === 'Student' ? Student : ''}
                            <br />
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-primary buttonAppear" data-dismiss="modal" style={{background: themeColor.themeColors.primaryColor}}>Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{ textAlign: 'right' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        <h2 className="h1-responsive font-weight-bold text-left my-4 generalHead" style={{background: themeColor.themeColors.primaryColor}}>
                            {' '}
                            Event Details{' '}
                        </h2>
                    </div>
                    <div className='displayElemet'>
                        <div className='displayElemet'
                            style={{ marginTop: 40, display: 'flex', flexDirection: 'column' }}
                        >
                            <Pagination
                                disableBPage={disableBPage}
                                disablepage={disablepage}
                                currentPage={currentPage}
                                pageNumber={pageNumber}
                                handleClickNext={handleClickNext}
                                handleClickNextBack={handleClickNextBack}
                                handleClickNextFrwd={handleClickNextFrwd}
                            />
                            <div
                                style={{
                                    width: 'fit-content',
                                    marginTop: -34,
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    marginLeft: 'auto'
                                }}
                            >
                                <SelectionInput
                                    selectName="Entries"
                                    selectFont={14}
                                    selectFontWeight={'bold'}
                                    newFlag="customWidth"
                                    feildName="customPageSize"
                                    selectedText={'Page Entries'}
                                    optionsArrys={['10', '20', '30', '40', '50']}
                                    errors={{}}
                                    isValidte={false}
                                    stateData={{
                                        customPageSize: customPageSize
                                    }}
                                    optionType="static"
                                    onHandleChange={onChangePageSize}
                                    useFlag={false}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='displayElemet'>
                    <CustomFilterSectionLayout displayDirection="column">
                        <div
                            style={{
                                width: '30%',
                                margin: '0 auto',
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            <React.Fragment>
                                <div class="col-md-6" style={{ marginTop: 25 }}>
                                    <div class="md-form mb-0">
                                        <label for="email" class="">
                                            Date On
											</label>
                                        <br />
                                        <DatePicker
                                            //                                    maxDate={true}
                                            onChange={e => onDateChange(e)}
                                            value={date}
                                            selectDateOnly
                                        />
                                    </div>
                                </div>
                            </React.Fragment>
                        </div>
                    </CustomFilterSectionLayout>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span className="customBadge" style={{ fontSize: 14 }}>
                        Page: {currentPage}
                    </span>

                    <span className="customBadge" style={{ fontSize: 14 }}>
                        Total Page: {pagination['totalPages']}
                    </span>
                </div>
            </div>
            {isLoading ? (
                <Spinner />
            ) :
                eventData.length < 1 ? <div style={{ display: 'flex', justifyContent: 'center', color: 'red' }}>
                    <strong>No Data</strong>
                </div>
                    : (

                        <div className="table-responsive">
                            <table class="table table-hover">
                                <thead style={{ background: themeColor.themeColors.primaryColor, color: 'white' }}>
                                    <tr class='vendorListHeading'>
                                        <th scope="col">#</th>
                                        {tHead.map(item => (
                                            item === 'Actions' ?
                                                <th className='displayElemet'
                                                    style={{
                                                        border: `1px solid ${Colors.SEA_GREEN_THEME}`,
                                                        borderColor: Colors.WHITE
                                                    }}
                                                    scope="col"
                                                >
                                                    {item}
                                                </th>
                                                :
                                                <th
                                                    style={{
                                                        border: `1px solid ${Colors.SEA_GREEN_THEME}`,
                                                        borderColor: Colors.WHITE
                                                    }}
                                                    scope="col"
                                                >
                                                    {item}
                                                </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>{bodyRendering(eventData)}</tbody>
                            </table>
                            <div class="page-footer">
            <div class="text-left">Printed By: {props.users.role}</div>
            <div class="text-right" style={{ marginTop: -25, marginRight: 20 }}>
              Powered by School Smart®
            </div>
		  </div>
                        </div>
                    )}
            <div class="text-center text-md-right displayElemet">
                <label for="name" class="" onClick={() => PrintContent('div1')}>
                    <i
                        className="fas fa-print fa-2x animated fadeIn"
                        style={{ marginRight: 4 }}
                    />
                        Print
                            </label>
            </div>
        </div>
    );
};

function mapDispatchToProps(dispatch) {
    return {
        sharedActions: bindActionCreators(sharedActions, dispatch)
    };
}
function mapStateToProps(state) {
	return {
		users: state.setUserReducer.users
	};
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewEventCertificate);