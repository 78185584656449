import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import DateandTime from '../Transport/DateandTime';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import SelectionInput from '../../components/SelectionInput/SelectionInput';
import TextInput from '../../components/TextInput/TextInput';
import FormLayoutWrapper from '../../components/HOC/FormLayoutWrapper';
import ThemeContext from "../../context/themeContext/ThemeContext";

class AddClassRenew extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			gradesData: [],
			isLoading: false,
			className: '',
			gradeId: '',
			msg: '',
			errMsg: false,
			errors: {},
			userName: props.username,
			role: props.role
		};
	}

	componentDidMount() {
		this.props.shareActions
			.getDataWithoutDispatch('/api/AddGrade')
			.then(success => {
				console.log(success);
				this.setState({
					gradesData: success,
					isLoading: false
				});
			})
			.catch(err => {
				this.setState({
					isLoading: false
				});
				console.log(err);
			});
	}

	componentDidUpdate() {
		if (this.state.msg || this.state.errMsg) {
			setTimeout(() => {
				this.setState({
					msg: false,
					errMsg: false
				});
			}, 3000);
		}
	}

	onChangeHandle = e => {
		this.setState({
			className: e.target.value
		});
	};

	onHandleSubmit = e => {
		e.preventDefault();

		const { className, gradeId } = this.state;

		console.log(gradeId);

		if (Object.is(className, '') || Object.is(gradeId, '')) {
			this.setState({
				errMsg: true
			});
		} else {
			this.setState({
				isLoading: true,
				gradeName: ''
			});

			let data = {
				className: className,
				gradeId: gradeId,
				accountId: this.props.username,
				accountType: this.props.role
			};
			this.props.shareActions
				.simpleAddRequest('/api/AddClass', data)
				.then(success => {
					this.setState({
						gradeId: '',
						className: '',
						isLoading: false,
						msg: 'Successfully Submit!',
						errMsg: false
					});
					this.props.snackbar();
				});
		}
	};

	onSelectGradeChange = e => {
		this.setState({
			gradeId: e.target.value
		});
	};

	myFunction = () => {
		// Get the snackbar DIV
		var x = document.getElementById('snackbar');

		// StepperGeneralButtons the "show" class to DIV
		x.className = 'show';

		// After 3 seconds, remove the show class from DIV
		setTimeout(function() {
			x.className = x.className.replace('show', '');
		}, 3000);
	};

	render() {
		const { errors } = this.state;

		const Form = (
			<ThemeContext.Consumer>
			{(context) => (
			<FormLayoutWrapper
				formName="Add Class"
				borderColor={context.themeColors.primaryColor}
				borderRadius={15}
			>
				{this.state.errMsg ? (
					<p style={{ color: 'red', textAlign: 'center' }}>
						Please fill the form properly
					</p>
				) : (
					''
				)}
				<div className="row">
					<div class="col-md-6">
						<div class="md-form mb-0">
							<label for="name" class="">
								{/* <i
                                    className="fas fa-user-shield animated fadeIn"
                                    style={{ marginRight: 4 }}
                                /> */}
								Admin Name
							</label>
							<input
								disabled={true}
								value={this.props.username}
								type="text"
								readOnly={true}
								name="AdminName"
								class="form-control"
							/>
						</div>
					</div>
					<div class="col-md-6">
						<div class="md-form mb-0">
							<label for="name" class="">
								Admin Type
							</label>
							<input
								disabled={true}
								readOnly={true}
								value={this.props.role}
								type="text"
								name="AdminType"
								class="form-control"
							/>
						</div>
					</div>
					<br />
					<br />
					<TextInput
						isValidte={false}
						onHandleChange={this.onChangeHandle}
						fieldName={'className'}
						inputLabelName={'Class Name'}
						errors={errors}
						stateData={this.state}
						placeHolder={'Class Name'}
						textFont={16}
					/>

					<SelectionInput
						feildName={'grade'}
						isValidte={false}
						selectName={'Grade'}
						onHandleChange={e => this.onSelectGradeChange(e)}
						errors={errors}
						optionsArrys={this.state.gradesData}
						selectedText={'Type'}
						stateData={this.state}
						optionType="dynamicWithPropIdAndName"
						property={'gradeName'}
						propertyId={'id'}
						successflag={'successflag'}
					></SelectionInput>
				</div>
				<br />
				<div class="float-right" style={{ paddingTop: 20 }}>
					<button
						type="submit"
						class="btn"
						onClick={this.onHandleSubmit}
						style={{
							borderRadius: 10,
							backgroundColor: context.themeColors.primaryColor,
							color: 'white'
						}}
					>
						Add
						<span
							style={{
								marginBottom: 5
							}}
							class={`${
								this.state.isLoading ? 'spinner-border spinner-border-sm' : ''
							}`}
						></span>
					</button>
				</div>
			</FormLayoutWrapper>
			)}
			</ThemeContext.Consumer>
		);
		return (
			<div class="container">
				{Form}
				<SnackBar msg={this.state.msg} />
			</div>
		);
	}
}

function mapDispatchToProp(dispatch) {
	return {
		shareActions: bindActionCreators(sharedActions, dispatch)
	};
}

function mapStateToProps(state) {
	return {
		role: state.setUserReducer.users.role,
		username: state.setUserReducer.users.unique_name
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProp
)(AddClassRenew);
