import React from 'react';
import SelectionInput from '../../../../components/SelectionInput/SelectionInput';
import TextInput from '../../../../components/TextInput/TextInput';

let errors = {};
export default function RenderingTd({
	subAndTechData,
	day,
	allPeriod,
	stateData,
	onChangeNewPeriodData,
	type
}) {
	return allPeriod[day] && allPeriod[day].length > 0 ? (
		allPeriod[day] &&
			allPeriod[day].map((item, index) => (
				<React.Fragment>
					{allPeriod[day][index].type === 'break' ? (
						<td style={{ background: '#6C757D', color: '#fff' }}>
							{' '}
							<div
								style={{
									margin: type === 'dataHandling' ? 50 : '',
									textAlign: 'center'
								}}
							>
								Break
							</div>{' '}
						</td>
					) : (
						<td style={{ padding: 0 }}>
							{/* <input type='text' placeholder='hello' /> */}
							{/* <SelectionInput /> */}
							{type === 'dataHandling' ? (
								<div
									style={{
										display: 'flex',
										flexWrap: 'wrap',
										position: 'relative',
										bottom: 22,
										justifyContent: 'center'
									}}
								>
									<div>
										<SelectionInput
											isValidte={false}
											feildName="subjectId"
											selectedText="Choose Subject"
											optionsArrys={stateData && stateData.teachAndStaff}
											errors={stateData && stateData.errors}
											stateData={stateData}
											dynamicIndex={index}
											day={day}
											optionType="dynamic"
											onHandleChange={e => onChangeNewPeriodData(e, index, day)}
											property="subjectName"
											// iconClassName='fas fa-level-down-alt'
											propertyId="subjectId"
											useFlag={true}
										/>
									</div>
									<div>
										<SelectionInput
											isValidte={false}
											feildName="staffId"
											selectedText="Choose Staff"
											optionsArrys={
												stateData &&
												stateData.newPeriodStruct[day] &&
												stateData.newPeriodStruct[day][index]
													? stateData.newPeriodStruct[day][index][
															'staffDropDown'
													  ]
													: []
											}
											errors={stateData && stateData.errors}
											stateData={stateData}
											dynamicIndex={index}
											day={day}
											optionType="dynamic"
											onHandleChange={e => onChangeNewPeriodData(e, index, day)}
											property="staff"
											propertyId="staffId"
											useFlag={true}
										/>
									</div>
								</div>
							) : type === 'EditDataHandling' ? (
								<div>
									<div
										style={{
											display: 'flex',
											flexWrap: 'wrap',
											position: 'relative',
											bottom: 22,
											justifyContent: 'center'
										}}
									>
										<div>
											<SelectionInput
												isValidte={false}
												editUse={true}
												feildName="subjectId"
												selectedText="Choose Subject"
												optionsArrys={subAndTechData.subject}
												errors={errors}
												stateData={item}
												day={day}
												dynamicIndex={index}
												optionType="dynamic"
												onHandleChange={e =>
													onChangeNewPeriodData(e, index, day)
												}
												property="subjectName"
												// iconClassName='fas fa-level-down-alt'
												propertyId="subjectId"
												useFlag={true}
											/>
										</div>
										<div>
											<SelectionInput
												isValidte={false}
												editUse={true}
												StaffFirstName={'StaffFirstName'}
												lastName={'lastName'}
												feildName="staffId"
												selectedText="Choose Staff"
												day={day}
												optionsArrys={
													allPeriod &&
													allPeriod[day] &&
													allPeriod[day][index] &&
													allPeriod[day][index]['staffDropDown']
														? allPeriod[day][index]['staffDropDown'][0][
																'assignStaff'
														  ]
														: []
												}
												errors={errors}
												stateData={item}
												dynamicIndex={index}
												optionType="dynamic"
												onHandleChange={e =>
													onChangeNewPeriodData(e, index, day)
												}
												property="staff"
												propertyId="staffId"
												useFlag={true}
											/>
										</div>
									</div>
								</div>
							) : (
								<div
									style={{ textAlign: 'center', position: 'relative', top: 10 }}
								>
									<label>
										{' '}
										<p style={{ color: '#000' }}>
											{item.Subject +
												' ' +
												'(' +
												item.StaffFirstName.charAt(0) +
												item.lastName.charAt(0) +
												')'}{' '}
										</p>
									</label>
								</div>
							)}

							{/* <SelectionInput
              feildName='section'
              selectedText='Choose Section'
              optionsArrys={stateData.grade}
              errors={stateData.errors}
              stateData={stateData}
              optionType='dynamic'
              // onHandleChange={(e) => props.onTextChange(e)}
              property='section'
              // iconClassName='fas fa-level-down-alt'
              propertyId='section'
              useFlag={true}
            /> */}
						</td>
					)}
				</React.Fragment>
			))
	) : (
		<React.Fragment>
			<td style={{ background: '#a2a2a2', color: '#fff', textAlign: 'center' }}>
				OFF
			</td>
		</React.Fragment>
	);
}
