import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as FamilyWiseActions from '../../Actions/FamilyWiseSearch';
import * as sharedActions from '../../Actions/sharedActions';
import $ from 'jquery';
import './ClassSection.css';

class FamilyWiseSearch extends Component {
	constructor(props) {
		super(props);
		this.state = {
			adminData: [],
			modalData: null,
			sections: [],
			sectionName: '',
			className: '',
			isLoading: true,
			disablepage: false,
			disableBPage: true,
			currentPage: 1,
			todosPerPage: 10,
			pageCounter: 10,
			msg: '',
			errorMsg: '',
			select: [],
			handler: [],
			check: true,
			currentSession: '',
			male: '0',
			female: '0',
			total: '0',
			apiSession: '- / -',
			apiSection: '-',
			apiClass: '-',
			familyCode: '',
			admissionNo: '-',
			registrationNo: '-',
			firstName: '-',
			fatherName: '-',
			gender: '-',
			status: '-',
			admissionType: '-',
			rollNo: '-'
		};
	}
	onSelectSectionChange = e => {
		console.log(e.target.value);

		const sections = this.props.Document;
		let check = sections.filter(item => item.className === e.target.value);
		console.log(check);

		this.setState({
			sections: check,
			className: e.target.value
		});
	};
	onSelectStudentChange = e => {
		console.log(e.target.value);

		this.setState({
			sectionName: e.target.value
		});
	};
	onSelectSessionChange = e => {
		console.log(e.target.value);

		this.setState({
			currentSession: e.target.value
		});
		console.log(this.state);
	};
	onHandleSubmit = e => {
		this.props.sharedActions
			.getAllData(
				`/api/Census/?admissionId=${this.state.familyCode}`,
				this.props.FamilyWiseActions.setAllFamilyWiseInRedux,
				this.props.FamilyWiseActions.updateFamilyWiseFlag
			)
			.then(success => {
				this.setState({
					admissionNo: this.state.adminData.admissionNo,
					registrationNo: this.state.adminData.registrationNo,
					firstName: this.state.adminData.firstName,
					fatherName: this.state.adminData.fatherName,
					gender: this.state.adminData.gender,
					status: this.state.adminData.status,
					admissionType: this.state.adminData.admissionType,
					rollNo: this.state.adminData.rollNo,
					isLoading: false,
					check: false,
					msg: true,
					errorMsg: false
				});
			})
			.catch(err => {
				// this.props.CensusActions.updateCensusFlag(false);
				this.setState({
					isLoading: false,
					check: true,
					msg: false,
					errorMsg: true,
					male: '0',
					female: '0',
					total: '0',
					apiSection: '-',
					apiSession: '- / -',
					apiClass: '-'
				});

				console.log(err);
			});
		console.log(this.state);
	};
	static getDerivedStateFromProps(nextProps, prevState) {
		if (prevState.adminData !== nextProps.AllTransportData) {
			return {
				adminData: nextProps.AllTransportData
			};
		}
		return null;
	}
	componentDidUpdate() {
		if (this.state.msg || this.state.errorMsg) {
			setTimeout(() => {
				this.setState({
					msg: false,
					errorMsg: false
				});
			}, 3000);
		}
		$('[data-toggle="tooltip"]').tooltip();
	}
	Message = (msg, color, fontSize, flag) => {
		if (flag) {
			return (
				<p style={{ fontSize: fontSize }} class={`text-${color}`}>
					{msg}
				</p>
			);
		}
	};
	btnTechHover = () => {
		$(document).ready(function() {
			$('button').hover(
				function() {
					$(this).css('box-shadow', '0px 5px 5px grey');
					$(this).css('border-color', 'white');
				},
				function() {
					$(this).css('box-shadow', '0px 0px 0px white');
				}
			);
		});
	};
	onHandleTextChange = e => {
		console.log(e.target.value);
		this.setState({
			[e.target.name]: e.target.value
		});
	};
	render() {
		const { data } = this.props;
		const { session } = this.props;
		const { adminData } = this.state;
		console.log('Data he Data', adminData);
		return (
			<div>
				<div className="page-header">
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between'
						}}
					>
						<div>
							<h2>Family Wise&nbsp;</h2>
						</div>
						<div>
							<h3>
								<small class="text-muted">Search</small>
							</h3>
						</div>
					</div>
				</div>
				<div class="row" style={{ justifyContent: 'center' }}>
					<div class="col-md-6" style={{ marginTop: 20 }}>
						<div class="md-form mb-0">
							<label for="email" class="">
								<i
									style={{ marginRight: 4 }}
									className="fas fa-child animated fadeIn"
								/>
								Family Code
							</label>
							<input
								type="text"
								name="familyCode"
								class="form-control"
								onChange={this.onHandleTextChange}
								placeholder="Enter Here"
							/>
						</div>
					</div>
				</div>
				<br />
				<div class="text-center text-md-right">
					<a href="#" data-toggle="tooltip" title="Search!">
						<button
							id="firstbutton"
							onClick={this.onHandleSubmit}
							onMouseOver={this.btnTechHover()}
							style={{
								cursor: 'pointer',
								fontSize: 20,
								border: 'none',
								marginLeft: 5,
								background: 'none',
								marginBottom: 5
							}}
						>
							<i class="fas fa-search fa-2x"></i>
						</button>
					</a>
				</div>
				<br />
				<br />
				<br />
				<br />
				<center>
					{this.Message('No Record Found!', 'danger', 20, this.state.errorMsg)}
				</center>
				<fieldset
					style={{
						border: 'solid',
						borderColor: '#01AC8A',
						height: 'auto',
						justifyContent: 'center',
						textAlign: 'center'
					}}
				>
					<legend class="scheduler-border">Information:</legend>
					<div class="row">
						<div class="col-md-3">
							<div class="md-form mb-0">
								<label style={{ fontWeight: 'bold' }}>Admission No:</label>
								<label style={{ fontSize: 20 }}>
									&nbsp;&nbsp; {this.state.admissionNo}
								</label>
							</div>
						</div>
						<div class="col-md-3">
							<div class="md-form mb-0">
								<label style={{ fontWeight: 'bold' }}>Registration No:</label>
								<label style={{ fontSize: 20 }}>
									&nbsp;&nbsp; {this.state.registrationNo}
								</label>
							</div>
						</div>
						<div class="col-md-3">
							<div class="md-form mb-0">
								<label style={{ fontWeight: 'bold' }}>First Name:</label>
								<label style={{ fontSize: 20 }}>
									&nbsp;&nbsp; {this.state.firstName}
								</label>
							</div>
						</div>
						<div class="col-md-3">
							<div class="md-form mb-0">
								<label style={{ fontWeight: 'bold' }}>Father Name:</label>
								<label style={{ fontSize: 20 }}>
									&nbsp;&nbsp; {this.state.fatherName}
								</label>
							</div>
						</div>
					</div>
					<br />
					<div class="row">
						<div class="col-md-3">
							<div class="md-form mb-0">
								<label style={{ fontWeight: 'bold' }}>Status:</label>
								<label style={{ fontSize: 20 }}>
									&nbsp;&nbsp; {this.state.status}
								</label>
							</div>
						</div>
						<div class="col-md-3">
							<div class="md-form mb-0">
								<label style={{ fontWeight: 'bold' }}>Gender:</label>
								<label style={{ fontSize: 20 }}>
									&nbsp;&nbsp; {this.state.gender}
								</label>
							</div>
						</div>
						<div class="col-md-3">
							<div class="md-form mb-0">
								<label style={{ fontWeight: 'bold' }}>Admission Type:</label>
								<label style={{ fontSize: 20 }}>
									&nbsp;&nbsp; {this.state.admissionType}
								</label>
							</div>
						</div>
						<div class="col-md-3">
							<div class="md-form mb-0">
								<label style={{ fontWeight: 'bold' }}>Roll No:</label>
								<label style={{ fontSize: 20 }}>
									&nbsp;&nbsp; {this.state.rollNo}
								</label>
							</div>
						</div>
					</div>
				</fieldset>
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch),
		FamilyWiseActions: bindActionCreators(FamilyWiseActions, dispatch)
	};
}

function mapStateToProps(state) {
	return {
		userData: state.setUserReducer.users,
		AllTransportData: state.setFamilyWiseReducer.Data,
		allTransportState: state.setFamilyWiseReducer
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(FamilyWiseSearch);
