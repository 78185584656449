import React from 'react';
import ViewRendering from '../components/ViewRendering';
import { renderingStuff, images } from '../utils';

export default function() {
	return (
		<div>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'center'
				}}
			>
				<div>
					<h4 class="generalHead">Manager Dashboard</h4>
				</div>
				<nav aria-label="Page navigation example">
					<ul style={{ color: '#01AC8A' }} class="pagination">
						{/* {renderPageNumbers} */}
					</ul>
				</nav>
			</div>
			<br />
			<ViewRendering
				centered={true}
				centerWidth={'50%'}
				mainHeading={images.imgAcHead}
				data={renderingStuff.Mangers_Link}
				mainHead={false}
			/>
		</div>
	);
}
