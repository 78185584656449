import React, { useState } from "react";
import CustomFilterSectionLayout from "../../../components/CustomFilterSectionLayout/CustomFilterSectionLayout";
import DatePicker from "react-date-picker";
import DeliTimePicker from "../../../components/TimePicker/TimePicker";
import general, { dateConversion } from "../../../utils/general";
import TimePicker from "../../../components/TimePicker";

import Button from "../../../components/Button";
import * as sharedActions from "../../../Actions/sharedActions";
import Colors from "../../../utils/app_constants/colors_constants";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import SelectionInput from "../../../components/SelectionInput/SelectionInput";
import { set } from "lodash";
import { useEffect } from "react";
import httpWithRequiredSetData from "../../../hooks/Services/httpWithRequiredSetData";
import Spinner from "../../../components/Spinner/Spinner";
import { useContext } from "react";
import ThemeContext from "../../../context/themeContext/ThemeContext";

function ApplySubstitution(props) {
  const themeContext = useContext(ThemeContext);
  const [date, setDate1] = useState("");
  const [isSubPerform, isSetSubPerform] = useState(true);
  const [waiting, setFlag] = useState(true);
  const [isSubstitutionApply, setSubstitutionStatus] = useState("");
  let [isLoading, getSubstitutionData1, setFetchData] = httpWithRequiredSetData(
    `/api/ActivePriority`,
    [],
    (data) => {
      debugger;
      // console.log(data);
      // let addAditionalData = addDataMiddleware(
      // 	{
      // 		Action: [
      // 			{
      // 				// hanlder: deleteHandler,
      // 				exceptionCase: true,
      // 				isEnable: true,
      // 				type: 'normalModalButton',
      // 				modalKey: '#deleteModal',
      // 				title: 'Delete',
      // 				initialPath: '/dashboard/ContinousNewToolCreation',
      // 				iconName: 'fas fa-trash-alt',
      // 				iconColor: Colors.RED_DANGER
      // 			}
      // 		]
      // 	},
      // 	data
      // );
      // debugger;
      setFetchData(data);
    }
  );
  useEffect(() => {
    setTimeout(() => {
      setFlag(false);
    }, 5000);
  }, []);
  const [cutOffTime, setCutOffTime] = useState("");
  const [isCustomLoader, setCustomLoader] = useState(false);
  const [getSubstitutionData, setSubstitutionData] = useState([]);
  const [getDate, setDate] = useState("");
  const [department, setDepartment] = useState("");
  const [getTime, setTime] = useState("");

  const onChangeDeparment = (e) => {
    console.log(e.target.value);
    debugger;
    if (e.target.value !== "Type") {
      setDepartment(e.target.value);
    }
  };

  useEffect(() => {
    props.sharedAction
      .getDataWithoutDispatch(`/api/SubstitutionCuttOffTime/?date=09/27/2020`)
      .then((success) => {
        debugger;
        props.sharedAction
          .getDataWithoutDispatch(
            `/api/StaffSubstitution/?date=${success[0]["date"]}&cutOffTime=${success[0]["cutOffTime"]}`
          )
          .then((success2) => {
            debugger;
            setDate1(success[0]["date"]);
            setCutOffTime(success["0"]["cutOffTime"]);
            props.sharedAction
              .getDataWithoutDispatch(
                `/api/SubsitutionPeriodsDaily/?date=${success[0]["date"]}`
              )
              .then((success2) => {
                // setSubstitutionData(success2);
                debugger;
              })
              .catch((error) => {});
            // setSubstitutionData(success2);
            debugger;
          })
          .catch((error) => {
            debugger;
            setSubstitutionStatus(error.response.data.Message);
          });
        debugger;
      })
      .catch((error) => {});
  }, []);
  const onSearchRecord = () => {
    debugger;
    props.sharedAction
      .getDataWithoutDispatch(`/api/SubsitutionPeriodsDaily/?date=${date}`)
      .then((success2) => {
        setSubstitutionData(success2);
        isSetSubPerform(false);
        debugger;
      })
      .catch((error) => {});
  };
  const onChangeDate = (date) => {
    debugger;

    setDate(date);
  };
  const onChange = (value) => {
    setTime(new Date(value && value._d).setSeconds(0));
  };
  return (
    <div>
      <h3
        style={{ background: themeContext.themeColors.primaryColor }}
        className="text-center generalHead"
      >
        Priority List
      </h3>
      {getSubstitutionData1 && getSubstitutionData1.length > 0 ? (
        <React.Fragment>
          {isSubstitutionApply == "" ? (
            <div className="d-flex justify-content-between">
              <div>
                <p>Date: {date}</p>
              </div>
              <div>
                <p>CuttOffTime: {cutOffTime}</p>
              </div>
            </div>
          ) : (
            <p
              style={{ background: themeContext.themeColors.primaryColor }}
              className="text-center"
            >
              Substitution Has been Performs
            </p>
          )}
          <table class="table">
            <thead
              style={{ background: themeContext.themeColors.primaryColor }}
            >
              <tr>
                <th
                  style={{
                    color: Colors.WHITE,
                    background: themeContext.themeColors.primaryColor,
                  }}
                  scope="col"
                >
                  #
                </th>
                <th
                  style={{
                    color: Colors.WHITE,
                    background: themeContext.themeColors.primaryColor,
                  }}
                  scope="col"
                >
                  Priority Name
                </th>
                <th
                  style={{
                    color: Colors.WHITE,
                    background: themeContext.themeColors.primaryColor,
                  }}
                  scope="col"
                >
                  Priority
                </th>
                <th
                  style={{
                    color: Colors.WHITE,
                    background: themeContext.themeColors.primaryColor,
                  }}
                  scope="col"
                >
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {getSubstitutionData1 &&
                getSubstitutionData1.map((item, index) => (
                  <tr>
                    <th scope="row">{index + 1}</th>
                    <td>{item.priorityName}</td>
                    <td>{item.priority}</td>
                    <td>{item.status}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </React.Fragment>
      ) : waiting ? (
        <Spinner />
      ) : (
        <p className="text-center text-danger">No Data Found</p>
      )}
      <div className="d-flex justify-content-start">
        {isSubPerform ? (
          <Button
            // width={'0%'}
            buttonClass="btns"
            btnName="Apply Substitution"
            textColor="#fff"
            onHandleSubmit={onSearchRecord}
          />
        ) : (
          ""
        )}
      </div>

      <br />
      <br />
      {getSubstitutionData && getSubstitutionData.length > 0 ? (
        <React.Fragment>
          <h3
            style={{ background: themeContext.themeColors.primaryColor }}
            className="text-center generalHead"
          >
            Substitution Result
          </h3>
          <br />

          <table class="table">
            <thead style={{ background: Colors.SEA_GREEN_THEME }}>
              <tr>
                <th
                  style={{
                    color: Colors.WHITE,
                    background: themeContext.themeColors.primaryColor,
                  }}
                  scope="col"
                >
                  #
                </th>
                <th
                  style={{
                    color: Colors.WHITE,
                    background: themeContext.themeColors.primaryColor,
                  }}
                  scope="col"
                >
                  Pre Staff Name
                </th>
                <th
                  style={{
                    color: Colors.WHITE,
                    background: themeContext.themeColors.primaryColor,
                  }}
                  scope="col"
                >
                  Pre Subject Name
                </th>
                <th
                  style={{
                    color: Colors.WHITE,
                    background: themeContext.themeColors.primaryColor,
                  }}
                  scope="col"
                >
                  New Staff Name
                </th>
                <th
                  style={{
                    color: Colors.WHITE,
                    background: themeContext.themeColors.primaryColor,
                  }}
                  scope="col"
                >
                  New Subject Name
                </th>

                <th
                  style={{
                    color: Colors.WHITE,
                    background: themeContext.themeColors.primaryColor,
                  }}
                  scope="col"
                >
                  Period #
                </th>
                <th
                  style={{
                    color: Colors.WHITE,
                    background: themeContext.themeColors.primaryColor,
                  }}
                  scope="col"
                >
                  Date
                </th>
                <th
                  style={{
                    color: Colors.WHITE,
                    background: themeContext.themeColors.primaryColor,
                  }}
                  scope="col"
                >
                  Day Name
                </th>
                <th
                  style={{
                    color: Colors.WHITE,
                    background: themeContext.themeColors.primaryColor,
                  }}
                  scope="col"
                >
                  Substitution For
                </th>
              </tr>
            </thead>
            <tbody>
              {getSubstitutionData &&
                getSubstitutionData.map((item, index) => (
                  <tr>
                    <th scope="row">{index + 1}</th>
                    <td>{item.preStaffName}</td>
                    <td>{item.preSubjectName}</td>
                    <td>{item.newStaffName}</td>
                    <td>{item.newSubjectName}</td>
                    <td>{item.name}</td>
                    <td>{item.date}</td>
                    <td>{item.dayName}</td>
                    <td>{item.substitutionFor}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </React.Fragment>
      ) : (
        <p className="text-center text-danger">No Substitution Result</p>
      )}
    </div>
  );
}
function mapStateToProps(state) {
  return {
    role: state.setUserReducer.users.role,
    name: state.setUserReducer.users.unique_name,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sharedAction: bindActionCreators(sharedActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplySubstitution);
