import React from 'react'
import { connect } from 'react-redux';
import $ from 'jquery'
import { Link } from 'react-router-dom';
import SelectionInput from '../../../components/SelectionInput/SelectionInput';
import Button from '../../../components/Button';
import * as sharedActions from '../../../Actions/sharedActions';
import SnackBar from '../../../components/SnackBar/SnackBar';
import { bindActionCreators } from 'redux';
import Spinner from '../../../components/Spinner/Spinner';
import general from '../../../utils/general'
import Colors from '../../../utils/app_constants/colors_constants';
import CustomFilterSectionLayout from '../../../components/CustomFilterSectionLayout/CustomFilterSectionLayout';
import ThemeContext from "../../../context/themeContext/ThemeContext";

let tHeadStudent = [
    'Patient',
    //    'Grade',
    'Doctor',
    'Date',
    'Contact #',
    'Problem',
    'Account',
    'Account Type',
    'Actions'
];

let renderFieldStudent = [
    'name',
    //    'grade',
    'doctorName',
    'date',
    'contactNo',
    'problem',
    'accountId',
    'accountType'
]
let tHeadStaff = [
    'Patient',
    'Staff',
    'Doctor',
    'Date',
    'Contact #',
    'Problem',
    'Account',
    'Account Type',
    'Actions'
];

let renderFieldStaff = [
    'name',
    'staffPost',
    'doctorName',
    'date',
    'contactNo',
    'problem',
    'accountId',
    'accountType'
]

class MedicalHistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            errorMsg: false,
            errors: {},
            studentStaff: '',
            id: '',
            msg: '',
            medicalHistory: []
        }
    }

    componentDidMount() {
        $(document).ready(function () {
            $('[data-toggle="tooltip"]').tooltip();
        });
        this.props.sharedActions
            .getDataWithoutDispatch(
                '/api/StaffPostDepart'
            )
            .then(success => {
                this.props.sharedActions
                    .getDataWithoutDispatch(
                        '/api/Student'
                    )
                    .then(success => {
                        this.setState({
                            mainData2: success,
                            classData: success.classes
                        })
                    })
                    .catch(error => {
                        this.setState({
                            isLoading: false
                        });
                        console.log(error);
                    });
                this.setState({
                    mainData: success,
                    departmentData: success.department,
                    isLoading: false
                });
            })
            .catch(error => {
                this.setState({
                    isLoading: false
                });
                console.log(error);
            });
    }

    componentDidUpdate() {
        if (this.state.msg || this.state.errorMsg) {
            setTimeout(() => {
                this.setState({
                    msg: '',
                    errorMsg: false,
                });
            }, 3000);
        }
    }

    onHandleDepartChange = e => {

        const { mainData } = this.state;
        const departmentId = e.target.value
        let filteredPostData = general.dataFilteration(
            mainData.post,
            ['postId', 'postName'],
            { departmentId: parseInt(departmentId) })

        this.setState({
            departmentId: e.target.value,
            postData: filteredPostData,
            staffData: []
        })
    }

    onHandlePostChange = e => {
        if (e.target.value === 'Post') {
            this.setState({
                postId: ''
            })
        } else {
            const { departmentId, mainData } = this.state;
            const postId = e.target.value

            const postNameExtract = general.dataFilteration(
                mainData.post,
                ['postName'],
                {
                    postId: parseInt(postId)
                }
            )

            const staffFilteredData = general.dataFilteration(
                mainData.staff,
                ['staffId', 'staffName'],
                {
                    postId: parseInt(postId),
                    departmentId: parseInt(departmentId)
                }
            )
            this.setState({
                [e.target.name]: e.target.value,
                staffData: staffFilteredData,
                staffPost: postNameExtract[0].postName
            })
        }
    }

    onHandleStaffChange = (e) => {
        const { mainData } = this.state
        if (e.target.value === 'Staff') {
            this.setState({
                staffId: ''
            })
        } else {
            const staffFilteredData = general.dataFilteration(
                mainData.staff,
                ['staffId', 'staffName'],
                {
                    staffId: e.target.value
                }
            )
            this.setState({
                [e.target.name]: e.target.value,
                name: staffFilteredData[0].staffName
            })
        }
    }

    onHandleStudentChange = (e) => {
        const { mainData2 } = this.state
        if (e.target.value === 'Student Name') {
            this.setState({
                studentId: ''
            })
        } else {
            const studentFilteredData = general.dataFilteration(
                mainData2.student,
                ['studentId', 'Student'],
                {
                    studentId: e.target.value
                }
            )
            this.setState({
                [e.target.name]: e.target.value,
                name: studentFilteredData[0].Student
            })
        }
    }

    onHandleClassChange = e => {
        const { mainData2 } = this.state;

        const sectionData = general.dataFilteration(
            mainData2.sections,
            ['sectionId', 'section'],
            {
                classId: e.target.value
            }
        )
        this.setState({
            [e.target.name]: e.target.value,
            sectionData: sectionData,
        })
    }

    onHandleSectionChange = e => {
        const { mainData2, classId } = this.state;

        const studentData = general.dataFilteration(
            mainData2.student,
            ['studentId', 'Student'],
            {
                sectionId: e.target.value,
                classId: classId
            }
        )
        this.setState({
            [e.target.name]: e.target.value,
            studentData: studentData,
        })
    }

    onHandleSelectChange = e => {
        if (e.target.value !== 'Student/Staff') {
            this.setState({
                [e.target.name]: e.target.value,
                id: '',
                medicalHistory: []
            })
        }
    }

    onSubmit = e => {
        e.preventDefault();
        const { studentStaff, id } = this.state;

        if (Object.is(id, '')
        ) {
            this.setState({
                error: true,
                errorMsg: true
            })
        } else {
            this.setState({
                isLoading: true,
            })
            this.props.sharedActions
                .getDataWithoutDispatch(
                    `/api/previousHistroy?id=${id}&post=${studentStaff}`
                )
                .then(success => {
                    this.setState({
                        medicalHistory: success,
                        isLoading: false
                    })
                })
                .catch(err => {
                    this.setState({
                        isLoading: false,
                    })
                })
        }
    }

    btnTechHover = () => {
        $(document).ready(function () {
            $('button').hover(
                function () {
                    $(this).css('box-shadow', '0px 5px 5px grey');
                    $(this).css('border-color', 'white');
                },
                function () {
                    $(this).css('box-shadow', '0px 0px 0px white');
                }
            );
        });
    };

    render() {
        const { errors, isLoading, msg, departmentData, postData, staffData, classData, sectionData, studentData, medicalHistory, studentStaff } = this.state;

        const Student = (
            <ThemeContext.Consumer>
            {(context) => (
            <React.Fragment>
                <SelectionInput
                    newFlag='customWidth'
                    feildName={'classId'}
                    selectedText={'Class Name'}
                    optionsArrys={classData}
                    errors={errors}
                    isValidte={false}
                    stateData={this.state}
                    optionType="dynamicWithPropIdAndName"
                    onHandleChange={e => this.onHandleClassChange(e)}
                    property={'className'}
                    propertyId={'classId'}
                    useFlag={false}
                />
                <SelectionInput
                    newFlag='customWidth'
                    feildName={'sectionId'}
                    selectedText={'Section'}
                    optionsArrys={sectionData}
                    errors={errors}
                    isValidte={false}
                    stateData={this.state}
                    optionType="dynamicWithPropIdAndName"
                    onHandleChange={e => this.onHandleSectionChange(e)}
                    property={'section'}
                    propertyId={'sectionId'}
                    useFlag={false}
                />
                <SelectionInput
                    newFlag='customWidth'
                    feildName={'id'}
                    selectedText={'Student Name'}
                    optionsArrys={studentData}
                    errors={errors}
                    isValidte={false}
                    stateData={this.state}
                    optionType="dynamicWithPropIdAndName"
                    onHandleChange={e => this.onHandleStudentChange(e)}
                    property={'Student'}
                    propertyId={'studentId'}
                    useFlag={false}
                />
                <Button
                    width="100%"
                    buttonClass="customButton"
                    btnName="Search"
                    loaderBmargin={5}
                    stateData={this.state}
                    marginTop={27}
                    onHandleSubmit={this.onSubmit}
                    margin={'0 auto'}
                    textColor='white'
                    btnBackColor = {context.themeColors.primaryColor}
                />
            </React.Fragment>
                                                )}
                                                </ThemeContext.Consumer>
        )

        const Staff = (
            <ThemeContext.Consumer>
            {(context) => (
            <React.Fragment>
                <SelectionInput
                    feildName={'departmentName'}
                    onHandleChange={e => this.onHandleDepartChange(e)}
                    errors={errors}
                    optionsArrys={departmentData}
                    selectedText={'Department'}
                    stateData={this.state}
                    optionType="dynamicWithPropIdAndName"
                    property={'departmentName'}
                    propertyId={'departmentId'}
                    successflag={'successflag'}
                    isValidte={false}
                    useFlag={false}
                    newFlag='customWidth'
                />

                <SelectionInput
                    feildName={'postId'}
                    onHandleChange={e => this.onHandlePostChange(e)}
                    errors={errors}
                    optionsArrys={postData}
                    selectedText={'Post Name'}
                    stateData={this.state}
                    optionType="dynamicWithPropIdAndName"
                    property={'postName'}
                    propertyId={'postId'}
                    successflag={'successflag'}
                    isValidte={false}
                    useFlag={false}
                    newFlag='customWidth'
                />
                <SelectionInput
                    feildName={'id'}
                    onHandleChange={e => this.onHandleStaffChange(e)}
                    errors={errors}
                    optionsArrys={staffData}
                    selectedText={'Staff Name'}
                    stateData={this.state}
                    optionType="dynamicWithPropIdAndName"
                    property={'staffName'}
                    propertyId={'staffId'}
                    successflag={'successflag'}
                    isValidte={false}
                    useFlag={false}
                    newFlag='customWidth'
                />
                <Button
                    width="100%"
                    buttonClass="customButton"
                    btnName="Search"
                    loaderBmargin={5}
                    stateData={this.state}
                    marginTop={27}
                    onHandleSubmit={this.onSubmit}
                    margin={'0 auto'}
                    textColor='white'
                    btnBackColor= {context.themeColors.primaryColor}
                />

            </React.Fragment>
                                                )}
                                                </ThemeContext.Consumer>
        )

        const bodyRendering = data =>
            data &&
            data.map((item, index) =>
            <ThemeContext.Consumer>
            {(context) => (
                studentStaff === 'Student' ? (
                    (
                        <tr>
                            <th
                                style={{
                                    padding: 2,
                                    border: `1px solid ${context.themeColors.primaryColor}`,
                                    borderColor: context.themeColors.primaryColor
                                }}
                                scope="row"
                            >
                                {index + 1}
                            </th>
                            {renderFieldStudent.map((item1, index) => (
                                <td
                                    style={{
                                        fontWeight: `${index === 0 ? 'bold' : 'normal'}`,
                                        border: `1px solid ${context.themeColors.primaryColor}`,
                                        padding: 2,
                                        borderColor: context.themeColors.primaryColor
                                    }}
                                >
                                    {item[item1]}
                                </td>
                            )
                            )}
                            <td
                                style={{
                                    border: `1px solid ${context.themeColors.primaryColor}`,
                                    padding: 2,
                                    borderColor: context.themeColors.primaryColor
                                }}
                            >
                                <Link to={`/dashboard/MedicalSlipTemplate/${item.id}/${item.studentStaff}`}>
                                    <button
                                        id="firstbutton"
                                        // onClick={() => this.onEditClick(item.id)}
                                        onMouseOver={this.btnTechHover()}
                                        style={{
                                            cursor: 'pointer',
                                            fontSize: 20,
                                            border: 'none',
                                            marginLeft: 5,
                                            background: 'none',
                                            marginBottom: 5
                                        }}
                                    >
                                        <a href="#" data-toggle="tooltip" title="View!">
                                            <i style={{ color: '#01AC8A' }} class="fas fa-list"></i>
                                        </a>
                                    </button>
                                </Link>
                            </td>
                        </tr>
                    )
                ) : (<tr>
                    <th
                        style={{
                            padding: 2,
                            border: `1px solid ${context.themeColors.primaryColor}`,
                            borderColor: context.themeColors.primaryColor
                        }}
                        scope="row"
                    >
                        {index + 1}
                    </th>
                    {renderFieldStaff.map((item1, index) => (
                        <td
                            style={{
                                fontWeight: `${index === 0 ? 'bold' : 'normal'}`,
                                border: `1px solid ${context.themeColors.primaryColor}`,
                                padding: 2,
                                borderColor: context.themeColors.primaryColor
                            }}
                        >
                            {item[item1]}
                        </td>
                    )
                    )}
                    <td
                        style={{
                            border: `1px solid ${context.themeColors.primaryColor}`,
                            padding: 2,
                            borderColor: context.themeColors.primaryColor
                        }}
                    >
                        <Link to={`/dashboard/MedicalSlipTemplate/${item.id}/${item.studentStaff}`}>
                            <button
                                id="firstbutton"
                                // onClick={() => this.onEditClick(item.id)}
                                onMouseOver={this.btnTechHover()}
                                style={{
                                    cursor: 'pointer',
                                    fontSize: 20,
                                    border: 'none',
                                    marginLeft: 5,
                                    background: 'none',
                                    marginBottom: 5
                                }}
                            >
                                <a href="#" data-toggle="tooltip" title="View!">
                                    <i style={{ color: '#01AC8A' }} class="fas fa-list"></i>
                                </a>
                            </button>
                        </Link>
                    </td>
                </tr>
                    )
                    )}
                    </ThemeContext.Consumer>
            );
        return (
            <ThemeContext.Consumer>
            {(context) => (
            <div>
                <SnackBar msg={msg} />
                <div style={{ textAlign: 'right' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                            <h2 className="h1-responsive font-weight-bold text-left my-4 generalHead" style={{background: context.themeColors.primaryColor}}>
                                {' '}
                            Medical History{' '}
                            </h2>
                        </div>
                    </div>
                    <CustomFilterSectionLayout displayDirection="column">
                        {this.state.errorMsg ? (
                            <p style={{ color: 'red', textAlign: 'center' }}>
                                Please fill the form properly
                            </p>
                        ) : (
                                ''
                            )}
                        <div
                            style={{
                                width: '30%',
                                margin: '0 auto',
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >

                            <SelectionInput
                                selectName={'Patient Type'}
                                newFlag="customWidth"
                                feildName="studentStaff"
                                selectedText={'Student/Staff'}
                                optionsArrys={general.patientType}
                                errors={errors}
                                isValidte={false}
                                stateData={{
                                    studentStaff
                                }}
                                optionType="static"
                                onHandleChange={e => this.onHandleSelectChange(e)}
                                useFlag={false}
                            />
                        </div>

                        <div
                            style={{
                                width: '30%',
                                margin: '0 auto',
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >

                            {studentStaff === 'Student' ? (
                                Student
                            ) : studentStaff === 'Staff' ? (
                                Staff
                            ) : ''}
                        </div>

                    </CustomFilterSectionLayout>
                </div>
                <br />
                {isLoading ? (
                    <Spinner />
                ) :
                    medicalHistory.length < 1 ? <div style={{ display: 'flex', justifyContent: 'center', color: 'red' }}>
                        <strong>No Data</strong>
                    </div>
                        : (
                            <div className="table-responsive">
                                <table class="table table-hover">
                                    <thead style={{ background: context.themeColors.primaryColor, color: 'white' }}>
                                        <tr>
                                            <th scope="col">#</th>
                                            {studentStaff === 'Student' ? (tHeadStudent.map(item => (
                                                <th
                                                    style={{
                                                        border: `1px solid ${Colors.SEA_GREEN_THEME}`,
                                                        borderColor: Colors.WHITE
                                                    }}
                                                    scope="col"
                                                >
                                                    {item}
                                                </th>
                                            ))) : (tHeadStaff.map(item => (
                                                <th
                                                    style={{
                                                        border: `1px solid ${Colors.SEA_GREEN_THEME}`,
                                                        borderColor: Colors.WHITE
                                                    }}
                                                    scope="col"
                                                >
                                                    {item}
                                                </th>
                                            )))}
                                        </tr>
                                    </thead>
                                    <tbody> {bodyRendering(medicalHistory)}</tbody>
                                </table>
                            </div>
                        )
                }
            </div>
                                                )}
                                                </ThemeContext.Consumer>
        )
    }
}

function mapStateToProps(state) {
    return {
        role: state.setUserReducer.users.role,
        name: state.setUserReducer.users.unique_name,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        sharedActions: bindActionCreators(sharedActions, dispatch)
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MedicalHistory);