import React, { Component } from 'react';

class StaffCertificatePrint extends Component {
	state = {};
	render() {
		const { Document } = this.props;
		console.log(Document, 'Certificate Data');
		return (
			<div>
				<div id="div1">
					<section class="mb-4">
						<div class="container">
							<div class="row rowStaffCheck" style={{ marginTop: 10 }}>
								<div class="col colStaffCheck">
									<label style={{ fontWeight: 'bold' }}>Certificate: </label>{' '}
									&nbsp;&nbsp;
									{Document && Document.certificateName}
								</div>
								<div class="col colStaffCheck">
									{' '}
									<label style={{ fontWeight: 'bold' }}>
										Organization:
									</label>
									&nbsp;&nbsp; {Document && Document.certificateOrg}
								</div>
							</div>
							<div class="row rowStaffCheck">
								<div class="col colStaffCheck">
									{' '}
									<label style={{ fontWeight: 'bold' }}>
										Duration:{' '}
									</label>
									&nbsp;&nbsp; {Document && Document.certificateDuration}
								</div>
								<div class="col colStaffCheck">
									<label style={{ fontWeight: 'bold' }}>
										Place:
									</label>
									&nbsp;&nbsp; {Document && Document.certificatePlace}
								</div>
								<div class="col colStaffCheck">
									<label style={{ fontWeight: 'bold' }}>
										Year:
									</label>
									&nbsp;&nbsp; {Document && Document.certificateYear}{' '}
								</div>
							</div>
						</div>
					</section>
				</div>
			</div>
		);
	}
}

export default StaffCertificatePrint;
