import React, { Component } from "react";
import DateandTime from "./../Transport/DateandTime";
import RenderDynamicSiblingsRow from "./RenderDynamicSiblingsRow";
import $ from "jquery";
import ThemeContext from "../../context/themeContext/ThemeContext";

export default class PreviousSchoolDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gridNum: 4,
    };
  }

  btnTechHover = () => {
    $(document).ready(function () {
      $("#addBtn").hover(
        function () {
          $(this).css(
            "background",
            "linear-gradient(to bottom left, #01425e 5%, #01ac8a 87%)"
          );
          $(this).css("border-color", "white");
        },
        function () {
          $(this).css("background", "#0A4F5E");
        }
      );
    });
  };

  render() {
    const {
      onHandleTextChange,
      stateData,
      updateSpecialArrObjOnChange,
      onClickAddItem,
      onClickDeleteItem,
    } = this.props;

    return (
      <div>
        {/*<div class="row">*/}
        {/*<div class="col-md-12">*/}
        {/*<div class="md-form mb-0">*/}
        {/*<DateandTime/>*/}
        {/*</div>*/}
        {/*</div>*/}
        {/*</div>*/}
        <div style={{ width: "100%" }}>
          <table class="table table-hover">
            <ThemeContext.Consumer>
              {(context) => (
                <thead
                  style={{
                    background: context.themeColors.primaryColor,
                    color: "white",
                  }}
                >
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Previous School Name</th>
                    <th scope="col">Enroll No</th>
                    <th scope="col">From Year</th>
                    <th scope="col">Year Upto</th>
                    <th scope="col">Reason Leaving</th>
                  </tr>
                </thead>
              )}
            </ThemeContext.Consumer>
            <tbody>
              {stateData &&
                stateData.previousSchoolDetails.map((item, index) => (
                  <tr key={index}>
                    <th scope={"row"}>{index + 1}</th>
                    <RenderDynamicSiblingsRow
                      arrName={"previousSchoolDetails"}
                      updateSpecialArrObjOnChange={updateSpecialArrObjOnChange}
                      rowIndex={index}
                      onHandleTextChange={onHandleTextChange}
                      stateData={stateData}
                      itemObj={item}
                    />
                  </tr>
                ))}
            </tbody>
            <div className={"row"} style={{ justifyContent: "flex-end" }}>
              <div>
                <div>
                  <button
                    id={"addBtn"}
                    onMouseEnter={this.btnTechHover()}
                    onClick={() =>
                      onClickAddItem("previousSchoolDetails", {
                        previousSchoolName: "",
                        enrollNo: "",
                        fromYear: "",
                        yearUpto: "",
                        reasonLeaving: "",
                      })
                    }
                    style={{
                      background: "#0A4F5E",
                      color: "#FFFFFF",
                      borderRadius: 100,
                    }}
                    type="button"
                    class="btn"
                  >
                    <i class="fas fa-plus"></i>
                  </button>
                </div>
                <div style={{ marginTop: 5 }}>
                  <button
                    onClick={() => onClickDeleteItem("previousSchoolDetails")}
                    style={{ color: "#FFFFFF", borderRadius: 100 }}
                    type="button"
                    class="btn btn-danger"
                  >
                    <i class="far fa-trash-alt"></i>
                  </button>
                </div>
              </div>
            </div>
          </table>
        </div>
        <br />
        <br />
        <br />
        {/*<div class="text-center text-md-center">*/}
        {/*<button class="btns">StepperGeneralButtons</button>*/}
        {/*</div>*/}
      </div>
    );
  }
}
// import React , { Component } from 'react';
// import DateandTime from './../Transport/DateandTime';
//
// export default class PreviousSchoolDetails extends Component {
//     constructor(props){
//         super(props)
//         this.state={
//             gridNum:4
//         }
//     }
//     render(){
//         return(
//             <div>
//                 <section class="mb-4">
//                     <h2 class="h1-responsive font-weight-bold text-left my-4">
//                         Previous School Details
//                     </h2>
//                     <label htmlFor="regForm">
//                         <i class="fas fa-id-card-alt" style={{marginRight:4}} ></i>
//                         Previous School Details Id:
//                     </label>
//                     <div
//                         class="row"
//                         style={{
//                             justifyContent: "left",
//                             textAlign: "left"
//                         }}
//                     >
//                         <div class="col-md-9 mb-md-0 mb-5">
//                             <form
//                                 id="contact-form"
//                                 name="contact-form"
//                                 // onSubmit={this.onHandleSubmit}
//                                 noValidate
//                             >
//                                 {/*<div class="row">*/}
//                                 {/*<div class="col-md-12">*/}
//                                 {/*<div class="md-form mb-0">*/}
//                                 <DateandTime/>
//                                 {/*</div>*/}
//                                 {/*</div>*/}
//                                 {/*</div>*/}
//                                 <br />
//                                 <div class="row">
//                                     <div class="col-md-6">
//                                         <div class="md-form mb-0">
//                                             <label for="name" class="">
//                                                 {/*<i*/}
//                                                 {/*className="fas fa-user-shield animated fadeIn"*/}
//                                                 {/*style={{ marginRight: 4 }}*/}
//                                                 {/*/>*/}
//                                                 Student Id:
//                                             </label>
//                                             <input style={{borderTop:0,borderLeft:0,borderRight:0,boxShadow:'none',width:'80%'}}
//                                                    type="text" class="form-control" id="exampleInputEmail1" placeholder="Student Id" />
//                                         </div>
//                                     </div>
//                                     <div class="col-md-6">
//                                         <div class="md-form mb-0">
//                                             <label for="name" class="">
//                                                 {/*<i*/}
//                                                 {/*className="fas fa-user-shield animated fadeIn"*/}
//                                                 {/*style={{ marginRight: 4 }}*/}
//                                                 {/*/>*/}
//                                                 Previous School Name
//                                             </label>
//                                             <input style={{borderTop:0,borderLeft:0,borderRight:0,boxShadow:'none',width:'80%'}}
//                                                    type="email" class="form-control" id="exampleInputEmail1" placeholder="Previous School Name" />
//                                         </div>
//                                     </div>
//                                     <div class="col-md-6">
//                                         <div class="md-form mb-0">
//                                             <label for="name" class="">
//                                                 <i
//                                                     className="fas fa-house animated fadeIn"
//                                                     style={{ marginRight: 4 }}
//                                                 />
//                                                 Enroll No
//                                             </label>
//                                             <input style={{borderTop:0,borderLeft:0,borderRight:0,boxShadow:'none',width:'80%'}}
//                                                    type="email" class="form-control" id="exampleInputEmail1" placeholder="Enroll No" />
//
//                                         </div>
//                                     </div>
//                                     <div class="col-md-6">
//                                         <div class="md-form mb-0">
//                                             <label for="name" class="">
//                                                 <i
//                                                     className="fas fa-house animated fadeIn"
//                                                     style={{ marginRight: 4 }}
//                                                 />
//                                                 From Year
//                                             </label>
//                                             <input style={{borderTop:0,borderLeft:0,borderRight:0,boxShadow:'none',width:'80%'}}
//                                                    type="email" class="form-control" id="exampleInputEmail1" placeholder="From Year" />
//
//                                         </div>
//                                     </div>
//                                     <div class="col-md-6">
//                                         <div class="md-form mb-0">
//                                             <label for="name" class="">
//                                                 <i
//                                                     className="fas fa-house animated fadeIn"
//                                                     style={{ marginRight: 4 }}
//                                                 />
//                                                 Year Upto
//                                             </label>
//                                             <input style={{borderTop:0,borderLeft:0,borderRight:0,boxShadow:'none',width:'80%'}}
//                                                    type="email" class="form-control" id="exampleInputEmail1" placeholder="Year Upto" />
//                                         </div>
//                                     </div>
//                                     <div class="col-md-6">
//                                         <div class="md-form mb-0">
//                                             <label for="name" class="">
//                                                 <i
//                                                     className="fas fa-house animated fadeIn"
//                                                     style={{ marginRight: 4 }}
//                                                 />
//                                                 Reason Leaving
//                                             </label>
//                                             <input style={{borderTop:0,borderLeft:0,borderRight:0,boxShadow:'none',width:'80%'}}
//                                                    type="email" class="form-control" id="exampleInputEmail1" placeholder="Reason Leaving" />
//                                         </div>
//                                     </div>
//                                 </div>
//
//                                 <br />
//                                 <br />
//                                 {/*<div class="text-center text-md-center">*/}
//                                 {/*<button class="btns">StepperGeneralButtons</button>*/}
//                                 {/*</div>*/}
//                             </form>
//                             <div class="status" />
//                         </div>
//                     </div>
//                 </section>
//
//             </div>
//         )
//     }
// }
