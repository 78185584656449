import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import $ from 'jquery';
import isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom'
import isNull from 'lodash/isNull'

import ThemeContext from "../../context/themeContext/ThemeContext";

const ApiList = [
    'Late Reporting Monthly',
    'Late Reporting Monthly By Student',
    'Late Consecutive Reporting Monthly',
    'Late Consecutive Reporting Monthly By Student',
    'Absent Reporting Monthly',
    'Absent Reporting Monthly By Student',
    'Absent Consecutive Reporting Monthly',
    'Absent Consecutive Reporting Monthly By Student',
]


class ViewFlagManagement extends Component {
    constructor(props){
        super(props);
        this.state = {
            isLoading: true,
            modalEdit: false,
            editToggle: false,
            generateToggle: false,
            generateIndex: '',
            msg: '',
            editIndex: '',

            flagType: null,
            studentId: null,
            classId: null,
            sectionId: null,
            subjectId: null,
            month: null,
            year: null,
            flagSelect: false,
			accountId: this.props.userData.unique_name,
			accountType: this.props.userData.role

        }

        


    }

    componentDidMount(){
        this.getSimple('Student', 'student')
        this.getSimple('classSectionGradeTeacher', 'classSectionGrade')

        this.generateMonthAndYear(30);
	}

	generateMonthAndYear = limit => {
		const year = new Date().getFullYear();
		const years = Array.from(new Array(limit), (val, index) => index + year);
		this.setState({
			yearList: years,
			monthList: [
				'January',
				'February',
				'March',
				'April',
				'May',
				'June',
				'July',
				'August',
				'September',
				'October',
				'November',
				'December'
			]
		});
	};

    getSimple = (type, state) => {
        this.props.sharedActions.getDataWithoutDispatch(
            `/api/${type}`
        ).then(success => { 
            this.setState({
                [state]: success,
                isLoading: false
            })


        }).catch(error => {
            this.setState({
                msg: 'Error Occured!!',
                errorMsg: true,
                isLoading: false
            })
            console.error('Error occured', error);
        })
    }


    checkBook = (studentId) => {
        const { student } = this.state;
        let f = student.student.filter((data) => {
            return data.studentId == studentId
        })

        if(f.length > 0 ){
            this.setState({
                classId: f[0].classId,
                sectionId: f[0].sectionId,
                msg: 'Student Found!!',
            })
            this.props.snackbar();
        }else{
            this.setState({
                msg: 'Student Not Found!!',
            })
            this.props.snackbar();
        }
        

    }
    


    PrintContent = el => {
        var restorepage = document.body.innerHTML;
        var Printcontent = document.getElementById(el).innerHTML;
        document.body.innerHTML = Printcontent;
        
        setTimeout(() => {
            window.print();
            window.location.reload();
        }, 700)
        document.body.innerHTML = restorepage;
        window.reload();
        
    };



    myFunction = () => {
		// Get the snackbar DIV
		var x = document.getElementById('snackbar');

		// StepperGeneralButtons the "show" class to DIV
		x.className = 'show';

		// After 3 seconds, remove the show class from DIV
		setTimeout(function() {
		}, 3000);
	};


        handleSearch = () => {
            const { classId, sectionId, subjectId, studentId, month, year, flagType } = this.state
            console.log(classId, sectionId, subjectId, month, year, flagType );

            if(![ApiList[1], ApiList[3], ApiList[5], ApiList[7]].includes(flagType)){
                
                if( isNull(this.state.classId) || isNull(subjectId) || isNull(sectionId) || isNull(month)|| isNull(year) ){


                    this.setState({
                        msg: "Please Fill The Form Repectively!",
                    })
                    this.props.snackbar()
                }else{
                   
                    switch (flagType) {
                        case ApiList[0]:
                            this.getSimple(`LateReportingMonthly/?classId=${classId}&sectionId=${sectionId}&subjectId=${subjectId}&month=${month}&year=${year}`, 'report')
                            break;
                        case ApiList[2]:
                            this.getSimple(`LateConsecutiveReportMonthly/?classId=${classId}&sectionId=${sectionId}&subjectId=${subjectId}&month=${month}&year=${year}`, 'report')
                            break;
                        case ApiList[4]:
                            this.getSimple(`AbsentReportingMonthly/?classId=${classId}&sectionId=${sectionId}&subjectId=${subjectId}&month=${month}&year=${year}`, 'report')
                            break;
                        case ApiList[6]:
                            this.getSimple(`AbsentConsecutiveReportMonthly/?classId=${classId}&sectionId=${sectionId}&subjectId=${subjectId}&month=${month}&year=${year}`, 'report')
                            break;
                            default:
                            break;
                    }
                    
                }    
            }else{
                if( isNull(this.state.classId) || isNull(subjectId) || isNull(sectionId) || isNull(studentId) || isNull(month) || isNull(year) ){
                    
                    this.setState({
                        msg: "Please Fill The Form Repectively!",
                    })
                    this.props.snackbar()
                }else{
                   
                    switch (flagType) {
                        case ApiList[1]:
                            this.getSimple(`LateReportingMonthly/student/?classId=${classId}&sectionId=${sectionId}&subjectId=${subjectId}&studentId=${studentId}&month=${month}&year=${year}`, 'report')
                            break;
                        case ApiList[3]:
                            this.getSimple(`LateConsecutiveReportMonthly/student/?classId=${classId}&sectionId=${sectionId}&subjectId=${subjectId}&studentId=${studentId}&month=${month}&year=${year}`, 'report')
                            break;
                        case ApiList[5]:
                            this.getSimple(`AbsentReportingMonthly/student/?classId=${classId}&sectionId=${sectionId}&subjectId=${subjectId}&studentId=${studentId}&month=${month}&year=${year}`, 'report')
                            break;
                        case ApiList[7]:
                            this.getSimple(`AbsentConsecutiveReportMonthly/student/?classId=${classId}&sectionId=${sectionId}&subjectId=${subjectId}&studentId=${studentId}&month=${month}&year=${year}`, 'report')
                            break;
                            default:
                            break;
                    }

                    
                }
            }
          
        }

    btnTechHover = () => {
		$(document).ready(function() {
			$('button').hover(
				function() {
					$(this).css('box-shadow', '0px 5px 5px grey');
					$(this).css('border-color', 'white');
				},
				function() {
					$(this).css('box-shadow', '0px 0px 0px white');
				}
			);
		});
    };
    
    onHandleCheckChange = e => {
        this.setState({
            [e.target.name]: e.target.checked,
        })
            
        console.log([e.target.name], e.target.checked);
    }

    onHandleFlag = e => {
            this.setState({
                [e.target.name]: e.target.value,
                studentId: "",
                classId: "",
                sectionId: "",
                subjectId: "",
                month: "",
                year: "",
                report: ""
            })
    }

    onHandleText = e => {
		this.setState({
			[e.target.name]: e.target.value
        });
        console.log([e.target.name], e.target.value)
    };
    


    render(){

        const { search, isLoading, editToggle, msg, accountId, accountType, classSectionGrade, report, student, yearList, monthList,
            flagType, flagSelect, classId, sectionId, subjectId, studentId, month, year,
        } = this.state;



        const Loader = (
            <ThemeContext.Consumer>
            {(context) => (
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<div className="spinner-border" style={{ color: context.themeColors.primaryColor }}></div>
			</div>
                                                                                                                                    )}
                                                                                                                                    </ThemeContext.Consumer>
        );

        const renderByClass = 
        <ThemeContext.Consumer>
        {(context) => (
        <div class="row my-3 mx-5 py-4" style={{
            border: `2px solid ${context.themeColors.primaryColor}`,
            borderRadius: 20
        }} >
            <div class="col-md-4" style={{marginBottom: '1%'}} >
            <select class="custom-select" 
                                value={classId}
                                name="classId"
                                onChange={this.onHandleText}
                                disabled={!flagType}
                            >
                                        <option value="">Please Select Class</option>
                                       {classSectionGrade && classSectionGrade.classes.map((value, index) => {
                                           
                                           return <option value={value.classId}>{value.className}</option>
                                       })}
                            </select>
            </div>
            <div class="col-md-4" >
            <select class="custom-select" 
                                value={sectionId}
                                name="sectionId"
                                onChange={this.onHandleText}
                                disabled={!classId}

                            >
                                        <option value="">Please Select Section</option>
                                       {classSectionGrade && classSectionGrade.sections.map((value, index) => {


                                                return value.classId == classId && 
                                                <option value={value.sectionId}>{value.section}</option>

                                       })}
                            </select>
            </div>
            <div class="col-md-4" >
            <select class="custom-select" 
                                value={subjectId}
                                name="subjectId"
                                onChange={this.onHandleText}
                                disabled={!sectionId}

                            >
                                        <option value="">Please Select Subject</option>
                                       {classSectionGrade && classSectionGrade.subject.map((value, index) => {

                                                return value.classId == classId &&
                                                <option value={value.subjectId}>{value.subjectName}</option>

                                       })}
                            </select>
            </div>
            <div class="col-md-4" >
            <select class="custom-select" 
                                value={month}
                                name="month"
                                onChange={this.onHandleText}
                                disabled={!subjectId}

                            >
                                        <option value="">Please Select Month</option>
                                       {monthList && monthList.map((value, index) => {

                                                return <option value={value}>{value}</option>

                                       })}
                            </select>
            </div>
            <div class="col-md-4" >
            <select class="custom-select" 
                                value={year}
                                name="year"
                                onChange={this.onHandleText}
                                disabled={!month}

                            >
                                        <option value="">Please Select Year</option>
                                       {yearList && yearList.map((value, index) => {

                                                return <option value={value}>{value}</option>

                                       })}
                            </select>
            </div>
            
            

        </div>
                                                                                                                                )}
                                                                                                                                </ThemeContext.Consumer>

        const renderByStudent = 
        <ThemeContext.Consumer>
        {(context) => (
        <div class="row my-3 mx-5 py-4" style={{
            border: `2px solid ${context.themeColors.primaryColor}`,
            borderRadius: 20
        }} >
            <div className="col-md-3">
                        
                        <div className="input-group md-form mb-3" >
                            
                            <input class="form-control" 
                                type='text'
                                value={studentId}
                                name="studentId"
                                placeholder="Student ID"
                                onChange={this.onHandleText}
                                disabled={![ApiList[1], ApiList[3], ApiList[5], ApiList[7]].includes(flagType) || !student && !classSectionGrade}
                            />
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary" 
                                disabled={![ApiList[1], ApiList[3], ApiList[5], ApiList[7]].includes(flagType) || !student && !classSectionGrade}
                                onClick={() => this.checkBook(studentId)}
                                type="button">
                                    <i class="fas fa-search" ></i>
                                    </button>
                            </div>
                        </div>
                        </div>
            <div class="col-md-3" >
            <select class="custom-select" 
                                value={classId}
                                name="classId"
                                onChange={this.onHandleText}
                                disabled={!flagType}
                            >
                                        <option value="">Please Select Class</option>
                                       {classSectionGrade && classSectionGrade.classes.map((value, index) => {
                                           
                                           return <option value={value.classId}>{value.className}</option>
                                       })}
                            </select>
            </div>
            <div class="col-md-3" >
            <select class="custom-select" 
                                value={sectionId}
                                name="sectionId"
                                onChange={this.onHandleText}
                                disabled={!classId}

                            >
                                        <option value="">Please Select Section</option>
                                       {classSectionGrade && classSectionGrade.sections.map((value, index) => {


                                                return value.classId == classId && 
                                                <option value={value.sectionId}>{value.section}</option>

                                       })}
                            </select>
            </div>
            <div class="col-md-3" >
            <select class="custom-select" 
                                value={studentId}
                                name="studentId"
                                onChange={this.onHandleText}
                                disabled={!sectionId}

                            >
                                        <option value="">Please Select Student</option>
                                        {student && student.student.map((value, index) => {
                                           
                                           return  value.classId == classId && value.sectionId == sectionId &&
                                           <option value={value.studentId}>{value.Student}</option>
                                       })}
                            </select>
            </div>
            <div class="col-md-4" >
            <select class="custom-select" 
                                value={subjectId}
                                name="subjectId"
                                onChange={this.onHandleText}
                                disabled={!studentId || !sectionId} 

                            >
                                        <option value="">Please Select Subject</option>
                                       {classSectionGrade && classSectionGrade.subject.map((value, index) => {

                                                return value.classId == classId &&
                                                <option value={value.subjectId}>{value.subjectName}</option>

                                       })}
                            </select>
            </div>
            <div class="col-md-4" >
            <select class="custom-select" 
                                value={month}
                                name="month"
                                onChange={this.onHandleText}
                                disabled={!subjectId}

                            >
                                        <option value="">Please Select Month</option>
                                       {monthList && monthList.map((value, index) => {

                                                return <option value={value}>{value}</option>

                                       })}
                            </select>
            </div>
            <div class="col-md-4" >
            <select class="custom-select" 
                                value={year}
                                name="year"
                                onChange={this.onHandleText}
                                disabled={!month}

                            >
                                        <option value="">Please Select Year</option>
                                       {yearList && yearList.map((value, index) => {

                                                return <option value={value}>{value}</option>

                                       })}
                            </select>
            </div>
            

        </div>
                                                                                                                                )}
                                                                                                                                </ThemeContext.Consumer>
        const renderReportData = ![ApiList[1], ApiList[3], ApiList[5], ApiList[7]].includes(flagType) ? report && report.studentList.map((item, index) => {
            return (

            <tr key={index} >
                <th scope="row" style={{ padding: 1 }} >
                    {index + 1}
                </th>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.studentName}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {accountId}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {accountType}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    <i class="fa fa-flag-o" style={{fontSize: 24, color: isEmpty(item.flagColor) ? 'Green' : item.flagColor }} ></i>
                </td>
            </tr>
            
            );
        }) :  report && 
            <tr key={1} >
                <th scope="row" style={{ padding: 1 }} >
                    {1}
                </th>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {report.studentName}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {accountId}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {accountType}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                <i class="fa fa-flag-o" style={{fontSize: 24, color: isEmpty(report.flagColor) ? 'Green' : report.flagColor }} ></i>

                </td>
            </tr>

        return (
            <ThemeContext.Consumer>
            {(context) => (
            <div className="page-header"> 
                <SnackBar msg={msg} />
                <div style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                }}>
                    <div class="row">
                        
                        <div class="col-md-6" >
                            <h2  
                        style={{ background: context.themeColors.primaryColor }}
                        className="h1-responsive font-weight-bold text-center  generalHead">
                        Flag Management Attendance
                    </h2>
                        </div>
                        </div>
                        <div class="row justify-content-center align-items-center my-3">

                        <div class="col-md-9" >
                        <select class="custom-select" 
                                                value={flagType}
                                                name="flagType"
                                                onChange={this.onHandleFlag}
                                            >
                                                        <option value="">Please Select Flag Type</option>
                                                        {flagSelect == true && [ApiList[1], ApiList[3], ApiList[5], ApiList[7]].map((value, index) => {
                                                            return <option value={value}>{value}</option>
                                                        })}
                                                        {flagSelect == false && [ApiList[0], ApiList[2], ApiList[4], ApiList[6]].map((value, index) => {
                                                            return <option value={value}>{value}</option>
                                                        })} 
                                            </select>
                         
                                                    </div>
                        <div className="my-2 custom-control custom-checkbox" >
                                    <input 
                                        type="checkBox" 
                                        class="custom-control-input" 
                                        id='flagSelect' 
                                        name='flagSelect'
                                        defaultChecked={false}
                                        onChange={this.onHandleCheckChange}
                                        />
                                        <label class="custom-control-label" for="flagSelect">By Student</label>  
                                </div>
                        </div>
                        {[ApiList[1], ApiList[3], ApiList[5], ApiList[7]].includes(flagType) && renderByStudent}
                        {![ApiList[1], ApiList[3], ApiList[5], ApiList[7]].includes(flagType) && renderByClass}
                        <div class="row my-2">
                            <div class="col-md-12  text-center" >
                                            <button
                                            class="btn btn-primary buttonAppear"
                                            onClick={this.handleSearch}
                                            style={{marginRight: '2%', background: context.themeColors.primaryColor}}
                                            >
                            <i class="fa fa-search"></i> Search
                                        </button>
                                        <Link to={{
                            pathname: "PrintFlagManagement",
                            state: {
                                report,
                                flagType,
                                ApiList,
                                month, year
                            }
                        }}  >
                            <a href="#" data-toggle="tooltip" title="Edit!">
                                
                            <button
                                            class="btn btn-primary buttonAppear"
                                            disabled={!report}
                                            style={{background: context.themeColors.primaryColor}}
                                            >
                            
                                    <i className="fas fa-list"></i> View
                                </button>
                            </a> 
                            
                        </Link>
                            </div>
                            
                        </div>
                        {[ApiList[1], ApiList[3], ApiList[5], ApiList[7]].includes(flagType) ?
                        <div class="row my-3 mx-2" style={{paddingLeft: '20px'}} >
                           <div class="col-md-4" >
                                    <span>Student ID: <strong style={{color: context.themeColors.primaryColor}} >{report && report.studentId}</strong></span>     
                            </div>
                            <div class="col-md-4" >
                                    <span>Class: <strong style={{color: context.themeColors.primaryColor}} >{report && report.className}</strong></span>     
                            </div>
                            <div class="col-md-4" >
                                    <span>Section: <strong style={{color: context.themeColors.primaryColor}} >{report && report.section}</strong></span>     
                            </div>
                            <div class="col-md-4" >
                                    <span>Subject: <strong style={{color: context.themeColors.primaryColor}} >{report && report.subjectName}</strong></span>     
                            </div>
                            <div class="col-md-4" >
                                    <span>Month: <strong style={{color: context.themeColors.primaryColor}} >{report && month}</strong></span>     
                            </div>
                            <div class="col-md-4" >
                                    <span>Year: <strong style={{color: context.themeColors.primaryColor}} >{report && year}</strong></span>     
                            </div>
                        </div> : <div class="row my-3 mx-2" style={{paddingLeft: '20px'}} >
                            <div class="col-md-4" >
                                    <span>Class: <strong style={{color: context.themeColors.primaryColor}} >{report && report.className}</strong></span>     
                            </div>
                            <div class="col-md-4" >
                                    <span>Section: <strong style={{color: context.themeColors.primaryColor}} >{report && report.section}</strong></span>     
                            </div>
                            <div class="col-md-4" >
                                    <span>Subject: <strong style={{color: context.themeColors.primaryColor}} >{report && report.subjectName}</strong></span>     
                            </div>
                            <div class="col-md-4" >
                                    <span>Month: <strong style={{color: context.themeColors.primaryColor}} >{report && month}</strong></span>     
                            </div>
                            <div class="col-md-4" >
                                    <span>Year: <strong style={{color: context.themeColors.primaryColor}} >{report && year}</strong></span>     
                            </div>
                        </div>}
                    <div className="table-responsive">
					<table className="table table-hover text-center">
						<thead style={{ background: context.themeColors.primaryColor, color: 'white' }}>
							<tr>
								<th scope="col" style={{ padding: 1 }}>
									#
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Student Name
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Account Name
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Account Role
								</th>
								<th scope="col" style={{ padding: 1 }}>
                                    Flag
								</th>
							</tr>
						</thead>
						<tbody>{isLoading ? Loader : renderReportData}</tbody>
					</table>
				</div>
                </div>
            </div>
                                                                                                                                    )}
                                                                                                                                    </ThemeContext.Consumer>
        );
    }

}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch),
	};
}
function mapStateToProps(state) {
	return {
		userData: state.setUserReducer.users,
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps  
)(ViewFlagManagement);
