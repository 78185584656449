import React, { useState, useContext, useEffect } from "react";
import { Types } from "../../hooks/HookReducers/StaffReducer";
import RenderDynamicSiblingsRow from "../../Container/Admission/RenderDynamicSiblingsRow";
import Button from "../../components/Button";
import CustomHeading from "../../components/CustomHeading";
import themeContext from "../../context/themeContext/ThemeContext";
import { MAIN_HEADING } from "../../utils/general";
import {
  editRecordWithoutDispatchAndThunk,
  getDataWithoutDispatch,
  getDataWithoutDispatchthunk,
  simpleAddRequestWithoutThunk,
  editRecordWithoutThunk,
} from "../../Actions/sharedActions";
import Spinner from "../../components/Spinner/Spinner";
import { General } from "../../utils";
import { useSelector } from "react-redux";
import SnackBar from "../../components/SnackBar/SnackBar";

const AddFeeTypeNew = (props) => {
  const [isEditLoading, setIsEditLoading] = useState(false);
  const { edit, filteredAddFeeType, classId } = props;
  const user = useSelector((state) => state.setUserReducer.users);
  const themeColor = useContext(themeContext);
  const [errors, setErrors] = useState({});
  const [msg, setMsg] = useState("");
  debugger;
  const [data, setData] = useState({
    classId: "",
  });
  const [updateStatus, setUpdateStatus] = useState("");

  const [postLoading, setPostLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [classSectionData, setClassSectionData] = useState([]);
  const [feeStructures, setQualification] = useState(
    edit
      ? filteredAddFeeType
      : [
          {
            feeName: "",
            feeAmount: "",
            feeCode: "",
          },
        ]
  );
  const dynamicFieldValidate = (array) => {
    let isValid = array.map((item, index) => {
      console.log(General.IsObjectEmpty(item));
      debugger;
      if (!General.IsObjectEmpty(item)) {
        debugger;
        return false;
      } else {
        debugger;
        return true;
      }
    });
    console.log(isValid);

    let getValue = false;
    if (isValid.includes(false)) {
      getValue = false;
    } else {
      getValue = true;
    }
    return getValue;
  };
  const getValidatedArray = (e, arr, createErrorField) => {
    let getCloneErrors = { ...errors };
    if (!dynamicFieldValidate(arr)) {
      getCloneErrors[createErrorField] = true;
      setErrors(getCloneErrors);
      debugger;
      return true;
    } else {
      getCloneErrors[createErrorField] = false;
      setErrors(getCloneErrors);
      debugger;
      return false;

      // handleScroll(e);
    }
  };
  useEffect(() => {
    getDataWithoutDispatchthunk("/api/classSection")
      .then((success) => {
        setIsLoading(false);
        setClassSectionData(success);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  }, []);
  const onSelectChange = (e) => {
    let getData = { ...data };
    getData[e.target.name] = e.target.value;
    debugger;
    setData(getData);
  };
  const updateSpecialArrObjOnChange = (e, index, arrName) => {
    const changeArrOfObj = [...feeStructures];
    changeArrOfObj[index][e.target.name] = e.target.value;
    setQualification(changeArrOfObj);
  };

  const onClickAddItem = (arr, obj) => {
    let getStateArr = [...arr];
    getStateArr = [...getStateArr, obj];
    console.log(getStateArr);
    setQualification(getStateArr);
  };

  const onClickDeleteItem = (arr) => {
    let getStateArr = [...arr];
    let getDeleteLength = getStateArr.length - 1;

    if (getDeleteLength > 0) {
      let getNewDetails = getStateArr.filter(
        (item, index) => index !== getDeleteLength
      );
      console.log(getNewDetails);
      setQualification(getNewDetails);
    }
  };
  const onHandleEditSubmit = (e) => {
    console.log("edit click done");
    console.log(feeStructures);
    setIsEditLoading(true);
    let getObjectWithoutId = feeStructures.map((item) =>
      typeof item.id === "undefined"
        ? { ...item, accountId: user.unique_name, accountType: user.role }
        : { ...item }
    );
    editRecordWithoutThunk(`/api/StudentFeeStructure/`, classId, {
      classId,
      feeStructures: [...getObjectWithoutId],
    })
      .then((success) => {
        setUpdateStatus("update");
        setIsEditLoading(false);
        debugger;
        // setMsg("success");
        // props.snackbar();
      })
      .catch((error) => {
        setIsEditLoading(false);
        debugger;
        console.log(error);
      });
    debugger;
    debugger;
  };
  const onHandleSubmit = (e) => {
    console.log(feeStructures);
    setPostLoading(true);
    const { classId } = data;
    if (!getValidatedArray(e, feeStructures, "feeStructures")) {
      let updatedData = feeStructures.map((item) => {
        return { ...item, accountType: user.role, accountId: user.unique_name };
      });
      debugger;
      simpleAddRequestWithoutThunk("/api/StudentFeeStructure", {
        classId,
        feeStructures: updatedData,
      })
        .then((success) => {
          setPostLoading(false);

          setQualification([
            {
              feeName: "",
              feeAmount: "",
              feeCode: "",
            },
          ]);

          setData({ classId: "" });
          props.snackbar();
          setMsg("Successfully submit");
        })
        .catch((error) => {
          setMsg(error.response.data.Message);
          props.snackbar();
          setPostLoading(false);
          debugger;
        });
    } else {
      setMsg(" Please fill the form properly!");
      props.snackbar();
      setPostLoading(false);
    }
  };
  return (
    <div>
      {updateStatus == "update" && (
        <p style={{ fontSize: 30 }} className="text-success text-center">
          Update Successfully
        </p>
      )}
      <SnackBar msg={msg} />
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {" "}
          {!edit && (
            <CustomHeading headingText={"ADD FEE TYPE"} type={MAIN_HEADING} />
          )}
          <div
            className="note"
            style={{
              border: `4px solid ${themeColor.themeColors.primaryColor}`,
              padding: "10px",
            }}
          >
            {General.renderingType.map((item, index) => (
              <div>
                <div className="name">{item.name}</div>
                <div className="text-center code">{item.code}</div>
              </div>
            ))}
          </div>
          {!edit && (
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-md-8">
                <div class="md-form mb-0">
                  <select
                    onChange={onSelectChange}
                    class="custom-select"
                    name="classId"
                    value={data.classId}
                  >
                    <option>Choose Class...</option>
                    {classSectionData &&
                      classSectionData["classList"] &&
                      classSectionData["classList"].map((item, index) => (
                        <option key={index} value={`${item.id}`}>
                          {item.className}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </div>
          )}
          <br />
          <br />
          <div style={{ width: "100%" }}>
            <table class="table table-hover">
              <thead
                style={{
                  background: themeColor.themeColors.primaryColor,
                  color: "white",
                }}
              >
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Fee Name</th>
                  <th scope="col">Fee Amount</th>
                  <th scope="col">Fee Code</th>
                </tr>
              </thead>
              <tbody>
                {feeStructures.map((item, index) => (
                  <tr key={index}>
                    <th scope={"row"}>{index + 1}</th>
                    <RenderDynamicSiblingsRow
                      arrName={"feeStructures"}
                      rowIndex={index}
                      stateData={{ feeStructures: [...feeStructures] }}
                      updateSpecialArrObjOnChange={updateSpecialArrObjOnChange}
                      hookReuse={false}
                      itemObj={item}
                    />
                  </tr>
                ))}
              </tbody>
              <div className={"row"} style={{ justifyContent: "flex-end" }}>
                <div>
                  <div>
                    <button
                      onClick={() =>
                        onClickAddItem(feeStructures, {
                          feeName: "",
                          feeAmount: "",
                          feeCode: "",
                        })
                      }
                      style={{
                        background: "#0A4F5E",
                        color: "#FFFFFF",
                        borderRadius: 100,
                      }}
                      type="button"
                      class="btn"
                    >
                      <i class="fas fa-plus"></i>
                    </button>
                  </div>
                  <div>
                    <button
                      onClick={() => onClickDeleteItem(feeStructures)}
                      style={{ color: "#FFFFFF", borderRadius: 100 }}
                      type="button"
                      class="btn btn-danger"
                    >
                      <i class="far fa-trash-alt"></i>
                    </button>
                  </div>
                </div>
              </div>
            </table>
          </div>
          <br />
          <br />
          <br />
          <div
            style={{
              marginTop: 10,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <div>
              {edit ? (
                <button
                  class="btns"
                  style={{
                    marginRight: 25,
                    background: themeColor.themeColors.primaryColor,
                  }}
                  onClick={(e) => onHandleEditSubmit(e)}
                >
                  {isEditLoading ? <Spinner color="#fff" /> : "Update"}
                </button>
              ) : (
                <button
                  class="btns"
                  style={{
                    marginRight: 25,
                    background: themeColor.themeColors.primaryColor,
                  }}
                  onClick={(e) => onHandleSubmit(e)}
                >
                  {postLoading ? <Spinner color="#fff" /> : "Submit"}
                </button>
              )}
            </div>
          </div>{" "}
        </>
      )}
    </div>
  );
};

export default AddFeeTypeNew;
