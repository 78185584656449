import React, { useState, Context } from "react";
import BluttenContext from "../BluttenContext/index";
import useHttp from "../../hooks/http";

export default function BulletenGlobalContext(props) {
  const [isLoading, getData, setBulleten] = useHttp("/api/AllbulletIn", []);
  return (
    <BluttenContext.Provider
      value={{
        Bulleten: getData,
        setBulleten,
      }}
    >
      {props.children}
    </BluttenContext.Provider>
  );
}
