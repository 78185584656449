import React, { Component } from "react";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import moment from "moment";

// import './App.css';
import TimePicker from "../../../components/TimePicker";

import "react-big-calendar/lib/css/react-big-calendar.css";
import events from "../events";
import $ from "jquery";
import TextInput from "../../../components/TextInput/TextInput";
import Button from "../../../components/Button";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as sharedActions from "../../../Actions/sharedActions";
import Axios from "axios";
import ThemeContext from "../../../context/themeContext/ThemeContext";
const localizer = momentLocalizer(moment);
const format = "h:mm a";

var d = new Date();
d.setDate(d.getDate() + 2);
console.log(d.toString());

class EventCalender extends Component {
  static contextType = ThemeContext;
  constructor(...args) {
    super(...args);

    this.state = {
      title: "",
      events: events,
      sTime: "",
      allDay: false,
      eTime: "",
      color: "01AC8A",
      info: {},
      hideTimeSection: true,
    };
  }
  eventStyleGetter = (event, start, end, isSelected) => {
    console.log(event);
    var backgroundColor = "#" + event.hexColor;
    var style = {
      backgroundColor: backgroundColor,
      borderRadius: "0px",
      opacity: 0.8,
      color: "#fff",
      border: "0px",
      display: "block",
    };
    return {
      style: style,
    };
  };
  componentDidMount() {
    this.props.sharedAction
      .getDataWithoutDispatch(`/api/Calendar`)
      .then((success) => {
        let getData = [...success];
        // var immutableArray = success.map(
        // 	({ accountType, accountId, time, date, id, allDay, ...rest }) => rest
        // );
        debugger;
        let getDesireData = getData.map((item) => {
          return {
            ...item,
            start: new Date(item.start),
            end: new Date(item.end),
          };
        });
        debugger;
        this.setState({
          events: getDesireData,
        });
        debugger;
      })
      .catch((error) => {
        debugger;
      });
  }
  onChangeDate = (date, state) => {
    debugger;
    this.setState({ [state]: date._d });
  };
  onChangeAllDay = (e) => {
    console.log(e.target.textContent);
    if (e.target.textContent === "Yes") {
      this.setState({
        allDay: true,
      });
      debugger;
    } else {
      this.setState({
        allDay: false,
      });
      debugger;
    }
    debugger;
  };
  handleSelect = (e) => {
    const { sTime, eTime, hideTimeSection } = this.state;

    if (new Date(e["slots"][0]).getTime() > new Date().getTime()) {
      debugger;
      if (hideTimeSection) {
        $("#dateTaker").modal("show");
        this.setState({
          info: e,
        });
      } else {
        this.setState(
          {
            info: e,
            allDay: false,
          },
          () => {
            $("#dateTaker").modal("show");
          }
        );
      }
    }
  };
  onHandleSubmit = () => {
    console.log(this.state.info);
    const { info, title, color, allDay } = this.state;
    if (info.slots.length == 1) {
      $("#dateTaker").modal("hide");
      debugger;
      const { sTime, eTime, info, title } = this.state;
      let startDate = moment(info["slots"][0]).format("YYYY-MM-DD");
      debugger;
      let startTime = moment(sTime, "h:mm:ss A").format("HH:mm:ss");
      debugger;
      let endTime = moment(eTime, "h:mm:ss A").format("HH:mm:ss");
      debugger;
      let getActualStartTime = new Date(
        moment(`${startDate} ${startTime}`, "YYYY-MM-DD HH:mm:ss").format()
      );
      let getActualEndTime = new Date(
        moment(`${startDate} ${endTime}`, "YYYY-MM-DD HH:mm:ss").format()
      );
      debugger;
      let newColor = color;
      var regexp = /#(\S)/g;
      newColor = newColor.replace(regexp, "$1");
      console.log(newColor);
      debugger;
      if (allDay) {
        this.setState(
          {
            events: [
              ...this.state.events,
              {
                start: getActualStartTime,
                end: getActualEndTime,
                title,
                hexColor: newColor,
                allDay: allDay,
              },
            ],
          },
          () => {
            this.props.sharedAction
              .simpleAddRequest(`/api/Calendar`, {
                start: getActualStartTime,
                end: getActualEndTime,
                title,
                hexColor: newColor,
                desc: "new desc",
                allDay: allDay,
                accountId: this.props.name,
                accountType: this.props.role,
              })
              .then((success) => {
                debugger;
                console.log(this.state.events);
                this.setState({
                  sTime: "",
                  eTime: "",
                  title: "",
                  color: "",
                  allDay: false,
                });
              })
              .catch((error) => {});
          }
        );
      } else {
        debugger;

        this.setState(
          {
            events: [
              ...this.state.events,
              {
                start: getActualStartTime,
                end: getActualEndTime,
                title,
                hexColor: newColor,
              },
            ],
          },
          () => {
            this.props.sharedAction
              .simpleAddRequest(`/api/Calendar`, {
                start: getActualStartTime,
                end: getActualEndTime,
                title,
                hexColor: newColor,
                desc: "new desc",

                accountId: this.props.name,
                accountType: this.props.role,
              })
              .then((success) => {
                debugger;
                console.log(this.state.events);
                this.setState({
                  sTime: "",
                  eTime: "",
                  title: "",
                  color: "",
                });
              })
              .catch((error) => {});
          }
        );
      }
    } else {
      $("#dateTaker").modal("hide");

      let newColor = color;
      var regexp = /#(\S)/g;
      newColor = newColor.replace(regexp, "$1");
      console.log(newColor);

      this.setState(
        {
          events: [
            ...this.state.events,
            {
              start: info["slots"][0],
              end: info["slots"][1],
              title,
              hexColor: newColor,
            },
          ],
        },
        () => {
          this.props.sharedAction
            .simpleAddRequest(`/api/Calendar`, {
              start: info["slots"][0],
              end: info["slots"][1],
              title,
              hexColor: newColor,
              desc: "new desc",
              accountId: this.props.name,
              accountType: this.props.role,
            })
            .then((success) => {
              debugger;
              console.log(this.state.events);
              this.setState({
                sTime: "",
                eTime: "",
                title: "",
                color: "",
                allDay: false,
              });
            })
            .catch((error) => {});
        }
      );
    }
    debugger;
  };
  onNavigationHandler = (e) => {
    debugger;
    if (
      e === "week" ||
      e === "day" ||
      Object.prototype.toString.call(e) === "[object Date]"
    ) {
      debugger;
      this.setState({
        hideTimeSection: false,
      });
    } else {
      debugger;
      this.setState({
        hideTimeSection: true,
      });
    }
  };
  onColorChange = (color) => {
    debugger;
    this.setState({
      color,
    });
  };
  onColor = (e) => {
    let newColor = e.target.value;
    var regexp = /#(\S)/g;
    newColor = newColor.replace(regexp, "$1");
    console.log(newColor);

    debugger;
    this.setState({
      color: newColor,
    });
  };
  render() {
    let themeValue = this.context;
    debugger;
    const { hideTimeSection } = this.state;
    return (
      <div className="EventCalender">
        <div
          class="modal fade"
          id={"dateTaker"}
          tabindex={`-1`}
          role="dialog"
          aria-labelledby={`exampleModalLabel`}
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content modal_content_custom">
              <ThemeContext.Consumer>
                {(context) => (
                  <div
                    style={{
                      borderBottomLeftRadius: 30,
                      borderBottomRightRadius: 30,
                      borderTopLeftRadius: 18,
                      borderTopRightRadius: 18,
                      background: themeValue.themeColors.primaryColor,
                    }}
                    class="modal-header modal_custom_header"
                  >
                    <h4 style={{ color: "white" }} class="modal-title">
                      <i class="fas fa-calendar-check"></i> Add Events
                    </h4>

                    <button
                      style={{ color: "white" }}
                      type="button"
                      class="close"
                      data-dismiss="modal"
                    >
                      &times;
                    </button>
                  </div>
                )}
              </ThemeContext.Consumer>
              <div class="modal-body">
                <div style={{ textAlign: "center" }}>
                  <div class="input-group input-group-sm mb-3">
                    <div class="input-group-prepend">
                      <span
                        style={{ backgroundColor: "#fff" }}
                        class="input-group-text"
                        id="inputGroup-sizing-sm"
                      >
                        Event Title
                      </span>
                    </div>
                    <input
                      onChange={(e) => this.setState({ title: e.target.value })}
                      placeholder={"Title"}
                      value={this.state.title}
                      type="text"
                      class="form-control"
                      aria-label="Small"
                      aria-describedby="inputGroup-sizing-sm"
                    />
                  </div>
                </div>

                {/* <div>
									<input type="color" id="head" name="head" value="#e66465" />
								</div> */}

                {hideTimeSection ? (
                  <>
                    <div>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <div class="input-group-prepend">
                          <span
                            style={{ backgroundColor: "#fff" }}
                            class="input-group-text"
                            id="inputGroup-sizing-sm"
                          >
                            All Day Event
                          </span>
                        </div>
                        <div style={{ width: "5%" }} />
                        <div>
                          <div
                            class="btn-group btn-group-toggle"
                            data-toggle="buttons"
                            onClick={this.onChangeAllDay}
                          >
                            <label
                              style={{ backgroundColor: "#01AC8A" }}
                              className="btn btn-secondary"
                            >
                              <input
                                onClick={this.onChangeAllDay}
                                type="radio"
                                name="options"
                                id="option1"
                                autocomplete="off"
                              />
                              Yes
                            </label>

                            <label
                              style={{ backgroundColor: "#0A4F5E" }}
                              class="btn btn-secondary"
                            >
                              <input
                                onClick={this.onChangeAllDay}
                                type="radio"
                                name="options"
                                id="option3"
                                autocomplete="off"
                              />
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div>
                      <div style={{ display: "flex" }}>
                        <div>
                          <span
                            style={{
                              padding: 10,
                              borderRadius: "30%",
                              fontSize: 15,
                            }}
                            class="badge badge-dark"
                          >
                            Start Time
                          </span>
                        </div>
                        <div style={{ width: "5%	" }} />
                        <div>
                          {" "}
                          <TimePicker
                            className="dateTimePicker"
                            showSecond={false}
                            placeholder={"Hr-Min-(AM/PM)"}
                            name="offSDate"
                            defaultValue={null}
                            onChange={(e) => this.onChangeDate(e, "sTime")}
                            format={format}
                            use12Hours
                            value={
                              this.state.sTime === ""
                                ? null
                                : moment(new Date(this.state.sTime))
                            }

                            // inputReadOnly
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          fontSize: 30,
                          marginLeft: 40,
                          fontWeight: "bolder",
                          color: "grey",
                        }}
                      >
                        |
                      </div>
                      <div style={{ display: "flex" }}>
                        <div>
                          <span
                            style={{
                              padding: 10,
                              borderRadius: "30%",
                              fontSize: 15,
                            }}
                            class="badge badge-dark"
                          >
                            End Time
                          </span>
                        </div>
                        <div style={{ width: "5%	" }} />

                        <div>
                          <TimePicker
                            className="dateTimePicker"
                            showSecond={false}
                            placeholder={"Hr-Min-(AM/PM)"}
                            name="offSDate"
                            defaultValue={null}
                            onChange={(e) => this.onChangeDate(e, "eTime")}
                            format={format}
                            use12Hours
                            value={
                              this.state.eTime === ""
                                ? null
                                : moment(new Date(this.state.eTime))
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
                <hr />
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      border: `10px solid #${this.state.color}`,
                      padding: 10,
                      borderRadius: 20,
                    }}
                  >
                    <div
                      onClick={() => this.onColorChange("01AC8A")}
                      className="ripple"
                      style={{
                        backgroundColor: "#01AC8A",
                        height: 40,
                        width: 40,
                        borderRadius: "50%",
                        cursor: "pointer",
                      }}
                    />
                    <div
                      onClick={() => this.onColorChange("01425E")}
                      className="ripple"
                      style={{
                        backgroundColor: "#01425E",
                        height: 40,
                        width: 40,
                        borderRadius: "50%",
                        cursor: "pointer",
                      }}
                    />
                    <div
                      onClick={() => this.onColorChange("008000")}
                      className="ripple"
                      style={{
                        backgroundColor: "green",
                        height: 40,
                        width: 40,
                        borderRadius: "50%",
                        cursor: "pointer",
                      }}
                    />
                    <div
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <input
                        onChange={this.onColor}
                        className="ripple differColor"
                        type="color"
                      />
                    </div>
                  </div>
                </div>
                <br />
                {/* {!hideTimeSection ? (
									<div
										style={{ display: 'flex', justifyContent: 'space-between' }}
									>
										<div>
											<p style={{ textAlign: 'center' }}>Start time</p>
											<TimePicker
												className="dateTimePicker"
												showSecond={false}
												placeholder={'Hr-Min-(AM/PM)'}
												name="offSDate"
												defaultValue={null}
												onChange={e => this.onChangeDate(e, 'sTime')}
												format={format}
												use12Hours

												// inputReadOnly
											/>
										</div>
										<div>
											<p style={{ textAlign: 'center' }}>End time</p>
											<TimePicker
												className="dateTimePicker"
												showSecond={false}
												placeholder={'Hr-Min-(AM/PM)'}
												name="offSDate"
												defaultValue={null}
												onChange={e => this.onChangeDate(e, 'eTime')}
												format={format}
												use12Hours
											/>
										</div>
									</div>
								) : (
									''
								)} */}

                <div style={{ textAlign: "center" }}>
                  <Button
                    customClause={
                      this.state.hideTimeSection
                        ? this.state.sTime !== "" &&
                          this.state.eTime !== "" &&
                          this.state.title !== ""
                          ? false
                          : true
                        : this.state.title !== ""
                        ? false
                        : true
                    }
                    onHandleSubmit={this.onHandleSubmit}
                    textColor={"#fff"}
                    buttonClass={"btn-smart-one"}
                    btnName={"Add"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <Calendar
          drilldownView={(view) => console.log("check view", view)}
          // date={new Date('4-14-2020')}
          style={{ height: 700 }}
          selectable
          localizer={localizer}
          events={this.state.events}
          defaultView={Views.MONTH}
          startAccessor={"start"}
          endAccessor={"end"}
          scrollToTime={new Date(1970, 1, 1, 6)}
          defaultDate={new Date()}
          onSelectEvent={(event) => alert(event.title)}
          onNavigate={this.onNavigationHandler}
          onSelectSlot={this.handleSelect}
          onView={this.onNavigationHandler}
          eventPropGetter={this.eventStyleGetter}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    role: state.setUserReducer.users.role,
    name: state.setUserReducer.users.unique_name,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    sharedAction: bindActionCreators(sharedActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(EventCalender);
