import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';

class AdministrationManagers extends Component {
	state = {};
	componentDidMount() {
		$(document).click(function(e) {
			if (!$(e.target).is('.panel-body')) {
				$('.collapse').collapse('hide');
			}
		});
	}
	render() {
		return (
			<div>
				<section class="mb-4">
					<h2 class="h1-responsive font-weight-bold text-center my-4">
						Administration Manager's
					</h2>

					<div
						class="row"
						style={{ justifyContent: 'center', textAlign: 'center' }}
					>
						<div class="col-md-8">
							<div class="md-form mb-0">
								<hr class="style-head" />
							</div>
						</div>
					</div>

					<p class="text-center w-responsive mx-auto mb-5" />

					<div
						class="row"
						style={{ textAlign: 'center', justifyContent: 'center' }}
					>
						<div class="col-md-9 mb-md-0 mb-5">
							<form id="contact-form" name="contact-form">
								<div
									class="row"
									style={{ justifyContent: 'center', textAlign: 'center' }}
								>
									<div class="col-md-6">
										<div class="md-form mb-0">
											<img
												src={require('../../Assets/Managers/managers-08.png')}
												width="150"
												height="160"
												data-toggle="collapse"
												data-target="#collapseExample11"
												aria-expanded="false"
												aria-controls="collapseExample11"
											/>
											<div
												class="collapse"
												id="collapseExample11"
												aria-labelledby="headingThree"
											>
												<div
													class="card card-body"
													style={{
														borderRadius: 20,
														border: 'solid',
														borderColor: '#0A4F5E'
													}}
												>
													<Link to="/dashboard/CensusManager">
														<button
															type="button"
															class="btn btn-dark"
															style={{
																width: 160,
																height: 40,
																fontSize: 16,
																borderRadius: 20
															}}
														>
															<i
																style={{ marginRight: 8 }}
																className="fas fa-map animated fadeIn"
															/>
															Add
														</button>
													</Link>
													<br />
													<Link to="/dashboard/ViewCensusManager">
														<button
															type="button"
															class="btn btn-dark"
															style={{
																width: 160,
																height: 40,
																fontSize: 16,
																borderRadius: 20
															}}
														>
															<i
																style={{ marginRight: 8 }}
																className="fas fa-file-word animated fadeIn"
															/>
															View
														</button>
													</Link>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-md-3">
										<div class="md-form mb-0">
											<img
												src={require('../../Assets/Managers/humza icon-04-01.png')}
												data-toggle="collapse"
												data-target="#collapseExample"
												aria-expanded="false"
												aria-controls="collapseExample"
												style={{ width: 150, height: 160 }}
											/>
										</div>
									</div>

									<div class="col-md-3">
										<div class="md-form mb-0">
											<img
												src={require('../../Assets/Managers/humza icon-04-02.png')}
												width="150"
												height="160"
												data-toggle="collapse"
												data-target="#collapseExample1"
												aria-expanded="false"
												aria-controls="collapseExample1"
											/>
										</div>
									</div>

									<div class="col-md-3">
										<div class="md-form mb-0">
											<img
												src={require('../../Assets/Managers/humza icon-04-03.png')}
												width="150"
												height="160"
												data-toggle="collapse"
												data-target="#collapseExample2"
												aria-expanded="false"
												aria-controls="collapseExample2"
											/>
										</div>
									</div>
									<div class="col-md-3">
										<div class="md-form mb-0">
											<img
												src={require('../../Assets/Managers/humza icon-04-04.png')}
												width="150"
												height="160"
												data-toggle="collapse"
												data-target="#collapseExample3"
												aria-expanded="false"
												aria-controls="collapseExample3"
											/>
										</div>
									</div>
								</div>
								<br />
							</form>
							<div class="status" />
						</div>
					</div>
				</section>
			</div>
		);
	}
}

export default AdministrationManagers;
