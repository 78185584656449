import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import DropDownsForData from '../DescriptiveReportModule/TermClassSubjectSelect';
import Moment from 'moment';
import DatePicker from 'react-date-picker';
import $ from 'jquery';
import ThemeContext from "../../context/themeContext/ThemeContext";
class PrintDisciplineReportStudentProfileRenew extends Component {
	constructor(props) {
		super(props);
		this.state = {
			SkillReports: [],
			adminData: null,
			enabled: false,
			id: null,
			select: [],
			date: '',
			studentName: '',
			isLoading: true,
			disablepage: false,
			disableBPage: true,
			currentPage: 1,
			todosPerPage: 10,
			pageCounter: 10,
			msg: '',
			errorMsg: '',
			increament: 1,
			selectClassData: '',
			classId: '',
			sectionId: '',
			sections: [],
			subjects: [],
			sessionId: '',
			subjectId: '',
			staffId: '',
			term: '',
			btnLoad: false,
			exist: false,
			checking: [],
			searchCheck: true,
			multiple: [],
			errorText: '',
			badRequest: false,
			present: '',
			absent: '',
			excusedAbsent: '',
			excusedNonAbsent: '',
			totalLate: ''
		};
	}

	componentDidUpdate() {
		$('.tooltip').tooltip('hide');

		if (
			this.state.msg ||
			this.state.errorMsg ||
			this.state.exist ||
			this.state.badRequest
		) {
			setTimeout(() => {
				this.setState({
					msg: false,
					errorMsg: false,
					exist: false,
					badRequest: false
				});
			}, 3000);
		}
	}

	componentDidMount() {
		const { item } = this.props.location.state;
		console.log(item);
		this.onSearchStudent();
	}

	getSimple = (type, state) => {
		this.props.sharedActions
			.getDataWithoutDispatch(`/api/${type}`)
			.then(success => {
				this.setState({
					[state]: success,
					isLoading: false
				});
			})
			.catch(error => {
				this.setState({
					errorMsg: true,
					successMsg: false,
					msg: 'Error Occured!!',
					isLoading: false
				});
				console.error('Error Name: ', error);
			});
	};

	onSearchStudent = e => {
		const { item, term, subjectId } = this.props.location.state;
		console.log(item);

		this.props.sharedActions
			.getDataWithoutDispatch(
				`/api/StudentAttendanceList/Termly/?studentId=${item.studentId}&classId=${item.classId}&sectionId=${item.sectionId}&term=${term}&sessionId=${item.sessionId}&subjectId=${subjectId}`
			)
			.then(success => {
				this.setState({
					adminData: success.attendanceList,
					present: success.present,
					absent: success.absent,
					excusedAbsent: success.excusedAbsents,
					excusedNonAbsent: success.nonExcusedAbsents,
					studentName: success.studentInfo.studentName,
					className: success.studentInfo.className,
					section: success.studentInfo.section,
					term: success.studentInfo.term,
					sessionName: success.studentInfo.sessionName,
					staffName: success.studentInfo.staffName,
					late: success.late,
					isLoading: false,
					btnLoad: false,
					searchCheck: false
				});
			})
			.catch(err => {
				this.setState({
					errorText: err.response
						? err.response.data.Message
						: 'Error Occured!!',
					isLoading: false,
					btnLoad: false,
					searchCheck: true,
					badRequest: true
				});

				console.log(err);
			});
	};

	checkBook = studentId => {
		const { student } = this.state;
		let f = student.student.filter(data => {
			return data.studentId == studentId;
		});
		console.log(f);

		if (f.length > 0) {
			this.setState({
				classId: f[0].classId,
				className: f[0].className,
				sectionId: f[0].sectionId,
				section: f[0].section
			});
		} else {
			this.setState({
				errorMsg: true
			});
		}
	};
	onHandleText = e => {
		this.setState({
			[e.target.name]: e.target.value
		});
	};

	PrintContent = el => {
		var restorepage = document.body.innerHTML;
		var Printcontent = document.getElementById(el).innerHTML;
		document.body.innerHTML = Printcontent;
		window.print();
		document.body.innerHTML = restorepage;
	};

	render() {
		const {
			classId,
			sectionId,
			sessionId,
			subjectId,
			term,
			adminData,
			date,
			student,
			studentId,
			session,
			classSectionGrade
		} = this.state;

		const { classes } = this.state.selectClassData;

		const Loader = (
			<ThemeContext.Consumer>
			{(context) => (
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<label style={{ color: 'red' }}>No Data</label>
			</div>
						              )}
									  </ThemeContext.Consumer>
		);

		const renderTodos =
			adminData &&
			adminData.map((item, index) => {
				return (
					<ThemeContext.Consumer>
					{(context) => (
					<tr key={index}>
						<th scope="row" className="bordersSpace" style={{ padding: 2, borderColor: context.themeColors.primaryColor }}>
							{index + 1}
						</th>
						<td className="bordersSpace" style={{ padding: 2, borderColor: context.themeColors.primaryColor }}>
							{item.date}
						</td>
						<td className="bordersSpace" style={{ padding: 2, borderColor: context.themeColors.primaryColor }}>
							{item.leaveType}
						</td>
						<td className="bordersSpace" style={{ padding: 2, borderColor: context.themeColors.primaryColor }}>
							{item.attendanceStatus}
						</td>
						<td className="bordersSpace" style={{ padding: 2, borderColor: context.themeColors.primaryColor }}>
							{item.lateTime}
						</td>
					</tr>
								              )}
											  </ThemeContext.Consumer>
				);
			});
		const MainContent = (
			<ThemeContext.Consumer>
			{(context) => (
			<div  class='container'>
				<div id="div1">
					<div class="divclass">
						<img src={require('../../Assets/images/header.png')} 
						style={{height:250}}/>
					</div>
					<br />
					<div class="colCheckb">
						<div class="row justify-content-between">
							<div class="col-md-4">
								<div class="md-form mb-0">
									<label for="name" class="">
										Student Name: &nbsp;&nbsp;&nbsp;&nbsp;
									</label>
									<label
										for="name"
										class=""
										style={{
											fontWeight: 'bold',
											fontSize: 20,
											color: '#01425e'
										}}
									>
										{this.state.studentName}
									</label>
								</div>
							</div>
							<div class="col-md-4">
								<div class="md-form mb-0">
									<label for="name" class="">
										Class: &nbsp;&nbsp;&nbsp;&nbsp;
									</label>
									<label
										for="name"
										class=""
										style={{
											fontWeight: 'bold',
											fontSize: 20,
											color: '#01425e'
										}}
									>
										{this.state.className}
									</label>
								</div>
							</div>
							<div class="col-md-4">
								<div class="md-form mb-0">
									<label for="name" class="">
										Section: &nbsp;
									</label>
									<label
										for="name"
										class=""
										style={{
											fontWeight: 'bold',
											fontSize: 20,
											color: '#01425e'
										}}
									>
										{this.state.section}
									</label>
								</div>
							</div>
							<div class="col-md-4">
								<div class="md-form mb-0">
									<label for="name" class="">
										Term: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									</label>
									<label
										for="name"
										class=""
										style={{
											fontWeight: 'bold',
											fontSize: 20,
											color: '#01425e'
										}}
									>
										{this.state.term}
									</label>
								</div>
							</div>
							<div class="col-md-4">
								<div class="md-form mb-0">
									<label for="name" class="">
										Session: &nbsp;
									</label>
									<label
										for="name"
										class=""
										style={{
											fontWeight: 'bold',
											fontSize: 20,
											color: '#01425e'
										}}
									>
										{this.state.sessionName}
									</label>
								</div>
							</div>
						</div>
						<div class="col-md-4">
							<div class="md-form mb-0">
								<label for="name" class="">
									Staff: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
								</label>
								<label
									for="name"
									class=""
									style={{
										fontWeight: 'bold',
										fontSize: 20,
										color: '#01425e'
									}}
								>
									{this.state.staffName}
								</label>
							</div>
						</div>
					</div>
					<br />
					<div className="table-responsive">
						<table class="table table-hover text-center">
							<thead
								style={{ background: context.themeColors.primaryColor, color: 'white' }}
								class="bordersSpace"
							>
								<tr class="vendorListHeading">
									<th class="bordersSpace" style={{ padding: 2, borderColor: context.themeColors.primaryColor }}>
										#
									</th>
									<th class="bordersSpace" style={{ padding: 2, borderColor: context.themeColors.primaryColor }}>
										Date
									</th>
									<th class="bordersSpace" style={{ padding: 2, borderColor: context.themeColors.primaryColor }}>
										Leave Type
									</th>
									<th class="bordersSpace" style={{ padding: 2, borderColor: context.themeColors.primaryColor }}>
										Attendance Status
									</th>
									<th class="bordersSpace" style={{ padding: 2, borderColor: context.themeColors.primaryColor }}>
										Late
									</th>
								</tr>
							</thead>
							<tbody>{renderTodos}</tbody>
						</table>
					</div>
					<div class="colCheckb">
						<div class="row justify-content-between">
							<div class="col-md-4">
								<div class="md-form mb-0">
									<label for="name" class="">
										Total Present: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									</label>
									<label
										for="name"
										class=""
										style={{
											fontWeight: 'bold',
											fontSize: 20,
											color: '#01425e'
										}}
									>
										{this.state.present}
									</label>
								</div>
							</div>
							<div class="col-sm-3">
								<div class="md-form mb-0">
									<label for="name" class="">
										Total Absent: &nbsp;&nbsp;&nbsp;&nbsp;
									</label>
									<label
										for="name"
										class=""
										style={{
											fontWeight: 'bold',
											fontSize: 20,
											color: '#01425e'
										}}
									>
										{this.state.absent}
									</label>
								</div>
							</div>
						</div>
						<div class="row justify-content-between">
							<div class="col-md-4">
								<div class="md-form mb-0">
									<label for="name" class="">
										Absent Excused: &nbsp;
									</label>
									<label
										for="name"
										class=""
										style={{
											fontWeight: 'bold',
											fontSize: 20,
											color: '#01425e'
										}}
									>
										{this.state.excusedAbsent}
									</label>
								</div>
							</div>
							<div class="col-sm-3">
								<div class="md-form mb-0">
									<label for="name" class="">
										Absent Non Excused: &nbsp;
									</label>
									<label
										for="name"
										class=""
										style={{
											fontWeight: 'bold',
											fontSize: 20,
											color: '#01425e'
										}}
									>
										{this.state.excusedNonAbsent}
									</label>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-12">
								<div class="md-form mb-0">
									<label for="name" class="">
										Total Late: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									</label>
									<label
										for="name"
										class=""
										style={{
											fontWeight: 'bold',
											fontSize: 20,
											color: '#01425e'
										}}
									>
										{this.state.late}
									</label>
								</div>
							</div>
						</div>
					</div>

					<br />
					{this.state.errorMsg ? (
						<p style={{ color: 'red', textAlign: 'center' }}>
							Grading Marks Already Exist For This Term
						</p>
					) : (
						''
					)}
					{this.state.msg ? (
						<p style={{ color: 'green', textAlign: 'center' }}>
							Added Successfully!
						</p>
					) : (
						''
					)}
					{this.state.exist ? (
						<p style={{ color: 'red', textAlign: 'center' }}>
							Please Fill the Fields
						</p>
					) : (
						''
					)}
				</div>
				<div class="text-center text-md-right">
					<label for="name" class="" onClick={() => this.PrintContent('div1')}>
						<i
							className="fas fa-print fa-2x animated fadeIn"
							style={{ marginRight: 4 }}
						/>
						Print It Out
					</label>
				</div>
			</div>
						              )}
									  </ThemeContext.Consumer>
		);
		return (			
		<ThemeContext.Consumer>
			{(context) => (
			<div>
				{this.state.badRequest ? (
					<p style={{ color: 'red', textAlign: 'center' }}>
						{this.state.errorText}
					</p>
				) : (
					''
				)}
				<div className="page-header">
					<SnackBar msg={this.state.msg} />
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'center'
						}}
					>
						<div>
							<h5 class="generalHead" style={{background: context.themeColors.primaryColor}}>View Student Attendance</h5>
						</div>
					</div>
				</div>
				<br />
				{this.state.searchCheck ? Loader : MainContent}
			</div>
						              )}
									  </ThemeContext.Consumer>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch)
	};
}
function mapStateToProps(state) {
	return {
		users: state.setUserReducer.users
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PrintDisciplineReportStudentProfileRenew);
