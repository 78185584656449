import React, { Component } from "react";
import $ from "jquery";
import "../Admin/viewAdmin.css";
import Axios from "axios";
import config from "../../config/config";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as CanteenActions from "../../Actions/Canteen";
import * as sharedActions from "../../Actions/sharedActions";
import setCanteenReducer from "../../Reducers/setCanteenReducer";
import SnackBar from "../../components/SnackBar/SnackBar";
import ThemeContext from "../../context/themeContext/ThemeContext";

class ViewCanteen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adminData: [],
      modalData: null,
      campusName: "",
      campusId: null,
      name: "",
      canteenName: "",
      handlerName: "",
      handlerId: null,
      enabled: false,
      id: null,
      isLoading: true,
      disablepage: false,
      disableBPage: true,
      currentPage: 1,
      todosPerPage: 10,
      pageCounter: 10,
      msg: "",
      select: [],
      handler: [],
    };
  }
  handleClickNextFrwd = () => {
    const { adminData, todosPerPage, count, currentPage } = this.state;
    let pageNumbers = [];
    for (
      let i = 1;
      i <= Math.ceil(adminData.length / this.state.todosPerPage);
      i++
    ) {
      pageNumbers.push(i);
    }
    console.log("pagelength: ", pageNumbers.length);
    console.log("current page: ", currentPage);
    if (currentPage === pageNumbers.length) {
      this.setState({
        disablepage: true,
      });
    } else {
      this.setState((prevState) => ({
        currentPage: prevState.currentPage + 1,
        disableBPage: false,
      }));
    }
  };
  handleClickNextBack = () => {
    const { adminData, todosPerPage, count, currentPage } = this.state;
    console.log("current page: ", currentPage);
    if (currentPage === 1) {
      this.setState({
        disableBPage: true,
        disablepage: false,
      });
    } else {
      this.setState((prevState) => ({
        currentPage: prevState.currentPage - 1,
      }));
      this.setState({
        disablepage: false,
      });
    }
  };
  handleClickNext = (event) => {
    this.setState({
      currentPage: Number(event.target.id),
      disablepage: false,
      disableBPage: false,
    });
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      prevState.adminData !== nextProps.AllCanteenData &&
      prevState.select !== nextProps.selectData &&
      prevState.handler !== nextProps.handlerData
    ) {
      return {
        adminData: nextProps.AllCanteenData,
        select: nextProps.selectData,
        handler: nextProps.handlerData,
      };
    }
    return null;
  }
  componentDidUpdate() {
    $('[data-toggle="tooltip"]').tooltip();
  }
  componentDidMount() {
    $(document).ready(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });
    this.props.sharedActions
      .gAllData(
        "/api/getCanteenHandler",
        this.props.canteenActions.getHandlerForCanteen
      )
      .then((success) => {
        console.log(success);
      })
      .catch((error) => {
        console.log(error);
      });
    this.props.sharedActions
      .gAllData("/api/Canteens", this.props.canteenActions.getCampusForCanteen)
      .then((success) => {
        console.log(success);
      })
      .catch((error) => {
        console.log(error);
      });
    if (this.props.allCanteenState.apiFlag) {
      this.props.sharedActions
        .getAllData(
          "/api/Canteens",
          this.props.canteenActions.setAllCanteenInRedux,
          this.props.canteenActions.updateCanteenFlag
        )
        .then((success) => {
          this.setState({
            isLoading: false,
          });
        })
        .catch((err) => {
          this.setState({
            isLoading: false,
          });

          console.log(err);
        });
    }
  }
  btnTechHover = () => {
    $(document).ready(function () {
      $("button").hover(
        function () {
          $(this).css("box-shadow", "0px 5px 5px grey");
          $(this).css("border-color", "white");
        },
        function () {
          $(this).css("box-shadow", "0px 0px 0px white");
        }
      );
    });
  };
  onTakeItemDelete = (itemId) => {
    this.setState({
      modalData: [],
    });
    this.props.sharedActions
      .deleteRecord(
        "/api/Canteens/",
        itemId,
        this.props.canteenActions.deleteCanteen
      )
      .then((success) => {
        this.setState({
          msg: "Delete Record Successfully!",
        });
        this.props.snackbar();
      })
      .catch((error) => {});
  };
  onTakeItem = (itemId) => {
    this.setState({
      modalData: [],
    });
    const { adminData } = this.state;
    let getData = adminData.filter((item) => item.t1.id === itemId);

    this.setState({
      modalData: getData,
      campusName: getData[0].campusName,
      name: getData[0].t1.name,
      id: getData[0].t1.id,
      campusId: getData[0].t1.campusId,
      handlerId: getData[0].t1.handlerId,
      handlerName: getData[0].HandlerName,
    });
  };

  onHandleText = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  onHandleSubmit = (e) => {
    e.preventDefault();
    const {
      id,
      campusName,
      name,
      handlerName,
      campusId,
      handlerId,
    } = this.state;
    var bodyParameters = {
      handlerName,
      id,
      campusName,
      campusId,
      name,
      date: new Date().toDateString(),
      time: new Date().toTimeString(),
      accountId: this.props.users.unique_name,
      accountType: this.props.users.role,
      handlerId,
    };
    this.props.sharedActions
      .editRecord(
        "/api/Canteens/",
        id,
        bodyParameters,
        this.props.canteenActions.updateCanteen
      )
      .then((success) => {
        console.log("Checking");

        this.setState({ enabled: false, msg: "Edit Record Successfully!" });
        this.props.snackbar();
      })
      .catch((err) => {});
  };
  onSelectChange = (e) => {
    console.log(e.target.value);
    let result = JSON.parse(e.target.value);
    console.log(result);
    this.setState({
      campusName: result.campusName,
      campusId: result.t1.campusId,
    });
  };
  onSelectChangeHandler = (e) => {
    console.log(e.target.value);
    let result = JSON.parse(e.target.value);
    console.log(result);
    this.setState({
      handlerName: result.Name,
      handlerId: result.Id,
    });
  };
  onEditClick = (itemId) => {
    this.onTakeItem(itemId);
    this.setState({ enabled: false });
  };
  render() {
    const { adminData, modalData, handler, select } = this.state;

    console.log("modal data: ", this.state.modalData);
    const Loader = (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <ThemeContext.Consumer>
          {(context) => (
            <div
              class="spinner-border"
              style={{ color: context.themeColors.primaryColor }}
            ></div>
          )}
        </ThemeContext.Consumer>
      </div>
    );
    let pageNumbers = [];
    for (
      let i = 1;
      i <= Math.ceil(adminData.length / this.state.todosPerPage);
      i++
    ) {
      pageNumbers.push(i);
    }
    const renderPageNumbers = pageNumbers.map((number, index) => {
      return index <= 3 ? (
        <li
          class={`page-item ${
            this.state.currentPage === number ? "active" : ""
          } `}
        >
          <a
            key={number}
            id={number}
            onClick={this.handleClickNext}
            class="page-link "
            href="#"
          >
            {number}
          </a>
        </li>
      ) : (
        ""
      );
    });
    const { currentPage, todosPerPage } = this.state;
    const indexOfLastTodo = currentPage * todosPerPage;
    const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
    const currentTodos = adminData.slice(indexOfFirstTodo, indexOfLastTodo);
    const renderTodos = currentTodos.map((item, index) => {
      return (
        <tr key={index}>
          <th scope="row">{index + 1}</th>
          <td>{item.t1.name}</td>
          <td>{item.HandlerName}</td>
          <td>{item.campusName}</td>
          <td>{item.t1.date}</td>
          <td>{item.t1.time}</td>

          <td>{item.t1.accountId}</td>
          <td>{item.t1.accountType}</td>
          <td>
            <a href="#" data-toggle="tooltip" title="Edit!">
              <button
                id="firstbutton"
                onClick={() => this.onEditClick(item.t1.id)}
                onMouseOver={this.btnTechHover()}
                data-toggle="modal"
                data-target="#myModal"
                style={{
                  cursor: "pointer",
                  fontSize: 20,
                  border: "none",
                  marginLeft: 5,
                  background: "none",
                  marginBottom: 5,
                }}
              >
                <i class="fas fa-pen"></i>
              </button>
            </a>
            <a href="#" data-toggle="tooltip" title="Delete!">
              <button
                onClick={() => this.onEditClick(item.t1.id)}
                data-toggle="modal"
                data-target="#myModal1"
                style={{
                  cursor: "pointer",
                  fontSize: 20,
                  border: "none",
                  background: "none",
                  marginLeft: 5,
                  marginBottom: 5,
                }}
              >
                <i style={{ color: "#D11A2A" }} class="fas fa-trash-alt"></i>
              </button>
            </a>
          </td>
        </tr>
      );
    });
    const MainContent = (
      <div>
        <div class="modal" id="myModal1">
          <div class="modal-dialog">
            <div class="modal-content modal_content_custom">
              <div
                style={{
                  borderBottomLeftRadius: 30,
                  borderBottomRightRadius: 30,
                  borderTopLeftRadius: 18,
                  borderTopRightRadius: 18,
                }}
                class="modal-header modal_custom_header"
              >
                <h4 style={{ color: "white" }} class="modal-title">
                  Confirm
                </h4>
                <button
                  style={{ color: "white" }}
                  type="button"
                  class="close"
                  data-dismiss="modal"
                >
                  &times;
                </button>
              </div>
              <div class="modal-body">
                {modalData &&
                  modalData.map((item, index) => (
                    <div key={index}>
                      <div style={{ textAlign: "center" }}>
                        <h4>Are you sure ?</h4>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <div>
                          <button
                            disabled={this.state.enabled}
                            data-dismiss="modal"
                            onClick={() => this.onTakeItemDelete(this.state.id)}
                            type="button"
                            class="btn btn-primary buttonAppear"
                          >
                            Yes
                          </button>
                        </div>
                        <div>
                          <button
                            data-dismiss="modal"
                            disabled={this.state.enabled}
                            type="button"
                            class="btn btn-primary buttonAppear"
                          >
                            No
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <div class="modal" id="myModal">
          <div class="modal-dialog">
            <div class="modal-content modal_content_custom">
              <div
                style={{
                  borderBottomLeftRadius: 30,
                  borderBottomRightRadius: 30,
                  borderTopLeftRadius: 18,
                  borderTopRightRadius: 18,
                }}
                class="modal-header modal_custom_header"
              >
                <h4 style={{ color: "white" }} class="modal-title">
                  Details
                </h4>
                <button
                  style={{ color: "white" }}
                  type="button"
                  class="close"
                  data-dismiss="modal"
                >
                  &times;
                </button>
              </div>
              <div class="modal-body">
                <form onSubmit={this.onHandleSubmit}>
                  {modalData &&
                    modalData.map((item, index) => (
                      <div key={index}>
                        <fieldset disabled={!this.state.enabled}>
                          <div class="form-group">
                            <label for="Campus City">Canteen Name</label>
                            <input
                              required
                              style={{
                                borderTop: 0,
                                borderLeft: 0,
                                borderRight: 0,
                                boxShadow: "none",
                              }}
                              value={this.state.name}
                              onChange={this.onHandleText}
                              type="text"
                              name="name"
                              class="form-control"
                              id="name"
                            />
                          </div>
                          <div class="form-group">
                            <label for="campusName">Campus Name:</label>
                            <input
                              readOnly={true}
                              style={{
                                borderTop: 0,
                                borderLeft: 0,
                                borderRight: 0,
                                boxShadow: "none",
                              }}
                              type="text"
                              value={this.state.campusName}
                              onChange={this.onHandleText}
                              name="campusName"
                              class="form-control"
                              id="campusName"
                            />
                          </div>

                          <div class="form-group">
                            <label for="handlerName">Handler Name</label>
                            <input
                              readOnly={true}
                              required
                              style={{
                                borderTop: 0,
                                borderLeft: 0,
                                borderRight: 0,
                                boxShadow: "none",
                              }}
                              value={this.state.handlerName}
                              onChange={this.onHandleText}
                              type="text"
                              name="handlerName"
                              class="form-control"
                              id="hanlderName"
                            />
                          </div>
                          <div class="col-md-12">
                            <div class="md-form mb-0">
                              <label for="email" class="">
                                <i
                                  style={{ marginRight: 4 }}
                                  className="fas fa-university animated fadeIn"
                                />
                                Handler Name
                              </label>
                              <select
                                onChange={this.onSelectChangeHandler}
                                class="custom-select"
                                name="handlerName"
                              >
                                <option
                                  selected={this.state.msg ? true : false}
                                >
                                  Choose Handler...
                                </option>
                                {handler.map((item, index) => (
                                  <option
                                    key={index}
                                    data="hello"
                                    value={`${JSON.stringify(item)}`}
                                  >
                                    {item.Name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="md-form mb-0">
                              <label for="email" class="">
                                <i
                                  style={{ marginRight: 4 }}
                                  className="fas fa-university animated fadeIn"
                                />
                                Campus Name
                              </label>
                              <select
                                onChange={this.onSelectChange}
                                class="custom-select"
                                name="selectCampusName"
                              >
                                <option
                                  selected={this.state.msg ? true : false}
                                >
                                  Choose Campus...
                                </option>
                                {select.map((item, index) => (
                                  <option
                                    key={index}
                                    value={`${JSON.stringify(item)}`}
                                  >
                                    {item.campusName}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </fieldset>
                        <br />
                        <button
                          disabled={this.state.enabled}
                          onClick={() => this.setState({ enabled: true })}
                          type="button"
                          class="btn btn-primary buttonAppear"
                        >
                          Edit
                        </button>
                        <button
                          style={{ marginLeft: 5 }}
                          disabled={!this.state.enabled}
                          type="submit"
                          class="btn btn-primary buttonAppear"
                        >
                          Save
                        </button>
                      </div>
                    ))}
                </form>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  style={{ background: "#00435D" }}
                  class="btn btn-danger"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="table-responsive" style={{ textAlign: "center" }}>
          <ThemeContext.Consumer>
            {(context) => (
              <table class="table table-hover table-bordered">
                <thead
                  style={{
                    background: context.themeColors.primaryColor,
                    color: "white",
                  }}
                >
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Canteen Name</th>
                    <th scope="col">Campus Name</th>
                    <th scope="col">Handler Name</th>
                    <th scope="col">Date</th>
                    <th scope="col">Time</th>
                    <th scope="col">AccountId</th>
                    <th scope="col">AccountType</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>{renderTodos}</tbody>
              </table>
            )}
          </ThemeContext.Consumer>
        </div>
      </div>
    );
    return (
      <ThemeContext.Consumer>
      {(context) => (
      <div>
        <div className="page-header">
          <SnackBar msg={this.state.msg} />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div>
              <h2
              class="h1-responsive font-weight-bold text-center my-4 generalHead" 
              style={{ background: context.themeColors.primaryColor }}
              >Canteen Details</h2>
            </div>
            <nav aria-label="Page navigation example">
              <ul style={{ color: "#01AC8A" }} class="pagination">
                <li
                  class={`page-item ${
                    this.state.disableBPage ? "disabled" : ""
                  }`}
                >
                  <a
                    onClick={this.handleClickNextBack}
                    class="page-link"
                    href="#"
                  >
                    {this.state.disableBPage ? (
                      <i
                        style={{ color: "grey" }}
                        class="far fa-stop-circle"
                      ></i>
                    ) : (
                      <i class="fas fa-backward"></i>
                    )}
                  </a>
                </li>
                {renderPageNumbers}
                <li
                  class={`page-item ${
                    this.state.disablepage ? "disabled" : ""
                  }`}
                >
                  <a
                    onClick={this.handleClickNextFrwd}
                    class="page-link"
                    href="#"
                  >
                    {this.state.disablepage ? (
                      <i
                        style={{ color: "grey" }}
                        class="far fa-stop-circle"
                      ></i>
                    ) : (
                      <i class="fas fa-forward"></i>
                    )}
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        {this.props.allCanteenState.apiFlag ? Loader : MainContent}
      </div>
                  )}
                  </ThemeContext.Consumer>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    canteenActions: bindActionCreators(CanteenActions, dispatch),
    sharedActions: bindActionCreators(sharedActions, dispatch),
  };
}
function mapStateToProps(state) {
  return {
    AllCanteenData: state.setCanteenReducer.Data,
    allCanteenState: state.setCanteenReducer,
    users: state.setUserReducer.users,
    selectData: state.setCanteenReducer.select,
    handlerData: state.setCanteenReducer.selectHandler,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewCanteen);
