import React, { useState, useContext } from "react";
import TextArea from "../../components/TextArea";
import TextInput from "../../components/TextInput/TextInput";
import { General } from "../../utils";
import SpecificFileUpload from "../../components/Admission/SpecificFileUpload";
import GeneralFileUploadOption from "../../components/Admission/GeneralFileUploadOption";
import CustomDatePicker from "../../components/CustomDatePicker";
import { StaffWrapper } from "../Staff";
import { StaffField } from "../../utils";
import Button from "../Button";
import themeContext from "../../context/themeContext/ThemeContext";

const StaffPassport = (props) => {
  const themeColor = useContext(themeContext);
  const {
    putLoader,
    docsChange,
    stateData,
    onNextClick,
    onPreviousClick,
    dispatch,
    nonStateData,
    edit = false,
    errors,
    editData,
    onUpdate,
    match,
  } = props;
  const [editState, setEditState] = useState(stateData);
  let getField = StaffField.getPassportFeildData(
    edit ? editState : nonStateData,
    stateData,
    edit,
    dispatch
  );

  const onChange = (e) => {
    let getOBJ = { ...editState };
    getOBJ[e.target.name] = e.target.value;
    setEditState(getOBJ);
  };

  const onDateChange = (date, fieldName) => {
    let getData = { ...editState };
    getData[fieldName] = date;
    console.log(getData);
    setEditState(getData);
  };
  const editHandleSubmit = (e) => {
    debugger;
    if (onNextClick(e, "staffPassPort", editState)) {
      onUpdate(dispatch, "ON_SET_STAFF_PASSPORT", {
        name: "StaffPassport",
        data: editState,
      });
    }
  };

  return (
    <React.Fragment>
      <div>
        <div style={{ display: "flex", justifyContent: "flex-start" }}>
          {Object.keys(errors).length > 0 && errors["fileType"] ? (
            <p style={{ color: "red", fontSize: 15 }}>
              Format should be <b>doc,dox,pdf</b> and size must be less than{" "}
              <b>2 MB </b>
            </p>
          ) : (
            ""
          )}
        </div>
        <StaffWrapper>
          {getField.map((item) =>
            item.type === "text" ? (
              <TextInput
                edit={edit}
                onEditHandleChange={onChange}
                newError={errors}
                enterWidth={item.enterWidth}
                customWidth={item.customWidth}
                isValidte={edit}
                onHandleChange={item.onHandleChange}
                stateData={item.stateData}
                feildName={item.feildName}
                inputLabelName={item.inputLabelName}
                errors={item.errors}
                placeholder={item.placeholder}
                dispatchFlag={edit}
                dispatch={dispatch}
                // readOnly={true}
                design={item.design}
                type={item.type}
              />
            ) : item.type === "date" ? (
              <CustomDatePicker
                // dispatchFlag={false}
                edit={edit}
                editOnDateChange={onDateChange}
                newError={errors}
                newHandleFlag={edit}
                // emptyDate={true}
                marginTop={item.marginTop}
                feildName={item.feildName}
                inputLabelName={item.inputLabelName}
                dispatchFlag={item.dispatchFlag}
                dispatch={item.dispatch}
                stateData={item.stateData}
                extraType={item.extraType}
              />
            ) : item.type === "radio" && !edit ? (
              <React.Fragment>
                <SpecificFileUpload
                  // onHandleTextChange={onCustomRadioStudentDocs}
                  dispatchFlag={true}
                  errors={errors}
                  dispatch={item.dispatch}
                  stateData={item.stateData}
                  Name={item.Name}
                  idForYes={item.idForYes}
                  idForNo={item.idForNo}
                  feildName={item.feildName}
                  uploadName={item.uploadName}
                />
                <div style={{ position: "relative", top: 35, width: "50%" }}>
                  <GeneralFileUploadOption
                    changeWidth={true}
                    dispatchFlag={true}
                    name={item.uploadName}
                    feildName={item.feildName}
                    dispatch={dispatch}
                    fileOnChange={docsChange}
                    stateData={item.stateData}
                  />
                </div>
              </React.Fragment>
            ) : (
              ""
            )
          )}
        </StaffWrapper>
        {!edit ? (
          <div
            style={{
              marginTop: 10,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <div>
              <button
                class="btns"
                style={{
                  marginRight: 25,
                  background: themeColor.themeColors.primaryColor,
                }}
                onClick={(e) => onNextClick(e, "staffPassPort")}
              >
                Next
              </button>
            </div>
            <div>
              <button
                style={{
                  background: themeColor.themeColors.primaryColor,
                }}
                class="btns"
                onClick={() => onPreviousClick()}
              >
                Previous
              </button>
            </div>
          </div>
        ) : (
          ""
        )}
        {edit ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              btnBackColor={themeColor.themeColors.primaryColor}
              stateData={{ isLoading: putLoader }}
              btnName="Update"
              buttonClass="btn-smart"
              loaderBmargin={5}
              textColor="#ffff"
              marginTop={20}
              onHandleSubmit={editHandleSubmit}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    </React.Fragment>
  );
};

export default StaffPassport;
