import React from 'react';
import $ from 'jquery';
const RenderAction = props => {
	const { Actions, EditClick, actionsName, actionFieldName } = props;

	const btnTechHover = () => {
		$(document).ready(function() {
			$('button').hover(
				function() {
					$(this).css('box-shadow', '0px 5px 5px grey');
					$(this).css('border-color', 'white');
				},
				function() {
					$(this).css('box-shadow', '0px 0px 0px white');
				}
			);
		});
	};
	return Actions.map((item, index) => (
		<a href="#" data-toggle="tooltip" title={item.tooltipTitle}>
			<button
				onMouseOver={btnTechHover()}
				onClick={
					item[actionFieldName] === 'ttView'
						? () => props.ViewClick(props.obj)
						: item[actionFieldName] === 'ttDelete'
						? () => props.DeleteClick(props.obj)
						: item[actionFieldName] === 'ttEdit'
						? () => EditClick(props.obj)
						: item[actionFieldName] === 'LessonDelete'
						? () => props.DeleteClick(props.obj)
						: item[actionFieldName] === 'LessonEdit'
						? () => EditClick(props.obj)
						: ''
				}
				id={item.btnId}
				data-toggle="modal"
				data-target={item.dataTarget}
				style={{
					cursor: 'pointer',
					fontSize: 20,
					border: 'none',
					marginLeft: 5,
					background: 'none',
					marginBottom: 5
				}}
			>
				<i style={{ color: item.color }} class={`fas ${item.iconName}`}></i>
			</button>
		</a>
	));
};

export default RenderAction;
