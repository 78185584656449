import React, { Component } from 'react';
import SelectionInput from '../../components/SelectionInput/SelectionInput';

const RenderDynamicRow = props => {
    const {
        itemObj,
        classHandler,
        sectionHandler,
        stateData,
        rowIndex,
        updateSpecialArrObjOnChange,
        arrName,
        errors,
        award
    } = props;

    let keys = Object.keys(itemObj);
    return (
        <React.Fragment>
            {keys.map((item, index) => {
                if (item === 'classId') {
                    return (
                        <td key={index} width='15%'>
                            <SelectionInput
                                newFlag={'customWidth'}
                                feildName={stateData[arrName][rowIndex][`${item}`]}
                                onHandleChange={e => classHandler(e, rowIndex, arrName)}
                                errors={errors}
                                optionsArrys={stateData['classData']}
                                selectedText={'Class'}
                                stateData={stateData}
                                optionType="dynamicWithPropIdAndName"
                                property={'className'}
                                propertyId={'classId'}
                                successflag={'successflag'}
                                isValidte={false}
                                useFlag={false}
                            />
                        </td>)
                } else if (item === 'sectionId') {
                    return (
                        <td key={index} width='15%'>
                            <SelectionInput
                                newFlag={'customWidth'}
                                feildName={stateData[arrName][rowIndex][`${item}`]}
                                onHandleChange={e => sectionHandler(e, rowIndex, arrName)}
                                errors={errors}
                                optionsArrys={stateData[`sectionData${rowIndex}`]}
                                selectedText={'Section'}
                                stateData={stateData}
                                optionType="dynamicWithPropIdAndName"
                                property={'section'}
                                propertyId={'sectionId'}
                                successflag={'successflag'}
                                isValidte={false}
                                useFlag={false}
                            />
                        </td>
                    )
                } else if (item === 'studentId') {
                    return (
                        <td key={index} width='16%'>
                            <SelectionInput
                                newFlag={'customWidth'}
                                feildName={stateData[arrName][rowIndex][`${item}`]}
                                onHandleChange={e => updateSpecialArrObjOnChange(e, rowIndex, arrName, item)}
                                errors={errors}
                                optionsArrys={stateData[`studentData${rowIndex}`]}
                                selectedText={'Student'}
                                stateData={stateData}
                                optionType="dynamicWithPropIdAndName"
                                property={'Student'}
                                propertyId={'studentId'}
                                successflag={'successflag'}
                                isValidte={false}
                                useFlag={false}
                            />
                        </td>
                    )
                } else if (item === 'certificateId') {
                    return (
                        <td key={index} width='18%'>
                            <SelectionInput
                                newFlag={'customWidth'}
                                feildName={stateData[arrName][rowIndex][`${item}`]}
                                onHandleChange={e => updateSpecialArrObjOnChange(e, rowIndex, arrName, item)}
                                errors={errors}
                                optionsArrys={stateData[`certificateCategoryData`]}
                                selectedText={'Certificate'}
                                stateData={stateData}
                                optionType="dynamicWithPropIdAndName"
                                property={'certificateTitle'}
                                propertyId={'id'}
                                successflag={'successflag'}
                                isValidte={false}
                                useFlag={false}
                            />
                        </td>
                    )
                } else if (item === 'awardCategoryId') {
                    return (
                        <td key={index} width='16%'>
                            <SelectionInput
                                newFlag={'customWidth'}
                                feildName={stateData[arrName][rowIndex][`${item}`]}
                                onHandleChange={e => updateSpecialArrObjOnChange(e, rowIndex, arrName, item)}
                                errors={errors}
                                optionsArrys={stateData[`awardCategoryData`]}
                                selectedText={'Award'}
                                stateData={stateData}
                                optionType="dynamicWithPropIdAndName"
                                property={'awardName'}
                                propertyId={'id'}
                                successflag={'successflag'}
                                isValidte={false}
                                useFlag={false}
                            />
                        </td>
                    )
                }
                else {
                    return (
                        <td key={index} width='20%'>
                            <input
                                type="text"
                                placeholder={`${item}`}
                                class="form-control"
                                name={`${item}`}
                                value={award}
                                disabled={true}
                                style={{ marginTop: 30 }}
                            />
                        </td>
                    )
                }
            })}
        </React.Fragment>
    );
};
export default RenderDynamicRow;
