import * as actionTypes from '../Actions/actionTypes';

const defaultState = {
	apiFlag: true,
	Data: [],
	select: [],
	sessionSelect: []
};

export default function setTestInfoReducer(state = defaultState, action = {}) {
	switch (action.type) {
		case actionTypes.SET_ALL_CLASS_NAME_INTO_TEST_INFO_SELECT:
			return {
				...state,
				select: action.payload
			};
		case actionTypes.SET_ALL_SESSION_NAME_INTO_TEST_INFO_SELECT:
			return {
				...state,
				sessionSelect: action.payload
			};

		case actionTypes.GET_ALL_TEST_INFO:
			return { ...state, Data: [...action.payload] };
		case actionTypes.SET_LOADER_FLAG_TEST_INFO:
			return { ...state, apiFlag: action.payload };
		case actionTypes.DELETE_TEST_INFO:
			let filterCandidateReqList = state.Data.filter(
				filterOBJ => filterOBJ.id !== action.payload
			);
			return {
				...state,
				Data: filterCandidateReqList
			};
		case actionTypes.EDIT_TEST_INFO_RECORD:
			var adminData = [...state.Data];
			var index = adminData.findIndex(obj => obj.id === action.payload.id);
			adminData[index].testName = action.payload.testName;
			adminData[index].testType = action.payload.testType;
			adminData[index].testDate = action.payload.testDate;
			adminData[index].testTime = action.payload.testTime;
			adminData[index].duration = action.payload.duration;
			adminData[index].totalMarks = action.payload.totalMarks;
			adminData[index].sectionName = action.payload.sectionName;
			adminData[index].StaffFirstName = action.payload.staffName;

			return { ...state, Data: [...adminData] };
		default:
			return state;
	}
}
