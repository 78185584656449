
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import $ from 'jquery';
import isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom'
import isNull from 'lodash/isNull'
import moment from 'moment'

import ThemeContext from "../../context/themeContext/ThemeContext";


class ViewDailyDisciplineReport extends Component {
    constructor(props){
        super(props);
        this.state = {
            isLoading: true,
            modalEdit: false,
            editToggle: false,
            generateToggle: false,
            generateIndex: '',
            msg: '',
            editIndex: '',
            classId: "",
            sectionId: "",
            subjectId: "",
            
            searchDate: moment().format('YYYY-MM-DD'),
			accountId: this.props.userData.unique_name,
			accountType: this.props.userData.role

        }

        


    }

    componentDidMount(){
        this.getSimple('classSectionGradeTeacher', 'classSectionGrade')
        this.handleSearch()
        console.log(this.state.date);
        
    }

    getSimple = (type, state) => {
        this.props.sharedActions.getDataWithoutDispatch(
            `/api/${type}`
        ).then(success => { 
            this.setState({
                [state]: success,
                isLoading: false
            })


        }).catch(error => {
            this.setState({
                msg: 'Error Occured!!',
                errorMsg: true,
                isLoading: false
            })
            console.error('Error occured', error);
        })
    }

    getDoubleState = (type, state) => {
        this.props.sharedActions.getDataWithoutDispatch(
            `/api/${type}`
        ).then(success => { 
            this.setState({
                [state]: success,
                contData: success,
                isLoading: false
            })


        }).catch(error => {
            this.setState({
                msg: 'Error Occured!!',
                errorMsg: true,
                isLoading: false
            })
            console.error('Error occured', error);
        })
    }
    


    


    PrintContent = el => {
        var restorepage = document.body.innerHTML;
        var Printcontent = document.getElementById(el).innerHTML;
        document.body.innerHTML = Printcontent;
        
        setTimeout(() => {
            window.print();
            window.location.reload();
        }, 700)
        document.body.innerHTML = restorepage;
        window.reload();
        
    };



    myFunction = () => {
		// Get the snackbar DIV
		var x = document.getElementById('snackbar');

		// StepperGeneralButtons the "show" class to DIV
		x.className = 'show';

		// After 3 seconds, remove the show class from DIV
		setTimeout(function() {
		}, 3000);
	};


        handleSearch = () => {
            const { searchDate } = this.state
            console.log( searchDate );
            let parsedDate = moment(searchDate).format('M/D/YYYY')
            if( isEmpty(searchDate) ){
                console.log('Empty');
                
                this.setState({
                    msg: "Please Fill The Form Repectively!",
                })            
                this.props.snackbar()
            }else{
                
                this.getDoubleState(`DisciplineDailyReport/?date=${parsedDate}`, 'disciplineReport')
                
            }
        }

    btnTechHover = () => {
		$(document).ready(function() {
			$('button').hover(
				function() {
					$(this).css('box-shadow', '0px 5px 5px grey');
					$(this).css('border-color', 'white');
				},
				function() {
					$(this).css('box-shadow', '0px 0px 0px white');
				}
			);
		});
	};

    onHandleText = e => {
		this.setState({
			[e.target.name]: e.target.value
        });
        console.log([e.target.name], e.target.value)
    };

    onHandleFilter = e => {
        this.setState({
			[e.target.name]: e.target.value
        }, () => {
            const { disciplineReport, classId, sectionId, subjectId, contData } = this.state
            if(disciplineReport){
            if( !isEmpty(classId) && isEmpty(sectionId) && isEmpty(subjectId) ){
                
            let filterReport = contData.filter((data, index) => {
                return classId == data.classId 
                
            })
            this.setState({
                disciplineReport: filterReport
            })
            console.log(filterReport);
            }else{

            
            if( !isEmpty(classId) && !isEmpty(sectionId) && isEmpty(subjectId) ){

                let filterReport = contData.filter((data, index) => {
                    return classId == data.classId && sectionId == data.sectionId
                    
                })
                this.setState({
                    disciplineReport: filterReport
                })
                console.log(filterReport);
            }else{
                if( !isEmpty(classId) && !isEmpty(sectionId) && !isEmpty(subjectId)){

                    let filterReport = contData.filter((data, index) => {
                        return classId == data.classId && sectionId == data.sectionId && subjectId == data.subjectId
                        
                    })
                    this.setState({
                        disciplineReport: filterReport
                    })
                    console.log(filterReport);
                }
            }
            }
    }
        });
        console.log([e.target.name], e.target.value)
    };
    


    render(){

        const { search, isLoading, editToggle, msg, accountId, accountType, classSectionGrade, disciplineReport, date,
            classId, sectionId, subjectId, searchDate
        } = this.state;



        const Loader = (
            <ThemeContext.Consumer>
                {(context) => (
			        <div style={{ display: 'flex', justifyContent: 'center' }}>
				        <div className="spinner-border" style={{ color: context.themeColors.primaryColor }}></div>
			        </div>
                )}
            </ThemeContext.Consumer>
        );
        
        const renderDisciplineReportData = disciplineReport && disciplineReport.map((item, index) => {
            return (
                <ThemeContext.Consumer>
                {(context) => (
                <tr key={index} >
                    <th scope="row" style={{ padding: 1 }} >
                        {index + 1}
                    </th>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.className}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.section}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.subjectName}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.staffName}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {moment(item.date).format('MM/DD/YYYY')}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.time}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.sessionName}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.term}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                    <Link to={{
                            pathname: '/dashboard/PrinClassDetailDisciplineReport',
                            state: {
                                sessionId: item.sessionId,
                                classId: item.classId,
                                sectionId: item.sectionId,
                                subjectId: item.subjectId,
                                staffId: item.staffId,
                                term: item.term
                            }
                        }}  >
                            <a href="#" data-toggle="tooltip" title="View">
                                
                                        <button
                                            id="firstbutton"
			            					onMouseOver={this.btnTechHover()}
						            		style={{
									            cursor: 'pointer',
									            fontSize: 20,
            									border: 'none',
			            						marginLeft: 5,
						            			background: 'none',
									            marginBottom: 5
								        }}
                                            >
                            
                                    <i className="fas fa-list"></i>
                                </button>
                            </a> 
                            
                        </Link>
                    </td>
                </tr>
                         )}
            </ThemeContext.Consumer>       
                );
            
        }) 

        return (
            <ThemeContext.Consumer>
            {(context) => (
            <div className="page-header"> 
                <SnackBar msg={msg} />
                <div style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                }}>
                    <div class="row">
                    <div class="col-xs-6 col-sm-6 col-md-6">
                                <h2  
                        style={{ background: context.themeColors.primaryColor }}
                        className="h1-responsive font-weight-bold text-center  generalHead">
                        Daily Discipline Report
                    </h2>
							</div>
                        </div>
                        <div class="row my-3 mx-5 py-4" style={{
                            border: `2px solid ${context.themeColors.primaryColor}`,
                            borderRadius: 20
                        }} >
                           
                            <div class="col-md-4" >
                            <select class="custom-select" 
                                                value={classId}
                                                name="classId"
                                                onChange={this.onHandleFilter}
                                                disabled={!classSectionGrade}
                                            >
                                                        <option value="">Please Select Class</option>
                                                       {classSectionGrade && classSectionGrade.classes.map((value, index) => {
                                                           
                                                           return <option value={value.classId}>{value.className}</option>
                                                       })}
                                            </select>
                            </div>
                            <div class="col-md-4" >
                            <select class="custom-select" 
                                                value={sectionId}
                                                name="sectionId"
                                                onChange={this.onHandleFilter}
                                                disabled={!classId}

                                            >
                                                        <option value="">Please Select Section</option>
                                                       {classSectionGrade && classSectionGrade.sections.map((value, index) => {


                                                                return value.classId == classId && 
                                                                <option value={value.sectionId}>{value.section}</option>

                                                       })}
                                            </select>
                            </div>
                            <div class="col-md-4" >
                            <select class="custom-select" 
                                                value={subjectId}
                                                name="subjectId"
                                                onChange={this.onHandleFilter}
                                                disabled={!sectionId}

                                            >
                                                        <option value="">Please Select Subject</option>
                                                       {classSectionGrade && classSectionGrade.subject.map((value, index) => {

                                                                return value.classId == classId &&
                                                                <option value={value.subjectId}>{value.subjectName}</option>

                                                       })}
                                            </select>
                            </div>
                            
    
                        </div>
                        <div class="row my-4">
                        <div className="col-md-3 text-right">
                        </div>
                        <div className="col-md-3 text-right">
                                        <div className="md-form mb-0 " >
                                            <input class="custom-select" 
                                                type='date'
                                                value={searchDate}
                                                name="searchDate"
                                                onChange={this.onHandleText}
                                            />
                                                    
                                        </div>
                                        </div>

                            <div class="col-md-4  text-left" >
                                            <button
                                            class="btn btn-primary buttonAppear"
                                            onClick={this.handleSearch}
                                            style={{marginRight: '2%', background: context.themeColors.primaryColor}}
                                            >
                            <i class="fa fa-search"></i> Search
                                        </button>
                                        <Link to={{
                            pathname: "PrintDailyDisciplineReport",
                            state: {
                                disciplineReport,
                                date: moment(searchDate).format('DD/MM/YYYY')
                            }
                        }}  >
                            <a href="#" data-toggle="tooltip" title="Edit!">
                                
                            <button
                                            class="btn btn-primary buttonAppear"
                                            disabled={!disciplineReport}
                                            style={{background: context.themeColors.primaryColor}}
                                            >
                            
                                    <i className="fas fa-list"></i> View
                                </button>
                            </a> 
                            
                        </Link>
                            </div>
                            
                        </div>
                    <div className="table-responsive">
					<table className="table table-hover text-center">
						<thead style={{ background: context.themeColors.primaryColor, color: 'white' }}>
							<tr>
								<th scope="col" style={{ padding: 1 }}>
									#
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Class
								</th>
								<th scope="col" style={{ padding: 1 }}>
                                    Section
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Subject
								</th>
								<th scope="col" style={{ padding: 1 }}>
                                    Staff Name
								</th>
                                <th scope="col" style={{ padding: 1 }}>
                                    Date
								</th>
                                <th scope="col" style={{ padding: 1 }}>
                                    Time
								</th>
                                <th scope="col" style={{ padding: 1 }}>
                                    Session
								</th>
                                <th scope="col" style={{ padding: 1 }}>
                                    Term
								</th>
                                <th scope="col" style={{ padding: 1 }}>
                                    Actions
								</th>
							</tr>
						</thead>
						<tbody>{isLoading ? Loader : renderDisciplineReportData}</tbody>
					</table>
				</div>
                </div>
            </div>
                            )}
                            </ThemeContext.Consumer>
        );
    }

}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch),
	};
}
function mapStateToProps(state) {
	return {
		userData: state.setUserReducer.users,
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ViewDailyDisciplineReport);
