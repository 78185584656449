import React from 'react'
import { connect } from 'react-redux';
import $ from 'jquery'
import SelectionInput from '../../../components/SelectionInput/SelectionInput';
import TextArea from '../../../components/TextArea'
import TextInput from '../../../components/TextInput/TextInput'
import FormLayoutWrapper from '../../../components/HOC/FormLayoutWrapper';
import * as sharedActions from '../../../Actions/sharedActions';
import Button from '../../../components/Button';
import SnackBar from '../../../components/SnackBar/SnackBar';
import { bindActionCreators } from 'redux';
import Spinner from '../../../components/Spinner/Spinner';
import general from '../../../utils/general';
import ColorPalette from '../../../components/ColorPalette/ColorPalette'

class AddPatient extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            accountId: props.name,
            accountType: props.role,
            errors: {},
            errorMsg: false,
            isLoadingInitialData: true,
            mainData: {},
            mainData2: {},
            doctorData: [],
            //valid check vals
            name: '',
            studentStaff: '',
            problem: '',
            contactNo: '',
            doctorId: '',

            classId: '',
            sectionId: '',
            studentId: '',
            staffId: '',
            staffPost: '',

            medicalCondition: '',
            recommadationForParents: '',

            isLoadingSearch: false
        }
    }

    componentDidUpdate() {
        if (this.state.msg || this.state.errorMsg) {
            setTimeout(() => {
                this.setState({
                    msg: false,
                    errorMsg: false
                });
            }, 3000);
        }
    }

    componentDidMount() {
        $(document).ready(function () {
            $('[data-toggle="tooltip"]').tooltip();
        });
        this.props.sharedActions
            .getDataWithoutDispatch(
                '/api/StaffPostDepart'
            )
            .then(success => {
                this.props.sharedActions
                    .getDataWithoutDispatch(
                        '/api/Student'
                    )
                    .then(success => {
                        this.props.sharedActions
                            .getDataWithoutDispatch(
                                '/api/Doctor'
                            )
                            .then(success => {
                                this.setState({
                                    doctorData: success
                                })
                            })
                            .catch(error => {
                                this.setState({
                                    isLoadingInitialData: false
                                });
                                console.log(error);
                            });

                        this.setState({
                            mainData2: success,
                            classData: success.classes
                        })
                    })
                    .catch(error => {
                        this.setState({
                            isLoadingInitialData: false
                        });
                        console.log(error);
                    });
                this.setState({
                    mainData: success,
                    departmentData: success.department,
                    isLoadingInitialData: false
                });
            })
            .catch(error => {
                this.setState({
                    isLoadingInitialData: false
                });
                console.log(error);
            });
    }

    onHandleDepartChange = e => {

        const { mainData } = this.state;
        const departmentId = e.target.value
        let filteredPostData = general.dataFilteration(
            mainData.post,
            ['postId', 'postName'],
            { departmentId: parseInt(departmentId) })

        this.setState({
            departmentId: e.target.value,
            postData: filteredPostData,
            staffData: []
        })
    }

    onHandlePostChange = e => {
        if (e.target.value === 'Post') {
            this.setState({
                postId: ''
            })
        } else {
            const { departmentId, mainData } = this.state;
            const postId = e.target.value

            const postNameExtract = general.dataFilteration(
                mainData.post,
                ['postName'],
                {
                    postId: parseInt(postId)
                }
            )

            const staffFilteredData = general.dataFilteration(
                mainData.staff,
                ['staffId', 'staffName'],
                {
                    postId: parseInt(postId),
                    departmentId: parseInt(departmentId)
                }
            )
            this.setState({
                [e.target.name]: e.target.value,
                staffData: staffFilteredData,
                staffPost: postNameExtract[0].postName
            })
        }
    }

    onHandleSelectChange = (e) => {
        if (e.target.value === 'Doctor Name') {
            this.setState({
                [e.target.name]: ''
            })
        } else {
            this.setState({
                [e.target.name]: e.target.value
            })
        }
    }

    onHandleStaffChange = (e) => {
        const { mainData } = this.state
        if (e.target.value === 'Staff') {
            this.setState({
                staffId: ''
            })
        } else {
            const staffFilteredData = general.dataFilteration(
                mainData.staff,
                ['staffId', 'staffName'],
                {
                    staffId: e.target.value
                }
            )
            this.setState({
                [e.target.name]: e.target.value,
                name: staffFilteredData[0].staffName
            })
        }
    }

    onHandleStudentChange = (e) => {
        const { mainData2 } = this.state
        if (e.target.value === 'Student Name') {
            this.setState({
                studentId: ''
            })
        } else {
            const studentFilteredData = general.dataFilteration(
                mainData2.student,
                ['studentId', 'Student'],
                {
                    studentId: e.target.value
                }
            )
            this.setState({
                [e.target.name]: e.target.value,
                name: studentFilteredData[0].Student
            })
        }
    }

    onHandleClassChange = e => {
        const { mainData2 } = this.state;

        const sectionData = general.dataFilteration(
            mainData2.sections,
            ['sectionId', 'section'],
            {
                classId: e.target.value
            }
        )
        this.setState({
            [e.target.name]: e.target.value,
            sectionData: sectionData,
        })
    }

    onHandleSectionChange = e => {
        const { mainData2, classId } = this.state;

        const studentData = general.dataFilteration(
            mainData2.student,
            ['studentId', 'Student'],
            {
                sectionId: e.target.value,
                classId: classId
            }
        )
        this.setState({
            [e.target.name]: e.target.value,
            studentData: studentData,
        })
    }

    handleOptionChange = (e) => {
        this.setState({
            studentStaff: e.target.value,
            name: '',
            problem: '',
            contactNo: '',
            classId: '',
            sectionId: '',
            studentId: '',
            staffId: '',
            departmentId: '',
            postId: '',
            staffPost: ''
        })
    };

    clicked = (e, item) => {
        this.setState({
            medicalCondition: item
        })
    }

    onHandleTextChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onSubmit = (e) => {
        const { name, studentStaff, classId, sectionId, problem, contactNo, doctorId, studentId, staffId, accountId, accountType, staffPost, medicalCondition, recommadationForParents } = this.state;

        e.preventDefault();

        if (
            Object.is(name, '') ||
            Object.is(studentStaff, '') ||
            Object.is(problem, '') ||
            Object.is(contactNo, '') ||
            Object.is(doctorId, '')
        ) {
            this.setState({
                error: true,
                errorMsg: true
            })
        }
        else {

            this.setState({
                isLoading: true
            })
            const data = {
                name,
                studentStaff,
                problem,
                contactNo,
                doctorId,
                classId,
                sectionId,
                studentId,
                staffId,
                staffPost,
                accountId,
                accountType,

                medicalCondition,
                recommadationForParents
            }
            console.log(data);
            this.props.sharedActions
                .simpleAddRequest('/api/PatientDetail',
                    data)
                .then(success => {
                    this.setState({
                        msg: 'Added Patient Successfully',
                        name: '',
                        studentStaff: '',
                        problem: '',
                        contactNo: '',
                        doctorId: '',

                        classId: '',
                        sectionId: '',
                        studentId: '',
                        staffId: '',
                        staffPost: '',
                        sectionData: [],
                        studentData: [],
                        staffData:[],
                        postData: [],

                        isLoading: false,
                        errorMsg: false,
                        error: false,
                    })
                    this.props.snackbar();
                })
        }

    }

    render() {
        const { errors, isLoadingInitialData, departmentData, postData, staffData, classData, sectionData, studentData, doctorData, studentStaff, isLoadingSearch } = this.state;

        const Student = (
            <div>
                <div className="row">

                    <SelectionInput
                        marginTop={20}
                        feildName={'classId'}
                        selectName={'Class Name'}
                        onHandleChange={e => this.onHandleClassChange(e)}
                        errors={errors}
                        optionsArrys={classData}
                        selectedText={'Class Name'}
                        stateData={this.state}
                        // iconClassName={'fas fa-building'}
                        optionType="dynamicWithPropIdAndName"
                        property={'className'}
                        propertyId={'classId'}
                        successflag={'successflag'}
                        isValidte={false}
                    // editUse={'staffEdit'}
                    />

                    <SelectionInput
                        marginTop={20}
                        feildName={'sectionId'}
                        selectName={'Section'}
                        onHandleChange={e => this.onHandleSectionChange(e)}
                        errors={errors}
                        optionsArrys={sectionData}
                        selectedText={'Section'}
                        stateData={this.state}
                        // iconClassName={'fas fa-building'}
                        optionType="dynamicWithPropIdAndName"
                        property={'section'}
                        propertyId={'sectionId'}
                        successflag={'successflag'}
                        isValidte={false}
                    // editUse={'staffEdit'}
                    />
                    <SelectionInput
                        marginTop={20}
                        feildName={'studentId'}
                        selectName={'Student Name'}
                        onHandleChange={e => this.onHandleStudentChange(e)}
                        errors={errors}
                        optionsArrys={studentData}
                        selectedText={'Student Name'}
                        stateData={this.state}
                        // iconClassName={'fas fa-building'}
                        optionType="dynamicWithPropIdAndName"
                        property={'Student'}
                        propertyId={'studentId'}
                        successflag={'successflag'}
                        isValidte={false}
                    // editUse={'staffEdit'}
                    />

                    <TextInput
                        marginTop={20}
                        feildName={'contactNo'}
                        inputLabelName={'Contact Number'}
                        onHandleChange={this.onHandleTextChange}
                        errors={errors}
                        stateData={this.state}
                        placeholder={'Enter Contact #'}
                        isValidte={false}
                    />
                    <TextArea
                        feildName={'problem'}
                        inputLabelName={'Problem'}
                        onHandleChange={this.onHandleTextChange}
                        errors={errors}
                        stateData={this.state}
                        // iconClassName={'fas fa-percentage'}
                        placeholder={'Add Problem'}
                        isValidte={false}
                    />

                    <TextArea
                        feildName={'recommadationForParents'}
                        inputLabelName={'Recommendation'}
                        onHandleChange={this.onHandleTextChange}
                        errors={errors}
                        stateData={this.state}
                        // iconClassName={'fas fa-percentage'}
                        placeholder={'Add Recommendation'}
                        isValidte={false}
                    />

                    <ColorPalette
                        marginTop={20}
                        colors={general.medColorRange}
                        inputLabelName={'Medical Condition'}
                        onHandleSubmit={(e, item) => this.clicked(e, item)}
                    />
                </div>
            </div>

        );


        const Staff = (
            <div>
                <div className="row">

                    <SelectionInput
                        marginTop={20}
                        feildName={'departmentName'}
                        selectName={'Department'}
                        onHandleChange={e => this.onHandleDepartChange(e)}
                        errors={errors}
                        optionsArrys={departmentData}
                        selectedText={'Department'}
                        stateData={this.state}
                        // iconClassName={'fas fa-building'}
                        optionType="dynamicWithPropIdAndName"
                        property={'departmentName'}
                        propertyId={'departmentId'}
                        successflag={'successflag'}
                        isValidte={false}
                    // editUse={'staffEdit'}
                    />

                    <SelectionInput
                        marginTop={20}
                        feildName={'postId'}
                        selectName={'Post'}
                        onHandleChange={e => this.onHandlePostChange(e)}
                        errors={errors}
                        optionsArrys={postData}
                        selectedText={'Post'}
                        stateData={this.state}
                        // iconClassName={'fas fa-building'}
                        optionType="dynamicWithPropIdAndName"
                        property={'postName'}
                        propertyId={'postId'}
                        successflag={'successflag'}
                        isValidte={false}
                    // editUse={'staffEdit'}
                    />
                    <SelectionInput
                        marginTop={20}
                        feildName={'staffId'}
                        selectName={'Staff Name'}
                        onHandleChange={e => this.onHandleStaffChange(e)}
                        errors={errors}
                        optionsArrys={staffData}
                        selectedText={'Staff Name'}
                        stateData={this.state}
                        // iconClassName={'fas fa-building'}
                        optionType="dynamicWithPropIdAndName"
                        property={'staffName'}
                        propertyId={'staffId'}
                        successflag={'successflag'}
                        isValidte={false}
                    // editUse={'staffEdit'}
                    />
                    <TextInput
                        marginTop={20}
                        feildName={'contactNo'}
                        inputLabelName={'Contact Number'}
                        onHandleChange={this.onHandleTextChange}
                        errors={errors}
                        stateData={this.state}
                        placeholder={'Enter Contact #'}
                        isValidte={false}
                    />
                    <TextArea
                        feildName={'problem'}
                        inputLabelName={'Problem'}
                        onHandleChange={this.onHandleTextChange}
                        errors={errors}
                        stateData={this.state}
                        // iconClassName={'fas fa-percentage'}
                        placeholder={'Add Problem'}
                        isValidte={false}
                    />
                </div>
            </div>
        );

        const Form = (
            <FormLayoutWrapper
                formName='Add Patient'
                borderColor='#01ac8a'
                borderRadius={15}
            >
                {this.state.errorMsg ? (
                    <p style={{ color: 'red', textAlign: 'center' }}>
                        Please fill the form properly
                    </p>
                ) : (
                        ''
                    )}
                <div class='row'>
                    <div class='col-md-12 d-flex justify-content-center'>
                        <SelectionInput
                            marginTop={20}
                            feildName={'doctorId'}
                            selectName={'Doctor Name'}
                            onHandleChange={e => this.onHandleSelectChange(e)}
                            errors={errors}
                            optionsArrys={doctorData}
                            selectedText={'Doctor Name'}
                            stateData={this.state}
                            // iconClassName={'fas fa-building'}
                            optionType="dynamicWithPropIdAndName"
                            property={'doctorName'}
                            propertyId={'id'}
                            successflag={'successflag'}
                            isValidte={false}
                        // editUse={'staffEdit'}
                        />
                    </div>
                </div>
                <br />
                <div class='col-md-12'>
                    <form class='form-inline' style={{ marginBottom: 25 }}>
                        <div class='col-md-12 form-inline d-flex justify-content-around'>
                            <label style={{ color: '#007b5e', fontWeight: 'bold', marginRight: 10, fontSize: 20 }}>
                                <input
                                    style={{ width: 20, height: 20 }}
                                    type="radio"
                                    value='Student'
                                    name={'Form'}
                                    onChange={e => this.handleOptionChange(e)}
                                />
                                Student</label>
                            <label style={{ color: '#007b5e', fontWeight: 'bold', marginRight: 10, fontSize: 20 }}>
                                <input
                                    style={{ width: 20, height: 20 }}
                                    type="radio"
                                    value='Staff'
                                    name={'Form'}
                                    onChange={e => this.handleOptionChange(e)}
                                />
                                Staff</label>
                        </div>
                    </form>
                </div>
                {studentStaff === 'Staff' ? Staff : studentStaff === 'Student' ? Student : ''}
                <br />
                <Button
                    width="100%"
                    buttonClass="customButton"
                    btnName="Add"
                    loaderBmargin={5}
                    stateData={this.state}
                    marginTop={20}
                    onHandleSubmit={this.onSubmit}
                    margin={'0 auto'}
                    textColor={'white'}
                />

            </FormLayoutWrapper>
        )
        return (
            <React.Fragment>
                <SnackBar msg={this.state.msg} />
                {isLoadingInitialData ? <Spinner /> : Form}
            </React.Fragment>
        )
    }


}

function mapStateToProps(state) {
    return {
        role: state.setUserReducer.users.role,
        name: state.setUserReducer.users.unique_name,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        sharedActions: bindActionCreators(sharedActions, dispatch)
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddPatient);