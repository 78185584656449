import React, { Component } from 'react';
import SnackBar from '../../components/SnackBar/SnackBar';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as CategoryWiseActions from '../../Actions/CategoryWiseSearch';
import * as sharedActions from '../../Actions/sharedActions';
import ProgressBarComponent from './ProgressBarComponent';
import $ from 'jquery';

class CategorySubComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			adminData: [],
			categoryName: '',
			msg: '',
			errorMsg: '',
			currentSession: '',
			male: '0',
			female: '0',
			total: '0',
			check: ''
		};
	}
	onSelectCategoryChange = e => {
		console.log(e.target.value);

		this.setState({
			categoryName: e.target.value
		});
	};
	onSelectSessionChange = e => {
		console.log(e.target.value);

		this.setState({
			currentSession: e.target.value
		});
		console.log(this.state);
	};
	onHandleSubmit = e => {
		this.props.sharedActions
			.getAllData(
				`/api/Census/?category=${this.state.categoryName}&currentSessionId=${this.state.currentSession}`,
				this.props.CategoryWiseActions.setAllCategoryWiseInRedux,
				this.props.CategoryWiseActions.updateCategoryWiseFlag
			)
			.then(success => {
				this.setState({
					msg: true,
					errorMsg: false,
					male: this.state.adminData.Male,
					female: this.state.adminData.Female,
					total: this.state.adminData.Total
				});
			})
			.catch(err => {
				// this.props.CensusActions.updateCensusFlag(false);
				this.setState({
					msg: false,
					errorMsg: true,
					male: '0',
					female: '0',
					total: '0'
				});

				console.log(err);
			});
		console.log(this.state);
	};
	static getDerivedStateFromProps(nextProps, prevState) {
		if (prevState.adminData !== nextProps.AllTransportData) {
			return {
				adminData: nextProps.AllTransportData
			};
		}
		return null;
	}
	componentDidUpdate() {
		if (this.state.msg || this.state.errorMsg) {
			setTimeout(() => {
				this.setState({
					msg: false,
					errorMsg: false
				});
			}, 3000);
		}
		$('[data-toggle="tooltip"]').tooltip();
	}
	Message = (msg, color, fontSize, flag) => {
		if (flag) {
			return (
				<p style={{ fontSize: fontSize }} class={`text-${color}`}>
					{msg}
				</p>
			);
		}
	};
	btnTechHover = () => {
		$(document).ready(function() {
			$('button').hover(
				function() {
					$(this).css('box-shadow', '0px 5px 5px grey');
					$(this).css('border-color', 'white');
				},
				function() {
					$(this).css('box-shadow', '0px 0px 0px white');
				}
			);
		});
	};
	onHandleTextChange = e => {
		console.log(e.target.value);
		this.setState({
			[e.target.name]: e.target.value
		});
	};
	render() {
		const { session } = this.props;
		const { adminData } = this.state;
		console.log('Data he Data', adminData);
		return (
			<div>
				<div className="page-header">
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between'
						}}
					>
						<div>
							<h2>Search&nbsp;</h2>
						</div>
						<div>
							<h3>
								<small class="text-muted">Category Wise</small>
							</h3>
						</div>
					</div>
				</div>

				<fieldset
					style={{ border: 'solid', borderColor: '#01AC8A', height: 'auto' }}
				>
					<legend class="scheduler-border">Information:</legend>
					<div class="row" style={{ justifyContent: 'center' }}>
						<div class="col-md-4" style={{ marginTop: 20 }}>
							<div class="md-form mb-0">
								<label for="email" class="">
									<i
										style={{ marginRight: 4 }}
										className="fas fa-bars animated fadeIn"
									/>
									Session's
								</label>
								<select
									onChange={this.onSelectSessionChange}
									class="custom-select"
									name="sectionName"
								>
									<option selected={this.state.msg ? true : false}>
										Select Current Session..
									</option>
									{session &&
										session.map((item, index) => (
											<option value={`${item.sessionId}`}>
												{item.currentSession}
											</option>
										))}
								</select>
							</div>
						</div>
						<div class="col-md-4" style={{ marginTop: 20 }}>
							<div class="md-form mb-0">
								<label for="email" class="">
									<i
										style={{ marginRight: 4 }}
										className="fas fa-school animated fadeIn"
									/>
									Category Name
								</label>
								<select
									onChange={this.onSelectCategoryChange}
									class="custom-select"
									name="studentId"
								>
									<option selected={this.state.msg ? true : false}>
										Choose Category..
									</option>
									<option value="Full Fees">Full Fees</option>
									<option value="Concession">Concession</option>
								</select>
							</div>
						</div>
					</div>
					<br />
					<div class="text-center text-md-right">
						<a href="#" data-toggle="tooltip" title="Search!">
							<button
								id="firstbutton"
								onClick={this.onHandleSubmit}
								onMouseOver={this.btnTechHover()}
								style={{
									cursor: 'pointer',
									fontSize: 20,
									border: 'none',
									marginLeft: 5,
									background: 'none',
									marginBottom: 5
								}}
							>
								<i class="fas fa-search fa-2x"></i>
							</button>
						</a>
					</div>
				</fieldset>
				<br />
				{this.Message('No Record Found!', 'danger', 20, this.state.errorMsg)}
				<div class="row">
					<div class="col-md-4">
						<div class="md-form mb-0">
							<ProgressBarComponent percentage={this.state.male} />
							<label style={{ fontWeight: 'bold', fontSize: 20 }}>
								Male Students
							</label>{' '}
						</div>
					</div>
					<div class="col-md-4">
						<div class="md-form mb-0">
							<ProgressBarComponent percentage={this.state.female} />
							<label style={{ fontWeight: 'bold', fontSize: 20 }}>
								Female Students
							</label>{' '}
						</div>
					</div>
					<div class="col-md-4">
						<div class="md-form mb-0">
							<ProgressBarComponent percentage={this.state.total} />
							<label style={{ fontWeight: 'bold', fontSize: 20 }}>
								Total Students
							</label>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch),
		CategoryWiseActions: bindActionCreators(CategoryWiseActions, dispatch)
	};
}

function mapStateToProps(state) {
	return {
		userData: state.setUserReducer.users,
		AllTransportData: state.setCategoryWiseReducer.Data,
		allTransportState: state.setCategoryWiseReducer
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CategorySubComponent);
