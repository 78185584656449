import React, { useState, useEffect } from "react";
import ThemeContext from "./ThemeContext";
import Colors from "../../utils/app_constants/colors_constants";

export default function ThemeGlobalContext(props) {
  const [themeColors, setThemeColor] = useState({
    primaryColor: Colors.THIRD_PRIMARY_COLOR,
    secondryColor: Colors.THIRD_PRIMARY_COLOR_TWO,
    WhiteColor: Colors.WHITE,
    BlackColor: Colors.BLACK,
    RedColor: Colors.RED_DANGER,
    SuccessGreen: Colors.LIGHT_GREEN,
    btnDisableClass: "disabledButton",
    btnEnabledClass: "enableButton",
  });

  return (
    <ThemeContext.Provider
      value={{
        themeColors,
        setThemeColor,
      }}
    >
      {props.children}
    </ThemeContext.Provider>
  );
}
