import * as actionTypes from '../actionTypes';

export function setAllStaffEnquiryInRedux(allData) {
	return {
		type: actionTypes.GET_ALL_STAFF_ENQUIRY,
		payload: allData
	};
}
export function getClassesForStaffEnquiry(allData) {
	return {
		type: actionTypes.SET_ALL_CLASS_NAME_INTO_STAFF_ENQUIRY_SELECT,
		payload: allData
	};
}
export function getDepartmentForStaffEnquiry(allData) {
	return {
		type: actionTypes.SET_ALL_DEPARTMENT_NAME_INTO_STAFF_ENQUIRY_SELECT,
		payload: allData
	};
}

export function getClassesForStaffEnquirySearch(allData) {
	return {
		type: actionTypes.GET_CLASSES_FOR_STAFF_ENQUIRY_SEARCH,
		payload: allData
	};
}

export function getAdmittedStaffEnquiryData(allData) {
	return {
		type: actionTypes.GET_ADMITTED_STAFF_ENQUIRY_DATA,
		payload: allData
	};
}

export function getIndividualStaffForEnquiry(allData) {
	return {
		type: actionTypes.GET_INDIVIDUAL_STAFF_ENQUIRY_DATA,
		payload: allData
	};
}
export function addStaffEnquiry(Data) {
	return {
		type: actionTypes.ADD_STAFF_ENQUIRY,
		payload: Data
	};
}
export function updateStaffEnquiryFlag(setFlag) {
	return {
		type: actionTypes.SET_LOADER_FLAG_STAFF_ENQUIRY_ADD,
		payload: setFlag
	};
}
export function updateStaffAdmittedFlag(setFlag) {
	return {
		type: actionTypes.SET_LOADER_FLAG_STAFF_ADMITTED_ADD,
		payload: setFlag
	};
}
// export function updateStudentEnquiry(data) {
//
//     return {
//         type: actionTypes.EDIT_STUDENT_ENQUIRY_RECORD,
//         payload: data
//     };
// }
export function updateAdmittedStaff(data) {
	return {
		type: actionTypes.EDIT_STAFF_ADMITTED_RECORD,
		payload: data
	};
}
// export function deleteStudentEnquiry(id) {
//
//     return {
//         type: actionTypes.DELETE_STUDENT_ENQUIRY,
//         payload: id
//     };
// }
