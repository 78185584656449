import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as SkillAction from "../../Actions/StudentSkill";
import * as sharedActions from "../../Actions/sharedActions";
import SnackBar from "../../components/SnackBar/SnackBar";
import { Link } from "react-router-dom";
import $ from "jquery";
import ThemeContext from "../../context/themeContext/ThemeContext";

class StudentSkillView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SkillReports: [],
      adminData: [],
      modalData: null,
      vehicleNo: "",
      capacity: "",
      transportType: "",
      enabled: false,
      id: null,
      select: [],
      efforts: "",
      participation: "",
      average: "",
      academicProgress: "",
      studentName: "",
      isLoading: true,
      disablepage: false,
      disableBPage: true,
      currentPage: 1,
      todosPerPage: 10,
      pageCounter: 10,
      msg: "",
      errorMsg: "",
      increament: 1,
      selectClassData: "",
      classId: "",
      sectionId: "",
      sections: [],
      subjects: [],
      session: [],
      sessionId: "",
      subjectId: "",
      staffId: "",
      term: "",
      btnLoad: false,
      exist: false,
      checking: [],
      searchCheck: true,
      multiple: [],
    };
  }
  handleClickNextFrwd = () => {
    const { adminData, todosPerPage, count, currentPage } = this.state;
    let pageNumbers = [];
    for (
      let i = 1;
      i <= Math.ceil(adminData.length / this.state.todosPerPage);
      i++
    ) {
      pageNumbers.push(i);
    }
    console.log("pagelength: ", pageNumbers.length);
    console.log("current page: ", currentPage);
    if (currentPage === pageNumbers.length) {
      this.setState({
        disablepage: true,
      });
    } else {
      this.setState((prevState) => ({
        currentPage: prevState.currentPage + 1,
        disableBPage: false,
      }));
    }
  };
  handleClickNextBack = () => {
    const { adminData, todosPerPage, count, currentPage } = this.state;
    console.log("current page: ", currentPage);
    if (currentPage === 1) {
      this.setState({
        disableBPage: true,
        disablepage: false,
      });
    } else {
      this.setState((prevState) => ({
        currentPage: prevState.currentPage - 1,
      }));
      this.setState({
        disablepage: false,
      });
    }
  };
  handleClickNext = (event) => {
    this.setState({
      currentPage: Number(event.target.id),
      disablepage: false,
      disableBPage: false,
    });
  };
  // static getDerivedStateFromProps(nextProps, prevState) {
  //     debugger
  //     if (prevState.adminData !== nextProps.AllTransportData || prevState.selectClassData !== nextProps.selectData
  //         || prevState.session !== nextProps.SessionSelect) {

  //         return {
  //             adminData: nextProps.AllTransportData,
  //             selectClassData: nextProps.selectData,
  //             session: nextProps.SessionSelect
  //         };
  //     }
  //     return null;
  // }
  onSelectChange = (e) => {
    console.log(e.target.value);

    const sections = this.state.selectClassData.staffClassSection;
    let check = sections.filter((item) => item.classId == e.target.value);
    console.log(check);

    this.setState({
      sections: check,
      staffId: check[0].staffId,
      classId: e.target.value,
    });
  };
  onSectionChange = (e) => {
    console.log(e.target.value);
    const sections = this.state.selectClassData.staffClassSection;
    let check2 = sections.filter(
      (item) =>
        item.classId == this.state.classId && item.sectionId == e.target.value
    );
    this.setState({
      subjects: check2,
      sectionId: e.target.value,
    });
    console.log(this.state.staffId);
  };
  onSubjectChange = (e) => {
    console.log(e.target.value);
    this.setState({
      subjectId: e.target.value,
    });
  };
  componentDidUpdate() {
    $('[data-toggle="tooltip"]').tooltip("hide");

    if (this.state.msg || this.state.errorMsg || this.state.exist) {
      setTimeout(() => {
        this.setState({
          msg: false,
          errorMsg: false,
          exist: false,
        });
      }, 3000);
    }
  }
  componentDidMount() {
    $(document).ready(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });
    this.props.sharedActions
      .getDataWithoutDispatch(
        `/api/StudentDetail?userName=${this.props.users.unique_name}`
      )
      .then((success) => {
        this.setState({
          adminData: success,
          searchCheck: false,
        });
      })
      .catch((error) => {
        this.setState({
          searchCheck: true,
        });
        console.log(error);
      });
    // this.props.sharedActions.getAllData(`/api/Assignments/getassign/Student/?Classid=0&secid=0`, this.props.AssignmentActions.getParticularAssignment, this.props.AssignmentActions.updateAssignmentFlag)
    //     .then((success) => {
    //
    //         this.setState({
    //             isLoading: false,
    //         })
    //     }).catch((err) => {
    //         this.props.AssignmentActions.updateAssignmentFlag(false);

    //         this.setState({
    //             isLoading: false
    //         });
    //
    //         console.log(err);
    //     })
  }
  onSearchStudent = (e) => {
    this.setState({
      btnLoad: true,
    });
    this.props.sharedActions
      .getAllData(
        `/api/studentSkillView/?classId=${this.state.classId}&sectionId=${this.state.sectionId}&term=${this.state.term}&sessionId=${this.state.sessionId}&subjectId=${this.state.subjectId}`,
        this.props.SkillAction.setAllSkillsViewInRedux,
        this.props.SkillAction.updateSkillFlagView
      )
      .then((success) => {
        this.setState({
          isLoading: false,
          btnLoad: false,
          searchCheck: false,
        });
      })
      .catch((err) => {
        this.props.SkillAction.updateSkillFlagView(false);

        this.setState({
          isLoading: false,
          btnLoad: false,
          searchCheck: true,
        });

        console.log(err);
      });
  };
  btnTechHover = () => {
    $(document).ready(function () {
      $("button").hover(
        function () {
          $(this).css("box-shadow", "0px 5px 5px grey");
          $(this).css("border-color", "white");
        },
        function () {
          $(this).css("box-shadow", "0px 0px 0px white");
        }
      );
    });
  };
  onTakeItemDelete = (itemId) => {
    this.setState({
      modalData: [],
    });
    this.props.sharedActions
      .deleteRecord(
        "/api/Vehicle/",
        itemId,
        this.props.routesActions.deleteVehicle
      )
      .then((success) => {
        this.setState({
          msg: "Delete Record Successfully!",
        });
        this.props.snackbar();
      })
      .catch((error) => {});
  };
  onTakeItem = (itemId) => {
    this.setState({
      modalData: [],
    });
    const { adminData } = this.state;
    let getData = adminData.filter((item) => item.skillReportId === itemId);

    this.setState({
      modalData: getData,
      efforts: getData[0].efforts,
      average: getData[0].average,
      academicProgress: getData[0].academicProgress,
      id: getData[0].skillReportId,
      studentName: getData[0].stduentName,
      participation: getData[0].participation,
    });
  };

  onHandleTextNumber = (e) => {
    console.log(e.target.value);
    if (e.target.value <= 5) {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
    console.log(this.state.effort);
  };
  onHandleText = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onHandleSubmit = (e) => {
    e.preventDefault();
    const { efforts, participation, academicProgress, id } = this.state;
    let data = { id, efforts, participation, academicProgress };

    this.props.sharedActions
      .editPatchRecord(
        "/api/editSkillReport/?id=",
        id,
        data,
        this.props.SkillAction.updateSkill
      )
      .then((success) => {
        this.setState({ enabled: false, msg: "Edit Record Successfully!" });
        // this.props.campusActions.updateCampusFlag(true);
      })
      .catch((err) => {
        console.log(err);
      });
    console.log(this.state);
  };
  onEditClick = (itemId) => {
    this.onTakeItem(itemId);
    this.setState({ enabled: false });
  };
  myFunction = () => {
    // Get the snackbar DIV
    var x = document.getElementById("snackbar");

    // StepperGeneralButtons the "show" class to DIV
    x.className = "show";

    // After 3 seconds, remove the show class from DIV
    setTimeout(function () {
      x.className = x.className.replace("show", "");
    }, 3000);
  };

  onClickDeleteItem = (arrName) => {
    let getStateArr = this.state[`${arrName}`];
    let getDeleteLength = getStateArr.length - 1;
    if (getDeleteLength > 0) {
      let getNewDetails = getStateArr.filter(
        (item, index) => index !== getDeleteLength
      );
      console.log(getNewDetails);
      this.setState({
        [arrName]: [...getNewDetails],
      });
    }
  };

  onClickAddItem = (arrName, obj) => {
    let getStateArr = this.state[`${arrName}`];
    getStateArr = [...getStateArr, obj];
    console.log(arrName);
    this.setState({
      [arrName]: getStateArr,
    });
  };
  updateSpecialArrObjOnChange = (e, index, arrName) => {
    if (e.target.value <= 5) {
      const changeArrOfObj = this.state[`${arrName}`];
      changeArrOfObj[index][e.target.name] = e.target.value;
      this.setState({
        [arrName]: changeArrOfObj,
      });
    }
  };
  onChangeText = (e) => {
    console.log(e.target.value);
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onChangingData = (index, event) => {
    let dataStrcuture = [
      {
        studentId: 1,
        studentName: "Muhammad Hamza",
        effort: "",
        average: "",
        participation: "",
      },
    ];

    const { multiple } = this.state;
    var check = [...multiple];
    check[index][event.target.name] = event.target.value;
    this.setState({ multiple: check });
  };
  render() {
    const { adminData, modalData, select } = this.state;
    console.log("Daata", this.state.SkillReports);
    console.log("Admin Data", this.state.adminData);
    console.log("Classes Ka Data", this.state.selectClassData);
    console.log("Session Ka Data", this.state.session);
    const { session } = this.state.session;
    const { classes } = this.state.selectClassData;
    // const { studentEnuiry } = this.state.adminData
    // const { paginationMetadata } = this.state.adminData
    // console.log("Pagination Ka Data", paginationMetadata);
    // console.log("Pagination Checking Ka Data", this.state.checking);

    console.log("modal data: ", this.state.modalData);
    const Loader = (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div class="spinner-border" style={{ color: "#01AC8A" }}></div>
      </div>
    );
    let pageNumbers = [];
    for (
      let i = 1;
      i <=
      Math.ceil(this.state.checking.totalCount / this.state.checking.pageSize);
      i++
    ) {
      pageNumbers.push(i);
    }
    const renderPageNumbers = pageNumbers.map((number, index) => {
      return (
        <li
          class={`page-item ${
            this.state.checking.currentPage === number ? "active" : ""
          } `}
        >
          <a
            key={number}
            id={number}
            onClick={() => this.paginationRequest(number)}
            class="page-link "
            href="#"
          >
            {number}
          </a>
        </li>
      );
    });
    // const { currentPage, todosPerPage } = this.state;
    // const indexOfLastTodo = currentPage * todosPerPage;
    // const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
    // const currentTodos = adminData.slice(indexOfFirstTodo, indexOfLastTodo);
    const renderTodos = adminData.map((item, index) => {
      return (
        <ThemeContext.Consumer>
          {(context) => (
            <tr key={index}>
              <th scope="row" style={{ padding: 10 }}>
                {index + 1}
              </th>
              <td style={{ padding: 10 }}>{item.name}</td>
              <td style={{ padding: 10 }}>{item.className}</td>
              <td style={{ padding: 10 }}>{item.section}</td>
              <td style={{ padding: 10 }}>{item.session}</td>
              <td style={{ padding: 10 }}>{item.status}</td>
              <td style={{ padding: 10 }}>
                <div class="col-md-12">
                  <div class="md-form mb-0">
                    <select
                      style={{ borderColor: context.themeColors.primaryColor }}
                      onChange={this.onHandleText}
                      class="custom-select"
                      name="term"
                    >
                      <option>Choose Term..</option>
                      <option value="1st">1st Term</option>
                      <option value="2nd">2nd Term</option>
                      <option value="3rd">3rd Term</option>
                    </select>
                  </div>
                </div>
              </td>
              {this.state.term !== "" ? (
                <td className="bordersSpace">
                  <Link
                    to={`/dashboard/PrintSkillReport/${item.studentId}/${item.classId}/${item.sectionId}/${this.state.term}/${item.sessionId}`}
                  >
                    <a
                      id="tooltipId"
                      data-toggle="tooltip"
                      title="Print Report"
                    >
                      <button
                        onMouseOver={this.btnTechHover()}
                        style={{
                          cursor: "pointer",
                          fontSize: 20,
                          border: "none",
                          background: "none",
                          marginLeft: 5,
                          marginBottom: 5,
                        }}
                      >
                        <i style={{ color: "#01AC8A" }} class="fas fa-list"></i>
                      </button>
                    </a>
                  </Link>
                </td>
              ) : (
                ""
              )}
            </tr>
          )}
        </ThemeContext.Consumer>
      );
    });
    const MainContent = (
      <div>
        <div class="modal" id="myModal1">
          <div class="modal-dialog">
            <div class="modal-content modal_content_custom">
              <div
                style={{
                  borderBottomLeftRadius: 30,
                  borderBottomRightRadius: 30,
                  borderTopLeftRadius: 18,
                  borderTopRightRadius: 18,
                }}
                class="modal-header modal_custom_header"
              >
                <h4 style={{ color: "white" }} class="modal-title">
                  Confirm
                </h4>
                <button
                  style={{ color: "white" }}
                  type="button"
                  class="close"
                  data-dismiss="modal"
                >
                  &times;
                </button>
              </div>
              <div class="modal-body">
                {modalData &&
                  modalData.map((item, index) => (
                    <div key={index}>
                      <div style={{ textAlign: "center" }}>
                        <h4>Are you sure ?</h4>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <div>
                          <button
                            disabled={this.state.enabled}
                            data-dismiss="modal"
                            onClick={() => this.onTakeItemDelete(this.state.id)}
                            type="button"
                            class="btn btn-primary buttonAppear"
                          >
                            Yes
                          </button>
                        </div>
                        <div>
                          <button
                            data-dismiss="modal"
                            disabled={this.state.enabled}
                            type="button"
                            class="btn btn-primary buttonAppear"
                          >
                            No
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <div class="modal" id="myModal">
          <div class="modal-dialog">
            <div class="modal-content modal_content_custom">
              <div
                style={{
                  borderBottomLeftRadius: 30,
                  borderBottomRightRadius: 30,
                  borderTopLeftRadius: 18,
                  borderTopRightRadius: 18,
                }}
                class="modal-header modal_custom_header"
              >
                <h4 style={{ color: "white" }} class="modal-title">
                  Details
                </h4>
                <button
                  style={{ color: "white" }}
                  type="button"
                  class="close"
                  data-dismiss="modal"
                >
                  &times;
                </button>
              </div>
              <div class="modal-body">
                <form onSubmit={this.onHandleSubmit}>
                  {modalData &&
                    modalData.map((item, index) => (
                      <div key={index}>
                        <fieldset disabled={!this.state.enabled}>
                          <div class="form-group">
                            <label for="campusName">Student Name:</label>
                            <input
                              readOnly={true}
                              style={{
                                borderTop: 0,
                                borderLeft: 0,
                                borderRight: 0,
                                boxShadow: "none",
                              }}
                              type="text"
                              value={this.state.studentName}
                              onChange={this.onHandleText}
                              name="campusName"
                              class="form-control"
                              id="campusName"
                            />
                          </div>
                          <div class="form-group">
                            <label for="Campus City">Effort</label>
                            <input
                              required
                              style={{
                                borderTop: 0,
                                borderLeft: 0,
                                borderRight: 0,
                                boxShadow: "none",
                              }}
                              value={this.state.efforts}
                              onChange={this.onHandleTextNumber}
                              type="number"
                              name="efforts"
                              class="form-control"
                              id="campusCity"
                            />
                          </div>
                          <div class="form-group">
                            <label for="Campus Address">Participation</label>
                            <input
                              required
                              style={{
                                borderTop: 0,
                                borderLeft: 0,
                                borderRight: 0,
                                boxShadow: "none",
                              }}
                              value={this.state.participation}
                              onChange={this.onHandleTextNumber}
                              type="number"
                              name="participation"
                              class="form-control"
                              id="campusAddress"
                            />
                          </div>
                          <div class="form-group">
                            <label for="Campus Phone">Academic Progress</label>
                            <input
                              required
                              style={{
                                borderTop: 0,
                                borderLeft: 0,
                                borderRight: 0,
                                boxShadow: "none",
                              }}
                              value={this.state.academicProgress}
                              onChange={this.onHandleTextNumber}
                              type="number"
                              name="academicProgress"
                              class="form-control"
                              id="campusPhoneNumber"
                            />
                          </div>
                        </fieldset>
                        <button
                          disabled={this.state.enabled}
                          onClick={() => this.setState({ enabled: true })}
                          type="button"
                          class="btn btn-primary buttonAppear"
                        >
                          Edit
                        </button>
                        <button
                          style={{ marginLeft: 5 }}
                          disabled={!this.state.enabled}
                          type="submit"
                          class="btn btn-primary buttonAppear"
                        >
                          Save
                        </button>
                      </div>
                    ))}
                </form>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  style={{ background: "#00435D" }}
                  class="btn btn-danger"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="table-responsive">
          <ThemeContext.Consumer>
            {(context) => (
              <table class="table table-hover text-center table-bordered">
                <thead
                  style={{
                    background: context.themeColors.primaryColor,
                    color: "white",
                  }}
                >
                  <tr>
                    <th scope="col" style={{ padding: 2 }}>
                      #
                    </th>
                    <th scope="col" style={{ padding: 2 }}>
                      Student Name
                    </th>
                    <th scope="col" style={{ padding: 2 }}>
                      Class
                    </th>
                    <th scope="col" style={{ padding: 2 }}>
                      Section
                    </th>
                    <th scope="col" style={{ padding: 2 }}>
                      Session
                    </th>
                    <th scope="col" style={{ padding: 2 }}>
                      Status
                    </th>
                    <th scope="col" style={{ padding: 2 }}>
                      Term
                    </th>
                    {this.state.term !== "" ? (
                      <th scope="col" style={{ padding: 2 }}>
                        Action
                      </th>
                    ) : (
                      ""
                    )}
                  </tr>
                </thead>
                <tbody>{renderTodos}</tbody>
              </table>
            )}
          </ThemeContext.Consumer>
        </div>
        {this.state.errorMsg ? (
          <p style={{ color: "red", textAlign: "center" }}>
            Grading Marks Already Exist For This Term
          </p>
        ) : (
          ""
        )}
        {this.state.msg ? (
          <p style={{ color: "green", textAlign: "center" }}>
            Added Successfully!
          </p>
        ) : (
          ""
        )}
        {this.state.exist ? (
          <p style={{ color: "red", textAlign: "center" }}>
            Please Fill the Fields
          </p>
        ) : (
          ""
        )}
        {/* <MultipleSkills onHandleTextChange={this.onChangeText}
											stateData={this.state}
											updateSpecialArrObjOnChange={
												this.updateSpecialArrObjOnChange
											}
											onClickAddItem={this.onClickAddItem}
                                            onClickDeleteItem={this.onClickDeleteItem}
                                            onChanging={this.onChangingData}
                                            session={this.state.session}
                                            />
                                             <div class="text-center text-md-right">
                    <button type="submit" class="btnss" onClick={this.onHandleSubmit}>
                        Submit
                                        <span style={{ marginBottom: 5 }} class={`${this.state.btnLoad ? 'spinner-border spinner-border-sm' : ''}`}></span>
                    </button>
                </div> */}
      </div>
    );
    return (
      <div>
        <div className="page-header">
          <SnackBar msg={this.state.msg} />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div>
              <h2>Your Skills Report</h2>
            </div>
            <nav aria-label="Page navigation example">
              <ul style={{ color: "#01AC8A" }} class="pagination">
                {/* {renderPageNumbers} */}
              </ul>
            </nav>
          </div>
        </div>
        <br />
        {this.state.searchCheck ? Loader : MainContent}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    SkillAction: bindActionCreators(SkillAction, dispatch),
    sharedActions: bindActionCreators(sharedActions, dispatch),
  };
}
function mapStateToProps(state) {
  return {
    AllTransportData: state.setStudentSkillReducer.DataP,
    selectData: state.setStudentSkillReducer.selectStudent,
    SessionSelect: state.setStudentSkillReducer.selectSession,
    allTransportState: state.setStudentSkillReducer,
    users: state.setUserReducer.users,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(StudentSkillView);
