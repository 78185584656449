import React from "react";
import { Types } from "../../hooks/HookReducers/StaffReducer";
import CustomDatePicker from "../../components/CustomDatePicker";
import { startCase } from "lodash";

const RenderDynamicSiblingsRow = (props) => {
  const {
    itemObj,
    onHandleTextChange,
    stateData,
    onDateChange,
    rowIndex,
    updateSpecialArrObjOnChange,
    arrName,
    hookReuse = false,
    dispatch,
  } = props;

  let keys = Object.keys(itemObj);
  // console.log(stateData.siblingsDetails[0]['age'])
  if (keys.includes("StudentSiblingDetailId") && keys.includes("studentId")) {
    let newKeys = keys.filter(
      (item) => item !== "studentId" && item !== "StudentSiblingDetailId"
    );
    keys = [...newKeys];
  }
  if (keys.includes("PreviousSchoolDetailId") && keys.includes("studentId")) {
    let newKeys = keys.filter(
      (item) => item !== "studentId" && item !== "PreviousSchoolDetailId"
    );
    keys = [...newKeys];
  }
  if (keys.includes("staffCertificateId") && keys.includes("staffId")) {
    let newKeys = keys.filter(
      (item) => item !== "staffCertificateId" && item !== "staffId"
    );
    keys = [...newKeys];
  }
  if (
    keys.includes("id") &&
    keys.includes("staffId") &&
    keys.includes("interviewId")
  ) {
    let newKeys = keys.filter(
      (item) => item !== "id" && item !== "staffId" && item !== "interviewId"
    );
    keys = [...newKeys];
  }
  if (
    keys.includes("id") &&
    keys.includes("staffId")
      ) {
    let newKeys = keys.filter(
      (item) => item !== "id" && item !== "staffId" && item !== "interviewId"
    );
    keys = [...newKeys];
  }

  if (
    keys.includes("id") &&
    keys.includes("classId") &&
    keys.includes("className") &&
    keys.includes("date") &&
    keys.includes("time") &&
    keys.includes("accountType") &&
    keys.includes("accountId")
  ) {
    let newKeys = keys.filter(
      (item) =>
        item !== "id" &&
        item !== "classId" &&
        item !== "className" &&
        item !== "date" &&
        item !== "time" &&
        item !== "accountType" &&
        item !== "accountId"
    );
    keys = [...newKeys];
  }

  return (
    <React.Fragment>
      {keys.map((item, index) =>
        item === "siblingpassDOI" ||
        item === "siblingpassDOE" ||
        item === "siblingIdcardDOI" ||
        item === "siblingIdcardDOE" ? (
          <td style={{ padding: 0 }}>
            <CustomDatePicker
              marginTop={0}
              feildName={item}
              onDateChange={onDateChange}
              reuse={true}
              rowIndex={rowIndex}
              arrName={arrName}
              // inputLabelName={item.inputLabelName}
              dispatchFlag={false}
              dispatch={dispatch}
              stateData={stateData}
              extraType={""}
            />
          </td>
        ) : (
          <td key={index}>
            <input
              type="text"
              placeholder={`Enter Field`}
              class="form-control"
              name={`${item}`}
              value={stateData[arrName][rowIndex][`${item}`]}
              onChange={
                hookReuse
                  ? (e) => onDateChange(e, item, rowIndex)
                  : (e) => updateSpecialArrObjOnChange(e, rowIndex, arrName)
              }
            />
          </td>
        )
      )}
    </React.Fragment>
  );
};
export default RenderDynamicSiblingsRow;
