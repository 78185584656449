import React, { useEffect, useState, useContext } from 'react'
import $ from 'jquery';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import SelectionInput from '../../components/SelectionInput/SelectionInput';
import Pagination from '../../components/Pagination/Pagination'
import Spinner from '../../components/Spinner/Spinner';
import httpWithRequiredSetData from '../../hooks/Services/httpWithRequiredSetData';
import Colors from '../../utils/app_constants/colors_constants';
import SnackBar from '../../components/SnackBar/SnackBar';
import DatePicker from 'react-date-picker';
import general from '../../utils/general'
import TextInput from '../../components/TextInput/TextInput'
import themeContext from "../../context/themeContext/ThemeContext";

let tHead = [
    'Student Name',
    'Class',
    'Section',
    'Ministry Code',
    'Recommendation',
    'Actions'
];

let renderFields = [
    'studentName',
    'className',
    'section',
    'ministryCode',
    'recommendation',
]

const InvestigationList = props => {

    const themeColor = useContext(themeContext);

    const [accountId, setAccountId] = useState(props.name)
    const [accountType, setAccountType] = useState(props.role)
    const [error, setError] = useState(false)
    const [update, setUpdate] = useState('')

    const [investigationData, setInvestigationData] = useState([])
    const [state, setState] = useState({
        studentId: '',
        classId: '',
        sectionId: '',
        sessionId: '',
        exclusionType: '',
        ministryCode: '',
        reason: '',
        file: '',
        fromDate: '',
        toDate: ''
    })
    const [modalData, setModalData] = useState([])
    const [id, setId] = useState('')
    const [msg, setSnackMsg] = useState('')

    const [searchURL, setSearchURL] = useState(
        `/api/InvestigationList/status/?pageNumber=1&pageSize=10`
    );


    const [disableBPage, setdisableBPage] = useState(true);
    const [customPageSize, setCustomPageSize] = useState('10');
    const [currentPage, setCurrentPage] = useState(1);
    const [pageNumber, setPageNumber] = useState([]);
    const [disablepage, setdisablepage] = useState(false);
    const [pagination, setPaginationData] = useState({});


    const [
        isLoading,
        fetchData,
        setFetchData,
        setIsLoading
    ] = httpWithRequiredSetData(searchURL, [], data => {
        let pageNumber = [];
        for (let i = 1; i <= data['paginationMetadata']['totalPages']; i++) {
            pageNumber.push(i);
        }
        setPageNumber(pageNumber);
        setInvestigationData(data['invesstigationList']);

        setPaginationData(data['paginationMetadata']);
    });


    const handleClickNextFrwd = () => {
        console.log('pagelength: ', pageNumber.length);
        console.log('current page: ', currentPage);
        if (currentPage === pageNumber.length) {
            setdisablepage(true);
        } else {
            setCurrentPage(prevState => prevState + 1);
            console.log(currentPage);

            setdisableBPage(false);
        }
    };

    useEffect(() => {
        if (msg || error) {
            setTimeout(() => {
                setError(false)
            }, 3000);
        }
    });

    useEffect(() => {
        console.log('Do something after counter has changed', currentPage);
        setIsLoading(true);
        props.sharedActions
            .getDataWithoutDispatch(
                `/api/InvestigationList/status/?pageNumber=${currentPage}&pageSize=${customPageSize}`)
            .then(success => {
                setInvestigationData(success['invesstigationList']);
                setIsLoading(false);
                let pageNumber = [];
                for (let i = 1; i <= success['paginationMetadata']['totalPages']; i++) {
                    pageNumber.push(i);
                }
                setPageNumber(pageNumber);
                setPaginationData(success['paginationMetadata']);
            })
            .catch(error => {
                setIsLoading(false);
                setInvestigationData([])
            });
    }, [currentPage, update, customPageSize]);

    const handleClickNext = event => {
        setCurrentPage(Number(event.target.id));
        setdisableBPage(false);
        setdisableBPage(false);
    };
    const handleClickNextBack = () => {
        if (currentPage === 1) {
            setdisableBPage(true);
            setdisablepage(false);
        } else {
            setCurrentPage(prevState => prevState - 1);
        }
    };

    const onChangePageSize = e => {
        if (e.target.value !== 'Page Entries') {
            setCustomPageSize(e.target.value);
            setCurrentPage(1);
        }
    };

    const onEditClick = id => {
        onTakeItem(id)
    };

    const onTakeItem = itemId => {
        setModalData([])
        let getData = investigationData.filter(item => item.id === itemId);

        setModalData(getData)
        setId(getData[0].id)

        setState(prevState => ({
            ...prevState,
            studentId: getData[0].studentId,
            classId: getData[0].classId,
            sectionId: getData[0].sectionId,
            sessionId: getData[0].sessionId,
            ministryCode: getData[0].ministryCode,
        }))
    };

    const onHandleSelectChange = e => {
        if (e.target.value !== 'Exclusion Type') {
            const target = e.target
            setState(prevState => ({
                ...prevState, [target.name]: target.value
            })
            )
        }
    }

    const onChangeFile = e => {

        const target = e.target

        setState(prevState => ({
            ...prevState, file: target.files[0]
        }))
    };

    const onDateChange = (e, target) => {
        const date = e

        if (target === 'toDate') {
            setState(prevState => ({
                ...prevState, toDate: date
            }))
        } else if (target === 'fromDate') {
            setState(prevState => ({
                ...prevState, fromDate: date
            }))
        }
    }

    const onHandleTextChange = e => {
        const target = e.target
        setState(prevState => ({
            ...prevState, [target.name]: target.value
        }))
    }

    const onHandleSubmit = (e) => {
        e.preventDefault();

        if (state['toDate'] === '' ||
            state['fromDate'] === '' ||
            state['file'] === '' ||
            state['reason'] === '' ||
            state['exclusionType'] === ''
        ) {
            setError(true)
        } else {

            const fromDate = general.dateToFormat(state['fromDate'])
            const toDate = general.dateToFormat(state['toDate'])

            let data = new FormData();
            data.append('studentId', state['studentId']);
            data.append('classId', state['classId'])
            data.append('sectionId', state['sectionId'])
            data.append('sessionId', state['sessionId'])
            data.append('exclusionType', state['exclusionType'])
            data.append('MinistryCode', state['ministryCode'])
            data.append('reason', state['reason'])
            data.append('accountId', accountId)
            data.append('accountType', accountType)
            data.append('file', state['file'])
            data.append('toDate', toDate)
            data.append('fromDate', fromDate)

            props.sharedActions
                .simpleAddRequest(
                    '/api/StudentExclusionRecords/',
                    data,
                )
                .then(success => {
                    setState({
                        studentId: '',
                        classId: '',
                        sectionId: '',
                        sessionId: '',
                        exclusionType: '',
                        ministryCode: '',
                        reason: '',
                        file: '',
                        fromDate: '',
                        toDate: ''
                    })
                    setUpdate(id)
                    $('#myModal').modal('toggle'); //or  $('#IDModal').modal('hide');
                    setSnackMsg('Student Exclusion Successful!')
                    props.snackbar();
                })
                .catch(err => { });
        }
    }

    const bodyRendering = data =>
        data &&
        data.map((item, index) => (
            <tr>
                <th
                    style={{
                        padding: 2,
                        border: `1px solid ${Colors.SEA_GREEN_THEME}`,
                        borderColor: themeColor.themeColors.primaryColor
                    }}
                    scope="row"
                >
                    {index + 1}
                </th>
                {renderFields.map((item1, index) => (
                    <td
                        style={{
                            fontWeight: `${index == 0 ? 'bold' : 'normal'}`,
                            border: `1px solid ${Colors.SEA_GREEN_THEME}`,
                            padding: 2,
                            borderColor: themeColor.themeColors.primaryColor
                        }}
                    >
                        {item[item1]}
                    </td>
                ))}
                <td className='displayElemet'
                    style={{
                        border: `1px solid ${Colors.SEA_GREEN_THEME}`,
                        padding: 2,
                        borderColor: themeColor.themeColors.primaryColor
                    }}
                >

                    <a href="#" data-toggle="tooltip" title="Conclude Exclusion!">
                        <button
                            id="firstbutton"
                            onClick={() => onEditClick(item.id)}
                            data-toggle="modal"
                            data-target="#myModal"
                            style={{
                                cursor: 'pointer',
                                fontSize: 20,
                                border: 'none',
                                marginLeft: 5,
                                background: 'none',
                                marginBottom: 5
                            }}
                        >
                            <i class="fas fa-calendar-times" style={{ color: '#01AC8A' }}></i>
                        </button>
                    </a>

                </td>
            </tr>
        ));

    return (
        <div>
            <SnackBar msg={msg} />
            <div class="modal" id="myModal">
                <div class="modal-dialog">
                    <div class="modal-content modal_content_custom">
                        <div
                            style={{
                                borderBottomLeftRadius: 30,
                                borderBottomRightRadius: 30,
                                borderTopLeftRadius: 18,
                                borderTopRightRadius: 18,
                                background: themeColor.themeColors.primaryColor
                            }}
                            class="modal-header modal_custom_header"
                        >
                            <h4 style={{ color: 'white' }} class="modal-title">
                                Details
								</h4>
                            <button
                                style={{ color: 'white' }}
                                type="button"
                                class="close"
                                data-dismiss="modal"
                            >
                                &times;
								</button>
                        </div>
                        <div class="modal-body">
                            {error ? (
                                <p style={{ color: 'red', textAlign: 'center' }}>
                                    Please fill the form properly
                                </p>
                            ) : (
                                    ''
                                )}

                            <div class='row'>
                                <div class="col-md-6 text-center" >
                                    <div class="md-form mb-0">
                                        <label for="email" class="">
                                            <p style={{ margin: 0, color: '#000' }}>
                                                From Date
                                </p>
                                        </label>
                                        <br />
                                        <DatePicker
                                            onChange={e => onDateChange(e, 'fromDate')}
                                            value={state['fromDate']}
                                            selectDateOnly
                                        />
                                    </div>
                                </div>

                                <div class="col-md-6 text-center" >
                                    <div class="md-form mb-0">
                                        <label for="email" class="">
                                            <p style={{ margin: 0, color: '#000' }}>
                                                To Date
                                </p>
                                        </label>
                                        <br />
                                        <DatePicker
                                            onChange={e => onDateChange(e, 'toDate')}
                                            value={state['toDate']}
                                            selectDateOnly
                                        />
                                    </div>
                                </div>

                                <SelectionInput
                                    newFlag="customWidth"
                                    useFlag={false}
                                    feildName={'exclusionType'}
                                    selectName={'Exclusion Type'}
                                    onHandleChange={e => onHandleSelectChange(e)}
                                    errors={{}}
                                    optionsArrys={general.committeeHead}
                                    selectedText={'Exclusion Type'}
                                    stateData={state}
                                    // iconClassName={'fas fa-building'}
                                    optionType="static"
                                    successflag={'successflag'}
                                    isValidte={false}
                                // editUse={'staffEdit'}
                                />

                                <div class="col-md-12" style={{ marginTop: 30 }}>
                                    <div class="md-form mb-0">
                                        <label for="email" class=""
                                            style={{
                                                fontSize: 17,
                                                color: '#000',
                                                fontWeight: 'normal'
                                            }}   >
                                            File
											</label>

                                        <div class="file-upload">
                                            <div class="file-select">
                                                <div class="file-select-button" id="fileName">
                                                    Choose File
													</div>
                                                <div class="file-select-name" id="noFile">
                                                    {state.file === '' ? 'No File Choosen' : state.file.name}
                                                </div>
                                                <input
                                                    type="file"
                                                    name="file"
                                                    id="file"
                                                    onChange={onChangeFile}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <TextInput
                                    customWidth={true}
                                    enterWidth={'300px'}
                                    feildName={'reason'}
                                    inputLabelName={'Reason'}
                                    onHandleChange={onHandleTextChange}
                                    errors={{}}
                                    stateData={state}
                                    placeholder={'Reason'}
                                    isValidte={false}
                                />

                            </div>
                        </div>
                        <div class="modal-footer">
                            <button
                                onClick={e => onHandleSubmit(e)}
                                type="button"
                                style={{ background: themeColor.themeColors.primaryColor }}
                                class="btn btn-danger"
                            >
                                Confirm Exclusion
								</button>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ textAlign: 'right' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        <h2 className="h1-responsive font-weight-bold text-left my-4 generalHead" style={{background: themeColor.themeColors.primaryColor}}>
                            {' '}
                            Investigation List Details{' '}
                        </h2>
                    </div>
                    <div
                        style={{ marginTop: 40, display: 'flex', flexDirection: 'column' }}
                    >
                        <Pagination
                            disableBPage={disableBPage}
                            disablepage={disablepage}
                            currentPage={currentPage}
                            pageNumber={pageNumber}
                            handleClickNext={handleClickNext}
                            handleClickNextBack={handleClickNextBack}
                            handleClickNextFrwd={handleClickNextFrwd}
                        />
                        <div
                            style={{
                                width: 'fit-content',
                                marginTop: -34,
                                display: 'flex',
                                justifyContent: 'flex-end',
                                marginLeft: 'auto'
                            }}
                        >
                            <SelectionInput
                                selectName="Entries"
                                selectFont={14}
                                selectFontWeight={'bold'}
                                newFlag="customWidth"
                                feildName="customPageSize"
                                selectedText={'Page Entries'}
                                optionsArrys={['10', '20', '30', '40', '50']}
                                errors={{}}
                                isValidte={false}
                                stateData={{
                                    customPageSize: customPageSize
                                }}
                                optionType="static"
                                onHandleChange={onChangePageSize}
                                useFlag={false}
                            />
                        </div>
                    </div>
                </div>
                <br />
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span className="customBadge" style={{ fontSize: 14 }}>
                        Page: {currentPage}
                    </span>

                    <span className="customBadge" style={{ fontSize: 14 }}>
                        Total Page: {pagination['totalPages']}
                    </span>
                </div>
            </div>
            <br />
            {isLoading ? (
                <Spinner />
            ) :
                investigationData.length < 1 ? <div style={{ display: 'flex', justifyContent: 'center', color: 'red' }}>
                    <strong>No Data</strong>
                </div>
                    : (

                        <div className="table-responsive">
                            <table class="table table-hover">
                                <thead style={{ background: themeColor.themeColors.primaryColor, color: 'white' }}>
                                    <tr>
                                        <th scope="col">#</th>
                                        {tHead.map(item => (
                                            <th
                                                style={{
                                                    border: `1px solid ${Colors.SEA_GREEN_THEME}`,
                                                    borderColor: Colors.WHITE
                                                }}
                                                scope="col"
                                            >
                                                {item}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>{bodyRendering(investigationData)}</tbody>
                            </table>
                        </div>
                    )}
        </div>
    );
};

function mapStateToProps(state) {
    return {
        role: state.setUserReducer.users.role,
        name: state.setUserReducer.users.unique_name,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        sharedActions: bindActionCreators(sharedActions, dispatch)
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InvestigationList);