import React from 'react';
import { renderingStuff, images } from '../../utils';
import ViewRendering from '../../components/ViewRendering';

const BudgetScreen = () => {
	return (
		<ViewRendering
			mainHeading={images.imgFHead}
			mainHead={true}
			data={renderingStuff.budgetLinks}
		/>
	);
};

export default BudgetScreen;
