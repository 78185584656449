import React, { useContext } from "react";
import ViewRendering from "../../../components/ViewRendering";
import { renderingStuff, images } from "../../../utils";
import NewViewRendering from "../../../components/ViewRendering/NewViewRendering";
import ThemeContext from "../../../context/themeContext/ThemeContext";

export default function StaffMainScreen(props) {
  const themeColor = useContext(ThemeContext);

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div class="row justify-content-center">
            <div class="col-md-12 text-center">
              <img
                src={require("../../../Assets/New_HR_Icons/staffdoc.svg")}
                class="img-fluid"
                alt="Responsive image"
                height="50"
                width="50"
              />
              <h4 className="mt-2" style={{ letterSpacing: 10 }}>
                Staff Dashboard
              </h4>
            </div>
          </div>
        </div>
      </div>

      <NewViewRendering {...props} data={renderingStuff.StaffModules_Links} />
    </div>
  );
}
