import React, { useState } from "react";
import FormLayoutWrapper from "../../../components/HOC/FormLayoutWrapper";
import SelectionInput from "../../../components/SelectionInput/SelectionInput";
import RowWrapper from "../../../components/style/RowWrapper";
import CustomLabel from "../../../components/Label/CustomLabel";
import Button from "../../../components/Button";
import useHttp from "../../../hooks/http";
import LoaderWrapper from "../../../components/HOC/LoaderWrapper";
import * as sharedActions from "../../../Actions/sharedActions";
import { bindActionCreators } from "redux";
import isEmpty from "lodash/isEmpty";

import SnackBar from "../../../components/SnackBar/SnackBar";
import { connect } from "react-redux";
import validateInput, {
  CHECK_PRIMARY_INFO,
} from "../../../validator/inputValidator";
import {
  dataFilteration,
  addDataMiddleware,
  isEmptyFieldInArrOBJ,
  disableColorOverCondition,
  viewClassReturn,
  customSubToolDataAddingMiddleWare,
  customGeneralDataAddingMiddleWare,
} from "../../../utils/general/index";
import TextInput from "../../../components/TextInput/TextInput";
import UpdateFormLayoutWrapper from "../../../components/HOC/UpdateFormLayoutWrapper";
import TextArea from "../../../components/TextArea";
import ViewComponent from "../../../components/ViewComponent/ViewComponent";
import useCustomHttpWithDataMiddleware from "../../../hooks/Services/httpWithCustomDataMiddleware";
import Colors from "../../../utils/app_constants/colors_constants";
import { useEffect } from "react";
import BulletenContext from "../../../context/BluttenContext/index";
import $ from "jquery";
import httpWithCaSubToolViewDataMiddlware from "../../../hooks/Services/httpWithCaSubToolViewDataMiddlware";
import { useContext } from "react";
import ThemeContext from "../../../context/themeContext/ThemeContext";

function AddBulletin(props) {
  const [deleteId, setDeleteId] = useState("");
  const themeContext = useContext(ThemeContext);

  const [editId, setEditId] = useState("");
  const [errors, setError] = useState({});
  const [bulletinEdit, setBulletinEdit] = useState("");
  const [periodsEdit, setPeriodsEdit] = useState("");

  const [bulletin, setBulletin] = useState("");
  const [updateNotifier, setUpdateNotifier] = useState(false);
  const [isViewLoading, setIsViewLoading] = useState(false);
  const [periods, setPeriods] = useState("");
  const [flagTakeDaysInNumber, setTakeDaysInNumber] = useState(false);
  const [flagTakeDaysInNumberEdit, setTakeDaysInNumberEdit] = useState(false);

  debugger;
  const [snackbarMessage, setMsg] = useState("");
  const [studentData, setStudentData] = useState("");

  const [classId, setClassId] = useState("");
  const [className, setClassName] = useState("");

  const [grade, setGrade] = useState([]);
  const deleteHandler = (id, data, ...args) => {
    debugger;
    let getItem = data.filter((item) => item.newsId === parseInt(id));
    debugger;
    $(`${getItem[0]["Action"][1]["modalKey"]}`).modal("show");

    setDeleteId(id);
  };

  const [
    isLoading1,
    fetchData1,
    setFetchData,
    setIsLoading,
  ] = httpWithCaSubToolViewDataMiddlware(
    `/api/AllbulletIn`,
    [],
    {
      Action: [
        {
          isEnable: true,
          type: "actionLink",
          title: "Edit",
          initialPath: "/dashboard/CA_ASSIGN_MAKRS",
          iconName: "fas fa-pen",
          iconColor: "#000",
        },
        {
          handler: deleteHandler,
          isEnable: true,
          exceptionCase: true,
          isEnable: true,
          modalKey: "#deleteModal",
          title: "Delete",
          initialPath: "/dashboard/CASubToolView",
          iconName: "fas fa-trash-alt",
          iconColor: Colors.RED_DANGER,
        },
      ],
    },
    true,
    "",
    "newsId",
    1
  );
  debugger;
  const [gradeId, setGradeId] = useState("");
  const [gradeName, setGradeName] = useState("");

  const [subjects, setSubjects] = useState("");
  const [subjectName, setSubjectName] = useState("");
  const [subjectId, setSubjectId] = useState("");

  const [sectionId, setSectionId] = useState("");
  const [sectionName, setSection] = useState("");

  const [sessionId, setSessionId] = useState("");
  const [sessionName, setSessionName] = useState("");

  const [courseType, setcourseType] = useState("");

  const [term, onSetTerm] = useState("");
  const [isCustomLoader, setCustomLoader] = useState(false);
  const [sessions, setSessions] = useState([]);
  const [sections, setSections] = useState([]);
  const [isLoader, fetchData] = useHttp(
    `/api/DescriptiveReport?userName=${props.name}`,
    []
  );

  const isValid = () => {
    const { errors, isValid } = validateInput(
      { classId, sectionId, sessionId, subjectId, term, courseType },
      CHECK_PRIMARY_INFO
    );
    debugger;
    if (!isValid) {
      debugger;
      setError(errors);
    } else {
      setError(errors);
    }
    debugger;
    return isValid;
  };
  const editHandler = (id, data, ...args) => {
    debugger;
    let getItem = data.filter((item) => item.newsId === parseInt(id));
    debugger;
    $(`${getItem[0]["Action"][0]["modalKey"]}`).modal("show");
    setBulletinEdit(getItem[0].newsDescription);
    if (getItem[0].period < 5) {
      setPeriodsEdit(`${getItem[0].period} day`);
    } else {
      setTakeDaysInNumberEdit(true);
      setPeriodsEdit(`${parseInt(getItem[0].period)}`);
    }
    setEditId(id);
  };

  useEffect(() => {
    setIsViewLoading(true);
    props.sharedAction
      .getDataWithoutDispatch(`/api/AllbulletIn`)
      .then((success) => {
        debugger;
        setIsViewLoading(false);
        let getUpdatedData = customGeneralDataAddingMiddleWare(
          {
            Action: [
              {
                handler: editHandler,
                isEnable: true,
                exceptionCase: true,
                isEnable: true,
                type: "modalButton",
                modalKey: "#EditModal",
                title: "Edit",
                iconName: "fas fa-pen",
                iconColor: Colors.BLACK,
              },
              {
                handler: deleteHandler,
                isEnable: true,
                exceptionCase: true,
                isEnable: true,
                type: "modalButton",

                modalKey: "#deleteModal",
                title: "Delete",
                initialPath: "/dashboard/CASubToolView",
                iconName: "fas fa-trash-alt",
                iconColor: Colors.RED_DANGER,
              },
            ],
          },
          success,
          "newsId",
          [
            {
              index: 0,
              modalKey: "#EditModal",
            },
            {
              index: 1,
              modalKey: "#deleteModal",
            },
          ]
        );
        setFetchData(getUpdatedData);
        debugger;
      })
      .catch((error) => {
        debugger;
      });
  }, [updateNotifier]);

  debugger;
  const [isLoading, fetchSessionData] = useHttp(`/api/Session`, []);

  const onSessionChange = (e) => {
    console.log(fetchSessionData);
    debugger;
    if (e.target.value !== "Choose Session") {
      debugger;
      let getSession = dataFilteration(
        fetchSessionData.session,
        ["currentSession"],
        {
          sessionId: parseInt(e.target.value),
        }
      );

      console.log(fetchSessionData);
      debugger;
      setSessionName(getSession[0].currentSession);
      debugger;
      setSessionId(e.target.value);
    }
  };
  // const onTermChange = e => {
  // 	if (e.target.value !== 'Choose Grade') setGrade(e.target.value);
  // };
  const onTermChange = (e) => {
    if (e.target.value !== "Choose Term") onSetTerm(e.target.value);
  };
  const onSubjectChange = (e) => {
    const { staffClassSection, classes } = fetchData;

    if (e.target.value !== "Choose Subject") {
      setSubjectId(e.target.value);
      let getSubjectName = dataFilteration(staffClassSection, ["subjectName"], {
        subjectId: parseInt(e.target.value),
        classId: classId,
        sectionId: sectionId,
      });
      setSubjectName(getSubjectName[0].subjectName);
      debugger;
    }
  };
  const onChangeClassName = (e) => {
    const { staffClassSection, classes } = fetchData;
    if (e.target.value !== "Choose Class") {
      let getSections = dataFilteration(
        staffClassSection,
        ["section", "sectionId"],
        {
          classId: parseInt(e.target.value),
        }
      );

      let getClassName = dataFilteration(classes, ["className"], {
        classId: parseInt(e.target.value),
      })[0].className;
      debugger;
      setClassName(getClassName);
      setClassId(parseInt(e.target.value));
      setSections(getSections);
      setSectionId("");
    }
  };
  const onSectionChange = (e) => {
    if (e.target.value !== "Choose Section") {
      const { staffClassSection, classes } = fetchData;
      debugger;
      let getSubjects = dataFilteration(
        staffClassSection,

        ["subjectName", "subjectId"],
        {
          classId: classId,
          sectionId: parseInt(e.target.value),
        }
      );
      let getGrades = dataFilteration(
        staffClassSection,

        ["gradeName", "gradeId"],
        {
          classId: classId,
          sectionId: parseInt(e.target.value),
        }
      );

      debugger;
      setGradeName(getGrades[0].gradeName);
      setGradeId(getGrades[0].gradeId);

      let sectionName = dataFilteration(
        staffClassSection,

        ["section"],
        {
          classId: classId,
          sectionId: parseInt(e.target.value),
        }
      );
      debugger;
      debugger;
      setSection(sectionName[0].section);
      setSectionId(parseInt(e.target.value));
      setSubjects(getSubjects);
    }
  };
  const onSetType = (e) => {
    if (e.target.value !== "Choose Type") {
      setcourseType(e.target.value);
    }
  };
  const returnGenerateName = (
    classId = "",
    section = "",
    session = "",
    subject = "",
    term = ""
  ) => `${classId}-${section}-${session}-${subject}-${term}`;

  const onHandleSubmit = () => {
    console.log(errors);
    debugger;
    if (isValid()) {
      console.log("everything is fine");
      console.log(subjectId, classId, sessionId, sectionId, term, courseType);
    }
  };
  const onEditSubmit = (context) => {
    debugger;
    console.log(periodsEdit, bulletinEdit);

    setCustomLoader(true);
    props.sharedAction
      .editRecordWithoutDispatch(`/api/DailyBulletIn/`, editId, {
        newsId: editId,
        newsDescription: bulletinEdit,
        status: "recent",
        period: parseInt(periodsEdit),
        accountId: props.name,
        accountType: props.role,
      })
      .then((success) => {
        let getWholeData = [...fetchData1];
        debugger;

        let getIndex = getWholeData.findIndex(
          (item) => item.newsId === parseInt(editId)
        );

        let getChangeObj = {
          ...getWholeData[getIndex],
          newsDescription: bulletinEdit,
          period: parseInt(periodsEdit),
        };

        getWholeData[getIndex] = getChangeObj;

        setCustomLoader(false);

        $(`EditModal${editId}`).modal("hide");
        $("body").removeClass("modal-open");
        $(".modal-backdrop").remove();
        context.setBulleten(getWholeData);

        setPeriodsEdit("");
        setBulletinEdit("");
        setUpdateNotifier((prev) => !prev);
        setTakeDaysInNumberEdit(false);
        setMsg("Successfully Submitted");
        props.snackbar();
      })
      .catch((error) => {
        if (error && error.response && error.response.status == 400) {
          setCustomLoader(false);
          //404-Error no understanding of 404 Error
          setMsg(error.response.data.Message);

          props.snackbar();
        }
      });
  };
  const onSubmit = (context) => {
    debugger;

    setCustomLoader(true);
    props.sharedAction
      .simpleAddRequest(`/api/DailyBulletIn`, {
        newsDescription: bulletin,
        status: "recent",
        period: parseInt(periods),
      })
      .then((success) => {
        debugger;
        context.setBulleten((prevState) => [
          ...prevState,
          {
            newsDescription: bulletin,
            status: "recent",
            period: parseInt(periods),
          },
        ]);
        debugger;
        setCustomLoader(false);
        debugger;
        setPeriods("");
        debugger;
        setBulletin("");

        setUpdateNotifier((prev) => !prev);
        setTakeDaysInNumber(false);
        setMsg("Successfully Submitted");
        props.snackbar();
      })
      .catch((error) => {
        if (error && error.response && error.response.status == 400) {
          setCustomLoader(false);
          //404-Error no understanding of 404 Error
          setMsg(error.response.data.Message);

          props.snackbar();
        }
      });
  };
  const remoteDeleteHandler = (deleteId, context) => {
    let getFetchData = $.extend(true, [], fetchData1);
    debugger;
    debugger;
    props.sharedAction
      .deleteRecordWithoutDispatch(`/api/DailyBulletIn`, deleteId)
      .then((success) => {
        debugger;
        let getFilterData = getFetchData.filter(
          (item) => item.newsId !== parseInt(deleteId)
        );
        context.setBulleten(getFilterData);
        setUpdateNotifier((prev) => !prev);
        debugger;
      })
      .catch((error) => {
        debugger;
      });
  };
  const onHandleTextChangeEdit = (e) => {
    console.log(e.target.value);
    if (e.target.value === "others" && e.target.value !== "Type") {
      setTakeDaysInNumberEdit(true);
      setPeriodsEdit("");
    } else {
      setPeriodsEdit(e.target.value);
      setTakeDaysInNumberEdit(false);
    }
    debugger;
  };
  const onHandleTextChange = (e) => {
    console.log(e.target.value);
    if (e.target.value === "others" && e.target.value !== "Type") {
      setTakeDaysInNumber(true);
      setPeriods("");
    } else {
      setPeriods(e.target.value);
      setTakeDaysInNumber(false);
    }
    debugger;
  };

  return (
    <div>
      <LoaderWrapper isLoading={isLoader && isLoading ? true : false}>
        <SnackBar backColor={"#000"} msg={snackbarMessage} />
        <UpdateFormLayoutWrapper
          styleHead={true}
          formName={"Add Bulletin"}
          borderColor={themeContext.themeColors.primaryColor}
          borderRadius={5}
          errorFlag={false}
          flagName={"error"}
        >
          <RowWrapper contextPosition="space-between" wrap={"wrap"}>
            <div>
              <p style={{ color: Colors.BLACK }}>Bulletin</p>
              <textarea
                value={bulletin}
                onChange={(e) => setBulletin(e.target.value)}
                class="form-control"
                id="exampleFormControlTextarea1"
                rows="2"
                cols={33}
                placeholder={"Description . . . "}
              ></textarea>
            </div>

            <SelectionInput
              isValidte={false}
              feildName={"periods"}
              selectName={"Period"}
              onHandleChange={onHandleTextChange}
              errors={errors}
              optionsArrys={["1 day", "2 day", "3 day", "4 day", "others"]}
              selectedText={"Type"}
              stateData={{
                periods: periods,
              }}
              // iconClassName={'fas fa-clock animated fadeIn'}
              optionType="static"
              successflag={"successflag"}
            />
            {flagTakeDaysInNumber ? (
              <TextInput
                isValidte={false}
                feildName={"periods"}
                type={"number"}
                inputLabelName={"Enter Number Of Days"}
                onHandleChange={(e) => setPeriods(e.target.value)}
                errors={errors}
                stateData={{ periods: periods }}
                // iconClassName={'fas fa-percentage'}
                placeholder={"Enter Days"}
              />
            ) : (
              ""
            )}
          </RowWrapper>
          <br />
          <br />
          <BulletenContext.Consumer>
            {(context) => (
              <Button
                buttonClass={"btnStandard"}
                textColor={"#fff"}
                customClause={bulletin !== "" && periods !== "" ? false : true}
                btnName="Submit"
                onHandleSubmit={() => onSubmit(context)}
                stateData={{ isLoading: isCustomLoader }}
              />
            )}
          </BulletenContext.Consumer>
        </UpdateFormLayoutWrapper>
        <LoaderWrapper isLoading={isViewLoading}>
          <ViewComponent
            children={() => (
              <React.Fragment>
                {fetchData1 &&
                  fetchData1.map((item, index) => (
                    <div
                      class="modal fade"
                      id={`EditModal${item.newsId}`}
                      tabindex={`${index}`}
                      role="dialog"
                      aria-labelledby={`exampleModalLabel${index}`}
                      aria-hidden="true"
                    >
                      <div class="modal-dialog" role="document">
                        <div class="modal-content modal_content_custom">
                          <div
                            style={{
                              borderBottomLeftRadius: 30,
                              borderBottomRightRadius: 30,
                              borderTopLeftRadius: 18,
                              borderTopRightRadius: 18,
                              background: themeContext.themeColors.primaryColor,
                            }}
                            class="modal-header modal_custom_header"
                          >
                            <h4 style={{ color: "white" }} class="modal-title">
                              Update
                            </h4>
                            <button
                              style={{ color: "white" }}
                              type="button"
                              class="close"
                              data-dismiss="modal"
                            >
                              &times;
                            </button>
                          </div>
                          <div className="modal-body">
                            <RowWrapper
                              contextPosition="space-between"
                              wrap={"wrap"}
                            >
                              <div>
                                <p style={{ color: Colors.BLACK }}>Bulletin</p>
                                <textarea
                                  value={bulletinEdit}
                                  onChange={(e) =>
                                    setBulletinEdit(e.target.value)
                                  }
                                  class="form-control"
                                  id="exampleFormControlTextarea1"
                                  rows="2"
                                  cols={33}
                                  placeholder={"Description . . . "}
                                ></textarea>
                              </div>

                              <SelectionInput
                                isValidte={false}
                                feildName={"periodsEdit"}
                                selectName={"Period"}
                                onHandleChange={onHandleTextChangeEdit}
                                errors={errors}
                                optionsArrys={[
                                  "1 day",
                                  "2 day",
                                  "3 day",
                                  "4 day",
                                  "others",
                                ]}
                                selectedText={"Type"}
                                stateData={{
                                  periodsEdit: periodsEdit,
                                }}
                                // iconClassName={'fas fa-clock animated fadeIn'}
                                optionType="static"
                                successflag={"successflag"}
                              />
                              {flagTakeDaysInNumberEdit ? (
                                <TextInput
                                  isValidte={false}
                                  feildName={"periodsEdit"}
                                  type={"number"}
                                  inputLabelName={"Enter Number Of Days"}
                                  onHandleChange={(e) =>
                                    setPeriodsEdit(e.target.value)
                                  }
                                  errors={errors}
                                  stateData={{ periodsEdit: periodsEdit }}
                                  // iconClassName={'fas fa-percentage'}
                                  placeholder={"Enter Days"}
                                />
                              ) : (
                                ""
                              )}
                            </RowWrapper>
                            <br />
                            <br />
                            <BulletenContext.Consumer>
                              {(context) => (
                                <Button
                                  buttonClass={"btnStandard"}
                                  textColor={"#fff"}
                                  customClause={
                                    bulletinEdit !== "" && periodsEdit !== ""
                                      ? false
                                      : true
                                  }
                                  btnName="Submit"
                                  onHandleSubmit={() => onEditSubmit(context)}
                                  stateData={{ isLoading: isCustomLoader }}
                                />
                              )}
                            </BulletenContext.Consumer>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                {fetchData1 &&
                  fetchData1.map((item, index) => (
                    <div
                      class="modal fade"
                      id={`deleteModal${item.newsId}`}
                      tabindex={`${index}`}
                      role="dialog"
                      aria-labelledby={`exampleModalLabel${index}`}
                      aria-hidden="true"
                    >
                      <div class="modal-dialog" role="document">
                        <div class="modal-content modal_content_custom">
                          <div
                            style={{
                              borderBottomLeftRadius: 30,
                              borderBottomRightRadius: 30,
                              borderTopLeftRadius: 18,
                              borderTopRightRadius: 18,
                              background: themeContext.themeColors.primaryColor,
                            }}
                            class="modal-header modal_custom_header"
                          >
                            <h4 style={{ color: "white" }} class="modal-title">
                              Confirm
                            </h4>
                            <button
                              style={{ color: "white" }}
                              type="button"
                              class="close"
                              data-dismiss="modal"
                            >
                              &times;
                            </button>
                          </div>
                          <div class="modal-body">
                            <div>
                              <div style={{ textAlign: "center" }}>
                                <h4>Are you sure ?</h4>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-evenly",
                                }}
                              >
                                <div>
                                  <BulletenContext.Consumer>
                                    {(context) => (
                                      <button
                                        data-dismiss="modal"
                                        onClick={() =>
                                          remoteDeleteHandler(deleteId, context)
                                        }
                                        type="button"
                                        class="btn btn-primary buttonAppear"
                                        style={{
                                          background:
                                            themeContext.themeColors
                                              .primaryColor,
                                        }}
                                      >
                                        Yes
                                      </button>
                                    )}
                                  </BulletenContext.Consumer>
                                </div>
                                <div>
                                  <button
                                    data-dismiss="modal"
                                    type="button"
                                    class="btn btn-primary buttonAppear"
                                    style={{
                                      background:
                                        themeContext.themeColors.primaryColor,
                                    }}
                                  >
                                    No
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </React.Fragment>
            )}
            mainData={fetchData1}
            conditionalIconsColor={disableColorOverCondition}
            exceptionalHandler={viewClassReturn}
            headData={[
              "S #",
              "News",
              "Status",
              "Apearing Period",
              "Account Type",
              "Account Id",
              "Actions",
            ]}
            excludedData={["newsId", "DateofExpiry", "date", "time"]}
            redirectIds={["newsId"]}
          />
        </LoaderWrapper>
      </LoaderWrapper>
    </div>
  );
}
function mapStateToProps(state) {
  return {
    role: state.setUserReducer.users.role,
    name: state.setUserReducer.users.unique_name,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    sharedAction: bindActionCreators(sharedActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(AddBulletin);
