import * as actionTypes from '../../Actions/actionTypes';

export function getStaffData(data) {
	return {
		type: actionTypes.GET_ALL_STAFF_DATA,
		payload: data
	};
}
export function addAppraisal(data) {
	return {
		type: actionTypes.ADD_APPRAISAL
	};
}
export function getAppraisalData(data) {
	return {
		type: actionTypes.GET_ALL_APPRAISAL_DATA,
		payload: data
	};
}
export function deleteStaffRecord(itemId) {
	return {
		type: actionTypes.DELETE_ITEM_IN_STAFF,
		payload: itemId
	};
}
export function editStaffRecord(itemId) {
	return {
		type: actionTypes.EDIT_ITEM_IN_STAFF,
		payload: itemId
	};
}

export function getNotificationData(data) {
	return {
		type: actionTypes.GET_NOTIFICATION_DATA,
		payload: data
	};
}
export function removeAppraisalData(itemId) {
	return {
		type: actionTypes.DELETE_ITEM_IN_NOTIFICATION,
		payload: itemId
	};
}
