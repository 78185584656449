import * as actionTypes from '../actionTypes';
import axios from 'axios';
import config from '../../config/config';
import Axios from 'axios/index';

export function addAdmin(Data) {
	return {
		type: actionTypes.ADD_ADMIN_RECORD,
		payload: Data
	};
}
export function setAllAdminInRedux(allData) {
	return {
		type: actionTypes.GET_ALL_ADMIN,
		payload: allData
	};
}
export function updateGlobalFlag(setFlag) {
	return {
		type: actionTypes.SET_LOADER_FLAG,
		payload: setFlag
	};
}
export function updateAdmin(data) {
	return {
		type: actionTypes.EDIT_ADMIN_RECORD,
		payload: data
	};
}
export function deleteAdmin(id) {
	return {
		type: actionTypes.DELETE_ADMIN,
		payload: id
	};
}
export function getAllAdminData(url) {
	let token = '';
	if (localStorage.jwtToken) token = localStorage.getItem('jwtToken');
	return dispatch => {
		return axios
			.get(`${config.localhttp}${url}`, {
				headers: { Authorization: `Bearer  ${token}` }
			})
			.then(res => {
				console.log('action creator response ', res);
				console.log(res.data);
				dispatch(setAllAdminInRedux(res.data));
				dispatch(updateGlobalFlag(false));
				return res.data;
			});
	};
}
export function deleteAdminRecord(url, id) {
	let token = '';
	if (localStorage.jwtToken) token = localStorage.getItem('jwtToken');
	return dispatch => {
		return axios
			.delete(`${config.localhttp}${url}/${id}`, {
				data: { id }, // or data: jdId, depending on how you handle it in the back end
				headers: {
					Authorization: 'Bearer ' + token
				}
			})
			.then(res => {
				if (res.status == 200) {
					console.log('action creator response ', res);
					console.log(res.data);
					dispatch(deleteAdmin(id));
					return res.data;
				}
			});
	};
}
export function editAdminRecord(url, id, data) {
	let token = '';
	if (localStorage.jwtToken) token = localStorage.getItem('jwtToken');
	return dispatch => {
		return axios
			.put(`${config.localhttp}${url}${id}`, data, {
				headers: { Authorization: `Bearer  ${token}` }
			})
			.then(res => {
				if (res.status == 200) {
					console.log('action creator response ', res);
					console.log(res.data);
					dispatch(updateAdmin(data));
					return res.data;
				}
			});
	};
}
export function addAdminRecord(url, data) {
	let token = '';
	if (localStorage.jwtToken) token = localStorage.getItem('jwtToken');
	return dispatch => {
		return axios
			.post(`${config.localhttp}${url}`, data, {
				headers: { Authorization: `Bearer  ${token}` }
			})
			.then(res => {
				if (res.status == 200) {
					console.log('action creator response ', res);
					console.log(res.data);
					dispatch(addAdmin(data));
					return res.data;
				}
			});
	};
}
