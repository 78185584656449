import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as SkillAction from "../../Actions/StudentSkill";
import * as sharedActions from "../../Actions/sharedActions";
import SnackBar from "../../components/SnackBar/SnackBar";
import AttendanceList from "./AttendanceList";
import DropDownsForData from "../DescriptiveReportModule/TermClassSubjectSelect";
import $ from "jquery";
import moment from "moment";
import ThemeContext from "../../context/themeContext/ThemeContext";

class StudentAttendance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SubjectWiseAttendance: [],
      absentList: [],
      adminData: [],
      modalData: null,
      enabled: false,
      id: null,
      select: [],
      date: new Date(),
      dropFlag: false,
      Loading: true,
      isLoading: true,
      disablepage: false,
      disableBPage: true,
      currentPage: 1,
      todosPerPage: 10,
      pageCounter: 10,
      msg: "",
      errorMsg: "",
      increament: 1,
      selectClassData: "",
      classId: "",
      sectionId: "",
      sections: [],
      subjects: [],
      session: [],
      sessionId: "",
      sessionName: "",
      subjectId: "",
      staffId: "",
      gradeId: "",
      term: "",
      btnLoad: false,
      exist: false,
      checking: [],
      searchCheck: true,
      multiple: [],
      badRequest: false,
      errorText: "",
    };
  }
  handleClickNextFrwd = () => {
    const { adminData, todosPerPage, count, currentPage } = this.state;
    let pageNumbers = [];
    for (
      let i = 1;
      i <= Math.ceil(adminData.length / this.state.todosPerPage);
      i++
    ) {
      pageNumbers.push(i);
    }
    console.log("pagelength: ", pageNumbers.length);
    console.log("current page: ", currentPage);
    if (currentPage === pageNumbers.length) {
      this.setState({
        disablepage: true,
      });
    } else {
      this.setState((prevState) => ({
        currentPage: prevState.currentPage + 1,
        disableBPage: false,
      }));
    }
  };
  handleClickNextBack = () => {
    const { adminData, todosPerPage, count, currentPage } = this.state;
    console.log("current page: ", currentPage);
    if (currentPage === 1) {
      this.setState({
        disableBPage: true,
        disablepage: false,
      });
    } else {
      this.setState((prevState) => ({
        currentPage: prevState.currentPage - 1,
      }));
      this.setState({
        disablepage: false,
      });
    }
  };
  handleClickNext = (event) => {
    this.setState({
      currentPage: Number(event.target.id),
      disablepage: false,
      disableBPage: false,
    });
  };

  onSelectChange = (e) => {
    console.log(e.target.value);
    let result = JSON.parse(e.target.value);
    const sections = this.state.selectClassData.staffClassSection;
    let check = sections.filter((item) => item.classId == result.classId);
    console.log(check);

    this.setState({
      sections: check,
      staffId: check[0].staffId,
      gradeId: check[0].gradeId,
      staffName: check[0].staffName,
      classId: result.classId,
      className: result.className,
    });
  };
  onSectionChange = (e) => {
    console.log(e.target.value);
    let result = JSON.parse(e.target.value);
    const sections = this.state.selectClassData.staffClassSection;
    let check2 = sections.filter(
      (item) =>
        item.classId == this.state.classId && item.sectionId == result.sectionId
    );
    this.setState({
      subjects: check2,
      sectionId: result.sectionId,
      sectionName: result.section,
    });
    console.log(this.state.staffId);
  };
  onSessionChange = (e) => {
    console.log(e.target.value);
    let result = JSON.parse(e.target.value);
    this.setState({
      sessionId: result.sessionId,
      sessionName: result.currentSession,
    });
    console.log(this.state);
  };
  onSubjectChange = (e) => {
    console.log(e.target.value);
    let result = JSON.parse(e.target.value);
    this.setState({
      subjectId: result.subjectId,
      subjectName: result.subjectName,
    });
  };
  componentDidUpdate() {
    $('[data-toggle="tooltip"]').tooltip();

    if (
      this.state.msg ||
      this.state.errorMsg ||
      this.state.exist ||
      this.state.badRequest
    ) {
      setTimeout(() => {
        this.setState({
          msg: false,
          errorMsg: false,
          exist: false,
          badRequest: false,
        });
      }, 3000);
    }
  }
  componentDidMount() {
    $(document).ready(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });
    this.props.sharedActions
      .getDataWithoutDispatch(
        `/api/DescriptiveReport?userName=${this.props.users.unique_name}`
      )
      .then((success) => {
        this.setState({
          selectClassData: success,
          Loading: false,
        });
        console.log(success);
      })
      .catch((error) => {
        console.log(error);
      });
    this.props.sharedActions
      .getDataWithoutDispatch("/api/Session")
      .then((success) => {
        this.setState({
          session: success,
        });
      })
      .catch((error) => {});
  }
  onSearchStudent = (e) => {
    this.setState({
      btnLoad: true,
    });
    // let Date1 = "06/07/2020";
    let Date1 = moment(this.state.date).format("MM/DD/YYYY");
    this.props.sharedActions
      .getDataWithoutDispatch(
        `/api/attendanceValidation/?clasId=${this.state.classId}&secId=${this.state.sectionId}&term=${this.state.term}&subjectId=${this.state.subjectId}&sessId=${this.state.sessionId}&date=${Date1}`
      )
      .then((success) => {
        debugger;
        let getAllData = [...success.studentList];
        let absentList = success.absentList;
        debugger;
        let SubjectWiseAttendance = getAllData.map((item, index) => {
          return {
            studentId: item.studentId,
            studentName: item.studentName,
            classId: item.classId,
            attendanceStatus: "Absent",
            leaveType: "",
            lateTime: "no",
            date: Date1,
            subjectId: this.state.subjectId,
            sectionId: this.state.sectionId,
            staffId: this.state.staffId,
            gradeId: this.state.gradeId,
            accountId: this.props.users.unique_name,
            accountType: this.props.users.role,
            isChecked: false,
            sessionId: this.state.sessionId,
            term: this.state.term,
          };
        });

        this.setState({
          SubjectWiseAttendance,
          isLoading: false,
          absentList: absentList,
          btnLoad: false,
          searchCheck: false,
        });
      })
      .catch((err) => {
        this.setState({
          errorText: err.response.data.Message,
          badRequest: true,
          isLoading: false,
          btnLoad: false,
          searchCheck: true,
        });

        console.log(err);
      });
  };
  btnTechHover = () => {
    $(document).ready(function () {
      $("button").hover(
        function () {
          $(this).css("box-shadow", "0px 5px 5px grey");
          $(this).css("border-color", "white");
        },
        function () {
          $(this).css("box-shadow", "0px 0px 0px white");
        }
      );
    });
  };
  onTakeItemDelete = (itemId) => {
    this.setState({
      modalData: [],
    });
    this.props.sharedActions
      .deleteRecord(
        "/api/Vehicle/",
        itemId,
        this.props.routesActions.deleteVehicle
      )
      .then((success) => {
        this.setState({
          msg: "Delete Record Successfully!",
        });
        this.props.snackbar();
      })
      .catch((error) => {});
  };
  onTakeItem = (itemId) => {
    this.setState({
      modalData: [],
    });
    const { adminData } = this.state;
    let getData = adminData.filter((item) => item.id === itemId);

    this.setState({
      modalData: getData,
      transportType: getData[0].transportType,
      capacity: getData[0].capacity,
      vehicleNo: getData[0].vehicleNo,
      id: getData[0].id,
      routeName: getData[0].dropOff,
      routeId: getData[0].routeId,
      startingServiceKm: getData[0].startingServiceKm,
      midServiceKm: getData[0].midServiceKm,
      endingServiceKm: getData[0].endingServiceKm,
      vehicleDateOfReg: getData[0].vehicleDateOfReg,
      vehicleDateOfRegExpiry: getData[0].vehicleDateOfRegExpiry,
    });
  };

  onHandleText = (e) => {
    console.log(e.target.value);
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onHandleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      btnLoad: true,
    });
    const { SubjectWiseAttendance } = this.state;
    if (SubjectWiseAttendance.every((o) => o.Efforts === "")) {
      this.setState({
        btnLoad: false,
        exist: true,
      });
    } else {
      var bodyParameters = { SubjectWiseAttendance };
      this.props.sharedActions
        .simpleAddRequest("/api/SubjectWiseAttendance", bodyParameters)
        .then((success) => {
          this.setState({
            examName: "",
            msg: "Attendance Marked Successfully",
            errorMsg: false,
            dropFlag: true,
            btnLoad: false,
            searchCheck: true,
          });
          this.props.snackbar();
        })
        .catch((err) => {
          debugger;
          this.setState({
            errorMsg: true,
            btnLoad: false,
          });
          console.log(err);
          console.log(err.data);
        });
    }
  };
  onEditClick = (itemId) => {
    this.onTakeItem(itemId);
    this.setState({ enabled: false });
  };
  myFunction = () => {
    // Get the snackbar DIV
    var x = document.getElementById("snackbar");

    // StepperGeneralButtons the "show" class to DIV
    x.className = "show";

    // After 3 seconds, remove the show class from DIV
    setTimeout(function () {
      x.className = x.className.replace("show", "");
    }, 3000);
  };

  onClickDeleteItem = (arrName) => {
    let getStateArr = this.state[`${arrName}`];
    let getDeleteLength = getStateArr.length - 1;
    if (getDeleteLength > 0) {
      let getNewDetails = getStateArr.filter(
        (item, index) => index !== getDeleteLength
      );
      console.log(getNewDetails);
      this.setState({
        [arrName]: [...getNewDetails],
      });
    }
  };

  onClickAddItem = (arrName, obj) => {
    let getStateArr = this.state[`${arrName}`];
    getStateArr = [...getStateArr, obj];
    console.log(arrName);
    this.setState({
      [arrName]: getStateArr,
    });
  };
  updateSpecialArrObjOnChange = (e, index, arrName) => {
    debugger;
    const changeArrOfObj = this.state[`${arrName}`];
    changeArrOfObj[index][e.target.name] = e.target.value;
    this.setState({
      [arrName]: changeArrOfObj,
    });
  };
  onChangeText = (e) => {
    console.log(e.target.value);
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onChangingData = (index, event) => {
    let dataStrcuture = [
      {
        studentId: 1,
        studentName: "Muhammad Hamza",
        effort: "",
        average: "",
        participation: "",
      },
    ];

    const { multiple } = this.state;
    var check = [...multiple];
    check[index][event.target.name] = event.target.value;
    this.setState({ multiple: check });
  };
  handleAllChecked = (event) => {
    let Data = this.state.SubjectWiseAttendance;
    Data.forEach((items) => {
      if (event.target.checked == true) {
        items.isChecked = event.target.checked;
        items.attendanceStatus = "Present";
        items.lateTime = "no";
      } else {
        items.isChecked = event.target.checked;
        items.attendanceStatus = "Absent";
        items.lateTime = "no";
      }
    });
    this.setState({
      SubjectWiseAttendance: Data,
    });
    console.log(Data);
  };
  handleCheckChildElement = (event) => {
    debugger;
    let Data = this.state.SubjectWiseAttendance;
    Data.forEach((items) => {
      if (items.studentId == event.target.value) {
        items.isChecked = event.target.checked;
        debugger;
      }

      if (
        event.target.checked == true &&
        items.studentId == event.target.value
      ) {
        items.attendanceStatus = "Present";
        debugger;
      }

      if (
        event.target.checked == false &&
        items.studentId == event.target.value
      ) {
        items.attendanceStatus = "Absent";
        items.lateTime = "no";
        debugger;
      }
    });
    this.setState({
      SubjectWiseAttendance: Data,
    });
    console.log("Data he Data", Data);
  };
  render() {
    const { classId, sectionId, sessionId, subjectId, term } = this.state;
    console.log("Daata", this.state.SubjectWiseAttendance);
    console.log("Admin Data", this.state.adminData);
    console.log("Classes Ka Data", this.state.selectClassData);
    console.log("Session Ka Data", this.state.session);
    const { session } = this.state.session;
    const { classes } = this.state.selectClassData;

    console.log("Attendence data: ", this.state.SubjectWiseAttendance);
    console.log("Absent List Data: ", this.state.absentList);
    const Loader = (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <label style={{ color: "red" }}>No Data</label>
      </div>
    );
    let pageNumbers = [];
    for (
      let i = 1;
      i <=
      Math.ceil(this.state.checking.totalCount / this.state.checking.pageSize);
      i++
    ) {
      pageNumbers.push(i);
    }
    const renderPageNumbers = pageNumbers.map((number, index) => {
      return (
        <li
          class={`page-item ${
            this.state.checking.currentPage === number ? "active" : ""
          } `}
        >
          <a
            key={number}
            id={number}
            onClick={() => this.paginationRequest(number)}
            class="page-link "
            href="#"
          >
            {number}
          </a>
        </li>
      );
    });
    const PreLoading = (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div class="spinner-border" style={{ color: "#01AC8A" }}></div>
      </div>
    );
    const MainContent = (
      <div>
        <form onSubmit={this.onHandleSubmit}>
          <AttendanceList
            onHandleTextChange={this.onChangeText}
            handleCheckChildElement={this.handleCheckChildElement}
            handleAllChecked={this.handleAllChecked}
            stateData={this.state}
            updateSpecialArrObjOnChange={this.updateSpecialArrObjOnChange}
            onClickAddItem={this.onClickAddItem}
            onClickDeleteItem={this.onClickDeleteItem}
            onChanging={this.onChangingData}
            session={this.state.session}
          />
          <div class="text-right">
            <button type="submit" class="buttonHoverFill">
              Add
              <span
                style={{ marginBottom: 5 }}
                class={`${
                  this.state.btnLoad ? "spinner-border spinner-border-sm" : ""
                }`}
              ></span>
            </button>
          </div>
        </form>
      </div>
    );
    return (
      <div>
        <SnackBar backColor={"#000"} msg={this.state.msg} />
        {this.state.errorMsg ? (
          <p style={{ color: "red", textAlign: "center" }}>
            Grading Marks Already Exist For This Term
          </p>
        ) : (
          ""
        )}
        {this.state.exist ? (
          <p style={{ color: "red", textAlign: "center" }}>
            Please Fill the Fields
          </p>
        ) : (
          ""
        )}
        {this.state.badRequest ? (
          <p style={{ color: "red", textAlign: "center" }}>
            {this.state.errorText}
          </p>
        ) : (
          ""
        )}
        <div className="page-header">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <div>
              <h4 className="mt-2" style={{ letterSpacing: 10 }}>
                Student Attendance
              </h4>
            </div>
            <nav aria-label="Page navigation example">
              <ul style={{ color: "#01AC8A" }} class="pagination">
                {/* {renderPageNumbers} */}
              </ul>
            </nav>
          </div>
        </div>
        <br />
        {this.state.Loading ? (
          PreLoading
        ) : (
          <div>
            {" "}
            <DropDownsForData
              onChangeDrop={this.onSelectChange}
              onSectionDrop={this.onSectionChange}
              onSubjectDrop={this.onSubjectChange}
              onSessionDrop={this.onSessionChange}
              onHandleChange={this.onHandleText}
              ClassData={classes}
              SessionData={session}
              SectionData={this.state.sections}
              SubjectData={this.state.subjects}
              flag={this.state.dropFlag}
            />
            <br />
            <div class="col-md-12 text-center">
              <ThemeContext.Consumer>
                {(context) => (
                  <button
                    type="submit"
                    disabled={
                      classId !== "" &&
                      sessionId !== "" &&
                      sectionId !== "" &&
                      term !== "" &&
                      subjectId !== ""
                        ? false
                        : true
                    }
                    style={{
                      backgroundColor:
                        classId !== "" &&
                        sessionId !== "" &&
                        sectionId !== "" &&
                        term !== "" &&
                        subjectId !== ""
                          ? context.themeColors.primaryColor
                          : "#ABD0BC",
                      border: "none",
                      fontSize: 20,
                      borderRadius: 4,
                      color: "white",
                    }}
                    onClick={this.onSearchStudent}
                  >
                    Search
                    <i
                      style={{ color: "white", marginLeft: 4 }}
                      class="fas fa-search"
                    ></i>
                    <span
                      style={{ marginBottom: 5 }}
                      class={`${
                        this.state.btnLoad
                          ? "spinner-border spinner-border-sm"
                          : ""
                      }`}
                    ></span>
                  </button>
                )}
              </ThemeContext.Consumer>
            </div>
            <br />
            {this.state.searchCheck ? Loader : MainContent}
          </div>
        )}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    SkillAction: bindActionCreators(SkillAction, dispatch),
    sharedActions: bindActionCreators(sharedActions, dispatch),
  };
}
function mapStateToProps(state) {
  return {
    AllTransportData: state.setStudentSkillReducer.Data,
    selectData: state.setStudentSkillReducer.selectStudent,
    SessionSelect: state.setStudentSkillReducer.selectSession,
    allTransportState: state.setStudentSkillReducer,
    users: state.setUserReducer.users,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(StudentAttendance);
