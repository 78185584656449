import React, { Component, useContext } from 'react';
import TextInput from '../../components/TextInput/TextInput';
import Button from '../../components/Button';
import Colors from '../../utils/app_constants/colors_constants';
import { images } from '../../utils';

import themeContext from "../../context/themeContext/ThemeContext";

const StudentProfileHeadComponentRenew = props => {

    const themeColor = useContext(themeContext);

	const { onSubmitGetStudent, stateData, onHandleTextChange } = props;

	return (
		<React.Fragment>
			<div>
				{stateData.errorMsg ? (
					<p style={{ color: 'red', textAlign: 'center' }}>
						Please fill the form properly
					</p>
				) : (
					''
				)}

				<div className="row" style={{ justifyContent: 'center' }}>
					<TextInput
						feildName={'searchStudentId'}
						onHandleChange={onHandleTextChange}
						errors={{}}
						stateData={stateData}
						placeholder={'Enter Student ID'}
						isValidte={false}
					/>
				</div>
				<div class="text-center">
					<Button
						icon={true}
						width="100%"
						buttonClass="customButton"
						btnName="Search"
						loaderBmargin={5}
						stateData={stateData}
						marginTop={20}
						onHandleSubmit={onSubmitGetStudent}
						margin={'0 auto'}
						textColor="white"
						btnBackColor={themeColor.themeColors.primaryColor}
					/>
				</div>
				<br />

				<div
					style={{
						flexGrow: 1,
						borderBottom: 5,
						borderRight: 0,
						borderLeft: 0,
						borderStyle: 'solid',
						borderColor: '#9e9abc'
					}}
				>
					<div className="row">
						<br />
						<br />

						<div class="col-md-3">
							<div
								style={{
									margin: '0 auto',
									marginTop: '10px',
									marginLeft: '20px'
								}}
							>
								<img
									src={
										stateData['image'] === '' || null
											? images.imgStudent
											: `http://embassyportal.schoolsmart.org.uk/Uploads/StudentImages/${
													stateData['image']
											  }`
									}
									class="card-img"
									style={{
										border: `5px solid #9e9abc`,
										width: 200,
										height: 200,
										borderRadius: '50%'
									}}
									alt="Student Img"
								/>
							</div>
						</div>
						<div class="col-md-9">
							<div
								style={{
									backgroundColor: themeColor.themeColors.primaryColor,
									color: '#fff'
								}}
							>
								<div class="form-inline">
									<div class="col-md-6">
										<h5>{stateData['arabicName']}</h5>
									</div>
									<div class="col-md-6">
										<h5
											class="text-right"
											style={{ textDecoration: 'underline' }}
										>
											<strong>{'  '} تقرير أداء الطالب</strong>
											<i class="fas fa-language"></i>{' '}
										</h5>
									</div>
								</div>

								<div class="form-inline">
									<div class="col-md-4">
										<h5 style={{ textDecoration: 'underline' }}>
											<i class="fas fa-user-graduate"></i>{' '}
											<strong>{'  '} Student Name </strong>
										</h5>
									</div>
									<div class="col-md-4">
										<h5>{stateData['name']}</h5>
									</div>
								</div>

								<div class="form-inline">
									<div class="col-md-4">
										<h5 style={{ textDecoration: 'underline' }}>
											<i class="fas fa-hashtag"></i>{' '}
											<strong>{'  '} Student ID </strong>{' '}
										</h5>
									</div>

									<div class="col-md-4">
										<h5>{stateData['studentId']}</h5>
									</div>
								</div>

								<div class="form-inline">
									<div class="col-md-4">
										<h5 style={{ textDecoration: 'underline' }}>
											<i class="fas fa-child"></i>{' '}
											<strong>{'  '} Father Name </strong>
										</h5>
									</div>
									<div class="col-md-4">
										<h5>{stateData['fatherName']}</h5>
									</div>
								</div>

								<div class="form-inline">
									<div class="col-md-4">
										<h5 style={{ textDecoration: 'underline' }}>
											<i class="fas fa-user"></i>{' '}
											<strong>{'  '} User Name </strong>
										</h5>
									</div>

									<div class="col-md-4">
										<h5>{stateData['userName']}</h5>
									</div>
								</div>

								<div class="form-inline">
									<div class="col-md-4">
										<h5 style={{ textDecoration: 'underline' }}>
											<i class="fas fa-birthday-cake"></i>{' '}
											<strong>{'  '} Date Of Birth </strong>
										</h5>
									</div>

									<div class="col-md-4">
										<h5>{stateData['dob']}</h5>
									</div>
								</div>

								<div class="form-inline">
									<div class="col-md-4">
										<h5 style={{ textDecoration: 'underline' }}>
											<i class="fas fa-phone-volume"></i>{' '}
											<strong>{'  '} Contact </strong>
										</h5>
									</div>

									<div class="col-md-4">
										<h5>{stateData['contactNo']}</h5>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};
export default StudentProfileHeadComponentRenew;
