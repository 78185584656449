import React, { useEffect, useState, useContext } from 'react'
import $ from 'jquery';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import SelectionInput from '../../components/SelectionInput/SelectionInput';
import Pagination from '../../components/Pagination/Pagination'
import Spinner from '../../components/Spinner/Spinner';
import httpWithRequiredSetData from '../../hooks/Services/httpWithRequiredSetData';
import general from '../../utils/general'
import Colors from '../../utils/app_constants/colors_constants';
import CustomFilterSectionLayout from '../../components/CustomFilterSectionLayout/CustomFilterSectionLayout';
import SnackBar from '../../components/SnackBar/SnackBar';
import TextInput from '../../components/TextInput/TextInput'
import TextArea from '../../components/TextArea'
import themeContext from "../../context/themeContext/ThemeContext";

let tHead = [
    'Student Name',
    'Class',
    'Section',
    'Head #',
    'Members #',
    'Ministry Code',
    'Recommendation',
    'Status',
    'Actions'
];

let renderFields = [
    'studentName',
    'className',
    'section',
    'committeeHead',
    'committeeMembers',
    'ministryCode',
    'recommendation',
    'status',
]

const ViewInvestigationCommittee = props => {

    const themeColor = useContext(themeContext);

    const [accountId, setAccountId] = useState(props.name)
    const [accountType, setAccountType] = useState(props.role)
    const [sessionData, setSessionData] = useState([])
    const [sessionId, setSessionId] = useState('')

    const [investigationData, setInvestigationData] = useState([])
    const [state, setState] = useState({
        findings: '',
        ministryCode: '',
        committeeHead: '',
        committeeMembers: '',
        recommendation: '',
        file: ''
    })
    const [modalData, setModalData] = useState([])
    const [enabled, setEnabled] = useState('')
    const [id, setId] = useState('')
    const [status, setStatus] = useState('')
    const [msg, setSnackMsg] = useState('')

    const [searchURL, setSearchURL] = useState(
        `/api/InvestigationCommittee/?pageNumber=1&pageSize=10&sessionId=`
    );


    const [disableBPage, setdisableBPage] = useState(true);
    const [customPageSize, setCustomPageSize] = useState('10');
    const [currentPage, setCurrentPage] = useState(1);
    const [pageNumber, setPageNumber] = useState([]);
    const [disablepage, setdisablepage] = useState(false);
    const [pagination, setPaginationData] = useState({});


    const [
        isLoading,
        fetchData,
        setFetchData,
        setIsLoading
    ] = httpWithRequiredSetData(searchURL, [], data => {
        let pageNumber = [];
        for (let i = 1; i <= data['paginationMetadata']['totalPages']; i++) {
            pageNumber.push(i);
        }
        setPageNumber(pageNumber);
        setInvestigationData(data['invesstigationList']);

        setPaginationData(data['paginationMetadata']);
    });

    const [isSessionLoading, sessions] = httpWithRequiredSetData(
        '/api/session',
        [],
        data => {
            setSessionData(data['session']);
        }
    );

    const handleClickNextFrwd = () => {
        console.log('pagelength: ', pageNumber.length);
        console.log('current page: ', currentPage);
        if (currentPage === pageNumber.length) {
            setdisablepage(true);
        } else {
            setCurrentPage(prevState => prevState + 1);
            console.log(currentPage);

            setdisableBPage(false);
        }
    };

    useEffect(() => {
        console.log('Do something after counter has changed', currentPage);
        setIsLoading(true);
        props.sharedActions
            .getDataWithoutDispatch(
                general.condtionalServicesInvestigation(
                    sessionId,
                    currentPage,
                    customPageSize
                )
            )
            .then(success => {
                setInvestigationData(success['invesstigationList']);
                setIsLoading(false);
                let pageNumber = [];
                for (let i = 1; i <= success['paginationMetadata']['totalPages']; i++) {
                    pageNumber.push(i);
                }
                setPageNumber(pageNumber);
                setPaginationData(success['paginationMetadata']);
            })
            .catch(error => {
                setIsLoading(false);
                setInvestigationData([])
            });
    }, [currentPage, sessionId, customPageSize]);

    const handleClickNext = event => {
        setCurrentPage(Number(event.target.id));
        setdisableBPage(false);
        setdisableBPage(false);
    };
    const handleClickNextBack = () => {
        if (currentPage === 1) {
            setdisableBPage(true);
            setdisablepage(false);
        } else {
            setCurrentPage(prevState => prevState - 1);
        }
    };

    const onChangePageSize = e => {
        if (e.target.value !== 'Page Entries') {
            setCustomPageSize(e.target.value);
            setCurrentPage(1);
        }
    };

    const onHandleChange = e => {
        if (e.target.name === 'sessionId') {
            if (e.target.value !== 'Session') {
                setSessionId(e.target.value)
            }
        }
    }


    const onEditClick = id => {
        onTakeItem(id)
        setEnabled(false)
    };

    const onTakeItem = itemId => {
        setModalData([])
        let getData = investigationData.filter(item => item.id === itemId);

        setModalData(getData)
        setId(getData[0].id)

        setState(prevState => ({
            ...prevState, findings: getData[0].findings,
            recommendation: getData[0].recommendation,
            committeeHead: getData[0].committeeHead,
            committeeMembers: getData[0].committeeMembers,
            ministryCode: getData[0].ministryCode
        }))


    };

    const onHandleTextChange = e => {
        const target = e.target
        setState(prevState => ({
            ...prevState, [target.name]: target.value
        }))
    }

    const onHandleSelectChange = e => {
        if (e.target.value !== 'Committee Head' && e.target.value !== 'Committee Members') {
            const target = e.target
            setState(prevState => ({
                ...prevState, [target.name]: target.value
            })
            )
        }
    }

    const onChangeFile = e => {

        const target = e.target

        setState(prevState => ({
            ...prevState, file: target.files[0]
        }))

        console.log(target.files[0]);

    };

    const onHandleSubmit = (e) => {
        e.preventDefault();

        modalData[0].findings = state['findings']
        modalData[0].committeeMembers = state['committeeMembers']
        modalData[0].committeeHead = state['committeeHead']
        modalData[0].recommendation = state['recommendation']
        modalData[0].accountType = accountType
        modalData[0].accountId = accountId

        let data = new FormData();

        for (var key in modalData[0]) {
            data.append(key, modalData[0][key])
        }
        if (state['file'] !== '') {
            data.append('file', state['file'])
        }
        console.log(data);

        props.sharedActions
            .editRecordWithoutDispatch(
                '/api/InvestigationCommittee/',
                id,
                data,
            )
            .then(success => {
                investigationData.forEach((item, index) => {
                    if (item.id === id) {
                        investigationData.splice(index, 1, modalData[0])
                    }
                })
                setEnabled(false)
                setState({
                    findings: '',
                    ministryCode: '',
                    committeeHead: '',
                    committeeMembers: '',
                    recommendation: '',
                    file: ''
                })
                $('#myModal').modal('toggle'); //or  $('#IDModal').modal('hide');
                setSnackMsg('Edit Record Successfully!')
                props.snackbar();
            })
            .catch(err => { });

    }

    const onClickExclusion = id => {
        setIsLoading(true)

        modalData[0].status = 'GoToExclusion'

        props.sharedActions
            .editPatchRecordWithoutDispatch(
                '/api/InvestigationCommittee/',
                id,
                modalData[0],
            )
            .then(success => {
                investigationData.forEach((item, index) => {
                    if (item.id === id) {
                        investigationData.splice(index, 1, modalData[0])
                    }
                })
                setIsLoading(false)
                setEnabled(false)
                setSnackMsg('Exclusion Record Successfully!')
                props.snackbar();
            })
            .catch(err => { });
    };

    const PrintContent = el => {
        var restorepage = document.body.innerHTML;
        var Printcontent = document.getElementById(el).innerHTML;
        document.body.innerHTML = Printcontent;
        window.print();
        document.body.innerHTML = restorepage;
    };

    const bodyRendering = data =>
        data &&
        data.map((item, index) => (
            <tr>
                <th
                    style={{
                        padding: 2,
                        border: `1px solid ${Colors.SEA_GREEN_THEME}`,
                        borderColor: themeColor.themeColors.primaryColorE
                    }}
                    scope="row"
                >
                    {index + 1}
                </th>
                {renderFields.map((item1, index) => (
                    <td
                        style={{
                            fontWeight: `${index == 0 ? 'bold' : 'normal'}`,
                            border: `1px solid ${Colors.SEA_GREEN_THEME}`,
                            padding: 2,
                            borderColor: themeColor.themeColors.primaryColor
                        }}
                    >
                        {item[item1]}
                    </td>
                ))}
                <td className='displayElemet'
                    style={{
                        border: `1px solid ${Colors.SEA_GREEN_THEME}`,
                        padding: 2,
                        borderColor: themeColor.themeColors.primaryColor
                    }}
                >

                    <a href="#" data-toggle="tooltip" title="Go To Exclusion!">
                        <button
                            id="firstbutton"
                            onClick={() => onEditClick(item.id)}
                            data-toggle="modal"
                            data-target="#myModal1"
                            style={{
                                cursor: 'pointer',
                                fontSize: 20,
                                border: 'none',
                                marginLeft: 5,
                                background: 'none',
                                marginBottom: 5
                            }}
                            disabled={item.status === 'On-Review' ? false : true}
                        >
                            <i class="fas fa-vote-yea" style={item.status === 'On-Review' ? { color: '#01AC8A' } : { color: '#d3d3d3' }}></i>
                        </button>
                    </a>

                    <a href="#" data-toggle="tooltip" title="Edit!">
                        <button
                            id="firstbutton"
                            onClick={() => onEditClick(item.id)}
                            data-toggle="modal"
                            data-target="#myModal"
                            style={{
                                cursor: 'pointer',
                                fontSize: 20,
                                border: 'none',
                                marginLeft: 5,
                                background: 'none',
                                marginBottom: 5
                            }}
                        >
                            <i class="fas fa-pen"></i>
                        </button>
                    </a>

                    <a
                        href={
                            `http://embassyportal.schoolsmart.org.uk/api/InvestigationFiles?fileName=${item.fileName}`
                        }
                        id="tooltipId"
                        data-toggle="tooltip"
                        title="Download File"
                        style={{
                            cursor: 'pointer',
                            fontSize: 20,
                            border: 'none',
                            marginLeft: 10,
                            background: 'none',
                            marginBottom: 5
                        }}

                    >
                        <i style={{ color: '#34495e' }} class="fas fa-file-download"></i>
                    </a>
                </td>
            </tr>
        ));

    return (
        <div id='div1'>
            <SnackBar msg={msg} />
            <div class="modal" id="myModal1">
                <div class="modal-dialog">
                    <div class="modal-content modal_content_custom">
                        <div
                            style={{
                                borderBottomLeftRadius: 30,
                                borderBottomRightRadius: 30,
                                borderTopLeftRadius: 18,
                                borderTopRightRadius: 18,
                                background: themeColor.themeColors.primaryColor
                            }}
                            class="modal-header modal_custom_header"
                        >
                            <h4 style={{ color: 'white' }} class="modal-title">
                                Confirm
							</h4>
                            <button
                                style={{ color: 'white' }}
                                type="button"
                                class="close"
                                data-dismiss="modal"
                            >
                                &times;
							</button>
                        </div>
                        <div class="modal-body">
                            <div>
                                <div style={{ textAlign: 'center' }}>
                                    <h4>Are you sure ?</h4>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-evenly'
                                    }}
                                >
                                    <div>
                                        <button
                                            // disabled={this.state.enabled}
                                            data-dismiss="modal"
                                            onClick={() => onClickExclusion(id)}
                                            type="button"
                                            class="btn btn-primary buttonAppear"
                                            style={{background: themeColor.themeColors.primaryColor}}
                                        >
                                            Yes
										</button>
                                    </div>
                                    <div>
                                        <button
                                            data-dismiss="modal"
                                            // disabled={this.state.enabled}
                                            type="button"
                                            class="btn btn-primary buttonAppear"
                                            style={{background: themeColor.themeColors.primaryColor}}
                                        >
                                            No
										</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal" id="myModal">
                <div class="modal-dialog">
                    <div class="modal-content modal_content_custom">
                        <div
                            style={{
                                borderBottomLeftRadius: 30,
                                borderBottomRightRadius: 30,
                                borderTopLeftRadius: 18,
                                borderTopRightRadius: 18,
                                background: themeColor.themeColors.primaryColor
                            }}
                            class="modal-header modal_custom_header"
                        >
                            <h4 style={{ color: 'white' }} class="modal-title">
                                Details
								</h4>
                            <button
                                style={{ color: 'white' }}
                                type="button"
                                class="close"
                                data-dismiss="modal"
                            >
                                &times;
								</button>
                        </div>
                        <div class="modal-body">
                            <form onSubmit={onHandleSubmit}>
                                {modalData &&
                                    modalData.map((item, index) => (
                                        <div key={index}>
                                            <fieldset disabled={!enabled}>
                                                <TextInput
                                                    customWidth={true}
                                                    enterWidth={'300px'}
                                                    feildName={'findings'}
                                                    inputLabelName={'Findings'}
                                                    onHandleChange={onHandleTextChange}
                                                    errors={{}}
                                                    stateData={state}
                                                    placeholder={'Findings'}
                                                    isValidte={false}
                                                />

                                                <TextInput
                                                    customWidth={true}
                                                    enterWidth={'300px'}
                                                    feildName={'ministryCode'}
                                                    inputLabelName={'Ministry Code'}
                                                    onHandleChange={onHandleTextChange}
                                                    errors={{}}
                                                    stateData={state}
                                                    placeholder={'Ministry Code'}
                                                    isValidte={false}
                                                />

                                                <SelectionInput
                                                    newFlag="customWidth"
                                                    useFlag={false}
                                                    feildName={'committeeHead'}
                                                    selectName={'Committee Head'}
                                                    onHandleChange={e => onHandleSelectChange(e)}
                                                    errors={{}}
                                                    optionsArrys={general.committeeHead}
                                                    selectedText={'Committee Head'}
                                                    stateData={state}
                                                    // iconClassName={'fas fa-building'}
                                                    optionType="static"
                                                    successflag={'successflag'}
                                                    isValidte={false}
                                                // editUse={'staffEdit'}
                                                />

                                                <SelectionInput
                                                    newFlag="customWidth"
                                                    useFlag={false}
                                                    feildName={'committeeMembers'}
                                                    selectName={'Committee Members'}
                                                    onHandleChange={e => onHandleSelectChange(e)}
                                                    errors={{}}
                                                    optionsArrys={general.committeeMember}
                                                    selectedText={'Committee Members'}
                                                    stateData={state}
                                                    // iconClassName={'fas fa-building'}
                                                    optionType="static"
                                                    successflag={'successflag'}
                                                    isValidte={false}
                                                // editUse={'staffEdit'}
                                                />

                                                <TextArea
                                                    customWidth={true}
                                                    enterWidth={'300px'}
                                                    feildName={'recommendation'}
                                                    inputLabelName={'Recommendation'}
                                                    onHandleChange={onHandleTextChange}
                                                    errors={{}}
                                                    stateData={state}
                                                    // iconClassName={'fas fa-percentage'}
                                                    placeholder={'Recommendation'}
                                                    isValidte={false}
                                                />

                                                <div class="col-md-12" style={{ marginTop: 30 }}>
                                                    <div class="md-form mb-0">
                                                        <label for="email" class=""
                                                            style={{
                                                                fontSize: 17,
                                                                color: '#000',
                                                                fontWeight: 'normal'
                                                            }}   >
                                                            File
											</label>

                                                        <div class="file-upload">
                                                            <div class="file-select">
                                                                <div class="file-select-button" id="fileName">
                                                                    Choose File
													</div>
                                                                <div class="file-select-name" id="noFile">
                                                                    {state.file === '' ? 'No File Choosen' : state.file.name}
                                                                </div>
                                                                <input
                                                                    type="file"
                                                                    name="file"
                                                                    id="file"
                                                                    onChange={onChangeFile}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </fieldset>
                                            <br />
                                            <button
                                                disabled={enabled}
                                                onClick={() => setEnabled(true)}
                                                type="button"
                                                class="btn btn-primary buttonAppear"
                                                style={{background: themeColor.themeColors.primaryColor}}
                                            >
                                                Edit
												</button>
                                            <button
                                                style={{ marginLeft: 5 }}
                                                disabled={!enabled}
                                                type="submit"
                                                class="btn btn-primary buttonAppear"
                                                style={{background: themeColor.themeColors.primaryColor}}
                                            >
                                                Save
												</button>
                                        </div>
                                    ))}
                            </form>
                        </div>
                        <div class="modal-footer">
                            <button
                                type="button"
                                style={{ background: '#00435D' }}
                                class="btn btn-danger"
                                data-dismiss="modal"
                            >
                                Close
								</button>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ textAlign: 'right' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        <h2 className="h1-responsive font-weight-bold text-left my-4 generalHead" style={{background: themeColor.themeColors.primaryColor}}>
                            {' '}
                            Investigation Committee Details{' '}
                        </h2>
                    </div>
                    <div class='displayElemet'>
                        <div
                            style={{ marginTop: 40, display: 'flex', flexDirection: 'column' }}
                        >
                            <Pagination
                                disableBPage={disableBPage}
                                disablepage={disablepage}
                                currentPage={currentPage}
                                pageNumber={pageNumber}
                                handleClickNext={handleClickNext}
                                handleClickNextBack={handleClickNextBack}
                                handleClickNextFrwd={handleClickNextFrwd}
                            />
                            <div
                                style={{
                                    width: 'fit-content',
                                    marginTop: -34,
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    marginLeft: 'auto'
                                }}
                            >
                                <SelectionInput
                                    selectName="Entries"
                                    selectFont={14}
                                    selectFontWeight={'bold'}
                                    newFlag="customWidth"
                                    feildName="customPageSize"
                                    selectedText={'Page Entries'}
                                    optionsArrys={['10', '20', '30', '40', '50']}
                                    errors={{}}
                                    isValidte={false}
                                    stateData={{
                                        customPageSize: customPageSize
                                    }}
                                    optionType="static"
                                    onHandleChange={onChangePageSize}
                                    useFlag={false}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class='displayElemet'>
                    <CustomFilterSectionLayout displayDirection="column">
                        <div
                            style={{
                                width: '30%',
                                margin: '0 auto',
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            <React.Fragment>
                                <SelectionInput
                                    newFlag="customWidth"
                                    useFlag={false}
                                    feildName={'sessionId'}
                                    selectName={'Session'}
                                    onHandleChange={e => onHandleChange(e)}
                                    errors={{}}
                                    optionsArrys={sessionData}
                                    selectedText={'Session'}
                                    stateData={{
                                        sessionId: sessionId
                                    }}
                                    // iconClassName={'fas fa-building'}
                                    optionType="dynamicWithPropIdAndName"
                                    property={'currentSession'}
                                    propertyId={'sessionId'}
                                    isValidte={false}
                                // editUse={'staffEdit'}
                                />
                            </React.Fragment>
                        </div>
                    </CustomFilterSectionLayout>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span className="customBadge" style={{ fontSize: 14 }}>
                        Page: {currentPage}
                    </span>

                    <span className="customBadge" style={{ fontSize: 14 }}>
                        Total Page: {pagination['totalPages']}
                    </span>
                </div>
            </div>
            <br />
            {isLoading ? (
                <Spinner />
            ) :
                investigationData.length < 1 ? <div style={{ display: 'flex', justifyContent: 'center', color: 'red' }}>
                    <strong>No Data</strong>
                </div>
                    : (

                        <div className="table-responsive">
                            <table class="table table-hover">
                                <thead style={{ background: themeColor.themeColors.primaryColor, color: 'white' }}>
                                    <tr class='vendorListHeading'>
                                        <th scope="col">#</th>
                                        {tHead.map(item => (
                                            item === 'Actions' ?
                                                <th className='displayElemet'
                                                    style={{
                                                        border: `1px solid ${Colors.SEA_GREEN_THEME}`,
                                                        borderColor: Colors.WHITE
                                                    }}
                                                    scope="col"
                                                >
                                                    {item}
                                                </th>
                                                :
                                                <th
                                                    style={{
                                                        border: `1px solid ${Colors.SEA_GREEN_THEME}`,
                                                        borderColor: Colors.WHITE
                                                    }}
                                                    scope="col"
                                                >
                                                    {item}
                                                </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>{bodyRendering(investigationData)}</tbody>
                            </table>
                        </div>
                    )}
            <div class="text-center text-md-right displayElemet">
                <label for="name" class="" onClick={() => PrintContent('div1')}>
                    <i
                        className="fas fa-print fa-2x animated fadeIn"
                        style={{ marginRight: 4 }}
                    />
                        Print
                            </label>
            </div>
            <div class='text-center text-md-right'>
                <strong>{props.name}</strong>
            </div>
        </div>
    );
};

function mapStateToProps(state) {
    return {
        role: state.setUserReducer.users.role,
        name: state.setUserReducer.users.unique_name,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        sharedActions: bindActionCreators(sharedActions, dispatch)
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewInvestigationCommittee);