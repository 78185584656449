import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import $ from 'jquery';
import isEmpty from 'lodash/isEmpty';
import Header from '../../Assets/images/3 headers-03.png';
import moment from 'moment'

import ThemeContext from "../../context/themeContext/ThemeContext";


class PrintStaffLeaveForHR extends Component {
    constructor(props){
        super(props);
        this.state = {
            payrollData: [],
            search: '',
            isLoading: false,
            modalEdit: false,
            editToggle: false,
            generateToggle: false,
            generateIndex: '',
            msg: '',

			accountId: this.props.userData.unique_name,
			accountType: this.props.userData.role

        }

    }

    static contextType = ThemeContext

    componentDidMount(){

    }

    
    PrintContent = el => {
        var restorepage = document.body.innerHTML;
        var Printcontent = document.getElementById(el).innerHTML;
        document.body.innerHTML = Printcontent;
            window.print();
        document.body.innerHTML = restorepage;
    };


    myFunction = () => {
		// Get the snackbar DIV
		var x = document.getElementById('snackbar');

		// StepperGeneralButtons the "show" class to DIV
		x.className = 'show';

		// After 3 seconds, remove the show class from DIV
		setTimeout(function() {
		}, 3000);
	};



    render(){
        const { isLoading, accountId, accountType } = this.state;
        const { leaveData, date } = this.props.location.state;

        const Loader = (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div className="spinner-border" style={{ color: this.context.themeColors.primaryColor }}></div>
            </div>
        );
        
        const renderLeavesData = leaveData && leaveData.Leaves.map((item, index) => {
            return (
                <tr key={index} >
                    <th scope="row" style={{ padding: 1 }} >
                        {index + 1}
                    </th>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.staffName}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.accountId}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.accountType}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.date}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.fromDate}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.toDate}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.fileName}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.description}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.status}
                    </td>
                </tr>
                
                );
            
        }) 
 

        return (
            <div className="page-header"> 
                <div id="printDiv" style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                }}>
                    <div class="row" >
								<div
									style={{
                                        width: '100%',
                                        height: '100%'
									}}
								>
									<img src={Header} width={'100%'} height={'100%'} />
								</div>
						</div>

                    <div class="row" style={{marginTop: '2%'}} >
							<div class="text-center col-xs-12 col-sm-12 col-md-12">
								<h3>Staff Leave Applications</h3>
							</div>
						</div>

                        <div class="row my-3" >
                        <div class="text-center col-xs-12 col-sm-12 col-md-12">
								<h5>{date}</h5>
							</div>
                            </div>

                    <div class="my-2 py-2 px-2" style={{
                        border: `3px solid ${this.context.themeColors.primaryColor}`,
                    }} >
                        <div class="row">
                            <div class="col-md-12 text-center" >
                                    <span>Page No: <strong style={{color: this.context.themeColors.primaryColor}} >{leaveData && leaveData.paginationMetadata.currentPage} - {leaveData && leaveData.paginationMetadata.totalPages}</strong></span>     
                            </div>
                        </div>
                        
                       
                    </div>
                    <div class="row my-2 mx-2" style={{paddingLeft: '20px'}} >
                        
                    </div>
                    
                    <div className="table-responsive">
					<table className="table table-hover text-center">
						<thead style={{ background: this.context.themeColors.primaryColor, color: 'white' }}>
							<tr>
								<th scope="col" style={{ padding: 1 }}>
									#
								</th>
                                <th scope="col" style={{ padding: 1 }}>
									Staff Name
								</th>
                                <th scope="col" style={{ padding: 1 }}>
									Account Id
								</th>
                                <th scope="col" style={{ padding: 1 }}>
									Account Type
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Application Date
								</th>
								<th scope="col" style={{ padding: 1 }}>
                                    From Date
								</th>
								<th scope="col" style={{ padding: 1 }}>
									To Date
								</th>
								<th scope="col" style={{ padding: 1 }}>
                                    File Name
								</th>
                                <th scope="col" style={{ padding: 1 }}>
                                    Description
								</th>
                                <th scope="col" style={{ padding: 1 }}>
                                    Status
								</th>
							</tr>
						</thead>
						<tbody>{isLoading ? Loader : renderLeavesData}</tbody>
					</table>
				</div>
            </div>
            <div class="row" style={{marginBottom: '2%', marginRight: '1%'}} >
                    <div class="col-md-12">
                            
                <div>
                  <div class="">
                    <div class="text-left">Printed By: {this.props.userData.unique_name}</div>
                    <div class="text-right" style={{ marginTop: -25, marginRight: 20 }}>
                      Powered by School Smart®
                    </div>
                  </div>
                </div>
                </div>
                </div>
                <div class="row" style={{marginBottom: '2%', marginRight: '1%'}} >
                    <div class="col-md-10">
                    
                    </div>
                    <div class="col-md-2">
                    <button class="btn btn-primary buttonAppear" onClick={() => this.PrintContent('printDiv')}>
                            <i class="fas fa-print"></i> Print
                        </button>
                    </div>
                </div>
            </div>
        );
    }

}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch),
	};
}
function mapStateToProps(state) {
	return {
		userData: state.setUserReducer.users,
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PrintStaffLeaveForHR);
