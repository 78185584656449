import React, { Component } from 'react';
import {
	Editor,
	EditorState,
	RichUtils,
	convertToRaw,
	convertFromRaw
} from 'draft-js';
import Axios from 'axios/index';
import config from '../../../src/config/config';
import $ from 'jquery';

class ViewSyllabusTemplate extends Component {
	constructor(props) {
		super(props);
		this.state = {
			editorState: EditorState.createEmpty(),
			adminData: [],
			Ref: [],
			Entity: [],
			Full: [],
			Subjects: this.props.subject
		};
	}
	componentDidMount() {
		const { match } = this.props;
		$(document).ready(function() {
			$('[data-toggle="tooltip"]').tooltip();
		});
		let token = '';
		if (localStorage.jwtToken) {
			token = localStorage.getItem('jwtToken');
		}
		Axios.get(`${config.localhttp}/api/SyllabusNew/${match.params.id}`, {
			headers: { Authorization: `Bearer  ${token}` }
		})
			.then(success => {
				console.log(success);

				this.setState({
					isLoading: false,
					adminData: success.data.Word_Syllabuses,
					Ref: success.data.blocks,
					Entity: success.data.entityMap
				});
				var blocks = [...this.state.Ref];
				var entityMap = {
					...this.state.Entity
				};
				this.setState({
					Full: { blocks, entityMap }
				});
				const contentState = convertFromRaw(this.state.Full);
				this.setState({
					editorState: EditorState.createWithContent(contentState)
				});
				return this.state.editorState;
			})
			.catch(error => {
				this.setState({
					isLoading: false
				});
				console.log(error);
			});
		// this.setState({
		//     Full: [...this.state.Ref , ...this.state.Entity]
		// })
		console.log('merge', this.state.Full);
		// const contentState = convertFromRaw(JSON.parse(this.state.adminData));
		// this.setState({
		// 	editorState: EditorState.createWithContent(contentState)
		// });
	}
	getSavedEditorData = () => {
		const contentState = convertFromRaw(this.state.Full);
		this.setState({
			editorState: EditorState.createWithContent(contentState)
		});
		return this.state.editorState;
	};
	getData = () => {
		var blocks = [...this.state.Ref];
		var entityMap = {
			...this.state.Entity
		};
		this.setState({
			Full: { blocks, entityMap }
		});
		console.log('Ref', this.state.Ref);
		console.log('Entity', this.state.Entity);
		console.log('After ', this.state.adminData);
	};
	PrintContent = el => {
		var restorepage = document.body.innerHTML;
		var Printcontent = document.getElementById(el).innerHTML;
		document.body.innerHTML = Printcontent;
		window.print();
		document.body.innerHTML = restorepage;
	};
	render() {
		console.log('Testing ', this.state.Full);
		console.log('Whole Data', this.state.adminData);
		return (
			<div>
				<div id="div1">
					<section class="mb-4" style={{ marginTop: -50 }}>
						<div>
							<input
								type="image"
								src={require('../../Assets/images/logo1.png')}
								class="rounded mx-auto d-block"
								style={{ width: 350, height: 250 }}
							/>
						</div>
						<div class="row text-center" style={{ marginTop: -50 }}>
							<div class="col-md-4">
								Class:&nbsp;
								<label
									for="name"
									class=""
									style={{ fontWeight: 'bold', fontSize: 20, color: '#01425e' }}
								>
									{this.state.adminData.className}
								</label>
								<br />
								Section:&nbsp;
								<label
									for="name"
									class=""
									style={{ fontWeight: 'bold', fontSize: 20, color: '#01425e' }}
								>
									{this.state.adminData.section}
								</label>
							</div>
							<div class="col-md-4">
								Address: B.R.R Tower I.I Chandigarh Road, Karachi
								<br /> Email:
								<a href="mailto:#"> schoolsmart@gmail.com</a> <br />
								Phone:
								<a href="tel:#">+91 7568543012</a>
							</div>
							<div class="col-md-4">
								Session:&nbsp;
								<label
									for="name"
									class=""
									style={{ fontWeight: 'bold', fontSize: 20, color: '#01425e' }}
								>
									{this.state.adminData.session}
								</label>
								<br />
								Term:&nbsp;
								<label
									for="name"
									class=""
									style={{ fontWeight: 'bold', fontSize: 20, color: '#01425e' }}
								>
									{this.state.adminData.term}
								</label>
							</div>
						</div>
						<br />
						<div class="row print">
							<div class="col-md-12">
								<div class="md-form mb-0">
									<h4
										style={{
											backgroundColor: '#0A4F5E',
											textAlign: 'center',
											color: 'white'
										}}
									>
										{this.state.adminData.title}
									</h4>
								</div>
							</div>
						</div>
						<div className="editors">
							<Editor editorState={this.state.editorState} />
						</div>
						<div></div>
					</section>
				</div>
				<div class="text-center text-md-right">
					<label for="name" class="" onClick={() => this.PrintContent('div1')}>
						<i
							className="fas fa-print fa-2x animated fadeIn"
							style={{ marginRight: 4 }}
						/>
						Print
					</label>
				</div>
			</div>
		);
	}
}

export default ViewSyllabusTemplate;
