import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import $ from 'jquery';
import isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom'
import isNull from 'lodash/isNull'

import ThemeContext from "../../context/themeContext/ThemeContext";




class ViewStudentTalentClass extends Component {
    constructor(props){
        super(props);
        this.state = {
            payrollData: [],
            search: '',
            isLoading: true,
            modalEdit: false,
            editToggle: false,
            generateToggle: false,
            generateIndex: '',
            msg: '',
            editIndex: '',

            accountId: this.props.userData.unique_name

        }

    }

	static contextType = ThemeContext


    componentDidMount(){
		this.getSimple('classSectionGradeTeacher', 'classSectionGrade')
        this.getSimple(`StudentDetail?userName=${this.state.accountId}`, 'studentData')
    }



    getSimple = (type, state) => {
        this.props.sharedActions.getDataWithoutDispatch(
            `/api/${type}`
        ).then(success => { 
            this.setState({
                [state]: success,
                isLoading: false
            })


        }).catch(error => {
            this.setState({
                msg: error.response ? error.response.data.Message : 'Error Occured!!',
                isLoading: false
            })
            console.error('Error occured', error);
        })
    }

    


    myFunction = () => {
		// Get the snackbar DIV
		var x = document.getElementById('snackbar');

		// StepperGeneralButtons the "show" class to DIV
		x.className = 'show';

		// After 3 seconds, remove the show class from DIV
		setTimeout(function() {
		}, 3000);
	};

    btnTechHover = () => {
		$(document).ready(function() {
			$('button').hover(
				function() {
					$(this).css('box-shadow', '0px 5px 5px grey');
					$(this).css('border-color', 'white');
				},
				function() {
					$(this).css('box-shadow', '0px 0px 0px white');
				}
			);
		});
	};

    onHandleText = e => {
        
		this.setState({
            [e.target.name]: e.target.value,
        });
        console.log([e.target.name], e.target.value)
	};
	
	onHandleFilter = (e, item) => {
		const { classId, sectionId, sessionId } = item
		const { subjectId, year } = this.state;
        
		this.setState({
            [e.target.name]: e.target.value,
        });
        console.log([e.target.name], e.target.value)
    };

    onSearch = (item) => {
        const { classId, sectionId, sessionId } = item
        const { term, subjectId, year } = this.state;
        if(isNull(this.state.term) || isNull(this.state.subjectId) || isNull(this.state.year)){
            console.log('Empty');
            
            this.setState({
                msg: "Please Fill The Form Repectively!",
            })            
            this.props.snackbar()
        }else{
        
        this.getSimple(`StudentViewPastPapers/?classId=${classId}&sectionId=${sectionId}&sessionId=${sessionId}&term=${term}&subjectId=${subjectId}&year=${year}&pageSize=${10}&pageNumber=${1}`, 'pastPaperData')
        }
    }
    


    render(){

        const { search, isLoading, editToggle, msg, studentTalents, accountId, accountType, classSectionGrade,
            termList, student, session, talentCategory, talentSubCategory, valid,
            sessionId, classId, sectionId, studentId, term, categoryId, subCategoryId, reward,
            studentName, className, section, sessionName, categoryName, subCategoryName, date,
            year,
            
            studentData, report, subjectId, pastPaperData
        } = this.state;

        const Loader = (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div className="spinner-border" style={{ color: this.context.themeColors.primaryColor }}></div>
            </div>
        );
        
        const renderStudentData = studentData && studentData.map((item, index) => {
			return (
				<tr key={index}>
					<th scope="row"  style={{ padding: 10 }}>
						{index + 1}
					</th>
					<td  style={{ padding: 10 }}>
						{item.name}
					</td>
					<td  style={{ padding: 10 }}>
						{item.className}
					</td>
					<td  style={{ padding: 10 }}>
						{item.section}
					</td>
					<td  style={{ padding: 10 }}>
						{item.session}
					</td>
					<td  style={{ padding: 10 }}>
						{item.status}
					</td>
					<td  style={{ padding: 10 }}>
						<div class="col-md-12">
							<div class="md-form mb-0">
								<select
									
									onChange={this.onHandleText}
									class="custom-select"
									name="subjectId"
								>
									<option>Choose Subject..</option>
									
									{classSectionGrade && classSectionGrade.subject.map((data, i) => {
										return data.classId == item.classId && 
										<option value={data.subjectId}>{data.subjectName}</option>

									})
									
									}
								</select>
							</div>
						</div>
					</td>
					<td  style={{ padding: 10 }}>
						<div class="col-md-12">
							<div class="md-form mb-0">
								<select
									
									onChange={e => this.onHandleFilter(e, item)}
									class="custom-select"
									name="term"
									disabled={!subjectId}
								>
									<option>Choose Term..</option>
									<option value="1st">1st Term</option>
									<option value="2nd">2nd Term</option>
									<option value="3rd">3rd Term</option>
								</select>
							</div>
						</div>
					</td>
                    <td  style={{padding: 10}}>

                    <div class="col-md-12"  >
                    <div className="input-group mb-3" >
											
											<input
												
                                                type="text"
                                                name="year"
                                                placeholder="Year...."
                                                class="form-control"
                                                disabled={!term}
												onChange={this.onHandleText}
												value={year}
											/>
                                            <div class="input-group-append">
                                                <button class="btn btn-primary buttonAppear" 
                                                disabled={!year}
                                                onClick={() => this.onSearch(item)}
                                                type="button"> <i class="fas fa-search" ></i></button>
                                            </div>
                                            </div>
									</div>
                    </td>
						<td >
                        {pastPaperData && <Link to={{
                            pathname: "ViewPastPaperStudent",
                            state: {
                                classId: item.classId,
                                sectionId: item.sectionId,
                                sessionId: item.sessionId,
                                term,
                                year,
                                subjectId
                            }
                        }}  >
                            <button
                            id="firstbutton"
                            onMouseOver={this.btnTechHover()}
                            style={{
                                cursor: 'pointer',
                                fontSize: 20,
                                border: 'none',
                                marginLeft: 5,
                                background: 'none',
                                marginBottom: 5,

                            }}
                        >
                            
                                    <i className="fas fa-list"></i>
                            </button>
                        </Link>}
						</td>
					
				</tr>
			);
		});
        return (
            <div>
				<section  className="mb-4">
				<h2  
                        style={{ background: this.context.themeColors.primaryColor }}
                        className="h1-responsive font-weight-bold text-center  generalHead">
                        View Past Papers
                    </h2>
			    <div>
                <SnackBar msg={msg} />
                <div style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginTop: '2%'
                }}>
                    <div className="table-responsive">
					<table className="table table-hover text-center">
						<thead style={{ background: this.context.themeColors.primaryColor, color: 'white' }}>
							<tr>
								<th scope="col" style={{ padding: 1 }}>
									#
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Student Name
								</th>
								<th scope="col" style={{ padding: 1 }}>
                                    Class
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Section
								</th>
								<th scope="col" style={{ padding: 1 }}>
                                    Session
								</th>
                                <th scope="col" style={{ padding: 1 }}>
                                    Status
								</th>
								<th scope="col" style={{ padding: 1 }}>
                                    Subject
								</th>
                                <th scope="col" style={{ padding: 1 }}>
                                    Term
								</th>
                                <th scope="col" style={{ padding: 1 }}>
                                    Year
								</th>
                                <th scope="col" style={{ padding: 1 }}>
                                    Actions
								</th>
                                

                                
							</tr>
						</thead>
						<tbody>{isLoading ? Loader : renderStudentData}</tbody>
					</table>
				</div>
                </div>
            </div>
            </section>
            </div>
        );
    }

}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch),
	};
}
function mapStateToProps(state) {
	return {
		userData: state.setUserReducer.users,
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ViewStudentTalentClass);
