import React, { Component } from "react";
import PropTypes from "prop-types";
import { prototype } from "events";
import { useContext } from "react";
import ThemeContext from "../../context/themeContext/ThemeContext";
const SpecificFileUpload = (props) => {
  const {
    errors = {},
    textFont,
    stateData,
    onHandleTextChange,
    Name,
    idForYes,
    idForNo,
    feildName,
    onChangeTransportStatus,
    uploadName,
    dispatch,
    dispatchFlag,
  } = props;
  const themeColor = useContext(ThemeContext);
  // let getFun = Name === 'School Bus:' ? (e) => onChangeTransportStatus(e) : (e) => onHandleTextChange(e);

  return (
    <div class="col-md-6">
      <div style={{ fontSize: 20, padding: 10 }} class="md-form mb-0">
        <label style={{ width: "max-content" }} for="name" class="">
          <i
            className="fas fa-house animated fadeIn"
            style={{ marginRight: 4 }}
          />

          {dispatchFlag ? (
            <p style={{ margin: 0, color: "#000", fontSize: "large" }}>
              {Name}
            </p>
          ) : (
            Name
          )}
        </label>
        <div className="row" style={{ marginLeft: 4 }}>
          <div class="custom-control custom-radio">
            <input
              onChange={
                dispatchFlag
                  ? (e) =>
                      dispatch({
                        type: "onChangeRadio",
                        payload: {
                          name: e.target.name,
                          value: e.target.value,
                          fieldName: uploadName,
                        },
                      })
                  : (e) => onHandleTextChange(e, uploadName, "")
              }
              type="radio"
              checked={stateData[feildName] === "Yes" ? true : false}
              class="custom-control-input"
              id={idForYes}
              name={feildName}
              value={"Yes"}
            />
            <label class="custom-control-label" for={idForYes}>
              {dispatchFlag ? (
                <p style={{ margin: 0, color: "#000", fontSize: "large" }}>
                  {"Yes"}
                </p>
              ) : (
                "Yes"
              )}
            </label>
          </div>

          <div class="custom-control custom-radio custom-control-inline">
            <input
              checked={stateData[feildName] === "No" ? true : false}
              onChange={
                dispatchFlag
                  ? (e) =>
                      dispatch({
                        type: "onChangeRadio",
                        payload: {
                          name: e.target.name,
                          value: e.target.value,
                          fieldName: uploadName,
                          set: "",
                        },
                      })
                  : (e) => onHandleTextChange(e, uploadName, "")
              }
              type="radio"
              style={{ background: themeColor.themeColors.primaryColor }}
              class="custom-control-input"
              id={idForNo}
              name={feildName}
              value="No"
            />

            <label class="custom-control-label" for={idForNo}>
              {dispatchFlag ? (
                <p style={{ margin: 0, color: "#000", fontSize: "large" }}>
                  {"No"}
                </p>
              ) : (
                "No"
              )}
            </label>
            <br />
          </div>
          <label>
            {Object.keys(errors).length > 0 && errors[feildName] ? (
              <p style={{ color: "red", fontSize: 15 }}>field required</p>
            ) : (
              ""
            )}
          </label>
        </div>
      </div>
    </div>
  );
};
SpecificFileUpload.prototype = {
  stateData: PropTypes.object.isRequired,
  onHandleTextChange: PropTypes.func.isRequired,
  Name: PropTypes.string.isRequired,
  idForYes: PropTypes.string.isRequired,
  idForNo: PropTypes.string.isRequired,
  feildName: PropTypes.string.isRequired,
};
export default SpecificFileUpload;
