import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import $ from "jquery";
import * as sharedActions from "../../Actions/sharedActions";
import SelectionInput from "../../components/SelectionInput/SelectionInput";
import SnackBar from "../../components/SnackBar/SnackBar";
import general from "../../utils/general";
import Button from "../../components/Button/index";
import ThemeContext from "../../context/themeContext/ThemeContext";

class ViewSubjectRenew extends React.Component {
  state = {
    subjectData: [],
    isLoading: true,
    msg: "",
    modalData: null,
    enabled: false,
    id: null,
    accountType: "",
    accountId: "",
    selectFlag: true,
    gradeId: "",
    gradeName: "",
    editFlag: true,
    currentPage: 1,
    todosPerPage: 10,
    isLoadingEditDelete: false,
    errors: {},
    classData: [],
    classId: "",
    classN: "",
    disablepage: false,
    disabledPB: true,
    disabledP: true,
    successflag: false,
    count: 1,
  };

  componentDidMount() {
    $(document).ready(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });

    this.props.sharedActions
      .getDataWithoutDispatch("/api/addsubject")
      .then((success) => {
        this.setState({
          subjectData: success,
        });
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
      });

    this.props.sharedActions
      .getDataWithoutDispatch("/api/addclass")
      .then((success) => {
        this.setState({
          isLoading: false,
          classData: success,
        });
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
      });
  }

  onHandleIdSubmit = (e) => {
    e.preventDefault();

    const { classId } = this.state;

    if (Object.is(classId, "")) {
      this.setState({
        error: true,
        errMsg: true,
      });
    } else {
      this.setState({
        isLoading: true,
      });

      const data = {
        classId: classId,
      };

      this.props.sharedActions
        .getDataWithoutDispatch(`/api/getsubjectbyclassid/?classId=${classId}`)
        .then((success) => {
          this.setState({
            isLoading: false,
            subjectData: success,
            msg: "Subjects For The Selected Class",
            errMsg: false,
            currentPage: 1,
            disablepage: false,
            disabledPB: true,
            disabledP: true,
          });

          /*                     if (success === undefined || success.length == 0) {
                                            this.setState({
                                                gradeData: []
                                            })
                                        }
                     */
          this.props.snackbar();
        })
        .catch((err) => {
          if (err && err.response && err.response.status == 400) {
            this.setState({
              isLoading: false,
              subjectData: [],
              msg: err.response.data.Message,
            });
            this.props.snackbar();
          }
        });
    }
  };

  onTakeItem = (itemId) => {
    console.log(itemId);

    this.setState({
      modalData: [],
    });
    const { subjectData } = this.state;

    let getData = subjectData.filter((item) => item.id === itemId);

    this.setState({
      modalData: getData,
      accountType: getData[0].accountType,
      accountId: getData[0].accountId,
      subjectName: getData[0].subjectName,
      courseType: getData[0].courseType,
      classId: getData[0].classId,
      id: getData[0].id,
      gradeId: getData[0].gradeId,
      classN: getData[0].className,
    });
  };

  onEditClick = (itemId) => {
    console.log(itemId);

    this.onTakeItem(itemId);
    this.setState({
      enabled: false,
    });
  };

  onTakeItemDelete = (itemId) => {
    const { subjectData } = this.state;
    this.setState({
      modalData: [],
      isLoading: true,
    });

    console.log(itemId);

    this.props.sharedActions
      .deleteRecordWithoutDispatch("/api/addsubject", itemId)
      .then((success) => {
        const result = subjectData.filter(
          (subjectItem) => subjectItem.id !== itemId
        );

        this.setState({
          msg: "Deleted Successfully!",
          subjectData: result,
          isLoading: false,
          errors: {},
          classId: "",
        });
        this.props.snackbar();
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isLoading: false,
        });
      });
  };

  onChangeClassHandle = (e) => {
    const { subjectData } = this.state;
    this.setState({
      classId: e.target.value,
    });

    /*         let getClassById = general.dataFilteration(
                    subjectData,
                    ['className', 'subjectName', 'courseType', 'account']
                    ) */
  };

  btnTechHover = () => {
    $(document).ready(function () {
      $("button").hover(
        function () {
          $(this).css("box-shadow", "0px 5px 5px grey");
          $(this).css("border-color", "white");
        },
        function () {
          $(this).css("box-shadow", "0px 0px 0px white");
        }
      );
    });
  };

  onChangeSubject = (e) => {
    this.setState({
      subjectName: e.target.value,
    });
  };

  onSelectCourseChange = (e) => {
    this.setState({
      courseType: e.target.value,
    });
  };

  onHandleSubmit = (e) => {
    e.preventDefault();

    const {
      subjectName,
      courseType,
      accountType,
      accountId,
      className,
      classId,
      subjectData,
      id,
      classN,
      gradeId,
    } = this.state;

    var bodyParameters = {
      subjectName,
      courseType,
      accountId,
      accountType,
      classId,
      className: classN,
      gradeId,
      id,
    };

    this.props.sharedActions
      .editRecordWithoutDispatch("/api/addSubject/", id, bodyParameters)
      .then((success) => {
        subjectData.filter((subjectItem, index) => {
          if (subjectItem.id === id) {
            subjectData.splice(index, 1, bodyParameters);
          }
        });
        this.setState({
          enabled: false,
          msg: "Edited Record Successfully!",
          editFlag: true,
          selectFlag: true,
        });
        this.props.snackbar();
      })
      .catch((err) => {});
  };

  handleClickNextFrwd = () => {
    const { subjectData, todosPerPage, count, currentPage } = this.state;
    let pageNumbers = [];
    for (let i = 1; i <= Math.ceil(subjectData.length / todosPerPage); i++) {
      pageNumbers.push(i);
    }
    console.log("pagelength: ", pageNumbers.length);
    console.log("current page: ", currentPage);
    if (currentPage === pageNumbers.length) {
      this.setState({
        disablepage: true,
      });
    } else {
      this.setState((prevState) => ({
        currentPage: prevState.currentPage + 1,
        disableBPage: false,
      }));
    }
  };
  handleClickNextBack = () => {
    const { subjectData, todosPerPage, count, currentPage } = this.state;
    console.log("current page: ", currentPage);
    if (currentPage === 1) {
      this.setState({
        disableBPage: true,
        disablepage: false,
      });
    } else {
      this.setState((prevState) => ({
        currentPage: prevState.currentPage - 1,
      }));
    }
  };
  handleClickNext = (event) => {
    this.setState({
      currentPage: Number(event.target.id),
      disablepage: false,
      disableBPage: false,
    });
  };

  render() {
    const {
      modalData,
      subjectData,
      isLoading,
      editFlag,
      className,
      errors,
      classData,
    } = this.state;
    const Loader = (
      <ThemeContext.Consumer>
        {(context) => (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              class="spinner-border"
              style={{ color: context.themeColors.primaryColor }}
            ></div>
          </div>
        )}
      </ThemeContext.Consumer>
    );

    let pageNumbers = [];
    for (
      let i = 1;
      i <= Math.ceil(subjectData.length / this.state.todosPerPage);
      i++
    ) {
      pageNumbers.push(i);
    }
    const renderPageNumbers = pageNumbers.map((number, index) => {
      return index <= 3 ? (
        <ThemeContext.Consumer>
          {(context) => (
            <li
              class={`page-item ${
                this.state.currentPage === number ? "active" : ""
              } `}
            >
              <a
                style={{
                  color:
                    this.state.currentPage !== number
                      ? context.themeColors.primaryColor
                      : "",
                  borderColor:
                    this.state.currentPage === number
                      ? context.themeColors.primaryColor
                      : "",
                  background:
                    this.state.currentPage === number
                      ? context.themeColors.primaryColor
                      : "",
                }}
                key={number}
                id={number}
                onClick={this.handleClickNext}
                class="page-link "
                href="#"
                // style={{ background: context.themeColors.primaryColor }}
              >
                {number}
              </a>
            </li>
          )}
        </ThemeContext.Consumer>
      ) : (
        ""
      );
    });
    const { currentPage, todosPerPage } = this.state;
    const indexOfLastTodo = currentPage * todosPerPage;
    const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
    const currentTodos = subjectData.slice(indexOfFirstTodo, indexOfLastTodo);
    const renderTodos = currentTodos.map((item, index) => {
      return (
        <ThemeContext.Consumer>
          {(context) => (
            <tr key={index}>
              <th scope="row">{index + 1}</th>
              <td style={{ padding: 2 }}>{item.className}</td>
              <td style={{ padding: 2 }}>{item.subjectName}</td>
              <td style={{ padding: 2 }}>{item.courseType}</td>
              <td style={{ padding: 2 }}>{item.accountId}</td>
              <td style={{ padding: 2 }}>{item.accountType}</td>

              <td style={{ padding: 2 }}>
                <a href="#" data-toggle="tooltip" title="Edit!">
                  <button
                    id="firstbutton"
                    onClick={() => this.onEditClick(item.id)}
                    onMouseOver={this.btnTechHover()}
                    data-toggle="modal"
                    data-target="#myModal"
                    style={{
                      cursor: "pointer",
                      fontSize: 20,
                      border: "none",
                      marginLeft: 5,
                      background: "none",
                      marginBottom: 5,
                    }}
                  >
                    <i class="fas fa-pen"></i>
                  </button>
                </a>
                <a href="#" data-toggle="tooltip" title="Delete!">
                  <button
                    onClick={() => this.onEditClick(item.id)}
                    data-toggle="modal"
                    data-target="#myModal1"
                    style={{
                      cursor: "pointer",
                      fontSize: 20,
                      border: "none",
                      background: "none",
                      marginLeft: 5,
                      marginBottom: 5,
                    }}
                  >
                    <i
                      style={{ color: "#D11A2A" }}
                      class="fas fa-trash-alt"
                    ></i>
                  </button>
                </a>
              </td>
            </tr>
          )}
        </ThemeContext.Consumer>
      );
    });

    const MainContent = (
      <ThemeContext.Consumer>
        {(context) => (
          <div>
            <div class="modal" id="myModal1">
              <div class="modal-dialog">
                <div class="modal-content modal_content_custom">
                  <div
                    style={{
                      borderBottomLeftRadius: 30,
                      borderBottomRightRadius: 30,
                      borderTopLeftRadius: 18,
                      borderTopRightRadius: 18,
                      background: context.themeColors.primaryColor,
                    }}
                    class="modal-header modal_custom_header"
                  >
                    <h4 style={{ color: "white" }} class="modal-title">
                      Confirm
                    </h4>
                    <button
                      style={{ color: "white" }}
                      type="button"
                      class="close"
                      data-dismiss="modal"
                    >
                      &times;
                    </button>
                  </div>
                  <div class="modal-body">
                    {modalData &&
                      modalData.map((item, index) => (
                        <div key={index}>
                          <div style={{ textAlign: "center" }}>
                            <h4>Are you sure ?</h4>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-evenly",
                            }}
                          >
                            <div>
                              <button
                                disabled={this.state.enabled}
                                data-dismiss="modal"
                                onClick={() =>
                                  this.onTakeItemDelete(this.state.id)
                                }
                                type="button"
                                class="btn btn-primary buttonAppear"
                                style={{
                                  background: context.themeColors.primaryColor,
                                }}
                              >
                                Yes
                              </button>
                            </div>
                            <div>
                              <button
                                data-dismiss="modal"
                                disabled={this.state.enabled}
                                type="button"
                                class="btn btn-primary buttonAppear"
                                style={{
                                  background: context.themeColors.primaryColor,
                                }}
                              >
                                No
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            <div class="modal" id="myModal">
              <div class="modal-dialog">
                <div class="modal-content modal_content_custom">
                  <div
                    style={{
                      borderBottomLeftRadius: 30,
                      borderBottomRightRadius: 30,
                      borderTopLeftRadius: 18,
                      borderTopRightRadius: 18,
                      background: context.themeColors.primaryColor,
                    }}
                    class="modal-header modal_custom_header"
                  >
                    <h4 style={{ color: "white" }} class="modal-title">
                      Details
                    </h4>
                    <button
                      style={{ color: "white" }}
                      type="button"
                      class="close"
                      data-dismiss="modal"
                    >
                      &times;
                    </button>
                  </div>
                  <div class="modal-body">
                    <form onSubmit={this.onHandleSubmit}>
                      {modalData &&
                        modalData.map((item, index) => (
                          <div key={index}>
                            <fieldset disabled={!this.state.enabled}>
                              <div class="form-group">
                                <label for="classname" style={{ padding: 5 }}>
                                  Subject Name
                                </label>
                                <input
                                  style={{
                                    borderTop: 0,
                                    borderLeft: 5,
                                    borderRight: 5,
                                    boxShadow: "none",
                                    marginRight: 5,
                                    marginLeft: 5,
                                  }}
                                  type="text"
                                  value={this.state.subjectName}
                                  onChange={this.onChangeSubject}
                                  class="form-control"
                                  id="subjectname"
                                  disabled={editFlag}
                                />
                              </div>
                              <SelectionInput
                                feildName={"courseType"}
                                newFlag={"customWidth"}
                                isValidte={false}
                                selectName={"Course Type"}
                                onHandleChange={this.onSelectCourseChange}
                                errors={errors}
                                optionsArrys={general.courseType}
                                selectedText={"Course Type"}
                                stateData={this.state}
                                optionType="static"
                                successflag={"successflag"}
                              ></SelectionInput>
                            </fieldset>
                            <br />
                            <button
                              disabled={this.state.enabled}
                              onClick={() =>
                                this.setState({
                                  enabled: true,
                                  editFlag: false,
                                })
                              }
                              type="button"
                              class="btn btn-primary buttonAppear"
                              style={{
                                background: context.themeColors.primaryColor,
                              }}
                            >
                              Edit
                            </button>
                            <button
                              style={{
                                marginLeft: 5,
                                background: context.themeColors.primaryColor,
                              }}
                              disabled={!this.state.enabled}
                              type="submit"
                              class="btn btn-primary buttonAppear"
                            >
                              Save
                            </button>
                          </div>
                        ))}
                    </form>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      style={{ background: "#00435D" }}
                      class="btn btn-danger"
                      data-dismiss="modal"
                      onClick={() =>
                        this.setState({
                          editFlag: true,
                        })
                      }
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="table-responsive">
              <table class="table table-hover">
                <thead
                  style={{
                    background: context.themeColors.primaryColor,
                    color: "white",
                  }}
                >
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Class Name</th>
                    <th scope="col">Subject Name</th>
                    <th scope="col">Course Type</th>
                    <th scope="col">Account Id</th>
                    <th scope="col">Account Type</th>

                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>{renderTodos}</tbody>
              </table>
            </div>
          </div>
        )}
      </ThemeContext.Consumer>
    );

    return (
      <ThemeContext.Consumer>
        {(context) => (
          <div>
            <div classname="page-header">
              <SnackBar msg={this.state.msg} />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <h2
                    class="h1-responsive font-weight-bold text-center my-4 generalHead"
                    style={{ background: context.themeColors.primaryColor }}
                  >
                    View Subject
                  </h2>
                </div>
                <nav aria-label="Page navigation example">
                  <ul
                    style={{ color: context.themeColors.primaryColor }}
                    class="pagination"
                  >
                    <li
                      //   style={{ background: context.themeColors.primaryColor }}
                      class={`page-item ${
                        this.state.disableBPage ? "disabled" : ""
                      }`}
                    >
                      <a
                        onClick={this.handleClickNextBack}
                        class="page-link"
                        href="#"
                      >
                        {this.state.disableBPage ? (
                          <i
                            style={{ color: "grey" }}
                            class="far fa-stop-circle"
                          ></i>
                        ) : (
                          <i
                            style={{
                              color: context.themeColors.primaryColor,
                            }}
                            class="fas fa-backward"
                          ></i>
                        )}
                      </a>
                    </li>
                    {renderPageNumbers}
                    <li
                      class={`page-item ${
                        this.state.disablepage ? "disabled" : ""
                      }`}
                    >
                      <a
                        onClick={this.handleClickNextFrwd}
                        class="page-link"
                        href="#"
                      >
                        {this.state.disablepage ? (
                          <i
                            style={{ color: "grey" }}
                            class="far fa-stop-circle"
                          ></i>
                        ) : (
                          <i
                            style={{
                              color: context.themeColors.primaryColor,
                            }}
                            class="fas fa-forward"
                          ></i>
                        )}
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>

            <form
              class="form-inline"
              style={{ alignItems: "center", justifyContent: "center" }}
            >
              <div class="form-group mx-sm-3 mb-2">
                {/*                         <input type='text' width="100%" class="form-control" id="className" placeholder="Class Name" /> */}
                <SelectionInput
                  feildName={"className"}
                  isValidte={false}
                  onHandleChange={(e) => this.onChangeClassHandle(e)}
                  errors={errors}
                  selectedText={"Class Name"}
                  stateData={this.state}
                  optionsArrys={classData}
                  optionType="dynamicWithPropIdAndName"
                  property={"className"}
                  propertyId={"id"}
                  newFlag={true}
                />
              </div>
              <button
                type="submit"
                class="btn mb-2"
                onClick={this.onHandleIdSubmit}
                style={{
                  borderRadius: 10,
                  backgroundColor: context.themeColors.primaryColor,
                  color: "white",
                }}
              >
                Search
              </button>
            </form>
            {/*                 <div class='row'>
                    <div class='col-md-6'>

                        <TextInput
                            isValidte={false}
                            stateData={this.state}
                            feildName={'classId'}
                            errors={errors}
                            textFont={16}
                            onHandleChange={this.onChangeClassHandle}
                        />
                                            <Button
                        btnName={'Search'}
                        />
                   
                        <div style={{ marginTop: 30 }}>
                            <button
                                type='submit'
                                class='btn'
                                onClick={this.onHandleSubmit}
                                style={{
                                    borderRadius: 10,
                                    backgroundColor: context.themeColors.primaryColor,
                                    color: 'white'
                                }}>
                                Add
                                    <span style={{
                                    marginBottom: 5
                                }}
                                    class={`${
                                        this.state.isLoading ? 'spinner-border spinner-border-sm' : ''
                                        }`}
                                ></span>
                            </button>


                        </div>
                    </div>
                </div> */}
            {isLoading ? Loader : MainContent}
          </div>
        )}
      </ThemeContext.Consumer>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    sharedActions: bindActionCreators(sharedActions, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(ViewSubjectRenew);
