import React, { Component } from 'react';
import Button from '../../components/Button';
import { images } from '../../utils';
import { Link } from 'react-router-dom';

const CardComponent = props => {
	const { bodyColor, footerColor, messageTo, directTo } = props;

	return (
		<React.Fragment>
			<div class="col-md-3" style={{ marginBottom: '50px' }}>
				<div class="card  text-center shadow-lg" style={{ color: '#fff' }}>
					<div class="card-body" style={{ backgroundColor: bodyColor }}>
						<br />
						<div
							class="col=md-12"
							style={{
								border: '1px solid #fff',
								borderLeft: 0,
								borderRight: 0
							}}
						>
							<h4 style={{ color: '#fff' }}>
								<strong>{messageTo}</strong>
							</h4>
						</div>
					</div>
					<div class="card-footer " style={{ backgroundColor: footerColor }}>
						<div class="row">
							<div class="col-md-12 text-center">
								<Link to={`/dashboard/${directTo}`} style={{ color: '#fff' }}>
									<i class="fas fa-users"></i> <strong>Users</strong>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};
export default CardComponent;
