import React from 'react';

const ColorPalette = ({
    width = '100%',
    marginTop,
    margin,
    mouseInEvent,
    colors = [],
    iconClassName,
    iconColor,
    feildName,
    textFont,
    inputLabelName,
    selectedColor,
    onHandleSubmit = (e, item) => console.log(item)
    ,
}) => {
    return (
        <React.Fragment>
            <div class="col-md-6" style={{ marginTop: marginTop }}>
                <div class="md-form mb-0">
                    <label for={feildName}>
                        <i
                            className={iconClassName}
                            style={{ marginRight: 4, color: iconColor }}
                        />
                        <p style={{ margin: 0, fontSize: textFont, color: '#000' }}>
                            {inputLabelName}
                        </p>
                    </label>
                    <div class='border' style={{ borderRadius: 5, }}>
                        {colors.map(item => (
                            <button
                                //                                id='A1'
                                class='btn'
                                onMouseEnter={mouseInEvent}
                                onClick={e => onHandleSubmit(e, item)}
                                style={{ backgroundColor: item, borderRadius: "100%", height: "32px", width: '32px', marginLeft: 15, margin: 5 }}
                            //                                style={{ backgroundColor: item, height: "32px", width: '32px', marginTop:'3px', marginBottom: '3px' }}

                            > &nbsp;
                </button>
                        ))}
                    </div>
                </div>
            </div>

        </React.Fragment>

    );
};

export default ColorPalette;
