import React from "react";
import $ from "jquery";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as sharedActions from "../../Actions/sharedActions";
import SnackBar from "../../components/SnackBar/SnackBar";
import SelectionInput from "../../components/SelectionInput/SelectionInput";
import Button from "../../components/Button";
import Colors from "../../utils/app_constants/colors_constants";
import general from "../../utils/general";
import Spinner from "../../components/Spinner/Spinner";
import CustomFilterSectionLayout from "../../components/CustomFilterSectionLayout/CustomFilterSectionLayout";
import { Link } from "react-router-dom";
import ThemeContext from "../../context/themeContext/ThemeContext";

let tHead = [
  "Class Name",
  "Section Name",
  "Session",
  "Staff Name",
  "Subject Name",
  "Date",
  "School Name",
  "Actions",
];

let renderFields = [
  "className",
  "sectionName",
  "session",
  "staff",
  "subjectName",
  "date",
  "schoolName",
];

class ViewTeacherEvaluation extends React.Component {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      evaluationData: [],
      pagination: {},
      classData: [],
      sectionData: [],
      sessionData: [],
      isLoadingInitialData: true,
      isLoading: false,
      classId: "",
      sectionId: "",
      sessionId: "",
      errorMsg: false,
      disablepage: false,
      disableBPage: true,
      currentPage: 1,
      todosPerPage: 10,
      pageCounter: 10,
      checking: [],
    };
  }

  componentDidUpdate() {
    if (this.state.msg || this.state.errorMsg) {
      setTimeout(() => {
        this.setState({
          msg: "",
          errorMsg: false,
        });
      }, 3000);
    }
  }

  componentDidMount() {
    $(document).ready(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });
    this.props.sharedActions
      .getDataWithoutDispatch(`/api/classsectiongradeteacher`)
      .then((success) => {
        this.setState({
          classData: success.classes,
          mainData: success,
        });
        this.props.sharedActions
          .getDataWithoutDispatch("/api/session")
          .then((success) => {
            this.setState({
              sessionData: success.session,
              isLoadingInitialData: false,
            });
          })
          .catch((err) => {
            console.log(err);
            this.setState({ isLoisLoadingInitialData: false });
          });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ isLoadingInitialData: false });
      });
  }

  onHandleClassChange = (e) => {
    const { mainData } = this.state;

    const sectionData = general.dataFilteration(
      mainData.sections,
      ["sectionId", "section"],
      {
        classId: e.target.value,
      }
    );
    this.setState({
      [e.target.name]: e.target.value,
      sectionData: sectionData,
    });
  };

  onHandleSelectChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();

    const { classId, sectionId, sessionId } = this.state;
    console.log(classId, sectionId, sessionId);

    if (
      Object.is(sessionId, "") ||
      Object.is(classId, "") ||
      Object.is(sectionId, "")
    ) {
      this.setState({
        errorMsg: true,
      });
    } else {
      this.setState({
        isLoading: true,
      });

      this.props.sharedActions
        .getDataWithoutDispatch(
          `/api/TeacherEvaluationForm/?pageNumber=1&pageSize=10&classId=${classId}&sectionId=${sectionId}&sessionId=${sessionId}`
        )
        .then((success) => {
          this.setState({
            msg: "Evaluations for the Selected Query",
            evaluationData: success.indentForm,
            checking: success.paginationMetadata,
            isLoading: false,
          });
          this.props.snackbar();
        })
        .catch((error) => {
          if (error && error.response && error.response.status === 404) {
            // 404-Error no understanding of 404 Error
            this.setState({
              msg: error.response.data.Message,
              isLoading: false,
            });
          }
          if (error && error.response && error.response.status === 400) {
            // 404-Error no understanding of 404 Error
            this.setState({
              msg: error.response.data.Message,
              isLoading: false,
            });
          }
          this.props.snackbar();
        });
    }
  };

  onTakeItem = (itemId) => {
    const { evaluationData } = this.state;
    this.setState({
      modalData: [],
    });
    let getData = evaluationData.filter((item) => item.id === itemId);

    this.setState({
      modalData: getData,
      id: getData[0].id,
    });
  };

  onEditClick = (itemId) => {
    this.onTakeItem(itemId);
    this.setState({ enabled: false });
  };

  onTakeItemDelete = (itemId) => {
    const { evaluationData } = this.state;

    this.setState({
      modalData: [],
      isLoading: true,
    });

    this.props.sharedActions
      .deleteRecordWithoutDispatch("/api/TeacherEvaluationForm/", itemId)
      .then((success) => {
        let getNewFilterArray = evaluationData.filter(
          (item) => item.id !== itemId
        );
        this.setState({
          msg: "Delete Record Successfully!",
          evaluationData: getNewFilterArray,
          isLoading: false,
        });
        this.props.snackbar();
      })
      .catch((error) => {});
  };

  btnTechHover = () => {
    $(document).ready(function () {
      $("button").hover(
        function () {
          $(this).css("box-shadow", "0px 5px 5px grey");
          $(this).css("border-color", "white");
        },
        function () {
          $(this).css("box-shadow", "0px 0px 0px white");
        }
      );
    });
  };

  paginationRequest = (number) => {
    const { classId, sectionId, sessionId } = this.state;
    this.props.sharedActions
      .getDataWithoutDispatch(
        `/api/TeacherEvaluationForm/?pageNumber=${number}&pageSize=10&classId=${classId}&sectionId=${sectionId}&sessionId=${sessionId}`
      )
      .then((success) => {
        this.setState({
          evaluationData: success.indentForm,
          checking: success.paginationMetadata,
          isLoading: false,
        });
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
          evaluationData: [],
        });

        console.log(err);
      });
  };

  handleClickNextFrwd = () => {
    const { evaluationData, todosPerPage, currentPage } = this.state;
    let pageNumbers = [];
    for (let i = 1; i <= Math.ceil(evaluationData.length / todosPerPage); i++) {
      pageNumbers.push(i);
    }
    console.log("pagelength: ", pageNumbers.length);
    console.log("current page: ", currentPage);
    if (currentPage === pageNumbers.length) {
      this.setState({
        disablepage: true,
      });
    } else {
      this.setState((prevState) => ({
        currentPage: prevState.currentPage + 1,
        disableBPage: false,
      }));
    }
  };

  handleClickNextBack = () => {
    const { currentPage } = this.state;
    console.log("current page: ", currentPage);
    if (currentPage === 1) {
      this.setState({
        disableBPage: true,
        disablepage: false,
      });
    } else {
      this.setState((prevState) => ({
        currentPage: prevState.currentPage - 1,
      }));
      this.setState({
        disablepage: false,
      });
    }
  };
  handleClickNext = (event) => {
    this.setState({
      currentPage: Number(event.target.id),
      disablepage: false,
      disableBPage: false,
    });
  };

  render() {
    const theme = this.context;
    const {
      errors,
      classData,
      sectionData,
      sessionData,
      isLoading,
      isLoadingInitialData,
      evaluationData,
      id,
      disableBPage,
      disablepage,
      currentPage,
      pageNumber,
    } = this.state;
    const Loader = (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          class="spinner-border"
          style={{ color: theme.themeColors.primaryColor }}
        ></div>
      </div>
    );

    let pageNumbers = [];
    for (
      let i = 1;
      i <=
      Math.ceil(this.state.checking.totalCount / this.state.checking.pageSize);
      i++
    ) {
      pageNumbers.push(i);
    }
    const renderPageNumbers = pageNumbers.map((number, index) => {
      return (
        <li
          class={`page-item ${
            this.state.checking.currentPage === number ? "active" : ""
          } `}
        >
          <a
            key={number}
            id={number}
            onClick={() => this.paginationRequest(number)}
            class="page-link "
            href="#"
          >
            {number}
          </a>
        </li>
      );
    });

    const MainContent = (data) =>
      data &&
      data.map((item, index) => (
        <tr>
          <th
            style={{
              padding: 2,
              border: `1px solid ${theme.themeColors.primaryColor}`,
              borderColor: theme.themeColors.primaryColor,
            }}
            scope="row"
          >
            {index + 1}
          </th>
          {renderFields.map((item1, index) => (
            <td
              style={{
                fontWeight: `${index == 0 ? "bold" : "normal"}`,
                border: `1px solid ${theme.themeColors.primaryColor}`,
                padding: 2,
                borderColor: theme.themeColors.primaryColor,
              }}
            >
              {item[item1]}
            </td>
          ))}
          <td
            style={{
              border: `1px solid ${theme.themeColors.primaryColor}`,
              padding: 2,
              borderColor: theme.themeColors.primaryColor,
            }}
          >
            <Link to={`/dashboard/TeacherObservationBlankTemplate/${item.id}`}>
              <button
                id="firstbutton"
                style={{
                  cursor: "pointer",
                  fontSize: 20,
                  border: "none",
                  marginLeft: 5,
                  background: "none",
                  marginBottom: 5,
                }}
              >
                <a href="#" data-toggle="tooltip" title="View!">
                  <i
                    style={{ color: theme.themeColors.primaryColor }}
                    class="fas fa-list"
                  ></i>
                </a>
              </button>
            </Link>
            <a href="#" data-toggle="tooltip" title="Edit!">
              <Link to={`/dashboard/EditEvaluationForm/${item.id}`}>
                <button
                  id="firstbutton"
                  onMouseOver={this.btnTechHover()}
                  data-toggle="modal"
                  data-target="#myModal"
                  style={{
                    cursor: "pointer",
                    fontSize: 20,
                    border: "none",
                    marginLeft: 5,
                    background: "none",
                    marginBottom: 5,
                  }}
                >
                  <i class="fas fa-pen"></i>
                </button>
              </Link>
            </a>

            <a href="#" data-toggle="tooltip" title="Delete!">
              <button
                onClick={() => this.onEditClick(item.id)}
                data-toggle="modal"
                data-target="#myModal1"
                style={{
                  cursor: "pointer",
                  fontSize: 20,
                  border: "none",
                  background: "none",
                  marginLeft: 5,
                  marginBottom: 5,
                }}
              >
                <i style={{ color: "#D11A2A" }} class="fas fa-trash-alt"></i>
              </button>
            </a>
          </td>
        </tr>
      ));

    const SearchContent = (
      <CustomFilterSectionLayout displayDirection="column">
        <div
          style={{
            width: "30%",
            margin: "0 auto",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {this.state.errorMsg ? (
            <p style={{ color: "red", textAlign: "center" }}>
              Please Select all fields!
            </p>
          ) : (
            ""
          )}
        </div>
        <div
          style={{
            width: "30%",
            margin: "0 auto",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <React.Fragment>
            <SelectionInput
              feildName={"classId"}
              selectName={"Class"}
              onHandleChange={(e) => this.onHandleClassChange(e)}
              errors={errors}
              optionsArrys={classData}
              selectedText={"Class Name"}
              stateData={this.state}
              // iconClassName={'fas fa-building'}
              optionType="dynamicWithPropIdAndName"
              property={"className"}
              propertyId={"classId"}
              isValidte={false}
              // editUse={'staffEdit'}
            />
            <SelectionInput
              feildName={"sectionId"}
              selectName={"Section"}
              onHandleChange={(e) => this.onHandleSelectChange(e)}
              errors={errors}
              optionsArrys={sectionData}
              selectedText={"Section"}
              stateData={this.state}
              // iconClassName={'fas fa-building'}
              optionType="dynamicWithPropIdAndName"
              property={"section"}
              propertyId={"sectionId"}
              isValidte={false}
              // editUse={'staffEdit'}
            />
            <SelectionInput
              feildName={"sessionId"}
              selectName={"Session"}
              onHandleChange={(e) => this.onHandleSelectChange(e)}
              errors={errors}
              optionsArrys={sessionData}
              selectedText={"Session"}
              stateData={this.state}
              // iconClassName={'fas fa-building'}
              optionType="dynamicWithPropIdAndName"
              property={"currentSession"}
              propertyId={"sessionId"}
              isValidte={false}
              // editUse={'staffEdit'}
            />
            <Button
              width="100%"
              buttonClass="customButton"
              btnName="Submit"
              loaderBmargin={5}
              stateData={this.state}
              marginTop={55}
              onHandleSubmit={this.onSubmit}
              margin={"0 auto"}
            />

            {/* <button type="submit" class="btn" style={{ marginLeft: 20, backgroundColor: '#01ac8a', color: 'white', }}> Search </button> */}
          </React.Fragment>
        </div>
      </CustomFilterSectionLayout>
    );

    return (
      <div>
        <div className="page-header">
          <SnackBar backColor={this.state.snackColor} msg={this.state.msg} />
          <div class="modal" id="myModal1">
            <div class="modal-dialog">
              <div class="modal-content modal_content_custom">
                <div
                  style={{
                    borderBottomLeftRadius: 30,
                    borderBottomRightRadius: 30,
                    borderTopLeftRadius: 18,
                    borderTopRightRadius: 18,
                  }}
                  class="modal-header modal_custom_header"
                >
                  <h4 style={{ color: "white" }} class="modal-title">
                    Confirm
                  </h4>
                  <button
                    style={{ color: "white" }}
                    type="button"
                    class="close"
                    data-dismiss="modal"
                  >
                    &times;
                  </button>
                </div>
                <div class="modal-body">
                  <div>
                    <div style={{ textAlign: "center" }}>
                      <h4>Are you sure ?</h4>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <div>
                        <button
                          // disabled={this.state.enabled}
                          data-dismiss="modal"
                          onClick={() => this.onTakeItemDelete(id)}
                          type="button"
                          class="btn btn-primary buttonAppear"
                        >
                          Yes
                        </button>
                      </div>
                      <div>
                        <button
                          data-dismiss="modal"
                          // disabled={this.state.enabled}
                          type="button"
                          class="btn btn-primary buttonAppear"
                        >
                          No
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div>
              <h2
                style={{ background: theme.themeColors.primaryColor }}
                className="h1-responsive font-weight-bold text-left my-4 generalHead"
              >
                {" "}
                Teacher Evaluation{" "}
              </h2>
            </div>
            <div
              style={{
                marginTop: 40,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <nav aria-label="Page navigation example">
                <ul
                  style={{ color: theme.themeColors.primaryColor }}
                  class="pagination"
                >
                  {renderPageNumbers}
                </ul>
              </nav>
              {/*                         <Pagination
                            disableBPage={disableBPage}
                            disablepage={disablepage}
                            currentPage={currentPage}
                            pageNumber={pageNumber}
                            handleClickNext={this.handleClickNext}
                            handleClickNextBack={this.handleClickNextBack}
                            handleClickNextFrwd={this.handleClickNextFrwd}
                        />
 */}{" "}
            </div>
          </div>
        </div>
        {isLoadingInitialData ? Loader : SearchContent}
        <br />

        {isLoading ? (
          <Spinner />
        ) : (
          <div className="table-responsive">
            <table class="table table-hover">
              <thead
                style={{
                  background: theme.themeColors.primaryColor,
                  color: "white",
                }}
              >
                <tr>
                  <th
                    style={{ background: theme.themeColors.primaryColor }}
                    scope="col"
                  >
                    #
                  </th>
                  {tHead.map((item) => (
                    <th
                      style={{
                        border: `1px solid ${theme.themeColors.primaryColor}`,
                        borderColor: Colors.WHITE,
                        background: theme.themeColors.primaryColor,
                      }}
                      scope="col"
                    >
                      {item}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>{MainContent(evaluationData)}</tbody>
            </table>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    role: state.setUserReducer.users.role,
    name: state.setUserReducer.users.unique_name,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    sharedActions: bindActionCreators(sharedActions, dispatch),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewTeacherEvaluation);
