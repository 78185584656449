import React, { useEffect, useState, useContext } from 'react';
import $ from 'jquery';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import * as sharedActions from '../../Actions/sharedActions';
import general from '../../utils/general';
import moment from 'moment';
import Colors from '../../utils/app_constants/colors_constants';
import Spinner from '../../components/Spinner/Spinner';
import httpWithRequiredSetData from '../../hooks/Services/httpWithRequiredSetData';
import themeContext from "../../context/themeContext/ThemeContext";

const StudentTimeTable = props => {
    const themeColor = useContext(themeContext);

	const [isLoading, setIsLoading] = useState(true);
	const [timeTableData, setTimeTableData] = useState({});
	const [tHead, setTHead] = useState([]);
	const [renderFields, setRenderFields] = useState([]);
	const [periods, setPeriods] = useState([]);
	const [departmentData, setDepartmentData] = useState({});
	const [timeTablePeriods, setTimeTablePeriods] = useState([])

	const [state, setState] = useState({
		//test purposes
		today: moment(),

		from_date: '',
		to_date: ''
	});

	useEffect(() => {
		if (
			state['today'].format('dddd') !== 'Friday' &&
			state['today'].format('dddd') !== 'Saturday'
		) {
			const from_date = moment()
			.startOf('week');

			const to_date = moment()
			.endOf('week')
			.subtract(2, 'days');
			console.log(from_date,'  -   ',from_date,'date')
			props.sharedActions
				.getDataWithoutDispatch(
					`/api/TimetableStudentView/?userName=${
						props.name
					}&fromDate=${general.dateToFormat(
						from_date
					)}&toDate=${general.dateToFormat(to_date)}`
				)
				.then(dataa => {
					console.log(from_date,'  -   ',from_date,'date')
					setTHead(dataa.days);
					setPeriods(dataa.staffWisePeriodsList);
					setTimeTablePeriods(dataa.period)
					setIsLoading(false);
				})
				.catch(err => {
					setTHead([]);
					setIsLoading(false);
				});
		} else {
			const from_date = moment()
				.add(1, 'weeks')
				.startOf('week');
			const to_date = moment()
				.add(1, 'weeks')
				.endOf('week')
				.subtract(2, 'days');
				console.log(from_date,'  -   ',from_date,'date')
			props.sharedActions
				.getDataWithoutDispatch(
					`/api/TimetableStudentView/?userName=${
						props.name
					}&fromDate=${general.dateToFormat(
						from_date
					)}&toDate=${general.dateToFormat(to_date)}`
				)
				.then(data => {
					console.log(from_date,'  -   ',from_date,'date')
					setTHead(data.days);
					setPeriods(data.staffWisePeriodsList);
					setTimeTablePeriods(data.period)
					setIsLoading(false);
				})
				.catch(err => {
					setIsLoading(false);
				});
		}
	}, []);

	const PrintContent = el => {
		var restorepage = document.body.innerHTML;
		var Printcontent = document.getElementById(el).innerHTML;
		document.body.innerHTML = Printcontent;
		window.print();
		document.body.innerHTML = restorepage;
	};

	const bodyRendering = data =>
		data &&
		data.map((item, index) => (
			<tr className="vendorListHeading">
				<th
					className="vendorListHeading"
					height="140px"
					style={{
						padding: 2,
						border: `1px solid ${themeColor.themeColors.primaryColor}`,
						borderColor: themeColor.themeColors.primaryColor,
						textAlign: 'center',
						verticalAlign: 'middle',
						background: '#A9A9A9',
						color: 'white'
					}}
					scope="row"
				>
					<div>
						{item.staffName}
						<br />
						<br />
						{item.subjectName}
					</div>
				</th>
				{item.dateWise.map((item1, index) =>
					item1.periodsData.map((item2, index) => (
						<td
							height="120px"
							style={
								item2.periods.length < 1
									? {
											fontWeight: 'bold',
											border: `1px solid white`,
											padding: 2,
											borderColor: themeColor.themeColors.primaryColor,
											textAlign: 'center',
											verticalAlign: 'middle',
											color: 'white'
									  }
									: {
											fontWeight: 'bold',
											border: `1px solid white`,
											padding: 2,
											borderColor: 'white',
											textAlign: 'center',
											verticalAlign: 'middle',
											color: 'white'
									  }
							}
						>
							{item2.periods.length < 1 ? (
								<div
									style={{
										textAlign: 'center',
										verticalAlign: 'middle',
										fontWeight: 'bold',
										color: themeColor.themeColors.primaryColor,
										height: '103%'
									}}
								>
									<strong>
										<br />
										<br />/
									</strong>
								</div>
							) : (
								<div
									style={{
										fontWeight: 'bold',
										height: '103%',
										backgroundColor:
											general.timeTableColors[
												Math.floor(
													Math.random() *
														Math.floor(general.timeTableColors.length)
												)
											],
										color: 'white'
									}}
								>
									{' '}
									<br />
									<br />
									{item2.periods.map((item3, index) => (
										<h6>
											<strong style={{ display: 'inline-block' }}>
											<i class='fas fa-check-circle'/>
											</strong>
										</h6>
									))}
								</div>
							)}
						</td>
					))
				)}
			</tr>
		));

	const TimeTableView = (
		<div className="table-responsive">
			<table
				class="table table-hover text-center animated slideInUp table-bordered"
				style={{
					borderCollapse: 'separate',
					borderSpacing: '5px 10px',
					fontSize: '1vw'
				}}
			>
				<thead style={{ background: '#A9A9A9', color: 'white' }}>
					<tr class="vendorListHeading">
						<th
							rowSpan={2}
							scope="col"
							style={{
								textAlign: 'center',
								verticalAlign: 'middle'
							}}
						>
							<i
								class="fas fa-chalkboard-teacher"
								style={{ color: 'white' }}
							></i>{' '}
							<strong> Staff</strong>
						</th>
						{tHead.map(item => (
							<th
								colSpan={timeTablePeriods.length}
								style={{
									border: `1px solid ${themeColor.themeColors.primaryColor}`,
									borderColor: Colors.WHITE
								}}
								scope="col"
							>
								<div>
									<strong>{item.date}</strong>
									<br />
									<strong>{item.dayName}</strong>
								</div>
							</th>
						))}
					</tr>
					<tr class="vendorListHeading">
					{[...timeTablePeriods,...timeTablePeriods,...timeTablePeriods,...timeTablePeriods,...timeTablePeriods].map(item => (
							<th
								style={{
									border: `1px solid ${themeColor.themeColors.primaryColor}`,
									borderColor: Colors.WHITE
								}}
								scope="col"
							>
								<div>
									<strong>{item}</strong>
								</div>
							</th>
						))}
					</tr>
				</thead>
				<tbody>{bodyRendering(periods)}</tbody>
			</table>
		</div>
	);

	return (
		<div id="div1">
			<div class="divclass">
				<img
					src={require('../../Assets/images/header.png')}
					style={{height: 250}}
					alt="Logo Banner"
				/>
			</div>
			<div class="col-md-12">
				<div>
					<h2 className="h1-responsive font-weight-bold text-center my-4 generalHead" style={{background: themeColor.themeColors.primaryColor}}>
						Student Timetable{' '}
					</h2>
				</div>
			</div>
			{isLoading ? (
				<Spinner />
			) : tHead < 1 ? (
				<div
					style={{ display: 'flex', justifyContent: 'center', color: 'red' }}
				>
					<strong>No Data</strong>
				</div>
			) : (
				TimeTableView
			)}

			<div class="page-footer">
				<div class="text-left">Printed By: {props.name}</div>
				<div class="text-right" style={{ marginTop: -25, marginRight: 50 }}>
					Powered by School Smart®
				</div>
			</div>

			<div class="text-center text-md-right displayElemet">
				<label for="name" class="" onClick={() => PrintContent('div1')}>
					<i
						className="fas fa-print fa-2x animated fadeIn"
						style={{ marginRight: 4 }}
					/>
					Print
				</label>
			</div>
		</div>
	);
};

function mapStateToProps(state) {
	return {
		role: state.setUserReducer.users.role,
		name: state.setUserReducer.users.unique_name
	};
}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch)
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(StudentTimeTable);
