import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import $ from 'jquery';
import isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom'
import isNull from 'lodash/isNull'
import moment from 'moment'

import ThemeContext from "../../context/themeContext/ThemeContext";

class ViewMonthlyStudentAttendanceReport extends Component {
    constructor(props){
        super(props);
        this.state = {
            isLoading: true,
            modalEdit: false,
            editToggle: false,
            generateToggle: false,
            generateIndex: '',
            msg: '',
            editIndex: '',

			accountId: this.props.userData.unique_name,
			accountType: this.props.userData.role

        }

        


    }

    componentDidMount(){
        
        this.getSimple('Student', 'student')
        this.getSimple('classSectionGradeTeacher', 'classSectionGrade')
        this.getSimple('Session', 'session')
        this.generateMonthAndYear(30);
	}

	generateMonthAndYear = limit => {
		const year = new Date().getFullYear();
		const years = Array.from(new Array(limit), (val, index) => index + year);
		this.setState({
			yearList: years,
			monthList: [
				'January',
				'February',
				'March',
				'April',
				'May',
				'June',
				'July',
				'August',
				'September',
				'October',
				'November',
				'December'
			]
		});
	};

    getSimple = (type, state) => {
        this.props.sharedActions.getDataWithoutDispatch(
            `/api/${type}`
        ).then(success => { 
            this.setState({
                [state]: success,
                isLoading: false
            })


        }).catch(error => {
            this.setState({
                msg: 'Error Occured!!',
                errorMsg: true,
                isLoading: false
            })
            console.error('Error occured', error);
        })
    }


    


    PrintContent = el => {
        var restorepage = document.body.innerHTML;
        var Printcontent = document.getElementById(el).innerHTML;
        document.body.innerHTML = Printcontent;
        
        setTimeout(() => {
            window.print();
            window.location.reload();
        }, 700)
        document.body.innerHTML = restorepage;
        window.reload();
        
    };



    myFunction = () => {
		// Get the snackbar DIV
		var x = document.getElementById('snackbar');

		// StepperGeneralButtons the "show" class to DIV
		x.className = 'show';

		// After 3 seconds, remove the show class from DIV
		setTimeout(function() {
		}, 3000);
	};


        handleSearch = () => {
            const { studentId, classId, sectionId, month, year, sessionId } = this.state
            console.log(studentId, classId, sectionId, month, year, sessionId);
            
            
            if( isNull(this.state.studentId) || isNull(this.state.classId) || isNull(this.state.sectionId) ||  isNull(month) || isNull(year) || isNull(sessionId)){
                console.log('Empty');
                
                this.setState({
                    msg: "Please Fill The Form Repectively!",
                })            
                this.props.snackbar()
            }else{
                this.getSimple(`AllAttendanceReport/Student/?studentId=${this.state.studentId}&classId=${this.state.classId}&sectionId=${this.state.sectionId}&month=${month}&year=${year}`, 'studentAttendance')
                
            }
        }

    btnTechHover = () => {
		$(document).ready(function() {
			$('button').hover(
				function() {
					$(this).css('box-shadow', '0px 5px 5px grey');
					$(this).css('border-color', 'white');
				},
				function() {
					$(this).css('box-shadow', '0px 0px 0px white');
				}
			);
		});
	};

    onHandleText = e => {
		this.setState({
			[e.target.name]: e.target.value
        });
        console.log([e.target.name], e.target.value)
    };

    checkBook = (studentId) => {
        const { student } = this.state;
        let f = student.student.filter((data) => {
            return data.studentId == studentId
        })

        if(f.length > 0 ){
            this.setState({
                classId: f[0].classId,
                sectionId: f[0].sectionId,
                msg: 'Student Found!!',
            })
            this.props.snackbar();
        }else{
            this.setState({
                msg: 'Student Not Found!!',
            })
            this.props.snackbar();
        }
        

    }
    
    


    render(){

        const { search, isLoading, editToggle, msg, accountId, accountType, classSectionGrade, session, studentAttendance, student, monthList, yearList, date,
            studentId, classId, sectionId, sessionId, month, year, searchDate
        } = this.state;



        const Loader = (
                    <ThemeContext.Consumer>
        {(context) => (
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<div className="spinner-border" style={{ color: context.themeColors.primaryColor }}></div>
			</div>
                                                                                                                                                )}
                                                                                                                                                </ThemeContext.Consumer>
        );
        
        const renderStudentAttendanceData = studentAttendance && studentAttendance.datelist.map((item, index) => {
            return item <= moment().format('MM/DD/YYYY') && (

                <ThemeContext.Consumer>
                {(context) => (

            <tr key={index} >
                <th scope="row" style={{ padding: 1 }} >
                    {item}
                </th>
                {classSectionGrade && classSectionGrade.subject.map((data, index) => {
                    if(data.classId == classId){

                    var fill = studentAttendance.dateWise.filter(data2 => {
                        return data2.subjectId == data.subjectId && data2.date == item
                    })
                    console.log(fill)
                    return fill.length == 1 && fill[0].date == item ? <td className="borderSpace" style={{ padding: 1 } && fill[0].attendance[0].attendanceStatus == 'Present' ? fill[0].attendance[0].attendanceStatus == 'Present' && fill[0].attendance[0].lateTime == 'yes' ? { color: 'orange'} :  { color: 'green'} : { color: 'red'}}  >
                    {fill[0].attendance[0].attendanceStatus.substring(0,1) == 'A' && 
                    `${fill[0].attendance[0].attendanceStatus.substring(0,1)} - ${fill[0].attendance[0].leaveType.substring(0,1) == 'E' ? 'E' : 'NE'}`}
                    {fill[0].attendance[0].attendanceStatus.substring(0,1) == 'P' && 
                    `${fill[0].attendance[0].attendanceStatus.substring(0,1)} ${fill[0].attendance[0].lateTime == 'yes' ? ' - Late' : ''}`}
                </td>
         : <td className="borderSpace" style={{ padding: '1.5%' }}></td>
                    }
                })}
            </tr>
                                                                                                                                                )}
                                                                                                                                                </ThemeContext.Consumer>
            );
        }) 

        const renderSubjectList =  classSectionGrade && classSectionGrade.subject.map((data, index) => {
            return data.classId == classId &&  <th scope="col" style={{ padding: 1 }} key={data.subjectId} >
                {data.subjectName}
            </th>
        })

        return (
            <ThemeContext.Consumer>
        {(context) => (
            <div className="page-header"> 
                <SnackBar msg={msg} />
                <div style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                }}>
                    <div class="row">
                            <div class="col-xs-6 col-sm-6 col-md-6">
                                <h2  
                        style={{ background: context.themeColors.primaryColor }}
                        className="h1-responsive font-weight-bold text-center  generalHead">
                        Monthly Student Attendance Report
                    </h2>
							</div>
                            <div class="col-xs-6 col-sm-6 col-md-6 text-right">
								<h5>{date}</h5>
							</div>
                        </div>
                        <div class="row my-3 mx-5 py-4" style={{
                            border: `2px solid ${context.themeColors.primaryColor}`,
                            borderRadius: 20
                        }} >
                                       <div className="col-md-3">
                           <div className="input-group md-form mb-3" >
                            
                            <input class="form-control" 
                                type='text'
                                value={studentId}
                                name="studentId"
                                placeholder="Student ID"
                                onChange={this.onHandleText}
                                disabled={!student && !classSectionGrade}
                            />
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary" 
                                disabled={!student && !classSectionGrade}
                                onClick={() => this.checkBook(studentId)}
                                type="button">
                                    <i class="fas fa-search" ></i>
                                    </button>
                            </div>
                        </div>
                        </div>
            <div class="col-md-3" >
            <select class="custom-select" 
                                value={classId}
                                name="classId"
                                onChange={this.onHandleText}
                                disabled={!classSectionGrade}
                            >
                                        <option value="">Please Select Class</option>
                                       {classSectionGrade && classSectionGrade.classes.map((value, index) => {
                                           
                                           return <option value={value.classId}>{value.className}</option>
                                       })}
                            </select>
            </div>
            <div class="col-md-3" >
            <select class="custom-select" 
                                value={sectionId}
                                name="sectionId"
                                onChange={this.onHandleText}
                                disabled={!classId}

                            >
                                        <option value="">Please Select Section</option>
                                       {classSectionGrade && classSectionGrade.sections.map((value, index) => {


                                                return value.classId == classId && 
                                                <option value={value.sectionId}>{value.section}</option>

                                       })}
                            </select>
            </div>
            <div class="col-md-3" >
            <select class="custom-select" 
                                value={studentId}
                                name="studentId"
                                onChange={this.onHandleText}
                                disabled={!sectionId}

                            >
                                        <option value="">Please Select Student</option>
                                        {student && student.student.map((value, index) => {
                                           
                                           return  value.classId == classId && value.sectionId == sectionId &&
                                           <option value={value.studentId}>{value.Student}</option>
                                       })}
                            </select>
            </div>
            <div class="col-md-4" >
            <select class="custom-select" 
                                value={month}
                                name="month"
                                onChange={this.onHandleText}
                                disabled={!sectionId}

                            >
                                        <option value="">Please Select Month</option>
                                       {monthList && monthList.map((value, index) => {

                                                return <option value={value}>{value}</option>

                                       })}
                            </select>
            </div>
            <div class="col-md-4" >
            <select class="custom-select" 
                                value={year}
                                name="year"
                                onChange={this.onHandleText}
                                disabled={!month}

                            >
                                        <option value="">Please Select Year</option>
                                       {yearList && yearList.map((value, index) => {

                                                return <option value={value}>{value}</option>

                                       })}
                            </select>
            </div>
                            <div class="col-md-4" >
                            <select class="custom-select" 
                                                value={sessionId}
                                                name="sessionId"
                                                onChange={this.onHandleText}
                                                disabled={!sectionId}

                                            >
                                                        <option value="">Please Select Session</option>
                                                       {session && session.session.map((value, index) => {

                                                                return <option value={value.sessionId}>{value.currentSession}</option>

                                                       })}
                                            </select>
                            </div>
                            
    
                        </div>
                        <div class="row my-4">
                        <div className="col-md-12 text-center">

                                            <button
                                            class="btn btn-primary buttonAppear"
                                            onClick={this.handleSearch}
                                            style={{marginRight: '2%', background: context.themeColors.primaryColor}}
                                            >
                            <i class="fa fa-search"></i> Search
                                        </button>
                                        <Link to={{
                            pathname: "PrintMonthlyStudentAttendanceReport",
                            state: {
                                studentAttendance,
                                month,
                                year,
                                session: studentAttendance && session.session.filter(data => data.sessionId == sessionId)[0].currentSession,
                                classSectionGrade, 
                                classId
                            }
                        }}  >
                            <a href="#" data-toggle="tooltip" title="Edit!">
                                
                            <button
                                            class="btn btn-primary buttonAppear"
                                            disabled={!studentAttendance}
                                            style={{background: context.themeColors.primaryColor}}
                                            >
                            
                                    <i className="fas fa-list"></i> View
                                </button>
                            </a> 
                            
                        </Link>
                            </div>
                            
                        </div>
                        <div class="row my-2 mx-2 row colCheckb justify-content-between" style={{paddingLeft: '20px'}} >
                            <div class="col-md-4" >
                                    <span>Student Name: <strong style={{color: context.themeColors.primaryColor}} >{studentAttendance && studentAttendance.info.studentName}</strong></span>     
                            </div>
                            <div class="col-md-4"  >
                                    <span>Class: <strong style={{color: context.themeColors.primaryColor}} >{studentAttendance && studentAttendance.info.className}</strong></span>     
                            </div>
                            <div class="col-md-4" >
                                    <span>Section: <strong style={{color: context.themeColors.primaryColor}} >{studentAttendance && studentAttendance.info.section}</strong></span>     
                            </div>
                            <div class="col-md-4" >
                                    <span>Month: <strong style={{color: context.themeColors.primaryColor}} >{studentAttendance && month}</strong></span>     
                            </div>
                            <div class="col-md-4" >
                                    <span>Year: <strong style={{color: context.themeColors.primaryColor}} >{studentAttendance && year}</strong></span>     
                            </div>
                            <div class="col-md-4" >
                                    <span>Session: <strong style={{color: context.themeColors.primaryColor}} >{studentAttendance && session.session.filter(data => data.sessionId == sessionId)[0].currentSession}</strong></span>     
                            </div>
                        </div>
                    <div className="table-responsive">
					<table className="table table-hover table-bordered text-center">
						<thead style={{ background: context.themeColors.primaryColor, color: 'white' }}>
							<tr>
								<th scope="col" style={{ padding: 1 }}>
									Date
								</th>
                                {renderSubjectList}
							</tr>
						</thead>
						<tbody>{isLoading ? Loader : renderStudentAttendanceData}</tbody>
					</table>
				</div>
                </div>
            </div>
                                                                                                                                                )}
                                                                                                                                                </ThemeContext.Consumer>
        );
    }

}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch),
	};
}
function mapStateToProps(state) {
	return {
		userData: state.setUserReducer.users,
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ViewMonthlyStudentAttendanceReport);
