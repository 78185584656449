import React, { useEffect, useState } from 'react';
import useHttp from '../../../../hooks/http';
import CustomReportComponent from '../../../../components/Reports/CustomReportComponent.jsx';
import img1 from '../../../../Assets/images/toplog.jpg';
import CustomReportHeader from '../../../../components/Reports/CustomReportHeader/CustomReportHeader.jsx';
import CustomHeading from '../../../../components/CustomHeading';
import {
	MAIN_HEADING,
	disableColorOverCondition,
	viewClassReturn
} from '../../../../utils/general/index.js';
import ViewComponent from '../../../../components/ViewComponent/ViewComponent';
import useCustomHttpWithDataMiddleware from '../../../../hooks/Services/httpWithCustomDataMiddleware';

import * as sharedActions from '../../../../Actions/sharedActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Colors from '../../../../utils/app_constants/colors_constants';
import $ from 'jquery';
import Button from '../../../../components/Button';
import SnackBar from '../../../../components/SnackBar/SnackBar';
import useHttpWithCaAssignMarksDataMiddleware from '../../../../hooks/Services/httpWithCaAssignMarksDataMiddleware';

function AssignMarks(props) {
	debugger;
	const [snackbarMsg, setSnackbarMsg] = useState('');
	const [studentId, setStudentId] = useState('');
	const [graphType, setGraphType] = useState('barChart');
	const [modalData, setModalData] = useState({});
	const [toolData, setToolData] = useState([]);
	const [newData, setData] = useState({});
	const [extractToolIds, setExtractToolsId] = useState([]);
	const { id } = props.match.params;
	const [isLoading, fetchData] = useHttp(
		`/api/OverAllScoreTemplate?pId=${id}`,
		[]
	);

	useEffect(() => {
		props.sharedAction
			.getDataWithoutDispatch(`/api/GetTemplateMarks?pId=${id}`)
			.then(success => {
				setData(success);
				debugger;
			})
			.catch(error => {
				debugger;
			});
	}, []);
	const testedArray = (arr = [], propName) => {
		let isEmpty = false;
		arr.forEach(element => {
			if (element[propName] === '') {
				isEmpty = true;
			}
		});
		return isEmpty;
	};

	const isEmpty = (arrobj, propName) => {
		let flag = false;
		let getSubTool = arrobj.map(item => item.subTools);

		let getResult = getSubTool.map(item => testedArray(item, propName));
		console.log(getResult);

		return getResult.every(item => item === false);
	};
	const myAction2 = (gid, data, ...args) => {
		props.sharedAction
			.getDataWithoutDispatch(`/api/GetAllMark/?studentId=${gid}&pId=${id}`)
			.then(success => {
				setStudentId(gid);
				console.log(args[0]);
				console.log('new Data: ', newData);
				let { tool } = args[0];
				debugger;
				console.log(newData);
				setToolData([]);
				let getToolId = new Set();
				let desireOBJ = {};
				console.log('working over my actions:', id);
				debugger;
				let getStudent = tool.filter(item => item.studentId == gid);
				let getSubTools = getStudent.map(item => item.subTools);

				// let getUpdated = success['assignAllToolMarks'][index]['subTools_CA_AssignMarks'].map(item =>
				// 	item.map(itemd => {
				// 		return {
				// 			...itemd,
				// 			min: 0,
				// 			max: 5
				// 		};
				// 	})
				// );

				let getDesireStudent = getStudent.map((item, index) => {
					return {
						...item,
						subTools:
							success['assignAllToolMarks'][index]['subTools_CA_AssignMarks']
					};
				});
				debugger;

				debugger;
				debugger;
				setToolData(getDesireStudent);
				debugger;

				setExtractToolsId([...getToolId]);
				debugger;
				setModalData(getStudent && getStudent[0]);
				debugger;

				$('.bd-example-modal-lg-1').modal('show');
				debugger;
			})
			.catch(error => {
				setSnackbarMsg(
					error &&
						error.response &&
						error.response.data &&
						error.response.data.Message
				);
				props.snackbar();
				debugger;
			});
	};
	const myAction = (gid, data, ...args) => {
		debugger;

		props.sharedAction
			.getDataWithoutDispatch(
				`/api/CheckAlreadyAssignCAMarks?studentId=${gid}&pId=${id}`
			)
			.then(success => {
				setStudentId(gid);
				console.log(args[0]);
				console.log('new Data: ', newData);
				let { tool } = args[0];
				debugger;
				console.log(newData);
				$('input:text').val('');
				setToolData([]);
				let getToolId = new Set();
				let desireOBJ = {};
				console.log('working over my actions:', id);
				debugger;
				let getStudent = tool.filter(item => item.studentId == gid);
				let getSubTools = getStudent.map(item => item.subTools);

				let getUpdated = getSubTools.map(item =>
					item.map(itemd => {
						return {
							...itemd,
							marks: ''
						};
					})
				);

				let getDesireStudent = getStudent.map((item, index) => {
					return {
						...item,
						subTools: getUpdated[index]
					};
				});

				debugger;
				debugger;
				setToolData(getDesireStudent);
				debugger;
				getStudent[0]['subTools'].map(item => getToolId.add(item.toolId));

				setExtractToolsId([...getToolId]);
				debugger;
				setModalData(getStudent && getStudent[0]);
				debugger;

				$('.bd-example-modal-lg').modal('show');

				debugger;
			})
			.catch(error => {
				setSnackbarMsg(
					error &&
						error.response &&
						error.response.data &&
						error.response.data.Message
				);
				props.snackbar();
				debugger;
			});
	};
	const [isLoader, studentMarks] = useHttpWithCaAssignMarksDataMiddleware(
		`/api/OverAllScoreTemplate?pId=${id}`,
		[],
		{
			Action: [
				{
					handler: myAction,
					isEnable: true,
					type: 'modalButton',
					modalKey: '.bd-example-modal-lg',
					title: 'Assign Marks',
					iconName: 'fas fa-plus',
					iconColor: Colors.SEA_GREEN_THEME
				},
				{
					exceptionCase: true,
					isEnable: true,
					type: 'actionLink',
					title: 'Assign Marks',
					initialPath: '/dashboard/CAEditAssignMarks',
					iconName: 'fas fa-pen',
					iconColor: Colors.BLACK
				}
			]
		},
		false,
		'studentMarks'
	);
	debugger;
	const onChangeToolData = (e, subIndex, toolIndex, min, max) => {
		console.log(e.target.value);

		debugger;
		if (parseInt(e.target.value) <= min || e.target.value === '') {
			debugger;
			let getToolData = JSON.parse(JSON.stringify(toolData));
			let getToolNode = getToolData[toolIndex];
			let { subTools } = getToolNode;
			subTools[subIndex]['marks'] = e.target.value;
			subTools[subIndex]['studentId'] = studentId;
			console.log(getToolData);

			setToolData(getToolData);
			debugger;
		}
	};

	const PrintContent = el => {
		var restorepage = document.body.innerHTML;
		var Printcontent = document.getElementById(el).innerHTML;
		document.body.innerHTML = Printcontent;
		window.print();
		// document.body.innerHTML = restorepage;
		// setTimeout(function() {
		// 	window.location.reload();
		// }, 0);
	};
	const onHandleSubmit = () => {
		console.log(toolData);
		let getWhole = [];

		let getSubTools = toolData.map(item => item.subTools);
		let mergeAllArrays = getSubTools.map(item => getWhole.push(...item));
		console.log(getWhole);
		props.sharedAction
			.simpleAddRequest(`/api/AssignMark`, {
				CA_AssignMark: [...getWhole]
			})
			.then(success => {
				debugger;
				$('.bd-example-modal-lg').modal('hide');
				props.history.push('/dashboard/CASubToolView');
			})
			.catch(error => {
				if (error && error.response && error.response.status == 404) {
					//404-Error no understanding of 404 Error
					debugger;
				}
			});

		debugger;
		debugger;
	};
	const onEditSubmit = () => {
		console.log(toolData);
		debugger;
	};
	console.log('Student Discipline Reports', props);
	return (
		<div id="printDiv">
			<SnackBar backColor="#000" msg={snackbarMsg} />
			<div>
				<img src={img1} alt="#img" style={{ width: '100%', height: '30%' }} />
				<CustomHeading
					headingText={'ASSIGN MARKS'}
					type={MAIN_HEADING}
					className="reportHead"
				/>

				<CustomReportHeader
					headRenderData={[
						{ displayData: 'Subject Name', fieldName: 'subject' },
						{ displayData: 'Grade Name', fieldName: 'gradeName' },
						{ displayData: 'Course Type', fieldName: 'courseType' }
					]}
					targetedObj="primaryInfoes"
					headData={fetchData}
				/>
				<CustomReportHeader
					headRenderData={[
						{ displayData: 'Class Name', fieldName: 'className' },
						{ displayData: 'Section', fieldName: 'section' },
						{ displayData: 'Term', fieldName: 'term' },
						{ displayData: 'Session', fieldName: 'session' }
					]}
					targetedObj="primaryInfoes"
					headData={fetchData}
				/>
				<br />
				<ViewComponent
					extraData={newData}
					mainData={studentMarks}
					conditionalIconsColor={disableColorOverCondition}
					exceptionalHandler={viewClassReturn}
					headData={['S #', 'Student Name', 'Actions']}
					excludedData={['pId', 'studentId', 'CA_AssignMark']}
					redirectIds={['studentId', 'pId']}
					children={() => (
						<React.Fragment>
							<div
								class="modal fade bd-example-modal-lg"
								tabindex="-1"
								role="dialog"
								aria-labelledby="myLargeModalLabel"
								aria-hidden="true"
							>
								<div class="modal-dialog modal-lg">
									<div style={{ borderRadius: 20 }} class="modal-content">
										<div className="caAssignMarksHead">
											<h1 style={{ textAlign: 'center' }}>
												{modalData['studentName']}
											</h1>
										</div>
										<hr
											style={{
												border: '2px solid #01AC8A',
												width: '100%',
												margin: 0
											}}
										/>
										<div>
											{toolData &&
												toolData.map((item, toolIndex) => (
													<React.Fragment>
														<div>
															<h3 style={{ textAlign: 'center', padding: 5 }}>
																{item.toolName}
															</h3>
															<table className="table table-bordered">
																<tr style={{ textAlign: 'center' }}>
																	{item.subTools.map(subItem => (
																		<td
																			style={{
																				backgroundColor: '#01AC8A',
																				color: '#fff'
																			}}
																		>
																			{
																				<React.Fragment>
																					{subItem.subToolName}
																					{`(`}
																					<b>{subItem.min}</b>
																					{' | '}
																					<b>{subItem.max}</b>
																					{`)`}
																				</React.Fragment>
																			}
																		</td>
																	))}
																</tr>
																<tr style={{ textAlign: 'center' }}>
																	{item.subTools.map((subItem, index) => (
																		<td>
																			<React.Fragment>
																				<input
																					// minLength={3}
																					style={{ width: '30%' }}
																					placeholder={`${subItem.min} - ${subItem.max}`}
																					onChange={e =>
																						onChangeToolData(
																							e,
																							index,
																							toolIndex,
																							subItem.min,
																							subItem.max
																						)
																					}
																					value={subItem['marks']}
																					type="text"
																				/>
																			</React.Fragment>
																		</td>
																	))}
																</tr>
															</table>
														</div>
													</React.Fragment>
												))}
											<div style={{ textAlign: 'center' }}>
												<Button
													customClause={!isEmpty(toolData, 'marks')}
													btnName="Submit"
													buttonClass="btn-smart-one"
													textColor="#fff"
													btnBackColor={'#0A4F5E'}
													onHandleSubmit={onHandleSubmit}
												/>
											</div>
											<br />
										</div>
									</div>
									{/* <div>
										{toolData &&
											toolData.map((item, index) => (
												<h3>
													{item['toolId'] == extractToolIds[index]
														? item['toolName']
														: ''}
												</h3>
											))}
									</div> */}
								</div>
							</div>
						</React.Fragment>
					)}
				/>
				{/* <table className="table table-bordered">
					<thead>
						<th>
							<table className="table table-bordered">
								<tr>
									<th>S #</th>
									<th> Student Name </th>
									{fetchData &&
										fetchData['toolSubtTool'].map((outerItem, index) => (
											<td
												style={{
													padding: 0,

													flexDirection: 'column'
												}}
											>
												<tr style={{ textAlign: 'center', display: 'block' }}>
													{outerItem['toolName']}
												</tr>
												<tr style={{ alignSelf: 'center' }}>
													{outerItem['subTools'].map(
														(innerItem, innerIndex) => (
															<React.Fragment>
																<td style={{ fontSize: 11 }} id="mySpan">
																	{' '}
																	{innerItem['subToolName']}
																</td>{' '}
															</React.Fragment>
														)
													)}
												</tr>
											</td>
										))}
								</tr>
								{fetchData &&
									fetchData['studentMarks'].map((item, index) => (
										<tr key={index}>
											<td>{index + 1}</td>
											<td>{item.studentName}</td>
											{getToolsId &&
												getToolsId.map((inneritem, index) => (
													<td>
														{getToolNameForRendering(
															inneritem,
															item['CA_AssignMark'],
															index
														)}
													</td>
												))}
										</tr>
									))}
							</table>
						</th>
					</thead>
				</table> */}
			</div>
			<div style={{ marginTop: 20 }} class="text-center text-md-right">
				<label for="name" class="" onClick={() => PrintContent('printDiv')}>
					<i
						className="fas fa-print fa-2x animated fadeIn"
						style={{ marginRight: 4 }}
					/>
					Print It Out
				</label>
			</div>
		</div>
	);
}

function mapDispatchToProps(dispatch) {
	return {
		sharedAction: bindActionCreators(sharedActions, dispatch)
	};
}
export default connect(
	null,
	mapDispatchToProps
)(AssignMarks);

{
	/*

    				<table className="table table-bordered">
					<thead>
						<tr>
							<th rowSpan={2}>S #</th>
							<th rowSpan={2}> Student Name </th>
							{fetchData &&
								fetchData['toolSubtTool'].map((item, index) => (
									<th style={{ textAlign: 'center' }}>{item.toolName}</th>
								))}
						</tr>
						<tr>
							{fetchData &&
								fetchData['toolSubtTool'].map((item, index) => (
									<th>
										<div
											style={{
												display: 'flex',
												justifyContent: 'space-around'
												// border: '1px solid black'
											}}
										>
											{renderTools(item.toolName, item.subTools)}
										</div>
									</th>
								))}
						</tr>
						<React.Fragment>
							{fetchData &&
								fetchData['studentMarks'].map((item, index) => (
									<React.Fragment>
										<tr>
											<td>{index + 1}</td>
											<td>{item['studentName']}</td>
											{takeArray(item['CA_AssignMark'])}
										</tr>
									</React.Fragment>
								))}
						</React.Fragment>
					</thead>
				</table>

    */
}
