import React, { Component } from "react";
import DashboardLayout from "../components/HOC/DashboardLayout";
import { images, renderingStuff } from "../utils";
import NewViewRendering from "../components/ViewRendering/NewViewRendering";

export default function AcademicsPlanDashboard(props) {
  return (
    <DashboardLayout name="Academic Plan Dashboard" image={images.imgAcademicPlan}>
      <NewViewRendering {...props} data={renderingStuff.academicPlan} />
    </DashboardLayout>
  );
}
