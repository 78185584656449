import React from 'react';
import '../css/pnf.css';
import img from '../Assets/images/notfound.jpeg';
const PageNotFound = () => {
	return (
		<div className="container">
			<img src={img} alt="Nature" class="responsive"></img>
		</div>
	);
};
export default PageNotFound;
