import React from 'react';
import $ from 'jquery'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import Spinner from '../../components/Spinner/Spinner';
import ThemeContext from "../../context/themeContext/ThemeContext";
import moment from 'moment'

class MedicalSlipTemplate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            date: moment().format('MM/DD/YYYY'),
            patient: {},
            isLoading: true
        }
    }
    componentDidMount() {
        const { match } = this.props;
        $(document).ready(function () {
            $('[data-toggle="tooltip"]').tooltip();
        });
        this.props.sharedActions
            .getDataWithoutDispatch(
                `/api/PatientDetail/?id=${match.params.id}&post=${match.params.post}`
            )
            .then(success => {
                console.log(success);
                this.setState({
                    patient: success,
                    isLoading: false
                })
            })
            .catch(error => {
                this.setState({
                    isLoading: false
                });
                console.log(error);
            });
    }

    PrintContent = el => {
        var restorepage = document.body.innerHTML;
        var Printcontent = document.getElementById(el).innerHTML;
        document.body.innerHTML = Printcontent;
        
        setTimeout(() => {
            window.print();
            window.location.reload();
        }, 700)
        document.body.innerHTML = restorepage;


        
    };
    render() {
        const { isLoading, patient } = this.state;
        const voucher = (
            <ThemeContext.Consumer>
            {(context) => (
            <section class='mb-4'>
                <div class="divclass">
                    <img src={require('../../Assets/images/header.png')} alt='Logo Banner' />
                </div>
                <div class='row text-center' style={{ flexGrow: 1, borderBottom: 5, borderRight: 0, borderLeft: 0, borderStyle: 'solid', borderColor: context.themeColors.primaryColor, margin: '5px' }}>
                    <div class='col-md-12 print'>
                        <h2 style={{
                            backgroundColor: context.themeColors.primaryColor,
                            textAlign: 'center',
                            color: 'white'
                        }}
                            className="h1-responsive font-weight-bold text-center my-4 generalHead">
                            {' '}
                            Medical Slip{' '}
                        </h2>
                    </div>
                </div>
                <br />
                <div class="card">
                    <div class="card-body">
                        <div class='row'>
                            <div class='col-md-12' style={{ padding: 40 }}>
                                <strong style={{ fontSize: 22 }}>Date: </strong>&nbsp;
								<label
                                    for="name"
                                    class=""
                                    style={{ fontWeight: 'bold', fontSize: 22, color: '#01425e' }}
                                >{this.state.date}</label>
                            </div>
                            <div class='col-md-6' style={{ padding: 40 }}>
                                <strong style={{ fontSize: 22 }}>Patient Name: </strong>&nbsp;
								<label
                                    for="name"
                                    class=""
                                    style={{ fontWeight: 'bold', fontSize: 22, color: '#01425e' }}
                                >{patient.name}</label>
                            </div>
                            <div class='col-md-6' style={{ padding: 40 }}>
                                <strong style={{ fontSize: 22 }}>Student / Staff: </strong>&nbsp;
								<label
                                    for="name"
                                    class=""
                                    style={{ fontWeight: 'bold', fontSize: 22, color: '#01425e' }}
                                >{patient.studentStaff}</label>
                            </div>
                            {patient.studentStaff === 'Student' ?
                                <div class='col-md-6' style={{ padding: 40 }}>
                                    <strong style={{ fontSize: 22 }}>Grade</strong>&nbsp;
                                                        <label
                                        for="name"
                                        class=""
                                        style={{ fontWeight: 'bold', fontSize: 22, color: '#01425e' }}
                                    >{patient.className}-{patient.section}</label>
                                </div>
                                :
                                <div class='col-md-6' style={{ padding: 40 }}>
                                    <strong style={{ fontSize: 22 }}>Staff Post: </strong>&nbsp;
								<label
                                        for="name"
                                        class=""
                                        style={{ fontWeight: 'bold', fontSize: 22, color: '#01425e' }}
                                    >{patient.staffPost}</label>
                                </div>
                            }
                            <div class='col-md-6' style={{ padding: 40 }}>
                                <strong style={{ fontSize: 22 }}>Contact No: </strong>&nbsp;
								<label
                                    for="name"
                                    class=""
                                    style={{ fontWeight: 'bold', fontSize: 22, color: '#01425e' }}
                                >{patient.contactNo}</label>
                            </div>
                            <div class='col-md-6' style={{ padding: 40 }}>
                                <strong style={{ fontSize: 22 }}>Doctor Name: </strong>&nbsp;
								<label
                                    for="name"
                                    class=""
                                    style={{ fontWeight: 'bold', fontSize: 22, color: '#01425e' }}
                                >{patient.doctorName}</label>
                            </div>
                            <div class='col-md-6' style={{ padding: 40 }}>
                                <strong style={{ fontSize: 22 }}>Problem: </strong>&nbsp;
								<label
                                    for="name"
                                    class=""
                                    style={{ fontWeight: 'bold', fontSize: 22, color: '#01425e' }}
                                >{patient.problem}</label>
                            </div>
                            {patient.studentStaff === 'Student' ? (
                                <div class='col-md-6' style={{ padding: 40 }} >
                                    <strong style={{ fontSize: 22 }}>Medical Condition: </strong>&nbsp;
                                    <label
                                        for="name"
                                        class=""
                                        style={{ fontWeight: 'bold', fontSize: 22, color: patient.medicalCondition }}
                                    >{patient.medicalCondition === '#d9534f'
                                        ? 'Critical'
                                        : patient.medicalCondition === '#f0ad4e'
                                            ? 'Serious'
                                            : patient.medicalCondition === '#5cb85c'
                                                ? 'Normal'
                                                : ''
                                        }</label>
                                </div>
                            )
                                : ''}
                            {patient.studentStaff === 'Student' ? (
                                <div class='col-md-6' style={{ padding: 40 }}>
                                    <strong style={{ fontSize: 22 }}>Recommendation: </strong>&nbsp;
                                    <label
                                        for="name"
                                        class=""
                                        style={{ fontWeight: 'bold', fontSize: 20, color: '#01425e' }}
                                    >{patient.recommadationForParents}</label>
                                </div>
                            )
                                : ''}
                        </div>
                        <div class='row text-center' style={{ marginTop: 40, alignItems: 'center', justifyContent: 'center' }}>
                            <div class='col-md-6'>
                                <strong style={{ fontSize: 22 }}>Created By: </strong>&nbsp;
								<label
                                    for="name"
                                    class=""
                                    style={{ fontWeight: 'bold', fontSize: 22, color: '#01425e' }}
                                >{patient.accountId}</label>
                            </div>
                            <div class='col-md-6'>
                                <strong style={{ fontSize: 22 }}>Account: </strong>&nbsp;
								<label
                                    for="name"
                                    class=""
                                    style={{ fontWeight: 'bold', fontSize: 22, color: '#01425e' }}
                                >{patient.accountType}</label>
                            </div>
                        </div>
                        <div class='row text-center' style={{ marginTop: 100, alignItems: 'center', justifyContent: 'center' }}>
                            <h4 style={{ textDecoration: 'overline' }}>Signature</h4>
                        </div>
                    </div>
                </div>
            </section>
                                                )}
                                                </ThemeContext.Consumer>
        )
        return (
            <div>
                <div id='div1'>
                    <div class='container'>
                        {isLoading ? <Spinner /> : voucher}
                    </div>
                    <div id="footer">
                        <div class="h1-responsive font-weight-bold text-center my-4">
                            <img
                                src={require('../../Assets/images/Powerd-01.svg')}
                                style={{ width: 200 }}
                                alt='Powered BY'
                            />
                        </div>
                    </div>
                </div>

                <div class="text-center text-md-right">
                    <label for="name" class="" onClick={() => this.PrintContent('div1')}>
                        <i
                            className="fas fa-print fa-2x animated fadeIn"
                            style={{ marginRight: 4 }}
                        />
                        Print
                            </label>
                </div>

            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        sharedActions: bindActionCreators(sharedActions, dispatch)
    };
}

export default connect(null, mapDispatchToProps)(MedicalSlipTemplate);