import React from 'react';

export default function CustomLabel(props) {
	const {
		displayData = '',
		position = 'center',
		labelName = 'labelName'
	} = props;
	return (
		<div>
			<h5 style={{ textAlign: position, fontWeight: 'bold', marginLeft: 15 }}>
				{labelName}&nbsp;:&nbsp;{displayData === '' ? '---------' : displayData}
			</h5>
		</div>
	);
}
