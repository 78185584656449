import React, { useState, useEffect, useContext } from "react";
import http from "../../hooks/http";
import { ViewDataTable } from "../../components/Edit";
import axios from "axios";
import config from "../../config/config";
import { connect } from "react-redux";
import $ from "jquery";
import SnackBar from "../../components/SnackBar/SnackBar";
import ThemeContext from "../../context/themeContext/ThemeContext";
const ViewLessonWeek = (props) => {
  const themeContext = useContext(ThemeContext);
  let [isLoading, fetchData, setFetchData, setIsLoading] = http(
    "/api/lessonperweeks",
    []
  );
  let [getDeleteId1, setDeleteId] = useState(null);
  let [getEditId, setEditId] = useState(null);
  let [Snackmsg, setSnackMsg] = useState("");
  let [getEditData, setEditData] = useState(null);
  let [errors, setErrors] = useState({});
  let [localLoad, setNewLoader] = useState(false);
  let [classSectionData, setclassSectionData] = useState(null);
  let [className, setClassName] = useState("");
  let [gradeName, setGradeName] = useState("");
  let [grade, setGradeData] = useState("");
  let [gradeId, setGradeId] = useState("");
  let [successflag, setSuccessFlag] = useState(false);
  let [section, setSection] = useState("");
  let [subjectName, setSubjectName] = useState(null);
  let [classId, setClassId] = useState("");
  let [subjectId, setSubjectId] = useState("");
  let [quantity, setQuantity] = useState("");
  let [sectionId, setSectionId] = useState("");
  let [disabledField, setDisabledFeild] = useState(true);
  let [subjects, setSubjects] = useState([]);
  let [newLoader, setNewLoader1] = useState(false);

  const onGeneralChange = (e) => {
    if (e.target.name === "subjectId") {
      setSubjectId(e.target.value);
      console.log(subjects);
      let getSubName = subjects.filter(
        (item) => item.subjectId === parseInt(e.target.value)
      )[0].subjectName;
      setSubjectName(getSubName);
    } else if (e.target.name === "quantity") {
      setQuantity(e.target.value);
    }
  };
  const validation = () => {
    if (
      classId !== "" &&
      sectionId !== "" &&
      quantity !== "" &&
      subjectId !== "" &&
      gradeId !== ""
    ) {
      let newErrors = { ...errors };
      console.log("every thing is fine");
      newErrors.fieldValidate = false;
      setErrors(newErrors);
      return false;
    } else {
      console.log("some thing is fishy");
      let newErrors = { ...errors };
      newErrors.fieldValidate = true;
      setErrors(newErrors);
      return true;
    }
  };

  const LPWremoveElement = () => {
    let getDeleteId = getDeleteId1;
    let token = "";
    if (localStorage.jwtToken) token = localStorage.getItem("jwtToken");
    axios
      .delete(`${config.localhttp}/api/lessonperweeks/${getDeleteId}`, {
        data: { getDeleteId }, // or data: jdId, depending on how you handle it in the back end
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((success) => {
        if (success.status === 200) {
          let getNewFilterArray = fetchData.filter(
            (item) => item.id !== getDeleteId
          );
          setSnackMsg("Remove Successfully!");
          $("#ttDeleteCon").modal("hide");
          setFetchData(getNewFilterArray);
          props.snackbar();
          setDeleteId(null);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const onSectionChange = (e) => {
    setSectionId(e.target.value);

    const { gradeSection } = classSectionData;
    let getSectionName = gradeSection.filter(
      (item) => item.sectionId === parseInt(e.target.value)
    )[0].section;
    setSection(getSectionName);
    if (e.target.value !== "Choose Section") {
      setNewLoader1(true);
      setDisabledFeild(true);
      let token = "";
      if (localStorage.jwtToken) token = localStorage.getItem("jwtToken");
      axios
        .get(
          `${config.localhttp}/api/AssignSubjectsToTeacher?classId=${classId}&sectionId=${e.target.value}&gradeId=${gradeId}`,
          {
            headers: { Authorization: `Bearer  ${token}` },
          }
        )
        .then((success) => {
          console.log(success);
          setNewLoader1(false);
          setDisabledFeild(false);
          setSubjects(success.data.subject);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const onChangeClassName = (e) => {
    console.log(e.target.value);
    const { gradeSection } = classSectionData;
    let getGradeData = gradeSection.filter(
      (item, index) => item.className === e.target.value
    );
    console.log(getGradeData);

    setGradeData(getGradeData);
    setClassName(e.target.value);
    setGradeName(getGradeData[0] && getGradeData[0].gradeName);
    setGradeId(getGradeData[0] && getGradeData[0].gradeId);
    setClassId(getGradeData[0] && getGradeData[0].id);
    setSuccessFlag(false);
  };
  const LPWEditClick = (obj) => {
    setEditId(obj.id);
    setNewLoader(true);
    let token = "";
    if (localStorage.jwtToken) token = localStorage.getItem("jwtToken");
    Promise.all([
      axios.get(`${config.localhttp}/api/ClassesSections`, {
        headers: { Authorization: `Bearer  ${token}` },
      }),
      axios.get(
        `${config.localhttp}/api/AssignSubjectsToTeacher?classId=${obj.ClassID}&sectionId=${obj.SectionID}&gradeId=${obj.GradeID}`,
        {
          headers: { Authorization: `Bearer  ${token}` },
        }
      ),
    ])
      .then((res) => {
        console.log("action creator response ", res);
        // console.log(res.data[0]);

        const { gradeSection } = res[0].data;
        let getGradeData = gradeSection.filter(
          (item, index) => item.className === obj.Class
        );
        setGradeData(getGradeData);
        setclassSectionData(res[0].data);
        setGradeName(obj.Grade);
        setGradeId(obj.GradeID);
        setClassId(obj.ClassID);
        setSubjects(res[1].data.subject);
        setSubjectId(obj.SubjectID);
        setSectionId(obj.SectionID);
        setSubjectName(obj.Subject);
        setQuantity(obj.quantity);
        setClassName(obj.Class);
        setDisabledFeild(false);
        setSection(obj.Section);
        setNewLoader(false);
        return res.data;
      })
      .catch((error) => {
        setNewLoader(false);
        console.log(error);
      });
    $("#ttEditModal").modal();
  };
  const LPWDeleteClick = (obj) => {
    $("#ttDeleteCon").modal();

    setDeleteId(obj.id);
  };
  const onEditSubmit = () => {
    debugger;
    if (!validation()) {
      debugger;
      let token = "";
      if (localStorage.jwtToken) token = localStorage.getItem("jwtToken");
      axios
        .put(
          `${config.localhttp}/api/lessonperweeks/${getEditId}`,
          {
            id: getEditId,
            GradeID: gradeId,
            Grade: gradeName,
            ClassID: classId,
            Class: className,
            SectionID: sectionId,
            SubjectID: subjectId,
            quantity,
            date: new Date().toLocaleDateString(),
            time: new Date().toLocaleTimeString(),
            accountId: props.name,
            accountType: props.role,
          },
          {
            headers: { Authorization: `Bearer  ${token}` },
          }
        )
        .then((res) => {
          debugger;
          console.log(quantity);

          if (res.status === 200) {
            let updatedFetchData = [...fetchData];
            let updateIndex = updatedFetchData.findIndex(
              (item) => item.id === getEditId
            );
            let getSection = updatedFetchData.filter(
              (item) => item.SectionID === parseInt(sectionId)
            )[0];
            updatedFetchData[updateIndex].GradeID = gradeId;
            updatedFetchData[updateIndex].Grade = gradeName;
            updatedFetchData[updateIndex].ClassID = classId;
            updatedFetchData[updateIndex].Class = className;

            updatedFetchData[updateIndex].Section = section;

            updatedFetchData[updateIndex].SectionID = sectionId;
            updatedFetchData[updateIndex].quantity = quantity;
            updatedFetchData[updateIndex].Subject = subjectName;
            updatedFetchData[updateIndex].SubjectID = subjectId;

            setFetchData(updatedFetchData);
            setEditId(null);
            console.log("action creator response ", res);
            console.log(res.data);
            setSnackMsg("Update Successfully!");
            $("#ttEditModal").modal("hide");
            props.snackbar();
            return res.data;
          }
        });
    }
  };
  const checkingFun = (name, value, feildname) => {
    if (feildname === "subjectId") {
      setSubjectName(name);
    } else if (feildname === "sectionId") {
      setSection(name);
    }
    return value;
  };

  return (
    <div>
      <SnackBar msg={Snackmsg} />
      <h2
        className="generalHead"
        style={{
          width: "fit-content",
          background: themeContext.themeColors.primaryColor,
        }}
      >
        {" "}
        Lesson Per Week Details{" "}
      </h2>
      <ViewDataTable
        // onSubmit={onEditSubmit}
        // onChangeDynamic={onChangeDynamic}
        subAndTechData={classSectionData}
        // eidtHorizonData={editHorizonData}
        // daysForEdit={workingDays}
        stateData={{
          subjects,
          quantity,
          subjectId,
          gradeId,
          successflag,
          section,
          sectionId,
          grade,
          gradeName,
          classId,
          className,
          disabledField,
          onGeneralChange,
          errors,
          onEditSubmit,
          setSection,
          checkingFun,
          isLoading: localLoad,
          dataLoader: newLoader,
        }}
        LPWData={{
          grade,
          gradeName,
          className,
          gradeId,
          classId,
          successflag,
          subjects,
          onGeneralChange,
          onEditSubmit,
        }}
        localLoad={localLoad}
        // ViewClick={LPWDeleteClick}
        onSectionChange={onSectionChange}
        EditClick={LPWEditClick}
        DeleteClick={LPWDeleteClick}
        removeElement={LPWremoveElement}
        onChangeClassName={onChangeClassName}
        // headerDetails={headerDetails}
        // techNames={techNames}
        // horizontalData={horizontalData}
        // workingDays={workingDays}
        // editPeriodData={editPeriodData}
        // periodBoxData={periodBoxData}
        // periods={periods}
        renderFields={[
          "Class",
          "Grade",
          "Section",
          "Subject",
          "quantity",
          "accountId",
          "accountType",
        ]}
        Data={fetchData}
        Loading={isLoading}
        actionsName={["LessonDelete", "LessonEdit"]}
        actionFieldName="viewLesson"
        Actions={[
          {
            header: false,

            viewLesson: "LessonEdit",
            tooltipTitle: "Edit!",
            btnId: "ttEdit",
            // fullModal: 'modal-full',
            type: "reuse",
            iconName: "fa-pen",
            mdId: "ttEditModal",
            height: 1000,
            modalTitle: "Edit Lesson Per Week",
            modalBody: "edit body",
            modalSize: "modal-lg",
            handleSubmit: () => onEditSubmit(),
          },
          {
            header: true,
            viewLesson: "LessonDelete",
            tooltipTitle: "Delete!",
            fullModal: "",
            height: 100,
            btnId: "ttDelete",
            iconName: "fa-trash-alt",
            color: "#D11A2A",
            mdId: "ttDeleteCon",
            modalTitle: "Confirmation",
            type: "reuse",
            modalBody: "body",
            modalSize: "modal-sm",
            handleSubmit: () => alert("delet"),
          },
        ]}
        tHeadData={[
          "Class Name",
          "Grade Name",
          "Section",
          "Subject",
          "Number Of Lessons",
          "Account ID",
          "Account Type",
          "Actions",
        ]}
      />
    </div>
  );
};
function mapStateToProps(state) {
  return {
    role: state.setUserReducer.users.role,
    name: state.setUserReducer.users.unique_name,
  };
}
export default connect(mapStateToProps, null)(ViewLessonWeek);
