import React, { Component } from "react";
import * as ParentActions from "../../Actions/Parents";
import * as SharedActions from "../../Actions/sharedActions";
import DateandTime from "../Transport/DateandTime";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import $ from "jquery";
import Snackbar from "../../components/SnackBar/SnackBar";
import ThemeContext from "../../context/themeContext/ThemeContext";
class AddParent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cnic: "",
      name: "",
      userName: "",
      password: "",
      msg: "",
      errorMsg: "",
      errorText: "",
      formLoading: false,
    };
  }

  componentDidMount() {
    // this.props.SharedActions.getDataWithoutDispatch(`/api/Student`)
    // 	.then(success => {
    // 		this.setState({
    // 			parentData: success,
    // 			formLoading: false
    // 		});
    // 		console.log(success);
    // 	})
    // 	.catch(error => {
    // 		console.log(error);
    // 	});
  }

  onClassChange = (e) => {
    console.log(e.target.value);
    const Sections = this.state.parentData.sections;
    let filtering = Sections.filter((item) => item.classId == e.target.value);
    console.log(filtering);

    this.setState({ classId: e.target.value, sections: filtering });
  };

  onSectionChange = (e) => {
    console.log(e.target.value);
    const Subjects = this.state.parentData.student;
    let studentFiltering = Subjects.filter(
      (item) =>
        item.classId == this.state.classId && item.sectionId == e.target.value
    );
    this.setState({ sectionId: e.target.value, students: studentFiltering });
  };

  onHandleSubmit = (e) => {
    e.preventDefault();
    const { name, userName, password, cnic } = this.state;
    if (
      Object.is(name, "") ||
      Object.is(userName, "") ||
      Object.is(password, "") ||
      Object.is(cnic, "")
    ) {
      this.setState({
        valid: false,
        errorMsg: true,
      });
    } else {
      this.setState({
        valid: true,
      });
      var bodyParameters = {
        name,
        password,
        userName,
        cnic,
        accountId: this.props.userData.unique_name,
        accountType: this.props.userData.role,
      };
      this.props.SharedActions.addRecord(
        `/api/Parent`,
        bodyParameters,
        this.props.ParentActions.addParent
      )
        .then((success) => {
          this.props.ParentActions.updateParentFlag(true);
          this.setState({
            name: "",
            password: "",
            userName: "",
            cnic: "",
            msg: "Parent Registered Successfully!",
            errorMsg: false,
          });
          this.props.snackbar();
        })
        .catch((error) => {
          debugger;
          this.setState({
            msg: error.response.data.Message,
          });
          this.props.snackbar();
          console.log(error);
        });
    }
    console.log(this.state);
  };

  componentDidUpdate() {
    if (this.state.errorMsg || this.state.badError) {
      setTimeout(() => {
        this.setState({
          errorMsg: false,
          badError: false,
        });
      }, 3000);
    }
  }

  Message = (msg, color, fontSize, flag) => {
    if (flag) {
      return (
        <p style={{ fontSize: fontSize }} class={`text-${color}`}>
          {msg}
        </p>
      );
    }
  };

  onHandleTextChange = (e) => {
    console.log(e.target.value);
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  HideUnHide = (e) => {
    $(".toggle-password").click(function () {
      $(this).toggleClass("fa-eye fa-eye-slash");
      var input = $($(this).attr("toggle"));
      if (input.attr("type") == "password") {
        input.attr("type", "text");
      } else {
        input.attr("type", "password");
      }
    });
  };
  onHandleIdCardChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.props.SharedActions.getDataWithoutDispatch(
      `/api/GetParentByIdCard/?idCard=${e.target.value}`
    )
      .then((success) => {
        console.log(success);
        this.setState({
          name: success.name,
        });
      })
      .catch((err) => {
        this.setState({
          name: "",
        });
        console.log(err);
      });
  };
  render() {
    console.log("props", this.props);
    const { formLoading } = this.state;

    const FormLoading = (
      <div style={{ display: "flex", flex: 1, justifyContent: "center" }}>
        <p>Loading...</p>
      </div>
    );

    const FormReady = (
      <ThemeContext.Consumer>
        {(context) => (
          <div>
            <div>
              <section class="mb-4">
                <h3
                  class="h1-responsive font-weight-bold text-center my-4 generalHead"
                  style={{ background: context.themeColors.primaryColor }}
                >
                  Parent Registration
                </h3>

                <p class="text-center w-responsive mx-auto mb-5" />

                <div
                  class="row"
                  style={{
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <div
                    class="col-md-9 mb-md-0 mb-5"
                    style={{
                      border: "solid",
                      borderColor: context.themeColors.primaryColor,
                      padding: 40,
                      borderRadius: 15,
                      width: "auto",
                    }}
                  >
                    {this.state.badError ? (
                      <p style={{ color: "red", textAlign: "center" }}>
                        {this.state.errorText}
                      </p>
                    ) : (
                      ""
                    )}
                    {this.Message(
                      "Please fill the form properly!",
                      "danger",
                      20,
                      this.state.errorMsg
                    )}
                    <form
                      id="contact-form" 
                      name="contact-form"
                      onSubmit={this.onHandleSubmit}
                    >
                      <div class="row">
                        <div class="col-md-12">
                          <div class="md-form mb-0">
                            <DateandTime />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6" style={{ marginTop: 20 }}>
                          <div class="md-form mb-0">
                            <label for="name" class="">
                              Admin Name
                            </label>
                            <input
                              readOnly={true}
                              value={this.props.userData.unique_name}
                              type="text"
                              name="AdminName"
                              class="form-control"
                              onChange={this.onHandleTextChange}
                            />
                          </div>
                        </div>

                        <div class="col-md-6" style={{ marginTop: 20 }}>
                          <div class="md-form mb-0">
                            <label for="name" class="">
                              Admin Type
                            </label>
                            <input
                              readOnly={true}
                              value={this.props.userData.role}
                              type="text"
                              name="AdminType"
                              class="form-control"
                              onChange={this.onHandleTextChange}
                            />
                          </div>
                        </div>
                        <div class="col-md-6" style={{ marginTop: 20 }}>
                          <div class="md-form mb-0">
                            <label for="name" class="">
                              ID Card
                            </label>
                            <input
                              type="text"
                              placeholder="e.g: 12345678"
                              class="form-control"
                              name="cnic"
                              onChange={this.onHandleIdCardChange}
                              value={this.state.cnic}
                            />
                          </div>
                        </div>
                        <div class="col-md-6" style={{ marginTop: 20 }}>
                          <div class="md-form mb-0">
                            <label for="name" class="">
                              Parent Name
                            </label>
                            <input
                              readOnly={true}
                              value={this.state.name}
                              type="text"
                              name="name"
                              class="form-control"
                              onChange={this.onHandleTextChange}
                            />
                          </div>
                        </div>
                        <div class="col-md-6" style={{ marginTop: 20 }}>
                          <div class="md-form mb-0">
                            <label for="name" class="">
                              User Name
                            </label>
                            <input
                              value={this.state.userName}
                              type="text"
                              name="userName"
                              class="form-control"
                              onChange={this.onHandleTextChange}
                            />
                          </div>
                        </div>
                        <div class="col-md-6" style={{ marginTop: 20 }}>
                          <div class="md-form mb-0">
                            <label for="name" class="">
                              Password
                              <span
                                toggle="#password-field"
                                class="fa fa-fw fa-eye field-icon toggle-password"
                                onClick={this.HideUnHide}
                              ></span>
                            </label>
                            <input
                              id="password-field"
                              type="password"
                              class="form-control"
                              name="password"
                              onChange={this.onHandleTextChange}
                              value={this.state.password}
                            />
                          </div>
                        </div>
                      </div>
                      <br />
                      <div class="text-center text-md-center">
                        <button
                          type="submit"
                          class="btns"
                          style={{
                            background: context.themeColors.primaryColor,
                          }}
                        >
                          Add
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </section>
            </div>
          </div>
        )}
      </ThemeContext.Consumer>
    );
    return (
      <div>
        <Snackbar msg={this.state.msg} />
        {formLoading ? FormLoading : FormReady}
      </div>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    SharedActions: bindActionCreators(SharedActions, dispatch),
    ParentActions: bindActionCreators(ParentActions, dispatch),
  };
}
function mapStateToProps(state) {
  return {
    userData: state.setUserReducer.users,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddParent);
