import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// import * as DescriptiveAction from '../../Actions/DescriptiveReport';
import * as sharedActions from "../../../Actions/sharedActions";
import SnackBar from "../../../components/SnackBar/SnackBar";
import GenerateTable from "./GenerateTable";

class AllSheetsView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reportData: [],
      DisciplineInfo: [],
      StudentInfo: [],
      loading: true,
      Sheet: "1",
      msg: "",
    };
  }
  componentDidMount() {
    const { state } = this.props.location;
    this.props.sharedActions
      .getDataWithoutDispatch(
        `/api/PhysicallyChallenged/?classId=${state.classId}&sectionId=${state.sectionId}&sessionId=${state.sessionId}&term=${state.term}&subjectId=${state.subjectId}`
      )
      .then((success) => {
        debugger;
        this.setState({
          reportData: success,
          DisciplineInfo: success.teacherInfo,
          StudentInfo: success.specialStudents,
          // Sheet: success.DisciplineInfo.sheet,
          // id: success.id,
          // classWrn1: success.classWrn1,
          // classWrn2: success.classWrn2,
          // classAction: success.classAction,
          // homeWorkWrn1: success.homeWorkWrn1,
          // homeWorkWrn2: success.homeWorkWrn2,
          // homeWorkAction: success.homeWorkAction,
          // forgettenBooksWrn1: success.forgettenBooksWrn1,
          // forgettenBooksWrn2: success.forgettenBooksWrn2,
          // forgettenBooksAction: success.forgettenBooksAction,
          // lateWrn1: success.lateWrn1,
          // lateWrn2: success.lateWrn2,
          // lateAction: success.lateAction,
          // BehaviourWrn1: success.behaviourWrn1,
          // BehaviourWrn2: success.behaviourWrn2,
          // BehaviourAction: success.behaviourAction,
          // otherWrn1: success.otherWrn1,
          // otherWrn2: success.otherWrn2,
          // otherAction: success.otherAction,

          loading: false,
        });
      })
      .catch((error) => {
        debugger;
        this.setState({
          msg: error.response.data.Message,
        });
        setTimeout(() => {
          this.props.history.push({
            pathname: "/dashboard/ViewSpecialEducationRecords",
          });
        }, 3000);
        this.props.snackbar();
      });
  }
  ChangeApi = (URLchange, sheet) => {
    const { state } = this.props.location;
    this.setState({
      loading: true,
    });
    this.props.sharedActions
      .getDataWithoutDispatch(
        `/api/${URLchange}/?classId=${state.classId}&sectionId=${state.sectionId}&sessionId=${state.sessionId}&term=${state.term}&subjectId=${state.subjectId}`
      )
      .then((success) => {
        this.setState({
          reportData: success,
          DisciplineInfo: success.teacherInfo,
          StudentInfo: success.specialStudents,
          loading: false,
          Sheet: sheet,
        });
      })
      .catch((error) => {});
  };
  render() {
    console.log(this.props);
    const { DisciplineInfo, StudentInfo } = this.state;
    console.log("Report Data", this.state.StudentInfo);
    const Loader = (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div class="spinner-border" style={{ color: "#01AC8A" }}></div>
      </div>
    );
    const AfterLoading = (
      <div>
        <div id="printDiv">
          <div class="container">
            <div class="divclass">
              <img src={require("../../../Assets/images/3 headers-03.png")} />
            </div>
            <br />
            <div class="row justify-content-between colCheckb">
              <div class="col-md-4">
                <div class="md-form mb-0">
                  <label for="name" class="">
                    Term: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </label>
                  <label
                    for="name"
                    class=""
                    style={{
                      fontWeight: "bold",
                      fontSize: 20,
                      color: "#01425e",
                    }}
                  >
                    {DisciplineInfo.term}
                  </label>
                </div>
              </div>
              <div class="col-md-4">
                <div class="md-form mb-0">
                  <label for="name" class="">
                    Staff: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </label>
                  <label
                    for="name"
                    class=""
                    style={{
                      fontWeight: "bold",
                      fontSize: 20,
                      color: "#01425e",
                    }}
                  >
                    {DisciplineInfo.StaffName}
                  </label>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="md-form mb-0">
                  <label for="name" class="">
                    Class: &nbsp;&nbsp;&nbsp;&nbsp;
                  </label>
                  <label
                    for="name"
                    class=""
                    style={{
                      fontWeight: "bold",
                      fontSize: 20,
                      color: "#01425e",
                    }}
                  >
                    {DisciplineInfo.className}
                  </label>
                </div>
              </div>
              <div class="col-md-4">
                <div class="md-form mb-0">
                  <label for="name" class="">
                    Session: &nbsp;
                  </label>
                  <label
                    for="name"
                    class=""
                    style={{
                      fontWeight: "bold",
                      fontSize: 20,
                      color: "#01425e",
                    }}
                  >
                    {DisciplineInfo.sessionName}
                  </label>
                </div>
              </div>
              <div class="col-md-4">
                <div class="md-form mb-0">
                  <label for="name" class="">
                    Sheet: &nbsp;&nbsp;&nbsp;&nbsp;
                  </label>
                  <label
                    for="name"
                    class=""
                    style={{
                      fontWeight: "bold",
                      fontSize: 20,
                      color: "#01425e",
                    }}
                  >
                    {DisciplineInfo.sheet}
                  </label>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="md-form mb-0">
                  <label for="name" class="">
                    Section: &nbsp;
                  </label>
                  <label
                    for="name"
                    class=""
                    style={{
                      fontWeight: "bold",
                      fontSize: 20,
                      color: "#01425e",
                    }}
                  >
                    {DisciplineInfo.section}
                  </label>
                </div>
              </div>
            </div>
            <br />
            <div class="row">
              <GenerateTable Data={StudentInfo} Sheet={this.state.Sheet} />
              {/* <div class="col-md-3 text-left colCheck">
								<label class="font-weight-bold">ACTION TAKEN</label> <br />
								<lable>1. CB &nbsp;&nbsp;&nbsp;- &nbsp;Contact Book</lable>{' '}
								<br />
								<lable>2. HoD - &nbsp;Department Head</lable> <br />
								<lable>3. DP &nbsp;&nbsp;- &nbsp;Deputy Principal</lable> <br />
								<lable>4. CP &nbsp;&nbsp;- &nbsp;Contact Parent</lable> <br />
								<lable>
									5. S &nbsp;&nbsp;&nbsp;&nbsp;- &nbsp;Suspension
								</lable>{' '}
								<br />
								<lable>6. Ex &nbsp;&nbsp;- &nbsp;Expulsion</lable> <br />
							</div>
							{this.state.Sheet == '2' ? (
								<div class="col-md-3 text-left colCheck offset-md-1">
									<label class="font-weight-bold">OTHER TRANSGRESSION</label>{' '}
									<br />
									<lable>1. Fi &nbsp;&nbsp;&nbsp;- &nbsp;Fighting</lable> <br />
									<lable>2. LA - &nbsp;Offensive Language</lable> <br />
									<lable>3. VA &nbsp;&nbsp;- &nbsp;Vandalism</lable> <br />
									<lable>4. MP &nbsp;&nbsp;- &nbsp;Mobile Phones</lable>
								</div>
							) : (
								''
							)} */}
            </div>
          </div>
          <br />
        </div>
        <div class="text-right">
          <button
            className="buttonHover"
            onClick={() => this.props.printFunc("printDiv")}
          >
            {" "}
            <i style={{ marginRight: 3 }} class="fas fa-2x fa-print"></i>
            Print it out
          </button>
        </div>
      </div>
    );
    return (
      <div>
        <SnackBar backColor={"#000"} msg={this.state.msg} />
        <div class="text-center">
          <button
            class="buttonHoverFillColor"
            style={{ marginRight: 10 }}
            onClick={() => this.ChangeApi("PhysicallyChallenged", 1)}
          >
            Physically Challenged
          </button>
          <button
            class="buttonHoverFillColor"
            style={{ marginLeft: 10 }}
            onClick={() => this.ChangeApi("LearningDifficulties", 2)}
          >
            Learning Difficulties
          </button>
          <button
            class="buttonHoverFillColor"
            style={{ marginLeft: 10 }}
            onClick={() => this.ChangeApi("PsychiatricDisablities", 3)}
          >
            Psychiatric Disablities
          </button>
          <button
            class="buttonHoverFillColor"
            style={{ marginLeft: 10 }}
            onClick={() => this.ChangeApi("AutismSpectrumDisorder", 4)}
          >
            Autism Spectrum Disorder
          </button>
        </div>
        <br />
        {this.state.loading ? Loader : AfterLoading}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    sharedActions: bindActionCreators(sharedActions, dispatch),
  };
}
function mapStateToProps(state) {
  return {
    users: state.setUserReducer.users,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(AllSheetsView);
