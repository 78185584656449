import React, { Component } from "react";
import DateandTime from "./../Transport/DateandTime";
import RenderDynamicSiblingsRow from "./RenderDynamicSiblingsRow";
import $ from "jquery";
import ThemeContext from "../../context/themeContext/ThemeContext";

export default class StudentSiblingDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gridNum: 4,
    };
  }

  btnTechHover = () => {
    $(document).ready(function () {
      $("#addBtn").hover(
        function () {
          $(this).css(
            "background",
            "linear-gradient(to bottom left, #01425e 5%, #01ac8a 87%)"
          );
          $(this).css("border-color", "white");
        },
        function () {
          $(this).css("background", "#0A4F5E");
        }
      );
    });
  };

  render() {
    const {
      onHandleTextChange,
      stateData,
      updateSpecialArrObjOnChange,
      onClickAddItem,
      onClickDeleteItem,
    } = this.props;
    return (
      <div>
        <div style={{ width: "100%" }}>
          <table class="table table-hover">
            <ThemeContext.Consumer>
              {(context) => (
                <thead
                  style={{
                    background: context.themeColors.primaryColor,
                    color: "white",
                  }}
                >
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Siblings Name</th>
                    <th scope="col">Age</th>
                    <th scope="col">Relation</th>
                    <th scope="col">Education</th>
                    <th scope="col">School</th>
                  </tr>
                </thead>
              )}
            </ThemeContext.Consumer>
            <tbody>
              {stateData &&
                stateData.siblingsDetails.map((item, index) => (
                  <tr key={index}>
                    <th scope={"row"}>{index + 1}</th>
                    <RenderDynamicSiblingsRow
                      arrName={"siblingsDetails"}
                      updateSpecialArrObjOnChange={updateSpecialArrObjOnChange}
                      rowIndex={index}
                      onHandleTextChange={onHandleTextChange}
                      stateData={stateData}
                      itemObj={item}
                    />
                  </tr>
                ))}
            </tbody>
            <div className={"row"} style={{ justifyContent: "flex-end" }}>
              <div>
                <div>
                  <button
                    id={"addBtn"}
                    onMouseEnter={this.btnTechHover()}
                    onClick={() =>
                      onClickAddItem("siblingsDetails", {
                        SiblingName: "",
                        Age: "",
                        Relation: "",
                        Education: "",
                        School: "",
                      })
                    }
                    style={{
                      background: "#0A4F5E",
                      color: "#FFFFFF",
                      borderRadius: 100,
                    }}
                    type="button"
                    class="btn"
                  >
                    <i class="fas fa-plus"></i>
                  </button>
                </div>
                <div>
                  <button
                    onClick={() => onClickDeleteItem("siblingsDetails")}
                    style={{ color: "#FFFFFF", borderRadius: 100 }}
                    type="button"
                    class="btn btn-danger"
                  >
                    <i class="far fa-trash-alt"></i>
                  </button>
                </div>
              </div>
            </div>
          </table>
        </div>
        <br />
        <br />
        <br />
        {/*<div class="text-center text-md-center">*/}
        {/*<button class="btns">Add</button>*/}
        {/*</div>*/}
      </div>
    );
  }
}
