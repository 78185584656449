import React, { useEffect, useState, useContext } from "react";
import useHttp from "../../../hooks/http.js";
import CustomReportComponent from "../../../components/Reports/CustomReportComponent.jsx";
import img1 from "../../../Assets/images/newheader.png";
import CustomReportHeader from "../../../components/Reports/CustomReportHeader/CustomReportHeader.jsx";
import CustomHeading from "../../../components/CustomHeading";
import { MAIN_HEADING } from "../../../utils/general/index.js";
import useCustomHttpWithDataMiddleware from "../../../hooks/Services/httpWithCustomDataMiddleware.js";
import GraphGeneralLayout from "../../../components/Graphs/GraphGeneralLayout/GraphGeneralLayout.jsx";
import { Bar, Line, Pie } from "react-chartjs-2";
import * as sharedActions from "../../../Actions/sharedActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import $ from "jquery";
import CustomBadge from "../../../components/CustomBadge/CustomBadge.jsx";
import Colors from "../../../utils/app_constants/colors_constants.js";
import SelectionInput from "../../../components/SelectionInput/SelectionInput.jsx";
import Button from "../../../components/Button/index.jsx";
import SnackBar from "../../../components/SnackBar/SnackBar.jsx";
import httpWithRequiredSetData from "../../../hooks/Services/httpWithRequiredSetData.js";
import ThemeContext from "../../../context/themeContext/ThemeContext.js";

function SelectionCriteria(props) {
  const { pid } = props.match.params;
  debugger;
  const themeContext = useContext(ThemeContext);
  const [graphType, setGraphType] = useState("barChart");
  const [graphData, setGraphData] = useState({});
  const [dummyCheck, setDummyCheck] = useState(false);
  const [dependency, setDependency] = useState(false);
  const [selectMonth, setMonth] = useState("");
  const [isLoading, remoteData] = useHttp(`/api/GetToolGraph?pId=${pid}`, []);
  const [isLoader, fetchData, setFetchData] = httpWithRequiredSetData(
    `/api/Getviewtemplate/?pid=${pid}`,
    [],
    (data) => {
      let getNewData = JSON.parse(JSON.stringify(data));
      let getFilteredData = getNewData["toolSubtTool"].filter(
        (item) => item.subTools.length > 0
      );
      let newStructure = {
        ...getNewData,
        toolSubtTool: [...getFilteredData],
      };
      debugger;
      setFetchData(newStructure);
    }
  );
  const [snackbarMsg, setSnackMsg] = useState("");
  const [maintainData, setMaintainData] = useState([]);
  debugger;
  debugger;

  const PrintContent = () => {
    setTimeout(() => {
      window.print();
      window.location.reload();
    }, 700);
  };

  const onChangeCheckBox = (e) => {
    let data = [...maintainData];
    console.log(e.target.value);
    console.log(e.target.name);
    console.log(e.target.id);
    if (e.target.checked) {
      let obj = {
        pId: pid,
        subToolId: e.target.name,
        month: selectMonth,
      };
      debugger;
      setMaintainData((prev) => [...prev, obj]);
    } else {
      debugger;
      debugger;
      let getFiiltered = data.filter(
        (item) => item.subToolId !== e.target.name
      );
      debugger;
      debugger;
      setMaintainData(getFiiltered);
    }
  };
  const renderTools = (toolName, arr) => {
    return arr.map((item, index) => (
      <div>
        <input
          style={{ background: themeContext.themeColors.primaryColor }}
          onChange={onChangeCheckBox}
          type="checkbox"
          name={item.subToolId}
          id={"newIdCheck"}
        />
        {item.subToolName}
      </div>
    ));
  };

  const postDataOn = () => {
    props.sharedAction
      .simpleAddRequest(`/api/postTeacherTemplate`, {
        TeacherViewTemplates: maintainData,
      })
      .then((success) => {
        debugger;
        $("input:checkbox").prop("checked", false);
        setSnackMsg("Successfully Submit");
        setMonth("");
        props.snackbar();
        setDependency((prev) => !prev);
        props.history.push("/dashboard/caSubToolViewAdmin");
      })
      .catch((error) => {
        debugger;
      });
  };

  let toolSubtTool = [
    {
      pId: 2,
      toolId: 7,
      toolName: "Home Work",
      subTools: [
        {
          id: 15,
          toolId: 7,
          toolName: "Home Work",
          pId: 2,
          subToolName: "HW1",
          min: 0,
          max: 15,
        },
        {
          id: 16,
          toolId: 7,
          toolName: "Home Work",
          pId: 2,
          subToolName: "HW2",
          min: 0,
          max: 15,
        },
        {
          id: 17,
          toolId: 7,
          toolName: "Home Work",
          pId: 2,
          subToolName: "HW AVG",
          min: 0,
          max: 15,
        },
      ],
    },
    {
      pId: 2,
      toolId: 8,
      toolName: "Class Work",
      subTools: [
        {
          id: 18,
          toolId: 8,
          toolName: "Class Work",
          pId: 2,
          subToolName: "CW1",
          min: 0,
          max: 15,
        },
        {
          id: 19,
          toolId: 8,
          toolName: "Class Work",
          pId: 2,
          subToolName: "CW2",
          min: 0,
          max: 15,
        },
        {
          id: 20,
          toolId: 8,
          toolName: "Class Work",
          pId: 2,
          subToolName: "CW AVG",
          min: 0,
          max: 15,
        },
      ],
    },
    {
      pId: 2,
      toolId: 9,
      toolName: "Total of CA",
      subTools: [
        {
          id: 21,
          toolId: 9,
          toolName: "Total of CA",
          pId: 2,
          subToolName: "CA",
          min: 0,
          max: 30,
        },
      ],
    },
  ];

  let studentMarks = [
    {
      studentId: 11,
      studentName: "Ahad Raza",
      pId: 2,
      CA_AssignMark: [
        {
          id: 29,
          toolId: 7,
          toolName: "Home Work",
          subToolId: 15,
          subToolName: "HW1",
          pId: 2,
          studentId: 11,
          marks: 12.0,
        },
        {
          id: 30,
          toolId: 7,
          toolName: "Home Work",
          subToolId: 16,
          subToolName: "HW2",
          pId: 2,
          studentId: 11,
          marks: 13.0,
        },
        {
          id: 31,
          toolId: 7,

          toolName: "Home Work",
          subToolId: 17,
          subToolName: "HW AVG",
          pId: 2,
          studentId: 11,
          marks: 12.5,
        },
        {
          id: 32,
          toolId: 8,
          toolName: "Class Work",
          subToolId: 18,
          subToolName: "CW1",
          pId: 2,
          studentId: 11,
          marks: 14.0,
        },
        {
          id: 33,
          toolId: 8,
          toolName: "Class Work",
          subToolId: 19,
          subToolName: "CW2",
          pId: 2,
          studentId: 11,
          marks: 10.0,
        },
        {
          id: 34,
          toolId: 8,
          toolName: "Class Work",
          subToolId: 20,
          subToolName: "CW AVG",
          pId: 2,
          studentId: 11,
          marks: 12.0,
        },
        {
          id: 35,
          toolId: 9,
          toolName: "Total of CA",
          subToolId: 21,
          subToolName: "CW",
          pId: 2,
          studentId: 11,
          marks: 24.5,
        },
      ],
    },
    {
      studentId: 25,
      studentName: "Rafay Khan",
      pId: 2,
      CA_AssignMark: [],
    },
    {
      studentId: 26,
      studentName: "Taimoor Shah",
      pId: 2,
      CA_AssignMark: [],
    },
    {
      studentId: 27,
      studentName: "Nakash Shah",
      pId: 2,
      CA_AssignMark: [],
    },
    {
      studentId: 28,
      studentName: "Wazeer Khan",
      pId: 2,
      CA_AssignMark: [],
    },
  ];
  let getToolsId =
    fetchData && fetchData["toolSubtTool"].map((item) => item.toolId);

  const simpleFilter = (arr, Value) => {
    let getElement = arr.filter((item) => item["toolId"] === Value);
    return getElement;
  };
  const changeStrcuture = (toolsNames = [], originalAr = []) => {
    let getNew = toolsNames.map((item) => {
      return { [item]: simpleFilter(originalAr, item) };
    });
    return getNew;
  };

  const renderMarks = (arr) => {
    return arr.map((item, index) => <div>{item.marks}</div>);
  };
  const getDummyToolName = toolSubtTool.map((item) => item.toolName);
  console.log("toolNAme: ", getDummyToolName);
  const getMarksByToolName = (toolName) => {};
  // console.log(fetchData);
  const getRenderItem = (obj = {}, toolName = []) => {
    let getKey = Object.keys(obj)[0];
    return obj[getKey].map((innerItem, innerIndex) => (
      <div>{innerItem["marks"]}</div>
    ));
  };
  const takeArray = (arr = [], toolName) => {
    let getAllMArks = changeStrcuture(getToolsId, arr);
    console.log("marks:", getAllMArks);
    return getAllMArks.map((item, index) => (
      <td>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          {getRenderItem(item, getToolsId)}
        </div>
      </td>
    ));
  };
  const getToolNameForRendering = (nameTool, arr, index) => {
    let getAllMArks = changeStrcuture(getToolsId, arr);
    return getAllMArks[index][nameTool].map((item) => (
      <React.Fragment>
        <td style={{ padding: 10 }}>{item["marks"]}</td>
      </React.Fragment>
    ));
  };
  console.log("Student Discipline Reports", props);
  console.log("Maintain", maintainData);
  return (
    <div id="printDiv">
      <div>
        <SnackBar msg={snackbarMsg} />
        <img src={img1} alt="#img" style={{ width: "100%", height: "30%" }} />

        <CustomHeading
          headingText={"TEMPLATE REPORT CRITERIA"}
          type={MAIN_HEADING}
          className="reportHead"
        />

        <CustomReportHeader
          headRenderData={[
            { displayData: "Subject Name", fieldName: "subject" },
            { displayData: "Grade Name", fieldName: "gradeName" },
            { displayData: "Course Type", fieldName: "courseType" },
          ]}
          targetedObj="primaryInfoes"
          headData={fetchData}
        />

        <CustomReportHeader
          headRenderData={[
            { displayData: "Class Name", fieldName: "className" },
            { displayData: "Section", fieldName: "section" },
            { displayData: "Term", fieldName: "term" },
            { displayData: "Session", fieldName: "session" },
          ]}
          targetedObj="primaryInfoes"
          headData={fetchData}
        />
        <br />

        {fetchData && fetchData["toolSubtTool"].length > 0 ? (
          ""
        ) : (
          <p style={{ color: Colors.RED_DANGER, textAlign: "center" }}>
            No SubTool Exists
          </p>
        )}
        {fetchData && fetchData["toolSubtTool"].length > 0 ? (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div style={{ width: "40%" }}>
              <select
                onChange={(e) => {
                  debugger;
                  setMonth(e.target.value);
                }}
                className="form-control"
              >
                <option value="Month">Month</option>

                <option value="January">January</option>
                <option value="February">February</option>
                <option value="March">March</option>
                <option value="April">April</option>
                <option value="May">May</option>
                <option value="June">June</option>
                <option value="July">July</option>
                <option value="August">August</option>
                <option value="September">September</option>
                <option value="October">October</option>
                <option value="November">November</option>
                <option value="December">December</option>
              </select>
            </div>
          </div>
        ) : (
          ""
        )}
        <br />
        {selectMonth !== "Month" && selectMonth !== "" ? 
        
        <table className="table table-bordered">
          <thead>
            <tr>
              {fetchData &&
                fetchData["toolSubtTool"].map((item, index) => (
                  <th style={{ textAlign: "center" }}>{`${
                    item && item.toolName
                  } (${
                    item &&
                    item.subTools &&
                    item.subTools[0] &&
                    item.subTools[0]["max"]
                  } | ${
                    item &&
                    item.subTools &&
                    item.subTools[0] &&
                    item.subTools[0]["min"]
                  }) `}</th>
                ))}
            </tr>
            <tr>
              {fetchData &&
                fetchData["toolSubtTool"].map((item, index) => (
                  <th>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        flexDirection: "column",
                        // border: '1px solid black'
                      }}
                    >
                      {renderTools(item.toolName, item.subTools)}
                    </div>
                  </th>
                ))}
            </tr>
          </thead>
        </table>
        : null
        }
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {fetchData && fetchData["toolSubtTool"].length > 0 ? (
          <Button
            customClause={
              maintainData.length < 1 ? true : false
            }
            textColor={"#fff"}
            btnName={"Submit"}
            onHandleSubmit={postDataOn}
            buttonClass={"btn-smart-one"}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
function mapDispatchToProps(dispatch) {
  return {
    sharedAction: bindActionCreators(sharedActions, dispatch),
  };
}
export default connect(null, mapDispatchToProps)(SelectionCriteria);
