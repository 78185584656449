import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import $ from 'jquery';
import isEmpty from 'lodash/isEmpty';

import LoaderWrapper from "../../components/HOC/LoaderWrapper";
import ThemeContext from "../../context/themeContext/ThemeContext";




class ViewPayroll extends Component {
    constructor(props){
        super(props);
        this.state = {
            payrollData: [],
            search: '',
            isLoading: true,
            modalEdit: false,
            editToggle: false,
            generateToggle: false,
            backColor: '',
            generateIndex: '',
            msg: '',
            editIndex: '',
            id: null,
            staffName: null,
            campusName: null,
            departmentName: null,
            postName: null,
            month: null,
            year: null,
            basicSalary: null,
            taxAmount: null,
            allowances: null,
            bonusAmount: null,
            loanamountDeduction: null,
            attendancededuction: null,
            taxdeduction: null,
            totatAmountDeduct: null,
            grossIncome: null,
            NetIncome: null,
            status: null,
            accountId: null,
            accountType: null, 

    

        }

        


    }

	static contextType = ThemeContext

    componentDidMount(){
        this.getSimple('campusDropdown', 'campus')
        this.getSimple(`AllPayrolls`, 'payrollData')


    }

	getSimple = (type, state) => {
        this.props.sharedActions.getDataWithoutDispatch(
            `/api/${type}`
        ).then(success => {
            this.setState({
                [state]: success,
                isLoading: false
            })


        }).catch(error => {
            this.setState({
                msg: 'Error Occured!!',
                isLoading: false
            })
            this.props.snackbar();
            console.error('Error occured');
        })
    }


    onGeneratePayslip = id => {
        const { payrollData } = this.state
        let modalData = payrollData.filter((items, index ) =>  {
            if(id === items.id){
                    
            this.setState({
                editIndex: index
            })
            console.log(index)
            return id === items.id 
            }
        })
        let data = {
            staffId: modalData[0].staffId,
            month: modalData[0].month,
            year: modalData[0].year,
            accountId: modalData[0].accountId,
            accountType: modalData[0].accountType, 
            
        }

        this.props.sharedActions.simpleAddRequest(
            `/api/EmployeePaySlip`,
            data
        ).then(success => {
            console.log(success);
            $('#myModal3').modal('hide')
            
            this.setState({
                msg: 'Payslip Generated!',
                isLoading: false
            })
            this.props.snackbar();

            
        }).catch(error => {

            
            $('#myModal3').modal('hide')
            this.setState({
                msg: error.response ? error.response.data.Message : 'Error Occured!!',
                errorMsg: true,
                isLoading: false
            })
            console.error('Error occured', error);
            
        })


    }
    

    onEditClick = id => {
        const { payrollData } = this.state
        let modalData = payrollData.filter((items, index ) =>  {
            if(id === items.id){
                    
            this.setState({
                editIndex: index
            })
            console.log(index)
            return id === items.id 
            }
        })
        console.log(modalData)
        this.setState({
            id: modalData[0].id,
            sessionId: modalData[0].sessionId,
            campusId: modalData[0].campusId,
            departmentId: modalData[0].departmentId,
            PostId: modalData[0].PostId,
            staffId: modalData[0].staffId,
            date: modalData[0].date,
            time: modalData[0].time,
            staffName: modalData[0].staffName,
            campusName: modalData[0].campusName,
            departmentName: modalData[0].departmentName,
            postName: modalData[0].postName,
            month: modalData[0].month,
            year: modalData[0].year,
            basicSalary: modalData[0].basicSalary,
            taxAmount: modalData[0].taxAmount,
            allowances: modalData[0].allowances,
            bonusAmount: modalData[0].bonusAmount,
            loanamountDeduction: modalData[0].loanamountDeduction,
            attendancededuction: modalData[0].attendancededuction,
            taxdeduction: modalData[0].taxdeduction,
            totatAmountDeduct: modalData[0].totatAmountDeduct,
            grossIncome: modalData[0].grossIncome,
            NetIncome: modalData[0].NetIncome,
            status: modalData[0].status,
            accountId: modalData[0].accountId,
            accountType: modalData[0].accountType, 
            
        }, () => {
            $('#myModal1').modal({
                show: true
            })
        })
    }

    
    onHandlePayslip = id => {
        const { payrollData } = this.state
        let modalData = payrollData.filter((items, index ) =>  {
            if(id === items.id){
                    
            this.setState({
                generateIndex: index
            })
            console.log(index)
            return id === items.id 
            }
        })

        console.log(modalData)
        let data = {
            staffId: modalData[0].staffId,
            month: modalData[0].month,
            year: modalData[0].year,
            accountId: modalData[0].accountId,
            accountType: modalData[0].accountType, 
            
        }

        console.log(data);
        
        this.props.sharedActions.getDataWithoutDispatch(
            `/api/EmployeePaySlip/?month=${modalData[0].month}&year=${modalData[0].year}&staffId=${modalData[0].staffId}`,
        ).then(success => {
            console.log(success);
            
            this.setState({
                successMsg: true,
                isLoading: false,
                id: modalData[0].id,
                sessionId: modalData[0].sessionId,
                campusId: modalData[0].campusId,
                departmentId: modalData[0].departmentId,
                PostId: modalData[0].PostId,
                staffId: modalData[0].staffId,
                date: modalData[0].date,
                time: modalData[0].time,
                staffName: modalData[0].staffName,
                campusName: modalData[0].campusName,
                departmentName: modalData[0].departmentName,
                postName: modalData[0].postName,
                month: modalData[0].month,
                year: modalData[0].year,
                basicSalary: modalData[0].basicSalary,
                taxAmount: modalData[0].taxAmount,
                allowances: modalData[0].allowances,
                bonusAmount: modalData[0].bonusAmount,
                loanamountDeduction: modalData[0].loanamountDeduction,
                attendancededuction: modalData[0].attendancededuction,
                taxdeduction: modalData[0].taxdeduction,
                totatAmountDeduct: modalData[0].totatAmountDeduct,
                grossIncome: modalData[0].grossIncome,
                NetIncome: modalData[0].NetIncome,
                status: modalData[0].status,
                accountId: modalData[0].accountId,
                accountType: modalData[0].accountType, 
                generated: false

            }, () => {
                $('#myModal3').modal({
                    show: true
                })
            })
        }).catch(error => {
            if(error.response.data.Message){
                this.setState({
                    id: modalData[0].id,
                    sessionId: modalData[0].sessionId,
                    campusId: modalData[0].campusId,
                    departmentId: modalData[0].departmentId,
                    PostId: modalData[0].PostId,
                    staffId: modalData[0].staffId,
                    date: modalData[0].date,
                    time: modalData[0].time,
                    staffName: modalData[0].staffName,
                    campusName: modalData[0].campusName,
                    departmentName: modalData[0].departmentName,
                    postName: modalData[0].postName,
                    month: modalData[0].month,
                    year: modalData[0].year,
                    basicSalary: modalData[0].basicSalary,
                    taxAmount: modalData[0].taxAmount,
                    allowances: modalData[0].allowances,
                    bonusAmount: modalData[0].bonusAmount,
                    loanamountDeduction: modalData[0].loanamountDeduction,
                    attendancededuction: modalData[0].attendancededuction,
                    taxdeduction: modalData[0].taxdeduction,
                    totatAmountDeduct: modalData[0].totatAmountDeduct,
                    grossIncome: modalData[0].grossIncome,
                    NetIncome: modalData[0].NetIncome,
                    status: modalData[0].status,
                    accountId: modalData[0].accountId,
                    accountType: modalData[0].accountType,
                    generated: true
                    
                }, () => {
                    $('#myModal3').modal({
                        show: true
                    })

                })      
            }
            
            this.setState({
                msg: error.response ? error.response.data.Message : 'Error Occured!!',
                errorMsg: true,
                isLoading: false
            })
            console.error('Error occured', error);
            
        })

    }

    

    

    myFunction = () => {
		// Get the snackbar DIV
		var x = document.getElementById('snackbar');

		// StepperGeneralButtons the "show" class to DIV
		x.className = 'show';

		// After 3 seconds, remove the show class from DIV
		setTimeout(function() {
		}, 3000);
	};

    handleSubmit = e => {
        e.preventDefault();
        console.log('Submit Called')

        const {  id,
            sessionId,
            campusId,
            departmentId,
            PostId,
            staffId,
            time,
            date,
            staffName,
            campusName,
            departmentName,
            postName,
            month,
            year,
            basicSalary,
            taxAmount,
            allowances,
            bonusAmount,
            loanamountDeduction,
            attendancededuction,
            taxdeduction,
            totatAmountDeduct,
            grossIncome,
            NetIncome,
            status,
            accountId,
            accountType,  } = this.state;

        let data = {
            id,
            sessionId,
            campusId,
            departmentId,
            PostId,
            staffId,
            time,
            date,
            staffName,
            campusName,
            departmentName,
            postName,
            month,
            year,
            basicSalary,
            taxAmount,
            allowances,
            bonusAmount,
            loanamountDeduction,
            attendancededuction,
            taxdeduction,
            totatAmountDeduct,
            grossIncome,
            NetIncome,
            status,
            accountId,
            accountType,
        }

        console.log('data model', data)
        this.props.sharedActions.editRecordWithoutDispatch(
            '/api/Payroll/',
            id,
            data
        ).then(success => {
            console.log('success', success)
            this.state.payrollData.splice(this.state.editIndex, 1, data)
            
                
                $('#myModal1').modal('hide')
            
            this.setState({
                msg: 'Record Changed Successfully!',

                
            })
            this.props.snackbar();
            

        }).catch(error => {
            console.error('Error name: ', error)
                
                $('#myModal1').modal('hide')
            this.setState({
                msg: 'Error Occured!'
            })
            this.props.snackbar();


        })
    }

    handleSearch = e => {


        console.log('called', e.target.value);
        
        if(e.target.value  ==  0){
            console.log('Empty')
            this.getSimple(`AllPayrolls`, 'payrollData')
        }else{

        this.setState({
            isLoading: true
        })
        this.props.sharedActions.getDataWithoutDispatch(
            `/api/Payroll/?deptId=${e.target.value}`
        ).then(success => {
            console.log('Not Empty', success)
            this.setState({
                payrollData: success,
                isLoading: false
            })

        }).catch(error => {
            this.setState({
                msg: error.response ? error.response.data.Message : 'Error Occured!!',
                isLoading: false,
                payrollData: []
            })
            
            this.props.snackbar()
            console.error('Error Occured!!')

        })
    }

    }

    btnTechHover = () => {
		$(document).ready(function() {
			$('button').hover(
				function() {
					$(this).css('box-shadow', '0px 5px 5px grey');
					$(this).css('border-color', 'white');
				},
				function() {
					$(this).css('box-shadow', '0px 0px 0px white');
				}
			);
		});
	};

    onHandleText = e => {
		this.setState({
			[e.target.name]: e.target.value
        });
        console.log([e.target.name], e.target.value)
	};


    render(){

        const { search, isLoading, editToggle, payrollData, msg, id, campus,
            staffName,
            campusName,
            departmentName,
            postName,
            month,
            year,
            basicSalary,
            taxAmount,
            allowances,
            bonusAmount,
            loanamountDeduction,
            attendancededuction,
            taxdeduction,
            totatAmountDeduct,
            grossIncome,
            NetIncome,
            status,
            accountId,
            accountType,
            generated } = this.state;

        


        const confirmPayslip = (
            <div>
            <div className="modal" id="myModal3">
                <div className="modal-dialog">
                    <div className="modal-content modal_content_custom">
                        <div
                            style={{
								background: this.context.themeColors.primaryColor,
                                borderBottomLeftRadius: 30,
                                borderBottomRightRadius: 30,
                                borderTopLeftRadius: 18,
                                borderTopRightRadius: 18
                            }}
                            className="modal-header modal_custom_header"
                        >
                            <h4 style={{ color: 'white' }} className="modal-title">
                                Confirm
                            </h4>
                            <button
                                style={{ color: 'white' }}
                                on
                                type="button"
                                className="close"
                                data-dismiss="modal"
                            >
                                &times;
                            </button>
                        </div>
                        <div className="modal-body">
                        <div >
											<div style={{ textAlign: 'center' }}>
												<h4>{generated ? 'Slip Already Generated!' : 'Do you Want to generate slip?'}</h4>
											</div>
											{!generated ? <div
												style={{
													display: 'flex',
													justifyContent: 'space-evenly'
												}}
											>
												<div>
													<button
														data-dismiss="modal"
														onClick={() => this.onGeneratePayslip(this.state.id)}
														type="button"
														class="btn btn-primary buttonAppear"
													>
														Yes
													</button>
												</div>
												<div>
													<button
														data-dismiss="modal"
														type="button"
														class="btn btn-primary buttonAppear"
													>
														No
													</button>
												</div>
											</div> : <div>
													{/* <button
														data-dismiss="modal"
														onClick={() => this.onViewSlip(this.state.id)}
														type="button"
														class="btn btn-primary buttonAppear"
													>
														View Slip
													</button> */}
												</div>}
										</div>
                        </div>
                        <div class="modal-footer">
								<button
									type="button"
									style={{ background: '#00435D' }}
                                    class="btn btn-danger"
                                    onClick={() => this.setState({editToggle: false})}
									data-dismiss="modal"
								>
									Close
								</button>
							</div>
                    </div>
                </div>
            </div>
            </div>
        )


        const editPayrollData = (
            <div>
            <div className="modal" id="myModal1">
                <div className="modal-dialog">
                    <div className="modal-content modal_content_custom">
                        <div
                            style={{
								background: this.context.themeColors.primaryColor,
                                borderBottomLeftRadius: 30,
                                borderBottomRightRadius: 30,
                                borderTopLeftRadius: 18,
                                borderTopRightRadius: 18
                            }}
                            className="modal-header modal_custom_header"
                        >
                            <h4 style={{ color: 'white' }} className="modal-title">
                                Edit
                            </h4>
                            <button
                                style={{ color: 'white' }}
                                on
                                type="button"
                                className="close"
                                data-dismiss="modal"
                            >
                                &times;
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={this.handleSubmit} >
                            <fieldset disabled={!editToggle}>
                                
                                <div className="form-group">
                                <label for="category">
                                                Status:
                                            </label>
                                <select class="custom-select" 
                                                value={status}
                                                name="status"
                                                onChange={this.onHandleText}

                                            >
                                                           <option value="On Hold">On Hold</option>
                                                           <option value="Approved">Approved</option>
                                            </select>
                                </div>
                                <div className="form-group">
                                <label for="accountId">Account Name:</label>
                                <input style={{
                                    borderTop: 0,
                                    borderRight: 0,
                                    borderLeft: 0
                                }}
                                type="text"
                                disabled
                                value={accountId}
                                name="modalDataAccountId"
                                className="form-control"
                                id="accountId"
                                />
                                </div>
                                <div className="form-group">
                                <label for="accountType">Account Type:</label>
                                <input style={{
                                    borderTop: 0,
                                    borderRight: 0,
                                    borderLeft: 0
                                }}
                                type="text"
                                disabled
                                value={accountType}
                                name="modalDataAccountType"
                                className="form-control"
                                id="accountType"
                                />
                                </div>
                                <div className="form-group">
                                <label for="staffName">Staff Name:</label>
                                <input style={{
                                    borderTop: 0,
                                    borderRight: 0,
                                    borderLeft: 0
                                }}
                                type="text"
                                disabled
                                value={staffName}
                                name="staffName"
                                className="form-control"
                                id="staffName"
                                />
                                </div><div className="form-group">
                                <label for="campusName">Campus Name:</label>
                                <input style={{
                                    borderTop: 0,
                                    borderRight: 0,
                                    borderLeft: 0
                                }}
                                type="text"
                                disabled
                                value={campusName}
                                name="campusName"
                                className="form-control"
                                id="campusName"
                                />
                                </div><div className="form-group">
                                <label for="departmentName">Department Name:</label>
                                <input style={{
                                    borderTop: 0,
                                    borderRight: 0,
                                    borderLeft: 0
                                }}
                                type="text"
                                disabled
                                value={departmentName}
                                name="departmentName"
                                className="form-control"
                                id="departmentName"
                                />
                                </div><div className="form-group">
                                <label for="postName">Post Name:</label>
                                <input style={{
                                    borderTop: 0,
                                    borderRight: 0,
                                    borderLeft: 0
                                }}
                                type="text"
                                disabled
                                value={postName}
                                name="postName"
                                className="form-control"
                                id="postName"
                                />
                                </div><div className="form-group">
                                <label for="month">Month</label>
                                <input style={{
                                    borderTop: 0,
                                    borderRight: 0,
                                    borderLeft: 0
                                }}
                                type="text"
                                disabled
                                value={month}
                                name="month"
                                className="form-control"
                                id="month"
                                />
                                </div><div className="form-group">
                                <label for="year">Year:</label>
                                <input style={{
                                    borderTop: 0,
                                    borderRight: 0,
                                    borderLeft: 0
                                }}
                                type="text"
                                disabled
                                value={year}
                                name="year"
                                className="form-control"
                                id="year"
                                />
                                </div><div className="form-group">
                                <label for="basicSalary">Basic Salary:</label>
                                <input style={{
                                    borderTop: 0,
                                    borderRight: 0,
                                    borderLeft: 0
                                }}
                                type="text"
                                disabled
                                value={basicSalary}
                                name="basicSalary"
                                className="form-control"
                                id="basicSalary"
                                />
                                </div><div className="form-group">
                                <label for="taxAmount">Tax Amount in %:</label>
                                <input style={{
                                    borderTop: 0,
                                    borderRight: 0,
                                    borderLeft: 0
                                }}
                                type="text"
                                disabled
                                value={taxAmount}
                                name="taxAmount"
                                className="form-control"
                                id="taxAmount"
                                />
                                </div><div className="form-group">
                                <label for="allowances">Allownaces:</label>
                                <input style={{
                                    borderTop: 0,
                                    borderRight: 0,
                                    borderLeft: 0
                                }}
                                type="text"
                                disabled
                                value={allowances}
                                name="allowances"
                                className="form-control"
                                id="allowances"
                                />
                                </div><div className="form-group">
                                <label for="bonusAmount">Bouns Amount:</label>
                                <input style={{
                                    borderTop: 0,
                                    borderRight: 0,
                                    borderLeft: 0
                                }}
                                type="text"
                                disabled
                                value={bonusAmount}
                                name="bonusAmount"
                                className="form-control"
                                id="bonusAmount"
                                />
                                </div><div className="form-group">
                                <label for="loanamountDeduction">Loan Amount Deduction:</label>
                                <input style={{
                                    borderTop: 0,
                                    borderRight: 0,
                                    borderLeft: 0
                                }}
                                type="text"
                                disabled
                                value={loanamountDeduction}
                                name="loanamountDeduction"
                                className="form-control"
                                id="loanamountDeduction"
                                />
                                </div><div className="form-group">
                                <label for="attendancededuction">Attendence Deduction:</label>
                                <input style={{
                                    borderTop: 0,
                                    borderRight: 0,
                                    borderLeft: 0
                                }}
                                type="text"
                                disabled
                                value={attendancededuction}
                                name="attendancededuction"
                                className="form-control"
                                id="attendancededuction"
                                />
                                </div><div className="form-group">
                                <label for="taxdeduction">Tax Deduction:</label>
                                <input style={{
                                    borderTop: 0,
                                    borderRight: 0,
                                    borderLeft: 0
                                }}
                                type="text"
                                disabled
                                value={taxdeduction}
                                name="taxdeduction"
                                className="form-control"
                                id="taxdeduction"
                                />
                                </div><div className="form-group">
                                <label for="totatAmountDeduct">Total Amount Deduct:</label>
                                <input style={{
                                    borderTop: 0,
                                    borderRight: 0,
                                    borderLeft: 0
                                }}
                                type="text"
                                disabled
                                value={totatAmountDeduct}
                                name="totatAmountDeduct"
                                className="form-control"
                                id="totatAmountDeduct"
                                />
                                </div><div className="form-group">
                                <label for="grossIncome">Gross Income:</label>
                                <input style={{
                                    borderTop: 0,
                                    borderRight: 0,
                                    borderLeft: 0
                                }}
                                type="text"
                                disabled
                                value={grossIncome}
                                name="grossIncome"
                                className="form-control"
                                id="grossIncome"
                                />
                                </div><div className="form-group">
                                <label for="NetIncome">Net Income:</label>
                                <input style={{
                                    borderTop: 0,
                                    borderRight: 0,
                                    borderLeft: 0
                                }}
                                type="text"
                                disabled
                                value={NetIncome}
                                name="NetIncome"
                                className="form-control"
                                id="NetIncome"
                                />
                                </div>
                            </fieldset>
                            <br />
							<br />
								<button
									disabled={editToggle}
									onClick={() => this.setState({ editToggle: true })}
									type="button"
									class="btn btn-primary buttonAppear"
									>
								    	Edit
									</button>
									<button
										style={{ marginLeft: 5 }}
										disabled={!editToggle}
										type="submit"
										class="btn btn-primary buttonAppear"
										>
										Save
									</button>
                            </form>
                        </div>
                        <div class="modal-footer">
								<button
									type="button"
									style={{ background: '#00435D' }}
                                    class="btn btn-danger"
                                    onClick={() => this.setState({editToggle: false})}
									data-dismiss="modal"
								>
									Close
								</button>
							</div>
                    </div>
                </div>
            </div>
            </div>                 
        )

        const Loader = (
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<div className="spinner-border" style={{ color: this.context.themeColors.primaryColor }}></div>
			</div>
        );
        
        const renderPayrollData = payrollData.map((item, index) => {
            return (

            <tr key={index} >
                <th scope="row" className="borderSpace" style={{ padding: 1 }} >
                    {index + 1}
                </th>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.staffName}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.accountId}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.accountType}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.campusName}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.departmentName}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.postName}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.month}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.year}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.basicSalary}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.taxAmount}%
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.allowances}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.bonusAmount}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.loanamountDeduction}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.attendancededuction}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.taxdeduction}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.totatAmountDeduct}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.grossIncome}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.NetIncome}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.status}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
							{item.status === 'On Hold' ? 
                <a href="#" data-toggle="tooltip" title="Edit!">
                            
                            <button
								id="firstbutton"
                                onClick={() => this.onEditClick(item.id)}
								onMouseOver={this.btnTechHover()}
								style={{
									cursor: 'pointer',
									fontSize: 20,
									border: 'none',
									marginLeft: 5,
									background: 'none',
									marginBottom: 5
								}}
							>
								<i className="fas fa-pen"></i>
							</button>
						</a> :
							<button className="btn btn-primary"
								onClick={() => this.onHandlePayslip(item.id)}
								style={{
									cursor: 'pointer',
								}}
							>
								Generate
							</button>
                            }
                </td>
                
            </tr>
            
            );
        }) 

        return (
            <div>
				<section  className="mb-4">
				<h2  
                        style={{ background: this.context.themeColors.primaryColor }}
                        className="h1-responsive font-weight-bold text-center  generalHead">
                        View Payroll
                    </h2>
			<div>
                <SnackBar msg={msg} />
                <div style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                }}>
                    <div class="row">
                    <div class="col-md-10" >
                        
                    </div>
                    <div class="col-md-2 align-right my-3">
                                <select class="form-control mr-sm-2" 
                                    type="search" 
                                    name="search" 
                                    placeholder="Department" 
                                    onChange={this.handleSearch}
                                    aria-label="Department" >
                                            <option value="0">All Department</option>
                                            {campus &&
													campus.department.map((value, index) => {
														return (
																<option value={value.departmentId}>
																	{value.departmentName}
																</option>
														);
													})}
                                    </select>
                                
                    </div>
                    </div>
                    
                    <div className="table-responsive">
					<table className="table table-hover text-center">
						<thead style={{ background: this.context.themeColors.primaryColor, color: 'white' }}>
							<tr>
								<th scope="col" style={{ padding: 1 }}>
									#
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Staff Name
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Account Name
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Account Role
								</th>
								<th scope="col" style={{ padding: 1 }}>
                                    Campus Name
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Department Name
								</th>
								<th scope="col" style={{ padding: 1 }}>
                                    Post Name
								</th>
                                <th scope="col" style={{ padding: 1 }}>
                                    Month
								</th>
                                <th scope="col" style={{ padding: 1 }}>
                                    Year
								</th>
                                <th scope="col" style={{ padding: 1 }}>
                                    Basic Salary
								</th>
                                <th scope="col" style={{ padding: 1 }}>
                                    Tax Amount in %
								</th>
                                <th scope="col" style={{ padding: 1 }}>
                                    Allownaces
								</th>
                                <th scope="col" style={{ padding: 1 }}>
                                    Bouns Amount
								</th>
                                <th scope="col" style={{ padding: 1 }}>
                                    Loan Amount Deduction
								</th>
                                <th scope="col" style={{ padding: 1 }}>
                                    Attendence Deduction
								</th>
                                <th scope="col" style={{ padding: 1 }}>
                                    Tax Deduction
								</th>
                                <th scope="col" style={{ padding: 1 }}>
                                    Total Amount Deduced
								</th>
                                <th scope="col" style={{ padding: 1 }}>
                                    Gross Income
								</th>
                                <th scope="col" style={{ padding: 1 }}>
                                    Net Income
								</th>
                                <th scope="col" style={{ padding: 1 }}>
                                    Status
								</th>
                                <th scope="col" style={{ padding: 1 }}>
                                    Actions
								</th>
                                

                                
							</tr>
						</thead>
						<tbody>{isLoading ? Loader : renderPayrollData}</tbody>
					</table>
				</div>
                </div>
                {editPayrollData}
                {confirmPayslip}
            </div>
			</section>
			</div>
        );
    }

}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch),
	};
}
function mapStateToProps(state) {
	return {
		userData: state.setUserReducer.users,
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ViewPayroll);
