import * as actionTypes from '../Actions/actionTypes';
import isEmpty from 'lodash/isEmpty';

const defaultState = {
	apiFlag: true,
	apiFlagAll: true,
	Data: [],
	DataP: [],
	selectStudent: [],
	selectSession: [],
	selectExam: []
};

export default function setSyllabusReducer(state = defaultState, action = {}) {
	switch (action.type) {
		case actionTypes.GET_ALL_SYLLABUS:
			return { ...state, Data: [...action.payload] };
		case actionTypes.GET_ALL_SYLLABUS_PAGINATION:
			return { ...state, DataP: action.payload };
		case actionTypes.SET_LOADER_FLAG_SYLLABUS:
			return { ...state, apiFlag: action.payload };
		case actionTypes.SET_LOADER_FLAG_SYLLABUS_ALL:
			return { ...state, apiFlagAll: action.payload };
		case actionTypes.DELETE_SYLLABUS:
			let filterCandidateReqList = state.Data.filter(
				filterOBJ => filterOBJ.Id !== action.payload
			);
			return {
				...state,
				Data: filterCandidateReqList
			};
		case actionTypes.SET_ALL_SESSION_NAME_INTO_SYLLABUS_SELECT:
			return {
				...state,
				selectSession: action.payload
			};
		case actionTypes.SET_ALL_CLASS_NAME_INTO_SYLLABUS_SELECT:
			return {
				...state,
				selectStudent: action.payload
			};
		case actionTypes.EDIT_SYLLABUS_RECORD:
			var adminData = [...state.Data];
			var index = adminData.findIndex(obj => obj.Id === action.payload.id);
			adminData[index].status = action.payload.status;
			// adminData[index].TotalMarks = action.payload.TotalMarks;
			// adminData[index].examTime = action.payload.examTime;
			// adminData[index].examDate = action.payload.examDate;

			return { ...state, Data: [...adminData] };
		default:
			return state;
	}
}
