import React, { Component } from "react";
import $ from "jquery";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import TextBoxesForCheques from "./TextBoxesForCheques";
import ThemeContext from "../../context/themeContext/ThemeContext";

class MultipleCheque extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  btnTechHover = () => {
    $(document).ready(function () {
      $("#addBtn").hover(
        function () {
          $(this).css(
            "background",
            "linear-gradient(to bottom left, #01425e 5%, #01ac8a 87%)"
          );
          $(this).css("border-color", "white");
        },
        function () {
          $(this).css("background", "#0A4F5E");
        }
      );
    });
  };
  render() {
    const {
      onHandleTextChange,
      stateData,
      updateSpecialArrObjOnChange,
      onClickAddItem,
      onClickDeleteItem,
      updateSpecialArrObjOnChangeDate,
    } = this.props;
    return (
      <div>
        <label class="h1-responsive font-weight-bold text-left my-4">
          Cheque Details:
        </label>

        <div style={{ width: "100%" }}>
          <table class="table-sm table-hover">
            <ThemeContext.Consumer>
              {(context) => (
                <thead
                  style={{
                    background: context.themeColors.primaryColor,
                    color: "white",
                  }}
                >
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Cheque No</th>
                    <th scope="col">Cheque Date</th>
                    <th scope="col">Bank Name</th>
                    <th scope="col">Amount</th>
                  </tr>
                </thead>
              )}
            </ThemeContext.Consumer>
            <tbody>
              {stateData &&
                stateData.ChequeDetails.map((item, index) => (
                  <tr key={index}>
                    <th scope={"row"}>{index + 1}</th>
                    <TextBoxesForCheques
                      arrName={"ChequeDetails"}
                      updateSpecialArrObjOnChange={updateSpecialArrObjOnChange}
                      updateSpecialArrObjOnChangeDate={
                        updateSpecialArrObjOnChangeDate
                      }
                      rowIndex={index}
                      onHandleTextChange={onHandleTextChange}
                      stateData={stateData}
                      itemObj={item}
                    />
                  </tr>
                ))}
            </tbody>
          </table>
          <div className={"row"} style={{ justifyContent: "flex-end" }}>
            <div>
              <button
                id={"addBtn"}
                onMouseEnter={this.btnTechHover()}
                onClick={() =>
                  onClickAddItem("ChequeDetails", {
                    chequeNo: "",
                    date: "",
                    bankName: "",
                    amount: ""
                  })
                }
                style={{
                  background: "#0A4F5E",
                  color: "#FFFFFF",
                  borderRadius: 100,
                }}
                type="button"
                class="btn"
              >
                <i class="fas fa-plus" />
              </button>
            </div>
            <div style={{ marginLeft: 8 }}>
              <button
                onClick={() => onClickDeleteItem("ChequeDetails")}
                style={{ color: "#FFFFFF", borderRadius: 100 }}
                type="button"
                class="btn btn-danger"
              >
                <i class="far fa-trash-alt" />
              </button>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        {/*<div class="text-center text-md-center">*/}
        {/*<button class="btns">Add</button>*/}
        {/*</div>*/}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    auth: state.setUserReducer.auth,
    role: state.setUserReducer.users.role,
    name: state.setUserReducer.users.unique_name,
  };
}

export default connect(mapStateToProps)(MultipleCheque);
