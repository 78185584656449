import React, { Component } from "react";
import "./Form.css";
import StudentAddress from "./StudentAddress";
import StudentParentInfo from "./StudentParentInfo";
import StudentDocuments from "./StudentDocuments";
import StudentParentDocument from "./StudentParentDocument";
import StudentMedicalCondition from "./StudentMedicalCondition";
import StudentSiblings from "./StudentSiblings";
import StudentPreviousSchoolDetail from "./StudentPreviousSchoolDetail";
import StudentWithdrawDetails from "./StudentWithdrawDetails";
import StudentOtherInformation from "./StudentOtherInformation";
import StudentTransport from "./StudentTransport";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as addActions from "../../Actions/Admission";
import * as sharedActions from "../../Actions/sharedActions";
import * as Generalfunction from "../../utils/general/index";
import LoaderWrapper from "../../components/HOC/LoaderWrapper";
class PrintAdmissionFormFinal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imagePreviewUrl:
        "http://ctt.trains.com/sitefiles/images/no-preview-available.png",
      getSingleAddData: [],
      gotData: false,
      images: "",
      imageData: "",
      isLoading: true,
    };
  }
  PrintContent = (el) => {
    var restorepage = document.body.innerHTML;
    var Printcontent = document.getElementById(el).innerHTML;
    document.body.innerHTML = Printcontent;
    window.print();
    document.body.innerHTML = restorepage;
  };
  conversionOfImageUrl = (url) => {
    let getSplitResult = url.split("\\");
    let result = "";
    for (let i = 4; i < getSplitResult.length; i++) {
      if (getSplitResult.length - 1 == i) result += getSplitResult[i];
      else result += getSplitResult[i].concat("\\\\");
    }
    let finalResult = "http://".concat(`${result}`);
    return finalResult;
  };
  componentDidMount() {
    const { match } = this.props;
    this.props.shareAction
      .gAllData(
        `/api/StudentAdmissionForm/${match.params.id}`,
        this.props.addAction.getIndividualStudentData
      )
      .then((success) => {
        console.log(this.state.getSingleAddData.StudentAdmissionForms);
        debugger;
        this.setState({
          isLoading: false,
          imagePreviewUrl: Generalfunction.makeStudentImageURL(
            this.state.getSingleAddData &&
              this.state.getSingleAddData.StudentImageUpload.fileName
          ),
        });
        console.log(success);
      })
      .catch((error) => {
        this.setState({
          isLoading: true,
        });
      });
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.getSingleAddData !== nextProps.getData) {
      return {
        getSingleAddData: nextProps.getData,
      };
    }

    return null;
  }
  render() {
    console.log("Image Path", this.state.imagePreviewUrl);
    return (
      <div>
        <LoaderWrapper isLoading={this.state.isLoading}>
          <div id="div1">
            <section class="mb-4">
              <div class="">
                <img
                  src={require("../../Assets/images/header.png")}
                  style={{
                    width: "100%",
                    height: "150",
                  }}
                />
              </div>
              <br />
              <div class="row print">
                <div class="col-md-12">
                  <div class="md-form mb-0">
                    <h2 className="FormHeadingStyle">
                      Student Admission Form{" "}
                    </h2>
                  </div>
                </div>
              </div>
              <div class="row mZero print">
                <div class="col-md-12 text-right">
                  <div class="md-form mb-0">
                    <label class="mr-2">Date:</label>
                    <label class="font-weight-bold">
                      {this.state.getSingleAddData &&
                        this.state.getSingleAddData.StudentAdmissionForms.date}
                    </label>
                  </div>
                </div>
              </div>
              <div class="container">
                <div class="row" style={{ float: "right" }}>
                  <div class="col-md-4">
                    <div class="md-form mb-0">
                      <div>
                        <img
                          src={this.state.imagePreviewUrl}
                          alt="..."
                          height={220}
                          width={200}
                          class="rounded float-left shadow p-3 bg-light"
                          // style={{ border: "solid" }}
                        ></img>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row rowCheck" style={{ marginTop: 10 }}>
                  <div class="col colCheck">
                    <label style={{ fontWeight: "bold" }}>
                      Registration No:{" "}
                    </label>{" "}
                    &nbsp;&nbsp; 0000
                    {this.state.getSingleAddData &&
                      this.state.getSingleAddData.StudentAdmissionForms
                        .studentAdmissionFormId}
                  </div>
                </div>

                <div class="row rowCheck">
                  <div class="col colCheck">
                    <label style={{ fontWeight: "bold" }}>
                      Name in Arabic:
                    </label>
                    &nbsp;&nbsp;{" "}
                    {this.state.getSingleAddData &&
                      this.state.getSingleAddData.StudentAdmissionForms
                        .fullNameArabic}{" "}
                  </div>{" "}
                </div>
                <div class="row rowCheck">
                  <div class="col colCheck">
                    <label style={{ fontWeight: "bold" }}>First Name:</label>
                    &nbsp;&nbsp;{" "}
                    {this.state.getSingleAddData &&
                      this.state.getSingleAddData.StudentAdmissionForms
                        .firstName}
                  </div>

                  <div class="col colCheck">
                    <label style={{ fontWeight: "bold" }}>Last Name:</label>
                    &nbsp;&nbsp;{" "}
                    {this.state.getSingleAddData &&
                      this.state.getSingleAddData.StudentAdmissionForms
                        .lastName}{" "}
                  </div>
                </div>
                <div class="row rowCheck">
                  <div class="col colCheck">
                    <label style={{ fontWeight: "bold" }}>Father Name: </label>
                    &nbsp;&nbsp;{" "}
                    {this.state.getSingleAddData &&
                      this.state.getSingleAddData.StudentAdmissionForms
                        .fatherName}
                  </div>
                </div>

                <div class="row rowCheck">
                  <div class="col colCheck">
                    <label style={{ fontWeight: "bold" }}>Gender: </label>{" "}
                    &nbsp;&nbsp;
                    {this.state.getSingleAddData &&
                      this.state.getSingleAddData.StudentAdmissionForms.gender}
                  </div>
                  <div class="col colCheck">
                    <label style={{ fontWeight: "bold" }}>Age:</label>{" "}
                    &nbsp;&nbsp;
                    {new Date().getFullYear() -
                      new Date(
                        this.state.getSingleAddData &&
                          this.state.getSingleAddData.StudentAdmissionForms.dob
                      ).getFullYear()}
                  </div>
                </div>
                <div class="row rowCheck">
                  <div class="col colCheck">
                    <label style={{ fontWeight: "bold" }}>Language:</label>
                    &nbsp;&nbsp;{" "}
                    {this.state.getSingleAddData &&
                      this.state.getSingleAddData.StudentAdmissionForms
                        .language}
                  </div>
                </div>
                <div class="row rowCheck">
                  <div class="col colCheck">
                    <label style={{ fontWeight: "bold" }}>Nationality: </label>{" "}
                    &nbsp;&nbsp;
                    {this.state.getSingleAddData &&
                    this.state.getSingleAddData.StudentAdmissionForms
                      .nationality !== null
                      ? Generalfunction.getCountryName(
                          this.state.getSingleAddData &&
                            this.state.getSingleAddData.StudentAdmissionForms
                              .nationality
                        )
                      : ""}
                  </div>
                  <div class="col colCheck">
                    <label style={{ fontWeight: "bold" }}>Religion: </label>
                    &nbsp;&nbsp;{" "}
                    {this.state.getSingleAddData &&
                      this.state.getSingleAddData.StudentAdmissionForms
                        .religion}
                  </div>
                </div>

                <div class="row rowCheck">
                  <div class="col colCheck">
                    <label style={{ fontWeight: "bold" }}>
                      Place Of Birth :
                    </label>
                    &nbsp;&nbsp;
                    {this.state.getSingleAddData &&
                      this.state.getSingleAddData.StudentAdmissionForms
                        .placeOfBirth}
                  </div>
                  <div class="col colCheck">
                    <label style={{ fontWeight: "bold" }}>Date Of Birth </label>
                    &nbsp;&nbsp;{" "}
                    {new Date(
                      this.state.getSingleAddData &&
                        this.state.getSingleAddData.StudentAdmissionForms.dob
                    ).toDateString()}
                  </div>
                </div>
                <div class="row rowCheck">
                  <div class="col colCheck">
                    <label style={{ fontWeight: "bold" }}>ID Card: </label>{" "}
                    &nbsp;&nbsp;
                    {this.state.getSingleAddData &&
                      this.state.getSingleAddData.StudentAddresss
                        .studentIdCardNo}
                  </div>
                  <div class="col colCheck">
                    <label style={{ fontWeight: "bold" }}>Email: </label>{" "}
                    &nbsp;&nbsp;
                    {this.state.getSingleAddData &&
                      this.state.getSingleAddData.StudentAddresss
                        .studentEmailId}
                  </div>
                </div>
                <div class="row rowCheck">
                  <div class="col colCheck">
                    <label style={{ fontWeight: "bold" }}>Grade: </label>
                    &nbsp;&nbsp;{" "}
                    {this.state.getSingleAddData &&
                      this.state.getSingleAddData.StudentAdmissionForms
                        .className}
                  </div>
                  <div class="col colCheck">
                    <label style={{ fontWeight: "bold" }}>Session:</label>
                    &nbsp;&nbsp;{" "}
                    {this.state.getSingleAddData &&
                      this.state.getSingleAddData.StudentAdmissionForms.session}
                  </div>
                </div>
                <div class="row rowCheck">
                  <div class="col colCheck">
                    <label style={{ fontWeight: "bold" }}>Campus Name: </label>{" "}
                    &nbsp;&nbsp;
                    {this.state.getSingleAddData &&
                      this.state.getSingleAddData.CampusGrade.campusName}
                  </div>
                  <div class="col colCheck">
                    <label style={{ fontWeight: "bold" }}>
                      Admission Type:{" "}
                    </label>
                    &nbsp;&nbsp;{" "}
                    {this.state.getSingleAddData &&
                      this.state.getSingleAddData.StudentAdmissionForms
                        .admissionType}{" "}
                  </div>
                </div>
                <div class="row rowCheck">
                  <div class="col colCheck">
                    <label style={{ fontWeight: "bold" }}>Username: </label>
                    &nbsp;&nbsp;{" "}
                    {this.state.getSingleAddData &&
                      this.state.getSingleAddData.StudentAdmissionForms
                        .userName}
                  </div>
                  <div class="col colCheck">
                    <label style={{ fontWeight: "bold" }}>Password: </label>
                    &nbsp;&nbsp;{" "}
                    {this.state.getSingleAddData &&
                      this.state.getSingleAddData.StudentAdmissionForms
                        .password}
                  </div>
                </div>

                <div class="row rowCheck">
                  <div class="col colCheck">
                    <label style={{ fontWeight: "bold" }}>
                      Admission as Status:
                    </label>{" "}
                    &nbsp;&nbsp;
                    {this.state.getSingleAddData &&
                      this.state.getSingleAddData.StudentAdmissionForms
                        .resultStatus}
                  </div>
                  <div class="col colCheck">
                    <label style={{ fontWeight: "bold" }}>
                      Account Status:{" "}
                    </label>{" "}
                    &nbsp;&nbsp;
                    {this.state.getSingleAddData &&
                      this.state.getSingleAddData.StudentAdmissionForms.status}
                  </div>
                </div>
                <div class="row rowCheck">
                  <div class="col colCheck">
                    <label style={{ fontWeight: "bold" }}>Account Name:</label>
                    &nbsp;&nbsp;{" "}
                    {this.state.getSingleAddData &&
                      this.state.getSingleAddData.StudentAdmissionForms
                        .accountId}
                  </div>
                  <div class="col colCheck">
                    <label style={{ fontWeight: "bold" }}>Account Type: </label>
                    &nbsp;&nbsp;{" "}
                    {this.state.getSingleAddData &&
                      this.state.getSingleAddData.StudentAdmissionForms
                        .accountType}
                  </div>
                </div>
              </div>
              <br />
              <StudentAddress
                data={
                  this.state.getSingleAddData &&
                  this.state.getSingleAddData.StudentAddresss
                }
              />
              <div class="hojaa">
                <StudentParentInfo
                  parentData={
                    this.state.getSingleAddData &&
                    this.state.getSingleAddData.ParentInfos
                  }
                />
              </div>
              <StudentDocuments
                studentDocs={
                  this.state.getSingleAddData &&
                  this.state.getSingleAddData.StudentDocuments
                }
              />

              <StudentParentDocument
                parentDocs={
                  this.state.getSingleAddData &&
                  this.state.getSingleAddData.ParentDocuments
                }
              />
              <StudentMedicalCondition
                medicalCondition={
                  this.state.getSingleAddData &&
                  this.state.getSingleAddData.MedicalConditions
                }
              />

              <div class="row print">
                <div class="col-md-12">
                  <div class="md-form mb-0">
                    <h2 className="FormHeadingStyle">Student Siblings:</h2>
                  </div>
                </div>
              </div>

              {this.state.getSingleAddData &&
                this.state.getSingleAddData.StudentSiblingDetails.map(
                  (item, index) => {
                    return <StudentSiblings siblingData={item} />;
                  }
                )}
              <div class="row print">
                <div class="col-md-12">
                  <div class="md-form mb-0">
                    <h2 className="FormHeadingStyle">
                      Previous School Details:
                    </h2>
                  </div>
                </div>
              </div>
              {this.state.getSingleAddData &&
                this.state.getSingleAddData.PreviousSchoolDetails.map(
                  (items, index) => {
                    return <StudentPreviousSchoolDetail prevSchool={items} />;
                  }
                )}
              <StudentWithdrawDetails
                widthDraw={
                  this.state.getSingleAddData &&
                  this.state.getSingleAddData.WidthdrawDetails
                }
              />
              <StudentOtherInformation
                otherInformation={
                  this.state.getSingleAddData &&
                  this.state.getSingleAddData.OtherInformations
                }
              />
              <StudentTransport
                studentTransport={
                  this.state.getSingleAddData &&
                  this.state.getSingleAddData.StudentTransports
                }
                transportDriver={
                  this.state.getSingleAddData &&
                  this.state.getSingleAddData.DriverName
                }
                routeTransport={
                  this.state.getSingleAddData &&
                  this.state.getSingleAddData.RoutesName
                }
              />

              <div class="row" style={{ marginTop: 150 }}>
                <div class="col-md-4 text-center">
                  <input
                    type="text"
                    disabled
                    style={{ border: 0, borderBottom: "1px solid #000" }}
                  />
                  <br />
                  <label className="font-weight-bold">
                    Principal Signature
                  </label>
                </div>
                <div class="col-md-4 text-center">
                  <input
                    type="text"
                    disabled
                    style={{ border: 0, borderBottom: "1px solid #000" }}
                  />
                  <br />
                  <label className="font-weight-bold">School Stamp</label>
                </div>
                <div class="col-md-4 text-center">
                  <input
                    type="text"
                    disabled
                    style={{ border: 0, borderBottom: "1px solid #000" }}
                  />
                  <br />
                  <label className="font-weight-bold">Parent Signature</label>
                </div>
              </div>
            </section>
            {/* <div id="footer">
						<div class="h1-responsive font-weight-bold text-center my-4">
							<img
								src={require('../../Assets/images/Powerd-01.svg')}
								style={{ width: 200 }}
							/>
						</div>
					</div> */}
            <div class="page-footer">
              <div class="text-left">Printed By: {this.props.users.role}</div>
              <div
                class="text-right"
                style={{ marginTop: -25, marginRight: 20 }}
              >
                Powered by School Smart®
              </div>
            </div>
            <div>
              <p style={{ color: "grey", textAlign: "center" }}>
                1305 building number 464/1, complex number 313. Madinat al Irfan
                Airport Heights - Bosher - Oman.
              </p>
            </div>
          </div>
          <div class="text-center text-md-right">
            <label
              for="name"
              class=""
              onClick={() => this.PrintContent("div1")}
            >
              <i
                className="fas fa-print fa-2x animated fadeIn"
                style={{ marginRight: 4 }}
              />
              Print
            </label>
          </div>
        </LoaderWrapper>
      </div>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    addAction: bindActionCreators(addActions, dispatch),
    shareAction: bindActionCreators(sharedActions, dispatch),
  };
}
function mapStateToProps(state) {
  return {
    getData: state.setAdmissionReducer.getSingleFormData,
    users: state.setUserReducer.users,
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrintAdmissionFormFinal);
