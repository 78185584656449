import React from 'react'
import $ from 'jquery';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import SelectionInput from '../../components/SelectionInput/SelectionInput';
import general from '../../utils/general'
import Spinner from '../../components/Spinner/Spinner';
import Colors from '../../utils/app_constants/colors_constants';
import CustomFilterSectionLayout from '../../components/CustomFilterSectionLayout/CustomFilterSectionLayout';
import Button from '../../components/Button';
import ThemeContext from "../../context/themeContext/ThemeContext";

let tHead = [
    'Date',
    'Month',
    'Time',
    'Tour',
    'Description',
    'Session',
];

let renderFields = [
    'tourDate',
    'month',
    'tourTime',
    'tour',
    'description',
    'session'
]

class ViewTourByStudent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tourData: [],
            checking: [],
            isLoading: true,
            disablepage: false,
            disableBPage: true,
            currentPage: 1,
            todosPerPage: 10,
            pageCounter: 10,
            classId: '',
            sessionId: '',
            sectionId: '',
            errorMsg: false
        }
    }

    componentDidMount() {
        $(document).ready(function () {
            $('[data-toggle="tooltip"]').tooltip();
        });

        this.props.sharedActions
            .getDataWithoutDispatch(
                `/api/StudentDetail?userName=${this.props.name}`
            )
            .then(success => {
                this.setState({
                    userData: success,
                    studentId: success[0].studentId,
                    classId: success[0].classId,
                    sectionId: success[0].sectionId,
                    sessionId: success[0].sessionId,
                })
                this.props.sharedActions
                    .getDataWithoutDispatch(
                        `/api/StudentTours?pageNumber=1&pageSize=10&classId=${this.state.classId}&sectionId=${this.state.sectionId}&sessionId=${this.state.sessionId}`
                    )
                    .then(success => {
                        this.setState({
                            tourData: success.tourList,
                            checking: success.paginationMetadata,
                            isLoading: false
                        })

                    })
                    .catch(err => {
                        this.setState({
                            isLoading: false
                        })
                    })
            })
            .catch(err => {
                this.setState({
                    isLoading: false
                })
            })
    }

    componentDidUpdate() {
        if (this.state.msg || this.state.errorMsg) {
            setTimeout(() => {
                this.setState({
                    msg: '',
                    errorMsg: false,
                });
            }, 3000);
        }
    }

    btnTechHover = () => {
        $(document).ready(function () {
            $('button').hover(
                function () {
                    $(this).css('box-shadow', '0px 5px 5px grey');
                    $(this).css('border-color', 'white');
                },
                function () {
                    $(this).css('box-shadow', '0px 0px 0px white');
                }
            );
        });
    };

    paginationRequest = number => {

        this.props.sharedActions
            .getDataWithoutDispatch(
                `/api/StudentTours?pageNumber=${number}&pageSize=10&classId=${this.state.classId}&sectionId=${this.state.sectionId}&sessionId=${this.state.sessionId}`
            )
            .then(success => {
                this.setState({
                    tourData: success.tourList,
                    checking: success.paginationMetadata,
                    isLoading: false
                })

            })
            .catch(err => {
                this.setState({
                    isLoading: false
                })
            })

    };


    handleClickNextFrwd = () => {
        const { tourData, todosPerPage, currentPage } = this.state;
        let pageNumbers = [];
        for (
            let i = 1;
            i <= Math.ceil(tourData.length / todosPerPage);
            i++
        ) {
            pageNumbers.push(i);
        }
        console.log('pagelength: ', pageNumbers.length);
        console.log('current page: ', currentPage);
        if (currentPage === pageNumbers.length) {
            this.setState({
                disablepage: true
            });
        } else {
            this.setState(prevState => ({
                currentPage: prevState.currentPage + 1,
                disableBPage: false
            }));
        }
    };

    handleClickNextBack = () => {
        const { currentPage } = this.state;
        console.log('current page: ', currentPage);
        if (currentPage === 1) {
            this.setState({
                disableBPage: true,
                disablepage: false
            });
        } else {
            this.setState(prevState => ({
                currentPage: prevState.currentPage - 1
            }));
            this.setState({
                disablepage: false
            });
        }
    };
    handleClickNext = event => {
        this.setState({
            currentPage: Number(event.target.id),
            disablepage: false,
            disableBPage: false
        });
    };


    render() {
        const { tourData, isLoading, } = this.state;

        let pageNumbers = [];
        for (
            let i = 1;
            i <=
            Math.ceil(this.state.checking.totalCount / this.state.checking.pageSize);
            i++
        ) {
            pageNumbers.push(i);
        }
        const renderPageNumbers = pageNumbers.map((number, index) => {
            return (
                <ThemeContext.Consumer>
                {(context) => (
                <li
                    class={`page-item ${
                        this.state.checking.currentPage === number ? 'active' : ''
                        } `}
                >
                    <a
                        key={number}
                        id={number}
                        onClick={() => this.paginationRequest(number)}
                        class="page-link "
                        href="#"
                    >
                        {number}
                    </a>
                </li>
                )}
                </ThemeContext.Consumer>
            );
        });
        const MainContent = data =>
            data &&
            data.map((item, index) => (
                <ThemeContext.Consumer>
                {(context) => (
                <tr>
                    <th
                        style={{
                            padding: 2,
                            border: `1px solid ${context.themeColors.primaryColor}`,
                            borderColor: context.themeColors.primaryColor
                        }}
                        scope="row"
                    >
                        {index + 1}
                    </th>
                    {renderFields.map((item1, index) => (
                        <td
                            style={{
                                fontWeight: `${index == 0 ? 'bold' : 'normal'}`,
                                border: `1px solid ${context.themeColors.primaryColor}`,
                                padding: 2,
                                borderColor: context.themeColors.primaryColor
                            }}
                        >
                            {item[item1]}
                        </td>
                    ))}
                </tr>
                )}
                </ThemeContext.Consumer>
            ));

        return (
            <ThemeContext.Consumer>
            {(context) => (
            <div>
                <div className="page-header">
                    <SnackBar msg={this.state.msg} />
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between'
                        }}
                    >
                        <div>
                            <h2 className="h1-responsive font-weight-bold text-left my-4 generalHead" style={{background: context.themeColors.primaryColor}}>
                                {' '}
                    Tour Details{' '}
                            </h2>
                        </div>
                        <div
                            style={{ marginTop: 40, display: 'flex', flexDirection: 'column' }}
                        >
                            <nav aria-label="Page navigation example">
                                <ul style={{ color: context.themeColors.primaryColor }} class="pagination">
                                    {renderPageNumbers}
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div >
                < br />
                {
                    isLoading ? (
                        <Spinner />
                    ) :
                        tourData.length < 1 ? <div style={{ display: 'flex', justifyContent: 'center', color: 'red' }}>
                            <strong>No Data</strong>
                        </div>
                            : (
                                <div className="table-responsive">
                                    <table class="table table-hover">
                                        <thead style={{ background: context.themeColors.primaryColor, color: 'white' }}>
                                            <tr>
                                                <th scope="col">#</th>
                                                {tHead.map(item => (
                                                    <th
                                                        style={{
                                                            border: `1px solid ${context.themeColors.primaryColor}`,
                                                            borderColor: Colors.WHITE
                                                        }}
                                                        scope="col"
                                                    >
                                                        {item}
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>{MainContent(tourData)}</tbody>
                                    </table>
                                </div>

                            )}
            </div>
            )}
            </ThemeContext.Consumer>
        );
    }

}

function mapStateToProps(state) {
    return {
        role: state.setUserReducer.users.role,
        name: state.setUserReducer.users.unique_name,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        sharedActions: bindActionCreators(sharedActions, dispatch)
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewTourByStudent);