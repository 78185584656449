import React, { Component } from 'react';

const RenderDynamicDescription = props => {
	const {
		itemObj,
		onHandleTextChange,
		stateData,
		rowIndex,
		updateSpecialArrObjOnChange,
		arrName
	} = props;

	let keys = Object.keys(itemObj);
	// console.log(stateData.siblingsDetails[0]['age'])
	if (keys.includes('StudentSiblingDetailId') && keys.includes('studentId')) {
		let newKeys = keys.filter(
			item => item !== 'studentId' && item !== 'StudentSiblingDetailId'
		);
		keys = [...newKeys];
	}
	if (keys.includes('PreviousSchoolDetailId') && keys.includes('studentId')) {
		let newKeys = keys.filter(
			item => item !== 'studentId' && item !== 'PreviousSchoolDetailId'
		);
		keys = [...newKeys];
	}
	if (
		keys.includes('studentId') &&
		keys.includes('classId') &&
		keys.includes('subjectId') &&
		keys.includes('term') &&
		keys.includes('sessionId') &&
		keys.includes('staffId') &&
		keys.includes('accountId') &&
		keys.includes('accountType') &&
		keys.includes('sectionId')
	) {
		let newKeys = keys.filter(
			item =>
				item !== 'studentId' &&
				item !== 'classId' &&
				item !== 'subjectId' &&
				item !== 'term' &&
				item !== 'sessionId' &&
				item !== 'staffId' &&
				item !== 'accountId' &&
				item !== 'studentId' &&
				item !== 'accountType' &&
				item !== 'sectionId'
		);
		keys = [...newKeys];
	}
	if (
		keys.includes('studentId') &&
		keys.includes('classId') &&
		keys.includes('subjectId') &&
		keys.includes('term') &&
		keys.includes('sessionId') &&
		keys.includes('accountId') &&
		keys.includes('accountType') &&
		keys.includes('sectionId')
	) {
		let newKeys = keys.filter(
			item =>
				item !== 'studentId' &&
				item !== 'classId' &&
				item !== 'subjectId' &&
				item !== 'term' &&
				item !== 'sessionId' &&
				item !== 'accountId' &&
				item !== 'studentId' &&
				item !== 'accountType' &&
				item !== 'sectionId'
		);
		keys = [...newKeys];
	}

	return (
		<React.Fragment>
			{keys.map((item, index) =>
				item === 'studentName' ? (
					<td
						key={index}
						class="col-md-3 text-left"
						style={{ fontWeight: 'bold' }}
					>
						{stateData[arrName][rowIndex][`${item}`]}
					</td>
				) : (
					<td class="col-md-7" key={index}>
						<textarea
							type="text"
							placeholder={`${item}`}
							class="form-control"
							name={`${item}`}
							value={stateData[arrName][rowIndex][`${item}`]}
							onChange={e => updateSpecialArrObjOnChange(e, rowIndex, arrName)}
							required
						/>
					</td>
				)
			)}
		</React.Fragment>
	);
};
export default RenderDynamicDescription;
