import React, { Component, useState, useEffect } from 'react';
import SelectionInput from '../../../components/SelectionInput/SelectionInput';
import LoaderWrapper from '../../../components/HOC/LoaderWrapper';
import useHttp from '../../../hooks/http.js';
import {
	dataFilteration,
	addDataMiddleware,
	isEmptyFieldInArrOBJ
} from '../../../utils/general/index';
import ListView from '../../../components/List/ListView/ListView.jsx';
import * as sharedActions from '../../../Actions/sharedActions';
import { bindActionCreators } from 'redux';
import Button from '../../../components/Button';
import { connect } from 'react-redux';
import SnackBar from '../../../components/SnackBar/SnackBar';
import validateInput from '../../../validator/inputValidator';
import { CHECK_MARKS_RANGE } from '../../../validator/inputValidator';
import { Link } from 'react-router-dom';
import InfoComponent from '../../../components/InfoComponent/InfoComponent';
import httpWithRequiredSetData from '../../../hooks/Services/httpWithRequiredSetData';
import Colors from '../../../utils/app_constants/colors_constants';
function CaAddExamV2Feature(props) {
	const [error, setError] = useState({});
	const { type, pid } = props.match.params;
	const [editStudentData, setEditStudentData] = useState([]);
	const [snackbarMessage, setMsg] = useState('');
	const [studentData, setStudentData] = useState('');
	const [classId, setClassId] = useState('');
	const [sectionId, setSectionId] = useState('');
	const [subjectId, setSubjectId] = useState('');
	const [sessionId, setSessionId] = useState('');
	const [isLoaderNew, setIsLoader] = useState(false);
	const [term, setTerm] = useState('');
	const [sessions, setSessions] = useState([]);
	const [subjects, setSubjects] = useState([]);
	const [sections, setSections] = useState([]);
	const [msg, setErrorMSg] = useState('');
	const [customLoader, setCustomLoader] = useState(false);
	const [
		isLoadingFetchData,
		realFetchData,
		setFetchData1
	] = httpWithRequiredSetData(`/api/ExamMarkListCA/?pId=${pid}`, [], data => {
		debugger;
		let getStudentMarks = JSON.parse(JSON.stringify(data))['studentMark'];
		setFetchData1(getStudentMarks);
	});
	const [isgetMessageLoader, getMessage] = useHttp(
		`/api/FinalExamMarkCAValidation/?pId=${pid}`,
		[]
	);
	debugger;
	const [
		isLoadingFetchData1,
		studentListData,
		setStudentListData
	] = httpWithRequiredSetData(
		`/api/ListOfStudentBypId?pId=${pid}`,
		[],
		data => {
			let extraField = {
				examMark: ''
			};
			debugger;
			let getStudentMarks = JSON.parse(JSON.stringify(data));
			let extendedArrObj = getStudentMarks['studentList'].map(item => {
				return {
					...item,
					...extraField
				};
			});
			let newCloneData = {
				...getStudentMarks,
				studentList: [...extendedArrObj]
			};
			debugger;
			console.log('new clone data:', newCloneData);
			debugger;
			setStudentListData(newCloneData);
			debugger;
		}
	);
	debugger;

	const [isLoader, fetchData] = httpWithRequiredSetData(
		`/api/ExamMarkListCA/?pId=${pid}`,
		[]
	);

	const [isLoading, fetchSessionData] = useHttp(`/api/Session`, []);
	console.log('fetch Session Data: ', fetchSessionData);
	const onSetTerm = e => {
		if (e.target.value == 'Choose Term') {
			setTerm('');
		} else {
			setTerm(e.target.value);
		}
	};
	const onChangeMarks = (e, index) => {
		debugger;
		if (
			parseInt(e.target.value) <= parseInt(studentListData['finalExamMarks']) ||
			e.target.value === ''
		) {
			debugger;
			let getCloneData = JSON.parse(JSON.stringify(studentListData));
			debugger;
			getCloneData['studentList'][index]['examMark'] = e.target.value;
			debugger;
			setStudentListData(getCloneData);
		}
		debugger;
	};
	const isValid = (data, validateType) => {
		const { errors, isValid } = validateInput(data, validateType);
		if (!isValid) {
			setError(errors);
		}
		return isValid;
	};
	const onSessionChange = e => {
		if (e.target.value !== 'Choose Session') setSessionId(e.target.value);
	};
	const onSubjectChange = e => {
		if (e.target.value !== 'Choose Subject') setSubjectId(e.target.value);
	};
	const onChangeClassName = e => {
		const { staffClassSection } = fetchData;
		if (e.target.value !== 'Choose Class') {
			let getSections = dataFilteration(
				staffClassSection,
				['section', 'sectionId'],
				{
					classId: parseInt(e.target.value)
				}
			);

			setClassId(parseInt(e.target.value));
			setSections(getSections);
			setSectionId('');
			setSubjectId('');
		}
	};
	const onMarksChange = e => {};
	const onSectionChange = e => {
		if (e.target.value !== 'Choose Section') {
			const { staffClassSection } = fetchData;
			let getSubjects = dataFilteration(
				staffClassSection,

				['subjectName', 'subjectId'],
				{
					classId: classId,
					sectionId: parseInt(e.target.value)
				}
			);
			setSectionId(parseInt(e.target.value));
			setSubjects(getSubjects);
		}
	};

	const onSearch = () => {
		setCustomLoader(true);
		props.sharedAction
			.getDataWithoutDispatch(
				`/api/StudentListDisciplinary?classId=${classId}&sectionId=${sectionId}&term=${term}&subjectId=${subjectId}&sessionId=${sessionId}`
			)
			.then(success => {
				let requiredData = addDataMiddleware(
					{
						marks: '',
						classId,
						sessionId,
						subjectId,
						sectionId,
						term,
						accountId: props.name,
						accountType: props.role
					},
					success
				);
				setStudentData(requiredData);
				setCustomLoader(false);
			})
			.catch(error => {
				console.log(error);
				if (error && error.response && error.response.status == 404) {
					// 404-Error no understanding of 404 Error
					setStudentData([]);

					setMsg('404 Not Found');
					props.snackbar();
					setCustomLoader(false);
				}
				if (error && error.response && error.response.status == 400) {
					setStudentData([]);
					console.log(error);

					setMsg(error.response.data.Message);
					props.snackbar();

					setCustomLoader(false);
				}
			});
	};

	useEffect(() => {
		setIsLoader(true);
		props.sharedAction
			.getDataWithoutDispatch(`/api/FinalExamMarkCAValidation/?pId=${pid}`)
			.then(success => {
				debugger;
				setMsg(success);
				setIsLoader(false);
			})
			.catch(error => {
				setIsLoader(false);
				debugger;
				setErrorMSg(
					error &&
						error.response &&
						error.response.data &&
						error.response.data.Message
				);
			});
	}, []);
	const onSubmit = () => {
		props.sharedAction
			.simpleAddRequest(`/api/FinalExamMarkCA`, {
				FinalTotalCAs: [...studentListData['studentList']]
			})
			.then(success => {
				debugger;
				setMsg('Successfully Submitted');
				props.snackbar();
				props.history.push(`/dashboard/ContinousNewToolCreation`);
			})
			.catch(error => {
				if (error && error.response && error.response.status == 404) {
					//404-Error no understanding of 404 Error
					setMsg('404 Not Found');
					props.snackbar();
				} else {
					setMsg(
						error &&
							error.response &&
							error.response.data &&
							error.response.data.Message
					);
					props.snackbar();
				}
			});
	};

	return (
		<LoaderWrapper isLoading={isLoaderNew}>
			<div>
				<h2
					style={{ width: 'fit-content' }}
					className="text-center generalHead"
				>
					ADD EXAM MARKS
				</h2>
				<SnackBar backColor={'#000'} msg={snackbarMessage} />
				<br />
				{msg !== '' ? (
					<InfoComponent
						classType="text-danger text-center"
						fontSize={25}
						message={msg}
					/>
				) : (
					''
				)}
				{error['rangeError'] ? (
					<InfoComponent
						classType="text-danger"
						fontSize={15}
						message={`Note: marks can only be assigned into the range of 0 - 4`}
					/>
				) : (
					''
				)}
				{msg === '' && !isLoading ? (
					<LoaderWrapper isLoading={customLoader}>
						<table class="table table-bordered">
							<thead>
								<tr>
									<th scope="col">#</th>
									<th scope="col">Student Name</th>
									<th scope="col">
										Marks {'('}
										{studentListData && studentListData['finalExamMarks']}
										{') '}
									</th>
								</tr>
							</thead>

							<tbody>
								{studentListData &&
									studentListData['studentList'].map((item, index) => (
										<tr>
											<td>{index + 1}</td>
											<td>{item && item.studentName}</td>
											<td>
												<input
													value={item.examMark}
													placeholder={`0-${studentListData &&
														studentListData['finalExamMarks']}`}
													onChange={e => onChangeMarks(e, index)}
													type="number"
													name=""
													id=""
												/>
											</td>
										</tr>
									))}
							</tbody>
						</table>
						{studentListData && studentListData['studentList'].length > 0 ? (
							<Button
								textColor={Colors.WHITE}
								buttonClass="customButton"
								btnName={'Submit'}
								customClause={isEmptyFieldInArrOBJ(
									studentListData['studentList'],
									'examMark'
								)}
								iconSize={19}
								onHandleSubmit={onSubmit}
							/>
						) : null}
					</LoaderWrapper>
				) : (
					''
				)}
			</div>
		</LoaderWrapper>
	);
}
function mapStateToProps(state) {
	return {
		role: state.setUserReducer.users.role,
		name: state.setUserReducer.users.unique_name
	};
}
function mapDispatchToProps(dispatch) {
	return {
		sharedAction: bindActionCreators(sharedActions, dispatch)
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CaAddExamV2Feature);
