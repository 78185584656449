import React from 'react';

export default function InfoComponent(props) {
	const {
		classType = 'text-primary',
		message = 'default message',
		fontSize = 15
	} = props;
	return (
		<div>
			<p style={{ fontSize: fontSize }} className={classType}>
				{message}
			</p>
		</div>
	);
}
