import React, { Component } from 'react';
import DateandTime from './../Transport/DateandTime';
import DatePicker from 'react-date-picker';
import * as AdminActions from '../../Actions/getAllAdmin';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import setClassSectionGradeReducer from '../../Reducers/setClassSectionGradeReducer';

const GradeWithClass = [
	{ className: 'pre-nursery', gradeId: '1', gradeName: 'pre-nursery' },
	{ className: 'nursery', gradeId: '2', gradeName: 'nursery' },
	{ className: 'one', gradeId: '3', gradeName: 'primary' }
];
class WidthdrawDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			gridNum: 4
		};
	}

	render() {
		const {
			onHandleTextChange,
			stateData,
			classData,
			onChangeDate
		} = this.props;
		let getData = stateData && stateData.dummySample && stateData.dummySample;

		return (
			<div>
				<section class="mb-4">
					<DateandTime />
					<div
						class="row"
						style={{
							justifyContent: 'left',
							textAlign: 'left'
						}}
					>
						<div class="col-md-9 mb-md-0 mb-5">
							<form
								id="contact-form"
								name="contact-form"
								// onSubmit={this.onHandleSubmit}
								noValidate
							>
								{/*<div class="row">*/}
								{/*<div class="col-md-12">*/}
								{/*<div class="md-form mb-0">*/}
								{/*<DateandTime/>*/}
								{/*</div>*/}
								{/*</div>*/}
								{/*</div>*/}
								<br />
								<div class="row">
									<div class="col-md-12">
										<div class="md-form mb-0">
											<label for="name" class="">
												<i
													className="fas fa-house animated fadeIn"
													style={{ marginRight: 4 }}
												/>
												Leaving Date
											</label>
											<br />
											<DatePicker
												style={{ borderTop: 0, borderLeft: 0, borderRight: 0 }}
												onChange={e => onChangeDate(e, 'dateLeaving')}
												value={new Date(stateData.dateLeaving)}
											/>
										</div>
									</div>
								</div>
								<div className={'row'}>
									<div class="col-md-12">
										<div class="md-form mb-0">
											<label for="email" class="">
												{/*<i*/}
												{/*style={{ marginRight: 4 }}*/}
												{/*className="fas fa-university animated fadeIn"*/}
												{/*/>*/}
												Class Left
											</label>
											<br />
											<select
												style={{
													borderTop: 0,
													borderLeft: 0,
													borderRight: 0,
													boxShadow: 'none',
													width: '60%'
												}}
												onChange={e => onHandleTextChange(e)}
												class="custom-select"
												name="classLeft"
												value={stateData.classLeft}
											>
												<option selected={true}>Class Name...</option>
												{this.props.ClassData &&
													this.props.ClassData.classes.map((item, index) => (
														<option value={item.classId} key={index}>
															{item.className}
														</option>
													))}
											</select>
										</div>
									</div>
								</div>
								<div className={'row'}>
									<div class="col-md-12">
										<div class="md-form mb-0">
											<label for="name" class="">
												<i
													className="fas fa-house animated fadeIn"
													style={{ marginRight: 4 }}
												/>
												Reason
											</label>
											<textarea
												style={{
													borderTop: 0,
													borderLeft: 0,
													borderRight: 0,
													boxShadow: 'none',
													width: '60%'
												}}
												onChange={e => onHandleTextChange(e)}
												value={stateData.reason}
												name={'reason'}
												type="text"
												class="form-control"
												id="exampleInputEmail1"
												placeholder="Reason"
											></textarea>
										</div>
									</div>
								</div>

								<br />
								<br />
								{/*<div class="text-center text-md-center">*/}
								{/*<button class="btns">Add</button>*/}
								{/*</div>*/}
							</form>
							<div class="status" />
						</div>
					</div>
				</section>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		ClassData: state.setClassSectionGradeReducer.Data
	};
}
export default connect(
	mapStateToProps,
	null
)(WidthdrawDetail);
