import React, { Component } from "react";
import $ from "jquery";
import RenderDynamicRows from "../TextBoxesForFees";
import ThemeContext from "../../../context/themeContext/ThemeContext";

class MultipleBilling extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  btnTechHover = () => {
    $(document).ready(function () {
      $("#addBtn").hover(
        function () {
          $(this).css(
            "background",
            "linear-gradient(to bottom left, #01425e 5%, #01ac8a 87%)"
          );
          $(this).css("border-color", "white");
        },
        function () {
          $(this).css("background", "#0A4F5E");
        }
      );
    });
  };
  render() {
    const {
      onHandleTextChange,
      stateData,
      updateSpecialArrObjOnChange,
      onClickAddItem,
      onClickDeleteItem,
    } = this.props;
    return (
      <div>
        <h3 class="h1-responsive font-weight-bold text-left my-4">
          Add Multiple Billing
        </h3>
        <ThemeContext.Consumer>
          {(context) => (
            <div style={{ width: "100%" }}>
              <table class="table table-hover">
                <thead
                  style={{
                    background: context.themeColors.primaryColor,
                    color: "white",
                  }}
                >
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Payment Method</th>
                    <th scope="col">Description</th>
                  </tr>
                </thead>
                <tbody>
                  {stateData &&
                    stateData.VoucherDescriptions.map((item, index) => (
                      <tr key={index}>
                        <th scope={"row"}>{index + 1}</th>
                        <RenderDynamicRows
                          arrName={"VoucherDescriptions"}
                          updateSpecialArrObjOnChange={
                            updateSpecialArrObjOnChange
                          }
                          rowIndex={index}
                          onHandleTextChange={onHandleTextChange}
                          stateData={stateData}
                          itemObj={item}
                        />
                      </tr>
                    ))}
                </tbody>
              </table>
              <div className={"row"} style={{ justifyContent: "flex-end" }}>
                <div>
                  <button
                    id={"addBtn"}
                    onMouseEnter={this.btnTechHover()}
                    onClick={() =>
                      onClickAddItem("VoucherDescriptions", {
                        amount: "",
                        paymentMethod: "",
                        description: "",
                      })
                    }
                    style={{
                      background: "#0A4F5E",
                      color: "#FFFFFF",
                      borderRadius: 100,
                    }}
                    type="button"
                    class="btn"
                  >
                    <i class="fas fa-plus" />
                  </button>
                </div>
                <div style={{ marginLeft: 8 }}>
                  <button
                    onClick={() => onClickDeleteItem("VoucherDescriptions")}
                    style={{ color: "#FFFFFF", borderRadius: 100 }}
                    type="button"
                    class="btn btn-danger"
                  >
                    <i class="far fa-trash-alt" />
                  </button>
                </div>
              </div>
            </div>
          )}
        </ThemeContext.Consumer>
        <br />
        <br />
        <br />
        {/*<div class="text-center text-md-center">*/}
        {/*<button class="btns">Add</button>*/}
        {/*</div>*/}
      </div>
    );
  }
}

export default MultipleBilling;
