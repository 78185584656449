import React, { Component } from 'react';
import DateandTime from '../Transport/DateandTime';

class Vacancy extends Component {
	constructor(props) {
		super(props);
		this.state = {
			AdminName: '',
			AdminType: '',
			Qualification: '',
			Experience: '',
			NoPosition: '',
			LastDateOfSubmission: '',
			Status: '',
			DepartmentName: '',
			PostName: '',
			CampusName: ''
		};
	}
	onHandleTextChange = e => {
		console.log(e.target.value);
		this.setState({
			[e.target.name]: e.target.value
		});
	};
	onHandleSubmit = e => {
		e.preventDefault();
		console.log(this.state);
	};
	render() {
		return (
			<div>
				<section class="mb-4">
					<h2 class="h1-responsive font-weight-bold text-center my-4">
						Add Vacancy
					</h2>

					<p class="text-center w-responsive mx-auto mb-5" />

					<div
						class="row"
						style={{
							justifyContent: 'center',
							textAlign: 'center'
						}}
					>
						<div
							class="col-md-9 mb-md-0 mb-5"
							style={{
								border: 'solid',
								borderColor: '#01ac8a',
								padding: 40,
								borderRadius: 15,
								width: 'auto'
							}}
						>
							<form id="contact-form" name="contact-form">
								<div class="row">
									<div class="col-md-12">
										<div class="md-form mb-0">
											<DateandTime />
										</div>
									</div>
								</div>
								<br />
								<div class="row">
									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="name" class="">
												Admin Name
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												type="text"
												name="AdminName"
												class="form-control"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="name" class="">
												{/* <i
                          className="fas fa-user-alt animated fadeIn"
                          style={{ marginRight: 8 }}
                        /> */}
												Admin Type
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												type="text"
												name="AdminType"
												class="form-control"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="name" class="">
												{/* <i
                          className="fas fa-user-graduate"
                          style={{ marginRight: 8 }}
                        /> */}
												Qualification
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												placeholder="Qualification"
												type="text"
												name="Qualification"
												class="form-control"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="name" class="">
												{/* <i className="fas fa-key" style={{ marginRight: 8 }} /> */}
												Experience
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												placeholder="Experience"
												type="text"
												name="Experience"
												class="form-control"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="name" class="">
												{/* <i className="fas fa-key" style={{ marginRight: 8 }} /> */}
												No Position
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												placeholder="No Position"
												type="text"
												name="NoPosition"
												class="form-control"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="name" class="">
												{/* <i
                          className="fas fa-calendar-day"
                          style={{ marginRight: 8 }}
                        /> */}
												Last Date Of Submission
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												placeholder="Last Date Of Submission"
												type="text"
												name="LastDateOfSubmission"
												class="form-control"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="name" class="">
												{/* <i
                          className="fas fa-shield-alt"
                          style={{ marginRight: 8 }}
                        /> */}
												Status
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												placeholder="Status"
												type="text"
												name="Status"
												class="form-control"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												{/* <i
                          style={{ marginRight: 8 }}
                          className="fas fa-building animated fadeIn"
                        /> */}
												Department Name
											</label>
											<select
												onChange={this.onHandleTextChange}
												class="custom-select"
												name="DepartmentName"
												style={{ borderColor: '#01AC8A' }}
											>
												<option selected>Names</option>
												<option value="1">Main</option>
												<option value="2">City</option>
												<option value="3">Shadow</option>
											</select>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												{/* <i
                          style={{ marginRight: 8 }}
                          className="fas fa-user-plus animated fadeIn"
                        /> */}
												Post Name
											</label>
											<select
												onChange={this.onHandleTextChange}
												class="custom-select"
												style={{ borderColor: '#01AC8A' }}
												name="PostName"
											>
												<option selected>Names</option>
												<option value="1">Post1</option>
												<option value="2">Post2</option>
												<option value="3">Post3</option>
											</select>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												{/* <i
                          style={{ marginRight: 4 }}
                          className="fas fa-building animated fadeIn"
                        /> */}
												Campus Name
											</label>
											<select
												onChange={this.onHandleTextChange}
												class="custom-select"
												name="CampusName"
												style={{ borderColor: '#01AC8A' }}
											>
												<option selected>Names</option>
												<option value="1">Main</option>
												<option value="2">City</option>
												<option value="3">Shadow</option>
											</select>
										</div>
									</div>
								</div>
								<div class="text-center text-md-center">
									<br />
									<br />
									<button onClick={this.onHandleSubmit} class="btns">
										Add
									</button>
								</div>
							</form>

							<div class="status" />
						</div>
					</div>
				</section>
			</div>
		);
	}
}

export default Vacancy;
