import moment from 'moment';
import React from 'react';
import DatePicker from 'react-date-picker';
import TimePicker from '../../../components/TimePicker';

const format = 'h:mm a';

const now = moment()
	.hour(0)
	.minute(0);

const TimeTableStep1 = props => {
	return (
		<div
			style={{
				fontSize: 20,
				boxShadow: '0px 0px 15px #0A4F5E',
				padding: 10,
				borderRadius: 5,
				border: '1px solid #D3D3D3'
			}}
		>
			<div class="row">
				<div class="col-md-12">
					<div style={{ textAlign: 'center' }} class="md-form mb-0">
						<label for="name">
							{/* <i
                className="fas fa-house animated fadeIn"
                style={{ marginRight: 4 }}
              /> */}
							<div style={{ textAlign: 'center' }}>
								<p style={{ fontSize: 30, textAlign: 'center' }}>
									<span
										style={{ position: 'relative', bottom: 25 }}
										class="badge badge-pill badge-dark"
									>
										<p style={{ margin: 'auto', color: '#fff' }}>
											Official Timing
										</p>
									</span>
								</p>
							</div>
							{props.stateData.errors.step1 ? (
								<p
									style={{
										color: 'red',
										fontSize: 17,
										textAlign: 'center',
										position: 'relative',
										bottom: 15
									}}
								>
									Please Fill Properly!
								</p>
							) : (
								''
							)}
						</label>
						<br />

						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								flexWrap: 'wrap',
								justifyContent: 'center',
								position: 'relative',
								bottom: 30
							}}
						>
							<div>
								{/* <div> */}
								<p
									style={{
										margin: 0,
										color: '#000',
										fontSize: 17,
										color: '#000'
									}}
								>
									Start Time
								</p>
								{/* </div> */}

								<div>
									<TimePicker
										className="dateTimePicker"
										showSecond={false}
										placeholder={'Hr-Min-(AM/PM)'}
										name="offSDate"
										defaultValue={null}
										onChange={e => props.onChange(e, 'offSDate')}
										format={format}
										use12Hours

										// inputReadOnly
									/>
								</div>
								<div style={{ marginTop: 10 }}>
									<p
										style={{
											marginLeft: 18,
											margin: 0,
											color: '#000',
											fontSize: 17,
											position: 'relative',
											top: 10
										}}
									>
										Valid Duration:
									</p>
									<div style={{ position: 'relative', left: 0, top: 13 }}>
										<DatePicker
											style={{ borderRadius: 5 }}
											value={new Date(props.stateData.validDuration)}
											onChange={e => props.onChangeDate(e, 'validDuration')}
										/>
									</div>
								</div>
							</div>
							<div style={{ margin: 25 }}>-</div>
							<div>
								<p style={{ margin: 0, color: '#000', fontSize: 17 }}>
									End Time
								</p>
								<div>
									{/* <DatePicker
                            style={{ borderTop: 0, borderLeft: 0, borderRight: 0 }}
                          //  onChange={(e)=>onChangeDate(e,'dateLeaving')}
                          //  value={new Date(stateData.dateLeaving)}
                          /> */}
									<TimePicker
										showSecond={false}
										defaultValue={null}
										name="Edate"
										placeholder={'Hr-Min-(AM/PM)'}
										onChange={e => props.onChange(e, 'offEDate')}
										format={format}
										use12Hours
										inputReadOnly
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* <button class="btn btn-primary" onClick={() => this.props.stepperFun()}>Next</button> */}
		</div>

		// </div>
	);
};
export default TimeTableStep1;
