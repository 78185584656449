import * as actionTypes from '../actionTypes';

export function setAllStockUsageInRedux(allData) {
	return {
		type: actionTypes.GET_ALL_STOCK_USAGE,
		payload: allData
	};
}
export function getItemsForStockUsageBarcode(allData) {
	return {
		type: actionTypes.SET_ALL_ITEMS_NAME_INTO_ADD_STOCK_USAGE_BARCODE,
		payload: allData
	};
}
export function setPartialStockUsageInRedux(allData) {
	return {
		type: actionTypes.GET_PARTIAL_STOCK_USAGE,
		payload: allData
	};
}
export function getItemsForStockUsage(allData) {
	return {
		type: actionTypes.SET_ALL_ITEMS_NAME_INTO_ADD_STOCK_USAGE_SELECT,
		payload: allData
	};
}
export function getStudentForStockUsage(allData) {
	return {
		type: actionTypes.SET_ALL_STUDENT_NAME_INTO_ADD_STOCK_USAGE_SELECT,
		payload: allData
	};
}
export function getStaffForStockUsage(allData) {
	return {
		type: actionTypes.SET_ALL_STAFF_NAME_INTO_ADD_STOCK_USAGE_SELECT,
		payload: allData
	};
}
export function addStockUsage(Data) {
	return {
		type: actionTypes.ADD_STOCK_USAGE,
		payload: Data
	};
}
export function updateStockUsageFlag(setFlag) {
	return {
		type: actionTypes.SET_LOADER_FLAG_STOCK_USAGE_ADD,
		payload: setFlag
	};
}
export function updateStockUsage(data) {
	return {
		type: actionTypes.EDIT_STOCK_USAGE_RECORD,
		payload: data
	};
}
export function deleteStockUsage(id) {
	return {
		type: actionTypes.DELETE_STOCK_USAGE,
		payload: id
	};
}
