import React, { useContext } from "react";
import themeContext from "../../../context/themeContext/ThemeContext";

const StepperList = (props) => {
  const themeColors = useContext(themeContext);
  const { mainRef, Stepes } = props;
  // const { stepperId } = props.props;

  const splitingData = (item) => {
    let getSplittingObj = item.split(" ");
    return getSplittingObj[1];
  };
  return (
    <div ref={mainRef}>
      <div class="bs-stepper-header">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
            paddingRight: 20,
          }}
        >
          {Stepes.map((item, index) => (
            <div>
              <div class="step" data-target={`#test-l-${splitingData(item)}`}>
                <button class="step-trigger">
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div>
                      <span
                        class="bs-stepper-circle"
                        style={{
                          backgroundColor: themeColors.themeColors.primaryColor,
                        }}
                      >
                        {splitingData(item)}
                      </span>
                    </div>
                    <div>
                      <span
                        class="bs-stepper-label"
                        style={{ color: "#A0A6AB" }}
                      >
                        {item}
                      </span>
                    </div>
                  </div>
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default StepperList;
