import React from 'react';
import Picture from '../../utils/general/index';
export default function CardsForAttendance(props) {
	console.log(props);
	debugger;
	const {
		excuseAbsents,
		nonExcusedAbsents,
		noOfDays,
		totalAbsents,
		totalLates,
		totalPresent,
		imagePath,
		studentName,
		className,
		section,
		subject
	} = props;

	return (
		// <div>
		// 	<div class="card mb-3 shadowForBox" style={{ maxWidth: '540px;' }}>
		// 		<div class="row no-gutters">
		// 			<div class="col-md-2">
		// 				<img
		// 					src={Picture.newconversionOfImageUrl(imagePath)}
		// 					class="card-img"
		// 					alt="..."
		// 					style={{ maxWidth: '540px;', height: 180 }}
		// 				/>
		// 			</div>
		// 			<div class="col-md-10">
		// 				<div class="card-body">
		// 					<h5 class="card-title">Student Information:</h5>
		// 					<div class="col-md-5">
		// 						<lable class="font-weight-bold">No Of Days:</lable>
		// 						<label style={{ marginLeft: 5 }}>{noOfDays}</label>
		// 					</div>
		// 					<div class="col-md-5 text-left">
		// 						<lable class="font-weight-bold">Excused Absents:</lable>
		// 						<label style={{ marginLeft: 5 }}>{excuseAbsents}</label>
		// 					</div>
		// 					<div class="col-md-5">
		// 						<lable class="font-weight-bold">Non Excused Absents:</lable>
		// 						<label style={{ marginLeft: 5 }}>{nonExcusedAbsents}</label>
		// 					</div>
		// 					<div class="col-md-5">
		// 						<lable class="font-weight-bold">Absents:</lable>
		// 						<label style={{ marginLeft: 5 }}>{totalAbsents}</label>
		// 					</div>
		// 					<div class="col-md-5">
		// 						<lable class="font-weight-bold">Presents:</lable>
		// 						<label style={{ marginLeft: 5 }}>{totalPresent}</label>
		// 					</div>
		// 					<div class="col-md-5">
		// 						<lable class="font-weight-bold">Lates:</lable>
		// 						<label style={{ marginLeft: 5 }}>{totalLates}</label>
		// 					</div>
		// 				</div>
		// 			</div>
		// 		</div>
		// 	</div>
		// </div>

		<div class="card shadowForBox">
			<div class="card-body">
				<div class="row">
					<div class="col-md-4"></div>
					<div class="col-md-4 text-center">
						<h4 class="card-title text-center" style={{ color: '#01ac8a' }}>
							Student Information
						</h4>
						<label
							class="text-center font-weight-bold"
							style={{ fontSize: 18 }}
						>
							{studentName}
						</label>
					</div>
					<div class="col-md-4 text-right">
						{' '}
						<lable class="font-weight-bold">Class:</lable>
						<label style={{ marginLeft: 5, fontSize: 18 }}>
							{className} - {section}
						</label>
					</div>
				</div>
				<hr />
				{/* <Snackbar msg={this.state.msg} backColor={this.state.backColor} /> */}

				<React.Fragment>
					<hr />
					<div class="row" style={{ float: 'left' }}>
						<div class="col-md-2">
							<img
								src={Picture.newconversionOfImageUrl(imagePath)}
								class="card-img"
								alt="..."
								style={{ width: 180, height: 180 }}
							/>
						</div>
					</div>

					<div class="row justify-content-center">
						<div class="col-md-4">
							<lable class="font-weight-bold">Total Days:</lable>
							<label style={{ marginLeft: 5 }}>{noOfDays}</label>
						</div>
						<div class="col-md-4">
							<lable class="font-weight-bold">Excused:</lable>
							<label style={{ marginLeft: 5 }}>{excuseAbsents}</label>
						</div>

						<div class="col-md-4">
							<lable class="font-weight-bold">Non Excused :</lable>
							<label style={{ marginLeft: 5 }}>{nonExcusedAbsents}</label>
						</div>
					</div>
					<br />
					<br />
					<div class="row justify-content-center">
						<div class="col-md-4">
							<lable class="font-weight-bold">Lates:</lable>
							<label style={{ marginLeft: 5 }}>{totalLates}</label>
						</div>
						<div class="col-md-4">
							<lable class="font-weight-bold">Presents:</lable>
							<label style={{ marginLeft: 5 }}>{totalPresent}</label>
						</div>
						<div class="col-md-4">
							<lable class="font-weight-bold">Absents:</lable>
							<label style={{ marginLeft: 5 }}>{totalAbsents}</label>
						</div>
					</div>
				</React.Fragment>
			</div>
		</div>
	);
}
