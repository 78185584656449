import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import DropDownsForData from '../DescriptiveReportModule/TermClassSubjectSelect';
import Moment from 'moment';
import DatePicker from 'react-date-picker';
import $ from 'jquery';
import ThemeContext from "../../context/themeContext/ThemeContext";
class StaffEventView extends Component {
	constructor(props) {
		super(props);
		this.state = {
			checking: [],
			SkillReports: [],
			adminData: [],
			enabled: false,
			id: null,
			select: [],
			date: '',
			studentName: '',
			isLoading: true,
			disablepage: false,
			disableBPage: true,
			currentPage: 1,
			todosPerPage: 10,
			pageCounter: 10,
			msg: '',
			errorMsg: '',
			increament: 1,
			selectClassData: '',
			classId: '',
			sectionId: '',
			sections: [],
			subjects: [],
			session: [],
			sessionId: '',
			subjectId: '',
			staffId: '',
			term: '',
			btnLoad: false,
			exist: false,
			checking: [],
			searchCheck: true,
			multiple: [],
			errorText: '',
			badRequest: false,
			present: '',
			absent: '',
			excusedAbsent: '',
			excusedNonAbsent: '',
			totalLate: ''
		};
	}

	onSelectChange = e => {
		console.log(e.target.value);
		let result = JSON.parse(e.target.value);
		const sections = this.state.selectClassData.staffClassSection;
		let check = sections.filter(item => item.classId == result.classId);
		console.log(check);

		this.setState({
			sections: check,
			staffId: check[0].staffId,
			staffName: check[0].staffName,
			classId: result.classId,
			className: result.className
		});
	};

	onSectionChange = e => {
		console.log(e.target.value);
		let result = JSON.parse(e.target.value);
		const sections = this.state.selectClassData.staffClassSection;
		let check2 = sections.filter(
			item =>
				item.classId == this.state.classId && item.sectionId == result.sectionId
		);
		this.setState({
			subjects: check2,
			sectionId: result.sectionId,
			sectionName: result.section
		});
		console.log(this.state.staffId);
	};

	onSessionChange = e => {
		console.log(e.target.value);
		let result = JSON.parse(e.target.value);
		this.setState({
			sessionId: result.sessionId,
			sessionName: result.currentSession
		});
		console.log(this.state);
	};

	onSubjectChange = e => {
		console.log(e.target.value);
		let result = JSON.parse(e.target.value);
		this.setState({
			subjectId: result.subjectId,
			subjectName: result.subjectName
		});
	};

	componentDidUpdate() {
		$('.tooltip').tooltip('hide');

		if (
			this.state.msg ||
			this.state.errorMsg ||
			this.state.exist ||
			this.state.badRequest
		) {
			setTimeout(() => {
				this.setState({
					msg: false,
					errorMsg: false,
					exist: false,
					badRequest: false
				});
			}, 3000);
		}
	}

	componentDidMount() {
		$(document).ready(function() {
			$('[data-toggle="tooltip"]').tooltip();
		});
		this.props.sharedActions
			.getDataWithoutDispatch(
				`/api/DescriptiveReport?userName=${this.props.users.unique_name}`
			)
			.then(success => {
				this.setState({
					selectClassData: success
				});
				console.log(success);
			})
			.catch(error => {
				console.log(error);
			});
		this.props.sharedActions
			.getDataWithoutDispatch('/api/Session')
			.then(success => {
				this.setState({
					session: success
				});
			})
			.catch(error => {});
	}

	onSearchStudent = e => {
		this.setState({
			btnLoad: true
		});
		this.props.sharedActions
			.getDataWithoutDispatch(
				`/api/StudentEvents?pageNumber=1&pageSize=10&classId=${this.state.classId}&sessionId=${this.state.sessionId}`
			)
			.then(success => {
				debugger;
				this.setState({
					btnLoad: false,
					searchCheck: false,
					adminData: success.eventList,
					checking: success.paginationMetadata
				});
			})
			.catch(error => {
				this.setState({
					btnLoad: false,
					searchCheck: true
				});
				console.log(error);
			});
	};

	onTakeItem = itemId => {
		this.setState({
			modalData: []
		});
		const { adminData } = this.state;
		let getData = adminData.filter(item => item.skillReportId === itemId);

		this.setState({
			modalData: getData,
			efforts: getData[0].efforts,
			average: getData[0].average,
			academicProgress: getData[0].academicProgress,
			id: getData[0].skillReportId,
			studentName: getData[0].stduentName,
			participation: getData[0].participation
		});
	};

	onHandleTextNumber = e => {
		console.log(e.target.value);
		if (e.target.value <= 5) {
			this.setState({
				[e.target.name]: e.target.value
			});
		}
		console.log(this.state.effort);
	};
	onHandleText = e => {
		this.setState({
			[e.target.name]: e.target.value
		});
	};

	onChangeText = e => {
		console.log(e.target.value);
		this.setState({
			[e.target.name]: e.target.value
		});
	};

	changeDate = (date, state) => {
		this.setState({
			[state]: date
		});
		console.log(date);
	};

	PrintContent = el => {
		var restorepage = document.body.innerHTML;
		var Printcontent = document.getElementById(el).innerHTML;
		document.body.innerHTML = Printcontent;
		window.print();
		document.body.innerHTML = restorepage;
	};
	paginationRequest = number => {
		this.setState({
			searchCheck: true
		});
		console.log(number);

		this.props.sharedActions
			.getDataWithoutDispatch(
				`/api/StudentEvents?pageNumber=${number}&pageSize=10&classId=${this.state.classId}&sessionId=${this.state.sessionId}`
			)
			.then(success => {
				this.setState({
					adminData: success.eventList,
					checking: success.paginationMetadata,
					searchCheck: false
				});
			})
			.catch(err => {
				this.setState({
					isLoading: false
				});

				console.log(err);
			});
	};
	render() {
		const {
			classId,
			sectionId,
			sessionId,
			subjectId,
			term,
			adminData,
			date
		} = this.state;

		const { session } = this.state.session;
		const { classes } = this.state.selectClassData;

		const Loader = (
			<ThemeContext.Consumer>
			{(context) => (
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<label style={{ color: 'red' }}>No Data</label>
			</div>
			                  )}
							  </ThemeContext.Consumer>
		);

		let pageNumbers = [];
		for (
			let i = 1;
			i <=
			Math.ceil(this.state.checking.totalCount / this.state.checking.pageSize);
			i++
		) {
			pageNumbers.push(i);
		}

		const renderPageNumbers = pageNumbers.map((number, index) => {
			return (
				<ThemeContext.Consumer>
				{(context) => (
				<li
					class={`page-item ${
						this.state.checking.currentPage === number ? 'active' : ''
					} `}
				>
					<a
						key={number}
						id={number}
						onClick={() => this.paginationRequest(number)}
						class="page-link "
						href="#"
					>
						{number}
					</a>
				</li>
				                  )}
								  </ThemeContext.Consumer>
			);
		});
		const renderTodos = adminData.map((item, index) => {
			return (
				<ThemeContext.Consumer>
				{(context) => (
				<tr key={index}>
					<th scope="row" style={{ padding: 10 }}>
						{index + 1}
					</th>
					<td style={{ padding: 10 }}>{item.month}</td>
					<td style={{ padding: 10 }}>{item.eventDate}</td>
					<td style={{ padding: 10 }}>{item.eventTime}</td>
					<td style={{ padding: 10 }}>{item.className}</td>
					<td style={{ padding: 10 }}>{item.subject}</td>
					<td style={{ padding: 10 }}>{item.accountType}</td>
					<td style={{ padding: 10 }}>{item.accountId}</td>
					<td style={{ padding: 10 }}>{item.description}</td>
				</tr>
				                  )}
								  </ThemeContext.Consumer>
			);
		});
		const MainContent = (
			<ThemeContext.Consumer>
      {(context) => (
			<div>
				<div id="div1">
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<span className="customBadge" style={{ fontSize: 14 }}>
							Page: {this.state.checking.currentPage}
						</span>

						<span className="customBadge" style={{ fontSize: 14 }}>
							Total Page: {this.state.checking.totalPages}
						</span>
					</div>
					<div className="table-responsive">
						<table class="table text-center table-bordered table-striped">
							<thead style={{ background: context.themeColors.primaryColor, color: 'white' }}>
								<tr>
									<th scope="col" style={{ padding: 2 }}>
										#
									</th>
									<th scope="col" style={{ padding: 2 }}>
										Month
									</th>
									<th scope="col" style={{ padding: 2 }}>
										Date
									</th>
									<th scope="col" style={{ padding: 2 }}>
										Time
									</th>
									<th scope="col" style={{ padding: 2 }}>
										Class
									</th>

									<th scope="col" style={{ padding: 2 }}>
										Subject/Event
									</th>
									<th scope="col" style={{ padding: 2 }}>
										Coordinator
									</th>
									<th scope="col" style={{ padding: 2 }}>
										Account
									</th>
									<th scope="col" style={{ padding: 2 }}>
										Description
									</th>
								</tr>
							</thead>
							<tbody>{renderTodos}</tbody>
						</table>
					</div>
					<br />
				</div>
			</div>
			                  )}
							  </ThemeContext.Consumer>
		);
		return (
			<ThemeContext.Consumer>
			{(context) => (
			<div>
				{this.state.badRequest ? (
					<p style={{ color: 'red', textAlign: 'center' }}>
						{this.state.errorText}
					</p>
				) : (
					''
				)}
				<div className="page-header">
					<div class="row">
						<div class="col-md-3"></div>
						<div class="col-md-6">
							<h4 class="generalHead" style={{background: context.themeColors.primaryColor}}>Staff Events List</h4>
						</div>
						<div class="col-md-3 text-right">
							<nav aria-label="Page navigation example">
								<ul style={{ color: context.themeColors.primaryColor }} class="pagination">
									{renderPageNumbers}
								</ul>
							</nav>
						</div>
					</div>
				</div>
				<div class="row justify-content-center">
					<div class="col-md-3 text-center" style={{ marginTop: 20 }}>
						<div class="md-form mb-0">
							<label for="email" class="font-weight-bold">
								Class
							</label>
							<select
								onChange={this.onSelectChange}
								class="custom-select"
								name="className"
							>
								<option selected={this.state.msg ? true : false}>
									Select Class...
								</option>
								{classes &&
									classes.map((item, index) => (
										<option key={index} value={`${JSON.stringify(item)}`}>
											{item.className}
										</option>
									))}
							</select>
						</div>
					</div>

					<div class="col-md-3 text-center" style={{ marginTop: 20 }}>
						<div class="md-form mb-0">
							<label for="email" class="font-weight-bold">
								Session
							</label>
							<select
								onChange={this.onSessionChange}
								class="custom-select"
								name="sessionId"
							>
								<option selected={this.state.msg ? true : false}>
									Select Session...
								</option>
								{session &&
									session.map((item, index) => (
										<option key={index} value={`${JSON.stringify(item)}`}>
											{item.currentSession}
										</option>
									))}
							</select>
						</div>
					</div>
				</div>
				<br />
				<div class="col-md-12 text-center">
					<button
						type="submit"
						disabled={classId !== '' && sessionId !== '' ? false : true}
						style={{
							backgroundColor:
								classId !== '' && sessionId !== '' ? context.themeColors.primaryColor : '#d2d2d2',
							border: 'none',
							fontSize: 20,
							borderRadius: 4,
							color: 'white'
						}}
						onClick={this.onSearchStudent}
					>
						Search
						<i
							style={{ color: 'white', marginLeft: 4 }}
							class="fas fa-search"
						></i>
						<span
							style={{ marginBottom: 5 }}
							class={`${
								this.state.btnLoad ? 'spinner-border spinner-border-sm' : ''
							}`}
						></span>
					</button>
				</div>
				<br />
				{this.state.searchCheck ? Loader : MainContent}
			</div>
			                  )}
							  </ThemeContext.Consumer>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch)
	};
}
function mapStateToProps(state) {
	return {
		users: state.setUserReducer.users
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(StaffEventView);
