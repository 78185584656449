import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import * as SyllabusAction from '../../Actions/Syllabus';
import * as sharedActions from '../../Actions/sharedActions';
import DateandTime from '../Transport/DateandTime';
import DatePicker from 'react-date-picker';
import $ from 'jquery';
import '../Admin/Add_Admin_Component/AddGm.css';
import EditorComponent from './EditorComponent';
import ViewEditor from './ViewEditorComponent';
import { Redirect } from 'react-router-dom';

class AddSyllabus extends Component {
	constructor(props) {
		super(props);
		this.state = {
			select: [],
			sessions: [],
			Sections: [],
			Subject: '',
			classId: '',
			className: '',
			sectionName: '',
			sessionName: '',
			sectionId: '',
			sessionId: '',
			examType: '',
			chooseFile: '',
			email: '',
			autoCheck: false,
			errorMsg: false,
			msg: false,
			redirect: false
		};
	}
	setRedirect = () => {
		this.setState({
			redirect: true
		});
	};
	renderRedirect = () => {
		if (this.state.redirect) {
			return (
				<Redirect
					to={{
						pathname: '/dashboard/EditorComponent',
						state: { id: this.state }
					}}
				/>
			);
		}
	};
	componentDidUpdate() {
		if (this.state.msg || this.state.errorMsg) {
			setTimeout(() => {
				this.setState({
					msg: false,
					errorMsg: false
				});
			}, 3000);
		}
	}
	static getDerivedStateFromProps(nextProps, prevState) {
		if (
			prevState.select !== nextProps.selectData ||
			prevState.sessions !== nextProps.selectSession
		) {
			return {
				select: nextProps.selectData,
				sessions: nextProps.selectSession
			};
		}
		return null;
	}
	componentDidMount() {
		$('#chooseFile').bind('change', function() {
			var filename = $('#chooseFile').val();
			if (/^\s*$/.test(filename)) {
				$('.file-upload').removeClass('active');
				$('#noFile').text('No file chosen...');
			} else {
				$('.file-upload').addClass('active');
				$('#noFile').text(filename.replace('C:\\fakepath\\', ''));
			}
		});
		this.props.sharedActions
			.gAllData(
				'/api/classSectionGradeTeacher',
				this.props.SyllabusAction.getClassGradeSubjectForSyllabus
			)
			.then(success => {
				console.log(success);
			})
			.catch(error => {
				console.log(error);
			});
		this.props.sharedActions
			.gAllData(
				'/api/Session',
				this.props.SyllabusAction.getSessionsForSyllabus
			)
			.then(success => {})
			.catch(error => {});
	}
	onSelectClassChange = e => {
		console.log(e.target.value);

		const Grade = this.state.select.grade;
		const Subject = this.state.select.subject;
		const Section = this.state.select.sections;
		const Staff = this.state.select.staff;
		let check = Grade.filter(item => item.classId == e.target.value);

		console.log(check);
		this.setState({
			gradeName: check[0] && check[0].gradeName,
			className: check[0] && check[0].className,
			classId: e.target.value,
			gradeId: check[0] && check[0].gradeId,
			email: true
		});
		let checkTwo = Subject.filter(item => item.classId == e.target.value);

		this.setState({
			Subject: checkTwo
		});
		let checkThird = Staff.filter(item => item.classId == e.target.value);

		this.setState({
			Staff: checkThird
		});
		let checkFourth = Section.filter(item => item.classId == e.target.value);

		this.setState({
			Sections: checkFourth
		});
		console.log('asdasd', this.state.className);
	};
	onSectionChange = e => {
		console.log(e.target.value);
		let result = JSON.parse(e.target.value);

		this.setState({
			sectionId: result.sectionId,
			sectionName: result.section
		});
		console.log(this.state.sectionName);
	};
	onSelectSessionChange = e => {
		console.log(e.target.value);
		let result = JSON.parse(e.target.value);

		this.setState({
			sessionId: result.sessionId,
			sessionName: result.currentSession
		});
		console.log(this.state.sessionName);
	};
	onHandleSubmit = e => {
		const { sectionId, sessionId, examType, classId, chooseFile } = this.state;
		e.preventDefault();
		if (
			Object.is(examType, '') ||
			Object.is(classId, '') ||
			Object.is(sessionId, '') ||
			Object.is(sectionId, '') ||
			Object.is(chooseFile, '')
		) {
			this.setState({
				error: true,
				errorMsg: true
			});
		} else {
			console.log(this.state);
			const {
				examType,
				subjectPaper,
				examId,
				gradeId,
				subjectId,
				sessionId,
				classId,
				examTime,
				examDate,
				Duration,
				chooseFile,
				TotalMarks
			} = this.state;
			let file = this.state.chooseFile;
			let accountType = this.state.accountType;
			let accountId = this.state.accountId;
			accountId = this.props.userData.unique_name;
			accountType = this.props.userData.role;
			let data = new FormData();
			data.append('file', file);
			data.append('sectionId', sectionId);
			data.append('sessionId', sessionId);
			data.append('classId', classId);
			data.append('termType', examType);
			data.append('accountId', accountId);
			data.append('accountType', accountType);
			console.log('DATA Part', data);

			this.props.sharedActions
				.addRecord('/api/Syllabus', data, this.props.SyllabusAction.addSyllabus)
				.then(success => {
					this.setState({
						msg: true,
						errorMsg: false
					});
					// this.props.campusActions.updateCampusFlag(true);
				})
				.catch(err => {
					console.log(err);
				});
		}
		console.log(this.state);
	};
	onHandleTextChange = e => {
		console.log(e.target.value);
		this.setState({
			[e.target.name]: e.target.value
		});
	};
	onAutoCheck = e => {
		this.setState({
			autoCheck: true
		});
	};
	onChangeFile = e => {
		if (e.target.files[0].size < 2000000) {
			this.setState({
				chooseFile: e.target.files[0]
			});
		} else {
			window.alert('Select only 2 MB File and File should be in JPEG and PNG');
		}

		console.log(this.state.chooseFile);
		console.log(e.target.files[0].size);
		console.log(e.target.files[0].type);
	};
	render() {
		console.log('classSectionGrade', this.state.select);
		const { classes } = this.state.select;
		console.log('Classes ka data', classes);
		const { session } = this.state.sessions;
		console.log('Session ka data', session);
		return (
			<div className="container">
				<section class="mb-4">
					<h3 class="h1-responsive font-weight-bold text-center my-4">
						Add Syllabus
					</h3>

					<p class="text-center w-responsive mx-auto mb-5" />

					<div
						class="row"
						style={{
							justifyContent: 'center',
							textAlign: 'center'
						}}
					>
						<div
							class="col-md-9 mb-md-0 mb-5"
							style={{
								border: 'solid',
								borderColor: '#01ac8a',
								padding: 40,
								borderRadius: 15,
								width: 'auto'
							}}
						>
							{this.state.errorMsg ? (
								<p style={{ color: 'red', textAlign: 'center' }}>
									Please fill the form properly
								</p>
							) : (
								''
							)}
							{this.state.msg ? (
								<p style={{ color: 'green', textAlign: 'center' }}>
									Added Successfully!
								</p>
							) : (
								''
							)}
							<form
								onSubmit={this.handleSubmit}
								id="contact-form"
								name="contact-form"
							>
								<div class="row">
									<div class="col-md-12">
										<div class="md-form mb-0">
											<DateandTime />
										</div>
									</div>
								</div>
								<br />
								<div class="row">
									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="name" class="">
												Admin Name
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												type="text"
												name="AdminName"
												class="form-control"
												readOnly={true}
												value={this.props.userData.unique_name}
											/>
										</div>
									</div>

									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="name" class="">
												Admin Type
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												type="text"
												name="AdminType"
												class="form-control"
												readOnly={true}
												value={this.props.userData.role}
											/>
										</div>
									</div>
									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												Class Name
											</label>
											<select
												style={{ borderColor: '#01AC8A' }}
												onChange={this.onSelectClassChange}
												class="custom-select"
												name="staffId"
											>
												<option selected={this.state.msg ? true : false}>
													Choose Name...
												</option>
												{this.state.select.classes &&
													classes.map((item, index) => (
														<option key={index} value={item.classId}>
															{item.className}
														</option>
													))}
											</select>
										</div>
									</div>
									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												Section Name
											</label>
											<select
												style={{ borderColor: '#01AC8A' }}
												onChange={this.onSectionChange}
												class="custom-select"
												name="staffId"
											>
												<option selected={this.state.msg ? true : false}>
													Section Name...
												</option>
												{this.state.Sections.map((item, index) => (
													<option key={index} value={`${JSON.stringify(item)}`}>
														{item.section}
													</option>
												))}
											</select>
										</div>
									</div>
									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												Sessions
											</label>
											<select
												onChange={this.onSelectSessionChange}
												class="custom-select"
												name="staffId"
												style={{ borderColor: '#01AC8A' }}
											>
												<option selected={this.state.msg ? true : false}>
													Choose Session...
												</option>
												{session &&
													session.map((item, index) => (
														<option
															key={index}
															value={`${JSON.stringify(item)}`}
														>
															{item.currentSession}
														</option>
													))}
											</select>
										</div>
									</div>
									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="name" class="">
												Add Exam
											</label>
											<select
												style={{ borderColor: '#01AC8A' }}
												onChange={this.onHandleTextChange}
												class="custom-select"
												name="examType"
											>
												<option>Choose Exam..</option>
												<option value="1st Terminal">1st Terminal</option>
												<option value="2nd Terminal">2nd Terminal</option>
												<option value="3rd Terminal">3rd Terminal</option>
											</select>
										</div>
									</div>
								</div>
							</form>
							<br />
							<br />
							<div class="row justify-content-center">
								<div class="col-md-4">
									<div class="md-form mb-0">
										<a
											href={`http://linkit.life/api/SyllabusTemplate?classId=${this.state.classId}&sectionId=${this.state.sectionId}&sessionId=${this.state.sessionId}&termType=${this.state.examType}`}
										>
											<button
												// type="submit"
												class="btn"
												disabled={!this.state.Subject[0]}
												style={{
													color: 'white',
													backgroundColor: '#01AC8A'
													// justifyContent: 'center',
													// textAlign: 'center'
												}}
											>
												Generate Syllabus
												<span
													style={{ marginBottom: 5 }}
													class={`${
														this.state.btnLoad
															? 'spinner-border spinner-border-sm'
															: ''
													}`}
												></span>
											</button>
										</a>
									</div>
								</div>
								<div class="col-sm-0">
									<h4>OR</h4>
								</div>
								<div class="col-md-4">
									<div class="md-form mb-0">
										{/* <Link
											to={`/dashboard/EditorComponent/${this.state.sessionId},${this.state.sectionId},${this.state.classId},${this.state.examType}`}
										> */}
										{this.state.classId &&
										this.state.sectionId &&
										this.state.sessionId &&
										this.state.examType &&
										this.state.Subject != ''
											? this.renderRedirect()
											: ''}
										<button
											// type="submit"
											class="btn"
											onClick={this.setRedirect}
											disabled={!this.state.Subject[0]}
											style={{
												color: 'white',
												backgroundColor: '#01425e'
												// justifyContent: 'center',
												// textAlign: 'center'
											}}
										>
											Generate Manually
											<span
												style={{ marginBottom: 5 }}
												class={`${
													this.state.btnLoad
														? 'spinner-border spinner-border-sm'
														: ''
												}`}
											></span>
										</button>
										{/* </Link> */}
									</div>
								</div>
							</div>
							<br />

							<form
								onSubmit={this.onHandleSubmit}
								id="contact-form"
								name="contact-form"
							>
								<div class="row">
									<div class="col-md-auto">
										<h5>Upload Syllabus Here: </h5>
									</div>
									<div class="w-100" />
									<div class="col-md-auto">
										<small style={{ color: 'red' }}>
											*download the syllabus template from above and upload the
											syllabus in the given format
										</small>
										{/* <br />
											<small style={{ color: 'red' }}>
												*upload the syllabus in the format of downloaded
												template
											</small> */}
									</div>
									<div class="col-md-12">
										<div class="md-form mb-0">
											<div class="file-upload">
												<div class="file-select">
													<div class="file-select-button" id="fileName">
														Choose File
													</div>
													<div class="file-select-name" id="noFile">
														No File Choosen
													</div>
													<input
														type="file"
														name="chooseFile"
														id="chooseFile"
														onChange={this.onChangeFile}
													/>
												</div>
											</div>
										</div>
									</div>
									{this.state.autoCheck ? (
										<ViewEditor subjects={this.state.Subject} />
									) : (
										''
									)}
								</div>
								<br />
								<div class="text-center text-md-center">
									<button type="submit" class="btns">
										Submit
									</button>
								</div>
							</form>
						</div>
					</div>
				</section>
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch),
		SyllabusAction: bindActionCreators(SyllabusAction, dispatch)
	};
}
function mapStateToProps(state) {
	return {
		userData: state.setUserReducer.users,
		selectData: state.setSyllabusReducer.selectStudent,
		selectSession: state.setSyllabusReducer.selectSession
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AddSyllabus);
