import React, { useEffect, useState, useContext } from 'react';
import $ from 'jquery';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import SelectionInput from '../../components/SelectionInput/SelectionInput';
import general from '../../utils/general';
import TextInput from '../../components/TextInput/TextInput';
import Button from '../../components/Button';
import TextArea from '../../components/TextArea';
import FormLayoutWrapper from '../../components/HOC/FormLayoutWrapper';
import httpWithRequiredSetData from '../../hooks/Services/httpWithRequiredSetData';
import Colors from '../../utils/app_constants/colors_constants';
import ThemeContext from "../../context/themeContext/ThemeContext";

const AssignStaffCoordinatorRenew = props => {
	const themeColor = useContext(ThemeContext);

	const [postData, setPostData] = useState([]);
	const [classData, setClassData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [msg, setSnackMsg] = useState('');
	const [state, setState] = useState({
		accountId: props.name,
		accountType: props.role,
		isLoading: '',
		searchStaffId: '',
		errorMsg: '',
		name: '',
		staffId: '',
		postId: '',
		post: '',
		username: '',
		password: '',
		postName: '',
		classId: '',
		backColor: '',
		errorMsgUsername: '',
		errorMsgStaffId: ''
	});

	const [isPostLoading, posts] = httpWithRequiredSetData(
		'/api/DropdownForGradeCoordinator',
		[],
		data => {
			setPostData(data);
		}
	);

	const [isClassLoading, classes] = httpWithRequiredSetData(
		'/api/Student',
		[],
		data => {
			setClassData(data['classes']);
		}
	);

	useEffect(() => {
		if (state['backColor'] !== '') {
			setTimeout(() => {
				setState(prevState => ({
					...prevState,
					backColor: ''
				}));
			}, 3000);
		}
		if (msg || state['errorMsg']) {
			setTimeout(() => {
				setState(prevState => ({
					...prevState,
					errorMsg: false
				}));
			}, 3000);
		}
	});

	const onHandleSelectChange = e => {
		if (e.target.value !== 'Select Class') {
			const target = e.target;
			setState(prevState => ({
				...prevState,
				[target.name]: target.value
			}));
		}
	};

	const onHandlePostChange = e => {
		if (e.target.value !== 'Select Post') {
			let filteredPost = postData.filter(
				item => parseInt(item.postId) === parseInt(e.target.value)
			);
			const target = e.target;
			setState(prevState => ({
				...prevState,
				[target.name]: target.value,
				post: filteredPost[0].postName
			}));
		}
	};

	const onHandleTextChange = e => {
		const target = e.target;
		setState(prevState => ({
			...prevState,
			[target.name]: target.value
		}));
	};

	const onHandleStaffIdChange = e => {
		const target = e.target;
		setState(prevState => ({
			...prevState,
			[target.name]: target.value
		}));

		props.sharedActions
			.getDataWithoutDispatch(
				`/api/GetGradeCoordinatorStaffById?staffId=${e.target.value}`
			)
			.then(success => {
				setState(prevState => ({
					...prevState,
					isLoading: false,
					name: success.staffName,
					staffId: success.staffId,
					postName: success.postFor,
					errorMsgStaffId: ''
				}));
			})
			.catch(error => {
				setState(prevState => ({
					...prevState,
					isLoading: false,
					name: '',
					staffId: '',
					postName: '',
					errorMsgStaffId: true
				}));
			});
	};

	const onSubmitGetStaff = e => {
		e.preventDefault();

		if (state['searchStaffId'] === '') {
			setState(prevState => ({
				...prevState,
				errorMsg: true
			}));
		} else {
			setState(prevState => ({
				...prevState,
				isLoading: true
			}));

			props.sharedActions
				.getDataWithoutDispatch(
					`/api/GetGradeCoordinatorStaffById?staffId=${state['searchStaffId']}`
				)
				.then(success => {
					setState(prevState => ({
						...prevState,
						isLoading: false,
						name: success.staffName,
						staffId: success.staffId,
						postName: success.postFor
					}));
				})
				.catch(error => {
					setState(prevState => ({
						...prevState,
						isLoading: false,
						name: '',
						staffId: '',
						postName: ''
					}));
				});
		}
	};

	const onHandleUsernameChange = e => {
		const target = e.target;
		setState(prevState => ({
			...prevState,
			[target.name]: target.value
		}));

		props.sharedActions
			.getDataWithoutDispatch(
				`/api/CheckManagerUsername/?username=${target.value}`
			)
			.then(success => {
				setState(prevState => ({
					...prevState,
					errorMsgUsername: ''
				}));
			})
			.catch(error => {
				console.log('Not Allowed');

				if (error && error.response && error.response.status == 404) {
					// 404-Error no understanding of 404 Error
					setState(prevState => ({
						...prevState,
						errorMsgUsername: true
					}));
				} else if (error && error.response && error.response.status == 400) {
					// 404-Error no understanding of 404 Error
					setState(prevState => ({
						...prevState,
						errorMsgUsername: true
					}));
				}
			});
	};

	const onSubmit = e => {
		e.preventDefault();
		console.log(state);

		if (
			state['name'] === '' ||
			state['staffId'] === '' ||
			state['postId'] === '' ||
			state['post'] === '' ||
			state['username'] === '' ||
			state['password'] === '' ||
			state['classId'] === ''
		) {
			setState(prevState => ({
				...prevState,
				errorMsg: true
			}));
		} else {
			setIsLoading(true);

			const data = {
				Managers: {
					name: state['name'],
					staffId: state['staffId'],
					postId: state['postId'],
					post: state['post'],
					userName: state['username'],
					password: state['password'],
					accountId: state['accountId'],
					accountType: state['accountType']
				},
				GradeCoordinatorInfoes: {
					classId: state['classId']
				}
			};

			props.sharedActions
				.simpleAddRequest('/api/AssignGradeCoordinators', data)
				.then(success => {
					setIsLoading(false);
					setSnackMsg('Added Coordinator Successfully!');
					setState(prevState => ({
						...prevState,
						accountId: props.name,
						accountType: props.role,
						isLoading: '',
						searchStaffId: '',
						errorMsg: '',
						name: '',
						staffId: '',
						postId: '',
						post: '',
						username: '',
						password: '',
						postName: '',
						classId: ''
					}));
					props.snackbar();
				})
				.catch(error => {
					if (error && error.response && error.response.status == 404) {
						// 404-Error no understanding of 404 Error
						setState(prevState => ({
							...prevState,
							backColor: '#FF0000'
						}));
						setIsLoading(false);
						props.snackbar();
						setSnackMsg(error.response.data.Message);
					} else if (error && error.response && error.response.status == 400) {
						// 404-Error no understanding of 404 Error
						setState(prevState => ({
							...prevState,
							backColor: '#FF0000'
						}));
						setIsLoading(false);
						props.snackbar();
						setSnackMsg(error.response.data.Message);
					}
					setIsLoading(false);
				});
		}
	};

	const Staff = (
		<div>
			<div className="row" style={{ justifyContent: 'center' }}>
				<div class="col-md-6" style={{ marginTop: 44 }}>
					<div class="md-form mb-0">
						<label for="staffId">
							{state.errorMsgStaffId ? (
								<p style={{ color: 'red', margin: 0 }}>Please Register Staff</p>
							) : (
								<p style={{ margin: 0, color: '#000' }}>Staff ID</p>
							)}
						</label>

						<input
							placeholder="Enter Staff ID"
							type="text"
							name="searchStaffId"
							value={state['searchStaffId']}
							className="form-control check"
							onChange={onHandleStaffIdChange}
						/>
					</div>
				</div>
				{/* 				<TextInput
					feildName={'searchStaffId'}
					onHandleChange={onHandleStaffIdChange}
					errors={{}}
					stateData={state}
					placeholder={'Enter Staff ID'}
					isValidte={false}
				/>
 */}{' '}
			</div>

			{/* 			<Button
				icon={true}
				width="100%"
				buttonClass="customButton"
				btnName="Search"
				loaderBmargin={5}
				stateData={state}
				marginTop={20}
				onHandleSubmit={onSubmitGetStaff}
				margin={'0 auto'}
				textColor="white"
			/>
 */}
			<div className="row" style={{ justifyContent: 'center' }}>
				<TextInput
					feildName={'name'}
					inputLabelName={'Staff Name'}
					errors={{}}
					stateData={state}
					placeholder="Staff Name"
					readOnly={true}
					type={'text'}
					isValidte={false}
				/>

				<TextInput
					feildName={'postName'}
					inputLabelName={'Post Name'}
					errors={{}}
					stateData={state}
					placeholder="Post Name"
					readOnly={true}
					type={'text'}
					isValidte={false}
				/>

				<SelectionInput
					marginTop={20}
					feildName={'postId'}
					selectName={'Post'}
					onHandleChange={e => onHandlePostChange(e)}
					errors={{}}
					optionsArrys={postData}
					selectedText={'Select Post'}
					stateData={state}
					// iconClassName={'fas fa-building'}
					optionType="dynamicWithPropIdAndName"
					property={'postName'}
					propertyId={'postId'}
					successflag={'successflag'}
					isValidte={false}
					// editUse={'staffEdit'}
				/>

				<SelectionInput
					marginTop={20}
					feildName={'classId'}
					selectName={'Class'}
					onHandleChange={e => onHandleSelectChange(e)}
					errors={{}}
					optionsArrys={classData}
					selectedText={'Select Class'}
					stateData={state}
					// iconClassName={'fas fa-building'}
					optionType="dynamicWithPropIdAndName"
					property={'className'}
					propertyId={'classId'}
					successflag={'successflag'}
					isValidte={false}
					// editUse={'staffEdit'}
				/>

				<div class="col-md-6" style={{ marginTop: 44 }}>
					<div class="md-form mb-0">
						<label for="username">
							{state.errorMsgUsername ? (
								<p style={{ color: 'red', margin: 0 }}>
									Username Already Exist
								</p>
							) : (
								<p style={{ margin: 0, color: '#000' }}>Username</p>
							)}
						</label>

						<input
							style={{
								borderColor: state['errorMsgUsername']
									? 'red'
									: Colors.SEA_GREEN_THEME
							}}
							placeholder="Enter Username"
							type="text"
							name="username"
							value={state['username']}
							className="form-control check"
							onChange={onHandleUsernameChange}
						/>
					</div>
				</div>

				{/* 				<TextInput
					marginTop={20}
					feildName={'username'}
					inputLabelName={'Username'}
					onHandleChange={onHandleTextChange}
					errors={{}}
					stateData={state}
					// iconClassName={'fas fa-percentage'}
					placeholder={'Username'}
					isValidte={false}
				/>
 */}
				<TextInput
					marginTop={20}
					type={'password'}
					feildName={'password'}
					inputLabelName={'Password'}
					onHandleChange={onHandleTextChange}
					errors={{}}
					stateData={state}
					// iconClassName={'fas fa-percentage'}
					placeholder={'Password'}
					isValidte={false}
				/>
			</div>
		</div>
	);

	const Form = (
		<FormLayoutWrapper
			formName="Grade Coordinator"
			borderColor={themeColor.themeColors.primaryColor}
			borderRadius={15}
		>
			{state.errorMsg ? (
				<p style={{ color: 'red', textAlign: 'center' }}>
					Please fill the form properly
				</p>
			) : (
				''
			)}

			{Staff}
			<br />

			<Button
				width="100%"
				buttonClass="customButton"
				btnName="Add"
				loaderBmargin={5}
				stateData={{
					isLoading
				}}
				marginTop={20}
				onHandleSubmit={onSubmit}
				margin={'0 auto'}
				textColor={'white'}
			/>
		</FormLayoutWrapper>
	);

	return (
		<React.Fragment>
			<SnackBar backColor={state['backColor']} msg={msg} />
			{Form}
		</React.Fragment>
	);
};

function mapStateToProps(state) {
	return {
		name: state.setUserReducer.users.unique_name,
		role: state.setUserReducer.users.role
	};
}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch)
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AssignStaffCoordinatorRenew);
