import React, { Component } from 'react';

class StaffExperiencePrint extends Component {
	state = {};
	render() {
		const { Document } = this.props;
		console.log(Document, 'Experience Data');
		return (
			<div>
				<div id="div1">
					<section class="mb-4">
						<div class="container">
							<div class="row rowStaffCheck" style={{ marginTop: 10 }}>
								<div class="col colStaffCheck">
									<label style={{ fontWeight: 'bold' }}>
										Position:{' '}
									</label>{' '}
									&nbsp;&nbsp;
									{Document && Document.experienceName}
								</div>
								<div class="col colStaffCheck">
									{' '}
									<label style={{ fontWeight: 'bold' }}>Institution:</label>
									&nbsp;&nbsp; {Document && Document.companyName}
								</div>
							</div>
							<div class="row rowStaffCheck">
								<div class="col colStaffCheck">
									<label style={{ fontWeight: 'bold' }}>
										Number Of Years:
									</label>
									&nbsp;&nbsp; {Document && Document.experinceYear}
								</div>
							</div>
						</div>
					</section>
				</div>
			</div>
		);
	}
}
export default StaffExperiencePrint;
