import React, { useEffect, useState } from 'react';
import { Line, Bar, Pie } from 'react-chartjs-2';
import GraphGeneralLayout from '../../../components/Graphs/GraphGeneralLayout/GraphGeneralLayout.jsx';
import LoaderWrapper from '../../../components/HOC/LoaderWrapper.jsx';
import ReportWrapper from '../../../components/HOC/ReportWrapper.jsx';
import ViewComponent from '../../../components/ViewComponent/ViewComponent.jsx';
import useHttp from '../../../hooks/http.js';
import CoverLayout from '../../../components/HOC/CoverLayout.jsx';
import httpWithRequiredSetData from '../../../hooks/Services/httpWithRequiredSetData.js';
import { getFrequencyCount } from '../../../utils/general/index.js';
import $ from 'jquery';
export default function CaSingleStudentTermReport(props) {
	const [graphType, setGraphType] = useState('lineChart');
	const [graphType1, setGraphType1] = useState('barChart');
	const [graphType2, setGraphType2] = useState('pieChart');
	const { classId, sectionId, sessionId, term, studentId } = props.match.params;
	const { isGraphLoading, setIsGraphLoading } = useState(false);
	const [data, setData] = useState({
		datasets: [
			{
				// barPercentage: 0.4,
				// minBarLength: 2,
				label: 'Course Average',
				data: [1, 2, 2, 3, 3, 4, 3, 2],
				backgroundColor: [
					'#ea766c',
					'#abe2b9',
					'#adbceb',
					'#be9e9d',
					'#9d5b4d',
					'#e4d86b',
					'#9ecf54',
					'#e4d86b'
				],
				borderColor: [
					'#ea766c',
					'#abe2b9',
					'#adbceb',
					'#be9e9d',
					'#9d5b4d',
					'#e4d86b',
					'#9ecf54',
					'#e4d86b'
				],
				borderWidth: 2
			}
		],

		// These labels appear in the legend and in the tooltips when hovering different arcs
		labels: ['BIO', 'ICT', 'MATH', 'SOCIAL', 'PHYS', 'CHEM', 'ENG', 'ISL'],
		options: {
			showLines: true
		}
	});
	const [data1, setData1] = useState({
		datasets: [
			{
				// barPercentage: 0.4,
				// minBarLength: 2,
				label: 'Course Average',
				data: [1, 2, 2, 3, 3, 4, 3, 2],
				backgroundColor: [
					'#ea766c',
					'#abe2b9',
					'#adbceb',
					'#be9e9d',
					'#9d5b4d',
					'#e4d86b',
					'#9ecf54',
					'#e4d86b'
				],
				borderColor: [
					'#ea766c',
					'#abe2b9',
					'#adbceb',
					'#be9e9d',
					'#9d5b4d',
					'#e4d86b',
					'#9ecf54',
					'#e4d86b'
				],
				borderWidth: 2
			}
		],

		// These labels appear in the legend and in the tooltips when hovering different arcs
		labels: ['BIO', 'ICT', 'MATH', 'SOCIAL', 'PHYS', 'CHEM', 'ENG', 'ISL'],
		options: {
			showLines: true
		}
	});
	const [data2, setData2] = useState({
		datasets: [
			{
				// barPercentage: 0.4,
				// minBarLength: 2,
				label: 'Course Average',
				data: [1, 2, 2, 3, 3, 4, 3, 2],
				backgroundColor: [
					'#ea766c',
					'#abe2b9',
					'#adbceb',
					'#be9e9d',
					'#9d5b4d',
					'#e4d86b',
					'#9ecf54',
					'#e4d86b'
				],
				borderColor: [
					'#ea766c',
					'#abe2b9',
					'#adbceb',
					'#be9e9d',
					'#9d5b4d',
					'#e4d86b',
					'#9ecf54',
					'#e4d86b'
				],
				borderWidth: 2
			}
		],

		// These labels appear in the legend and in the tooltips when hovering different arcs
		labels: ['BIO', 'ICT', 'MATH', 'SOCIAL', 'PHYS', 'CHEM', 'ENG', 'ISL'],
		options: {
			showLines: true
		}
	});
	const [isLoader, fetchData, setFetchData] = httpWithRequiredSetData(
		`/api/SingleStudentCA/?classId=${classId}&sectionId=${sectionId}&sessionId=${sessionId}&term=${term}&studentId=${studentId}`,
		[],
		remoteData => {
			let graphData1 = $.extend(true, {}, data);
			let graphData2 = $.extend(true, {}, data1);
			let graphData3 = $.extend(true, {}, data2);

			let getCompleteCloneOfData = { ...remoteData };
			let getStudentData = remoteData['StudentResultLists'][
				'SubjectResultLists'
			].map(item => {
				return { ...item, ...item['DetailMarkLists'] };
			});
			// remark Graph Data
			let originalRemarkData = getStudentData.map(item => item.remark);
			let uniqueRemarkData = [...new Set(originalRemarkData)];
			let dataFiguresForRemarkData = getFrequencyCount(
				originalRemarkData,
				uniqueRemarkData
			);
			graphData1['datasets'][0]['data'] = [...dataFiguresForRemarkData];
			graphData1['labels'] = [...uniqueRemarkData];
			setData(graphData1);
			debugger;
			// end of remark graph data
			//grade graph Data
			let originalGradeData = getStudentData.map(item => item.grade);
			let uniqueGradeData = [...new Set(originalGradeData)];
			let dataFiguresForGradeData = getFrequencyCount(
				originalGradeData,
				uniqueGradeData
			);
			graphData2['datasets'][0]['data'] = [...dataFiguresForGradeData];
			graphData2['labels'] = [...originalGradeData];
			setData1(graphData2);
			debugger;
			// end of grade Graph data
			// total graph data
			let originalTotalData = getStudentData.map(item => item.total);
			let originalSubjectData = getStudentData.map(item => item.subjectName);
			graphData3['datasets'][0]['data'] = [...originalTotalData];
			graphData3['labels'] = [...originalSubjectData];
			setData2(graphData3);
			// end of total graph data
			debugger;
			let updatePrimaryInfoes = {
				...getCompleteCloneOfData['primaryInfoes'],
				studentName:
					getCompleteCloneOfData['StudentResultLists']['studentName'],
				staffName: getCompleteCloneOfData['StudentResultLists']['classTeacher']
			};

			let getCompleteCloneOfDataSetting = {
				...remoteData,
				primaryInfoes: { ...updatePrimaryInfoes }
			};
			debugger;
			debugger;

			setFetchData(getCompleteCloneOfDataSetting);
		}
	);
	useEffect(() => {
		// let graphData = { ...data };
		// let StudentSubjectWiseMarks =
		// 	fetchData && fetchData['StudentSubjectWiseMarks'];
		// debugger;
		// let getSubjectName =
		// 	StudentSubjectWiseMarks &&
		// 	StudentSubjectWiseMarks.map(item =>
		// 		item.subjectName.slice(0, 3).toUpperCase()
		// 	);
		// let getMarks =
		// 	StudentSubjectWiseMarks &&
		// 	StudentSubjectWiseMarks.map(item =>
		// 		item.marksList && item.marksList.length > 0
		// 			? item.marksList[0] && item.marksList[0]['marks']
		// 			: 0
		// 	);
		// graphData['datasets'][0]['data'] = getMarks;
		// graphData['labels'] = getSubjectName;
		// setData(graphData);
		// debugger;
	}, [fetchData]);
	const PrintContent = () => {
		// Previous code mentioned below

		setTimeout(() => {
			window.print();
			window.location.reload();
		}, 700);
	};
	// debugger;
	let requireFetchData = { ...fetchData };
	debugger;
	let getNewData = [];
	debugger;
	if (requireFetchData && requireFetchData['StudentResultLists']) {
		debugger;
		getNewData =
			requireFetchData &&
			requireFetchData['StudentResultLists']['SubjectResultLists'].map(item => {
				return { ...item, ...item['DetailMarkLists'] };
			});
		debugger;
	}
	debugger;
	const returnGraphs = (type, data) => {
		switch (type) {
			case 'barChart':
				return <Bar height={100} data={data} />;

			case 'pieChart':
				return <Pie height={100} data={data} />;

			case 'lineChart':
				return <Line height={100} data={data} />;

			default:
				break;
		}
	};
	return (
		<div>
			{
				<LoaderWrapper isLoading={isLoader}>
					<ReportWrapper
						gradeChat={false}
						headRenderData={[
							{ displayData: 'Class Name', fieldName: 'className' },
							{ displayData: 'Section', fieldName: 'section' },
							{ displayData: 'Term', fieldName: 'term' },
							{ displayData: 'Session', fieldName: 'session' }
						]}
						headingName="Student Marks Report"
						headData={fetchData}
						defaultKey={'primaryInfoes'}
						targetedObj="primaryInfoes"
						PrintContent={PrintContent}
					>
						<LoaderWrapper isLoading={isLoader}>
							<CoverLayout
								titleName={'Report'}
								titleIcon={'fas fa-clipboard'}
								children={() => (
									<ViewComponent
										mainData={getNewData}
										headData={[
											'S#',
											'Subject Name',
											'CA',
											'EX',
											'Total',
											'Grade',
											'Remark'
										]}
										excludedData={['subjectId', 'DetailMarkLists']}
									/>
								)}
							/>
							<br />
							<br />
							<br />
							<div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
								<div style={{ width: '50%', height: '50%' }}>
									<GraphGeneralLayout
										gradingScale={false}
										setType={setGraphType}
										setProperty={graphType}
										titleName={'Remarks Analysis'}
										titleIcon={'fas fa-chart-bar'}
										children={() => returnGraphs(graphType, data)}
									/>
								</div>
								<div style={{ width: '10%' }} />
								<div style={{ width: '50%', height: '50%' }}>
									<GraphGeneralLayout
										gradingScale={false}
										fieldName={'graphType1'}
										setType={setGraphType1}
										setProperty={graphType1}
										titleName={'Grade Analysis'}
										titleIcon={'fas fa-chart-bar'}
										children={() => returnGraphs(graphType1, data1)}
									/>
								</div>
							</div>
							<br />
							<br />
							<div
								style={{
									width: '50%',
									height: '50%',
									display: 'flex',
									justifyContent: 'center'
								}}
							>
								<br />
								<br />
								<GraphGeneralLayout
									gradingScale={false}
									setType={setGraphType2}
									setProperty={graphType2}
									titleName={'Total Marks Analysis'}
									titleIcon={'fas fa-chart-bar'}
									children={() => returnGraphs(graphType2, data2)}
								/>
							</div>

							<br />
							<br />
							<br />
							<br />
						</LoaderWrapper>
						<br />
						<br />
					</ReportWrapper>
				</LoaderWrapper>
			}
		</div>
	);
}
