import * as actionTypes from '../Actions/actionTypes';

const defaultState = {
	apiFlag: true,
	Data: [],
	select: [],
	SearchData: [],
	SelectPost: []
};

export default function setPostReducer(state = defaultState, action = {}) {
	switch (action.type) {
		case actionTypes.SET_ALL_DEPARTMENT_NAME_INTO_POST_SELECT:
			return {
				...state,
				select: [...action.payload]
			};
		case actionTypes.SET_ALL_POST_NAME_INTO_SEARCHSTAFFPOST_SELECT:
			return {
				...state,
				SelectPost: action.payload
			};

		case actionTypes.GET_ALL_POST:
			return { ...state, Data: [...action.payload] };
		case actionTypes.GET_ALL_STAFFSEARCH_BY_POST:
			return { ...state, SearchData: action.payload };
		case actionTypes.SET_LOADER_FLAG_POST:
			return { ...state, apiFlag: action.payload };
		case actionTypes.DELETE_POST:
			let filterCandidateReqList = state.Data.filter(
				filterOBJ => filterOBJ.PostId !== action.payload
			);
			return {
				...state,
				Data: filterCandidateReqList
			};
		case actionTypes.EDIT_POST_RECORD:
			var adminData = [...state.Data];
			var index = adminData.findIndex(
				obj => obj.PostId === action.payload.postId
			);
			// adminData[index].departmentName = action.payload.departmentName;
			adminData[index].Post = action.payload.postName;

			return { ...state, Data: [...adminData] };
		default:
			return state;
	}
}
