import React, { useEffect, useState, useContext} from 'react'
import $ from 'jquery';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import SelectionInput from '../../components/SelectionInput/SelectionInput';
import TextInput from '../../components/TextInput/TextInput'
import Pagination from '../../components/Pagination/Pagination'
import Spinner from '../../components/Spinner/Spinner';
import httpWithRequiredSetData from '../../hooks/Services/httpWithRequiredSetData';
import general from '../../utils/general'
import Colors from '../../utils/app_constants/colors_constants';
import CustomFilterSectionLayout from '../../components/CustomFilterSectionLayout/CustomFilterSectionLayout';
import TextArea from '../../components/TextArea'
import themeContext from "../../context/themeContext/ThemeContext";

let tHead = [
    'Vehicle No.',
    'Driver Name',
    'Maintenance',
    'Month',
    'Date',
    'Account ID',
    'Account Type',
    'Actions'
];

let renderFields = [
    'vehicleNo',
    'driverName',
    'maintenance',
    'month',
    'date',
    'accountId',
    'accountType',
]

const ViewTransportMaintenance = props => {
    const themeColor = useContext(themeContext);

    const [enabled, setEnabled] = useState('false')
    const [id, setId] = useState('')
    const [otherDetails, setotherDetails] = useState('')
    const [driverIssue, setDriverIssue] = useState('')
    const [maintenance, setMaintenance] = useState('')
    const [modalData, setModalData] = useState([])
    const [transportData, setTransportData] = useState([])
    const [sessionId, setSessionId] = useState('')
    const [vehicleNo, setVehicleNo] = useState('')
    const [sessionData, setSessionData] = useState([])
    const [vehicleNoData, setVehicleNoData] = useState([])
    const [month, setMonth] = useState('')
    const [accountType, setAccountType] = useState(props.role)
    const [accountId, setAccountId] = useState(props.name)

    const [isSessionLoading, sessions] = httpWithRequiredSetData(
        '/api/session',
        [],
        data => {
            setSessionData(data['session']);
        }
    );

    const [isVehicleLoading, vehicless] = httpWithRequiredSetData(
        '/api/VehicleNoList',
        [],
        data => {
            setVehicleNoData(data);
        }
    );

    const [searchURL, setSearchURL] = useState(
        '/api/TransportMaintenance/?vehicleNo=&sessionId=&month=&pageNumber=1&pageSize=10'
    );


    const [disableBPage, setdisableBPage] = useState(true);
    const [customPageSize, setCustomPageSize] = useState('10');
    const [currentPage, setCurrentPage] = useState(1);
    const [pageNumber, setPageNumber] = useState([]);
    const [disablepage, setdisablepage] = useState(false);
    const [pagination, setPaginationData] = useState({});


    const [
        isLoading,
        fetchData,
        setFetchData,
        setIsLoading
    ] = httpWithRequiredSetData(searchURL, [], data => {
        let pageNumber = [];
        for (let i = 1; i <= data['paginationMetadata']['totalPages']; i++) {
            pageNumber.push(i);
        }
        setPageNumber(pageNumber);

        setPaginationData(data['paginationMetadata']);
    });

    useEffect(() => {
        return () => {
            $('[data-toggle="tooltip"]').tooltip('hide');
        };
    }, []);

    const handleClickNextFrwd = () => {
        console.log('pagelength: ', pageNumber.length);
        console.log('current page: ', currentPage);
        if (currentPage === pageNumber.length) {
            setdisablepage(true);
        } else {
            setCurrentPage(prevState => prevState + 1);
            console.log(currentPage);

            setdisableBPage(false);
        }
    };

    const onHandleChange = e => {
        if (e.target.name === 'month') {
            if (e.target.value !== 'Month') {
                setMonth(e.target.value)
            }
        } else if (e.target.name === 'sessionId') {
            if (e.target.value !== 'Session') {
                setSessionId(e.target.value)
            }
        } else if (e.target.name === 'maintenance') {
            if (e.target.value !== 'Maintenance') {
                setMaintenance(e.target.value)
            }
        } else if (e.target.name === 'vehicleNo') {
            if (e.target.value !== 'Vehicle') {
                let vehicle = vehicleNoData.filter(item => parseInt(item.id) === parseInt(e.target.value))
                setVehicleNo(vehicle[0].vehicleNo)
            }
        }
    }

    useEffect(() => {
        console.log('Do something after counter has changed', currentPage);
        setIsLoading(true);
        props.sharedActions
            .getDataWithoutDispatch(
                general.condtionalServicesTransportMaintenance(
                    month,
                    sessionId,
                    vehicleNo,
                    currentPage,
                    customPageSize
                )
            )
            .then(success => {
                setTransportData(success['items']);
                setIsLoading(false);
                let pageNumber = [];
                for (let i = 1; i <= success['paginationMetadata']['totalPages']; i++) {
                    pageNumber.push(i);
                }
                setPageNumber(pageNumber);
                setPaginationData(success['paginationMetadata']);
            })
            .catch(error => {
                setIsLoading(false);
                setTransportData([])
            });
    }, [currentPage, sessionId, vehicleNo, month, customPageSize]);

    const handleClickNext = event => {
        setCurrentPage(Number(event.target.id));
        setdisableBPage(false);
        setdisableBPage(false);
    };
    const handleClickNextBack = () => {
        if (currentPage === 1) {
            setdisableBPage(true);
            setdisablepage(false);
        } else {
            setCurrentPage(prevState => prevState - 1);
        }
    };
    const [msg, setSnackMsg] = useState('');

    const onChangePageSize = e => {
        if (e.target.value !== 'Page Entries') {
            setCustomPageSize(e.target.value);
            setCurrentPage(1);
        }
    };

    const onEditClick = itemId => {
        onTakeItem(itemId)
        setEnabled(false)
    }

    const onTakeItem = (itemId) => {
        setModalData([])

        let getData = transportData.filter(item => parseInt(item.id) === parseInt(itemId));

        setModalData(getData)
        setotherDetails(getData[0].otherDetails)
        setDriverIssue(getData[0].driverIssue)
        setMaintenance(getData[0].maintenance)
        setId(getData[0].id)
    }

    const onHandleTextChange = e => {
        if (e.target.name === 'otherDetails') {
            setotherDetails(e.target.value)
        } else if (e.target.name === 'driverIssue') {
            setDriverIssue(e.target.value)
        }
    }


    const onClickDelete = id => {
        setIsLoading(true)

        props.sharedActions
            .deleteRecordWithoutDispatch(
                '/api/TransportMaintenance/',
                id
            )
            .then(success => {
                // if (success.status === 200) {
                let getNewFilterArray = transportData.filter(item => item.id !== id);
                setSnackMsg('Remove Successfully!');
                setTransportData(getNewFilterArray);
                setIsLoading(false)
                props.snackbar();
                setId(null);
                // }
            })
            .catch(error => {
                setIsLoading(false)
                console.log(error);
            });
    };

    const onHandleSubmitUpdate = e => {
        e.preventDefault();

        modalData[0].otherDetails = otherDetails
        modalData[0].driverIssue = driverIssue
        modalData[0].maintenance = maintenance

        const data = {
            otherDetails,
            driverIssue,
            maintenance,
            accountId,
            accountType
        }

        props.sharedActions
            .editPatchRecordWithoutDispatch(
                `/api/TransportMaintenance/`,
                id,
                data
            )
            .then(success => {
                transportData.forEach((item, index) => {
                    if (item.id == id) {
                        transportData.splice(index, 1, modalData[0])
                    }
                })
                setEnabled(false)
                setSnackMsg('Edited Record Successfully!')
                props.snackbar();
            })
    }

    const PrintContent = el => {
        var restorepage = document.body.innerHTML;
        var Printcontent = document.getElementById(el).innerHTML;
        document.body.innerHTML = Printcontent;
        window.print();
        document.body.innerHTML = restorepage;
    };

    const bodyRendering = data =>
        data &&
        data.map((item, index) => (
            <tr>
                <th
                    style={{
                        padding: 2,
                        border: `1px solid ${themeColor.themeColors.primaryColor}`,
                        borderColor: themeColor.themeColors.primaryColor
                    }}
                    scope="row"
                >
                    {index + 1}
                </th>
                {renderFields.map((item1, index) => (
                    <td
                        style={{
                            fontWeight: `${index == 0 ? 'bold' : 'normal'}`,
                            border: `1px solid ${themeColor.themeColors.primaryColor}`,
                            padding: 2,
                            borderColor: themeColor.themeColors.primaryColor
                        }}
                    >
                        {item[item1]}
                    </td>
                ))}
                <td className='displayElemet'
                    style={{
                        border: `1px solid ${themeColor.themeColors.primaryColor}`,
                        padding: 2,
                        borderColor: themeColor.themeColors.primaryColor
                    }}
                >
                    <Link to={`/dashboard/MaintenanceReport/${item.id}`}>
                        <button
                            id="firstbutton"
                            // onClick={() => this.onEditClick(item.id)}
                            style={{
                                cursor: 'pointer',
                                fontSize: 20,
                                border: 'none',
                                marginLeft: 5,
                                background: 'none',
                                marginBottom: 5
                            }}
                        >
                            <a href="#" data-toggle="tooltip" title="View!">
                                <i style={{ color: '#01AC8A' }} class="fas fa-list"></i>
                            </a>
                        </button>
                    </Link>

                    <a href="#" data-toggle="tooltip" title="Edit!">
                        <button
                            id="firstbutton"
                            onClick={() => onEditClick(item.id)}
                            data-toggle="modal"
                            data-target="#myModal"
                            style={{
                                cursor: 'pointer',
                                fontSize: 20,
                                border: 'none',
                                marginLeft: 5,
                                background: 'none',
                                marginBottom: 5
                            }}
                        >
                            <i class="fas fa-pen"></i>
                        </button>
                    </a>

                    <a href="#" data-toggle="tooltip" title="Delete!">
                        <button
                            onClick={() => onEditClick(item.id)}
                            data-toggle="modal"
                            data-target="#myModal1"
                            style={{
                                cursor: 'pointer',
                                fontSize: 20,
                                border: 'none',
                                background: 'none',
                                marginLeft: 5,
                                marginBottom: 5
                            }}
                        >
                            <i style={{ color: '#D11A2A' }} class="fas fa-trash-alt"></i>
                        </button>
                    </a>
                </td>
            </tr>
        ));

    return (
        <div id='div1'>
            <SnackBar msg={msg} />
            <div class="modal" id="myModal1">
                <div class="modal-dialog">
                    <div class="modal-content modal_content_custom">
                        <div
                            style={{
                                borderBottomLeftRadius: 30,
                                borderBottomRightRadius: 30,
                                borderTopLeftRadius: 18,
                                borderTopRightRadius: 18,
                                background: themeColor.themeColors.primaryColor
                            }}
                            class="modal-header modal_custom_header"
                        >
                            <h4 style={{ color: 'white' }} class="modal-title">
                                Confirm
							</h4>
                            <button
                                style={{ color: 'white' }}
                                type="button"
                                class="close"
                                data-dismiss="modal"
                            >
                                &times;
							</button>
                        </div>
                        <div class="modal-body">
                            <div>
                                <div style={{ textAlign: 'center' }}>
                                    <h4>Are you sure ?</h4>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-evenly'
                                    }}
                                >
                                    <div>
                                        <button
                                            // disabled={this.state.enabled}
                                            data-dismiss="modal"
                                            onClick={() => onClickDelete(id)}
                                            type="button"
                                            class="btn btn-primary buttonAppear"
                                            style={{background: themeColor.themeColors.primaryColor}}
                                        >
                                            Yes
										</button>
                                    </div>
                                    <div>
                                        <button
                                            data-dismiss="modal"
                                            // disabled={this.state.enabled}
                                            type="button"
                                            class="btn btn-primary buttonAppear"
                                            style={{background: themeColor.themeColors.primaryColor}}
                                        >
                                            No
										</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal" id="myModal">
                <div class="modal-dialog modal-md modal-dialog-centered">
                    <div class="modal-content modal_content_custom">
                        <div
                            style={{
                                borderBottomLeftRadius: 30,
                                borderBottomRightRadius: 30,
                                borderTopLeftRadius: 18,
                                borderTopRightRadius: 18,
                                background: themeColor.themeColors.primaryColor
                            }}
                            class="modal-header modal_custom_header"
                        >
                            <h4 style={{ color: 'white' }} class="modal-title">
                                Details
								</h4>
                            <button
                                style={{ color: 'white' }}
                                type="button"
                                class="close"
                                data-dismiss="modal"
                            >
                                &times;
								</button>
                        </div>
                        <div class="modal-body">
                            <form onSubmit={e => onHandleSubmitUpdate(e)}>
                                {modalData &&
                                    modalData.map((item, index) => (
                                        <div key={index}>
                                            <fieldset disabled={!enabled}>
                                                <div class='row'>

                                                    <TextArea
                                                        feildName={'otherDetails'}
                                                        inputLabelName={'Other Details'}
                                                        onHandleChange={onHandleTextChange}
                                                        errors={{}}
                                                        stateData={{ otherDetails }}
                                                        // iconClassName={'fas fa-percentage'}
                                                        placeholder={'Other Details'}
                                                        isValidte={false}
                                                    />

                                                    <TextArea
                                                        feildName={'driverIssue'}
                                                        inputLabelName={'Driver Issue'}
                                                        onHandleChange={onHandleTextChange}
                                                        errors={{}}
                                                        stateData={{ driverIssue }}
                                                        // iconClassName={'fas fa-percentage'}
                                                        placeholder={'Driver Issue'}
                                                        isValidte={false}
                                                    />


                                                    <SelectionInput
                                                        feildName={'maintenance'}
                                                        selectName={'Maintenance'}
                                                        onHandleChange={e => onHandleChange(e)}
                                                        errors={{}}
                                                        optionsArrys={general.maintenance}
                                                        selectedText={'Maintenance'}
                                                        stateData={{ maintenance }}
                                                        // iconClassName={'fas fa-building'}
                                                        optionType="static"
                                                        successflag={'successflag'}
                                                        isValidte={false}
                                                    // editUse={'staffEdit'}
                                                    />

                                                </div>
                                            </fieldset>
                                            <br />
                                            <br />
                                            <button
                                                disabled={enabled}
                                                onClick={() => setEnabled(true)}
                                                type="button"
                                                class="btn btn-primary buttonAppear"
                                                style={{background: themeColor.themeColors.primaryColor}}
                                            >
                                                Edit
												</button>
                                            <button
                                                style={{ marginLeft: 5 }}
                                                disabled={!enabled}
                                                type="submit"
                                                class="btn btn-primary buttonAppear"
                                                style={{background: themeColor.themeColors.primaryColor}}
                                            >
                                                Save
												</button>
                                        </div>
                                    ))}
                            </form>
                        </div>
                        <div class="modal-footer">
                            <button
                                type="button"
                                style={{ background: '#00435D' }}
                                class="btn btn-danger"
                                data-dismiss="modal"
                            >
                                Close
								</button>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{ textAlign: 'right' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        <h2 className="h1-responsive font-weight-bold text-left my-4 generalHead" style={{background: themeColor.themeColors.primaryColor}}>
                            {' '}
                            Transport Maintenance{' '}
                        </h2>
                    </div>
                    <div className='displayElemet'>
                        <div
                            style={{ marginTop: 40, display: 'flex', flexDirection: 'column' }}
                        >
                            <Pagination
                                disableBPage={disableBPage}
                                disablepage={disablepage}
                                currentPage={currentPage}
                                pageNumber={pageNumber}
                                handleClickNext={handleClickNext}
                                handleClickNextBack={handleClickNextBack}
                                handleClickNextFrwd={handleClickNextFrwd}
                            />
                            <div
                                style={{
                                    width: 'fit-content',
                                    marginTop: -34,
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    marginLeft: 'auto'
                                }}
                            >
                                <SelectionInput
                                    selectName="Entries"
                                    selectFont={14}
                                    selectFontWeight={'bold'}
                                    newFlag="customWidth"
                                    feildName="customPageSize"
                                    selectedText={'Page Entries'}
                                    optionsArrys={['10', '20', '30', '40', '50']}
                                    errors={{}}
                                    isValidte={false}
                                    stateData={{
                                        customPageSize: customPageSize
                                    }}
                                    optionType="static"
                                    onHandleChange={onChangePageSize}
                                    useFlag={false}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='displayElemet'>
                    <CustomFilterSectionLayout displayDirection="column">
                        <div
                            style={{
                                width: '30%',
                                margin: '0 auto',
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            <React.Fragment>
                                <SelectionInput
                                    newFlag="customWidth"
                                    feildName="month"
                                    selectName={'Month'}
                                    selectedText={'Month'}
                                    optionsArrys={general.Months}
                                    errors={{}}
                                    isValidte={false}
                                    stateData={{
                                        month: month
                                    }}
                                    optionType="static"
                                    onHandleChange={e => onHandleChange(e)}
                                    useFlag={false}
                                />
                                <SelectionInput
                                    newFlag="customWidth"
                                    useFlag={false}
                                    feildName={'sessionId'}
                                    selectName={'Session'}
                                    onHandleChange={e => onHandleChange(e)}
                                    errors={{}}
                                    optionsArrys={sessionData}
                                    selectedText={'Session'}
                                    stateData={{
                                        sessionId: sessionId
                                    }}
                                    // iconClassName={'fas fa-building'}
                                    optionType="dynamicWithPropIdAndName"
                                    property={'currentSession'}
                                    propertyId={'sessionId'}
                                    isValidte={false}
                                // editUse={'staffEdit'}
                                />

                                <SelectionInput
                                    newFlag="customWidth"
                                    useFlag={false}
                                    feildName={'vehicleNo'}
                                    selectName={'Vehicle'}
                                    onHandleChange={e => onHandleChange(e)}
                                    errors={{}}
                                    optionsArrys={vehicleNoData}
                                    selectedText={'Vehicle'}
                                    stateData={{
                                        vehicleNo: vehicleNo
                                    }}
                                    // iconClassName={'fas fa-building'}
                                    optionType="dynamicWithPropIdAndName"
                                    property={'vehicleNo'}
                                    propertyId={'id'}
                                    isValidte={false}
                                // editUse={'staffEdit'}
                                />
                            </React.Fragment>
                        </div>
                    </CustomFilterSectionLayout>
                </div>

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span className="customBadge" style={{ fontSize: 14 }}>
                        Page: {currentPage}
                    </span>

                    <span className="customBadge" style={{ fontSize: 14 }}>
                        Total Page: {pagination['totalPages']}
                    </span>
                </div>
            </div>
            {isLoading ? (
                <Spinner />
            ) :
                transportData.length < 1 ? <div style={{ display: 'flex', justifyContent: 'center', color: 'red' }}>
                    <strong>No Data</strong>
                </div>

                    : (
                        <div className="table-responsive">
                            <table class="table table-hover">
                                <thead style={{ background: themeColor.themeColors.primaryColor, color: 'white' }}>
                                    <tr class='vendorListHeading'>
                                        <th scope="col">#</th>
                                        {tHead.map(item => (
                                            item === 'Actions' ?
                                                <th className='displayElemet'
                                                    style={{
                                                        border: `1px solid ${themeColor.themeColors.primaryColor}`,
                                                        borderColor: Colors.WHITE
                                                    }}
                                                    scope="col"
                                                >
                                                    {item}
                                                </th>
                                                :
                                                <th
                                                    style={{
                                                        border: `1px solid ${themeColor.themeColors.primaryColor}`,
                                                        borderColor: Colors.WHITE
                                                    }}
                                                    scope="col"
                                                >
                                                    {item}
                                                </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>{bodyRendering(transportData)}</tbody>
                            </table>
                        </div>
                    )}
            <div class="text-center text-md-right displayElemet">
                <label for="name" class="" onClick={() => PrintContent('div1')}>
                    <i
                        className="fas fa-print fa-2x animated fadeIn"
                        style={{ marginRight: 4 }}
                    />
                        Print
                            </label>
            </div>
        </div>
    );
};

function mapStateToProps(state) {
    return {
        role: state.setUserReducer.users.role,
        name: state.setUserReducer.users.unique_name,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        sharedActions: bindActionCreators(sharedActions, dispatch)
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewTransportMaintenance);