import React, { Component } from 'react';
import DatePicker from 'react-date-picker';

const RenderDynamicRow = props => {
    const {
        itemObj,
        onHandleTextChange,
        stateData,
        rowIndex,
        updateSpecialArrObjOnChange,
        arrName,
        dateHandler
    } = props;

    let keys = Object.keys(itemObj);
    return (
        <React.Fragment>
            {keys.map((item, index) => {
                if (item === 'expectedDate') {
                    return (
                        <td key={index}>
                            <DatePicker
                                onChange={e => dateHandler(e, rowIndex, arrName)}
                                value={stateData[arrName][rowIndex][`${item}`]}
                                selectDateOnly
                            />
                        </td>)
                } else if(item === 'quantity' || item === 'unit') {
                    return (
                        <td width='12%' key={index}>
                            <input
                                type="text"
                                placeholder={`${item}`}
                                class="form-control"
                                name={`${item}`}
                                value={stateData[arrName][rowIndex][`${item}`]}
                                onChange={e => updateSpecialArrObjOnChange(e, rowIndex, arrName)}
                            />
                        </td>
                    )
                }
                 else {
                    return (
                        <td key={index}>
                            <input
                                type="text"
                                placeholder={`${item}`}
                                class="form-control"
                                name={`${item}`}
                                value={stateData[arrName][rowIndex][`${item}`]}
                                onChange={e => updateSpecialArrObjOnChange(e, rowIndex, arrName)}
                            />
                        </td>
                    )
                }
            })}
        </React.Fragment>
    );
};
export default RenderDynamicRow;
