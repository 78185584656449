import React from 'react'
import $ from 'jquery';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import Spinner from '../../components/Spinner/Spinner';
import general from '../../utils/general'
import Colors from '../../utils/app_constants/colors_constants';
import CustomFilterSectionLayout from '../../components/CustomFilterSectionLayout/CustomFilterSectionLayout';
import RenderDynamicRows from './RenderDynamicRows';
import DatePicker from 'react-date-picker';
import TimePicker from '../../components/TimePicker';
import SelectionInput from '../../components/SelectionInput/SelectionInput';
import moment from 'moment';
import TextInput from '../../components/TextInput/TextInput'
import TextArea from '../../components/TextArea'
import ThemeContext from "../../context/themeContext/ThemeContext"

let tHead = [
    'Date',
    'Month',
    'Time',
    'Tour',
    'Description',
    'Session',
    'Actions'
];

let renderFields = [
    'tourDate',
    'month',
    'tourTime',
    'tour',
    'description',
    'session'
]

class ViewTour extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingInitialData: true,
            isLoading: false,
            accountId: props.name,
            accountType: props.role,
            tourData: [],
            classData: [],
            mainData: [],
            modalData: [],
            sectionData: [],
            errors: {},
            errorMsg: false,
            disablepage: false,
            disableBPage: true,
            currentPage: 1,
            todosPerPage: 10,
            pageCounter: 10,
            checking: [],
            pagination: [],
            TourGrades: [
                {
                    tourId: '',
                    classId: '',
                    sectionId: ''
                }
            ],
            tourTime: '',
            date: ''
        }
    }

    onClickAddItem = (arrName, obj) => {
        let getStateArr = this.state[`${arrName}`];
        getStateArr = [...getStateArr, obj];
        this.setState({
            [arrName]: getStateArr
        });
    };

    onClickDeleteItem = arrName => {
        let getStateArr = this.state[`${arrName}`];
        let getDeleteLength = getStateArr.length - 1;
        if (getDeleteLength > 0) {
            let getNewDetails = getStateArr.filter(
                (item, index) => index !== getDeleteLength
            );
            console.log(getNewDetails);
            this.setState({
                [arrName]: [...getNewDetails]
            });
        }
    };

    componentDidMount() {
        $(document).ready(function () {
            $('[data-toggle="tooltip"]').tooltip();
        });

        const promises = [

            this.props.sharedActions
                .getDataWithoutDispatch(
                    '/api/ClassSection'
                ),
            this.props.sharedActions
                .getDataWithoutDispatch(
                    '/api/TourList?pageNumber=1&pageSize=10&date='
                ),
            this.props.sharedActions
                .getDataWithoutDispatch(
                    '/api/session'
                )
        ]

        Promise.all(promises)
            .then(success => {
                this.setState({
                    isLoadingInitialData: false,
                    classData: success[0].classList,
                    tourData: success[1].tourList,
                    checking: success[1].paginationMetadata,
                    mainData: success[0],
                    sessionData: success[2].session
                })
            })
            .catch(err => {
                this.setState({
                    isLoadingInitialData: false
                });
            })

    }

    paginationRequest = number => {
        const { date } = this.state;
        let tempDate = new Date(date)
        let apiDate = ((tempDate.getMonth() > 8) ? (tempDate.getMonth() + 1) : ('0' + (tempDate.getMonth() + 1))) + '/' + ((tempDate.getDate() > 9) ? tempDate.getDate() : ('0' + tempDate.getDate())) + '/' + tempDate.getFullYear();

        date === '' ?
            this.props.sharedActions
                .getDataWithoutDispatch(
                    `/api/TourList/?pageNumber=${number}&pageSize=10&date=`
                )
                .then(success => {
                    this.setState({
                        tourData: success.tourList,
                        checking: success.paginationMetadata,
                        isLoading: false
                    });
                })
                .catch(err => {
                    this.setState({
                        isLoading: false,
                        tourData: []
                    });

                    console.log(err);
                })
            :
            this.props.sharedActions
                .getDataWithoutDispatch(
                    `/api/TourList/?pageNumber=${number}&pageSize=10&date=${apiDate}`
                )
                .then(success => {
                    this.setState({
                        tourData: success.tourList,
                        checking: success.paginationMetadata,
                        isLoading: false
                    });
                })
                .catch(err => {
                    this.setState({
                        isLoading: false,
                        tourData: []
                    });

                    console.log(err);
                });
    };

    handleClickNextFrwd = () => {
        const { tourData, todosPerPage, currentPage } = this.state;
        let pageNumbers = [];
        for (
            let i = 1;
            i <= Math.ceil(tourData.length / todosPerPage);
            i++
        ) {
            pageNumbers.push(i);
        }
        console.log('pagelength: ', pageNumbers.length);
        console.log('current page: ', currentPage);
        if (currentPage === pageNumbers.length) {
            this.setState({
                disablepage: true
            });
        } else {
            this.setState(prevState => ({
                currentPage: prevState.currentPage + 1,
                disableBPage: false
            }));
        }
    };

    handleClickNextBack = () => {
        const { currentPage } = this.state;
        console.log('current page: ', currentPage);
        if (currentPage === 1) {
            this.setState({
                disableBPage: true,
                disablepage: false
            });
        } else {
            this.setState(prevState => ({
                currentPage: prevState.currentPage - 1
            }));
            this.setState({
                disablepage: false
            });
        }
    };
    handleClickNext = event => {
        this.setState({
            currentPage: Number(event.target.id),
            disablepage: false,
            disableBPage: false
        });
    };

    onDateChange = date => {
        const { currentPage, customPageSize } = this.state;

        this.setState({
            isLoading: true,
            date: date
        })

        let tempDate = new Date(date)
        let apiDate = ((tempDate.getMonth() > 8) ? (tempDate.getMonth() + 1) : ('0' + (tempDate.getMonth() + 1))) + '/' + ((tempDate.getDate() > 9) ? tempDate.getDate() : ('0' + tempDate.getDate())) + '/' + tempDate.getFullYear();


        this.props.sharedActions
            .getDataWithoutDispatch(
                `/api/TourList?pageNumber=${currentPage}&pageSize=${parseInt(customPageSize)}&date=${apiDate}`
            )
            .then(success => {
                this.setState({
                    tourData: success.tourList,
                    pageNumber: success.paginationMetadata.pageNumber,
                    checking: success.paginationMetadata,
                    isLoading: false
                })
                let pageNumber = [];
                for (
                    let i = 1;
                    i <= success['paginationMetadata']['totalPages'];
                    i++
                ) {
                    pageNumber.push(i);
                }
            })
            .catch(error => {
                this.setState({
                    isLoading: false,
                    tourData: []
                })
            });
    }

    btnTechHover = () => {
        $(document).ready(function () {
            $('button').hover(
                function () {
                    $(this).css('box-shadow', '0px 5px 5px grey');
                    $(this).css('border-color', 'white');
                },
                function () {
                    $(this).css('box-shadow', '0px 0px 0px white');
                }
            );
        });
    };

    onAddClassClick = (tourId, tourName) => {
        this.setState({
            tourId: tourId,
            tour: tourName
        })
    }

    handleSectionChange = (e, index, arrName) => {
        const changeArrOfObj = this.state[`${arrName}`];

        changeArrOfObj[index]['sectionId'] = e.target.value
        this.setState({
            [arrName]: changeArrOfObj
        })
    }

    handleClassChange = (e, index, arrName) => {
        const { mainData } = this.state
        const changeArrOfObj = this.state[`${arrName}`];

        let uniqueSectionArr = `sectionData${index}`
        changeArrOfObj[index]['classId'] = e.target.value
        //        changeArrOfObj[index]['tourId'] = tourId
        this.setState({
            [arrName]: changeArrOfObj
        });

        const filteredSection = general.dataFilteration(
            mainData.section,
            ['section', 'sectionId'],
            {
                classId: e.target.value
            }
        )

        this.setState({
            [uniqueSectionArr]: filteredSection
        })
    }

    onHandleSubmitUpdate = (e) => {
        e.preventDefault();

        const { tourData, modalTour, tourDate, tourTime, month, description, accountId, accountType, sessionId, modalData, session, id } = this.state;
        this.setState({ isLoading: true })
        let time = moment(tourTime).format("hh:mm a");
        let date = new Date(tourDate)
        date = ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear();

        modalData[0].accountId = accountId
        modalData[0].accountType = accountType
        modalData[0].description = description
        modalData[0].month = month
        modalData[0].session = session
        modalData[0].sessionId = sessionId
        modalData[0].tour = modalTour
        modalData[0].tourDate = date
        modalData[0].tourTime = time

        this.props.sharedActions
            .editRecordWithoutDispatch(
                '/api/Tour/',
                id,
                modalData[0],
            )
            .then(success => {
                tourData.forEach((item, index) => {
                    if (item.id === id) {
                        tourData.splice(index, 1, modalData[0])
                    }
                })
                this.setState({ enabled: false, msg: 'Edited Record Successfully!', id: '', isLoading: false });
                this.props.snackbar();
            })
            .catch(err => { });
    };

    onHandleSubmit = (e) => {
        e.preventDefault();

        const { TourGrades, tourId } = this.state;
        TourGrades.forEach(item => {
            item.tourId = tourId
        })

        const data = {
            TourGrades
        }
        this.setState({
            isLoading: true
        })
        this.props.sharedActions
            .simpleAddRequest(
                '/api/AddClassInTour/',
                data
            )
            .then(success => {
                this.setState({
                    TourGrades: [
                        {
                            tourId: '',
                            classId: '',
                            sectionId: ''
                        }
                    ],
                    tourId: '',
                    errorMsg: false,
                    isLoading: false,
                    msg: 'Class Added Successfully!',
                })
                $('#myModal2').modal('toggle'); //or  $('#IDModal').modal('hide');
                this.props.snackbar();
            })
    }

    onTakeItem = itemId => {
        this.setState({
            modalData: []
        });
        const { tourData } = this.state;
        let getData = tourData.filter(item => item.id === itemId);

        let dateObj = getData[0].tourDate.split('/')
        dateObj = new Date(dateObj[2], dateObj[0] - 1, dateObj[1])

        let timeObj = moment(getData[0].tourTime, 'LT')

        this.setState({
            modalData: getData,
            id: getData[0].id,
            modalTour: getData[0].tour,
            description: getData[0].description,
            month: getData[0].month,
            tourDate: dateObj,
            tourTime: timeObj,
            sessionId: getData[0].sessionId,
            session: getData[0].session
        })

    }

    componentDidUpdate() {
        if (this.state.msg || this.state.errorMsg) {
            setTimeout(() => {
                this.setState({
                    msg: false,
                    errorMsg: false
                });
            }, 3000);
        }
    }

    handleChange = (date) => {
        this.setState({
            tourDate: date,
            month: general.Months[date.getMonth()]
        });
    };

    onHandleTimeChange = (time) => {

        this.setState({
            tourTime: time
        })
    }

    onHandleTextChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onHandleSelectChange = (e) => {
        const { sessionData } = this.state;
        this.setState({
            [e.target.name]: e.target.value
        })
        let sessionName = general.dataFilteration(
            sessionData,
            ['sessionId', 'currentSession'],
            {
                sessionId: e.target.value
            }
        )

        this.setState({
            session: sessionName[0].currentSession
        })
    }


    onEditClick = itemId => {
        this.onTakeItem(itemId);
        this.setState({ enabled: false });
    };

    onTakeItemDelete = itemId => {
        const { tourData } = this.state
        this.setState({
            modalData: [],
            isLoading: true
        });
        this.props.sharedActions
            .deleteRecordWithoutDispatch(
                '/api/Tour/',
                itemId,
            )
            .then(success => {
                let getNewFilterArray = tourData.filter(item => item.id !== itemId);
                this.setState({
                    msg: 'Delete Record Successfully!',
                    tourData: getNewFilterArray,
                    isLoading: false
                });
                this.props.snackbar();
            })
            .catch(error => { });
    };

    PrintContent = el => {
        var restorepage = document.body.innerHTML;
        var Printcontent = document.getElementById(el).innerHTML;
        document.body.innerHTML = Printcontent;
        window.print();
        document.body.innerHTML = restorepage;
        window.location.reload();
    };

    render() {
        const { date, isLoading, isLoadingInitialData, tourData, TourGrades, modalData, id, tour, enabled, errors, sessionData,
            tourDate, tourTime
        } = this.state

        let pageNumbers = [];
        for (
            let i = 1;
            i <=
            Math.ceil(this.state.checking.totalCount / this.state.checking.pageSize);
            i++
        ) {
            pageNumbers.push(i);
        }
        const renderPageNumbers = pageNumbers.map((number, index) => {
            return (
                <ThemeContext.Consumer>
                {(context) => (
                <li
                    class={`page-item ${
                        this.state.checking.currentPage === number ? 'active' : ''
                        } `}
                >
                    <a
                       style={{
                        backgroundColor:context.themeColors.primaryColor,
                        borderColor:context.themeColors.primaryColor
                    }}
                        key={number}
                        id={number}
                        onClick={() => this.paginationRequest(number)}
                        class="page-link "
                        
                        href="#"
                    >
                        {number}
                    </a>
                </li>
                                                        )}
                                                        </ThemeContext.Consumer>
            );
        });

        const MainContent = data =>
            data &&
            data.map((item, index) => (
                <ThemeContext.Consumer>
                {(context) => (
                <tr>
                    <th
                        style={{
                            padding: 2,
                            border: `1px solid ${context.themeColors.primaryColor}`,
                            borderColor: context.themeColors.primaryColor
                        }}
                        scope="row"
                    >
                        {index + 1}
                    </th>
                    {renderFields.map((item1, index) => (
                        <td
                            style={{
                                fontWeight: `${index == 0 ? 'bold' : 'normal'}`,
                                border: `1px solid ${context.themeColors.primaryColor}`,
                                padding: 2,
                                borderColor: context.themeColors.primaryColor
                            }}
                        >
                            {item[item1]}
                        </td>
                    ))}
                    <td
                    className="no-print"
                        style={{
                            border: `1px solid ${context.themeColors.primaryColor}`,
                            padding: 2,
                            borderColor: context.themeColors.primaryColor
                        }}
                    >
                        <a href="#" data-toggle="tooltip" title="Add Class!">
                            <button
                                id="firstbutton"
                                onClick={() => this.onAddClassClick(item.id, item.tour)}
                                onMouseOver={this.btnTechHover()}
                                data-toggle="modal"
                                data-target="#myModal2"
                                style={{
                                    cursor: 'pointer',
                                    fontSize: 20,
                                    border: 'none',
                                    marginLeft: 5,
                                    background: 'none',
                                    marginBottom: 5
                                }}
                            >
                                <i class="fas fa-plus" style={{ color: context.themeColors.primaryColor }}></i>
                            </button>
                        </a>
                        <Link to={`/dashboard/ViewClassesInTour/${item.id}`}>
                            <button
                                id="firstbutton"
                                // onClick={() => this.onEditClick(item.id)}
                                onMouseOver={this.btnTechHover()}
                                style={{
                                    cursor: 'pointer',
                                    fontSize: 20,
                                    border: 'none',
                                    marginLeft: 5,
                                    background: 'none',
                                    marginBottom: 5
                                }}
                            >
                                <a href="#" data-toggle="tooltip" title="View!">
                                    <i style={{ color: context.themeColors.primaryColor }} class="fas fa-list"></i>
                                </a>
                            </button>
                        </Link>

                        <a href="#" data-toggle="tooltip" title="Edit!">
                            <button
                                id="firstbutton"
                                onClick={() => this.onEditClick(item.id)}
                                onMouseOver={this.btnTechHover()}
                                data-toggle="modal"
                                data-target="#myModal"
                                style={{
                                    cursor: 'pointer',
                                    fontSize: 20,
                                    border: 'none',
                                    marginLeft: 5,
                                    background: 'none',
                                    marginBottom: 5
                                }}
                            >
                                <i class="fas fa-pen"></i>
                            </button>
                        </a>

                        <a href="#" data-toggle="tooltip" title="Delete!">
                            <button
                                onClick={() => this.onEditClick(item.id)}
                                data-toggle="modal"
                                data-target="#myModal1"
                                style={{
                                    cursor: 'pointer',
                                    fontSize: 20,
                                    border: 'none',
                                    background: 'none',
                                    marginLeft: 5,
                                    marginBottom: 5
                                }}
                            >
                                <i style={{ color: '#D11A2A' }} class="fas fa-trash-alt"></i>
                            </button>
                        </a>

                    </td>
                </tr>
                                        )}
                                        </ThemeContext.Consumer>
            ));

        const SearchContent = (
            <CustomFilterSectionLayout displayDirection="column">
                <div
                    style={{
                        width: '30%',
                        margin: '0 auto',
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                >
                    <React.Fragment>

                        <div class="col-md-6" style={{ marginTop: 25 }}>
                            <div class="md-form mb-0">
                                <label for="email" class="">
                                    Date On
											</label>
                                <br />
                                <DatePicker
                                    //                                    maxDate={true}
                                    onChange={e => this.onDateChange(e)}
                                    value={date}
                                    selectDateOnly
                                />
                            </div>
                        </div>
                        {/* <button type="submit" class="btn" style={{ marginLeft: 20, backgroundColor: '#01ac8a', color: 'white', }}> Search </button> */}
                    </React.Fragment>
                </div>
            </CustomFilterSectionLayout>
        )

        return (
            <ThemeContext.Consumer>
            {(context) => (
            <div>
                <div className="page-header">
                    <SnackBar backColor={this.state.snackColor} msg={this.state.msg} />
                    <div class="modal" id="myModal">
                        <div class="modal-dialog modal-md modal-dialog-centered">
                            <div class="modal-content modal_content_custom">
                                <div
                                    style={{
                                        borderBottomLeftRadius: 30,
                                        borderBottomRightRadius: 30,
                                        borderTopLeftRadius: 18,
                                        borderTopRightRadius: 18,
                                        background: context.themeColors.primaryColor
                                    }}
                                    class="modal-header modal_custom_header"
                                >
                                    <h4 style={{ color: 'white' }} class="modal-title">
                                        Details
								</h4>
                                    <button
                                        style={{ color: 'white' }}
                                        type="button"
                                        class="close"
                                        data-dismiss="modal"
                                    >
                                        &times;
								</button>
                                </div>
                                <div class="modal-body">
                                    <form onSubmit={e => this.onHandleSubmitUpdate(e)}>
                                        {modalData &&
                                            modalData.map((item, index) => (
                                                <div key={index}>
                                                    <fieldset disabled={!enabled}>
                                                        <div class='row'>

                                                            <TextInput
                                                                feildName={'modalTour'}
                                                                inputLabelName={'Tour'}
                                                                onHandleChange={this.onHandleTextChange}
                                                                errors={errors}
                                                                stateData={this.state}
                                                                placeholder={'Enter Tour'}
                                                                isValidte={false}
                                                            />

                                                            <SelectionInput
                                                                feildName={'sessionId'}
                                                                selectName={'Session'}
                                                                onHandleChange={e => this.onHandleSelectChange(e)}
                                                                errors={errors}
                                                                optionsArrys={sessionData}
                                                                selectedText={'Session'}
                                                                stateData={this.state}
                                                                // iconClassName={'fas fa-building'}
                                                                optionType="dynamicWithPropIdAndName"
                                                                property={'currentSession'}
                                                                propertyId={'sessionId'}
                                                                successflag={'successflag'}
                                                                isValidte={false}
                                                            // editUse={'staffEdit'}
                                                            />

                                                            <div class="col-md-6" >
                                                                <div class="md-form mb-0">
                                                                    <label for="email" class="">
                                                                        <p style={{ margin: 0, color: '#000' }}>
                                                                            Date
                                </p>
                                                                    </label>
                                                                    <br />
                                                                    <DatePicker
                                                                        onChange={e => this.handleChange(e)}
                                                                        value={tourDate}
                                                                        selectDateOnly
                                                                        disabled={!enabled}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div class="col-md-6" >
                                                                <div class="md-form mb-0">
                                                                    <label for="email" class="">
                                                                        <p style={{ margin: 0, color: '#000' }}>
                                                                            Time
                                </p>
                                                                    </label>
                                                                    <br />
                                                                    <TimePicker
                                                                        className="dateTimePicker"
                                                                        showSecond={false}
                                                                        placeholder={'Hr-Min-(AM/PM)'}
                                                                        name="tourTime"
                                                                        defaultValue={tourTime}
                                                                        onChange={e => this.onHandleTimeChange(e)}
                                                                        format={general.timeFormat}
                                                                        disabled={!enabled}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <TextInput
                                                                feildName={'month'}
                                                                inputLabelName={'Month'}
                                                                errors={errors}
                                                                stateData={this.state}
                                                                placeholder="Month"
                                                                readOnly={true}
                                                                type={'text'}
                                                                isValidte={false}
                                                            />

                                                            <TextArea
                                                                feildName={'description'}
                                                                inputLabelName={'Description'}
                                                                onHandleChange={this.onHandleTextChange}
                                                                errors={errors}
                                                                stateData={this.state}
                                                                // iconClassName={'fas fa-percentage'}
                                                                placeholder={'Add Description'}
                                                                isValidte={false}
                                                            />
                                                        </div>
                                                    </fieldset>
                                                    <br />
                                                    <br />
                                                    <button
                                                        disabled={enabled}
                                                        onClick={() => this.setState({ enabled: true })}
                                                        type="button"
                                                        class="btn btn-primary buttonAppear"
                                                        style={{background: context.themeColors.primaryColor}}
                                                    >
                                                        Edit
												</button>
                                                    <button
                                                        style={{ marginLeft: 5, background: context.themeColors.primaryColor }}
                                                        disabled={!enabled}
                                                        type="submit"
                                                        class="btn btn-primary buttonAppear"
                                                    >
                                                        Save
												</button>
                                                </div>
                                            ))}
                                    </form>
                                </div>
                                <div class="modal-footer">
                                    <button
                                        type="button"
                                        style={{ background: context.themeColors.primaryColor }}
                                        class="btn btn-danger"
                                        data-dismiss="modal"
                                    >
                                        Close
								</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal" id="myModal1">
                        <div class="modal-dialog">
                            <div class="modal-content modal_content_custom">
                                <div
                                    style={{
                                        borderBottomLeftRadius: 30,
                                        borderBottomRightRadius: 30,
                                        borderTopLeftRadius: 18,
                                        borderTopRightRadius: 18,
                                        background: context.themeColors.primaryColor
                                    }}
                                    class="modal-header modal_custom_header"
                                >
                                    <h4 style={{ color: 'white' }} class="modal-title">
                                        Confirm
								</h4>
                                    <button
                                        style={{ color: 'white' }}
                                        type="button"
                                        class="close"
                                        data-dismiss="modal"
                                    >
                                        &times;
								</button>
                                </div>
                                <div class="modal-body">
                                    {modalData &&
                                        modalData.map((item, index) => (
                                            <div key={index}>
                                                <div style={{ textAlign: 'center' }}>
                                                    <h4>Are you sure ?</h4>
                                                </div>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-evenly'
                                                    }}
                                                >
                                                    <div>
                                                        <button
                                                            disabled={this.state.enabled}
                                                            data-dismiss="modal"
                                                            onClick={() => this.onTakeItemDelete(id)}
                                                            type="button"
                                                            class="btn btn-primary buttonAppear"
                                                            style={{background: context.themeColors.primaryColor}}
                                                        >
                                                            Yes
													</button>
                                                    </div>
                                                    <div>
                                                        <button
                                                            data-dismiss="modal"
                                                            disabled={this.state.enabled}
                                                            type="button"
                                                            class="btn btn-primary buttonAppear"
                                                            style={{background: context.themeColors.primaryColor}}
                                                        >
                                                            No
													</button>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal" id="myModal2">
                        <div class="modal-dialog modal-lg modal-dialog-centered">
                            <div class="modal-content modal_content_custom">
                                <div
                                    style={{
                                        borderBottomLeftRadius: 30,
                                        borderBottomRightRadius: 30,
                                        borderTopLeftRadius: 18,
                                        borderTopRightRadius: 18,
                                        background: context.themeColors.primaryColor
                                    }}
                                    class="modal-header modal_custom_header"
                                >
                                    <h4 style={{ color: 'white' }} class="modal-title">
                                        Add Class
							</h4>
                                    <button
                                        style={{ color: 'white' }}
                                        type="button"
                                        class="close"
                                        data-dismiss="modal"
                                    >
                                        &times;
							</button>
                                </div>
                                <div class="modal-body">
                                    <div style={{ width: '100%' }}>
                                        <table class="table table-hover">
                                            <thead style={{ background: context.themeColors.primaryColor, color: 'white' }}>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Tour</th>
                                                    <th scope="col">Class</th>
                                                    <th scope="col">Section</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {TourGrades.map((item, index) => (
                                                    <tr key={index}>
                                                        <th scope={'row'}><text>{index + 1}</text></th>
                                                        <RenderDynamicRows
                                                            arrName={'TourGrades'}
                                                            rowIndex={index}
                                                            stateData={this.state}
                                                            itemObj={item}
                                                            classHandler={this.handleClassChange}
                                                            sectionHandler={this.handleSectionChange}
                                                            tour={tour}
                                                        />
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        <div className={'row'} style={{ justifyContent: 'flex-end', marginRight: 10 }}>
                                            <div>
                                                <button
                                                    id={'addBtn'}
                                                    onMouseEnter={this.btnTechHover()}
                                                    onClick={() =>
                                                        this.onClickAddItem('TourGrades', {
                                                            tourId: '',
                                                            classId: '',
                                                            sectionId: '',
                                                        })
                                                    }
                                                    style={{
                                                        background: '#0A4F5E',
                                                        color: '#FFFFFF',
                                                        borderRadius: 100
                                                    }}
                                                    type="button"
                                                    class="btn"
                                                >
                                                    <i class="fas fa-plus" />
                                                </button>
                                            </div>
                                            <div style={{ marginLeft: 8 }}>
                                                <button
                                                    onClick={() => this.onClickDeleteItem('TourGrades')}
                                                    style={{ color: '#FFFFFF', borderRadius: 100 }}
                                                    type="button"
                                                    class="btn btn-danger"
                                                >
                                                    <i class="far fa-trash-alt" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-primary buttonAppear" onClick={e => this.onHandleSubmit(e)} style={{background: context.themeColors.primaryColor}}>Post</button>
                                    <button type="button" class="btn btn-primary buttonAppear" data-dismiss="modal" style={{background: context.themeColors.primaryColor}}>Close</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between'
                        }}
                    >
                        <div>
                            <h2 className="h1-responsive font-weight-bold text-left my-4 generalHead" style={{background: context.themeColors.primaryColor}}>
                                {' '}
                    Tour Details{' '}
                            </h2>
                        </div>
                        <div
                            style={{ marginTop: 40, display: 'flex', flexDirection: 'column' }}
                        >
                            <nav aria-label="Page navigation example">
                                <ul style={{background: context.themeColors.primaryColor }} class="pagination">
                                    {renderPageNumbers}
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div >
                {isLoadingInitialData ? <Spinner /> : SearchContent}
                < br />

                {
                    isLoading ? (
                        <Spinner />
                    ) : (
                            <div id='div1' className="table-responsive">
                                <div class="">
          <img
            src={require("../../Assets/images/header.png")}
            style={{
              width: "100%",
              height: "150",
            }}
          />
        </div>
                                <table class="table table-hover">
                                    <thead class='headCol'  style={{ background: context.themeColors.primaryColor, color: 'white' }}>
                                        <tr class="vendorListHeading">
                                            <th scope="col">#</th>
                                            {tHead.map(item => (
                                                <th
                                                    style={{
                                                        border: `1px solid ${Colors.SEA_GREEN_THEME}`,
                                                        borderColor: Colors.WHITE
                                                    }}
                                                    className={item == "Actions" ? "no-print" : ''}
                                                    scope="col"
                                                >
                                                    {item}
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>{MainContent(tourData)}</tbody>
                                </table>
                                <div class="page-footer">
            <div class="text-left">Printed By: {this.props.role}</div>
            <div class="text-right" style={{ marginTop: -25, marginRight: 20 }}>
              Powered by School Smart®
            </div>
          </div>
                            </div>
                        )
                }
                <div class="text-center text-md-right">
                    <label for="name" class="" onClick={() => this.PrintContent('div1')}>
                        <i
                            className="fas fa-print fa-2x animated fadeIn"
                            style={{ marginRight: 4 }}
                        />
                        Print
                            </label>
                </div>
            </div >
                                    )}
                                    </ThemeContext.Consumer>
        )
    }
}

function mapStateToProps(state) {
    return {
        name: state.setUserReducer.users.unique_name,
        role: state.setUserReducer.users.role,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        sharedActions: bindActionCreators(sharedActions, dispatch)
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewTour);