import React, { Component } from 'react';
import '../Admin/Add_Admin_Component/AddGm.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as addActions from '../../Actions/Admission';
import * as sharedActions from '../../Actions/sharedActions';
import RenderAddFormData from '../../Container/Admission/RenderAddFormData';
import RenderArrayData from '../../Container/Admission/RenderArrayData';
const AddressIcons = [
	'fas fa-building animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn'
];
const studentFormIcons = [
	'fas fa-registered animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-male animated fadeIn',
	'fas fa-user animated fadeIn',
	'fas fa-list-ol animated fadeIn',
	'fas fa-book-reader animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-bars animated fadeIn',
	'fas fa-language animated fadeIn',
	'fas fa-restroom animated fadeIn',
	'fas fa-birthday-cake animated fadeIn',
	'fas fa-sort-numeric-down animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-pray animated fadeIn',
	'fas fa-building animated fadeIn',
	'fas fa-bars animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-key animated fadeIn',
	'fas fa-poll animated fadeIn',
	'fas fa-signal animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn'
];
const parentInfoIcons = [
	'fas fa-building animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn'
];
const studentDocumentIcons = [
	'fas fa-building animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn'
];
const parentDocIcons = [
	'fas fa-building animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn'
];
const medConIcons = [
	'fas fa-building animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn'
];
const widthDrawIcons = [
	'fas fa-building animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn'
];
const otherInfosIcons = [
	'fas fa-building animated fadeIn',
	'fas fa-user-shield animated fadeIn'
];
const studentTransIcons = [
	'fas fa-building animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn'
];
const siblingIcons = [
	'fas fa-building animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn'
];
const previousSchoolIcons = [
	'fas fa-building animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn'
];

class PrintAdmissionForm extends Component {
	state = {
		getSingleAddData: [],
		isLoader: true
	};
	PrintContent = el => {
		var restorepage = document.body.innerHTML;
		var Printcontent = document.getElementById(el).innerHTML;
		document.body.innerHTML = Printcontent;
		window.print();
		document.body.innerHTML = restorepage;
	};
	componentDidMount() {
		const { match } = this.props;
		this.props.shareAction
			.gAllData(
				`/api/StudentAdmissionForm/${match.params.id}`,
				this.props.addAction.getIndividualStudentData
			)
			.then(success => {
				this.setState({
					isLoader: false
				});
				console.log(success);
			})
			.catch(error => {
				this.setState({
					isLoader: false
				});
			});
	}
	static getDerivedStateFromProps(nextProps, prevState) {
		if (prevState.getSingleAddData !== nextProps.getData) {
			return {
				getSingleAddData: nextProps.getData
			};
		}
		return null;
	}
	render() {
		const { AddData, match } = this.props;
		const { getSingleAddData } = this.state;
		let medical = [getSingleAddData && getSingleAddData.MedicalConditions];
		let student = [getSingleAddData && getSingleAddData.StudentAdmissionForms];
		let parentInfo = [getSingleAddData && getSingleAddData.ParentInfos];
		let otherInfo = [getSingleAddData && getSingleAddData.OtherInformations];
		let parentDocs = [getSingleAddData && getSingleAddData.ParentDocs];
		let prevSchoolDetails = [
			getSingleAddData && getSingleAddData.PreviousSchoolDetails
		];
		let studentAddresss = [
			getSingleAddData && getSingleAddData.StudentAddresss
		];
		//  let studentADDRESS = {
		//      Address : studentAddresss[0].address,
		//      ['House No'] : studentAddresss[0].houseNo,
		//      ['Student Id Card No'] : studentAddresss[0].studentIdCardNo,
		//      ['Student Email Id'] :studentAddresss[0].studentEmailId,
		//      ['Way No'] : studentAddresss[0].wayNo,
		//      ['Street No'] : studentAddresss[0].streetNo,
		//      ['Area'] : studentAddresss[0].area,
		//      City:studentAddresss[0].city,
		//      Country:studentAddresss[0].country

		//  }
		let studentDocs = [getSingleAddData && getSingleAddData.StudentDocs];
		let studentImageUpload = [
			getSingleAddData && getSingleAddData.StudentImageUpload
		];
		let studentSiblingDetails = [
			getSingleAddData && getSingleAddData.StudentSiblingDetails
		];
		let studentTransports = [
			getSingleAddData && getSingleAddData.StudentTransports
		];
		let widthdrawDetails = [
			getSingleAddData && getSingleAddData.WidthdrawDetails
		];
		let getAllData = [
			...student,
			...studentAddresss,
			...parentInfo,
			...studentDocs,
			...parentDocs,
			...medical,
			[...prevSchoolDetails],
			...widthdrawDetails,
			...otherInfo,
			...studentTransports
		];
		console.log('This is :' + getAllData);
		const Loader = (
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<div class="spinner-border" style={{ color: '#01AC8A' }}></div>
			</div>
		);
		const Content = (
			<div>
				<div id="div1">
					<section class="mb-4" style={{ marginTop: -50 }}>
						<div
							style={{
								justifyContent: 'center',
								textAlign: 'center',
								height: 180
							}}
						>
							<input
								type="image"
								src={require('../../Assets/images/logo1.png')}
								style={{ width: 350, height: 250 }}
							/>
						</div>
						<div style={{ justifyContent: 'center', textAlign: 'center' }}>
							<div class="col-md-12">
								Address: 798 South Park Avenue, Mascat, Oman <br /> Email:
								<a href="mailto:#">schoolsmart@gmail.com</a> <br />
								Phone:
								<a href="tel:#">+91 7568543012</a>
							</div>
						</div>
						<br />
						<br />
						<div class="row">
							<div class="col-md-12">
								<div class="md-form mb-0">
									<hr class="style-four" />
								</div>
							</div>
						</div>

						<p class="text-center w-responsive mx-auto mb-5" />
						<div
							class="row"
							style={{
								justifyContent: 'center'
							}}
						>
							<div class="col-md-9 mb-md-0 mb-5">
								<div class="row">
									<div class="col-md-4">
										<div class="md-form mb-0">
											<div style={{ float: 'center' }}>
												<h4 class="h1-responsive font-weight-bold  my-4">
													Admission Form:
												</h4>
											</div>
										</div>
									</div>
								</div>
								<div class="row">
									<RenderAddFormData
										sectionType="StudentAddmission"
										itemObj={student}
										Icons={studentFormIcons}
									/>
								</div>
								<div class="row">
									<div class="col-md-4">
										<div class="md-form mb-0">
											<div style={{ float: 'left' }}>
												<h4 class="h1-responsive font-weight-bold  my-4">
													Student Address:
												</h4>
											</div>
										</div>
									</div>
								</div>
								<br />

								<div class="row">
									<RenderAddFormData
										sectionType="StudentAddress"
										itemObj={studentAddresss}
										Icons={AddressIcons}
									/>
								</div>

								<div class="row">
									<div class="col-md-4">
										<div class="md-form mb-0">
											<div style={{ float: 'left' }}>
												<h4 class="h1-responsive font-weight-bold  my-4">
													Parent Info:
												</h4>
											</div>
										</div>
									</div>
								</div>
								<br />

								<div class="row">
									<RenderAddFormData
										sectionType="ParentInfos"
										itemObj={parentInfo}
										Icons={parentInfoIcons}
									/>
								</div>
								<div class="row">
									<div class="col-md-4">
										<div class="md-form mb-0">
											<div style={{ float: 'left' }}>
												<h4 class="h1-responsive font-weight-bold  my-4">
													Student Documents:
												</h4>
											</div>
										</div>
									</div>
								</div>
								<br />
								<div class="row">
									<RenderAddFormData
										sectionType="StudentDocs"
										itemObj={studentDocs}
										Icons={studentDocumentIcons}
									/>
								</div>

								<div class="row">
									<div class="col-md-4">
										<div class="md-form mb-0">
											<div style={{ float: 'left' }}>
												<h4 class="h1-responsive font-weight-bold  my-4">
													Parent Documents:
												</h4>
											</div>
										</div>
									</div>
								</div>
								<br />

								<div class="row">
									<RenderAddFormData
										sectionType="ParentDocs"
										itemObj={parentDocs}
										Icons={parentDocIcons}
									/>
								</div>

								<div class="row">
									<div class="col-md-4">
										<div class="md-form mb-0">
											<div style={{ float: 'left' }}>
												<h4 class="h1-responsive font-weight-bold  my-4">
													Medical Condition:
												</h4>
											</div>
										</div>
									</div>
								</div>
								<br />

								<form id="contact-form" name="contact-form">
									<div class="row">
										<RenderAddFormData
											sectionType="MedicalCon"
											itemObj={medical}
											Icons={medConIcons}
										/>
									</div>
									<br />
									<div class="row">
										<div class="col-md-4">
											<div class="md-form mb-0">
												<div style={{ float: 'left' }}>
													<h4 class="h1-responsive font-weight-bold  my-4">
														Sibling Details:
													</h4>
												</div>
											</div>
										</div>
									</div>
									<br />
									<form id="contact-form" name="contact-form">
										<div class="row">
											<RenderArrayData
												sectionType="SiblingDetails"
												data={studentSiblingDetails}
												Icons={siblingIcons}
											/>
											{/* {studentSiblingDetails && studentSiblingDetails[0].map((item,index)=>(
                                      <RenderAddFormData
                                      sectionType='SiblingDetails' key ={index} itemObj={item} Icons={siblingIcons}
                                      />                                 
                                      ))} */}
										</div>
									</form>

									<div class="row">
										<div class="col-md-4">
											<div class="md-form mb-0">
												<div style={{ float: 'left' }}>
													<h4 class="h1-responsive font-weight-bold  my-4">
														Previous School Details:
													</h4>
												</div>
											</div>
										</div>
									</div>
									<br />

									<form id="contact-form" name="contact-form">
										<div class="row">
											<RenderArrayData
												sectionType="PreviousSchoolDetails"
												data={prevSchoolDetails}
												Icons={previousSchoolIcons}
											/>
										</div>
									</form>

									<div class="row">
										<div class="col-md-4">
											<div class="md-form mb-0">
												<div style={{ float: 'left' }}>
													<h4 class="h1-responsive font-weight-bold  my-4">
														Withdraw Details:
													</h4>
												</div>
											</div>
										</div>
									</div>
									<br />

									<div class="row">
										<RenderAddFormData
											sectionType="WidthDraw"
											itemObj={widthdrawDetails}
											Icons={widthDrawIcons}
										/>
									</div>

									<br />

									<div class="row">
										<div class="col-md-4">
											<div class="md-form mb-0">
												<div style={{ float: 'left' }}>
													<h4 class="h1-responsive font-weight-bold  my-4">
														Other Information:
													</h4>
												</div>
											</div>
										</div>
									</div>
									<br />

									<div class="row">
										<RenderAddFormData
											sectionType="OtherInfo"
											itemObj={otherInfo}
											Icons={otherInfosIcons}
										/>
									</div>
									<div class="row">
										<div class="col-md-4">
											<div class="md-form mb-0">
												<div style={{ float: 'left' }}>
													<h4 class="h1-responsive font-weight-bold  my-4">
														Student Transport:
													</h4>
												</div>
											</div>
										</div>
									</div>

									<div class="row">
										<RenderAddFormData
											sectionType="StudentTransport"
											itemObj={studentTransports}
											Icons={studentTransIcons}
										/>
									</div>
								</form>
							</div>
						</div>
					</section>
				</div>
				<div class="text-center text-md-right">
					<label for="name" class="" onClick={() => this.PrintContent('div1')}>
						<i
							className="fas fa-print fa-2x animated fadeIn"
							style={{ marginRight: 4 }}
						/>
						Print It Out
					</label>
				</div>
			</div>
		);
		return <div>{this.state.isLoader ? Loader : Content}</div>;
	}
}
function mapDispatchToProps(dispatch) {
	return {
		addAction: bindActionCreators(addActions, dispatch),
		shareAction: bindActionCreators(sharedActions, dispatch)
	};
}
function mapStateToProps(state) {
	return {
		getData: state.setAdmissionReducer.getSingleFormData
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PrintAdmissionForm);
