import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import $ from 'jquery';
import isEmpty from 'lodash/isEmpty';
import Header from '../../Assets/images/3 headers-03.png';
import moment from 'moment'

import ThemeContext from "../../context/themeContext/ThemeContext";


class PrintMonthlyStudentAttendanceReport extends Component {
    constructor(props){
        super(props);
        this.state = {
            payrollData: [],
            search: '',
            isLoading: false,
            modalEdit: false,
            editToggle: false,
            generateToggle: false,
            generateIndex: '',
            msg: '',

			accountId: this.props.userData.unique_name,
			accountType: this.props.userData.role

        }

        


    }
	static contextType = ThemeContext

    componentDidMount(){

    }

    
    PrintContent = el => {
        var restorepage = document.body.innerHTML;
        var Printcontent = document.getElementById(el).innerHTML;
        document.body.innerHTML = Printcontent;
        
        setTimeout(() => {
            window.print();
            window.location.reload();
        }, 700)
        document.body.innerHTML = restorepage;
    };


    myFunction = () => {
		// Get the snackbar DIV
		var x = document.getElementById('snackbar');

		// StepperGeneralButtons the "show" class to DIV
		x.className = 'show';

		// After 3 seconds, remove the show class from DIV
		setTimeout(function() {
		}, 3000);
	};



    render(){
        const { isLoading, accountId, accountType, } = this.state;
        const { studentAttendance, month, year, session, classSectionGrade, classId } = this.props.location.state

        const Loader = (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div className="spinner-border" style={{ color: this.context.themeColors.primaryColor }}></div>
            </div>
        );

        const renderStudentAttendanceData = studentAttendance && studentAttendance.datelist.map((item, index) => {
            return item <= moment().format('MM/DD/YYYY') && (

            <tr key={index} >
                <th scope="row" style={{ padding: 1 }} >
                    {item}
                </th>
                {classSectionGrade && classSectionGrade.subject.map((data, index) => {
                    if(data.classId == classId){

                    var fill = studentAttendance.dateWise.filter(data2 => {
                        return data2.subjectId == data.subjectId && data2.date == item
                    })
                    console.log(fill)
                    return fill.length == 1 && fill[0].date == item ? <td className="borderSpace" style={{ padding: 1 } && fill[0].attendance[0].attendanceStatus == 'Present' ? fill[0].attendance[0].attendanceStatus == 'Present' && fill[0].attendance[0].lateTime == 'yes' ? { color: 'orange'} :  { color: 'green'} : { color: 'red'}}  >
                    {fill[0].attendance[0].attendanceStatus.substring(0,1) == 'A' && 
                    `${fill[0].attendance[0].attendanceStatus.substring(0,1)} - ${fill[0].attendance[0].leaveType.substring(0,1) == 'E' ? 'E' : 'NE'}`}
                    {fill[0].attendance[0].attendanceStatus.substring(0,1) == 'P' && 
                    `${fill[0].attendance[0].attendanceStatus.substring(0,1)} ${fill[0].attendance[0].lateTime == 'yes' ? ' - Late' : ''}`}
                </td> : <td className="borderSpace" style={{ padding: '1.5%' }}></td>
                    }
                })}
            </tr>
            
            );
        }) 

        const renderSubjectList =  classSectionGrade && classSectionGrade.subject.map((data, index) => {
            return data.classId == classId &&  <th scope="col" style={{ padding: 1 }} key={data.subjectId} >
                {data.subjectName}
            </th>
        })



        return (
            <div className="page-header"> 
                <div id="printPage" style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                }}>
                    <div class="row" >
								<div
									style={{
                                        flex: 1,
                                        width: '100%',
                                        height: '100%'
									}}
								>
									<img src={Header} width={'100%'} height={'100%'} />
								</div>
						</div>

                    <div class="row my-2 mx-2  colCheckb justify-content-between" style={{marginTop: '2%'}} >
							<div class="text-center col-xs-12 col-sm-12 col-md-12">
								<h3>Monthly Student Attendance Report</h3>
								
							</div>
						</div>
                        <div class="row my-2 mx-2  colCheckb justify-content-between" style={{paddingLeft: '20px'}} >
                            <div class="col-md-4" >
                                    <span>Student Name: <strong style={{color: this.context.themeColors.primaryColor}} >{studentAttendance && studentAttendance.info.studentName}</strong></span>     
                            </div>
                            <div class="col-md-4"  >
                                    <span>Class: <strong style={{color: this.context.themeColors.primaryColor}} >{studentAttendance && studentAttendance.info.className}</strong></span>     
                            </div>
                            <div class="col-md-4" >
                                    <span>Section: <strong style={{color: this.context.themeColors.primaryColor}} >{studentAttendance && studentAttendance.info.section}</strong></span>     
                            </div>
                            <div class="col-md-4" >
                                    <span>Month: <strong style={{color: this.context.themeColors.primaryColor}} >{studentAttendance && month}</strong></span>     
                            </div>
                            <div class="col-md-4" >
                                    <span>Year: <strong style={{color: this.context.themeColors.primaryColor}} >{studentAttendance && year}</strong></span>     
                            </div>
                            <div class="col-md-4" >
                                    <span>Session: <strong style={{color: this.context.themeColors.primaryColor}} >{studentAttendance && session}</strong></span>     
                            </div>
                        </div>
                    <div className="table-responsive">
					<table className="table table-hover table-bordered text-center">
						<thead style={{ background: this.context.themeColors.primaryColor, color: 'white' }}>
							<tr>
								<th scope="col" style={{ padding: 1 }}>
									Date
								</th>
                                {renderSubjectList}
							</tr>
						</thead>
						<tbody>{isLoading ? Loader : renderStudentAttendanceData}</tbody>
					</table>
				</div>
                </div>
                <div class="row" style={{marginBottom: '2%', marginRight: '1%'}} >
                    <div class="col-md-4">
                            <span>Printed By: <strong style={{color: this.context.themeColors.primaryColor}} >{this.props.userData.unique_name}</strong></span>     
                    </div>
                    <div class="col-md-4 text-center">
                             
                            <span>Role: <strong style={{color: this.context.themeColors.primaryColor}} >{this.props.userData.role}</strong></span>     

                    </div>
                    <div class="col-md-4 text-right">
                             
                            <span>Time: <strong style={{color: this.context.themeColors.primaryColor}} >{moment().format('HH:mm - MM/DD/YYYY')}</strong></span>     

                    </div>
                </div>
                <div class="row" style={{marginBottom: '2%', marginRight: '1%'}} >
                    <div class="col-md-12 text-right">
                    <button class="btn btn-primary buttonAppear no-print" onClick={() => this.PrintContent('printPage')}>
                            <i class="fas fa-print"></i> Print
                        </button>
                    </div>
                </div>
            </div>
        );
    }

}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch),
	};
}
function mapStateToProps(state) {
	return {
		userData: state.setUserReducer.users,
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PrintMonthlyStudentAttendanceReport);
