import React, { Component } from 'react';
import './ClassSection.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as CategoryWiseActions from '../../Actions//CategoryWiseSearch';
import * as sharedActions from '../../Actions/sharedActions';
import CategorySubComponent from './CategorySubComponent';

class CategoryWiseSearch extends Component {
	constructor(props) {
		super(props);
		this.state = {
			sessions: []
		};
	}
	static getDerivedStateFromProps(nextProps, prevState) {
		if (prevState.sessions !== nextProps.selectSession) {
			return {
				sessions: nextProps.selectSession
			};
		}
		return null;
	}
	componentDidMount() {
		this.props.sharedActions
			.gAllData(
				'/api/Session',
				this.props.CategoryWiseActions.getSessionsForCategoryWise
			)
			.then(success => {})
			.catch(error => {});
	}
	render() {
		const { session } = this.state.sessions;
		console.log('Sessions', session);
		return (
			<div>
				<div style={{ justifyContent: 'center', textAlign: 'center' }}>
					<CategorySubComponent session={session} />
				</div>
			</div>
		);
	}
}
function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch),
		CategoryWiseActions: bindActionCreators(CategoryWiseActions, dispatch)
	};
}

function mapStateToProps(state) {
	return {
		userData: state.setUserReducer.users,
		selectSession: state.setCategoryWiseReducer.selectSession
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CategoryWiseSearch);
