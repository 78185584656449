import * as actionTypes from '../actionTypes';

export function setAllFinanceInRedux(allData) {
	return {
		type: actionTypes.GET_ALL_FINANCE,
		payload: allData
	};
}
export function getSessionForFinance(allData) {
	return {
		type: actionTypes.SET_ALL_SESSION_NAME_INTO_FINANCE_SELECT,
		payload: allData
	};
}
export function addRecordToFinance(eachRecord) {
	return {
		type: actionTypes.Add_Recrod_To_FINANCE,
		payload: eachRecord
	};
}

export function addFinance(Data) {
	return {
		type: actionTypes.ADD_FINANCE,
		payload: Data
	};
}
export function updateFinanceFlag(setFlag) {
	return {
		type: actionTypes.SET_LOADER_FLAG_FINANCE_ADD,
		payload: setFlag
	};
}
export function updateFinance(data) {
	return {
		type: actionTypes.EDIT_FINANCE_RECORD,
		payload: data
	};
}
export function deleteFinance(id) {
	return {
		type: actionTypes.DELETE_FINANCE,
		payload: id
	};
}
