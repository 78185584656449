import React, { Components } from 'react';

const RenderingObjects = props => {
	const { sectionType, obj, indVal, Icons } = props;
	let siblingDetails = {
		'Sibling Name: ': obj.siblingName,
		'Age :': obj.age,
		'Relation: ': obj.relation,
		'Education:': obj.education,
		'School :': obj.school
	};
	let prevSchoolDetails = {
		'Previous School Name: ': obj.prevSchoolName,
		'Enroll No :': obj.enrollNo,
		'From Year :': obj.fromYear,
		'Year Upto :': obj.yearUpto,
		'Reason Leaving :': obj.reasonLeaving
	};
	let keys = null;
	switch (sectionType) {
		case 'SiblingDetails':
			keys = Object.keys(siblingDetails);
			break;
		case 'PreviousSchoolDetails':
			keys = Object.keys(prevSchoolDetails);
			break;

		default:
			break;
	}
	return (
		<div>
			{keys.map((item, index) => (
				<div style={{ padding: 10 }} class="col-md-5">
					<div class="md-form mb-0">
						<label for="name" class="" style={{ fontWeight: 'bold' }}>
							<i className={`${Icons[index]}`} style={{ marginRight: 4 }} />
							{item}
						</label>
						<label style={{ marginLeft: 6 }}>
							{sectionType === 'SiblingDetails'
								? siblingDetails[`${item}`]
								: sectionType === 'PreviousSchoolDetails'
								? prevSchoolDetails[`${item}`]
								: ''}
						</label>
					</div>
				</div>
			))}
		</div>
	);
};
export default RenderingObjects;
