import React, { useEffect, useState } from "react";
import useHttp from "../../../hooks/http.js";
import CustomReportComponent from "../../../components/Reports/CustomReportComponent.jsx";
import img1 from "../../../Assets/images/newheader.png";
import CustomReportHeader from "../../../components/Reports/CustomReportHeader/CustomReportHeader.jsx";
import CustomHeading from "../../../components/CustomHeading";
import { MAIN_HEADING } from "../../../utils/general/index.js";
import useCustomHttpWithDataMiddleware from "../../../hooks/Services/httpWithCustomDataMiddleware.js";
import GraphGeneralLayout from "../../../components/Graphs/GraphGeneralLayout/GraphGeneralLayout.jsx";
import { Bar, Line, Pie } from "react-chartjs-2";
import * as sharedActions from "../../../Actions/sharedActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import $ from "jquery";
import CustomBadge from "../../../components/CustomBadge/CustomBadge.jsx";
import Colors from "../../../utils/app_constants/colors_constants.js";
import SelectionInput from "../../../components/SelectionInput/SelectionInput.jsx";
import httpWithRequiredSetData from "../../../hooks/Services/httpWithRequiredSetData.js";

function CaAverageReportSheet(props) {
  const [data1, setData1] = useState({
    datasets: [
      {
        barPercentage: 0.4,
        minBarLength: 2,
        label: "Class Grades",
        data: [3, 2, 2, 3, 3, 4, 3, 2],
        backgroundColor: [
          "#ea766c",
          "#abe2b9",
          "#adbceb",
          "#be9e9d",
          "#9d5b4d",
          "#e4d86b",
          "#9ecf54",
          "#e4d86b",
        ],
        borderColor: [
          "#ea766c",
          "#abe2b9",
          "#adbceb",
          "#be9e9d",
          "#9d5b4d",
          "#e4d86b",
          "#9ecf54",
          "#e4d86b",
        ],
        borderWidth: 2,
      },
    ],

    // These labels appear in the legend and in the tooltips when hovering different arcs
    labels: ["BIO", "ICT", "MATH", "SOCIAL", "PHYS", "CHEM", "ENG", "ISL"],
    options: {},
  });

  const { pid } = props.match.params;
  const [graphType, setGraphType] = useState("barChart");
  const [graphType1, setGraphType1] = useState("barChart");

  const [graphData, setGraphData] = useState({});
  const [collectGradeData, setGradeData] = useState([]);
  const [numberGrades, setNumberGrade] = useState([]);
  const [isLoading, remoteData] = useHttp(`/api/GetToolGraph?pId=${pid}`, []);
  const [isLoader, fetchData] = useHttp(`/api/View3?pId=${pid}`, []);
  const [averageData, setAverageData] = useState([]);
  const [isLoader1, fetchData1, setFetchData1] = httpWithRequiredSetData(
    `/api/View3?pId=${pid}`,
    [],
    (remoteData) => {
      let collectMax = [];
      let max = 0;
      let getRemoteDataClone = JSON.parse(JSON.stringify(remoteData));
      let getOriginalArray = getRemoteDataClone["toolView3"].map(
        (item) => item.grade
      );
      debugger;
      let getGradeUniqueData = new Set([
        ...getRemoteDataClone["toolView3"].map((item) => item.grade),
      ]);
      setGradeData(getGradeUniqueData);
      let getnewArray = [...getGradeUniqueData];
      debugger;
      for (let i = 0; i < getnewArray.length; i++) {
        for (let j = 0; j < getOriginalArray.length; j++) {
          if (getnewArray[i] === getOriginalArray[j]) {
            max += 1;
          }
        }
        collectMax.push(max);
        max = 0;
      }
      console.log(collectMax);
      setNumberGrade(collectMax);
      let getData1 = $.extend(true, {}, data1);
      getData1["datasets"][0]["data"] = [...collectMax];
      getData1["labels"] = [...getnewArray];

      setData1(getData1);

      debugger;
      console.log(new Set([...getGradeUniqueData]));
      debugger;
      let getAverageCloneTools = [
        ...getRemoteDataClone["overAllAverageTool"],
        {
          toolId: 2399999999,
          pId: 239992222225,
          toolName: "Over All Ca Mark",
          avg: getRemoteDataClone["overAllCaMark"],
        },
        {
          toolId: 239999994,
          pId: 239992222224,

          toolName: "Over All Exam Mark",
          avg: getRemoteDataClone["overAllExamMark"],
        },
        {
          toolId: 239999993,
          pId: 239992222223,

          toolName: "Over All Final Total",
          avg: getRemoteDataClone["overAllFinalTotal"],
        },
        {
          toolId: 239999992,
          pId: 239992222222,
          toolName: "Over All Grade",
          avg: getRemoteDataClone["overAllGrade"],
        },
        {
          toolId: 239999991,
          pId: 239992222221,

          toolName: "Over All Remarks",
          avg: getRemoteDataClone["overAllRemarks"],
        },
      ];
      let getCloneTools = [
        ...getRemoteDataClone["tools"],
        {
          id: 2399999999,
          pId: 239992222225,
          toolName: "CA Total",
        },
        {
          id: 239999994,
          pId: 239992222224,
          toolName: "Exam Mark",
        },
        {
          id: 239999993,
          pId: 239992222223,
          toolName: "Final Total",
        },
        {
          id: 239999992,
          pId: 239992222222,
          toolName: "Grade (A-F)",
        },
        {
          id: 239999991,
          pId: 239992222221,
          toolName: "Remarks",
        },
      ];
      let newStudentStructure = getRemoteDataClone["toolView3"].map(
        (item, index) => {
          return {
            ...item,
            ToolMarkView3: [
              ...item["ToolMarkView3"],
              {
                toolId: 2399999999,
                pId: 239992222225,
                toolName: "CA Total",
                avg: item["caMark"],
              },
              {
                toolId: 239999994,
                pId: 239992222224,
                toolName: "Exam Mark",
                avg: item["examMark"],
              },
              {
                toolId: 239999993,
                pId: 239992222223,
                toolName: "Final Total",
                avg: item["finalTotal"],
              },
              {
                toolId: 239999992,
                pId: 239992222222,
                toolName: "Grade (A-F)",
                avg: item["grade"],
              },
              {
                toolId: 239999991,
                pId: 239992222221,
                toolName: "Remarks",
                avg: item["remarks"],
              },
            ],
          };
        }
      );
      let getGraphData = [
        ...getRemoteDataClone["overAllAverageTool"],
        {
          toolId: 2399999999,
          pId: 239992222225,
          toolName: "Over All Ca Mark",
          avg: getRemoteDataClone["overAllCaMark"],
        },
        {
          toolId: 239999994,
          pId: 239992222224,

          toolName: "Over All Exam Mark",
          avg: getRemoteDataClone["overAllExamMark"],
        },
        {
          toolId: 239999993,
          pId: 239992222223,

          toolName: "Over All Final Total",
          avg: getRemoteDataClone["overAllFinalTotal"],
        },
      ];
      let getData = $.extend(true, {}, data);
      getData["datasets"][0]["data"] = getGraphData.map((item) => item.avg);
      getData["labels"] = getGraphData.map((item) => item.toolName);

      setData(getData);
      // setAverageData(getGraphData);
      let changeStructuress = {
        ...getRemoteDataClone,
        tools: [...getCloneTools],
        toolView3: [
          ...newStudentStructure,
          {
            studentId: 3999,
            studentName: "Average",
            ToolMarkView3: [...getAverageCloneTools],
          },
        ],
      };

      setFetchData1(changeStructuress);
    }
  );

  const returnGraphs1 = (type) => {
    switch (type) {
      case "barChart":
        return <Bar height={60} data={data1} />;

      case "pieChart":
        return <Pie height={60} data={data1} />;

      case "lineChart":
        return <Line height={60} data={data1} />;

      default:
        break;
    }
  };
  const returnGraphs = (type) => {
    switch (type) {
      case "barChart":
        return <Bar height={60} data={data} />;

      case "pieChart":
        return <Pie height={60} data={data} />;

      case "lineChart":
        return <Line height={60} data={data} />;

      default:
        break;
    }
  };
  const [data, setData] = useState({
    datasets: [
      {
        barPercentage: 0.4,
        minBarLength: 2,
        label: "Work Average",
        data: [3, 2, 2, 3, 3, 4, 3, 2],
        backgroundColor: [
          "#ea766c",
          "#abe2b9",
          "#adbceb",
          "#be9e9d",
          "#9d5b4d",
          "#e4d86b",
          "#9ecf54",
          "#e4d86b",
        ],
        borderColor: [
          "#ea766c",
          "#abe2b9",
          "#adbceb",
          "#be9e9d",
          "#9d5b4d",
          "#e4d86b",
          "#9ecf54",
          "#e4d86b",
        ],
        borderWidth: 2,
      },
    ],

    // These labels appear in the legend and in the tooltips when hovering different arcs
    labels: ["BIO", "ICT", "MATH", "SOCIAL", "PHYS", "CHEM", "ENG", "ISL"],
    options: {},
  });

  useEffect(() => {
    console.log(data);

    let getData = $.extend(true, {}, data);
    getData["datasets"][0]["data"] = averageData.map((item) => item.avg);
    setData(getData);
  }, []);

  const PrintContent = () => {
    // Previous code mentioned below

    setTimeout(() => {
      window.print();
      window.location.reload();
    }, 700);
  };

  const renderTools = (toolName, arr) => {
    return arr.map((item, index) => <div id="mySpan">{item.subToolName}</div>);
  };

  let tools = [
    {
      pId: 2,
      toolId: 7,
      toolName: "Home Work",
      subTools: [
        {
          id: 15,
          toolId: 7,
          toolName: "Home Work",
          pId: 2,
          subToolName: "HW1",
          min: 0,
          max: 15,
        },
        {
          id: 16,
          toolId: 7,
          toolName: "Home Work",
          pId: 2,
          subToolName: "HW2",
          min: 0,
          max: 15,
        },
        {
          id: 17,
          toolId: 7,
          toolName: "Home Work",
          pId: 2,
          subToolName: "HW AVG",
          min: 0,
          max: 15,
        },
      ],
    },
    {
      pId: 2,
      toolId: 8,
      toolName: "Class Work",
      subTools: [
        {
          id: 18,
          toolId: 8,
          toolName: "Class Work",
          pId: 2,
          subToolName: "CW1",
          min: 0,
          max: 15,
        },
        {
          id: 19,
          toolId: 8,
          toolName: "Class Work",
          pId: 2,
          subToolName: "CW2",
          min: 0,
          max: 15,
        },
        {
          id: 20,
          toolId: 8,
          toolName: "Class Work",
          pId: 2,
          subToolName: "CW AVG",
          min: 0,
          max: 15,
        },
      ],
    },
    {
      pId: 2,
      toolId: 9,
      toolName: "Total of CA",
      subTools: [
        {
          id: 21,
          toolId: 9,
          toolName: "Total of CA",
          pId: 2,
          subToolName: "CA",
          min: 0,
          max: 30,
        },
      ],
    },
  ];

  let toolView3 = [
    {
      studentId: 11,
      studentName: "Ahad Raza",
      pId: 2,
      ToolMarkView3: [
        {
          id: 29,
          toolId: 7,
          toolName: "Home Work",
          subToolId: 15,
          subToolName: "HW1",
          pId: 2,
          studentId: 11,
          marks: 12.0,
        },
        {
          id: 30,
          toolId: 7,
          toolName: "Home Work",
          subToolId: 16,
          subToolName: "HW2",
          pId: 2,
          studentId: 11,
          marks: 13.0,
        },
        {
          id: 31,
          toolId: 7,
          toolName: "Home Work",
          subToolId: 17,
          subToolName: "HW AVG",
          pId: 2,
          studentId: 11,
          marks: 12.5,
        },
        {
          id: 32,
          toolId: 8,
          toolName: "Class Work",
          subToolId: 18,
          subToolName: "CW1",
          pId: 2,
          studentId: 11,
          marks: 14.0,
        },
        {
          id: 33,
          toolId: 8,
          toolName: "Class Work",
          subToolId: 19,
          subToolName: "CW2",
          pId: 2,
          studentId: 11,
          marks: 10.0,
        },
        {
          id: 34,
          toolId: 8,
          toolName: "Class Work",
          subToolId: 20,
          subToolName: "CW AVG",
          pId: 2,
          studentId: 11,
          marks: 12.0,
        },
        {
          id: 35,
          toolId: 9,
          toolName: "Total of CA",
          subToolId: 21,
          subToolName: "CW",
          pId: 2,
          studentId: 11,
          marks: 24.5,
        },
      ],
    },
    {
      studentId: 25,
      studentName: "Rafay Khan",
      pId: 2,
      ToolMarkView3: [],
    },
    {
      studentId: 26,
      studentName: "Taimoor Shah",
      pId: 2,
      ToolMarkView3: [],
    },
    {
      studentId: 27,
      studentName: "Nakash Shah",
      pId: 2,
      ToolMarkView3: [],
    },
    {
      studentId: 28,
      studentName: "Wazeer Khan",
      pId: 2,
      ToolMarkView3: [],
    },
  ];
  let getToolsId = fetchData1 && fetchData1["tools"].map((item) => item.id);

  let getMarksCA_MARKS =
    fetchData1 && fetchData1["toolView3"].map((item) => item["ToolMarkView3"]);

  const simpleFilter = (arr, Value) => {
    let getElement = arr.filter((item) => item["toolId"] === Value);
    return getElement;
  };
  const changeStrcuture = (toolsNames = [], originalAr = []) => {
    let getNew = toolsNames.map((item) => {
      return { [item]: simpleFilter(originalAr, item) };
    });
    return getNew;
  };

  const renderMarks = (arr) => {
    return arr.map((item, index) => <div>{item.marks}</div>);
  };
  const getDummyToolName = tools.map((item) => item.toolName);
  console.log("toolNAme: ", getDummyToolName);
  const getMarksByToolName = (toolName) => {};
  // console.log(fetchData1);
  const getRenderItem = (obj = {}, toolName = []) => {
    let getKey = Object.keys(obj)[0];
    return obj[getKey].map((innerItem, innerIndex) => (
      <div
        style={{
          fontWeight:
            innerItem["toolId"] == 239999993 ||
            innerItem["toolId"] == 239999991 ||
            innerItem["toolId"] == 239999993 ||
            innerItem["toolId"] == 239999994 ||
            innerItem["toolId"] == 2399999999 ||
            innerItem["toolId"] == 239999992
              ? "bold"
              : "normal",
        }}
      >
        {innerItem["avg"]}
      </div>
    ));
  };
  const takeArray = (arr = [], toolName) => {
    let getAllMArks = changeStrcuture(getToolsId, arr);
    console.log("marks:", getAllMArks);
    return getAllMArks.map((item, index) => (
      <td>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          {getRenderItem(item, getToolsId)}
        </div>
      </td>
    ));
  };
  const getToolNameForRendering = (nameTool, arr, index) => {
    let getAllMArks = changeStrcuture(getToolsId, arr);
    return getAllMArks[index][nameTool].map((item) => (
      <React.Fragment>
        <td style={{ padding: 10 }}>{item["marks"]}</td>
      </React.Fragment>
    ));
  };
  console.log("Student Discipline Reports", props);
  return (
    <div id="printDiv">
      <div>
        <img src={img1} alt="#img" style={{ width: "100%", height: "30%" }} />
        <CustomHeading
          headingText={"FINAL CONTINUOUS ASSESSMENT REPORT"}
          type={MAIN_HEADING}
          className="reportHead"
        />

        <CustomReportHeader
          headRenderData={[
            { displayData: "Subject Name", fieldName: "subject" },
            { displayData: "Grade Name", fieldName: "gradeName" },
            { displayData: "Course Type", fieldName: "courseType" },
          ]}
          targetedObj="primaryInfoes"
          headData={fetchData1}
        />

        <CustomReportHeader
          headRenderData={[
            { displayData: "Class Name", fieldName: "className" },
            { displayData: "Section", fieldName: "section" },
            { displayData: "Term", fieldName: "term" },
            { displayData: "Session", fieldName: "session" },
          ]}
          targetedObj="primaryInfoes"
          headData={fetchData1}
        />
        <br />
        <table className="table table-bordered">
          <thead>
            <tr>
              <th rowSpan={2}>S #</th>
              <th rowSpan={2}> Student Name </th>
              {fetchData1 &&
                fetchData1["tools"].map((item, index) => (
                  <th
                    rowSpan={2}
                    style={{ textAlign: "center" }}
                  >{`${item.toolName}`}</th>
                ))}
            </tr>
            <tr></tr>
            <React.Fragment>
              {fetchData1 &&
                fetchData1["toolView3"].map((item, index) => (
                  <React.Fragment>
                    <tr>
                      <td
                        style={{
                          visibility:
                            item["studentId"] === 3999 ? "hidden" : "",
                        }}
                      >
                        {index + 1}
                      </td>
                      <td
                        style={{
                          fontWeight:
                            item["studentId"] === 3999 ? "bolder" : "",
                          textAlign:
                            item["studentId"] === 3999 ? "center" : "left",
                        }}
                      >
                        {item["studentName"]}
                      </td>
                      {takeArray(item["ToolMarkView3"])}
                    </tr>
                  </React.Fragment>
                ))}
            </React.Fragment>
          </thead>
        </table>

        <br />
        <br />

        <div
          className="lineGraph"
          style={{
            border: `3px solid ${Colors.DARK_SEA_GREEN_THEME}`,
          }}
        >
          <CustomBadge
            fontSize={25}
            badgeText={"Analysis"}
            badgeIconClass={"fas fa-chart-bar"}
          />
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div
              style={{
                width: "15%",
              }}
            >
              <SelectionInput
                newFlag="customWidth"
                isValidte={false}
                marginTop={-50}
                feildName={"graphType"}
                selectName={"Graph Type"}
                onHandleChange={(e) => {
                  if (e.target.value !== "Type") setGraphType1(e.target.value);
                }}
                errors={{}}
                optionsArrys={["barChart", "pieChart", "lineChart"]}
                selectedText={"Type"}
                stateData={{
                  graphType: graphType1,
                }}
                optionType="static"
                successflag={"successflag"}
              />
            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "space-around" }}>
            {graphData &&
              graphData["ToolAvgs"] &&
              graphData["ToolAvgs"].map((item) => (
                <div
                  style={{
                    textAlign: "right",
                    color: `${Colors.SEA_GREEN_THEME}`,
                    fontWeight: "bold",
                    marginLeft: 5,
                    fontSize: 20,
                  }}
                >
                  {` ${item.toolName} (${item.max} to ${item.min})  `}
                </div>
              ))}
          </div>
          {returnGraphs1(graphType1)}
        </div>
        <br />
        <div
          className="lineGraph"
          style={{
            border: `3px solid ${Colors.DARK_SEA_GREEN_THEME}`,
          }}
        >
          <CustomBadge
            fontSize={25}
            badgeText={"Analysis"}
            badgeIconClass={"fas fa-chart-bar"}
          />
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div
              style={{
                width: "15%",
              }}
            >
              <SelectionInput
                newFlag="customWidth"
                isValidte={false}
                marginTop={-50}
                feildName={"graphType"}
                selectName={"Graph Type"}
                onHandleChange={(e) => {
                  if (e.target.value !== "Type") setGraphType(e.target.value);
                }}
                errors={{}}
                optionsArrys={["barChart", "pieChart", "lineChart"]}
                selectedText={"Type"}
                stateData={{
                  graphType: graphType,
                }}
                optionType="static"
                successflag={"successflag"}
              />
            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "space-around" }}>
            {graphData &&
              graphData["ToolAvgs"] &&
              graphData["ToolAvgs"].map((item) => (
                <div
                  style={{
                    textAlign: "right",
                    color: `${Colors.SEA_GREEN_THEME}`,
                    fontWeight: "bold",
                    marginLeft: 5,
                    fontSize: 20,
                  }}
                >
                  {` ${item.toolName} (${item.max} to ${item.min})  `}
                </div>
              ))}
          </div>
          {returnGraphs(graphType)}
        </div>
      </div>

      <div
        style={{ marginTop: 20 }}
        class="text-center text-md-right displayElemet"
      >
        <label for="name" class="" onClick={() => PrintContent("printDev")}>
          <i
            className="fas fa-print fa-2x animated fadeIn"
            style={{ marginRight: 4 }}
          />
          Print It Out
        </label>
      </div>
      <div class="page-footer">
        <div class="text-left">Printed By: {props.name}</div>
        <div class="text-right" style={{ marginTop: -25, marginRight: 20 }}>
          Powered by School Smart®
        </div>
      </div>
    </div>
  );
}
function mapStateToProps(state) {
  return {
    role: state.setUserReducer.users.role,
    name: state.setUserReducer.users.unique_name,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    sharedAction: bindActionCreators(sharedActions, dispatch),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CaAverageReportSheet);
