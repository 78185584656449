import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import DateandTime from '../Transport/DateandTime';
import isNull from 'lodash/isNull'
import DatePicker from 'react-date-picker';
import SnackBar from '../../components/SnackBar/SnackBar';
import moment from 'moment'

import LoaderWrapper from "../../components/HOC/LoaderWrapper";
import ThemeContext from "../../context/themeContext/ThemeContext";



class AddStaffLeave extends Component {
    constructor(props){
        super(props);
        this.state = {
            errorMsg: false,
            successMsg: false,
            msg: '',
            isLoading: false,

            fromDate: null,
            toDate: null,
            file: null,
            description: null,
            
        }
    }

    componentDidMount() {
        this.getSimple(`DescriptiveReport?userName=${this.props.userData.unique_name}`, 'staffData')
    }


    getSimple = (type, state) => {
        this.props.sharedActions.getDataWithoutDispatch(
            `/api/${type}`
        ).then(success => {
            this.setState({
                [state]: success,
                isLoading: false
            })


        }).catch(error => {
            this.setState({
                msg: 'Error Occured!!',
                errorMsg: true,
                isLoading: false
            })
            console.error('Error occured', error);
        })
    }

    myFunction = () => {
		// Get the snackbar DIV
		var x = document.getElementById('snackbar');

		// StepperGeneralButtons the "show" class to DIV
		x.className = 'show';

		// After 3 seconds, remove the show class from DIV
		setTimeout(function() {
		}, 3000);
	};

    onHandleTextChange = e => {
        console.log([e.target.name], e.target.value);
        
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onHandleFileChange = e => {
        console.log( e.target.name, e.target.files);
        this.setState({
            [e.target.name]: e.target.files[0]
        })
    }


    handleSubmit = e => {
        e.preventDefault();
        const { 
            fromDate,
            toDate,
            description,
            file,
            staffData
         } = this.state;

         let staffId = staffData && staffData.classes && staffData.classes.length > 0 && staffData.classes[0].staffId
        this.setState({
            isLoading: true
        })
        if(
            isNull(fromDate) || 
            isNull(toDate) || 
            isNull(description) || 
            isNull(file) ||
            isNull(staffId)

        ){
            this.setState({
                msg: 'Please fill the form respectively!'
            })
            this.props.snackbar()
        }else{
            console.log(file);
            
            let data = new FormData();
            data.append('file', file, file.name);
            data.append('accountId', this.props.userData.unique_name);
            data.append('accountType', this.props.userData.role);
            data.append('description', description);
            data.append('fromDate', moment(fromDate, "YYYY-MM-DD").format("MM/DD/YYYY"));
            data.append('toDate', moment(toDate, "YYYY-MM-DD").format("MM/DD/YYYY"));
            data.append('staffId', staffId);

            for (var pair of data.entries()) {
                console.log(pair[0]+ ', ' + pair[1]); 
            }

        this.props.sharedActions.addRecordWithoutDispatch(
            `/api/StaffLeaves`,
            data
        ).then(success => {
            console.log(success);
            
            this.setState({
                msg: 'Leave Form Submitted!!',
                isLoading: false,
                fromDate: null,
                toDate: null,
                description: null,
                file: null,
            })
            this.props.snackbar()
        }).catch(error => {
            this.setState({
                msg: error.response ?  error.response.data.Message : 'Error Occured!!',
                isLoading: false
            })
            this.props.snackbar()

            console.error('Error occured', error);
            
        })
        }    
    }

    render(){

        const { successMsg, errorMsg, msg, isLoading, termList, student, session, talentCategory, talentSubCategory, valid,
            sessionId, classId, sectionId, studentId, term, categoryId, subCategoryId, reward,

            fromDate, toDate, description, file
             } = this.state;

        return (
            <div className="container">
				<section class="mb-4">
				<ThemeContext.Consumer>
                    {(context) => (
                    <h2  
                        style={{ background: context.themeColors.primaryColor }}
                        className="h1-responsive font-weight-bold text-center my-4 generalHead">
                        Add Leave
                    </h2>
                     )}
                </ThemeContext.Consumer>
				<SnackBar msg={this.state.msg} backColor={this.state.backColor} />

				 <p class="text-center w-responsive mx-auto mb-5" />

                    <div className="row" style={{
                        justifyContent: 'center',
                        textAlign: 'center'
                    }}>

                <ThemeContext.Consumer>
                {(context) => (
                        <div className="col-md-9 mb-md-0 mb-5" style={{
                            border: 'solid',
                            borderColor: context.themeColors.primaryColor,
                            padding: 40,
                            borderRadius: 15,
                            width: 'auto'

                        }}>
                      <LoaderWrapper isLoading={this.state.isLoading}>
                            <form id="payroll-form" name="payroll-form" onSubmit={this.handleSubmit} >
                                <div className="row">
									<div className="col-md-12">
										<div className="md-form mb-0">
											<DateandTime />
										</div>
									</div>
								</div>
								<br />
                                <div class="row">
									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="AdminName" class="">
												Admin Name
											</label>
											<input
												
												type="text"
												name="AdminName"
												class="form-control"
												readOnly={true}
												value={this.props.userData.unique_name}
											/>
										</div>
								    </div>

									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="AdminType" class="">
												Admin Type
											</label>
											<input
												
												type="text"
												name="AdminType"
												class="form-control"
												readOnly={true}
												value={this.props.userData.role}
											/>
										</div>
								    </div>
                                </div>
                                    <br/>
                                <div className="row">
                                    <div class="col-md-6">
                                        <div className="md-form mb-0 " >
                                        <label for="fromDate">
                                                From Date
                                            </label>
                                            <input class="custom-select" 
                                                type='date'
                                                value={fromDate}
                                                name="fromDate"
                                                onChange={this.onHandleTextChange}
                                            />
                                                    
                                        </div>
						            </div>
                                    <div class="col-md-6">
                                        <div className="md-form mb-0 " >
                                        <label for="toDate">
                                                To Date
                                            </label>
                                            <input class="custom-select" 
                                                type='date'
                                                value={toDate}
                                                name="toDate"
                                                onChange={this.onHandleTextChange}
                                            />
                                                    
                                        </div>
						            </div>
                                </div>  
                                <br/>
                                <div className="row">
                                    
                                <div class="col-md-12">
                                    <label>
                                        Application ( PDF, JPEG, PNG )
                                    </label>
                                         <div class="custom-file">
                                        <input 
                                            type="file" 
                                            class="custom-file-input" 
                                            id="file"
                                            name="file"
                                            accept="application/pdf, image/png, image/jpeg"
                                            onChange={this.onHandleFileChange}
                                            />
                                        <label class="custom-file-label" for="customFile">{file ? file.name : "Choose File"}</label>
                                        </div>
						            </div>
                                       
                                </div>
                                <br/>
                                <div className="row">
                                        <div className="col-md-12">
                                        <div className="md-form mb-0" >
                                        <label for="description">
                                                Description
                                            </label>
                                            
                                            <textarea class="form-control"
                                                rows={6}
                                                value={description}
                                                name="description"
                                                onChange={this.onHandleTextChange}
                                            />
                                            
                                        </div>
                                        </div>
                                        
                                </div>
                                
                                        <br/>
                                        <br/>
                                        <div className="text-center text-md-center">
								<button type="submit" className="btns" style={{
                                                    background: context.themeColors.primaryColor,
                                            }}>
										Add
										<span
											style={{ marginBottom: 5 }}
											class={`${
												this.state.isLoading
													? 'spinner-border spinner-border-sm'
													: ''
											}`}
										></span>
									</button>
								</div>
							</form>
							</LoaderWrapper>
                            <div class="status" />
                </div>
              )}
            </ThemeContext.Consumer>
                    </div>
				</section>
			</div>
        );
    }

}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch),
	};
}
function mapStateToProps(state) {
	return {
		userData: state.setUserReducer.users,
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AddStaffLeave);
