import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as SkillAction from "../../Actions/StudentSkill";
import * as sharedActions from "../../Actions/sharedActions";
import SnackBar from "../../components/SnackBar/SnackBar";
import MultipleSkills from "./MultipleSkill";
import ThemeContext from "../../context/themeContext/ThemeContext";

import $ from "jquery";
// import TableComponent from "./TableComponent";
class StudentSkill extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SkillReports: [],
      adminData: [],
      modalData: null,
      vehicleNo: "",
      capacity: "",
      transportType: "",
      enabled: false,
      id: null,
      select: [],
      routeId: "",
      routeName: "",
      startingServiceKm: "",
      midServiceKm: "",
      endingServiceKm: "",
      vehicleDateOfReg: "",
      vehicleDateOfRegExpiry: "",
      isLoading: true,
      disablepage: false,
      disableBPage: true,
      currentPage: 1,
      todosPerPage: 10,
      pageCounter: 10,
      msg: "",
      errorMsg: "",
      increament: 1,
      selectClassData: "",
      classId: "",
      sectionId: "",
      sections: [],
      subjects: [],
      session: [],
      sessionId: "",
      subjectId: "",
      staffId: "",
      term: "",
      btnLoad: false,
      exist: false,
      checking: [],
      searchCheck: true,
      multiple: [],
      badRequest: false,
      errorText: "",
    };
  }
  handleClickNextFrwd = () => {
    const { adminData, todosPerPage, count, currentPage } = this.state;
    let pageNumbers = [];
    for (
      let i = 1;
      i <= Math.ceil(adminData.length / this.state.todosPerPage);
      i++
    ) {
      pageNumbers.push(i);
    }
    console.log("pagelength: ", pageNumbers.length);
    console.log("current page: ", currentPage);
    if (currentPage === pageNumbers.length) {
      this.setState({
        disablepage: true,
      });
    } else {
      this.setState((prevState) => ({
        currentPage: prevState.currentPage + 1,
        disableBPage: false,
      }));
    }
  };
  handleClickNextBack = () => {
    const { adminData, todosPerPage, count, currentPage } = this.state;
    console.log("current page: ", currentPage);
    if (currentPage === 1) {
      this.setState({
        disableBPage: true,
        disablepage: false,
      });
    } else {
      this.setState((prevState) => ({
        currentPage: prevState.currentPage - 1,
      }));
      this.setState({
        disablepage: false,
      });
    }
  };
  handleClickNext = (event) => {
    this.setState({
      currentPage: Number(event.target.id),
      disablepage: false,
      disableBPage: false,
    });
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      prevState.adminData !== nextProps.AllTransportData ||
      prevState.selectClassData !== nextProps.selectData ||
      prevState.session !== nextProps.SessionSelect
    ) {
      return {
        adminData: nextProps.AllTransportData,
        selectClassData: nextProps.selectData,
        session: nextProps.SessionSelect,
      };
    }
    return null;
  }
  onSelectChange = (e) => {
    console.log(e.target.value);

    const sections = this.state.selectClassData.staffClassSection;
    let check = sections.filter((item) => item.classId == e.target.value);
    console.log(check);

    this.setState({
      sections: check,
      staffId: check[0].staffId,
      classId: e.target.value,
    });
  };
  onSectionChange = (e) => {
    console.log(e.target.value);
    const sections = this.state.selectClassData.staffClassSection;
    let check2 = sections.filter(
      (item) =>
        item.classId == this.state.classId && item.sectionId == e.target.value
    );
    this.setState({
      subjects: check2,
      sectionId: e.target.value,
    });
    console.log(this.state.staffId);
  };
  onSubjectChange = (e) => {
    console.log(e.target.value);
    this.setState({
      subjectId: e.target.value,
    });
  };
  componentDidUpdate() {
    $('[data-toggle="tooltip"]').tooltip();

    if (
      this.state.msg ||
      this.state.errorMsg ||
      this.state.exist ||
      this.state.badRequest
    ) {
      setTimeout(() => {
        this.setState({
          msg: false,
          errorMsg: false,
          exist: false,
          badRequest: false,
        });
      }, 3000);
    }
  }
  componentDidMount() {
    $(document).ready(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });
    this.props.sharedActions
      .gAllData(
        `/api/DescriptiveReport?userName=${this.props.users.unique_name}`,
        this.props.SkillAction.getClassSectionSubjectForStudentSkill
      )
      .then((success) => {
        console.log(success);
      })
      .catch((error) => {
        console.log(error);
      });
    this.props.sharedActions
      .gAllData("/api/Session", this.props.SkillAction.getSessionsForSkills)
      .then((success) => {})
      .catch((error) => {});
    // this.props.sharedActions.getAllData(`/api/Assignments/getassign/Student/?Classid=0&secid=0`, this.props.AssignmentActions.getParticularAssignment, this.props.AssignmentActions.updateAssignmentFlag)
    //     .then((success) => {
    //
    //         this.setState({
    //             isLoading: false,
    //         })
    //     }).catch((err) => {
    //         this.props.AssignmentActions.updateAssignmentFlag(false);

    //         this.setState({
    //             isLoading: false
    //         });
    //
    //         console.log(err);
    //     })
  }
  onSearchStudent = (e) => {
    this.setState({
      btnLoad: true,
    });
    this.props.sharedActions
      .getAllData(
        `/api/StudentSkillList/?classId=${this.state.classId}&sectionId=${this.state.sectionId}&term=${this.state.term}&subjectId=${this.state.subjectId}&sessionId=${this.state.sessionId}`,
        this.props.SkillAction.setAllSkillsInRedux,
        this.props.SkillAction.updateSkillFlag
      )
      .then((success) => {
        let getAllData = [...success];
        let SkillReports = getAllData.map((item, index) => {
          return {
            studentId: item.studentId,
            studentName: item.studentName,
            classId: item.classId,
            subjectId: this.state.subjectId,
            sectionId: this.state.sectionId,
            staffId: this.state.staffId,
            accountId: this.props.users.unique_name,
            accountType: this.props.users.role,
            AcademicProgress: "",
            Efforts: "",
            Participation: "",
            sessionId: this.state.sessionId,
            term: this.state.term,
          };
        });

        this.setState({
          SkillReports,
          isLoading: false,
          btnLoad: false,
          searchCheck: false,
        });
      })
      .catch((err) => {
        this.props.SkillAction.updateSkillFlag(false);

        this.setState({
          errorText: err.response.data.Message,
          badRequest: true,
          isLoading: false,
          btnLoad: false,
          searchCheck: true,
        });

        console.log(err);
      });
  };
  btnTechHover = () => {
    $(document).ready(function () {
      $("button").hover(
        function () {
          $(this).css("box-shadow", "0px 5px 5px grey");
          $(this).css("border-color", "white");
        },
        function () {
          $(this).css("box-shadow", "0px 0px 0px white");
        }
      );
    });
  };
  onTakeItemDelete = (itemId) => {
    this.setState({
      modalData: [],
    });
    this.props.sharedActions
      .deleteRecord(
        "/api/Vehicle/",
        itemId,
        this.props.routesActions.deleteVehicle
      )
      .then((success) => {
        this.setState({
          msg: "Delete Record Successfully!",
        });
        this.props.snackbar();
      })
      .catch((error) => {});
  };
  onTakeItem = (itemId) => {
    this.setState({
      modalData: [],
    });
    const { adminData } = this.state;
    let getData = adminData.filter((item) => item.id === itemId);

    this.setState({
      modalData: getData,
      transportType: getData[0].transportType,
      capacity: getData[0].capacity,
      vehicleNo: getData[0].vehicleNo,
      id: getData[0].id,
      routeName: getData[0].dropOff,
      routeId: getData[0].routeId,
      startingServiceKm: getData[0].startingServiceKm,
      midServiceKm: getData[0].midServiceKm,
      endingServiceKm: getData[0].endingServiceKm,
      vehicleDateOfReg: getData[0].vehicleDateOfReg,
      vehicleDateOfRegExpiry: getData[0].vehicleDateOfRegExpiry,
    });
  };

  onHandleText = (e) => {
    console.log(e.target.value);
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onHandleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      btnLoad: true,
    });
    const { SkillReports } = this.state;
    if (SkillReports.every((o) => o.Efforts === "")) {
      this.setState({
        btnLoad: false,
        exist: true,
      });
    } else {
      var bodyParameters = { SkillReports };
      this.props.sharedActions
        .addRecord(
          "/api/SkillReport",
          bodyParameters,
          this.props.SkillAction.addSkill
        )
        .then((success) => {
          this.setState({
            examName: "",
            msg: true,
            errorMsg: false,
            btnLoad: false,
            searchCheck: true,
          });
          // this.props.campusActions.updateCampusFlag(true);
        })
        .catch((err) => {
          this.setState({
            errorMsg: true,
            btnLoad: false,
          });
          console.log(err);
          console.log(err.data);
        });
    }
  };
  onEditClick = (itemId) => {
    this.onTakeItem(itemId);
    this.setState({ enabled: false });
  };
  myFunction = () => {
    // Get the snackbar DIV
    var x = document.getElementById("snackbar");

    // StepperGeneralButtons the "show" class to DIV
    x.className = "show";

    // After 3 seconds, remove the show class from DIV
    setTimeout(function () {
      x.className = x.className.replace("show", "");
    }, 3000);
  };

  onClickDeleteItem = (arrName) => {
    let getStateArr = this.state[`${arrName}`];
    let getDeleteLength = getStateArr.length - 1;
    if (getDeleteLength > 0) {
      let getNewDetails = getStateArr.filter(
        (item, index) => index !== getDeleteLength
      );
      console.log(getNewDetails);
      this.setState({
        [arrName]: [...getNewDetails],
      });
    }
  };

  onClickAddItem = (arrName, obj) => {
    let getStateArr = this.state[`${arrName}`];
    getStateArr = [...getStateArr, obj];
    console.log(arrName);
    this.setState({
      [arrName]: getStateArr,
    });
  };
  updateSpecialArrObjOnChange = (e, index, arrName) => {
    if (e.target.value <= 5) {
      const changeArrOfObj = this.state[`${arrName}`];
      changeArrOfObj[index][e.target.name] = e.target.value;
      this.setState({
        [arrName]: changeArrOfObj,
      });
    }
  };
  onChangeText = (e) => {
    console.log(e.target.value);
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onChangingData = (index, event) => {
    let dataStrcuture = [
      {
        studentId: 1,
        studentName: "Muhammad Hamza",
        effort: "",
        average: "",
        participation: "",
      },
    ];

    const { multiple } = this.state;
    var check = [...multiple];
    check[index][event.target.name] = event.target.value;
    this.setState({ multiple: check });
  };
  render() {
    const { classId, sectionId, sessionId, subjectId, term } = this.state;
    console.log("Daata", this.state.SkillReports);
    console.log("Admin Data", this.state.adminData);
    console.log("Classes Ka Data", this.state.selectClassData);
    console.log("Session Ka Data", this.state.session);
    const { session } = this.state.session;
    const { classes } = this.state.selectClassData;

    console.log("modal data: ", this.state.modalData);
    const Loader = (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <label style={{ color: "red" }}>No Data</label>
      </div>
    );
    let pageNumbers = [];
    for (
      let i = 1;
      i <=
      Math.ceil(this.state.checking.totalCount / this.state.checking.pageSize);
      i++
    ) {
      pageNumbers.push(i);
    }
    const renderPageNumbers = pageNumbers.map((number, index) => {
      return (
        <li
          class={`page-item ${
            this.state.checking.currentPage === number ? "active" : ""
          } `}
        >
          <a
            key={number}
            id={number}
            onClick={() => this.paginationRequest(number)}
            class="page-link "
            href="#"
          >
            {number}
          </a>
        </li>
      );
    });
    const MainContent = (
      <div>
        <form onSubmit={this.onHandleSubmit}>
          <MultipleSkills
            onHandleTextChange={this.onChangeText}
            stateData={this.state}
            updateSpecialArrObjOnChange={this.updateSpecialArrObjOnChange}
            onClickAddItem={this.onClickAddItem}
            onClickDeleteItem={this.onClickDeleteItem}
            onChanging={this.onChangingData}
            session={this.state.session}
          />
          <div class="text-center text-md-right">
            <ThemeContext.Consumer>
              {(context) => (
                <button
                  type="submit"
                  class="btnss"
                  style={{
                    background: context.themeColors.primaryColor,
                  }}
                >
                  Submit
                  <span
                    style={{ marginBottom: 5 }}
                    class={`${
                      this.state.btnLoad
                        ? "spinner-border spinner-border-sm"
                        : ""
                    }`}
                  ></span>
                </button>
              )}
            </ThemeContext.Consumer>
          </div>
        </form>
      </div>
    );
    return (
      <div>
        {this.state.errorMsg ? (
          <p style={{ color: "red", textAlign: "center" }}>
            Grading Marks Already Exist For This Term
          </p>
        ) : (
          ""
        )}
        {this.state.msg ? (
          <p style={{ color: "green", textAlign: "center" }}>
            Added Successfully!
          </p>
        ) : (
          ""
        )}
        {this.state.exist ? (
          <p style={{ color: "red", textAlign: "center" }}>
            Please Fill the Fields
          </p>
        ) : (
          ""
        )}
        {this.state.badRequest ? (
          <p style={{ color: "red", textAlign: "center" }}>
            {this.state.errorText}
          </p>
        ) : (
          ""
        )}
        <div className="page-header">
          <SnackBar msg={this.state.msg} />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <div>
              <h4 className="mt-2" style={{ letterSpacing: 10 }}>
                Student Skill
              </h4>{" "}
            </div>
          </div>
        </div>
        <br />
        <div class="container">
          <div class="row" style={{ justifyContent: "center" }}>
            <div class="col-md-12">
              <div class="md-form mb-0">
                {/* <label for="name" class="">
												  Term
											</label> */}
                <select
                  onChange={this.onHandleText}
                  class="custom-select"
                  name="term"
                >
                  <option>Select Type..</option>
                  <option value="1st">1st Term</option>
                  <option value="2nd">2nd Term</option>
                  <option value="3rd">3rd Term</option>
                </select>
              </div>
              <br />
            </div>

            <div class="col-md-2" style={{ marginTop: 20 }}>
              <div class="md-form mb-0">
                {/* <label for="email" class="">
                                <i
                                    style={{ marginRight: 4 }}
                                    className="fas fa-map animated fadeIn"
                                />
                                Class Name
                      </label> */}
                <select
                  onChange={this.onSelectChange}
                  class="custom-select"
                  name="className"
                >
                  <option selected={this.state.msg ? true : false}>
                    Choose Class...
                  </option>
                  {classes &&
                    classes.map((item, index) => (
                      <option key={index} value={`${item.classId}`}>
                        {item.className}
                      </option>
                    ))}
                </select>
              </div>
            </div>

            <div class="col-md-2" style={{ marginTop: 20 }}>
              <div class="md-form mb-0">
                {/* <label for="email" class="">
                                <i
                                    style={{ marginRight: 4 }}
                                    className="fas fa-map animated fadeIn"
                                />
                                Section
                      </label> */}
                <select
                  onChange={this.onSectionChange}
                  class="custom-select"
                  name="className"
                >
                  <option selected={this.state.msg ? true : false}>
                    Select Section...
                  </option>
                  {this.state.sections.map((item, index) => (
                    <option key={index} value={`${item.sectionId}`}>
                      {item.section}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div class="col-md-2" style={{ marginTop: 20 }}>
              <div class="md-form mb-0">
                {/* <label for="email" class="">
                                <i
                                    style={{ marginRight: 4 }}
                                    className="fas fa-map animated fadeIn"
                                />
                                Subject
                      </label> */}
                <select
                  onChange={this.onSubjectChange}
                  class="custom-select"
                  name="className"
                >
                  <option selected={this.state.msg ? true : false}>
                    Select Subject...
                  </option>
                  {this.state.subjects.map((item, index) => (
                    <option key={index} value={`${item.subjectId}`}>
                      {item.subjectName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div class="col-md-2" style={{ marginTop: 20 }}>
              <div class="md-form mb-0">
                {/* <label for="email" class="">
							<i
								style={{ marginRight: 4 }}
								className="fas fa-map animated fadeIn"
							/>
							Session
						</label> */}
                <select
                  onChange={this.onHandleText}
                  class="custom-select"
                  name="sessionId"
                >
                  <option selected={this.state.msg ? true : false}>
                    Select Session...
                  </option>
                  {session &&
                    session.map((item, index) => (
                      <option key={index} value={`${item.sessionId}`}>
                        {item.currentSession}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div class="col-md-12 text-center">
          <ThemeContext.Consumer>
            {(context) => (
              <button
                type="submit"
                disabled={
                  classId !== "" &&
                  sessionId !== "" &&
                  sectionId !== "" &&
                  term !== "" &&
                  subjectId !== ""
                    ? false
                    : true
                }
                style={{
                  backgroundColor:
                    classId !== "" &&
                    sessionId !== "" &&
                    sectionId !== "" &&
                    term !== "" &&
                    subjectId !== ""
                      ? context.themeColors.primaryColor
                      : "#ABD0BC",
                  border: "none",
                  fontSize: 20,
                  borderRadius: 4,
                  color: "white",
                }}
                onClick={this.onSearchStudent}
              >
                Search
                <i
                  style={{ color: "white", marginLeft: 4 }}
                  class="fas fa-search"
                ></i>
                <span
                  style={{ marginBottom: 5 }}
                  class={`${
                    this.state.btnLoad ? "spinner-border spinner-border-sm" : ""
                  }`}
                ></span>
              </button>
            )}
          </ThemeContext.Consumer>
        </div>
        <br />
        {this.state.searchCheck ? Loader : MainContent}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    SkillAction: bindActionCreators(SkillAction, dispatch),
    sharedActions: bindActionCreators(sharedActions, dispatch),
  };
}
function mapStateToProps(state) {
  return {
    AllTransportData: state.setStudentSkillReducer.Data,
    selectData: state.setStudentSkillReducer.selectStudent,
    SessionSelect: state.setStudentSkillReducer.selectSession,
    allTransportState: state.setStudentSkillReducer,
    users: state.setUserReducer.users,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(StudentSkill);
