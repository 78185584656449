import React, { useEffect, useState, useContext } from 'react';
import $ from 'jquery';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import SelectionInput from '../../components/SelectionInput/SelectionInput';
import Pagination from '../../components/Pagination/Pagination';
import Spinner from '../../components/Spinner/Spinner';
import httpWithRequiredSetData from '../../hooks/Services/httpWithRequiredSetData';
import general from '../../utils/general';
import Colors from '../../utils/app_constants/colors_constants';
import CustomFilterSectionLayout from '../../components/CustomFilterSectionLayout/CustomFilterSectionLayout';
import TextInput from '../../components/TextInput/TextInput';
import themeContext from "../../context/themeContext/ThemeContext";

let tHead = [
	'Student ID',
	'Student Name',
	'Student Email',
	'Username',
	'Password',
	'Actions'
];

let renderFields = ['stduentId', 'name', 'email', 'username', 'password'];

const StudentUsers = props => {
	const themeColor = useContext(themeContext);
	const [modalData, setModalData] = useState([]);

	const [studentData, setStudentData] = useState([]);
	const [id, setId] = useState(null);
	const [state, setState] = useState({
		studentId: '',
		accountId: '',
		accountType: ''
	});
	const [searchURL, setSearchURL] = useState(
		`/api/StudentPassword?studentId=&pageNumber=1&pageSize=10`
	);

	const [disableBPage, setdisableBPage] = useState(true);
	const [customPageSize, setCustomPageSize] = useState('10');
	const [currentPage, setCurrentPage] = useState(1);
	const [pageNumber, setPageNumber] = useState([]);
	const [disablepage, setdisablepage] = useState(false);
	const [pagination, setPaginationData] = useState({});

	const [
		isLoading,
		fetchData,
		setFetchData,
		setIsLoading
	] = httpWithRequiredSetData(searchURL, [], data => {
		let pageNumber = [];
		for (let i = 1; i <= data['paginationMetadata']['totalPages']; i++) {
			pageNumber.push(i);
		}
		setPageNumber(pageNumber);
		setStudentData(data['passwordList']);

		setPaginationData(data['paginationMetadata']);
	});

	const handleClickNextFrwd = () => {
		console.log('pagelength: ', pageNumber.length);
		console.log('current page: ', currentPage);
		if (currentPage === pageNumber.length) {
			setdisablepage(true);
		} else {
			setCurrentPage(prevState => prevState + 1);
			console.log(currentPage);

			setdisableBPage(false);
		}
	};

	useEffect(() => {
		console.log('Do something after counter has changed', currentPage);
		setIsLoading(true);
		props.sharedActions
			.getDataWithoutDispatch(
				general.condtionalServicesPortalCoordinator(
					state['studentId'],
					currentPage,
					customPageSize
				)
			)
			.then(success => {
				setStudentData(success['passwordList']);
				setIsLoading(false);
				let pageNumber = [];
				for (let i = 1; i <= success['paginationMetadata']['totalPages']; i++) {
					pageNumber.push(i);
				}
				setPageNumber(pageNumber);
				setPaginationData(success['paginationMetadata']);
			})
			.catch(error => {
				setIsLoading(false);
				setStudentData([]);
			});
	}, [currentPage, state['studentId'], customPageSize]);

	const onHandleTextChange = e => {
		const target = e.target;
		setState(prevState => ({
			...prevState,
			[target.name]: target.value
		}));
	};

	const togglePassword = (el, elementId) => {
		const elementsIndex = studentData.findIndex(
			element => element.stduentId == elementId
		);

		debugger;

		let newArray = [...studentData];
		newArray[elementsIndex] = {
			...newArray[elementsIndex],
			show: !newArray[elementsIndex].show
		};
		setStudentData(newArray);
	};

	const onEditClick = id => {
		onTakeItem(id);
	};

	const onTakeItem = itemId => {
		setModalData([]);
		let getData = studentData.filter(item => item.stduentId == itemId);

		setModalData(getData);
		setId(getData[0].stduentId);
	};

	const handleClickNext = event => {
		setCurrentPage(Number(event.target.id));
		setdisableBPage(false);
		setdisableBPage(false);
	};
	const handleClickNextBack = () => {
		if (currentPage === 1) {
			setdisableBPage(true);
			setdisablepage(false);
		} else {
			setCurrentPage(prevState => prevState - 1);
		}
	};
	const [msg, setSnackMsg] = useState('');

	const onChangePageSize = e => {
		if (e.target.value !== 'Page Entries') {
			setCustomPageSize(e.target.value);
			setCurrentPage(1);
		}
	};

	const onSubmit = e => {
		setIsLoading(true);

		props.sharedActions
			.getDataWithoutDispatch(
				`/api/SendPassworToEmail/?post=student&name=${modalData[0].name}&email=${modalData[0].email}&username=${modalData[0].username}&password=${modalData[0].password}`
			)
			.then(success => {
				setIsLoading(false);
				setSnackMsg('Emailed Successfully!');
				props.snackbar();
			})
			.catch(error => {
				if (error && error.response && error.response.status == 404) {
					// 404-Error no understanding of 404 Error
					setIsLoading(false);
					props.snackbar();
					setSnackMsg(error.response.data.Message);
				} else if (error && error.response && error.response.status == 400) {
					// 404-Error no understanding of 404 Error
					setIsLoading(false);
					props.snackbar();
					setSnackMsg(error.response.data.Message);
				}
				setIsLoading(false);
			});
	};

	const bodyRendering = data =>
		data &&
		data.map((item, index) => (
			<tr>
				<th
					style={{
						padding: 2,
						border: `1px solid ${themeColor.themeColors.primaryColor}`,
						borderColor: themeColor.themeColors.primaryColor
					}}
					scope="row"
				>
					{index + 1}
				</th>
				{renderFields.map((item1, index) =>
					item1 === 'password' ? (
						<td
							style={{
								fontWeight: `${index == 0 ? 'bold' : 'normal'}`,
								border: `1px solid ${themeColor.themeColors.primaryColor}`,
								padding: 2,
								borderColor: themeColor.themeColors.primaryColor
							}}
						>
							<input
								type={item.show ? 'password' : 'text'}
								id={item.stduentId}
								disabled={true}
								style={{
									border: 'none',
									borderColor: 'transparent',
									backgroundColor: '#fff',
									width: '100%',
									padding: '10px',
									margin: '0px'
								}}
								value={item[item1]}
							/>
						</td>
					) : (
						<td
							style={{
								fontWeight: `${index == 0 ? 'bold' : 'normal'}`,
								border: `1px solid ${themeColor.themeColors.primaryColor}`,
								padding: 2,
								borderColor: themeColor.themeColors.primaryColor
							}}
						>
							{item[item1]}
						</td>
					)
				)}
				<td
					style={{
						border: `1px solid ${themeColor.themeColors.primaryColor}`,
						padding: 2,
						borderColor: themeColor.themeColors.primaryColor
					}}
				>
					<a
						href="#"
						data-toggle="tooltip"
						title={item.show ? 'Show Password' : 'Hide Password'}
					>
						<button
							onClick={e => togglePassword(e, item.stduentId)}
							style={{
								cursor: 'pointer',
								fontSize: 20,
								border: 'none',
								background: 'none',
								marginLeft: 5,
								marginBottom: 5
							}}
						>
							<i
								style={{ color: Colors.SEA_GREEN_THEME }}
								class={item.show ? 'fas fa-eye' : 'fas fa-eye-slash'}
							></i>
						</button>
					</a>

					<a href="#" data-toggle="tooltip" title="Email Password!">
						<button
							onClick={() => onEditClick(item.stduentId)}
							data-toggle="modal"
							data-target="#myModal1"
							style={{
								cursor: 'pointer',
								fontSize: 20,
								border: 'none',
								background: 'none',
								marginLeft: 5,
								marginBottom: 5
							}}
						>
							<i
								style={{ color: Colors.SEA_GREEN_THEME }}
								class="fas fa-envelope"
							></i>
						</button>
					</a>
				</td>
			</tr>
		));

	const SearchByStudentId = (
		<React.Fragment>
			<TextInput
				feildName={'studentId'}
				inputLabelName={'Student ID'}
				onHandleChange={onHandleTextChange}
				errors={{}}
				stateData={state}
				placeholder={'StudentId'}
				isValidte={false}
				customWidth={true}
				enterWidth={'100%'}
			/>
		</React.Fragment>
	);

	return (
		<div>
			<SnackBar msg={msg} />
			<div class="modal" id="myModal1">
				<div class="modal-dialog">
					<div class="modal-content modal_content_custom">
						<div
							style={{
								borderBottomLeftRadius: 30,
								borderBottomRightRadius: 30,
								borderTopLeftRadius: 18,
								borderTopRightRadius: 18,
								background: themeColor.themeColors.primaryColor
							}}
							class="modal-header modal_custom_header"
						>
							<h4 style={{ color: 'white' }} class="modal-title">
								Confirm
							</h4>
							<button
								style={{ color: 'white' }}
								type="button"
								class="close"
								data-dismiss="modal"
							>
								&times;
							</button>
						</div>
						<div class="modal-body">
							<div>
								<div style={{ textAlign: 'center' }}>
									<h4>Are you sure ?</h4>
								</div>
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-evenly'
									}}
								>
									<div>
										<button
											// disabled={this.state.enabled}
											onClick={e => onSubmit(e)}
											type="button"
											class="btn btn-primary buttonAppear"
											style={{background: themeColor.themeColors.primaryColor}}
										>
											Yes
										</button>
									</div>
									<div>
										<button
											data-dismiss="modal"
											// disabled={this.state.enabled}
											type="button"
											class="btn btn-primary buttonAppear"
											style={{background: themeColor.themeColors.primaryColor}}
										>
											No
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div style={{ textAlign: 'right' }}>
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<div>
						<h2 className="h1-responsive font-weight-bold text-left my-4 generalHead" style={{background: themeColor.themeColors.primaryColor}}>
							{' '}
							Student Users{' '}
						</h2>
					</div>
					<div
						style={{ marginTop: 40, display: 'flex', flexDirection: 'column' }}
					>
						<Pagination
							disableBPage={disableBPage}
							disablepage={disablepage}
							currentPage={currentPage}
							pageNumber={pageNumber}
							handleClickNext={handleClickNext}
							handleClickNextBack={handleClickNextBack}
							handleClickNextFrwd={handleClickNextFrwd}
						/>
						<div
							style={{
								width: 'fit-content',
								marginTop: -34,
								display: 'flex',
								justifyContent: 'flex-end',
								marginLeft: 'auto'
							}}
						>
							<SelectionInput
								selectName="Entries"
								selectFont={14}
								selectFontWeight={'bold'}
								newFlag="customWidth"
								feildName="customPageSize"
								selectedText={'Page Entries'}
								optionsArrys={['10', '20', '30', '40', '50']}
								errors={{}}
								isValidte={false}
								stateData={{
									customPageSize: customPageSize
								}}
								optionType="static"
								onHandleChange={onChangePageSize}
								useFlag={false}
							/>
						</div>
					</div>
				</div>
				<CustomFilterSectionLayout displayDirection="column">
					<div
						style={{
							width: '30%',
							margin: '0 auto',
							display: 'flex',
							justifyContent: 'center'
						}}
					>
						{SearchByStudentId}
					</div>
				</CustomFilterSectionLayout>

				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<span className="customBadge" style={{ fontSize: 14 }}>
						Page: {currentPage}
					</span>

					<span className="customBadge" style={{ fontSize: 14 }}>
						Total Page: {pagination['totalPages']}
					</span>
				</div>
			</div>
			{isLoading ? (
				<Spinner />
			) : studentData.length < 1 ? (
				<div
					style={{ display: 'flex', justifyContent: 'center', color: 'red' }}
				>
					<strong>No Data</strong>
				</div>
			) : (
				<div className="table-responsive">
					<table class="table table-hover">
						<thead style={{ background: themeColor.themeColors.primaryColor, color: 'white' }}>
							<tr>
								<th scope="col">#</th>
								{tHead.map(item => (
									<th
										style={{
											border: `1px solid ${Colors.SEA_GREEN_THEME}`,
											borderColor: Colors.WHITE
										}}
										scope="col"
									>
										{item}
									</th>
								))}
							</tr>
						</thead>
						<tbody>{bodyRendering(studentData)}</tbody>
					</table>
				</div>
			)}
		</div>
	);
};

function mapStateToProps(state) {
	return {
		role: state.setUserReducer.users.role,
		name: state.setUserReducer.users.unique_name
	};
}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch)
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(StudentUsers);
