import React, { Component } from 'react';
const period = [
	{ time: '4:30', peroid: 'Peroid 1' },
	{ time: '4:32', peroid: 'Peroid 2' },
	{ time: '4:31', peroid: 'Peroid 3' }
];
const workingDays = [
	{ day: 'Monday', off: true },
	{ day: 'Tuesday', off: false },
	{ day: 'Wednesday', off: false },
	{ day: 'Thrusday', off: false },
	{ day: 'Friday', off: false },
	{ day: 'Saturday', off: false },
	{ day: 'Sunday', off: false }
];
export default class Check extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isEnable: true,
			class: null,
			sections: null,
			classTech: '',
			selectedClass: '',
			selectedSection: '',
			facultyName: '',
			FilterObject: null,
			classNames: null,
			dummySample: {
				classes: [
					'Pre-Nursery',
					'Nursery',
					'1',
					'2',
					'3',
					'4',
					'5',
					'6',
					'7',
					'8',
					'9',
					'10'
				],
				grade: [
					{
						className: 'Pre-Nursery',
						gradeName: 'Pre-Nursery',
						section: 'A'
					},
					{
						className: '1',
						gradeName: 'Primary',
						section: 'A'
					},
					{
						className: '1',
						gradeName: 'Primary',
						section: 'B'
					}
				]
			},
			dummyData1: [
				{
					className: '1',
					section: 'A',
					faculty: 'wazeer'
				},
				{
					className: '1',
					section: 'B',
					faculty: 'Fiza'
				},
				{
					className: 'Pre-Nursery',
					section: 'A',
					faculty: 'wajih'
				}
				// {
				//     className: "Pre-Nursery",
				//     section: "B",
				//     faculty: "wahaj"
				// }
			],
			dummyData: [
				{
					Class: 1,
					sections: ['A', 'B'],
					A: {
						section: 'A',
						classTech: 'Adil'
					},
					B: {
						section: 'B',
						classTech: 'Taha'
					}
				},
				{
					Class: 2,
					sections: ['A'],
					A: {
						section: 'A',
						classTech: 'Ammar'
					},
					B: {
						section: 'B',
						classTech: 'Salik'
					}
				},
				{
					Class: 3,
					sections: ['A', 'B'],
					A: {
						section: 'A',
						classTech: 'Muhammad Hamza'
					},
					B: {
						section: 'B',
						classTech: 'Syed Hamzah'
					}
				}
			]
		};
	}
	componentDidMount() {
		this.removeRepeatedElements(this.state.dummyData1);
	}
	removeRepeatedElements = arrOBJ => {
		let NonRepeated = new Set();
		arrOBJ.map((item, index) => {
			NonRepeated.add(item.className);
		});
		console.log(NonRepeated);
		this.setState({
			classNames: [...NonRepeated]
		});
	};
	onSectionSelectChange = e => {
		console.log(e.target.value);
		const { dummyData1, selectedClass } = this.state;

		let getTechName = dummyData1.filter(
			(item, index) =>
				item.section == e.target.value && item.className === selectedClass
		);
		console.log(getTechName);
		this.setState({
			facultyName: getTechName[0].faculty
		});
	};
	onSelectChangeHandler = e => {
		const { dummyData1 } = this.state;
		console.log(this.state.selectedClass);

		let getFilterData = dummyData1.filter(
			item => item.className === e.target.value
		);
		console.log(getFilterData);
		this.setState({
			sections: [...getFilterData],
			isEnable: false,
			selectedClass: e.target.value
		});

		// let getSections=getFilterData[0].sections;
		// let classTech=getFilterData[0]
		//
		// this.setState({
		//     class:e.target.value,
		//     isEnable:false,
		//     sections:[...getSections],
		//     FilterObject:getFilterData,
		// })
	};

	render() {
		const {
			dummyData,
			isEnable,
			sections,
			dummyData1,
			classNames,
			facultyName
		} = this.state;
		// student validation
		// else if (Object.is(formType, 'ParentDocsForm')) {
		// 	if (
		// 		Object.is(passportFather, 'Yes') &&
		// 		Object.is(filePath_passportFather, '')
		// 	) {
		// 		return false;
		// 	} else if (
		// 		Object.is(passportMother, 'Yes') &&
		// 		Object.is(filePath_passportMother, '')
		// 	) {
		// 		return false;
		// 	} else if (
		// 		Object.is(idCardFather, 'Yes') &&
		// 		Object.is(filePath_idCardFather, '')
		// 	) {
		// 		return false;
		// 	} else if (
		// 		Object.is(idCardMother, 'Yes') &&
		// 		Object.is(filePath_idCardMother, '')
		// 	) {
		// 		return false;
		// 	} else if (
		// 		Object.is(idCardMother, null) ||
		// 		Object.is(idCardFather, null) ||
		// 		Object.is(passportMother, null) ||
		// 		Object.is(passportFather, null)
		// 	) {
		// 		return false;
		// 	} else {
		// 		return true;
		// 	}
		//  student validation
		// if (Object.is(formType, 'studentDocument')) {
		// 	if (Object.is(dobCertificate, 'Yes') && Object.is(filePath, '')) {
		// 		return false;
		// 	} else if (
		// 		Object.is(transferCertificate, 'Yes') &&
		// 		Object.is(filePath1, '')
		// 	) {
		// 		return false;
		// 	} else if (
		// 		Object.is(ministryVacination, 'Yes') &&
		// 		Object.is(filePath_ministryVacination, '')
		// 	) {
		// 		return false;
		// 	} else if (
		// 		Object.is(medicalHistory, 'Yes') &&
		// 		Object.is(filePath_medicalHistory, '')
		// 	) {
		// 		return false;
		// 	} else if (
		// 		Object.is(idCardCopy, 'Yes') &&
		// 		Object.is(filePath_idCardCopy, '')
		// 	) {
		// 		return false;
		// 	} else if (
		// 		Object.is(passportCopy, 'Yes') &&
		// 		Object.is(filePath_passportCopy, '')
		// 	) {
		// 		return false;
		// 	} else if (
		// 		Object.is(lastResult, 'Yes') &&
		// 		Object.is(filePath_lastResult, '')
		// 	) {
		// 		return false;
		// 	} else if (
		// 		Object.is(testResult, 'Yes') &&
		// 		Object.is(filePath_testResult, '')
		// 	) {
		// 		return false;
		// 	} else if (
		// 		Object.is(dobCertificate, null) ||
		// 		Object.is(transferCertificate, null) ||
		// 		Object.is(ministryVacination, null) ||
		// 		Object.is(medicalHistory, null) ||
		// 		Object.is(idCardCopy, null) ||
		// 		Object.is(passportCopy, null) ||
		// 		Object.is(lastResult, null) ||
		// 		Object.is(testResult, null)
		// 	) {
		// 		return false;
		// 	} else {
		// 		return true;
		// 	}
		return (
			<div>
				{/* <div className='row'> */}
				<table>
					<thead>
						<th>Days</th>
						{period.map(item => (
							<th>
								{item.peroid}
								<br />
								{item.time}
							</th>
						))}
					</thead>
					<tbody>
						{workingDays.map(itemD => (
							<tr>
								<td>{itemD.day}</td>
								{itemD.off ? (
									<td>OFF</td>
								) : (
									period.map(item => (
										<td style={{ background: `${itemD.off}` ? '#fff' : '' }}>
											{item.time}{' '}
										</td>
									))
								)}
							</tr>
						))}
					</tbody>
				</table>
				{/* </div> */}
			</div>
		);
	}
}
