import React from "react";
import { Link } from "react-router-dom";
import fianance from '../../Assets/main/finance-smart.png';

const CardsForView = (props) => {
  const { data, isImage } = props;
  
  debugger;
  console.log("props Data", data);
  return (
    <React.Fragment>
      <Link to={`${data.linkPath}`}>
        <div class="card shadow-lg p-1 mb-5 bg-white rounded">
          <div class="card-body">
            <div class="row">
              <div class="col-md-4">
                <img
                  src={data.imagePath}
                  class="img-fluid"
                  alt="Responsive image"
                  height="120"
                  width="120"
                />
              </div>
              <div class="vl col-md-1"></div>

              <div class="col-md-6 text-left">
                {
                  data && data.isImage ? <img src={data.moduleImage} height={30} width={200} ></img> :
                  data && data.smartImage ?
                  
                  <h3 style={{ color: data.color }}>{
                    data.title.toUpperCase() } <img className='mb-2' src={data.smartImage} height={30} width={100}   /> </h3> :
                     <h3 style={{ color: data.color }}>{
                      data.title.toUpperCase() } </h3>
                 
                }


                <label className="text-muted">{data.subtitle}</label>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </React.Fragment>
  );
};

export default CardsForView;
