import React from 'react'
import { connect } from 'react-redux';
import $ from 'jquery'
import SelectionInput from '../../components/SelectionInput/SelectionInput';
import TextInput from '../../components/TextInput/TextInput'
import FormLayoutWrapper from '../../components/HOC/FormLayoutWrapper';
import * as sharedActions from '../../Actions/sharedActions';
import Button from '../../components/Button';
import SnackBar from '../../components/SnackBar/SnackBar';
import { bindActionCreators } from 'redux';
import TextArea from '../../components/TextArea'
import RenderDynamicRows from './RenderDynamicRows';
import ThemeContext from "../../context/themeContext/ThemeContext";

class AddIndent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            studentName: '',
            fieldValidate: true,
            requiredFor: '',
            errors: {},
            accountType: props.role,
            accountId: props.name,
            indentData: [
                {
                    product: '',
                    quantity: '',
                    unit: '',
                    expectedDate: '',
                    remarks: '',
                }
            ]
        }
    }

    componentDidUpdate() {
        if (this.state.msg || this.state.errorMsg) {
            setTimeout(() => {
                this.setState({
                    msg: false,
                    errorMsg: false
                });
            }, 3000);
        }
    }

    componentDidMount() {
        const { accountId } = this.state;
        $(document).ready(function () {
            $('[data-toggle="tooltip"]').tooltip();
        });
        this.props.sharedActions
            .getDataWithoutDispatch(
                `/api/DescriptiveReport?userName=${accountId}`,
            )
            .then(success => {
                this.setState({
                    reportData: success,
                    staffId: success.classes[0].staffId,
                    isLoading: false
                })
            })
            .catch(err => {
                console.log(err)
                this.setState({ isLoading: false })
            })
    }

    onClickAddItem = (arrName, obj) => {
        let getStateArr = this.state[`${arrName}`];
        getStateArr = [...getStateArr, obj];
        console.log(arrName);
        this.setState({
            [arrName]: getStateArr
        });
    };

    onClickDeleteItem = arrName => {
        let getStateArr = this.state[`${arrName}`];
        let getDeleteLength = getStateArr.length - 1;
        if (getDeleteLength > 0) {
            let getNewDetails = getStateArr.filter(
                (item, index) => index !== getDeleteLength
            );
            console.log(getNewDetails);
            this.setState({
                [arrName]: [...getNewDetails]
            });
        }
    };

    onHandleTextChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    handleDateChange = (date, index, arrName) => {

        const changeArrOfObj = this.state[`${arrName}`];

        changeArrOfObj[index]['expectedDate'] = date
        this.setState({
            [arrName]: changeArrOfObj
        });
    }

    updateSpecialArrObjOnChange = (e, index, arrName) => {
        const changeArrOfObj = this.state[`${arrName}`];
        changeArrOfObj[index][e.target.name] = e.target.value;
        this.setState({
            [arrName]: changeArrOfObj
        });
    };

    btnTechHover = () => {
        $(document).ready(function () {
            $('#addBtn').hover(
                function () {
                    $(this).css(
                        'background',
                        'linear-gradient(to bottom left, #01425e 5%, #01ac8a 87%)'
                    );
                    $(this).css('border-color', 'white');
                },
                function () {
                    $(this).css('background', '#0A4F5E');
                }
            );
        });
    };

    onSubmit = (e) => {
        e.preventDefault();

        const { indentData, isLoading, accountId, accountType, requiredFor, staffId } = this.state;

        if (
            Object.is(requiredFor, '')
        ) {
            this.setState({
                errorMsg: true
            });
        } else {
            this.setState({
                isLoading: true
            })
            let temp = indentData.map((item, index) => (
                item
            ))
            temp.forEach(obj => {
                let date = new Date(obj.expectedDate)
                let expectedDate = ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear();
                obj.expectedDate = expectedDate
            })
            const IndentForms = {
                purpose: requiredFor, accountId, accountType, staffId
            }
            const data = {
                IndentForms,
                IndentItems: temp
            }
            console.log(data);

            this.props.sharedActions
                .simpleAddRequest(
                    '/api/IndentForm',
                    data)
                .then(success => {
                    this.setState({
                        msg: 'Added Request Successfully',
                        isLoading: false,
                        indentData: [
                            {
                                product: '',
                                quantity: '',
                                unit: '',
                                expectedDate: '',
                                remarks: '',
                            }
                        ],
                        errorMsg: false,
                        error: false,
                        requiredFor: ''
                    })
                    this.props.snackbar();
                })
        }
    }


    render() {
        const { errors, isLoading, indentData } = this.state;

        const Loader = (
            <ThemeContext.Consumer>
            {(context) => (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div class="spinner-border" style={{ color: context.themeColors.primaryColor }}></div>
            </div>
                                                            )}
                                                            </ThemeContext.Consumer>
        );
        const Form = (
            <ThemeContext.Consumer>
            {(context) => (
            <FormLayoutWrapper
                formName='Add Indent Form'
                borderColor={context.themeColors.primaryColor}
                borderRadius={15}
                flagName={'fieldValidate'}
                errorFlag={errors}
                type="row"

            >
                {this.state.errorMsg ? (
                    <p style={{ color: 'red', textAlign: 'center' }}>
                        Please fill the form properly
                    </p>
                ) : (
                        ''
                    )}

                <div style={{ width: '100%' }}>
                    <table class="table table-hover">
                        <thead style={{ background: context.themeColors.primaryColor, color: 'white' }}>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Product</th>
                                <th scope="col">QTY</th>
                                <th scope="col">Unit</th>
                                <th scope="col">Expected Date</th>
                                <th scope="col">Remarks</th>
                            </tr>
                        </thead>
                        <tbody>

                            {indentData.map((item, index) => (
                                <tr key={index}>
                                    <th scope={'row'}>{index + 1}</th>
                                    <RenderDynamicRows
                                        arrName={'indentData'}
                                        updateSpecialArrObjOnChange={this.updateSpecialArrObjOnChange}
                                        rowIndex={index}
                                        onHandleTextChange={this.onHandleTextChange}
                                        stateData={this.state}
                                        itemObj={item}
                                        dateHandler={this.handleDateChange}
                                    />
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className={'row'} style={{ justifyContent: 'flex-end' }}>
                        <div>
                            <button
                                id={'addBtn'}
                                onMouseEnter={this.btnTechHover()}
                                onClick={() =>
                                    this.onClickAddItem('indentData', {
                                        product: '',
                                        quantity: '',
                                        unit: '',
                                        expectedDate: '',
                                        remarks: '',
                                    })
                                }
                                style={{
                                    background: '#0A4F5E',
                                    color: '#FFFFFF',
                                    borderRadius: 100
                                }}
                                type="button"
                                class="btn"
                            >
                                <i class="fas fa-plus" />
                            </button>
                        </div>
                        <div style={{ marginLeft: 8 }}>
                            <button
                                onClick={() => this.onClickDeleteItem('indentData')}
                                style={{ color: '#FFFFFF', borderRadius: 100 }}
                                type="button"
                                class="btn btn-danger"
                            >
                                <i class="far fa-trash-alt" />
                            </button>
                        </div>
                    </div>
                </div>
                <br />
                <br />
                <br />

                <div class='col-md-12'>
                    <TextArea
                        feildName={'requiredFor'}
                        inputLabelName={'Required For'}
                        onHandleChange={this.onHandleTextChange}
                        errors={errors}
                        stateData={this.state}
                        // iconClassName={'fas fa-percentage'}
                        placeholder={'Add Required For'}
                        isValidte={false}
                    />
                </div>

                <Button
                    width="100%"
                    buttonClass="customButton"
                    btnName="Submit"
                    loaderBmargin={5}
                    stateData={this.state}
                    marginTop={20}
                    onHandleSubmit={this.onSubmit}
                    margin={'0 auto'}
                />

            </ FormLayoutWrapper >
                                                            )}
                                                            </ThemeContext.Consumer>
        )
        return (
            <React.Fragment>
                <SnackBar msg={this.state.msg} />
                {isLoading ? Loader : Form}
            </React.Fragment>

        )
    }
}

function mapStateToProps(state) {
    return {
        role: state.setUserReducer.users.role,
        name: state.setUserReducer.users.unique_name,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        sharedActions: bindActionCreators(sharedActions, dispatch)
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddIndent);