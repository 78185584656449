import React, { Component } from "react";
import DashboardLayout from "../components/HOC/DashboardLayout";
import { images, renderingStuff } from "../utils";
import NewViewRendering from "../components/ViewRendering/NewViewRendering";

export default function AcademicsDashboard(props) {
  return (
    <DashboardLayout name="Academic Dashboard" image={images.imgAcDash}>
      <NewViewRendering {...props} data={renderingStuff.academicLinks} />
    </DashboardLayout>
  );
}
