import React, { useEffect, useState, useContext } from 'react';
import $ from 'jquery';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import * as sharedActions from '../../Actions/sharedActions';
import general from '../../utils/general';
import moment from 'moment';
import Colors from '../../utils/app_constants/colors_constants';
import Spinner from '../../components/Spinner/Spinner';
import TextInput from '../../components/TextInput/TextInput';
import CustomFilterSectionLayout from '../../components/CustomFilterSectionLayout/CustomFilterSectionLayout';
import themeContext from "../../context/themeContext/ThemeContext";

const StaffTimeTableByCoordinator = props => {
    const themeColor = useContext(themeContext);

	const [isLoading, setIsLoading] = useState(false);
	const [timeTableData, setTimeTableData] = useState({});
	const [tHead, setTHead] = useState([]);
	const [renderFields, setRenderFields] = useState([]);
	const [periods, setPeriods] = useState([]);

	const [state, setState] = useState({
		today: moment(),
		from_date: '',
		to_date: ''
	});

	const onHandleTextChange = e => {
		const target = e.target;
		setState(prevState => ({
			...prevState,
			[target.name]: target.value
		}));
	};

	useEffect(() => {
		setIsLoading(true);
		if (
			state['today'].format('dddd') !== 'Friday' &&
			state['today'].format('dddd') !== 'Saturday'
		) {
			const from_date = moment().startOf('week');

			const to_date = moment()
				.endOf('week')
				.subtract(2, 'days');

			props.sharedActions
				.getDataWithoutDispatch(
					`/api/TimeTable/staff/?staffId=${
						state['staffId']
					}&fromDate=${general.dateToFormat(
						from_date
					)}&toDate=${general.dateToFormat(to_date)}`
				)
				.then(data => {
					setTimeTableData(data);
					setTHead(data.classes);
					setPeriods(data.periodsList);
					setIsLoading(false);
				})
				.catch(err => {
					setTimeTableData({});
					setTHead([]);
					setIsLoading(false);
				});
		} else {
			const from_date = moment()
				.add(1, 'weeks')
				.startOf('week');
			const to_date = moment()
				.add(1, 'weeks')
				.endOf('week')
				.subtract(2, 'days');

			props.sharedActions
				.getDataWithoutDispatch(
					`/api/TimeTable/staff/?staffId=${
						state['staffId']
					}&fromDate=${general.dateToFormat(
						from_date
					)}&toDate=${general.dateToFormat(to_date)}`
				)
				.then(data => {
					setIsLoading(true);
					setTimeTableData(data);
					setTHead(data.classes);
					setPeriods(data.periodsList);

					setIsLoading(false);
				})
				.catch(err => {
					setTimeTableData({});
					setTHead([]);
					setIsLoading(false);
				});
		}
	}, [state['staffId']]);

	const bodyRendering = data =>
		data &&
		data.map((item, index) => (
			<tr>
				<th
					style={{
						padding: 2,
						border: `1px solid ${themeColor.themeColors.primaryColor}`,
						borderColor: themeColor.themeColors.primaryColor,
						textAlign: 'center',
						verticalAlign: 'middle',
						background: '#A9A9A9',
						color: 'white'
					}}
					scope="row"
				>
					<div>
						{item.dayName}
						<br />
						{item.date}
					</div>
				</th>
				{item.periods.map((item1, index) => (
					<td
						height="140px"
						width={`${80 / tHead.length}%`}
						style={
							item1.periodNames.length < 1
								? {
										fontWeight: 'bold',
										border: `1px solid white`,
										padding: 2,
										borderColor: Colors.WHITE,
										textAlign: 'center',
										verticalAlign: 'middle',
										backgroundColor: themeColor.themeColors.primaryColor,
										color: Colors.WHITE
								  }
								: {
										fontWeight: 'bold',
										border: `1px solid white`,
										padding: 2,
										borderColor: 'white',
										textAlign: 'center',
										verticalAlign: 'middle',
										backgroundColor:
											general.timeTableColors[
												Math.floor(
													Math.random() *
														Math.floor(general.timeTableColors.length)
												)
											],
										color: 'white'
								  }
						}
					>
						{item1.periodNames.length < 1 ? (
							<div>
								<strong>No Slots</strong>
							</div>
						) : (
							<div>
								{item1.periodNames.map((item2, index) => (
									<strong>{`${item2.name} `}</strong>
								))}
								<strong>{item1.type}</strong>
								<br />
								<strong>{item1.subjectName}</strong>
							</div>
						)}
					</td>
				))}
			</tr>
		));

	const SearchByStaffId = (
		<React.Fragment>
			<TextInput
				feildName={'staffId'}
				inputLabelName={'Staff ID'}
				onHandleChange={onHandleTextChange}
				errors={{}}
				stateData={state}
				placeholder={'Staff ID'}
				isValidte={false}
				customWidth={true}
				enterWidth={'100%'}
			/>
		</React.Fragment>
	);

	const TimeTableView = (
		<div className="table-responsive">
			<table
				class="table table-hover text-center animated slideInUp"
				style={{
					borderCollapse: 'separate',
					borderSpacing: '0 15px',
					fontSize: '22px'
				}}
			>
				<thead style={{ background: '#A9A9A9', color: 'white' }}>
					<tr class="vendorListHeading">
						<th scope="col">
							{' '}
							<i class="fas fa-calendar-day" style={{ color: 'white' }}></i>
							{'   Days'}
						</th>
						{tHead.map(item => (
							<th
								style={{
									border: `1px solid ${themeColor.themeColors.primaryColor}`,
									borderColor: Colors.WHITE
								}}
								scope="col"
							>
								<i
									class="fas fa-chalkboard-teacher"
									style={{ color: 'white' }}
								></i>
								{`    ${item.className}-${item.section}`}
							</th>
						))}
					</tr>
				</thead>
				<tbody>{bodyRendering(periods)}</tbody>
			</table>
		</div>
	);

	return (
		<div id="div1">
			<div style={{ textAlign: 'right' }}>
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<div>
						<h2 className="h1-responsive font-weight-bold text-left my-4 generalHead" style={{background: themeColor.themeColors.primaryColor}}>
							{' '}
							Staff Timetable{' '}
						</h2>
					</div>
				</div>
			</div>

			<CustomFilterSectionLayout displayDirection="column">
				<div
					style={{
						width: '30%',
						margin: '0 auto',
						display: 'flex',
						justifyContent: 'center'
					}}
				>
					{SearchByStaffId}
				</div>
			</CustomFilterSectionLayout>

			<br />
			<div class="col-md-12">
				<div>
					<h2 className="h1-responsive font-weight-bold text-center my-4 generalHead" style={{background: themeColor.themeColors.primaryColor}}>
						Staff Timetable{' '}
						<strong style={{ textDecoration: 'underline' }}>
							{timeTableData.staffName}
						</strong>
					</h2>
				</div>
			</div>
			{isLoading ? (
				<Spinner />
			) : tHead < 1 ? (
				<div
					style={{ display: 'flex', justifyContent: 'center', color: 'red' }}
				>
					<strong>No Data</strong>
				</div>
			) : (
				TimeTableView
			)}
		</div>
	);
};

function mapStateToProps(state) {
	return {
		role: state.setUserReducer.users.role,
		name: state.setUserReducer.users.unique_name
	};
}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch)
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(StaffTimeTableByCoordinator);
