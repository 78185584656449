import React, { Component } from "react";

class StaffDocumentsPrint extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { Document } = this.props;
    console.log(Document, "Document Data");
    return (
      <div>
        <div id="div1">
          <section class="mb-4">
            <div class="row print">
              <div class="col-md-12">
                <div class="md-form mb-0">
                  <h2 className="FormHeadingStyle">Staff Documents</h2>
                </div>
              </div>
            </div>
            <div class="container">
              <div class="row rowStaffCheck" style={{ marginTop: 10 }}>
                <div class="col colStaffCheck">
                  <label style={{ fontWeight: "bold" }}>
                    Experience Letter:{" "}
                  </label>{" "}
                  &nbsp;&nbsp;
                  {Document && Document.experienceLetter}
                </div>
                <div class="col colStaffCheck">
                  {" "}
                  <label style={{ fontWeight: "bold" }}>
                    IELTS Certificate:
                  </label>
                  &nbsp;&nbsp; {Document && Document.iletsCertificate}
                </div>
              </div>
              <div class="row rowStaffCheck">
                <div class="col colStaffCheck">
                  <label style={{ fontWeight: "bold" }}>
                    Last Qualification:
                  </label>
                  &nbsp;&nbsp; {Document && Document.lastQualification}
                </div>
                <div class="col colStaffCheck">
                  <label style={{ fontWeight: "bold" }}>Passport Copy:</label>
                  &nbsp;&nbsp; {Document && Document.passportCopy}
                </div>
              </div>
              <div class="row rowStaffCheck">
                <div class="col colStaffCheck">
                  <label style={{ fontWeight: "bold" }}>ID Card Copy:</label>
                  &nbsp;&nbsp; {Document && Document.idCardCopys}
                </div>
                <div class="col colStaffCheck">
                  {" "}
                  <label style={{ fontWeight: "bold" }}>
                    Ministry Approval:{" "}
                  </label>
                  &nbsp;&nbsp; {Document && Document.ministryApproval}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default StaffDocumentsPrint;
