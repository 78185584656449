class URL_CONSTANTS {
  static URL_STAFF_IMAGE =
    "https://embassyportal.schoolsmart.org.uk/Uploads/StaffImages/";
  static URL_STUDENT_IMAGE =
    "https://embassyportal.schoolsmart.org.uk/Uploads/StudentImages/";
  static URL_STAFF_DOCS =
    "https://embassyportal.schoolsmart.org.uk/Uploads/StaffDocuments/";
  static URL_STUDENT_DOCS =
    "https://embassyportal.schoolsmart.org.uk/Uploads/StudentDocuments/";
  static URL_PARENT_DOCS =
    "https://embassyportal.schoolsmart.org.uk/Uploads/ParentDocuments/";
    
}
export default URL_CONSTANTS;
