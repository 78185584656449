import React, { Component } from "react";
import DatePicker from "react-date-picker";

const TextBoxesForDiscounts = (props) => {
  const {
    itemObj,
    onHandleTextChange,
    stateData,
    rowIndex,
    updateSpecialArrObjOnChange,
    updateFeeTypeAndAmount,
    arrName,
    updateDiscountAmount,
  } = props;

  let keys = Object.keys(itemObj);
  // console.log(stateData.siblingsDetails[0]['age'])
  if (keys.includes("StudentSiblingDetailId") && keys.includes("studentId")) {
    let newKeys = keys.filter(
      (item) => item !== "studentId" && item !== "StudentSiblingDetailId"
    );
    keys = [...newKeys];
  }
  if (keys.includes("PreviousSchoolDetailId") && keys.includes("studentId")) {
    let newKeys = keys.filter(
      (item) => item !== "studentId" && item !== "PreviousSchoolDetailId"
    );
    keys = [...newKeys];
  }
  if (keys.includes("accountId") && keys.includes("accountType")) {
    let newKeys = keys.filter(
      (item) => item !== "accountId" && item !== "accountType"
    );
    keys = [...newKeys];
  }

  return (
    <React.Fragment>
      {keys.map((item, index) =>
        item === "fee" ? (
          <td key={index}>
            <input
              type="text"
              readOnly={true}
              class="form-control"
              name={`${item}`}
              placeholder={`${item}`}
              //   onChange={(e) =>
              //     updateSpecialArrObjOnChangeDate(e, rowIndex, arrName)
              //   }
              value={stateData[arrName][rowIndex][`${item}`]}
            />
          </td>
        ) : item === "discountType" ? (
          <td key={index}>
            <select
              class="custom-select"
              name={`${item}`}
              onChange={(e) =>
                updateSpecialArrObjOnChange(e, rowIndex, arrName)
              }
            >
              <option selected={true}>Select Discount Type..</option>
              {stateData.discountType &&
                stateData.discountType.map((item, index) => (
                  <option key={index} value={`${item}`}>
                    {item}
                  </option>
                ))}
            </select>
          </td>
        ) : item === "discount" ? (
          <td key={index}>
            <input
              type="text"
              readOnly={true}
              class="form-control"
              name={`${item}`}
              placeholder={`${item}`}
              //   onChange={(e) =>
              //     updateSpecialArrObjOnChangeDate(e, rowIndex, arrName)
              //   }
              value={stateData[arrName][rowIndex][`${item}`]}
            />
          </td>
        ) : item === "feeName" ? (
          <td key={index}>
            <select
              class="custom-select"
              name={`${item}`}
              onChange={(e) => updateFeeTypeAndAmount(e, rowIndex, arrName)}
            >
              <option selected={true}>Select Discount Type..</option>
              {stateData.dataCollection &&
                stateData.dataCollection.map((items, index) => (
                  <option key={index} value={JSON.stringify(items)}>
                    {items.feeName}
                  </option>
                ))}
            </select>
          </td>
        ) : (
          <td key={index}>
            <input
              type="text"
              placeholder={`${item}`}
              class="form-control"
              name={`${item}`}
              value={stateData[arrName][rowIndex][`${item}`]}
              onChange={(e) => updateDiscountAmount(e, rowIndex, arrName)}
            />
          </td>
        )
      )}
    </React.Fragment>
  );
};
export default TextBoxesForDiscounts;
