import React, { Component } from 'react';

class DateandTime extends Component {
	constructor(props) {
		super(props);
		this.state = {
			Date: ''
		};
	}
	componentDidMount() {
		setInterval(() => {
			this.setState({
				Date: new Date().toLocaleString()
			});
		}, 1000);
	}
	render() {
		return (
			<div style={{ position: 'relative' }}>
				<label style={{ float: 'right' }}>{this.state.Date}</label>
			</div>
		);
	}
}

export default DateandTime;
