import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import $ from 'jquery';
import isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom';

import ThemeContext from "../../context/themeContext/ThemeContext";


class AcademicCalendarMain extends Component {
    constructor(props){
        super(props);
        this.state = {
            roomData: [],
            isLoading: true,
            modalEdit: false,
            editToggle: false,
            generateToggle: false,
            generateIndex: '',
            msg: '',
            editIndex: '',

            accountId: this.props.userData.unique_name,
            accountType: this.props.userData.role
        }

        


    }

    static contextType = ThemeContext

    componentDidMount(){
        this.getSimple('AnnualCalendar', "sessionList")
        this.getSimple('Session', 'session')
        // this.handleDelete(1)
        // this.handleDelete(2)
        // this.handleDelete(4)
        console.log(this.props);
        
    }   

    getSimple = (type, state) => {
        this.props.sharedActions.getDataWithoutDispatch(
            `/api/${type}`
        ).then(success => { 
            this.setState({
                [state]: success,
                isLoading: false
            })
        }).catch(error => {
            this.setState({
                msg: 'Error Occured!!',
                errorMsg: true,
                isLoading: false
            })
            console.error('Error occured', error);
        })
    }

    

    onEditClick = id => {
        const { roomData } = this.state
        let modalData = roomData.filter((items, index ) =>  {
            if(id === items.id){
                    
            this.setState({
                editIndex: index
            })
            console.log(index)
            return id === items.id 
            }
        })
        console.log(modalData)
      
        this.setState({
            id: modalData[0].id,
            date: modalData[0].date,
            time: modalData[0].time,
            floorId: modalData[0].floorId,
            floorName: modalData[0].floorName,
            roomName: modalData[0].roomName,
            accountId: modalData[0].accountId,
            accountType: modalData[0].accountType, 
            
        }, () => {
            $('#myModal1').modal({
                show: true
            })
        })
    }


    myFunction = () => {
		// Get the snackbar DIV
		var x = document.getElementById('snackbar');

		// StepperGeneralButtons the "show" class to DIV
		x.className = 'show';

		// After 3 seconds, remove the show class from DIV
		setTimeout(function() {
		}, 3000);
	};

    handleSubmit = e => {
        e.preventDefault();
        console.log('Submit Called')

        const {  
            roomData,
            id,
            date,
            time,
            floorId,
            floorName,
            roomName,
            accountId,
            accountType, 
             } = this.state;


             let newRoomData = roomData.map((items, index ) => {
                 if(items.id == id){
                     return {
                        id,
                        date,
                        time,
                        floorId,
                        floorName,
                        roomName,
                        accountId,
                        accountType,
                     }
                 }else{
                    return items
                }
             })

             let data = {
                 "Rooms" : newRoomData
             }
        
        console.log('data model', data)
        this.props.sharedActions.editRecordWithoutDispatch(
            `/api/Rooms/`,
            id,
            data
        ).then(success => {
            console.log('success', success)
            this.setState({
                roomData: success.Rooms
            })
            
                
                $('#myModal1').modal('hide')
            
            this.setState({
                msg: 'Record Changed Successfully!',

                
            })
            this.props.snackbar();
            

        }).catch(error => {
            $('#myModal1').modal('hide')

            this.setState({
                msg: error.response ? error.response.data.Message : 'Error Occured!!',
                isLoading: false
            })
            console.error('Error name: ', error)
            this.props.snackbar();


        })
    }

    onDeleteClick = id => {
        const { roomData } = this.state
        let modalData = roomData.filter((items, index ) =>  {
            if(id === items.id){
                    
            this.setState({
                editIndex: index
            })
            console.log(index)
            return id === items.id 
            }
        })
        console.log(id)
        this.setState({
             modalDataDeleteId: id
            
        }, () => {
            $('#myModal2').modal({
                show: true
            })
        })
    }

    handleDelete = id => {
        
        const { editIndex } = this.state;

        this.props.sharedActions.deleteRecordWithoutDispatch(
            '/api/AnnualCalendar',
            id
        ).then(success => {
            this.state.roomData.splice(editIndex, 1)
            
                this.setState({
                    msg: 'Record Deleted!',
                })
                this.props.snackbar();

            
        }).catch(error => {
            
            this.setState({
                msg: error.response ? error.response.data.Message : 'Error Occured!!',
                isLoading: false
            })
            this.props.snackbar();

        })
    }


    btnTechHover = () => {
		$(document).ready(function() {
			$('button').hover(
				function() {
					$(this).css('box-shadow', '0px 5px 5px grey');
					$(this).css('border-color', 'white');
				},
				function() {
					$(this).css('box-shadow', '0px 0px 0px white');
				}
			);
		});
	};

    onHandleText = e => {
		this.setState({
			[e.target.name]: e.target.value
        });
        console.log([e.target.name], e.target.value)
	};


    render(){

        const { search, isLoading, editToggle, roomData, msg, floorData, session, sessionList,
            floorName,
            roomName,
            accountId,
            accountType
     } = this.state;
    
     const deleteRoomData = (
        <div>
        <div className="modal" id="myModal2">
            <div className="modal-dialog">
                <div className="modal-content modal_content_custom">
                    <div
                        style={{
                            background: this.context.themeColors.primaryColor,
                            borderBottomLeftRadius: 30,
                            borderBottomRightRadius: 30,
                            borderTopLeftRadius: 18,
                            borderTopRightRadius: 18
                        }}
                        className="modal-header modal_custom_header"
                    >
                        <h4 style={{ color: 'white' }} className="modal-title">
                            Confirm
                        </h4>
                        <button
                            style={{ color: 'white' }}
                            on
                            type="button"
                            className="close"
                            data-dismiss="modal"
                        >
                            &times;
                        </button>
                    </div>
                    <div className="modal-body">
                    <div >
                                        <div style={{ textAlign: 'center' }}>
                                            <h4>Are you sure?</h4>
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-evenly'
                                            }}
                                        >
                                            <div>
                                                <button
                                                    data-dismiss="modal"
                                                    onClick={() => this.handleDelete(this.state.modalDataDeleteId)}
                                                    type="button"
                                                    class="btn btn-primary buttonAppear"
                                                >
                                                    Yes
                                                </button>
                                            </div>
                                            <div>
                                                <button
                                                    data-dismiss="modal"
                                                    type="button"
                                                    class="btn btn-primary buttonAppear"
                                                >
                                                    No
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                    </div>
                    <div class="modal-footer">
                            <button
                                type="button"
                                style={{ background: '#00435D' }}
                                class="btn btn-danger"
                                onClick={() => this.setState({editToggle: false})}
                                data-dismiss="modal"
                            >
                                Close
                            </button>
                        </div>
                </div>
            </div>
        </div>
        </div>
    )

        const Loader = (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div className="spinner-border" style={{ color: this.context.themeColors.primaryColor }}></div>
            </div>
        );
        console.log(this.props.userData.role,'role');
        const renderSessionData = session && session.session.map((item, index) => {
           
            return (

            <tr key={index} >
                <th scope="row" className="borderSpace" style={{ padding: 1 }} >
                    {index + 1}
                </th>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.currentSession}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {accountId}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {accountType}
                </td>
                {
                    this.props.userData.role ==='Owner' ?
                <td className="borderSpace" style={{ padding: 1 }}>
                {(!sessionList || sessionList.filter((data, index) => data.sessionId == item.sessionId ).length < 1) && <Link to={{
                        pathname: "InitiateAcademicCalendar",
                        state: {
                            sessionId: item.sessionId
                        }
                    }}  >
                        <a href="#" data-toggle="tooltip" title="Add!">
                            
                            <button
								id="firstbutton"
								onMouseOver={this.btnTechHover()}
								style={{
									cursor: 'pointer',
									fontSize: 20,
									border: 'none',
									marginLeft: 5,
									background: 'none',
									marginBottom: 5
								}}
							>
								<i className="fas fa-plus"></i>
							</button>
						</a></Link>
                        
                        }
                        {
    

   sessionList && sessionList.filter((data, index) => data.sessionId == item.sessionId ).length > 0 && <Link to={{
                        pathname: "ViewAcademicCalendar",
                        state: {
                            sessionId: item.sessionId
                        }
                    }}  >
                        <a href="#" data-toggle="tooltip" title="View!">
							<button
								onMouseOver={this.btnTechHover()}
								style={{
									cursor: 'pointer',
									fontSize: 20,
									border: 'none',
									background: 'none',
									marginLeft: 5,
									marginBottom: 5
								}}
							>
								<i className="fas fa-list"></i>
							</button>
						</a>
                        </Link>
                        
                        }
                </td>
                : ''
                }
                
            </tr>
            
            );
        }) 

        return (
            
            <div>
				<section  className="mb-4">
				<h2  
                        style={{ background: this.context.themeColors.primaryColor }}
                        className="h1-responsive font-weight-bold text-center  generalHead">
                        Academic Calendar
                    </h2>
			    <div>
                <SnackBar msg={msg} />
                <div className="my-2" style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                }}>
                    <div className="table-responsive">
					<table className="table table-hover text-center">
						<thead style={{ background: this.context.themeColors.primaryColor, color: 'white' }}>
							<tr>
								<th scope="col" style={{ padding: 1 }}>
									#
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Session
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Account Name
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Account Role
								</th>
                                { this.props.userData.role ==='Owner' ?
                                <th scope="col" style={{ padding: 1 }}>
                                    Actions
								</th>
                                :''}

                                
							</tr>
						</thead>
						<tbody>{isLoading ? Loader : renderSessionData}</tbody>
					</table>
				</div>
                </div>
                {deleteRoomData}
            </div>
            </section>
            </div>
        );
    }

}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch),
	};
}
function mapStateToProps(state) {
	return {
		userData: state.setUserReducer.users,
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AcademicCalendarMain);
