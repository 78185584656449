import React from "react";
import { Types } from "../../hooks/HookReducers/StaffReducer";
import CustomDatePicker from "../../components/CustomDatePicker";
import { startCase } from "lodash";

const DynamicRowsForFeeAcademic = (props) => {
  const {
    itemObj,
    onHandleTextChange,
    stateData,
    onDateChange,
    rowIndex,
    updateSpecialArrObjOnChange,
    updateSpecialArrObjOnChangeDrop,
    arrName,
    hookReuse = false,
    dispatch,
    dropData
  } = props;

  let keys = Object.keys(itemObj);
  // console.log(stateData.siblingsDetails[0]['age'])
  if (keys.includes("StudentSiblingDetailId") && keys.includes("studentId")) {
    let newKeys = keys.filter(
      (item) => item !== "studentId" && item !== "StudentSiblingDetailId"
    );
    keys = [...newKeys];
  }
  if (keys.includes("PreviousSchoolDetailId") && keys.includes("studentId")) {
    let newKeys = keys.filter(
      (item) => item !== "studentId" && item !== "PreviousSchoolDetailId"
    );
    keys = [...newKeys];
  }
  if (keys.includes("staffCertificateId") && keys.includes("staffId")) {
    let newKeys = keys.filter(
      (item) => item !== "staffCertificateId" && item !== "staffId"
    );
    keys = [...newKeys];
  }
  if (
    keys.includes("id") &&
    keys.includes("staffId") &&
    keys.includes("interviewId")
  ) {
    let newKeys = keys.filter(
      (item) => item !== "id" && item !== "staffId" && item !== "interviewId"
    );
    keys = [...newKeys];
  }
  if (
    keys.includes("id") &&
    keys.includes("staffId")
  ) {
    let newKeys = keys.filter(
      (item) => item !== "id" && item !== "staffId" && item !== "interviewId"
    );
    keys = [...newKeys];
  }

  if (
    keys.includes("id") &&
    keys.includes("classId") &&
    keys.includes("className") &&
    keys.includes("date") &&
    keys.includes("time") &&
    keys.includes("accountType") &&
    keys.includes("accountId")
  ) {
    let newKeys = keys.filter(
      (item) =>
        item !== "id" &&
        item !== "classId" &&
        item !== "className" &&
        item !== "date" &&
        item !== "time" &&
        item !== "accountType" &&
        item !== "accountId"
    );
    keys = [...newKeys];
  }
  console.log("ITEMSSS" , itemObj)
  debugger;
  return (
    <React.Fragment>
      {keys.map((item, index) =>
        item === "feeName" ||
          item === "siblingpassDOE" ||
          item === "siblingIdcardDOI" ||
          item === "siblingIdcardDOE" ? (
          <td style={{ padding: 0 }}>
            <div class="md-form mt-2">
              <select
                onChange={(e) => updateSpecialArrObjOnChangeDrop(e, rowIndex, arrName)}
                class="custom-select"
                name="feeName"
                // value={item.feeName == item.feeName}
              >
                <option selected>
                 {stateData[arrName][rowIndex][`${item}`] !== "" ? stateData[arrName][rowIndex][`${item}`] : "Select FeeName" }
												</option>
                {dropData &&
                  dropData.map((item, index) => (
                    <option key={index} value={JSON.stringify(item)}>
                      {item.feeName}
                    </option>
                  ))}
              </select>
            </div>
            {/* <CustomDatePicker
              marginTop={0}
              feildName={item}
              onDateChange={onDateChange}
              reuse={true}
              rowIndex={rowIndex}
              arrName={arrName}
              // inputLabelName={item.inputLabelName}
              dispatchFlag={false}
              dispatch={dispatch}
              stateData={stateData}
              extraType={""}
            /> */}
          </td>
        ) : item === "view" ? 
            (
              <td key={index}>
              <input
                disabled={true}
                type="text"
                placeholder={`Enter Field`}
                class="form-control"
                name={`${item}`}
                value={stateData[arrName][rowIndex][`${item}`]}
                onChange={
                  hookReuse
                    ? (e) => onDateChange(e, item, rowIndex)
                    : (e) => updateSpecialArrObjOnChange(e, rowIndex, arrName)
                }
              />
            </td>
            ) :
        (
          <td key={index}>
            <input
              disabled={item === "feeCode" ? true : false}
              type="text"
              placeholder={`Enter Field`}
              class="form-control"
              name={`${item}`}
              value={stateData[arrName][rowIndex][`${item}`]}
              onChange={
                hookReuse
                  ? (e) => onDateChange(e, item, rowIndex)
                  : (e) => updateSpecialArrObjOnChange(e, rowIndex, arrName)
              }
            />
          </td>
        )
      )}
    </React.Fragment>
  );
};
export default DynamicRowsForFeeAcademic;