import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import DateandTime from '../Transport/DateandTime';
import isNull from 'lodash/isNull';

import SnackBar from "../../components/SnackBar/SnackBar";
import LoaderWrapper from "../../components/HOC/LoaderWrapper";
import ThemeContext from "../../context/themeContext/ThemeContext";

class AddPayroll extends Component {
	constructor(props) {
		super(props);
		this.state = {
			errorMsg: false,
			successMsg: false,
			msg: '',
            backColor: '',
			isLoading: false,
			monthList: [],
			yearList: [],
			session: null,
			sessionId: null,
			campus: null,
			campusId: null,
			departmentId: null,
			postId: null,
			staffId: null,
			month: null,
			year: null,
			taxAmount: null,
			allowances: null,
			bonusAmount: null,
			valid: null
		};
	}

	componentDidMount() {
		this.getSimple('session', 'session');
		this.getSimple('campusDropdown', 'campus');
		this.generateMonthAndYear(30);
	}

	generateMonthAndYear = limit => {
		const year = new Date().getFullYear();
		const years = Array.from(new Array(limit), (val, index) => index + year);
		this.setState({
			yearList: years,
			monthList: [
				'January',
				'February',
				'March',
				'April',
				'May',
				'June',
				'July',
				'August',
				'September',
				'October',
				'November',
				'December'
			]
		});
	};

    getSimple = (type, state) => {
        this.props.sharedActions.getDataWithoutDispatch(
            `/api/${type}`
        ).then(success => {
            this.setState({
                [state]: success,
                isLoading: false
            })


        }).catch(error => {
            this.setState({
                msg: 'Error Occured!!',
                isLoading: false
            })
            this.props.snackbar();
            console.error('Error occured', error);
        })
    }


	myFunction = () => {
		// Get the snackbar DIV
		var x = document.getElementById('snackbar');

		// StepperGeneralButtons the "show" class to DIV
		x.className = 'show';

		// After 3 seconds, remove the show class from DIV
		setTimeout(function() {}, 3000);
	};

	onHandleTextChange = e => {
		console.log([e.target.name], e.target.value);

		this.setState(
			{
				[e.target.name]: e.target.value
			},
			() => {
				if (this.state.year !== null) {
					this.handleValidation();
				}
			}
		);
	};

	handleValidation = () => {
		const { campusId, departmentId, postId, staffId, month, year } = this.state;

		this.props.sharedActions
			.getDataWithoutDispatch(
				`/api/Payroll/?staffId=${staffId}&month=${month}&year=${year}&campusId=${campusId}&postId=${postId}&departmentId=${departmentId}`
			)
			.then(success => {
				this.setState({
					valid: success,
					isLoading: false
				});
			})
			.catch(error => {
				this.setState({
					valid: null,
					msg: error.response ? error.response.data.Message :  'Error Occured!!',
					isLoading: false
				});

				this.props.snackbar();

				

				console.error('Error Occured!!');
			});
	};

	handleSubmit = e => {
		e.preventDefault();
		const {
			sessionId,
			campusId,
			departmentId,
			postId,
			staffId,
			month,
			year,
			taxAmount,
			allowances,
			bonusAmount,
			fromDate,
			toDate
		} = this.state;
		if (
			isNull(sessionId) ||
			isNull(campusId) ||
			isNull(departmentId) ||
			isNull(postId) ||
			isNull(staffId) ||
			isNull(month) ||
			isNull(year) ||
			isNull(taxAmount) ||
			isNull(allowances) ||
			isNull(bonusAmount)
		) {
			this.setState({
				msg: 'Please fill the form respectively!'
			});
			this.props.snackbar();
		} else {
			const monthNo =
				new Date(Date.parse(`${month} +" 1, ${year}`)).getMonth() + 1;
			const lastDate = new Date(year, monthNo, 0).getDate();

			const parsedMonth = monthNo.toString();
			const parsedFromDate = `${('0' + parsedMonth).slice(-2)}/01/${year}`;
			const parsedToDate = `${('0' + parsedMonth).slice(
				-2
			)}/${lastDate}/${year}`;

			let data = {
				accountId: this.props.userData.unique_name,
				accountType: this.props.userData.role,
				sessionId,
				campusId,
				departmentId,
				postId,
				staffId,
				month,
				year,
				taxAmount,
				allowances,
				bonusAmount
			};

			console.log(data);
			let hit = `/api/Payroll/?fromdate=${parsedFromDate}&todate=${parsedToDate}`;
			console.log(hit);

			this.props.sharedActions
				.simpleAddRequest(
					`/api/Payroll/?fromdate=${parsedFromDate}&todate=${parsedToDate}`,
					data
				)
				.then(success => {
					console.log(success);

					this.setState({
						msg: 'Payroll Added!',
						successMsg: true,
						isLoading: false,
						sessionId: '',
						campusId: '',
						departmentId: '',
						postId: '',
						staffId: '',
						month: '',
						year: '',
						taxAmount: '',
						allowances: '',
						bonusAmount: ''
					});
					this.props.snackbar();
				})
				.catch(error => {
					this.setState({
						msg: error.response ? error.response.data.Message :  'Error Occured!!',
						errorMsg: true,
						isLoading: false
					});
					console.error('Error occured', error);
				});
		}
	};

	render() {
		const {
			successMsg,
			errorMsg,
			msg,
			isLoading,
			session,
			sessionId,
			campus,
			campusId,
			departmentId,
			postId,
			staffId,
			month,
			monthList,
			year,
			yearList,
			taxAmount,
			allowances,
			bonusAmount,
			valid
		} = this.state;

		return (
			<div className="container">
				<section  className="mb-4">
                <ThemeContext.Consumer>
                    {(context) => (
                    <h2  
                        style={{ background: context.themeColors.primaryColor }}
                        className="h1-responsive font-weight-bold text-center my-4 generalHead">
                        Add Payroll
                    </h2>
                     )}
                </ThemeContext.Consumer>

              <SnackBar msg={this.state.msg} backColor={this.state.backColor} />

                    <p class="text-center w-responsive mx-auto mb-5" />

                    <div className="row" style={{
                        justifyContent: 'center',
                        textAlign: 'center'
                    }}>

                <ThemeContext.Consumer>
                {(context) => (
                        <div className="col-md-9 mb-md-0 mb-5" style={{
                            border: 'solid',
                            borderColor: context.themeColors.primaryColor,
                            padding: 40,
                            borderRadius: 15,
                            width: 'auto'

                        }}>
                      <LoaderWrapper isLoading={this.state.isLoading}>
							<form
								id="payroll-form"
								name="payroll-form"
								onSubmit={this.handleSubmit}
							>
								<div className="row">
									<div className="col-md-12">
										<div className="md-form mb-0">
											<DateandTime />
										</div>
									</div>
								</div>
								<br />
								<div class="row">
									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="AdminName" class="font-weight-bold">
												Admin Name
											</label>
											<input
												type="text"
												name="AdminName"
												class="form-control"
												readOnly={true}
												value={this.props.userData.unique_name}
											/>
										</div>
									</div>

									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="AdminType" class="font-weight-bold">
												Admin Type
											</label>
											<input
												type="text"
												name="AdminType"
												class="form-control"
												readOnly={true}
												value={this.props.userData.role}
											/>
										</div>
									</div>
								</div>
								<br />
								<div className="row">
									<div className="col-md-6">
										<div className="md-form mb-0">
											<label for="sessionId" class="font-weight-bold">
												Session
											</label>
											<select
												class="custom-select"
												value={sessionId}
												name="sessionId"
												onChange={this.onHandleTextChange}
												disabled={!session}
											>
												<option value="">Please Select Session</option>
												{session &&
													session.session.map((value, index) => {
														return (
															<option value={value.sessionId}>
																{value.currentSession}
															</option>
														);
													})}
											</select>
										</div>
									</div>
									<div className="col-md-6">
										<div className="md-form mb-0">
											<label for="campusId" class="font-weight-bold">
												Campus
											</label>
											<select
												class="custom-select"
												value={campusId}
												name="campusId"
												onChange={this.onHandleTextChange}
												disabled={!sessionId || !campus}
											>
												<option value="">Please Select Campus</option>
												{campus &&
													campus.campus.map((value, index) => {
														return (
															<option value={value.id}>
																{value.campusName}
															</option>
														);
													})}
											</select>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-md-6" style={{ marginTop: 20 }}>
										<div className="md-form mb-0">
											<label for="departmentId" class="font-weight-bold">
												Department
											</label>
											<select
												class="custom-select"
												value={departmentId}
												name="departmentId"
												onChange={this.onHandleTextChange}
												disabled={!campusId}
											>
												<option value="">Please Select Department</option>
												{campus &&
													campus.department.map((value, index) => {
														return (
															value.campusId == campusId && (
																<option value={value.departmentId}>
																	{value.departmentName}
																</option>
															)
														);
													})}
											</select>
										</div>
									</div>
									<div className="col-md-6" style={{ marginTop: 20 }}>
										<div className="md-form mb-0">
											<label for="postId" class="font-weight-bold">
												Post
											</label>
											<select
												class="custom-select"
												value={postId}
												name="postId"
												onChange={this.onHandleTextChange}
												disabled={!departmentId}
											>
												<option value="">Please Select post</option>
												{campus &&
													campus.post.map((value, index) => {
														return (
															value.campusId == campusId &&
															value.departmentId == departmentId && (
																<option value={value.postId}>
																	{value.postName}
																</option>
															)
														);
													})}
											</select>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-md-6" style={{ marginTop: 20 }}>
										<div className="md-form mb-0">
											<label for="staffId" class="font-weight-bold">
												Staff
											</label>
											<select
												class="custom-select"
												value={staffId}
												name="staffId"
												onChange={this.onHandleTextChange}
												disabled={!postId}
											>
												<option value="">Please Select Staff</option>
												{campus &&
													campus.staff.map((value, index) => {
														return (
															value.campusId == campusId &&
															value.departmentId == departmentId &&
															value.postId == postId && (
																<option value={value.staffId}>
																	{value.staffName}
																</option>
															)
														);
													})}
											</select>
										</div>
									</div>
									<div className="col-md-6" style={{ marginTop: 20 }}>
										<div className="md-form mb-0">
											<label for="month" class="font-weight-bold">
												Month
											</label>
											<select
												class="custom-select"
												value={month}
												name="month"
												onChange={this.onHandleTextChange}
												disabled={!staffId}
											>
												<option value="">Please Select Month</option>
												{monthList &&
													monthList.map((value, index) => {
														return <option value={value}>{value}</option>;
													})}
											</select>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-md-6" style={{ marginTop: 20 }}>
										<div className="md-form mb-0">
											<label for="year" class="font-weight-bold">
												Year
											</label>
											<select
												class="custom-select"
												value={year}
												name="year"
												onChange={this.onHandleTextChange}
												disabled={!month}
											>
												<option value="">Please Select Year</option>
												{yearList &&
													yearList.map((value, index) => {
														return <option value={value}>{value}</option>;
													})}
											</select>
										</div>
									</div>
									<div className="col-md-6" style={{ marginTop: 20 }}>
										<div className="md-form mb-0">
											<label for="taxAmount" class="font-weight-bold">
												Tax Amount %
											</label>
											<input
												type="number"
												name="taxAmount"
												class="form-control"
												value={taxAmount}
												onChange={this.onHandleTextChange}
												disabled={!valid}
											/>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-md-6" style={{ marginTop: 20 }}>
										<div className="md-form mb-0">
											<label for="allowances" class="font-weight-bold">
												Allowances
											</label>
											<input
												type="number"
												name="allowances"
												class="form-control"
												value={allowances}
												onChange={this.onHandleTextChange}
												disabled={!valid}
											/>
										</div>
									</div>
									<div className="col-md-6" style={{ marginTop: 20 }}>
										<div className="md-form mb-0">
											<label for="bonusAmount" class="font-weight-bold">
												Bouns Amount
											</label>
											<input
												type="number"
												name="bonusAmount"
												class="form-control"
												value={bonusAmount}
												onChange={this.onHandleTextChange}
												disabled={!valid}
											/>
										</div>
									</div>
								</div>
								<br />
								<br />
								<div className="text-center text-md-center">
								<button type="submit" className="btns" style={{
                                                    background: context.themeColors.primaryColor,
                                            }}>
										Add
										<span
											style={{
												marginBottom: 5
											}}
											className={
												isLoading && 'spinner-border spinner-border-sm'
											}
										></span>
									</button>
								</div>
							</form>
							</LoaderWrapper>
                            <div class="status" />
                </div>
              )}
            </ThemeContext.Consumer>
                    </div>
                </section>
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch)
	};
}
function mapStateToProps(state) {
	return {
		userData: state.setUserReducer.users
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AddPayroll);
