import React from "react";
import ViewRendering from "../components/ViewRendering";
import { renderingStuff, images } from "../utils";
import DashboardLayout from "../components/HOC/DashboardLayout";
import NewViewRendering from "../components/ViewRendering/NewViewRendering";

export default function LibraryDashboard(props) {
  return (
    <div>
      {/* <h3 class="h3-responsive font-weight-bold text-center my-4">
				Student Progress Report Dashboard
			</h3>
			<div
				class="row"
				style={{
					justifyContent: 'center',
					textAlign: 'center',
					marginBottom: -30
				}}
			>
				<div class="col-md-8">
					<div class="md-form mb-0">
						<hr class="style-head" />
					</div>
				</div>
			</div>
			<ViewRendering
				centered={true}
				centerWidth={'50%'}
				mainHeading={images.imgAcHead}
				data={renderingStuff.StudentReport_Dashboard_Links}
				mainHead={false}
			/> */}
      <DashboardLayout
        name="Student Progress Report Dashboard"
        image={images.DashboardStudentProgressReport}
      >
        <NewViewRendering
          {...props}
          data={renderingStuff.StudentReport_Dashboard_Links}
        />
      </DashboardLayout>
    </div>
  );
}
