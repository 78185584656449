export const SET_USER = 'SET_USER';
export const SET_NOTIFICATION_LINK = 'SET_NOTIFICATION_LINK';

// Admin Details
export const GET_ALL_ADMIN = 'GET_ALL_ADMIN';
export const SET_LOADER_FLAG = 'SET_LOADER_FLAG';
export const DELETE_ADMIN = 'DELETE_ADMIN';
export const EDIT_ADMIN_RECORD = 'EDIT_ADMIN_RECORD';
export const ADD_ADMIN_RECORD = 'ADD_ADMIN_RECORD';
// End Admin Details

// StepperGeneralButtons Campus
export const GET_ALL_CAMPUS = 'GET_ALL_CAMPUS';
export const SET_LOADER_FLAG_CAMPUS = 'SET_LOADER_FLAG_CAMPUS';
export const DELETE_CAMPUS = 'DELETE_CAMPUS';
export const EDIT_CAMPUS_RECORD = 'EDIT_CAMPUS_RECORD';
export const ADD_CAMPUS = 'ADD_CAMPUS';
//End Campus

//Canteen
export const SET_ALL_CAMPUS_NAME_INTO_CANTEEN_SELECT =
	'SET_ALL_CAMPUS_NAME_INTO_CANTEEN_SELECT';
// export const SET_ALL_CATEGORY_NAME_INTO_ITEMS_SELECT =
//   "SET_ALL_CATEGORY_NAME_INTO_ITEMS_SELECT";
export const GET_ALL_CANTEEN = 'GET_ALL_CANTEEN';
export const SET_LOADER_FLAG_CANTEEN = 'SET_LOADER_FLAG_CANTEEN';
export const DELETE_CANTEEN = 'DELETE_CANTEEN';
export const EDIT_CANTEEN_RECORD = 'EDIT_CANTEEN_RECORD';
export const ADD_CANTEEN = 'ADD_CANTEEN';
export const SET_ALL_HANDLER_NAME_INTO_CANTEEN_SELECT =
	'SET_ALL_HANDLER_NAME_INTO_CANTEEN_SELECT';
// export const SET_ALL_CANTEEN_NAME_INTO_CATEGORY_SELECT =
//   "SET_ALL_CANTEEN_NAME_INTO_CATEGORY_SELECT";
//END CANTEEN

//Canteen Category

export const GET_ALL_CANTEEN_CATEGORY = 'GET_ALL_CANTEEN_CATEGORY';
export const SET_LOADER_FLAG_CANTEEN_CATEGORY =
	'SET_LOADER_FLAG_CANTEEN_CATEGORY';
export const DELETE_CANTEEN_CATEGORY = 'DELETE_CANTEEN_CATEGORY';
export const EDIT_CANTEEN_CATEGORY_RECORD = 'EDIT_CANTEEN_CATEGORY_RECORD';
export const ADD_CANTEEN_CATEGORY = 'ADD_CANTEEN_CATEGORY';
export const SET_ALL_CANTEEN_NAME_INTO_CATEGORY_SELECT =
	'SET_ALL_CANTEEN_NAME_INTO_CATEGORY_SELECT';

//End Canteen Category

//Canteen Items

export const SET_ALL_CATEGORY_NAME_INTO_ITEMS_SELECT =
	'SET_ALL_CATEGORY_NAME_INTO_ITEMS_SELECT';
export const GET_ALL_CANTEEN_ITEMS = 'GET_ALL_CANTEEN_ITEMS';
export const SET_LOADER_FLAG_CANTEEN_ITEMS = 'SET_LOADER_FLAG_CANTEEN_ITEMS';
export const DELETE_CANTEEN_ITEMS = 'DELETE_CANTEEN_ITEMS';
export const EDIT_CANTEEN_ITEMS_RECORD = 'EDIT_CANTEEN_ITEMS_RECORD';
export const ADD_CANTEEN_ITEMS = 'ADD_CANTEEN_ITEMS';

//END CANTEEN Items

//Transport
export const SET_ALL_ROUTE_NAME_INTO_VEHICLE_SELECT =
	'SET_ALL_ROUTE_NAME_INTO_VEHICLE_SELECT';
// export const SET_ALL_VEHICLE_NAME_INTO_DRIVER_SELECT =
//   "SET_ALL_VEHICLE_NAME_INTO_DRIVER_SELECT";
export const GET_ALL_TRANSPORT = 'GET_ALL_TRANSPORT';
export const SET_LOADER_FLAG_TRANSPORT = 'SET_LOADER_FLAG_TRANSPORT';
export const DELETE_TRANSPORT = 'DELETE_TRANSPORT';
export const EDIT_TRANSPORT_RECORD = 'EDIT_TRANSPORT_RECORD';
export const ADD_TRANSPORT = 'ADD_TRANSPORT';
// END TRANSPORT

//Driver

export const SET_ALL_VEHICLE_NAME_INTO_DRIVER_SELECT =
	'SET_ALL_VEHICLE_NAME_INTO_DRIVER_SELECT';
export const GET_ALL_DRIVER = 'GET_ALL_DRIVER';
export const SET_LOADER_FLAG_DRIVER = 'SET_LOADER_FLAG_DRIVER';
export const DELETE_DRIVER = 'DELETE_DRIVER';
export const EDIT_DRIVER_RECORD = 'EDIT_DRIVER_RECORD';
export const ADD_DRIVER = 'ADD_DRIVER';

//End Driver

//Driver HISTORY
export const GET_ALL_DRIVER_HISTORY = 'GET_ALL_DRIVER_HISTORY';
export const SET_ALL_DRIVER_NAME_IN_HISTORY_SELECT =
	'SET_ALL_DRIVER_NAME_IN_HISTORY_SELECT';

//END HISTORY

//Vehicle
export const SET_ALL_VEHICLE_NAME_INTO_VEHICLE_SELECT =
	'SET_ALL_VEHICLE_NAME_INTO_VEHICLE_SELECT';
export const GET_ALL_VEHICLE = 'GET_ALL_VEHICLE';
export const SET_LOADER_FLAG_VEHICLE = 'SET_LOADER_FLAG_VEHICLE';
export const DELETE_VEHICLE = 'DELETE_VEHICLE';
export const EDIT_VEHICLE_RECORD = 'EDIT_VEHICLE_RECORD';
export const ADD_VEHICLE = 'ADD_VEHICLE';
//End Vehicle

//ADD_INVENTORY_ITEMS
export const SET_ALL_CATEGORY_NAME_INTO_ADD_INVENTORY_ITEM_SELECT =
	'SET_ALL_CATEGORY_NAME_INTO_ADD_INVENTORY_ITEM_SELECT';
export const SET_ALL_SUPPLIER_NAME_INTO_ADD_INVENTORY_ITEM_SELECT =
	'SET_ALL_SUPPLIER_NAME_INTO_ADD_INVENTORY_ITEM_SELECT';
export const GET_ALL_INVENTORY = 'GET_ALL_INVENTORY';
export const SET_LOADER_FLAG_INVENTORY_ADD = 'SET_LOADER_FLAG_INVENTORY_ADD';
export const DELETE_INVENTORY_ITEM = 'DELETE_INVENTORY_ITEM';
export const EDIT_INVENTORY_ITEM_RECORD = 'EDIT_INVENTORY_ITEM_RECORD';
export const ADD_INVENTORY_ITEM = 'ADD_INVENTORY_ITEM';
//End INVENTORY ITEMS

//STOCK
export const SET_ALL_ITEMS_NAME_INTO_ADD_STOCK_SELECT =
	'SET_ALL_ITEMS_NAME_INTO_ADD_STOCK_SELECT';
export const SET_ALL_ITEMS_NAME_INTO_ADD_STOCK_BARCODE =
	'SET_ALL_ITEMS_NAME_INTO_ADD_STOCK_BARCODE';
export const GET_ALL_STOCK = 'GET_ALL_STOCK';
export const SET_LOADER_FLAG_STOCK_ADD = 'SET_LOADER_FLAG_STOCK_ADD';
export const DELETE_STOCK = 'DELETE_STOCK';
export const EDIT_STOCK_RECORD = 'EDIT_STOCK_RECORD';
export const ADD_STOCK = 'ADD_STOCK';
//END STOCK

//FINANCE

export const GET_ALL_FINANCE = 'GET_ALL_FINANCE';
export const SET_LOADER_FLAG_FINANCE_ADD = 'SET_LOADER_FLAG_FINANCE_ADD';
export const DELETE_FINANCE = 'DELETE_FINANCE';
export const EDIT_FINANCE_RECORD = 'EDIT_FINANCE_RECORD';
export const ADD_FINANCE = 'ADD_FINANCE';
export const Add_Recrod_To_FINANCE = 'Add_Recrod_To_FINANCE';
export const SET_ALL_SESSION_NAME_INTO_FINANCE_SELECT =
	'SET_ALL_SESSION_NAME_INTO_FINANCE_SELECT';
//END FINANCE

//BUDGET
export const SET_ALL_SESSION_NAME_INTO_BUDGET_SELECT =
	'SET_ALL_SESSION_NAME_INTO_BUDGET_SELECT';
export const SET_ALL_BUDGETITEM_NAME_INTO_BUDGET_SELECT =
	'SET_ALL_BUDGETITEM_NAME_INTO_BUDGET_SELECT';
export const GET_ALL_BUDGET = 'GET_ALL_BUDGET';
export const SET_LOADER_FLAG_BUDGET_ADD = 'SET_LOADER_FLAG_BUDGET_ADD';
export const DELETE_BUDGET = 'DELETE_BUDGET';
export const EDIT_BUDGET_RECORD = 'EDIT_BUDGET_RECORD';
export const ADD_BUDGET = 'ADD_BUDGET';
//END BUDGET

//STOCK_USAGE
export const SET_ALL_ITEMS_NAME_INTO_ADD_STOCK_USAGE_BARCODE =
	'SET_ALL_ITEMS_NAME_INTO_ADD_STOCK_USAGE_BARCODE';
export const SET_ALL_ITEMS_NAME_INTO_ADD_STOCK_USAGE_SELECT =
	'SET_ALL_ITEMS_NAME_INTO_ADD_STOCK_USAGE_SELECT';
export const SET_ALL_STUDENT_NAME_INTO_ADD_STOCK_USAGE_SELECT =
	'SET_ALL_STUDENT_NAME_INTO_ADD_STOCK_USAGE_SELECT';
export const SET_ALL_STAFF_NAME_INTO_ADD_STOCK_USAGE_SELECT =
	'SET_ALL_STAFF_NAME_INTO_ADD_STOCK_USAGE_SELECT';
export const GET_ALL_STOCK_USAGE = 'GET_ALL_STOCK_USAGE';
export const GET_PARTIAL_STOCK_USAGE = 'GET_PARTIAL_STOCK_USAGE';
export const SET_LOADER_FLAG_STOCK_USAGE_ADD =
	'SET_LOADER_FLAG_STOCK_USAGE_ADD';
export const DELETE_STOCK_USAGE = 'DELETE_STOCK_USAGE';
export const EDIT_STOCK_USAGE_RECORD = 'EDIT_STOCK_USAGE_RECORD';
export const ADD_STOCK_USAGE = 'ADD_STOCK_USAGE';
//END STOCK_USAGE

// ADMISSION FORM
export const GET_UPLOAD_STUDENT_IMAGE = 'GET_UPLOAD_STUDENT_IMAGE';
export const GET_ALL_STUDENT_DOCUMENTS = 'GET_ALL_STUDENT_DOCUMENTS';
export const GET_ALL_PARENT_DOCUMENTS = 'GET_ALL_PARENT_DOCUMENTS';

export const GET_CLASS_SECTION_GRADE = 'GET_CLASS_SECTION_GRADE';
export const SET_LOADER_SECTION_GRADE = 'SET_LOADER_SECTION_GRADE';
export const SET_FORM_LEAVE_FLAG = 'SET_FORM_LEAVE_FLAG';
export const ADD_STUDENT_ADMISSION_FORM = 'ADD_STUDENT_ADMISSION_FORM';
export const SET_ADMISSION_FORM_DATA = 'SET_ADMISSION_FORM_DATA';
export const SET_SINGLE_FORM_DATA = 'SET_SINGLE_FORM_DATA';
export const GET_INDIVIDUAL_STUDENT_DATA = 'GET_INDIVIDUAL_STUDENT_DATA';
export const DELETE_ADD_FORM_FROM_VIEW = 'DELETE_ADD_FORM_FROM_VIEW';
export const GET_CURRENT_SESSION = 'GET_CURRENT_SESSION';
export const GET_ALL_ROUTES_DETAILS = 'GET_ALL_ROUTES_DETAILS';

//end addmision form

//Census Information
export const SET_ALL_STUDENT_NAME_INTO_CENSUS_SELECT =
	'SET_ALL_STUDENT_NAME_INTO_CENSUS_SELECT';
export const GET_ALL_CENSUS = 'GET_ALL_CENSUS';
export const SET_LOADER_FLAG_CENSUS_ADD = 'SET_LOADER_FLAG_CENSUS_ADD';
export const SET_ALL_SESSIONS_INTO_CENSUS_SELECT =
	'SET_ALL_SESSIONS_INTO_CENSUS_SELECT';
//End Census Information

//ClassWiseSearch
export const SET_ALL_SESSIONS_INTO_CLASSWISE_SELECT =
	'SET_ALL_SESSIONS_INTO_CLASSWISE_SELECT';
export const SET_ALL_STUDENT_NAME_INTO_CLASSWISE_SELECT =
	'SET_ALL_STUDENT_NAME_INTO_CLASSWISE_SELECT';
export const GET_ALL_CLASSWISE_SEARCH = 'GET_ALL_CLASSWISE_SEARCH';
export const SET_LOADER_FLAG_CLASSWISE_ADD = 'SET_LOADER_FLAG_CLASSWISE_ADD';
//EndClassWiseSearch

//Age Wise Search
export const SET_ALL_SESSIONS_INTO_AGEWISE_SELECT =
	'SET_ALL_SESSIONS_INTO_AGEWISE_SELECT';
export const SET_ALL_STUDENT_NAME_INTO_AGEWISE_SELECT =
	'SET_ALL_STUDENT_NAME_INTO_AGEWISE_SELECT';
export const GET_ALL_AGEWISE_SEARCH = 'GET_ALL_AGEWISE_SEARCH';
export const SET_LOADER_FLAG_AGEWISE_ADD = 'SET_LOADER_FLAG_AGEWISE_ADD';

//End Age Wise Search

//Category Wise Search
export const SET_ALL_SESSIONS_INTO_CATEGORYWISE_SELECT =
	'SET_ALL_SESSIONS_INTO_CATEGORYWISE_SELECT';
export const GET_ALL_CATEGORYWISE_SEARCH = 'GET_ALL_CATEGORYWISE_SEARCH';
export const SET_LOADER_FLAG_CATEGORYWISE_ADD =
	'SET_LOADER_FLAG_CATEGORYWISE_ADD';
//End Category Wise Search

//User Wise Search
export const SET_ALL_SESSIONS_INTO_USERWISE_SELECT =
	'SET_ALL_SESSIONS_INTO_USERWISE_SELECT';
export const SET_ALL_STUDENT_NAME_INTO_USERWISE_SELECT =
	'SET_ALL_STUDENT_NAME_INTO_USERWISE_SELECT';
export const GET_ALL_USERWISE_SEARCH = 'GET_ALL_USERWISE_SEARCH';
export const SET_LOADER_FLAG_USERWISE_ADD = 'SET_LOADER_FLAG_USERWISE_ADD';
//User Wise Search

//Family Wise Search
export const GET_ALL_FAMILYWISE_SEARCH = 'GET_ALL_FAMILYWISE_SEARCH';
export const SET_LOADER_FLAG_FAMILYWISE_ADD = 'SET_LOADER_FLAG_FAMILYWISE_ADD';
//End Family Wise Search

//Student Result Status
export const SET_ALL_STUDENT_NAME_INTO_STUDENTRESULT_SELECT =
	'SET_ALL_STUDENT_NAME_INTO_STUDENTRESULT_SELECT';
export const GET_ALL_STUDENTRESULT_SEARCH = 'GET_ALL_STUDENTRESULT_SEARCH';
export const SET_LOADER_FLAG_STUDENTRESULT_ADD =
	'SET_LOADER_FLAG_STUDENTRESULT_ADD';
//End Student Result Status
//Staff Data
export const GET_ALL_STAFF_DATA = 'GET_ALL_STAFF_DATA';
export const ADD_APPRAISAL = 'ADD_APPRAISAL';
export const GET_ALL_APPRAISAL_DATA = 'GET_ALL_APPRAISAL_DATA';
export const DELETE_ITEM_IN_STAFF = 'DELETE_ITEM_IN_STAFF';
export const EDIT_ITEM_IN_STAFF = 'EDIT_ITEM_IN_STAFF';
export const GET_NOTIFICATION_DATA = 'GET_NOTIFICATION_DATA';
export const DELETE_ITEM_IN_NOTIFICATION = 'DELETE_ITEM_IN_NOTIFICATION';
//end Staff

// Time-table module

// export const GET_CLASS =
export const GET_TEACHER_AND_SUBJECT_FILTER = 'GET_TEACHER_AND_SUBJECT_FILTER';
//end of time-table module

//Staff Admission Form
export const GET_INDIVIDUAL_STAFF_DATA = 'GET_INDIVIDUAL_STAFF_DATA';

//End Staff Admission Form

//Student Enquiry
export const GET_ALL_STUDENT_ENQUIRY = 'GET_ALL_STUDENT_ENQUIRY';
export const SET_LOADER_FLAG_STUDENT_ENQUIRY_ADD =
	'SET_LOADER_FLAG_STUDENT_ENQUIRY_ADD';
export const DELETE_STUDENT_ENQUIRY = 'DELETE_STUDENT_ENQUIRY';
export const EDIT_STUDENT_ENQUIRY_RECORD = 'EDIT_STUDENT_ENQUIRY_RECORD';
export const ADD_STUDENT_ENQUIRY = 'ADD_STUDENT_ENQUIRY';
export const SET_ALL_CLASS_NAME_INTO_STUDENT_ENQUIRY_SELECT =
	'SET_ALL_CLASS_NAME_INTO_STUDENT_ENQUIRY_SELECT';
export const SET_ALL_CLASS_NAME_INTO_STUDENT_SEARCH_ENQUIRY_SELECT =
	'SET_ALL_CLASS_NAME_INTO_STUDENT_SEARCH_ENQUIRY_SELECT';
export const GET_INDIVIDUAL_STUDENT_ENQUIRY_DATA =
	'GET_INDIVIDUAL_STUDENT_ENQUIRY_DATA';
export const GET_ADMITTED_STUDENT_ENQUIRY_DATA =
	'GET_ADMITTED_STUDENT_ENQUIRY_DATA';
export const EDIT_STUDENT_ADMITTED_RECORD = 'EDIT_STUDENT_ADMITTED_RECORD';

//END Student Enquiry
//Staff Enquiry
export const ADD_STAFF_ENQUIRY = 'ADD_STAFF_ENQUIRY';
export const GET_ALL_STAFF_ENQUIRY = 'GET_ALL_STAFF_ENQUIRY';
export const GET_CLASSES_FOR_STAFF_ENQUIRY_SEARCH =
	'GET_CLASSES_FOR_STAFF_ENQUIRY_SEARCH';
export const SET_LOADER_FLAG_STAFF_ENQUIRY_ADD =
	'SET_LOADER_FLAG_STAFF_ENQUIRY_ADD';
export const SET_ALL_CLASS_NAME_INTO_STAFF_ENQUIRY_SELECT =
	'SET_ALL_CLASS_NAME_INTO_STAFF_ENQUIRY_SELECT';
export const SET_ALL_DEPARTMENT_NAME_INTO_STAFF_ENQUIRY_SELECT =
	'SET_ALL_DEPARTMENT_NAME_INTO_STAFF_ENQUIRY_SELECT';
export const GET_INDIVIDUAL_STAFF_ENQUIRY_DATA =
	'GET_INDIVIDUAL_STAFF_ENQUIRY_DATA';
export const GET_ADMITTED_STAFF_ENQUIRY_DATA =
	'GET_ADMITTED_STAFF_ENQUIRY_DATA';
export const EDIT_STAFF_ADMITTED_RECORD = 'EDIT_STAFF_ADMITTED_RECORD';
export const SET_LOADER_FLAG_STAFF_ADMITTED_ADD =
	'SET_LOADER_FLAG_STAFF_ADMITTED_ADD';
//End Staff Enquiry
//Satff ki Post
export const SET_ALL_DEPARTMENT_NAME_INTO_POST_SELECT =
	'SET_ALL_DEPARTMENT_NAME_INTO_POST_SELECT';
export const SET_ALL_POST_NAME_INTO_SEARCHSTAFFPOST_SELECT =
	'SET_ALL_POST_NAME_INTO_SEARCHSTAFFPOST_SELECT';
export const GET_ALL_STAFFSEARCH_BY_POST = 'GET_ALL_STAFFSEARCH_BY_POST';
export const GET_ALL_POST = 'GET_ALL_POST';
export const SET_LOADER_FLAG_POST = 'SET_LOADER_FLAG_POST';
export const DELETE_POST = 'DELETE_POST';
export const EDIT_POST_RECORD = 'EDIT_POST_RECORD';
export const ADD_POST = 'ADD_POST';
//End Satff ki Post

//Department
export const SET_ALL_CAMPUS_NAME_INTO_DEPARTMENT_SELECT =
	'SET_ALL_CAMPUS_NAME_INTO_DEPARTMENT_SELECT';
export const SET_ALL_DEPARTMENT_NAME_INTO_SEARCHSTAFFDEPARTMENT_SELECT =
	'SET_ALL_DEPARTMENT_NAME_INTO_SEARCHSTAFFDEPARTMENT_SELECT';
export const GET_ALL_DEPARTMENT = 'GET_ALL_DEPARTMENT';
export const GET_ALL_STAFFSEARCH_BY_DEPART = 'GET_ALL_STAFFSEARCH_BY_DEPART';
export const SET_LOADER_FLAG_DEPARTMENT = 'SET_LOADER_FLAG_DEPARTMENT';
export const DELETE_DEPARTMENT = 'DELETE_DEPARTMENT';
export const EDIT_DEPARTMENT_RECORD = 'EDIT_DEPARTMENT_RECORD';
export const ADD_DEPARTMENT = 'ADD_DEPARTMENT';
//End Department
// Hr Development and Training
export const ADD_HR_DEVELOPMENT = 'ADD_HR_DEVELOPMENT';
export const GET_ALL_HR_DEVELOPMENT = 'GET_ALL_HR_DEVELOPMENT';
export const GET_ALL_HR_DEVELOPMENT_For_Edit =
	'GET_ALL_HR_DEVELOPMENT_For_Edit';
export const SET_LOADER_FLAG_HR_DEVELOPMENT_ADD =
	'SET_LOADER_FLAG_HR_DEVELOPMENT_ADD';
export const SET_LOADER_FLAG_HR_DEVELOPMENT_EDIT =
	'SET_LOADER_FLAG_HR_DEVELOPMENT_EDIT';
export const DELETE_HR_DEVELOPMENT = 'DELETE_HR_DEVELOPMENT';
export const EDIT_HR_DEVELOPMENT_RECORD = 'EDIT_HR_DEVELOPMENT_RECORD';
// End Development and Training

//LessonPlan
export const SET_ALL_CLASS_NAME_INTO_PLAN_SELECT =
	'SET_ALL_CLASS_NAME_INTO_PLAN_SELECT';
export const SET_ALL_SESSION_NAME_INTO_PLAN_SELECT =
	'SET_ALL_SESSION_NAME_INTO_PLAN_SELECT';
export const GET_ALL_PLANS = 'GET_ALL_PLANS';
export const SET_LOADER_FLAG_PLANS = 'SET_LOADER_FLAG_PLANS';
export const DELETE_PLANS = 'DELETE_PLANS';
export const EDIT_PLANS_RECORD = 'EDIT_PLANS_RECORD';
export const ADD_PLANS = 'ADD_PLANS';
//End LessonPlan

//Assignment
export const SET_ALL_CLASS_NAME_INTO_ASSIGNMENT_SELECT =
	'SET_ALL_CLASS_NAME_INTO_ASSIGNMENT_SELECT';
export const SET_ALL_SESSION_NAME_INTO_ASSIGNMENT_SELECT =
	'SET_ALL_SESSION_NAME_INTO_ASSIGNMENT_SELECT';
export const GET_ALL_ASSIGNMENT = 'GET_ALL_ASSIGNMENT';
export const GET_PARTICULAR_ASSIGNMENT = 'GET_PARTICULAR_ASSIGNMENT';
export const SET_LOADER_FLAG_ASSIGNMETN = 'SET_LOADER_FLAG_ASSIGNMETN';
export const DELETE_ASSIGNMENT = 'DELETE_ASSIGNMENT';
export const EDIT_ASSIGNMETN_RECORD = 'EDIT_ASSIGNMETN_RECORD';
export const ADD_ASSIGNMENT = 'ADD_ASSIGNMENT';
//End Assignment
//Home Work
export const SET_ALL_CLASS_NAME_INTO_HOMEWORK_SELECT =
	'SET_ALL_CLASS_NAME_INTO_HOMEWORK_SELECT';
export const SET_ALL_SESSION_NAME_INTO_HOMEWORK_SELECT =
	'SET_ALL_SESSION_NAME_INTO_HOMEWORK_SELECT';
export const GET_ALL_HOMEWORK = 'GET_ALL_HOMEWORK';
export const SET_LOADER_FLAG_HOMEWORK = 'SET_LOADER_FLAG_HOMEWORK';
export const DELETE_HOMEWORK = 'DELETE_HOMEWORK';
export const EDIT_HOMEWORK_RECORD = 'EDIT_HOMEWORK_RECORD';
export const ADD_HOMEWORK = 'ADD_HOMEWORK';
//End Home Work

//Budget Items
export const SET_ALL_SESSION_NAME_INTO_BUDGETITEM_SELECT =
	'SET_ALL_SESSION_NAME_INTO_BUDGETITEM_SELECT';
export const GET_ALL_BUDGETITEMS = 'GET_ALL_BUDGETITEMS';
export const SEARCH_ALL_BUDGETITEMS = 'SEARCH_ALL_BUDGETITEMS';
export const SET_LOADER_FLAG_BUDGETITEMS = 'SET_LOADER_FLAG_BUDGETITEMS';
export const DELETE_BUDGETITEMS = 'DELETE_BUDGETITEMS';
export const EDIT_BUDGETITEMS_RECORD = 'EDIT_BUDGETITEMS_RECORD';
export const ADD_BUDGETITEMS = 'ADD_BUDGETITEMS';
//End Budget Items

//Quiz
export const SET_ALL_SESSION_NAME_INTO_QUIZ_SELECT =
	'SET_ALL_SESSION_NAME_INTO_QUIZ_SELECT';
export const SET_ALL_CLASS_GRADE_NAME_INTO_QUIZ_SELECT =
	'SET_ALL_CLASS_GRADE_NAME_INTO_QUIZ_SELECT';
export const GET_ALL_QUIZ = 'GET_ALL_QUIZ';
// export const SEARCH_ALL_BUDGETITEMS = 'SEARCH_ALL_BUDGETITEMS';
export const SET_LOADER_FLAG_QUIZ = 'SET_LOADER_FLAG_QUIZ';
export const DELETE_QUIZ = 'DELETE_QUIZ';
export const EDIT_QUIZ_RECORD = 'EDIT_QUIZ_RECORD';
export const ADD_QUIZ = 'ADD_QUIZ';
//End Quiz
//Quiz Info
export const SET_ALL_CLASS_NAME_INTO_QUIZ_INFO_SELECT =
	'SET_ALL_CLASS_NAME_INTO_QUIZ_INFO_SELECT';
export const SET_ALL_SESSION_NAME_INTO_QUIZ_INFO_SELECT =
	'SET_ALL_SESSION_NAME_INTO_QUIZ_INFO_SELECT';
export const GET_ALL_QUIZ_INFO = 'GET_ALL_QUIZ_INFO';
export const SET_LOADER_FLAG_QUIZ_INFO = 'SET_LOADER_FLAG_QUIZ_INFO';
export const DELETE_QUIZ_INFO = 'DELETE_QUIZ_INFO';
export const EDIT_QUIZ_INFO_RECORD = 'EDIT_QUIZ_INFO_RECORD';
export const ADD_QUIZ_INFO = 'ADD_QUIZ_INFO';
//End Quiz Info
//Test Info
export const SET_ALL_CLASS_NAME_INTO_TEST_INFO_SELECT =
	'SET_ALL_CLASS_NAME_INTO_TEST_INFO_SELECT';
export const SET_ALL_SESSION_NAME_INTO_TEST_INFO_SELECT =
	'SET_ALL_SESSION_NAME_INTO_TEST_INFO_SELECT';
export const GET_ALL_TEST_INFO = 'GET_ALL_TEST_INFO';
export const SET_LOADER_FLAG_TEST_INFO = 'SET_LOADER_FLAG_TEST_INFO';
export const DELETE_TEST_INFO = 'DELETE_TEST_INFO';
export const EDIT_TEST_INFO_RECORD = 'EDIT_TEST_INFO_RECORD';
export const ADD_TEST_INFO = 'ADD_TEST_INFO';
//End Test info
//Test
export const SET_ALL_CLASS_NAME_INTO_TEST_SELECT =
	'SET_ALL_CLASS_NAME_INTO_TEST_SELECT';
export const SET_ALL_SESSION_NAME_INTO_TEST_SELECT =
	'SET_ALL_SESSION_NAME_INTO_TEST_SELECT';
export const GET_ALL_TEST = 'GET_ALL_TEST';
export const SET_LOADER_FLAG_TEST = 'SET_LOADER_FLAG_TEST';
export const DELETE_TEST = 'DELETE_TEST';
export const EDIT_TEST_RECORD = 'EDIT_TEST_RECORD';
export const ADD_TEST = 'ADD_TEST';
//End Test

// Exam
export const GET_ALL_EXAM = 'GET_ALL_EXAM';
export const GET_SESSION_FOR_EXAM = 'GET_SESSION_FOR_EXAM';
export const GET_EXAM_FOR_EXAMDETAILS = 'GET_EXAM_FOR_EXAMDETAILS';
export const GET_CLASS_GRADE_SUBJECT_FOR_EXAM =
	'GET_CLASS_GRADE_SUBJECT_FOR_EXAM';
export const SET_LOADER_FLAG_EXAM = 'SET_LOADER_FLAG_EXAM';
export const DELETE_EXAM = 'DELETE_EXAM';
export const EDIT_EXAM_RECORD = 'EDIT_EXAM_RECORD';
export const ADD_EXAM = 'ADD_EXAM';
//End Exam

//Exam Info
export const SET_ALL_CLASS_NAME_INTO_EXAM_INFO_SELECT =
	'SET_ALL_CLASS_NAME_INTO_EXAM_INFO_SELECT';
export const SET_ALL_SESSION_NAME_INTO_EXAM_INFO_SELECT =
	'SET_ALL_SESSION_NAME_INTO_EXAM_INFO_SELECT';
export const GET_ALL_EXAM_INFO = 'GET_ALL_EXAM_INFO';
export const SET_LOADER_FLAG_EXAM_INFO = 'SET_LOADER_FLAG_EXAM_INFO';
export const DELETE_EXAM_INFO = 'DELETE_EXAM_INFO';
export const EDIT_EXAM_INFO_RECORD = 'EDIT_EXAM_INFO_RECORD';
export const ADD_EXAM_INFO = 'ADD_EXAM_INFO';
//End Exam info

//PastPapers
export const SET_ALL_CLASS_NAME_INTO_PASTPAPERS_SELECT =
	'SET_ALL_CLASS_NAME_INTO_PASTPAPERS_SELECT';
export const SET_ALL_YEAR_NAME_INTO_PASTPAPERS_SELECT =
	'SET_ALL_YEAR_NAME_INTO_PASTPAPERS_SELECT';
export const SET_ALL_SESSION_NAME_INTO_PASTPAPERS_SELECT =
	'SET_ALL_SESSION_NAME_INTO_PASTPAPERS_SELECT';
export const GET_ALL_PASTPAPERS = 'GET_ALL_PASTPAPERS';
export const SET_LOADER_FLAG_PASTPAPERS = 'SET_LOADER_FLAG_PASTPAPERS';
export const DELETE_PASTPAPERS = 'DELETE_PASTPAPERS';
export const ADD_PASTPAPERS = 'ADD_PASTPAPERS';
//End PastPapers
//Syallabus
export const SET_ALL_CLASS_NAME_INTO_SYLLABUS_SELECT =
	'SET_ALL_CLASS_NAME_INTO_SYLLABUS_SELECT';
export const SET_ALL_SESSION_NAME_INTO_SYLLABUS_SELECT =
	'SET_ALL_SESSION_NAME_INTO_SYLLABUS_SELECT';
export const GET_ALL_SYLLABUS = 'GET_ALL_SYLLABUS';
export const GET_ALL_SYLLABUS_PAGINATION = 'GET_ALL_SYLLABUS_PAGINATION';
export const SET_LOADER_FLAG_SYLLABUS = 'SET_LOADER_FLAG_SYLLABUS';
export const SET_LOADER_FLAG_SYLLABUS_ALL = 'SET_LOADER_FLAG_SYLLABUS_ALL';
export const DELETE_SYLLABUS = 'DELETE_SYLLABUS';
export const EDIT_SYLLABUS_RECORD = 'EDIT_SYLLABUS_RECORD';
export const ADD_SYLLABUS = 'ADD_SYLLABUS';
//End Syllabus

//Student Skills
export const SET_ALL_CLASS_NAME_INTO_STUDENT_SKILL_SELECT =
	'SET_ALL_CLASS_NAME_INTO_STUDENT_SKILL_SELECT';
export const GET_ALL_SKILLS = 'GET_ALL_SKILLS';
export const GET_ALL_SKILLS_FOR_VIEW = 'GET_ALL_SKILLS_FOR_VIEW';
export const GET_ALL_STUDENT_SKILLS_LIST = 'GET_ALL_STUDENT_SKILLS_LIST';
export const SET_LOADER_FLAG_SKILLS_LIST = 'SET_LOADER_FLAG_SKILLS_LIST';
export const SET_LOADER_FLAG_SKILLS = 'SET_LOADER_FLAG_SKILLS';
export const SET_LOADER_FLAG_SKILLS_VIEW = 'SET_LOADER_FLAG_SKILLS_VIEW';
export const SET_ALL_SESSION_NAME_INTO_SKILL_SELECT =
	'SET_ALL_SESSION_NAME_INTO_SKILL_SELECT';
export const ADD_SKILL = 'ADD_SKILL';
export const EDIT_SKILL_RECORD = 'EDIT_SKILL_RECORD';
//End Student Skills

//Descriptive Report
export const SET_ALL_CLASS_NAME_INTO_DESCRIPTIVE_SELECT =
	'SET_ALL_CLASS_NAME_INTO_DESCRIPTIVE_SELECT';
export const SET_ALL_SESSION_NAME_INTO_DESCRIPTIVE_SELECT =
	'SET_ALL_SESSION_NAME_INTO_DESCRIPTIVE_SELECT';
export const ADD_DESCRIPTIVE_REPORT = 'ADD_DESCRIPTIVE_REPORT';
export const GET_ALL_DESCRIPTIVE_FOR_VIEW = 'GET_ALL_DESCRIPTIVE_FOR_VIEW';
export const SET_LOADER_FLAG_Descriptive_VIEW =
	'SET_LOADER_FLAG_Descriptive_VIEW';
export const EDIT_DESCRIPTIVE_RECORD = 'EDIT_DESCRIPTIVE_RECORD';
//End Descriptive Report

//Student Class Discipline
export const ADD_CLASS_DISCIPLINE = 'ADD_CLASS_DISCIPLINE';
export const GET_ALL_STUDENT_IN_CLASS_DISCIPLINE =
	'GET_ALL_STUDENT_IN_CLASS_DISCIPLINE';
export const GET_LOADER_FLAG_ACTIVE = 'GET_LOADER_FLAG_ACTIVE';
//End Student Class Discipline
// Parent Details
export const GET_ALL_PARENT = 'GET_ALL_PARENT';
export const SET_LOADER_FLAG_PARENT = 'SET_LOADER_FLAG_PARENT';
export const DELETE_PARENT = 'DELETE_PARENT';
export const EDIT_PARENT_RECORD = 'EDIT_PARENT_RECORD';
export const ADD_PARENT_RECORD = 'ADD_PARENT_RECORD';
// End Parent Details
