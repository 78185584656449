import React from "react";
import Layout from "./Container/HOC/Layout";
import { Switch, Route } from "react-router-dom";
import MainDCom from "./components/MainDCom";
import Head from "./components/Head";
import LoginLayout from "./Container/Login_Component/LoginLayout";
import Main from "./components/Container/Main_Component/Main";
import subroutes from "./subroutes";
import requireAuth from "./middleware/requireAuthCommon";
import PageNotFound from "./components/PageNotFound.jsx";
import ThemeGlobalContext from "./context/themeContext/GlobalThemeContext";
import BulletenGlobalContext from "./context/BluttenContext/BulletenGlobalContext";
import InboxGlobalContext from './context/InboxContext/InboxGlobalContext';
import ParentTeacherInboxGlobalContext from './context/ParentTeacherInboxContext/ParentTeacherInboxGlobalContext';
const Routes = (props) => {
  return (
    // <Layout>
    <ParentTeacherInboxGlobalContext>
		<InboxGlobalContext>
    <BulletenGlobalContext>
      <ThemeGlobalContext>
        <Switch>
          <Route exact component={requireAuth(Main)} path="/" />

          <Route
            path="/Login/:select"
            component={requireAuth((props) => (
              <LoginLayout {...props} />
            ))}
          />
          <Route component={() => <div>Admin List</div>} path="/AdminList" />
          <Route component={subroutes} path="/dashboard" />
          <Route component={PageNotFound} />
        </Switch>
      </ThemeGlobalContext>
    </BulletenGlobalContext>
		</InboxGlobalContext>
    </ParentTeacherInboxGlobalContext>
    // </Layout>
  );
};
export default Routes;
