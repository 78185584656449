import React, { Component } from 'react';
import DatePicker from 'react-date-picker';
import DateandTime from '../Transport/DateandTime';
import validator from '../../validator/registrationValidator';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import FormLayoutWrapper from '../../components/HOC/FormLayoutWrapper';
import ThemeContext from "../../context/themeContext/ThemeContext";

class AddSessionRenew extends Component {
	state = {
		dateStart: '',
		dateEnd: '',
		errors: {},
		isLoading: false,
		msg: '',
		errMsg: false
	};

	onChangeStart = date => {
		this.setState({ dateStart: date });
		console.log(date);
	};

	onChangeEnd = date => {
		this.setState({ dateEnd: date });
	};

	formatDate = date => {
		var d = new Date(date),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear();

		if (month.length < 2) month = '0' + month;
		if (day.length < 2) day = '0' + day;

		return [month, day, year].join('/');
	};

	// Validating date try one

	/*     isValid = () => {
            const { errors, isValid } = validator(this.state, 'checkAddClassForm');
    
            if (!isValid) {
                this.setState({
                    errors
                })
            }
        } */

	componentDidUpdate() {
		if (this.state.msg || this.state.errMsg) {
			setTimeout(() => {
				this.setState({
					msg: false,
					errMsg: false
				});
			}, 3000);
		}
	}

	onHandleSubmit = e => {
		e.preventDefault();

		const { dateStart, dateEnd } = this.state;

		if (Object.is(dateStart, '') || Object.is(dateEnd, '')) {
			this.setState({
				error: true,
				errMsg: true
			});
		} else {
			this.setState({
				isLoading: true
			});

			let dStart = this.formatDate(dateStart).toString();
			let dEnd = this.formatDate(dateEnd).toString();

			console.log(dEnd.toString());

			let data = {
				startSession: dStart,
				endSession: dEnd,
				accountId: this.props.username,
				accountType: this.props.role
			};

			this.props.shareAction
				.simpleAddRequest('/api/Session', data)
				.then(success => {
					this.setState({
						dateStart: '',
						dateEnd: '',
						isLoading: false,
						errors: {},
						msg: 'Successfully Submit!',
						errMsg: false
					});
					this.props.snackbar();
				});
			console.log(this.state.dateStart);
			console.log(this.state.dateEnd);
		}
	};

	myFunction = () => {
		// Get the snackbar DIV
		var x = document.getElementById('snackbar');

		// StepperGeneralButtons the "show" class to DIV
		x.className = 'show';

		// After 3 seconds, remove the show class from DIV
		setTimeout(function() {
			x.className = x.className.replace('show', '');
		}, 3000);
	};

	render() {
		const { dateStart, dateEnd } = this.state;

		const Form = (
			<ThemeContext.Consumer>
			{(context) => (
			<FormLayoutWrapper
				formName="Add Session"
				borderColor={context.themeColors.primaryColor}
				borderRadius={15}
			>
				{this.state.errMsg ? (
					<p style={{ color: 'red', textAlign: 'center' }}>
						Please fill the form properly
					</p>
				) : (
					''
				)}
				<div className="row">
					<div class="col-md-6">
						<div class="md-form mb-0">
							<label for="name" class="">
								{/* <i
                                    className="fas fa-user-shield animated fadeIn"
                                    style={{ marginRight: 4 }}
                                /> */}
								Admin Name
							</label>
							<input
								disabled={true}
								value={this.props.username}
								type="text"
								readOnly={true}
								name="AdminName"
								class="form-control"
							/>
						</div>
					</div>
					<div class="col-md-6">
						<div class="md-form mb-0">
							<label for="name" class="">
								Admin Type
							</label>
							<input
								disabled={true}
								readOnly={true}
								value={this.props.role}
								type="text"
								name="AdminType"
								class="form-control"
							/>
						</div>
					</div>
					<br />
					<br />
				</div>
				<br />
				<br />

				<div
					class="row"
					style={{
						display: 'flex',
						justifyContent: 'center',
						textAlign: 'center'
					}}
				>
					<div class="col-md-6">
						<label>Start Session</label>
						<br />
						<DatePicker
							style={{ borderTop: 0, borderLeft: 0, borderRight: 0 }}
							name="dateStart"
							id="dateStart"
							onChange={this.onChangeStart}
							value={this.state.dateStart}
						/>
					</div>
					<div class="col-md-6">
						<label>End Session</label>
						<br />
						<DatePicker
							style={{ borderTop: 0, borderLeft: 0, borderRight: 0 }}
							name="dateEnd"
							id="dateEnd"
							onChange={this.onChangeEnd}
							value={this.state.dateEnd}
						/>
					</div>
				</div>
				<br />
				<div style={{ width: '50%', margin: '0 auto', marginTop: 20 }}>
					<button
						style={{ width: '80%', background: context.themeColors.primaryColor }}
						onClick={this.onHandleSubmit}
						className="btns"
					>
						Add
						<span
							style={{ marginBottom: 5 }}
							class={`${
								this.state.isLoading ? 'spinner-border spinner-border-sm' : ''
							}`}
						></span>
					</button>
				</div>
			</FormLayoutWrapper>
			)}
			</ThemeContext.Consumer>
		);
		return (
			<div class="container">
				{Form}
				<SnackBar msg={this.state.msg} />
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		shareAction: bindActionCreators(sharedActions, dispatch)
	};
}
function mapStateToProps(state) {
	return {
		role: state.setUserReducer.users.role,
		username: state.setUserReducer.users.unique_name
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AddSessionRenew);
