import React from "react";
import ViewRendering from "../components/ViewRendering";
import { renderingStuff, images } from "../utils";
import NewViewRendering from "../components/ViewRendering/NewViewRendering";
import { useContext, useEffect, useState } from "react";
import ThemeContext from "../context/themeContext/ThemeContext";
import * as sharedActions from "../../src/Actions/sharedActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import $ from "jquery";
import { Link } from "react-router-dom";

function CamousMaintenanceDashboard(props) {
  const themeColor = useContext(ThemeContext);
  const [cleanData, setcleanData] = useState([]);
  const [showCleanData, setshowCleanData] = useState(false);

  useEffect(() => {
    props.sharedActions
      .getDataWithoutDispatch("/api/CyclicCleaness")
      .then((res) => {
        setcleanData(res);
        $("#successModal").modal("show");
        debugger;
      })
      .catch((err) => {
        debugger;
      });
  }, []);
  return (
    <div>
      <div
        class="modal fade"
        id="successModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Cyclic Cleanliness
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body text-center">
              <div style={{ padding: "3vh 0" }}>
                <i className="fas fa-broom fa-3x text-primary"></i>
              </div>
              <h5>You have {cleanData.length} Notifications</h5>
                <button
                  className="btn btn-success mb-2"
                  onClick={() => setshowCleanData(true)}
                >
                  See Now
                </button>

              {showCleanData ? (
                <table class="table table-sm">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Floor Name</th>
                      <th scope="col">Room Name</th>
                      <th scope="col">Category Name</th>
                      <th scope="col">Type</th>
                      <th scope="col">Date</th>
                      <th scope="col">New Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cleanData.map((item, index) => {
                      return <tr key={index}>
                        <th scope="row">{index + 1}</th>
                        <td>{item.floorName}</td>
                        <td>{item.roomName}</td>
                        <td>{item.categoryName}</td>
                        <td>{item.type}</td>
                        <td>{item.date}</td>
                        <td>{item.NewDate}</td>
                      </tr>
                    })}
                  </tbody>
                </table>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div class="row justify-content-center">
            <div class="col-md-12 text-center">
              <img
                src={images.imgDashboardCampusMaintenance}
                class="img-fluid"
                alt="Responsive image"
                height="50"
                width="50"
              />
              <h4 className="mt-2" style={{ letterSpacing: 10 }}>
                Campus Maintenance Dashboard
              </h4>
            </div>
          </div>
        </div>
      </div>
      <NewViewRendering
        {...props}
        data={renderingStuff.Campus_Maintenance_Dashboard_Links}
      />
    </div>
  );
}
function mapDispatchToProps(dispatch) {
  return {
    sharedActions: bindActionCreators(sharedActions, dispatch),
  };
}
// function mapStateToProps(state) {
//   return {
//     AllAdminData: state.setCampusReducer.Data,
//     allAdminState: state.setCampusReducer,
//     users: state.setUserReducer.users,
//   };
// }
export default connect(null, mapDispatchToProps)(CamousMaintenanceDashboard);
