class Document_File_Constant {
	// Staff Docs
	static experienceLetter = 'experienceLetter';
	static iletsCertificate = 'iletsCertificate';
	static ministryApproval = 'ministryApproval';
	static lastQualification = 'lastQualification';
	static passportCopy = 'passportCopy';
	static idCardCopys = 'idCardCopy';

	// Student Docs

	static dobCertificate = 'dobCertificate';
	static transferCertificate = 'transferCertificate';
	static ministryVaccination = 'ministryVaccination';
	static passportCopy = 'passportCopy';
	static idCardCopy = 'idCardCopy';
	static medicalHistory = 'medicalHistory';
	static lastResult = 'lastResult';
	static testResult = 'testResult';
	static fileNameDobCertificate = 'fileNameDobCertificate';
	static fileNameTransferCertifcate = 'fileNameTransferCertifcate';
	static fileNameMinistryVaccination = 'fileNameMinistryVaccination';
	static fileNamePassportCopy = 'fileNamePassportCopy';
	static fileNameIdCard = 'fileNameIdCard';
	static fileNameMedicalHistory = 'fileNameMedicalHistory';
	static fileNameLastResult = 'fileNameLastResult';
	static fileNameTestResult = 'fileNameTestResult';

	// Parent Docs
	static idCardFather = 'idCardFather';
	static passportFather = 'passportFather';
	static idCardMother = 'idCardMother';
	static passportMother = 'passportMother';
	static fileNameIdCardFather = 'fileNameIdCardFather';
	static fileNamePassportFather = 'fileNamePassportFather';
	static fileNameIdCardMother = 'fileNameIdCardMother';
	static fileNamePassportMother = 'fileNamePassportMother';
}

export default Document_File_Constant;
