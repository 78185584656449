
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import $ from 'jquery';
import isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom'
import isNull from 'lodash/isNull'
import moment from 'moment'
import Pagination from '../../components/Pagination/Pagination'

import ThemeContext from "../../context/themeContext/ThemeContext";

class ViewLessonPlanStaff extends Component {
    constructor(props){
        super(props);
        this.state = {
            isLoading: true,
            modalEdit: false,
            editToggle: false,
            generateToggle: false,
            generateIndex: '',
            msg: '',
            editIndex: '',
            classId: "",
            sectionId: "",
            subjectId: "",
            pageNumber: [],
            dataLimit: 10,
            currentPage: 1,
            termList: [{id: '1st', name: '1st Term'},{id: '2nd', name: '2nd Term'},{id: '3rd', name: '3rd Term'}],
			planTypeList: ['Weekly', 'Monthly', 'Termly', 'Yearly'],
            staffData: null,

			accountId: this.props.userData.unique_name,
			accountType: this.props.userData.role

        }

    }

	static contextType = ThemeContext


    componentDidMount(){
		this.getSimple('Session', 'session')
		this.getSimple('classSectionGradeTeacher', 'staffData')
			
			

    }
    handleSearch = () => {
        const {  classId, sectionId, subjectId, sessionId, term, planType, staffData } = this.state
        
        if(isNull(this.state.classId) || isNull(this.state.sectionId) ||  isNull(subjectId) || isNull(sessionId) || isNull(term)){
            console.log('Empty');
            
            this.setState({
                msg: "Please Fill The Form Repectively!",
            })            
            this.props.snackbar()
        }else{
            this.getPaginationSimple(`ExamInfo/?classId=${this.state.classId}&sectionId=${this.state.sectionId}&sessionId=${this.state.sessionId}&term=${this.state.term}&pageSize=${this.state.dataLimit}&pageNumber=${this.state.currentPage}`, 'examInfoData')
            
        }
    }

    
    getSimple = (type, state) => {
        this.props.sharedActions.getDataWithoutDispatch(
            `/api/${type}`
        ).then(success => { 
            this.setState({
                [state]: success,
                isLoading: false
            })


        }).catch(error => {
            this.setState({
                msg: 'Error Occured!!',
                errorMsg: true,
                isLoading: false
            })
            console.error('Error occured', error);
        })
    }

    getPaginationSimple = (type, state) => {
        this.props.sharedActions.getDataWithoutDispatch(
            `/api/${type}`
        ).then(success => { 
            this.setState({
                [state]: success,
                isLoading: false
            })
            this.setState({
                currentPage: success.paginationMetadata.currentPage,
                disableBPage: success.paginationMetadata.previousPage == 'No' ? true : false,
                disablepage: success.paginationMetadata.nextPage == 'No' ? true : false,
                pageNumber: Array.from(new Array(success.paginationMetadata.totalPages), (val, index) => index + 1)
            })



        }).catch(error => {
            this.setState({
                msg: error.response ? error.response.data.Message : 'Error Occured!!',
                isLoading: false
            })
            this.props.snackbar();
            console.error('Error occured', error);
        })
    }

    
	handleClickNextFrwd = () => {
		const {  currentPage, pageNumber } = this.state;
		console.log('current page: ', currentPage, pageNumber);
        if (currentPage === pageNumber.length) {
			this.setState({
				disablepage: true
            }, () => {
                this.handleSearch()

            });

            
		} else {
			this.setState({
				currentPage: currentPage + 1,
				disableBPage: false
            }, () => {
                this.handleSearch()

            });
            
            
		}
    };
    
	handleClickNextBack = () => {
		const { currentPage } = this.state;
		console.log('current page: ', currentPage);
		if (currentPage === 1) {
			this.setState({
				disableBPage: true,
				disablepage: false
            }, () => {
                this.handleSearch()

            });
		} else {
			this.setState({
                currentPage: currentPage - 1,
				disablepage: false
            }, () => {
                this.handleSearch()

            });
		}
    };
    
	handleClickNext = event => {
        const { currentPage, pageNumber } = this.state
                this.setState({
                    currentPage: Number(event.target.id),
                    disablepage: false,
                    disableBPage: false
                }, () => {
                    this.handleSearch()
    
                });
		
	};

    getDoubleState = (type, state) => {
        this.props.sharedActions.getDataWithoutDispatch(
            `/api/${type}`
        ).then(success => { 
            this.setState({
                [state]: success,
                contData: success,
                isLoading: false
            })


        }).catch(error => {
            this.setState({
                msg: 'Error Occured!!',
                errorMsg: true,
                isLoading: false
            })
            console.error('Error occured', error);
        })
    }


    
    onHandleFileChange = e => {
        console.log( e.target.name, e.target.files);
        this.setState({
            [e.target.name]: e.target.files[0]
        })
    }

    PrintContent = el => {
        var restorepage = document.body.innerHTML;
        var Printcontent = document.getElementById(el).innerHTML;
        document.body.innerHTML = Printcontent;
        
        setTimeout(() => {
            window.print();
            window.location.reload();
        }, 700)
        document.body.innerHTML = restorepage;
        window.reload();
        
    };



    myFunction = () => {
		// Get the snackbar DIV
		var x = document.getElementById('snackbar');

		// StepperGeneralButtons the "show" class to DIV
		x.className = 'show';

		// After 3 seconds, remove the show class from DIV
		setTimeout(function() {
		}, 3000);
	};



    btnTechHover = () => {
		$(document).ready(function() {
			$('button').hover(
				function() {
					$(this).css('box-shadow', '0px 5px 5px grey');
					$(this).css('border-color', 'white');
				},
				function() {
					$(this).css('box-shadow', '0px 0px 0px white');
				}
			);
		});
	};

    onHandleText = e => {
		this.setState({
			[e.target.name]: e.target.value
        });
        console.log([e.target.name], e.target.value)
    };

    onHandleFilter = e => {
        this.setState({
			[e.target.name]: e.target.value
        })
        console.log([e.target.name], e.target.value)
    };

    onDeleteClick = id => {
        const { examInfoData } = this.state
        let modalData = examInfoData.examInfos.filter((items, index ) =>  {
            if(id === items.planId){
                    
            this.setState({
                editIndex: index
            })
            console.log(index)
            return id === items.planId 
            }
        })
        console.log(id)
        this.setState({
             modalDataDeleteId: id
            
        }, () => {
            $('#myModal2').modal({
                show: true
            })
        })
    }

    handleDelete = id => {
        
        const { editIndex } = this.state;

        this.props.sharedActions.deleteRecordWithoutDispatch(
            '/api/LessonPlan',
            id
        ).then(success => {
            this.state.examInfoData.examInfos.splice(editIndex, 1)
            
                this.setState({
                    msg: 'Record Deleted!',
                })
                this.props.snackbar();

            
        }).catch(error => {
            
            this.setState({
                msg: error.response ? error.response.data.Message : 'Error Occured!!',
                isLoading: false
            })
            this.props.snackbar();

        })
    }


    render(){

        const { search, isLoading, editToggle, msg, examInfoData,
                currentPage, pageNumber, disableBPage, disablepage, 
                staffData, session, termList, planTypeList,
                
            id,
            classId,
            className,
            date,
            gradeName,
            gradeId,
            fileName,
            fileLength,
            filePath,
            homeworkName,
            section,
            sectionId,
            sessionId,
            sessionName,
            staffId,
            staffName,
            subjectId,
            subjectName,
            submissionDate,
            term,
            planType,
            time,
            topicDescription,
            accountId,
            accountType,
            file
        } = this.state;


        const Loader = (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div className="spinner-border" style={{ color: this.context.themeColors.primaryColor }}></div>
            </div>
        );
        
        const renderExamInfoData = examInfoData && examInfoData.examInfos.map((item, index) => {
            return (
                <tr key={index} >
                    <th scope="row" style={{ padding: 1 }} >
                        {index + 1}
                    </th>
					<td className="borderSpace" style={{ padding: 1 }}>
                        {item.StaffName}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.className}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.section}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.gradeName}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.subjectName}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.sessionName}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.term}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.examDate}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.examType}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.examTime}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.duration}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.totalMarks}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.date}
                    </td>
                </tr>
                
                );
            
        }) 

        return (
            <div>
				<section  className="mb-4">
				<h2  
                        style={{ background: this.context.themeColors.primaryColor }}
                        className="h1-responsive font-weight-bold text-center  generalHead">
                        View Exam Info
                    </h2>
			    <div>
                <SnackBar msg={msg} />
                <div style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                }}>
                    <div>
                            <div class="row my-3 mx-5 py-4" style={{
                            border: `2px solid ${this.context.themeColors.primaryColor}`,
                            borderRadius: 20
                        }} >
                                <div class="col-md-4" >
                                    
                                <select class="custom-select" 
                                value={classId}
                                name="classId"
                                onChange={this.onHandleText}
                                disabled={!staffData}
                            >
                                        <option value="">Please Select Class</option>
                                       {staffData && staffData.classes.map((value, index) => {
                                           return <option value={value.classId}>{value.className}</option>
                                       })}
                            </select>
                            </div>
                             <div class="col-md-4" >
                             <select class="custom-select" 
                                value={sectionId}
                                name="sectionId"
                                onChange={this.onHandleText}
                                disabled={!classId}

                            >
                                        <option value="">Please Select Section</option>
                                       {staffData && staffData.sections.map((value, index) => {


                                                return value.classId == classId && 
                                                <option value={value.sectionId}>{value.section}</option>

                                       })}
                            </select>
                            </div>
                                       
                            <div class="col-md-4" >
                            <select class="custom-select" 
                                                value={sessionId}
                                                name="sessionId"
                                                onChange={this.onHandleText}
                                                disabled={!sectionId || !session}

                                            >
                                                        <option value="">Please Select Session</option>
                                                       {session && session.session.map((value, index) => {

                                                                return <option value={value.sessionId}>{value.currentSession}</option>

                                                       })}
                                            </select>
                            </div>
                              <div class="col-md-4" >
                             <select class="custom-select" 
                                value={term}
                                style={{marginTop: '3%'}}
                                name="term"
                                onChange={this.onHandleText}
                                disabled={!sessionId}

                            >
                                        <option value="">Please Select Term</option>
                                       {termList.map((value, index) => {

                                                return <option value={value.id}>{value.name}</option>

                                       })}
                            </select>
                            </div>

                        </div>
                        <div class="row my-4">
                        <div className="col-md-12 text-center">

                                            <button
                                            class="btn btn-primary buttonAppear"
                                            onClick={this.handleSearch}
                                            style={{marginRight: '2%'}}
                                            >
                            <i class="fa fa-search"></i> Search
                                        </button>
                                        <Link to={{
                            pathname: "PrintExamInfo",
                            state: {
                                examInfoData,
                                date: moment().format('MM/DD/YYYY'),
                                currentSession: examInfoData && session.session.filter(d => d.sessionId == sessionId)[0].currentSession,
                                term,
                                className: examInfoData && staffData.classes.filter(d => d.classId == classId)[0].className,
                                section: examInfoData && staffData.sections.filter(d => d.sectionId == sectionId)[0].section,

                            }
                        }}  >
                            <a href="#" data-toggle="tooltip" title="Edit!">
                                
                            <button
                                            class="btn btn-primary buttonAppear"
                                            // disabled={!studentAttendance}
                                            >
                            
                                    <i className="fas fa-list"></i> View
                                </button>
                            </a> 
                            
                        </Link>
                            </div>
                            
                        </div>
                        <div class="row">
                                <div class="col-md-12 text-right">
                                <Pagination
                                    disableBPage={disableBPage}
                                    disablepage={disablepage}
                                    currentPage={currentPage}
                                    pageNumber={pageNumber}
                                    handleClickNext={this.handleClickNext}
                                    handleClickNextBack={this.handleClickNextBack}
                                    handleClickNextFrwd={this.handleClickNextFrwd}
                                />
                                </div>
                            </div>
                    <div className="table-responsive">
					<table className="table table-hover text-center">
						<thead style={{ background: this.context.themeColors.primaryColor, color: 'white' }}>
							<tr>
								<th scope="col" style={{ padding: 1 }}>
									#
								</th>
								<th scope="col" style={{ padding: 1 }}>
                                    Staff Name
								</th>
								<th scope="col" style={{ padding: 1 }}>
                                    Class
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Section
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Grade
								</th>
								<th scope="col" style={{ padding: 1 }}>
                                    Subject
								</th>
                                <th scope="col" style={{ padding: 1 }}>
									Session
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Term
								</th>
                                <th scope="col" style={{ padding: 1 }}>
									Exam Date
								</th>
                                <th scope="col" style={{ padding: 1 }}>
									Exam Type
								</th>
                                <th scope="col" style={{ padding: 1 }}>
									Exam Time
								</th>
                                <th scope="col" style={{ padding: 1 }}>
									Duration
								</th>
                                <th scope="col" style={{ padding: 1 }}>
									Total Marks
								</th>
                                <th scope="col" style={{ padding: 1 }}>
									Posted Date
								</th>
							</tr>
						</thead>
						<tbody>{isLoading ? Loader : renderExamInfoData}</tbody>
					</table>
				</div>
                </div>
            </div>
            </div>
            </section>
            </div>
        );
    }

}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch),
	};
}
function mapStateToProps(state) {
	return {
		userData: state.setUserReducer.users,
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ViewLessonPlanStaff);
