import React from 'react';

export function CheckBox(props) {
	console.log(props);
	const {
		handleCheckChildElement,
		studentId,
		studentName,
		isChecked,
		onHandleChange,
		rowIndex
	} = props;
	return (
		<React.Fragment>
			<input
				key={studentId}
				onClick={e => handleCheckChildElement(e)}
				type="checkbox"
				checked={isChecked}
				value={studentId}
			/>{' '}
			&nbsp;
			{isChecked == true ? (
				<React.Fragment>
					<label>Present</label>
					<div class="col-md-4" style={{ float: 'right' }}>
						<div class="md-form mb-0">
							<select
								class="custom-select"
								name="lateTime"
								onChange={e =>
									onHandleChange(e, rowIndex, 'SubjectWiseAttendance')
								}
							>
								<option selected={true}>Late</option>
								<option value="yes">Yes</option>
								<option value="no">No</option>
							</select>
						</div>
					</div>
				</React.Fragment>
			) : (
				<React.Fragment>
					<label class="text-danger">Absent</label>
					<div class="col-md-4" style={{ float: 'right' }}>
						<div class="md-form mb-0">
							<select
								class="custom-select"
								name="leaveType"
								onChange={e =>
									onHandleChange(e, rowIndex, 'SubjectWiseAttendance')
								}
							>
								<option selected={true}>Select Leave..</option>
								<option value="Excused">Excused</option>
								<option value="Non-Excused">Non-Excused</option>
							</select>
						</div>
					</div>
				</React.Fragment>
			)}
		</React.Fragment>
	);
}

export default CheckBox;
