import React, { Component } from 'react';
export default function PeriodBox({
	type,
	name,
	index,
	periodDuration,
	startDate,
	endDate,
	dispTime
}) {
	return (
		<React.Fragment>
			<th
				style={{
					textAlign: 'center',
					background: `${type === 'break' ? '#6C757D' : ''}`,
					color: `${type === 'break' ? '#fff' : ''}`
				}}
				scope="col"
			>
				{type.charAt(0).toUpperCase() + type.substring(1)} <br />
				<span
					style={{ background: '#0A4F5E' }}
					class="badgep badge-pill badge-info"
				>
					{name}
				</span>
				<br />
				<p
					style={{ color: `${type === 'break' ? '#fff' : ''}` }}
					className="disTime"
				>
					{dispTime}
				</p>
			</th>
		</React.Fragment>
	);
}
