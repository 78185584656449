import React from "react";
import ViewRendering from "../components/ViewRendering";
import { renderingStuff, images } from "../utils";
import NewViewRendering from "../components/ViewRendering/NewViewRendering";

export default function FamilyTreeDashboard(props) {
  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div class="row justify-content-center">
            <div class="col-md-12 text-center">
            <img
                src={images.imgFamilyTree}
                class="img-fluid"
                alt="Responsive image"
                height="50"
                width="50"
              />

     <h4 className="mt-2" style={{ letterSpacing: 10 }}>Family Tree</h4>
     </div>
          </div>
        </div>
      </div>
      <NewViewRendering
        {...props}
        data={renderingStuff.FamilyTreeLink}
      />
    </div>
  );
}