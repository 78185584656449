import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as SyllabusAction from '../../Actions/Syllabus';
import * as sharedActions from '../../Actions/sharedActions';
import DateandTime from '../Transport/DateandTime';
import SnackBar from '../../components/SnackBar/SnackBar';
import $ from 'jquery';
import moment from 'moment'

import LoaderWrapper from "../../components/HOC/LoaderWrapper";
import ThemeContext from "../../context/themeContext/ThemeContext";


class AddSyllabusStaff extends Component {
	constructor(props) {
		super(props);
		this.state = {
			staffId: '',
			subjectId: '',
			sectionId: '',
			gradeId: '',
			sessionId: '',
			classId: '',
			homeworkName: '',
			submissionDate: '',
			topicDescription: '',
			term: '',
			totalMarks: '',
			chooseFile: '',
			select: [],
			sessions: [],
			Subject: [],
			Exam: [],
			Staff: [],
			Section: [],
			termList: [{id: '1st', name: '1st Term'},{id: '2nd', name: '2nd Term'},{id: '3rd', name: '3rd Term'}],
			staffData: null,
			msg: false,
			errorMsg: false,
			isLoading: false,
			chooseFile: ''
		};
	}
	onHandleTextChange = e => {
		console.log(e.target.value);
		this.setState({
			[e.target.name]: e.target.value
		});
	};
    
    getSimple = (type, state) => {
        this.props.sharedActions.getDataWithoutDispatch(
            `/api/${type}`
        ).then(success => { 
            this.setState({
                [state]: success,
                isLoading: false
            })


        }).catch(error => {
            this.setState({
                msg: 'Error Occured!!',
                errorMsg: true,
                isLoading: false
            })
            console.error('Error occured', error);
        })
	}
	
	
    myFunction = () => {
		// Get the snackbar DIV
		var x = document.getElementById('snackbar');

		// StepperGeneralButtons the "show" class to DIV
		x.className = 'show';

		// After 3 seconds, remove the show class from DIV
		setTimeout(function() {
		}, 3000);
	};

	componentDidMount() {

        this.getSimple(`DescriptiveReport?userName=${this.props.userData.unique_name}`, 'staffData')
			this.getSimple('Session', 'session')

		$('#chooseFile').bind('change', function() {
			var filename = $('#chooseFile').val();
			if (/^\s*$/.test(filename)) {
				$('.file-upload').removeClass('active');
				$('#noFile').text('No file chosen...');
			} else {
				$('.file-upload').addClass('active');
				$('#noFile').text(filename.replace('C:\\fakepath\\', ''));
			}
		});
	}
	handleChange = (date, state) => {
		this.setState({
			[state]: date
		});
		console.log(date);
	};
	handleSubmit = e => {
		e.preventDefault();
		const {
			staffId,
			classId,
			sectionId,
			subjectId,
			sessionId,
			gradeId,
			term,
			chooseFile,
			totalMarks
		} = this.state;
		if (
			Object.is(staffId, '') ||
			Object.is(classId, '') ||
			Object.is(sectionId, '') ||
			Object.is(subjectId, '') ||
			Object.is(sessionId, '') ||
			Object.is(term, '') ||
			Object.is(chooseFile, '') 
		) {
			this.setState({
				msg: 'Please fill the form Respectivly!!',
			});
			this.props.snackbar()
		} else {
			let file = this.state.chooseFile;
			let accountType = this.state.accountType;
			let accountId = this.state.accountId;
			accountId = this.props.userData.unique_name;
			accountType = this.props.userData.role;

			let data = new FormData();
			data.append('fileName', file);
			data.append('sectionId', sectionId);
			data.append('staffId', staffId);
			data.append('subjectId', subjectId);
			data.append('sessionId', sessionId);
			data.append('classId', classId);
			data.append('termType', term);
			data.append('accountId', accountId);
			data.append('accountType', accountType);
			console.log('DATA Part', data);
			for (var pair of data.entries()) {
                console.log(pair[0]+ ', ' + pair[1]); 
            }
			this.setState({
				isLoading: true
			});
			this.props.sharedActions
				.addRecordWithoutDispatch(
					'/api/Syllabus',
					data,
				)
				.then(success => {
					this.setState({
						errorMsg: false,
						staffId: '',
						classId: '',
						sectionId: '',
						subjectId: '',
						sessionId: '',
						gradeId: '',
						term: '',
						msg: "Record Added Successfully!!",
						isLoading: false
					});
				this.props.snackbar()

					this.props.SyllabusAction.updateSyllabusFlag(true);
				})
				.catch(error => {
					this.setState({
						msg: error.response ?  error.response.data.Message : 'Error Occured!!',
						isLoading: false
					})
				this.props.snackbar()
				});
		}
	};
	inputData = e => {
		console.log(e.target.value);
		this.setState({
			[e.target.name]: e.target.value
		});
	};
	
	onSelectClassChange = e => {
		const { staffData } = this.state;
		let d = staffData.staffClassSection.filter((item, index) => {
			return e.target.value == item.classId
		})
		console.log(d);
		
		this.setState({
			classId: e.target.value,
			gradeId: d.length > 0 && d[0].gradeId,
			gradeName: d.length > 0 && d[0].gradeName,
		})
	};
	onSelectSubjectChange = e => {
		console.log(e.target.value);

		this.setState({
			subjectId: e.target.value
		});
	};
	onSelectSessionChange = e => {
		console.log(e.target.value);

		this.setState({
			sessionId: e.target.value
		});
	};
	onSectionChange = e => {
		const { classId, staffData  } = this.state
		console.log(e.target.value);
		let d = staffData.staffClassSection.filter((item, index) => {
			return classId == item.classId && e.target.value == item.sectionId
		})
		console.log(d);
		
		this.setState({
			sectionId: e.target.value,
			staffId: d.length > 0 && d[0].staffId,
		});
	};
	onStaffChange = e => {
		console.log(e.target.value);

		this.setState({
			staffId: e.target.value
		});
	};
	onChangeFile = e => {
		this.setState({
			chooseFile: e.target.files[0]
		});
		console.log(this.state.chooseFile);
	};
	render() {
		const { session, staffData, classId, sectionId, staffId, subjectId, termList, term, msg } = this.state
		return (
			<div className="container">
				<section class="mb-4">
				<ThemeContext.Consumer>
                    {(context) => (
                    <h2  
                        style={{ background: context.themeColors.primaryColor }}
                        className="h1-responsive font-weight-bold text-center my-4 generalHead">
                        Add Syllabus
                    </h2>
                     )}
                </ThemeContext.Consumer>
				<SnackBar msg={this.state.msg} backColor={this.state.backColor} />

				 <p class="text-center w-responsive mx-auto mb-5" />

                    <div className="row" style={{
                        justifyContent: 'center',
                        textAlign: 'center'
                    }}>

                <ThemeContext.Consumer>
                {(context) => (
                        <div className="col-md-9 mb-md-0 mb-5" style={{
                            border: 'solid',
                            borderColor: context.themeColors.primaryColor,
                            padding: 40,
                            borderRadius: 15,
                            width: 'auto'

                        }}>
                      <LoaderWrapper isLoading={this.state.isLoading}>
							<form
								onSubmit={this.handleSubmit}
								id="contact-form"
								name="contact-form"
							>
								<div class="row">
									<div class="col-md-12">
										<div class="md-form mb-0">
											<DateandTime />
										</div>
									</div>
								</div>
								<br />
								<div class="row">
									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="name" class="">
												Admin Name
											</label>
											<input
												
												type="text"
												name="AdminName"
												class="form-control"
												readOnly={true}
												value={this.props.userData.unique_name}
											/>
										</div>
									</div>

									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="name" class="">
												Admin Type
											</label>
											<input
												
												type="text"
												name="AdminType"
												class="form-control"
												readOnly={true}
												value={this.props.userData.role}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												Class
											</label>
											<select
												
												onChange={this.onSelectClassChange}
												class="custom-select"
												name="staffId"
											>
												<option selected={this.state.msg ? true : false}>
													Choose Name...
												</option>
												{staffData &&
													staffData.classes.map((item, index) => (
														<option key={index} value={`${item.classId}`}>
															{item.className}
														</option>
													))}
											</select>
										</div>
									</div>
									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												Section
											</label>
											<select
												
												onChange={this.onSectionChange}
												class="custom-select"
												name="staffId"
											>
												<option selected={this.state.msg ? true : false}>
													Choose Section...
												</option>
												{classId && staffData.staffClassSection.map((item, index) => {
													return classId == item.classId && <option key={index} value={`${item.sectionId}`}>
														{item.section}
													</option>
												})}
											</select>
										</div>
									</div>
									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												Staff Name
											</label>
											<select
												
												onChange={this.onStaffChange}
												value={staffId}
												disabled
												class="custom-select"
												name="staffId"
											>
												<option selected={this.state.msg ? true : false}>
													Choose Staff...
												</option>
												{sectionId && staffData.staffClassSection.map((item, index) => {
													return classId == item.classId && sectionId == item.sectionId && <option key={index} value={`${item.staffId}`}>
														{item.staffName}
													</option>
												})}
											</select>
										</div>
									</div>
									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="name" class="">
												Grade
											</label>
											<input
												
												type="text"
												name="gradeId"
												class="form-control"
												readOnly={true}
												value={this.state.gradeName}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="sessionId" class="">
												Sessions
											</label>
											<select
												onChange={this.onSelectSessionChange}
												class="custom-select"
												name="sessionId"
												
											>
												<option selected={this.state.msg ? true : false}>
													Choose Session...
												</option>
												{session &&
													session.session.map((item, index) => (
														<option key={index} value={`${item.sessionId}`}>
															{item.currentSession}
														</option>
													))}
											</select>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="term" class="">
												Term
											</label>
											<select
												onChange={this.onHandleTextChange}
												class="custom-select"
												name="term"
												
											>
												<option selected={this.state.msg ? true : false}>
													Choose Term...
												</option>
												{
													termList.map((item, index) => (
														<option key={index} value={`${item.id}`}>
															{item.name}
														</option>
													))}
											</select>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												Subject
											</label>
											<select
												
												onChange={this.onSelectSubjectChange}
												class="custom-select"
												name="staffId"
											>
												<option selected={this.state.msg ? true : false}>
													Subject Name...
												</option>
												{staffId && staffData.staffClassSection.map((item, index) => {
													return classId == item.classId && sectionId == item.sectionId && staffId == item.staffId && <option key={index} value={`${item.subjectId}`}>
														{item.subjectName}
													</option>
												})}
											</select>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 50 }}>
										<div class="md-form mb-0">
											<div class="file-upload">
												<div class="file-select">
													<div class="file-select-button" id="fileName">
														Choose File
													</div>
													<div class="file-select-name" id="noFile">
														No File Choosen
													</div>
													<input
														type="file"
														name="chooseFile"
														id="chooseFile"
														onChange={this.onChangeFile}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
								<br />
								<br />
								<div className="text-center text-md-center">
								<button type="submit" className="btns" style={{
                                                    background: context.themeColors.primaryColor,
                                            }}>
										Add
										<span
											style={{ marginBottom: 5 }}
											class={`${
												this.state.isLoading
													? 'spinner-border spinner-border-sm'
													: ''
											}`}
										></span>
									</button>
								</div>
							</form>
							</LoaderWrapper>
                            <div class="status" />
                </div>
              )}
            </ThemeContext.Consumer>
                    </div>
				</section>
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch),
		SyllabusAction: bindActionCreators(SyllabusAction, dispatch)
	};
}
function mapStateToProps(state) {
	return {
		userData: state.setUserReducer.users,
		selectData: state.setHomeWorkReducer.select,
		selectSession: state.setHomeWorkReducer.sessionSelect
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AddSyllabusStaff);
