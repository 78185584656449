import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import $ from 'jquery';
import isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom'
import isNull from 'lodash/isNull'

import ThemeContext from "../../context/themeContext/ThemeContext";


class ViewStudentTalent extends Component {
    constructor(props){
        super(props);
        this.state = {
            payrollData: [],
            search: '',
            isLoading: true,
            modalEdit: false,
            editToggle: false,
            generateToggle: false,
            generateIndex: '',
            msg: '',
            editIndex: '',

            termList: ['1st term', '2nd term', '3rd term'],
            term: null,
            session: null,
            sessionId: null,
            classId: null,
            sectionId: null,
            studentId: null,
            categoryId: null,
            subCategoryId: null,
            reward: null,
            valid: null,

        }

        


    }

    componentDidMount(){
        
        this.getSimple('session', 'session')
        this.getSimple('Student', 'student')
        this.getSimple('TalentCategory', 'talentCategory')
        this.getSimple('TalentSubCategory', 'talentSubCategory')
    }

    getSimple = (type, state) => {
        this.props.sharedActions.getDataWithoutDispatch(
            `/api/${type}`
        ).then(success => { 
            this.setState({
                [state]: success,
                isLoading: false
            })


        }).catch(error => {
            this.setState({
                msg: 'Error Occured!!',
                isLoading: false
            })
            this.props.snackbar();
            console.error('Error occured', error);
        })
    }


    

    onEditClick = id => {
        const { studentTalents } = this.state
        let modalData = studentTalents.filter((items, index ) =>  {
            if(id === items.id){
                    
            this.setState({
                editIndex: index
            })
            console.log(index)
            return id === items.id 
            }
        })
        console.log(modalData)
        this.setState({
            id: modalData[0].id,
            accountId: modalData[0].accountId,
            accountType: modalData[0].accountType,
            studentName: modalData[0].studentName,
            className: modalData[0].className,
            section: modalData[0].section,
            sessionName: modalData[0].sessionName,
            categoryName: modalData[0].categoryName,
            subCategoryName: modalData[0].subCategoryName,
            reward: modalData[0].reward,
            time: modalData[0].time, 
            date: modalData[0].date,
            
        }, () => {
            $('#myModal1').modal({
                show: true
            })
        })
    }

    PrintContent = el => {
        var restorepage = document.body.innerHTML;
        var Printcontent = document.getElementById(el).innerHTML;
        document.body.innerHTML = Printcontent;
        
        setTimeout(() => {
            window.print();
            window.location.reload();
        }, 700)
        document.body.innerHTML = restorepage;
        window.reload();
        
    };



    myFunction = () => {
		// Get the snackbar DIV
		var x = document.getElementById('snackbar');

		// StepperGeneralButtons the "show" class to DIV
		x.className = 'show';

		// After 3 seconds, remove the show class from DIV
		setTimeout(function() {
		}, 3000);
	};

    handleSubmit = e => {
        e.preventDefault();
        console.log('Submit Called')

        const { accountId, accountType,
            id, sessionId, classId, sectionId, studentId, term, categoryId, subCategoryId, reward,
            studentName, className, section, sessionName, categoryName, subCategoryName, date, time
           } = this.state;

        let data = {
            accountId,
            accountType,
            id, 
            sessionId, 
            classId, 
            sectionId, 
            studentId, 
            term, 
            categoryId, 
            subCategoryId, 
            reward,
            studentName, 
            className, 
            section, 
            sessionName, 
            categoryName, 
            subCategoryName, 
            date, 
            time 
        }


        console.log('data model', data)
        this.props.sharedActions.editRecordWithoutDispatch(
            '/api/StudentTalents/',
            id,
            data
        ).then(success => {
            console.log('success', success)
            this.state.studentTalents.splice(this.state.editIndex, 1, data)
            
                
                $('#myModal1').modal('hide')
            
            this.setState({
                msg: 'Record Changed Successfully!',

                
            })
            this.props.snackbar();
            

        }).catch(error => {
            this.setState({
                msg: error.response ? error.response.data.Message : 'Error Occured!!',
                isLoading: false
            })
            console.error('Error name: ', error)
                
                $('#myModal1').modal('hide')
            this.setState({
                msg: 'Error Occured!'
            })
            this.props.snackbar();


        })
    }

    
    checkBook = (studentId) => {
        const { student } = this.state;
        let f = student.student.filter((data) => {
            return data.studentId == studentId
        })

        if(f.length > 0 ){
            this.setState({
                classId: f[0].classId,
                sectionId: f[0].sectionId,
                msg: 'Student Found!!',
            })
            this.props.snackbar();
        }else{
            this.setState({
                msg: 'Student Not Found!!',
            })
            this.props.snackbar();

        }
        

    }

        handleSearch = () => {
            const { classId, sectionId, sessionId, studentId, term, categoryId, subCategoryId } = this.state
            console.log(classId, sectionId, sessionId, studentId, term, categoryId, subCategoryId);
            
            if( false ){
            }else{
                
                this.getSimple(`/StudentTalents/?classId=${classId}&sectionId=${sectionId}&sessionId=${sessionId}&studentId=${studentId}&term=${term}&categoryId=${categoryId}&subcategoryId=${subCategoryId}`,'studentTalents')
                
            }
        }

    btnTechHover = () => {
		$(document).ready(function() {
			$('button').hover(
				function() {
					$(this).css('box-shadow', '0px 5px 5px grey');
					$(this).css('border-color', 'white');
				},
				function() {
					$(this).css('box-shadow', '0px 0px 0px white');
				}
			);
		});
	};

    onHandleText = e => {
		this.setState({
			[e.target.name]: e.target.value
        });
        console.log([e.target.name], e.target.value)
    };
    


    render(){

        const { search, isLoading, editToggle, msg, studentTalents, accountId, accountType,
            termList, student, session, talentCategory, talentSubCategory, valid,
            sessionId, classId, sectionId, studentId, term, categoryId, subCategoryId, reward,
            studentName, className, section, sessionName, categoryName, subCategoryName, date
            
        } = this.state;

        



        const editStudentTalentsData = (
            <ThemeContext.Consumer>
            {(context) => (
            <div>
            <div className="modal" id="myModal1">
                <div className="modal-dialog">
                    <div className="modal-content modal_content_custom">
                        <div
                            style={{
                                borderBottomLeftRadius: 30,
                                borderBottomRightRadius: 30,
                                borderTopLeftRadius: 18,
                                borderTopRightRadius: 18,
                                background: context.themeColors.primaryColor
                            }}
                            className="modal-header modal_custom_header"
                        >
                            <h4 style={{ color: 'white' }} className="modal-title">
                                Edit
                            </h4>
                            <button
                                style={{ color: 'white' }}
                                on
                                type="button"
                                className="close"
                                data-dismiss="modal"
                            >
                                &times;
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={this.handleSubmit} >
                            <fieldset disabled={!editToggle}>
                            <div className="form-group">
                                <label for="subCategoryName">Reward:</label>
                                <input style={{
                                    borderTop: 0,
                                    borderRight: 0,
                                    borderLeft: 0
                                }}
                                type="text"
                                value={reward}
                                onChange={this.onHandleText}
                                name="reward"
                                className="form-control"
                                id="reward"
                                />
                                </div>
                                <div className="form-group">
                                <label for="accountId">Account Name:</label>
                                <input style={{
                                    borderTop: 0,
                                    borderRight: 0,
                                    borderLeft: 0
                                }}
                                type="text"
                                disabled
                                value={accountId}
                                name="modalDataAccountId"
                                className="form-control"
                                id="accountId"
                                />
                                </div>
                                <div className="form-group">
                                <label for="accountType">Account Type:</label>
                                <input style={{
                                    borderTop: 0,
                                    borderRight: 0,
                                    borderLeft: 0
                                }}
                                type="text"
                                disabled
                                value={accountType}
                                name="modalDataAccountType"
                                className="form-control"
                                id="accountType"
                                />
                                </div>
                                <div className="form-group">
                                <label for="staffName">Student Name:</label>
                                <input style={{
                                    borderTop: 0,
                                    borderRight: 0,
                                    borderLeft: 0
                                }}
                                type="text"
                                disabled
                                value={studentName}
                                name="studentName"
                                className="form-control"
                                id="studentName"
                                />
                                </div><div className="form-group">
                                <label for="className">Class:</label>
                                <input style={{
                                    borderTop: 0,
                                    borderRight: 0,
                                    borderLeft: 0
                                }}
                                type="text"
                                disabled
                                value={className}
                                name="className"
                                className="form-control"
                                id="className"
                                />
                                </div><div className="form-group">
                                <label for="section">Section:</label>
                                <input style={{
                                    borderTop: 0,
                                    borderRight: 0,
                                    borderLeft: 0
                                }}
                                type="text"
                                disabled
                                value={section}
                                name="section"
                                className="form-control"
                                id="section"
                                />
                                </div><div className="form-group">
                                <label for="sessionName">Session:</label>
                                <input style={{
                                    borderTop: 0,
                                    borderRight: 0,
                                    borderLeft: 0
                                }}
                                type="text"
                                disabled
                                value={sessionName}
                                name="sessionName"
                                className="form-control"
                                id="sessionName"
                                />
                                </div><div className="form-group">
                                <label for="term">Term:</label>
                                <input style={{
                                    borderTop: 0,
                                    borderRight: 0,
                                    borderLeft: 0
                                }}
                                type="text"
                                disabled
                                value={term}
                                name="term"
                                className="form-control"
                                id="term"
                                />
                                </div><div className="form-group">
                                <label for="categoryName">Category:</label>
                                <input style={{
                                    borderTop: 0,
                                    borderRight: 0,
                                    borderLeft: 0
                                }}
                                type="text"
                                disabled
                                value={categoryName}
                                name="categoryName"
                                className="form-control"
                                id="categoryName"
                                />
                                </div><div className="form-group">
                                <label for="subCategoryName">Sub-Category:</label>
                                <input style={{
                                    borderTop: 0,
                                    borderRight: 0,
                                    borderLeft: 0
                                }}
                                type="text"
                                disabled
                                value={subCategoryName}
                                name="subCategoryName"
                                className="form-control"
                                id="subCategoryName"
                                />
                                </div><div className="form-group">
                                <label for="date">Date:</label>
                                <input style={{
                                    borderTop: 0,
                                    borderRight: 0,
                                    borderLeft: 0
                                }}
                                type="text"
                                disabled
                                value={date}
                                name="date"
                                className="form-control"
                                id="date"
                                />
                                </div>
                            </fieldset>
                            <br />
							<br />
								<button
                                style={{background: context.themeColors.primaryColor}}
									disabled={editToggle}
									onClick={() => this.setState({ editToggle: true })}
									type="button"
									class="btn btn-primary buttonAppear"
									>
								    	Edit
									</button>
									<button
										style={{ marginLeft: 5, backgroundL: context.themeColors.primaryColor }}
										disabled={!editToggle}
										type="submit"
										class="btn btn-primary buttonAppear"
										>
										Save
									</button>
                            </form>
                        </div>
                        <div class="modal-footer">
								<button
									type="button"
									style={{ background: '#00435D' }}
                                    class="btn btn-danger"
                                    onClick={() => this.setState({editToggle: false})}
									data-dismiss="modal"
								>
									Close
								</button>
							</div>
                    </div>
                </div>
            </div>
            </div>                 
                                                                                                        )}
                                                                                                        </ThemeContext.Consumer>
        )

        const Loader = (
            <ThemeContext.Consumer>
            {(context) => (
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<div className="spinner-border" style={{ color: context.themeColors.primaryColor }}></div>
			</div>
                                                                                                        )}
                                                                                                        </ThemeContext.Consumer>
        );
        
        const renderStudentTalentsData = studentTalents && studentTalents.map((item, index) => {
            return (
                <ThemeContext.Consumer>
                {(context) => (

            <tr key={index} >
                <th scope="row" style={{ padding: 1 }} >
                    {index + 1}
                </th>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.studentName}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.accountId}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.accountType}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.className}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.section}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.sessionName}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.term}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.categoryName}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.subCategoryName}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.reward}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.date}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                <a href="#" data-toggle="tooltip" title="Edit!">
                            
                            <button
								id="firstbutton"
                                onClick={() => this.onEditClick(item.id)}
								onMouseOver={this.btnTechHover()}
								style={{
									cursor: 'pointer',
									fontSize: 20,
									border: 'none',
									marginLeft: 5,
									background: 'none',
									marginBottom: 5
								}}
							>
								<i className="fas fa-pen"></i>
							</button>
						</a>
                </td>
                
            </tr>
                                                                                                        )}
                                                                                                        </ThemeContext.Consumer>
            );
        }) 

        return (
            <ThemeContext.Consumer>
        {(context) => (
            <div className="page-header"> 
                <SnackBar msg={msg} />
                <div style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                }}>
                    <div class="row">
                        
                        <div class="col-md-12" >
                        <h2  
                        style={{ background: context.themeColors.primaryColor }}
                        className="h1-responsive font-weight-bold text-center  generalHead">
                        Student Talents
                    </h2>
                        </div>
                        </div>
                        <div class="row my-3 mx-5 py-4" style={{
                            border: `2px solid ${context.themeColors.primaryColor}`,
                            borderRadius: 20
                        }} >
                            <div class="col-md-3" >
                            <select class="custom-select" 
                                    value={sessionId}
                                    name="sessionId"
                                    onChange={this.onHandleText}
                                >
                                        <option value={null}>Please Select Session</option>
                                       {session && session.session.map((value, index) => {
                                           
                                           return <option value={value.sessionId}>{value.currentSession}</option>
                                       })}
                            </select>
                            </div>
                            <div class="col-md-3">
                            <div className="input-group md-form mb-3" >
                                            
                                            <input class="form-control" 
                                                type='text'
                                                value={studentId}
                                                name="studentId"
                                                onChange={this.onHandleText}
                                                disabled={!student}
                                            />
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary" 
                                                disabled={!studentId && !student}
                                                onClick={() => this.checkBook(studentId)}
                                                type="button">
                                                    <i class="fas fa-search" ></i>
                                                    </button>
                                            </div>
                                        </div>
                            </div>
                            <div class="col-md-3" >
                            <select class="custom-select" 
                                                value={classId}
                                                name="classId"
                                                onChange={this.onHandleText}
                                                // disabled={!sessionId}
                                            >
                                                        <option value="">Please Select Class</option>
                                                       {student && student.classes.map((value, index) => {
                                                           
                                                           return <option value={value.classId}>{value.className}</option>
                                                       })}
                                            </select>
                            </div>
                            <div class="col-md-3" >
                            <select class="custom-select" 
                                                value={sectionId}
                                                name="sectionId"
                                                onChange={this.onHandleText}
                                                // disabled={!classId}

                                            >
                                                        <option value="">Please Select Section</option>
                                                       {student && student.sections.map((value, index) => {


                                                                return value.classId == classId && 
                                                                <option value={value.sectionId}>{value.section}</option>

                                                       })}
                                            </select>
                            </div>
                            <div class="col-md-3" >
                            <select class="custom-select" 
                                                value={studentId}
                                                name="studentId"
                                                onChange={this.onHandleText}
                                                // disabled={!sectionId}
                                            >
                                                        <option value="">Please Select Student</option>
                                                       {student && student.student.map((value, index) => {
                                                           
                                                           return value.classId == classId && value.sectionId == sectionId && 
                                                           <option value={value.studentId}>{value.Student}</option>
                                                       })}
                                            </select>
                            </div>
                            <div class="col-md-3" >
                            <select class="custom-select" 
                                                value={term}
                                                name="term"
                                                onChange={this.onHandleText}
                                                // disabled={!studentId}
                                            >
                                                        <option value="">Please Select Term</option>
                                                        {termList.map((value, index) => {
                                                           
                                                           return <option value={value}>{value}</option>
                                                       })}
                                            </select>
                            </div>
                            <div class="col-md-3" >
                            <select class="custom-select" 
                                                value={categoryId}
                                                name="categoryId"
                                                onChange={this.onHandleText}
                                                // disabled={!term || !talentCategory}
                                            >
                                                        <option value="">Please Select Category</option>
                                                        {talentCategory && talentCategory.map((value, index) => {
                                                           
                                                           return <option value={value.id}>{value.categoryName}</option>
                                                       })}
                                            </select>
                            </div>
                            <div class="col-md-3" >
                            <select class="custom-select" 
                                                value={subCategoryId}
                                                name="subCategoryId"
                                                onChange={this.onHandleText}
                                                // disabled={!categoryId || !talentSubCategory}
                                            >
                                                        <option value="">Please Select Sub Category</option>
                                                        {talentSubCategory && talentSubCategory.map((value, index) => {
                                                           
                                                           return categoryId == value.talentCategoryId && 
                                                           <option value={value.id}>{value.subCategoryName}</option>
                                                       })}
                                            </select>
                            </div>
    
                        </div>
                        <div class="row my-2">
                            <div class="col-md-12  text-center" >
                                            <button
                                            class="btn btn-primary buttonAppear"
                                            onClick={this.handleSearch}
                                            style={{marginRight: '2%', background: context.themeColors.primaryColor}}
                                            >
                            <i class="fa fa-search"></i> Search
                                        </button>
                                        <Link to={{
                            pathname: "PrintStudentTalent",
                            state: {
                                studentTalents,
                            }
                        }}  >
                            <a href="#" data-toggle="tooltip" title="Edit!">
                                
                            <button
                                            class="btn btn-primary buttonAppear"
                                            style={{background: context.themeColors.primaryColor}}
                                            disabled={!studentTalents}
                                            >
                            
                                    <i className="fas fa-list"></i> View
                                </button>
                            </a> 
                            
                        </Link>
                            </div>
                            
                        </div>
                        <div class="row my-2 mx-2" style={{paddingLeft: '20px'}} >
                            <div class="col-md-3" >
                                    <span>Session: <strong style={{color: context.themeColors.primaryColor}} >{studentTalents && studentTalents[0].sessionName}</strong></span>     
                            </div>
                            <div class="col-md-3 "  >
                                    <span>Student ID: <strong style={{color: context.themeColors.primaryColor}} >{studentTalents && studentTalents[0].studentId}</strong></span>     
                            </div>
                            <div class="col-md-3" >
                                    <span>Class: <strong style={{color: context.themeColors.primaryColor}} >{studentTalents && studentTalents[0].className}</strong></span>     
                            </div>
                            <div class="col-md-3 "  >
                                    <span>Section: <strong style={{color: context.themeColors.primaryColor}} >{studentTalents && studentTalents[0].section}</strong></span>     
                            </div>
                            <div class="col-md-3" >
                                    <span>Student Name: <strong style={{color: context.themeColors.primaryColor}} >{studentTalents && studentTalents[0].studentName}</strong></span>     
                            </div>
                            <div class="col-md-3" >
                                    <span>Term: <strong style={{color: context.themeColors.primaryColor}} >{studentTalents && studentTalents[0].term}</strong></span>     
                            </div>
                            <div class="col-md-3" >
                                    <span>Category: <strong style={{color: context.themeColors.primaryColor}} >{studentTalents && studentTalents[0].categoryName}</strong></span>     
                            </div>
                            <div class="col-md-3" >
                                    <span>Sub-Category: <strong style={{color: context.themeColors.primaryColor}} >{studentTalents && studentTalents[0].subCategoryName}</strong></span>     
                            </div>
                        </div>
                    <div className="table-responsive">
					<table className="table table-hover text-center">
						<thead style={{ background: context.themeColors.primaryColor, color: 'white' }}>
							<tr>
								<th scope="col" style={{ padding: 1 }}>
									#
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Student Name
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Account Name
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Account Role
								</th>
								<th scope="col" style={{ padding: 1 }}>
                                    Class
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Section
								</th>
								<th scope="col" style={{ padding: 1 }}>
                                    Session
								</th>
                                <th scope="col" style={{ padding: 1 }}>
                                    Term
								</th>
                                <th scope="col" style={{ padding: 1 }}>
                                    Category
								</th>
                                <th scope="col" style={{ padding: 1 }}>
                                    Sub-Category
								</th>
                                <th scope="col" style={{ padding: 1 }}>
                                    Reward
								</th>
                                <th scope="col" style={{ padding: 1 }}>
                                    Date
								</th>
                                <th scope="col" style={{ padding: 1 }}>
                                    Actions
								</th>
                                

                                
							</tr>
						</thead>
						<tbody>{isLoading ? Loader : renderStudentTalentsData}</tbody>
					</table>
				</div>
                {editStudentTalentsData}
                </div>
            </div>
                                                                                                        )}
                                                                                                        </ThemeContext.Consumer>
        );
    }

}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch),
	};
}
function mapStateToProps(state) {
	return {
		userData: state.setUserReducer.users,
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ViewStudentTalent);
