import React, { Component } from 'react';

class StaffSkillsPrint extends Component {
	state = {};
	render() {
		const { Document } = this.props;
		console.log(Document, 'Skills Data');
		return (
			<div>
				<div id="div1">
					<section class="mb-4">
						<div class="container">
							<div class="row rowStaffCheck" style={{ marginTop: 10 }}>
								<div class="col colStaffCheck">
									<label style={{ fontWeight: 'bold' }}>Skill Name: </label>{' '}
									&nbsp;&nbsp;
									{Document && Document.skillName}
								</div>
							</div>
						</div>
					</section>
				</div>
			</div>
		);
	}
}

export default StaffSkillsPrint;
