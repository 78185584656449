import React from "react";
import CardsForView from "../components/CardsForView";
import { renderingStuff, images } from "../utils";

const FloorPlanDashboard = () => {
  console.log(renderingStuff.Floor_Plan_Links, "Links");
  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div class="row justify-content-center">
            <div class="col-md-12 text-center">
              <img
                src={require("../Assets/AdministrationDashboard/floor plan icon.svg")}
                class="img-fluid"
                alt="Responsive image"
                height="50"
                width="50"
              />
              <h4 className="mt-2" style={{ letterSpacing: 10 }}>
                Floor Plan
              </h4>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className="row">
        {renderingStuff.Floor_Plan_Links.map((item, index) => {
          return (
            <div className="col-md-6">
              <CardsForView data={item} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FloorPlanDashboard;
