import React, { Component } from "react";

class StaffMedicalCondition extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { medicalCondition } = this.props;
    console.log(medicalCondition, "Medical Conditions Ka Data haii");
    return (
      <div>
        <section class="mb-4">
          <div class="row print">
            <div class="col-md-12">
              <div class="md-form mb-0">
                <h2 className="FormHeadingStyle">Medical Condition:</h2>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row rowCheck" style={{ marginTop: 10 }}>
              <div class="col colStaffCheck">
                <label style={{ fontWeight: "bold" }}>Threat Condition: </label>
                &nbsp;&nbsp;
                {medicalCondition && medicalCondition.threatCondition}
              </div>
              <div class="col colStaffCheck">
                <label style={{ fontWeight: "bold" }}>Need Medication: </label>
                &nbsp;&nbsp;
                {medicalCondition && medicalCondition.needMedication}
              </div>
              <div class="col colStaffCheck">
                <label style={{ fontWeight: "bold" }}>Other Medication: </label>
                &nbsp;&nbsp;
                {medicalCondition && medicalCondition.otherMedication}
              </div>
            </div>
            <div class="row rowCheck">
              <div class="col colStaffCheck">
                <label style={{ fontWeight: "bold" }}>
                  Threat Condition Reason:
                </label>{" "}
                &nbsp;&nbsp;
                {medicalCondition && medicalCondition.threatConditionReason}
              </div>
            </div>
            <div class="row rowCheck">
              <div class="col colStaffCheck">
                <label style={{ fontWeight: "bold" }}>
                  Medication Reason:{" "}
                </label>
                &nbsp;&nbsp;
                {medicalCondition && medicalCondition.needMedicationReason}
              </div>
            </div>
            <div class="row rowCheck">
              <div class="col colStaffCheck">
                <label style={{ fontWeight: "bold" }}>
                  Other Medication Reason:{" "}
                </label>
                &nbsp;&nbsp;
                {medicalCondition && medicalCondition.otherMedicationReason}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default StaffMedicalCondition;
