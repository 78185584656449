import * as actionTypes from '../Actions/actionTypes';

const defaultState = {
	apiFlag: true,
	Data: [],
	searchData: [],
	select: [],
	sessionSelect: []
};

export default function setBudgetItemsReducer(
	state = defaultState,
	action = {}
) {
	switch (action.type) {
		case actionTypes.SET_ALL_SESSION_NAME_INTO_BUDGETITEM_SELECT:
			return {
				...state,
				sessionSelect: action.payload
			};

		case actionTypes.GET_ALL_BUDGETITEMS:
			return { ...state, Data: [...action.payload] };
		case actionTypes.SEARCH_ALL_BUDGETITEMS:
			return { ...state, searchData: [...action.payload] };
		case actionTypes.SET_LOADER_FLAG_BUDGETITEMS:
			return { ...state, apiFlag: action.payload };
		case actionTypes.DELETE_BUDGETITEMS:
			let filterCandidateReqList = state.Data.filter(
				filterOBJ => filterOBJ.budgetPlanId !== action.payload
			);
			return {
				...state,
				Data: filterCandidateReqList
			};
		case actionTypes.EDIT_BUDGETITEMS_RECORD:
			var adminData = [...state.Data];
			var index = adminData.findIndex(obj => obj.id === action.payload.id);
			adminData[index].homeworkName = action.payload.homeworkName;
			adminData[index].topicDescription = action.payload.topicDescription;
			adminData[index].submissionDate = action.payload.submissionDate;
			// adminData[index].gradeName = action.payload.gradeName;
			// adminData[index].subjectName = action.payload.subjectName;
			// adminData[index].sectionName = action.payload.sectionName;
			// adminData[index].className = action.payload.className;
			// adminData[index].StaffFirstName = action.payload.staffName;
			// adminData[index].sessionName = action.payload.sessionName;

			return { ...state, Data: [...adminData] };
		default:
			return state;
	}
}
