import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import $ from 'jquery';
import isEmpty from 'lodash/isEmpty';

import LoaderWrapper from "../../components/HOC/LoaderWrapper";
import ThemeContext from "../../context/themeContext/ThemeContext";




class ViewFloor extends Component {
    constructor(props){
        super(props);
        this.state = {
            search: '',
            isLoading: true,
            modalEdit: false,
            editToggle: false,
            generateToggle: false,
            generateIndex: '',
            msg: '',
            editIndex: '',
            floorData: [],
            floorId: null,
            floorName: null,

        }

        


    }
	static contextType = ThemeContext

    componentDidMount(){
        this.getSimple('Floors', 'floorData')
    }

    getSimple = (type, state) => {
        this.props.sharedActions.getDataWithoutDispatch(
            `/api/${type}`
        ).then(success => { 
            this.setState({
                [state]: success,
                isLoading: false
            })


        }).catch(error => {
            this.setState({
                msg: 'Error Occured!!',
                isLoading: false
            })
            console.error('Error occured', error);
        })
    }


    onEditClick = id => {
        const { floorData } = this.state
        let modalData = floorData.filter((items, index ) =>  {
            if(id === items.id){
                    
            this.setState({
                editIndex: index
            })
            console.log(index)
            return id === items.id 
            }
        })
        console.log(modalData)
        this.setState({
            id: modalData[0].id,
            floorName: modalData[0].floorName,
            accountId: modalData[0].accountId,
            accountType: modalData[0].accountType, 
            
        }, () => {
            $('#myModal1').modal({
                show: true
            })
        })
    }



    myFunction = () => {
		// Get the snackbar DIV
		var x = document.getElementById('snackbar');

		// StepperGeneralButtons the "show" class to DIV
		x.className = 'show';

		// After 3 seconds, remove the show class from DIV
		setTimeout(function() {
		}, 3000);
	};

    handleSubmit = e => {
        e.preventDefault();
        console.log('Submit Called')

        const {  id,
            floorName,
            accountId,
            accountType,  } = this.state;

        let data = {
            id,
            floorName,
            accountId,
            accountType,
        }

        console.log('data model', data)
        this.props.sharedActions.editRecordWithoutDispatch(
            '/api/Floors/',
            id,
            data
        ).then(success => {
            console.log('success', success)
            this.state.floorData.splice(this.state.editIndex, 1, data)
            
                $('#myModal1').modal('hide')
            
            this.setState({
                msg: 'Record Changed Successfully!',

                
            })
            this.props.snackbar();
            

        }).catch(error => {
            console.error('Error name: ', error)
                
                $('#myModal1').modal('hide')
            this.setState({
                msg: error.response ? error.response.data.Message : 'Error Occured!!',
                isLoading: false
            })
            this.props.snackbar();


        })
    }

    onDeleteClick = id => {
        const { floorData } = this.state
        let modalData = floorData.filter((items, index ) =>  {
            if(id === items.id){
                    
            this.setState({
                editIndex: index
            })
            console.log(index)
            return id === items.id 
            }
        })
        console.log(id)
        this.setState({
             modalDataDeleteId: id
            
        }, () => {
            $('#myModal2').modal({
                show: true
            })
        })
    }

    handleDelete = id => {
        
        const { editIndex } = this.state;

        this.props.sharedActions.deleteRecordWithoutDispatch(
            '/api/Floors',
            id
        ).then(success => {
            this.state.floorData.splice(editIndex, 1)
            
                this.setState({
                    msg: 'Record Deleted!',
                })
                this.props.snackbar();

            
        }).catch(error => {
            
            this.setState({
                msg: error.response ? error.response.data.Message : 'Error Occured!!',
                isLoading: false
            })
            this.props.snackbar();

        })
    }

    btnTechHover = () => {
		$(document).ready(function() {
			$('button').hover(
				function() {
					$(this).css('box-shadow', '0px 5px 5px grey');
					$(this).css('border-color', 'white');
				},
				function() {
					$(this).css('box-shadow', '0px 0px 0px white');
				}
			);
		});
	};

    onHandleText = e => {
		this.setState({
			[e.target.name]: e.target.value
        });
        console.log([e.target.name], e.target.value)
	};


    render(){

        const { search, isLoading, editToggle, msg, 
            floorData,
            floorName,
            accountId,
            accountType,
        } = this.state;

        
        const deleteFloorData = (
            <div>
            <div className="modal" id="myModal2">
                <div className="modal-dialog">
                    <div className="modal-content modal_content_custom">
                        <div
                            style={{
                                background: this.context.themeColors.primaryColor,
                                borderBottomLeftRadius: 30,
                                borderBottomRightRadius: 30,
                                borderTopLeftRadius: 18,
                                borderTopRightRadius: 18
                            }}
                            className="modal-header modal_custom_header"
                        >
                            <h4 style={{ color: 'white' }} className="modal-title">
                                Confirm
                            </h4>
                            <button
                                style={{ color: 'white' }}
                                on
                                type="button"
                                className="close"
                                data-dismiss="modal"
                            >
                                &times;
                            </button>
                        </div>
                        <div className="modal-body">
                        <div >
											<div style={{ textAlign: 'center' }}>
												<h4>Are you sure?</h4>
											</div>
											<div
												style={{
													display: 'flex',
													justifyContent: 'space-evenly'
												}}
											>
												<div>
													<button
														data-dismiss="modal"
														onClick={() => this.handleDelete(this.state.modalDataDeleteId)}
														type="button"
														class="btn btn-primary buttonAppear"
													>
														Yes
													</button>
												</div>
												<div>
													<button
														data-dismiss="modal"
														type="button"
														class="btn btn-primary buttonAppear"
													>
														No
													</button>
												</div>
											</div>
										</div>
                        </div>
                        <div class="modal-footer">
								<button
									type="button"
									style={{ background: '#00435D' }}
                                    class="btn btn-danger"
                                    onClick={() => this.setState({editToggle: false})}
									data-dismiss="modal"
								>
									Close
								</button>
							</div>
                    </div>
                </div>
            </div>
            </div>
        )


        const editFloorData = (
            <div>
            <div className="modal" id="myModal1">
                <div className="modal-dialog">
                    <div className="modal-content modal_content_custom">
                        <div
                            style={{
                                background: this.context.themeColors.primaryColor,
                                borderBottomLeftRadius: 30,
                                borderBottomRightRadius: 30,
                                borderTopLeftRadius: 18,
                                borderTopRightRadius: 18
                            }}
                            className="modal-header modal_custom_header"
                        >
                            <h4 style={{ color: 'white' }} className="modal-title">
                                Edit
                            </h4>
                            <button
                                style={{ color: 'white' }}
                                on
                                type="button"
                                className="close"
                                data-dismiss="modal"
                            >
                                &times;
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={this.handleSubmit} >
                            <fieldset disabled={!editToggle}>
                                
                                <div className="form-group">
                                <label for="accountId">Account Name:</label>
                                <input style={{
                                    borderTop: 0,
                                    borderRight: 0,
                                    borderLeft: 0
                                }}
                                type="text"
                                disabled
                                value={accountId}
                                name="modalDataAccountId"
                                className="form-control"
                                id="accountId"
                                />
                                </div>
                                <div className="form-group">
                                <label for="accountType">Account Type:</label>
                                <input style={{
                                    borderTop: 0,
                                    borderRight: 0,
                                    borderLeft: 0
                                }}
                                type="text"
                                disabled
                                value={accountType}
                                name="modalDataAccountType"
                                className="form-control"
                                id="accountType"
                                />
                                </div>
                                <div className="form-group">
                                <label for="staffName">Floor Name:</label>
                                <input style={{
                                    borderTop: 0,
                                    borderRight: 0,
                                    borderLeft: 0
                                }}
                                onChange={this.onHandleText}
                                type="text"
                                value={floorName}
                                name="floorName"
                                className="form-control"
                                id="floorName"
                                />
                                </div>

                            </fieldset>
                            <br />
							<br />
								<button
									disabled={editToggle}
									onClick={() => this.setState({ editToggle: true })}
									type="button"
									class="btn btn-primary buttonAppear"
									>
								    	Edit
									</button>
									<button
										style={{ marginLeft: 5 }}
										disabled={!editToggle}
										type="submit"
										class="btn btn-primary buttonAppear"
										>
										Save
									</button>
                            </form>
                        </div>
                        <div class="modal-footer">
								<button
									type="button"
									style={{ background: '#00435D' }}
                                    class="btn btn-danger"
                                    onClick={() => this.setState({editToggle: false})}
									data-dismiss="modal"
								>
									Close
								</button>
							</div>
                    </div>
                </div>
            </div>
            </div>                 
        )

        const Loader = (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div className="spinner-border" style={{ color: this.context.themeColors.primaryColor }}></div>
            </div>
        );
        
        const renderFloorData = floorData.map((item, index) => {
            return (

            <tr key={index} >
                <th scope="row" className="borderSpace" style={{ padding: 1 }} onClick={() => this.props.history.push({pathname: '/dashboard/ViewRoom', state: item})}  >
                    {index + 1}
                </th>
                <td className="borderSpace" style={{ padding: 1 }} onClick={() => this.props.history.push({pathname: '/dashboard/ViewRoom', state: item})} >
                    {item.floorName}
                </td>
                <td className="borderSpace" style={{ padding: 1 }} onClick={() => this.props.history.push({pathname: '/dashboard/ViewRoom', state: item})} >
                    {item.accountId}
                </td>
                <td className="borderSpace" style={{ padding: 1 }} onClick={() => this.props.history.push({pathname: '/dashboard/ViewRoom', state: item})} >
                    {item.accountType}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                <a href="#" data-toggle="tooltip" title="Edit!">
                            
                            <button
								id="firstbutton"
                                onClick={() => this.onEditClick(item.id)}
								onMouseOver={this.btnTechHover()}
								style={{
									cursor: 'pointer',
									fontSize: 20,
									border: 'none',
									marginLeft: 5,
									background: 'none',
									marginBottom: 5
								}}
							>
								<i className="fas fa-pen"></i>
							</button>
						</a>
                        <a href="#" data-toggle="tooltip" title="Delete!">
							<button
								onClick={() => this.onDeleteClick(item.id)}
								onMouseOver={this.btnTechHover()}
								style={{
									cursor: 'pointer',
									fontSize: 20,
									border: 'none',
									background: 'none',
									marginLeft: 5,
									marginBottom: 5
								}}
							>
								<i style={{ color: '#D11A2A' }} className="fas fa-trash-alt"></i>
							</button>
						</a>

                </td>
                
            </tr>
            
            );
        }) 

        return (
            <div className="page-header"> 
                <SnackBar msg={msg} />
                <div style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                }}>
                    <div class="row">
                        
                    <div class="col-md-6" >
                        <h2  
                        style={{ background: this.context.themeColors.primaryColor }}
                        className="h1-responsive font-weight-bold text-center  generalHead">
                        View Floors
                    </h2>
                    </div>
                    </div>
                    
                    <div className="table-responsive">
					<table className="table table-hover text-center">
						<thead style={{ background: this.context.themeColors.primaryColor, color: 'white' }}>
							<tr>
								<th scope="col" style={{ padding: 1 }}>
									#
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Floor Name
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Account Name
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Account Role
								</th>
                                <th scope="col" style={{ padding: 1 }}>
                                    Actions
								</th>
                                

                                
							</tr>
						</thead>
						<tbody>{isLoading ? Loader : renderFloorData}</tbody>
					</table>
				</div>
                </div>
                {editFloorData}
                {deleteFloorData}
            </div>
        );
    }

}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch),
	};
}
function mapStateToProps(state) {
	return {
		userData: state.setUserReducer.users,
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ViewFloor);
