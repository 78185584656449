import React, { Component } from "react";
import DateandTime from "./../Transport/DateandTime";
import DatePicker from "react-date-picker";
import $ from "jquery";
import { connect } from "react-redux";
import GeneralSelectionOptions from "../../components/Admission/GeneralSelectionOptions";
import { allCountries } from "../../utils/general";
import { General } from "../../utils";
import ReactFlagsSelect from "react-flags-select";

const gender = ["Male", "Female"];

const religion = ["Islam", "Christianity", "Hinduism"];
const admission_Status = ["Accepted", "Not Accepted", "Conditional Accepted"];

const GradeWithClass = [
  { className: "pre-nursery", gradeId: "1", gradeName: "pre-nursery" },
  { className: "nursery", gradeId: "2", gradeName: "nursery" },
  { className: "one", gradeId: "3", gradeName: "primary" },
];

const currentSession = ["session 1", "session 2", "session 3"];

const campus = [
  { campusId: "1", campusName: "FB AREA" },
  { campusId: "2", campusName: "Shahfaisal" },
  { campusId: "3", campusName: "Nipa" },
  { campusId: "4", campusName: "Area" },
];
const admissionType = ["General", "Special Condition"];

const language = ["Monolingual", "Bilingual", "International"];
const status = ["Active", "Non-Active"];
const nationality = ["Pakistan", "Oman", "Saudi", "Qatar", "Iran"];
const gradDropDown = [];
class StudentAdmission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gridNum: 4,
      grade: null,
      gradeName: "",
      className: "",
      section: "",
      editSection: false,
    };
  }

  componentDidMount() {
    const { stateData } = this.props;
    console.log("dumySample:", stateData.dummySample.classes);

    //   console.log(stateData.className);
    //   const { grade } = stateData.dummySample;
    //   let getGradeData = grade.filter((item, index) => item.className === stateData.className)
    //   console.log('getGradeData: ',getGradeData);
    //   ;
    //   this.setState({
    //     grade: getGradeData,
    //     gradeName: getGradeData[0] && getGradeData[0].gradeName,
    //     className: stateData.className,
    //     section:getGradeData[0].section
    //   })
    // }
  }

  render() {
    const {
      onGeneralSelectChangeHandler,
      onHandleTextChange,
      stateData,
      onChangeClassName,
      onChangeDate,
      onCustomAdmissionChange,
      onFileProfileChange,
      imageChange,
      sectionType,
      onChangeCountry,
      sType,
      errors,
    } = this.props;
    debugger;

    return (
      <div style={{ fontSize: 20, padding: 10 }} class="col-md-9 mb-md-0 mb-5">
        {/*{this.Message('Admin Add Successfully!','success',20,this.state.msg)}*/}
        {/*{this.Message('Please fill the form properly!','danger',20,this.state.errorMsg)}*/}
        <form
          id="contact-form"
          name="contact-form"
          // onSubmit={this.onHandleSubmit}
          noValidate
        >
          <div class="row">
            <div class="col-md-12">
              <div class="md-form mb-0">
                <DateandTime />
              </div>
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col-md-6">
              <div class="md-form mb-0">
                <label for="name" class="">
                  {/*<i*/}
                  {/*className="fas fa-user-shield animated fadeIn"*/}
                  {/*style={{ marginRight: 4 }}*/}
                  {/*/>*/}
                  Registration No:
                </label>
                <input
                  style={{
                    borderTop: 0,
                    borderLeft: 0,
                    borderRight: 0,
                    boxShadow: "none",
                    width: "80%",
                  }}
                  onChange={(e) => onCustomAdmissionChange(e)}
                  name="registrationNo"
                  value={stateData.registrationNo}
                  type="number"
                  class="form-control"
                  readOnly={true}
                  id="exampleInputEmail1"
                  placeholder="Registration No"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="md-form mb-0">
                <label for="name" class="">
                  {/*<i*/}
                  {/*className="fas fa-user-shield animated fadeIn"*/}
                  {/*style={{ marginRight: 4 }}*/}
                  {/*/>*/}
                  Full Arabic Name
                </label>
                <input
                  className={`form-control ${
                    errors.fullNameArabic ? "is-invalid" : ""
                  }`}
                  style={{
                    borderTop: 0,
                    borderLeft: 0,
                    borderRight: 0,
                    boxShadow: "none",
                    width: "80%",
                  }}
                  onChange={(e) => onHandleTextChange(e)}
                  value={stateData.fullNameArabic}
                  name="fullNameArabic"
                  type="text"
                  class="form-control"
                  id="exampleInputEmail1"
                  placeholder="Full Arabic Name"
                />
              </div>
            </div>

            <div class="col-md-6">
              <div class="md-form mb-0">
                <label for="name" class="">
                  {/*<i*/}
                  {/*className="fas fa-user-shield animated fadeIn"*/}
                  {/*style={{ marginRight: 4 }}*/}
                  {/*/>*/}
                  First Name
                </label>
                <input
                  className={`form-control ${
                    errors.firstName ? "is-invalid" : ""
                  }`}
                  style={{
                    borderTop: 0,
                    borderLeft: 0,
                    borderRight: 0,
                    boxShadow: "none",
                    width: "80%",
                  }}
                  onChange={(e) => onHandleTextChange(e)}
                  value={stateData.firstName}
                  name="firstName"
                  type="text"
                  class="form-control"
                  id="exampleInputEmail1"
                  placeholder="First Name"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="md-form mb-0">
                <label for="name" class="">
                  {/*<i*/}
                  {/*className="fas fa-user-shield animated fadeIn"*/}
                  {/*style={{ marginRight: 4 }}*/}
                  {/*/>*/}
                  Last Name
                </label>
                <input
                  style={{
                    borderTop: 0,
                    borderLeft: 0,
                    borderRight: 0,
                    boxShadow: "none",
                    width: "80%",
                  }}
                  onChange={(e) => onHandleTextChange(e)}
                  value={stateData.lastName}
                  name="lastName"
                  type="text"
                  class={`form-control ${errors.lastName ? "is-invalid" : ""}`}
                  id="exampleInputEmail1"
                  placeholder="Last Name"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="md-form mb-0">
                <label for="name" class="">
                  <i
                    className="fas fa-house animated fadeIn"
                    style={{ marginRight: 4 }}
                  />
                  Father Name
                </label>
                <input
                  style={{
                    borderTop: 0,
                    borderLeft: 0,
                    borderRight: 0,
                    boxShadow: "none",
                    width: "80%",
                  }}
                  onChange={(e) => onHandleTextChange(e)}
                  value={stateData.fatherName}
                  name="fatherName"
                  type="text"
                  class={`form-control ${
                    errors.fatherName ? "is-invalid" : ""
                  }`}
                  id="exampleInputEmail1"
                  placeholder="Father Name"
                />
              </div>
            </div>

            <div class="col-md-6">
              <div class="md-form mb-0">
                <label for="status" class="">
                  {/*<i*/}
                  {/*style={{ marginRight: 4 }}*/}
                  {/*className="fas fa-university animated fadeIn"*/}
                  {/*/>*/}
                  Admission Type
                </label>
                <br />
                <select
                  style={{
                    borderTop: 0,
                    borderLeft: 0,
                    borderRight: 0,
                    boxShadow: "none",
                    width: "80%",
                  }}
                  onChange={(e) => onHandleTextChange(e)}
                  className={`custom-select form-control ${
                    errors.admissionType ? "is-invalid" : ""
                  }`}
                  value={stateData.admissionType}
                  name="admissionType"
                >
                  <option selected={true}>Admission Type...</option>
                  {admissionType.map((item, index) => (
                    <option value={item} key={index}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="md-form mb-0">
                <label for="status" class="">
                  {/*<i*/}
                  {/*style={{ marginRight: 4 }}*/}
                  {/*className="fas fa-university animated fadeIn"*/}
                  {/*/>*/}
                  Admission Status
                </label>
                <br />
                <select
                  style={{
                    borderTop: 0,
                    borderLeft: 0,
                    borderRight: 0,
                    boxShadow: "none",
                    width: "80%",
                  }}
                  onChange={(e) => onHandleTextChange(e)}
                  className={`form-control ${
                    errors.admissionStatus ? "is-invalid" : ""
                  }`}
                  value={stateData.admissionStatus}
                  name="admissionStatus"
                >
                  <option selected={true}>Choose Admission Status...</option>
                  {admission_Status.map((item, index) => (
                    <option value={item} key={index}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="md-form mb-0">
                <label for="email" class="">
                  {/*<i*/}
                  {/*style={{ marginRight: 4 }}*/}
                  {/*className="fas fa-university animated fadeIn"*/}
                  {/*/>*/}
                  Current Session
                </label>
                <br />
                <select
                  style={{
                    borderTop: 0,
                    borderLeft: 0,
                    borderRight: 0,
                    boxShadow: "none",
                    width: "80%",
                  }}
                  onChange={(e) => onHandleTextChange(e)}
                  className={`custom-select form-control ${
                    errors.currentSessionId ? "is-invalid" : ""
                  }`}
                  value={stateData.currentSessionId}
                  name="currentSessionId"
                >
                  {/* <option selected={true}>Current Session...</option>
                        {currentSession.map((item, index) => (
                          <option value={item} key={index}>
                            {item}
                          </option>
                        ))} */}
                  <GeneralSelectionOptions
                    sectionType={"StudentAdmissionFormsForSessionData"}
                    currentSession={stateData.currentSession}
                    sessionData={this.props.sessionData}
                  />
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="md-form mb-0">
                <label for="email" class="">
                  {/*<i*/}
                  {/*style={{ marginRight: 4 }}*/}
                  {/*className="fas fa-university animated fadeIn"*/}
                  {/*/>*/}
                  Language
                </label>
                <br />
                <select
                  style={{
                    borderTop: 0,
                    borderLeft: 0,
                    borderRight: 0,
                    boxShadow: "none",
                    width: "80%",
                  }}
                  onChange={(e) => onHandleTextChange(e)}
                  class="custom-select"
                  className={`custom-select form-control ${
                    errors.language ? "is-invalid" : ""
                  }`}
                  value={stateData.language}
                  name="language"
                >
                  <option selected={true}>Choose Language...</option>
                  {language.map((item, index) => (
                    <option value={item} key={index}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="md-form mb-0">
                <label for="email" class="">
                  {/*<i*/}
                  {/*style={{ marginRight: 4 }}*/}
                  {/*className="fas fa-university animated fadeIn"*/}
                  {/*/>*/}
                  Gender
                </label>
                <br />
                <select
                  style={{
                    borderTop: 0,
                    borderLeft: 0,
                    borderRight: 0,
                    boxShadow: "none",
                    width: "80%",
                  }}
                  onChange={(e) => onCustomAdmissionChange(e)}
                  value={stateData.gender}
                  className={`custom-select form-control ${
                    errors.gender ? "is-invalid" : ""
                  }`}
                  name="gender"
                >
                  <option selected={true}>Choose Gender...</option>
                  {gender.map((item, index) => (
                    <option
                      className="text-capitalize"
                      value={item}
                      key={index}
                    >
                      {item}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="md-form mb-0">
                <label for="name" class="">
                  <i
                    className="fas fa-house animated fadeIn"
                    style={{ marginRight: 4 }}
                  />
                  Date Of Birth
                </label>
                <br />
                <DatePicker
                  style={{ borderTop: 0, borderLeft: 0, borderRight: 0 }}
                  onChange={(e) => onChangeDate(e, "dob")}
                  value={new Date(stateData.dob)}
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="md-form mb-0">
                <label for="email" class="">
                  {/*<i*/}
                  {/*style={{ marginRight: 4 }}*/}
                  {/*className="fas fa-university animated fadeIn"*/}
                  {/*/>*/}
                  Nationality
                </label>
                <br />
                <ReactFlagsSelect
                  searchable
                  selectButtonClassName="borderRemove"
                  selected={stateData.nationality}
                  onSelect={(code) => onChangeCountry(code)}
                />
                {/* <select
                  style={{
                    borderTop: 0,
                    borderLeft: 0,
                    borderRight: 0,
                    boxShadow: "none",
                    width: "80%",
                  }}
                  onChange={(e) => onHandleTextChange(e)}
                  className={`custom-select form-control ${
                    errors.nationality ? "is-invalid" : ""
                  }`}
                  value={stateData.nationality}
                  name="nationality"
                >
                  <option selected={true}>Nationality...</option>
                  {General.country.map((item, index) => (
                    <option value={item.name} key={index}>
                      {item.name}
                    </option>
                  ))}
                </select> */}
                {/* <input
                  style={{
                    borderTop: 0,
                    borderLeft: 0,
                    borderRight: 0,
                    boxShadow: "none",
                    width: "80%",
                  }}
                  onChange={(e) => onHandleTextChange(e)}
                  value={stateData.nationality}
                  name="nationality"
                  // readOnly={true}
                  type="text"
                  id="exampleInputEmail1"
                  className={`form-control ${
                    errors.nationality ? "is-invalid" : ""
                  }`}
                  placeholder="Enter Nationality"
                /> */}
              </div>
            </div>
            <div class="col-md-6">
              <div class="md-form mb-0">
                <label for="name" class="">
                  <i
                    className="fas fa-house animated fadeIn"
                    style={{ marginRight: 4 }}
                  />
                  Age
                </label>
                <input
                  style={{
                    borderTop: 0,
                    borderLeft: 0,
                    borderRight: 0,
                    boxShadow: "none",
                    width: "80%",
                  }}
                  onChange={(e) => onHandleTextChange(e)}
                  value={stateData.age}
                  name="age"
                  readOnly={true}
                  type="text"
                  id="exampleInputEmail1"
                  className={`form-control ${errors.age ? "is-invalid" : ""}`}
                  placeholder="Age"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="md-form mb-0">
                <label for="name" class="">
                  <i
                    className="fas fa-house animated fadeIn"
                    style={{ marginRight: 4 }}
                  />
                  Place Of Birth
                </label>
                <select
                  style={{
                    borderTop: 0,
                    borderLeft: 0,
                    borderRight: 0,
                    boxShadow: "none",
                    width: "80%",
                  }}
                  onChange={(e) => onHandleTextChange(e)}
                  className={`custom-select form-control ${
                    errors.placeOfBirth ? "is-invalid" : ""
                  }`}
                  value={stateData.placeOfBirth}
                  name="placeOfBirth"
                >
                  <option selected={true}>Place Of Birth...</option>
                  {General.country.map((item, index) => (
                    <option value={item.name} key={index}>
                      {item.name}
                    </option>
                  ))}
                </select>

                {/* <select
                  style={{
                    borderTop: 0,
                    borderLeft: 0,
                    borderRight: 0,
                    boxShadow: "none",
                    width: "80%",
                  }}
                  className={`form-control ${
                    errors.placeOfBirth ? "is-invalid" : ""
                  }`}
                  onChange={(e) => onHandleTextChange(e)}
                  value={stateData.placeOfBirth}
                  name="placeOfBirth"
                >
                  <option selected={true}>Place Of Birth...</option>
                  {allCountries.map((item, index) => (
                    <option value={item.name} key={index}>
                      {item.name}
                    </option>
                  ))}
                </select> */}
                {/* <input
                  style={{
                    borderTop: 0,
                    borderLeft: 0,
                    borderRight: 0,
                    boxShadow: "none",
                    width: "80%",
                  }}
                  onChange={(e) => onHandleTextChange(e)}
                  value={stateData.placeOfBirth}
                  name="placeOfBirth"
                  className={`form-control ${
                    errors.placeOfBirth ? "is-invalid" : ""
                  }`}
                  type="text"
                  id="exampleInputEmail1"
                  placeholder="Place Of Birth"
                /> */}
              </div>
            </div>

            <div class="col-md-6">
              <div class="md-form mb-0">
                <label for="email" class="">
                  {/*<i*/}
                  {/*style={{ marginRight: 4 }}*/}
                  {/*className="fas fa-university animated fadeIn"*/}
                  {/*/>*/}
                  Religion
                </label>
                <br />
                <select
                  style={{
                    borderTop: 0,
                    borderLeft: 0,
                    borderRight: 0,
                    boxShadow: "none",
                    width: "80%",
                  }}
                  onChange={(e) => onHandleTextChange(e)}
                  className={`custom-select form-control ${
                    errors.religion ? "is-invalid" : ""
                  }`}
                  value={stateData.religion}
                  name="religion"
                >
                  <option selected={true}>Religion...</option>
                  {General.religion.map((item, index) => (
                    <option value={item} key={index}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="md-form mb-0">
                <label for="email" class="">
                  {/*<i*/}
                  {/*style={{ marginRight: 4 }}*/}
                  {/*className="fas fa-university animated fadeIn"*/}
                  {/*/>*/}
                  Campus Name
                </label>
                <br />
                <select
                  style={{
                    borderTop: 0,
                    borderLeft: 0,
                    borderRight: 0,
                    boxShadow: "none",
                    width: "80%",
                  }}
                  onChange={(e) =>
                    onGeneralSelectChangeHandler(
                      e,
                      "campusName",
                      "campusId",
                      "id"
                    )
                  }
                  className={`custom-select form-control ${
                    errors.campusName ? "is-invalid" : ""
                  }`}
                >
                  {/* <option selected={true}>Campus Name...</option>
                        {this.props.campusData.map((item, index) => (
                          <option value={`${JSON.stringify(item)}`} key={index}>
                            {item.campusName}
                          </option>
                        ))} */}
                  <GeneralSelectionOptions
                    // section={stateData.section}
                    // className={stateData.className}
                    // gradeData={stateData.dummySample}
                    sectionType={"StudentAdmissionFormsForCampus"}
                    campusName={stateData.campusName}
                    campusData={this.props.campusData}
                  />
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="md-form mb-0">
                <label for="email" class="">
                  {/*<i*/}
                  {/*style={{ marginRight: 4 }}*/}
                  {/*className="fas fa-university animated fadeIn"*/}
                  {/*/>*/}
                  Class Name
                </label>
                <br />
                <select
                  style={{
                    borderTop: 0,
                    borderLeft: 0,
                    borderRight: 0,
                    boxShadow: "none",
                    width: "80%",
                  }}
                  onChange={(e) => onChangeClassName(e)}
                  className={`custom-select form-control ${
                    errors.className ? "is-invalid" : ""
                  }`}
                  name="className"
                  value={stateData.classId}
                >
                  <option selected={true}>Class Name...</option>

                  {this.props.ClassData &&
                    this.props.ClassData.classes.map((item, index) => (
                      <option value={item.classId} key={index}>
                        {item.className}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="md-form mb-0">
                <label for="name" class="">
                  <i
                    className="fas fa-house animated fadeIn"
                    style={{ marginRight: 4 }}
                  />
                  Grade Id
                </label>
                <input
                  readOnly={true}
                  style={{
                    borderTop: 0,
                    borderLeft: 0,
                    borderRight: 0,
                    boxShadow: "none",
                    width: "80%",
                  }}
                  value={stateData.gradeName}
                  type="text"
                  className="form-control"
                  id="exampleInputEmail1"
                  placeholder="Grade Id"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="md-form mb-0">
                <label for="email" class="">
                  {/*<i*/}
                  {/*style={{ marginRight: 4 }}*/}
                  {/*className="fas fa-university animated fadeIn"*/}
                  {/*/>*/}
                  Section
                </label>
                <br />
                <select
                  style={{
                    borderTop: 0,
                    borderLeft: 0,
                    borderRight: 0,
                    boxShadow: "none",
                    width: "80%",
                  }}
                  onChange={(e) => onHandleTextChange(e)}
                  className={`custom-select form-control ${
                    errors.section ? "is-invalid" : ""
                  }`}
                  name="section"
                  // value={stateData.section}
                >
                  <GeneralSelectionOptions
                    typeForm={this.props.typeForm}
                    sectionType={"StudentAdmissionForms"}
                    section={stateData.section}
                    classId={stateData.classId}
                    gradeData={stateData.dummySample}
                  />
                  } />
                </select>
              </div>
            </div>
            {/* <div class="col-md-6">
              <div class="md-form mb-0">
                <label for="name" class="">
                  <i
                    className="fas fa-house animated fadeIn"
                    style={{ marginRight: 4 }}
                  />
                  Username
                </label>
                <input
                  style={{
                    borderTop: 0,
                    borderLeft: 0,
                    borderRight: 0,
                    boxShadow: "none",
                    width: "80%",
                  }}
                  className={`form-control ${
                    errors.userName ? "is-invalid" : ""
                  }`}
                  onChange={(e) => onHandleTextChange(e)}
                  value={stateData.userName}
                  name="userName"
                  type="text"
                  class="form-control"
                  id="exampleInputEmail1"
                  placeholder="Username"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="md-form mb-0">
                <label for="name" class="">
                  <i
                    className="fas fa-house animated fadeIn"
                    style={{ marginRight: 4 }}
                  />
                  Password
                </label>
                <input
                  style={{
                    borderTop: 0,
                    borderLeft: 0,
                    borderRight: 0,
                    boxShadow: "none",
                    width: "80%",
                  }}
                  onChange={(e) => onHandleTextChange(e)}
                  value={stateData.password}
                  name="password"
                  type="password"
                  className={`form-control ${
                    errors.password ? "is-invalid" : ""
                  }`}
                  id="exampleInputEmail1"
                  placeholder="Password"
                />
              </div>
            </div> */}
            <div class="col-md-6">
              <div class="md-form mb-0">
                <label for="name" class="">
                  <i
                    className="fas fa-house animated fadeIn"
                    style={{ marginRight: 4 }}
                  />
                  Account Id
                </label>
                <input
                  style={{
                    borderTop: 0,
                    borderLeft: 0,
                    borderRight: 0,
                    boxShadow: "none",
                    width: "80%",
                  }}
                  readOnly={true}
                  value={stateData.accountId}
                  type="text"
                  class="form-control"
                  id="exampleInputEmail1"
                  placeholder="Account Id"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="md-form mb-0">
                <label for="name" class="">
                  <i
                    className="fas fa-house animated fadeIn"
                    style={{ marginRight: 4 }}
                  />
                  Account Type
                </label>
                <input
                  style={{
                    borderTop: 0,
                    borderLeft: 0,
                    borderRight: 0,
                    boxShadow: "none",
                    width: "80%",
                  }}
                  readOnly={true}
                  value={stateData.accountType}
                  type="text"
                  class="form-control"
                  id="exampleInputEmail1"
                  placeholder="Account Type"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="md-form mb-0">
                <label for="status" class="">
                  Account Status
                </label>
                <br />
                <select
                  style={{
                    borderTop: 0,
                    borderLeft: 0,
                    borderRight: 0,
                    boxShadow: "none",
                    width: "80%",
                  }}
                  onChange={(e) => onHandleTextChange(e)}
                  className={`custom-select form-control ${
                    errors.status ? "is-invalid" : ""
                  }`}
                  value={stateData.status}
                  name="status"
                >
                  <option selected={true}>Choose Status...</option>
                  {status.map((item, index) => (
                    <option value={item} key={index}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <br />
          <br />
          {/*<div class="text-center text-md-center">*/}
          {/*<button class="btns">Add</button>*/}
          {/*</div>*/}
        </form>
        <div class="status" />
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    ClassData: state.setClassSectionGradeReducer.Data,
  };
}
export default connect(mapStateToProps, null)(StudentAdmission);
