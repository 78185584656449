import React from 'react';
import Colors from '../../../utils/app_constants/colors_constants';
import CustomBadge from '../../CustomBadge/CustomBadge';

import ThemeContext from "../../../context/themeContext/ThemeContext";

export default function GraphLayoutForAcademicCalendar({
	children,
	titleName,
	titleIcon,
    scaleValue = 0,
    scaleName= '',
	graphList = false,
	onGraphChange
}) {
	return (
		<ThemeContext.Consumer>
                    {(context) => (
		<div
			className="lineGraph"
			style={{
				border: `3px solid ${context.themeColors.primaryColor}`
			}}
		>
			<CustomBadge
				fontSize={25}
				badgeText={titleName}
				badgeIconClass={titleIcon}
			/>
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<div
					style={{
						textAlign: 'right',
						color: `${context.themeColors.primaryColor}`,
						fontWeight: 'bold',
						marginLeft: 5,
						fontSize: 20
					}}
				>
					{scaleName}: {scaleValue}
				</div>
				{graphList ? (
					<div class="col-md-4">
						<div class="md-form mb-0">
							<select
								class="custom-select"
								name="post"
								style={{ backgroundColor: context.themeColors.primaryColor, color: 'white' }}
								onChange={e => onGraphChange(e)}
							>
								<option selected={true}>Select Graph..</option>
								<option value="Line">Line</option>
								<option value="Pie">Pie</option>
								<option value="Bar">Bar</option>
							</select>
						</div>
					</div>
				) : (
					''
				)}
			</div>
			{children()}
		</div>
		)}
		</ThemeContext.Consumer>
	);
}
