import React, { useEffect, useState, useContext } from 'react';
import $ from 'jquery';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import * as sharedActions from '../../Actions/sharedActions';
import general from '../../utils/general';
import moment from 'moment';
import Colors from '../../utils/app_constants/colors_constants';
import Spinner from '../../components/Spinner/Spinner';
import themeContext from "../../context/themeContext/ThemeContext";

const StaffTimeTable = props => {
    const themeColor = useContext(themeContext);

	const [isLoading, setIsLoading] = useState(true);
	const [timeTableData, setTimeTableData] = useState({});
	const [tHead, setTHead] = useState([]);
	const [renderFields, setRenderFields] = useState([]);
	const [periods, setPeriods] = useState([]);

	const [state, setState] = useState({
		today: moment(),

		from_date: '',
		to_date: ''
	});

	useEffect(() => {
		if (
			state['today'].format('dddd') !== 'Friday' &&
			state['today'].format('dddd') !== 'Saturday'
		) {
			const from_date = moment()
			.startOf('week');

			const to_date = moment()
				.endOf('week')
				.subtract(2, 'days');

			props.sharedActions
				.getDataWithoutDispatch(
					`/api/TimeTableForStaff/?userName=${
						props.name
					}&fromDate=${general.dateToFormat(
						from_date
					)}&toDate=${general.dateToFormat(to_date)}`
				)
				.then(data => {
					setIsLoading(true);
					setTimeTableData(data);
					setTHead(data.classes);
					setPeriods(data.periodsList);
					setIsLoading(false);
				})
				.catch(err => {
					setTHead([]);
					setIsLoading(false);
				});
		} else {


			const from_date = moment()
				.add(1, 'weeks')
				.startOf('week');
			const to_date = moment()
				.add(1, 'weeks')
				.endOf('week')
				.subtract(2, 'days');

			props.sharedActions
				.getDataWithoutDispatch(
					`/api/TimeTableForStaff/?userName=${
						props.name
					}&fromDate=${general.dateToFormat(
						from_date
					)}&toDate=${general.dateToFormat(to_date)}`
				)

			
				.then(data => {
					console.log(from_date,'  -   ',from_date,'date')
					setIsLoading(true);
					setTimeTableData(data);
					setTHead(data.classes);
					setPeriods(data.periodsList);

					setIsLoading(false);
				})
				.catch(err => {
					console.log(from_date,'  -   ',from_date,'date')
					setIsLoading(false);
				});
		}
	}, []);

	const PrintContent = el => {
		var restorepage = document.body.innerHTML;
		var Printcontent = document.getElementById(el).innerHTML;
		document.body.innerHTML = Printcontent;
		window.print();
		document.body.innerHTML = restorepage;
	};

	const bodyRendering = data =>
		data &&
		data.map((item, index) => (
			<tr className="vendorListHeading">
				<th
					className="vendorListHeading"
					style={{
						padding: 2,
						border: `1px solid ${themeColor.themeColors.primaryColor}`,
						borderColor: themeColor.themeColors.primaryColor,
						textAlign: 'center',
						verticalAlign: 'middle',
						background: '#A9A9A9',
						color: 'white'
					}}
					scope="row"
				>
					<div>
						{item.dayName}
						<br />
						{item.date}
					</div>
				</th>
				{item.periods.map((item1, index) => (
					<td
						height="140px"
						width={`${80 / tHead.length}%`}
						style={
							item1.periodNames.length < 1
								? {
										fontWeight: 'bold',
										border: `1px solid white`,
										padding: 1,
										borderColor: themeColor.themeColors.primaryColor,
										textAlign: 'center',
										verticalAlign: 'middle',
										color: 'white'
								  }
								: {
										fontWeight: 'bold',
										border: `1px solid white`,
										padding: 1,
										borderColor: 'white',
										textAlign: 'center',
										verticalAlign: 'middle',
										color: 'white'
								  }
						}
					>
						{item1.periodNames.length < 1 ? (
							<div
								style={{
									textAlign: 'center',
									verticalAlign: 'middle',
									fontWeight: 'bold',
									color: themeColor.themeColors.primaryColor,
									height: '103%'
								}}
							>
								<br />
								<strong>No Slots</strong>
							</div>
						) : (
							<div
								style={{
									textAlign: 'center',
									verticalAlign: 'middle',
									height: '103%',
									fontWeight: 'bold',
									backgroundColor:
										general.timeTableColors[
											Math.floor(
												Math.random() *
													Math.floor(general.timeTableColors.length)
											)
										],
									color: 'white'
								}}
							>
								<br />
								{item1.periodNames.map((item2, index) => (
									<strong>{`${item2.name} `}</strong>
								))}
								<strong>{item1.type}</strong>
								<br />
								<strong>{item1.subjectName}</strong>
							</div>
						)}
					</td>
				))}
			</tr>
		));

	const TimeTableView = (
		<div className="table-responsive">
			<table
				class="table table-hover text-center animated slideInUp table-bordered"
				style={{
					borderCollapse: 'separate',
					borderSpacing: '0 15px',
					fontSize: '22px'
				}}
			>
				<thead style={{ background: '#A9A9A9', color: 'white' }}>
					<tr className="vendorListHeading">
						<th scope="col">
							{' '}
							<i class="fas fa-calendar-day" style={{ color: 'white' }}></i>
							{'   Days'}
						</th>
						{tHead.map(item => (
							<th
								style={{
									border: `1px solid ${themeColor.themeColors.primaryColor}`,
									borderColor: Colors.WHITE
								}}
								scope="col"
							>
								<i
									class="fas fa-chalkboard-teacher"
									style={{ color: 'white' }}
								></i>
								{`    ${item.className}-${item.section}`}
							</th>
						))}
					</tr>
				</thead>
				<tbody>{bodyRendering(periods)}</tbody>
			</table>
		</div>
	);

	return (
		<React.Fragment>
			<div id="div1">
				<div class="container">
					<div class="divclass">
						<img
							src={require('../../Assets/images/header.png')}
							style={{height: 250}}
							alt="Logo Banner"
						/>
					</div>
					<div class="col-md-12">
						<div>
							<h2 className="h1-responsive font-weight-bold text-center my-4 generalHead" style={{background: themeColor.themeColors.primaryColor}}>
								Staff Timetable{' '}
								<strong style={{ textDecoration: 'underline' }}>
									{timeTableData.staffName}
								</strong>
							</h2>
						</div>
					</div>
					{isLoading ? (
						<Spinner />
					) : tHead < 1 ? (
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								color: 'red'
							}}
						>
							<strong>No Data</strong>
						</div>
					) : (
						TimeTableView
					)}

					<div class="page-footer">
						<div class="text-left">Printed By: {props.name}</div>
						<div class="text-right" style={{ marginTop: -25, marginRight: 50 }}>
							Powered by School Smart®
						</div>
					</div>

					<div class="text-center text-md-right displayElemet">
						<label for="name" class="" onClick={() => PrintContent('div1')}>
							<i
								className="fas fa-print fa-2x animated fadeIn"
								style={{ marginRight: 4 }}
							/>
							Print
						</label>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

function mapStateToProps(state) {
	return {
		role: state.setUserReducer.users.role,
		name: state.setUserReducer.users.unique_name
	};
}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch)
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(StaffTimeTable);
