import React, { Component } from "react";
import DateandTime from "../Transport/DateandTime";
import * as sharedActions from "../../Actions/sharedActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import SnackBar from "../../components/SnackBar/SnackBar";
import LoaderWrapper from "../../components/HOC/LoaderWrapper";
import ThemeContext from "../../context/themeContext/ThemeContext";

class AddEntryExam extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      classes: [],
      backColor: "",
      session: [],
      sessionId: "",
      name: "",
      fatherName: "",
      classId: "",
      sessionId: "",
      contactNo: "",
      email: "",
      errorMsg: false,
      msg: false,
    };
  }

  onClickDeleteItem = (arrName) => {
    let getStateArr = this.state[`${arrName}`];
    let getDeleteLength = getStateArr.length - 1;
    if (getDeleteLength > 0) {
      let getNewDetails = getStateArr.filter(
        (item, index) => index !== getDeleteLength
      );
      console.log(getNewDetails);
      this.setState({
        [arrName]: [...getNewDetails],
      });
    }
  };

  onClickAddItem = (arrName, obj) => {
    let getStateArr = this.state[`${arrName}`];
    getStateArr = [...getStateArr, obj];
    console.log(arrName);
    this.setState({
      [arrName]: getStateArr,
    });
  };
  updateSpecialArrObjOnChange = (e, index, arrName) => {
    const changeArrOfObj = this.state[`${arrName}`];
    changeArrOfObj[index][e.target.name] = e.target.value;
    this.setState({
      [arrName]: changeArrOfObj,
    });
  };
  onChangeText = (e) => {
    console.log(e.target.value);
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onHandleTextChange = (e) => {
    console.log(e.target.value);
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onHandleSubmit = (e) => {
    e.preventDefault();
    const {
      name,
      fatherName,
      email,
      contactNo,
      classId,
      sessionId,
    } = this.state;
    if (
      Object.is(sessionId, "") ||
      Object.is(name, "") ||
      Object.is(fatherName, "") ||
      Object.is(email, "") ||
      Object.is(classId, "") ||
      Object.is(contactNo, "")
    ) {
      this.setState({
        errorMsg: true,
      });
    } else {
      const data = {
        name,
        fatherName,
        contactNo,
        email,
        sessionId,
        classId,
        accountId: this.props.name,
        accountType: this.props.role,
      };
      this.props.sharedActions
        .simpleAddRequest("/api/EntryExam", data)
        .then((success) => {
          this.setState({
            errorMsg: false,
            name: "",
            email: "",
            contactNo: "",
            sessionId: "",
            classId: "",
            fatherName: "",
            msg: "Candidate Added Successfully",
            backColor: "#01ac8a",
          });
          this.props.snackbar();
        })
        .catch((error) => {
          this.setState({
            msg: error.response.data.Message,
            backColor: "red",
          });
          this.props.snackbar();
        });
    }
    console.log(this.state);
  };
  componentDidMount() {
    this.props.sharedActions
      .getDataWithoutDispatch("/api/Session")
      .then((success) => {
        debugger;
        this.setState({
          session: success.session,
        });
      })
      .catch((err) => {
        this.setState({});
        console.log(err);
      });

    this.props.sharedActions
      .getDataWithoutDispatch("/api/ClassSection")
      .then((success) => {
        debugger;
        this.setState({
          classes: success.classList,
          isLoading: false,
        });
      })
      .catch((err) => {
        this.setState({});
        console.log(err);
      });
  }

  componentDidUpdate() {
    if (this.state.msg || this.state.errorMsg) {
      setTimeout(() => {
        this.setState({
          msg: false,
          errorMsg: false,
        });
      }, 3000);
    }
  }

  onSessionChange = (e) => {
    this.setState({
      sessionId: e.target.value,
    });
  };
  onClassChange = (e) => {
    this.setState({
      classId: e.target.value,
    });
  };

  render() {
    return (
      <div>
        <section class="mb-4">
          <ThemeContext.Consumer>
            {(context) => (
              <h2
                style={{ background: context.themeColors.primaryColor }}
                class="h1-responsive font-weight-bold text-center my-4 generalHead"
              >
                Add Candidate
              </h2>
            )}
          </ThemeContext.Consumer>

          <SnackBar msg={this.state.msg} backColor={this.state.backColor} />
          <p class="text-center w-responsive mx-auto mb-5" />

          <div
            class="row"
            style={{
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <ThemeContext.Consumer>
              {(context) => (
                <div
                  class="col-md-9 mb-md-0 mb-5"
                  style={{
                    border: "solid",
                    borderColor: context.themeColors.primaryColor,
                    padding: 40,
                    borderRadius: 15,
                    width: "auto",
                  }}
                >
                  <LoaderWrapper isLoading={this.state.isLoading}>
                    <form id="contact-form" name="contact-form">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="md-form mb-0">
                            <DateandTime />
                          </div>
                        </div>
                      </div>
                      <br />
                      <div class="row">
                        <div class="col-md-6" style={{ marginTop: 20 }}>
                          <div class="md-form mb-0">
                            <label for="name" class="font-weight-bold">
                              Admin Name
                            </label>
                            <input
                              value={this.props.name}
                              readOnly={true}
                              type="text"
                              name="AdminName"
                              class="form-control"
                              onChange={this.onHandleTextChange}
                            />
                          </div>
                        </div>

                        <div class="col-md-6" style={{ marginTop: 20 }}>
                          <div class="md-form mb-0">
                            <label for="name" class="font-weight-bold">
                              Admin Type
                            </label>
                            <input
                              value={this.props.role}
                              readOnly={true}
                              type="text"
                              name="AdminType"
                              class="form-control"
                              onChange={this.onHandleTextChange}
                            />
                          </div>
                        </div>
                        <div class="col-md-6" style={{ marginTop: 20 }}>
                          <div class="md-form mb-0">
                            <label for="name" class="font-weight-bold">
                              Name
                            </label>
                            <input
                              placeholder="Add Name"
                              type="text"
                              name="name"
                              class="form-control"
                              value={this.state.name}
                              onChange={this.onHandleTextChange}
                            />
                          </div>
                        </div>

                        <div class="col-md-6" style={{ marginTop: 20 }}>
                          <div class="md-form mb-0">
                            <label for="name" class="font-weight-bold">
                              Father Name
                            </label>
                            <input
                              placeholder="Add Father Name"
                              type="text"
                              name="fatherName"
                              class="form-control"
                              value={this.state.fatherName}
                              onChange={this.onHandleTextChange}
                            />
                          </div>
                        </div>
                        <div class="col-md-6" style={{ marginTop: 20 }}>
                          <div class="md-form mb-0">
                            <label class="font-weight-bold">Class</label>
                            <select
                              onChange={this.onClassChange}
                              class="custom-select"
                              name="sessionId"
                            >
                              <option selected={this.state.msg ? true : false}>
                                Select Class..
                              </option>
                              {this.state.classes &&
                                this.state.classes.map((item, index) => (
                                  <option key={index} value={`${item.id}`}>
                                    {item.className}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                        <div class="col-md-6" style={{ marginTop: 20 }}>
                          <div class="md-form mb-0">
                            <label class="font-weight-bold">Session</label>
                            <select
                              onChange={this.onSessionChange}
                              class="custom-select"
                              name="sessionId"
                            >
                              <option selected={this.state.msg ? true : false}>
                                Select Session..
                              </option>
                              {this.state.session &&
                                this.state.session.map((item, index) => (
                                  <option
                                    key={index}
                                    value={`${item.sessionId}`}
                                  >
                                    {item.currentSession}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                        <div class="col-md-6" style={{ marginTop: 20 }}>
                          <div class="md-form mb-0">
                            <label for="email" class="font-weight-bold">
                              Contact No
                            </label>
                            <input
                              placeholder="Enter Contact No"
                              type="number"
                              name="contactNo"
                              value={this.state.contactNo}
                              className="form-control check"
                              onChange={this.onHandleTextChange}
                            />
                          </div>
                        </div>
                        <div class="col-md-6" style={{ marginTop: 20 }}>
                          <div class="md-form mb-0">
                            <label for="email" class="font-weight-bold">
                              Email
                            </label>
                            <input
                              placeholder="Enter Email"
                              type="email"
                              name="email"
                              value={this.state.email}
                              className="form-control check"
                              onChange={this.onHandleTextChange}
                            />
                          </div>
                        </div>
                      </div>
                      <br />
                      <br />
                      {this.state.errorMsg ? (
                        <p style={{ color: "red", textAlign: "center" }}>
                          Please fill the form properly
                        </p>
                      ) : (
                        ""
                      )}
                      <div class="text-right text-md-right">
                        <button
                          style={{
                            background: context.themeColors.primaryColor,
                          }}
                          onClick={this.onHandleSubmit}
                          class="btns"
                        >
                          Add
                        </button>
                      </div>
                    </form>
                  </LoaderWrapper>
                  <div class="status" />
                </div>
              )}
            </ThemeContext.Consumer>
          </div>
        </section>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    sharedActions: bindActionCreators(sharedActions, dispatch),
  };
}
function mapStateToProps(state) {
  return {
    auth: state.setUserReducer.auth,
    role: state.setUserReducer.users.role,
    name: state.setUserReducer.users.unique_name,
    accountId: state.setUserReducer.users.accountId,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(AddEntryExam);
