import * as actionTypes from '../actionTypes';

export function setAllHomeWorkInRedux(allData) {
	return {
		type: actionTypes.GET_ALL_HOMEWORK,
		payload: allData
	};
}

export function getClassesForHomeWork(allData) {
	return {
		type: actionTypes.SET_ALL_CLASS_NAME_INTO_HOMEWORK_SELECT,
		payload: allData
	};
}
export function getSessionForHomeWork(allData) {
	return {
		type: actionTypes.SET_ALL_SESSION_NAME_INTO_HOMEWORK_SELECT,
		payload: allData
	};
}
export function addHomeWork(Data) {
	return {
		type: actionTypes.ADD_HOMEWORK,
		payload: Data
	};
}
export function updateHomeWorkFlag(setFlag) {
	return {
		type: actionTypes.SET_LOADER_FLAG_HOMEWORK,
		payload: setFlag
	};
}
export function updateHomeWork(data) {
	return {
		type: actionTypes.EDIT_HOMEWORK_RECORD,
		payload: data
	};
}
export function deleteHomeWork(id) {
	return {
		type: actionTypes.DELETE_HOMEWORK,
		payload: id
	};
}
