import React, { useEffect, useState, useContext } from 'react';
import $ from 'jquery';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import SelectionInput from '../../components/SelectionInput/SelectionInput';
import Pagination from '../../components/Pagination/Pagination';
import Spinner from '../../components/Spinner/Spinner';
import httpWithRequiredSetData from '../../hooks/Services/httpWithRequiredSetData';
import general from '../../utils/general';
import Colors from '../../utils/app_constants/colors_constants';
import CustomFilterSectionLayout from '../../components/CustomFilterSectionLayout/CustomFilterSectionLayout';
import TextInput from '../../components/TextInput/TextInput';
import { Link } from 'react-router-dom';
import themeContext from "../../context/themeContext/ThemeContext";

let tHead = ['Staff Name', 'Post For', 'Post Name', 'Actions'];

let renderFields = ['name', 'postFor', 'postName'];

const BioMetricRegistered = props => {
    const themeColor = useContext(themeContext);

	const [registeredData, setRegisteredData] = useState([]);
	const [search, setSearch] = useState('');
	const [staffId, setStaffId] = useState('');
	const [id, setId] = useState(null);

	const [searchURL, setSearchURL] = useState(
		`/api/BioMetricRegisteredStaffList/?staffId=&pageNumber=1&pageSize=10`
	);

	const [disableBPage, setdisableBPage] = useState(true);
	const [customPageSize, setCustomPageSize] = useState('10');
	const [currentPage, setCurrentPage] = useState(1);
	const [pageNumber, setPageNumber] = useState([]);
	const [disablepage, setdisablepage] = useState(false);
	const [pagination, setPaginationData] = useState({});

	const [
		isLoading,
		fetchData,
		setFetchData,
		setIsLoading
	] = httpWithRequiredSetData(searchURL, [], data => {
		let pageNumber = [];
		for (let i = 1; i <= data['paginationMetadata']['totalPages']; i++) {
			pageNumber.push(i);
		}
		setPageNumber(pageNumber);
		setRegisteredData(data['registerStaffList']);

		setPaginationData(data['paginationMetadata']);
	});

	const handleClickNextFrwd = () => {
		console.log('pagelength: ', pageNumber.length);
		console.log('current page: ', currentPage);
		if (currentPage === pageNumber.length) {
			setdisablepage(true);
		} else {
			setCurrentPage(prevState => prevState + 1);
			console.log(currentPage);

			setdisableBPage(false);
		}
	};

	const onHandleStaffId = e => {
		setStaffId(e.target.value);
	};

	useEffect(() => {
		console.log('Do something after counter has changed', currentPage);
		setIsLoading(true);
		props.sharedActions
			.getDataWithoutDispatch(
				general.condtionalServicesBioMetricRegistered(
					staffId,
					currentPage,
					customPageSize
				)
			)
			.then(success => {
				setRegisteredData(success['registerStaffList']);
				setIsLoading(false);
				let pageNumber = [];
				for (let i = 1; i <= success['paginationMetadata']['totalPages']; i++) {
					pageNumber.push(i);
				}
				setPageNumber(pageNumber);
				setPaginationData(success['paginationMetadata']);
			})
			.catch(error => {
				setIsLoading(false);
				setRegisteredData([]);
			});
	}, [currentPage, staffId, customPageSize]);

	const handleClickNext = event => {
		setCurrentPage(Number(event.target.id));
		setdisableBPage(false);
		setdisableBPage(false);
	};
	const handleClickNextBack = () => {
		if (currentPage === 1) {
			setdisableBPage(true);
			setdisablepage(false);
		} else {
			setCurrentPage(prevState => prevState - 1);
		}
	};
	const [msg, setSnackMsg] = useState('');

	const onChangePageSize = e => {
		if (e.target.value !== 'Page Entries') {
			setCustomPageSize(e.target.value);
			setCurrentPage(1);
		}
	};

	const onEditClick = id => {
		onTakeItem(id);
	};

	const onTakeItem = itemId => {
		let getData = registeredData.filter(item => item.staffId === itemId);

		setId(getData[0].staffId);
	};

	const onClickDelete = () => {
		setIsLoading(true);

		props.sharedActions
			.deleteRecordWithoutDispatchWithoutSlash(
				`/api/DeleteBioMetricStaff/?staffId=`,
				id
			)
			.then(success => {
				// if (success.status === 200) {
				let getNewFilterArray = registeredData.filter(
					item => item.staffId !== id
				);
				setSnackMsg('Remove Successfully!');
				setRegisteredData(getNewFilterArray);
				setIsLoading(false);
				props.snackbar();
				setId(null);
				// }
			})
			.catch(error => {
				setIsLoading(false);
				console.log(error);
			});
	};

	const bodyRendering = data =>
		data &&
		data.map((item, index) => (
			<tr>
				<th
					style={{
						padding: 2,
						border: `1px solid ${themeColor.themeColors.primaryColor}`,
						borderColor: themeColor.themeColors.primaryColor
					}}
					scope="row"
				>
					{index + 1}
				</th>
				{renderFields.map((item1, index) => (
					<td
						style={{
							fontWeight: `${index == 0 ? 'bold' : 'normal'}`,
							border: `1px solid ${themeColor.themeColors.primaryColor}`,
							padding: 2,
							borderColor: themeColor.themeColors.primaryColor
						}}
					>
						{item[item1]}
					</td>
				))}
				<td
					style={{
						border: `1px solid ${themeColor.themeColors.primaryColor}`,
						padding: 2,
						borderColor: themeColor.themeColors.primaryColor
					}}
				>
					<a href="#" data-toggle="tooltip" title="Delete!">
						<button
							onClick={() => onEditClick(item.staffId)}
							data-toggle="modal"
							data-target="#myModal1"
							style={{
								cursor: 'pointer',
								fontSize: 20,
								border: 'none',
								background: 'none',
								marginLeft: 5,
								marginBottom: 5
							}}
						>
							<i style={{ color: '#D11A2A' }} class="fas fa-trash-alt"></i>
						</button>
					</a>
				</td>
			</tr>
		));

	const SearchByStudentId = (
		<React.Fragment>
			<TextInput
				feildName={'staffId'}
				inputLabelName={'Staff ID'}
				onHandleChange={onHandleStaffId}
				errors={{}}
				stateData={{
					staffId: staffId
				}}
				placeholder={'Staff ID'}
				isValidte={false}
				customWidth={true}
				enterWidth={'100%'}
			/>
		</React.Fragment>
	);

	return (
		<div>
			<div class="modal" id="myModal1">
				<div class="modal-dialog">
					<div class="modal-content modal_content_custom">
						<div
							style={{
								borderBottomLeftRadius: 30,
								borderBottomRightRadius: 30,
								borderTopLeftRadius: 18,
								borderTopRightRadius: 18,
								background: themeColor.themeColors.primaryColor
							}}
							class="modal-header modal_custom_header"
						>
							<h4 style={{ color: 'white' }} class="modal-title">
								Confirm
							</h4>
							<button
								style={{ color: 'white' }}
								type="button"
								class="close"
								data-dismiss="modal"
							>
								&times;
							</button>
						</div>
						<div class="modal-body">
							<div>
								<div style={{ textAlign: 'center' }}>
									<h4>Are you sure ?</h4>
								</div>
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-evenly'
									}}
								>
									<div>
										<button
											// disabled={this.state.enabled}
											data-dismiss="modal"
											onClick={() => onClickDelete(id)}
											type="button"
											class="btn btn-primary buttonAppear"
											style={{background: themeColor.themeColors.primaryColor}}
										>
											Yes
										</button>
									</div>
									<div>
										<button
											data-dismiss="modal"
											// disabled={this.state.enabled}
											type="button"
											class="btn btn-primary buttonAppear"
											style={{background: themeColor.themeColors.primaryColor}}
										>
											No
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<SnackBar msg={msg} />
			<div style={{ textAlign: 'right' }}>
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<div>
						<h2 className="h1-responsive font-weight-bold text-left my-4 generalHead" style={{background: themeColor.themeColors.primaryColor}}>
							{' '}
							Bio Metric Registered Staff{' '}
						</h2>
					</div>
					<div
						style={{ marginTop: 40, display: 'flex', flexDirection: 'column' }}
					>
						<Pagination
							disableBPage={disableBPage}
							disablepage={disablepage}
							currentPage={currentPage}
							pageNumber={pageNumber}
							handleClickNext={handleClickNext}
							handleClickNextBack={handleClickNextBack}
							handleClickNextFrwd={handleClickNextFrwd}
						/>
						<div
							style={{
								width: 'fit-content',
								marginTop: -34,
								display: 'flex',
								justifyContent: 'flex-end',
								marginLeft: 'auto'
							}}
						>
							<SelectionInput
								selectName="Entries"
								selectFont={14}
								selectFontWeight={'bold'}
								newFlag="customWidth"
								feildName="customPageSize"
								selectedText={'Page Entries'}
								optionsArrys={['10', '20', '30', '40', '50']}
								errors={{}}
								isValidte={false}
								stateData={{
									customPageSize: customPageSize
								}}
								optionType="static"
								onHandleChange={onChangePageSize}
								useFlag={false}
							/>
						</div>
					</div>
				</div>
				<CustomFilterSectionLayout displayDirection="column">
					<div
						style={{
							width: '27%',
							margin: '0 auto',
							display: 'flex',
							justifyContent: 'center'
						}}
					>
						{SearchByStudentId}
					</div>
				</CustomFilterSectionLayout>

				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<span className="customBadge" style={{ fontSize: 14 }}>
						Page: {currentPage}
					</span>

					<span className="customBadge" style={{ fontSize: 14 }}>
						Total Page: {pagination['totalPages']}
					</span>
				</div>
			</div>
			{isLoading ? (
				<Spinner />
			) : registeredData.length < 1 ? (
				<div
					style={{ display: 'flex', justifyContent: 'center', color: 'red' }}
				>
					<strong>No Data</strong>
				</div>
			) : (
				<div className="table-responsive">
					<table class="table table-hover">
						<thead style={{ background: themeColor.themeColors.primaryColor, color: 'white' }}>
							<tr>
								<th scope="col">#</th>
								{tHead.map(item => (
									<th
										style={{
											border: `1px solid ${themeColor.themeColors.primaryColor}`,
											borderColor: Colors.WHITE
										}}
										scope="col"
									>
										{item}
									</th>
								))}
							</tr>
						</thead>
						<tbody>{bodyRendering(registeredData)}</tbody>
					</table>
				</div>
			)}
			<div class="text-md-right">
				<Link
					to={{
						pathname: '/dashboard/PrintBioMetricRegistered/',
						state: { registeredData }
					}}
				>
					<a href="#" data-toggle="tooltip" title="Go To Print!">
						<button
							style={{
								cursor: 'pointer',
								fontSize: 15,
								border: 'none',
								marginLeft: 5,
								background: 'none',
								marginBottom: 5
							}}
							disabled={registeredData.length < 1 ? true : false}
						>
							Go To Print {''}
							<i className="fas fa-print fa-2x animated fadeIn"></i>
						</button>
					</a>
				</Link>
			</div>
		</div>
	);
};

function mapStateToProps(state) {
	return {
		role: state.setUserReducer.users.role,
		name: state.setUserReducer.users.unique_name
	};
}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch)
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(BioMetricRegistered);
