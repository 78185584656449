import React from 'react';
import $ from 'jquery'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import Spinner from '../../components/Spinner/Spinner';
import ThemeContext from "../../context/themeContext/ThemeContext";
class MaintenanceReport extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            report: {},
            isLoading: true
        }
    }
    componentDidMount() {
        const { match } = this.props;
        $(document).ready(function () {
            $('[data-toggle="tooltip"]').tooltip();
        });
        this.props.sharedActions
            .getDataWithoutDispatch(
                `/api/TransportMaintenance/${match.params.id}`
            )
            .then(success => {
                console.log(success);
                this.setState({
                    report: success,
                    isLoading: false
                })
            })
            .catch(error => {
                this.setState({
                    isLoading: false
                });
                console.log(error);
            });
    }

    PrintContent = el => {
        var restorepage = document.body.innerHTML;
        var Printcontent = document.getElementById(el).innerHTML;
        document.body.innerHTML = Printcontent;
        window.print();
        document.body.innerHTML = restorepage;
    };
    render() {
        const { isLoading, report } = this.state;
        const voucher = (
            <ThemeContext.Consumer>
            {(context) => (
            <section class='mb-4'>
                <div class="divclass">
                    <img src={require('../../Assets/images/header.png')} alt='Logo Banner'
                    style={{height: 250}}
                    />
                </div>
                <div class='row text-center' style={{ flexGrow: 1, borderBottom: 5, borderRight: 0, borderLeft: 0, borderStyle: 'solid', borderColor: context.themeColors.primaryColor, margin: '5px' }}>
                    <div class='col-md-12 print'>
                        <h2 style={{
                            backgroundColor: context.themeColors.primaryColor,
                            textAlign: 'center',
                            color: 'white'
                        }}
                            className="h1-responsive font-weight-bold text-center my-4 generalHead">
                            {' '}
                            Maintenance Report{' '}
                        </h2>
                    </div>
                </div>
                <br />
                <div class="card">
                    <div class="card-body">
                        <div class='row'>
                            <div class='row text-center col-md-12' style={{ flexGrow: 1, borderBottom: 5, borderRight: 0, borderLeft: 0, borderStyle: 'solid', borderColor: context.themeColors.primaryColor, margin: '5px' }}>
                                <div class='col-md-4'>
                                    <button
                                        class='btn'
                                        style={{ backgroundColor: '#d9534f', borderRadius: "100%", height: "32px", width: '32px', marginLeft: 15, margin: 5 }}
                                    >
                                    </button>
                                    <strong style={{ fontSize: 16 }}> Below Average </strong>
                                </div>
                                <div class='col-md-4'>
                                    <button
                                        class='btn'
                                        style={{ backgroundColor: '#f0ad4e', borderRadius: "100%", height: "32px", width: '32px', marginLeft: 15, margin: 5 }}

                                    >                                 &nbsp;
                </button>
                                    <strong style={{ fontSize: 16 }}> Average </strong>

                                </div>
                                <div class='col-md-4'>
                                    <button
                                        class='btn'
                                        style={{ backgroundColor: '#5cb85c', borderRadius: "100%", height: "32px", width: '32px', marginLeft: 15, margin: 5 }}
                                    >                                 &nbsp;

                </button>
                                    <strong style={{ fontSize: 16 }}> Good </strong>
                                </div>
                            </div>
                            <div class='col-md-6' style={{ padding: 40 }}>
                                <strong style={{ fontSize: 22 }}>Driver Name: </strong>&nbsp;
								<label
                                    for="name"
                                    class=""
                                    style={{ fontWeight: 'bold', fontSize: 22, color: '#01425e' }}
                                >{report.driverName}</label>
                            </div>
                            <div class='col-md-6' style={{ padding: 40 }}>
                                <strong style={{ fontSize: 22 }}>Vehicle No: </strong>&nbsp;
								<label
                                    for="name"
                                    class=""
                                    style={{ fontWeight: 'bold', fontSize: 22, color: '#01425e' }}
                                >{report.vehicleNo}</label>
                            </div>
                            <div class='col-md-6' style={{ padding: 40 }}>
                                <strong style={{ fontSize: 22 }}>Month: </strong>&nbsp;
								<label
                                    for="name"
                                    class=""
                                    style={{ fontWeight: 'bold', fontSize: 22, color: '#01425e' }}
                                >{report.month}</label>
                            </div>
                            <div class='col-md-6' style={{ padding: 40 }}>
                                <strong style={{ fontSize: 22 }}>Date: </strong>&nbsp;
								<label
                                    for="name"
                                    class=""
                                    style={{ fontWeight: 'bold', fontSize: 22, color: '#01425e' }}
                                >{report.date}</label>
                            </div>
                            <div class='col-md-6' style={{ padding: 40 }}>
                                <strong style={{ fontSize: 22 }}>Maintenance: </strong>&nbsp;
								<label
                                    for="name"
                                    class=""
                                    style={{
                                        fontWeight: 'bold', fontSize: 22, color: parseInt(report.maintenance) <= 2 ? '#d9534f'
                                            : parseInt(report.maintenance) === 3 ? '#f0ad4e'
                                                : parseInt(report.maintenance) > 3 ? '#5cb85c'
                                                    : ''
                                    }}

                                > {report.maintenance}</label>
                            </div>
                            <div class='col-md-6' style={{ padding: 40 }}>
                                <strong style={{ fontSize: 22 }}>Driver Issue: </strong>&nbsp;
								<label
                                    for="name"
                                    class=""
                                    style={{ fontWeight: 'bold', fontSize: 22, color: '#01425e' }}
                                >{report.driverIssue}</label>
                            </div>
                            <div class='col-md-6' style={{ padding: 40 }}>
                                <strong style={{ fontSize: 22 }}>Other Details: </strong>&nbsp;
								<label
                                    for="name"
                                    class=""
                                    style={{ fontWeight: 'bold', fontSize: 22, color: '#01425e' }}
                                >{report.otherDetails}</label>
                            </div>
                        </div>
                        <div class='row text-center' style={{ marginTop: 40, alignItems: 'center', justifyContent: 'center' }}>
                            <div class='col-md-6'>
                                <strong style={{ fontSize: 22 }}>Created By: </strong>&nbsp;
								<label
                                    for="name"
                                    class=""
                                    style={{ fontWeight: 'bold', fontSize: 22, color: '#01425e' }}
                                >{report.accountId}</label>
                            </div>
                            <div class='col-md-6'>
                                <strong style={{ fontSize: 22 }}>Account: </strong>&nbsp;
								<label
                                    for="name"
                                    class=""
                                    style={{ fontWeight: 'bold', fontSize: 22, color: '#01425e' }}
                                >{report.accountType}</label>
                            </div>
                        </div>
                        <div class='row text-center' style={{ marginTop: 100, alignItems: 'center', justifyContent: 'center' }}>
                            <h4 style={{ textDecoration: 'overline' }}>Signature</h4>
                        </div>
                    </div>
                </div>
            </section >
                                                            )}
                                                            </ThemeContext.Consumer>
        )
        return (
            <div>
                <div id='div1'>
                    <div class='container'>
                        {isLoading ? <Spinner /> : voucher}
                    </div>
                    <div id="footer">
                        <div class="h1-responsive font-weight-bold text-center my-4">
                            <img
                                src={require('../../Assets/images/Powerd-01.svg')}
                                style={{ width: 200 }}
                                alt='Powered BY'
                            />
                        </div>
                    </div>
                </div>

                <div class="text-center text-md-right">
                    <label for="name" class="" onClick={() => this.PrintContent('div1')}>
                        <i
                            className="fas fa-print fa-2x animated fadeIn"
                            style={{ marginRight: 4 }}
                        />
                        Print
                            </label>
                </div>

            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        sharedActions: bindActionCreators(sharedActions, dispatch)
    };
}

export default connect(null, mapDispatchToProps)(MaintenanceReport);