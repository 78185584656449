import * as actionTypes from '../Actions/actionTypes';

const defaultState = {
	valid: false,
	getAddData: [],
	paginationDetails: null,
	getSingleFormData: null,
	getCurrenSession: null,
	getData: null,
	getImageData: null,
	getStudentDocs: [],
	getParentDocs: []
};

export default function setStaffFormReducer(state = defaultState, action = {}) {
	switch (action.type) {
		case actionTypes.GET_INDIVIDUAL_STAFF_DATA:
			return {
				...state,
				getSingleFormData: {
					...action.payload
				}
			};

		default:
			return state;
	}
}
