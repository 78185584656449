import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as SkillAction from "../../Actions/StudentSkill";
import * as sharedActions from "../../Actions/sharedActions";
import SnackBar from "../../components/SnackBar/SnackBar";
import { Link } from "react-router-dom";
import { addDataMiddleware } from "../../utils/general/index";
import $ from "jquery";
import ViewComponent from "../../components/ViewComponent/ViewComponent";
import ThemeContext from "../../context/themeContext/ThemeContext";

class StudentFeeHistoryP extends Component {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);
    this.state = {
      SkillReports: [],
      checking: [],
      adminData: [],
      studentNames: [],
      studentUserName: "",
      studentId: "",
      modalData: null,
      vehicleNo: "",
      capacity: "",
      transportType: "",
      enabled: false,
      id: null,
      select: [],
      efforts: "",
      participation: "",
      average: "",
      academicProgress: "",
      studentName: "",
      isLoading: true,
      disablepage: false,
      disableBPage: true,
      currentPage: 1,
      todosPerPage: 10,
      pageCounter: 10,
      msg: "",
      errorMsg: "",
      increament: 1,
      selectClassData: "",
      classId: "",
      sectionId: "",
      sections: [],
      subjects: [],
      session: [],
      sessionId: "",
      subjectId: "",
      staffId: "",
      term: "",
      btnLoad: false,
      exist: false,
      checking: [],
      searchCheck: true,
      Loading: true,
      multiple: [],
    };
  }

  componentDidUpdate() {
    $('[data-toggle="tooltip"]').tooltip("hide");

    if (this.state.msg || this.state.errorMsg || this.state.exist) {
      setTimeout(() => {
        this.setState({
          msg: false,
          errorMsg: false,
          exist: false,
        });
      }, 3000);
    }
  }
  componentDidMount() {
    $(document).ready(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });
    this.props.sharedActions
      .getDataWithoutDispatch(
        `/api/GetChildByParent?userName=${this.props.users.unique_name}`
      )
      .then((success) => {
        this.setState({
          studentNames: success,
          searchCheck: false,
        });
      })
      .catch((error) => {
        this.setState({
          searchCheck: true,
        });
        console.log(error);
      });
  }

  btnTechHover = () => {
    $(document).ready(function () {
      $("button").hover(
        function () {
          $(this).css("box-shadow", "0px 5px 5px grey");
          $(this).css("border-color", "white");
        },
        function () {
          $(this).css("box-shadow", "0px 0px 0px white");
        }
      );
    });
  };

  onHandleText = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  myFunction = () => {
    // Get the snackbar DIV
    var x = document.getElementById("snackbar");

    // StepperGeneralButtons the "show" class to DIV
    x.className = "show";

    // After 3 seconds, remove the show class from DIV
    setTimeout(function () {
      x.className = x.className.replace("show", "");
    }, 3000);
  };

  onChangeText = (e) => {
    console.log(e.target.value);
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  onStudentChange = (e) => {
    console.log(e.target.value);
    this.setState(
      { studentId: e.target.value },
      this.getFeesHistory(e.target.value)
    );
  };
  getFeesHistory = (stdId) => {
    this.setState({ searchCheck: true });
    this.props.sharedActions
      .getDataWithoutDispatch(
        `/api/ParentStudentPaymentHistory?studentId=${stdId}&pageNumber=1&pageSize=10`
      )
      .then((success) => {
        debugger;
        let requireData = addDataMiddleware(
          {
            Action: [
              {
                isEnable: true,
                type: "actionLink",
                title: "View Receipt",
                initialPath: "/dashboard/FeeReceipt",
                iconName: "fas fa-receipt",
                iconColor: "#01AC8A",
              },
            ],
          },
          success.payment
        );
        this.setState({
          adminData: requireData,
          checking: success.paginationMetadata,
          searchCheck: false,
          Loading: false,
        });
      })
      .catch((error) => {
        this.setState({
          searchCheck: false,
          Loading: true,
        });
        console.log(error);
      });
  };
  SearchChildData = (e) => {
    this.setState({ searchCheck: true });
    this.props.sharedActions
      .getDataWithoutDispatch(
        `/api/StudentDetail?userName=${this.state.studentUserName}`
      )
      .then((success) => {
        this.setState({
          adminData: success,
          searchCheck: false,
          Loading: false,
        });
      })
      .catch((err) => {
        // this.props.CensusActions.updateCensusFlag(false);
        this.setState({
          isLoading: false,
          Loading: true,
          check: true,
          searchCheck: true,
          btnLoad: false,
        });

        console.log(err);
      });
    console.log(this.state);
  };
  paginationRequest = (number) => {
    console.log(number);
    debugger;
    this.props.sharedActions
      .getDataWithoutDispatch(
        `/api/ParentStudentPaymentHistory?studentId=${this.state.studentId}&pageNumber=${number}&pageSize=10`
      )
      .then((success) => {
        debugger;
        let requireData = addDataMiddleware(
          {
            Action: [
              {
                isEnable: true,
                type: "actionLink",
                title: "View Receipt",
                initialPath: "/dashboard/FeeReceipt",
                iconName: "fas fa-receipt",
                iconColor: "#01AC8A",
              },
            ],
          },
          success.payment
        );
        this.setState({
          adminData: requireData,
          checking: success.paginationMetadata,
          searchCheck: false,
        });
      })
      .catch((err) => {
        debugger;
        this.setState({
          searchCheck: false,
        });
        console.log(err);
      });
  };
  render() {
    const theme = this.context;
    const { adminData, studentNames } = this.state;
    console.log("Students", this.state.studentNames);
    console.log("Students All Data", this.state.adminData);

    const Loader = (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div class="spinner-border" style={{ color: "#01AC8A" }}></div>
      </div>
    );
    let pageNumbers = [];
    for (
      let i = 1;
      i <=
      Math.ceil(this.state.checking.totalCount / this.state.checking.pageSize);
      i++
    ) {
      pageNumbers.push(i);
    }
    const renderPageNumbers = pageNumbers.map((number, index) => {
      return (
        <li
          class={`page-item ${
            this.state.checking.currentPage === number ? "active" : ""
          } `}
        >
          <a
            key={number}
            id={number}
            onClick={() => this.paginationRequest(number)}
            class="page-link "
            href="#"
          >
            {number}
          </a>
        </li>
      );
    });

    const MainContent = (
      <div>
        {this.state.Loading ? (
          <p class="text-center text-danger">No Data</p>
        ) : (
          <ViewComponent
            mainData={this.state.adminData}
            headData={[
              "#",
              "Name",
              "Class",
              "Section",
              "Month",
              "Date",
              "Status",
              "Amount Paid",
              "Actions",
            ]}
            excludedData={[
              "studentId",
              "feeCollectionId",
              "classId",
              "sectionId",
              "voucherNo",
            ]}
            redirectIds={["voucherNo"]}
          />
        )}
      </div>
    );
    return (
      <div>
        <div className="page-header">
          <SnackBar msg={this.state.msg} />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div>
              <h2
                style={{ background: theme.themeColors.primaryColor }}
                className="generalHead"
              >
                Student Fee History
              </h2>
            </div>
            <nav aria-label="Page navigation example">
              <ul style={{ color: "#01AC8A" }} class="pagination">
                {renderPageNumbers}
              </ul>
            </nav>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-md-4 text-center" style={{ marginTop: 0 }}>
            <div class="md-form mb-0">
              <label for="email" class="">
                Select your child
              </label>
              <select
                onChange={this.onStudentChange}
                class="custom-select"
                name="sectionName"
              >
                <option selected={this.state.msg ? true : false}>
                  Select Child..
                </option>
                {studentNames.map((item, index) => (
                  <option key={index} value={`${item.studentId}`}>
                    {item.studentName}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <br />
        {this.state.searchCheck ? Loader : MainContent}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    SkillAction: bindActionCreators(SkillAction, dispatch),
    sharedActions: bindActionCreators(sharedActions, dispatch),
  };
}
function mapStateToProps(state) {
  return {
    AllTransportData: state.setStudentSkillReducer.DataP,
    selectData: state.setStudentSkillReducer.selectStudent,
    SessionSelect: state.setStudentSkillReducer.selectSession,
    allTransportState: state.setStudentSkillReducer,
    users: state.setUserReducer.users,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(StudentFeeHistoryP);
