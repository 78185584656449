import React, { useState } from 'react';
import useCustomHttpWithDataMiddleware from '../../../hooks/Services/httpWithCustomDataMiddleware';
import Colors from '../../../utils/app_constants/colors_constants';
import ViewComponent from '../../../components/ViewComponent/ViewComponent';
import CustomHeading from '../../../components/CustomHeading';
import * as sharedActions from '../../../Actions/sharedActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import LoaderWrapper from '../../../components/HOC/LoaderWrapper';
import { SUB_HEADING } from '../../../utils/general';
import Button from '../../../components/Button';
import $ from 'jquery';
import SnackBar from '../../../components/SnackBar/SnackBar';
function ContinousEditAssignMarksModule(props) {
	const { stId, pId } = props.match.params;
	const [cloneData, setCloneData] = useState();
	const [snackbarMsg, setSnackbarMsg] = useState('');

	const [toolData, setToolData] = useState([]);
	const [modalData, setModalData] = useState({});

	const myModalAction = (id, data, ...args) => {
		debugger;
		let getRecord = data.filter(item => item.toolId == id);
		debugger;

		setToolData(getRecord);
		setModalData(getRecord && getRecord[0]);

		$('.bd-example-modal-lg-1').modal('show');
	};
	const testedArray = (arr = [], propName) => {
		let isEmpty = false;
		arr.forEach(element => {
			if (element[propName] === '') {
				isEmpty = true;
			}
		});
		return isEmpty;
	};

	const isEmpty = (arrobj, propName) => {
		let flag = false;
		let getSubTool = arrobj.map(item => item.subTools_CA_AssignMarks);

		let getResult = getSubTool.map(item => testedArray(item, propName));
		console.log(getResult);

		return getResult.every(item => item === false);
	};

	const [isLoading, fetchData, setFetchData] = useCustomHttpWithDataMiddleware(
		`/api/GetAllMark/?studentId=${stId}&pId=${pId}`,
		[],
		{
			Action: [
				{
					handler: myModalAction,
					isEnable: true,
					type: 'modalButton',
					modalKey: '.bd-example-modal-lg-1',
					title: 'Update Marks',
					iconName: 'fas fa-pen',
					iconColor: Colors.BLACK
				}
			]
		},
		false,
		'assignAllToolMarks'
	);
	debugger;
	const onChangeToolData = (e, subIndex, toolId, pId, toolIndex, min, max) => {
		debugger;
		let getToolIndex = fetchData.findIndex(
			item => item.pId == pId && item.toolId == toolId
		);
		let getSubToolDetails = fetchData[getToolIndex]['subTools_CA_AssignMarks'];
		let getSubToolIndex = getSubToolDetails.findIndex(
			item => item.toolId == toolId
		);
		debugger;
		debugger;
		console.log(fetchData);
		let getData = $.extend(true, [], fetchData);
		debugger;
		if (parseInt(e.target.value) <= min || e.target.value === '') {
			debugger;
			let getToolData = JSON.parse(JSON.stringify(toolData));
			let getToolNode = getToolData[toolIndex];
			let { subTools_CA_AssignMarks } = getToolNode;
			subTools_CA_AssignMarks[subIndex]['marks'] = e.target.value;
			subTools_CA_AssignMarks[subIndex]['studentId'] = stId;
			getData[getToolIndex]['subTools_CA_AssignMarks'][getSubToolIndex][
				'marks'
			] = e.target.value;
			setCloneData(getData);

			console.log(getData);
			debugger;
			console.log(getToolData);

			setToolData(getToolData);
			debugger;
		}
	};
	const onEditSubmit = () => {
		console.log(toolData);
		let getKeys = Object.keys(toolData[0]);
		let getDesireKeys = getKeys.filter(item => item !== 'Action');
		debugger;
		let getNEwOBJ = {};
		getDesireKeys.map(item => (getNEwOBJ[item] = toolData[0][item]));
		console.log(getNEwOBJ);
		props.sharedAction
			.patchParticularRecord(
				`/api/AssignMark/?pId=${pId}&studentId=${stId}&toolId=${
					toolData[0]['toolId']
				}`,
				getNEwOBJ
			)
			.then(success => {
				setSnackbarMsg('Update Successfully!');
				setFetchData(cloneData);
				props.snackbar();
				$('.bd-example-modal-lg-1').modal('hide');
				setToolData(toolData);
			})
			.catch(error => {
				debugger;
			});
	};
	return (
		<div>
			<div style={{ width: '40%', margin: '0 auto' }}>
				<CustomHeading type={SUB_HEADING} headingText={'Update Marks'} />
			</div>
			<SnackBar backColor="#000" msg={snackbarMsg} />

			<br />
			<LoaderWrapper isLoading={isLoading}>
				<ViewComponent
					children={() => (
						<React.Fragment>
							<div
								class="modal fade bd-example-modal-lg-1"
								tabindex="-1"
								role="dialog"
								aria-labelledby="myLargeModalLabel"
								aria-hidden="true"
							>
								<div class="modal-dialog modal-lg">
									<div class="modal-content">
										<div className="caAssignMarksHead">
											<h1 style={{ textAlign: 'center' }}>
												{modalData['toolName']}
											</h1>
										</div>
										<hr
											style={{
												border: '2px solid #01AC8A',
												width: '100%',
												margin: 0
											}}
										/>
										<br />
										<div>
											{toolData &&
												toolData.map((item, toolIndex) => (
													<React.Fragment>
														<div>
															<table className="table table-bordered">
																<tr style={{ textAlign: 'center' }}>
																	{item.subTools_CA_AssignMarks.map(subItem => (
																		<td
																			style={{
																				backgroundColor: '#01AC8A',
																				color: '#fff'
																			}}
																		>
																			{
																				<React.Fragment>
																					{subItem.subToolName}
																					{`(`}
																					<b>{subItem.min}</b>
																					{' | '}
																					<b>{subItem.max}</b>
																					{`)`}
																				</React.Fragment>
																			}
																		</td>
																	))}
																</tr>
																<tr style={{ textAlign: 'center' }}>
																	{item.subTools_CA_AssignMarks.map(
																		(subItem, index) => (
																			<td>
																				<React.Fragment>
																					<input
																						// minLength={3}
																						style={{ width: '30%' }}
																						placeholder={`${subItem.min} - ${subItem.max}`}
																						onChange={e =>
																							onChangeToolData(
																								e,
																								index,
																								subItem.toolId,
																								subItem.pId,
																								toolIndex,
																								subItem.min,
																								subItem.max
																							)
																						}
																						value={subItem['marks']}
																						type="text"
																					/>
																				</React.Fragment>
																			</td>
																		)
																	)}
																</tr>
															</table>
														</div>
													</React.Fragment>
												))}
											<div style={{ textAlign: 'center' }}>
												<Button
													customClause={!isEmpty(toolData, 'marks')}
													btnName="Submit"
													buttonClass="btn-smart-one"
													textColor="#fff"
													btnBackColor={'#0A4F5E'}
													onHandleSubmit={onEditSubmit}
												/>
											</div>
											<br />
										</div>
									</div>
								</div>
							</div>
						</React.Fragment>
					)}
					mainData={fetchData}
					headData={['S #', 'ToolName', 'Actions']}
					excludedData={['toolId', 'pId', 'subTools_CA_AssignMarks']}
					redirectIds={['toolId']}
				/>
			</LoaderWrapper>
		</div>
	);
}

function mapDispatchToProps(dispatch) {
	return {
		sharedAction: bindActionCreators(sharedActions, dispatch)
	};
}
export default connect(
	null,
	mapDispatchToProps
)(ContinousEditAssignMarksModule);
