import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as CanteenActions from "../../Actions/Canteen";
import * as sharedActions from "../../Actions/sharedActions";
import DateandTime from "../Transport/DateandTime";
import ThemeContext from "../../context/themeContext/ThemeContext";
class AddCanteen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      campusName: "",
      campusId: null,
      msg: false,
      errorMsg: false,
      select: [],
      handler: [],
      handlerId: null,
      handlerName: "",
    };
    console.log("network status", navigator.onLine);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      prevState.select !== nextProps.selectData ||
      prevState.handler !== nextProps.handlerData
    ) {
      return {
        select: nextProps.selectData,
        handler: nextProps.handlerData,
      };
    }
    return null;
  }

  onHandleTextChange = (e) => {
    console.log(e.target.value);
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  onHandleSubmit = (e) => {
    const { handlerId } = this.state;
    e.preventDefault();
    console.log(this.state);
    if (
      Object.is(this.state.name, "") ||
      Object.is(this.state.campusId, "") ||
      Object.is(this.state.handlerId, "")
    ) {
      this.setState({
        errorMsg: true,
      });
    } else {
      const { campusId, select, name } = this.state;
      let data = {
        name,
        campusId,
        accountType: this.props.userData.role,
        accountId: this.props.userData.unique_name,
        handlerId,
      };

      this.props.sharedActions
        .addRecord("/api/Canteens", data, this.props.canteenActions.addCanteen)
        .then((success) => {
          this.setState({
            name: "",
            campusName: "",
            campusId: null,
            msg: true,
            handlerName: "",
            errorMsg: false,
            handlerId: null,
            select: [],
          });
          this.props.canteenActions.updateCanteenFlag(true);
        })
        .catch((error) => {});
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      prevState.select !== nextProps.selectData &&
      prevState.handler !== nextProps.handlerData
    ) {
      return {
        select: nextProps.selectData,
        handler: nextProps.handlerData,
      };
    }
    return null;
  }

  onHandleTextChange = (e) => {
    console.log(e.target.value);
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  onHandleSubmit = (e) => {
    const { handlerId } = this.state;
    e.preventDefault();
    console.log(this.state);
    if (Object.is(this.state.name, "")) {
      this.setState({
        errorMsg: true,
      });
    } else {
      const { campusId, select, name } = this.state;
      let data = {
        name,
        campusId,
        accountType: this.props.userData.role,
        accountId: this.props.userData.unique_name,
        handlerId,
      };

      this.props.sharedActions
        .addRecord("/api/Canteens", data, this.props.canteenActions.addCanteen)
        .then((success) => {
          this.setState({
            name: "",
            campusName: "",
            campusId: null,
            msg: true,
            handlerName: "",
            errorMsg: false,
            handlerId: null,
            select: [],
          });
          this.props.canteenActions.updateCanteenFlag(true);
        })
        .catch((error) => {});
    }
  };

  componentDidMount() {
    this.props.sharedActions
      .gAllData(
        "/api/getCanteenHandler",
        this.props.canteenActions.getHandlerForCanteen
      )
      .then((success) => {
        console.log(success);
      })
      .catch((error) => {
        console.log(error);
      });
    this.props.sharedActions
      .gAllData(
        "/api/CampusDetail",
        this.props.canteenActions.getCampusForCanteen
      )
      .then((success) => {
        console.log(success);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  componentDidUpdate() {
    if (this.state.msg || this.state.errorMsg) {
      setTimeout(() => {
        this.setState({
          msg: false,
          errorMsg: false,
        });
      }, 3000);
    }
  }
  onSelectChange = (e) => {
    console.log(e.target.value);
    let result = JSON.parse(e.target.value);
    console.log(result);
    this.setState({
      campusId: result.id,
    });
  };
  onSelectChangeHandler = (e) => {
    console.log(e.target.value);
    let result = JSON.parse(e.target.value);
    console.log(result);

    this.setState({
      handlerId: result.Id,
    });
  };
  Message = (msg, color, fontSize, flag) => {
    if (flag) {
      return (
        <p style={{ fontSize: fontSize }} class={`text-${color}`}>
          {msg}
        </p>
      );
    }
  };
  render() {
    const { select, handler } = this.state;

    return (
          <ThemeContext.Consumer>
            {(context) => (
      <div className="container">
        <section class="mb-4">
          <h2 class="h1-responsive font-weight-bold text-center my-4 generalHead"
            style={{ background: context.themeColors.primaryColor }}
          >
            Add Canteen
          </h2>
          <p class="text-center w-responsive mx-auto mb-5" />
          <div
            class="row"
            style={{
              justifyContent: "center",
              textAlign: "center",
            }}
          >
                <div
                  class="col-md-9 mb-md-0 mb-5"
                  style={{
                    border: "solid",
                    borderColor: context.themeColors.primaryColor,
                    padding: 40,
                    borderRadius: 15,
                    width: "auto",
                  }}
                >
                  {this.Message(
                    "Canteen Added Successfully!",
                    "success",
                    20,
                    this.state.msg
                  )}
                  {this.Message(
                    "Please fill the form properly!",
                    "danger",
                    20,
                    this.state.errorMsg
                  )}
                  <form
                    onSubmit={this.onHandleSubmit}
                    id="contact-form"
                    name="contact-form"
                  >
                    <div class="row">
                      <div class="col-md-12">
                        <div class="md-form mb-0">
                          <DateandTime />
                        </div>
                      </div>
                    </div>
                    <br />
                    <div class="row">
                      <div class="col-md-6">
                        <div class="md-form mb-0">
                          <label for="name" class="">
                            Admin Name
                          </label>
                          <input
                            style={{
                              borderColor: context.themeColors.primaryColor,
                            }}
                            readOnly={true}
                            value={this.props.userData.unique_name}
                            type="text"
                            name="AdminName"
                            class="form-control"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="md-form mb-0">
                          <label for="name" class="">
                            Admin Type
                          </label>
                          <input
                            style={{
                              borderColor: context.themeColors.primaryColor,
                            }}
                            readOnly={true}
                            value={this.props.userData.role}
                            type="text"
                            name="AdminType"
                            class="form-control"
                          />
                        </div>
                      </div>
                      <div class="col-md-6" style={{ marginTop: 20 }}>
                        <div class="md-form mb-0">
                          <label for="name" class="">
                            Canteen Name
                          </label>
                          <input
                            style={{
                              borderColor: context.themeColors.primaryColor,
                            }}
                            type="text"
                            placeholder="Canteen Name"
                            class="form-control"
                            name="name"
                            value={this.state.name}
                            onChange={this.onHandleTextChange}
                          />
                        </div>
                      </div>
                      <div class="col-md-6" style={{ marginTop: 20 }}>
                        <div class="md-form mb-0">
                          <label for="email" class="">
                            Handler Name
                          </label>
                          <select
                            style={{
                              borderColor: context.themeColors.primaryColor,
                            }}
                            onChange={this.onSelectChangeHandler}
                            class="custom-select"
                            name="handlerName"
                          >
                            <option selected={this.state.msg ? true : false}>
                              Choose Handler...
                            </option>
                            {handler.map((item, index) => (
                              <option
                                key={index}
                                data="hello"
                                value={`${JSON.stringify(item)}`}
                              >
                                {item.Name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div class="col-md-6" style={{ marginTop: 20 }}>
                        <div class="md-form mb-0">
                          <label for="email" class="">
                            Campus Name
                          </label>
                          <select
                            style={{
                              borderColor: context.themeColors.primaryColor,
                            }}
                            onChange={this.onSelectChange}
                            class="custom-select"
                            name="CampusName"
                          >
                            <option selected={this.state.msg ? true : false}>
                              Choose Campus...
                            </option>
                            {this.state.select.map((item, index) => (
                              <option
                                key={index}
                                value={`${JSON.stringify(item)}`}
                              >
                                {item.campusName}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <br />
                    <br />
                    <div class="text-center text-md-right">
                      <button
                        type="submit"
                        class="buttonHover2"
                        style={{
                          background: context.themeColors.primaryColor,
                        }}
                      >
                        Add
                      </button>
                    </div>
                  </form>
                  <div class="status" />
                </div>
          </div>
        </section>
      </div>
              )}
            </ThemeContext.Consumer>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    sharedActions: bindActionCreators(sharedActions, dispatch),
    canteenActions: bindActionCreators(CanteenActions, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    userData: state.setUserReducer.users,
    selectData: state.setCanteenReducer.select,
    handlerData: state.setCanteenReducer.selectHandler,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddCanteen);
