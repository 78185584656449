import React, { Component } from 'react';
import Button from '../../components/Button';
import { images } from '../../utils';
import { Link } from 'react-router-dom';

const CardComponent = props => {
	const {
		bodyColor,
		footerColor,
		messageTo,
		unread,
		comImg,
		redirect,
		redirect2
	} = props;

	return (
		<React.Fragment>
			<div class="col-md-4 mt-1 mb-4">
				<div class="card  text-center shadow-lg" style={{ color: '#fff' }}>
					<div class="card-body" style={{ backgroundColor: bodyColor }}>
						<div class="col-md-12 text-md-center">
							<span
								style={{
									height: '10px',
									width: '10px',
									backgroundColor: `${unread === 0 ? '#bbb' : 'red'}`,
									borderRadius: '50%',
									display: 'inline-block'
								}}
							></span>{' '}
							{''}
							<strong>Unread Messages ({unread})</strong>
						</div>
						<br />
						<div class="col-md-12">
							<img
								src={comImg}
								class="card-img"
								style={{
									border: `1px solid #fff`,
									width: 100,
									height: 100,
									borderRadius: '50%'
								}}
								alt="Student Img"
							/>
						</div>
						<br />
						<div
							class="col=md-12"
							style={{
								border: '1px solid #fff',
								borderLeft: 0,
								borderRight: 0
							}}
						>
							<strong style={{ color: '#fff' }}>{messageTo}</strong>
						</div>
					</div>
					<div class="card-footer " style={{ backgroundColor: footerColor }}>
						<div class="row">
							<div
								class="col-md-6"
								style={{
									borderRight: `1px solid #fff`
								}}
							>
								<Link to={`/dashboard/${redirect}`} style={{ color: '#fff' }}>
									<i class="fas fa-comment"></i> <br /><strong>Message</strong>
								</Link>
							</div>
							<div
								class="col-md-6"
								style={{
									borderLeft: `1px solid #fff`
								}}
							>
								<Link
									to={{
										pathname: `/dashboard/CoordinatorInbox/${redirect2}`
									}}
									style={{ color: '#fff' }}
								>
									<i class="fas fa-inbox"></i> <br /><strong>Inbox</strong>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};
export default CardComponent;
