import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import Spinner from '../../components/Spinner/Spinner';
import $ from 'jquery'
import { images } from '../../utils';

class OtherCertificateTemplate2 extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            certificate: ''
        }
    }

    componentDidMount() {
        const { match } = this.props;
        $(document).ready(function () {
            $('[data-toggle="tooltip"]').tooltip();
        });
        this.props.sharedActions
            .getDataWithoutDispatch(
                `/api/OtherCertificate/${match.params.id}`
            )
            .then(success => {
                this.setState({
                    certificate: success,
                    isLoading: false
                })
            })
            .catch(error => {
                this.setState({
                    isLoading: false
                });
                console.log(error);
            });
    }

    PrintContent = el => {

        const { certificate } = this.state;

        let Certificate =
            `            <div>
                <img src=${images.imgCertificateTemplate2} width= 100% height= 700 style= "display: block;   position: absolute;
                left: 0;
                top: 0;" alt="Certificate Template" >

                <div style = "
                z-index: 100;
                position: absolute;
                left: 0;
                right: 0;
                top: 20%;
                width: 100%;
                text-align: center;
                margin: 0 auto;
                font-size: 16px;
                ">
                    <h1 >
                        <strong> ${certificate.certificateTitle}</strong>
                        <br />
                        <br />
                    ${certificate.fewDetail}
                        <br />
                        <br />
                        <strong style= "text-decoration: underline; color: #777777;" >${certificate.name}</strong>
                        <br />
                        <br />
                        </h1>
                        <label style = "
                        font-size: 20px; ">
                    ${certificate.largeDetail}
                        <br />
                        </label>
                    <br />
                    <br />
                    <br />
                    <div style= "  margin: 0 auto;
                    padding: 10px 0; justify-content: space-between; display: flex">
                            <h7 style= "text-decoration: overline; color: #777777; margin-left:300px">Date</h7>
                            <h7 style= "text-decoration: overline; color: #777777; margin-right: 300px ">Signature</h7>
                    </div>

                </div>
            </div>`;

        var pri = document.getElementById("ifmcontentstoprint").contentWindow;
        pri.document.open();
        pri.document.write(Certificate);
        pri.document.close();
        pri.focus();
        pri.print();
    };

    render() {
        const { certificate, isLoading } = this.state;

        const Certificate = (
            <div id='div1' class='print' style={{
                position: 'relative',
                display: 'inline-block',
/*                     transform: 'rotate(-90deg)',
                marginTop: '60px',
*/                }}>
                <img src={images.imgCertificateTemplate2} style={{ width: 1000, display: 'block' }} alt="Certificate Template" />
                <div style={{
                    position: 'absolute',
                    zIndex: 999,
                    margin: '0 auto',
                    left: 0,
                    right: 0,
                    top: '25%',
                    textAlign: 'center',
                    width: '60%'
                }}>
                    <h1>
                        <strong class='text-center'>{certificate.certificateTitle}</strong>
                        <br />
                        <br />
                        {certificate.fewDetail}
                        <br />
                        <strong style={{ textDecoration: 'underline' }}>{certificate.name}</strong>
                        <br />
                        <br />
                        {certificate.largeDetail}
                        <br />
                    </h1>
                    <div class='row text-center' style={{ marginTop: 100, alignItems: 'center', justifyContent: 'center' }}>
                        <div class='col-md-6'>
                            <h7 style={{ textDecoration: 'overline' }}>Date</h7>
                        </div>
                        <div class='col-md-6'>
                            <h7 style={{ textDecoration: 'overline' }}>Signature</h7>
                        </div>
                    </div>

                </div>
            </div>
        )
        return (
            <div>
                {isLoading ? <Spinner /> : Certificate}
                <br />
                <br />
                <iframe
                    id="ifmcontentstoprint"
                    style={{ height: 0, width: 0, position: "absolute" }}
                ></iframe>
                <div class="text-center text-md-right no-print">
                    <label for="name" class="" onClick={() => this.PrintContent('div1')}>
                        <i
                            className="fas fa-print fa-2x animated fadeIn"
                            style={{ marginRight: 4 }}
                        />
                        Print
                            </label>
                </div>
            </div >
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        sharedActions: bindActionCreators(sharedActions, dispatch)
    };
}

export default connect(null, mapDispatchToProps)(OtherCertificateTemplate2);