import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "rc-time-picker/assets/index.css";
import { BrowserRouter as Router } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap";
import "../node_modules/popper.js/dist/popper.min";
import "../node_modules/font-awesome/css/font-awesome.min.css";
import "./Assets/css/app.css";
import "./css/animated.css";
import "bs-stepper/dist/css/bs-stepper.min.css";
import "jquery";
// import '../src/Assets/css/app.css';
import configureStore from "./Store/configureStore";
import Routes from "./routes";

import { Provider } from "react-redux";
import jwt from "jsonwebtoken";
import setAuthorizationToken from "./config/setAuthToken";
import * as LoginActions from "./Actions/LoginActions/index";
const store = configureStore();
if (localStorage.jwtToken) {
  setAuthorizationToken(localStorage.jwtToken);
  store.dispatch(
    LoginActions.setCurrentUser(jwt.decode(localStorage.jwtToken))
  );
}

ReactDOM.render(
  <Router>
    <Provider store={store}>
      <Routes />
    </Provider>
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
