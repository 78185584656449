import React from "react";
import $ from "jquery";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as sharedActions from "../../Actions/sharedActions";
import SnackBar from "../../components/SnackBar/SnackBar";
import SelectionInput from "../../components/SelectionInput/SelectionInput";
import general from "../../utils/general";
import Colors from "../../utils/app_constants/colors_constants";
import Spinner from "../../components/Spinner/Spinner";
import { Link } from "react-router-dom";
import ThemeContext from "../../context/themeContext/ThemeContext";

let tHead = [
  "Class",
  "Section",
  "Session",
  "Staff Name",
  "Subject",
  "Date",
  "Actions",
];

let renderFields = [
  "className",
  "section",
  "session",
  "staff",
  "subjectName",
  "date",
];

class HODEvaluationFormView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reportData: {},
      classData: [],
      sectionData: [],
      subjectData: [],
      evaluationData: [],
      isLoading: false,
      isLoadingInitialData: true,
      snackColor: "",
      disablepage: false,
      disableBPage: true,
      currentPage: 1,
      todosPerPage: 10,
      pageCounter: 10,
      classId: "",
      subjectId: "",
      sectionId: "",
      staffId: "",
      errorMsg: false,
    };
  }

  componentDidMount() {
    $(document).ready(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });

    this.props.sharedActions
      .getDataWithoutDispatch(
        `/api/DescriptiveReport?userName=${this.props.match.params.name}`
      )
      .then((success) => {
        this.setState({
          reportData: success,
          classData: success.classes,
          staffClassSection: success.staffClassSection,
          isLoadingInitialData: false,
          staffId: success.classes[0].staffId,
        });
      })
      .catch((err) => {
        this.setState({
          isLoadingInitialData: false,
        });
      });
  }

  componentDidUpdate() {
    if (this.state.msg || this.state.errorMsg) {
      setTimeout(() => {
        this.setState({
          msg: "",
          errorMsg: false,
          snackColor: "",
        });
      }, 3000);
    }
  }

  btnTechHover = () => {
    $(document).ready(function () {
      $("button").hover(
        function () {
          $(this).css("box-shadow", "0px 5px 5px grey");
          $(this).css("border-color", "white");
        },
        function () {
          $(this).css("box-shadow", "0px 0px 0px white");
        }
      );
    });
  };

  onHandleClassChange = (e) => {
    const { staffClassSection } = this.state;

    let sectionData = general.dataFilteration(
      staffClassSection,
      ["section", "sectionId"],
      {
        classId: parseInt(e.target.value),
      }
    );
    this.setState({
      [e.target.name]: e.target.value,
      sectionData: sectionData,
    });
  };

  onHandleSectionChange = (e) => {
    const { staffClassSection, classId } = this.state;

    let subjectData = general.dataFilteration(
      staffClassSection,
      ["subjectName", "subjectId"],
      {
        classId: parseInt(classId),
        sectionId: parseInt(e.target.value),
      }
    );
    this.setState({
      [e.target.name]: e.target.value,
      subjectData: subjectData,
    });
  };

  onHandleSelectChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();

    const { subjectId, classId, sectionId, staffId } = this.state;

    if (
      Object.is(subjectId, "") ||
      Object.is(classId, "") ||
      Object.is(sectionId, "")
    ) {
      this.setState({
        errorMsg: true,
      });
    } else {
      this.setState({
        isLoading: true,
      });
      this.props.sharedActions
        .getDataWithoutDispatch(
          `/api/TeacherEvaluationForm/?classId=${classId}&sectionId=${sectionId}&subjectId=${subjectId}&staffId=${staffId}`
        )
        .then((success) => {
          this.setState({
            msg: "Evaluations for the Selected Query",
            evaluationData: success,
            isLoading: false,
            classId: "",
            subjectId: "",
            sectionId: "",
          });
          this.props.snackbar();
        })
        .catch((error) => {
          if (error && error.response && error.response.status == 404) {
            // 404-Error no understanding of 404 Error
            this.setState({
              msg: error.response.data.Message,
              isLoading: false,
            });
            this.props.snackbar();
          }
        });
    }
  };

  render() {
    const {
      errors,
      classData,
      sectionData,
      subjectData,
      isLoadingInitialData,
      evaluationData,
      isLoading,
    } = this.state;

    const SearchContent = (
      <div>
        {this.state.errorMsg ? (
          <p style={{ color: "red", textAlign: "center" }}>
            Please Select all fields!
          </p>
        ) : (
          ""
        )}

        <form
          onSubmit={this.onSubmit}
          class="form-inline"
          style={{ justifyContent: "center" }}
        >
          <div class="row">
            <div class="col-md-3">
              <SelectionInput
                feildName={"classId"}
                selectName={"Class"}
                onHandleChange={(e) => this.onHandleClassChange(e)}
                errors={errors}
                optionsArrys={classData}
                selectedText={"Class Name"}
                stateData={this.state}
                // iconClassName={'fas fa-building'}
                optionType="dynamicWithPropIdAndName"
                property={"className"}
                propertyId={"classId"}
                isValidte={false}
                // editUse={'staffEdit'}
              />
            </div>
            <div class="col-md-3">
              <SelectionInput
                feildName={"sectionId"}
                selectName={"Section"}
                onHandleChange={(e) => this.onHandleSectionChange(e)}
                errors={errors}
                optionsArrys={sectionData}
                selectedText={"Section"}
                stateData={this.state}
                // iconClassName={'fas fa-building'}
                optionType="dynamicWithPropIdAndName"
                property={"section"}
                propertyId={"sectionId"}
                isValidte={false}
                // editUse={'staffEdit'}
              />
            </div>
            <div class="col-md-3">
              <SelectionInput
                feildName={"subjectId"}
                selectName={"Subject"}
                onHandleChange={(e) => this.onHandleSelectChange(e)}
                errors={errors}
                optionsArrys={subjectData}
                selectedText={"Subject"}
                stateData={this.state}
                // iconClassName={'fas fa-building'}
                optionType="dynamicWithPropIdAndName"
                property={"subjectName"}
                propertyId={"subjectId"}
                isValidte={false}
                // editUse={'staffEdit'}
              />
            </div>
            <div class="col-md-3" style={{ marginTop: 26 }}>
              <ThemeContext.Consumer>
                {(context) => (
                  <button
                    type="submit"
                    class="btn"
                    style={{
                      backgroundColor: context.themeColors.primaryColor,
                      color: "white",
                    }}
                  >
                    {" "}
                    Search{" "}
                  </button>
                )}
              </ThemeContext.Consumer>
            </div>
          </div>
        </form>
      </div>
    );

    const MainContent = (data) =>
      data &&
      data.map((item, index) => (
        <tr>
          <th
            style={{
              padding: 2,
              border: `1px solid ${Colors.SEA_GREEN_THEME}`,
              borderColor: Colors.SEA_GREEN_THEME,
            }}
            scope="row"
          >
            {index + 1}
          </th>
          {renderFields.map((item1, index) => (
            <td
              style={{
                fontWeight: `${index == 0 ? "bold" : "normal"}`,
                border: `1px solid ${Colors.SEA_GREEN_THEME}`,
                padding: 2,
                borderColor: Colors.SEA_GREEN_THEME,
              }}
            >
              {item[item1]}
            </td>
          ))}
          <td className="bordersSpace">
            <Link to={`/dashboard/EvaluationByStaffTemplate/${item.id}`}>
              <button
                id="firstbutton"
                // onClick={() => this.onEditClick(item.id)}
                onMouseOver={this.btnTechHover()}
                style={{
                  cursor: "pointer",
                  fontSize: 20,
                  border: "none",
                  marginLeft: 5,
                  background: "none",
                  marginBottom: 5,
                }}
              >
                <ThemeContext.Consumer>
                  {(context) => (
                    <a href="#" data-toggle="tooltip" title="View!">
                      <i
                        style={{ color: context.themeColors.primaryColor }}
                        class="fas fa-list"
                      ></i>
                    </a>
                  )}
                </ThemeContext.Consumer>
              </button>
            </Link>
          </td>
        </tr>
      ));

    return (
      <div>
        <SnackBar msg={this.state.msg} />
        <div className="page-header">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div>
              <ThemeContext.Consumer>
                {(context) => (
                  <h2
                    style={{ background: context.themeColors.primaryColor }}
                    className="h1-responsive font-weight-bold text-left my-4 generalHead"
                  >
                    {" "}
                    Teacher Evaluation Details{" "}
                  </h2>
                )}
              </ThemeContext.Consumer>
            </div>
          </div>
        </div>
        <br />

        {isLoadingInitialData ? <Spinner /> : SearchContent}
        <br />
        <br />
        {isLoading ? (
          <Spinner />
        ) : evaluationData.length < 1 ? (
          <div
            style={{ display: "flex", justifyContent: "center", color: "red" }}
          >
            <strong>No Data</strong>
          </div>
        ) : (
          <div className="table-responsive">
            <table class="table table-hover">
              <ThemeContext.Consumer>
                {(context) => (
                  <thead
                    style={{
                      background: context.themeColors.primaryColor,
                      color: "white",
                    }}
                  >
                    <tr>
                      <th scope="col">#</th>
                      {tHead.map((item) => (
                        <th
                          style={{
                            border: `1px solid ${Colors.SEA_GREEN_THEME}`,
                            borderColor: Colors.WHITE,
                          }}
                          scope="col"
                        >
                          {item}
                        </th>
                      ))}
                    </tr>
                  </thead>
                )}
              </ThemeContext.Consumer>
              <tbody>{MainContent(evaluationData)}</tbody>
            </table>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    role: state.setUserReducer.users.role,
    name: state.setUserReducer.users.unique_name,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    sharedActions: bindActionCreators(sharedActions, dispatch),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HODEvaluationFormView);
