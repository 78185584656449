import * as actionTypes from '../Actions/actionTypes';

const defaultState = {
	apiFlag: true,
	Data: [],
	SearchData: [],
	select: [],
	SelectDepartment: []
};

export default function setDepartmentReducer(
	state = defaultState,
	action = {}
) {
	switch (action.type) {
		case actionTypes.SET_ALL_CAMPUS_NAME_INTO_DEPARTMENT_SELECT:
			return {
				...state,
				select: [...action.payload]
			};
		case actionTypes.SET_ALL_DEPARTMENT_NAME_INTO_SEARCHSTAFFDEPARTMENT_SELECT:
			return {
				...state,
				SelectDepartment: action.payload
			};

		case actionTypes.GET_ALL_DEPARTMENT:
			return { ...state, Data: [...action.payload] };
		case actionTypes.GET_ALL_STAFFSEARCH_BY_DEPART:
			return { ...state, SearchData: action.payload };
		case actionTypes.SET_LOADER_FLAG_DEPARTMENT:
			return { ...state, apiFlag: action.payload };
		case actionTypes.DELETE_DEPARTMENT:
			let filterCandidateReqList = state.Data.filter(
				filterOBJ => filterOBJ.DepartmentId !== action.payload
			);
			return {
				...state,
				Data: filterCandidateReqList
			};
		case actionTypes.EDIT_DEPARTMENT_RECORD:
			var adminData = [...state.Data];
			var index = adminData.findIndex(
				obj => obj.DepartmentId === action.payload.departmentId
			);
			adminData[index].departmentName = action.payload.departmentName;
			// adminData[index].campusName = action.payload.campusName;
			// adminData[index].campusId = action.payload.campusId;

			return { ...state, Data: [...adminData] };
		default:
			return state;
	}
}
