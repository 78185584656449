import React, { Component } from 'react';
import { PeriodBox, DayRowCom } from '../../TimeTableRendering';
import { Link } from 'react-router-dom';
export default function TimeTableRendering(props) {
	const {
		AllPeriods,
		PeriodArray,
		msToTime,
		timeData,
		workingDays,
		stateData,
		onChangeNewPeriodData,
		staffTeachData,
		handleCloseButton
	} = props;
	// timeData.Sdate = new Date().toLocaleTimeString();
	// let endDate =  new Date(new Date().getTime() + AllPeriods[0].periodDuration).toLocaleTimeString();
	let sDate = props.stateData.offSDate;
	let sTryDate = props.stateData.offSDate;
	// let getSDate= props.stateData.offSDate;
	let eDate = null;
	let contain = null;
	let getupdatedResult = PeriodArray.map((item, index) => {
		if (index === 0) {
			contain += item.periodDuration;
			eDate = new Date(sDate).getTime() + item.periodDuration;

			return {
				...item,
				dispTime:
					new Date(sDate).toLocaleTimeString() +
					' to ' +
					new Date(
						new Date(sDate).getTime() + item.periodDuration
					).toLocaleTimeString()
			};
		} else {
			contain += item.periodDuration;

			sDate = new Date(sTryDate).getTime() + contain;
			let disSDateVar = new Date(new Date(eDate)).toLocaleTimeString();
			let disEDateVar = new Date(new Date(sDate)).toLocaleTimeString();
			eDate = new Date(sTryDate).getTime() + contain;
			return { ...item, dispTime: disSDateVar + ' to ' + disEDateVar };
		}
	});
	return (
		<div>
			{stateData.mLoader ? <p>Loading...</p> : ''}
			{stateData.errors.responseError ? (
				<p
					className={`${
						stateData.errors.responseError ? 'animated shake' : ''
					} ?`}
					style={{ color: 'red' }}
				>
					{stateData.errors.responseError ===
					'TimeTable Already Present for this Class, Section and Grade !' ? (
						<span>
							{stateData.errors.responseError}
							<span
								style={{ color: 'blue', cursor: 'pointer' }}
								onClick={() => handleCloseButton()}
							>
								Try Again
							</span>
						</span>
					) : (
						stateData.errors.responseError
					)}
				</p>
			) : (
				''
			)}
			<table class="table table-bordered">
				<thead>
					<tr>
						<th scope="col">
							{' '}
							<div style={{ marginBottom: 50 }}> Days </div>{' '}
						</th>
						{getupdatedResult.map((item, index) => (
							<PeriodBox
								dispTime={item.dispTime}
								periodDuration={item.periodDuration}
								type={item.type}
								name={item.name}
								index={index}
							/>
						))}
					</tr>
				</thead>
				<tbody>
					{workingDays.map((item, index) => (
						<DayRowCom
							type="dataHandling"
							off={item.off}
							onChangeNewPeriodData={onChangeNewPeriodData}
							stateData={stateData}
							day={item.day}
							indexDay={index}
							AllPeriods={AllPeriods}
						/>
					))}
				</tbody>
			</table>
		</div>
	);
}
