import * as actionTypes from '../Actions/actionTypes';

const defaultState = {
	apiFlag: true,
	apiFlagEdit: true,
	Data: [],
	select: [],
	DataEdit: []
};

export default function setHrDevelopmentReducer(
	state = defaultState,
	action = {}
) {
	switch (action.type) {
		// case actionTypes.SET_ALL_VEHICLE_NAME_INTO_VEHICLE_SELECT:
		//
		//     return {
		//         ...state,
		//         select: [...action.payload]
		//     };

		// case actionTypes.SET_ALL_ROUTE_NAME_INTO_VEHICLE_SELECT:
		//
		//     return {
		//         ...state,
		//         select: [...action.payload]
		//     };

		// case actionTypes.SET_ALL_VEHICLE_NAME_INTO_DRIVER_SELECT:
		//
		//     return {
		//         ...state,
		//         select: [...action.payload]
		//     };
		case actionTypes.GET_ALL_HR_DEVELOPMENT:
			return { ...state, Data: [...action.payload] };
		case actionTypes.GET_ALL_HR_DEVELOPMENT_For_Edit:
			return { ...state, DataEdit: [...action.payload] };
		case actionTypes.SET_LOADER_FLAG_HR_DEVELOPMENT_ADD:
			return { ...state, apiFlag: action.payload };
		case actionTypes.SET_LOADER_FLAG_HR_DEVELOPMENT_EDIT:
			return { ...state, apiFlagEdit: action.payload };
		case actionTypes.DELETE_HR_DEVELOPMENT:
			let filterCandidateReqList = state.Data.filter(
				filterOBJ => filterOBJ.id !== action.payload
			);
			return {
				...state,
				Data: filterCandidateReqList
			};
		case actionTypes.EDIT_HR_DEVELOPMENT_RECORD:
			var adminData = [...state.Data];
			var index = adminData.findIndex(obj => obj.id === action.payload.id);
			// adminData[index].description = action.payload.description;

			return { ...state, Data: [...adminData] };
		default:
			return state;
	}
}
