import React from "react";
import ViewComponent from "../../../components/ViewComponent/ViewComponent";
import CustomHeading from "../../../components/CustomHeading";
import useHttp from "../../../hooks/http";
import { dateConversion, addDataMiddleware } from "../../../utils/general";
import httpWithRequiredSetData from "../../../hooks/Services/httpWithRequiredSetData";
import Colors from "../../../utils/app_constants/colors_constants";
import $ from "jquery";
import * as sharedActions from "../../../Actions/sharedActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import Spinner from "../../../components/Spinner/Spinner";
import { useContext } from "react";
import ThemeContext from "../../../context/themeContext/ThemeContext";

let newDummyListData = [
  {
    staffId: "10",
    staffName: "Khalid Latif",
    date: "08/24/2020",
    noOfPeriods: 1,
  },
  {
    staffId: "6",
    staffName: "Sanaullah Sheikh",
    date: "08/24/2020",
    noOfPeriods: 1,
  },
  {
    staffId: "8",
    staffName: "Huma Ahmed",
    date: "08/24/2020",
    noOfPeriods: 1,
  },
  {
    staffId: "2",
    staffName: "Hadiqa Altaf",
    date: "08/24/2020",
    noOfPeriods: 1,
  },
];

// debugger;
let ListData = [
  {
    timeTableId: 18,
    classId: "12",
    sectionId: "1015",
    gradeId: "4",
    previousStaffId: "1",
    preStaffName: "Ali Khan",
    previousSubjectId: "47",
    preSubjectName: "Arabic",
    newstaffId: 17,
    newStaffName: "Badar Sami",
    newSubjectId: 47,
    newSubjectName: "Arabic",
    periodType: "Period",
    name: "1st",
    date: "08/13/2020",
    dayName: "Thursday",
    perioDuration: "45",
    substitutionFor: "Department",
  },
  {
    timeTableId: 22,
    classId: "13",
    sectionId: "1016",
    gradeId: "4",
    previousStaffId: 1,
    preStaffName: "Ali Khan",
    previousSubjectId: 24,
    preSubjectName: "English",
    newstaffId: 11,
    newStaffName: "Madiha Khurum",
    newSubjectId: 24,
    newSubjectName: "English",
    periodType: "Period",
    name: "3rd",
    date: "08/13/2020",
    dayName: "Thursday",
    perioDuration: 45,
    substitutionFor: "Department",
  },
  {
    timeTableId: 21,
    classId: "12",
    sectionId: "1020",
    gradeId: 4,
    previousStaffId: 9,
    preStaffName: "Hira Anwar",
    previousSubjectId: 6,
    preSubjectName: "Business Study",
    newstaffId: 6,
    newStaffName: "Sanaullah Sheikh",
    newSubjectId: 6,
    newSubjectName: "Business Study",
    periodType: "Period",
    name: "5th",
    date: "08/13/2020",
    dayName: "Thursday",
    perioDuration: 45,
    substitutionFor: "Department",
  },
  {
    timeTableId: 18,
    classId: "12",
    sectionId: "1015",
    gradeId: 4,
    previousStaffId: 9,
    preStaffName: "Hira Anwar",
    previousSubjectId: 23,
    preSubjectName: "English",
    newstaffId: 11,
    newStaffName: "Madiha Khurum",
    newSubjectId: 23,
    newSubjectName: "English",
    periodType: "Period",
    name: "6th",
    date: "08/13/2020",
    dayName: "Thursday",
    perioDuration: 45,
    substitutionFor: "Department",
  },
];
function SubstitutionPeriodList(props) {
  const themeContext = useContext(ThemeContext);
  const btnTechHover = () => {
    $(document).ready(function () {
      $("button").hover(
        function () {
          $(this).css("box-shadow", "0px 5px 5px grey");
          $(this).css("border-color", "white");
        },
        function () {
          $(this).css("box-shadow", "0px 0px 0px white");
        }
      );
    });
  };
  const onViewStaffPeriods = (id, date) => {
    props.sharedAction
      .getDataWithoutDispatch(
        `/api/listOfPeriodsStaff/?staffId=${id}&date=${date}`
      )
      .then((success) => {
        setModalInformation(success);
        debugger;
        $(`.bd-example-modal-lg`).modal("show");
      })
      .catch((error) => {
        debugger;
      });
  };
  let [getModalInformation, setModalInformation] = useState([]);
  let [dummyData, setDummyData] = useState([]);
  let [isLoading, getSubstitutionData, setFetchData] = httpWithRequiredSetData(
    `/api/SubsitutionPeriodsList/?date=09/27/2020`,
    [],
    (data) => {
      console.log(data);
      let addAditionalData = addDataMiddleware(
        {
          Action: [
            {
              // hanlder: deleteHandler,
              exceptionCase: true,
              isEnable: true,
              type: "normalModalButton",
              modalKey: "#deleteModal",
              title: "Delete",
              initialPath: "/dashboard/ContinousNewToolCreation",
              iconName: "fas fa-trash-alt",
              iconColor: Colors.RED_DANGER,
            },
          ],
        },
        data
      );
      debugger;
      setFetchData(addAditionalData);
    }
  );
  useEffect(() => {
    const deleteHandler = (id, data, ...args) => {
      debugger;
    };
    let addAditionalData = addDataMiddleware(
      {
        Action: [
          {
            hanlder: () => {
              debugger;
            },
            isEnable: true,
            type: "normalModalButton",
            modalKey: ".bd-example-modal-lg",
            title: "Delete",
            initialPath: "/dashboard/ContinousNewToolCreation",
            iconName: "fas fa-trash-alt",
            iconColor: Colors.RED_DANGER,
          },
        ],
      },
      newDummyListData
    );
    debugger;
    setDummyData(addAditionalData);
  }, []);

  debugger;
  return (
    <div>
      <CustomHeading headingText={"Period Substituion List"} />
      <br />
      <div
        class="modal fade bd-example-modal-lg"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content modal_content_custom">
            <div
              style={{
                borderBottomLeftRadius: 30,
                borderBottomRightRadius: 30,
                borderTopLeftRadius: 18,
                borderTopRightRadius: 18,
                background: themeContext.themeColors.primaryColor,
              }}
              class="modal-header modal_custom_header"
            >
              <h4 style={{ color: "white" }} class="modal-title">
                Period Details
              </h4>
              <button
                style={{ color: "white" }}
                type="button"
                class="close"
                data-dismiss="modal"
              >
                &times;
              </button>
            </div>
            <table class="table">
              <thead
                style={{ background: themeContext.themeColors.primaryColor }}
              >
                <tr>
                  <th
                    style={{
                      color: Colors.WHITE,
                      background: themeContext.themeColors.primaryColor,
                    }}
                    scope="col"
                  >
                    #
                  </th>
                  <th
                    style={{
                      color: Colors.WHITE,
                      background: themeContext.themeColors.primaryColor,
                    }}
                    scope="col"
                  >
                    Class Name
                  </th>
                  <th
                    style={{
                      color: Colors.WHITE,
                      background: themeContext.themeColors.primaryColor,
                    }}
                    scope="col"
                  >
                    Section
                  </th>
                  <th
                    style={{
                      color: Colors.WHITE,
                      background: themeContext.themeColors.primaryColor,
                    }}
                    scope="col"
                  >
                    Grade Name
                  </th>
                  <th
                    style={{
                      color: Colors.WHITE,
                      background: themeContext.themeColors.primaryColor,
                    }}
                    scope="col"
                  >
                    Staff Name
                  </th>
                  <th
                    style={{
                      color: Colors.WHITE,
                      background: themeContext.themeColors.primaryColor,
                    }}
                    scope="col"
                  >
                    Subject Name
                  </th>
                  <th
                    style={{
                      color: Colors.WHITE,
                      background: themeContext.themeColors.primaryColor,
                    }}
                    scope="col"
                  >
                    Type
                  </th>
                  <th
                    style={{
                      color: Colors.WHITE,
                      background: themeContext.themeColors.primaryColor,
                    }}
                    scope="col"
                  >
                    Name
                  </th>
                  <th
                    style={{
                      color: Colors.WHITE,
                      background: themeContext.themeColors.primaryColor,
                    }}
                    scope="col"
                  >
                    Day
                  </th>
                </tr>
              </thead>
              <tbody>
                {getModalInformation &&
                  getModalInformation.map((item, index) => (
                    <tr>
                      <th scope="row">{index + 1}</th>
                      <td>{item.className}</td>
                      <td>{item.section}</td>
                      <td>{item.gradeName}</td>
                      <td>{item.staffName}</td>
                      <td>{item.subjectName}</td>
                      <td>{item.type}</td>
                      <td>{item.name}</td>
                      <td>{item.dayName}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {getSubstitutionData && getSubstitutionData.length > 0 ? (
        <table class="table">
          <thead style={{ background: Colors.SEA_GREEN_THEME }}>
            <tr>
              <th
                style={{
                  color: Colors.WHITE,
                  background: themeContext.themeColors.primaryColor,
                }}
                scope="col"
              >
                #
              </th>
              <th
                style={{
                  color: Colors.WHITE,
                  background: themeContext.themeColors.primaryColor,
                }}
                scope="col"
              >
                Staff Name
              </th>
              <th
                style={{
                  color: Colors.WHITE,
                  background: themeContext.themeColors.primaryColor,
                }}
                scope="col"
              >
                Date
              </th>
              <th
                style={{
                  color: Colors.WHITE,
                  background: themeContext.themeColors.primaryColor,
                }}
                scope="col"
              >
                No Of Period
              </th>
              <th
                style={{
                  color: Colors.WHITE,
                  background: themeContext.themeColors.primaryColor,
                }}
                scope="col"
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {getSubstitutionData &&
              getSubstitutionData.map((item, index) => (
                <tr>
                  <th scope="row">{index + 1}</th>
                  <td>{item.staffName}</td>
                  <td>{item.date}</td>
                  <td>{item.noOfPeriods}</td>
                  <td>
                    <a href="#" data-toggle="tooltip" title="View!">
                      <button
                        id="firstbutton"
                        onClick={() =>
                          onViewStaffPeriods(item.staffId, item.date)
                        }
                        onMouseOver={btnTechHover()}
                        data-toggle="modal"
                        data-target="#myModal"
                        style={{
                          cursor: "pointer",
                          fontSize: 20,
                          border: "none",
                          marginLeft: 5,
                          background: "none",
                          marginBottom: 5,
                        }}
                      >
                        <i class="fas fa-list"></i>
                      </button>
                    </a>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      ) : (
        <p style={{ color: "red", textAlign: "center" }}> No Data Found </p>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    role: state.setUserReducer.users.role,
    name: state.setUserReducer.users.unique_name,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sharedAction: bindActionCreators(sharedActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubstitutionPeriodList);
