import * as actionTypes from '../Actions/actionTypes';

const defaultState = {
	Data: null
};

export default function setTimeTableReducer(state = defaultState, action = {}) {
	switch (action.type) {
		case actionTypes.GET_TEACHER_AND_SUBJECT_FILTER:
			return {
				...state,
				Data: action.payload
			};

		default:
			return state;
	}
}
