import React, { Component } from 'react';
import SelectionInput from '../../components/SelectionInput/SelectionInput';

const RenderDynamicRow = props => {
    const {
        itemObj,
        classHandler,
        sectionHandler,
        stateData,
        rowIndex,
        updateSpecialArrObjOnChange,
        arrName,
        errors,
        tour
    } = props;

    let keys = Object.keys(itemObj);
    return (
        <React.Fragment>
            {keys.map((item, index) => {
                if (item === 'classId') {
                    return (
                        <td key={index} width='35%'>
                            <SelectionInput
                                newFlag={'customWidth'}
                                feildName={stateData[arrName][rowIndex][`${item}`]}
                                onHandleChange={e => classHandler(e, rowIndex, arrName)}
                                errors={errors}
                                optionsArrys={stateData['classData']}
                                selectedText={'Class'}
                                stateData={stateData}
                                optionType="dynamicWithPropIdAndName"
                                property={'className'}
                                propertyId={'id'}
                                successflag={'successflag'}
                                isValidte={false}
                                useFlag={false}
                            />
                        </td>)
                } else if (item === 'sectionId') {
                    return (
                        <td key={index} width='35%'>
                            <SelectionInput
                                newFlag={'customWidth'}
                                feildName={stateData[arrName][rowIndex][`${item}`]}
                                onHandleChange={e => sectionHandler(e, rowIndex, arrName)}
                                errors={errors}
                                optionsArrys={stateData[`sectionData${rowIndex}`]}
                                selectedText={'Section'}
                                stateData={stateData}
                                optionType="dynamicWithPropIdAndName"
                                property={'section'}
                                propertyId={'sectionId'}
                                successflag={'successflag'}
                                isValidte={false}
                                useFlag={false}
                            />
                        </td>
                    )
                }
                else {
                    return (
                        <td key={index} width='30%'>
                            <input
                                type="text"
                                placeholder={`${item}`}
                                class="form-control"
                                name={`${item}`}
                                value={tour}
                                disabled={true}
                                style={{ marginTop: 30 }}
                            />
                        </td>
                    )
                }
            })}
        </React.Fragment>
    );
};
export default RenderDynamicRow;
