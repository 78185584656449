import React from "react";
import ThemeContext from "../../../context/themeContext/ThemeContext";

export default function GenerateTable(props) {
  const { Data, Sheet } = props;
  return (
    <React.Fragment>
      {Sheet == "1" ? (
        <div className="table-responsive">
          <ThemeContext.Consumer>
            {(context) => (
              <table class="table text-center">
                <thead
                  class="bordersSpace"
                  style={{
                    background: context.themeColors.primaryColor,
                    color: "white",
                  }}
                >
                  <tr class="vendorListHeading bordersSpace">
                    <th className="bordersSpace" rowSpan="2" style={{borderColor:'white'}}>
                      NO.
                    </th>
                    <th className="bordersSpace" rowSpan="2" style={{borderColor:'white'}}>
                      Students
                    </th>
                    <th className="bordersSpace" colSpan="3" style={{borderColor:'white'}}>
                      Class Work
                    </th>
                    <th className="bordersSpace" colSpan="3" style={{borderColor:'white'}}>
                      Home Work
                    </th>
                    <th className="bordersSpace" colSpan="3" style={{borderColor:'white'}}> 
                      Forgetting Work
                    </th>
                  </tr>
          
                  <tr class="vendorListHeading bordersSpace">
                    <th className="bordersSpace" style={{borderColor:'white'}}>Warn 1</th>
                    <th className="bordersSpace" style={{borderColor:'white'}}>Warn 2</th>
                    <th className="bordersSpace" style={{borderColor:'white'}}>Action Taken</th>
                    <th className="bordersSpace" style={{borderColor:'white'}}>Warn 1</th>
                    <th className="bordersSpace" style={{borderColor:'white'}}>Warn 2</th>
                    <th className="bordersSpace" style={{borderColor:'white'}}>Action Taken</th>
                    <th className="bordersSpace" style={{borderColor:'white'}}>Warn 1</th>
                    <th className="bordersSpace" style={{borderColor:'white'}}>Warn 2</th>
                    <th className="bordersSpace" style={{borderColor:'white'}}>Action Taken</th>
                  </tr>

                </thead>
                <ThemeContext.Consumer>
              {(context) => (
                <tbody>
                  {Data.map((item, index) => (
                    <tr class="font-weight-bold bordersSpace" key={index} style={{borderColor:context.themeColors.primaryColor}}>
                      <td className="bordersSpace" style={{borderColor:context.themeColors.primaryColor}}>{index + 1}</td>
                      <td class="bordersSpace font-weight-bold" style={{borderColor:context.themeColors.primaryColor}}>
                        {item.studentName}
                      </td>
                      {item.studentDisciplineList.map((data, indexes) => (
                        <React.Fragment>
                          <td className="bordersSpace" style={{borderColor:context.themeColors.primaryColor}}>{data.classWrn1}</td>
                          <td className="bordersSpace" style={{borderColor:context.themeColors.primaryColor}}>{data.classWrn2}</td>
                          <td className="bordersSpace" style={{borderColor:context.themeColors.primaryColor}}>{data.classAction}</td>
                          <td className="bordersSpace" style={{borderColor:context.themeColors.primaryColor}}>{data.homeWorkWrn1}</td>
                          <td className="bordersSpace" style={{borderColor:context.themeColors.primaryColor}}>{data.homeWorkWrn2}</td>
                          <td className="bordersSpace" style={{borderColor:context.themeColors.primaryColor}}>
                            {data.homeWorkAction}
                          </td>
                          <td className="bordersSpace" style={{borderColor:context.themeColors.primaryColor}}>
                            {data.forgettenBooksWrn1}
                          </td>
                          <td className="bordersSpace" style={{borderColor:context.themeColors.primaryColor}}>
                            {data.forgettenBooksWrn2}
                          </td>
                          <td className="bordersSpace" style={{borderColor:context.themeColors.primaryColor}}>
                            {data.forgettenBooksAction}
                          </td>
                        </React.Fragment>
                      ))}
                    </tr>
                  ))}
                </tbody>
                
)}
</ThemeContext.Consumer>
              </table>
            )}
          </ThemeContext.Consumer>
        </div>
      ) : (
        <div className="table-responsive">
          <ThemeContext.Consumer>
            {(context) => (
              <table class="table text-center">
                <thead
                  class="bordersSpace"
                  style={{
                    background: context.themeColors.primaryColor,
                    color: "white",
                  }}
                >
                  <tr class="vendorListHeading bordersSpace">
                    <th className="bordersSpace" rowSpan="2" style={{borderColor: "white"}}>
                      NO.
                    </th>
                    <th className="bordersSpace" rowSpan="2" style={{borderColor: "white"}}>
                      Students
                    </th>
                    <th className="bordersSpace" colSpan="3" style={{borderColor: "white"}}>
                      Late Coming
                    </th>
                    <th className="bordersSpace" colSpan="3" style={{borderColor: "white"}}>
                      Behaviour
                    </th>
                    <th className="bordersSpace" colSpan="3" style={{borderColor: "white"}}>
                      Other
                    </th>
                  </tr>
                  <tr class="vendorListHeading">
                    <th className="bordersSpace" style={{borderColor: "white"}}>Warn 1</th>
                    <th className="bordersSpace" style={{borderColor: "white"}}>Warn 2</th>
                    <th className="bordersSpace"style={{borderColor: "white"}}>Action Taken</th>
                    <th className="bordersSpace" style={{borderColor: "white"}}>Warn 1</th>
                    <th className="bordersSpace"style={{borderColor: "white"}}>Warn 2</th>
                    <th className="bordersSpace" style={{borderColor: "white"}}>Action Taken</th>
                    <th className="bordersSpace"style={{borderColor: "white"}}>Warn 1</th>
                    <th className="bordersSpace"style={{borderColor: "white"}}>Warn 2</th>
                    <th className="bordersSpace"style={{borderColor: "white"}}>Action Taken</th>
                  </tr>
                </thead>
                <ThemeContext.Consumer>
              {(context) => (
                <tbody>
                  {Data.map((item, index) => (
                    <tr class="font-weight-bold" key={index}>
                      <td className="bordersSpace" style={{borderColor:context.themeColors.primaryColor}}>{index + 1}</td>
                      <td class="bordersSpace font-weight-bold" style={{borderColor:context.themeColors.primaryColor}}>
                        {item.studentName}
                      </td>
                      {item.studentDisciplineList.map((data, indexes) => (
                        <React.Fragment>
                          <td className="bordersSpace" style={{borderColor:context.themeColors.primaryColor}}>{data.lateWrn1}</td>
                          <td className="bordersSpace" style={{borderColor:context.themeColors.primaryColor}}>{data.lateWrn2}</td>
                          <td className="bordersSpace" style={{borderColor:context.themeColors.primaryColor}}>{data.lateAction}</td>
                          <td className="bordersSpace" style={{borderColor:context.themeColors.primaryColor}}>{data.behaviourWrn1}</td>
                          <td className="bordersSpace" style={{borderColor:context.themeColors.primaryColor}}>{data.behaviourWrn2}</td>
                          <td className="bordersSpace" style={{borderColor:context.themeColors.primaryColor}}>
                            {data.behaviourAction}
                          </td>
                          <td className="bordersSpace" style={{borderColor:context.themeColors.primaryColor}}>{data.otherWrn1}</td>
                          <td className="bordersSpace" style={{borderColor:context.themeColors.primaryColor}}>{data.otherWrn2}</td>
                          <td className="bordersSpace" style={{borderColor:context.themeColors.primaryColor}}>{data.otherAction}</td>
                        </React.Fragment>
                      ))}
                    </tr>
                  ))}
                </tbody>
                
)}
</ThemeContext.Consumer>
              </table>
            )}
          </ThemeContext.Consumer>
        </div>
      )}
    </React.Fragment>
  );
}
