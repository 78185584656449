import React, { useEffect, useState } from "react";
import useHttp from "../../../hooks/http.js";
import CustomReportComponent from "../../../components/Reports/CustomReportComponent.jsx";
import img1 from "../../../Assets/images/newheader.png";
import CustomReportHeader from "../../../components/Reports/CustomReportHeader/CustomReportHeader.jsx";
import CustomHeading from "../../../components/CustomHeading";
import { MAIN_HEADING, SUB_HEADING } from "../../../utils/general/index.js";
import useCustomHttpWithDataMiddleware from "../../../hooks/Services/httpWithCustomDataMiddleware.js";
import GraphGeneralLayout from "../../../components/Graphs/GraphGeneralLayout/GraphGeneralLayout.jsx";
import { Bar, Line, Pie } from "react-chartjs-2";
import * as sharedActions from "../../../Actions/sharedActions";
import { bindActionCreators } from "redux";
import { connect, useSelector } from "react-redux";
import $ from "jquery";
import CustomBadge from "../../../components/CustomBadge/CustomBadge.jsx";
import Colors from "../../../utils/app_constants/colors_constants.js";
import SelectionInput from "../../../components/SelectionInput/SelectionInput.jsx";

function CaViewFeature(props) {
  const { pid, type } = props.match.params;
  const [graphType, setGraphType] = useState("barChart");
  const [graphData, setGraphData] = useState({});
  const [isLoading, remoteData] = useHttp(`/api/GetToolGraph?pId=${pid}`, []);
  const [isLoader, fetchData] = useHttp(
    `/api/ViewMonthlyReportCA?pId=${pid}&month=${type}`,
    []
  );
  debugger;
  debugger;
  const returnGraphs = (type) => {
    switch (type) {
      case "barChart":
        return <Bar height={60} data={data} />;

      case "pieChart":
        return <Pie height={60} data={data} />;

      case "lineChart":
        return <Line height={60} data={data} />;

      default:
        break;
    }
  };
  const [data, setData] = useState({
    datasets: [
      {
        barPercentage: 0.4,
        minBarLength: 2,
        label: "Course Average",
        data: [3, 2, 2, 3, 3, 4, 3, 2],
        backgroundColor: [
          "#ea766c",
          "#abe2b9",
          "#adbceb",
          "#be9e9d",
          "#9d5b4d",
          "#e4d86b",
          "#9ecf54",
          "#e4d86b",
        ],
        borderColor: [
          "#ea766c",
          "#abe2b9",
          "#adbceb",
          "#be9e9d",
          "#9d5b4d",
          "#e4d86b",
          "#9ecf54",
          "#e4d86b",
        ],
        borderWidth: 2,
      },
    ],

    // These labels appear in the legend and in the tooltips when hovering different arcs
    labels: ["BIO", "ICT", "MATH", "SOCIAL", "PHYS", "CHEM", "ENG", "ISL"],
    options: {},
  });

  useEffect(() => {
    console.log(data);
    debugger;
    let getData = $.extend(true, {}, data);

    debugger;
    props.sharedAction
      .getDataWithoutDispatch(`/api/GetToolGraph?pId=${pid}`)
      .then((success) => {
        setGraphData(success);
        let getToolsAvg = success["ToolAvgs"].map((item) => item.avg);
        let getToolName = success["ToolAvgs"].map((item) => item.toolName);
        getData["datasets"][0]["data"] = [...getToolsAvg];
        getData["labels"] = [...getToolName];
        setData(getData);
        debugger;
      })
      .catch((error) => {
        debugger;
      });
  }, []);

  const PrintContent = () => {
    // Previous code mentioned below

    setTimeout(() => {
      window.print();
      window.location.reload();
    }, 700);
  };

  const renderTools = (toolName, arr) => {
    return arr.map((item, index) => <div id="mySpan">{item.subToolName}</div>);
  };

  let ToolListTemplates = [
    {
      pId: 2,
      toolId: 7,
      toolName: "Home Work",
      subTools: [
        {
          id: 15,
          toolId: 7,
          toolName: "Home Work",
          pId: 2,
          subToolName: "HW1",
          min: 0,
          max: 15,
        },
        {
          id: 16,
          toolId: 7,
          toolName: "Home Work",
          pId: 2,
          subToolName: "HW2",
          min: 0,
          max: 15,
        },
        {
          id: 17,
          toolId: 7,
          toolName: "Home Work",
          pId: 2,
          subToolName: "HW AVG",
          min: 0,
          max: 15,
        },
      ],
    },
    {
      pId: 2,
      toolId: 8,
      toolName: "Class Work",
      subTools: [
        {
          id: 18,
          toolId: 8,
          toolName: "Class Work",
          pId: 2,
          subToolName: "CW1",
          min: 0,
          max: 15,
        },
        {
          id: 19,
          toolId: 8,
          toolName: "Class Work",
          pId: 2,
          subToolName: "CW2",
          min: 0,
          max: 15,
        },
        {
          id: 20,
          toolId: 8,
          toolName: "Class Work",
          pId: 2,
          subToolName: "CW AVG",
          min: 0,
          max: 15,
        },
      ],
    },
    {
      pId: 2,
      toolId: 9,
      toolName: "Total of CA",
      subTools: [
        {
          id: 21,
          toolId: 9,
          toolName: "Total of CA",
          pId: 2,
          subToolName: "CA",
          min: 0,
          max: 30,
        },
      ],
    },
  ];

  let studentMarks = [
    {
      studentId: 11,
      studentName: "Ahad Raza",
      pId: 2,
      CA_AssignMark: [
        {
          id: 29,
          toolId: 7,
          toolName: "Home Work",
          subToolId: 15,
          subToolName: "HW1",
          pId: 2,
          studentId: 11,
          marks: 12.0,
        },
        {
          id: 30,
          toolId: 7,
          toolName: "Home Work",
          subToolId: 16,
          subToolName: "HW2",
          pId: 2,
          studentId: 11,
          marks: 13.0,
        },
        {
          id: 31,
          toolId: 7,
          toolName: "Home Work",
          subToolId: 17,
          subToolName: "HW AVG",
          pId: 2,
          studentId: 11,
          marks: 12.5,
        },
        {
          id: 32,
          toolId: 8,
          toolName: "Class Work",
          subToolId: 18,
          subToolName: "CW1",
          pId: 2,
          studentId: 11,
          marks: 14.0,
        },
        {
          id: 33,
          toolId: 8,
          toolName: "Class Work",
          subToolId: 19,
          subToolName: "CW2",
          pId: 2,
          studentId: 11,
          marks: 10.0,
        },
        {
          id: 34,
          toolId: 8,
          toolName: "Class Work",
          subToolId: 20,
          subToolName: "CW AVG",
          pId: 2,
          studentId: 11,
          marks: 12.0,
        },
        {
          id: 35,
          toolId: 9,
          toolName: "Total of CA",
          subToolId: 21,
          subToolName: "CW",
          pId: 2,
          studentId: 11,
          marks: 24.5,
        },
      ],
    },
    {
      studentId: 25,
      studentName: "Rafay Khan",
      pId: 2,
      CA_AssignMark: [],
    },
    {
      studentId: 26,
      studentName: "Taimoor Shah",
      pId: 2,
      CA_AssignMark: [],
    },
    {
      studentId: 27,
      studentName: "Nakash Shah",
      pId: 2,
      CA_AssignMark: [],
    },
    {
      studentId: 28,
      studentName: "Wazeer Khan",
      pId: 2,
      CA_AssignMark: [],
    },
  ];
  let getToolsId =
    fetchData && fetchData["ToolListTemplates"].map((item) => item.toolId);

  let getMarksCA_MARKS =
    fetchData && fetchData["studentMarks"].map((item) => item["CA_AssignMark"]);

  const simpleFilter = (arr, Value) => {
    let getElement = arr.filter((item) => item["toolId"] === Value);
    return getElement;
  };
  const changeStrcuture = (toolsNames = [], originalAr = []) => {
    let getNew = toolsNames.map((item) => {
      return { [item]: simpleFilter(originalAr, item) };
    });
    return getNew;
  };

  const renderMarks = (arr) => {
    return arr.map((item, index) => <div>{item.marks}</div>);
  };
  const getDummyToolName = ToolListTemplates.map((item) => item.toolName);
  console.log("toolNAme: ", getDummyToolName);
  const getMarksByToolName = (toolName) => {};
  // console.log(fetchData);
  const getRenderItem = (obj = {}, toolName = []) => {
    let getKey = Object.keys(obj)[0];
    return obj[getKey].map((innerItem, innerIndex) => (
      <div>{innerItem["marks"] === "" ? "" : innerItem["marks"]}</div>
    ));
  };
  const takeArray = (arr = [], toolName) => {
    debugger;
    let getAllMArks = changeStrcuture(getToolsId, arr);
    debugger;
    console.log("marks:", getAllMArks);
    debugger;
    return getAllMArks.map((item, index) => (
      <td>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          {getRenderItem(item, getToolsId)}
        </div>
      </td>
    ));
  };
  const getToolNameForRendering = (nameTool, arr, index) => {
    let getAllMArks = changeStrcuture(getToolsId, arr);
    return getAllMArks[index][nameTool].map((item) => (
      <React.Fragment>
        <td style={{ padding: 10 }}>{item["marks"]}</td>
      </React.Fragment>
    ));
  };
  console.log("Student Discipline Reports", props);
  return (
    <div id="printDiv">
      <div>
        <img src={img1} alt="#img" style={{ width: "100%", height: "30%" }} />
        <CustomHeading
          headingText={"ASSESSMENT OF MONTHLY REPORT"}
          type={MAIN_HEADING}
          className="reportHead"
        />
        <CustomHeading
          headingText={type}
          type={SUB_HEADING}
          className="reportHead"
        />

        <CustomReportHeader
          headRenderData={[
            { displayData: "Subject Name", fieldName: "subject" },
            { displayData: "Grade Name", fieldName: "gradeName" },
            { displayData: "Course Type", fieldName: "courseType" },
          ]}
          targetedObj="primaryInfoes"
          headData={fetchData}
        />

        <CustomReportHeader
          headRenderData={[
            { displayData: "Class Name", fieldName: "className" },
            { displayData: "Section", fieldName: "section" },
            { displayData: "Term", fieldName: "term" },
            { displayData: "Session", fieldName: "session" },
          ]}
          targetedObj="primaryInfoes"
          headData={fetchData}
        />
        <br />
        <table className="table table-bordered">
          <thead>
            <tr>
              <th rowSpan={2}>S #</th>
              <th rowSpan={2}> Student Name </th>
              {fetchData &&
                fetchData["ToolListTemplates"].map((item, index) => (
                  <th style={{ textAlign: "center" }}>{`${item.toolName} (${
                    item &&
                    item.subToolTemplates &&
                    item.subToolTemplates[0] &&
                    item.subToolTemplates[0]["max"]
                  } | ${
                    item &&
                    item.subToolTemplates &&
                    item.subToolTemplates[0] &&
                    item.subToolTemplates[0]["min"]
                  }) `}</th>
                ))}
            </tr>
            <tr>
              {fetchData &&
                fetchData["ToolListTemplates"].map((item, index) => (
                  <th>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        // border: '1px solid black'
                      }}
                    >
                      {renderTools(item.toolName, item.subToolTemplates)}
                    </div>
                  </th>
                ))}
            </tr>
            <React.Fragment>
              {fetchData &&
                fetchData["studentMarks"].map((item, index) => (
                  <React.Fragment>
                    <tr>
                      <td>{index + 1}</td>
                      <td>{item["stduentName"]}</td>
                      {takeArray(item["CA_AssignMark"])}
                    </tr>
                  </React.Fragment>
                ))}
              {/* {studentMarks.map((item, index) => ( */}
              {/* <td>
									<div
										// key={index}
										style={{
											display: 'flex',
											justifyContent: 'space-around'
										}}
									>
										<div>3</div>
										<div>3</div>
										<div>3</div>
									</div>
								</td> */}
              {/* ))} */}
            </React.Fragment>
          </thead>
        </table>
        {/* <table className="table table-bordered">
					<thead>
						<th>
							<table className="table table-bordered">
								<tr>
									<th>S #</th>
									<th> Student Name </th>
									{fetchData &&
										fetchData['ToolListTemplates'].map((outerItem, index) => (
											<td
												style={{
													padding: 0,

													flexDirection: 'column'
												}}
											>
												<tr style={{ textAlign: 'center', display: 'block' }}>
													{outerItem['toolName']}
												</tr>
												<tr style={{ alignSelf: 'center' }}>
													{outerItem['subTools'].map(
														(innerItem, innerIndex) => (
															<React.Fragment>
																<td style={{ fontSize: 11 }} id="mySpan">
																	{' '}
																	{innerItem['subToolName']}
																</td>{' '}
															</React.Fragment>
														)
													)}
												</tr>
											</td>
										))}
								</tr>
								{fetchData &&
									fetchData['studentMarks'].map((item, index) => (
										<tr key={index}>
											<td>{index + 1}</td>
											<td>{item.studentName}</td>
											{getToolsId &&
												getToolsId.map((inneritem, index) => (
													<td>
														{getToolNameForRendering(
															inneritem,
															item['CA_AssignMark'],
															index
														)}
													</td>
												))}
										</tr>
									))}
							</table>
						</th>
					</thead>
				</table> */}
        <br />
        <br />

        {/* <div
					className="lineGraph"
					style={{
						border: `3px solid ${Colors.DARK_SEA_GREEN_THEME}`
					}}
				>
					<CustomBadge
						fontSize={25}
						badgeText={'Analysis'}
						badgeIconClass={'fas fa-chart-bar'}
					/>
					<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
						<div
							style={{
								width: '15%'
							}}
						>
							<SelectionInput
								newFlag="customWidth"
								isValidte={false}
								marginTop={-50}
								feildName={'graphType'}
								selectName={'Graph Type'}
								onHandleChange={e => {
									if (e.target.value !== 'Type') setGraphType(e.target.value);
								}}
								errors={{}}
								optionsArrys={['barChart', 'pieChart', 'lineChart']}
								selectedText={'Type'}
								stateData={{
									graphType: graphType
								}}
								// iconClassName={'fas fa-clock animated fadeIn'}
								optionType="static"
								successflag={'successflag'}
							/>
						</div>
					</div>

					<div style={{ display: 'flex', justifyContent: 'space-around' }}>
						{graphData &&
							graphData['ToolAvgs'] &&
							graphData['ToolAvgs'].map(item => (
								<div
									style={{
										textAlign: 'right',
										color: `${Colors.SEA_GREEN_THEME}`,
										fontWeight: 'bold',
										marginLeft: 5,
										fontSize: 20
									}}
								>
									{` ${item.toolName} (${item.max} to ${item.min})  `}
								</div>
							))}
					</div>
					{returnGraphs(graphType)}
				</div>
			</div> */}

        <div
          style={{ marginTop: 20 }}
          class="text-center text-md-right displayElemet"
        >
          <label for="name" class="" onClick={() => PrintContent("printDev")}>
            <i
              className="fas fa-print fa-2x animated fadeIn"
              style={{ marginRight: 4 }}
            />
            Print It Out
          </label>
        </div>
      </div>
      <div class="page-footer">
        <div class="text-left">Printed By: {props.name}</div>
        <div class="text-right" style={{ marginTop: -25, marginRight: 20 }}>
          Powered by School Smart®
        </div>
      </div>
    </div>
  );
}
function mapDispatchToProps(dispatch) {
  return {
    sharedAction: bindActionCreators(sharedActions, dispatch),
  };
}
function mapStateToProps(state) {
  return {
    role: state.setUserReducer.users.role,
    name: state.setUserReducer.users.unique_name,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(CaViewFeature);
