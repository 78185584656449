import React, { useEffect, useState, useRef, useContext } from "react";
import $ from "jquery";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import * as sharedActions from "../../Actions/sharedActions";
import SnackBar from "../../components/SnackBar/SnackBar";
import SelectionInput from "../../components/SelectionInput/SelectionInput";
import Pagination from "../../components/Pagination/Pagination";
import Spinner from "../../components/Spinner/Spinner";
import httpWithRequiredSetData from "../../hooks/Services/httpWithRequiredSetData";
import general from "../../utils/general";
import Colors from "../../utils/app_constants/colors_constants";
import CustomFilterSectionLayout from "../../components/CustomFilterSectionLayout/CustomFilterSectionLayout";
import ThemeContext from "../../context/themeContext/ThemeContext";
let tHead = [
  "Purpose",
  "Status",
  "Approved By",
  "Account ID",
  "Account Type",
  "Actions",
];

let renderFields = [
  "purpose",
  "status",
  "approvedBy",
  "accountId",
  "accountType",
];

const ViewIndentForm = (props) => {
  const themeContext = useContext(ThemeContext);
  const [eId, setEId] = useState(null);
  const [enabled, setEnabled] = useState(false);

  const [dId, setDId] = useState(null);
  const [status, setStatus] = useState("");

  const [statusModal, setStatusModal] = useState("");
  const [modalData, setModalData] = useState([]);
  const [accountId, setAccountId] = useState(props.name);

  const [indentData, setIndentData] = useState([]);

  const [msg, setSnackMsg] = useState("");
  const [disableBPage, setdisableBPage] = useState(true);
  const [customPageSize, setCustomPageSize] = useState("10");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageNumber, setPageNumber] = useState([]);
  const [disablepage, setdisablepage] = useState(false);
  const [pagination, setPaginationData] = useState({});
  const mounted = useRef();

  const [searchURL, setSearchURL] = useState(
    "/api/IndentForm?pageNumber=1&pageSize=10&status="
  );

  const [
    isLoading,
    fetchData,
    setFetchData,
    setIsLoading,
  ] = httpWithRequiredSetData(searchURL, [], (data) => {
    let pageNumber = [];
    for (let i = 1; i <= data["paginationMetadata"]["totalPages"]; i++) {
      pageNumber.push(i);
    }
    setPageNumber(pageNumber);
    console.log(data);

    setIndentData(data["indentForm"]);

    setPaginationData(data["paginationMetadata"]);
  });

  const onSelectChange = (e) => {
    if (e.target.value !== "Choose Status") {
      setIsLoading(true);
      setStatus(e.target.value);
      props.sharedActions
        .getDataWithoutDispatch(
          `/api/IndentForm?pageNumber=${currentPage}&pageSize=${parseInt(
            customPageSize
          )}&status=${e.target.value}`
        )
        .then((success) => {
          console.log(success);

          setIndentData(success["indentForm"]);
          let pageNumber = [];
          for (
            let i = 1;
            i <= success["paginationMetadata"]["totalPages"];
            i++
          ) {
            pageNumber.push(i);
          }
          setPageNumber(pageNumber);
          setPaginationData(success["paginationMetadata"]);
          setIsLoading(false);
        })
        .catch((error) => {
          setIndentData([]);
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    console.log("Do something after counter has changed", currentPage);
    setIsLoading(true);
    props.sharedActions
      .getDataWithoutDispatch(
        `/api/IndentForm?pageNumber=${currentPage}&pageSize=${parseInt(
          customPageSize
        )}&status=`
      )
      .then((success) => {
        setIndentData(success["indentForm"]);
        setIsLoading(false);
        let pageNumber = [];
        for (let i = 1; i <= success["paginationMetadata"]["totalPages"]; i++) {
          pageNumber.push(i);
        }
        setPageNumber(pageNumber);
        setPaginationData(success["paginationMetadata"]);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, [currentPage, customPageSize]);

  const onSelectStatusChange = (e) => {
    setStatusModal(e.target.value);
  };

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      $('[data-toggle="tooltip"]').tooltip();
    }
  });
  useEffect(() => {
    return () => {
      $('[data-toggle="tooltip"]').tooltip("hide");
    };
  }, []);

  const handleClickNextFrwd = () => {
    console.log("pagelength: ", pageNumber.length);
    console.log("current page: ", currentPage);
    if (currentPage === pageNumber.length) {
      setdisablepage(true);
    } else {
      setCurrentPage((prevState) => prevState + 1);
      console.log(currentPage);

      setdisableBPage(false);
    }
  };

  const handleClickNext = (event) => {
    setCurrentPage(Number(event.target.id));
    setdisableBPage(false);
    setdisableBPage(false);
  };
  const handleClickNextBack = () => {
    if (currentPage === 1) {
      setdisableBPage(true);
      setdisablepage(false);
    } else {
      setCurrentPage((prevState) => prevState - 1);
    }
  };

  const btnTechHover = () => {
    $(document).ready(function () {
      $("button").hover(
        function () {
          $(this).css("box-shadow", "0px 5px 5px grey");
          $(this).css("border-color", "white");
        },
        function () {
          $(this).css("box-shadow", "0px 0px 0px white");
        }
      );
    });
  };

  const onChangePageSize = (e) => {
    if (e.target.value !== "Page Entries") {
      setCustomPageSize(e.target.value);
      setCurrentPage(1);
    }
  };

  const onTakeItem = (itemId) => {
    setModalData([]);

    let getData = indentData.filter((item) => item.id === itemId);
    console.log(getData);
    setModalData(getData);
    setStatusModal(getData[0].status);
    setEId(getData[0].id);
  };

  const onEditClick = (itemId) => {
    onTakeItem(itemId);
    setEnabled(false);
  };

  const onHandleSubmit = (e) => {
    e.preventDefault();

    const data = {
      status: statusModal,
      accountId,
    };

    modalData[0].status = statusModal;
    modalData[0].approvedBy = accountId;

    props.sharedActions
      .editPatchRecordWithoutDispatch("/api/IndentForm/", eId, data)
      .then(
        indentData.forEach((item, index) => {
          if (item.id === eId) {
            indentData.splice(index, 1, modalData[0]);
          }
        }),
        setSnackMsg("Edited Record Successfully!"),
        setEId(""),
        setStatusModal(""),
        setModalData([]),
        props.snackbar()
      );
    $("#myModal").modal("toggle"); //or  $('#IDModal').modal('hide');
  };

  const setDeleteID = (id) => {
    setDId(id);
    console.log(id);
  };

  const onClickDelete = (id) => {
    setIsLoading(true);

    props.sharedActions
      .deleteRecordWithoutDispatch("/api/IndentForm/", dId)
      .then((success) => {
        // if (success.status === 200) {
        let getNewFilterArray = indentData.filter((item) => item.id !== dId);
        setSnackMsg("Removed Successfully!");
        setIndentData(getNewFilterArray);
        setIsLoading(false);
        props.snackbar();
        setDId(null);
        // }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const bodyRendering = (data) =>
    data &&
    data.map((item, index) => (
      <tr>
        <th
          style={{
            padding: 2,
            border: `1px solid ${Colors.SEA_GREEN_THEME}`,
            borderColor: Colors.SEA_GREEN_THEME,
          }}
          scope="row"
        >
          {index + 1}
        </th>
        {renderFields.map((item1, index) => (
          <td
            style={{
              fontWeight: `${index == 0 ? "bold" : "normal"}`,
              border: `1px solid ${Colors.SEA_GREEN_THEME}`,
              padding: 2,
              borderColor: Colors.SEA_GREEN_THEME,
            }}
          >
            {item[item1]}
          </td>
        ))}
        <td
          style={{
            border: `1px solid ${Colors.SEA_GREEN_THEME}`,
            padding: 2,
            borderColor: Colors.SEA_GREEN_THEME,
          }}
        >
          <Link to={`/dashboard/IndentFormTemplate/${item.id}`}>
            <button
              id="firstbutton"
              // onClick={() => this.onEditClick(item.id)}
              onMouseOver={btnTechHover()}
              style={{
                cursor: "pointer",
                fontSize: 20,
                border: "none",
                marginLeft: 5,
                background: "none",
                marginBottom: 5,
              }}
            >
              <a href="#" data-toggle="tooltip" title="View!">
                <i style={{ color: "#01AC8A" }} class="fas fa-list"></i>
              </a>
            </button>
          </Link>
          <a href="#" data-toggle="tooltip" title="Edit!">
            <button
              id="firstbutton"
              onClick={() => onEditClick(item.id)}
              onMouseOver={btnTechHover()}
              data-toggle="modal"
              data-target="#myModal"
              style={{
                cursor: "pointer",
                fontSize: 20,
                border: "none",
                marginLeft: 5,
                background: "none",
                marginBottom: 5,
              }}
            >
              <i class="fas fa-pen"></i>
            </button>
          </a>
          <a href="#" data-toggle="tooltip" title="Delete!">
            <button
              onClick={() => setDeleteID(item.id)}
              data-toggle="modal"
              data-target="#myModal1"
              style={{
                cursor: "pointer",
                fontSize: 20,
                border: "none",
                background: "none",
                marginLeft: 5,
                marginBottom: 5,
              }}
            >
              <i style={{ color: "#D11A2A" }} class="fas fa-trash-alt"></i>
            </button>
          </a>
        </td>
      </tr>
    ));

  return (
    <div>
      <SnackBar msg={msg} />
      <div class="modal" id="myModal1">
        <div class="modal-dialog">
          <div class="modal-content modal_content_custom">
            <div
              style={{
                borderBottomLeftRadius: 30,
                borderBottomRightRadius: 30,
                borderTopLeftRadius: 18,
                borderTopRightRadius: 18,
              }}
              class="modal-header modal_custom_header"
            >
              <h4 style={{ color: "white" }} class="modal-title">
                Confirm
              </h4>
              <button
                style={{ color: "white" }}
                type="button"
                class="close"
                data-dismiss="modal"
              >
                &times;
              </button>
            </div>
            <div class="modal-body">
              <div>
                <div style={{ textAlign: "center" }}>
                  <h4>Are you sure ?</h4>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                  }}
                >
                  <div>
                    <button
                      data-dismiss="modal"
                      onClick={() => onClickDelete(dId)}
                      type="button"
                      class="btn btn-primary buttonAppear"
                    >
                      Yes
                    </button>
                  </div>
                  <div>
                    <button
                      data-dismiss="modal"
                      type="button"
                      class="btn btn-primary buttonAppear"
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal" id="myModal">
        <div class="modal-dialog">
          <div class="modal-content modal_content_custom">
            <div
              style={{
                borderBottomLeftRadius: 30,
                borderBottomRightRadius: 30,
                borderTopLeftRadius: 18,
                borderTopRightRadius: 18,
              }}
              class="modal-header modal_custom_header"
            >
              <h4 style={{ color: "white" }} class="modal-title">
                Details
              </h4>
              <button
                style={{ color: "white" }}
                type="button"
                class="close"
                data-dismiss="modal"
              >
                &times;
              </button>
            </div>
            <div class="modal-body">
              <form onSubmit={(e) => onHandleSubmit(e)}>
                {modalData &&
                  modalData.map((item, index) => (
                    <div key={index}>
                      <fieldset disabled={!enabled}>
                        <SelectionInput
                          newFlag="customWidth"
                          feildName="statusModal"
                          selectedText={"Choose Status"}
                          optionsArrys={general.indentDropDown}
                          errors={{}}
                          isValidte={false}
                          stateData={{
                            status: statusModal,
                          }}
                          optionType="static"
                          onHandleChange={(e) => onSelectStatusChange(e)}
                          useFlag={false}
                        />
                      </fieldset>
                      <br />
                      <br />
                      <button
                        disabled={enabled}
                        onClick={() => setEnabled(true)}
                        type="button"
                        class="btn btn-primary buttonAppear"
                      >
                        Edit
                      </button>
                      <button
                        style={{ marginLeft: 5 }}
                        disabled={!enabled}
                        type="submit"
                        class="btn btn-primary buttonAppear"
                      >
                        Save
                      </button>
                    </div>
                  ))}
              </form>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                style={{ background: "#00435D" }}
                class="btn btn-danger"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      <div style={{ textAlign: "right" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <h2
              style={{ background: themeContext.themeColors.primaryColor }}
              className="h1-responsive font-weight-bold text-left my-4 generalHead"
            >
              {" "}
              Indent Form{" "}
            </h2>
          </div>
          <div
            style={{ marginTop: 40, display: "flex", flexDirection: "column" }}
          >
            <Pagination
              disableBPage={disableBPage}
              disablepage={disablepage}
              currentPage={currentPage}
              pageNumber={pageNumber}
              handleClickNext={handleClickNext}
              handleClickNextBack={handleClickNextBack}
              handleClickNextFrwd={handleClickNextFrwd}
            />
            <div
              style={{
                width: "fit-content",
                marginTop: -34,
                display: "flex",
                justifyContent: "flex-end",
                marginLeft: "auto",
              }}
            >
              <SelectionInput
                selectName="Entries"
                selectFont={14}
                selectFontWeight={"bold"}
                newFlag="customWidth"
                feildName="customPageSize"
                selectedText={"Page Entries"}
                optionsArrys={["10", "20", "30", "40", "50"]}
                errors={{}}
                isValidte={false}
                stateData={{
                  customPageSize: customPageSize,
                }}
                optionType="static"
                onHandleChange={onChangePageSize}
                useFlag={false}
              />
            </div>
          </div>
        </div>
        <CustomFilterSectionLayout displayDirection="column">
          <div
            style={{
              width: "30%",
              margin: "0 auto",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <React.Fragment>
              <SelectionInput
                newFlag="customWidth"
                feildName="status"
                selectedText={"Choose Status"}
                optionsArrys={general.indentDropDown}
                errors={{}}
                isValidte={false}
                stateData={{
                  status: status,
                }}
                optionType="static"
                onHandleChange={(e) => onSelectChange(e)}
                useFlag={false}
              />
            </React.Fragment>
          </div>
        </CustomFilterSectionLayout>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span className="customBadge" style={{ fontSize: 14 }}>
            Page: {currentPage}
          </span>

          <span className="customBadge" style={{ fontSize: 14 }}>
            Total Page: {pagination["totalPages"]}
          </span>
        </div>
      </div>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="table-responsive">
          <table class="table table-hover">
            <thead style={{ background: "#01AC8A", color: "white" }}>
              <tr>
                <th
                  style={{ background: themeContext.themeColors.primaryColor }}
                  scope="col"
                >
                  #
                </th>
                {tHead.map((item) => (
                  <th
                    style={{
                      border: `1px solid ${Colors.SEA_GREEN_THEME}`,
                      borderColor: Colors.WHITE,
                      background: themeContext.themeColors.primaryColor,
                    }}
                    scope="col"
                  >
                    {item}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>{bodyRendering(indentData)}</tbody>
          </table>
        </div>
      )}
    </div>
  );
};
function mapStateToProps(state) {
  return {
    name: state.setUserReducer.users.unique_name,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sharedActions: bindActionCreators(sharedActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewIndentForm);
