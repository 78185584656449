import * as actionTypes from '../actionTypes';

export function setAllClassDisciplineInRedux(allData) {
	return {
		type: actionTypes.GET_ALL_STUDENT_IN_CLASS_DISCIPLINE,
		payload: allData
	};
}
export function addClassDiscipline(Data) {
	return {
		type: actionTypes.ADD_CLASS_DISCIPLINE,
		payload: Data
	};
}
export function updateClassDisciplineFlag(setFlag) {
	return {
		type: actionTypes.GET_LOADER_FLAG_ACTIVE,
		payload: setFlag
	};
}
// export function updateCampus(data) {
// 	return {
// 		type: actionTypes.EDIT_CAMPUS_RECORD,
// 		payload: data
// 	};
// }
// export function deleteCampus(id) {
// 	return {
// 		type: actionTypes.DELETE_CAMPUS,
// 		payload: id
// 	};
// }
