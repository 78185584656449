import React, { useEffect, useState, useContext } from 'react'
import $ from 'jquery';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import SelectionInput from '../../components/SelectionInput/SelectionInput';
import Pagination from '../../components/Pagination/Pagination'
import Spinner from '../../components/Spinner/Spinner';
import httpWithRequiredSetData from '../../hooks/Services/httpWithRequiredSetData';
import general from '../../utils/general'
import Colors from '../../utils/app_constants/colors_constants';
import CustomFilterSectionLayout from '../../components/CustomFilterSectionLayout/CustomFilterSectionLayout';
import ThemeContext from "../../context/themeContext/ThemeContext";

let tHead = [
    'Class Name',
    'Section Name',
    'Grade Name',
    'Staff Name',
    'Subject Name',
    'Account ID',
    'Account Type',
    'Actions'
];

let renderFields = [
    'className',
    'section',
    'gradeName',
    'staffName',
    'subjectName',
    'accountId',
    'accountType',
]

const ViewAssignSubject = props => {
    const themeColor = useContext(ThemeContext);

    const [classId, setClassId] = useState('');
    const [sectionId, setSectionId] = useState('');
    const [dId, setDId] = useState(null);

    const [classData, setClassData] = useState([]);
    const [sectionData, setSectionData] = useState([]);
    const [staffData, setStaffData] = useState([]);

    const [isClassSectionLoading, classSectionData] = httpWithRequiredSetData(
        '/api/ClassesSections',
        [],
        data => {
            setClassData(data['classes']);
        }
    );
    const [searchURL, setSearchURL] = useState(
        '/api/ViewAssignSubject?pageNumber=1&pageSize=10'
    );


    const [disableBPage, setdisableBPage] = useState(true);
    const [customPageSize, setCustomPageSize] = useState('10');
    const [currentPage, setCurrentPage] = useState(1);
    const [pageNumber, setPageNumber] = useState([]);
    const [disablepage, setdisablepage] = useState(false);
    const [pagination, setPaginationData] = useState({});
    

    const [
        isLoading,
        fetchData,
        setFetchData,
        setIsLoading
    ] = httpWithRequiredSetData(searchURL, [], data => {
        let pageNumber = [];
        for (let i = 1; i <= data['paginationMetadata']['totalPages']; i++) {
            pageNumber.push(i);
        }
        setPageNumber(pageNumber);
        setStaffData(data['staff']);

        setPaginationData(data['paginationMetadata']);
    });

    const onClassChange = inputClassId => {
        setSectionId('');
        if (inputClassId !== 'Choose Class') {
            console.log(classSectionData);

            let getSection = general.dataFilteration(
                classSectionData['gradeSection'],
                ['sectionId', 'section'],
                {
                    id: inputClassId
                }
            );

            setIsLoading(true);
            setClassId(inputClassId);
            props.sharedActions
                .getDataWithoutDispatch(
                    `/api/ViewAssignSubjectClass?pageNumber=${currentPage}&pageSize=${parseInt(customPageSize)}&classId=${parseInt(inputClassId)}`
                )
                .then(success => {
                    console.log(success);

                    setStaffData(success['staff']);
                    let pageNumber = [];
                    for (
                        let i = 1;
                        i <= success['paginationMetadata']['totalPages'];
                        i++
                    ) {
                        pageNumber.push(i);
                    }
                    setPageNumber(pageNumber);
                    setPaginationData(success['paginationMetadata']);
                    setSectionData(getSection);

                    setIsLoading(false);
                })
                .catch(error => {
                    setIsLoading(false);
                });
        }
    };


    const onSectionChange = inputSectionId => {
        if (inputSectionId !== 'Choose Section') {
            setIsLoading(true);
            setSectionId(inputSectionId);
            props.sharedActions
                .getDataWithoutDispatch(
                    `/api/ViewAssignSubjectClassSection?pageNumber=${currentPage}&pageSize=${parseInt(customPageSize)}&classId=${parseInt(classId)}&sectionId=${parseInt(inputSectionId)}`
                )
                .then(success => {
                    setStaffData(success['staff']);
                    let pageNumber = [];
                    for (
                        let i = 1;
                        i <= success['paginationMetadata']['totalPages'];
                        i++
                    ) {
                        pageNumber.push(i);
                    }
                    setPageNumber(pageNumber);
                    setPaginationData(success['paginationMetadata']);

                    setIsLoading(false);
                })
                .catch(error => {
                    setIsLoading(false);
                });
        }
    };

  
    useEffect(() => {
        return () => {
            $('[data-toggle="tooltip"]').tooltip('hide');
        };
    }, []);

    const handleClickNextFrwd = () => {
        console.log('pagelength: ', pageNumber.length);
        console.log('current page: ', currentPage);
        if (currentPage === pageNumber.length) {
            setdisablepage(true);
        } else {
            setCurrentPage(prevState => prevState + 1);
            console.log(currentPage);

            setdisableBPage(false);
        }
    };

    useEffect(() => {
        console.log('Do something after counter has changed', currentPage);
        setIsLoading(true);
        props.sharedActions
            .getDataWithoutDispatch(
                general.condtionalServicesSubject(
                    classId,
                    sectionId,
                    currentPage,
                    customPageSize
                )
            )
            .then(success => {
                setStaffData(success['staff']);
                setIsLoading(false);
                let pageNumber = [];
                for (let i = 1; i <= success['paginationMetadata']['totalPages']; i++) {
                    pageNumber.push(i);
                }
                setPageNumber(pageNumber);
                setPaginationData(success['paginationMetadata']);
            })
            .catch(error => {
                setIsLoading(false);
            });
    }, [currentPage, /* getdepartmentId, */ customPageSize]);
    const handleClickNext = event => {
        setCurrentPage(Number(event.target.id));
        setdisableBPage(false);
        setdisableBPage(false);
    };
    const handleClickNextBack = () => {
        if (currentPage === 1) {
            setdisableBPage(true);
            setdisablepage(false);
        } else {
            setCurrentPage(prevState => prevState - 1);
        }
    };
    const [msg, setSnackMsg] = useState('');

    const onChangePageSize = e => {
        if (e.target.value !== 'Page Entries') {
            setCustomPageSize(e.target.value);
            setCurrentPage(1);
        }
    };

    const setDeleteID = id => {
        setDId(id);
    };

    const onClickDelete = id => {
        setIsLoading(true)

        props.sharedActions
            .deleteRecordWithoutDispatch(
                '/api/AssignSubjectsToTeacher/',
                dId
            )
            .then(success => {
                // if (success.status === 200) {
                let getNewFilterArray = staffData.filter(item => item.id !== dId);
                setSnackMsg('Remove Successfully!');
                setStaffData(getNewFilterArray);
                setIsLoading(false)
                props.snackbar();
                setDId(null);
                // }
            })
            .catch(error => {
                setIsLoading(false)
                console.log(error);
            });
    };


    const bodyRendering = data =>
        data &&
        data.map((item, index) => (
            <tr>
                <th
                    style={{
                        padding: 2,
                        border: `1px solid ${themeColor.themeColors.primaryColor}`,
                        borderColor: themeColor.themeColors.primaryColor
                    }}
                    scope="row"
                >
                    {index + 1}
                </th>
                {renderFields.map((item1, index) => (
                    <td
                        style={{
                            fontWeight: `${index == 0 ? 'bold' : 'normal'}`,
                            border: `1px solid ${themeColor.themeColors.primaryColor}`,
                            padding: 2,
                            borderColor: themeColor.themeColors.primaryColor
                        }}
                    >
                        {item[item1]}
                    </td>
                ))}
                <td
                    style={{
                        border: `1px solid ${themeColor.themeColors.primaryColor}`,
                        padding: 2,
                        borderColor: themeColor.themeColors.primaryColor
                    }}
                >
                    <a href="#" data-toggle="tooltip" title="Delete!">
                        <button
                            onClick={() => setDeleteID(item.id)}
                            data-toggle="modal"
                            data-target="#myModal1"
                            style={{
                                cursor: 'pointer',
                                fontSize: 20,
                                border: 'none',
                                background: 'none',
                                marginLeft: 5,
                                marginBottom: 5
                            }}
                        >
                            <i style={{ color: '#D11A2A' }} class="fas fa-trash-alt"></i>
                        </button>
                    </a>
                </td>
            </tr>
        ));

    return (
        <div>
            <SnackBar msg={msg} />
            <div class="modal" id="myModal1">
                <div class="modal-dialog">
                    <div class="modal-content modal_content_custom">
                        <div
                            style={{
                                borderBottomLeftRadius: 30,
                                borderBottomRightRadius: 30,
                                borderTopLeftRadius: 18,
                                borderTopRightRadius: 18,
                                background: themeColor.themeColors.primaryColor
                            }}
                            class="modal-header modal_custom_header"
                        >
                            <h4 style={{ color: 'white' }} class="modal-title">
                                Confirm
							</h4>
                            <button
                                style={{ color: 'white' }}
                                type="button"
                                class="close"
                                data-dismiss="modal"
                            >
                                &times;
							</button>
                        </div>
                        <div class="modal-body">
                            <div>
                                <div style={{ textAlign: 'center' }}>
                                    <h4>Are you sure ?</h4>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-evenly'
                                    }}
                                >
                                    <div>
                                        <button
                                            // disabled={this.state.enabled}
                                            data-dismiss="modal"
                                            onClick={() => onClickDelete(dId)}
                                            type="button"
                                            class="btn btn-primary buttonAppear"
                                            style={{background: themeColor.themeColors.primaryColor}}
                                        >
                                            Yes
										</button>
                                    </div>
                                    <div>
                                        <button
                                            data-dismiss="modal"
                                            // disabled={this.state.enabled}
                                            type="button"
                                            class="btn btn-primary buttonAppear"
                                            style={{background: themeColor.themeColors.primaryColor}}
                                        >
                                            No
										</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ textAlign: 'right' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        <h2 className="h1-responsive font-weight-bold text-left my-4 generalHead" style={{background: themeColor.themeColors.primaryColor}}>
                            {' '}
                            Assigned Subjects{' '}
                        </h2>
                    </div>
                    <div
                        style={{ marginTop: 40, display: 'flex', flexDirection: 'column' }}
                    >
                        <Pagination
                            disableBPage={disableBPage}
                            disablepage={disablepage}
                            currentPage={currentPage}
                            pageNumber={pageNumber}
                            handleClickNext={handleClickNext}
                            handleClickNextBack={handleClickNextBack}
                            handleClickNextFrwd={handleClickNextFrwd}
                        />
                        <div
                            style={{
                                width: 'fit-content',
                                marginTop: -34,
                                display: 'flex',
                                justifyContent: 'flex-end',
                                marginLeft: 'auto'
                            }}
                        >
                            <SelectionInput
                                selectName="Entries"
                                selectFont={14}
                                selectFontWeight={'bold'}
                                newFlag="customWidth"
                                feildName="customPageSize"
                                selectedText={'Page Entries'}
                                optionsArrys={['10', '20', '30', '40', '50']}
                                errors={{}}
                                isValidte={false}
                                stateData={{
                                    customPageSize: customPageSize
                                }}
                                optionType="static"
                                onHandleChange={onChangePageSize}
                                useFlag={false}
                            />
                        </div>
                    </div>
                </div>
                <CustomFilterSectionLayout displayDirection="column">
                    <div
                        style={{
                            width: '30%',
                            margin: '0 auto',
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                        <React.Fragment>
                            <SelectionInput
                                newFlag="customWidth"
                                feildName="classId"
                                selectedText={'Choose Class'}
                                optionsArrys={classData}
                                errors={{}}
                                isValidte={false}
                                stateData={{
                                    classId: classId
                                }}
                                optionType="dynamic"
                                onHandleChange={e => onClassChange(e.target.value)}
                                property={'className'}
                                propertyId={'id'}
                                useFlag={false}
                            />
                            <SelectionInput
                                newFlag="customWidth"
                                feildName="sectionId"
                                selectedText={'Choose Section'}
                                optionsArrys={sectionData}
                                errors={{}}
                                isValidte={false}
                                stateData={{
                                    sectionId: sectionId
                                }}
                                optionType="dynamic"
                                onHandleChange={e => onSectionChange(e.target.value)}
                                property={'section'}
                                propertyId={'sectionId'}
                                useFlag={false}
                            />
                        </React.Fragment>
                    </div>
                </CustomFilterSectionLayout>

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span className="customBadge" style={{ fontSize: 14 }}>
                        Page: {currentPage}
                    </span>

                    <span className="customBadge" style={{ fontSize: 14 }}>
                        Total Page: {pagination['totalPages']}
                    </span>
                </div>
            </div>
            {isLoading ? (
                <Spinner />
            ) : (
                    <div className="table-responsive">
                        <table class="table table-hover">
                            <thead style={{ background: themeColor.themeColors.primaryColor, color: 'white' }}>
                                <tr>
                                    <th scope="col">#</th>
                                    {tHead.map(item => (
                                        <th
                                            style={{
                                                border: `1px solid ${themeColor.themeColors.primaryColor}`,
                                                borderColor: Colors.WHITE
                                            }}
                                            scope="col"
                                        >
                                            {item}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>{bodyRendering(staffData)}</tbody>
                        </table>
                    </div>
                )}
        </div>
    );
};

function mapDispatchToProps(dispatch) {
    return {
        sharedActions: bindActionCreators(sharedActions, dispatch)
    };
}
export default connect(
    null,
    mapDispatchToProps
)(ViewAssignSubject);