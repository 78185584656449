import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import DateandTime from '../Transport/DateandTime';
import SnackBar from '../../components/SnackBar/SnackBar';
import isNull from 'lodash/isNull'
import { isEmpty } from 'lodash';

import LoaderWrapper from "../../components/HOC/LoaderWrapper";
import ThemeContext from "../../context/themeContext/ThemeContext";






class InitiateAcademicCalendar extends Component {
    constructor(props){
        super(props);
        this.state = {
            errorMsg: false,
            successMsg: false,
            msg: '',
            isLoading: false,
            monthList: [],
            yearList: [],
            staffId: null,
            floorId: null,
            roomId: null,
            categoryId: null,
            description: null,

            sessionId: this.props.location.state.sessionId
        }
    }

        componentDidMount(){
            this.getSimple('Session', 'session')
            this.generateMonthAndYear(30)
        }
    
        generateMonthAndYear = (limit) => {
            const year = new Date().getFullYear();
            const years = Array.from(new Array(limit), (val, index) => index + year);
            this.setState({
                yearList: years,
                monthList: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
            })
        }

    getSimple = (type, state) => {
        this.props.sharedActions.getDataWithoutDispatch(
            `/api/${type}`
        ).then(success => {
            this.setState({
                [state]: success,
                isLoading: false
            })


        }).catch(error => {
            this.setState({
                msg: 'Error Occured!!',
                errorMsg: true,
                isLoading: false
            })
            console.error('Error occured', error);
        })
    }

    myFunction = () => {
		// Get the snackbar DIV
		var x = document.getElementById('snackbar');

		// StepperGeneralButtons the "show" class to DIV
		x.className = 'show';

		// After 3 seconds, remove the show class from DIV
		setTimeout(function() {
		}, 3000);
	};

    onHandleTextChange = e => {
        console.log([e.target.name], e.target.value);
        
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleSubmit = e => {
        e.preventDefault();
        const { fromMonth, fromYear, toMonth, toYear, monthList } = this.state;
        const { sessionId } = this.props.location.state;
        console.log(sessionId, fromMonth, fromYear, toMonth, toYear);
        

        if( isNull(sessionId) || isEmpty(fromMonth) || isEmpty(fromYear) || isEmpty(toMonth) || isEmpty(toYear) ){
            this.setState({
                msg: 'Please fill the form respectively!'
            })
            this.props.snackbar();
        }else{
            let calenderData = []
            let yearList = Array.from(new Array((toYear - fromYear) + 1), (val, index) => index + Number(fromYear));
            yearList.map((data, yearIndex) => {
                switch (yearIndex) {
                    case 0:
                        calenderData.push({
                            year: data,
                            monthList: monthList.slice(monthList.indexOf(fromMonth))
                        })
                        break;
                    case yearList.length - 1:
                        calenderData.push({
                            year: data,
                            monthList: monthList.slice(0, monthList.indexOf(toMonth) + 1)
                        })
                        break;
                    default:
                        calenderData.push({
                            year: data,
                            monthList: monthList
                        })
                        break;
                }
                
            })
            console.log(calenderData);
            
            this.props.history.push({pathname: '/dashboard/AddAcademicCalendar', state: {
                sessionId, fromMonth, fromYear, toMonth, toYear, calenderData
            }})
        }

    }   


    render(){

        const { successMsg, errorMsg, msg, isLoading, session, monthList, yearList,
            sessionId, fromMonth, fromYear, toMonth, toYear
        } = this.state;

        return (
			<div className="container">
				<section class="mb-4">
				<ThemeContext.Consumer>
                    {(context) => (
                    <h2  
                        style={{ background: context.themeColors.primaryColor }}
                        className="h1-responsive font-weight-bold text-center my-4 generalHead">
                        Add Academic Calendar
                    </h2>
                     )}
                </ThemeContext.Consumer>
				<SnackBar msg={this.state.msg} backColor={this.state.backColor} />

				 <p class="text-center w-responsive mx-auto mb-5" />

                    <div className="row" style={{
                        justifyContent: 'center',
                        textAlign: 'center'
                    }}>

                <ThemeContext.Consumer>
                {(context) => (
                        <div className="col-md-9 mb-md-0 mb-5" style={{
                            border: 'solid',
                            borderColor: context.themeColors.primaryColor,
                            padding: 40,
                            borderRadius: 15,
                            width: 'auto'

                        }}>
                      <LoaderWrapper isLoading={this.state.isLoading}>  
                            <form id="emergencyMaintenance-form" name="emergencyMaintenance-form" onSubmit={this.handleSubmit} >
                                <div className="row">
									<div className="col-md-12">
										<div className="md-form mb-0">
											<DateandTime />
										</div>
									</div>
								</div>
								<br />
                                <div class="row">
									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="AdminName" class="">
												Admin Name
											</label>
											<input
												
												type="text"
												name="AdminName"
												class="form-control"
												readOnly={true}
												value={this.props.userData.unique_name}
											/>
										</div>
								    </div>

									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="AdminType" class="">
												Admin Type
											</label>
											<input
												
												type="text"
												name="AdminType"
												class="form-control"
												readOnly={true}
												value={this.props.userData.role}
											/>
										</div>
								    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="md-form mb-0" >
                                            <label for="sessionId">
                                                Session
                                            </label>
                                            <select class="custom-select" 
                                                value={sessionId}
                                                name="sessionId"
                                                onChange={this.onHandleTextChange}
                                                disabled
                                            >
                                                        <option value="">Please Select Session</option>
                                                       {session && session.session.map((value, index) => {

                                                                return <option value={value.sessionId}>{value.currentSession}</option>

                                                       })}
                                            </select>  
                                        </div>
                                        </div>
                                        <div className="col-md-12" >
                                                       <label>From</label>
                                        </div>
                                        <div className="col-md-6">
                                        <div className="md-form mb-0" >
                                            <label for="fromMonth">
                                                Month
                                            </label>
                                            <select class="custom-select" 
                                                value={fromMonth}
                                                name="fromMonth"
                                                onChange={this.onHandleTextChange}
                                                disabled={!sessionId}
                                            >
                                                        <option value="">Starting Month</option>
                                                       {monthList && monthList.map((value, index) => {
                                                           
                                                           return <option value={value}>{value}</option>
                                                       })}
                                            </select>  
                                        </div>
                                        </div>
                                        <div className="col-md-6">
                                        <div className="md-form mb-0" >
                                            <label for="fromYear">
                                                Year
                                            </label>
                                            <select class="custom-select" 
                                                value={fromYear}
                                                name="fromYear"
                                                onChange={this.onHandleTextChange}
                                                disabled={!sessionId}
                                            >
                                                        <option value="">Starting Year</option>
                                                       {yearList && yearList.map((value, index) => {
                                                           
                                                           return <option value={value}>{value}</option>
                                                       })}
                                            </select>  
                                        </div>
                                        </div>

                                        
                                        <div className="col-md-12" >
                                                       <label>To</label>
                                        </div>
                                        <div className="col-md-6">
                                        <div className="md-form mb-0" >
                                            <label for="toMonth">
                                                Month
                                            </label>
                                            <select class="custom-select" 
                                                value={toMonth}
                                                name="toMonth"
                                                onChange={this.onHandleTextChange}
                                                disabled={!sessionId}
                                            >
                                                        <option value="">Ending Month</option>
                                                       {monthList && monthList.map((value, index) => {
                                                           
                                                           return <option value={value}>{value}</option>
                                                       })}
                                            </select>  
                                        </div>
                                        </div>
                                        <div className="col-md-6">
                                        <div className="md-form mb-0" >
                                            <label for="toYear">
                                                Year
                                            </label>
                                            <select class="custom-select" 
                                                value={toYear}
                                                name="toYear"
                                                onChange={this.onHandleTextChange}
                                                disabled={!sessionId}
                                            >
                                                        <option value="">Ending Year</option>
                                                       {yearList && yearList.map((value, index) => {
                                                           
                                                           return <option value={value}>{value}</option>
                                                       })}
                                            </select>  
                                        </div>
                                        </div>
                                </div>
                                        <br/>
                                        <br/>
                                        <div className="text-center text-md-center">
								<button type="submit" className="btns" style={{
                                                    background: context.themeColors.primaryColor,
                                            }}>
										Add
										<span
											style={{ marginBottom: 5 }}
											class={`${
												this.state.isLoading
													? 'spinner-border spinner-border-sm'
													: ''
											}`}
										></span>
									</button>
								</div>
							</form>
							</LoaderWrapper>
                            <div class="status" />
                </div>
              )}
            </ThemeContext.Consumer>
                    </div>
				</section>
			</div>
        );
    }

}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch),
	};
}
function mapStateToProps(state) {
	return {
		userData: state.setUserReducer.users,
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(InitiateAcademicCalendar);
