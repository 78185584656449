import $ from "jquery";
import Colors from "../app_constants/colors_constants";
import { JsonWebTokenError } from "jsonwebtoken";

// all counteris
export const courseType = ["Elective", "Compulsory"];
export let allCountries = [
  { name: "Afghanistan", code: "AF" },
  { name: "Åland Islands", code: "AX" },
  { name: "Albania", code: "AL" },
  { name: "Algeria", code: "DZ" },
  { name: "American Samoa", code: "AS" },
  { name: "AndorrA", code: "AD" },
  { name: "Angola", code: "AO" },
  { name: "Anguilla", code: "AI" },
  { name: "Antarctica", code: "AQ" },
  { name: "Antigua and Barbuda", code: "AG" },
  { name: "Argentina", code: "AR" },
  { name: "Armenia", code: "AM" },
  { name: "Aruba", code: "AW" },
  { name: "Australia", code: "AU" },
  { name: "Austria", code: "AT" },
  { name: "Azerbaijan", code: "AZ" },
  { name: "Bahamas", code: "BS" },
  { name: "Bahrain", code: "BH" },
  { name: "Bangladesh", code: "BD" },
  { name: "Barbados", code: "BB" },
  { name: "Belarus", code: "BY" },
  { name: "Belgium", code: "BE" },
  { name: "Belize", code: "BZ" },
  { name: "Benin", code: "BJ" },
  { name: "Bermuda", code: "BM" },
  { name: "Bhutan", code: "BT" },
  { name: "Bolivia", code: "BO" },
  { name: "Bosnia and Herzegovina", code: "BA" },
  { name: "Botswana", code: "BW" },
  { name: "Bouvet Island", code: "BV" },
  { name: "Brazil", code: "BR" },
  { name: "British Indian Ocean Territory", code: "IO" },
  { name: "Brunei Darussalam", code: "BN" },
  { name: "Bulgaria", code: "BG" },
  { name: "Burkina Faso", code: "BF" },
  { name: "Burundi", code: "BI" },
  { name: "Cambodia", code: "KH" },
  { name: "Cameroon", code: "CM" },
  { name: "Canada", code: "CA" },
  { name: "Cape Verde", code: "CV" },
  { name: "Cayman Islands", code: "KY" },
  { name: "Central African Republic", code: "CF" },
  { name: "Chad", code: "TD" },
  { name: "Chile", code: "CL" },
  { name: "China", code: "CN" },
  { name: "Christmas Island", code: "CX" },
  { name: "Cocos (Keeling) Islands", code: "CC" },
  { name: "Colombia", code: "CO" },
  { name: "Comoros", code: "KM" },
  { name: "Congo", code: "CG" },
  { name: "Congo, The Democratic Republic of the", code: "CD" },
  { name: "Cook Islands", code: "CK" },
  { name: "Costa Rica", code: "CR" },
  { name: "Cote D'Ivoire", code: "CI" },
  { name: "Croatia", code: "HR" },
  { name: "Cuba", code: "CU" },
  { name: "Cyprus", code: "CY" },
  { name: "Czech Republic", code: "CZ" },
  { name: "Denmark", code: "DK" },
  { name: "Djibouti", code: "DJ" },
  { name: "Dominica", code: "DM" },
  { name: "Dominican Republic", code: "DO" },
  { name: "Ecuador", code: "EC" },
  { name: "Egypt", code: "EG" },
  { name: "El Salvador", code: "SV" },
  { name: "Equatorial Guinea", code: "GQ" },
  { name: "Eritrea", code: "ER" },
  { name: "Estonia", code: "EE" },
  { name: "Ethiopia", code: "ET" },
  { name: "Falkland Islands (Malvinas)", code: "FK" },
  { name: "Faroe Islands", code: "FO" },
  { name: "Fiji", code: "FJ" },
  { name: "Finland", code: "FI" },
  { name: "France", code: "FR" },
  { name: "French Guiana", code: "GF" },
  { name: "French Polynesia", code: "PF" },
  { name: "French Southern Territories", code: "TF" },
  { name: "Gabon", code: "GA" },
  { name: "Gambia", code: "GM" },
  { name: "Georgia", code: "GE" },
  { name: "Germany", code: "DE" },
  { name: "Ghana", code: "GH" },
  { name: "Gibraltar", code: "GI" },
  { name: "Greece", code: "GR" },
  { name: "Greenland", code: "GL" },
  { name: "Grenada", code: "GD" },
  { name: "Guadeloupe", code: "GP" },
  { name: "Guam", code: "GU" },
  { name: "Guatemala", code: "GT" },
  { name: "Guernsey", code: "GG" },
  { name: "Guinea", code: "GN" },
  { name: "Guinea-Bissau", code: "GW" },
  { name: "Guyana", code: "GY" },
  { name: "Haiti", code: "HT" },
  { name: "Heard Island and Mcdonald Islands", code: "HM" },
  { name: "Holy See (Vatican City State)", code: "VA" },
  { name: "Honduras", code: "HN" },
  { name: "Hong Kong", code: "HK" },
  { name: "Hungary", code: "HU" },
  { name: "Iceland", code: "IS" },
  { name: "India", code: "IN" },
  { name: "Indonesia", code: "ID" },
  { name: "Iran, Islamic Republic Of", code: "IR" },
  { name: "Iraq", code: "IQ" },
  { name: "Ireland", code: "IE" },
  { name: "Isle of Man", code: "IM" },
  { name: "Israel", code: "IL" },
  { name: "Italy", code: "IT" },
  { name: "Jamaica", code: "JM" },
  { name: "Japan", code: "JP" },
  { name: "Jersey", code: "JE" },
  { name: "Jordan", code: "JO" },
  { name: "Kazakhstan", code: "KZ" },
  { name: "Kenya", code: "KE" },
  { name: "Kiribati", code: "KI" },
  { name: "Korea, Democratic People'S Republic of", code: "KP" },
  { name: "Korea, Republic of", code: "KR" },
  { name: "Kuwait", code: "KW" },
  { name: "Kyrgyzstan", code: "KG" },
  { name: "Lao People'S Democratic Republic", code: "LA" },
  { name: "Latvia", code: "LV" },
  { name: "Lebanon", code: "LB" },
  { name: "Lesotho", code: "LS" },
  { name: "Liberia", code: "LR" },
  { name: "Libyan Arab Jamahiriya", code: "LY" },
  { name: "Liechtenstein", code: "LI" },
  { name: "Lithuania", code: "LT" },
  { name: "Luxembourg", code: "LU" },
  { name: "Macao", code: "MO" },
  { name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
  { name: "Madagascar", code: "MG" },
  { name: "Malawi", code: "MW" },
  { name: "Malaysia", code: "MY" },
  { name: "Maldives", code: "MV" },
  { name: "Mali", code: "ML" },
  { name: "Malta", code: "MT" },
  { name: "Marshall Islands", code: "MH" },
  { name: "Martinique", code: "MQ" },
  { name: "Mauritania", code: "MR" },
  { name: "Mauritius", code: "MU" },
  { name: "Mayotte", code: "YT" },
  { name: "Mexico", code: "MX" },
  { name: "Micronesia, Federated States of", code: "FM" },
  { name: "Moldova, Republic of", code: "MD" },
  { name: "Monaco", code: "MC" },
  { name: "Mongolia", code: "MN" },
  { name: "Montserrat", code: "MS" },
  { name: "Morocco", code: "MA" },
  { name: "Mozambique", code: "MZ" },
  { name: "Myanmar", code: "MM" },
  { name: "Namibia", code: "NA" },
  { name: "Nauru", code: "NR" },
  { name: "Nepal", code: "NP" },
  { name: "Netherlands", code: "NL" },
  { name: "Netherlands Antilles", code: "AN" },
  { name: "New Caledonia", code: "NC" },
  { name: "New Zealand", code: "NZ" },
  { name: "Nicaragua", code: "NI" },
  { name: "Niger", code: "NE" },
  { name: "Nigeria", code: "NG" },
  { name: "Niue", code: "NU" },
  { name: "Norfolk Island", code: "NF" },
  { name: "Northern Mariana Islands", code: "MP" },
  { name: "Norway", code: "NO" },
  { name: "Oman", code: "OM" },
  { name: "Pakistan", code: "PK" },
  { name: "Palau", code: "PW" },
  { name: "Palestinian Territory, Occupied", code: "PS" },
  { name: "Panama", code: "PA" },
  { name: "Papua New Guinea", code: "PG" },
  { name: "Paraguay", code: "PY" },
  { name: "Peru", code: "PE" },
  { name: "Philippines", code: "PH" },
  { name: "Pitcairn", code: "PN" },
  { name: "Poland", code: "PL" },
  { name: "Portugal", code: "PT" },
  { name: "Puerto Rico", code: "PR" },
  { name: "Qatar", code: "QA" },
  { name: "Reunion", code: "RE" },
  { name: "Romania", code: "RO" },
  { name: "Russian Federation", code: "RU" },
  { name: "RWANDA", code: "RW" },
  { name: "Saint Helena", code: "SH" },
  { name: "Saint Kitts and Nevis", code: "KN" },
  { name: "Saint Lucia", code: "LC" },
  { name: "Saint Pierre and Miquelon", code: "PM" },
  { name: "Saint Vincent and the Grenadines", code: "VC" },
  { name: "Samoa", code: "WS" },
  { name: "San Marino", code: "SM" },
  { name: "Sao Tome and Principe", code: "ST" },
  { name: "Saudi Arabia", code: "SA" },
  { name: "Senegal", code: "SN" },
  { name: "Serbia and Montenegro", code: "CS" },
  { name: "Seychelles", code: "SC" },
  { name: "Sierra Leone", code: "SL" },
  { name: "Singapore", code: "SG" },
  { name: "Slovakia", code: "SK" },
  { name: "Slovenia", code: "SI" },
  { name: "Solomon Islands", code: "SB" },
  { name: "Somalia", code: "SO" },
  { name: "South Africa", code: "ZA" },
  { name: "South Georgia and the South Sandwich Islands", code: "GS" },
  { name: "Spain", code: "ES" },
  { name: "Sri Lanka", code: "LK" },
  { name: "Sudan", code: "SD" },
  { name: "Suriname", code: "SR" },
  { name: "Svalbard and Jan Mayen", code: "SJ" },
  { name: "Swaziland", code: "SZ" },
  { name: "Sweden", code: "SE" },
  { name: "Switzerland", code: "CH" },
  { name: "Syrian Arab Republic", code: "SY" },
  { name: "Taiwan, Province of China", code: "TW" },
  { name: "Tajikistan", code: "TJ" },
  { name: "Tanzania, United Republic of", code: "TZ" },
  { name: "Thailand", code: "TH" },
  { name: "Timor-Leste", code: "TL" },
  { name: "Togo", code: "TG" },
  { name: "Tokelau", code: "TK" },
  { name: "Tonga", code: "TO" },
  { name: "Trinidad and Tobago", code: "TT" },
  { name: "Tunisia", code: "TN" },
  { name: "Turkey", code: "TR" },
  { name: "Turkmenistan", code: "TM" },
  { name: "Turks and Caicos Islands", code: "TC" },
  { name: "Tuvalu", code: "TV" },
  { name: "Uganda", code: "UG" },
  { name: "Ukraine", code: "UA" },
  { name: "United Arab Emirates", code: "AE" },
  { name: "United Kingdom", code: "GB" },
  { name: "United States", code: "US" },
  { name: "United States Minor Outlying Islands", code: "UM" },
  { name: "Uruguay", code: "UY" },
  { name: "Uzbekistan", code: "UZ" },
  { name: "Vanuatu", code: "VU" },
  { name: "Venezuela", code: "VE" },
  { name: "Viet Nam", code: "VN" },
  { name: "Virgin Islands, British", code: "VG" },
  { name: "Virgin Islands, U.S.", code: "VI" },
  { name: "Wallis and Futuna", code: "WF" },
  { name: "Western Sahara", code: "EH" },
  { name: "Yemen", code: "YE" },
  { name: "Zambia", code: "ZM" },
  { name: "Zimbabwe", code: "ZW" },
];

// Tools Constant
export const RENDER_SUB_TOOLS = "sub_tools";
export const RENDER_TOOLS = "tools";
export const DELETE_CA = "delete";
export const ASSIGN_MARKS = "assign_marks";

// End of Tools Constants

// Heading constants

export const MAIN_HEADING = "MAIN_HEADING";
export const SUB_HEADING = "SUB_HEADING";
export const SMALL_HEADING = "SMALL_HEADING";

// end of Heading constants

export function dateConversion(inputFormat) {
  function pad(s) {
    return s < 10 ? "0" + s : s;
  }
  var d = new Date(inputFormat);
  return [pad(d.getMonth() + 1), pad(d.getDate()), d.getFullYear()].join("/");
}
//  camelCaseToCamelCase
function camelCaseToCamelCase(input) {
  return input
    .replace(/((?<!^)[A-Z](?![A-Z]))(?=\S)/g, " $1")
    .replace(/^./, (s) => s.toUpperCase());
}
export function customGeneralDataAddingMiddleWare(
  addData,
  studentData,
  propterty,
  changeActionLength = [{ index: 1, modalKey: "#EditModal" }]
) {
  let desireData = [];
  let getAllData = JSON.parse(JSON.stringify(studentData));

  for (let i = 0; i < studentData.length; i++) {
    let getData = $.extend(true, {}, addData);
    for (let j = 0; j < changeActionLength.length; j++) {
      getData["Action"][changeActionLength[j].index][
        "modalKey"
      ] = `${changeActionLength[j].modalKey}${studentData[i][propterty]}`;
    }
    desireData.push({
      ...studentData[i],
      ...getData,
    });
    debugger;
  }

  debugger;
  return desireData;
}
//
let Months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

let gender = ["Male", "Female"];

let maritalStatus = ["Single", "Married", "Divorced", "Widow", "Other"];

let YesNO = ["Yes", "No"];
const nationality = ["Pakistan", "Oman", "Saudi", "Iran", "Iraq", "India"];

let BloodGroup = [
  "(A+)",
  "(A-)",
  "(B+)",
  "(B-)",
  "(O+)",
  "(O-)",
  "(AB+)",
  "(AB-)",
];

// indent Form status
let indentDropDown = ["Approved", "Not Approved"];

//Teacher Evaluation with or Without
let withOrWithout = ["With Prior Notification", "Without Prior Notification"];

//term
let term = ["1st", "2nd", "3rd"];

//transport maintenance
let maintenance = ["1", "2", "3", "4", "5"];

// time format

const timeFormat = "h:mm a";

//leaveType
let leaveType = ["Excused"];

//patient type
let patientType = ["Student", "Staff"];

//# Date Converters
const dateToFormat = (date) => {
  let tempDate = new Date(date);
  let apiDate =
    (tempDate.getMonth() > 8
      ? tempDate.getMonth() + 1
      : "0" + (tempDate.getMonth() + 1)) +
    "/" +
    (tempDate.getDate() > 9 ? tempDate.getDate() : "0" + tempDate.getDate()) +
    "/" +
    tempDate.getFullYear();
  return apiDate;
};

const formateToDate = (date) => {
  let dateObj = date.split("/");
  dateObj = new Date(dateObj[2], dateObj[0] - 1, dateObj[1]);
  return dateObj;
};

//#endregion

//# Student Medical severity
const medColorRange = ["#5cb85c", "#f0ad4e", "#d9534f"];
//#endregion

//#region Exclusion
const exclusion = ["Exclusion", "Non-Exclusion"];

const statusExclusion = ["On-Hold", "Investigate"];
const statusNonExclusion = ["On-Review", "Accepted"];

const committeeHead = ["1", "2", "3"];
const committeeMember = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];
//#endregion

//#region communication coordinator
const recipients = [
  {
    name: "Head Of Department",
    bodyColor: "#B2B09B",
    footerColor: "#A6A48C",
    Link: "MessageHOD",
    Link2: "HOD",
  },
  {
    name: "Academic Coordinator",
    bodyColor: "#6fa495",
    footerColor: "#619b8a",
    Link: "MessageAC",
    Link2: "Academic Coordinator",
  },
  {
    name: "Dean",
    bodyColor: "#26de81",
    footerColor: "#20bf6b",
    Link: "MessageDean",
    Link2: "Dean",
  },
  {
    name: "Manager",
    bodyColor: "#2bcbba",
    footerColor: "#0fb9b1",
    Link: "MessageManager",
    Link2: "Manager",
  },
  {
    name: "Staff",
    bodyColor: "#a1c181",
    footerColor: "#81ae86",
    Link: "MessageStaff",
    Link2: "Staff",
  },
];
//#endregion

//#region Portal coordinator
const PortalUsers = [
  {
    Link: "HODUsers",
    name: "HOD",
    bodyColor: "#61C4AE",
    footerColor: "#42B098",
  },
  {
    Link: "DeanUsers",
    name: "Dean",
    bodyColor: "#314857",
    footerColor: "#253641",
  },
  {
    Link: "AdminUsers",
    name: "Admin",
    bodyColor: "#FCB01A",
    footerColor: "#EFA303",
  },
  {
    Link: "ManagerUsers",
    name: "Manager",
    bodyColor: "#2bcbba",
    footerColor: "#0fb9b1",
  },
  {
    Link: "CoordinatorUsers",
    name: "Coordinator",
    bodyColor: "#E0CF36",
    footerColor: "#BFAF1E",
  },
  {
    Link: "StaffUsers",
    name: "Staff",
    bodyColor: "#a1c181",
    footerColor: "#81ae86",
  },
  {
    Link: "StudentUsers",
    name: "Student",
    bodyColor: "#6fa495",
    footerColor: "#619b8a",
  },
  {
    Link: "ParentUsers",
    name: "Parent",
    bodyColor: "#26de81",
    footerColor: "#20bf6b",
  },
];
//#endregion

const timeTableColors = ["#fc5c65", "#fd9644", "#f7b731", "#45aaf2"];

const timeTablePeriods = [
  1,
  2,
  3,
  4,
  5,
  6,
  1,
  2,
  3,
  4,
  5,
  6,
  1,
  2,
  3,
  4,
  5,
  6,
  1,
  2,
  3,
  4,
  5,
  6,
  1,
  2,
  3,
  4,
  5,
  6,
];

const loginTimeLimit = [
  "Owner",
  "General Manager",
  "Manager",
  "Admin",
  "Staff",
  "Coordinator",
  "Dean",
  "HOD",
  "Student",
  "Parent",
];

const conversionOfImageUrl = (url) => {
  try {
    let getSplitResult = url.split("\\");
    let result = "";
    for (let i = 4; i < getSplitResult.length; i++) {
      if (getSplitResult.length - 1 == i) result += getSplitResult[i];
      else result += getSplitResult[i].concat("\\\\");
    }
    let finalResult = "http://".concat(`${result}`);
    return finalResult;
  } catch (e) {
    console.log(e);
  }
};
export const newconversionOfImageUrl = (url) => {
  let getNewSplit = String.raw`${url}`.split("\\").join("//");
  let getnewSliting = getNewSplit.split("//");

  // let getSplitResult = url.split('');
  let filterURL = [
    ...getnewSliting.filter(
      (item) =>
        item !== "C:" &&
        item !== "Inetpub" &&
        item !== "vhosts" &&
        item !== "httpdocs"
    ),
  ];
  debugger;
  let getDesireURL = filterURL.join("/");

  // let result = '';
  // for (let i = 4; i < getSplitResult.length; i++) {
  //   if (getSplitResult.length - 1 == i)
  // 	result += getSplitResult[i];
  //   else
  // 	result += getSplitResult[i].concat('\\\\');
  // }
  let finalResult = "http://".concat(`${getDesireURL}`);
  let newFinal = finalResult.replace("linkit.life", "schoolsmart.org.uk");
  return newFinal;
};
export const newconversionOfImageUrlDAK = (url) => {
  let getNewSplit = String.raw`${url}`.split("\\").join("//");
  let getnewSliting = getNewSplit.split("//");

  // let getSplitResult = url.split('');
  let filterURL = [
    ...getnewSliting.filter(
      (item) => item !== "C:" && item !== "Inetpub" && item !== "vhosts"
    ),
  ];
  debugger;
  let getDesireURL = filterURL.join("/");

  // let result = '';
  // for (let i = 4; i < getSplitResult.length; i++) {
  //   if (getSplitResult.length - 1 == i)
  // 	result += getSplitResult[i];
  //   else
  // 	result += getSplitResult[i].concat('\\\\');
  // }
  let finalResult = "https:/".concat(`${getDesireURL}`);
  let newFinal = finalResult.replace("linkit.life", "");
  return newFinal;
};
export const makeStudentImageURL = (input) => {
  var ini = "https://embassyportal.schoolsmart.org.uk/Uploads/StudentImages/";
  return ini.concat(input);
};
export const makeVisitorImageURL = (input) => {
  var ini = "https://embassyportal.schoolsmart.org.uk/Uploads/VisitorImages/";
  return ini.concat(input);
};
export const makeStaffImageURL = (input) => {
  var ini = "https://embassyportal.schoolsmart.org.uk/Uploads/StaffImages/";
  return ini.concat(input);
};
const stringConversion = (input) => {
  return [...input.match(/[A-Z][a-z]+/g)].join(" ");
};

const country = [
  { name: "Afghanistan", code: "AF" },
  { name: "Åland Islands", code: "AX" },
  { name: "Albania", code: "AL" },
  { name: "Algeria", code: "DZ" },
  { name: "American Samoa", code: "AS" },
  { name: "AndorrA", code: "AD" },
  { name: "Angola", code: "AO" },
  { name: "Anguilla", code: "AI" },
  { name: "Antarctica", code: "AQ" },
  { name: "Antigua and Barbuda", code: "AG" },
  { name: "Argentina", code: "AR" },
  { name: "Armenia", code: "AM" },
  { name: "Aruba", code: "AW" },
  { name: "Australia", code: "AU" },
  { name: "Austria", code: "AT" },
  { name: "Azerbaijan", code: "AZ" },
  { name: "Bahamas", code: "BS" },
  { name: "Bahrain", code: "BH" },
  { name: "Bangladesh", code: "BD" },
  { name: "Barbados", code: "BB" },
  { name: "Belarus", code: "BY" },
  { name: "Belgium", code: "BE" },
  { name: "Belize", code: "BZ" },
  { name: "Benin", code: "BJ" },
  { name: "Bermuda", code: "BM" },
  { name: "Bhutan", code: "BT" },
  { name: "Bolivia", code: "BO" },
  { name: "Bosnia and Herzegovina", code: "BA" },
  { name: "Botswana", code: "BW" },
  { name: "Bouvet Island", code: "BV" },
  { name: "Brazil", code: "BR" },
  { name: "British Indian Ocean Territory", code: "IO" },
  { name: "Brunei Darussalam", code: "BN" },
  { name: "Bulgaria", code: "BG" },
  { name: "Burkina Faso", code: "BF" },
  { name: "Burundi", code: "BI" },
  { name: "Cambodia", code: "KH" },
  { name: "Cameroon", code: "CM" },
  { name: "Canada", code: "CA" },
  { name: "Cape Verde", code: "CV" },
  { name: "Cayman Islands", code: "KY" },
  { name: "Central African Republic", code: "CF" },
  { name: "Chad", code: "TD" },
  { name: "Chile", code: "CL" },
  { name: "China", code: "CN" },
  { name: "Christmas Island", code: "CX" },
  { name: "Cocos (Keeling) Islands", code: "CC" },
  { name: "Colombia", code: "CO" },
  { name: "Comoros", code: "KM" },
  { name: "Congo", code: "CG" },
  { name: "Congo, The Democratic Republic of the", code: "CD" },
  { name: "Cook Islands", code: "CK" },
  { name: "Costa Rica", code: "CR" },
  { name: "Cote D'Ivoire", code: "CI" },
  { name: "Croatia", code: "HR" },
  { name: "Cuba", code: "CU" },
  { name: "Cyprus", code: "CY" },
  { name: "Czech Republic", code: "CZ" },
  { name: "Denmark", code: "DK" },
  { name: "Djibouti", code: "DJ" },
  { name: "Dominica", code: "DM" },
  { name: "Dominican Republic", code: "DO" },
  { name: "Ecuador", code: "EC" },
  { name: "Egypt", code: "EG" },
  { name: "El Salvador", code: "SV" },
  { name: "Equatorial Guinea", code: "GQ" },
  { name: "Eritrea", code: "ER" },
  { name: "Estonia", code: "EE" },
  { name: "Ethiopia", code: "ET" },
  { name: "Falkland Islands (Malvinas)", code: "FK" },
  { name: "Faroe Islands", code: "FO" },
  { name: "Fiji", code: "FJ" },
  { name: "Finland", code: "FI" },
  { name: "France", code: "FR" },
  { name: "French Guiana", code: "GF" },
  { name: "French Polynesia", code: "PF" },
  { name: "French Southern Territories", code: "TF" },
  { name: "Gabon", code: "GA" },
  { name: "Gambia", code: "GM" },
  { name: "Georgia", code: "GE" },
  { name: "Germany", code: "DE" },
  { name: "Ghana", code: "GH" },
  { name: "Gibraltar", code: "GI" },
  { name: "Greece", code: "GR" },
  { name: "Greenland", code: "GL" },
  { name: "Grenada", code: "GD" },
  { name: "Guadeloupe", code: "GP" },
  { name: "Guam", code: "GU" },
  { name: "Guatemala", code: "GT" },
  { name: "Guernsey", code: "GG" },
  { name: "Guinea", code: "GN" },
  { name: "Guinea-Bissau", code: "GW" },
  { name: "Guyana", code: "GY" },
  { name: "Haiti", code: "HT" },
  { name: "Heard Island and Mcdonald Islands", code: "HM" },
  { name: "Holy See (Vatican City State)", code: "VA" },
  { name: "Honduras", code: "HN" },
  { name: "Hong Kong", code: "HK" },
  { name: "Hungary", code: "HU" },
  { name: "Iceland", code: "IS" },
  { name: "India", code: "IN" },
  { name: "Indonesia", code: "ID" },
  { name: "Iran, Islamic Republic Of", code: "IR" },
  { name: "Iraq", code: "IQ" },
  { name: "Ireland", code: "IE" },
  { name: "Isle of Man", code: "IM" },
  { name: "Israel", code: "IL" },
  { name: "Italy", code: "IT" },
  { name: "Jamaica", code: "JM" },
  { name: "Japan", code: "JP" },
  { name: "Jersey", code: "JE" },
  { name: "Jordan", code: "JO" },
  { name: "Kazakhstan", code: "KZ" },
  { name: "Kenya", code: "KE" },
  { name: "Kiribati", code: "KI" },
  { name: "Korea, Democratic People'S Republic of", code: "KP" },
  { name: "Korea, Republic of", code: "KR" },
  { name: "Kuwait", code: "KW" },
  { name: "Kyrgyzstan", code: "KG" },
  { name: "Lao People'S Democratic Republic", code: "LA" },
  { name: "Latvia", code: "LV" },
  { name: "Lebanon", code: "LB" },
  { name: "Lesotho", code: "LS" },
  { name: "Liberia", code: "LR" },
  { name: "Libyan Arab Jamahiriya", code: "LY" },
  { name: "Liechtenstein", code: "LI" },
  { name: "Lithuania", code: "LT" },
  { name: "Luxembourg", code: "LU" },
  { name: "Macao", code: "MO" },
  { name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
  { name: "Madagascar", code: "MG" },
  { name: "Malawi", code: "MW" },
  { name: "Malaysia", code: "MY" },
  { name: "Maldives", code: "MV" },
  { name: "Mali", code: "ML" },
  { name: "Malta", code: "MT" },
  { name: "Marshall Islands", code: "MH" },
  { name: "Martinique", code: "MQ" },
  { name: "Mauritania", code: "MR" },
  { name: "Mauritius", code: "MU" },
  { name: "Mayotte", code: "YT" },
  { name: "Mexico", code: "MX" },
  { name: "Micronesia, Federated States of", code: "FM" },
  { name: "Moldova, Republic of", code: "MD" },
  { name: "Monaco", code: "MC" },
  { name: "Mongolia", code: "MN" },
  { name: "Montserrat", code: "MS" },
  { name: "Morocco", code: "MA" },
  { name: "Mozambique", code: "MZ" },
  { name: "Myanmar", code: "MM" },
  { name: "Namibia", code: "NA" },
  { name: "Nauru", code: "NR" },
  { name: "Nepal", code: "NP" },
  { name: "Netherlands", code: "NL" },
  { name: "Netherlands Antilles", code: "AN" },
  { name: "New Caledonia", code: "NC" },
  { name: "New Zealand", code: "NZ" },
  { name: "Nicaragua", code: "NI" },
  { name: "Niger", code: "NE" },
  { name: "Nigeria", code: "NG" },
  { name: "Niue", code: "NU" },
  { name: "Norfolk Island", code: "NF" },
  { name: "Northern Mariana Islands", code: "MP" },
  { name: "Norway", code: "NO" },
  { name: "Oman", code: "OM" },
  { name: "Pakistan", code: "PK" },
  { name: "Palau", code: "PW" },
  { name: "Palestinian Territory, Occupied", code: "PS" },
  { name: "Panama", code: "PA" },
  { name: "Papua New Guinea", code: "PG" },
  { name: "Paraguay", code: "PY" },
  { name: "Peru", code: "PE" },
  { name: "Philippines", code: "PH" },
  { name: "Pitcairn", code: "PN" },
  { name: "Poland", code: "PL" },
  { name: "Portugal", code: "PT" },
  { name: "Puerto Rico", code: "PR" },
  { name: "Qatar", code: "QA" },
  { name: "Reunion", code: "RE" },
  { name: "Romania", code: "RO" },
  { name: "Russian Federation", code: "RU" },
  { name: "RWANDA", code: "RW" },
  { name: "Saint Helena", code: "SH" },
  { name: "Saint Kitts and Nevis", code: "KN" },
  { name: "Saint Lucia", code: "LC" },
  { name: "Saint Pierre and Miquelon", code: "PM" },
  { name: "Saint Vincent and the Grenadines", code: "VC" },
  { name: "Samoa", code: "WS" },
  { name: "San Marino", code: "SM" },
  { name: "Sao Tome and Principe", code: "ST" },
  { name: "Saudi Arabia", code: "SA" },
  { name: "Senegal", code: "SN" },
  { name: "Serbia and Montenegro", code: "CS" },
  { name: "Seychelles", code: "SC" },
  { name: "Sierra Leone", code: "SL" },
  { name: "Singapore", code: "SG" },
  { name: "Slovakia", code: "SK" },
  { name: "Slovenia", code: "SI" },
  { name: "Solomon Islands", code: "SB" },
  { name: "Somalia", code: "SO" },
  { name: "South Africa", code: "ZA" },
  { name: "South Georgia and the South Sandwich Islands", code: "GS" },
  { name: "Spain", code: "ES" },
  { name: "Sri Lanka", code: "LK" },
  { name: "Sudan", code: "SD" },
  { name: "Suriname", code: "SR" },
  { name: "Svalbard and Jan Mayen", code: "SJ" },
  { name: "Swaziland", code: "SZ" },
  { name: "Sweden", code: "SE" },
  { name: "Switzerland", code: "CH" },
  { name: "Syrian Arab Republic", code: "SY" },
  { name: "Taiwan, Province of China", code: "TW" },
  { name: "Tajikistan", code: "TJ" },
  { name: "Tanzania, United Republic of", code: "TZ" },
  { name: "Thailand", code: "TH" },
  { name: "Timor-Leste", code: "TL" },
  { name: "Togo", code: "TG" },
  { name: "Tokelau", code: "TK" },
  { name: "Tonga", code: "TO" },
  { name: "Trinidad and Tobago", code: "TT" },
  { name: "Tunisia", code: "TN" },
  { name: "Turkey", code: "TR" },
  { name: "Turkmenistan", code: "TM" },
  { name: "Turks and Caicos Islands", code: "TC" },
  { name: "Tuvalu", code: "TV" },
  { name: "Uganda", code: "UG" },
  { name: "Ukraine", code: "UA" },
  { name: "United Arab Emirates", code: "AE" },
  { name: "United Kingdom", code: "GB" },
  { name: "United States", code: "US" },
  { name: "United States Minor Outlying Islands", code: "UM" },
  { name: "Uruguay", code: "UY" },
  { name: "Uzbekistan", code: "UZ" },
  { name: "Vanuatu", code: "VU" },
  { name: "Venezuela", code: "VE" },
  { name: "Viet Nam", code: "VN" },
  { name: "Virgin Islands, British", code: "VG" },
  { name: "Virgin Islands, U.S.", code: "VI" },
  { name: "Wallis and Futuna", code: "WF" },
  { name: "Western Sahara", code: "EH" },
  { name: "Yemen", code: "YE" },
  { name: "Zambia", code: "ZM" },
  { name: "Zimbabwe", code: "ZW" },
];
export const getCountryName = (code) => {
  debugger;
  let getName = country.filter((item) => item.code == code);

  debugger;
  return getName[0].name;
};
const religion = [
  "Islam",
  "African Traditional & Diasporic",
  "Agnostic",
  "Atheist",
  "Baha",
  "Buddhism",
  "Cao Dai",
  "Chinese traditional religion",
  "Christianity",
  "Hinduism",
  "Jainism",
  "Juche",
  "Judaism",
  "Neo-Paganism",
  "Non-religious",
  "Rastafarianism",
  "Secular",
  "Shinto",
  "Sikhism",
  "Spiritism",
  "Tenrikyo",
  "Unitarian-Universalism",
  "Zoroastrianism",
  "Primal-indigenous",
  "Other",
];
const status = ["On Hold", "Active", "Non-Active"];
const appStatus = ["Yes", "No"];
export const getNationalityData = (dumpState, feildName) => {
  debugger;
  return dumpState[feildName];
};
const handleDumpDateChange = (date, dumpState, name) => {
  dumpState[name] = date;
  console.log(dumpState);
};
const handleDumpInputChange = (e, dumpState, code = "NA") => {
  // arrow function
  debugger;
  if (e.target.name === "nationality") {
    dumpState.current[e.target.name] = code;
    debugger;
  } else {
    debugger;
    dumpState[e.target.name] = e.target.value;
  }
  console.log(dumpState);
};
const IsObjectEmpty = (Obj) => {
  let checkFlag = false;

  for (let value of Object.values(Obj)) {
    if (Object.is(value, "")) {
      checkFlag = false; // John, then 30
      break;
    }
    checkFlag = true;
  }
  console.log(checkFlag);
  return checkFlag;
};
const btnTechHover = () => {
  $(document).ready(function () {
    $("button").hover(
      function () {
        $(this).css("box-shadow", "0px 5px 5px grey");
        $(this).css("border-color", "white");
      },
      function () {
        $(this).css("box-shadow", "0px 0px 0px white");
      }
    );
  });
};
function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}
function extensionCheck(imgUpload = false, fileUpload = false, fileName) {
  if (imgUpload) return /\.(jpg|jpeg|png)$/i.test(fileName);
  else if (fileUpload) return /\.(docx|doc|pdf)$/i.test(fileName);
  else return false;
}

function checkForImageOrFile(
  isImageUpload = false,
  isFileUpload = false,
  error = {},
  fileSize = 0,
  fileSizeInMB = 2.1,
  fileName,
  errorFieldName = "defaultFlag",
  setErrorFun
) {
  let getImgSize = formatBytes(fileSize);
  let getPices = getImgSize.split(" ");

  console.log(getPices);
  if (
    getPices[1] === "KB" &&
    parseFloat(getPices[0]) > fileSizeInMB &&
    extensionCheck(isImageUpload, isFileUpload, fileName)
  ) {
    console.log(getPices);
    console.log("fine with KB");
    let cloneError = { ...error };
    cloneError[errorFieldName] = false;
    setErrorFun(cloneError);
    return false;
  } else if (
    getPices[1] === "Bytes" &&
    parseFloat(getPices[0]) > fileSizeInMB &&
    extensionCheck(isImageUpload, isFileUpload, fileName)
  ) {
    console.log(getPices);
    console.log("fine with bytes");
    let cloneError = { ...error };
    cloneError[errorFieldName] = false;
    setErrorFun(cloneError);
    return false;
  } else if (
    getPices[1] === "MB" &&
    parseFloat(getPices[0]) < fileSizeInMB &&
    extensionCheck(isImageUpload, isFileUpload, fileName)
  ) {
    console.log(getPices);
    console.log("fine with MB");
    let cloneError = { ...error };
    cloneError[errorFieldName] = false;
    setErrorFun(cloneError);
    return false;
  } else {
    let cloneError = { ...error };
    cloneError[errorFieldName] = true;
    setErrorFun(cloneError);
    return true;
  }
}
function extraction(extractArr = [], obj) {
  let getOBJ = {};
  let getKeys = Object.keys(obj);
  extractArr.forEach((element) => {
    if (getKeys.includes(element)) {
      getOBJ[element] = obj[element];
    }
  });
  let check = getOBJ;

  return getOBJ;
  console.log(getOBJ);
}

export function dataFilteration(data, extractingValue = [], filter = {}) {
  let reqArr = [];

  let getResult = [
    ...data.filter(function (item) {
      for (var key in filter) {
        if (item[key] === undefined || item[key] != filter[key]) return false;
      }
      return true;
    }),
  ];
  let requiredResult = getResult.map((item) =>
    extraction(extractingValue, item)
  );

  return requiredResult;
}
function isObjEmpty(obj = {}) {
  let emptyStatus = true;
  let getKeys = Object.keys(obj);
  if (getKeys.length > 0) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const element = obj[key];
        if (element !== "") {
          emptyStatus = false;
          break;
        }
      }
    }

    return emptyStatus;
  }
  return true;
}
export function isEmptyFieldInArrOBJ(arrObj, fieldName) {
  debugger;
  let isEmpty = false;
  arrObj.forEach((element) => {
    if (element[fieldName] === "") {
      isEmpty = true;
    }
  });
  debugger;
  return isEmpty;
}

export function customSubToolDataAddingMiddleWare(addData, studentData) {
  debugger;
  let desireData = [];
  let getAllData = JSON.parse(JSON.stringify(studentData));

  for (let i = 0; i < studentData.length; i++) {
    let getData = $.extend(true, {}, addData);
    getData["Action"][3]["modalKey"] = `#deleteModal${studentData[i].pId}`;
    desireData.push({
      ...studentData[i],
      ...getData,
    });
    debugger;
  }

  // studentData.map((item, index) => {
  // 	getData['Action'][3]['modalKey'] = `#deleteModal${index + 1}`;
  // 	debugger;
  // 	return {
  // 		...item,
  // 		...getData
  // 	};
  // 	debugger;
  // });
  debugger;
  return desireData;
}
export function customDataAddingMiddleWare(addData, studentData) {
  let desireData = [];
  let getData = $.extend(true, {}, addData);
  debugger;
  let getAllData = JSON.parse(JSON.stringify(studentData));
  debugger;
  desireData = studentData.map((item) => {
    if (item.CA_AssignMark.length > 0) {
      getData["Action"][0]["isEnable"] = false;
      getData["Action"][0]["iconColor"] = Colors.DISABLE_GREY;
      debugger;
      return {
        ...item,
        ...getData,
      };
      debugger;
    } else {
      debugger;
      addData["Action"][1]["isEnable"] = false;
      addData["Action"][1]["iconColor"] = Colors.DISABLE_GREY;
      debugger;
      return {
        ...item,
        ...addData,
      };
    }
    debugger;
  });
  // getAllData.map(item => {
  // 	if (item.CA_AssignMark.length > 0) {
  // 		debugger;
  // getData['Action'][0]['isEnable'] = false;
  // 		getData['Action'][0]['iconColor'] = Colors.DISABLE_GREY;
  // 		debugger;
  // 		debugger;
  // 	}
  // });
  debugger;
  return desireData;
}
export function addDataMiddleware(addFields = {}, targatedArrObj = []) {
  debugger;
  let extendedArrObj = [];
  if (!isObjEmpty(addFields)) {
    // object shouldn't be empty
    extendedArrObj = targatedArrObj.map((item) => {
      return {
        ...item,
        ...addFields,
      };
    });
    debugger;
    return extendedArrObj;
  }
}
export function addCustomDataMiddleware(addFields = {}, targatedArrObj = []) {
  let extendedArrObj = [];
  if (!isObjEmpty(addFields)) {
    // object shouldn't be empty
    extendedArrObj = targatedArrObj.map((item) => {
      return {
        ...item,
        ...addFields,
      };
    });

    return extendedArrObj;
  }
}

export function checkingProperty(data = [], UserData) {
  for (let index = 0; index < UserData.length; index++) {
    if (!data.includes(UserData[index])) return false;
  }
  return true;
}
export function checkForFilteration(objKeys = [], userKeys = []) {
  let getNewKeys = objKeys.filter((item) => {
    if (userKeys.includes(item)) return false;
    return true;
  });
  return getNewKeys;
}
export function extractIds(
  propName = [],
  itemObj = {},
  additional = "",
  type = false
) {
  let urlString = "";
  let getKeys = Object.keys(itemObj);
  let extractObj = {};
  getKeys.map((item) => {
    if (propName.includes(item)) extractObj[item] = itemObj[item];
  });
  if (!type) {
    for (const key in extractObj) {
      if (extractObj.hasOwnProperty(key)) {
        urlString += `/${extractObj[key]}`;
      }
    }
  } else {
    for (const key in extractObj) {
      if (extractObj.hasOwnProperty(key)) {
        urlString += `${extractObj[key]}`;
      }
    }
  }
  if (additional !== "undefined" && !type) {
    urlString += `/${additional}`;
  }

  return urlString;
}
function checkBlur() {
  console.log("hit bluerr");
}

function condtionalServices(
  classId,
  sectionId,
  sessionId,
  currentPage,
  customPageSize
) {
  let returnString = "";

  if (classId !== "" && sectionId !== "" && sessionId !== "") {
    return `/api/StudentAdmissionForm?classId=${classId}&sectionId=${sectionId}&sessionId=${sessionId}&pageNumber=${currentPage}&pageSize=${parseInt(
      customPageSize
    )}`;
  }

  if (classId !== "" && sessionId !== "") {
    return `/api/StudentAdmissionForm?classId=${classId}&sessionId=${sessionId}&pageNumber=${currentPage}&pageSize=${parseInt(
      customPageSize
    )}`;
  }

  if (sessionId !== "") {
    return `/api/StudentSession?sessionId=${sessionId}&pageNumber=${currentPage}&pageSize=${parseInt(
      customPageSize
    )}`;
  }

  return `/api/AllStudentAdmission?pageNumber=${currentPage}&pageSize=${parseInt(
    customPageSize
  )}`;
}

export function viewClassReturn(type, data, rowIndex) {
  debugger;
  if (type && type["isEnable"] && !type.hasOwnProperty("renderType")) {
    return "";
  } else if (type && !type["isEnable"]) {
    debugger;
    return "disabled-link";
  }
  if (
    type.hasOwnProperty("renderType") &&
    type["renderType"] === ASSIGN_MARKS &&
    data[rowIndex]["tools"].length > 0 &&
    data[rowIndex]["subTools"].length < 1
  ) {
    return "disabled-link";
  }
  if (
    type.hasOwnProperty("renderType") &&
    type["renderType"] === RENDER_SUB_TOOLS &&
    data[rowIndex]["tools"].length < 1
  ) {
    return "disabled-link";
  }
  if (
    type.hasOwnProperty("renderType") &&
    type["renderType"] === ASSIGN_MARKS &&
    data[rowIndex]["tools"].length > 0 &&
    data[rowIndex]["subTools"].length > 0
  ) {
    return "";
  } else if (
    type.hasOwnProperty("renderType") &&
    type["renderType"] === ASSIGN_MARKS &&
    data[rowIndex]["tools"].length < 1 &&
    data[rowIndex]["subTools"].length < 1
  ) {
    return "disabled-link";
  }
  if (
    type.hasOwnProperty("renderType") &&
    type["renderType"] === RENDER_SUB_TOOLS &&
    data[rowIndex]["tools"].length > 0 &&
    data[rowIndex]["subTools"].length > 0
  ) {
    return "disabled-link";
  }

  if (
    type.hasOwnProperty("renderType") &&
    type["renderType"] === RENDER_TOOLS &&
    data[rowIndex]["tools"].length > 0 &&
    data[rowIndex]["subTools"].length > 0
  ) {
    return "disabled-link";
  }
  if (
    type.hasOwnProperty("renderType") &&
    type["renderType"] === RENDER_TOOLS &&
    data[rowIndex]["tools"].length > 0
  ) {
    return "disabled-link";
  }
}
export function disableColorOverCondition(type, data, rowIndex) {
  debugger;
  if (type && type["isEnable"] && !type.hasOwnProperty("renderType")) {
    debugger;
    return type["iconColor"];
  } else if (type && !type["isEnable"]) {
    debugger;
    return "#d3d3d3";
  }
  if (
    type.hasOwnProperty("renderType") &&
    type["renderType"] === RENDER_SUB_TOOLS &&
    data[rowIndex]["tools"].length < 1
  ) {
    return "#d3d3d3";
  }

  if (
    type.hasOwnProperty("renderType") &&
    type["renderType"] === ASSIGN_MARKS &&
    data[rowIndex]["tools"].length > 0 &&
    data[rowIndex]["subTools"].length < 1
  ) {
    return "#d3d3d3";
  }
  if (
    type.hasOwnProperty("renderType") &&
    type["renderType"] === ASSIGN_MARKS &&
    data[rowIndex]["tools"].length > 0 &&
    data[rowIndex]["subTools"].length > 0
  ) {
    return type["iconColor"];
  } else if (
    type.hasOwnProperty("renderType") &&
    type["renderType"] === ASSIGN_MARKS &&
    data[rowIndex]["tools"].length < 1 &&
    data[rowIndex]["subTools"].length < 1
  ) {
    debugger;
    return "#d3d3d3";
  }
  if (
    type.hasOwnProperty("renderType") &&
    type["renderType"] === RENDER_SUB_TOOLS &&
    data[rowIndex]["tools"].length > 0 &&
    data[rowIndex]["subTools"].length > 0
  ) {
    return "#d3d3d3";
  }
  if (
    type.hasOwnProperty("renderType") &&
    type["renderType"] === RENDER_TOOLS &&
    data[rowIndex]["tools"].length > 0 &&
    data[rowIndex]["subTools"].length > 0
  ) {
    return "#d3d3d3";
  }
  if (
    type.hasOwnProperty("renderType") &&
    type["renderType"] === RENDER_TOOLS &&
    data[rowIndex]["tools"].length > 0
  ) {
    return "#d3d3d3";
  }
}
function condtionalServicesSubject(
  classId,
  sectionId,
  currentPage,
  customPageSize
) {
  let returnString = "";

  if (classId !== "" && sectionId !== "") {
    return `/api/ViewAssignSubjectClassSection?pageNumber=${currentPage}&pageSize=${parseInt(
      customPageSize
    )}&classId=${parseInt(classId)}&sectionId=${parseInt(sectionId)}`;
  }

  if (classId !== "") {
    return `/api/ViewAssignSubjectClass?pageNumber=${currentPage}&pageSize=${parseInt(
      customPageSize
    )}&classId=${parseInt(classId)}`;
  }

  return `/api/ViewAssignSubject?pageNumber=${currentPage}&pageSize=${parseInt(
    customPageSize
  )}`;
}

function condtionalServicesVisitor(date, currentPage, customPageSize) {
  let returnString = "";

  let tempDate = new Date(date);
  let apiDate =
    (tempDate.getMonth() > 8
      ? tempDate.getMonth() + 1
      : "0" + (tempDate.getMonth() + 1)) +
    "/" +
    (tempDate.getDate() > 9 ? tempDate.getDate() : "0" + tempDate.getDate()) +
    "/" +
    tempDate.getFullYear();

  if (date !== "") {
    return `/api/SearchByDate?pageNumber=${currentPage}&pageSize=${parseInt(
      customPageSize
    )}&date=${apiDate}`;
  }
  return `/api/VisitorEntryRecord?PageNumber=${currentPage}&pageSize=${parseInt(
    customPageSize
  )}`;
}

function condtionalServicesPatient(
  studentStaff,
  doctorId,
  date,
  currentPage,
  customPageSize
) {
  let returnString = "";

  let tempDate = new Date(date);
  let apiDate =
    (tempDate.getMonth() > 8
      ? tempDate.getMonth() + 1
      : "0" + (tempDate.getMonth() + 1)) +
    "/" +
    (tempDate.getDate() > 9 ? tempDate.getDate() : "0" + tempDate.getDate()) +
    "/" +
    tempDate.getFullYear();

  if (date !== "" && doctorId !== "") {
    return `/api/PatientDetail?pageNumber=${currentPage}&pageSize=${parseInt(
      customPageSize
    )}&doctorId=${doctorId}&date=${apiDate}&studentStaff=${studentStaff}`;
  }

  if (doctorId !== "") {
    return `/api/PatientDetail?pageNumber=${currentPage}&pageSize=${parseInt(
      customPageSize
    )}&doctorId=${doctorId}&date=${""}&studentStaff=${studentStaff}`;
  }

  if (date !== "") {
    return `/api/PatientDetail?pageNumber=${currentPage}&pageSize=${parseInt(
      customPageSize
    )}&doctorId=${""}&date=${apiDate}&studentStaff=${studentStaff}`;
  }
  return `/api/PatientDetail?pageNumber=${currentPage}&pageSize=${parseInt(
    customPageSize
  )}&doctorId=${""}&date=${""}&studentStaff=${studentStaff}`;
}

function condtionalServicesTour(
  classId,
  sectionId,
  sessionId,
  date,
  currentPage,
  customPageSize
) {
  let returnString = "";

  let tempDate = new Date(date);
  let apiDate =
    (tempDate.getMonth() > 8
      ? tempDate.getMonth() + 1
      : "0" + (tempDate.getMonth() + 1)) +
    "/" +
    (tempDate.getDate() > 9 ? tempDate.getDate() : "0" + tempDate.getDate()) +
    "/" +
    tempDate.getFullYear();

  if (date !== "" && sectionId !== "" && sessionId !== "" && classId !== "") {
    return `/api/AllTours?pageNumber=${currentPage}&pageSize=${parseInt(
      customPageSize
    )}&classId=${classId}&sectionId=${sectionId}&sessionId=${sessionId}&date=${apiDate}`;
  }

  if (date !== "" && classId !== "" && sectionId !== "") {
    return `/api/AllTours?pageNumber=${currentPage}&pageSize=${parseInt(
      customPageSize
    )}&classId=${classId}&sectionId=${sectionId}&sessionId=&date=${apiDate}`;
  }

  if (date !== "" && classId !== "" && sessionId !== "") {
    return `/api/AllTours?pageNumber=${currentPage}&pageSize=${parseInt(
      customPageSize
    )}&classId=${classId}&sectionId=&sessionId=${sessionId}&date=${apiDate}`;
  }

  if (classId !== "" && sectionId !== "" && sessionId !== "") {
    return `/api/AllTours?pageNumber=${currentPage}&pageSize=${parseInt(
      customPageSize
    )}&classId=${classId}&sectionId=${sectionId}&sessionId=${sessionId}&date=`;
  }

  if (date !== "" && sessionId) {
    return `/api/AllTours?pageNumber=${currentPage}&pageSize=${parseInt(
      customPageSize
    )}&classId=&sectionId=&sessionId=${sessionId}&date=${apiDate}`;
  }

  if (date !== "" && classId) {
    return `/api/AllTours?pageNumber=${currentPage}&pageSize=${parseInt(
      customPageSize
    )}&classId=${classId}&sectionId=&sessionId=&date=${apiDate}`;
  }

  if (classId !== "" && sessionId !== "") {
    return `/api/AllTours?pageNumber=${currentPage}&pageSize=${parseInt(
      customPageSize
    )}&classId=${classId}&sectionId=&sessionId=${sessionId}&date=`;
  }

  if (classId !== "" && sectionId !== "") {
    return `/api/AllTours?pageNumber=${currentPage}&pageSize=${parseInt(
      customPageSize
    )}&classId=${classId}&sectionId=${sectionId}&sessionId=&date=`;
  }

  if (classId !== "") {
    return `/api/AllTours?pageNumber=${currentPage}&pageSize=${parseInt(
      customPageSize
    )}&classId=${classId}&sectionId=&sessionId=&date=`;
  }

  if (sessionId !== "") {
    return `/api/AllTours?pageNumber=${currentPage}&pageSize=${parseInt(
      customPageSize
    )}&classId=&sectionId=&sessionId=${sessionId}&date=`;
  }

  if (date !== "") {
    return `/api/AllTours?pageNumber=${currentPage}&pageSize=${parseInt(
      customPageSize
    )}&classId=&sectionId=&sessionId=&date=${apiDate}`;
  }

  return `/api/AllTours?pageNumber=${currentPage}&pageSize=${parseInt(
    customPageSize
  )}&classId=&sectionId=&sessionId=&date=`;
}

function condtionalServicesNewAdmit(
  classId,
  sessionId,
  name,
  currentPage,
  customPageSize
) {
  if (classId !== "" && name !== "" && sessionId !== "") {
    return `/api/ListOfAdmissionsBySessionClassName?pageNumber=${currentPage}&pageSize=${parseInt(
      customPageSize
    )}&classId=${classId}&sessionId=${sessionId}&name=${name}`;
  }

  if (name !== "" && sessionId) {
    return `/api/ListOfAdmissionsBySessionName?pageNumber=${currentPage}&pageSize=${parseInt(
      customPageSize
    )}&classId=${""}&sessionId=${sessionId}&name=${name}`;
  }

  if (name !== "" && classId) {
    return `/api/ListOfAdmissionsByClassName?pageNumber=${currentPage}&pageSize=${parseInt(
      customPageSize
    )}&classId=${classId}&sessionId=${""}&name=${name}`;
  }

  if (classId !== "" && sessionId !== "") {
    return `/api/ListOfAdmissionsBySessionClass?pageNumber=${currentPage}&pageSize=${parseInt(
      customPageSize
    )}&classId=${classId}&sessionId=${sessionId}&name=${""}`;
  }

  if (classId !== "") {
    return `/api/ListOfAdmissionsByClass?pageNumber=${currentPage}&pageSize=${parseInt(
      customPageSize
    )}&classId=${classId}&sessionId=${""}&name=${""}`;
  }

  if (sessionId !== "") {
    return `/api/ListOfAdmissionsBySession?pageNumber=${currentPage}&pageSize=${parseInt(
      customPageSize
    )}&classId=${""}&sessionId=${sessionId}&name=${""}`;
  }

  if (name !== "") {
    return `/api/ListOfAdmissionsByName?pageNumber=${currentPage}&pageSize=${parseInt(
      customPageSize
    )}&classId=${""}&sessionId=${""}&name=${name}`;
  }

  return `/api/ListOfAdmissions?pageNumber=${currentPage}&pageSize=${parseInt(
    customPageSize
  )}&classId=${""}&sessionId=${""}&name=${""}`;
}

function condtionalServicesClassOfStudent(
  classId,
  sectionId,
  sessionId,
  currentPage,
  customPageSize
) {
  if (classId !== "" && sectionId !== "" && sessionId !== "") {
    return `/api/ListOfStdFromClassSectionSession/?pageNumber=${currentPage}&pageSiz=${parseInt(
      customPageSize
    )}&classId=${classId}&sectionId=${sectionId}&sessionId=${sessionId}`;
  }

  if (sectionId !== "" && classId !== "") {
    return `/api/ListOfStdFromClassSection/?pageNumber=${currentPage}&pageSiz=${parseInt(
      customPageSize
    )}&classId=${classId}&sectionId=${sectionId}&sessionId=
		`;
  }

  if (classId !== "" && sessionId !== "") {
    return `/api/ListOfStdFromClassSession/?pageNumber=${currentPage}&pageSize=${parseInt(
      customPageSize
    )}&classId=${classId}&sectionId=&sessionId=${sessionId}`;
  }

  if (classId !== "") {
    return `/api/ListOfStdFromClass/?pageNumber=${currentPage}&pageSize=${parseInt(
      customPageSize
    )}&classId=${classId}&sectionId=&sessionId=`;
  }

  if (sessionId !== "") {
    return `/api/ListOfStdFromsSession/?pageNumber=${currentPage}&pageSiz=${parseInt(
      customPageSize
    )}&sessionId=${sessionId}&classId=&sectionId=`;
  }

  return `/api/ListOfAllStd/?pageNumber=${currentPage}&pageSiz=${parseInt(
    customPageSize
  )}&classId=&sectionId=&sessionId=`;
}

function condtionalServicesTransferStudent(
  classId,
  sectionId,
  currentPage,
  customPageSize
) {
  if (sectionId !== "" && classId !== "") {
    return `/api/GetListOfStudentCurrentClassSection/?pageNumber=${currentPage}&pageSize=${parseInt(
      customPageSize
    )}&classId=${classId}&sectionId=${sectionId}`;
  }

  if (classId !== "") {
    return `/api/GetListOfStudentCurrentClass/?pageNumber=${currentPage}&pageSize=${parseInt(
      customPageSize
    )}&classId=${classId}&sectionId=`;
  }
  return `/api/GetListOfAllStudentCurrentClassSection?classId=&sectionId=&pageNumber=${currentPage}&pageSize=${parseInt(
    customPageSize
  )}`;
}

function condtionalServicesOffGame(
  classId,
  sectionId,
  gameId,
  date,
  currentPage,
  customPageSize
) {
  let returnString = "";

  let tempDate = new Date(date);
  let apiDate =
    (tempDate.getMonth() > 8
      ? tempDate.getMonth() + 1
      : "0" + (tempDate.getMonth() + 1)) +
    "/" +
    (tempDate.getDate() > 9 ? tempDate.getDate() : "0" + tempDate.getDate()) +
    "/" +
    tempDate.getFullYear();

  if (date !== "" && sectionId !== "" && gameId !== "" && classId !== "") {
    return `/api/SearchAllOffGameClassSectionDate?pageNumber=${currentPage}&pageSize=${parseInt(
      customPageSize
    )}&classId=${classId}&sectionId=${sectionId}&gameId=${gameId}&date=${apiDate}`;
  }

  if (date !== "" && classId !== "" && sectionId !== "") {
    return `/api/SearchAllOffClassSectionDate?pageNumber=${currentPage}&pageSize=${parseInt(
      customPageSize
    )}&classId=${classId}&sectionId=${sectionId}&date=${apiDate}&gameId=`;
  }

  if (date !== "" && classId !== "" && gameId !== "") {
    return `/api/SearchAllOffGameClassDate?pageNumber=${currentPage}&pageSize=${parseInt(
      customPageSize
    )}&classId=${classId}&gameId=${gameId}&date=${apiDate}&sectionId=`;
  }

  if (classId !== "" && sectionId !== "" && gameId !== "") {
    return `/api/SearchAllOffGameClassSection?pageNumber=${currentPage}&pageSize=${parseInt(
      customPageSize
    )}&classId=${classId}&sectionId=${sectionId}&gameId=${gameId}&date=`;
  }

  if (date !== "" && gameId) {
    return `/api/SearchAllOffGameDate?pageNumber=${currentPage}&pageSize=${parseInt(
      customPageSize
    )}&gameId=${gameId}&date=${apiDate}&classId=&sectionId=`;
  }

  if (date !== "" && classId) {
    return `/api/SearchAllOffClassDate?pageNumber=${currentPage}&pageSize=${parseInt(
      customPageSize
    )}&classId=${classId}&date=${apiDate}&gameId=&sectionId=`;
  }

  if (classId !== "" && gameId !== "") {
    return `/api/SearchAllOffGameClass?pageNumber=${currentPage}&pageSize=${parseInt(
      customPageSize
    )}&classId=${classId}&gameId=${gameId}&sectionId=&date=`;
  }

  if (classId !== "" && sectionId !== "") {
    return `/api/SearchAllOffClassSection?pageNumber=${currentPage}&pageSize=${parseInt(
      customPageSize
    )}&classId=${classId}&sectionId=${sectionId}&gameId=${""}&date=${""}`;
  }

  if (classId !== "") {
    return `/api/SearchAllOffClassId?pageNumber=${currentPage}&pageSize=${parseInt(
      customPageSize
    )}&classId=${classId}&sectionId=${""}&gameId=${""}&date=${""}`;
  }

  if (gameId !== "") {
    return `/api/SearchAllOffGameId?pageNumber=${currentPage}&pageSize=${parseInt(
      customPageSize
    )}&gameId=${gameId}&classId=&sectionId=&date=`;
  }

  if (date !== "") {
    return `/api/SearchAllOffDate?pageNumber=${currentPage}&pageSize=${parseInt(
      customPageSize
    )}&date=${apiDate}&sectionId=${""}&gameId=${""}&classId=${""}`;
  }

  return `/api/SearchAllOffGameRecord?pageNumber=${currentPage}&pageSize=${parseInt(
    customPageSize
  )}&classId=&sectionId=&gameId=&date=`;
}

function condtionalServicesOtherCertificate(name, currentPage, customPageSize) {
  let returnString = "";

  if (name !== "") {
    return `/api/othercertificate?pageNumber=${currentPage}&pageSize=${parseInt(
      customPageSize
    )}&name=${name}`;
  }
  return `/api/othercertificate?pageNumber=${currentPage}&pageSize=${parseInt(
    customPageSize
  )}&name=`;
}

function condtionalServicesEvent(date, currentPage, customPageSize) {
  let returnString = "";

  let tempDate = new Date(date);
  let apiDate =
    (tempDate.getMonth() > 8
      ? tempDate.getMonth() + 1
      : "0" + (tempDate.getMonth() + 1)) +
    "/" +
    (tempDate.getDate() > 9 ? tempDate.getDate() : "0" + tempDate.getDate()) +
    "/" +
    tempDate.getFullYear();

  if (date !== "") {
    return `/api/EventInfo?pageNumber=${currentPage}&pageSize=${parseInt(
      customPageSize
    )}&date=${apiDate}`;
  }
  return `/api/EventInfo?PageNumber=${currentPage}&pageSize=${parseInt(
    customPageSize
  )}&date=`;
}

function condtionalServicesTransportMaintenance(
  month,
  sessionId,
  vehicleNo,
  currentPage,
  customPageSize
) {
  if (month !== "" && vehicleNo !== "" && sessionId !== "") {
    return `/api/TransportMaintenance?pageNumber=${currentPage}&pageSize=${parseInt(
      customPageSize
    )}&month=${month}&sessionId=${sessionId}&vehicleNo=${vehicleNo}`;
  }

  if (vehicleNo !== "" && sessionId) {
    return `/api/TransportMaintenance?pageNumber=${currentPage}&pageSize=${parseInt(
      customPageSize
    )}&month=${""}&sessionId=${sessionId}&vehicleNo=${vehicleNo}`;
  }

  if (vehicleNo !== "" && month) {
    return `/api/TransportMaintenance?pageNumber=${currentPage}&pageSize=${parseInt(
      customPageSize
    )}&month=${month}&sessionId=${""}&vehicleNo=${vehicleNo}`;
  }

  if (month !== "" && sessionId !== "") {
    return `/api/TransportMaintenance?pageNumber=${currentPage}&pageSize=${parseInt(
      customPageSize
    )}&month=${month}&sessionId=${sessionId}&vehicleNo=${""}`;
  }

  if (month !== "") {
    return `/api/TransportMaintenance?pageNumber=${currentPage}&pageSize=${parseInt(
      customPageSize
    )}&month=${month}&sessionId=${""}&vehicleNo=${""}`;
  }

  if (sessionId !== "") {
    return `/api/TransportMaintenance?pageNumber=${currentPage}&pageSize=${parseInt(
      customPageSize
    )}&month=${""}&sessionId=${sessionId}&vehicleNo=${""}`;
  }

  if (vehicleNo !== "") {
    return `/api/TransportMaintenance?pageNumber=${currentPage}&pageSize=${parseInt(
      customPageSize
    )}&month=${""}&sessionId=${""}&vehicleNo=${vehicleNo}`;
  }

  return `/api/TransportMaintenance?pageNumber=${currentPage}&pageSize=${parseInt(
    customPageSize
  )}&month=${""}&sessionId=${""}&vehicleNo=${""}`;
}

function condtionalServicesStaffToHOD(staffId, currentPage, customPageSize) {
  let returnString = "";

  if (staffId !== "") {
    return `/api/ListOfSubjectTeachersByStaffId?pageNumber=${currentPage}&pageSize=${parseInt(
      customPageSize
    )}&staffId=${staffId}`;
  }
  return `/api/ListOfSubjectTeachers?pageNumber=${currentPage}&pageSize=${parseInt(
    customPageSize
  )}&staffId=`;
}

function condtionalServicesStaffInHOD(hodId, currentPage, customPageSize) {
  let returnString = "";

  if (hodId !== "") {
    return `/api/EnrollStaffInHODPostId?pageNumber=${currentPage}&pageSize=${parseInt(
      customPageSize
    )}&hodPostId=${hodId}`;
  }
  return `/api/EnrollStaffInHOD?pageNumber=${currentPage}&pageSize=${parseInt(
    customPageSize
  )}&hodPostId=`;
}

function condtionalServicesExclusion(
  complainType,
  currentPage,
  customPageSize
) {
  let returnString = "";

  if (complainType !== "") {
    return `/api/StudentComplains/?pageNumber=${parseInt(
      currentPage
    )}&pageSize=${customPageSize}&complainType=${complainType}`;
  }
  return `/api/StudentComplains/?pageNumber=${parseInt(
    currentPage
  )}&pageSize=${customPageSize}&complainType=${""}`;
}

function condtionalServicesLoginTimeLimit(
  loginType,
  currentPage,
  customPageSize
) {
  let returnString = "";

  if (loginType !== "") {
    return `/api/LoginTimeLimit/?loginType=${loginType}&pageNumber=${parseInt(
      currentPage
    )}&pageSize=${customPageSize}`;
  }
  return `/api/LoginTimeLimit/?loginType=${""}&pageNumber=${parseInt(
    currentPage
  )}&pageSize=${customPageSize}`;
}

function condtionalServicesInvestigation(
  sessionId,
  currentPage,
  customPageSize
) {
  let returnString = "";

  if (sessionId !== "") {
    return `/api/InvestigationSessionWise/?pageNumber=${parseInt(
      currentPage
    )}&pageSize=${customPageSize}&sessionId=${sessionId}`;
  }
  return `/api/InvestigationCommittee/?pageNumber=${parseInt(
    currentPage
  )}&pageSize=${customPageSize}`;
}

function condtionalServicesExclusionRecord(
  sessionId,
  currentPage,
  customPageSize
) {
  let returnString = "";

  if (sessionId !== "") {
    return `/api/StudentExclusionRecords/?pageNumber=${parseInt(
      currentPage
    )}&pageSize=${customPageSize}&sessionId=${sessionId}`;
  }
  return `/api/StudentExclusionRecords/?pageNumber=${parseInt(
    currentPage
  )}&pageSize=${customPageSize}`;
}

function condtionalServicesPortalCoordinator(
  studentId,
  currentPage,
  customPageSize
) {
  let returnString = "";

  if (studentId !== "") {
    return `/api/StudentPassword?studentId=${studentId}&pageNumber=${currentPage}&pageSize=${parseInt(
      customPageSize
    )}`;
  }
  return `/api/StudentPassword?studentId=&pageNumber=${currentPage}&pageSize=${parseInt(
    customPageSize
  )}`;
}

function condtionalServicesPortalCoordinatorStaff(
  staffId,
  currentPage,
  customPageSize
) {
  let returnString = "";

  if (staffId !== "") {
    return `/api/StaffPassword?staffId=${staffId}&pageNumber=${currentPage}&pageSize=${parseInt(
      customPageSize
    )}`;
  }
  return `/api/StaffPassword?staffId=&pageNumber=${currentPage}&pageSize=${parseInt(
    customPageSize
  )}`;
}

function condtionalServicesPortalCoordinatorParent(
  cnic,
  currentPage,
  customPageSize
) {
  let returnString = "";

  if (cnic !== "") {
    return `/api/ParentPassword?cnic=${cnic}&pageNumber=${currentPage}&pageSize=${parseInt(
      customPageSize
    )}`;
  }
  return `/api/ParentPassword?cnic=&pageNumber=${currentPage}&pageSize=${parseInt(
    customPageSize
  )}`;
}

function condtionalServicesPortalCoordinatorDean(
  staffId,
  currentPage,
  customPageSize
) {
  let returnString = "";

  if (staffId !== "") {
    return `/api/DeanPassword?staffId=${staffId}&pageNumber=${currentPage}&pageSize=${parseInt(
      customPageSize
    )}`;
  }
  return `/api/DeanPassword?staffId=&pageNumber=${currentPage}&pageSize=${parseInt(
    customPageSize
  )}`;
}

function condtionalServicesPortalCoordinatorCoordinator(
  staffId,
  currentPage,
  customPageSize
) {
  let returnString = "";

  if (staffId !== "") {
    return `/api/CoordinatorPassword?staffId=${staffId}&pageNumber=${currentPage}&pageSize=${parseInt(
      customPageSize
    )}`;
  }
  return `/api/CoordinatorPassword?staffId=&pageNumber=${currentPage}&pageSize=${parseInt(
    customPageSize
  )}`;
}

function condtionalServicesPortalCoordinatorHOD(
  staffId,
  currentPage,
  customPageSize
) {
  let returnString = "";

  if (staffId !== "") {
    return `/api/HODPassword?staffId=${staffId}&pageNumber=${currentPage}&pageSize=${parseInt(
      customPageSize
    )}`;
  }
  return `/api/HODPassword?staffId=&pageNumber=${currentPage}&pageSize=${parseInt(
    customPageSize
  )}`;
}

function condtionalServicesViewStaffSubject(
  staffId,
  currentPage,
  customPageSize
) {
  let returnString = "";

  if (staffId !== "") {
    return `/api/GetSubjectByStaffId?pageNumber=${currentPage}&pageSize=${parseInt(
      customPageSize
    )}&staffId=${staffId}`;
  }
  return `/api/GetSubjectStaffList?pageNumber=${currentPage}&pageSize=${parseInt(
    customPageSize
  )}`;
}

function condtionalServicesPortalCoordinatorManager(
  staffId,
  currentPage,
  customPageSize
) {
  let returnString = "";

  if (staffId !== "") {
    return `/api/ManagerPassword?staffId=${staffId}&pageNumber=${currentPage}&pageSize=${parseInt(
      customPageSize
    )}`;
  }
  return `/api/ManagerPassword?staffId=&pageNumber=${currentPage}&pageSize=${parseInt(
    customPageSize
  )}`;
}

function condtionalServicesPortalCoordinatorAdmin(
  staffId,
  currentPage,
  customPageSize
) {
  let returnString = "";

  if (staffId !== "") {
    return `/api/AdminPassword?staffId=${staffId}&pageNumber=${currentPage}&pageSize=${parseInt(
      customPageSize
    )}`;
  }
  return `/api/AdminPassword?staffId=&pageNumber=${currentPage}&pageSize=${parseInt(
    customPageSize
  )}`;
}

function condtionalServicesViewStaffCoordinator(
  postId,
  currentPage,
  customPageSize
) {
  let returnString = "";

  if (postId !== "") {
    return `/api/StaffByPostId?pageNumber=${currentPage}&pageSize=${parseInt(
      customPageSize
    )}&postId=${postId}`;
  }
  return `/api/StaffListCoordinator?pageNumber=${currentPage}&pageSize=${parseInt(
    customPageSize
  )}`;
}

function condtionalServicesBioMetricAttendance(
  staffId,
  currentPage,
  customPageSize
) {
  let returnString = "";

  if (staffId !== "") {
    return `/api/BioMetricStaffAttendance/?staffId=${staffId}&pageNumber=${currentPage}&pageSize=${parseInt(
      customPageSize
    )}`;
  }
  return `/api/BioMetricStaffAttendance/?staffId=&pageNumber=${currentPage}&pageSize=${parseInt(
    customPageSize
  )}`;
}

function condtionalServicesBioMetricRegistered(
  staffId,
  currentPage,
  customPageSize
) {
  let returnString = "";

  if (staffId !== "") {
    return `/api/BioMetricRegisteredStaffList/?staffId=${staffId}&pageNumber=${currentPage}&pageSize=${parseInt(
      customPageSize
    )}`;
  }
  return `/api/BioMetricRegisteredStaffList/?staffId=&pageNumber=${currentPage}&pageSize=${parseInt(
    customPageSize
  )}`;
}

function condtionalServicesStudentParent(
  classId,
  sectionId,
  sessionId,
  studentId
) {
  let returnString = [];

  if (classId !== "" && sectionId !== "" && sessionId !== "") {
    return `/api/GetStudentParentForMessage/?classId=${classId}&sectionId=${sectionId}&sessionId=${sessionId}&studentId=`;
  }
  if (
    classId !== "" &&
    sectionId !== "" &&
    sessionId !== "" &&
    studentId !== ""
  ) {
    return `/api/GetStudentParentForMessage/?classId=${classId}&sectionId=${sectionId}&sessionId=${sessionId}&studentId=${studentId}`;
  }
  return returnString;
}

export function getFrequencyCount(originalArr, getUniqueArray) {
  let max = 0;
  let collectMax = [];
  for (let i = 0; i < getUniqueArray.length; i++) {
    for (let j = 0; j < originalArr.length; j++) {
      if (getUniqueArray[i] === originalArr[j]) {
        max += 1;
      }
    }
    collectMax.push(max);
    max = 0;
  }
  return collectMax;
}
export const staffPost = [
  { name: "Admin", id: 1 },
  { name: "Staff", id: 2 },
  { name: "Dean", id: 3 },
  { name: "Hod", id: 4 },
  { name: "Academic Coordinator", id: 5 },
  { name: "Manager/Coordinator", id: 6 },
];
const renderingType = [
  {
    name:'Entry Test' , code:'Et',
  },
  {
    name:'Registration' , code:'R',
  },{
    name:'Tution' , code:'T',
  },{
    name:'Canteen' , code:'C',
  },{
    name:'Stationary' , code:'St',
  },{
    name:'Books' , code:'B',
  },{
    name:'Uniform' , code:'U',
  },{
    name:'Security' , code:'S',
  },{
    name:'Graduation' , code:'G',
  }]
export default {
  renderingType,
  staffPost,
  Months,
  getFrequencyCount,
  viewClassReturn,
  condtionalServices,
  condtionalServicesSubject,
  condtionalServicesVisitor,
  condtionalServicesPatient,
  condtionalServicesTour,
  condtionalServicesOtherCertificate,
  condtionalServicesEvent,
  condtionalServicesOffGame,
  condtionalServicesNewAdmit,
  condtionalServicesClassOfStudent,
  condtionalServicesTransferStudent,
  condtionalServicesTransportMaintenance,
  condtionalServicesStaffToHOD,
  condtionalServicesStaffInHOD,
  condtionalServicesExclusion,
  condtionalServicesInvestigation,
  condtionalServicesExclusionRecord,
  condtionalServicesPortalCoordinator,
  condtionalServicesPortalCoordinatorStaff,
  condtionalServicesPortalCoordinatorParent,
  condtionalServicesPortalCoordinatorDean,
  condtionalServicesPortalCoordinatorCoordinator,
  condtionalServicesPortalCoordinatorHOD,
  condtionalServicesPortalCoordinatorManager,
  condtionalServicesPortalCoordinatorAdmin,
  condtionalServicesViewStaffSubject,
  condtionalServicesViewStaffCoordinator,
  condtionalServicesBioMetricAttendance,
  condtionalServicesBioMetricRegistered,
  condtionalServicesStudentParent,
  condtionalServicesLoginTimeLimit,
  gender,
  maritalStatus,
  YesNO,
  nationality,
  country,
  religion,
  status,
  handleDumpInputChange,
  handleDumpDateChange,
  IsObjectEmpty,
  BloodGroup,
  conversionOfImageUrl,
  stringConversion,
  btnTechHover,
  checkForImageOrFile,
  appStatus,
  newconversionOfImageUrl,
  dataFilteration,
  camelCaseToCamelCase,
  checkBlur,
  term,
  leaveType,
  indentDropDown,
  patientType,
  timeFormat,
  withOrWithout,
  dateToFormat,
  formateToDate,
  medColorRange,
  maintenance,
  exclusion,
  statusExclusion,
  statusNonExclusion,
  committeeHead,
  committeeMember,
  recipients,
  PortalUsers,
  timeTableColors,
  timeTablePeriods,
  courseType,
  loginTimeLimit,
};
