import React, { useEffect, useState, useContext } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import Colors from '../../utils/app_constants/colors_constants';
import general from '../../utils/general';
import httpWithRequiredSetData from '../../hooks/Services/httpWithRequiredSetData';
import Spinner from '../../components/Spinner/Spinner';
import TextInput from '../../components/TextInput/TextInput';
import SelectionInput from '../../components/SelectionInput/SelectionInput';
import TextArea from '../../components/TextArea';
import Button from '../../components/Button';
import SnackBar from '../../components/SnackBar/SnackBar';
import themeContext from "../../context/themeContext/ThemeContext";

const ParentMessageTeacher = props => {
    const themeColor = useContext(themeContext);

	const [msg, setSnackMsg] = useState('');
	const [update, setUpdate] = useState(false);
    
    const [mainData, setMainData ] = useState([])
    const [classData, setClassData ] = useState([])
    const [sectionData, setSectionData ] =useState([])
	const [studentData, setStudentData] = useState([])
	const [staffData, setStaffData ] = useState([])
	const [recipients, setRecipients] = useState([]);
	const [state, setState] = useState({
		title: '',
		message: '',
		sendAll: false,
		accountId: props.name,
		accountType: props.role,
        errorMsg: false,
        Form:'',
        classId: '',
        sectionId:'',
		studentId:'',
		className:'',
		sectionName:'',
		parentId:'',
	});

    const [isClassSectionLoading, classSectionData] = httpWithRequiredSetData(
		'/api/classSectionGradeTeacher',
        [],
        data => {
            setMainData(data)
            setClassData(data['classes']);
        }
    );

    const [isStudentsLoading, students] = httpWithRequiredSetData(
        `/api/GetChildByParent?userName=${props.name}`,
        [],
        data => {
            setStudentData(data)
        }
	);

	const onHandleTextChange = e => {
		const target = e.target;

		if(e.target.name === 'staffId')
		{
			setRecipients([])

			var getStaff = general.dataFilteration(
                classSectionData['staff'],
                ['staffName', 'staffId'],
                {
                    staffId: e.target.value
                }
			);
			setRecipients([getStaff[0]])
		}

		setState(prevState => ({
			...prevState,
			[target.name]: target.value
		}));
    }
	const onSubmit = e => {
		e.preventDefault();

		if (state['message'] === '' || recipients.length === 0) {
			setState(prevState => ({
				...prevState,
				errorMsg: true
			}));
		} else {
			setState(prevState => ({
				...prevState,
				isLoading: true
			}));

			const recipientsTemp = recipients.map(item => ({
				fromParentId: state['parentId'],
				toStaffId: item.staffId,
				title: state['title'],
				message: state['message'],
				accountId: state['accountId'],
				accountType: state['accountType']
			}));
			const data = {
				ParentMsgTeachers: recipientsTemp
			};

			props.sharedActions
				.simpleAddRequest('/api/ParentMsg', data)
				.then(success => {
					setSnackMsg('Message Sent Successfully!');
					props.snackbar();
					setState(prevState => ({
						title: '',
						message: '',
						sendAll: false,
						accountId: props.name,
						accountType: props.role,
						errorMsg: false,
						Form:'',
						classId: '',
						sectionId:'',
						studentId:'',
						className:'',
						sectionName:'',
						parentId:'',
					}));
					setRecipients([]);
					setStaffData([])

				})
				.catch(error => {
					if (error && error.response && error.response.status == 404) {
						// 404-Error no understanding of 404 Error
						setState(prevState => ({
							...prevState,
							isLoading: false
						}));
						props.snackbar();
						setSnackMsg(error.response.data.Message);
					} else if (error && error.response && error.response.status == 400) {
						// 404-Error no understanding of 404 Error
						setState(prevState => ({
							...prevState,
							isLoading: false
						}));
						props.snackbar();
						setSnackMsg(error.response.data.Message);
					}
				});
		}
	};

	useEffect(() => {
		if (state['errorMsg']) {
			setTimeout(() => {
				setState(prevState => ({
					...prevState,
					errorMsg: false
				}));
			}, 3000);
		}
    });
    
    const onHandleStudentChange = e => {
		const target = e.target;

        if (e.target.value !== 'Student') {

			let getStudent = general.dataFilteration(
				studentData,
				['classId', 'sectionId', 'className', 'section', 'parent'],
				{
					studentId: e.target.value
				}
			)

            let getStaff = general.dataFilteration(
                classSectionData['staff'],
                ['staffId', 'staffName'],
                {
					classId: getStudent[0].classId,
					sectionId: getStudent[0].sectionId
                }
            );

            setState(prevState => ({
                ...prevState,
				studentId: target.value,
				classId: getStudent[0].classId,
				sectionId: getStudent[0].sectionId,
				className: getStudent[0].className,
				sectionName: getStudent[0].section,
				parentId: getStudent[0].parent
			}));
			setStaffData(getStaff)
        }
	}
    
    const singleStudent = (
        <div class='col-md-12 ml-5'>
        <div class='col-md-6 d-flex'>
        <SelectionInput
                feildName={'studentId'}
                selectName={'Student'}
                onHandleChange={e => onHandleStudentChange(e)}
                errors={{}}
                optionsArrys={studentData}
                selectedText={'Student'}
                stateData={state}
                // iconClassName={'fas fa-building'}
                optionType="dynamicWithPropIdAndName"
                property={'studentName'}
                propertyId={'studentId'}
                successflag={'successflag'}
                isValidte={false}
                isFeildDisabled = {state['sendAll']}
            // editUse={'staffEdit'}
            />

							<TextInput
							inputLabelName= 'Class Name'
								feildName={'className'}
								errors={{}}
								stateData={state}
								placeholder="Class Name"
								readOnly={true}
								type={'text'}
								isValidte={false}
							/>

<TextInput
							inputLabelName= 'Section Name'
								feildName={'sectionName'}
								errors={{}}
								stateData={state}
								placeholder="Section Name"
								readOnly={true}
								type={'text'}
								isValidte={false}
							/>

<SelectionInput
                feildName={'staffId'}
                selectName={'Staff Name'}
                onHandleChange={e => onHandleTextChange(e)}
                errors={{}}
                optionsArrys={staffData}
                selectedText={'Staff'}
                stateData={state}
                // iconClassName={'fas fa-building'}
                optionType="dynamicWithPropIdAndName"
                property={'staffName'}
                propertyId={'staffId'}
                successflag={'successflag'}
                isValidte={false}
            // editUse={'staffEdit'}
            />

        </div>
{/* { studentData.length === 0 ? '' : <div class="col-md-12 text-center mt-3">
						<div class="custom-control custom-switch">
							<input
								type="checkbox"
								class="custom-control-input"
								id="customSwitch1"
								onChange={allStaff}
							/>
							<label class="custom-control-label" for="customSwitch1">
								<strong>Send to all parents</strong>
							</label>
						</div>
	</div> } */}
        </div>     
    )

	const cards = (
		<div class="card">
			<div
				class="card-header text-center"
				style={{ backgroundColor: '#d3d3d3', color: '#fff' }}
			>
				<h3>
					<strong>NEW MESSAGE</strong>
				</h3>
			</div>
			<div class="card-body">
				<div class="row ">
					<div class="col-md-12">
						{state.errorMsg ? (
							<p style={{ color: 'red', textAlign: 'center' }}>
								Please add recipients and a message
							</p>
						) : (
							''
						)}
					</div>
					<div class="col-md-1" style={{ paddingLeft: '25px' }}>
						<strong>To</strong>
					</div>
					<div class="col-md-11 border-bottom">
						<strong style={{ color: '#bbb' }}>Recipeints </strong>
						{recipients.map(item => (
							<div>
								<strong
									style={{
										backgroundColor: '#bbb'
									}}
								>
									{item.staffName}
								</strong>
							</div>
						))}
					</div>

					<div class="col-md-1"></div>
                    { singleStudent }
					<div
						class="col-md-12 border-top border-info d-flex justify-content-start"
						style={{
							padding: '10px',
							marginTop: '15px'
						}}
					>
						<div class="col-md-1" style={{ marginTop: '38px' }}>
							<strong>From</strong>
						</div>
						<div class="col-md-6 d-flex">
							<TextInput
								feildName={'accountType'}
								errors={{}}
								stateData={state}
								placeholder="Coordinator Post Name"
								readOnly={true}
								type={'text'}
								isValidte={false}
							/>

							<TextInput
								feildName={'accountId'}
								errors={{}}
								stateData={state}
								placeholder="Coordinator Name"
								readOnly={true}
								type={'text'}
								isValidte={false}
							/>
						</div>
					</div>
					<div
						class="col-md-12 border-top border-info d-flex justify-content-start"
						style={{
							marginTop: '15px'
						}}
					>
						<div class="col-md-1" style={{ marginTop: '38px' }}>
							<strong>Title</strong>
						</div>
						<div class="col-md-12">
							<TextInput
								feildName={'title'}
								errors={{}}
								stateData={state}
								placeholder="Add Title"
								type={'text'}
								isValidte={false}
								onHandleChange={onHandleTextChange}
							/>
						</div>
					</div>
					<div
						class="col-md-12 border-top border-info d-flex justify-content-start"
						style={{
							marginTop: '30px'
						}}
					>
						<div class="col-md-1" style={{ marginTop: '38px' }}>
							<strong>Message</strong>
						</div>
						<div class="col-md-12">
							<TextArea
								feildName={'message'}
								onHandleChange={onHandleTextChange}
								errors={{}}
								stateData={state}
								// iconClassName={'fas fa-percentage'}
								placeholder={'Add Message'}
								isValidte={false}
								row={15}
								customWidth={true}
								enterWidth={'180%'}
							/>
						</div>
					</div>
					<div
						class="col-md-12 border-top border-info d-flex justify-content-start"
						style={{
							marginTop: '30px'
						}}
					>
						<Button
							btnBackColor={themeColor.themeColors.primaryColor}
							width="150%"
							buttonClass="customButton"
							btnName="Send"
							loaderBmargin={5}
							stateData={state}
							marginTop={20}
							onHandleSubmit={onSubmit}
							margin={'0 auto'}
							textColor={'white'}
						/>
					</div>
				</div>
			</div>
		</div>
	);

	return (
		<React.Fragment>
			<SnackBar msg={msg} />
			<div>
				<h2 className="h1-responsive font-weight-bold my-4 generalHead" style={{background: themeColor.themeColors.primaryColor}}>
					{' '}
					Message To Staff{' '}
				</h2>
			</div>
			<br />

			{isClassSectionLoading ? <Spinner /> : cards}
		</React.Fragment>
	);
};

function mapStateToProps(state) {
	return {
		name: state.setUserReducer.users.unique_name,
		role: state.setUserReducer.users.role
	};
}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch)
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ParentMessageTeacher);
