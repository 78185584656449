import React, { useEffect, useState, useContext } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import Colors from '../../utils/app_constants/colors_constants';
import { images } from '../../utils';
import general from '../../utils/general';
import CardComponent from './CardComponent';
import httpWithRequiredSetData from '../../hooks/Services/httpWithRequiredSetData';
import Spinner from '../../components/Spinner/Spinner';
import themeContext from "../../context/themeContext/ThemeContext";

const UsersList = props => {
    const themeColor = useContext(themeContext);

	const cards = (
		<div>
			<div class="row d-flex justify-content-between animated slideInUp">
				{general.PortalUsers.map(item => (
					<CardComponent
						messageTo={item.name}
						directTo={item.Link}
						bodyColor={item.bodyColor}
						footerColor={item.footerColor}
					/>
				))}
			</div>
		</div>
	);

	return (
		<React.Fragment>
			<div>
				<h2 className="h1-responsive font-weight-bold my-4 generalHead" style={{background: themeColor.themeColors.primaryColor}}>
					{' '}
					Portal Coordinator{' '}
				</h2>
			</div>
			<br />
			<br />
			<br />
			<br />

			{cards}
		</React.Fragment>
	);
};

function mapStateToProps(state) {
	return {
		name: state.setUserReducer.users.unique_name,
		role: state.setUserReducer.users.role
	};
}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch)
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(UsersList);
