import React from 'react'
import { connect } from 'react-redux';
import $ from 'jquery'
import SelectionInput from '../../../components/SelectionInput/SelectionInput';
import TextArea from '../../../components/TextArea'
import TextInput from '../../../components/TextInput/TextInput'
import FormLayoutWrapper from '../../../components/HOC/FormLayoutWrapper';
import * as sharedActions from '../../../Actions/sharedActions';
import Button from '../../../components/Button';
import SnackBar from '../../../components/SnackBar/SnackBar';
import { bindActionCreators } from 'redux';
import Spinner from '../../../components/Spinner/Spinner';
import general from '../../../utils/general';
import ColorPalette from '../../../components/ColorPalette/ColorPalette'
import ThemeContext from "../../../context/themeContext/ThemeContext";

class AddPatient2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            accountId: props.name,
            accountType: props.role,
            errors: {},
            errorMsg: false,
            isLoadingInitialData: true,
            mainData: {},
            mainData2: {},
            doctorData: [],
            //valid check vals
            name: '',
            studentStaff: '',
            problem: '',
            contactNo: '',
            doctorId: '',

            classId: '',
            sectionId: '',
            studentId: '',
            staffId: '',
            staffPost: '',
            mobileNumber: '',
            parentMobileNumber: '',

            medicalCondition: '',
            recommadationForParents: '',

            searchStudentId: '',
            isLoadingSearch: false
        }
    }

    componentDidUpdate() {
        if (this.state.msg || this.state.errorMsg) {
            setTimeout(() => {
                this.setState({
                    msg: false,
                    errorMsg: false
                });
            }, 3000);
        }
    }

    componentDidMount() {
        $(document).ready(function () {
            $('[data-toggle="tooltip"]').tooltip();
        });

        this.props.sharedActions
        .getDataWithoutDispatch(
            '/api/Doctor'
        )
        .then(success => {
            this.setState({
                doctorData: success,
                isLoadingInitialData: false
            })
        })
        .catch(error => {
            this.setState({
                isLoadingInitialData: false
            });
            console.log(error);
        });
    }

    handleOptionChange = (e) => {
        this.setState({
            studentStaff: e.target.value,
            name: '',
            problem: '',
            contactNo: '',
            classId: '',
            sectionId: '',
            studentId: '',
            staffId: '',
            departmentId: '',
            postId: '',
            staffPost: ''
        })
    };

    onHandleSelectChange = (e) => {
        if (e.target.value === 'Doctor Name') {
            this.setState({
                [e.target.name]: ''
            })
        } else {
            this.setState({
                [e.target.name]: e.target.value
            })
        }
    }

    clicked = (e, item) => {
        this.setState({
            medicalCondition: item
        })
    }

    onHandleTextChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onSubmitGetStudent = (e) => {
        const { searchStudentId } = this.state;

        e.preventDefault();

        if (searchStudentId === '') {
            this.setState({
                error: true,
                errorMsg: true,
            })
        } else {
            this.setState({
                isLoadingSearch: true
            })

            this.props.sharedActions
                .getDataWithoutDispatch(
                    `/api/StudentInformationById?studentId=${searchStudentId}`
                )
                .then(success => {
                    this.setState({
                        isLoadingSearch: false,
                        classId: success.classId,
                        sectionId: success.sectionId,
                        studentId: success.studentAdmissionFormId,
                        name: success.studentName,
                        section: success.section,
                        className: success.className,
                        parentMobileNumber: success.parentMobileNo

                    })
                    console.log(success);
                })
                .catch(error => {
                    this.setState({
                        isLoadingSearch: false
                    })
                })
        }
    }

    onSubmitGetStaff = (e) => {
        const { searchStaffId } = this.state;

        e.preventDefault();

        if (searchStaffId === '') {
            this.setState({
                error: true,
                errorMsg: true,
            })
        } else {
            this.setState({
                isLoadingSearch: true
            })

            this.props.sharedActions
                .getDataWithoutDispatch(
                    `/api/StaffInformationById?staffId=${searchStaffId}`
                )
                .then(success => {
                    console.log(success);
                    this.setState({
                        isLoadingSearch: false,
                        postId: success.postId,
                        staffPost: success.postName,
                        staffId: success.staffId,
                        name: success.staffName,
                        postName: success.postName,
                        mobileNumber: success.mobileNumber
                    })
                })
                .catch(error => {
                    this.setState({
                        isLoadingSearch: false
                    })
                })
        }
    }

    onSubmit = (e) => {
        const { name, studentStaff, classId, sectionId, problem, contactNo, doctorId, studentId, staffId, accountId, accountType, staffPost, medicalCondition, recommadationForParents } = this.state;

        e.preventDefault();

        if (
            Object.is(name, '') ||
            Object.is(studentStaff, '') ||
            Object.is(problem, '') ||
            Object.is(contactNo, '') ||
            Object.is(doctorId, '')
        ) {
            this.setState({
                error: true,
                errorMsg: true
            })
        }
        else {

            this.setState({
                isLoading: true
            })
            const data = {
                name,
                studentStaff,
                problem,
                contactNo,
                doctorId,
                classId,
                sectionId,
                studentId,
                staffId,
                staffPost,
                accountId,
                accountType,

                medicalCondition,
                recommadationForParents
            }
            console.log(data);
            this.props.sharedActions
                .simpleAddRequest('/api/PatientDetail',
                    data)
                .then(success => {
                    this.setState({
                        msg: 'Added Patient Successfully',
                        name: '',
                        studentStaff: '',
                        problem: '',
                        contactNo: '',
                        doctorId: '',

                        classId: '',
                        sectionId: '',
                        studentId: '',
                        staffId: '',
                        stafffPost: '',
                        className: '',
                        section: '',
                        postName: '',

                        isLoading: false,
                        errorMsg: false,
                        error: false,
                    })
                    this.props.snackbar();
                })
        }

    }

    render() {
        const { errors, isLoadingInitialData, doctorData, studentStaff, isLoadingSearch } = this.state;

        const Student = (
            <ThemeContext.Consumer>
            {(context) => (
            <div>
                <div className="row" style={{ justifyContent: 'center' }}>
                    <TextInput
                        feildName={'searchStudentId'}
                        onHandleChange={this.onHandleTextChange}
                        errors={errors}
                        stateData={this.state}
                        placeholder={'Enter Student ID'}
                        isValidte={false}
                    />
                </div>
                <Button
                    icon={true}
                    width="100%"
                    buttonClass="customButton"
                    btnName="Search"
                    loaderBmargin={5}
                    stateData={{
                        isLoading: isLoadingSearch
                    }}
                    marginTop={20}
                    onHandleSubmit={this.onSubmitGetStudent}
                    margin={'0 auto'}
                    textColor='white'
                    btnBackColor={context.themeColors.primaryColor}
                />

                <div className="row">

                    <TextInput
                        feildName={'className'}
                        inputLabelName={'Class Name'}
                        errors={errors}
                        stateData={this.state}
                        placeholder="Class Name"
                        readOnly={true}
                        type={'text'}
                        isValidte={false}
                    />

                    <TextInput
                        feildName={'section'}
                        inputLabelName={'Section'}
                        errors={errors}
                        stateData={this.state}
                        placeholder="Section"
                        readOnly={true}
                        type={'text'}
                        isValidte={false}
                    />

                    <TextInput
                        marginTop={20}
                        feildName={'name'}
                        inputLabelName={'Student Name'}
                        errors={errors}
                        stateData={this.state}
                        placeholder="Student Name"
                        readOnly={true}
                        type={'text'}
                        isValidte={false}
                    />

                    <TextInput
                        marginTop={20}
                        feildName={'parentMobileNumber'}
                        inputLabelName={'Contact Number'}
                        onHandleChange={this.onHandleTextChange}
                        errors={errors}
                        stateData={this.state}
                        placeholder={'Enter Contact #'}
                        isValidte={false}
                        readOnly={true}
                    />
                    <TextArea
                        feildName={'problem'}
                        inputLabelName={'Problem'}
                        onHandleChange={this.onHandleTextChange}
                        errors={errors}
                        stateData={this.state}
                        // iconClassName={'fas fa-percentage'}
                        placeholder={'Add Problem'}
                        isValidte={false}
                    />

                    <TextArea
                        feildName={'recommadationForParents'}
                        inputLabelName={'Recommendation'}
                        onHandleChange={this.onHandleTextChange}
                        errors={errors}
                        stateData={this.state}
                        // iconClassName={'fas fa-percentage'}
                        placeholder={'Add Recommendation'}
                        isValidte={false}
                    />

                    <ColorPalette
                        marginTop={20}
                        colors={general.medColorRange}
                        inputLabelName={'Medical Condition'}
                        onHandleSubmit={(e, item) => this.clicked(e, item)}
                    />
                </div>
            </div>
                                                )}
                                                </ThemeContext.Consumer>
        );


        const Staff = (
            <ThemeContext.Consumer>
            {(context) => (
            <div>
                <div className="row" style={{ justifyContent: 'center' }}>
                    <TextInput
                        feildName={'searchStaffId'}
                        onHandleChange={this.onHandleTextChange}
                        errors={errors}
                        stateData={this.state}
                        placeholder={'Enter Staff ID'}
                        isValidte={false}
                    />
                </div>
                <Button
                    icon={true}
                    width="100%"
                    buttonClass="customButton"
                    btnName="Search"
                    loaderBmargin={5}
                    stateData={{
                        isLoading: isLoadingSearch
                    }}
                    marginTop={20}
                    onHandleSubmit={this.onSubmitGetStaff}
                    margin={'0 auto'}
                    textColor='white'
                    btnBackColor={context.themeColors.primaryColor}
                />
                <div className="row">

                    <TextInput
                        feildName={'postName'}
                        inputLabelName={'Post Name'}
                        errors={errors}
                        stateData={this.state}
                        placeholder="Post Name"
                        readOnly={true}
                        type={'text'}
                        isValidte={false}
                    />

                    <TextInput
                        feildName={'name'}
                        inputLabelName={'Staff Name'}
                        errors={errors}
                        stateData={this.state}
                        placeholder="Staff Name"
                        readOnly={true}
                        type={'text'}
                        isValidte={false}
                    />


                    <TextInput
                        marginTop={20}
                        feildName={'mobileNumber'}
                        inputLabelName={'Contact Number'}
                        onHandleChange={this.onHandleTextChange}
                        errors={errors}
                        stateData={this.state}
                        placeholder={'Enter Contact #'}
                        isValidte={false}
                        readOnly={true}
                    />
                    <TextArea
                        feildName={'problem'}
                        inputLabelName={'Problem'}
                        onHandleChange={this.onHandleTextChange}
                        errors={errors}
                        stateData={this.state}
                        // iconClassName={'fas fa-percentage'}
                        placeholder={'Add Problem'}
                        isValidte={false}
                    />
                </div>
            </div>
                                                            )}
                                                            </ThemeContext.Consumer>
        );

        const Form = (
            <ThemeContext.Consumer>
            {(context) => (
            <FormLayoutWrapper
                formName='Add Patient'
                borderColor= {context.themeColors.primaryColor}
                borderRadius={15}
            >
                {this.state.errorMsg ? (
                    <p style={{ color: 'red', textAlign: 'center' }}>
                        Please fill the form properly
                    </p>
                ) : (
                        ''
                    )}
                <div class='row'>
                    <div class='col-md-12 d-flex justify-content-center'>
                        <SelectionInput
                            marginTop={20}
                            feildName={'doctorId'}
                            selectName={'Doctor Name'}
                            onHandleChange={e => this.onHandleSelectChange(e)}
                            errors={errors}
                            optionsArrys={doctorData}
                            selectedText={'Doctor Name'}
                            stateData={this.state}
                            // iconClassName={'fas fa-building'}
                            optionType="dynamicWithPropIdAndName"
                            property={'doctorName'}
                            propertyId={'id'}
                            successflag={'successflag'}
                            isValidte={false}
                        // editUse={'staffEdit'}
                        />
                    </div>
                </div>
                <br />
                <div class='col-md-12'>
                    <form class='form-inline' style={{ marginBottom: 25 }}>
                        <div class='col-md-12 form-inline d-flex justify-content-around'>
                            <label style={{ color: context.themeColors.primaryColor, fontWeight: 'bold', marginRight: 10, fontSize: 20 }}>
                                <input
                                    style={{ width: 20, height: 20 }}
                                    type="radio"
                                    value='Student'
                                    name={'Form'}
                                    onChange={e => this.handleOptionChange(e)}
                                />
                                Student</label>
                            <label style={{ color: context.themeColors.primaryColor, fontWeight: 'bold', marginRight: 10, fontSize: 20 }}>
                                <input
                                    style={{ width: 20, height: 20 }}
                                    type="radio"
                                    value='Staff'
                                    name={'Form'}
                                    onChange={e => this.handleOptionChange(e)}
                                />
                                Staff</label>
                        </div>
                    </form>
                </div>
                {studentStaff === 'Staff' ? Staff : studentStaff === 'Student' ? Student : ''}
                <br />
                <Button
                    width="100%"
                    buttonClass="customButton"
                    btnName="Add"
                    loaderBmargin={5}
                    stateData={this.state}
                    marginTop={20}
                    onHandleSubmit={this.onSubmit}
                    margin={'0 auto'}
                    textColor={'white'}
                    btnBackColor={context.themeColors.primaryColor}
                />

            </FormLayoutWrapper>
                                                )}
                                                </ThemeContext.Consumer>
        )
        return (
            <React.Fragment>
                <SnackBar msg={this.state.msg} />
                {isLoadingInitialData ? <Spinner /> : Form}
            </React.Fragment>
        )
    }


}

function mapStateToProps(state) {
    return {
        role: state.setUserReducer.users.role,
        name: state.setUserReducer.users.unique_name,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        sharedActions: bindActionCreators(sharedActions, dispatch)
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddPatient2);