import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import TextInput from '../../components/TextInput/TextInput';
import SelectionInput from '../../components/SelectionInput/SelectionInput';
import FormLayoutWrapper from '../../components/HOC/FormLayoutWrapper';
import SnackBar from '../../components/SnackBar/SnackBar';
import ThemeContext from "../../context/themeContext/ThemeContext";

class AddClassTeacherRenew extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			staffPostDepart: {},
			classSection: {},
			postData: [],
			staffData: [],
			isLoading: true,
			userName: props.username,
			role: props.role,
			staffId: '',
			classId: '',
			sectionId: '',
			errMsg: false,
			msg: '',
			errors: {},
			isLoadingForm: false
		};
	}
	componentDidMount() {
		this.props.sharedActions
			.getDataWithoutDispatch('/api/staffpostdepart')
			.then(success => {
				this.setState({
					staffPostDepart: success
				});
			})
			.catch(err => {
				this.setState({
					isLoading: false
				});
				console.log(err);
			});

		this.props.sharedActions
			.getDataWithoutDispatch('/api/classsection')
			.then(success => {
				this.setState({
					isLoading: false,
					classSection: success
				});
			});
	}

	onHandleSubmit = e => {
		e.preventDefault();

		const { staffId, role, userName, classId, sectionId } = this.state;

		if (
			Object.is(staffId, '') ||
			Object.is(sectionId, '') ||
			Object.is(classId, '')
		) {
			this.setState({
				errMsg: true
			});
		} else {
			this.setState({
				isLoadingForm: true
			});

			let data = {
				staffId: staffId,
				classId,
				classId,
				sectionId: sectionId,
				accountId: userName,
				accountType: role
			};

			console.log(data);

			this.props.sharedActions
				.simpleAddRequest('/api/AssignClassTeacher', data)
				.then(success => {
					this.setState({
						classId: '',
						sectionId: '',
						staffId: '',
						postData: [],
						staffData: [],
						msg: 'Successfully Submitted!',
						errMsg: false,
						isLoadingForm: false,
						sectionData: []
					});
					this.props.snackbar();
				})
				.catch(err => {
					if (err && err.response && err.response.status == 400) {
						this.setState({
							isLoadingForm: false,
							subjectData: [],
							msg: err.response.data.Message
						});
						this.props.snackbar();
					}
				});
		}
	};

	onChangeDepartHandle = e => {
		const { staffPostDepart } = this.state;

		const postData = staffPostDepart.post.filter(
			(item, index) => item.departmentId == e.target.value
		);
		this.setState({
			departId: e.target.value,
			postData: postData
		});
	};

	onChangePostHandle = e => {
		const { staffPostDepart, departId } = this.state;

		const staffData = staffPostDepart.staff.filter(
			(item, index) =>
				item.departmentId == departId && item.staffId == e.target.value
		);
		this.setState({
			postId: e.target.value,
			staffData: staffData
		});
	};

	onChangeClassHandle = e => {
		const { classSection } = this.state;
		const sectionData = classSection.section.filter(
			item => item.classId == e.target.value
		);

		this.setState({
			classId: e.target.value,
			sectionData: sectionData
		});
	};

	onHandleSelectChange = e => {
		this.setState({
			[e.target.name]: e.target.value
		});
	};

	render() {
		const {
			errors,
			isLoading,
			isLoadingForm,
			staffPostDepart,
			postData,
			staffData,
			classSection,
			sectionData
		} = this.state;

		const Form = (
			<ThemeContext.Consumer>
            {(context) => (
			<FormLayoutWrapper
				formName="Add Class Teacher"
				borderColor={context.themeColors.primaryColor}
				borderRadius={15}
			>
				{this.state.errMsg ? (
					<p style={{ color: 'red', textAlign: 'center' }}>
						Please fill the form properly
					</p>
				) : (
					''
				)}
				<div className="row">
					<TextInput
						disabledField={true}
						isValidte={false}
						stateData={this.state}
						feildName={'userName'}
						inputLabelName={'Admin Name'}
						errors={errors}
						textFont={16}
						readOnly={true}
					/>

					<TextInput
						disabledField={true}
						isValidte={false}
						stateData={this.state}
						feildName={'role'}
						inputLabelName={'Admin Type'}
						errors={errors}
						textFont={16}
						readOnly={true}
					/>

					<br />
					<br />

					<SelectionInput
						feildName={'className'}
						isValidte={false}
						selectName={'Class Name'}
						onHandleChange={e => this.onChangeClassHandle(e)}
						errors={errors}
						optionsArrys={classSection.classList}
						selectedText={'Class Name'}
						stateData={this.state}
						optionType="dynamicWithPropIdAndName"
						property={'className'}
						propertyId={'id'}
					/>

					<SelectionInput
						feildName={'sectionId'}
						isValidte={false}
						selectName={'Section Name'}
						onHandleChange={this.onHandleSelectChange}
						errors={errors}
						optionsArrys={sectionData}
						selectedText={'Section Name'}
						stateData={this.state}
						optionType="dynamicWithPropIdAndName"
						property={'section'}
						propertyId={'sectionId'}
					/>

					<SelectionInput
						feildName={'departmentName'}
						isValidte={false}
						selectName={'Department Name'}
						onHandleChange={e => this.onChangeDepartHandle(e)}
						errors={errors}
						optionsArrys={staffPostDepart.department}
						selectedText={'Department Name'}
						stateData={this.state}
						optionType="dynamicWithPropIdAndName"
						property={'departmentName'}
						propertyId={'departmentId'}
					/>

					<SelectionInput
						feildName={'postName'}
						isValidte={false}
						selectName={'Post Name'}
						onHandleChange={e => this.onChangePostHandle(e)}
						errors={errors}
						optionsArrys={postData}
						selectedText={'Post Name'}
						stateData={this.state}
						optionType="dynamicWithPropIdAndName"
						property={'postName'}
						propertyId={'postId'}
					/>

					<SelectionInput
						feildName={'staffId'}
						isValidte={false}
						selectName={'Staff Name'}
						onHandleChange={this.onHandleSelectChange}
						errors={errors}
						optionsArrys={staffData}
						selectedText={'Staff Name'}
						stateData={this.state}
						// iconClassName={'fas fa-clock animated fadeIn'}
						optionType="dynamicWithPropIdAndName"
						property={'staffName'}
						propertyId={'staffId'}
					/>
				</div>

				<div class="float-right" style={{ paddingTop: 20 }}>
					<button
						type="submit"
						class="btn"
						onClick={this.onHandleSubmit}
						style={{
							borderRadius: 10,
							backgroundColor: context.themeColors.primaryColor,
							color: 'white'
						}}
					>
						Add
						<span
							style={{
								marginBottom: 5
							}}
							class={`${
								isLoadingForm ? 'spinner-border spinner-border-sm' : ''
							}`}
						></span>
					</button>
				</div>
			</FormLayoutWrapper>
			)}
			</ThemeContext.Consumer>
		);

		const Loader = (
			<ThemeContext.Consumer>
            {(context) => (
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					<div class="spinner-border" style={{ color: context.themeColors.primaryColor }}></div>
				</div>
				)}
			</ThemeContext.Consumer>
		);
		return (
			<div class="container">
				{isLoading ? Loader : Form}
				<SnackBar msg={this.state.msg} />
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch)
	};
}

function mapStateToProps(state) {
	return {
		role: state.setUserReducer.users.role,
		username: state.setUserReducer.users.unique_name
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AddClassTeacherRenew);
