import * as actionTypes from '../actionTypes';

export function setAllBudgetItemInRedux(allData) {
	return {
		type: actionTypes.GET_ALL_BUDGETITEMS,
		payload: allData
	};
}
export function searchBudgetItemBySession(allData) {
	return {
		type: actionTypes.SEARCH_ALL_BUDGETITEMS,
		payload: allData
	};
}
export function getSessionForBudgetItems(allData) {
	return {
		type: actionTypes.SET_ALL_SESSION_NAME_INTO_BUDGETITEM_SELECT,
		payload: allData
	};
}
export function addBudgetItem(Data) {
	return {
		type: actionTypes.ADD_BUDGETITEMS,
		payload: Data
	};
}
export function updateBudgetItemFlag(setFlag) {
	return {
		type: actionTypes.SET_LOADER_FLAG_BUDGETITEMS,
		payload: setFlag
	};
}
export function updateBudgetItem(data) {
	return {
		type: actionTypes.EDIT_BUDGETITEMS_RECORD,
		payload: data
	};
}
export function deleteBudgetItem(id) {
	return {
		type: actionTypes.DELETE_BUDGETITEMS,
		payload: id
	};
}
