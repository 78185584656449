import React, { useState, useEffect, useContext } from "react";
import TextInput from "../../components/TextInput/TextInput";
import SelectionInput from "../../components/SelectionInput/SelectionInput";
import TextArea from "../../components/TextArea";
import { StaffWrapper } from "../Staff";
import CustomDatePicker from "../../components/CustomDatePicker";
import { General } from "../../utils";
import { StaffField } from "../../utils";
import { Types } from "../../hooks/HookReducers/StaffReducer";
import http from "../../hooks/http";
import Button from "../Button";
import Spinner from "../Spinner/Spinner";
import { getNationalityData, staffPost } from "../../utils/general";
import themeContext from "../../context/themeContext/ThemeContext";
import ReactFlagsSelect from "react-flags-select";
const StaffDetails = (props) => {
  const {
    onUserFieldBlur,
    stateData,
    dispatch,
    imageDetailsEdit,
    nonStateData,
    putLoader = false,
    edit,
    imageChange,
    onNextClick,
    errors,
    onUpdate,
    imageChangeEdit = null,
  } = props;
  const [natFlag, isNatFlag] = useState(false);
  const [nationality, setNationality] = useState("");
  debugger;
  const themeColor = useContext(themeContext);
  const [isLoad, fetch, setFetch] = http("/api/StaffPostDepart", []);
  const [isLoading, fetchData, setFetchData] = http(
    "/api/classSectionGradeTeacher",
    []
  );
  const onChangeNationality = (e, data) => {
    setNationality(e);
    debugger;
    General.handleDumpInputChange(
      {
        target: {
          name: "nationality",
        },
      },
      data,
      e
    );
  };

  debugger;
  const [isCDP, getCDP] = http("/api/campusDetail", []);
  const [isCamLoad, camFetchData, setCamData] = http("/api/campusDetail", []);
  debugger;
  console.log("fetch campus data:", camFetchData);

  console.log("fetch depart post data:", fetch);

  console.log("fetch data", fetchData);
  const [sectionData, setSectionData] = useState(null);
  const [subjectData, setSubData] = useState(null);
  const [imgEdit, setImgEdit] = useState(false);

  const [newStateData, setStateData] = useState(edit ? stateData : null);

  debugger;
  let getStaffFields = StaffField.getStaffDetailsFeilds(
    edit ? newStateData : nonStateData,
    edit ? newStateData : stateData,
    edit,
    dispatch,
    edit && stateData && stateData["departmentId"] === 1
      ? fetchData && fetchData["classes"]
      : stateData["classes"],
    fetch,
    camFetchData && camFetchData["campus"],
    getCDP,
    camFetchData && camFetchData["department"],
    edit ? fetchData : [],
    edit && stateData && stateData["departmentId"] === 2 ? true : false,
    staffPost
  );

  const onChangeText = (e, code = "NA") => {
    debugger;
    let getData = { ...newStateData };
    if (e.target.name === "nationality") {
      debugger;
      getData[e.target.name] = code;
      debugger;
      setStateData(getData);
    } else {
      getData[e.target.name] = e.target.value;
      debugger;
      setStateData(getData);
    }
  };

  const onDateChange = (date, fieldName) => {
    debugger;
    if (fieldName === "dob") {
      debugger;
      let getData = { ...newStateData };

      let getAge = new Date().getFullYear() - new Date(date).getFullYear();

      getData[fieldName] = date;
      getData["age"] = getAge;
      setStateData(getData);
    } else {
      debugger;

      let getData = { ...newStateData };
      getData[fieldName] = date;
      console.log(getData);
      setStateData(getData);
    }
  };
  const onSelectChange = (e) => {
    debugger;
  };
  const onClassChange = (e) => {
    debugger;
    if (e.target.name === "classId") {
      let getData = { ...newStateData };
      const { grade } = fetchData;
      const { sections } = fetchData;
      const { subject } = fetchData;

      let getSectionData = sections.filter(
        (item) => item.classId === parseInt(e.target.value)
      );
      let getGradeData = grade.filter(
        (item, index) => item.classId === parseInt(e.target.value)
      );
      let getSubjectsData = subject.filter(
        (item) => item.classId === parseInt(e.target.value)
      );
      console.log(getGradeData);

      getData["className"] = getGradeData[0] && getGradeData[0].className;
      getData["gradeName"] = getGradeData[0] && getGradeData[0].gradeName;
      getData["classId"] = e.target.value;
      getData["gradeId"] = getGradeData[0] && getGradeData[0].gradeId;
      getData["grade"] = getSectionData;
      getData["subjectData"] = getSubjectsData;
      setStateData(getData);
      console.log(getData);
    } else if (e.target.name === "staffPostId") {
      let newGetData1 = JSON.parse(JSON.stringify(newStateData));
      debugger;
      let getFlag =
        General.staffPost.filter(
          (item) => item.id === parseInt(e.target.value)
        )[0].id === 2
          ? true
          : false;

      debugger;
      newGetData1[e.target.name] = e.target.value;
      newGetData1["staffPost"] = General.staffPost.filter(
        (item) => item.id === parseInt(e.target.value)
      )[0].name;

      newGetData1["isAccountEditAccess"] = getFlag;
      setStateData(newGetData1);
    } else {
      debugger;
      let newGetData = JSON.parse(JSON.stringify(newStateData));
      debugger;
      newGetData[e.target.name] = e.target.value;
      debugger;
      setStateData(newGetData);
    }
  };
  const enableUploadButton = (flag) => {
    setImgEdit(flag);
  };
  const onEditHandleSubmit = (e) => {
    if (
      onNextClick(
        e,
        parseInt(stateData["departmentId"]) === 2
          ? "checkStaffDetailsForTeach"
          : "checkStaffDetailsForNonTech",
        newStateData,
        newStateData
      )
    ) {
      onUpdate(dispatch, "ON_SET_STAFF_DETAILS", {
        name: "StaffDetails",
        data: newStateData,
      });
    }
  };
  console.log(edit);
  debugger;
  return (
    <React.Fragment>
      {isLoading ? (
        <p>Loading....</p>
      ) : (
        <React.Fragment>
          <StaffWrapper>
            {/* </div> */}
            {/* </div> */}
            {/* </div> */}
            {getStaffFields.map((item) =>
              item.type === "text" ? (
                <React.Fragment>
                  <TextInput
                    newError={errors}
                    enterWidth={item.enterWidth}
                    customWidth={item.customWidth}
                    isValidte={item.isValidte}
                    onHandleChange={
                      edit ? (e) => onChangeText(e) : item.onHandleChange
                    }
                    stateData={item.stateData}
                    feildName={item.feildName}
                    inputLabelName={item.inputLabelName}
                    errors={item.errors}
                    placeholder={item.placeholder}
                    dispatchFlag={edit}
                    dispatch={dispatch}
                    focus={item.focus}
                    blurEvent={onUserFieldBlur}
                    readOnly={item.readOnly}
                    design={item.design}
                    type={item.type}
                  />
                </React.Fragment>
              ) : item.type === "date" ? (
                <CustomDatePicker
                  // dispatchFlag={false}
                  edit={edit}
                  editOnDateChange={onDateChange}
                  newHandleFlag={true}
                  newError={errors}
                  marginTop={item.marginTop}
                  feildName={item.feildName}
                  inputLabelName={item.inputLabelName}
                  dispatchFlag={item.dispatchFlag}
                  dispatch={item.dispatch}
                  stateData={item.stateData}
                  extraType={item.extraType}
                />
              ) : item.type === "select" ? (
                <SelectionInput
                  isFeildDisabled={item.isFeildDisabled}
                  newError={errors}
                  edit={edit}
                  editClassOnChange={onClassChange}
                  extraData={[
                    fetchData,
                    fetch,
                    camFetchData && camFetchData["campus"],
                    getCDP,
                    staffPost,
                  ]}
                  marginTop={item.marginTop}
                  feildName={item.feildName}
                  selectName={item.selectName}
                  design={item.design}
                  isValidte={item.isValidte}
                  dispatchFlag={item.dispatchFlag}
                  dispatch={dispatch}
                  onHandleChange={edit ? onSelectChange : item.onHandleChange}
                  handleFlag={item.handleFlag}
                  stateData={item.stateData}
                  errors={item.errors}
                  optionsArrys={item.optionsArrays}
                  selectedText={item.selectedText}
                  optionType={item.optionType}
                  property={item.propterty}
                  propertyId={item.propertyId}
                  dynamicDispatchFlag={item.dynamicDispatchFlag}
                />
              ) : item.type === "flagSelect" ? (
                <ReactFlagsSelect
                  searchable
                  className="flagClass"
                  selected={edit ? newStateData.nationality : nationality}
                  onSelect={
                    edit
                      ? (e) =>
                          onChangeText({ target: { name: "nationality" } }, e)
                      : (code) => onChangeNationality(code, item.stateData)
                  }
                />
              ) : (
                ""
              )
            )}
          </StaffWrapper>
        </React.Fragment>
      )}
      {!isLoading && !edit ? (
        <div style={{ marginTop: 20, marginLeft: 20 }}>
          <button
            style={{ background: themeColor.themeColors.primaryColor }}
            class="btns"
            onClick={(e) => {
              debugger;
              onNextClick(
                e,
                parseInt(stateData["departmentId"]) === 2
                  ? "checkStaffDetailsForNonTech"
                  : "checkStaffDetailsForTeach"
              );
            }}
          >
            Next
          </button>
        </div>
      ) : (
        ""
      )}
      {edit && !isLoading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            btnBackColor={themeColor.themeColors.primaryColor}
            stateData={{ isLoading: putLoader }}
            btnName="Update"
            textColor="#fff"
            buttonClass="btn-smart"
            loaderBmargin={3}
            marginTop={20}
            customClause={putLoader}
            onHandleSubmit={onEditHandleSubmit}
          />
        </div>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default StaffDetails;
