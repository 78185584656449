import * as actionTypes from '../Actions/actionTypes';

const defaultState = {
	apiFlag: true,
	Data: [],
	select: [],
	selectHandler: []
};

export default function setCanteenCategoryReducer(
	state = defaultState,
	action = {}
) {
	switch (action.type) {
		case actionTypes.SET_ALL_HANDLER_NAME_INTO_CANTEEN_SELECT:
			return {
				...state,
				selectHandler: [...action.payload]
			};
		case actionTypes.SET_ALL_CAMPUS_NAME_INTO_CANTEEN_SELECT:
			return {
				...state,
				select: [...action.payload]
			};

		case actionTypes.SET_ALL_CATEGORY_NAME_INTO_ITEMS_SELECT:
			return {
				...state,
				select: [...action.payload]
			};
		case actionTypes.GET_ALL_CANTEEN_CATEGORY:
			return {
				...state,
				Data: [...action.payload]
			};
		case actionTypes.SET_LOADER_FLAG_CANTEEN_CATEGORY:
			return {
				...state,
				apiFlag: action.payload
			};
		case actionTypes.DELETE_CANTEEN_CATEGORY:
			let filterCandidateReqList = state.Data.filter(
				filterOBJ => filterOBJ.id !== action.payload
			);

			return {
				...state,
				Data: filterCandidateReqList
			};
		case actionTypes.EDIT_CANTEEN_CATEGORY_RECORD:
			var adminData = [...state.Data];
			var index = adminData.findIndex(obj => obj.t1.id === action.payload.id);

			adminData[index].t1.name = action.payload.name;
			adminData[index].CanteenName = action.payload.canteenName;
			return {
				...state,
				Data: [...adminData]
			};
		default:
			return state;
	}
}
