import React from 'react'
import DatePicker from 'react-date-picker';
import $ from 'jquery';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SnackBar from '../../components/SnackBar/SnackBar';
import * as sharedActions from '../../Actions/sharedActions';
import ThemeContext from "../../context/themeContext/ThemeContext";

const sessionSample = [
    {
        id: 1,
        accountId: 'Muhammad Hamza',
        accountType: 'Admin',
        dateStart: '19/02/20',
        dateEnd: '20/02/20'
    },
    {
        id: 2,
        accountId: 'Muhammad Hamza',
        accountType: 'Admin',
        dateStart: '19/02/20',
        dateEnd: '20/02/20'
    },
    {
        id: 3,
        accountId: 'Muhammad Hamza',
        accountType: 'Admin',
        dateStart: '19/02/20',
        dateEnd: '20/02/20'
    }
]

class ViewSessionRenew extends React.Component {
    state = {
        dateStart: '',
        dateEnd: '',
        modalData: null,
        enabled: false,
        currentPage: 1,
        todosPerPage: 10,
        msg: '',
        id: null,
        isLoading: true,
        sessionData: [],
        accountId: '',
        accountType: '',
        pickerFlag: true,
        disablepage: false,
        disabledPB: true,
        disabledP: true,
        successflag: false,
        count: 1
    }

    componentDidMount() {

        $(document).ready(function () {
            $('[data-toggle="tooltip"]').tooltip();
        });

        this.props.sharedActions
            .getDataWithoutDispatch(
                '/api/getsession'
            )
            .then(success => {
                this.setState({
                    isLoading: false,
                    sessionData: success
                })
            })
            .catch(err => {
                this.setState({
                    isLoading: false
                });
                console.log(err);
            });
    }

    formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [month, day, year].join('/');
    }

    onTakeItem = itemId => {

        this.setState({
            modalData: []
        });
        const { sessionData } = this.state;

        let getData = sessionData.filter(item => item.sessionId === itemId);

        let dateStart = getData[0].startSession.split('/')
        let dateStartObj = new Date(dateStart[2], dateStart[0] - 1, dateStart[1])

        let dateEnd = getData[0].endSession.split('/')
        let dateEndObj = new Date(dateEnd[2], dateEnd[0] - 1, dateEnd[1])

        this.setState({
            modalData: getData,
            accountType: getData[0].accountType,
            accountId: getData[0].accountId,
            dateStart: dateStartObj,
            id: getData[0].sessionId,
            dateEnd: dateEndObj
        });
    };

    onTakeItemDelete = itemId => {
        const { sessionData, deleteIndex } = this.state;
        this.setState({
            modalData: []
        });

        this.props.sharedActions
            .deleteRecordWithoutDispatch('/api/session', itemId)
            .then(success => {
                const result = sessionData.filter(session =>
                    session.sessionId !== itemId
                )

                console.log(result);

                this.setState({
                    msg: "Deleted Session Successfully!",
                    sessionData: result
                });
                this.props.snackbar();
            })
            .catch(error => { });
    }


    onChangeStart = (date) => {
        this.setState({ dateStart: date })
    }

    onChangeEnd = (date) => {
        this.setState({ dateEnd: date })
    }

    /* 
        onHandleText = e => {
            this.setState({
                [e.target.name]: e.target.value
            });
        };
     */
    onHandleSubmit = e => {
        e.preventDefault();
        const { dateStart, dateEnd, id, accountType, accountId, sessionData, editIndex } = this.state;
        let dStart = this.formatDate(dateStart).toString()
        let dEnd = this.formatDate(dateEnd).toString()
        var bodyParameters = { sessionId: id, startSession: dStart, endSession: dEnd, accountId, accountType };
        this.props.sharedActions
            .editRecordWithoutDispatch(
                '/api/Session/',
                id,
                bodyParameters,
            )
            .then(success => {
                sessionData.filter((session, index) => {
                    if (session.sessionId === id) {
                        console.log(index)
                        sessionData.splice(index, 1, bodyParameters)
                    }
                })
                this.setState({ enabled: false, msg: 'Edited Record Successfully!', pickerFlag: true });
                this.props.snackbar();
            })
            .catch(err => { });
    };

    onEditClick = itemId => {
        this.onTakeItem(itemId);
        this.setState({
            enabled: false,

        });
    };

    btnTechHover = () => {
        $(document).ready(function () {
            $('button').hover(
                function () {
                    $(this).css('box-shadow', '0px 5px 5px grey');
                    $(this).css('border-color', 'white');
                },
                function () {
                    $(this).css('box-shadow', '0px 0px 0px white');
                }
            );
        });
    };

    handleClickNextFrwd = () => {
        const { sessionData, todosPerPage, count, currentPage } = this.state;
        let pageNumbers = [];
        for (
            let i = 1;
            i <= Math.ceil(sessionData.length / todosPerPage);
            i++
        ) {
            pageNumbers.push(i);
        }
        console.log('pagelength: ', pageNumbers.length);
        console.log('current page: ', currentPage);
        if (currentPage === pageNumbers.length) {
            this.setState({
                disablepage: true
            });
        } else {
            this.setState(prevState => ({
                currentPage: prevState.currentPage + 1,
                disableBPage: false
            }));
        }
    };
    handleClickNextBack = () => {
        const { sessionData, todosPerPage, count, currentPage } = this.state;
        console.log('current page: ', currentPage);
        if (currentPage === 1) {
            this.setState({
                disableBPage: true,
                disablepage: false
            });
        } else {
            this.setState(prevState => ({
                currentPage: prevState.currentPage - 1
            }));
        }
    };
    handleClickNext = event => {
        this.setState({
            currentPage: Number(event.target.id),
            disablepage: false,
            disableBPage: false
        });
    };



    render() {
        const { modalData, sessionData, isLoading, pickerFlag } = this.state;

        const Loader = (
            <ThemeContext.Consumer>
            {(context) => (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div class="spinner-border" style={{ color: context.themeColors.primaryColor }}></div>
            </div>
            )}
            </ThemeContext.Consumer>
        );
        let pageNumbers = [];
        for (
            let i = 1;
            i <= Math.ceil(sessionData.length / this.state.todosPerPage);
            i++
        ) {
            pageNumbers.push(i);
        }
        const renderPageNumbers = pageNumbers.map((number, index) => {
            return index <= 3 ? (
                <ThemeContext.Consumer>
                {(context) => (
                <li
                    class={`page-item ${
                        this.state.currentPage === number ? 'active' : ''
                        } `}
                >
                    <a
                        key={number}
                        id={number}
                        onClick={this.handleClickNext}
                        class="page-link "
                        href="#"
                        style={{background: context.themeColors.primaryColor}}
                    >
                        {number}
                    </a>
                </li>
                )}
                </ThemeContext.Consumer>
            ) : (
                    ''
                );
        });
        const { currentPage, todosPerPage } = this.state;
        const indexOfLastTodo = currentPage * todosPerPage;
        const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
        const currentTodos = sessionData.slice(indexOfFirstTodo, indexOfLastTodo);
        const renderTodos = currentTodos.map((item, index) => {
            return (
                <ThemeContext.Consumer>
                {(context) => (
                <tr key={index}>
                    <th scope="row">{index + 1}</th>
                    <td style={{ padding: 2 }}>{item.startSession}</td>
                    <td style={{ padding: 2 }}>{item.endSession}</td>
                    <td style={{ padding: 2 }}>{item.accountId}</td>
                    <td style={{ padding: 2 }}>{item.accountType}</td>

                    <td style={{ padding: 2 }}>
                        <a href="#" data-toggle="tooltip" title="Edit!">
                            <button
                                id="firstbutton"
                                onClick={() => this.onEditClick(item.sessionId)}
                                onMouseOver={this.btnTechHover()}
                                data-toggle="modal"
                                data-target="#myModal"
                                style={{
                                    cursor: 'pointer',
                                    fontSize: 20,
                                    border: 'none',
                                    marginLeft: 5,
                                    background: 'none',
                                    marginBottom: 5
                                }}
                            >
                                <i class="fas fa-pen"></i>
                            </button>
                        </a>
                        <a href="#" data-toggle="tooltip" title="Delete!">
                            <button
                                onClick={() => this.onEditClick(item.sessionId)}
                                data-toggle="modal"
                                data-target="#myModal1"
                                style={{
                                    cursor: 'pointer',
                                    fontSize: 20,
                                    border: 'none',
                                    background: 'none',
                                    marginLeft: 5,
                                    marginBottom: 5
                                }}
                            >
                                <i style={{ color: '#D11A2A' }} class="fas fa-trash-alt"></i>
                            </button>
                        </a>
                    </td>
                </tr>
                )}
                </ThemeContext.Consumer>
            );
        });

        const MainContent = (
            <ThemeContext.Consumer>
            {(context) => (
            <div>
                <div class="modal" id="myModal1">
                    <div class="modal-dialog">
                        <div class="modal-content modal_content_custom">
                            <div
                                style={{
                                    borderBottomLeftRadius: 30,
                                    borderBottomRightRadius: 30,
                                    borderTopLeftRadius: 18,
                                    borderTopRightRadius: 18,
                                    background: context.themeColors.primaryColor
                                }}
                                class="modal-header modal_custom_header"
                            >
                                <h4 style={{ color: 'white' }} class="modal-title">
                                    Confirm
								</h4>
                                <button
                                    style={{ color: 'white' }}
                                    type="button"
                                    class="close"
                                    data-dismiss="modal"
                                >
                                    &times;
								</button>
                            </div>
                            <div class="modal-body">
                                {modalData &&
                                    modalData.map((item, index) => (
                                        <div key={index}>
                                            <div style={{ textAlign: 'center' }}>
                                                <h4>Are you sure ?</h4>
                                            </div>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-evenly'
                                                }}
                                            >
                                                <div>
                                                    <button
                                                        disabled={this.state.enabled}
                                                        data-dismiss="modal"
                                                        onClick={() => this.onTakeItemDelete(this.state.id)}
                                                        type="button"
                                                        class="btn btn-primary buttonAppear"
                                                        style={{background: context.themeColors.primaryColor}}
                                                    >
                                                        Yes
													</button>
                                                </div>
                                                <div>
                                                    <button
                                                        data-dismiss="modal"
                                                        disabled={this.state.enabled}
                                                        type="button"
                                                        class="btn btn-primary buttonAppear"
                                                        style={{background: context.themeColors.primaryColor}}
                                                    >
                                                        No
													</button>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal" id="myModal">
                    <div class="modal-dialog">
                        <div class="modal-content modal_content_custom">
                            <div
                                style={{
                                    borderBottomLeftRadius: 30,
                                    borderBottomRightRadius: 30,
                                    borderTopLeftRadius: 18,
                                    borderTopRightRadius: 18,
                                    background: context.themeColors.primaryColor
                                }}
                                class="modal-header modal_custom_header"
                            >
                                <h4 style={{ color: 'white' }} class="modal-title">
                                    Details
								</h4>
                                <button
                                    style={{ color: 'white' }}
                                    type="button"
                                    class="close"
                                    data-dismiss="modal"
                                >
                                    &times;
								</button>
                            </div>
                            <div class="modal-body" >
                                <form onSubmit={this.onHandleSubmit}
                                    style={{ justifyContent: 'center' }}
                                >
                                    {modalData &&
                                        modalData.map((item, index) => (
                                            <div key={index} >
                                                <fieldset disabled={!this.state.enabled}>
                                                    <div class="form-group"
                                                        style={{ textAlign: 'center' }}
                                                    >
                                                        <label for="datestart">
                                                            Start Session:</label>
                                                        <br />
                                                        <DatePicker
                                                            style={{
                                                                borderTop: 0,
                                                                borderLeft: 0,
                                                                borderRight: 0,
                                                                boxShadow: 'none'
                                                            }}
                                                            value={this.state.dateStart}
                                                            onChange={this.onChangeStart}
                                                            class="form-control"
                                                            id="datestart"
                                                            disableCalendar={pickerFlag}
                                                        />
                                                    </div>
                                                    <br />

                                                    <div class="form-group" style={{ textAlign: 'center' }}>
                                                        <label for="dateend"
                                                        >End Session:</label>
                                                        <br />
                                                        <DatePicker
                                                            required
                                                            style={{
                                                                borderTop: 0,
                                                                borderLeft: 0,
                                                                borderRight: 0,
                                                                boxShadow: 'none'
                                                            }}
                                                            value={this.state.dateEnd}
                                                            onChange={this.onChangeEnd}
                                                            class="form-control"
                                                            id="dateend"
                                                            disableCalendar={this.state.pickerFlag}
                                                        />
                                                    </div>
                                                    <br />
                                                </fieldset>
                                                <button
                                                    disabled={this.state.enabled}
                                                    onClick={() =>
                                                        this.setState({ enabled: true, pickerFlag: false })
                                                    }
                                                    type="button"
                                                    class="btn btn-primary buttonAppear"
                                                    style={{background: context.themeColors.primaryColor}}
                                                >
                                                    Edit
												</button>
                                                <button
                                                    style={{ marginLeft: 5, background: context.themeColors.primaryColor }}
                                                    disabled={!this.state.enabled}
                                                    type="submit"
                                                    class="btn btn-primary buttonAppear"
                                                >
                                                    Save
												</button>
                                            </div>
                                        ))}
                                </form>
                            </div>
                            <div class="modal-footer">
                                <button
                                    type="button"
                                    style={{ background: '#00435D' }}
                                    class="btn btn-danger"
                                    data-dismiss="modal"
                                    onClick={() => this.setState({
                                        pickerFlag: true
                                    })}
                                >
                                    Close
								</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="table-responsive">
                    <table class="table table-hover">
                        <thead style={{ background: context.themeColors.primaryColor, color: 'white' }}>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Start Session</th>
                                <th scope="col">End Session</th>
                                <th scope="col">AccountId</th>
                                <th scope="col">AccountType</th>

                                <th scope="col">Actions</th>
                            </tr>
                        </thead>
                        <tbody>{renderTodos}</tbody>
                    </table>
                </div>
            </div>
            )}
            </ThemeContext.Consumer>
        )
        return (
            <ThemeContext.Consumer>
			{(context) => (
            <div>
                <div className="page-header">
                    <SnackBar msg={this.state.msg} />
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between'
                        }}
                    >
                        <div>
                        <h2 class="h1-responsive font-weight-bold text-center my-4 generalHead"
            style={{ background: context.themeColors.primaryColor }}
          >
            View Session
          </h2>
                        </div>

                        <nav aria-label="Page navigation example">
                            <ul style={{ color: '#01AC8A' }} class="pagination">
                                <li
                                    class={`page-item ${
                                        this.state.disableBPage ? 'disabled' : ''
                                        }`}
                                >
                                    <a
                                        onClick={this.handleClickNextBack}
                                        class="page-link"
                                        href="#"
                                    >
                                        {this.state.disableBPage ? (
                                            <i
                                                style={{ color: 'grey' }}
                                                class="far fa-stop-circle"
                                            ></i>
                                        ) : (
                                                <i class="fas fa-backward"></i>
                                            )}
                                    </a>
                                </li>
                                {renderPageNumbers}
                                <li
                                    class={`page-item ${
                                        this.state.disablepage ? 'disabled' : ''
                                        }`}
                                >
                                    <a
                                        onClick={this.handleClickNextFrwd}
                                        class="page-link"
                                        href="#"
                                    >
                                        {this.state.disablepage ? (
                                            <i
                                                style={{ color: 'grey' }}
                                                class="far fa-stop-circle"
                                            ></i>
                                        ) : (
                                                <i class="fas fa-forward"></i>
                                            )}
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
                {isLoading ? Loader : MainContent}
            </div>
            )}
            </ThemeContext.Consumer>
        )
    }

}

function mapDispatchToProps(dispatch) {
    return {
        sharedActions: bindActionCreators(sharedActions, dispatch)
    };
}

export default connect(null, mapDispatchToProps)(ViewSessionRenew);