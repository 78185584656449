import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as sharedActions from "../../Actions/sharedActions";
import SnackBar from "../../components/SnackBar/SnackBar";
import $ from "jquery";
import isEmpty from "lodash/isEmpty";
import { Link } from "react-router-dom";
import isNull from "lodash/isNull";
import moment from "moment";
import GenerateTable1 from "./GenerateTable1";
import ThemeContext from "../../context/themeContext/ThemeContext";

class ViewStudentDisciplineReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      modalEdit: false,
      editToggle: false,
      generateToggle: false,
      generateIndex: "",
      msg: "",
      editIndex: "",
      classId: "",
      sectionId: "",
      subjectId: "",
      pageNo: "1",

      accountId: this.props.userData.unique_name,
      accountType: this.props.userData.role,
    };
  }
  static contextType = ThemeContext;

  componentDidMount() {
    this.getSimple("Student", "student");
    this.getSimple("classSectionGradeTeacher", "classSectionGrade");
    this.getSimple("Session", "session");
  }

  getSimple = (type, state) => {
    this.props.sharedActions
      .getDataWithoutDispatch(`/api/${type}`)
      .then((success) => {
        this.setState({
          [state]: success,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          msg: "Error Occured!!",
          errorMsg: true,
          isLoading: false,
        });
        console.error("Error occured", error);
      });
  };

  PrintContent = (el) => {
    var restorepage = document.body.innerHTML;
    var Printcontent = document.getElementById(el).innerHTML;
    document.body.innerHTML = Printcontent;

    setTimeout(() => {
      window.print();
      window.location.reload();
    }, 700);
    document.body.innerHTML = restorepage;
    window.reload();
  };

  myFunction = () => {
    // Get the snackbar DIV
    var x = document.getElementById("snackbar");

    // StepperGeneralButtons the "show" class to DIV
    x.className = "show";

    // After 3 seconds, remove the show class from DIV
    setTimeout(function () {}, 3000);
  };

  handleSearch = () => {
    const { classId, sectionId, term, sessionId, studentId } = this.state;
    if (
      isNull(this.state.studentId) ||
      isNull(this.state.classId) ||
      isNull(this.state.sectionId) ||
      isNull(term) ||
      isNull(sessionId)
    ) {
      console.log(studentId, classId, sectionId, term, sessionId);
      this.setState({
        msg: "Please Fill The Form Repectively!",
      });
      this.props.snackbar();
    } else {
      this.getSimple(
        `DisciplineReports/Student/?studentId=${this.state.studentId}&classId=${this.state.classId}&sectionId=${this.state.sectionId}&sessionId=${sessionId}&term=${term}`,
        "studentDiscipline"
      );
    }
  };

  checkBook = (studentId) => {
    const { student } = this.state;
    let f = student.student.filter((data) => {
      return data.studentId == studentId;
    });

    if (f.length > 0) {
      this.setState({
        classId: f[0].classId,
        sectionId: f[0].sectionId,
        msg: "Student Found!!",
      });
      this.props.snackbar();
    } else {
      this.setState({
        msg: "Student Not Found!!",
      });
      this.props.snackbar();
    }
  };

  btnTechHover = () => {
    $(document).ready(function () {
      $("button").hover(
        function () {
          $(this).css("box-shadow", "0px 5px 5px grey");
          $(this).css("border-color", "white");
        },
        function () {
          $(this).css("box-shadow", "0px 0px 0px white");
        }
      );
    });
  };

  onHandleText = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    console.log([e.target.name], e.target.value);
  };

  render() {
    const {
      search,
      isLoading,
      editToggle,
      msg,
      accountId,
      accountType,
      classSectionGrade,
      session,
      student,
      studentDiscipline,
      pageNo,
      studentId,
      classId,
      sectionId,
      subjectId,
      term,
      sessionId,
    } = this.state;

    const Loader = (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          className="spinner-border"
          style={{ color: this.context.themeColors.primaryColor }}
        ></div>
      </div>
    );

    return (
      <ThemeContext.Consumer>
        {(context) => (
          <div className="page-header">
            <SnackBar msg={msg} />
            <div
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div class="row">
                <h3
                  class="h1-responsive font-weight-bold text-center my-4 generalHead"
                  style={{ background: context.themeColors.primaryColor }}
                >
                  Student Discipline Report
                </h3>
              </div>
              <div
                class="row my-3 mx-5 py-4"
                style={{
                  border: `2px solid ${context.themeColors.primaryColor}`,
                  borderRadius: 20,
                }}
              >
                <div className="col-md-4">
                  <div className="input-group md-form mb-3">
                    <input
                      class="form-control"
                      type="text"
                      value={studentId}
                      name="studentId"
                      placeholder="Student ID"
                      onChange={this.onHandleText}
                      disabled={!!student && !classSectionGrade}
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary"
                        disabled={!!student && !classSectionGrade}
                        onClick={() => this.checkBook(studentId)}
                        type="button"
                      >
                        <i class="fas fa-search"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <select
                    class="custom-select"
                    value={classId}
                    name="classId"
                    onChange={this.onHandleText}
                    disabled={!classSectionGrade}
                  >
                    <option value="">Please Select Class</option>
                    {classSectionGrade &&
                      classSectionGrade.classes.map((value, index) => {
                        return (
                          <option value={value.classId}>
                            {value.className}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div class="col-md-4">
                  <select
                    class="custom-select"
                    value={sectionId}
                    name="sectionId"
                    onChange={this.onHandleText}
                    disabled={!classId}
                  >
                    <option value="">Please Select Section</option>
                    {classSectionGrade &&
                      classSectionGrade.sections.map((value, index) => {
                        return (
                          value.classId == classId && (
                            <option value={value.sectionId}>
                              {value.section}
                            </option>
                          )
                        );
                      })}
                  </select>
                </div>
                <div class="col-md-6">
                  <select
                    class="custom-select"
                    value={term}
                    name="term"
                    onChange={this.onHandleText}
                    disabled={!sectionId}
                  >
                    <option value="">Please Select Term</option>
                    <option value="1st">1st Term</option>
                    <option value="2nd">2nd Term</option>
                    <option value="3rd">3rd Term</option>
                  </select>
                </div>
                <div class="col-md-6">
                  <select
                    class="custom-select"
                    value={sessionId}
                    name="sessionId"
                    onChange={this.onHandleText}
                    disabled={!term}
                  >
                    <option value="">Please Select Session</option>
                    {session &&
                      session.session.map((value, index) => {
                        return (
                          <option value={value.sessionId}>
                            {value.currentSession}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
              <div class="row my-4">
                <div className="col-md-3 text-right"></div>
                <div class="col-md-12 text-center">
                  <button
                    class="btn btn-primary buttonAppear"
                    onClick={this.handleSearch}
                    style={{
                      marginRight: "2%",
                      background: context.themeColors.primaryColor,
                    }}
                  >
                    <i class="fa fa-search"></i> Search
                  </button>
                  <Link
                    to={{
                      pathname: "PrintStudentDisciplineReport",
                      state: {
                        studentDiscipline,
                        session,
                        term,
                        sessionId,
                        pageNo,
                      },
                    }}
                  >
                    <a href="#" data-toggle="tooltip" title="Edit!">
                      <button
                        class="btn btn-primary buttonAppear"
                        disabled={!studentDiscipline}
                        style={{ background: context.themeColors.primaryColor }}
                      >
                        <i className="fas fa-list"></i> View
                      </button>
                    </a>
                  </Link>
                  <select
                    class="custom-select"
                    style={{ width: "15%", marginLeft: "2%" }}
                    value={pageNo}
                    name="pageNo"
                    onChange={this.onHandleText}
                    disabled={!studentDiscipline}
                  >
                    <option value="1">Page No. 1</option>
                    <option value="2">Page No. 2</option>
                  </select>
                </div>
                {/* <div class="row">
                                <div class="col-md-6">
                                <Pagination
                                    disableBPage={disableBPage}
                                    disablepage={disablepage}
                                    currentPage={currentPage}
                                    pageNumber={pageNumber}
                                    handleClickNext={this.handleClickNext}
                                    handleClickNextBack={this.handleClickNextBack}
                                    handleClickNextFrwd={this.handleClickNextFrwd}
                                />
                                </div>
                            </div>
                             */}
              </div>
              <div class="row my-2 mx-2" style={{ paddingLeft: "20px" }}>
                <div class="col-md-4">
                  <span>
                    Student:{" "}
                    <strong style={{ color: context.themeColors.primaryColor }}>
                      {studentDiscipline && studentDiscipline.info.studentName}
                    </strong>
                  </span>
                </div>
                <div class="col-md-4">
                  <span>
                    Class:{" "}
                    <strong style={{ color: context.themeColors.primaryColor }}>
                      {studentDiscipline && studentDiscipline.info.className}
                    </strong>
                  </span>
                </div>
                <div class="col-md-4">
                  <span>
                    Section:{" "}
                    <strong style={{ color: context.themeColors.primaryColor }}>
                      {studentDiscipline && studentDiscipline.info.section}
                    </strong>
                  </span>
                </div>
                <div class="col-md-4">
                  <span>
                    Term:{" "}
                    <strong style={{ color: context.themeColors.primaryColor }}>
                      {studentDiscipline && term}
                    </strong>
                  </span>
                </div>
                <div class="col-md-4">
                  <span>
                    Session:{" "}
                    <strong style={{ color: context.themeColors.primaryColor }}>
                      {studentDiscipline &&
                        session.session.filter(
                          (data) => data.sessionId == sessionId
                        )[0].currentSession}
                    </strong>
                  </span>
                </div>

                <div class="col-md-4">
                  <span>
                    Page No:{" "}
                    <strong style={{ color: context.themeColors.primaryColor }}>
                      {studentDiscipline && `${pageNo} - 2`}
                    </strong>
                  </span>
                </div>
              </div>
              {isLoading ? (
                Loader
              ) : (
                <GenerateTable1
                  Data={studentDiscipline}
                  pageNo={pageNo}
                  primaryColor={this.context.themeColors.primaryColor}
                />
              )}
              <div class="row">
                <div
                  class="col-md-3 text-left colCheck"
                  style={{ borderColor: this.context.themeColors.primaryColor }}
                >
                  <label class="font-weight-bold">ACTION TAKEN</label> <br />
                  <lable>
                    1. CB &nbsp;&nbsp;&nbsp;- &nbsp;Contact Book
                  </lable>{" "}
                  <br />
                  <lable>2. HoD - &nbsp;Department Head</lable> <br />
                  <lable>3. DP &nbsp;&nbsp;- &nbsp;Deputy Principal</lable>{" "}
                  <br />
                  <lable>4. CP &nbsp;&nbsp;- &nbsp;Contact Parent</lable> <br />
                  <lable>
                    5. S &nbsp;&nbsp;&nbsp;&nbsp;- &nbsp;Suspension
                  </lable>{" "}
                  <br />
                  <lable>6. Ex &nbsp;&nbsp;- &nbsp;Expulsion</lable> <br />
                </div>
                {pageNo == "2" ? (
                  <div
                    class="col-md-3 text-right colCheck offset-md-1"
                    style={{
                      borderColor: this.context.themeColors.primaryColor,
                    }}
                  >
                    <label class="font-weight-bold">OTHER TRANSGRESSION</label>{" "}
                    <br />
                    <lable>1. Fi &nbsp;&nbsp;&nbsp;- &nbsp;Fighting</lable>{" "}
                    <br />
                    <lable>2. LA - &nbsp;Offensive Language</lable> <br />
                    <lable>3. VA &nbsp;&nbsp;- &nbsp;Vandalism</lable> <br />
                    <lable>4. MP &nbsp;&nbsp;- &nbsp;Mobile Phones</lable>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        )}
      </ThemeContext.Consumer>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    sharedActions: bindActionCreators(sharedActions, dispatch),
  };
}
function mapStateToProps(state) {
  return {
    userData: state.setUserReducer.users,
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewStudentDisciplineReport);
