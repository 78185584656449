import React, { useEffect, useState, useContext } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import Colors from '../../utils/app_constants/colors_constants';
import { images } from '../../utils';
import general from '../../utils/general';
import CardComponent from './CardComponent';
import httpWithRequiredSetData from '../../hooks/Services/httpWithRequiredSetData';
import Spinner from '../../components/Spinner/Spinner';
import themeContext from "../../context/themeContext/ThemeContext";
import { Link } from 'react-router-dom';

const ListOfRecipients = props => {
    const themeColor = useContext(themeContext);

	const [unreadData, setUnreadData] = useState({});

	const [isLoadingUnreads, unreads] = httpWithRequiredSetData(
		'/api/CountMessageOfStaffs',
		[],
		data => {
			setUnreadData(data);
		}
	);

	const cards = (
		<div class='mt-2'>
			<div class="row d-flex justify-content-between animated slideInUp">
				{general.recipients.map(item => (
					<CardComponent
						redirect={item.Link}
						redirect2={item.Link2}
						messageTo={item.name}
						bodyColor={item.bodyColor}
						footerColor={item.footerColor}
						unread={
							item.name === 'Academic Coordinator'
								? unreadData.academicCoordinator
								: item.name === 'Dean'
								? unreadData.dean
								: item.name === 'Staff'
								? unreadData.staff
								: item.name === 'Manager'
								? unreadData.manager
								: item.name === 'Head Of Department'
								? unreadData.hod
								: ''
						}
						comImg={
							item.name === 'Academic Coordinator'
								? images.imgComCo1
								: item.name === 'Dean'
								? images.imgComCo3
								: item.name === 'Staff'
								? images.imgComCo5
								: item.name === 'Manager'
								? images.imgComCo2
								: item.name === 'Head Of Department'
								? images.imgComCo4
								: ''
						}
					/>
				))}
						<React.Fragment>
			<div class="col-md-4 mt-1 mb-4">
				<div class="card  text-center shadow-lg" style={{ color: '#fff' }}>
					<div class="card-body" style={{ backgroundColor: '#848E96' }}>
					<div class="col-md-12 text-md-center">
							<span
								style={{
									height: '10px',
									width: '10px',
									backgroundColor: '#bbb',
									borderRadius: '50%',
									display: 'inline-block'
								}}
							></span>{' '}
							{''}
							<strong>Unread Messages ({0})</strong>
						</div>
						<br />
						<div class="col-md-12">
							<img
								src={images.imgComCo3}
								class="card-img"
								style={{
									border: `1px solid #fff`,
									width: 100,
									height: 100,
									borderRadius: '50%'
								}}
								alt="Student Img"
							/>
						</div>
						<br />
						<div
							class="col=md-12"
							style={{
								border: '1px solid #fff',
								borderLeft: 0,
								borderRight: 0
							}}
						>
							<strong style={{ color: '#fff' }}>{'Parents'}</strong>
						</div>
					</div>
					<div class="card-footer " style={{ backgroundColor: '#a8b3bd' }}>
						<div class="row">
							<div
								class="col-md-12"
								style={{
									borderRight: `1px solid #fff`
								}}
							>
								<Link to={`/dashboard/MessageParent`} style={{ color: '#fff' }}>
									<i class="fas fa-comment"></i> <br /><strong>Message</strong>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
			</div>
		</div>
	);

	return (
		<React.Fragment>
			<div>
				<h2 className="h1-responsive font-weight-bold my-4 generalHead" style={{background: themeColor.themeColors.primaryColor}}>
					{' '}
					Communication Coordinator{' '}
				</h2>
			</div>
			{isLoadingUnreads ? <Spinner /> : cards}
		</React.Fragment>
	);
};

function mapStateToProps(state) {
	return {
		name: state.setUserReducer.users.unique_name,
		role: state.setUserReducer.users.role
	};
}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch)
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ListOfRecipients);
