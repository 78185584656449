import React from "react";
import ViewRendering from "../components/ViewRendering";
import { renderingStuff, images } from "../utils";
import NewViewRendering from "../components/ViewRendering/NewViewRendering";
import DashboardLayout from "../components/HOC/DashboardLayout";

export default function LeaveDashboard(props) {
  return (
    <div>
      <DashboardLayout name="Leave Module's" image={images.imgAcademicLeave}>
        <NewViewRendering
          {...props}
          data={renderingStuff.Leave_Dashboard_Links}
        />
      </DashboardLayout>
      {/* <h3 class="h3-responsive font-weight-bold text-center my-4">
				Leave Module's
			</h3>
			<div
				class="row"
				style={{
					justifyContent: 'center',
					textAlign: 'center',
					marginBottom: -30
				}}
			>
				<div class="col-md-8">
					<div class="md-form mb-0">
						<hr class="style-head" />
					</div>
				</div>
			</div>
			<ViewRendering
				centered={true}
				centerWidth={'50%'}
				data={renderingStuff.Leave_Dashboard_Links}
				mainHead={false}
			/> */}
    </div>
  );
}
