import React from 'react';
import ThemeContext from "../../../context/themeContext/ThemeContext";
export default function CustomReportHeader(props) {
	const { headRenderData, headData, targetedObj } = props;
	const renderItems = (data, item) => {
		if (
			data &&
			data[targetedObj] &&
			data[targetedObj] &&
			data[targetedObj][item]
		) {
			return <span>{data[targetedObj][item]}</span>;
		}
	};
	return (
		<ThemeContext.Consumer>
              {(context) => (
		<div
			style={{
				display: 'flex',
				justifyContent: 'space-between',
				flexWrap: 'wrap',
				border: '3px solid ',
				borderColor:context.themeColors.primaryColor,
				padding: 8
			}}
		>
			{headRenderData.map((item, index) => (
				<div key={index} className="reportText">
					{`${item.displayData}`} : {renderItems(headData, item.fieldName)}
				</div>
			))}
		</div>
		)}
		</ThemeContext.Consumer>
	);
}
