import React, { useState, useEffect, useContext } from "react";
import CustomHeading from "../../../../components/CustomHeading/index";
import { SUB_HEADING, isEmptyFieldInArrOBJ } from "../../../../utils/general";
import ListView from "../../../../components/List/ListView/ListView";
import Button from "../../../../components/Button";
import validateInput, {
  CHECK_FIELD_IS_EMPTY,
} from "../../../../validator/inputValidator";
import { CHECK_MARKS_RANGE } from "../../../../validator/inputValidator";
import InfoComponent from "../../../../components/InfoComponent/InfoComponent";
import * as sharedActions from "../../../../Actions/sharedActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import SnackBar from "../../../../components/SnackBar/SnackBar";
import Colors from "../../../../utils/app_constants/colors_constants";
import ThemeContext from "../../../../context/themeContext/ThemeContext";

function ContinuousCreateTools(props) {
  const themeContext = useContext(ThemeContext);
  const [snackbarMessage, setMsg] = useState("");

  const { id, type } = props.match.params;
  const [error, setError] = useState({});

  const [subTool, setSubTools] = useState([
    {
      pId: id,
      toolName: "",
    },
  ]);

  const onChangeDynamic = (e, index, fieldName) => {
    debugger;
    if (isValid({ toolName: e.target.value }, CHECK_FIELD_IS_EMPTY)) {
      const getClone = JSON.parse(JSON.stringify(subTool));

      getClone[index][fieldName] = e.target.value;

      console.log(getClone);
      debugger;
      setSubTools(getClone);
    } else {
      const getClone = JSON.parse(JSON.stringify(subTool));

      getClone[index][fieldName] = e.target.value;

      console.log(getClone);
      debugger;
      setSubTools(getClone);
    }
  };

  const isValid = (data, validateType) => {
    const { errors, isValid } = validateInput(data, validateType);
    if (!isValid) {
      setError(errors);
    }
    return isValid;
  };
  const onAddTool = () => {
    let getSubTool = [...subTool];
    debugger;
    getSubTool.push({
      pId: id,
      toolName: "",
    });
    setSubTools(getSubTool);
  };

  const onDeleteTool = () => {
    let getSubTool = [...subTool];
    debugger;
    if (subTool.length > 1) {
      getSubTool.pop();
      setSubTools(getSubTool);
    }
  };

  const onSubmit = () => {
    console.log(subTool);
    debugger;
    props.sharedAction
      .simpleAddRequest(`/api/Tool`, { tools: [...subTool] })
      .then((success) => {
        console.log(success);
        debugger;
        props.history.push({
          pathname: "/dashboard/CASubToolViewAdmin",
          state: { postFlag: true },
        });
      })
      .catch((error) => {
        debugger;
        console.log(error);
        setMsg(error.response.data.Message);
        props.snackbar();
      });
  };

  return (
    <div>
      <CustomHeading headingText={"Create Tools"} type={SUB_HEADING} />
      <SnackBar backColor={"#000"} msg={snackbarMessage} />
      <br />
      <div style={{ margin: "0 auto", width: "50%" }}>
        {error["isEmpty"] ? (
          <InfoComponent
            classType="text-danger"
            fontSize={15}
            message={`Note: All fields are required`}
          />
        ) : (
          ""
        )}
        <ListView
          excludedData={["pId"]}
          tableMainData={subTool}
          tableHeadData={["S #", "Tool Name"]}
          type={type}
          onChangeMarks={onChangeDynamic}
        />
        {subTool.length > 0 ? (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              textColor={"#fff"}
              buttonClass="customButton"
              btnName={"Submit"}
              customClause={isEmptyFieldInArrOBJ(subTool, "toolName")}
              iconSize={19}
              onHandleSubmit={onSubmit}
            />
            <div>
              <Button
                btnBackColor={`${Colors.RED_DANGER}`}
                buttonClass="customButton"
                customBorder={60}
                btnName={""}
                icon={true}
                iconClass="fas fa-plus"
                // customClause={isEmptyFieldInArrOBJ(studentData, 'marks')}
                iconSize={19}
                iconColor={"#fff"}
                onHandleSubmit={onAddTool}
              />
              <br />
              <Button
                btnBackColor={`${Colors.BLACK}`}
                buttonClass="customButton"
                customBorder={60}
                btnName={""}
                icon={true}
                iconClass="fas fa-minus"
                // customClause={isEmptyFieldInArrOBJ(studentData, 'marks')}
                iconSize={19}
                iconColor={"#fff"}
                onHandleSubmit={onDeleteTool}
              />{" "}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
function mapStateToProps(state) {
  return {
    role: state.setUserReducer.users.role,
    name: state.setUserReducer.users.unique_name,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    sharedAction: bindActionCreators(sharedActions, dispatch),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContinuousCreateTools);
