import React, { Component } from 'react';
import ValidationText from '../../utils/app_constants/validate_messages_constants';

const TextInput = props => {
	const {
		focus = false,
		blurEvent,
		edit = false,
		onEditHandleChange,
		newError = {},
		textFont,
		marginTop,
		feildName,
		iconColor,
		inputLabelName,
		onHandleChange,
		errors,
		stateData,
		iconClassName,
		placeholder,
		disabledField,
		type,
		readOnly,
		required,
		customWidth = false,
		enterWidth,
		design = false,
		dispatchFlag = false,
		dispatch,
		isValidte = true
	} = props;

	return (
		<React.Fragment>
			<div class="col-md-6" style={{ marginTop: marginTop }}>
				<div class="md-form mb-0">
					<label for={feildName}>
						<i
							className={iconClassName}
							style={{ marginRight: 4, color: iconColor }}
						/>
						<p style={{ margin: 0, fontSize: textFont, color: '#000' }}>
							{inputLabelName}
						</p>
					</label>
					<input
						onBlur={focus ? blurEvent : null}
						style={{
							width: customWidth ? enterWidth : '',
							borderTop: design ? 0 : '',
							borderLeft: design ? 0 : '',
							borderRight: design ? 0 : '',
							boxShadow: design ? 'none' : ''
						}}
						readOnly={readOnly}
						type={type}
						placeholder={placeholder}
						name={feildName}
						class={`form-control ${
							errors[feildName] && stateData[feildName] === '' && isValidte
								? 'is-invalid'
								: stateData[feildName] !== '' && isValidte
								? 'is-valid'
								: ''
						}`}
						onChange={
							edit
								? e => onEditHandleChange(e)
								: !isValidte
								? e => onHandleChange(e, stateData.current)
								: e => onHandleChange(e)
						}
						disabled={stateData[disabledField]}
						value={stateData[feildName]}
						required={required}
					/>
					{Object.keys(newError).length > 0 && newError[feildName] ? (
						<p style={{ color: 'red', fontSize: 15 }}>
							{ValidationText.FIELD_REQUIRED}
						</p>
					) : (
						''
					)}
					{Object.keys(newError).length > 0 &&
					newError['exist'] &&
					feildName === 'username' ? (
						<p style={{ color: 'red', fontSize: 15 }}>
							{ValidationText.ALREADY_EXIST}
						</p>
					) : (
						''
					)}
				</div>
			</div>
		</React.Fragment>
	);
};
export default TextInput;
