import * as actionTypes from '../Actions/actionTypes';
import isEmpty from 'lodash/isEmpty';

const defaultState = {
	apiFlag: true,
	Data: [],
	sessionSelect: [],
	ItemsSelect: []
};

export default function setBudgetReducer(state = defaultState, action = {}) {
	switch (action.type) {
		case actionTypes.GET_ALL_BUDGET:
			return {
				...state,
				Data: [...action.payload]
			};
		case actionTypes.SET_ALL_SESSION_NAME_INTO_BUDGET_SELECT:
			return {
				...state,
				sessionSelect: action.payload
			};
		case actionTypes.SET_ALL_BUDGETITEM_NAME_INTO_BUDGET_SELECT:
			return {
				...state,
				ItemsSelect: action.payload
			};
		case actionTypes.SET_LOADER_FLAG_BUDGET_ADD:
			return {
				...state,
				apiFlag: action.payload
			};
		case actionTypes.DELETE_BUDGET:
			let filterCandidateReqList = state.Data.filter(
				filterOBJ => filterOBJ.budgetId !== action.payload
			);
			return {
				...state,
				Data: filterCandidateReqList
			};
		case actionTypes.EDIT_BUDGET_RECORD:
			var adminData = [...state.Data];
			var index = adminData.findIndex(
				obj => obj.budgetId === action.payload.id
			);
			adminData[index].particular = action.payload.particular;
			adminData[index].budgetAmount = action.payload.budgetAmount;
			adminData[index].status = action.payload.status;
			return {
				...state,
				Data: [...adminData]
			};
		default:
			return state;
	}
}
