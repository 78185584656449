import React, { Component } from "react";
import "./DisciplineCard.css";
import CardsForDiscipline from "./CardsForDiscipline";
import * as sharedActions from "../../../Actions/sharedActions";
import * as ClassDisciplineAction from "../../../Actions/ClassDiscipline";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SnackBar from "../../../components/SnackBar/SnackBar";
import $ from "jquery";

class ViewSpecialEducation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      DisciplineData: [],

      partialVision: "",
      PartialVisionReason: "",

      partialDeaf: "",
      partialDeafReason: "",

      mobilityIssue: "",
      mobilityIssueReason: "",

      dexterity: "",
      dexterityReason: "",

      stemina: "",
      steminaReason: "",

      protanopia: "",
      protanopiaReason: "",

      dyslexia: "",
      dyslexiaReason: "",

      attentionDefictHyperDisorder: "",
      attentionDefictHyperDisorderReason: "",

      dyscalulia: "",
      dyscaluliaReason: "",

      dysgraphia: "",
      dysgraphiaReason: "",

      processingDeficts: "",
      processingDefictsReason: "",

      majorDepression: "",
      majorDepressionReason: "",

      bipolarDisOrder: "",
      bipolarDisOrderReason: "",

      anxiety: "",
      anxietyReason: "",

      schizophrenia: "",
      schizophreniaReason: "",

      socialInteraction: "",
      socialInteractionReason: "",

      communication: "",
      communicationReason: "",

      behaviour: "",
      behaviourReason: "",

      aspergerSyndrome: "",
      aspergerSyndromeReason: "",

      classWrn1: "",
      classWrn2: "",
      classAction: "",
      homeWorkWrn1: "",
      homeWorkWrn2: "",
      homeWorkAction: "",
      forgettenBooksWrn1: "",
      forgettenBooksWrn2: "",
      forgettenBooksAction: "",
      lateWrn1: "",
      lateWrn2: "",
      lateAction: "",
      BehaviourWrn1: "",
      BehaviourWrn2: "",
      BehaviourAction: "",
      otherWrn1: "",
      otherWrn2: "",
      otherAction: "",

      id: "",
      msg: "",
      loader: true,
    };
  }
  componentDidUpdate() {
    $(".tooltip").tooltip("hide");
  }
  componentDidMount() {
    const { state } = this.props.location;
    this.props.sharedActions
      .getDataWithoutDispatch(
        `/api/StudentSpecialNeeds/?classId=${state.classId}&sectionId=${state.sectionId}&term=${state.term}&subjectId=${state.subjectId}&sessionId=${state.sessionId}&studentId=${state.studentId}`
      )
      .then((success) => {
        this.setState({
          DisciplineData: success,
          id: success.studentInfo[0].id,
          partialDeaf: success.studentInfo[0].partialDeaf,
          partialDeafReason: success.studentInfo[0].PartialDeafReason,
          partialVision: success.studentInfo[0].partialVision,
          PartialVisionReason: success.studentInfo[0].PartialVisionReason,
          anxiety: success.studentInfo[0].anxiety,
          anxietyReason: success.studentInfo[0].anxietyReason,
          aspergerSyndrome: success.studentInfo[0].aspergerSyndrome,
          aspergerSyndromeReason: success.studentInfo[0].aspergerSyndromeReason,
          attentionDefictHyperDisorder: success.studentInfo[0].attentionDefictHyperDisorder,
          attentionDefictHyperDisorderReason: success.studentInfo[0].attentionDefictHyperDisorderReason,
          behaviour: success.studentInfo[0].behaviour,
          behaviourReason: success.studentInfo[0].behaviourReason,
          bipolarDisOrder: success.studentInfo[0].bipolarDisOrder,
          bipolarDisOrderReason: success.studentInfo[0].bipolarDisOrderReason,
          communication: success.studentInfo[0].communication,
          communicationReason: success.studentInfo[0].communicationReason,
          dexterity: success.studentInfo[0].dexterity,
          dexterityReason: success.studentInfo[0].dexterityReason,
          dyscalulia: success.studentInfo[0].dyscalulia,
          dyscaluliaReason: success.studentInfo[0].dyscaluliaReason,
          dysgraphia: success.studentInfo[0].dysgraphia,
          dysgraphiaReason: success.studentInfo[0].dysgraphiaReason,
          dyslexia: success.studentInfo[0].dyslexia,
          dyslexiaReason: success.studentInfo[0].dyslexiaReason,
          majorDepression: success.studentInfo[0].majorDepression,
          majorDepressionReason: success.studentInfo[0].majorDepressionReason,
          mobilityIssue: success.studentInfo[0].mobilityIssue,
          mobilityIssueReason: success.studentInfo[0].mobilityIssueReason,
          processingDeficts: success.studentInfo[0].processingDeficts,
          processingDefictsReason: success.studentInfo[0].processingDefictsReason,
          protanopia: success.studentInfo[0].protanopia,
          protanopiaReason: success.studentInfo[0].protanopiaReason,
          schizophrenia: success.studentInfo[0].schizophrenia,
          schizophreniaReason: success.studentInfo[0].schizophreniaReason,
          socialInteraction: success.studentInfo[0].socialInteraction,
          socialInteractionReason: success.studentInfo[0].socialInteractionReason,
          stemina: success.studentInfo[0].stemina,
          steminaReason: success.studentInfo[0].steminaReason,


          loader: false,
        });
      })
      .catch((error) => {
        this.setState({
          msg: error.response.data.Message,
          btnLoad: false,
        });
        setTimeout(() => {
          this.props.history.push({
            pathname: "/dashboard/UpdateClassDiscipline",
          });
        }, 3000);
        this.props.snackbar();
      });
  }

  onDropChange = (e) => {
    console.log(e.target.value);
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  onHandleSubmit = (e) => {
    e.preventDefault();
    const {
      id,
      partialVision,
      PartialVisionReason,

      partialDeaf,
      partialDeafReason,

      mobilityIssue,
      mobilityIssueReason,

      dexterity,
      dexterityReason,

      stemina,
      steminaReason,

      protanopia,
      protanopiaReason,

      dyslexia,
      dyslexiaReason,

      attentionDefictHyperDisorder,
      attentionDefictHyperDisorderReason,

      dyscalulia,
      dyscaluliaReason,

      dysgraphia,
      dysgraphiaReason,

      processingDeficts,
      processingDefictsReason,

      majorDepression,
      majorDepressionReason,

      bipolarDisOrder,
      bipolarDisOrderReason,

      anxiety,
      anxietyReason,

      schizophrenia,
      schizophreniaReason,

      socialInteraction,
      socialInteractionReason,

      communication,
      communicationReason,

      behaviour,
      behaviourReason,

      aspergerSyndrome,
      aspergerSyndromeReason,
    } = this.state;
    const { state } = this.props.location;
    var bodyParameters = {
      id,
      sessionId: state.sessionId,
      classId: state.classId,
      sectionId: state.sectionId,
      subjectId: state.subjectId,
      staffId: state.staffId,
      studentId: state.studentId,
      term: state.term,
      accountId: state.accountId,
      accountType: state.accountType,
      partialVision,
      PartialVisionReason,

      partialDeaf,
      partialDeafReason,

      mobilityIssue,
      mobilityIssueReason,

      dexterity,
      dexterityReason,

      stemina,
      steminaReason,

      protanopia,
      protanopiaReason,

      dyslexia,
      dyslexiaReason,

      attentionDefictHyperDisorder,
      attentionDefictHyperDisorderReason,

      dyscalulia,
      dyscaluliaReason,

      dysgraphia,
      dysgraphiaReason,

      processingDeficts,
      processingDefictsReason,

      majorDepression,
      majorDepressionReason,

      bipolarDisOrder,
      bipolarDisOrderReason,

      anxiety,
      anxietyReason,

      schizophrenia,
      schizophreniaReason,

      socialInteraction,
      socialInteractionReason,

      communication,
      communicationReason,

      behaviour,
      behaviourReason,

      aspergerSyndrome,
      aspergerSyndromeReason,
    };
    this.props.sharedActions
      .editRecordWithoutDispatch(
        "/api/StudentSpecialNeeds/",
        id,
        bodyParameters
      )
      .then((success) => {
        this.setState({ enabled: false, msg: "Edit Record Successfully!" });
        this.props.snackbar();
      })
      .catch((err) => {
        this.setState({
          msg: err.response.data.Message,
        });
        this.props.snackbar();

        console.log(err);
      });
  };
  render() {
    console.log("Get Data of Discipline", this.state.DisciplineData);
    const { state } = this.props.location;
    const DataForPhysicallyChallenged = [
      {
        Heading: "Partial Vision",
        name1: "partialVision",
        name2: "PartialVisionReason",
        state1: this.state.partialVision,
        state2: this.state.PartialVisionReason,
        id: 1,
      },
      {
        Heading: "Partial Deaf",
        name1: "partialDeaf",
        name2: "partialDeafReason",
        state1: this.state.partialDeaf,
        state2: this.state.partialDeafReason,
        id: 2,
      },
      {
        Heading: "Mobility Issue",
        name1: "mobilityIssue",
        name2: "mobilityIssueReason",
        state1: this.state.mobilityIssue,
        state2: this.state.mobilityIssueReason,
        id: 3,
      },
      {
        Heading: "Dexterity",
        name1: "dexterity",
        name2: "dexterityReason",
        state1: this.state.dexterity,
        state2: this.state.dexterityReason,
        id: 4,
      },
      {
        Heading: "Stamina",
        name1: "stemina",
        name2: "steminaReason",
        state1: this.state.stemina,
        state2: this.state.steminaReason,
        id: 5,
      },
      {
        Heading: "Protanopia",
        name1: "protanopia",
        name2: "protanopiaReason",
        state1: this.state.protanopia,
        state2: this.state.protanopiaReason,
        id: 6,
      },
    ];

    const DataForLearningDifficulty = [
      {
        Heading: "Dyslexia",
        name1: "dyslexia",
        name2: "dyslexiaReason",
        state1: this.state.dyslexia,
        state2: this.state.dyslexiaReason,
        id: 7,
      },
      {
        Heading: "Attention Defict Hyper Disorder",
        name1: "attentionDefictHyperDisorder",
        name2: "attentionDefictHyperDisorderReason",
        state1: this.state.attentionDefictHyperDisorder,
        state2: this.state.attentionDefictHyperDisorderReason,
        id: 8,
      },
      {
        Heading: "Dyscalulia",
        name1: "dyscalulia",
        name2: "dyscaluliaReason",
        state1: this.state.dyscalulia,
        state2: this.state.dyscaluliaReason,
        id: 9,
      },
      {
        Heading: "Dysgraphia",
        name1: "dysgraphia",
        name2: "dysgraphiaReason",
        state1: this.state.dysgraphia,
        state2: this.state.dysgraphiaReason,
        id: 10,
      },
      {
        Heading: "Processing Deficts",
        name1: "processingDeficts",
        name2: "processingDefictsReason",
        state1: this.state.processingDeficts,
        state2: this.state.processingDefictsReason,
        id: 11,
      },
    ];

    const DataForPsuciatric = [
      {
        Heading: "Major Depression",
        name1: "majorDepression",
        name2: "majorDepressionReason",
        state1: this.state.majorDepression,
        state2: this.state.majorDepressionReason,
        id: 12,
      },
      {
        Heading: "Bipolar Disorder",
        name1: "bipolarDisOrder",
        name2: "bipolarDisOrderReason",
        state1: this.state.bipolarDisOrder,
        state2: this.state.bipolarDisOrderReason,
        id: 13,
      },
      {
        Heading: "Anxiety",
        name1: "anxiety",
        name2: "anxietyReason",
        state1: this.state.anxiety,
        state2: this.state.anxietyReason,
        id: 14,
      },
      {
        Heading: "Schizophrenia",
        name1: "schizophrenia",
        name2: "schizophreniaReason",
        state1: this.state.schizophrenia,
        state2: this.state.schizophreniaReason,
        id: 15,
      },
    ];

    const DataForAutism = [
      {
        Heading: "Social Interaction",
        name1: "socialInteraction",
        name2: "socialInteractionReason",
        state1: this.state.socialInteraction,
        state2: this.state.socialInteractionReason,
        id: 16,
      },
      {
        Heading: "Communication",
        name1: "communication",
        name2: "communicationReason",
        state1: this.state.communication,
        state2: this.state.communicationReason,
        id: 17,
      },
      {
        Heading: "Behaviour",
        name1: "behaviour",
        name2: "behaviourReason",
        state1: this.state.behaviour,
        state2: this.state.behaviourReason,
        id: 18,
      },
      {
        Heading: "Asperger Syndrome",
        name1: "aspergerSyndrome",
        name2: "aspergerSyndromeReason",
        state1: this.state.aspergerSyndrome,
        state2: this.state.aspergerSyndromeReason,
        id: 19,
      },
    ];
    const Loading = (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div class="spinner-border" style={{ color: "#01AC8A" }}></div>
      </div>
    );
    const DataAfterLoad = (
      <div>
        <link
          href="//maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css"
          rel="stylesheet"
          id="bootstrap-css"
        />
        <script src="//maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js"></script>
        <script src="//cdnjs.cloudflare.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script>

        <section id="team" class="pb-5">
          <div class="container">
            <div class="" style={{ marginTop: -30 }}>
              <div class="row justify-content-between">
                <div class="col-md-4">
                  <div class="md-form mb-0">
                    <label for="name" class="">
                      Term:
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </label>
                    <label
                      for="name"
                      class=""
                      style={{
                        fontWeight: "bold",
                        fontSize: 20,
                        color: "#01425e",
                      }}
                    >
                      {state.term}
                    </label>
                  </div>
                </div>
                <div class="col-md-5">
                  <div class="md-form mb-0">
                    <h4 class="text-uppercase" style={{ color: "#007b5e" }}>
                      View Special Education Needs
                    </h4>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="md-form mb-0">
                    <label for="name" class="">
                      Class: &nbsp;&nbsp;&nbsp;&nbsp;
                    </label>
                    <label
                      for="name"
                      class=""
                      style={{
                        fontWeight: "bold",
                        fontSize: 20,
                        color: "#01425e",
                      }}
                    >
                      {state.className}
                    </label>
                  </div>
                </div>
              </div>
              <div class="row justify-content-between">
                <div class="col-md-4">
                  <div class="md-form mb-0">
                    <label for="name" class="">
                      Session: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </label>
                    <label
                      for="name"
                      class=""
                      style={{
                        fontWeight: "bold",
                        fontSize: 20,
                        color: "#01425e",
                      }}
                    >
                      {state.sessionName}
                    </label>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="md-form mb-0">
                    <label for="name" class="">
                      Section: &nbsp;
                    </label>
                    <label
                      for="name"
                      class=""
                      style={{
                        fontWeight: "bold",
                        fontSize: 20,
                        color: "#01425e",
                      }}
                    >
                      {state.sectionName}
                    </label>
                  </div>
                </div>
              </div>

              <div class="row justify-content-between">
                <div class="col-md-4">
                  <div class="md-form mb-0">
                    <label for="name" class="">
                      Student: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </label>
                    <label
                      for="name"
                      class=""
                      style={{
                        fontWeight: "bold",
                        fontSize: 20,
                        color: "#01425e",
                      }}
                    >
                      {state.studentName}
                    </label>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="md-form mb-0">
                    <label for="name" class="">
                      Subject: &nbsp;
                    </label>
                    <label
                      for="name"
                      class=""
                      style={{
                        fontWeight: "bold",
                        fontSize: 20,
                        color: "#01425e",
                      }}
                    >
                      {state.subjectName}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              {DataForPhysicallyChallenged.map((item, index) => (
                <div class="col-xs-12 col-sm-6 col-md-4">
                  <CardsForDiscipline
                    key={index}
                    Index={index}
                    info={item}
                    onChangeDrop={this.onDropChange}
                    stateData={this.state}
                    heading="Physically Challenged"
                  />
                </div>
              ))}
              
              {DataForLearningDifficulty.map((item, index) => (
                <div class="col-xs-12 col-sm-6 col-md-4">
                  <CardsForDiscipline
                    Index={index}
                    key={index}
                    info={item}
                    onChangeDrop={this.onDropChange}
                    stateData={this.state}
                    heading="Learning Difficulties"
                  />
                </div>
              ))}
              <div className="col-md-12">
                <br />
              </div>
              {DataForPsuciatric.map((item, index) => (
                <div class="col-xs-12 col-sm-6 col-md-4">
                  <CardsForDiscipline
                    Index={index}
                    key={index}
                    info={item}
                    onChangeDrop={this.onDropChange}
                    stateData={this.state}
                    heading="Psychiatric Disabilities"
                  />
                </div>
              ))}
               <div className="col-md-12">
                <br />
              </div>
              {DataForAutism.map((item, index) => (
                <div class="col-xs-12 col-sm-6 col-md-4">
                  <CardsForDiscipline
                    Index={index}
                    key={index}
                    info={item}
                    onChangeDrop={this.onDropChange}
                    stateData={this.state}
                    heading="Autism Spectrum Disorder"
                  />
                </div>
              ))}
            </div>
            <div class="text-right">
              <button
                type="submit"
                onClick={this.onHandleSubmit}
                class="buttonHoverFill"
              >
                Update
              </button>
            </div>
          </div>
        </section>
      </div>
    );
    return (
      <div>
        <SnackBar backColor={"#000"} msg={this.state.msg} />
        {this.state.loader ? Loading : DataAfterLoad}
      </div>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    ClassDisciplineAction: bindActionCreators(ClassDisciplineAction, dispatch),
    sharedActions: bindActionCreators(sharedActions, dispatch),
  };
}
export default connect(null, mapDispatchToProps)(ViewSpecialEducation);
