import React, { Component } from "react";
import "./main.css";
import { Link } from "react-router-dom";
import $ from "jquery";
class Main extends Component {
  constructor(props) {
    super(props);
    console.log(this.props);
  }

  btnTechHover = () => {
    $(document).ready(function () {
      $("button").hover(
        function () {
          $(this).css(
            "background",
            "linear-gradient(to bottom left, #fcd810 5%, #f47856 87%)"
          );
          $(this).css("border-color", "white");
        },
        function () {
          $(this).css("background", "#5cc5c9");
        }
      );
    });
  };
  render() {
    return (
      <div className="mainBackImg">
        <div className="container">
          <div className="row justify-content-right">
            <div
              class="col-md-6 text-center animated slideInRight"
              style={{ marginTop: 180 }}
            >
              <img
                style={{
                  width: 440,
                  height: 130,
                }}
                class="img-fluid"
                src={require("../../../Assets/DoratPictures/newSS.png")}
              />
            </div>
            <div
              class="col-md-6 text-center animated slideInLeft"
              style={{ marginTop: 180 }}
            >
              <img
                style={{
                  width: 500,
                  height: 100,
                }}
                class="img-fluid"
                src={require("../../../Assets/DoratPictures/newlogo.png")}
              />
            </div>
          </div>
          {/* <div className="row animated slideInUp">
            <div class="col-md-12 text-center">
              <img
                style={{
                  width: 400,
                  height: "auto",
                }}
                class="img-fluid"
                src={require("../../../Assets/DoratPictures/linkit logo.png")}
              />
            </div>
          </div> */}
          <br />

          <div class="row justify-content-center align-content-center">
            <div class="col-md-1"></div>
            <div class="col-md-2">
              <div class="dropdown">
                <button
                  class="btn btn-info"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  style={{
                    width: 150,
                    height: 40,
                    // backgroundColor: "#5cc5c9",
                    // color: "white",
                  }}
                >
                  <i
                    style={{ marginRight: 6 }}
                    className="fas fa-user-shield animated fadeIn"
                  />
                  Select Role
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <Link to="/Login/:Owner">
                    {" "}
                    <a class="dropdown-item" href="#">
                      {" "}
                      <i
                        style={{ position: "relative", right: 8 }}
                        class="fas fa-dot-circle"
                      ></i>
                      Owner
                    </a>
                  </Link>
                  <Link to="./Login/:General Manager">
                    <a class="dropdown-item" href="#">
                      <i
                        style={{ position: "relative", right: 8 }}
                        class="fas fa-dot-circle"
                      ></i>
                      General Manager
                    </a>
                  </Link>
                  <Link to="./Login/:Admin">
                    <a class="dropdown-item" href="#">
                      <i
                        style={{ position: "relative", right: 8 }}
                        class="fas fa-dot-circle"
                      ></i>
                      Admin
                    </a>
                  </Link>
                  <Link to="./Login/:Manager">
                    <a class="dropdown-item" href="#">
                      <i
                        style={{ position: "relative", right: 8 }}
                        class="fas fa-dot-circle"
                      ></i>
                      Manager
                    </a>
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-md-2">
              {/* <div className="b" style={{ width: 1000 }}> */}
              <Link to="/Login/:Student">
                <button
                  type="button"
                  class="btn btn-info"
                  style={{ width: 150, height: 40 }}
                >
                  <i
                    style={{ marginRight: 6 }}
                    className="fas fa-user-alt animated fadeIn"
                  />
                  Student
                </button>
              </Link>
            </div>
            <div className="col-md-2">
              <Link to="/Login/:Parent">
                <button
                  type="button"
                  class="btn btn-info"
                  style={{ width: 150, height: 40, margin: 1 }}
                >
                  <i
                    style={{ marginRight: 6 }}
                    className="fas fa-restroom animated fadeIn"
                  />
                  Parent
                </button>
              </Link>
            </div>
            <div class="col-md-2">
              <div class="dropdown">
                <button
                  class="btn btn-info"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  style={{ width: 150, height: 40 }}
                >
                  <i
                    style={{ marginRight: 6 }}
                    className="fas fa-user-shield animated fadeIn"
                  />
                  Staff Roles
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <Link to="/Login/:Staff">
                    {" "}
                    <a class="dropdown-item" href="#">
                      {" "}
                      <i
                        style={{ position: "relative", right: 8 }}
                        class="fas fa-dot-circle"
                      ></i>
                      Staff
                    </a>
                  </Link>
                  <Link to="./Login/:Dean">
                    <a class="dropdown-item" href="#">
                      <i
                        style={{ position: "relative", right: 8 }}
                        class="fas fa-dot-circle"
                      ></i>
                      Dean
                    </a>
                  </Link>
                  <Link to="./Login/:Hod">
                    <a class="dropdown-item" href="#">
                      <i
                        style={{ position: "relative", right: 8 }}
                        class="fas fa-dot-circle"
                      ></i>
                      HOD
                    </a>
                  </Link>
                  <Link to="./Login/:Coordinator">
                    <a class="dropdown-item" href="#">
                      <i
                        style={{ position: "relative", right: 8 }}
                        class="fas fa-dot-circle"
                      ></i>
                      Coordinator
                    </a>
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-md-2"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default Main;
