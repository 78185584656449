import React, { useState, useReducer, useContext } from "react";
import { StaffDoc } from "../../../components/Staff";
import staff from "../../../hooks/HookReducers/StaffReducer/index";
import General from "../../../utils/general";
import Button from "../../../components/Button";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as sharedActions from "../../../Actions/sharedActions";
import SnackBar from "../../../components/SnackBar/SnackBar";
import Document_File_Constant from "../../../utils/app_constants/document_file_constants";
import Axios from "axios";
import config from "../../../config/config";
import Colors from "../../../utils/app_constants/colors_constants";
import UploadProgressBar from "../../../components/UploadProgressBar/UploadProgressBar";
import { StaffField } from "../../../utils";
import themeContext from "../../../context/themeContext/ThemeContext";

function StaffDocumentUploadSection(props) {
  const themeColor = useContext(themeContext);
  const { id, type } = props;
  const [customLoader, setCustomLoader] = useState(false);
  const { paramsId } = props.match.params;
  debugger;
  const [snackbarMessage, setMsg] = useState("");
  const [check, setCheck] = useState(0);
  const { onSkipDocuments } = props;
  const [errors, setErrors] = useState({});
  const [newState, dispatch] = useReducer(
    staff.staffDocsReducer,
    staff.staffDocState
  );
  const docsChange = (e) => {
    e.preventDefault();
    try {
      let reader = new FileReader();
      let file = e.target.files[0];

      if (
        !General.checkForImageOrFile(
          false,
          true,
          errors,
          file.size,
          2.1,
          file.name,
          "fileType",
          setErrors
        )
      ) {
        dispatch({
          type: "onFileChange",
          payload: {
            name: e.target.name,
            value: e.target.files[0],
          },
        });
      } else {
      }
    } catch (e) {
      console.log(e);
    }
  };
  const onUpload = () => {
    console.log(newState);
    const {
      experienceLetter,
      iletsCertificate,
      ministryApproval,
      lastQualification,
      staffpassportCopy,
      passportCopy,
      idCardCopy,
      experienceLetterfilePath,
      iletsCertificatefilePath,
      ministryApprovalfilePath,
      lastQualificationfilePath,
      staffpassportCopyfilePath,
      passportCopyfilePath,
      idCardCopysfilePath,
    } = newState;
    let data = new FormData();
    data.append("staffId", id || paramsId);

    //parent docs
    if (experienceLetter === "Yes") {
      data.append(
        "filePath_experienceLetter",
        experienceLetterfilePath,
        experienceLetterfilePath.name
      );
      data.append(
        "experienceLetter",
        experienceLetter + "_" + Document_File_Constant.experienceLetter
      );
    } else {
      data.append(
        "experienceLetter",
        experienceLetter + "_" + Document_File_Constant.experienceLetter
      );
      data.append("filePath_experienceLetter", experienceLetterfilePath);
    }
    if (iletsCertificate === "Yes") {
      data.append(
        "filePath_iletsCertificate",
        iletsCertificatefilePath,
        iletsCertificatefilePath.name
      );
      data.append(
        "iletsCertificate",
        iletsCertificate + "_" + Document_File_Constant.iletsCertificate
      );
    } else {
      data.append(
        "iletsCertificate",
        iletsCertificate + "_" + Document_File_Constant.iletsCertificate
      );
      data.append("filePath_iletsCertificate", iletsCertificatefilePath);
    }
    if (ministryApproval === "Yes") {
      data.append(
        "filePath_ministryApproval",
        ministryApprovalfilePath,
        ministryApprovalfilePath.name
      );
      data.append(
        "ministryApproval",
        ministryApproval + "_" + Document_File_Constant.ministryApproval
      );
    } else {
      data.append(
        "ministryApproval",
        ministryApproval + "_" + Document_File_Constant.ministryApproval
      );
      data.append("filePath_ministryApproval", ministryApprovalfilePath);
    }
    if (lastQualification === "Yes") {
      data.append(
        "filePath_lastQualification",
        lastQualificationfilePath,
        lastQualificationfilePath.name
      );
      data.append(
        "lastQualification",
        lastQualification + "_" + Document_File_Constant.lastQualification
      );
    } else {
      data.append(
        "lastQualification",
        lastQualification + "_" + Document_File_Constant.lastQualification
      );
      data.append("filePath_lastQualification", lastQualificationfilePath);
    }
    if (passportCopy === "Yes") {
      data.append(
        "filePath_passportCopy",
        passportCopyfilePath,
        passportCopyfilePath.name
      );
      data.append(
        "passportCopy",
        passportCopy + "_" + Document_File_Constant.passportCopy
      );
    } else {
      data.append(
        "passportCopy",
        passportCopy + "_" + Document_File_Constant.passportCopy
      );
      data.append("filePath_passportCopy", passportCopyfilePath);
    }
    if (idCardCopy === "Yes") {
      data.append(
        "filePath_idCardCopy",
        idCardCopysfilePath,
        idCardCopysfilePath.name
      );
      data.append(
        "idCardCopy",
        idCardCopy + "_" + Document_File_Constant.idCardCopys
      );
    } else {
      data.append(
        "idCardCopy",
        idCardCopy + "_" + Document_File_Constant.idCardCopys
      );
      data.append("filePath_idCardCopy", idCardCopysfilePath);
    }

    data.append("accountId", props.name);
    data.append("accountType", props.role);
    let token = "";
    if (localStorage.jwtToken) token = localStorage.getItem("jwtToken");
    setCustomLoader(true);
    Axios.post(`${config.localhttp}/api/DocumentStaff`, data, {
      headers: { Authorization: `Bearer  ${token}` },
      onUploadProgress: (progressEvent) => {
        console.log(
          "percentage: ",
          setCheck(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          ) + "%"
        );
      },
    })
      .then((success) => {
        debugger;
        setMsg(success.data);
        props.snackbar();
        setCustomLoader(false);
        setTimeout(() => {
          setCheck(0);
          props.history.push("/dashboard/ViewStaff");
        }, 2000);
      })
      .catch((error) => {});
    // props.sharedAction
    // 	.simpleAddRequest('/api/DocumentStaff', data)
    // 	.then(success => {
    // 		setMsg(success);
    // 		props.snackbar();
    // 		setTimeout(() => {
    // 			props.history.push('/dashboard/ViewStaff');
    // 		}, 2000);
    // 		debugger;
    // 	})
    // 	.catch(error => {
    // 		setMsg(
    // 			error &&
    // 				error.response &&
    // 				error.response.data &&
    // 				error.response.data.Message
    // 		);
    // 		props.snackbar();
    // 		debugger;
    // 	});
  };

  return (
    <div
      style={{
        width: "80%",
        margin: "0 auto",
        boxShadow: "5px 5px 5px grey",
        borderRadius: 5,
      }}
    >
      <SnackBar backColor={"#000"} msg={snackbarMessage} />
      <h1
        style={{ background: themeColor.themeColors.primaryColor }}
        className="generalHead"
      >
        Staff Documents
      </h1>
      <br />
      <UploadProgressBar percent={check} />
      <div
        style={{
          margin: "0 auto",
          width: "max-content",
        }}
      >
        <p className="text-danger"><span className="text-dark">Note:</span> Please upload latest document </p>
        <StaffDoc
          fieldsData={StaffField.getStaffDocsDetails}
          errors={errors}
          edit={false}
          dispatch={dispatch}
          docsChange={docsChange}
          stateData={newState}
        />
      </div>
      <br />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ marginLeft: 10, marginBottom: 5 }}>
          <Button
            btnBackColor={themeColor.themeColors.primaryColor}
            icon={true}
            iconSize={12}
            textColor="#fff"
            iconClass="fas fa-upload"
            btnName="Upload"
            buttonClass="btn-smart-one"
            loaderBmargin={2}
            customClause={
              !newState["experienceLetter"] ||
              !newState["iletsCertificate"] ||
              !newState["ministryApproval"] ||
              !newState["lastQualification"] ||
              !newState["passportCopy"] ||
              !newState["idCardCopy"]
                ? true
                : false
            }
            stateData={{
              isLoading: customLoader,
            }}
            onHandleSubmit={onUpload}
          />
        </div>
        {type !== "edit" ? (
          <div>
            <span onClick={onSkipDocuments} class="badge badge-light">
              {" "}
              <a style={{ textDecoration: "none", fontSize: 15 }} href="">
                Skip Step{" "}
                <i
                  style={{
                    fontSize: 13,
                    padding: 5,
                    cursor: "pointer",
                    color: "#000",
                  }}
                  class={"fas fa-forward"}
                ></i>{" "}
              </a>
            </span>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
function mapStateToProps(state) {
  return {
    role: state.setUserReducer.users.role,
    name: state.setUserReducer.users.unique_name,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    sharedAction: bindActionCreators(sharedActions, dispatch),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StaffDocumentUploadSection);
