import * as actionTypes from '../actionTypes';

export function setAllBudgetInRedux(allData) {
	return {
		type: actionTypes.GET_ALL_BUDGET,
		payload: allData
	};
}
export function getSessionForBudget(allData) {
	return {
		type: actionTypes.SET_ALL_SESSION_NAME_INTO_BUDGET_SELECT,
		payload: allData
	};
}
export function getBudgetItemsForBudget(allData) {
	return {
		type: actionTypes.SET_ALL_BUDGETITEM_NAME_INTO_BUDGET_SELECT,
		payload: allData
	};
}
export function addBudget(Data) {
	return {
		type: actionTypes.ADD_BUDGET,
		payload: Data
	};
}
export function updateBudgetFlag(setFlag) {
	return {
		type: actionTypes.SET_LOADER_FLAG_BUDGET_ADD,
		payload: setFlag
	};
}
export function updateBudget(data) {
	return {
		type: actionTypes.EDIT_BUDGET_RECORD,
		payload: data
	};
}
export function deleteBudget(id) {
	return {
		type: actionTypes.DELETE_BUDGET,
		payload: id
	};
}
