import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as driverHistoryAction from '../../Actions/DriverHistory';
import * as sharedActions from '../../Actions/sharedActions';
import $ from 'jquery';
import SnackBar from '../../components/SnackBar/SnackBar';

class ViewDriverHistory extends Component {
	constructor(props) {
		super(props);
		this.state = {
			adminData: [],
			select: [],
			modalData: null,
			driverId: '',
			driverName: '',
			vehicleId: '',
			vehicleName: '',
			age: '',
			driverMobileNo: '',
			enabled: false,
			isLoading: true,
			disablepage: false,
			disableBPage: true,
			currentPage: 1,
			todosPerPage: 10,
			pageCounter: 10,
			msg: '',
			licenseIssueDate: '',
			licenseExpirayDate: ''
		};
	}
	handleClickNextFrwd = () => {
		const { adminData, todosPerPage, count, currentPage } = this.state;
		let pageNumbers = [];
		for (
			let i = 1;
			i <= Math.ceil(adminData.length / this.state.todosPerPage);
			i++
		) {
			pageNumbers.push(i);
		}
		console.log('pagelength: ', pageNumbers.length);
		console.log('current page: ', currentPage);
		if (currentPage === pageNumbers.length) {
			this.setState({
				disablepage: true
			});
		} else {
			this.setState(prevState => ({
				currentPage: prevState.currentPage + 1,
				disableBPage: false
			}));
		}
	};
	handleClickNextBack = () => {
		const { adminData, todosPerPage, count, currentPage } = this.state;
		console.log('current page: ', currentPage);
		if (currentPage === 1) {
			this.setState({
				disableBPage: true,
				disablepage: false
			});
		} else {
			this.setState(prevState => ({
				currentPage: prevState.currentPage - 1
			}));
			this.setState({
				disablepage: false
			});
		}
	};
	handleClickNext = event => {
		this.setState({
			currentPage: Number(event.target.id),
			disablepage: false,
			disableBPage: false
		});
	};
	static getDerivedStateFromProps(nextProps, prevState) {
		if (
			prevState.adminData !== nextProps.AllTransportData ||
			prevState.select !== nextProps.selectData
		) {
			return {
				adminData: nextProps.AllTransportData,
				select: nextProps.selectData
			};
		}
		return null;
	}
	componentDidUpdate() {
		$('[data-toggle="tooltip"]').tooltip();
	}
	componentDidMount() {
		this.props.sharedActions
			.gAllData(
				'/api/Driver',
				this.props.driverHistoryAction.setAllDriverNameInDriverHistorySelect
			)
			.then(success => {})
			.catch(error => {});
		$(document).ready(function() {
			$('[data-toggle="tooltip"]').tooltip();
		});
		this.props.sharedActions
			.getAllData(
				`/api/Driver/${this.state.driverId}`,
				this.props.driverHistoryAction.setAllDriverHistoryInRedux,
				this.props.driverHistoryAction.updateDriverFlag
			)
			.then(success => {
				this.setState({
					isLoading: false
				});
			})
			.catch(err => {
				this.props.driverHistoryAction.updateDriverFlag(false);

				this.setState({
					isLoading: false
				});

				console.log(err);
			});
	}
	btnTechHover = () => {
		$(document).ready(function() {
			$('button').hover(
				function() {
					$(this).css('box-shadow', '0px 5px 5px grey');
					$(this).css('border-color', 'white');
				},
				function() {
					$(this).css('box-shadow', '0px 0px 0px white');
				}
			);
		});
	};
	onTakeItemDelete = itemId => {
		this.setState({
			modalData: []
		});
		this.props.sharedActions
			.deleteRecord(
				'/api/Driver/',
				itemId,
				this.props.driverAction.deleteTransport
			)
			.then(success => {
				this.setState({
					msg: 'Delete Record Successfully!'
				});
				this.props.snackbar();
			})
			.catch(error => {});
	};
	onTakeItem = itemId => {
		this.setState({
			modalData: []
		});
		const { adminData, select } = this.state;
		let getData = adminData.filter(item => item.driverId === itemId);

		this.setState({
			driverId: getData[0].driverId,
			modalData: getData,
			driverName: getData[0].driverName,
			age: getData[0].age,
			driverMobileNo: getData[0].driverMobileNo,
			driverIdCardNo: getData[0].driverIdCardNo,
			driverLicense: getData[0].driverLicense,
			vehicleId: getData[0].vehicleId,
			vehicleName: getData[0].vehicleName,
			licenseIssueDate: getData[0].licenseIssueDate,
			licenseExpirayDate: getData[0].licenseExpirayDate
		});
	};

	onHandleText = e => {
		this.setState({
			[e.target.name]: e.target.value
		});
	};

	onHandleSubmit = e => {
		e.preventDefault();
		const {
			driverId,
			driverName,
			age,
			driverMobileNo,
			driverIdCardNo,
			driverLicense,
			vehicleId,
			licenseExpirayDate,
			licenseIssueDate
		} = this.state;
		var bodyParameters = {
			vehicleId,
			driverId,
			driverName,
			age,
			driverMobileNo,
			driverIdCardNo,
			driverLicense,
			licenseIssueDate,
			licenseExpirayDate,
			date: new Date().toDateString(),
			time: new Date().toTimeString()
		};
		this.props.sharedActions
			.editRecord(
				'/api/Driver/',
				driverId,
				bodyParameters,
				this.props.driverAction.updateDriver
			)
			.then(success => {
				this.setState({ enabled: false, msg: 'Edit Record Successfully!' });
				this.props.snackbar();
			})
			.catch(err => {});
	};
	onSelectChange = e => {
		console.log(e.target.value);
		let result = JSON.parse(e.target.value);
		console.log(result);
		this.setState({
			vehicleName: result.transportType,
			vehicleId: result.id
		});
	};
	onEditClick = itemId => {
		this.onTakeItem(itemId);
		this.setState({ enabled: false });
	};
	myFunction = () => {
		// Get the snackbar DIV
		var x = document.getElementById('snackbar');

		// StepperGeneralButtons the "show" class to DIV
		x.className = 'show';

		// After 3 seconds, remove the show class from DIV
		setTimeout(function() {
			x.className = x.className.replace('show', '');
		}, 3000);
	};
	onSelectDriverChange = e => {
		console.log(e.target.value);
		let result = JSON.parse(e.target.value);
		console.log(result);
		this.setState({
			driverId: result.driverId
		});
	};

	check = e => {
		this.props.sharedActions
			.getAllData(
				`/api/Driver/${this.state.driverId}`,
				this.props.driverHistoryAction.setAllDriverHistoryInRedux,
				this.props.driverHistoryAction.updateDriverFlag
			)
			.then(success => {
				this.setState({
					isLoading: false
				});
			})
			.catch(err => {
				this.props.driverHistoryAction.updateDriverFlag(false);

				this.setState({
					isLoading: false
				});

				console.log(err);
			});
		console.log(this.state);
	};
	render() {
		const { adminData, modalData, select } = this.state;
		console.log(this.state.adminData);

		console.log('modal data: ', this.state.modalData);
		const Loader = (
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<div class="spinner-border" style={{ color: '#01AC8A' }}></div>
			</div>
		);
		let pageNumbers = [];
		for (
			let i = 1;
			i <= Math.ceil(adminData.length / this.state.todosPerPage);
			i++
		) {
			pageNumbers.push(i);
		}
		const renderPageNumbers = pageNumbers.map((number, index) => {
			return index <= 3 ? (
				<li
					class={`page-item ${
						this.state.currentPage === number ? 'active' : ''
					} `}
				>
					<a
						key={number}
						id={number}
						onClick={this.handleClickNext}
						class="page-link "
						href="#"
					>
						{number}
					</a>
				</li>
			) : (
				''
			);
		});
		const { currentPage, todosPerPage } = this.state;
		const indexOfLastTodo = currentPage * todosPerPage;
		const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
		const currentTodos = adminData.slice(indexOfFirstTodo, indexOfLastTodo);
		const renderTodos = currentTodos.map((item, index) => {
			return (
				<tr key={index}>
					<th scope="row" className="bordersSpace" style={{ padding: 2 }}>
						{index + 1}
					</th>
					<td className="bordersSpace" style={{ padding: 2 }}>
						{item.fineDetails}
					</td>
					<td className="bordersSpace" style={{ padding: 2 }}>
						{item.fineFee}
					</td>
					<td className="bordersSpace" style={{ padding: 2 }}>
						{item.fineType}
					</td>
					<td className="bordersSpace" style={{ padding: 2 }}>
						{item.ticketNo}
					</td>
					<td className="bordersSpace" style={{ padding: 2 }}>
						{item.date}
					</td>
					<td className="bordersSpace" style={{ padding: 2 }}>
						{item.time}
					</td>
					<td className="bordersSpace" style={{ padding: 2 }}>
						{item.accountId}
					</td>
					<td className="bordersSpace" style={{ padding: 2 }}>
						{item.accountType}
					</td>

					{/* <td style={{ padding: 2 }} >
                    <a href="#" data-toggle="tooltip" title="Edit!">
                        <button id='firstbutton' onClick={() => this.onEditClick(item.driverId)} onMouseOver={this.btnTechHover()} data-toggle="modal" data-target="#myModal" style={{ cursor: 'pointer', fontSize: 20, border: 'none', marginLeft: 5, background: 'none', marginBottom: 5 }}  >
                            <i class="fas fa-pen"></i>
                        </button>
                    </a>
                    <a href="#" data-toggle="tooltip" title="Delete!">

                        <button onClick={() => this.onEditClick(item.driverId)} data-toggle="modal" data-target="#myModal1" style={{ cursor: 'pointer', fontSize: 20, border: 'none', background: 'none', marginLeft: 5, marginBottom: 5 }} >
                            <i style={{ color: '#D11A2A' }} class="fas fa-trash-alt"></i>
                        </button>
                    </a>

                </td> */}
				</tr>
			);
		});
		const MainContent = (
			<div>
				<div class="modal" id="myModal1">
					<div class="modal-dialog">
						<div class="modal-content modal_content_custom">
							<div
								style={{
									borderBottomLeftRadius: 30,
									borderBottomRightRadius: 30,
									borderTopLeftRadius: 18,
									borderTopRightRadius: 18
								}}
								class="modal-header modal_custom_header"
							>
								<h4 style={{ color: 'white' }} class="modal-title">
									Confirm
								</h4>
								<button
									style={{ color: 'white' }}
									type="button"
									class="close"
									data-dismiss="modal"
								>
									&times;
								</button>
							</div>
							<div class="modal-body">
								{modalData &&
									modalData.map((item, index) => (
										<div key={index}>
											<div style={{ textAlign: 'center' }}>
												<h4>Are you sure ?</h4>
											</div>
											<div
												style={{
													display: 'flex',
													justifyContent: 'space-evenly'
												}}
											>
												<div>
													<button
														disabled={this.state.enabled}
														data-dismiss="modal"
														onClick={() => this.onTakeItemDelete(this.state.id)}
														type="button"
														class="btn btn-primary buttonAppear"
													>
														Yes
													</button>
												</div>
												<div>
													<button
														data-dismiss="modal"
														disabled={this.state.enabled}
														type="button"
														class="btn btn-primary buttonAppear"
													>
														No
													</button>
												</div>
											</div>
										</div>
									))}
							</div>
						</div>
					</div>
				</div>
				<div class="modal" id="myModal">
					<div class="modal-dialog">
						<div class="modal-content modal_content_custom">
							<div
								style={{
									borderBottomLeftRadius: 30,
									borderBottomRightRadius: 30,
									borderTopLeftRadius: 18,
									borderTopRightRadius: 18
								}}
								class="modal-header modal_custom_header"
							>
								<h4 style={{ color: 'white' }} class="modal-title">
									Details
								</h4>
								<button
									style={{ color: 'white' }}
									type="button"
									class="close"
									data-dismiss="modal"
								>
									&times;
								</button>
							</div>
							<div class="modal-body">
								<form onSubmit={this.onHandleSubmit}>
									{modalData &&
										modalData.map((item, index) => (
											<div key={index}>
												<fieldset disabled={!this.state.enabled}>
													<div class="form-group">
														<label for="driverName">Driver Name:</label>
														<input
															style={{
																borderTop: 0,
																borderLeft: 0,
																borderRight: 0,
																boxShadow: 'none'
															}}
															type="text"
															value={this.state.driverName}
															onChange={this.onHandleText}
															name="driverName"
															class="form-control"
															id="driverName"
														/>
													</div>
													<div class="form-group">
														<label for="age">Age</label>
														<input
															required
															style={{
																borderTop: 0,
																borderLeft: 0,
																borderRight: 0,
																boxShadow: 'none'
															}}
															value={this.state.age}
															onChange={this.onHandleText}
															type="text"
															name="age"
															class="form-control"
															id="age"
														/>
													</div>
													<div class="form-group">
														<label for="driverMobileNo">Mobile No</label>
														<input
															required
															style={{
																borderTop: 0,
																borderLeft: 0,
																borderRight: 0,
																boxShadow: 'none'
															}}
															value={this.state.driverMobileNo}
															onChange={this.onHandleText}
															type="text"
															name="driverMobileNo"
															class="form-control"
															id="driverMobileNo"
														/>
													</div>
													<div class="form-group">
														<label for="driverIdCardNo">
															Driver Id Card No
														</label>
														<input
															required
															style={{
																borderTop: 0,
																borderLeft: 0,
																borderRight: 0,
																boxShadow: 'none'
															}}
															value={this.state.driverIdCardNo}
															onChange={this.onHandleText}
															type="text"
															name="driverIdCardNo"
															class="form-control"
															id="driverIdCardNo"
														/>
													</div>
													<div class="form-group">
														<label for="driverLicense">Driver License No</label>
														<input
															required
															style={{
																borderTop: 0,
																borderLeft: 0,
																borderRight: 0,
																boxShadow: 'none'
															}}
															value={this.state.driverLicense}
															onChange={this.onHandleText}
															type="text"
															name="driverLicense"
															class="form-control"
															id="driverLicense"
														/>
													</div>
													<div class="form-group">
														<label for="licenseIssueDate">
															License Issue Date
														</label>
														<input
															required
															style={{
																borderTop: 0,
																borderLeft: 0,
																borderRight: 0,
																boxShadow: 'none'
															}}
															value={this.state.licenseIssueDate}
															onChange={this.onHandleText}
															type="text"
															name="licenseIssueDate"
															class="form-control"
															id="licenseIssueDate"
														/>
													</div>
													<div class="form-group">
														<label for="licenseExpirayDate">
															License Expirey Date
														</label>
														<input
															required
															style={{
																borderTop: 0,
																borderLeft: 0,
																borderRight: 0,
																boxShadow: 'none'
															}}
															value={this.state.licenseExpirayDate}
															onChange={this.onHandleText}
															type="text"
															name="licenseExpirayDate"
															class="form-control"
															id="licenseExpirayDate"
														/>
													</div>
													<div class="form-group">
														<label for="driverLicense"> Vehicle Name</label>
														<input
															required
															style={{
																borderTop: 0,
																borderLeft: 0,
																borderRight: 0,
																boxShadow: 'none'
															}}
															value={this.state.vehicleName}
															onChange={this.onHandleText}
															type="text"
															name="vehicleName"
															class="form-control"
															id="vehicleName"
														/>
													</div>
													<div class="col-md-12">
														<div class="md-form mb-0">
															<label for="email" class="">
																<i
																	style={{ marginRight: 4 }}
																	className="fas fa-university animated fadeIn"
																/>
																Vehicle Name
															</label>
															<select
																onChange={this.onSelectChange}
																class="custom-select"
																name="select"
															>
																<option
																	selected={this.state.msg ? true : false}
																>
																	Choose Vehicle...
																</option>
																{select.map((item, index) => (
																	<option
																		key={index}
																		value={`${JSON.stringify(item)}`}
																	>
																		{item.transportType}
																	</option>
																))}
															</select>
														</div>
													</div>
												</fieldset>
												<br />
												<br />
												<button
													disabled={this.state.enabled}
													onClick={() => this.setState({ enabled: true })}
													type="button"
													class="btn btn-primary buttonAppear"
												>
													Edit
												</button>
												<button
													style={{ marginLeft: 5 }}
													disabled={!this.state.enabled}
													type="submit"
													class="btn btn-primary buttonAppear"
												>
													Save
												</button>
											</div>
										))}
								</form>
							</div>
							<div class="modal-footer">
								<button
									type="button"
									style={{ background: '#00435D' }}
									class="btn btn-danger"
									data-dismiss="modal"
								>
									Close
								</button>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-md-6" style={{ marginTop: 20 }}>
						<div class="md-form mb-0">
							<label for="email" class="">
								<i
									style={{ marginRight: 4 }}
									className="fas fa-car animated fadeIn"
								/>
								Driver Name
							</label>
							<select
								onChange={this.onSelectDriverChange}
								class="custom-select"
								name="itemId"
							>
								<option selected={this.state.msg ? true : false}>
									Choose Name...
								</option>
								{select.map((item, index) => (
									<option key={index} value={`${JSON.stringify(item)}`}>
										{item.driverName}
									</option>
								))}
							</select>
						</div>
					</div>
					<div class="col-md-6" style={{ marginTop: 50 }}>
						<div class="md-form mb-0">
							<button type="submit" class="btn btn-dark" onClick={this.check}>
								Search
							</button>
						</div>
					</div>
				</div>
				<br />
				<br />
				<br />
				<div className="table-responsive">
					<table class="table table-hover">
						<thead style={{ background: '#01AC8A', color: 'white' }}>
							<tr>
								<th scope="col">#</th>
								<th scope="col">Fine Details</th>
								<th scope="col">Fine Fee</th>
								<th scope="col">Fine Type</th>
								<th scope="col">Ticket No </th>
								<th scope="col">Date</th>
								<th scope="col">Time</th>
								<th scope="col">Account Id</th>
								<th scope="col">Account Type</th>
							</tr>
						</thead>
						<tbody>{renderTodos}</tbody>
					</table>
				</div>
			</div>
		);
		return (
			<div>
				<div className="page-header">
					<SnackBar msg={this.state.msg} />
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between'
						}}
					>
						<div>
							<h2>
								Driver's History&nbsp;<small class="text-muted">Details</small>
							</h2>
						</div>
						<nav aria-label="Page navigation example">
							<ul style={{ color: '#01AC8A' }} class="pagination">
								<li
									class={`page-item ${
										this.state.disableBPage ? 'disabled' : ''
									}`}
								>
									<a
										onClick={this.handleClickNextBack}
										class="page-link"
										href="#"
									>
										{this.state.disableBPage ? (
											<i
												style={{ color: 'grey' }}
												class="far fa-stop-circle"
											></i>
										) : (
											<i class="fas fa-backward"></i>
										)}
									</a>
								</li>
								{renderPageNumbers}
								<li
									class={`page-item ${
										this.state.disablepage ? 'disabled' : ''
									}`}
								>
									<a
										onClick={this.handleClickNextFrwd}
										class="page-link"
										href="#"
									>
										{this.state.disablepage ? (
											<i
												style={{ color: 'grey' }}
												class="far fa-stop-circle"
											></i>
										) : (
											<i class="fas fa-forward"></i>
										)}
									</a>
								</li>
							</ul>
						</nav>
					</div>
				</div>
				{this.props.allTransportState.apiFlag ? Loader : MainContent}
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		driverHistoryAction: bindActionCreators(driverHistoryAction, dispatch),
		sharedActions: bindActionCreators(sharedActions, dispatch)
	};
}
function mapStateToProps(state) {
	return {
		AllTransportData: state.setDriverHistoryReducer.Data,
		allTransportState: state.setDriverHistoryReducer,
		users: state.setUserReducer.users,
		selectData: state.setDriverHistoryReducer.select
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ViewDriverHistory);
